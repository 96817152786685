<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="500px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
        label-position="right"
        label-width="170px"
      >
        <el-form-item label="可用库存/预分配/已入库">
          <div class="display-flex">
            <el-input
              disabled
              v-model="dialog.inventoryCount"
            ></el-input>
            <el-input
              disabled
              v-model="dialog.allPreCount"
            ></el-input>
            <el-input
              disabled
              v-model="data.inCount"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="出库1/2/3">
          <div class="display-flex">
            <el-input
              disabled
              v-model="data.outCount"
            ></el-input>
            <el-input
              disabled
              v-model="data.outCount2"
            ></el-input>
            <el-input
              disabled
              v-model="data.outCount3"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="申请数量">
          <el-input
            disabled
            v-model="data.count"
          ></el-input>
        </el-form-item>
        <el-form-item label="已预分配库存数量">
          <div class="display-flex">
            <el-input
              disabled
              v-model="dialog.preCount"
            ></el-input>
            <el-input-number
              :disabled="dialog.inventoryCount === 0"
              :controls="false"
              v-model="dialog.number"
              :min="0"
              :max="dialog.maxPreCount"
            ></el-input-number>
            <el-button @click="okClick">OK</el-button>
          </div>
        </el-form-item>
        <el-form-item label="已订货数量">
          <el-input
            disabled
            v-model="data.orderCount"
          ></el-input>
        </el-form-item>
        <el-form-item label="待订货数量">
          <el-input
            disabled
            v-model="dialog.unorderCount"
          ></el-input>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { PreAllocated } from '@/api/warehouse/purchaseOrders'

export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '预分配库存设备',
        inventoryCount: 0,
        allPreCount: 0,
        preCount: 0,
        unorderCount: 0,
        number: 0,
        maxPreCount: 0,
      },
      rules: {
      },


    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        Object.assign(this.dialog, {
          preCount: this.data.preCount,
          allPreCount: this.data.allPreCount,
          inventoryCount: this.data.inventoryCount,
          number: 0,
          unorderCount: this.data.count - this.data.orderCount - this.data.preCount,
          maxPreCount: this.data.inventoryCount + this.data.preCount,
        })

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    okClick () {
      this.dialog.unorderCount = this.dialog.unorderCount + this.dialog.preCount - this.dialog.number;
      this.dialog.unorderCount = this.dialog.unorderCount < 0 ? 0 : this.dialog.unorderCount;

      this.dialog.inventoryCount = this.dialog.inventoryCount + this.dialog.preCount - this.dialog.number;
      this.dialog.allPreCount = this.dialog.allPreCount - this.dialog.preCount + this.dialog.number;
      this.dialog.preCount = this.dialog.number;
      this.dialog.number = 0;
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          id: this.data.id,
          count: this.dialog.preCount,
          // count: this.dialog.number,
        }
        PreAllocated(param).then(res => {
          if (res.success) {
            this.$emit('update');
            this.$message.success(this.$i18n.t('message.add'))
            this.dialog.visible = false;
          }
        });
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
  .el-input:first-child,
  .el-input-number:first-child {
    margin-left: 0px;
  }
  .el-input,
  .el-input-number {
    flex: 1;
    margin-left: 10px;
  }
  .el-button {
    flex: 1;
    margin-left: 10px;
    padding: 0;
  }
}
.el-input-number,
.el-input {
  width: 100%;
  ::v-deep .el-input__inner {
    padding: 0 5px;
    text-align: center;
  }
}
</style>