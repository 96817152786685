<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="1200px"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form
            :model="dialog"
            :rules="rules"
            size="small"
            class="user-form"
            ref="dialogForm"
            label-position="left"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('contactList.dialog.label1')"
                  prop="name"
                >
                  <el-input v-model="dialog.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  :label="$t('contactList.dialog.label13')"
                  prop="number"
                >
                  <el-input v-model="dialog.number"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  :label="$t('contactList.dialog.label14')"
                  prop="position"
                >
                  <el-input v-model="dialog.position"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('contactList.dialog.label2')"
                  prop="organic"
                >
                  <el-select
                    v-model="dialog.organic"
                    filterable
                  >
                    <el-option
                      v-for="item in organics"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('contactList.dialog.label3')"
                  prop="dataAccessGroup"
                >
                  <el-select
                    v-model="dialog.dataAccessGroup"
                    @change="dataAccessGroupChange"
                    filterable
                  >
                    <el-option
                      v-for="item in dataAccessGroups"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              :label="$t('contactList.dialog.label4')"
              prop="email1"
            >
              <el-input v-model="dialog.email1"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('contactList.dialog.label5')"
              prop="email2"
            >
              <el-input v-model="dialog.email2"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('contactList.dialog.label6')"
                  prop="phone1"
                >
                  <el-input v-model="dialog.phone1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('contactList.dialog.label7')"
                  prop="phone2"
                >
                  <el-input v-model="dialog.phone2"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              :label="$t('contactList.dialog.label8')"
              prop="site"
            >
              <el-select
                v-model="dialog.site"
                filterable
              >
                <el-option
                  v-for="item in stores"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('contactList.dialog.label9')"
              prop="reportGroup"
            >
              <el-select
                v-model="dialog.reportGroup"
                filterable
              >
                <el-option
                  v-for="item in reports"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('contactList.dialog.label10')"
              prop="status"
            >
              <el-radio-group v-model="dialog.status">
                <el-radio label="0">Active</el-radio>
                <el-radio label="1">Terminated</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('contactList.dialog.label12')"
              prop="supervisor"
            >
              <el-checkbox v-model="dialog.supervisor"></el-checkbox>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col
          :span="12"
          class="table-wrap"
        >
          <el-row>
            <div class="input-wrap">
              {{$t('contactList.dialog.label15')}}
              <el-button-group class="input-group">
                <el-button
                  size="medium"
                  @click="showAdd"
                >{{$t('operation.add')}}</el-button>
                <el-button
                  size="medium"
                  @click="delItem"
                >{{$t('operation.del')}}</el-button>
              </el-button-group>
            </div>
          </el-row>
          <el-row
            class="table-row"
            ref="tableRow"
          >
            <el-table
              border
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
              @selection-change="val => tableSelected = val"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('contactList.dialog.col1')"
                sortable
                prop="storeId"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('contactList.dialog.col2')"
                sortable
                prop="name"
              >
              </el-table-column>
            </el-table>
          </el-row>
        </el-col>
      </el-row>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <store-dialog
      :visible.sync="StoreDialogVisible"
      :id="dialog.site"
      @submit="storeSubmit"
    ></store-dialog>
  </div>
</template>
<script>
// 组件
import StoreDialog from './StoreDialog';
// 接口
import {
  AddContact,
  GetContactByBranch
} from '@/api/contactList'
import { GetSiteGroupAll } from '@/api/stores'
import { GetSysReportGroupAll } from '@/api/reportGroup'
import {
  GetOrganization,
  GetDataAccessGroup,
} from '@/api/organizations'

export default {
  components: {
    StoreDialog
  },
  props: ['visible', 'data'],
  data () {
    return {
      StoreDialogVisible: false,
      organics: [],
      dataAccessGroups: [],
      stores: [],
      reports: [],
      dialog: {
        visible: false,
        title: '',
        id: null,
        name: '',
        organic: '',
        dataAccessGroup: localStorage.accessGroupId,
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        site: '',
        reportGroup: '',
        status: '0',
        createAccount: false,
        supervisor: false,
        number: '',
        position: '',
      },
      rules: {},

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 668,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();

        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('contactList.dialog.title2'),
              id: this.data.id,
              name: this.data.name,
              organic: this.data.orgId,
              dataAccessGroup: this.data.accessGroupId,
              email1: this.data.email1,
              email2: this.data.email2,
              phone1: this.data.phone,
              phone2: this.data.secondPhone,
              site: this.data.sgId,
              reportGroup: this.data.rgId,
              status: this.data.status,
              supervisor: Boolean(this.data.supervisor),
              number: this.data.number,
              position: this.data.post,
            })
            this.getTableData();
          })
        } else {
          this.dialog.title = this.$i18n.t('contactList.dialog.title1')
        }


        this.dataAccessGroupChange();
        // 获取数据访问组
        if (this.dataAccessGroups.length === 0) {
          GetDataAccessGroup({ accessGroupId: this.dialog.dataAccessGroup }).then(res => this.dataAccessGroups = res);
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: 9999,
        page: 1,
        contactId: this.dialog.id,
      }
      GetContactByBranch(param).then(res => {
        this.tableData = res.list;
      })
    },
    showAdd () {
      this.StoreDialogVisible = true;
    },
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.tableData = this.tableData.filter(item => !this.tableSelected.includes(item));
    },
    // 网点提交
    storeSubmit (data) {
      let ids = this.tableData.map(item => item.id);
      data = data.filter(item => !ids.includes(item.id));
      this.tableData = this.tableData.concat(data);
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          email1: this.dialog.email1,
          email2: this.dialog.email2,
          phone: this.dialog.phone1,
          secondPhone: this.dialog.phone2,
          status: this.dialog.status,
          orgId: this.dialog.organic,
          accessGroupId: this.dialog.dataAccessGroup,
          sgId: this.dialog.site,
          rgId: this.dialog.reportGroup,
          userAccout: Number(this.dialog.createAccount),
          supervisor: Number(this.dialog.supervisor),
          number: this.dialog.number,
          post: this.dialog.position,
          brandIds: this.tableData.map(item => item.id).join(','),
        }
        let msg = this.$i18n.t('message.add');
        if (this.dialog.id) {
          param.id = this.dialog.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddContact(param).then(res => {
          if (res.success) {
            this.$emit('update');
            this.$message.success(msg)
            this.dialog.visible = false;
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    // 数据访问组变更
    dataAccessGroupChange (e) {
      let param = { accessGroupId: this.dialog.dataAccessGroup }
      if (e !== undefined) {
        this.dialog.organic = '';
        this.dialog.site = '';
        this.dialog.reportGroup = '';
      }
      // 获取组织
      GetOrganization(param).then(res => this.organics = res)
      // 获取店铺组
      GetSiteGroupAll(param).then(res => this.stores = res)
      // 获取系统报表组
      GetSysReportGroupAll(param).then(res => this.reports = res);
    },
  }
}
</script>
<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    min-width: 160px;
  }
}
</style>