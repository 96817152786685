<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">{{operator}}</div>
      <el-input
        class="search-input"
        size="small"
        v-model="searchVal"
        placeholder="Search"
        clearable
        @input="searchData"
      ></el-input>
      <div
        class="table-wrap"
        ref="tableWrap"
      >
        <u-table
          ref="table"
          :height="tableHeight"
          :row-height="30"
          use-virtual
        >
          <u-table-column width="50">
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-checkbox
                v-model="all"
                @change="allChange"
              ></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
                @change="checkChange"
              ></el-checkbox>
            </template>
          </u-table-column>
          <u-table-column
            :label="title"
            :prop="columnProp"
          ></u-table-column>
        </u-table>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-button
            size="small"
            @click="selectAll"
          >{{all ? 'Unselect All' : 'Select All'}}</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="showQuery"
          >Detail</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'

export default {
  props: ['visible', 'typeReport', 'zindex', 'data'],
  data () {
    return {
      treeLoading: false,
      operator: 'In List',
      searchVal: '',
      tableSelected: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      title: '',
      columnProp: '',
      storeCount: 0,
      selectType: false,

      tableHeight: null,
      itemNumber: 0,
      all: false,
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        if (this.treeData.length === 0) {
          this.treeData = JSON.parse(JSON.stringify(this.$global.BranchData.map(item => {
            item.checked = false;
            return item;
          })))
          this.$nextTick(() => {
            this.tableHeight = this.$refs.tableWrap.offsetHeight;
            // this.$refs.table.reloadData(this.treeData);
            this.initData();
          })
        }
      }
    },
    'data': {
      handler: function (newVal, oldVal) {
        this.initData();
      },
      deep: true
    },
  },
  created () {
  },
  methods: {
    initData () {
      let result
      this.itemNumber = this.treeData.length;
      this.title = this.data.data.field;
      this.columnProp = this.data.data.field === 'Branch Code' ? 'storeId' : 'storeId5';
      this.operator = this.$global.typeOperatorsText[this.data.data.fieldType][this.data.data.operator];

      if (this.data.con === 'ALL') {
        this.treeData.forEach(item => item.checked = true)
        this.itemNumber = 0;
        this.all = true;
      } else {
        if (this.data.data.operator === 2) {
          result = this.data.data.list;
        } else {
          result = this.data.data.condition.toString().split(',');
        }
        this.treeData = this.treeData.map(item => {
          if (result.includes(item[this.columnProp])) {
            this.itemNumber--;
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        })

        let temp = [];
        for (let i = this.treeData.length - 1; i >= 0; i--) {
          if (this.treeData[i].checked) {
            temp = temp.concat(this.treeData.splice(i, 1))
          }
        }
        temp = temp.concat(this.treeData)
        this.treeData = temp;
      }
      this.$refs.table.reloadData(this.treeData);

    },
    dialogClose () {
      this.$emit('update:visible', false);
    },
    submit () {
      let label = 'ALL';
      let stores = this.treeData.filter(item => item.checked).map(item => item[this.columnProp])
      if (stores.length !== this.treeData.length) {
        label = stores.join(',');
      }
      this.$emit('submit', Object.assign(this.data, {
        con: label,
        data: Object.assign(this.data.data, {
          operator: 2,
          list: stores
        })
      }))
      this.dialogClose();
    },
    selectAll () {
      if (this.all) {
        this.all = false;
        this.allChange(false)
      } else {
        this.all = true;
        this.allChange(true)
      }
    },
    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    showQuery () {
      this.$emit('showQuery');
    },

    searchData: debounce(function () {
      let result = this.treeData.filter(item => !this.searchVal || item[this.columnProp].toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) !== -1);
      this.$refs.table.reloadData(result);
    }, 100),
    allChange (val) {
      this.treeData.forEach(item => item.checked = val)
      if (val) {
        this.itemNumber = 0;
      } else {
        this.itemNumber = this.treeData.length;
      }
    },
    // 复选框
    checkChange (val) {
      if (val) {
        this.itemNumber--;
      } else {
        this.itemNumber++;
      }
      this.all = this.itemNumber === 0;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
.table-wrap {
  height: calc(100% - 26px - 37px);

  ::v-deep th,
  ::v-deep td {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px !important;
  }
  ::v-deep th {
    // border: 1px solid #5072a62e;
  }
  ::v-deep td {
    border: none;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>