const timeZones = [{
  "id": 0,
  "name": "UTC-11:00: 萨摩亚群岛标准时间 (Pacific/Midway)",
  "timeZone": "-11"
}, {
  "id": 1,
  "name": "UTC-11:00: 纽威岛时间 (Pacific/Niue)",
  "timeZone": "-11"
}, {
  "id": 2,
  "name": "UTC-11:00: 萨摩亚群岛标准时间 (Pacific/Pago_Pago)",
  "timeZone": "-11"
}, {
  "id": 3,
  "name": "UTC-11:00: 萨摩亚群岛标准时间 (Pacific/Samoa)",
  "timeZone": "-11"
}, {
  "id": 4,
  "name": "UTC-10:00: 夏威夷标准时间 (Pacific/Honolulu)",
  "timeZone": "-10"
}, {
  "id": 5,
  "name": "UTC-10:00: 夏威夷标准时间 (Pacific/Johnston)",
  "timeZone": "-10"
}, {
  "id": 6,
  "name": "UTC-10:00: 库克群岛时间 (Pacific/Rarotonga)",
  "timeZone": "-10"
}, {
  "id": 7,
  "name": "UTC-10:00: 大溪地岛时间 (Pacific/Tahiti)",
  "timeZone": "-10"
}, {
  "id": 8,
  "name": "UTC-9:30: 马克萨斯时间 (Pacific/Marquesas)",
  "timeZone": "-9"
}, {
  "id": 9,
  "name": "UTC-9:00: 夏威夷夏令时 (America/Adak)",
  "timeZone": "-9"
}, {
  "id": 10,
  "name": "UTC-9:00: 夏威夷夏令时 (America/Atka)",
  "timeZone": "-9"
}, {
  "id": 11,
  "name": "UTC-9:00: 冈比亚时间 (Pacific/Gambier)",
  "timeZone": "-9"
}, {
  "id": 12,
  "name": "UTC-8:00: 阿拉斯加夏令时 (America/Anchorage)",
  "timeZone": "-8"
}, {
  "id": 13,
  "name": "UTC-8:00: 阿拉斯加夏令时 (America/Juneau)",
  "timeZone": "-8"
}, {
  "id": 14,
  "name": "UTC-8:00: 阿拉斯加夏令时 (America/Metlakatla)",
  "timeZone": "-8"
}, {
  "id": 15,
  "name": "UTC-8:00: 阿拉斯加夏令时 (America/Nome)",
  "timeZone": "-8"
}, {
  "id": 16,
  "name": "UTC-8:00: 阿拉斯加夏令时 (America/Sitka)",
  "timeZone": "-8"
}, {
  "id": 17,
  "name": "UTC-8:00: 阿拉斯加夏令时 (America/Yakutat)",
  "timeZone": "-8"
}, {
  "id": 18,
  "name": "UTC-8:00: 皮特康岛标准时间 (Pacific/Pitcairn)",
  "timeZone": "-8"
}, {
  "id": 19,
  "name": "UTC-7:00: Mountain 标准时间 (America/Creston)",
  "timeZone": "-7"
}, {
  "id": 20,
  "name": "UTC-7:00: 太平洋夏令时 (America/Dawson)",
  "timeZone": "-7"
}, {
  "id": 21,
  "name": "UTC-7:00: Mountain 标准时间 (America/Dawson_Creek)",
  "timeZone": "-7"
}, {
  "id": 22,
  "name": "UTC-7:00: 太平洋夏令时 (America/Ensenada)",
  "timeZone": "-7"
}, {
  "id": 23,
  "name": "UTC-7:00: Mountain 标准时间 (America/Fort_Nelson)",
  "timeZone": "-7"
}, {
  "id": 24,
  "name": "UTC-7:00: Mountain 标准时间 (America/Hermosillo)",
  "timeZone": "-7"
}, {
  "id": 25,
  "name": "UTC-7:00: 太平洋夏令时 (America/Los_Angeles)",
  "timeZone": "-7"
}, {
  "id": 26,
  "name": "UTC-7:00: Mountain 标准时间 (America/Phoenix)",
  "timeZone": "-7"
}, {
  "id": 27,
  "name": "UTC-7:00: 太平洋夏令时 (America/Santa_Isabel)",
  "timeZone": "-7"
}, {
  "id": 28,
  "name": "UTC-7:00: 太平洋夏令时 (America/Tijuana)",
  "timeZone": "-7"
}, {
  "id": 29,
  "name": "UTC-7:00: 太平洋夏令时 (America/Vancouver)",
  "timeZone": "-7"
}, {
  "id": 30,
  "name": "UTC-7:00: 太平洋夏令时 (America/Whitehorse)",
  "timeZone": "-7"
}, {
  "id": 31,
  "name": "UTC-6:00: 中央标准时间 (America/Belize)",
  "timeZone": "-6"
}, {
  "id": 32,
  "name": "UTC-6:00: Mountain 夏令时 (America/Boise)",
  "timeZone": "-6"
}, {
  "id": 33,
  "name": "UTC-6:00: Mountain 夏令时 (America/Cambridge_Bay)",
  "timeZone": "-6"
}, {
  "id": 34,
  "name": "UTC-6:00: Mountain 夏令时 (America/Chihuahua)",
  "timeZone": "-6"
}, {
  "id": 35,
  "name": "UTC-6:00: 中央标准时间 (America/Costa_Rica)",
  "timeZone": "-6"
}, {
  "id": 36,
  "name": "UTC-6:00: Mountain 夏令时 (America/Denver)",
  "timeZone": "-6"
}, {
  "id": 37,
  "name": "UTC-6:00: Mountain 夏令时 (America/Edmonton)",
  "timeZone": "-6"
}, {
  "id": 38,
  "name": "UTC-6:00: 中央标准时间 (America/El_Salvador)",
  "timeZone": "-6"
}, {
  "id": 39,
  "name": "UTC-6:00: 中央标准时间 (America/Guatemala)",
  "timeZone": "-6"
}, {
  "id": 40,
  "name": "UTC-6:00: Mountain 夏令时 (America/Inuvik)",
  "timeZone": "-6"
}, {
  "id": 41,
  "name": "UTC-6:00: 中央标准时间 (America/Managua)",
  "timeZone": "-6"
}, {
  "id": 42,
  "name": "UTC-6:00: Mountain 夏令时 (America/Mazatlan)",
  "timeZone": "-6"
}, {
  "id": 43,
  "name": "UTC-6:00: Mountain 夏令时 (America/Ojinaga)",
  "timeZone": "-6"
}, {
  "id": 44,
  "name": "UTC-6:00: 中央标准时间 (America/Regina)",
  "timeZone": "-6"
}, {
  "id": 45,
  "name": "UTC-6:00: Mountain 夏令时 (America/Shiprock)",
  "timeZone": "-6"
}, {
  "id": 46,
  "name": "UTC-6:00: 中央标准时间 (America/Swift_Current)",
  "timeZone": "-6"
}, {
  "id": 47,
  "name": "UTC-6:00: 中央标准时间 (America/Tegucigalpa)",
  "timeZone": "-6"
}, {
  "id": 48,
  "name": "UTC-6:00: Mountain 夏令时 (America/Yellowknife)",
  "timeZone": "-6"
}, {
  "id": 49,
  "name": "UTC-6:00: 复活岛时间 (Chile/EasterIsland)",
  "timeZone": "-6"
}, {
  "id": 50,
  "name": "UTC-6:00: 复活岛时间 (Pacific/Easter)",
  "timeZone": "-6"
}, {
  "id": 51,
  "name": "UTC-6:00: 加拉巴哥时间 (Pacific/Galapagos)",
  "timeZone": "-6"
}, {
  "id": 52,
  "name": "UTC-5:00: 东部标准时间 (America/Atikokan)",
  "timeZone": "-5"
}, {
  "id": 53,
  "name": "UTC-5:00: 中央夏令时 (America/Bahia_Banderas)",
  "timeZone": "-5"
}, {
  "id": 54,
  "name": "UTC-5:00: 哥伦比亚时间 (America/Bogota)",
  "timeZone": "-5"
}, {
  "id": 55,
  "name": "UTC-5:00: 东部标准时间 (America/Cancun)",
  "timeZone": "-5"
}, {
  "id": 56,
  "name": "UTC-5:00: 东部标准时间 (America/Cayman)",
  "timeZone": "-5"
}, {
  "id": 57,
  "name": "UTC-5:00: 中央夏令时 (America/Chicago)",
  "timeZone": "-5"
}, {
  "id": 58,
  "name": "UTC-5:00: 东部标准时间 (America/Coral_Harbour)",
  "timeZone": "-5"
}, {
  "id": 59,
  "name": "UTC-5:00: Acre 时间 (America/Eirunepe)",
  "timeZone": "-5"
}, {
  "id": 60,
  "name": "UTC-5:00: 厄瓜多尔时间 (America/Guayaquil)",
  "timeZone": "-5"
}, {
  "id": 61,
  "name": "UTC-5:00: 中央夏令时 (America/Indiana/Knox)",
  "timeZone": "-5"
}, {
  "id": 62,
  "name": "UTC-5:00: 中央夏令时 (America/Indiana/Tell_City)",
  "timeZone": "-5"
}, {
  "id": 63,
  "name": "UTC-5:00: 东部标准时间 (America/Jamaica)",
  "timeZone": "-5"
}, {
  "id": 64,
  "name": "UTC-5:00: 中央夏令时 (America/Knox_IN)",
  "timeZone": "-5"
}, {
  "id": 65,
  "name": "UTC-5:00: 秘鲁时间 (America/Lima)",
  "timeZone": "-5"
}, {
  "id": 66,
  "name": "UTC-5:00: 中央夏令时 (America/Matamoros)",
  "timeZone": "-5"
}, {
  "id": 67,
  "name": "UTC-5:00: 中央夏令时 (America/Menominee)",
  "timeZone": "-5"
}, {
  "id": 68,
  "name": "UTC-5:00: 中央夏令时 (America/Merida)",
  "timeZone": "-5"
}, {
  "id": 69,
  "name": "UTC-5:00: 中央夏令时 (America/Mexico_City)",
  "timeZone": "-5"
}, {
  "id": 70,
  "name": "UTC-5:00: 中央夏令时 (America/Monterrey)",
  "timeZone": "-5"
}, {
  "id": 71,
  "name": "UTC-5:00: 中央夏令时 (America/North_Dakota/Beulah)",
  "timeZone": "-5"
}, {
  "id": 72,
  "name": "UTC-5:00: 中央夏令时 (America/North_Dakota/Center)",
  "timeZone": "-5"
}, {
  "id": 73,
  "name": "UTC-5:00: 中央夏令时 (America/North_Dakota/New_Salem)",
  "timeZone": "-5"
}, {
  "id": 74,
  "name": "UTC-5:00: 东部标准时间 (America/Panama)",
  "timeZone": "-5"
}, {
  "id": 75,
  "name": "UTC-5:00: Acre 时间 (America/Porto_Acre)",
  "timeZone": "-5"
}, {
  "id": 76,
  "name": "UTC-5:00: 中央夏令时 (America/Rainy_River)",
  "timeZone": "-5"
}, {
  "id": 77,
  "name": "UTC-5:00: 中央夏令时 (America/Rankin_Inlet)",
  "timeZone": "-5"
}, {
  "id": 78,
  "name": "UTC-5:00: 中央夏令时 (America/Resolute)",
  "timeZone": "-5"
}, {
  "id": 79,
  "name": "UTC-5:00: Acre 时间 (America/Rio_Branco)",
  "timeZone": "-5"
}, {
  "id": 80,
  "name": "UTC-5:00: 中央夏令时 (America/Winnipeg)",
  "timeZone": "-5"
}, {
  "id": 81,
  "name": "UTC-5:00: Acre 时间 (Brazil/Acre)",
  "timeZone": "-5"
}, {
  "id": 82,
  "name": "UTC-4:00: 大西洋标准时间 (America/Anguilla)",
  "timeZone": "-4"
}, {
  "id": 83,
  "name": "UTC-4:00: 大西洋标准时间 (America/Antigua)",
  "timeZone": "-4"
}, {
  "id": 84,
  "name": "UTC-4:00: 大西洋标准时间 (America/Aruba)",
  "timeZone": "-4"
}, {
  "id": 85,
  "name": "UTC-4:00: 巴拉圭时间 (America/Asuncion)",
  "timeZone": "-4"
}, {
  "id": 86,
  "name": "UTC-4:00: 大西洋标准时间 (America/Barbados)",
  "timeZone": "-4"
}, {
  "id": 87,
  "name": "UTC-4:00: 大西洋标准时间 (America/Blanc-Sablon)",
  "timeZone": "-4"
}, {
  "id": 88,
  "name": "UTC-4:00: 亚马逊时间 (America/Boa_Vista)",
  "timeZone": "-4"
}, {
  "id": 89,
  "name": "UTC-4:00: 亚马逊时间 (America/Campo_Grande)",
  "timeZone": "-4"
}, {
  "id": 90,
  "name": "UTC-4:00: 委内瑞拉时间 (America/Caracas)",
  "timeZone": "-4"
}, {
  "id": 91,
  "name": "UTC-4:00: 亚马逊时间 (America/Cuiaba)",
  "timeZone": "-4"
}, {
  "id": 92,
  "name": "UTC-4:00: 大西洋标准时间 (America/Curacao)",
  "timeZone": "-4"
}, {
  "id": 93,
  "name": "UTC-4:00: 东部夏令时 (America/Detroit)",
  "timeZone": "-4"
}, {
  "id": 94,
  "name": "UTC-4:00: 大西洋标准时间 (America/Dominica)",
  "timeZone": "-4"
}, {
  "id": 95,
  "name": "UTC-4:00: 东部夏令时 (America/Fort_Wayne)",
  "timeZone": "-4"
}, {
  "id": 96,
  "name": "UTC-4:00: 东部夏令时 (America/Grand_Turk)",
  "timeZone": "-4"
}, {
  "id": 97,
  "name": "UTC-4:00: 大西洋标准时间 (America/Grenada)",
  "timeZone": "-4"
}, {
  "id": 98,
  "name": "UTC-4:00: 大西洋标准时间 (America/Guadeloupe)",
  "timeZone": "-4"
}, {
  "id": 99,
  "name": "UTC-4:00: 圭亚那时间 (America/Guyana)",
  "timeZone": "-4"
}, {
  "id": 100,
  "name": "UTC-4:00: 古巴夏令时 (America/Havana)",
  "timeZone": "-4"
}, {
  "id": 101,
  "name": "UTC-4:00: 东部夏令时 (America/Indiana/Indianapolis)",
  "timeZone": "-4"
}, {
  "id": 102,
  "name": "UTC-4:00: 东部夏令时 (America/Indiana/Marengo)",
  "timeZone": "-4"
}, {
  "id": 103,
  "name": "UTC-4:00: 东部夏令时 (America/Indiana/Petersburg)",
  "timeZone": "-4"
}, {
  "id": 104,
  "name": "UTC-4:00: 东部夏令时 (America/Indiana/Vevay)",
  "timeZone": "-4"
}, {
  "id": 105,
  "name": "UTC-4:00: 东部夏令时 (America/Indiana/Vincennes)",
  "timeZone": "-4"
}, {
  "id": 106,
  "name": "UTC-4:00: 东部夏令时 (America/Indiana/Winamac)",
  "timeZone": "-4"
}, {
  "id": 107,
  "name": "UTC-4:00: 东部夏令时 (America/Indianapolis)",
  "timeZone": "-4"
}, {
  "id": 108,
  "name": "UTC-4:00: 东部夏令时 (America/Iqaluit)",
  "timeZone": "-4"
}, {
  "id": 109,
  "name": "UTC-4:00: 东部夏令时 (America/Kentucky/Louisville)",
  "timeZone": "-4"
}, {
  "id": 110,
  "name": "UTC-4:00: 东部夏令时 (America/Kentucky/Monticello)",
  "timeZone": "-4"
}, {
  "id": 111,
  "name": "UTC-4:00: 大西洋标准时间 (America/Kralendijk)",
  "timeZone": "-4"
}, {
  "id": 112,
  "name": "UTC-4:00: 玻利维亚时间 (America/La_Paz)",
  "timeZone": "-4"
}, {
  "id": 113,
  "name": "UTC-4:00: 东部夏令时 (America/Louisville)",
  "timeZone": "-4"
}, {
  "id": 114,
  "name": "UTC-4:00: 大西洋标准时间 (America/Lower_Princes)",
  "timeZone": "-4"
}, {
  "id": 115,
  "name": "UTC-4:00: 亚马逊时间 (America/Manaus)",
  "timeZone": "-4"
}, {
  "id": 116,
  "name": "UTC-4:00: 大西洋标准时间 (America/Marigot)",
  "timeZone": "-4"
}, {
  "id": 117,
  "name": "UTC-4:00: 大西洋标准时间 (America/Martinique)",
  "timeZone": "-4"
}, {
  "id": 118,
  "name": "UTC-4:00: 东部夏令时 (America/Montreal)",
  "timeZone": "-4"
}, {
  "id": 119,
  "name": "UTC-4:00: 大西洋标准时间 (America/Montserrat)",
  "timeZone": "-4"
}, {
  "id": 120,
  "name": "UTC-4:00: 东部夏令时 (America/Nassau)",
  "timeZone": "-4"
}, {
  "id": 121,
  "name": "UTC-4:00: 东部夏令时 (America/New_York)",
  "timeZone": "-4"
}, {
  "id": 122,
  "name": "UTC-4:00: 东部夏令时 (America/Nipigon)",
  "timeZone": "-4"
}, {
  "id": 123,
  "name": "UTC-4:00: 东部夏令时 (America/Pangnirtung)",
  "timeZone": "-4"
}, {
  "id": 124,
  "name": "UTC-4:00: 东部夏令时 (America/Port-au-Prince)",
  "timeZone": "-4"
}, {
  "id": 125,
  "name": "UTC-4:00: 大西洋标准时间 (America/Port_of_Spain)",
  "timeZone": "-4"
}, {
  "id": 126,
  "name": "UTC-4:00: 亚马逊时间 (America/Porto_Velho)",
  "timeZone": "-4"
}, {
  "id": 127,
  "name": "UTC-4:00: 大西洋标准时间 (America/Puerto_Rico)",
  "timeZone": "-4"
}, {
  "id": 128,
  "name": "UTC-4:00: 智利时间 (America/Santiago)",
  "timeZone": "-4"
}, {
  "id": 129,
  "name": "UTC-4:00: 大西洋标准时间 (America/Santo_Domingo)",
  "timeZone": "-4"
}, {
  "id": 130,
  "name": "UTC-4:00: 大西洋标准时间 (America/St_Barthelemy)",
  "timeZone": "-4"
}, {
  "id": 131,
  "name": "UTC-4:00: 大西洋标准时间 (America/St_Kitts)",
  "timeZone": "-4"
}, {
  "id": 132,
  "name": "UTC-4:00: 大西洋标准时间 (America/St_Lucia)",
  "timeZone": "-4"
}, {
  "id": 133,
  "name": "UTC-4:00: 大西洋标准时间 (America/St_Thomas)",
  "timeZone": "-4"
}, {
  "id": 134,
  "name": "UTC-4:00: 大西洋标准时间 (America/St_Vincent)",
  "timeZone": "-4"
}, {
  "id": 135,
  "name": "UTC-4:00: 东部夏令时 (America/Thunder_Bay)",
  "timeZone": "-4"
}, {
  "id": 136,
  "name": "UTC-4:00: 东部夏令时 (America/Toronto)",
  "timeZone": "-4"
}, {
  "id": 137,
  "name": "UTC-4:00: 大西洋标准时间 (America/Tortola)",
  "timeZone": "-4"
}, {
  "id": 138,
  "name": "UTC-4:00: 大西洋标准时间 (America/Virgin)",
  "timeZone": "-4"
}, {
  "id": 139,
  "name": "UTC-4:00: 亚马逊时间 (Brazil/West)",
  "timeZone": "-4"
}, {
  "id": 140,
  "name": "UTC-4:00: 智利时间 (Chile/Continental)",
  "timeZone": "-4"
}, {
  "id": 141,
  "name": "UTC-3:00: 巴西利亚时间 (America/Araguaina)",
  "timeZone": "-3"
}, {
  "id": 142,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Buenos_Aires)",
  "timeZone": "-3"
}, {
  "id": 143,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Catamarca)",
  "timeZone": "-3"
}, {
  "id": 144,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/ComodRivadavia)",
  "timeZone": "-3"
}, {
  "id": 145,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Cordoba)",
  "timeZone": "-3"
}, {
  "id": 146,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Jujuy)",
  "timeZone": "-3"
}, {
  "id": 147,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/La_Rioja)",
  "timeZone": "-3"
}, {
  "id": 148,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Mendoza)",
  "timeZone": "-3"
}, {
  "id": 149,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Rio_Gallegos)",
  "timeZone": "-3"
}, {
  "id": 150,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Salta)",
  "timeZone": "-3"
}, {
  "id": 151,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/San_Juan)",
  "timeZone": "-3"
}, {
  "id": 152,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/San_Luis)",
  "timeZone": "-3"
}, {
  "id": 153,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Tucuman)",
  "timeZone": "-3"
}, {
  "id": 154,
  "name": "UTC-3:00: 阿根廷时间 (America/Argentina/Ushuaia)",
  "timeZone": "-3"
}, {
  "id": 155,
  "name": "UTC-3:00: 巴西利亚时间 (America/Bahia)",
  "timeZone": "-3"
}, {
  "id": 156,
  "name": "UTC-3:00: 巴西利亚时间 (America/Belem)",
  "timeZone": "-3"
}, {
  "id": 157,
  "name": "UTC-3:00: 阿根廷时间 (America/Buenos_Aires)",
  "timeZone": "-3"
}, {
  "id": 158,
  "name": "UTC-3:00: 阿根廷时间 (America/Catamarca)",
  "timeZone": "-3"
}, {
  "id": 159,
  "name": "UTC-3:00: 法属圭亚那时间 (America/Cayenne)",
  "timeZone": "-3"
}, {
  "id": 160,
  "name": "UTC-3:00: 阿根廷时间 (America/Cordoba)",
  "timeZone": "-3"
}, {
  "id": 161,
  "name": "UTC-3:00: 巴西利亚时间 (America/Fortaleza)",
  "timeZone": "-3"
}, {
  "id": 162,
  "name": "UTC-3:00: 大西洋夏令时 (America/Glace_Bay)",
  "timeZone": "-3"
}, {
  "id": 163,
  "name": "UTC-3:00: 大西洋夏令时 (America/Goose_Bay)",
  "timeZone": "-3"
}, {
  "id": 164,
  "name": "UTC-3:00: 大西洋夏令时 (America/Halifax)",
  "timeZone": "-3"
}, {
  "id": 165,
  "name": "UTC-3:00: 阿根廷时间 (America/Jujuy)",
  "timeZone": "-3"
}, {
  "id": 166,
  "name": "UTC-3:00: 巴西利亚时间 (America/Maceio)",
  "timeZone": "-3"
}, {
  "id": 167,
  "name": "UTC-3:00: 阿根廷时间 (America/Mendoza)",
  "timeZone": "-3"
}, {
  "id": 168,
  "name": "UTC-3:00: 大西洋夏令时 (America/Moncton)",
  "timeZone": "-3"
}, {
  "id": 169,
  "name": "UTC-3:00: 乌拉圭时间 (America/Montevideo)",
  "timeZone": "-3"
}, {
  "id": 170,
  "name": "UTC-3:00: 苏利南时间 (America/Paramaribo)",
  "timeZone": "-3"
}, {
  "id": 171,
  "name": "UTC-3:00: 巴西利亚时间 (America/Recife)",
  "timeZone": "-3"
}, {
  "id": 172,
  "name": "UTC-3:00: 阿根廷时间 (America/Rosario)",
  "timeZone": "-3"
}, {
  "id": 173,
  "name": "UTC-3:00: 巴西利亚时间 (America/Santarem)",
  "timeZone": "-3"
}, {
  "id": 174,
  "name": "UTC-3:00: 巴西利亚时间 (America/Sao_Paulo)",
  "timeZone": "-3"
}, {
  "id": 175,
  "name": "UTC-3:00: 大西洋夏令时 (America/Thule)",
  "timeZone": "-3"
}, {
  "id": 176,
  "name": "UTC-3:00: 智利时间 (Antarctica/Palmer)",
  "timeZone": "-3"
}, {
  "id": 177,
  "name": "UTC-3:00: 罗瑟拉时间 (Antarctica/Rothera)",
  "timeZone": "-3"
}, {
  "id": 178,
  "name": "UTC-3:00: 大西洋夏令时 (Atlantic/Bermuda)",
  "timeZone": "-3"
}, {
  "id": 179,
  "name": "UTC-3:00: 福克兰群岛时间 (Atlantic/Stanley)",
  "timeZone": "-3"
}, {
  "id": 180,
  "name": "UTC-3:00: 巴西利亚时间 (Brazil/East)",
  "timeZone": "-3"
}, {
  "id": 181,
  "name": "UTC-2:30: 纽芬兰夏令时 (America/St_Johns)",
  "timeZone": "-2"
}, {
  "id": 182,
  "name": "UTC-2:00: 西格林兰岛夏令时 (America/Godthab)",
  "timeZone": "-2"
}, {
  "id": 183,
  "name": "UTC-2:00: 皮埃尔岛及密克隆岛夏令时 (America/Miquelon)",
  "timeZone": "-2"
}, {
  "id": 184,
  "name": "UTC-2:00: 费尔南多德诺罗尼亚时间 (America/Noronha)",
  "timeZone": "-2"
}, {
  "id": 185,
  "name": "UTC-2:00: 南乔治亚标准时间 (Atlantic/South_Georgia)",
  "timeZone": "-2"
}, {
  "id": 186,
  "name": "UTC-2:00: 费尔南多德诺罗尼亚时间 (Brazil/DeNoronha)",
  "timeZone": "-2"
}, {
  "id": 187,
  "name": "UTC-1:00: 佛德角时间 (Atlantic/Cape_Verde)",
  "timeZone": "-1"
}, {
  "id": 188,
  "name": "UTC+0:00: 格林威治时间 (Africa/Abidjan)",
  "timeZone": "+0"
}, {
  "id": 189,
  "name": "UTC+0:00: 加纳时间 (Africa/Accra)",
  "timeZone": "+0"
}, {
  "id": 190,
  "name": "UTC+0:00: 格林威治时间 (Africa/Bamako)",
  "timeZone": "+0"
}, {
  "id": 191,
  "name": "UTC+0:00: 格林威治时间 (Africa/Banjul)",
  "timeZone": "+0"
}, {
  "id": 192,
  "name": "UTC+0:00: 格林威治时间 (Africa/Bissau)",
  "timeZone": "+0"
}, {
  "id": 193,
  "name": "UTC+0:00: 格林威治时间 (Africa/Conakry)",
  "timeZone": "+0"
}, {
  "id": 194,
  "name": "UTC+0:00: 格林威治时间 (Africa/Dakar)",
  "timeZone": "+0"
}, {
  "id": 195,
  "name": "UTC+0:00: 格林威治时间 (Africa/Freetown)",
  "timeZone": "+0"
}, {
  "id": 196,
  "name": "UTC+0:00: 格林威治时间 (Africa/Lome)",
  "timeZone": "+0"
}, {
  "id": 197,
  "name": "UTC+0:00: 格林威治时间 (Africa/Monrovia)",
  "timeZone": "+0"
}, {
  "id": 198,
  "name": "UTC+0:00: 格林威治时间 (Africa/Nouakchott)",
  "timeZone": "+0"
}, {
  "id": 199,
  "name": "UTC+0:00: 格林威治时间 (Africa/Ouagadougou)",
  "timeZone": "+0"
}, {
  "id": 200,
  "name": "UTC+0:00: 格林威治时间 (Africa/Timbuktu)",
  "timeZone": "+0"
}, {
  "id": 201,
  "name": "UTC+0:00: 格林威治时间 (America/Danmarkshavn)",
  "timeZone": "+0"
}, {
  "id": 202,
  "name": "UTC+0:00: 东格林岛夏令时 (America/Scoresbysund)",
  "timeZone": "+0"
}, {
  "id": 203,
  "name": "UTC+0:00: 亚速尔群岛夏令时 (Atlantic/Azores)",
  "timeZone": "+0"
}, {
  "id": 204,
  "name": "UTC+0:00: 格林威治时间 (Atlantic/Reykjavik)",
  "timeZone": "+0"
}, {
  "id": 205,
  "name": "UTC+0:00: 格林威治时间 (Atlantic/St_Helena)",
  "timeZone": "+0"
}, {
  "id": 206,
  "name": "UTC+0:00: 协调世界时间 (UTC)",
  "timeZone": "+0"
}, {
  "id": 207,
  "name": "UTC+1:00: 中欧时间 (Africa/Algiers)",
  "timeZone": "+1"
}, {
  "id": 208,
  "name": "UTC+1:00: 西非时间 (Africa/Bangui)",
  "timeZone": "+1"
}, {
  "id": 209,
  "name": "UTC+1:00: 西非时间 (Africa/Brazzaville)",
  "timeZone": "+1"
}, {
  "id": 210,
  "name": "UTC+1:00: 西欧时间 (Africa/Casablanca)",
  "timeZone": "+1"
}, {
  "id": 211,
  "name": "UTC+1:00: 西非时间 (Africa/Douala)",
  "timeZone": "+1"
}, {
  "id": 212,
  "name": "UTC+1:00: 西欧时间 (Africa/El_Aaiun)",
  "timeZone": "+1"
}, {
  "id": 213,
  "name": "UTC+1:00: 西非时间 (Africa/Kinshasa)",
  "timeZone": "+1"
}, {
  "id": 214,
  "name": "UTC+1:00: 西非时间 (Africa/Lagos)",
  "timeZone": "+1"
}, {
  "id": 215,
  "name": "UTC+1:00: 西非时间 (Africa/Libreville)",
  "timeZone": "+1"
}, {
  "id": 216,
  "name": "UTC+1:00: 西非时间 (Africa/Luanda)",
  "timeZone": "+1"
}, {
  "id": 217,
  "name": "UTC+1:00: 西非时间 (Africa/Malabo)",
  "timeZone": "+1"
}, {
  "id": 218,
  "name": "UTC+1:00: 西非时间 (Africa/Ndjamena)",
  "timeZone": "+1"
}, {
  "id": 219,
  "name": "UTC+1:00: 西非时间 (Africa/Niamey)",
  "timeZone": "+1"
}, {
  "id": 220,
  "name": "UTC+1:00: 西非时间 (Africa/Porto-Novo)",
  "timeZone": "+1"
}, {
  "id": 221,
  "name": "UTC+1:00: 西非时间 (Africa/Sao_Tome)",
  "timeZone": "+1"
}, {
  "id": 222,
  "name": "UTC+1:00: 中欧时间 (Africa/Tunis)",
  "timeZone": "+1"
}, {
  "id": 223,
  "name": "UTC+1:00: 西欧夏令时 (Atlantic/Canary)",
  "timeZone": "+1"
}, {
  "id": 224,
  "name": "UTC+1:00: 西欧夏令时 (Atlantic/Faeroe)",
  "timeZone": "+1"
}, {
  "id": 225,
  "name": "UTC+1:00: 西欧夏令时 (Atlantic/Faroe)",
  "timeZone": "+1"
}, {
  "id": 226,
  "name": "UTC+1:00: 西欧夏令时 (Atlantic/Madeira)",
  "timeZone": "+1"
}, {
  "id": 227,
  "name": "UTC+1:00: 英国夏令时 (Europe/Belfast)",
  "timeZone": "+1"
}, {
  "id": 228,
  "name": "UTC+1:00: 爱尔兰夏令时 (Europe/Dublin)",
  "timeZone": "+1"
}, {
  "id": 229,
  "name": "UTC+1:00: 英国夏令时 (Europe/Guernsey)",
  "timeZone": "+1"
}, {
  "id": 230,
  "name": "UTC+1:00: 英国夏令时 (Europe/Isle_of_Man)",
  "timeZone": "+1"
}, {
  "id": 231,
  "name": "UTC+1:00: 英国夏令时 (Europe/Jersey)",
  "timeZone": "+1"
}, {
  "id": 232,
  "name": "UTC+1:00: 西欧夏令时 (Europe/Lisbon)",
  "timeZone": "+1"
}, {
  "id": 233,
  "name": "UTC+1:00: 英国夏令时 (Europe/London)",
  "timeZone": "+1"
}, {
  "id": 234,
  "name": "UTC+2:00: 中非时间 (Africa/Blantyre)",
  "timeZone": "+2"
}, {
  "id": 235,
  "name": "UTC+2:00: 中非时间 (Africa/Bujumbura)",
  "timeZone": "+2"
}, {
  "id": 236,
  "name": "UTC+2:00: 东欧时间 (Africa/Cairo)",
  "timeZone": "+2"
}, {
  "id": 237,
  "name": "UTC+2:00: 中欧夏令时 (Africa/Ceuta)",
  "timeZone": "+2"
}, {
  "id": 238,
  "name": "UTC+2:00: 中非时间 (Africa/Gaborone)",
  "timeZone": "+2"
}, {
  "id": 239,
  "name": "UTC+2:00: 中非时间 (Africa/Harare)",
  "timeZone": "+2"
}, {
  "id": 240,
  "name": "UTC+2:00: 南非标准时间 (Africa/Johannesburg)",
  "timeZone": "+2"
}, {
  "id": 241,
  "name": "UTC+2:00: 中非时间 (Africa/Khartoum)",
  "timeZone": "+2"
}, {
  "id": 242,
  "name": "UTC+2:00: 中非时间 (Africa/Kigali)",
  "timeZone": "+2"
}, {
  "id": 243,
  "name": "UTC+2:00: 中非时间 (Africa/Lubumbashi)",
  "timeZone": "+2"
}, {
  "id": 244,
  "name": "UTC+2:00: 中非时间 (Africa/Lusaka)",
  "timeZone": "+2"
}, {
  "id": 245,
  "name": "UTC+2:00: 中非时间 (Africa/Maputo)",
  "timeZone": "+2"
}, {
  "id": 246,
  "name": "UTC+2:00: 南非标准时间 (Africa/Maseru)",
  "timeZone": "+2"
}, {
  "id": 247,
  "name": "UTC+2:00: 南非标准时间 (Africa/Mbabane)",
  "timeZone": "+2"
}, {
  "id": 248,
  "name": "UTC+2:00: 东欧时间 (Africa/Tripoli)",
  "timeZone": "+2"
}, {
  "id": 249,
  "name": "UTC+2:00: 中非时间 (Africa/Windhoek)",
  "timeZone": "+2"
}, {
  "id": 250,
  "name": "UTC+2:00: 中欧夏令时 (Antarctica/Troll)",
  "timeZone": "+2"
}, {
  "id": 251,
  "name": "UTC+2:00: 中欧夏令时 (Arctic/Longyearbyen)",
  "timeZone": "+2"
}, {
  "id": 252,
  "name": "UTC+2:00: 中欧夏令时 (Atlantic/Jan_Mayen)",
  "timeZone": "+2"
}, {
  "id": 253,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Amsterdam)",
  "timeZone": "+2"
}, {
  "id": 254,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Andorra)",
  "timeZone": "+2"
}, {
  "id": 255,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Belgrade)",
  "timeZone": "+2"
}, {
  "id": 256,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Berlin)",
  "timeZone": "+2"
}, {
  "id": 257,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Bratislava)",
  "timeZone": "+2"
}, {
  "id": 258,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Brussels)",
  "timeZone": "+2"
}, {
  "id": 259,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Budapest)",
  "timeZone": "+2"
}, {
  "id": 260,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Busingen)",
  "timeZone": "+2"
}, {
  "id": 261,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Copenhagen)",
  "timeZone": "+2"
}, {
  "id": 262,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Gibraltar)",
  "timeZone": "+2"
}, {
  "id": 263,
  "name": "UTC+2:00: 东欧时间 (Europe/Kaliningrad)",
  "timeZone": "+2"
}, {
  "id": 264,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Ljubljana)",
  "timeZone": "+2"
}, {
  "id": 265,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Luxembourg)",
  "timeZone": "+2"
}, {
  "id": 266,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Madrid)",
  "timeZone": "+2"
}, {
  "id": 267,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Malta)",
  "timeZone": "+2"
}, {
  "id": 268,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Monaco)",
  "timeZone": "+2"
}, {
  "id": 269,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Oslo)",
  "timeZone": "+2"
}, {
  "id": 270,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Paris)",
  "timeZone": "+2"
}, {
  "id": 271,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Podgorica)",
  "timeZone": "+2"
}, {
  "id": 272,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Prague)",
  "timeZone": "+2"
}, {
  "id": 273,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Rome)",
  "timeZone": "+2"
}, {
  "id": 274,
  "name": "UTC+2:00: 中欧夏令时 (Europe/San_Marino)",
  "timeZone": "+2"
}, {
  "id": 275,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Sarajevo)",
  "timeZone": "+2"
}, {
  "id": 276,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Skopje)",
  "timeZone": "+2"
}, {
  "id": 277,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Stockholm)",
  "timeZone": "+2"
}, {
  "id": 278,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Tirane)",
  "timeZone": "+2"
}, {
  "id": 279,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Vaduz)",
  "timeZone": "+2"
}, {
  "id": 280,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Vatican)",
  "timeZone": "+2"
}, {
  "id": 281,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Vienna)",
  "timeZone": "+2"
}, {
  "id": 282,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Warsaw)",
  "timeZone": "+2"
}, {
  "id": 283,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Zagreb)",
  "timeZone": "+2"
}, {
  "id": 284,
  "name": "UTC+2:00: 中欧夏令时 (Europe/Zurich)",
  "timeZone": "+2"
}, {
  "id": 285,
  "name": "UTC+3:00: 东非时间 (Africa/Addis_Ababa)",
  "timeZone": "+3"
}, {
  "id": 286,
  "name": "UTC+3:00: 东非时间 (Africa/Asmara)",
  "timeZone": "+3"
}, {
  "id": 287,
  "name": "UTC+3:00: 东非时间 (Africa/Asmera)",
  "timeZone": "+3"
}, {
  "id": 288,
  "name": "UTC+3:00: 东非时间 (Africa/Dar_es_Salaam)",
  "timeZone": "+3"
}, {
  "id": 289,
  "name": "UTC+3:00: 东非时间 (Africa/Djibouti)",
  "timeZone": "+3"
}, {
  "id": 290,
  "name": "UTC+3:00: 东非时间 (Africa/Juba)",
  "timeZone": "+3"
}, {
  "id": 291,
  "name": "UTC+3:00: 东非时间 (Africa/Kampala)",
  "timeZone": "+3"
}, {
  "id": 292,
  "name": "UTC+3:00: 东非时间 (Africa/Mogadishu)",
  "timeZone": "+3"
}, {
  "id": 293,
  "name": "UTC+3:00: 东非时间 (Africa/Nairobi)",
  "timeZone": "+3"
}, {
  "id": 294,
  "name": "UTC+3:00: Syowa 时间 (Antarctica/Syowa)",
  "timeZone": "+3"
}, {
  "id": 295,
  "name": "UTC+3:00: 阿拉伯标准时间 (Asia/Aden)",
  "timeZone": "+3"
}, {
  "id": 296,
  "name": "UTC+3:00: 东欧夏令时 (Asia/Amman)",
  "timeZone": "+3"
}, {
  "id": 297,
  "name": "UTC+3:00: 阿拉伯标准时间 (Asia/Baghdad)",
  "timeZone": "+3"
}, {
  "id": 298,
  "name": "UTC+3:00: 阿拉伯标准时间 (Asia/Bahrain)",
  "timeZone": "+3"
}, {
  "id": 299,
  "name": "UTC+3:00: 东欧夏令时 (Asia/Beirut)",
  "timeZone": "+3"
}, {
  "id": 300,
  "name": "UTC+3:00: 东欧夏令时 (Asia/Damascus)",
  "timeZone": "+3"
}, {
  "id": 301,
  "name": "UTC+3:00: 东欧夏令时 (Asia/Gaza)",
  "timeZone": "+3"
}, {
  "id": 302,
  "name": "UTC+3:00: 东欧夏令时 (Asia/Hebron)",
  "timeZone": "+3"
}, {
  "id": 303,
  "name": "UTC+3:00: 东欧时间 (Asia/Istanbul)",
  "timeZone": "+3"
}, {
  "id": 304,
  "name": "UTC+3:00: 以色列夏令时 (Asia/Jerusalem)",
  "timeZone": "+3"
}, {
  "id": 305,
  "name": "UTC+3:00: 阿拉伯标准时间 (Asia/Kuwait)",
  "timeZone": "+3"
}, {
  "id": 306,
  "name": "UTC+3:00: 东欧夏令时 (Asia/Nicosia)",
  "timeZone": "+3"
}, {
  "id": 307,
  "name": "UTC+3:00: 阿拉伯标准时间 (Asia/Qatar)",
  "timeZone": "+3"
}, {
  "id": 308,
  "name": "UTC+3:00: 阿拉伯标准时间 (Asia/Riyadh)",
  "timeZone": "+3"
}, {
  "id": 309,
  "name": "UTC+3:00: 以色列夏令时 (Asia/Tel_Aviv)",
  "timeZone": "+3"
}, {
  "id": 310,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Athens)",
  "timeZone": "+3"
}, {
  "id": 311,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Bucharest)",
  "timeZone": "+3"
}, {
  "id": 312,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Chisinau)",
  "timeZone": "+3"
}, {
  "id": 313,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Helsinki)",
  "timeZone": "+3"
}, {
  "id": 314,
  "name": "UTC+3:00: 东欧时间 (Europe/Istanbul)",
  "timeZone": "+3"
}, {
  "id": 315,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Kiev)",
  "timeZone": "+3"
}, {
  "id": 316,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Mariehamn)",
  "timeZone": "+3"
}, {
  "id": 317,
  "name": "UTC+3:00: 莫斯科标准时间 (Europe/Minsk)",
  "timeZone": "+3"
}, {
  "id": 318,
  "name": "UTC+3:00: 莫斯科标准时间 (Europe/Moscow)",
  "timeZone": "+3"
}, {
  "id": 319,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Nicosia)",
  "timeZone": "+3"
}, {
  "id": 320,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Riga)",
  "timeZone": "+3"
}, {
  "id": 321,
  "name": "UTC+3:00: 莫斯科标准时间 (Europe/Simferopol)",
  "timeZone": "+3"
}, {
  "id": 322,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Sofia)",
  "timeZone": "+3"
}, {
  "id": 323,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Tallinn)",
  "timeZone": "+3"
}, {
  "id": 324,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Tiraspol)",
  "timeZone": "+3"
}, {
  "id": 325,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Uzhgorod)",
  "timeZone": "+3"
}, {
  "id": 326,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Vilnius)",
  "timeZone": "+3"
}, {
  "id": 327,
  "name": "UTC+3:00: 东欧夏令时 (Europe/Zaporozhye)",
  "timeZone": "+3"
}, {
  "id": 328,
  "name": "UTC+3:00: 东非时间 (Indian/Antananarivo)",
  "timeZone": "+3"
}, {
  "id": 329,
  "name": "UTC+3:00: 东非时间 (Indian/Comoro)",
  "timeZone": "+3"
}, {
  "id": 330,
  "name": "UTC+3:00: 东非时间 (Indian/Mayotte)",
  "timeZone": "+3"
}, {
  "id": 331,
  "name": "UTC+4:00: 亚塞拜然时间 (Asia/Baku)",
  "timeZone": "+4"
}, {
  "id": 332,
  "name": "UTC+4:00: 波斯湾标准时间 (Asia/Dubai)",
  "timeZone": "+4"
}, {
  "id": 333,
  "name": "UTC+4:00: 波斯湾标准时间 (Asia/Muscat)",
  "timeZone": "+4"
}, {
  "id": 334,
  "name": "UTC+4:00: 乔治亚时间 (Asia/Tbilisi)",
  "timeZone": "+4"
}, {
  "id": 335,
  "name": "UTC+4:00: 亚美尼亚时间 (Asia/Yerevan)",
  "timeZone": "+4"
}, {
  "id": 336,
  "name": "UTC+4:00: 沙马拉时间 (Europe/Samara)",
  "timeZone": "+4"
}, {
  "id": 337,
  "name": "UTC+4:00: 莫斯科标准时间 (Europe/Volgograd)",
  "timeZone": "+4"
}, {
  "id": 338,
  "name": "UTC+4:00: 塞席尔群岛时间 (Indian/Mahe)",
  "timeZone": "+4"
}, {
  "id": 339,
  "name": "UTC+4:00: 摩里西斯时间 (Indian/Mauritius)",
  "timeZone": "+4"
}, {
  "id": 340,
  "name": "UTC+4:00: 留尼旺岛时间 (Indian/Reunion)",
  "timeZone": "+4"
}, {
  "id": 341,
  "name": "UTC+4:30: 阿富汗时间 (Asia/Kabul)",
  "timeZone": "+4"
}, {
  "id": 342,
  "name": "UTC+4:30: 伊朗夏令时 (Asia/Tehran)",
  "timeZone": "+4"
}, {
  "id": 343,
  "name": "UTC+5:00: 莫森时间 (Antarctica/Mawson)",
  "timeZone": "+5"
}, {
  "id": 344,
  "name": "UTC+5:00: Aqtau 时间 (Asia/Aqtau)",
  "timeZone": "+5"
}, {
  "id": 345,
  "name": "UTC+5:00: Aqtobe 时间 (Asia/Aqtobe)",
  "timeZone": "+5"
}, {
  "id": 346,
  "name": "UTC+5:00: 土库曼时间 (Asia/Ashgabat)",
  "timeZone": "+5"
}, {
  "id": 347,
  "name": "UTC+5:00: 土库曼时间 (Asia/Ashkhabad)",
  "timeZone": "+5"
}, {
  "id": 348,
  "name": "UTC+5:00: 塔吉克斯坦时间 (Asia/Dushanbe)",
  "timeZone": "+5"
}, {
  "id": 349,
  "name": "UTC+5:00: 巴基斯坦时间 (Asia/Karachi)",
  "timeZone": "+5"
}, {
  "id": 350,
  "name": "UTC+5:00: Oral 时间 (Asia/Oral)",
  "timeZone": "+5"
}, {
  "id": 351,
  "name": "UTC+5:00: 乌兹别克斯坦时间 (Asia/Samarkand)",
  "timeZone": "+5"
}, {
  "id": 352,
  "name": "UTC+5:00: 乌兹别克斯坦时间 (Asia/Tashkent)",
  "timeZone": "+5"
}, {
  "id": 353,
  "name": "UTC+5:00: Yekaterinburg 时间 (Asia/Yekaterinburg)",
  "timeZone": "+5"
}, {
  "id": 354,
  "name": "UTC+5:00: 法属南极时间 (Indian/Kerguelen)",
  "timeZone": "+5"
}, {
  "id": 355,
  "name": "UTC+5:00: 马尔代夫时间 (Indian/Maldives)",
  "timeZone": "+5"
}, {
  "id": 356,
  "name": "UTC+5:30: 印度标准时间 (Asia/Calcutta)",
  "timeZone": "+5"
}, {
  "id": 357,
  "name": "UTC+5:30: 印度标准时间 (Asia/Colombo)",
  "timeZone": "+5"
}, {
  "id": 358,
  "name": "UTC+5:30: 印度标准时间 (Asia/Kolkata)",
  "timeZone": "+5"
}, {
  "id": 359,
  "name": "UTC+5:45: 尼泊尔时间 (Asia/Kathmandu)",
  "timeZone": "+5"
}, {
  "id": 360,
  "name": "UTC+5:45: 尼泊尔时间 (Asia/Katmandu)",
  "timeZone": "+5"
}, {
  "id": 361,
  "name": "UTC+6:00: 莫斯托克时间 (Antarctica/Vostok)",
  "timeZone": "+6"
}, {
  "id": 362,
  "name": "UTC+6:00: Alma-Ata 时间 (Asia/Almaty)",
  "timeZone": "+6"
}, {
  "id": 363,
  "name": "UTC+6:00: 吉尔吉斯斯坦时间 (Asia/Bishkek)",
  "timeZone": "+6"
}, {
  "id": 364,
  "name": "UTC+6:00: 孟加拉时间 (Asia/Dacca)",
  "timeZone": "+6"
}, {
  "id": 365,
  "name": "UTC+6:00: 孟加拉时间 (Asia/Dhaka)",
  "timeZone": "+6"
}, {
  "id": 366,
  "name": "UTC+6:00: 中国标准时间 (Asia/Kashgar)",
  "timeZone": "+6"
}, {
  "id": 367,
  "name": "UTC+6:00: 鄂木斯克时间 (Asia/Omsk)",
  "timeZone": "+6"
}, {
  "id": 368,
  "name": "UTC+6:00: Qyzylorda 时间 (Asia/Qyzylorda)",
  "timeZone": "+6"
}, {
  "id": 369,
  "name": "UTC+6:00: 不丹时间 (Asia/Thimbu)",
  "timeZone": "+6"
}, {
  "id": 370,
  "name": "UTC+6:00: 不丹时间 (Asia/Thimphu)",
  "timeZone": "+6"
}, {
  "id": 371,
  "name": "UTC+6:00: 中国标准时间 (Asia/Urumqi)",
  "timeZone": "+6"
}, {
  "id": 372,
  "name": "UTC+6:00: 印度洋地带时间 (Indian/Chagos)",
  "timeZone": "+6"
}, {
  "id": 373,
  "name": "UTC+6:30: 缅甸时间 (Asia/Rangoon)",
  "timeZone": "+6"
}, {
  "id": 374,
  "name": "UTC+6:30: 可可斯群岛时间 (Indian/Cocos)",
  "timeZone": "+6"
}, {
  "id": 375,
  "name": "UTC+7:00: 戴维斯时间 (Antarctica/Davis)",
  "timeZone": "+7"
}, {
  "id": 376,
  "name": "UTC+7:00: 印度支那时间 (Asia/Bangkok)",
  "timeZone": "+7"
}, {
  "id": 377,
  "name": "UTC+7:00: 印度支那时间 (Asia/Ho_Chi_Minh)",
  "timeZone": "+7"
}, {
  "id": 378,
  "name": "UTC+7:00: 科布多时间 (Asia/Hovd)",
  "timeZone": "+7"
}, {
  "id": 379,
  "name": "UTC+7:00: 西印度尼西亚时间 (Asia/Jakarta)",
  "timeZone": "+7"
}, {
  "id": 380,
  "name": "UTC+7:00: 克拉斯诺亚尔斯克时间 (Asia/Krasnoyarsk)",
  "timeZone": "+7"
}, {
  "id": 381,
  "name": "UTC+7:00: 克拉斯诺亚尔斯克时间 (Asia/Novokuznetsk)",
  "timeZone": "+7"
}, {
  "id": 382,
  "name": "UTC+7:00: Novosibirsk 时间 (Asia/Novosibirsk)",
  "timeZone": "+7"
}, {
  "id": 383,
  "name": "UTC+7:00: 印度支那时间 (Asia/Phnom_Penh)",
  "timeZone": "+7"
}, {
  "id": 384,
  "name": "UTC+7:00: 西印度尼西亚时间 (Asia/Pontianak)",
  "timeZone": "+7"
}, {
  "id": 385,
  "name": "UTC+7:00: 印度支那时间 (Asia/Saigon)",
  "timeZone": "+7"
}, {
  "id": 386,
  "name": "UTC+7:00: 印度支那时间 (Asia/Vientiane)",
  "timeZone": "+7"
}, {
  "id": 387,
  "name": "UTC+7:00: 圣诞岛时间 (Indian/Christmas)",
  "timeZone": "+7"
}, {
  "id": 388,
  "name": "UTC+8:00: 西部标准时间 (澳大利亚) (Antarctica/Casey)",
  "timeZone": "+8"
}, {
  "id": 389,
  "name": "UTC+8:00: 文莱时间 (Asia/Brunei)",
  "timeZone": "+8"
}, {
  "id": 390,
  "name": "UTC+8:00: Choibalsan 时间 (Asia/Choibalsan)",
  "timeZone": "+8"
}, {
  "id": 391,
  "name": "UTC+8:00: 中国标准时间 (Asia/Chongqing)",
  "timeZone": "+8"
}, {
  "id": 392,
  "name": "UTC+8:00: 中国标准时间 (Asia/Chungking)",
  "timeZone": "+8"
}, {
  "id": 393,
  "name": "UTC+8:00: 中国标准时间 (Asia/Harbin)",
  "timeZone": "+8"
}, {
  "id": 394,
  "name": "UTC+8:00: 香港时间 (Asia/Hong_Kong)",
  "timeZone": "+8"
}, {
  "id": 395,
  "name": "UTC+8:00: 伊尔库次克时间 (Asia/Irkutsk)",
  "timeZone": "+8"
}, {
  "id": 396,
  "name": "UTC+8:00: 马来西亚时间 (Asia/Kuala_Lumpur)",
  "timeZone": "+8"
}, {
  "id": 397,
  "name": "UTC+8:00: 马来西亚时间 (Asia/Kuching)",
  "timeZone": "+8"
}, {
  "id": 398,
  "name": "UTC+8:00: 中国标准时间 (Asia/Macao)",
  "timeZone": "+8"
}, {
  "id": 399,
  "name": "UTC+8:00: 中国标准时间 (Asia/Macau)",
  "timeZone": "+8"
}, {
  "id": 400,
  "name": "UTC+8:00: 中部印度尼西亚时间 (Asia/Makassar)",
  "timeZone": "+8"
}, {
  "id": 401,
  "name": "UTC+8:00: Philippines Standard Time (Asia/Manila)",
  "timeZone": "+8"
}, {
  "id": 402,
  "name": "UTC+8:00: 中国标准时间 (Asia/Shanghai)",
  "timeZone": "+8"
}, {
  "id": 403,
  "name": "UTC+8:00: 新加坡时间 (Asia/Singapore)",
  "timeZone": "+8"
}, {
  "id": 404,
  "name": "UTC+8:00: 中国标准时间 (Asia/Taipei)",
  "timeZone": "+8"
}, {
  "id": 405,
  "name": "UTC+8:00: 中部印度尼西亚时间 (Asia/Ujung_Pandang)",
  "timeZone": "+8"
}, {
  "id": 406,
  "name": "UTC+8:00: 库伦时间 (Asia/Ulaanbaatar)",
  "timeZone": "+8"
}, {
  "id": 407,
  "name": "UTC+8:00: 库伦时间 (Asia/Ulan_Bator)",
  "timeZone": "+8"
}, {
  "id": 408,
  "name": "UTC+8:00: 西部标准时间 (澳大利亚) (Australia/Perth)",
  "timeZone": "+8"
}, {
  "id": 409,
  "name": "UTC+8:00: 西部标准时间 (澳大利亚) (Australia/West)",
  "timeZone": "+8"
}, {
  "id": 410,
  "name": "UTC+8:45: 中西部标准时间 (澳大利亚) (Australia/Eucla)",
  "timeZone": "+8"
}, {
  "id": 411,
  "name": "UTC+9:00: 亚库次克时间 (Asia/Chita)",
  "timeZone": "+9"
}, {
  "id": 412,
  "name": "UTC+9:00: 东帝汶时间 (Asia/Dili)",
  "timeZone": "+9"
}, {
  "id": 413,
  "name": "UTC+9:00: 东印度尼西亚时间 (Asia/Jayapura)",
  "timeZone": "+9"
}, {
  "id": 414,
  "name": "UTC+9:00: 亚库次克时间 (Asia/Khandyga)",
  "timeZone": "+9"
}, {
  "id": 415,
  "name": "UTC+9:00: 韩国标准时间 (Asia/Pyongyang)",
  "timeZone": "+9"
}, {
  "id": 416,
  "name": "UTC+9:00: 韩国标准时间 (Asia/Seoul)",
  "timeZone": "+9"
}, {
  "id": 417,
  "name": "UTC+9:00: 日本标准时间 (Asia/Tokyo)",
  "timeZone": "+9"
}, {
  "id": 418,
  "name": "UTC+9:00: 亚库次克时间 (Asia/Yakutsk)",
  "timeZone": "+9"
}, {
  "id": 419,
  "name": "UTC+9:00: 帛琉时间 (Pacific/Palau)",
  "timeZone": "+9"
}, {
  "id": 420,
  "name": "UTC+9:30: 中央标准时间 (南澳大利亚) (Australia/Adelaide)",
  "timeZone": "+9"
}, {
  "id": 421,
  "name": "UTC+9:30: 中央标准时间 (南澳大利亚/新南威尔斯) (Australia/Broken_Hill)",
  "timeZone": "+9"
}, {
  "id": 422,
  "name": "UTC+9:30: 中央标准时间 (北领地) (Australia/Darwin)",
  "timeZone": "+9"
}, {
  "id": 423,
  "name": "UTC+9:30: 中央标准时间 (北领地) (Australia/North)",
  "timeZone": "+9"
}, {
  "id": 424,
  "name": "UTC+9:30: 中央标准时间 (南澳大利亚) (Australia/South)",
  "timeZone": "+9"
}, {
  "id": 425,
  "name": "UTC+9:30: 中央标准时间 (南澳大利亚/新南威尔斯) (Australia/Yancowinna)",
  "timeZone": "+9"
}, {
  "id": 426,
  "name": "UTC+10:00: Dumont-d'Urville 时间(Antarctica / DumontDUrville)",
  "timeZone": "+10"
}, {
  "id": 427,
  "name": "UTC+10:00: 乌斯季涅拉时间 (Asia/Ust-Nera)",
  "timeZone": "+10"
}, {
  "id": 428,
  "name": "UTC+10:00: 海参崴时间 (Asia/Vladivostok)",
  "timeZone": "+10"
}, {
  "id": 429,
  "name": "UTC+10:00: 东部标准时间 (新南威尔斯) (Australia/ACT)",
  "timeZone": "+10"
}, {
  "id": 430,
  "name": "UTC+10:00: 东部标准时间 (昆士兰) (Australia/Brisbane)",
  "timeZone": "+10"
}, {
  "id": 431,
  "name": "UTC+10:00: 东部标准时间 (新南威尔斯) (Australia/Canberra)",
  "timeZone": "+10"
}, {
  "id": 432,
  "name": "UTC+10:00: 东部标准时间 (新南威尔斯) (Australia/Currie)",
  "timeZone": "+10"
}, {
  "id": 433,
  "name": "UTC+10:00: 东部标准时间 (塔斯马尼亚) (Australia/Hobart)",
  "timeZone": "+10"
}, {
  "id": 434,
  "name": "UTC+10:00: 东部标准时间 (昆士兰) (Australia/Lindeman)",
  "timeZone": "+10"
}, {
  "id": 435,
  "name": "UTC+10:00: 东部标准时间 (维多利亚) (Australia/Melbourne)",
  "timeZone": "+10"
}, {
  "id": 436,
  "name": "UTC+10:00: 东部标准时间 (新南威尔斯) (Australia/NSW)",
  "timeZone": "+10"
}, {
  "id": 437,
  "name": "UTC+10:00: 东部标准时间 (昆士兰) (Australia/Queensland)",
  "timeZone": "+10"
}, {
  "id": 438,
  "name": "UTC+10:00: 东部标准时间 (新南威尔斯) (Australia/Sydney)",
  "timeZone": "+10"
}, {
  "id": 439,
  "name": "UTC+10:00: 东部标准时间 (塔斯马尼亚) (Australia/Tasmania)",
  "timeZone": "+10"
}, {
  "id": 440,
  "name": "UTC+10:00: 东部标准时间 (维多利亚) (Australia/Victoria)",
  "timeZone": "+10"
}, {
  "id": 441,
  "name": "UTC+10:00: 丘克时间 (Pacific/Chuuk)",
  "timeZone": "+10"
}, {
  "id": 442,
  "name": "UTC+10:00: Chamorro 标准时间 (Pacific/Guam)",
  "timeZone": "+10"
}, {
  "id": 443,
  "name": "UTC+10:00: 巴布亚新几内亚时间 (Pacific/Port_Moresby)",
  "timeZone": "+10"
}, {
  "id": 444,
  "name": "UTC+10:00: Chamorro 标准时间 (Pacific/Saipan)",
  "timeZone": "+10"
}, {
  "id": 445,
  "name": "UTC+10:00: 丘克时间 (Pacific/Truk)",
  "timeZone": "+10"
}, {
  "id": 446,
  "name": "UTC+10:00: 丘克时间 (Pacific/Yap)",
  "timeZone": "+10"
}, {
  "id": 447,
  "name": "UTC+10:30: 豪公标准时间 (Australia/LHI)",
  "timeZone": "+10"
}, {
  "id": 448,
  "name": "UTC+10:30: 豪公标准时间 (Australia/Lord_Howe)",
  "timeZone": "+10"
}, {
  "id": 449,
  "name": "UTC+11:00: 麦夸里岛时间 (Antarctica/Macquarie)",
  "timeZone": "+11"
}, {
  "id": 450,
  "name": "UTC+11:00: Magadan 时间 (Asia/Magadan)",
  "timeZone": "+11"
}, {
  "id": 451,
  "name": "UTC+11:00: 库页岛时间 (Asia/Sakhalin)",
  "timeZone": "+11"
}, {
  "id": 452,
  "name": "UTC+11:00: Srednekolymsk Time (Asia/Srednekolymsk)",
  "timeZone": "+11"
}, {
  "id": 453,
  "name": "UTC+11:00: Bougainville Standard Time (Pacific/Bougainville)",
  "timeZone": "+11"
}, {
  "id": 454,
  "name": "UTC+11:00: 瓦奴阿图时间 (Pacific/Efate)",
  "timeZone": "+11"
}, {
  "id": 455,
  "name": "UTC+11:00: 所罗门群岛时间 (Pacific/Guadalcanal)",
  "timeZone": "+11"
}, {
  "id": 456,
  "name": "UTC+11:00: Kosrae 时间 (Pacific/Kosrae)",
  "timeZone": "+11"
}, {
  "id": 457,
  "name": "UTC+11:00: 诺福克时间 (Pacific/Norfolk)",
  "timeZone": "+11"
}, {
  "id": 458,
  "name": "UTC+11:00: 新加勒多尼亚时间 (Pacific/Noumea)",
  "timeZone": "+11"
}, {
  "id": 459,
  "name": "UTC+11:00: 波纳佩时间 (Pacific/Pohnpei)",
  "timeZone": "+11"
}, {
  "id": 460,
  "name": "UTC+11:00: 波纳佩时间 (Pacific/Ponape)",
  "timeZone": "+11"
}, {
  "id": 461,
  "name": "UTC+12:00: 新西兰标准时间 (Antarctica/McMurdo)",
  "timeZone": "+12"
}, {
  "id": 462,
  "name": "UTC+12:00: 新西兰标准时间 (Antarctica/South_Pole)",
  "timeZone": "+12"
}, {
  "id": 463,
  "name": "UTC+12:00: 阿那底河时间 (Asia/Anadyr)",
  "timeZone": "+12"
}, {
  "id": 464,
  "name": "UTC+12:00: 彼得罗巴甫洛夫斯克时间 (Asia/Kamchatka)",
  "timeZone": "+12"
}, {
  "id": 465,
  "name": "UTC+12:00: 新西兰标准时间 (Pacific/Auckland)",
  "timeZone": "+12"
}, {
  "id": 466,
  "name": "UTC+12:00: 斐济时间 (Pacific/Fiji)",
  "timeZone": "+12"
}, {
  "id": 467,
  "name": "UTC+12:00: 吐鲁瓦时间 (Pacific/Funafuti)",
  "timeZone": "+12"
}, {
  "id": 468,
  "name": "UTC+12:00: 马绍尔群岛时间 (Pacific/Kwajalein)",
  "timeZone": "+12"
}, {
  "id": 469,
  "name": "UTC+12:00: 马绍尔群岛时间 (Pacific/Majuro)",
  "timeZone": "+12"
}, {
  "id": 470,
  "name": "UTC+12:00: 诺鲁时间 (Pacific/Nauru)",
  "timeZone": "+12"
}, {
  "id": 471,
  "name": "UTC+12:00: 吉伯特群岛时间 (Pacific/Tarawa)",
  "timeZone": "+12"
}, {
  "id": 472,
  "name": "UTC+12:00: 威克时间 (Pacific/Wake)",
  "timeZone": "+12"
}, {
  "id": 473,
  "name": "UTC+12:00: 瓦利斯及福杜纳群岛时间 (Pacific/Wallis)",
  "timeZone": "+12"
}, {
  "id": 474,
  "name": "UTC+12:45: 查萨姆标准时间 (Pacific/Chatham)",
  "timeZone": "+12"
}, {
  "id": 475,
  "name": "UTC+13:00: 西萨摩亚时间 (Pacific/Apia)",
  "timeZone": "+13"
}, {
  "id": 476,
  "name": "UTC+13:00: 菲尼克斯群岛时间 (Pacific/Enderbury)",
  "timeZone": "+13"
}, {
  "id": 477,
  "name": "UTC+13:00: 托克劳群岛时间 (Pacific/Fakaofo)",
  "timeZone": "+13"
}, {
  "id": 478,
  "name": "UTC+13:00: 东加时间 (Pacific/Tongatapu)",
  "timeZone": "+13"
}, {
  "id": 479,
  "name": "UTC+14:00: Line 岛时间 (Pacific/Kiritimati)",
  "timeZone": "+14"
}];

export default timeZones
