<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="签名"
      :visible.sync="dialogVisible"
      center
      width="700px"
    >
      <canvas
        id="canvas"
        class="canvas"
        width="650"
        height="300"
        @mousedown="draw"
      ></canvas>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          @click="reset"
        >{{$t('operation.reset')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { UploadImage } from '@/api'

export default {
  props: ['visible', 'photo'],
  data () {
    return {
      dialogVisible: false,
      canvas: null,
      ctx: null,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        if (this.canvas === null) {
          this.$nextTick(() => {
            this.canvas = document.querySelector("#canvas");
            this.ctx = this.canvas.getContext("2d");
            this.reset();
          })
        } else {
          this.reset();
        }
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  mounted () {
  },
  methods: {
    draw (ev) {
      ev = ev || window.event;
      if (this.canvas.setCapture) {
        this.canvas.setCapture();
      }
      this.ctx.beginPath();
      //点出第一个点
      this.ctx.moveTo(ev.offsetX, ev.offsetY);
      //鼠标按下的同时设置鼠标移动move事件
      document.onmousemove = (ev) => {
        ev = ev || window.event;
        //绘制路径
        this.ctx.lineTo(ev.offsetX, ev.offsetY);
        this.ctx.stroke();
      }
      //最后在鼠标抬起事件中将画笔抬起
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        if (document.releaseCapture) {
          document.releaseCapture();
        }
      }
      return false;
    },
    //重置 
    reset () {
      this.ctx.fillStyle = "#FFFFFF";
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },

    // 提交
    submit () {
      let formData = new FormData();
      let file = this.dataURLtoFile(this.canvas.toDataURL(), 'signature.png');

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.$emit('submit', res.message)
          this.dialogVisible = false;
        }
      })
    },
    // 图片转文件
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

  }
}
</script>

<style lang="scss" scoped>
.canvas {
  // background-color: #F2F2F2;
  border: 2px solid #f2f2f2;
}
</style>