<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            label="Status"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.name"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            label="Button"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.button"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            label="Key"
          >
            <template slot-scope="scope">
              <el-select
                size="mini"
                v-model="scope.row.status"
              >
                <el-option
                  :value="0"
                  label="Normal"
                ></el-option>
                <el-option
                  :value="1"
                  label="Abnormal"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column min-width="120">
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="delItem(scope.row,scope.$index)"
                :disabled="tableData.length === 1"
              ></el-button>
              <el-button
                type="success"
                size="mini"
                icon="el-icon-plus"
                @click="addItem(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetApprovalOptionList,
  DelApprovalOption,
  AddApprovalOption,
} from '@/api/warehouse/approvalWorkflow'
export default {
  props: ['visible', 'ids'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Edit Approval Option',
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 385,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.$nextTick(() => {
          this.$refs.table.clearSelection();
          this.tableData.forEach(item => {
            if (this.ids.includes(item.id)) {
              this.$refs.table.toggleRowSelection(item, true)
            }
          })
        });
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },

  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      this.tableLoading = true;
      GetApprovalOptionList({ symbolId: '' }).then(res => {
        this.tableData = res;
        if (this.tableData.length === 0) {
          this.tableData.push({
            name: '',
            button: '',
            status: 0,
          })
        }
        this.tableLoading = false;

        if (!!fun) fun()
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 提交
    submit () {
      let param = this.tableData.map((item, index) => {
        item.sn = index;
        return item;
      })
      let selectTemp = this.tableSelected;
      AddApprovalOption(param).then(res => {
        if (res.success) {
          this.getTableData(() => {
            this.$nextTick(() => {
              let data = [];
              selectTemp.forEach(item => {
                data.push(this.tableData.find(option => option.sn === item.sn))
              })
              this.$emit('submit', data);
              this.dialog.visible = false;
            })
          });

        }
      });
    },
    // 删除
    delItem (row, index) {
      if (row.id) {
        DelApprovalOption({ id: row.id })
      }
      this.tableData.splice(index, 1)


      // let selectIndex = this.tableSelected.findIndex(item => item.sn === row.sn)
      // this.tableSelected.splice(selectIndex, 1);
    },
    // 新增
    addItem (index) {
      this.tableData.splice(index + 1, 0, {
        name: '',
        button: '',
        status: 0,
      })
    },

  }
}
</script>

<style lang="scss" scoped>
</style>