<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >

      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          prop="deviceInfoName"
          :label="$t('newSubmitRequest.fault.label1')"
        >
          <el-input v-model="dialog.deviceInfoName"> </el-input>
        </el-form-item>
        <el-form-item :label="$t('newSubmitRequest.fault.label2')">
          <div class="label-wrap">
            <div class="add-box">
              <el-button
                icon="el-icon-plus"
                @click="FaultItemVisible = true"
              ></el-button>
            </div>
            <div class="label-box">
              <el-tag
                v-for="(item,index) in tags"
                :key="index"
                closable
                @close="tagClose(item)"
              >
                {{item.description}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          prop="faultDescription"
          :label="$t('newSubmitRequest.fault.label3')"
        >
          <el-input
            type="textarea"
            v-model="dialog.faultDescription"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>

    </el-dialog>

    <!-- 服务类型 -->
    <fault-item
      :visible.sync="FaultItemVisible"
      :tags-data="tags"

      @submit="faultItemSubmit"
    ></fault-item>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 组件
import FaultItem from './FaultItem'
// 接口
import { GetSystemTypeByDeviceType } from '@/api/warehouse/dataMaintenance/systemType'
import { GetFaultPhenomenonList } from '@/api/warehouse/dataMaintenance/systemType'

export default {
  components: {
    FaultItem
  },
  props: ['visible', 'data'],
  data () {
    return {
      FaultItemVisible: false,
      searchVal: '',
      viewType: 1,
      faultTypes: [],
      faultTypeLabels: [],
      tags: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        deviceInfoName: '',
        faultTypeId: '',
        faultTypeName: '',
        faultDescription: '',
      },
      rules: {
        deviceInfoName: [],
        faultDescription: [],
      },
      faultTypeName: '',
    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.$nextTick(() => {
          this.tags = [];
          this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
          this.viewType = 1;
          if (this.data) {
            this.dialog.title = this.$i18n.t('newSubmitRequest.fault.title2');
            Object.assign(this.dialog, this.data)
            this.getFaultNameById(this.data.faultTypeId, this.data.faultTypeName);
          } else {
            this.dialog.title = this.$i18n.t('newSubmitRequest.fault.title1');
          }
        })
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'viewType': function (newVal) {
      switch (newVal) {
        case 1:
          this.dialog.title = this.data ? this.$i18n.t('newSubmitRequest.fault.title2') : this.$i18n.t('newSubmitRequest.fault.title1');
          Array.from(document.querySelectorAll('.search-box .tag.active')).map(item => item.classList.remove('active'));
          break;
        case 2:
          this.dialog.title = this.$i18n.t('newSubmitRequest.fault.title3');
          break;
        case 3:
          this.dialog.title = this.$i18n.t('newSubmitRequest.fault.title4');
          break;
      }
    },
    'dialog.deviceTypeId': function (newVal) {
      if (newVal === '') return;
      let param = { deviceTypeId: newVal };
      GetSystemTypeByDeviceType(param).then(res => {
        this.dialog.systemTypeName = res.map(item => item.name).join(',');
      })
    },
    'searchVal': function (newVal) {
      if (newVal === '') {
        this.faultTypeLabels = this.faultTypes.slice(0, 100);
      } else {
        this.faultTypeLabels = this.faultTypes.filter(item => item.description.indexOf(newVal) !== -1)
      }
    },
  },
  created () {
    this.getFaultLabel();
  },
  methods: {
    getFaultLabel () {
      let param = {
        limit: 9999,
        page: 1,
        search: "",
      }
      GetFaultPhenomenonList(param).then(res => {
        this.faultTypes = res.list;
        this.faultTypeLabels = this.faultTypes.slice(0, 100);
      })
    },

    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        this.dialog.faultTypeId = this.tags.map(item => item.id ? item.id : item.description).join(',');
        this.dialog.faultTypeName = this.tags.map(item => item.description).join('、');
        this.$emit('submit', {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? 'edit' : 'add'
        });
        this.dialog.visible = false;
      });
    },
    //故障类型提交
    faullSubmit () {
      let activeTags = Array.from(document.querySelectorAll('.search-box .tag.active'));
      let tagIds = this.tags.map(item => item.id).join(',');
      activeTags = activeTags.map(item => Object.create({
        name: item.dataset.name,
        id: item.dataset.id,
      })).filter(item => {
        if (tagIds.includes(item.id)) {
          this.$message.warning(`${item.name}${this.$i18n.t('newSubmitRequest.fault.message1')}`);
          return false;
        } else {
          return true;
        }
      });
      this.viewType = 1;
      this.tags = this.tags.concat(activeTags)
    },
    // 新增提交
    addSubmit () {
      if (this.faultTypeName.length === 0) return this.$message.warning(this.$i18n.t('newSubmitRequest.fault.message2'));
      this.viewType = 1;
      this.tags.push({ name: this.faultTypeName, id: '' });
    },
    // 获取故障名称
    getFaultNameById (ids, names) {
      if (ids === '') return;
      ids = ids.split(',');
      names = names.split('、');
      this.tags = ids.map((item, index) => {
        return {
          description: names[index],
          id: item,
        }
      })
    },
    // 关闭故障标签
    tagClose (tag) {
      this.tags.splice(this.tags.findIndex(item => item.id === tag.id), 1);
    },
    faultItemSubmit (data) {
      this.tags = data;
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .add-box {
    width: 100px;
  }
  .label-wrap {
    display: flex;
  }
}
.el-tag {
  margin: 0 5px 5px;
  cursor: pointer;
}
.footer-box {
  text-align: center;
}
.search-box {
  .tag-box {
    min-height: calc(256px - 42px - 36px);
  }
  .label-item {
    height: 32px;
    line-height: 32px;
  }
  .el-input {
    margin-bottom: 10px;
  }
  .tag {
    background-color: #ffffff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #606266;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
    &.active {
      color: #409eff;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
    }
  }
}
.add-box {
  .label-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      width: 200px;
      padding-right: 12px;
      text-align: right;
      display: inline-block;
    }
  }
}
.align-right {
  text-align: right;
}
</style>