<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-date-picker
            size="medium"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            unlink-panels
            value-format="yyyy-MM-dd"
            @change="searchData"
          >
          </el-date-picker>
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              :disabled="!permit.down"
              size="medium"
              @click="downPDF"
            >{{$t('servicesConfirmation.downloadPDF')}}</el-button>
            <el-button
              :disabled="!permit.down"
              size="medium"
              @click="downWord"
            >{{$t('servicesConfirmation.downloadWord')}}</el-button>
            <el-button
              size="medium"
              @click="evaluate"
            >{{$t('operation.evaluate')}}</el-button>
            <el-button
              size="medium"
              @click="exportServiceConfirmation"
            >{{$t('operation.export')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="showEdit"
          @row-click="rowClick"
          :default-sort="{prop: 'createTime', order: 'descending'}"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="selection"></el-table-column>
          <!-- <el-table-column
            min-width="150"
            sortable
            prop="createTime"
            :label="$t('servicesConfirmation.col9')"
          >
          </el-table-column> -->
          <el-table-column
            min-width="150"
            sortable
            prop="currentHandleTime"
            :label="$t('servicesConfirmation.col6')"
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="220"
            sortable
            prop="number"
            :label="$t('servicesConfirmation.col1')"
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            prop="cityName"
            :label="$t('servicesConfirmation.col2')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="customerOrgName"
            :label="$t('servicesConfirmation.col10')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="branchName"
            :label="$t('servicesConfirmation.col3')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="description"
            :label="$t('servicesConfirmation.col4')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="serviceUserName"
            :label="$t('servicesConfirmation.col5')"
          >
          </el-table-column>

          <el-table-column
            min-width="100"
            sortable
            prop="source"
            :label="$t('servicesConfirmation.col7')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="serviceTypeName"
            :label="$t('servicesConfirmation.col8')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 全局方法
import {
  downFile,
  formatUTCDate,
  toLocaleDate,
} from '@/utils/common'
// 组件
import ColSet from '@/components/ColSet'
// 接口
import {
  GetConfirmOrderList,
  DelConfirmOrder,
  DownLoadPDF,
  AccessoryDownload,
  ExportConfirmOrder,
} from '@/api/warehouse/serviceConfirmation'
export default {
  name: 'ServiceConfirmationForm',
  components: {
    ColSet,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      permit: {
        search: true,
        add: true,
        del: true,
        down: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      dateRange: null,
      searchVal: '',
      tableSelected: [],
      tableLoading: false,
      tableData: [],
      tableHeight: null,
      selectItem: {},
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'ServiceConfirmationForm') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
    this.dealPermission();

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        date: this.dateRange !== null ? `${formatUTCDate(this.dateRange[0])}~${formatUTCDate(this.dateRange[1] + ' 23:59:59')}` : '',
      }
      this.tableLoading = true;
      GetConfirmOrderList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 修改
    showEdit (e) {
      this.selectItem = e;
      this.$router.push({ path: '/home/newServiceConfirmationForm', query: { id: e.id, type: 1 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: this.tableSelected.map(item => item.id).join(',') };
        DelConfirmOrder(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 下载PDF
    downPDF () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      let param = { id: this.tableSelected[0].id }
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      window.open(`${this.baseUrl}/ConfirmOrder/exportPDF.do?id=${param.id}&timeZone=${timeZone}`);
    },
    // 下载Word
    downWord () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      let param = { id: this.tableSelected[0].id };
      window.open(`${this.baseUrl}/Export/downloadAnnex.do?id=${param.id}`);

    },
    // 评价
    evaluate () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.$router.push({ path: '/home/score', query: { id: this.tableSelected[0].id } })

    },
    // 导出
    exportServiceConfirmation () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')

      let param = this.tableSelected.map(item => {
        let data = item.maintenanceServiceConfirmOrderDetail || {};
        let handleMethod = [item.handleMethod];

        let maintenanceEquipmentTable = data.repairDeviceList || [];
        let replaceEquipmentTable = data.replaceDeviceList || [];
        let demolitionEquipmentTable = data.removeDeviceList || [];
        let configEidtTable = data.classifiedList || [];
        let installationTable = data.installationDeviceList || [];

        maintenanceEquipmentTable.forEach(item => {
          if (!!item.handleMethod) handleMethod.push(item.handleMethod);
        })
        replaceEquipmentTable.forEach(item => {
          if (!!item.remark) handleMethod.push(item.remark);
        })
        demolitionEquipmentTable.forEach(item => {
          if (!!item.remark) handleMethod.push(item.remark);
        })
        configEidtTable.forEach(item => {
          if (!!item.remark) handleMethod.push(item.remark);
        })
        installationTable.forEach(item => {
          if (!!item.remark) handleMethod.push(item.remark);
        })
        handleMethod = Array.from(new Set(handleMethod)).join('\n');
        return {
          currentHandleTime: toLocaleDate(item.currentHandleTime),
          number: item.number,
          cityName: item.cityName,
          customerOrgName: item.customerOrgName,
          branchName: item.branchName,
          description: item.description,
          serviceUserName: item.serviceUserName,
          source: item.source,
          serviceTypeName: item.serviceTypeName,
          handleMethod: handleMethod,
        }
      })
      ExportConfirmOrder(param).then(res => {
        window.open(`${this.baseUrl}/Export/download2.do?fileName=${res}`)
        // let blob = new Blob([res]);
        // let url = window.URL.createObjectURL(blob);
        // let a = document.createElement('a');
        // let myEvent = new MouseEvent('click')
        // a.download = `${this.tableSelected[0].number}.xlsx`;
        // a.href = url
        // a.dispatchEvent(myEvent)
      })
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row);
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.selectItem.id === row.id) {
        return 'checked-row';
      }
      return '';
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('ServiceConfirmationFormSearch');
        this.permit.add = permission.includes('ServiceConfirmationFormAdd');
        this.permit.del = permission.includes('ServiceConfirmationFormDelete');
        this.permit.down = permission.includes('ServiceConfirmationFormDownloadServiceConfirmationForm');

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-wrap {
  justify-content: start;
  .el-date-editor {
    margin-right: 10px;
  }
}
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>
