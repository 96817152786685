<template>
  <div class="wrap">
    <el-form
      :model="groupForm"
      :rules="rules"
      ref="groupForm"
      class="group-form"
      size="medium"
      label-width="170px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item
            :label="$t('allocateGoods.applyFrom.label1')"
            prop="applyFormNo"
          >
            <el-button-group class="input-group">
              <el-input
                v-model="groupForm.applyFormNo"
                class="search-input"
              ></el-input>
              <el-button
                v-if="viewType === 1"
                @click="ApplyDialogVisible = true"
              >{{$t("operation.search")}}</el-button>
              <el-button
                v-else
                @click="autoNum"
              >{{$t("operation.auto")}}</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
        <el-col
          v-if="viewType === 2"
          :span="6"
        >
          <el-form-item
            :label="$t('allocateGoods.applyFrom.label2')"
            prop="requisitioner"
          >
            <el-input
              v-model="groupForm.requisitionerName"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            :label="$t('allocateGoods.applyFrom.label3')"
            prop="dataAccessGroup"
          >
            <el-select
              :disabled="viewType === 1"
              v-model="groupForm.dataAccessGroup"
              filterable
            >
              <el-option
                v-for="item in dataAccessGroups"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('allocateGoods.applyFrom.label4')">
            <div
              class="status-box"
              :title="groupForm.status"
            >
              {{groupForm.status}}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('allocateGoods.applyFrom.label5')">
            <span v-if="groupForm.createMethod === 0">{{$t('allocateGoods.applyFrom.option1')}}</span>
            <span v-if="groupForm.createMethod === 1">{{$t('allocateGoods.applyFrom.option2')}}</span>
            <span v-if="groupForm.createMethod === 2">{{$t('allocateGoods.applyFrom.option3')}}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">{{
      $t("allocateGoods.applyFrom.subtitle1")
    }}</el-divider>

    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            v-if="viewType === 2"
            :disabled="!permit.editAll && !permit.editSelf || orderOrPre || !permit.del"
            size="medium"
            @click="delItem"
          >{{$t("operation.del")}}</el-button>
          <el-button
            v-if="viewType === 2"
            :disabled="!permit.editAll && !permit.editSelf || orderOrPre || !permit.add"
            size="medium"
            @click="showAdd"
          >{{$t("operation.add")}}</el-button>
          <el-button
            :disabled="orderOrPre || !permit.lock"
            size="medium"
            @click="setLock"
          >{{$t("allocateGoods.applyFrom.btn1")}}</el-button>
          <el-button
            :disabled="orderOrPre || !permit.replace"
            size="medium"
            @click="showReplace"
          >{{$t("allocateGoods.applyFrom.btn2")}}</el-button>
          <el-button
            :disabled="!permit.signIn"
            size="medium"
            @click="signIn"
          >{{$t("allocateGoods.applyFrom.btn3")}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        :row-class-name="tableRowClassName"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          prop="typeName"
          :label="$t('allocateGoods.applyFrom.col1')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="deviceName"
          :label="$t('allocateGoods.applyFrom.col2')"
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="deviceModel"
          :label="$t('allocateGoods.applyFrom.col3')"
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="brandName"
          :label="$t('allocateGoods.applyFrom.col4')"
        ></el-table-column>
        <el-table-column
          min-width="80"
          prop="unit"
          :label="$t('allocateGoods.applyFrom.col5')"
        ></el-table-column>
        <el-table-column
          min-width="100"
          prop="count"
          :label="$t('allocateGoods.applyFrom.col6')"
        ></el-table-column>
        <el-table-column
          v-if="permit.showPOAmount"
          min-width="100"
          prop="singlePrice"
          :label="$t('allocateGoods.applyFrom.col7')"
        ></el-table-column>
        <el-table-column
          v-if="permit.showPOAmount"
          min-width="100"
          :label="$t('allocateGoods.applyFrom.col8')"
        >
          <template slot-scope="scope">
            {{ parseInt(scope.row.count) * parseFloat(scope.row.singlePrice) }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="orderCount"
          :label="$t('allocateGoods.applyFrom.col9')"
        ></el-table-column>
        <el-table-column
          min-width="200"
          prop="preCount"
          :label="$t('allocateGoods.applyFrom.col10')"
        ></el-table-column>
        <el-table-column
          min-width="380"
          prop="projectName"
          :label="$t('allocateGoods.applyFrom.col11')"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          min-width="150"
          :label="$t('allocateGoods.applyFrom.col12')"
          prop="quotationNumber"
        ></el-table-column>
      </el-table>
    </el-row>
    <el-row class="bottom-btn">
      <el-button-group>
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!!groupForm.id"
          size="medium"
          :disabled="!permit.request || !$global.ExamineData.success || groupForm.approvalStatus === 2"
          :id="groupForm.id"
          @click="reviewApply"
        >审核</el-button>
        <el-button
          v-if="!!groupForm.id && ( permit.editAll || permit.editSelf || viewType == 1) && groupForm.approvalStatus === 2"
          size="medium"
          type="primary"
          @click="resetSubmit"
        >重新提交</el-button>
        <el-button
          v-if="!groupForm.id || permit.editAll || permit.editSelf || viewType == 1"
          size="medium"
          type="primary"
          @click="saveBack"
        >{{$t("allocateGoods.btn3")}}</el-button>
        <el-button
          v-if="!!groupForm.id"
          size="medium"
          type="primary"
          @click="printApply"
        >{{$t("allocateGoods.btn4")}}</el-button>
      </el-button-group>
    </el-row>
    <!-- 申请单弹框 -->
    <apply-dialog
      :visible.sync="ApplyDialogVisible"
      @submit="applySubmit"
    ></apply-dialog>

    <!-- 新增设备 -->
    <add-equipment
      :visible.sync="AddEquipmentVisible"
      :data="equipmentData"
      :permit="permit"
      @submit="equipmentSubmit"
    ></add-equipment>

    <!-- 锁定弹框 -->
    <set-lock
      :visible.sync="SetLockVisible"
      :apply-id="groupForm.id"
      :device-id="deviceData.deviceInfoId"
      :lock-num="deviceData.lockCount"
      :apply-num="deviceData.count"
      @update="getApplyDevice"
    ></set-lock>
    <!-- 审核弹框 -->
    <examine-dialog
      :visible.sync="ExamineDialogVisible"
      :id="groupForm.id"
      :type="examineType"
    ></examine-dialog>
    <!-- 替换弹框 -->
    <replace-dialog
      :visible.sync="ReplaceDialogVisible"
      :application-form-id="groupForm.id"
      :data="equipmentData"
      @update="getApplyDevice"
    ></replace-dialog>

    <!-- 编辑弹框 -->
    <sign-in-dialog
      :visible.sync="SignInDialogVisible"
      :type="2"
      :data="equipmentData"
      @update="getApplyDevice"
    ></sign-in-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
import ApplyDialog from "./ApplyDialog";
import AddEquipment from "./AddEquipment";
import SetLock from "./SetLock";
import ReplaceDialog from "./ReplaceDialog";
import ExamineDialog from "@/pages/warehouse/purchaseRequisition/components/ExamineDialog";
import SignInDialog from './SignInDialog'

// 接口
import {
  AddPurchaseRequisition,
  AutoPurchaseRequisitionNum,
  GetPurchaseRequisitionItem,
  GetApplyDeviceList,
  DelApplyDevice,
  GetPurchaseRequisitionAll,
} from "@/api/warehouse/purchaseRequisition";
import { GetDataAccessGroupAll } from "@/api/organizations";
import { GetUserAll } from "@/api/userManagement";
export default {
  components: {
    MySelect,
    ApplyDialog,
    AddEquipment,
    SetLock,
    ExamineDialog,
    ReplaceDialog,
    SignInDialog,
  },
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      permit: {
        request: true,
        showPOAmount: true,
        editAll: true,
        editSelf: true,
        // 
        del: false,
        add: false,
        edit: false,
        lock: false,
        replace: false,
        signIn: false,
      },
      ApplyDialogVisible: false,
      AddEquipmentVisible: false,
      SetLockVisible: false,
      ExamineDialogVisible: false,
      ReplaceDialogVisible: false,
      SignInDialogVisible: false,

      viewType: null,
      requisitioners: [],
      dataAccessGroups: [],
      applicationNumber: '',
      groupForm: {
        id: null,
        loading: false,
        applyFormNo: '',
        requisitioner: '',
        requisitionerName: '',
        dataAccessGroup: localStorage.accessGroupId,
        status: '',
        createMethod: 0,
        approvalStatus: 2,
      },
      rules: {
        applyFormNo: [{ validator: this.validateApplyNumber, required: true, trigger: 'blur' }],
        requisitioner: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      tableLoading: false,
      equipmentData: null,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      applyData: null,
      deviceData: {},
      examineType: null,
      signInData: null,
      isResubmit: null,
    };
  },
  computed: {
    requisitionerName: function () {
      let user = this.requisitioners.find(item => item.id === this.groupForm.requisitioner);
      if (user) {
        return user.fullname
      } else {
        return this.groupForm.requisitionerName;
      }
    },
    // 只要有一个设备订货或预分配，该单只能进行签收操作
    orderOrPre: function () {
      return this.tableData.some(item => item.orderCount > 0 || item.preCount > 0)
    },
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab1') {
        this.getApplyDevice();
      }
    }
  },
  created () {
    this.dealPermission();
    this.initView();

    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1);
    },
    initView () {
      this.viewType = Number(this.$route.query.type);
      switch (this.viewType) {
        case 1:
          break;
        case 2:
          this.groupForm.id = this.$route.query.id;
          if (this.groupForm.id) {
            this.getItem();
          } else {
            this.autoNum();
            this.groupForm.requisitioner = this.$cookies.get('userId');
            this.groupForm.requisitionerName = this.$cookies.get('fullName');
            this.groupForm.approvalStatus = 0;

            Object.assign(this.permit, {
              del: true,
              add: true,
              edit: true,
              lock: true,
              replace: true,
            })
          }
          // 获取申购人
          GetUserAll({}).then(res => {
            this.requisitioners = res
            if (!!this.groupForm.requisitionerName) this.addUser();
          });
          break;
      }
    },
    // 获取购物申请单详情
    getItem () {
      let parma = { id: this.groupForm.id };
      GetPurchaseRequisitionItem(parma).then(res => {
        this.applicationNumber = res.applicationNumber;
        Object.assign(this.groupForm, {
          applyFormNo: res.applicationNumber,
          requisitioner: res.purchaseUserId,
          requisitionerName: res.purchaseUser,
          dataAccessGroup: res.accessGroupId,
          createMethod: res.createMethod,
          finance: res.finance,
          purchasingManager: res.purchasingManager,
          generalManager: res.generalManager,
          deptManager: res.deptManager,
          approvalStatus: res.approvalStatus,
        });
        this.applyData = {
          id: res.id,
          projectContractId: res.projectContractId,
          projectName: res.projectName,
          quotationNumber: res.quotationNumber,
          applyFormNo: res.applyFormNo,
          purchaseUser: res.purchaseUser
        };
        this.getApplyDevice();

        if (this.requisitioners.length > 0) this.addUser();

        if (res.approvalStatus === null || res.approvalStatus === 0) {
          this.groupForm.status = '待审批';
        } else {
          this.groupForm.status = res.approvalSymbol + ' - ' + res.opName;
        }
        this.permit.signIn = this.groupForm.approvalStatus !== 2
        // 0未操作，1通过，2不通过
        if (this.groupForm.finance !== 0) {
          this.permit.add = false;
        } else if (this.groupForm.purchasingManager !== 0) {
          Object.assign(this.permit, {
            del: this.groupForm.purchasingManager === 1 ? false : true,
            add: this.groupForm.purchasingManager === 1 ? false : true,
            edit: this.groupForm.purchasingManager === 1 ? false : true,
            replace: this.groupForm.purchasingManager === 1 ? false : true,
            lock: true,
          })
        } else if (this.groupForm.generalManager !== 0) {
          Object.assign(this.permit, {
            del: this.groupForm.generalManager === 1 ? false : true,
            add: this.groupForm.generalManager === 1 ? false : true,
            edit: this.groupForm.generalManager === 1 ? false : true,
            replace: this.groupForm.generalManager === 1 ? false : true,
            lock: true,
          })
        } else if (this.groupForm.deptManager !== 0) {
          Object.assign(this.permit, {
            del: this.groupForm.deptManager === 1 ? false : true,
            add: this.groupForm.deptManager === 1 ? false : true,
            edit: this.groupForm.deptManager === 1 ? false : true,
            replace: this.groupForm.deptManager === 1 ? false : true,
            lock: true,
          })
        } else {
          Object.assign(this.permit, {
            del: true,
            add: true,
            edit: true,
            lock: true,
            replace: true,
          })
        }
      });
    },
    // 添加申购人员
    addUser () {
      if (this.requisitioners.some(item => item.id === this.groupForm.requisitioner)) return;

      this.requisitioners.push({
        id: this.groupForm.requisitioner,
        fullname: this.groupForm.requisitionerName,
      })
    },
    // 获取申请设备
    getApplyDevice () {
      if (!this.groupForm.id) return;

      let param = {
        applicationFormId: this.groupForm.id,
      };
      GetApplyDeviceList(param).then(res => {
        this.tableData = res.list;
      });
    },

    // 锁定
    setLock () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.');
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.');
      this.SetLockVisible = true;
      this.deviceData = this.tableSelected[0];
    },
    // 显示新增弹框
    showAdd () {
      if (this.groupForm.id === null) return this.$message.warning(this.$i18n.t('allocateGoods.message2'));
      this.equipmentData = null;
      this.AddEquipmentVisible = true;
    },
    dblclickEdit (e) {
      if (this.viewType === 1) return;
      // if (!this.permit.edit) return;
      this.equipmentData = e;
      this.AddEquipmentVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.');
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          let checkIndex = this.tableSelected.map(item => item.rowIndex);
          let param = { id: this.tableSelected.map(item => item.id).join(',') };
          this.tableData = this.tableData.filter(
            item => !checkIndex.includes(item.rowIndex)
          );

          if (param.id == '') return this.$i18n.t('message.del');
          DelApplyDevice(param).then(res => {
            if (res.success) {
              this.$message.success(this.$i18n.t('message.del'));
            } else {
              this.$message.warning(res.data);
            }
          });
        })
        .catch(() => { });
    },
    // 重新提交
    resetSubmit () {
      this.isResubmit = 1;
      this.submit();
    },
    // 保存退出
    saveBack () {
      this.isResubmit = 0;
      this.submit();
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          form: {
            accessGroupId: this.groupForm.dataAccessGroup,
            applicationNumber: this.groupForm.applyFormNo,
            purchaseUser: this.requisitionerName,
            purchaseUserId: this.groupForm.requisitioner,
            isResubmit: this.isResubmit,
          }
        };

        if (this.tableData.length > 0) {
          param.infos = this.tableData.map(item => {
            item.deliveryInfos = item.deliveryInfos || [];
            let data = {
              id: item.id || '',
              applicationFormId: this.groupForm.id,
              deviceInfoId: item.deviceInfoId,
              count: item.count,
              unit: item.unit,
              useTo: item.useTo,
              expecteBuyBackTime: item.expecteBuyBackTime,
              remark: item.remark,
              typeName: item.typeName,
              deviceName: item.deviceName,
              brandName: item.brandName,
              deviceModel: item.deviceModel,
              supplierName: item.supplierName,
              singlePrice: item.singlePrice,
              singlePriceUnit: 1,
              projectContractId: item.projectContractId,
              quotationTypeId: item.quotationTypeId,
              customerApprovalId: item.customerApprovalId,
              detailed: item.detailed,
              // operateFlag: item.operateFlag,
              deliveryInfos: item.deliveryInfos.map(item => {
                // 验证是否是新收货地址
                if (!/.{8}(-.{4}){3}-.{12}/.test(item.addressId)) {
                  item.address = item.addressId;
                  item.addressId = '';
                }
                // 验证是否是新收件人
                if (!/.{8}(-.{4}){3}-.{12}/.test(item.consigneeId)) {
                  item.consignee = item.consigneeId;
                  item.consigneeId = '';
                }
                return item;
              })
            };
            return data;
          });
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.form.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddPurchaseRequisition(param)
          .then(res => {
            if (res.success) {
              this.$message.success(msg);
              this.back();
            }
            this.groupForm.loading = false;
          })
          .catch(error => {
            this.groupForm.loading = false;
          });
      });
    },
    // 购物申请单提交
    applySubmit (data) {
      Object.assign(this.groupForm, {
        id: data.id,
        applyFormNo: data.applicationNumber,
        requisitioner: data.purchaseUserId,
        dataAccessGroup: data.accessGroupId,
        projectName: data.projectName,
      });
      this.$refs.groupForm.validateField('applyFormNo');
      this.getApplyDevice();
      this.applyData = data;
    },
    // 设备提交
    equipmentSubmit ({ data, type }) {
      switch (type) {
        case 'add':
          let item = this.tableData.find(item => item.deviceInfoId === data.deviceInfoId && item.projectContractId === data.projectContractId && item.singlePrice == data.singlePrice)
          if (!!item) {
            item.count += data.count;
          } else {
            this.tableData.push(data);
          }
          break;
        case 'edit':
          this.$set(this.tableData, data.rowIndex, data);
          break;
      }
    },
    // 自动生成申请单号
    autoNum () {
      AutoPurchaseRequisitionNum().then(res => {
        if (res.success) {
          this.groupForm.applyFormNo = res.data;
          this.$refs.groupForm.validateField('applyFormNo');
        }
      });
    },
    // 检测申请单号
    validateApplyNumber (rule, value, callback) {
      if (this.viewType === 1) return callback();
      if (value == '') {
        callback(new Error(this.$i18n.t('message.notNull')));
      } else if (value === this.applicationNumber) {
        callback();
      } else {
        let param = { applicationNumber: value };
        GetPurchaseRequisitionAll(param).then(res => {
          if (res.length > 0)
            callback(new Error(this.$i18n.t('allocateGoods.message1')));
          else callback();
        });
      }
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row);
    },

    // 审核
    reviewApply () {
      this.ExamineDialogVisible = true;
    },
    // 打印
    printApply () {
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      window.open(`${this.baseUrl}/Export/exportPurchase3.do?id=${this.groupForm.id}&timeZone=${timeZone}`);
    },
    // 显示替换
    showReplace () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.');
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.');
      if (!this.tableSelected[0].id) return this.$message.warning('New equipment cannot be replaced.');
      this.equipmentData = this.tableSelected[0];
      this.ReplaceDialogVisible = true;
    },
    // 显示签收
    signIn () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.');
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.');
      this.equipmentData = this.tableSelected[0];
      this.SignInDialogVisible = true;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.request = permission.includes('PurchaseRequisitionRequestApproval');
        this.permit.showPOAmount = permission.includes('PurchaseRequisitionBeallowedtoseetheprice');
        this.permit.editAll = permission.includes('PurchaseRequisitionEdit-Allitems');
        this.permit.editSelf = permission.includes('PurchaseRequisitionEdit-Toonlyallowausertoedithisownitems')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.bottom-btn {
  text-align: center;
}
.input-group {
  height: 36px;
}
.cell-style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-row {
  height: calc(100% - 268px);
}
.status-box {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
