<template>
  <div class="wrap">
    <el-row
      class="top-row"
      :class="{
        'full-height':tabActive === 'DataAccessGroups'
      }"
    >
      <el-card class="table-card">
        <el-tabs v-model="tabActive">
          <el-tab-pane
            v-if="permit.orgTab"
            :label="$t('organizations.tab1')"
            name="Organizations"
          >
            <organizations-table
              :organization-id.sync="organizationId"
              :data.sync="organizationData"
              :active="tabActive"
              ref="organizations"
            ></organizations-table>
          </el-tab-pane>
          <el-tab-pane
            v-if="permit.dataTab"
            :label="$t('organizations.tab2')"
            name="DataAccessGroups"
          >
            <data-access-group :active="tabActive"></data-access-group>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-row>
    <el-row
      class="bottom-row"
      :gutter="10"
      v-show="tabActive === 'Organizations' && organizationId !== null"
    >
      <el-col :span="12">
        <el-card class="tree-wrap">
          <div class="title">{{$t('organizations.subtitle1')}}
            <el-button-group class="btn-wrap">
              <el-button
                :disabled="!permit.shareOrganizationChart"
                size="medium"
                @click="showShare"
              >{{$t('organizations.btn1')}}</el-button>
              <el-button
                size="medium"
                @click="showStore(2)"
              >{{$t('organizations.btn2')}}</el-button>
              <el-button
                :disabled="!permit.editOrganizationChart"
                size="medium"
                @click="showStore(3)"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.delOrganizationChart"
                size="medium"
                @click="delNode"
              >{{$t('operation.del')}}</el-button>
              <el-button size="medium">{{$t('operation.move')}}</el-button>
              <el-button size="medium">{{$t('operation.copy')}}</el-button>
            </el-button-group>
          </div>

          <el-tree
            :data="treeData"
            :expand-on-click-node="false"
            node-key="id"
            ref="tree"
            v-loading="treeLoading"
            @node-click="nodeClick"
            default-expand-all
            highlight-current
          >
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="layout-wrap">
          <div class="title">{{$t('organizations.subtitle2')}}</div>
          <div class="form-wrap">
            <div class="form-item">
              <div class="label">LOGO</div>
              <div class="logo-box">
                <el-button
                  :disabled="!permit.editLogo"
                  size="medium"
                  @click="$refs.logoFile.click()"
                >Upload</el-button>
                <div class="logo">
                  <img
                    v-if="!!logoForm.logo"
                    :src="baseUrl + '/Engineer/getImage.do?imageId=' + logoForm.logo"
                  >
                </div>
                <input
                  type="file"
                  ref="logoFile"
                  @change="uploadImage"
                  hidden
                >
              </div>
            </div>
            <div class="form-item">
              <div class="label">{{$t('organizations.label1')}}</div>
              <el-select
                :disabled="!permit.editThemeColors"
                size="medium"
                v-model="logoForm.themeColor"
                class="theme-color"
                :class="logoForm.themeColor"  
              >
                <el-option
                  v-for="(item,index) in themeColors"
                  :key="index"
                  :value="item"
                  label=" "
                  :class="item"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="label">{{$t('organizations.label2')}}</div>
              <el-select
                :disabled="!permit.editReceiptTemplates"
                size="medium"
                v-model="logoForm.receipt"
                filterable
              >
                <el-option
                  v-for="(item,index) in receipts"
                  :key="index"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="label">{{$t('organizations.label3')}}</div>
              <el-select
                :disabled="!permit.editOverviewDashboardTemplates"
                size="medium"
                v-model="logoForm.dashboard"
                filterable
              >
                <el-option
                  v-for="(item,index) in dashboards"
                  :key="index"
                  :value="item"
                  :class="item"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="label">{{$t('organizations.label4')}}</div>
              <el-select
                :disabled="!permit.editKPITemplates"
                size="medium"
                v-model="logoForm.kpi"
                filterable
              >
                <el-option
                  v-for="(item,index) in kpis"
                  :key="index"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="label">{{$t('organizations.label5')}}</div>
              <el-select
                :disabled="!permit.editKPIDashboardTemplates"
                size="medium"
                v-model="logoForm.kpiDashboard"
                filterable
              >
                <el-option
                  v-for="(item,index) in kpiDashboards"
                  :key="index"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div class="btn-wrap">
              <el-button size="medium">{{$t('operation.cancel')}}</el-button>
              <el-button
                :disabled="!permit.save"
                size="medium"
                type="primary"
                @click="submitLayout"
              >{{$t('operation.save')}}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加部门弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="departmentDialog.title"
      :visible.sync="departmentDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="departmentDialog"
        :rules="rules"
        ref="departmentForm"
        size="small"
      >
        <el-form-item
          :label="$t('organizations.nodeDialog.label1')"
          prop="type"
        >
          <el-select v-model="departmentDialog.type">
            <el-option
              label="Department"
              value="0"
            ></el-option>
            <el-option
              label="Position"
              value="1"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="departmentDialog.label"
          prop="name"
        >
          <el-input v-model="departmentDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('organizations.nodeDialog.label4')"
          prop="descirption"
        >
          <el-input
            type="textarea"
            :rows="3"
            v-model="departmentDialog.descirption"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="departmentDialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitDepartment"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <!-- 分享弹窗 -->
    <share-dialog :visible.sync="ShareDialogVisible"></share-dialog>
  </div>
</template>

<script>
// 全局方法
import { dealTree } from '@/utils/common'
import '@/utils/getPixelColor'
// 组件
import DataAccessGroup from './components/DataAccessGroup'
import OrganizationsTable from './components/OrganizationsTable'
import ShareDialog from './components/ShareDialog'
import LockModule from '@/components/LockModule'
// 接口
import { UploadImage } from '@/api'
import {
  AddDepartment,
  AddChildDepartment,
  DelDepartment,
  UploadLogo,
  GetDepartmentTree,
  SaveOrganizationLayout,
} from '@/api/organizations'
import { GetLeveListAll } from '@/api/riskOfLossLevel'
export default {
  name: 'Organizations',
  components: {
    DataAccessGroup,
    OrganizationsTable,
    LockModule,
    ShareDialog,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      ShareDialogVisible: false,
      permit: {
        orgTab: true,
        dataTab: true,
        shareOrganizationChart: true,
        editOrganizationChart: true,
        delOrganizationChart: true,
        editLogo: true,
        editThemeColors: true,
        editRiskLevelTemplates: true,
        editReceiptTemplates: true,
        editOverviewDashboardTemplates: true,
        editKPITemplates: true,
        editKPIDashboardTemplates: true,
        save: true,
      },
      tabActive: 'Organizations',
      organizationId: null,
      organizationData: {},
      treeLoading: false,
      currentNode: null,
      treeTime: null,
      treeData: [],
      departmentDialog: {
        visible: false,
        loading: false,
        title: '',
        label: '',
        parentId: null,
        id: null,
        type: '0',
        name: '',
        descirption: '',
        orgzarionsId: '',
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Node Name.', trigger: 'blur' }],
      },
      themeColors: [
        'black',
        'blue',
        'green',
        'orange',
        'grey',
      ],
      risks: [],
      receipts: [],
      dashboards: [],
      kpis: [],
      kpiDashboards: [],
      logoForm: {
        logo: '',
        themeColor: 'grey',
        risk: '',
        receipt: '',
        dashboard: '',
        kpi: '',
        kpiDashboard: '',
      }
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'Organizations')
        GetLeveListAll().then(res => this.risks = res)
    },
    'organizationId': function (newVal) {
      if (newVal === null) return;
      this.getTreeData()
      Object.assign(this.logoForm, {
        logo: this.organizationData.logo,
        themeColor: this.organizationData.colors || 'grey',
        risk: this.organizationData.leveTemplatesId,
        receipt: this.organizationData.receiptTemplatesId,
        dashboard: this.organizationData.dashboardTemplatesId,
        kpi: this.organizationData.kpiTemplatesId,
        kpiDashboard: this.organizationData.kpiDashboardTemplatesId,
      })
    },
  },
  created () {
    this.dealPermission();
    GetLeveListAll().then(res => this.risks = res)
  },
  mounted () {
  },
  methods: {
    getTreeData () {
      let param = {
        orgZarionsId: this.organizationId
      }
      this.treeLoading = true;
      GetDepartmentTree(param).then(res => {
        dealTree(res);
        this.treeData = res;
        this.treeLoading = false;
      })
    },
    // 显示分享
    showShare () {
      this.ShareDialogVisible = true;
    },
    // 显示树节点弹框
    showStore (type) {
      if (this.currentNode === null && type !== 1) return this.$message.warning('Please select one item.');
      this.$refs.departmentForm && this.$refs.departmentForm.resetFields();
      switch (type) {
        case 2:
          this.departmentDialog.title = this.$i18n.t('organizations.nodeDialog.title1');
          this.departmentDialog.label = this.$i18n.t('organizations.nodeDialog.label2');
          this.departmentDialog.id = null;
          this.departmentDialog.parentId = this.currentNode.id;
          break;
        case 3:
          if (this.organizationId === this.currentNode.id) return this.$message.warning('Root node cannot be edited.');
          this.departmentDialog.title = this.$i18n.t('organizations.nodeDialog.title2');
          this.departmentDialog.label = this.$i18n.t('organizations.nodeDialog.label3');
          this.departmentDialog.id = this.currentNode.id;
          this.$nextTick(() => {
            this.departmentDialog.name = this.currentNode.label;
            this.departmentDialog.type = this.currentNode.type;
            this.departmentDialog.descirption = this.currentNode.description;
          })
          this.departmentDialog.parentId = this.currentNode.parentId || null;
          break;
      }
      this.departmentDialog.visible = true;
    },
    // 添加、编辑节点
    submitDepartment () {
      this.$refs.departmentForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.departmentDialog.name,
          description: this.departmentDialog.descirption,
          orgZarionsId: this.organizationId,
          parentId: this.departmentDialog.parentId,
          type: this.departmentDialog.type,
        }
        let msg = this.$i18n.t('message.add');
        if (this.departmentDialog.id) {
          param.id = this.departmentDialog.id;
          msg = this.$i18n.t('message.edit');
        }
        this.departmentDialog.loading = true;
        AddChildDepartment(param).then(res => {
          this.$message.success(msg);
          this.getTreeData();
          this.departmentDialog.loading = false;
          this.departmentDialog.visible = false;
        }).catch(error => {
          this.departmentDialog.loading = false;
        })
      })
    },
    // 删除
    delNode () {
      if (this.currentNode === null) return this.$message.warning('Please select one item.');
      if (this.organizationId === this.currentNode.id) return this.$message.warning('Root node cannot be deleted.');
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.currentNode.id,
        }
        DelDepartment(param).then(res => {
          if (res.success) {
            this.currentNode = null;
            this.getTreeData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 点击树节点
    nodeClick (node) {
      let oldid = this.currentNode === null ? null : this.currentNode.id;
      this.currentNode = node;

      if (this.treeTime === null) {
        this.treeTime = new Date()
      } else {
        let interval = new Date() - this.treeTime;
        this.treeTime = null;
        if (interval < 1000 && oldid === this.currentNode.id) this.showStore(3)
      }
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.logoFile.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.logoForm.logo = res.message;
        }
      })
    },
    // 提交布局 
    submitLayout () {
      let param = {
        id: this.organizationId,
        logo: this.logoForm.logo,
        colors: this.logoForm.themeColor,
        leveTemplatesId: this.logoForm.risk,
        receiptTemplatesId: this.logoForm.receipt,
        dashboardTemplatesId: this.logoForm.dashboard,
        kpiTemplatesId: this.logoForm.kpi,
        kpiDashboardTemplatesId: this.logoForm.kpiDashboard,
      }
      SaveOrganizationLayout(param).then(res => {
        if (res.success) {
          this.$refs.organizations.getTableData();
          this.$message.success(this.$i18n.t('message.save'));
        }
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.orgTab = permission.includes('OrganizationsView');
        this.permit.dataTab = permission.includes('AccessGroupsView');
        this.tabActive = this.permit.orgTab ? 'Organizations' : 'DataAccessGroups';

        this.permit.editLogo = permission.includes('OrganizationsEditLogo');
        this.permit.editThemeColors = permission.includes('OrganizationsEditThemeColors');
        this.permit.shareOrganizationChart = permission.includes('OrganizationsShareOrganizationChart');
        this.permit.editOrganizationChart = permission.includes('OrganizationsEditOrganizationChart');
        this.permit.delOrganizationChart = permission.includes('OrganizationsDeleteOrganizationChart');
        this.permit.editRiskLevelTemplates = permission.includes('OrganizationsEditRiskLevelTemplates');
        this.permit.editReceiptTemplates = permission.includes('OrganizationsEditReceiptTemplates');
        this.permit.editOverviewDashboardTemplates = permission.includes('OrganizationsEditOverviewDashboardTemplates');
        this.permit.editKPITemplates = permission.includes('OrganizationsEditKPITemplates');
        this.permit.editKPIDashboardTemplates = permission.includes('OrganizationsEditKPIDashboardTemplates');
        this.permit.save = permission.includes('OrganizationsSubmitarequesttochangesettings')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.top-row {
  height: 50%;
  margin-bottom: 10px;
}

.bottom-row {
  height: calc(50% - 10px);
  .el-col {
    height: 100%;
  }
}
.full-height {
  height: 100%;
}
.tree-wrap {
  .btn-wrap {
    .el-button {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.title {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
  margin-bottom: 10px;
}

.form-wrap,
.el-tree {
  height: calc(100% - 54px - 10px);
  overflow: auto;
}
.layout-wrap {
  .form-wrap {
    width: 100%;
    .form-item {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
    }
    .label {
      width: 250px;
      height: 36px;
      line-height: 36px;
    }
    .el-select {
      width: calc(100% - 250px);
      margin-bottom: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .logo-box {
      width: calc(100% - 250px);

      .el-button {
        width: 100%;
        margin-bottom: 10px;
      }
      .logo {
        height: 150px;
        border: 1px solid #bcbcbc;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 220px;
        }
      }
    }
  }
  .btn-wrap {
    text-align: center;
  }
}
.theme-color {
  ::v-deep .el-input__inner {
    background: none;
    border-width: 0;
  }
}
.black {
  background-color: #1d2939;
}
.darkBrown {
  background-color: #3e2723;
}
.darkBlue {
  background-color: #111755;
}
.blue {
  background-color: #2b579a;
}
.purple {
  background-color: #80397b;
}
.orange {
  background-color: #b7472a;
}
.brown {
  background-color: #845900;
}
.darkGreen {
  background-color: #006a5e;
}
.green {
  background-color: #217346;
}
.lightGreen {
  background-color: #527900;
}
.grey {
  background-color: #f2f2f2;
}

.el-form {
  .el-select {
    width: 100%;
  }
}
</style>
