<template>
  <div
    v-show="dialog.visible"
    class="menu-box"
    @click.stop
  >
    <div class="header">
      <span>{{dialog.title}}</span>
      <i
        class="el-icon-close close-btn"
        @click="close"
      ></i>
    </div>
    <div class="btn-box">
      <el-button
        :disabled="!data.applicationFormId"
        size="medium"
        type="primary"
        @click="transfer(0)"
      >购物申请单</el-button>

      <el-popover
        placement="right"
        trigger="manual"
        v-model="orderVisible"
      >
        <ul class="orderDate">
          <li
            v-for="(item,index) in data.orderNumber"
            :key="index"
            @click="transferOrder(data.orderFormId[index])"
          >{{item}}</li>
        </ul>
        <el-button
          slot="reference"
          :disabled="data.orderNumber && data.orderNumber.length === 0"
          size="medium"
          type="primary"
          @click="transfer(1)"
        >采购订单</el-button>
      </el-popover>
      <el-button
        :disabled="!data.inCount && !data.outCount && !data.outCount2 && !data.outCount3"
        size="medium"
        type="primary"
        @click="transfer(2)"
      >出入库信息</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        title: '跳转',
      },
      orderVisible: false,
    }
  },
  watch: {
    'visible': function (newVal) {
      this.dialog.visible = this.visible;
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
        this.orderVisible = false;
      }
    },
  },
  created () {
  },
  methods: {
    close () {
      this.dialog.visible = false;
    },
    transfer (type) {
      switch (type) {
        case 0:
          this.$router.push({ path: '/home/allocateGoods', query: { id: this.data.applicationFormId, type: 2 } })
          break;
        case 1:
          this.orderVisible = !this.orderVisible;
          break;
        case 2:
          this.$router.push({
            path: '/home/deviceDetailsList',
            query: {
              id: this.data.deviceInfoId,
              applicationFormNumber: this.data.applicationNumber,
              projectContractId: this.data.projectContractId,
              type: 1
            }
          })
          break;
      }
      // this.dialog.visible = false;
    },
    transferOrder (id) {
      this.orderVisible = false;
      this.$router.push({ path: '/home/newOrder', query: { id: id, type: 1 } })
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-box {
  padding: 20px 40px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 999;
}
.header {
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.btn-box {
  text-align: center;
  .el-button {
    width: 100%;
    margin: 10px 0;
    display: block;
  }
}
.orderDate {
  margin: 0;
  padding: 0;
  li {
    height: 32px;
    line-height: 32px;
    color: #409eff;
    text-align: center;
    text-decoration: underline;
    list-style: none;
    cursor: pointer;
  }
}
</style>