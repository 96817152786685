<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <!-- <el-button
            size="medium"
            @click="printPdf"
          >{{$t('maintenance.record.btn1')}}</el-button>
          <el-button
            disabled
            size="medium"
          >{{$t('maintenance.record.btn2')}}</el-button> -->
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('maintenance.record.col1')"
          prop="createTime"
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('maintenance.record.col2')"
          prop="fileName"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          sortable
          :label="$t('maintenance.record.col3')"
          prop="branchName"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          :label="$t('maintenance.record.col4')"
          prop="engineerName"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          :label="$t('maintenance.record.col5')"
          prop="location"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click.stop="showView(scope.row)"
            >View</el-button>
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          :label="$t('maintenance.maintenance.col5')"
        >
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.templateEnable"
              :key="index"
              class="cell-style"
            >
              <span>
                {{item.name}}
              </span>
              <el-button
                size="mini"
                @click="printItem(scope.row.confirmOrderId, item)"
              >{{$t('operation.print')}}</el-button>
              <!-- <el-button
                size="mini"
                @click="exportItem(item)"
              >{{$t('operation.export')}}</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'

// 接口
import { GetRecord } from '@/api/warehouse/maintenance'
import { GetConfirmOrderItem } from '@/api/warehouse/serviceConfirmation'

export default {
  props: ['active', 'branch'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      userData: null,
      tableSelected: [],
      tableData: [{}],
      tableHeight: null,
    }
  },
  watch: {
    'branch': function () {
      if (this.active === 'Record') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
    'active': function () {
      if (this.active === 'Record') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
    '$route': function (to, from) {
      if (to.name === 'Maintenance' && this.active === 'Record') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);

        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
    this.dealPermission();
  },
  mounted () {
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        branchId: this.branch,
        isRecord: 1,
      }
      this.tableLoading = true;
      GetRecord(param).then(res => {
        this.tableData = res.list.map(item => {
          item.templateEnable = item.file.templateList.filter(item => !!item.isEnable)
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0])
    },
    dblclickEdit (e) {
      sessionStorage.fileData = JSON.stringify(e.file);
      this.$router.push({ path: '/home/maintenanceCheckList', query: { fileId: e.fileId, id: e.id, confirmOrderId: e.confirmOrderId, type: 1 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelDevice(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 打印
    printItem (confirmOrderId, item) {

      let param = { id: confirmOrderId }
      GetConfirmOrderItem(param).then(res => {
        let pdfData = JSON.parse(item.template);
        let data = res.maintenanceServiceConfirmOrderDetail || {};

        pdfData.page.forEach(page => {
          page.elements.forEach(el => {
            if (el.alt === '[ Site Name ]') el.content = res.branchName;
            if (el.alt === '[ Site Address ]') el.content = res.address;
            if (el.alt === '[ Engineer ]') el.content = data.serviceUserName;
            if (el.alt === '[ Phone Number ]') el.content = data.signatureCustomerUserPhone
            if (el.alt === '[ Processing Time ]') el.content = data.currentHandleStartTime + ' - ' + data.currentHandleEndTime;
            if (el.alt === '[ Processing Time (Y-M-D) ]') el.content = formatDate(new Date(data.currentHandleStartTime), 'yyyy-MM-dd') + ' - ' + formatDate(new Date(data.currentHandleEndTime), 'yyyy-MM-dd');
            if (el.alt === '[ Processing Start Time ]') el.content = data.currentHandleStartTime;
            if (el.alt === '[ Processing End Time ]') el.content = data.currentHandleEndTime;
            if (el.alt === '[ Description ]') el.content = describeAll.join('\n');
            if (el.alt === '[ Detail Process ]') el.content = disposeAll.join('\n');
            if (el.alt === '[ Signature ]') el.content = this.baseUrl + '/Engineer/getImage.do?imageId=' + data.signatureCustomerPictureName;
            if (el.alt === '[ Client Contact ]') el.content = data.signatureCustomerUserId;
            if (el.alt === '[ Jobtitle ]') el.content = data.signatureCustomerUserPost;
            if (el.alt === '[ Staff ID ]') el.content = data.signatureCustomerUserNo;
          })
        });

        sessionStorage.pdfData = JSON.stringify(pdfData)
        let { href } = this.$router.resolve({ path: `/pdf` })
        window.open(href, '_blank')
      })
    },
    // 导出
    exportItem () {

    },
    // 
    showView (e) {
      this.$router.push({ path: '/home/newServiceConfirmationForm', query: { id: e.confirmOrderId, type: 3 } })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        return;
        this.permit.search = permission.includes('DeviceManagementSearch');
        this.permit.add = permission.includes('DeviceManagementAdd');
        // this.permit.edit = permission.includes('DeviceManagementEdit');
        this.permit.del = permission.includes('DeviceManagementDelete');
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.cell-style {
  width: 100%;
  height: 28px;
  span {
    width: 100px;
    height: 28px;
    line-height: 28px;

    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-button {
    vertical-align: top;
  }
}
</style>
