import request from '@/utils/request'

// 查询系统类型列表
export const GetSystemTypeList = (data) => request({
  method: 'post',
  url: '/WarehouseSystemType/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改系统类型
export const AddSystemType = (data) => request({
  method: 'post',
  url: '/WarehouseSystemType/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除系统类型
export const DelSystemType = (data) => request({
  method: 'post',
  url: '/WarehouseSystemType/del.do',
  data: JSON.stringify(data)
})

// 查询系统类型已关联的设备类型列表
export const GetBySystemDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryBySysId.do',
  data: JSON.stringify(data)
})

// 查询系统类型未关联的设备类型列表
export const GetNoSystemDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryNoSysId.do',
  data: JSON.stringify(data)
})

// 关联系统类型与设备类型
export const AddSystemDevice = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypeDeviceType/add.do',
  data: JSON.stringify(data)
})

// 删除系统类型关联的设备类型
export const DelSystemDevice = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypeDeviceType/del.do',
  data: JSON.stringify(data)
})

// 获取系统类型集合
export const GetSystemTypeAll = (data) => request({
  method: 'post',
  url: '/WarehouseSystemType/queryByCondition.do',
  data: JSON.stringify(data)
})

// 查询配置类型列表
export const GetConfigTypeList = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypeConfigType/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改配置类型
export const AddConfigType = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypeConfigType/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除配置类型
export const DelConfigType = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypeConfigType/del.do',
  data: JSON.stringify(data)
})

// 根据设备类型获取系统类型
export const GetSystemTypeByDeviceType = (data) => request({
  method: 'post',
  url: '/WarehouseSystemType/queryByDeviceType.do',
  data: JSON.stringify(data)
})

// 查询故障现象列表
export const GetFaultPhenomenonList = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypePhenomenon/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改故障现象
export const AddFaultPhenomenon = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypePhenomenon/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除故障现象
export const DelFaultPhenomenon = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypePhenomenon/del.do',
  data: JSON.stringify(data)
})

// 获取故障现象集合
export const GetFaultPhenomenonAll = (data) => request({
  method: 'post',
  url: '/WarehouseSystemTypePhenomenon/queryByCondition.do',
  data: JSON.stringify(data)
})
