import Vue from 'vue'
import VueCookies from 'vue-cookies'
import router from './index'
import {
  logout
} from '@/utils/common'

router.beforeEach((to, from, next) => {
  let token = VueCookies.get('token');
  if (Vue.$httpRequestList.length > 0) { //强行中断时才向下执行
    Vue.$httpRequestList.forEach(item => {
      item('interrupt'); //给个标志，中断请求
    })
    Vue.$httpRequestList = [];
  }
  if ((!token || !localStorage.permission && !localStorage.isSuper) && to.fullPath !== '/login') {
    logout();
    next({
      path: '/login'
    })
  } else if (to.meta.permit === false) {
    next({
      name: 'Error404'
    })
  } else {
    next();
  }
})

router.afterEach(route => {})
