import request from '@/utils/request'

// 查询服务单
export const GetSubmitRequestList = (data) => request({
  method: 'post',
  url: '/WorkOrder/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增服务单 - 客户提交
export const AddSubmitRequest = (data) => request({
  method: 'post',
  url: '/WorkOrder/add.do',
  data: JSON.stringify(data)
})
// 删除服务单
export const DelSubmitRequest = (data) => request({
  method: 'post',
  url: '/WorkOrder/delete.do',
  data: JSON.stringify(data)
})

// 撤销服务单
export const RevokeSubmitRequest = (data) => request({
  method: 'post',
  url: '/WorkOrder/revoke.do',
  data: JSON.stringify(data)
})

// 获取服务单详情
export const GetSubmitRequestItem = (data) => request({
  method: 'post',
  url: '/WorkOrder/queryById.do',
  data: JSON.stringify(data)
})

// 派单
export const DistributeSubmitRequest = (data) => request({
  method: 'post',
  url: '/WorkOrder/distribute.do',
  data: JSON.stringify(data)
})

// 新增服务单 - 工程师提交
export const EngineerSubmit = (data) => request({
  method: 'post',
  url: '/WorkOrder/addMy.do',
  data: JSON.stringify(data)
})

// 投诉
export const Complaint = (data) => request({
  method: 'post',
  url: '/MaintenanceComplaintsAndSuggestions/add.do',
  data: JSON.stringify(data)
})

// 派单和状态更新详情查询
export const GetDistributeInfo = (data) => request({
  method: 'post',
  url: '/WorkOrder/queryByDispatchOrStatus.do',
  data: JSON.stringify(data)
})


// 确定服务单
export const ConfirmedSubmitRequest = (data) => request({
  method: 'post',
  url: '/WorkOrder/updateConfirmed.do',
  data: JSON.stringify(data)
})

// 关闭服务单
export const CloseSubmitRequest = (data) => request({
  method: 'post',
  url: '/WorkOrder/close.do',
  data: JSON.stringify(data)
})



// 获取客户编号
export const GetReference = (data) => request({
  method: 'post',
  url: '/MaintenanceWorkOrderNo/queryReference.do',
  data: JSON.stringify(data)
})
// 获取合同报价编号
export const GetQuotation = (data) => request({
  method: 'post',
  url: '/MaintenanceWorkOrderNo/queryQuotation.do',
  data: JSON.stringify(data)
})
// 获取采购订单号
export const GetPurchase = (data) => request({
  method: 'post',
  url: '/MaintenanceWorkOrderNo/queryPurchase.do',
  data: JSON.stringify(data)
})
