<template>
  <div class="wrap">
    <el-card>
      <el-tabs v-model="tabActive">
        <el-tab-pane
          label="订单"
          name="tab2"
        >
          <order-list :active="tabActive"></order-list>
        </el-tab-pane>
        <el-tab-pane
          label="状态跟踪"
          name="orderStatus"
        >
          <order-status
            type="order"
            :active="tabActive"
          ></order-status>
        </el-tab-pane>
        <el-tab-pane
          label="付款状态"
          name="tab3"
        >
        </el-tab-pane>
        <el-tab-pane
          label="撤销订单"
          name="tab4"
        >
          <order-cancel-list :active="tabActive"></order-cancel-list>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import OrderStatus from './components/OrderStatus'
import OrderList from './components/OrderList'
import OrderCancelList from './components/OrderCancelList'
// 接口
// import { GetLeveListAll } from '@/api/riskOfLossLevel'

export default {
  name: 'PurchaseOrders',
  components: {
    OrderStatus,
    OrderList,
    OrderCancelList,
  },
  data () {
    return {
      permit: {
      },
      tabActive: 'tab2',
    }
  },

  created () {
    this.dealPermission();
  },
  methods: {
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.orgTab = permission.includes('OrganizationsView');
        // this.permit.dataTab = permission.includes('AccessGroupsView');
        // this.tabActive = this.permit.orgTab ? 'Organizations' : 'DataAccessGroups';
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>
