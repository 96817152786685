<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <p>{{$t('stores.storeTree.label1')}}</p>
      <el-input
        size="small"
        v-model="searchVal"
      ></el-input>
      <el-tree
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        ref="tree"
        :filter-node-method="filterNode"
        highlight-current
      >
      </el-tree>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  MoveSite,
  MoveStores,
} from '@/api/stores'
export default {
  props: ['visible', 'data', 'moveIds', 'type'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '',
      },
      searchVal: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.title = this.type === 1 ? '移动节点' : this.$i18n.t('stores.storeTree.title1');
        this.dialog.visible = this.visible;
        this.treeData = this.data;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    searchVal (val) {
      this.$refs.tree.filter(val);
    }
  },
  created () {
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    submit () {
      // MoveSite
      let id = this.$refs.tree.getCurrentKey();
      let param;
      if (!id) return;
      if (this.type === 1) {
        // 移动店铺
        param = {
          id: id,
          storesId: this.moveIds,
        }
        MoveStores(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.edit'))
            this.dialog.visible = false;
            this.$emit('update')
          }
        })
      } else {
        // 移动网点
        param = {
          id: id,
          brandId: this.moveIds,
        }
        MoveSite(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.edit'))
            this.dialog.visible = false;
            this.$emit('update')
          }
        })
      }
    },



  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}

.el-tree {
  height: 500px;
  overflow: auto;
  margin-top: 10px;
}
</style>