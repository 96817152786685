<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">{{operator}}</div>
      <el-input
        class="search-input"
        size="small"
        v-model="searchVal"
        placeholder="Search"
        clearable
      ></el-input>
      <div class="option-box">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          node-key="id"
          ref="tree"
          show-checkbox
          @check-change="checkChange"
          v-loading="treeLoading"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
          >
            <span>{{ node.label }}</span>
            <span v-if="!!data.description">-</span>
            <span>{{ data.description }}</span>
          </span>
        </el-tree>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-button
            size="small"
            @click="selectAll"
          >{{selectType ? 'Unselect All' : 'Select All'}}</el-button>
        </el-col>
        <el-col
          :offset="8"
          :span="8"
        >
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 接口
import { GetStoreTree } from '@/api/stores'

export default {
  props: ['visible', 'zindex', 'data'],
  data () {
    return {
      title: 'Site Name',
      operator: 'In List',
      searchVal: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectType: true,
      treeLoading: false,
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initData();
        })
      }
    },
    'data': {
      handler: function (newVal, oldVal) {
        this.initData();
      },
      deep: true
    },
    searchVal (val) {
      this.$refs.tree.filter(val);
    },
  },
  created () {
    this.getTableData();
  },
  methods: {
    initData () {
      if (this.data.con === 'ALL') {
        this.$refs.tree.setCheckedNodes(this.treeData);
      } else {
        let stores = this.data.val.split(',');
        this.$refs.tree.setCheckedKeys(stores);
      }
    },
    getTableData () {
      let param = {
        type: '2'
      };
      this.treeLoading = true;
      GetStoreTree(param).then(res => {
        this.treeData = res;
        // this.$refs.table.reloadData(this.treeData);
        this.treeLoading = false;

        // this.$nextTick(() => {
        //   if (this.data.con === 'ALL') {
        //     this.$refs.table.toggleAllSelection();
        //   } else {
        //     let arr = [];
        //     this.treeData.forEach(row => {
        //       if (this.data.val.includes(row.id))
        //         arr.push({ row: row, selected: true })
        //     })
        //     this.$refs.table.toggleRowSelection(arr);
        //   }
        // })
      }).catch(error => {
        this.treeLoading = false;
      })

    },
    dialogClose () {
      this.$emit('update:visible', false);
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    submit () {
      let label = 'ALL';
      let stores = this.$refs.tree.getCheckedKeys();
      if (stores.length !== this.treeData.length) {
        label = this.$refs.tree.getCheckedNodes().map(item => item.label).join('、')
      }

      this.$emit('submit', Object.assign(this.data, {
        con: label,
        val: stores.join(',')
      }))
      this.dialogClose();
    },
    selectAll () {
      if (this.selectType) {
        this.$refs.tree.setCheckedNodes([]);
      } else {
        this.$refs.tree.setCheckedNodes(this.treeData);
      }
      this.selectType = !this.selectType;
    },
    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    checkChange (data, state) {
      if (state) {
        let stores = this.$refs.tree.getCheckedKeys();
        if (stores.length === this.treeData.length)
          this.selectType = true
      } else {
        this.selectType = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
</style>