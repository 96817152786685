import request from '@/utils/request'

// 查询设备列表
// export const GetDeviceList = (data) => request({
//   method: 'post',
//   url: '/Device/queryByPage.do',
//   data: JSON.stringify(data)
// })

// 新增和修改设备
// export const AddDevice = (data) => request({
//   method: 'post',
//   url: '/Device/addOrUpdate.do',
//   data: JSON.stringify(data)
// })

// 删除设备
export const DelDevice = (data) => request({
  method: 'post',
  url: '/SysDevice/delete.do',
  data: JSON.stringify(data)
})

// 批量设置二维码
export const BatchSetQR = (data) => request({
  method: 'post',
  url: '/Device/getQrCodeList.do',
  data: JSON.stringify(data)
})

// 单独分配二维码
export const SetQR = (data) => request({
  method: 'post',
  url: '/Device/genScanCode.do',
  data: JSON.stringify(data)
})

// 导入设备
export const ImprotDevice = (data) => request({
  method: 'post',
  // url: '/Device/improt.do',
  url: '/SysDevice/importData.do',
  data: data
})

// 保存二维码
export const SaveQR = (data) => request({
  method: 'post',
  url: '/Device/addCodes.do',
  data: data
})

// 导出PDF
export const ExportPDF = (data) => request({
  method: 'post',
  url: '/Device/exportPDF.do',
  data: data,
  responseType: 'arraybuffer'
})

// 导出二维码PDF
export const ExportQRCode = (data) => request({
  method: 'post',
  url: '/Device/exportBarCode.do',
  data: data,
  responseType: 'arraybuffer'
})

// 获取设备详情
export const GetDeviceItem = (data) => request({
  method: 'post',
  url: '/SysDevice/queryById.do',
  data: JSON.stringify(data)
})

// 预先打印二维码
export const ExportBeforehandBarCode = (data) => request({
  method: 'post',
  url: '/Device/exportBeforehandBarCode.do',
  data: JSON.stringify(data),
  responseType: 'arraybuffer'
})

// 索引打印
export const PrintIndex = (data) => request({
  method: 'post',
  url: '/Device/exportPdfEwm.do',
  data: JSON.stringify(data),
  responseType: 'arraybuffer'
})

// 库存设备二维码列表
export const InventoryQRcodeList = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryDevice/queryByPage.do',
  data: JSON.stringify(data),
})

// 批量添加库存设备二维码
export const AddInventoryQRcode = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryDevice/addOrUpdateList.do',
  data: JSON.stringify(data),
})

// 获取设备二维码列表
export const GetDeviceQRcodeList = (data) => request({
  method: 'post',
  url: '/Device/queryByNotIds.do',
  data: JSON.stringify(data)
})

// 获取库存设备列表
export const GetInventoryDeviceList = (data) => request({
  method: 'post',
  url: '/Device/queryByPage1.do',
  data: JSON.stringify(data)
})

// 根据网点查设备
export const GetDeviceList = (data) => request({
  method: 'post',
  url: '/SysDevice/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加、修改设备
export const AddDevice = (data) => request({
  method: 'post',
  url: '/SysDevice/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取设备位置
export const GetLocationAll = (data) => request({
  method: 'post',
  url: '/SysDevice/queryAllLocation.do',
  data: JSON.stringify(data)
})

// 批量更新设备信息
export const BatchEditDeviceInfo = (data) => request({
  method: 'post',
  url: '/SysDevice/updateData.do',
  data: JSON.stringify(data)
})

// 查询项目记录列表
export const GetProjectRecordList = (data) => request({
  method: 'post',
  url: '/ProjectRecord/queryByPage.do',
  data: JSON.stringify(data)
}) 

// 添加、修改项目记录
export const AddProjectRecord = (data) => request({
  method: 'post',
  url: '/ProjectRecord/addOrUpdate.do',
  data: JSON.stringify(data)
}) 

// 删除项目记录
export const DelProjectRecord = (data) => request({
  method: 'post',
  url: '/ProjectRecord/del.do',
  data: JSON.stringify(data)
}) 

// 查询购买记录列表
export const GetPurchaseRecordList = (data) => request({
  method: 'post',
  url: '/PurchaseRecord/queryByPage.do',
  data: JSON.stringify(data)
}) 

// 添加、修改购买记录
export const AddPurchaseRecord = (data) => request({
  method: 'post',
  url: '/PurchaseRecord/addOrUpdate.do',
  data: JSON.stringify(data)
}) 

// 删除购买记录
export const DelPurchaseRecord = (data) => request({
  method: 'post',
  url: '/PurchaseRecord/del.do  ',
  data: JSON.stringify(data)
}) 

// 合并设备
export const MergeDevice = (data) => request({
  method: 'post',
  url: '/SysDevice/mergeDevice.do',
  data: JSON.stringify(data)
})