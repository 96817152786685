<template>
  <div class="wrap">
    <el-form
      :model="groupForm"
      :rules="rules"
      ref="groupForm"
      class="group-form"
      size="medium"
      label-width="150px"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            :label="$t('newProjects.info.label1')"
            prop="project"
          >
            <my-select
              :data="projects"
              :value.sync="groupForm.project"
              size="small"
              :prop="{
                label:'name',
                value:'id'
              }"
              @change="projectChange"
            ></my-select>

            <!-- <el-select
              v-model="groupForm.project"
              filterable
              remote
              allow-create
              default-first-option
              :remote-method="queryProjectContract"
              @change="projectChange"
            >
              <el-option
                v-for="item in projects"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newProjects.info.label2')"
            prop="projectType"
          >
            <el-select
              :disabled="!isNewProjects"
              v-model="groupForm.projectType"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in projectTypes"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newProjects.info.label3')"
            prop="dataAccessGroup"
          >
            <el-select
              v-model="groupForm.dataAccessGroup"
              filterable
            >
              <el-option
                v-for="item in dataAccessGroups"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            :label="$t('newProjects.info.label4')"
            prop="projectDate"
          >
            <el-date-picker
              v-model="groupForm.projectDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              unlink-panels
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
        </el-col>
        <el-col
          :span="8"
          :offset="8"
        >
          <el-form-item
            :label="$t('newProjects.info.label5')"
            prop="offerCode"
          >
            <el-input
              v-model="groupForm.offerCode"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item
          :label="$t('newProjects.info.label6')"
          prop="remark"
        >
          <el-input
            v-model="groupForm.remark"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>

      </el-row>
    </el-form>

  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'

// 接口
import { GetDataAccessGroupAll } from '@/api/organizations'
import { GetProjectContractAll } from '@/api/warehouse/projectContract'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import {
  AddProjects,
  GetProjectsInfo,
} from '@/api/warehouse/projects'
export default {
  components: {
    MySelect
  },
  data () {
    return {
      AddBranchVisible: false,
      title: '',
      projects: [],
      projectTypes: [],
      dataAccessGroups: [],
      groupForm: {
        id: null,
        loading: false,
        project: '',
        projectName: '',
        projectType: '',
        dataAccessGroup: localStorage.accessGroupId,
        projectDate: null,
        offerCode: '',
        remark: '',
      },
      rules: {
        project: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // projectDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
  },
  computed: {
    isNewProjects () {

      return !/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.project);
    }
  },
  created () {
    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.getItem();
    }

    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
    // 获取项目类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000008' }).then(res => this.projectTypes = res)
    // 
    this.queryProjectContract('');
    // this.queryProjectType('');
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      GetProjectsInfo(this.groupForm.id).then(res => {
        Object.assign(this.groupForm, {
          project: res.contractId,
          projectName: res.name,
          projectType: res.projectTypeId,
          dataAccessGroup: res.accessGroupId,
          projectDate: [res.startDate, res.endDate],
          offerCode: res.contractNo,
          remark: res.notes,
        })
        if (this.isNewProjects) {
          this.groupForm.project = res.name;
        }
      })
    },
    projectChange (value) {
      if (!/.{8}(-.{4}){3}-.{12}/.test(value)) {
        this.groupForm.projectType = '';
        this.groupForm.offerCode = '';
      } else {
        let item = this.projects.find(item => item.id === value);
        this.groupForm.projectName = item.name;
        this.groupForm.projectType = item.projectTypeId;
        this.groupForm.offerCode = item.quotationNumber;
      }
    },

    // 查询项目
    queryProjectContract (query) {
      let param = { name: query };
      GetProjectContractAll(param).then(res => this.projects = res);
    },

    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        let param = {
          contractId: '',
          contractNo: this.groupForm.offerCode,
          projectTypeId: this.groupForm.projectType || '',
          name: '',
          startDate: !!this.groupForm.projectDate ? this.groupForm.projectDate[0] : null,
          endDate: !!this.groupForm.projectDate ? this.groupForm.projectDate[1] : null,
          notes: this.groupForm.remark,
          accessGroupId: this.groupForm.dataAccessGroup,
        }

        if (this.isNewProjects) {
          param.name = this.groupForm.project;
        } else {
          param.contractId = this.groupForm.project;
          param.name = this.groupForm.projectName;
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddProjects(param).then(res => {
          if (res) {
            this.$message.success(msg)
            this.back();
          }
          this.groupForm.loading = false;
        })
      })
    }

  }
}
</script>
<style lang="scss" scoped>
</style>