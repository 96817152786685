<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <query-conditions
        :data="queryConditions"
        :template-id="templateId"
        @submit="submitData"
      ></query-conditions>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryConditions from '@/pages/reportDesigner/components/QueryConditions'

export default {
  components: {
    QueryConditions
  },
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Query Conditions',
      },
      queryConditions: [],
      templateId: null,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.templateId = this.data.data.templateId;
        this.queryConditions = JSON.parse(this.data.data.queryConditions)
      }
      this.dialog.visible = this.visible;
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  methods: {
    submitData (data, queryConditions) {
      this.queryConditions = queryConditions;
    },
    submit () {
      this.$emit('submit', JSON.stringify(this.queryConditions))
      this.dialog.visible = false;
    },
  }
}
</script>

<style>
</style>