<template>
  <div
    class="pdf-main"
    id="content-box"
    style="display: none"
  >
    <div
      v-for="(page,pageIndex) in pdfData.page"
      :key="pageIndex"
      class="pdf-page"
      :style="{
            width: pdfData.width + 'px',
            height: pdfData.height + 'px',
            'padding-top':Math.round(pdfData.paddingTop * pdfData.rate) + 'px',
            'padding-bottom':Math.round(pdfData.paddingBottom * pdfData.rate) + 'px',
            'padding-left':Math.round(pdfData.paddingLeft * pdfData.rate) + 'px',
            'padding-right':Math.round(pdfData.paddingRight * pdfData.rate) + 'px',
          }"
    >
      <div
        data-op-type="block"
        class="pdf-page-content"
      >
        <!-- 页眉 -->
        <div
          v-if="pdfData.header.show"
          class="pdf-header "
          :style="{
                height: pdfData.header.height + 'px',
              }"
        >
          <div class="pdf-content">
            <div
              v-show="!el.hide"
              v-for="(el,elIndex) in pdfData.header.elements"
              :key="elIndex"
              class="pdf-el-box"
              :style="{
                    left :el.style.left + 'px',
                    top :el.style.top + 'px',
                    width :el.style.width + 'px',
                    height :el.style.height + 'px',
                  }"
            >
              <component
                :is="el.name"
                :el-data="el"
                :index="pageIndex"
              ></component>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="pdf-content">
          <div
            v-show="!el.hide"
            v-for="(el,elIndex) in page.elements"
            :key="elIndex"
            class="pdf-el-box"
            :style="{
              left :el.style.left + 'px',
              top :el.style.top + 'px',
              width :el.style.width + 'px',
              height :el.style.height + 'px',
            }"
          >
            <component
              :is="el.name"
              :el-data="el"
              :index="pageIndex"
            ></component>
          </div>
        </div>
        <!-- 页脚 -->
        <div
          v-if="pdfData.footer.show"
          class="pdf-footer"
          :style="{
                height :pdfData.footer.height + 'px',
              }"
        >
          <div class="pdf-content">
            <div
              v-show="!el.hide"
              v-for="(el,elIndex) in pdfData.footer.elements"
              :key="elIndex"
              class="pdf-el-box"
              :style="{
                  left :el.style.left + 'px',
                  top :el.style.top + 'px',
                  width :el.style.width + 'px',
                  height :el.style.height + 'px',
                }"
            >
              <component
                :is="el.name"
                :el-data="el"
                :index="pageIndex"
              ></component>
            </div>
          </div>
        </div>
        <div
          v-show="pdfData.pageIndex.show"
          class="page-index"
          :style="{
                  'text-align':pdfData.pageIndex.style.textAlign,
                }"
        >{{pageIndex + 1}}/{{pdfData.page.length}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// 插件
import domtoimage from 'dom-to-image';
// 组件
import TextLabel from './components/TextLabel'
import ImageLabel from './components/ImageLabel'
import TableLabel from './components/TableLabel'
import IndexLabel from './components/IndexLabel'
export default {
  name: 'Pdf',
  components: {
    TextLabel,
    ImageLabel,
    TableLabel,
    IndexLabel,
  },
  data () {
    return {
      pdfData: {},

    }
  },
  created () {


    this.addScript();
  },
  mounted () {
    if (!sessionStorage.pdfData) {
      this.$router.push({ path: '/404' })

    } else {
      this.pdfData = JSON.parse(sessionStorage.pdfData);
      let orientation = this.pdfData.direction === 0 ? 'portrait' : 'landscape';
      window.bookConfig = {
        pageSize: 'A4',
        orientation: orientation,
        padding: "0mm 0mm 0mm 0mm",
        // simplePageNum: {
        //   pageBegin: 0,
        //   pageEnd: -1,
        //   pendant: '<div class="page-num-simple"><span style="">第${PAGE}页 / 共${TOTAL_PAGE}页</span></div>',
        // },
        toolBar: {
          serverPrint: false,
          webPrint: true,
          saveHtml: false,
        },
        start: false,
      }

      this.$nextTick(() => {
        window.bookConfig.start = true;
        setTimeout(() => {
          document.querySelector('.nop-page-items').children[0].remove();
          this.pdfData.page.forEach(item => {
            item.elements.forEach(item => {
              if (item.name === 'TableLabel' && item.originData.length !== 0) {
                this.asyncTableCol(item.id);
              }
            })
          });
        }, 1000);
      })
    }
  },
  methods: {
    addScript () {
      let plugin = [
        '/static/plugin/polyfill.min.js',
        '/static/plugin/jquery.min.js',
        '/static/plugin/lodash.min.js',
        '/static/plugin/bookjs-eazy.min.js',
      ]
      if (process.env.NODE_ENV === 'production') {
        plugin = plugin.map(item => `/serviceSupport${item}`)
      }
      plugin.forEach(item => {
        let script = document.createElement('script');
        script.src = item;
        document.head.append(script);
      })
    },
    // 同步表格列宽
    asyncTableCol (id) {
      let els = Array.from(document.querySelectorAll(`.table${id}`));

      let originTable = els.shift();
      els.forEach(item => {
        for (let i = 0; i < item.rows[0].cells.length; i++) {
          item.rows[0].cells[i].style.width = originTable.rows[1].cells[i].offsetWidth + 'px'
        }
      })
    },
  }
}
</script>
<style >
.pdf-main {
  /* width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth; */
}
.pdf-page {
  /* margin: 0 auto; */
  background-color: #fff;
  /* background-color: rgb(123, 180, 255); */
}

.pdf-page-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.pdf-el-box {
  position: absolute;
}

.pdf-header,
.pdf-footer {
  width: 100%;
  flex-shrink: 0;
}
.pdf-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.page-index {
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  position: absolute;
  bottom: -24px;
}
</style>
