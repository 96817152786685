import { render, staticRenderFns } from "./FolderDialog.vue?vue&type=template&id=724f12cb&scoped=true&"
import script from "./FolderDialog.vue?vue&type=script&lang=js&"
export * from "./FolderDialog.vue?vue&type=script&lang=js&"
import style0 from "./FolderDialog.vue?vue&type=style&index=0&id=724f12cb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724f12cb",
  null
  
)

export default component.exports