<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-row>
        <el-input
          size="small"
          v-model="searchVal"
        ></el-input>
      </el-row>
      <el-tree
        ref="tree"
        :data="treeData"
        node-key="id"
        :props="defaultProps"
        :filter-node-method="filterNode"
        highlight-current
      ></el-tree>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetTaskTree2 } from '@/api/warehouse/projects'
export default {
  props: ['visible'],
  data () {
    return {
      projectsId: null,
      searchVal: '',
      dialog: {
        visible: false,
        loading: false,
        title: this.$i18n.t('newProjects.ganttChart.task.title5'),
      },

      treeData: [],
      defaultProps: {
        children: 'childrens',
        label: 'name'
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.getTreeData();

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    searchVal (val) {
      this.$refs.tree.filter(val);
    }
  },
  created () {
    this.projectsId = this.$route.query.id
  },
  methods: {
    // 获取数据
    getTreeData () {
      GetTaskTree2(this.projectsId).then(res => {
        this.treeData = res;
      })
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 提交
    submit () {
      let data = this.$refs.tree.getCurrentNode();
      if (!data) return this.$message.warning('Please select at least one item.');

      this.$emit('submit', data);
      this.dialog.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  .el-switch {
    width: 100px;
  }
}
</style>