<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">{{operator}}</div>
      <el-input
        class="search-input"
        size="small"
        v-model="searchVal"
        placeholder="Search"
        clearable
        @input="searchData"
      ></el-input>
      <div
        class="table-wrap"
        ref="tableWrap"
      >
        <u-table
          ref="table"
          :height="tableHeight"
          :row-height="30"
          row-key="id"
          use-virtual
          @selection-change="val => tableSelected = val"
        >
          <u-table-column
            type="selection"
            reserve-selection
          ></u-table-column>

          <u-table-column
            :label="title"
            prop="name"
          ></u-table-column>
        </u-table>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">

        <el-col
          :span="8"
          :offset="8"
        >
          <el-button
            size="small"
            @click="showQuery"
          >Detail</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 接口
import { GetEngineerAll } from '@/api/warehouse/engineerManagement'

export default {
  props: ['visible', 'zindex', 'data'],
  data () {
    return {
      treeLoading: false,
      operator: 'In List',
      searchVal: '',
      tableSelected: [],
      treeData: [],
      tableLoading: false,

      title: '',
      storeCount: 0,
      selectType: false,

      tableHeight: null,
      all: false,
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tableWrap.offsetHeight;
          // this.$refs.table.reloadData(this.treeData);
          this.initData();
        })
      }
    },
    'data': {
      handler: function (newVal, oldVal) {
        this.initData();
      },
      deep: true
    },
  },
  created () {
  },
  methods: {
    initData () {
      let result
      this.title = this.data.data.field;
      this.operator = this.$global.typeOperatorsText[this.data.data.fieldType][this.data.data.operator];
      this.getTableData();
    },
    getTableData () {
      this.tableLoading = true;
      GetEngineerAll({}).then(res => {
        this.treeData = res;
        this.$refs.table.reloadData(this.treeData);

        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })

    },

    dialogClose () {
      this.$emit('update:visible', false);
    },
    submit () {

      let label = this.tableSelected.length !== 0 ? this.tableSelected.map(item => item.name).join(',') : 'Please Select one';
      let condition = this.tableSelected.map(item => item.id).join(',');

      this.$emit('submit', Object.assign(this.data, {
        con: label,
        data: Object.assign(this.data.data, {
          operator: 0,
          condition: condition,
        })
      }))
      this.dialogClose();
    },
    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    showQuery () {
      this.$emit('showQuery');
    },

    searchData: debounce(function () {
      let result = this.treeData.filter(item => {
        if (!!this.searchVal) {
          if (!!item.name) {
            return item.name.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) !== -1
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      this.$refs.table.reloadData(result);
    }, 100),
  }
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
.table-wrap {
  height: calc(100% - 26px - 37px);

  ::v-deep th,
  ::v-deep td {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px !important;
  }
  ::v-deep th {
    // border: 1px solid #5072a62e;
  }
  ::v-deep td {
    border: none;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>