<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">{{operator}}</div>
      <el-input
        v-show="data.data.operator !== 2"
        class="search-input"
        size="small"
        v-model="condition"
        placeholder="Value"
        clearable
      ></el-input>
      <div
        v-show="data.data.operator === 2"
        class="range-box"
      >
        <el-input
          class="range-input"
          v-model="range.start"
          size="small"
        ></el-input>
        <span class="range-line"></span>
        <el-input
          class="range-input"
          v-model="range.end"
          size="small"
        ></el-input>
      </div>
      <div class="option-box">
        <div
          v-for="(item,index) in listData"
          :key="index"
          class="date-item"
          :class="{'active':index == type}"
          @click="selectType(index)"
        >{{item.data.operator !== 2 ? item.data.condition : item.data.range.start + ' - ' + item.data.range.end}}</div>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <!-- <el-col :span="8">
          <el-button
            size="small"
            @click="selectAll"
          >Select All</el-button>
        </el-col> -->
        <el-col
          :offset="8"
          :span="8"
        >
          <el-button
            size="small"
            @click="showQuery"
          >Detail</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  props: ['visible', 'zindex', 'data'],
  data () {
    return {
      title: '',
      operator: 'Greater than',
      condition: '',
      range: {
        start: '',
        end: '',
      },
      listData: [],
      type: null,
    }
  },

  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.initData();
      },
      deep: true
    },
    '$store.state.filterRequestHistory': {
      handler: function (newVal) {
        let label = this.data.label;
        let result = this.$store.state.filterRequestHistory
          .filter(item => item.name == label)
          .map(item => item.condition);
        let setArr = new Set(result);
        this.listData = Array.from(setArr).map(item => JSON.parse(item))
      },
      deep: true
    }

  },
  created () {

    this.initData();
  },
  methods: {
    initData () {
      this.title = this.data.data.field;
      this.condition = this.data.data.condition;
      this.range = this.data.data.range;
      this.operator = this.$global.typeOperatorsText[this.data.data.fieldType][this.data.data.operator];
      this.type = null;

      let label = this.data.label;
      let result = this.$store.state.filterRequestHistory
        .filter(item => item.name == label)
        .map(item => item.condition);
      let setArr = new Set(result);
      this.listData = Array.from(setArr).map(item => JSON.parse(item))
    },
    dialogClose () {
      this.$emit('update:visible', false);
    },
    selectType (index) {
      this.type = this.type === index ? null : index;
      // this.$emit('submit', Object.assign(this.data, item), 1);
    },
    submit () {
      let result
      if (this.type !== null) {
        result = this.listData[this.type];
      } else {
        let label = this.data.data.operator !== 2 ? this.condition : `${this.range.start} - ${this.range.end}`;
        result = Object.assign(this.data, {
          con: label,
          data: Object.assign(this.data.data, {
            condition: this.condition,
            range: this.range,
          })
        })
      }
      this.$emit('submit', result);
      this.dialogClose();
    },

    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    showQuery () {
      this.$emit('showQuery');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
.range-box {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  .range-line {
    width: 15px;
    border-bottom: 1px solid #000;
    margin: 0 5px;
  }
}
</style>