<template>
  <div>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="!permit.editInfo"
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <!-- <el-button
            :disabled="!permit.editInfo"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button> -->
          <el-button
            :disabled="!permit.editInfo"
            size="medium"
            @click="printPdf"
          >{{$t('newServiceConfirmationForm.tab7.btn1')}}</el-button>
          <el-button
            :disabled="!permit.editInfo"
            size="medium"
            @click="exportItem"
          >{{$t('newServiceConfirmationForm.tab7.btn2')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-table
      border
      ref="table"
      :data="tableData"
      :row-class-name="tableRowClassName"
      @selection-change="val => tableSelected = val"
      @row-dblclick="dblclickEdit"
      @row-click="rowClick"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('newServiceConfirmationForm.tab7.col1')"
        :formatter="$formatTableData"
      >
      </el-table-column>
      <el-table-column
        prop="fileName"
        :label="$t('newServiceConfirmationForm.tab7.col2')"
      >
      </el-table-column>
      <el-table-column
        prop="engineerName"
        :label="$t('newServiceConfirmationForm.tab7.col3')"
      >
      </el-table-column>
    </el-table>
    <!-- 现场维修设备 -->
    <installation-dialog
      :visible.sync="InstallationDialogVisble"
      :data="maintenanceData"
      :describe="describe"
      :dispose="dispose"
      :branch-id="branchId"
      @submit="maintenanceSubmit"
    ></installation-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import InstallationDialog from './InstallationDialog'
// 接口
import { GetConfirmOrderItem } from '@/api/warehouse/serviceConfirmation'

export default {
  components: {
    InstallationDialog
  },
  props: ['data', 'permit', 'describe', 'dispose', 'branchId'],
  data () {
    return {
      InstallationDialogVisble: false,

      searchVal: '',
      tableSelected: [],
      tableData: [],
      maintenanceData: null,
    }
  },
  watch: {
    'data': function (newVal) {
      this.tableData = newVal.map(item => {
        let template = item.file.templateList.find(item => !!item.isEnable)
        item.templateName = !!template ? template.name : '';
        item.templateData = !!template ? template.template : '';
        return item;
      });;
    }
  },
  methods: {
    searchData () {

    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0])
    },
    dblclickEdit (e) {
      sessionStorage.fileData = JSON.stringify(e.file);
      this.$router.push({ path: '/home/maintenanceCheckList', query: { fileId: e.fileId, id: e.id, confirmOrderId: e.confirmOrderId, type: 1 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
      }).catch(() => { });
    },
    // 新增现场维修设备
    maintenanceSubmit ({ data, type }) {
      switch (type) {
        case 'add':
          this.tableData.push(data);
          break
        case 'edit':
          this.$set(this.tableData, data.rowIndex, data);
          break
      }
    },
    // 打印
    printPdf () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (!this.tableSelected[0].templateName) return this.$message.warning('未绑定模板.');

      let param = { id: this.tableSelected[0].confirmOrderId }
      GetConfirmOrderItem(param).then(res => {
        let pdfData = JSON.parse(this.tableSelected[0].templateData);
        let data = res.maintenanceServiceConfirmOrderDetail || {};

        pdfData.page.forEach(page => {
          page.elements.forEach(el => {
            if (el.alt === '[ Site Name ]') el.content = res.branchName;
            if (el.alt === '[ Site Address ]') el.content = res.address;
            if (el.alt === '[ Engineer ]') el.content = data.serviceUserName;
            if (el.alt === '[ Phone Number ]') el.content = data.signatureCustomerUserPhone
            if (el.alt === '[ Processing Time ]') el.content = data.currentHandleStartTime + ' - ' + data.currentHandleEndTime;
            if (el.alt === '[ Processing Time (Y-M-D) ]') el.content = formatDate(new Date(data.currentHandleStartTime), 'yyyy-MM-dd') + ' - ' + formatDate(new Date(data.currentHandleEndTime), 'yyyy-MM-dd');
            if (el.alt === '[ Processing Start Time ]') el.content = data.currentHandleStartTime;
            if (el.alt === '[ Processing End Time ]') el.content = data.currentHandleEndTime;
            if (el.alt === '[ Description ]') el.content = describeAll.join('\n');
            if (el.alt === '[ Detail Process ]') el.content = disposeAll.join('\n');
            if (el.alt === '[ Signature ]') el.content = this.baseUrl + '/Engineer/getImage.do?imageId=' + data.signatureCustomerPictureName;
            if (el.alt === '[ Client Contact ]') el.content = data.signatureCustomerUserId;
            if (el.alt === '[ Jobtitle ]') el.content = data.signatureCustomerUserPost;
            if (el.alt === '[ Staff ID ]') el.content = data.signatureCustomerUserNo;
          })
        });

        sessionStorage.pdfData = JSON.stringify(pdfData)
        let { href } = this.$router.resolve({ path: `/pdf` })
        window.open(href, '_blank')
      })
    },
    exportItem () {

    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  width: auto !important;
}
</style>