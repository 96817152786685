<template>
  <div
    class="model-wrap"
    ref="modelWrap"
  >
    <el-row>
      <div
        class="label-item"
        style="width:50%"
      >
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
      </div>
      <div
        class="label-item"
        style="width:50%"
      >
        <label>{{$t('dataMaintenance.supplier.abbreviation')}} :{{abbreviation}}</label>
      </div>
    </el-row>
    <el-tabs
      class="tab-wrap"
      v-model="activeName"
      type="card"
    >
      <el-tab-pane
        class="tab-pane"
        :label="$t('dataMaintenance.supplier.tab1')"
        name="tab1"
      >
        <supply-contact
          :items="items"
          :table-height="tableHeight"
        ></supply-contact>
      </el-tab-pane>
      <el-tab-pane
        class="tab-pane"
        :label="$t('dataMaintenance.supplier.tab2')"
        name="tab2"
      >
        <supply-equipment
          :items="items"
          :table-height="tableHeight"
        ></supply-equipment>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SupplyContact from './SupplyContact'
import SupplyEquipment from './SupplyEquipment'
export default {
  props: ['items'],
  // props: ['items', 'countProp'],
  components: {
    SupplyContact,
    SupplyEquipment,
  },
  data () {
    return {
      activeName: 'tab1',
      name: '',
      abbreviation: '',
      tableHeight: null,
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.name = '';
        this.abbreviation = '';
      } else {
        this.name = this.items.length === 1 ? this.items[0].name : `${this.items[0].name}…`
        this.abbreviation = this.items.length === 1 ? this.items[0].abbreviation : `${this.items[0].abbreviation}…`
      }
    },
    // 'activeName': function () {
    //   if (this.activeName === 'tab1') {
    //     this.$emit('update:countProp', 'count');
    //   } else {
    //     this.$emit('update:countProp', 'count2');
    //   }
    // },
  },
  mounted () {
    this.tableHeight = this.$refs.modelWrap.clientHeight - 46 - 56 - 46 - 42
  },
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";

.model-wrap {
  height: calc(100% - 55px);
}
</style>