<template>
  <div>
    <el-table
      :data="data"
      @row-dblclick="rowDblclick"
    >
      <el-table-column
        min-width="150"
        :label="$t('newDevice.replace.col1')"
        sortable
        prop="createTime"
      >
      </el-table-column>
      <el-table-column
        min-width="220"
        :label="$t('newDevice.replace.col2')"
        sortable
        prop="reasonName"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        :label="$t('newDevice.replace.col3')"
        sortable
        prop="faultTypeName"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        :label="$t('newDevice.replace.col4')"
        sortable
        prop="oldQrcode"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        :label="$t('newDevice.replace.col5')"
        sortable
        prop="oldSerialNumber"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        :label="$t('newDevice.replace.col6')"
        sortable
        prop="newQrcode"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        :label="$t('newDevice.replace.col7')"
        sortable
        prop="newSerialNumber"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        :label="$t('newDevice.replace.col8')"
        sortable
        prop="installationSite"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        :label="$t('newDevice.replace.col9')"
        sortable
        prop="deviceSupplier"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        :label="$t('newDevice.replace.col10')"
        sortable
        prop="remark"
      >
      </el-table-column>
      <el-table-column
        min-width="220"
        :label="$t('newDevice.replace.col11')"
        sortable
        prop="number"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {

    }
  },
  methods: {
    rowDblclick (row) {
      this.$router.push({ path: '/home/newServiceConfirmationForm', query: { id: row.confirmOrderId, type: 1 } })
    }
  }
}
</script>

<style>
</style>