import request from '@/utils/request'

// 查询邮箱配置
export const GetSMTP = (data) => request({
  method: 'post',
  url: '/SysServerSettings/getTop1.do',
  data: JSON.stringify(data)
})

// 保存邮箱配置
export const SaveSMTP = (data) => request({
  method: 'post',
  url: '/SysServerSettings/addOrUpdate.do',
  data: JSON.stringify(data)
})
