<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      center
      width="500px"
    >
      <el-row>
        <el-table
          border
          :row-class-name="tableRowClassName"
          :data="tableData"
        >
          <el-table-column
            :width="100"
            :label="$t('newSubmitRequest.number.col1')"
            type="index"
          ></el-table-column>
          <el-table-column
            :label="colName"
            prop="id"
          >
            <template slot-scope="scope">
              <div class="input-box">

                <my-select
                  class="my-select"
                  :data="selectData"
                  :value.sync="scope.row.id"
                  size="mini"
                  :prop="{
                    label:'name',
                    value:'id'
                  }"
                ></my-select>
                <el-button
                  icon="el-icon-delete"
                  size="mini"
                  type="danger"
                  @click="delItem(scope.row.rowIndex)"
                ></el-button>
                <el-button
                  icon="el-icon-plus"
                  size="mini"
                  type="success"
                  @click="addItem(scope.row.rowIndex)"
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import {
  GetReference,
  GetQuotation,
  GetPurchase,
} from '@/api/warehouse/submitRequest'
export default {
  components: {
    MySelect
  },
  props: ['visible', 'data', 'type','branchId'],
  data () {
    return {
      dialogVisible: false,
      title: '',
      colName: '',
      searchVal: '',
      tableData: [],
      selectData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        if (this.data.length === 0) {
          this.tableData = [{
            name: '',
            id: '',
          }];
        } else {
          this.tableData = JSON.parse(JSON.stringify(this.data));
        }
        switch (this.type) {
          case 1:
            GetReference({}).then(res => this.selectData = res.map(item => {
              if (!!item.name) {
                item.name = `${item.number}-${item.name}`;
              } else {
                item.name = item.number;
              }
              return item;
            }));
            this.title = this.$i18n.t('newSubmitRequest.number.title1');
            this.colName = this.$i18n.t('newSubmitRequest.number.col2');
            break;
          case 2:
            GetQuotation({
              groupId: this.branchId
            }).then(res => this.selectData = res.map(item => {
              if (!!item.name) {
                item.name = `${item.number}-${item.name}`;
              } else {
                item.name = item.number;
              }
              return item;
            }));
            this.title = this.$i18n.t('newSubmitRequest.number.title2');
            this.colName = this.$i18n.t('newSubmitRequest.number.col3');
            break;
          case 3:
            GetPurchase({}).then(res => this.selectData = res.map(item => {
              if (!!item.name) {
                item.name = `${item.number}-${item.name}`;
              } else {
                item.name = item.number;
              }
              return item;
            }));
            this.title = this.$i18n.t('newSubmitRequest.number.title3');
            this.colName = this.$i18n.t('newSubmitRequest.number.col4');
            break;
        }
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 添加
    addItem (index) {
      this.tableData.splice(index + 1, 0, {
        name: '',
        id: '',
      });
    },
    // 删除
    delItem (index) {
      this.tableData.splice(index, 1);
    },
    // 提交序列号
    submit () {
      if (this.isRepetition()) return this.$message.warning('编号存在重复');
      if (this.tableData.some(item => item.id === '')) return this.$message.warning('有序列号未填');
      this.tableData.map(item => {
        let temp = this.selectData.find(select => select.id === item.id);
        item.name = !!temp ? temp.name : '';
        return item;
      })
      this.$emit(
        'submit',
        this.type,
        JSON.parse(JSON.stringify(this.tableData)),
      );
      this.dialogVisible = false;
    },
    // 检测序列号重复
    isRepetition () {
      let result = false;
      let numberId = this.tableData.map(item => item.id).join(';');
      result = this.tableData.some(item => {
        let re = new RegExp(`(?<=^|;)${item.id}(?=;|$)`, 'g');
        return numberId.match(re).length > 1;
      })
      return result;
    },

    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  line-height: 36px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.input-box {
  display: flex;
  .my-select {
    margin-right: 10px;
  }
}
</style>