<template>
  <div
    class="wrap"
    v-resize="resize"
    id="dashBoardWrap"
    ref="wrap"
  >
    <el-row>
      <condition
        :layout-data="layoutData"
        :layout-param="layoutParam"
        :common-param="commonParam"
        @showLayout="showLayout"
        @submitLayout="submitLayout"
        @submit="submitParam"
        @closeEdit="closeEdit"
        @submitExport="submitExport"
      ></condition>
    </el-row>
    <div
      class="box"
      ref="box"
    >
      <grid-layout
        :layout="layoutData"
        :col-num="24"
        :rowHeight="baseLen"
        :margin="[10, 10]"
        :use-style-cursor="false"
        :use-css-transforms="false"
      >
        <grid-item
          drag-allow-from=".chart-card"
          drag-ignore-from=".con"
          v-for="(item,index) in layoutData"
          :key="item.id"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :class="{'collapse':item.h === 1}"
          @moved="movedEvent"
          @resized="resizedEvent"
          :isDraggable="true"
        >
          <layout-card
            ref="chartCard"
            :data="item"
            :common-param="commonParam"
            :cell-loading="item.loading"
            :index="index"
            @showDialog="showDialog"
            @cardClose="cardClose"
            @loadMore="loadMore"
            v-loading="item.loading"
          ></layout-card>
        </grid-item>
      </grid-layout>
    </div>

    <!-- <el-card
      class="add-card"
      @click.native="showLayout"
      v-loading="layoutLoading"
    >
      <i class="el-icon-plus add"></i>
    </el-card> -->
    <!-- 总览设置弹框 -->
    <overview
      :visible.sync="OverviewVisible"
      :layout-index="layoutIndex"
      :chart-id="chartId"
      @submit="submitOverview"
    ></overview>

    <!-- 新增格子 -->
    <add-layout
      :visible.sync="AddLayoutVisible"
      :layoutData="layoutData"
      @addLayout='addLayout'
    ></add-layout>

    <!-- 图表条件弹框 -->
    <query-dialog
      :visible.sync="QueryDialogVisible"
      :data="layoutData[layoutIndex]"
      @submit="submitQuery"
    ></query-dialog>
    <!-- 图表排序弹框 -->
    <sorting-dialog
      :visible.sync="SortingDialogVisible"
      :data="layoutData[layoutIndex]"
      @submit="submitSorting"
    ></sorting-dialog>
  </div>

</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 插件
import VueGridLayout from 'vue-grid-layout'
import domtoimage from 'dom-to-image';

// 组件
import Overview from '@/components/DashBoardDialog/Overview'
import Condition from './components/Condition'
import LayoutCard from './components/LayoutCard'
import AddLayout from './components/AddLayout'
import QueryDialog from './components/QueryDialog'
import SortingDialog from './components/SortingDialog'

// 接口
import { GetPanelData, GetLayout } from '@/api/dashboard'
import { GetChart } from '@/api/reportDesigner'
import { GetReportData } from '@/api/counterMode'

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Overview,
    Condition,
    LayoutCard,
    AddLayout,
    QueryDialog,
    SortingDialog,
  },
  name: 'DashBoard',
  data () {
    return {
      SortingDialogVisible: false,
      QueryDialogVisible: false,
      OverviewVisible: false,
      AddLayoutVisible: false,
      baseLen: 0,
      chartId: null,
      layoutIndex: null,
      // layoutLoading: false,
      layoutData: [],
      layoutParam: null,
      clock: null,
      serialNum: 1,
      commonParam: [{
        mark: 'BU',
        prop: 'branchIds',
        label: 'Site Name',
        con: 'ALL',
        val: ''
      }, {
        "prop": "Date Time",
        "label": "Date",
        // "con":'',
        "con": "2020-10-22 00:00:00~2020-10-22 23:59:59",
        "data": {
          "visible": true,
          "activeTab": "New Query",
          "relation": "and",
          "queryType": 0,
          "field": "Date",
          "prop": "Date Time",
          "invert": false,
          "operator": 0,
          // "condition": '',
          "condition": 13,
          "range": {
            "start": "",
            "end": ""
          },
          // "dateRange":[],
          "dateRange": [
            "2020-10-22 00:00:00",
            "2020-10-22 23:59:59"
          ],
          "list": [],
          "description": "",
          "fieldType": "date"
        }
      }, {
        "prop": "branch_code",
        "label": "Branch Code",
        "con": "",
        "data": {
          "visible": true,
          "activeTab": "New Query",
          "relation": "and",
          "queryType": 0,
          "field": "Branch Code",
          "prop": "branch_code",
          "invert": false,
          "operator": 0,
          "condition": "",
          "range": {
            "start": "",
            "end": ""
          },
          "dateRange": [],
          "list": [],
          "description": "",
          "fieldType": "stores"
        }
      }],
      idNum: 0,
    }
  },
  watch: {

  },
  created () {
    window.addEventListener("resize", () => {
    });
  },
  mounted () {
    this.getLayout();

    let tempBaseLen = Math.floor((this.$refs.box.clientWidth - 10 * 25) / 24);
    this.baseLen = tempBaseLen;
  },
  methods: {
    resize: debounce(function () {
      let tempBaseLen = Math.floor((this.$refs.box.clientWidth - 10 * 25) / 24);

      if (tempBaseLen > 0) {
        this.baseLen = tempBaseLen

        var myEvent = new Event('resize');
        window.dispatchEvent(myEvent);
      }
    }, 100),
    getLayout () {
      GetLayout().then(res => {
        if (res === '') {
          res = { layoutJson: '[]' };
        }
        this.submitLayout(1, res);
      })
    },
    getlayoutChart (param) {
      return new Promise((resolve, reject) => {
        GetChart(param.chartId).then(res => {
          if (!!res) {
            let index = this.layoutData.findIndex(item => item.i === param.i);
            this.layoutData[index].data = res;
            resolve()
          } else {
            resolve()
          }
        })
      })
    },

    // 显示增加单元
    showLayout () {
      this.AddLayoutVisible = true;
    },
    // 绘制图片
    drawCard () {
      clearTimeout(this.clock)
      this.clock = setTimeout(() => {
        domtoimage.toPng(this.$refs.box).then((dataUrl) => {
          let gridArr = Array.from(document.querySelectorAll('.vue-grid-item'));
          let screenshot = new Image();
          screenshot.src = dataUrl;
          screenshot.onload = () => {
            var tnCanvas = document.createElement('canvas');
            var tnCanvasContext = tnCanvas.getContext('2d');
            var bufferCanvas = document.createElement('canvas');
            var bufferContext = bufferCanvas.getContext('2d');
            bufferCanvas.width = screenshot.naturalWidth;
            bufferCanvas.height = screenshot.naturalHeight;
            bufferContext.drawImage(screenshot, 0, 0);
            gridArr.forEach((item, index) => {
              if (item.clientWidth === 0) return
              tnCanvas.width = item.clientWidth;
              tnCanvas.height = item.clientHeight;
              tnCanvasContext.drawImage(bufferCanvas, item.offsetLeft - 10, item.offsetTop, item.clientWidth, item.clientHeight, 0, 0, item.clientWidth, item.clientHeight);
              this.layoutData[index].img = tnCanvas.toDataURL();
            })
          }
        })
      }, 500)
    },
    // 新增单元
    addLayout (layout) {
      this.layoutData = JSON.parse(JSON.stringify(layout));
    },
    // 显示图表设置
    showDialog (type, param) {
      this.layoutIndex = param.layoutIndex;
      switch (type) {
        case 1:
          this.chartId = this.layoutData[this.layoutIndex].chartId;
          this.OverviewVisible = true;
          break;
        case 2:
          this.QueryDialogVisible = true;
          break;
        case 3:
          this.SortingDialogVisible = true;
          break;
      }
    },
    // 关闭卡片
    cardClose (index) {
      this.layoutData.splice(index, 1);
      // this.$nextTick(() => {
      //   this.$refs.chartCard.forEach(item => item.initData())
      // })
    },
    // 移动结束事件
    movedEvent () {
      // this.drawCard();
    },
    // 调整大小结束事件
    resizedEvent () {
      this.drawCard();
      this.resize();
    },
    // 提交报表配置
    submitOverview (type, data, index) {
      let item = Object.assign(this.layoutData[index], {
        data: data,
        chartId: data.id,
        cardType: type,
      })
      this.$set(this.layoutData, index, item)
      this.getData([this.layoutData[index].i])
      this.drawCard();
    },
    // 提交布局
    submitLayout (type, data) {
      if (type === 1) {
        this.layoutParam = data;
      }
      this.layoutData = JSON.parse(data.layoutJson).map(item => {
        item.id = this.idNum++;
        return item;
      });

      let arr = this.layoutData
        .filter(item => !!item.chartId)
        .map((item, index) => this.getlayoutChart({
          i: item.i,
          chartId: item.chartId,
        }))
      Promise.all(arr).then(res => {
        this.duplicationCheck();
      })
    },
    // 提交排序
    submitSorting (data) {
      this.layoutData[this.layoutIndex].data.sortingJson = data;
      this.$refs.chartCard[this.layoutIndex].initData();
    },
    // 提交图表筛选条件
    submitQuery (data) {
      this.layoutData[this.layoutIndex].data.queryConditions = data;
      this.getData([this.layoutData[this.layoutIndex].i])
    },
    // 提交公共筛选条件
    submitParam (data) {
      this.commonParam = data;
      this.duplicationCheck();
    },
    // 关闭编辑 
    closeEdit () {
      this.getLayout();
    },
    // 重复检验
    duplicationCheck () {
      let obj = {};
      let arr = [];
      this.layoutData.forEach(item => {
        if (!item.data) return;
        let key = item.data.templateId + item.data.queryConditions;
        if (!!obj[key]) {
          obj[key].push(item.i)
        } else {
          obj[key] = [item.i];
        }
      })

      for (var i in obj) {
        this.getData(obj[i]);
      }
      this.drawCard();
    },
    loadMore (index) {
      let chartData = this.layoutData[index].data;
      let filterParam = this.dealParam(chartData);
      let templatesId = chartData.templateId;
      this.layoutData[index].page = !!this.layoutData[index].page ? this.layoutData[index].page + 1 : 2;
      let param = {
        // limit: this.page.size,
        // page: this.page.current,
        limit: 100,
        page: this.layoutData[index].page,
        templatesId: templatesId,
      }
      for (let i in filterParam) {
        if (i !== 'templatesId')
          param[i] = filterParam[i];
      }
      GetReportData(param).then(res => {
        res.data.data = this.dealData(res.data.data, res.header);
        this.layoutData[index].dataOrigin = res;
        this.$nextTick(() => {
          this.$refs.chartCard[index].addData();
        })
      })
    },
    getData (iArray) {
      let index = this.layoutData.findIndex(item => item.i === iArray[0]);
      let chartData = this.layoutData[index].data;
      let filterParam = this.dealParam(chartData);
      let templatesId = chartData.templateId;
      let param = {
        // limit: this.page.size,
        // page: this.page.current,
        limit: 100,
        page: 1,
        templatesId: templatesId,
      }
      for (let i in filterParam) {
        if (i !== 'templatesId')
          param[i] = filterParam[i];
      }
      iArray.forEach(i => {
        let index = this.layoutData.findIndex(item => item.i === i);
        this.layoutData[index].loading = true;
      })
      GetReportData(param).then(res => {
        res.data.data = this.dealData(res.data.data, res.header);
        iArray.forEach(i => {
          let index = this.layoutData.findIndex(item => item.i === i);
          let temp = this.layoutData[index];
          temp.dataOrigin = res;
          temp.loading = false;
          this.$set(this.layoutData, index, temp)

          this.$nextTick(() => {
            this.$refs.chartCard[index].initData();
          })
        })
      }).catch(error => {
        iArray.forEach(i => {
          let index = this.layoutData.findIndex(item => item.i === i);
          let temp = this.layoutData[index];
          temp.loading = false;
          this.$set(this.layoutData, index, temp)
        })
      })
    },
    // 提交条件
    dealParam (chartData) {
      let filterParamData = this.dealCommonParam(JSON.parse(chartData.queryConditions));
      let paramCom = {
        parameters: []
      };

      filterParamData.forEach((item, index) => {
        if (item.mark === 'Report') {
          paramCom[item.prop] = item.val;
        } else if (item.mark === 'BU') {
          if (item.con !== 'ALL') {
            paramCom[item.prop] = item.val;
          } else {
            paramCom[item.prop] = '';
            // paramCom[item.prop] = '0f458006-7267-4643-944e-d2f5cb427564,1d9a50ec-bd16-4f20-bea7-d271a5ea12a0,1f5c3afe-41a0-448c-893a-8f33a9609170,28dab1c9-7f58-481a-a4f3-d2f9ac61bc54,2eda1607-9ec4-49e9-8c7d-b0de602b8735,4abd3e12-e1b8-4779-9b35-fc54da65493a,4ac4f9c1-2354-4e07-9246-2586e008f20a,52fe49a6-005f-45f8-9f39-52fae9fa22d1,5c555ef5-ebbe-4f47-9883-10dbc85a6067,5e1d3352-82a3-4bc7-ad41-e8a60c3ac203,5eee1d90-a0da-4eb7-96c1-41221b81e6cc,65f4b5c1-8f3b-41f0-9e42-7cbd9771cbb4,662006ef-9caf-469b-b00e-b2b073961f24,689165ae-a4c6-4f12-befc-7723b65bb870,6eba7d01-03f9-49e7-aaae-b68c8fd21fc4,70c24bad-ec94-4eb8-9a77-16136d364308,714b2241-2036-42be-898b-883360fba8fc,807ece25-8677-4b02-a1ea-207eb2e17d12,85056551-a53b-487e-9f75-20a9481c949a,8aca4e25-46cb-4f64-90dd-b66cbe62118a,8b3d876d-a5d7-4ca6-8b28-2c37fec9e7ae,a3af44b1-bb24-41db-a26c-0aa404617377,a980c247-d133-4b20-a0db-3536885d84f0,af6a5e52-056a-464a-9778-60ecf493f552,bea30e50-36a3-4aa5-9260-335b291aa812,c645d79a-fc8c-403b-8340-cc8fcf17626f,c711f5b8-ce4f-436f-bc9d-0d608a7051f3,cdfe9b66-a332-4566-ad61-69065bf058d6,ce21ee88-6de1-4e53-8586-1aea57e5bdbf,d2d89266-9e40-4b20-9677-6c261bb027fd,d9a3c566-1287-4312-bf82-cbf3955f99ba,dd411008-bb02-4b5d-9eda-71705932a18e,e947853a-eee5-4194-a498-7fb144697ddd,f1479c52-8d79-408c-afd2-1e8c59175d40';
          }
        } else {
          if (item.data.condition === '' && item.data.list.length === 0 && item.data.dateRange.length === 0 && item.data.range.start === '') return;

          this.dealParamType(paramCom, item.data);
        }
      })
      return paramCom;
    },
    // 处理条件
    dealParamType (paramCom, item) {
      let value;
      let data = {
        key: item.prop,
        operator: this.$global.typeOperators[item.fieldType][item.operator],
        relation: item.relation,
        invert: Number(!item.invert),
      }
      switch (item.fieldType) {
        case 'number':
        case 'string':
          switch (item.operator) {
            case 6:
              data.value = item.list.join(',');
              break;
            case 7:
              data.value = `${item.range.start}~${item.range.end}`;
              break;
            default:
              data.value = item.condition;
          }
          break;
        case 'stores':
          switch (item.operator) {
            case 2:
              data.value = item.list.join(',');
              break;
            default:
              data.value = item.condition;
          }
          break;
        case 'date':
          // data.value = item.condition !== 11 ? item.dateRange[0] + '~' + item.dateRange[1] : '';
          data.value = item.dateRange[0] + '~' + item.dateRange[1];
          break;
        case 'time':
          data.value = `${item.dateRange[0]}~${item.dateRange[1]}`;
          break;
        case 'mins':
          data.value = item.operator !== 2 ? `${item.condition}~0` : `${item.range.start}~${item.range.end}`;
          break;
        default:
          data.value = item.condition;
      }
      data.type = item.fieldType;
      data.value = data.value.toString().trim();
      paramCom.parameters.push(data)
    },
    // 处理公共筛选条件
    dealCommonParam (param) {
      let commonParam = [];
      let paramProp = param.map(item => item.prop);
      this.commonParam.forEach((item, index) => {
        // if (index === 0 && item.val === '') return;
        if (index === 1 && item.con === '') return;
        if (index === 2 && item.con === '') return;
        commonParam.push(item);
      })

      commonParam.forEach(com => {
        let index;
        if (!com.data) {
          index = -1;
        } else {
          index = param.findIndex(item => item.data.fieldType === com.data.fieldType);
        }
        if (index === -1) {
          param.push(com)
        } else {
          param[index] = Object.assign(com, {
            prop: param[index].prop,
            data: Object.assign(com.data, {
              prop: param[index].prop
            })
          })
        }
      })
      return param;
    },
    // 处理数据
    dealData (data, header) {
      let jsonVar = {};
      this.headerTraversal(header, jsonVar)
      data = data.map(item => {
        item.children = [];
        item.tableId = this.serialNum++;
        let count = 0;
        for (let key in jsonVar) {
          item[key] = !!item[key] ? JSON.parse(item[key]) : [];
          if (count < item[key].length) count = item[key].length;
        }

        if (count > 0) {
          for (let key in jsonVar) {
            for (let i = 0; i < count - 1; i++) {
              if (!item.children[i]) item.children[i] = { tableId: this.serialNum++ };
              item.children[i].order_no = null;
              item.children[i][key] = item[key][i + 1] || {};
              // if (!!item.children[i][key].card_no) item.children[i][key].card_no = item.children[i][key].card_no.replace(/.{4}$/, '****');
            }
            item[key] = item[key][0] || {};
            // if (!!item[key].card_no) item[key].card_no = item[key].card_no.replace(/.{4}$/, '****');
          }
        }

        // if (!!item.card_no) item.card_no = item.card_no.replace(/.{4}$/, '****');
        return item;
      })
      return data;
    },
    // 遍历出需要解析的字段
    headerTraversal (data, result) {
      data.forEach(item => {
        if (/^(.+)\.(.+)$/.test(item.prop)) {
          var key = item.prop.split('.');
          result[key[0]] = 1;
        }
        if (item.children.length > 0) {
          this.headerTraversal(item.children, result)
        }
      })
    },
    // 导出 
    submitExport (data) {
      let formats = {
        PDF: 'pdf',
        JPG: 'jpg',
        PNG: 'png',
      };
      if (data.format === 'PDF') {
        window.print()
      } else {
        domtoimage.toPng(this.$refs.wrap).then((dataUrl) => {
          var a = document.createElement('a');
          var myEvent = new MouseEvent('click')
          a.download = `${data.name}.${formats[data.format]}`;
          a.href = dataUrl;
          a.dispatchEvent(myEvent)
          this.$message.success(this.$i18n.t('message.export'))
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100%;
  background: #f2f7fb;
}
.el-card {
  ::v-deep .el-card__body {
    height: 100%;
  }
}

.add-card {
  cursor: pointer;
  color: #e4e4e4;
  text-align: center;
  .add {
    font-size: 32px;
    font-weight: bold;
  }
  &:hover {
    background: #e4e4e4;
    color: #304156;
  }
}
.title {
  padding-bottom: 5px;
  // border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  .tooltip {
    color: #535353;
    // position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    user-select: none;
    .btn {
      border-radius: 5px;
      &:hover {
        background: #b4bccc;
        color: #fff;
      }
    }
  }
}

// .collapse {
//   height: 55px !important;
// }
.screen-full {
  width: 100vw !important;
  height: 100vh !important;
  transform: none !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 999;
}

.box {
  margin-left: -10px;
  margin-right: -10px;
  ::v-deep .vue-grid-item.vue-grid-placeholder {
    background: #29e0bb;
  }
}

.box {
  ::v-deep .vue-resizable-handle {
    display: none;
  }
  ::v-deep .vue-grid-item:hover {
    .vue-resizable-handle {
      display: block !important;
    }
  }
}
</style>