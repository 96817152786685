<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="permit.edit"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="120px"
      >
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label1')"
              prop="name"
            >
              <my-select
                :data="deviceNames"
                :value.sync="groupForm.itemName"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="deviceNameChange"
              ></my-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label2')"
              prop="model"
            >
              <my-select
                :data="models"
                :value.sync="groupForm.model"
                :prop="{
                label:'model',
                value:'model'
              }"
              ></my-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label3')"
              prop="brand"
            >
              <my-select
                :data="brands"
                :value.sync="groupForm.brand"
                :prop="{
                  label:'name',
                  value:'name'
                }"
              ></my-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label4')"
              prop="type"
            >
              <my-select
                :data="types"
                :value.sync="groupForm.type"
                :prop="{
                  label:'name',
                  value:'name'
                }"
              ></my-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('newQuotation.tab1')"
          name="tab1"
        >
          <quotation-list
            ref="quotationList"
            :data="quotationData"
            :total="total"
            :permit="permit"
            @tableChange="initData"
          ></quotation-list>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('newQuotation.tab2')"
          name="tab2"
        >
          <eol-alternative
            ref="eolAlternative"
            :data="groupForm"
            :permit="permit"
          ></eol-alternative>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('newQuotation.tab3')"
          name="tab3"
        >
          <specifications
            ref="specifications"
            :data="groupForm.specifications"
          ></specifications>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import MySelect from '@/components/MySelect'
import PageHeader from '@/components/PageHeader'
import QuotationList from './components/QuotationList'
import EolAlternative from './components/EolAlternative'
import Specifications from './components/Specifications'

// 接口
import {
  GetDeviceNameAll,
  GetDeviceModelAll,
} from '@/api/warehouse/dataMaintenance/deviceType'
import {
  EditQuotationDevice,
  GetQuotationDetailsList,
} from '@/api/warehouse/quotation'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'NewQuotation',
  components: {
    MySelect,
    PageHeader,
    QuotationList,
    EolAlternative,
    Specifications,
  },
  data () {
    return {
      permit: {
        add: true,
        edit: true,
        del: true,
      },
      title: '',
      activeName: 'tab1',
      deviceNames: [],
      models: [],
      brands: [],
      types: [],
      groupForm: {
        loading: false,
        id: null,
        itemName: '',
        model: '',
        deviceTypeId: '',
        brand: '',
        brandId: '',
        type: '',
        typeInfoId: '',
        discontinue: '',
        discontinueDate: null,
        specifications: '',
      },
      rules: {

      },
      total: 0,
      quotationData: [],
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.name === 'NewQuotation' && from.name === 'NewQuote') {
        this.$refs.quotationList.getTableData();
        this.$refs.eolAlternative.getTableData();
      }
    }
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;
    this.title = this.permit.edit ? this.$i18n.t('newQuotation.title1') : this.$i18n.t('newQuotation.title2');
    this.initData()
    this.initSelect();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    initData (size, current) {
      let param = {
        limit: size || 10,
        page: current || 1,
        infoId: this.groupForm.id,
      }
      GetQuotationDetailsList(param).then(res => {
        this.quotationData = res.body.list.map(item => {
          item.inquiryDate = formatDate(item.inquiryDate, 'yyyy-MM-dd')
          return item;
        });
        this.total = res.total;
        Object.assign(this.groupForm, res.info);
        localStorage.quotationData = JSON.stringify(this.groupForm);
      })
    },
    // 初始化下拉框 
    initSelect () {
      this.queryDeviceName('');
      this.queryModel('');
      // 品牌
      GetDataFieldAll({ parent: '100000000000000000000000000000000002' }).then(res => this.brands = res)
      // 设备类型
      GetDataFieldAll({ parent: '100000000000000000000000000000000001' }).then(res => this.types = res)

    },

    // 查询设备名称
    queryDeviceName (query) {
      let param = { name: query }
      GetDeviceNameAll(param).then(res => this.deviceNames = res);
    },
    // 查询设备型号
    queryModel (query) {
      let param = {
        name: this.groupForm.deviceName || undefined,
        model: query
      }
      GetDeviceModelAll(param).then(res => this.models = res);
    },

    deviceNameChange () {
      let param = {
        name: this.groupForm.deviceName,
      }
      GetDeviceModelAll(param).then(res => this.models = res);
    },
    // 提交
    submit () {
      let param = {
        itemName: this.groupForm.itemName,
        model: this.groupForm.model,
        type: this.groupForm.type,
        brand: this.groupForm.brand,
        typeInfoId: this.groupForm.typeInfoId,
        deviceTypeId: this.groupForm.deviceTypeId,
        brandId: this.groupForm.brandId,
        qInfoId: this.groupForm.id,
        discontinue: Number(this.$refs.eolAlternative.eol).toString(),
        discontinueDate: this.$refs.eolAlternative.date,
        specifications: this.$refs.specifications.tinyValue,
      }
      EditQuotationDevice(param).then(res => {
        if (res) {
          this.$message.success(this.$i18n.t('message.save'))
          this.back();
        }
      })
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('QuotationSearch');
        this.permit.add = permission.includes('QuotationAdd');
        this.permit.edit = permission.includes('QuotationEdit');
        this.permit.del = permission.includes('QuotationDelete');

      }
    }
  }
}
</script>

<style>
</style>