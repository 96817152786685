<template>
  <div class="wrap">
    <el-card>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              :disabled="!permit.edit"
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('projects.col1')"
            prop="name"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('projects.col2')"
            prop="duration"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('projects.col3')"
            prop="startDate"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('projects.col4')"
            prop="endDate"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="300"
            :label="$t('projects.col5')"
            prop="percentage"
          >
            <template slot-scope="scope">
              <el-progress
                :text-inside="true"
                :stroke-width="24"
                :percentage="scope.row.percentage"
                status="success"
              ></el-progress>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

  </div>
</template>

<script>
// 全局方法
import { computeTime } from '@/utils/common'
// 组件
import ColSet from '@/components/ColSet'
// 接口
import {
  GetProjectsList,
  DelProjects,
} from '@/api/warehouse/projects'
export default {
  name: 'Projects',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [{}],
      tableHeight: null,
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'Projects') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetProjectsList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.duration = !!item.startDate ? computeTime(item.startDate, item.endDate) : null;
          item.percentage = Math.round(item.completeNode / (item.taskNode || 1) * 100)
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newProjects' })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0])
    },
    dblclickEdit (e) {
      if (!this.permit.edit) return;
      this.$router.push({ path: '/home/newProjects', query: { id: e.id } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let arr = this.tableSelected.map(item => this.delPromise(item.id));
        Promise.all(arr).then(res => {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.del'))
        })
      }).catch(() => { });
    },
    delPromise (id) {
      return new Promise((resolve, reject) => {
        DelProjects(id).then(res => {
          if (res) resolve();
        })
      })
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>