<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
        >
          <el-table-column
            min-width="150"
            prop="createTime"
            label="日期"
            sortable
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="isFactory"
            label="寄件方式"
            sortable
          >
            <template slot-scope="scope">
              <template v-if="scope.row.type === 0">
                <span v-if="scope.row.isFactory ===  1">厂家直发项目</span>
                <span v-if="scope.row.isFactory === 2">BGB仓库发货</span>
                <span v-if="scope.row.isFactory === 3">厂家发BGB仓库</span>
              </template>
              <template v-if="scope.row.type === 1">
                <span v-if="scope.row.isFactory ===  1">厂家直发项目</span>
                <span v-if="scope.row.isFactory === 2">厂家发BGB仓库</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            label="类型"
            sortable
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type === 0">发货</span>
              <span v-if="scope.row.type === 1">到货</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceName"
            label="设备名称"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="brandName"
            label="品牌"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceModel"
            label="型号"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="appCount"
            label="申购数量"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="orderCount"
            label="订购数量"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="status"
            label="状态"
            sortable
          >
            <template slot-scope="scope">
              <template v-if="scope.row.type === 0">
                <span v-if="scope.row.status === 0">未发货</span>
                <span v-if="scope.row.status === 1">已发货-部分</span>
                <span v-if="scope.row.status === 2">已发货-全部</span>
              </template>
              <template v-if="scope.row.type === 1">
                <span v-if="scope.row.status === 0">已到货-部分</span>
                <span v-if="scope.row.status === 1">已到货-全部</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="estimatedDate"
            label="预计发货日期"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="count1"
            label="发货数量"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="count2"
            label="到货数量"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="sendFirm"
            label="寄件方"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="logisticsCompany"
            label="物流公司"
            sortable
          ></el-table-column>
          <el-table-column
            min-width="130"
            prop="recipient"
            label="收件人"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="180"
            prop="number"
            label="运单号"
            sortable
          >
            <template slot-scope="scope">
              <span class="number-box">{{scope.row.number}}</span>
              <el-button
                v-if="!!scope.row.number"
                size="mini"
                @click="numberCopy(scope.row)"
              >复制</el-button>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="xxx"
            label="签收状态"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="fullname"
            label="操作员"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="remark"
            label="备注"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { copyText } from '@/utils/common'
// 接口
import { GetLogisticsInfoList } from '@/api/warehouse/purchaseOrders'
export default {
  props: ['visible', 'id'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '物流信息',
      },
      rules: {
        // reason: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
      tableHeight: 385,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.page.current = 1;
        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        deviceInfoId: this.id
      }
      this.tableLoading = true;
      GetLogisticsInfoList(param).then(res => {
        this.tableData = res.list
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 复制运单号 
    numberCopy (row) {
      copyText(row.number)
      this.$message.success(this.$i18n.t('message.copy'))
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }
  .el-button {
    width: 100%;
  }
}
</style>