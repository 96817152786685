<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="800px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newMaintenanceCheckList.tableContent.checkDialog.label3')"
              prop="checkItem"
            >
              <!-- <el-input
            disabled
            v-model="dialog.item"
          ></el-input> -->
              <my-select
                size="small"
                :data="checkItems"
                :value.sync="dialog.checkItem"
                :prop="{
                label:'item',
                value:'item'
              }"
                @change="checkItemsChange"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newMaintenanceCheckList.tableContent.checkDialog.label4')"
              prop="checkItemId"
            >
              <div class="flex-box">
                <el-checkbox
                  v-model="dialog.isRelation"
                  @change="isRelationChange"
                ></el-checkbox>
                <el-select
                  v-model="dialog.checkItemId"
                  @change="checkTypeChange"
                  filterable
                >
                  <el-option
                    v-for="(item,index) in checkTypes"
                    :key="index"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
                <!-- <el-input
              disabled
              v-model="dialog.checkType"
            ></el-input> -->
                <el-button
                  icon="el-icon-top"
                  :class="{'disabled': arrowIndex === 0}"
                  @click="arrowClick(1)"
                ></el-button>
                <el-button
                  icon="el-icon-bottom"
                  :class="{'disabled': arrowIndex === checkTypes.length - 1}"
                  @click="arrowClick(0)"
                ></el-button>
              </div>
            </el-form-item>
            <el-form-item
              v-if="itemType === 1 ||itemType === 5||itemType === 6"
              :label="$t('newMaintenanceCheckList.tableContent.checkDialog.label5')"
              prop="checkType"
            >
              <el-input
                disabled
                v-model="dialog.checkTypeItem.optionInput"
              ></el-input>
            </el-form-item>
            <div v-if="itemType === 2 || itemType === 3">
              <div class="input-label">{{$t('newMaintenanceCheckList.tableContent.checkDialog.label5')}}</div>
              <div
                class="input-box"
                v-for="(item,index) in dialog.checkTypeItem.options"
                :key="index"
              >
                <el-checkbox
                  v-if="itemType === 3"
                  disabled
                ></el-checkbox>
                <el-input
                  size="small"
                  v-model="item.name"
                ></el-input>
                <el-select
                  size="small"
                  v-model="item.isNormal"
                >
                  <el-option
                    label="Normal"
                    :value="0"
                  ></el-option>
                  <el-option
                    label="Abnormal"
                    :value="1"
                  ></el-option>
                  <el-option
                    label="N/A"
                    :value="2"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="dialog.checkTypeItem.options.length === 2"
                  size="small"
                  type="danger"
                  icon="el-icon-delete"
                  @click="delRadio(index)"
                ></el-button>
                <el-button
                  size="small"
                  type="success"
                  icon="el-icon-plus"
                  @click="addRadio(index)"
                ></el-button>
              </div>
            </div>
            <div v-if="itemType === 4">
              <div class="input-label">{{$t('newMaintenanceCheckList.tableContent.checkDialog.label5')}}</div>
              <div
                class="input-box"
                v-for="(item,index) in dialog.checkTypeItem.options"
                :key="index"
              >
                <el-radio disabled></el-radio>
                <el-input
                  disabled
                  size="small"
                  v-model="item.name"
                ></el-input>
                <el-select
                  size="small"
                  v-model="item.isNormal"
                >
                  <el-option
                    label="Normal"
                    :value="0"
                  ></el-option>
                  <el-option
                    label="Abnormal"
                    :value="1"
                  ></el-option>
                  <el-option
                    label="N/A"
                    :value="2"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="itemType === 7">
              <div class="input-label">{{$t('newMaintenanceCheckList.tableContent.checkDialog.label5')}}</div>
              <div class="input-box">
                <el-input-number
                  size="small"
                  :max="dialog.checkTypeItem.end"
                  :controls="false"
                  v-model="dialog.checkTypeItem.start"
                ></el-input-number>
                <span>to</span>
                <el-input-number
                  size="small"
                  :max="dialog.checkTypeItem.max"
                  :controls="false"
                  v-model="dialog.checkTypeItem.end"
                ></el-input-number>
              </div>
            </div>
            <el-form-item
              v-if="itemType === 8 || itemType === 9 || itemType === 10"
              :label="$t('newMaintenanceCheckList.tableContent.checkDialog.label5')"
              prop="checkType"
            >
              <el-input disabled>
                <template slot="append">{{dialog.checkTypeItem.unit}}</template>
              </el-input>
            </el-form-item>
            <div v-if="itemType === 11">
              <el-form-item
                label="IP"
                prop="ip"
              >
                <el-input
                  disabled
                  v-model="dialog.checkTypeItem.ip"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="Mask"
                prop="mask"
              >
                <el-input
                  disabled
                  v-model="dialog.checkTypeItem.mask"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="Gateway"
                prop="gateway"
              >
                <el-input
                  disabled
                  v-model="dialog.checkTypeItem.gateway"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="itemType === 12">
              <div class="input-label">{{$t('newMaintenanceCheckList.tableContent.checkDialog.label5')}}</div>
              <img
                class="signature"
                src="/static/image/signature.png"
              >
            </div>
            <div v-if="itemType === 13">
              <el-table
                border
                :data="dialog.checkTypeItem.options"
              >
                <el-table-column
                  min-width="150"
                  prop="name"
                  label="list"
                >
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newMaintenanceCheckList.tableContent.checkDialog.label1')"
              prop="mainType"
            >
              <my-select
                size="small"
                :data="mainTypes"
                :value.sync="dialog.mainType"
                :prop="{
              label:'name',
              value:'name'
            }"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newMaintenanceCheckList.tableContent.checkDialog.label2')"
              prop="notes"
            >
              <el-input
                type="textarea"
                :rows="8"
                v-model="dialog.notes"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{ isCopy ? $t('newMaintenanceCheckList.tableContent.checkDialog.btn1') : $t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'

// 接口
import {
  AddCheckTypeItem,
  GetCheckTypeItemList,
} from '@/api/warehouse/dataMaintenance/checkType'
import {
  GetMainType,
} from '@/api/warehouse/maintenance'
export default {
  components: {
    MySelect
  },
  props: ['visible', 'data', 'isCopy', 'orgId'],
  data () {
    return {
      mainTypes: [],
      checkItems: [],
      checkTypes: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        id: null,
        mainType: '',
        checkItem: '',
        notes: '',
        checkItemId: '',
        isCount: 0,
        relatedDevice: 0,
        itemList: [],
        isRelation: false,
        checkTypeItem: {
          id: null,
          itemId: '',
          item: '',
          checkType: '',
          checkTypeId: '',
          options: [],
          optionInput: '',
          charaterLength: 1,
          start: 0,
          end: 9,
          max: 9,
          unit: '',
          ip: '0.0.0.0',
          mask: '0.0.0.0',
          gateway: '0.0.0.0',
          type: '',
          sn: null,
        }
      },
      rules: {
        checkItem: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        checkItemId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      itemType: '',
      dateFormats: [
        '2021-08-18',
        '2021/08/18',
        '08/18/2021',
        '2021年8月18日',
        '2021-08',
        '08-2021',
      ],
      timeFormats: [
        '10:28:28',
        '10:28:28AM',
      ],
      tableData: [],
      checkItem: {},
      arrowIndex: 0,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.id = null;
        this.dialog.checkItemId = '';
        this.dialog.sn = null;
        this.arrowIndex = 0;
        this.itemType = '';

        this.getData(() => {
          if (this.data) {
            this.dialog = JSON.parse(JSON.stringify(Object.assign(this.dialog, this.data)));
            this.dialog.title = this.isCopy ? this.$i18n.t('newMaintenanceCheckList.tableContent.checkDialog.title3') : this.$i18n.t('newMaintenanceCheckList.tableContent.checkDialog.title2');
            this.dialog.isRelation = false;

            this.checkTypes = this.tableData.filter(item => item.item === this.dialog.checkItem)
              .map(item => {
                item.name = `${item.checkType} - ${item.item}`
                return item;
              });
            this.checkItem = this.dialog.checkTypeItem;
            this.initData();
          } else {
            this.dialog.title = this.$i18n.t('newMaintenanceCheckList.tableContent.checkDialog.title1');
            this.checkTypes = [];
          }
        });
        if (this.mainTypes.length === 0) GetMainType().then(res => this.mainTypes = res.map(item => Object.assign({ name: item })));

        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getData (fun) {
      let param = {
        limit: 9999,
        page: 1,
        orgId: this.orgId,
      }
      GetCheckTypeItemList(param).then(res => {
        this.tableData = res.list;

        let tempData = this.tableData.map(item => item.item);
        tempData = Array.from(new Set(tempData));
        this.checkItems = tempData.map(item => Object.assign({ item: item }));
        fun();
      })
    },
    initData () {
      this.itemType = this.checkItem.type;
      Object.assign(this.dialog.checkTypeItem, {
        itemId: this.checkItem.itemId,
        id: this.checkItem.id,
        item: this.checkItem.item,
        checkType: this.checkItem.checkType,
        checkTypeId: this.checkItem.checkTypeId,
        options: this.checkItem.options,
        type: this.itemType,
        isLinked: this.checkItem.isLinked,
        siteId: this.checkItem.siteId,
        showData: this.checkItem.showData,
        filterData: this.checkItem.filterData,
      });
      if (this.itemType === 1) this.dialog.checkTypeItem.optionInput = 'Type here';
      if (this.itemType === 5) this.dialog.checkTypeItem.optionInput = this.dateFormats[this.dialog.checkTypeItem.options[0].name]
      if (this.itemType === 6) this.dialog.checkTypeItem.optionInput = this.timeFormats[this.dialog.checkTypeItem.options[0].name]
      if (this.itemType === 7) {
        this.dialog.checkTypeItem.charaterLength = Number(this.dialog.checkTypeItem.options[0].name);
        this.dialog.checkTypeItem.start = Number(this.dialog.checkTypeItem.options[1].name);
        this.dialog.checkTypeItem.end = Number(this.dialog.checkTypeItem.options[2].name);
        this.dialog.checkTypeItem.max = Number(new Array(this.dialog.checkTypeItem.charaterLength).fill(9).join(''));
      }
      if (this.itemType === 8 || this.itemType === 9 || this.itemType === 10) {
        this.dialog.checkTypeItem.unit = this.dialog.checkTypeItem.options[0].name;
      }
      if (this.itemType === 11) {
        Object.assign(this.dialog.checkTypeItem, {
          ip: this.dialog.checkTypeItem.options[0].name,
          mask: this.dialog.checkTypeItem.options[1].name,
          gateway: this.dialog.checkTypeItem.options[2].name,
        });
      }
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          item: this.dialog.checkItem,
          checkTypeId: this.dialog.checkTypeItem.checkTypeId,
        }
        if ([2, 3, 4, 13].includes(this.itemType)) {
          param.options = this.dialog.checkTypeItem.options.map((item, index) => {
            item.itemId = this.dialog.checkTypeItem.id;
            item.sn = index;
            return item;
          })
        }

        if (this.itemType === 7) {
          param.options = [
            { sn: 0, name: this.dialog.checkTypeItem.charaterLength },
            { sn: 1, name: this.dialog.checkTypeItem.start },
            { sn: 2, name: this.dialog.checkTypeItem.end },
          ];
        }
        if (this.itemType === 11) {
          param.options = [
            { sn: 0, name: this.dialog.checkTypeItem.ip },
            { sn: 1, name: this.dialog.checkTypeItem.mask },
            { sn: 2, name: this.dialog.checkTypeItem.gateway },
          ];
        }

        if ([5, 6, 8, 9, 10].includes(this.itemType)) {
          param.options = this.dialog.checkTypeItem.options;
        }

        if (this.itemType === 13) {
          param.siteId = this.dialog.checkTypeItem.siteId;
          param.isLinked = this.dialog.checkTypeItem.isLinked;
          param.showData = this.dialog.checkTypeItem.showData;
          param.filterData = this.dialog.checkTypeItem.filterData;
        }

        this.dialog.loading = true;
        AddCheckTypeItem(param).then(res => {

          this.dialog.checkTypeItem.options = param.options;
          this.dialog.checkTypeItem.id = res.message;
          this.dialog.checkItemId = res.message;
          let data = JSON.parse(JSON.stringify(this.dialog));
          delete data.loading
          delete data.visible
          if (this.isCopy) delete data.id

          this.$emit('submit', data);

          this.dialog.loading = false;
          if (!this.isCopy) this.dialog.visible = false;

        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    addRadio (index) {
      this.dialog.checkTypeItem.options.splice(index + 1, 0, {
        name: '',
        isNormal: 0,
        enable: 1,
      });
    },
    delRadio (index) {
      this.dialog.checkTypeItem.options.splice(index, 1);
    },
    // 
    arrowClick (type) {
      if (type === 1 && this.arrowIndex !== 0) {
        this.arrowIndex--;
      } else if (type === 0 && this.arrowIndex !== this.checkTypes.length - 1) {
        this.arrowIndex++;
      }
      this.checkItem = JSON.parse(JSON.stringify(this.checkTypes[this.arrowIndex]));
      this.dialog.checkItemId = this.checkItem.id;
      this.initData();
    },
    // 
    checkItemsChange () {
      this.dialog.checkItemId = '';
      this.arrowIndex = 0;
      this.itemType = '';
      if (this.dialog.isRelation) {
        this.checkTypes = this.tableData.map(item => {
          item.name = `${item.checkType} - ${item.item}`
          return item;
        });
      } else {
        this.checkTypes = this.tableData
          .filter(item => item.item === this.dialog.checkItem)
          .map(item => {
            item.name = `${item.checkType} - ${item.item}`
            return item;
          });
      }
    },
    // 
    checkTypeChange () {
      let item = this.tableData.find(item => item.id === this.dialog.checkItemId);
      this.checkItem = JSON.parse(JSON.stringify(item));
      this.initData();
    },
    // 
    isRelationChange (e) {
      this.checkItemsChange();
    }
  }
}
</script>

<style lang="scss" scoped>
.input-label {
  height: 32px;
  line-height: 32px;
}
.input-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  .el-select {
    margin-right: 10px;
  }
  .el-input {
    margin-right: 10px;
  }
  .el-radio {
    margin-right: 10px;
  }
  .el-checkbox {
    margin-right: 5px;
  }
  span {
    text-align: center;
    width: 50px;
  }
}
.signature {
  width: 70%;
  margin: 20px 15% 0;
}

.flex-box {
  width: 100%;
  display: flex;
  .el-checkbox {
    margin-right: 10px;
  }
  .el-select {
    margin-right: 10px;
  }
}
</style>