<template>
  <div class="wrap">
    <el-card>

      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              class="search-input"
              size="medium"
              placeholder="Search"
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >Search</el-button>
            <el-button
              :disabled="!permit.check"
              size="medium"
              @click="showRequest"
            >Approve or Deny</el-button>
            <el-button
              :disabled="!permit.export"
              size="medium"
            >Export</el-button>
          </el-button-group>
          <col-set
            @save="saveColSet"
            @updata="getTableData"
          ></col-set>
        </div>
      </el-row>
      <el-row>
        <el-table border          :data="tableData"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="ID"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Date Time"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Subject"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Request Details"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Reason to Deny"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Submitter"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Approval Status"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 添加组织弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="Request for Approval"
      width="400px"
      center
      :visible.sync="requestDialog.visible"
    >
      <el-form
        :model="requestDialog"
        size="small"
      >
        <el-form-item label="Subject">
          <el-input v-model="requestDialog.subject"></el-input>
        </el-form-item>
        <el-form-item label="Request Details">
          <el-input
            type="textarea"
            :rows="4"
            v-model="requestDialog.requestDetails"
          ></el-input>
        </el-form-item>
        <el-form-item label="Reason to Deny">
          <el-input
            type="textarea"
            :rows="4"
            v-model="requestDialog.reasonToDeny"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="requestDialog.visible = false"
        >Deny</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="requestDialog.visible = false"
        >Approve</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ColSet from '@/components/ColSet'
export default {
  name: 'DailyDataSync',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        check: true,
        export: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableSelected: [],
      tableData: [{}, {}, {}, {}],
      requestDialog: {
        visible: false,
        subject: '',
        requestDetails: '',
        reasonToDeny: '',
      }
    }
  },
  created () {
    this.getTableData();
    this.dealPermission();
  },
  methods: {
    getTableData () {
      let param = {}
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    showRequest () {
      this.requestDialog.visible = true;
    },
    //保存ColSet 
    saveColSet (data) {

    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('NotificationSearch');
        this.permit.check = permission.includes('NotificationApproveorDeny');
        this.permit.export = permission.includes('NotificationExport');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>