<template>
  <div>
    <el-row>
      <div
        class="label-item"
        style="width:50%"
      >
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
      </div>
      <div
        class="label-item"
        style="width:50%"
      >
        <div
          class="color-block"
          :style="{background:color}"
        ></div>
        <!-- <el-color-picker
          v-model="color"
          size="medium"
        ></el-color-picker> -->
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      name: '',
      color: '',
      sn: '',
      loading: false,
    }
  },
  watch: {
    'item': function () {
      this.name = this.item.name;
      this.color = this.item.color;
      this.sn = this.item.sn;
    }
  },
  methods: {
    submit () {
      this.loading = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";
.color-block {
  width: 24px;
  height: 24px;
  margin: 6px;
}
</style>