import request from '@/utils/request'

// 查询报价单列表
export const GetQuotationList = (data) => request({
  method: 'post',
  url: '/quotations/quotes/queryByPage.do',
  data: JSON.stringify(data)
})
// 添加或修改报价单
export const AddQuotation = (data) => request({
  method: 'post',
  url: '/quotations/quotes/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除报价单
export const DelQuotation = (id) => request({
  method: 'post',
  url: `/quotations/quotes/deleted/${id}.do`,
})

// 删除报价单集合
export const DelQuotationCollection = (data) => request({
  method: 'post',
  url: `/quotations/quotes/deleted/info.do`,
  data: JSON.stringify(data)
})

// 复制报价单
export const CopyQuotation = (id) => request({
  method: 'post',
  url: `/quotations/quotes/copy/${id}.do`,
})

// 查询报价单详情
export const GetQuotationItem = (id) => request({
  method: 'get',
  url: `/quotations/quotes/get/${id}.do`,
})

// 查询供应商联系人
export const GetSupplierContact = (data) => request({
  method: 'get',
  url: `/quotations/query/supplier_contact.do`,
  params: data
})

// 查询报价单闲情列表
export const GetQuotationDetailsList = (data) => request({
  method: 'post',
  url: '/quotations/quotes/queryPageByInfo.do',
  data: JSON.stringify(data)
})

// 获取汇率
export const GetExchangeRate = (data) => request({
  method: 'get',
  url: '/quotations/query/rate.do',
  params: data
})

// 修改报价设备
export const EditQuotationDevice = (data) => request({
  method: 'post',
  url: '/quotations/quotes/editInfo.do',
  data: data
})

// 停产替代列表
export const GetEolList = (data) => request({
  method: 'post',
  url: `/quotations/info/query/page/by/id.do`,
  data: JSON.stringify(data)
})

// 删除停产替代
export const DelRelevanceDevive = (id) => request({
  method: 'post',
  url: `/quotations/info/query/deleted/${id}.do`,
})

// 关联设备列表
export const GetRelevanceDeviveList = (data) => request({
  method: 'post',
  url: `/quotations/info/query/page.do`,
  data: JSON.stringify(data)
})

// 关联设备
export const RelevanceDevive = (data) => request({
  method: 'post',
  url: `/quotations/info/query/save.do`,
  data: JSON.stringify(data)
})

// 获取详情分页列表
export const GetQuotesDetailsList = (data) => request({
  method: 'post',
  url: `/quotations/quotes/queryPageByInfo.do`,
  data: JSON.stringify(data)
})