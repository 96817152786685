<template>
  <div class="wrap">
    <div class="login-wrap">
      <el-form
        ref="form"
        :model="loginForm"
      >
        <div class="logo-box">
          <img
            src="/static/image/sign_logo.png"
            class="logo"
          >
          <div class="logo-text">SERVICE SUPPORT CENTER</div>
        </div>
        <el-form-item>
          <el-input
            v-model="loginForm.userAccount"
            placeholder="Username"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="loginForm.password"
            placeholder="Password"
            show-password
            @keypress.enter.native="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="loginForm.lang"
            placeholder="Language"
            @change="langChange"
          >
            <el-option
              value="cn"
              label="简体中文"
            ></el-option>
            <el-option
              value="tw"
              label="繁體中文"
            ></el-option>
            <el-option
              value="en"
              label="English"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            @click="onSubmit"
          >{{$t('login.login')}}</el-button>
        </el-form-item>
      </el-form>
      <img
        class="gif"
        src="/static/image/conversion.png"
      >
      <div class="code-wrap">
        <div class="code-box">
          <!-- <label>苹果应用下载</label> -->
          <img
            class="platform"
            src="/static/image/Apple.png"
          >
          <img
            class="code"
            src="/static/image/code1.png"
          >
        </div>
        <div class="code-box">
          <!-- <label>安卓应用下载</label> -->
          <img
            class="platform"
            src="/static/image/Android.png"
          >
          <img
            class="code"
            src="/static/image/code2.png"
          >
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      Copyright © 1998 - 2021 All Rights Reserved.
    </div>
  </div>
</template>

<script>
// 路由
import { resetRouter } from '@/router'
import { loadRouter } from '@/router/loadRouter'
// 插件
import sha1 from 'js-sha1'
// 全局变量
import { getGlobalData } from '@/utils/getGlobalData.js'
// 接口
import { Login } from '@/api'
export default {
  name: 'Notification',
  data () {
    return {
      loading: false,
      loginForm: {
        userAccount: '',
        password: '',
        lang: localStorage.lang || 'en',
      }
    }
  },
  methods: {
    onSubmit () {
      let param = {
        userAccount: this.loginForm.userAccount.trim(),
        password: sha1(this.loginForm.password),
      };
      this.loading = true;
      Login(param).then(res => {
        if (res.success) {
          let data = JSON.parse(res.data);
          if (data.isSuper) localStorage.isSuper = 1;
          localStorage.permission = data.jurisdictionJson;
          localStorage.accessGroupId = data.accessGroupId === 'undefined' ? '' : data.accessGroupId;

          localStorage.lang = this.loginForm.lang;
          this.$i18n.locale = this.loginForm.lang;
          
          // data.logo = undefined;
          // data.colors = undefined;
          this.$cookies.config(`${data.passwordExpiration}d`)
          this.$cookies.set('fullName', data.fullName)
          this.$cookies.set('userAccount', param.userAccount)
          this.$cookies.set('userId', data.userId)
          this.$cookies.set('logo', data.logo || '')
          this.$cookies.set('theme', data.colors || '')
          this.$cookies.set('token', data.sessionId)
          this.$cookies.set('miPasswordLength', data.miPasswordLength)

          resetRouter();
          loadRouter();
          getGlobalData();
          this.$router.push({ path: '/home' })
        } else {
          if (res.status === 600) this.$message.warning('Wrong User or password. Three times the error, the account will be locked.');
          if (res.status === 601) this.$message.warning('Wrong User or password. Three times the error, the account will be locked.');
          if (res.status === 602) this.$message.warning('Account is locked.');
        }
        this.loading = false;
      }).catch(error => {
        this.loading = false;
      })

    },
    // 切换语言
    langChange (val) {
      this.$i18n.locale = val;
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.login-wrap {
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.el-form {
  box-sizing: border-box;
  width: 460px;
  height: 460px;
  padding: 50px 30px;
  margin-right: 50px;
  border-radius: 5px;
  box-shadow: 0 0px 56px 0px rgba(0, 0, 0, 0.1);
  .logo-box {
    margin: 0 auto 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: auto;
      margin-bottom: 20px;
    }
    .logo-text {
      height: 20px;
      line-height: 20px;
      color: #4c6e94;
    }
  }
  .el-button {
    width: 100%;
    background: #194577;
    color: #fff;
  }
  .el-select {
    width: 100%;
  }
}
.footer-wrap {
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #eeeeee;
}

.code-wrap {
  position: fixed;
  right: 20px;
  bottom: 60px;
  .code-box {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    label {
      text-align: center;
      font-size: 18px;
      color: #cac9c9;
      margin-bottom: 5px;
    }
    img {
      margin: 0 auto;
    }
    .platform {
      width: 138px;
      margin-bottom: 10px;
    }
    .code {
      width: 130px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .login-wrap {
    height: calc(100% - 50px);
  }
  .footer-wrap {
    height: 50px;
    line-height: 50px;
  }
}
</style>