import request from '@/utils/request'

// 查询项目合同列表
export const GetProjectContractList = (data) => request({
  method: 'post',
  url: '/ProjectContract/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改项目合同
export const AddProjectContract = (data) => request({
  method: 'post',
  url: '/ProjectContract/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除项目合同
export const DelProjectContract = (data) => request({
  method: 'post',
  url: '/ProjectContract/del.do',
  data: JSON.stringify(data)
})

// 查询项目合同详情
export const GetProjectContractItem = (data) => request({
  method: 'post',
  url: '/ProjectContract/queryById.do',
  data: JSON.stringify(data)
})

// 获取客户集合
// export const GetClientAll = (data) => request({
//   method: 'post',
//   url: '/sysStores/queryTypeTree.do',
//   data: JSON.stringify(data)
// })

// 获取分支机构集合
// export const GetBranchAll = (data) => request({
//   method: 'post',
//   url: '/sysStoreBrand/queryByStoreId.do',
//   data: JSON.stringify(data)
// })

// 根据公司查询网点
export const GetCompanyBranch = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/queryByCompanyList.do',
  data: JSON.stringify(data)
})

// 查询合同网点
export const GetContractBranch = (data) => request({
  method: 'post',
  url: '/ProjectContractBranch/queryByPage.do',
  data: JSON.stringify(data)
})

// 合同网点删除
export const DelContractBranch = (data) => request({
  method: 'post',
  url: '/ProjectContractBranch/del.do',
  data: JSON.stringify(data)
})

// 获取客户集合
export const GetClientAll = (data) => request({
  method: 'post',
  url: '/ProjectCustomer/queryByCondition.do',
  data: JSON.stringify(data)
})

// 获取网点集合
export const GetBranchAll = (data) => request({
  method: 'post',
  url: '/ProjectOrg/queryByPage.do',
  data: JSON.stringify(data)
})

// 项目网点状态更新
export const ProjectUpdate = (data) => request({
  method: 'post',
  url: '/ProjectContract/updateBrand.do',
  data: JSON.stringify(data)
})

// 获取合同集合
export const GetProjectContractAll = (data) => request({
  method: 'get',
  url: '/ProjectContract/list.do',
  params: data
})

// 获取合同集合
export const GetProjectContractByBranchList = (data) => request({
  method: 'post',
  url: '/ProjectContract/queryByPageBranch.do',
  data: JSON.stringify(data)
})