var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":_vm.dialog.title,"visible":_vm.dialog.visible,"center":"","width":"400px"},on:{"update:visible":function($event){return _vm.$set(_vm.dialog, "visible", $event)}}},[_c('el-form',{ref:"dialogForm",staticClass:"user-form",attrs:{"model":_vm.dialog,"rules":_vm.rules,"size":"small"}},[_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col1'),"prop":"name"}},[_c('el-input',{model:{value:(_vm.dialog.name),callback:function ($$v) {_vm.$set(_vm.dialog, "name", $$v)},expression:"dialog.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.deviceType'),"prop":"type"}},[_c('el-select',{attrs:{"filterable":""},model:{value:(_vm.dialog.type),callback:function ($$v) {_vm.$set(_vm.dialog, "type", $$v)},expression:"dialog.type"}},_vm._l((_vm.types),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.id,"label":item.name}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col2'),"prop":"brandId"}},[_c('my-select',{attrs:{"data":_vm.brands,"value":_vm.dialog.brandId,"size":"small","prop":{
            label:'name',
            value:'id'
          }},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "brandId", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col3'),"prop":"model"}},[_c('el-input',{model:{value:(_vm.dialog.model),callback:function ($$v) {_vm.$set(_vm.dialog, "model", $$v)},expression:"dialog.model"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col4'),"prop":"supplierId"}},[_c('el-select',{attrs:{"multiple":"","filterable":"","collapse-tags":""},on:{"visible-change":_vm.visibleChange},model:{value:(_vm.dialog.supplierId),callback:function ($$v) {_vm.$set(_vm.dialog, "supplierId", $$v)},expression:"dialog.supplierId"}},_vm._l((_vm.suppliers),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.id,"label":item.name}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col7'),"prop":"unit"}},[_c('my-select',{attrs:{"data":_vm.units,"value":_vm.dialog.unit,"size":"small","prop":{
            label:'name',
            value:'id'
          }},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "unit", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col8'),"prop":"status"}},[_c('el-select',{model:{value:(_vm.dialog.status),callback:function ($$v) {_vm.$set(_vm.dialog, "status", $$v)},expression:"dialog.status"}},[_c('el-option',{attrs:{"value":1,"label":"Valid"}}),_c('el-option',{attrs:{"value":0,"label":"Invalid"}})],1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.col9'),"prop":"sync"}},[_c('el-select',{model:{value:(_vm.dialog.sync),callback:function ($$v) {_vm.$set(_vm.dialog, "sync", $$v)},expression:"dialog.sync"}},[_c('el-option',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.option1'),"value":1}}),_c('el-option',{attrs:{"label":_vm.$t('dataMaintenance.deviceType.option2'),"value":0}})],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){_vm.dialog.visible = false}}},[_vm._v(_vm._s(_vm.$t('operation.cancel')))]),_c('el-button',{attrs:{"size":"medium","type":"primary","loading":_vm.dialog.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('operation.save')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }