import VueCookies from 'vue-cookies'

/**
 * 函数防抖
 */
export function debounce(fn, delay) {
  var timer = null;
  var delay = delay || 1;
  return function () {
    var args = arguments;
    var that = this;
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(that, args)
    }, delay);
  }
}

/**
 * 树形数据添加父节点id
 */
export function dealTree(arr, id) {
  let prevId = null;
  arr.forEach(item => {
    if (id !== undefined) {
      item.parentId = id;
    }

    item.prevId = prevId;
    prevId = item.id;

    if (item.children.length > 0) {
      dealTree(item.children, item.id)
    }
  })
}

/**
 * 树形数据清楚空children
 */
export function clearTreeChild(arr, field = 'children') {
  arr.forEach(item => {
    if (item[field].length > 0)
      clearTreeChild(item.children, field)
    else
      delete item[field];
  })
}
/**
 * 文本复制
 */
export function copyText(text) {
  const input = document.createElement("input");
  input.setAttribute("readonly", "readonly");
  input.setAttribute("value", text);
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, -1);
  if (document.execCommand("copy")) {
    document.execCommand("copy");
  }
  document.body.removeChild(input);
}
/**
 * 时间格式化
 */
export function formatDate(date, fmt = 'yyyy-MM-dd HH:mm:ss') {
  if (!date) return '';
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
}
/**
 * 世界时间格式化
 */
export function formatUTCDate(date, fmt = 'yyyy-MM-dd HH:mm:ss') {
  if (!date) return '';
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getUTCFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getUTCMonth() + 1,
    'd+': date.getUTCDate(),
    'H+': date.getUTCHours(),
    'm+': date.getUTCMinutes(),
    's+': date.getUTCSeconds()
  };
  // if (/(y+)/.test(fmt)) {
  //   fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  // }
  // let o = {
  //   'M+': date.getMonth() + 1,
  //   'd+': date.getDate(),
  //   'H+': date.getHours(),
  //   'm+': date.getMinutes(),
  //   's+': date.getSeconds()
  // };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
}
/**
 * 转化本地时间
 */
export function toLocaleDate(date, fmt = 'yyyy-MM-dd HH:mm:ss') {
  if (!date) return '';
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  let dateTime = date.getTime() - (date.getTimezoneOffset() * 60000)
  // let dateTime = date.getTime();
  date = new Date(dateTime);

  return formatDate(date, fmt);
}
/**
 * 转化表格本地时间
 */
export function formatTableData(row, column) {
  return !!row[column.property] ? toLocaleDate(row[column.property]) : '';
}
/**
 * 计算时间
 */
export function computeTime(startDate, endData) {
  //开始时间
  let BirthDay = new Date(startDate);
  // 结束时间
  endData = !!endData ? new Date(endData) : new Date();

  if (BirthDay.getTime() > endData.getTime()) return '0天';

  //获取当前时间
  let timeold = (endData.getTime() - BirthDay.getTime()); //总豪秒数
  let e_daysold = timeold / (24 * 60 * 60 * 1000);
  let daysold = Math.floor(e_daysold); //相差天数
  let e_hrsold = (e_daysold - daysold) * 24;
  let hrsold = Math.floor(e_hrsold); //相差小时数

  //将所获取的时间拼接到一起，再把值显示到页面
  if (localStorage.lang == 'en') {
    return hrsold == 0 ? daysold + " d " : daysold + " d " + hrsold + " hr";
  } else {
    return hrsold == 0 ? daysold + "天" : daysold + "天" + hrsold + "小时";
  }
}
/**
 * 下载文件
 */
export function downFile(data, name) {
  let blob = new Blob([data], {
    type: 'application/x-xls'
  });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  link.click()
}

/**
 * 退出登录
 */
export function logout() {
  sessionStorage.removeItem('tabList')

  localStorage.removeItem('permission')
  localStorage.removeItem('isSuper')
  localStorage.removeItem('accessGroupId')

  VueCookies.keys().forEach(item => {
    VueCookies.remove(item)
  })
}

/**
 * 兼容u-table国际化
 */
export function dealLang() {
  let lang = {
    en: 'No Data',
    cn: '暂无数据',
    tw: '暂无数据',
  }
  Array.from(document.querySelectorAll('.el-table__empty-text')).forEach(item => {
    item.innerHTML = lang[localStorage.lang];
  })
}

/**
 * eltable排序函数
 */
export function sortChange(column, tableData) {
  //点击向上【向下】激发排序　　
  if (column.order !== null && column.prop !== null) { //项目中要求所有列可排序，所以条件进行了判断！==null 即所有列 【如果项目中只有单个列【特定列】存在排序要求，出现混乱，colum.prop == "列的key"即可。】
    var index = column.prop;
    var data = [];
    var samllData = [];
    for (let i = 0; i < tableData.length; i++) { //this.tableDate 为表格总数据，后台请求的数据
      if (tableData[i][index] === null) {}
      if (tableData[i][index] === null || tableData[i][index] === '' || tableData[i][index] === undefined) {
        data.push(tableData[i]);
      } else if (tableData[i][index] <= 0) { //项目中存在金钱数据，返回0.00余数为2的此种数据，所以无法用等于判断，此处进行了大小比较，来抓取0.00这个数据。
        samllData.push(tableData[i])
      } else {
        data.unshift(tableData[i]);
      }
    }
    tableData = data.concat(samllData); //【如此拼接顺序，保证null在前，紧接0，依次增大。】
  }

  return tableData
}