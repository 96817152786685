<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newDevice.contractDialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-row>
        <div class="input-wrap">
          <div class="left-box">
            <el-button-group class="input-group">
              <el-input
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
            </el-button-group>
          </div>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="140"
            prop="signingDate"
            :label="$t('newDevice.contractDialog.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="name"
            sortable
            :label="$t('newDevice.contractDialog.col2')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            min-width="210"
            prop="quotationNumber"
            sortable
            :label="$t('newDevice.contractDialog.col3')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="projectType"
            sortable
            :label="$t('newDevice.contractDialog.col4')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


// 接口
import { GetProjectContractByBranchList } from '@/api/warehouse/projectContract'

export default {
  props: ['visible', 'organic'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '',
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.searchData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },

  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        groupId: this.organic,
      }
      this.tableLoading = true;
      GetProjectContractByBranchList(param).then(res => {
        let toDay = new Date().getTime()
        this.tableData = res.list.map(item => {
          let start = new Date(item.contractStartDate).getTime();
          let end = new Date(item.contractEndDate).getTime();
          if (item.contractStartDate === null) {
            item.state = 4;
          } else if (toDay < start) {
            item.state = 1;
          } else if (toDay < end) {
            item.state = 2;
          } else if (end < toDay) {
            item.state = 3;
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },

    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      this.$emit('submit', this.tableSelected)
      this.dialog.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>