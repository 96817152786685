import { render, staticRenderFns } from "./FaultItem.vue?vue&type=template&id=713db012&scoped=true&"
import script from "./FaultItem.vue?vue&type=script&lang=js&"
export * from "./FaultItem.vue?vue&type=script&lang=js&"
import style0 from "./FaultItem.vue?vue&type=style&index=0&id=713db012&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713db012",
  null
  
)

export default component.exports