<template>
  <div class="wrap">
    <el-card class="card-wrap">
      <page-header title="New Receipt Templates">
        <el-button
          size="medium"
          @click="back"
        >Cancle</el-button>
        <el-button
          size="medium"
          type="primary"
        >Submit</el-button>
      </page-header>
      <el-container>
        <el-aside>
          <div class="header">All Charts</div>
          <div class="chart-menu">
            <div
              class="chart-item"
              v-for="(item,index) in charts"
              :key="index"
            >
              <div class="img"></div>
              <span class="label">{{item}}</span>
            </div>
          </div>
        </el-aside>
        <el-main>
          <el-row :gutter="20">
            <el-col
              :span="8"
              v-for="item in 4"
              :key="item"
            >
              <div class="icon-btn">
                <el-switch
                  v-model="switchVal"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-text="switchVal ? 'Enable' : 'Disable'"
                ></el-switch>
                <el-button
                  size="small"
                  :disabled="!switchVal"
                >ICON...</el-button>
              </div>
              <div class="icon-box"></div>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      switchVal: true,
      charts: ['Area', 'Bar', 'Pie', 'Line', 'Icon', 'Bar', 'Pie', 'Line', 'Icon', 'Bar', 'Pie', 'Line', 'Icon']
    }
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
.card-wrap {
  height: calc(100vh - 130px);
  ::v-deep .el-card__body {
    height: 100%;
  }
  .el-container {
    height: calc(100% - 69px);
    .el-aside {
      width: 200px !important;
      .header {
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
      .chart-menu {
        height: calc(100% - 30px);
        border: 1px solid #dcdfe6;
        padding: 10px;
        overflow: auto;
        .chart-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover {
            background: #dcdfe6;
          }
          .img {
            width: 50px;
            height: 50px;
            background: #f2f2f2;
            margin-right: 10px;
          }
        }
      }
    }
    .el-main {
      .icon-btn {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .icon-box {
        height: 20vw;
        background: #f2f2f2;
      }
    }
  }
}
</style>