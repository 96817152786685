<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('orderStateUpdate.title')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>
      <el-divider content-position="left">{{$t('orderStateUpdate.subtitle1')}}</el-divider>
      <el-form
        size="medium"
        label-width="170px"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item :label="$t('orderStateUpdate.label1')">
              <el-input
                readonly
                v-model="customerName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('orderStateUpdate.label2')">
              <el-input
                readonly
                v-model="arrivalDate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-table border          :data="tableData1"
          v-loading="tableLoading1"
        >
          <el-table-column
            prop="deviceInfoName"
            :label="$t('orderStateUpdate.col2')"
          >
          </el-table-column>
          <el-table-column
            prop="faultDescription"
            :label="$t('orderStateUpdate.col4')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-divider content-position="left">{{$t('orderStateUpdate.subtitle2')}}</el-divider>
      <el-row>
        <el-table border          :data="tableData2"
          v-loading="tableLoading2"
        >
          <el-table-column
            prop="createTime"
            :label="$t('orderStateUpdate.col5')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="serviceUserName"
            :label="$t('orderStateUpdate.col6')"
            sortable
          >
          </el-table-column>
          <!-- <el-table-column
            :label="$t('orderStateUpdate.col7')"
            sortable
          >
            <template>
            </template>
          </el-table-column>
          <el-table-column
            prop="dispatchToday"
            :label="$t('orderStateUpdate.col8')"
            sortable
          >
          </el-table-column> -->
          <el-table-column
            prop="planArrivalTime"
            :label="$t('orderStateUpdate.col9')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="xxx"
            label="实际抵达时间"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-button-group>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row>
        <el-table border          :data="tableData3"
          @selection-change="val => tableSelected3 = val"
          v-loading="tableLoading3"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('orderStateUpdate.col10')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="number"
            :label="$t('orderStateUpdate.col11')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="statusName"
            :label="$t('orderStateUpdate.col12')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="nextHandleTime"
            :label="$t('orderStateUpdate.col13')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="unfinishedReason"
            :label="$t('orderStateUpdate.col14')"
            sortable
          >
          </el-table-column>
          <el-table-column :label="$t('orderStateUpdate.col15')">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="showOrder(scope.row)"
              >{{$t('orderStateUpdate.newFormBtn')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <!-- 新增服务确认单 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('orderStateUpdate.newForm.title')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('orderStateUpdate.newForm.number')"
          prop="number"
        >
          <el-button-group class="input-group">
            <el-input
              v-model="dialog.number"
              class="search-input"
            ></el-input>
            <el-button @click="autoNumber">{{$t('operation.auto')}}</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item
          :label="$t('orderStateUpdate.newForm.status')"
          prop="state"
        >
          <el-select
            class="state-select"
            v-model="dialog.state"
            size="medium"
            filterable
          >
            <el-option
              v-for="item in states"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('orderStateUpdate.newForm.date')"
          prop="date"
        >
          <el-date-picker
            v-model="dialog.date"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('orderStateUpdate.newForm.Reasons')"
          prop="cause"
        >
          <el-input
            type="textarea"
            :rows="4"
            v-model="dialog.cause"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="statusSubmit(1)"
        >{{$t('operation.save')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="statusSubmit(2)"
        >{{$t('operation.saveAdd')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import { GetDistributeInfo } from '@/api/warehouse/submitRequest'
import {
  StatusUpdate,
  AutoConfirmOrderNumber,
  DelConfirmOrder,
} from '@/api/warehouse/serviceConfirmation'
import { GetConfirmOrderStatus } from '@/api/warehouse/serviceConfirmation'

export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      states: [],

      loading: false,
      tableLoading1: false,
      arrivalDate: '',
      customerName: '',
      tableData1: [],

      tableLoading2: false,
      tableData2: [],

      tableLoading3: false,
      tableSelected3: [],
      tableData3: [],

      dialog: {
        visible: false,
        loading: false,
        number: '',
        state: '',
        name: 'zyl',
        date: '',
        cause: '',
      },
      rules: {
        state: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      }
    }
  },
  watch: {

  },
  created () {
    this.getItem();

    // 获取状态
    GetConfirmOrderStatus({}).then(res => this.states = res);
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      // 故障设备
      let param = { id: this.$route.query.id };
      GetDistributeInfo(param).then(res => {
        this.customerName = res.branchName;
        this.arrivalDate = res.requireArrivalTime;
        this.tableData1 = res.workOrderDeviceList;
        this.tableData2 = res.distributionOrderList;
        this.tableData3 = res.confirmOrderList;
      });
    },
    // 删除
    delItem () {
      if (this.tableSelected3.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected3.map(item => item.id).join(',')
        }
        DelConfirmOrder(param).then(res => {
          if (res.success) {
            this.getItem();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    //显示新增
    showAdd () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();

      this.dialog.visible = true;
    },
    // 提交
    statusSubmit (type) {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          workOrderId: this.$route.query.id,
          number: this.dialog.number,
          statusId: this.dialog.state,
          nextHandleTime: this.dialog.date,
          unfinishedReason: this.dialog.cause,
        }
        this.dialog.loading = true;
        StatusUpdate(param).then(res => {
          if (res.success) {
            if (type == 1) {
              this.dialog.visible = false;
            } else {
              this.$refs.dialogForm.resetFields();
            }
            this.getItem();
            this.dialog.loading = false;
            this.$message.success(this.$i18n.t('message.save'))
          }
        })
      })
    },

    // 提交
    submit () {

    },
    // 自动编号
    autoNumber () {
      AutoConfirmOrderNumber().then(res => {
        this.dialog.number = res;
      })
    },
    // 显示新增服务确认单页面
    showOrder (e) {
      this.$router.push({ path: '/home/newServiceConfirmationForm', query: { id: e.id, type: 2 } })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>