<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            size="medium"
            @click="deviceCode"
          >{{$t('inventoryManagement.outWarehouse.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="exportExcel"
          >{{$t('inventoryManagement.outWarehouse.btn2')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="cacelOutWarehouse"
          >{{$t('inventoryManagement.outWarehouse.btn3')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="borrowReturn"
          >{{$t('inventoryManagement.outWarehouse.btn4')}}</el-button>
        </el-button-group>
        <col-set
          @update="getTableData"
          url="/"
          :data.sync="colData"
        ></col-set>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <out-warehouse-table
        ref="outWarehouseTable"
        :table-data="tableData"
        :table-loading="tableLoading"
        :table-selected.sync="tableSelected"
        :permit="permit"
        :table-height="tableHeight"
        :cols="colData"
      ></out-warehouse-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

  </div>
</template>

<script>
// 全局方法
import { downFile } from '@/utils/common'
// 组件
import OutWarehouseTable from './OutWarehouseTable'
import ColSet from '@/components/ColSet'
// 接口
import {
  GetOutWarehouseList,
  DelOutWarehouse,
  ReturnOutWarehouse,
  ExportOutWarehouse,
} from '@/api/warehouse/inventoryManagement'
export default {
  props: ['active'],
  components: {
    OutWarehouseTable,
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      colData: {
        createTime: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col1'),
          visible: true,
        },
        deviceName: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col2'),
          visible: true,
        },
        deviceBrand: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col3'),
          visible: true,
        },
        deviceModel: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col4'),
          visible: true,
        },
        deviceType: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col5'),
          visible: true,
        },
        serialNumbers: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col6'),
          visible: true,
        },
        deviceUnit: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col7'),
          visible: true,
        },
        count: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col8'),
          visible: true,
        },
        receiverName: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col11'),
          visible: true,
        },
        projectName: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col12'),
          visible: true,
        },
        quotationNumber: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col13'),
          visible: true,
        },
        applicationFormNumber: {
          name: '购物申请单',
          visible: true,
        },
        orderNumber: {
          name: '订单编号',
          visible: true,
        },
        outRemark: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col14'),
          visible: true,
        },
        isBorrowOut: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col15'),
          visible: true,
        },
        returnDate: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col16'),
          visible: true,
        },
        returnedTime: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col17'),
          visible: true,
        },
        courierNumber: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col18'),
          visible: true,
        },
        courierCompany: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col19'),
          visible: true,
        },
        courierCost: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col20'),
          visible: true,
        }
      },
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === "InventoryManagement" && this.active === 'outWarehouse') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.outWarehouseTable.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }

    },
    'active': function (newVal) {
      if (newVal === 'outWarehouse') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetOutWarehouseList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 设备二维码
    deviceCode () {
      this.$router.push({
        path: '/home/inventoryDeviceCode',
        query: {
          type: 3,
          id: this.tableSelected.length === 0 ? undefined : this.tableSelected.map(item => item.inventoryId).join(',')
        }
      })
    },
    // 导出Excel
    exportExcel () {
      let param = {
        search: this.searchVal,
        fieldMap: {
          outTime: this.$i18n.t('inventoryManagement.outWarehouse.col1'),
          deviceName: this.$i18n.t('inventoryManagement.outWarehouse.col2'),
          deviceBrand: this.$i18n.t('inventoryManagement.outWarehouse.col3'),
          deviceModel: this.$i18n.t('inventoryManagement.outWarehouse.col4'),
          deviceType: this.$i18n.t('inventoryManagement.outWarehouse.col15'),
          serialNumbers: this.$i18n.t('inventoryManagement.outWarehouse.col6'),
          deviceUnit: this.$i18n.t('inventoryManagement.outWarehouse.col7'),
          count: this.$i18n.t('inventoryManagement.outWarehouse.col8'),
          projectOrg: this.$i18n.t('inventoryManagement.outWarehouse.col9'),
          projectType: this.$i18n.t('inventoryManagement.outWarehouse.col10'),
          receiverName: this.$i18n.t('inventoryManagement.outWarehouse.col11'),
          projectName: this.$i18n.t('inventoryManagement.outWarehouse.col12'),
          quotationNumber: this.$i18n.t('inventoryManagement.outWarehouse.col13'),
          outRemark: this.$i18n.t('inventoryManagement.outWarehouse.col14'),
          isBorrowOut1: this.$i18n.t('inventoryManagement.outWarehouse.col15'),
          returnDate: this.$i18n.t('inventoryManagement.outWarehouse.col16'),
          courierNumber: this.$i18n.t('inventoryManagement.outWarehouse.col18'),
          courierCompany: this.$i18n.t('inventoryManagement.outWarehouse.col19'),
          courierCost: this.$i18n.t('inventoryManagement.outWarehouse.col20'),
        }
      }
      ExportOutWarehouse(param).then(res => {

      })
    },
    // 撤销出库
    cacelOutWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('确认要撤销选择的设备吗？', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: this.tableSelected.map(item => item.id).join(',') }
        DelOutWarehouse(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success('撤销成功')
          }
        })
      }).catch(() => { });
    },
    // 借用归还
    borrowReturn () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (!this.tableSelected.every(item => item.isBorrowOut === 1)) return this.$message.warning('The equipment is not borrowed out of the library and cannot be returned') //该设备不是借用出库，无法归还
      if (!this.tableSelected.every(item => item.isReturned === 0)) return this.$message.warning('Equipment returned')  //设备已归还

      let param = { id: this.tableSelected.map(item => item.id).join(',') }
      ReturnOutWarehouse(param).then(res => {
        if (res.success) {
          this.getTableData();
          this.$message.success('Returned successfully'); //归还成功
          // this.$message.success(this.$i18n.t('message.del'))
        }
      })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('InventoryManagementSearch');
        this.permit.add = permission.includes('InventoryManagementAdd');
        this.permit.edit = permission.includes('InventoryManagementEdit');
        this.permit.del = permission.includes('InventoryManagementDelete');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: calc(100% - 36px - 10px - 32px - 10px);
}
</style>