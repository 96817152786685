<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
          <el-select
            size="medium"
            v-model="address"
            multiple
            @change="searchData"
            filterable
          >
            <el-option
              v-for="item in inWarehouseSites"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table border          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @row-dblclick="rowClick"
        >
          <el-table-column
            min-width="150"
            sortable
            prop="deviceType"
            :label="$t('inventoryStatistics.label1')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="deviceName"
            :label="$t('inventoryStatistics.label2')"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            sortable
            prop="deviceModel"
            :label="$t('inventoryStatistics.label3')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="deviceBrand"
            :label="$t('inventoryStatistics.label4')"
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            prop="deviceUnit"
            :label="$t('inventoryStatistics.label5')"
          >
          </el-table-column>
          <el-table-column
            min-width="200"
            sortable
            prop="deviceCount"
            :label="$t('inventoryStatistics.label6')"
          >
            <template slot-scope="scope">
              {{scope.row.deviceCount + scope.row.outCount}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="200"
            sortable
            prop="outCount"
            :label="$t('inventoryStatistics.label7')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="deviceCount"
            :label="$t('inventoryStatistics.label8')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

  </div>
</template>

<script>
// 接口
import { GetInventoryList } from '@/api/warehouse/inventoryManagement'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'


export default {
  name: 'InventoryStatistics',
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      inWarehouseSites: [],
      address: [],
      searchVal: '',
      tableLoading: false,
      tableData: [],
      tableHeight: null,
    }
  },
  created () {
    // 获取入库地址
    GetDataFieldAll({ parent: '100000000000000000000000000000000015' }).then(res => this.inWarehouseSites = res);

    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        orgId: this.address.join(','),
      }
      this.tableLoading = true;
      GetInventoryList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    rowClick (e) {
      this.$router.push({ path: '/home/deviceDetailsList', query: { id: e.deviceInfoId, type: 2 } })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('InventoryStatisticsSearch');
        this.permit.add = permission.includes('InventoryStatisticsAdd');
        this.permit.edit = permission.includes('InventoryStatisticsEdit');
        this.permit.del = permission.includes('InventoryStatisticsDelete');
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>