<template>
  <table
    cellpadding="0"
    :style="{
      'font-size':elData.style.fontSize + 'px',
      'line-height':elData.style.lineHeight + 'px',
    }"
    :class="'table' +elData.id"
  >
    <thead
      v-if="elData.originData.length !== 0"
      :style="{
        color :elData.style.color ,
        'font-weight':elData.style.fontWeight ,
        'font-style': elData.style.fontStyle,
        'text-decoration': elData.style.textDecoration,
      }"
    >
      <tr v-if="!(elData.tableHeader.length === 1 && elData.tableHeader[0].mainType === '')">
        <td
          v-for="(item,index) in elData.tableHeader"
          :key="index"
          :colspan="item.children.length"
          :style="{
            'height':elData.style.trHeight + 'px',
            'text-align': elData.style.textAlign,
          }"
        >{{item.mainType}}</td>
      </tr>
      <tr>
        <td
          v-for="(item,index) in elData.checkListData"
          :key="index"
          :style="{
            'height': elData.style.trHeight + 'px',
             'text-align': elData.style.textAlign,
             'width': elData.colWidth[index] + 'px',
          }"
        >{{item.checkItem}}

          <div class="slide"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row,rowIndex) in elData.tableData"
        :key="rowIndex"
      >
        <td
          v-for="(item,index) in elData.checkListData"
          :key="index"
          :style="{
              'height':elData.style.trHeight + 'px',
            }"
        >
          <template v-if="elData.checkListData[index].checkTypeItem.type === 4">
            <div
              class="radio-item"
              v-for="radio in elData.checkListData[index].checkTypeItem.options"
              :key="radio.id"
            >
              <div class="radio">
                <div
                  v-if="row[`content${item.index}`] === radio.id"
                  class="black"
                ></div>
              </div>
              <label>{{radio.name}}</label>
            </div>
            <!-- <el-radio-group v-model="row[`content${item.index}`]">

            </el-radio-group> -->
          </template>
          <template v-else>
            {{row[`content${item.index}`]}}
          </template>
          <div class="slide"></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {
    }
  },
  watch: {

  },
  created () {

  },

  methods: {
    initHeight () {
      let len = this.elData.tableData.length + 1;
      let height = len * this.elData.style.trHeight + len + 1;
      this.elData.style.height = height;
    },
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  // height:100%;
  height: calc(100% - 1px);
  border-collapse: collapse;
  background-color: #fff;
  // box-sizing: border-box;
  // margin: 0;
  th,
  td {
    height: inherit;
    border: 1px solid #000;
    padding: 0px;
    position: relative;
  }
  tbody {
    td {
      text-align: center;
    }
  }
}
.slide {
  width: 5px; 
  height: 100%;
  background-color: aquamarine;
  cursor: e-resize;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}
.radio-item {
  width: 80px;
  height: 20px;
  text-align: left;
  padding:0 5px;
  .radio {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000;
    position: relative;
    top: 2px;
  }
  .black {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #000;
    margin: 2px 0 0 2px;
  }
}
</style>