import request from '@/utils/request'

// 查询
export const GetCheckTypeItemList = (data) => request({
  method: 'post',
  url: '/CheckTypeItem/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改
export const AddCheckTypeItem = (data) => request({
  method: 'post',
  url: '/CheckTypeItem/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelCheckTypeItem = (data) => request({
  method: 'post',
  url: '/CheckTypeItem/del.do',
  data: JSON.stringify(data)
})

// 查询集合
export const GetCheckTypeItemAll = (data) => request({
  method: 'post',
  url: '/CheckTypeItem/queryByCondition.do',
  data: JSON.stringify(data)
})

// 删除option
export const DelOption = (data) => request({
  method: 'post',
  url: '/CheckOption/del.do',
  data: JSON.stringify(data)
})

