<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            :disabled="!permit.copy"
            size="medium"
            @click="showCopy"
          >Copy to</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
        <col-set @update="getTableData"></col-set>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          sortable
          prop="name"
          :label="$t('slaPolicies.col1')"
        >
        </el-table-column>
        <el-table-column
          sortable
          prop="typeName"
          :label="$t('slaPolicies.col2')"
        >
        </el-table-column>
        <el-table-column
          sortable
          prop="dag"
          :label="$t('slaPolicies.col3')"
        >
        </el-table-column>
        <el-table-column :label="$t('slaPolicies.col4')">
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.customerName"
              :key="index"
            >{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('slaPolicies.col5')">
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.siteName"
              :key="index"
            >{{item}}</div>
          </template>

        </el-table-column>
        <el-table-column
          sortable
          prop="createTime"
          :label="$t('slaPolicies.col6')"
          :formatter="$formatTableData"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
    <!-- 复制弹框 -->
    <copy-dialog
      :visible.sync="CopyDialogVisible"
      :copy-id="copyId"
    ></copy-dialog>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
import CopyDialog from './CopyDialog'

// 接口
import {
  GetSLAPoliciesList,
  DelSLAPolicies,
} from '@/api/warehouse/slaPolicies'
export default {
  components: {
    ColSet,
    CopyDialog,
  },
  props: ['active'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
        copy: true,
      },
      CopyDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      copyId: null,
    }
  },
  watch: {
    '$route': function (to, form) {
      if (to.name === 'SLAPolicies' && this.active === 'SLAPolicies') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
    'active': function (newVal) {
      if (newVal === 'SLAPolicies') {
        this.getTableData();
        this.$refs.table.doLayout();
      }
    }

  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      };
      this.tableLoading = true;
      GetSLAPoliciesList(param).then((res) => {
        this.tableData = res.list.map(item => {
          if (!!item.customerName) item.customerName = item.customerName.split(',');
          if (!!item.siteName) item.siteName = item.siteName.split(',');
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    showAdd () {
      this.$router.push({ path: '/home/newSLAPolicy' })
    },
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item);
    },
    dblclickEdit (item) {
      sessionStorage.setItem('policyData', JSON.stringify(item));
      this.$router.push({ path: '/home/newSLAPolicy', query: { id: item.id } })
    },
    // 复制
    showCopy () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.copyId = this.tableSelected[0].id;
      this.CopyDialogVisible = true
    },
    // 删除 
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(','),
        }
        DelSLAPolicies(param).then(res => {
          if (res.success) {
            this.getTableData()
            this.$message.success('deleted successfully.')
          }
        }).catch(error => {

        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.search = permission.includes('SLAPolicies-SLAPoliciesSearch');
        this.permit.add = permission.includes('SLAPolicies-SLAPoliciesAdd');
        this.permit.del = permission.includes('SLAPolicies-SLAPoliciesDelete');
        this.permit.copy = permission.includes('SLAPolicies-SLAPoliciesCopy');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>