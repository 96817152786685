import globalVariable from '@/utils/globalVariable.js'

import {
  GetBUAll,
  GetUserStore2,
  GetStoreList,
} from '@/api/stores'
import {
  GetLastOrderDate,
} from '@/api/counterMode'
import {
  GetApprovalBtn
} from '@/api/warehouse/approvalWorkflow'
import {
  GetApprovalWorkflowList
} from '@/api/warehouse/approvalWorkflow'
// 
export function getGlobalData() {
  // GetStoreList().then(res => globalVariable.BusinessData = res)
  // GetUserStore2().then(res => globalVariable.BranchData = res)
  // GetLastOrderDate().then(res => globalVariable.TheLastThirtyMinutes = res)

  GetApprovalWorkflowList({
    limit: 9999,
    page: 1,
  }).then(res => {
    if (res.list.length > 0 && /^PR/.test(res.list[0].workFlowId)) {
      GetApprovalBtn({
        tableId: res.list[0].id
      }).then(res => globalVariable.ExamineData = res)
    }
  })
}
