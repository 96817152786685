var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"bgcolor-wrap"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(_vm.data.bgcolor.subFieldText))]),_c('el-col',{attrs:{"span":16}},[_vm._v(_vm._s(_vm.data.bgcolor.name))])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v("Level")]),_c('el-col',{attrs:{"span":16}},[_vm._v("Threshold Range")])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"level",style:({
          'background':_vm.data.bgcolor.colorL1
        })},[_vm._v("L1")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"level-con",style:({
          'border-color':_vm.data.bgcolor.colorL1
        })},[_c('div',{staticClass:"level-num"},[_c('div',{staticClass:"triangle"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.bgcolor.minL1))])]),_c('span',{staticClass:"con",style:({
            'background':_vm.bgcolor1
          })},[_vm._v(_vm._s(_vm.num1))]),_c('div',{staticClass:"level-num"},[_c('div',{staticClass:"triangle next"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.bgcolor.maxL1))])])])])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"level",style:({
        'background':_vm.data.bgcolor.colorL2
      })},[_vm._v("L2")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"level-con",style:({
          'border-color':_vm.data.bgcolor.colorL2
        })},[_c('div',{staticClass:"level-num"},[_c('div',{staticClass:"triangle"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.bgcolor.minL2))])]),_c('span',{staticClass:"con",style:({
            'background':_vm.bgcolor2
          })},[_vm._v(_vm._s(_vm.num2))]),_c('div',{staticClass:"level-num"},[_c('div',{staticClass:"triangle next"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.bgcolor.maxL2))])])])])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"level",style:({
        'background':_vm.data.bgcolor.colorL3
      })},[_vm._v("L3")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"level-con",style:({
          'border-color':_vm.data.bgcolor.colorL3
        })},[_c('div',{staticClass:"level-num"},[_c('div',{staticClass:"triangle"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.bgcolor.minL3))])]),_c('span',{staticClass:"con",style:({
            'background':_vm.bgcolor3
          })},[_vm._v(_vm._s(_vm.num3))]),_c('div',{staticClass:"level-num"},[_c('div',{staticClass:"triangle next"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.data.bgcolor.maxL3))])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }