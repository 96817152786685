<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showAdd"
            :disabled="type == 1"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
            :disabled="type == 1"
          >{{$t('operation.del')}}</el-button>
          <el-button
            disabled
            size="medium"
            @click="importItem"
          >{{$t('newMaintenanceCheckList.tableContent.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="moveItem(1)"
            :disabled="type == 1"
          >{{$t('newMaintenanceCheckList.tableContent.btn2')}}</el-button>
          <el-button
            size="medium"
            @click="moveItem(2)"
            :disabled="type == 1"
          >{{$t('newMaintenanceCheckList.tableContent.btn3')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
            :disabled="type == 1"
          >{{$t('newMaintenanceCheckList.tableContent.btn4')}}</el-button>
          <el-button
            size="medium"
            @click="showCopy"
            :disabled="type == 1"
          >{{$t('newMaintenanceCheckList.tableContent.btn5')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :data="$store.state.checkListData"
        :height="tableHeight"
        :row-class-name="tableRowClassName"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>

        <el-table-column
          min-width="220"
          prop="checkItem"
          :label="$t('newMaintenanceCheckList.tableContent.col2')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="notes"
          :label="$t('newMaintenanceCheckList.tableContent.col3')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="checkTypeItem.checkType"
          :label="$t('newMaintenanceCheckList.tableContent.col4')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newMaintenanceCheckList.tableContent.col5')"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.checkTypeItem.type === 1">
              <el-input
                size="mini"
                disabled
                placeholder="Type here"
              ></el-input>
            </div>
            <div v-if="scope.row.checkTypeItem.type === 2">
              <el-tag
                size="small"
                v-for="(item,index) in scope.row.checkTypeItem.options"
                :key="index"
              >{{item.name}}</el-tag>
            </div>
            <div v-if="scope.row.checkTypeItem.type === 3">
              <el-checkbox
                v-for="(item,index) in scope.row.checkTypeItem.options"
                :key="index"
                :label="item.name"
              ></el-checkbox>
            </div>
            <div v-if="scope.row.checkTypeItem.type === 4">
              <el-radio
                v-for="(item,index) in scope.row.checkTypeItem.options"
                :key="index"
                :label="item.name"
              ></el-radio>
            </div>
            <div v-if="scope.row.checkTypeItem.type === 5">
              {{dateFormats[scope.row.checkTypeItem.options[0].name]}}
            </div>
            <div v-if="scope.row.checkTypeItem.type === 6">
              {{timeFormats[scope.row.checkTypeItem.options[0].name]}}
            </div>
            <div v-if="scope.row.checkTypeItem.type === 7">
              {{scope.row.checkTypeItem.options[1].name}} to {{scope.row.checkTypeItem.options[2].name}}
            </div>
            <div v-if="scope.row.checkTypeItem.type === 8 || scope.row.checkTypeItem.type === 9 || scope.row.checkTypeItem.type === 10">
              <el-input
                size="mini"
                disabled
              >
                <template slot="append">{{scope.row.checkTypeItem.options[0].name}}</template>
              </el-input>
            </div>
            <div v-if="scope.row.checkTypeItem.type === 11">
              {{scope.row.checkTypeItem.options[0].name}}
            </div>
            <div v-if="scope.row.checkTypeItem.type === 12">
              <img
                class="signature"
                src="/static/image/signature.png"
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newMaintenanceCheckList.tableContent.col6')"
        >
          <template slot-scope="scope">
            <el-switch
              :disabled="type == 1"
              size="mini"
              v-model="scope.row.isCount"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newMaintenanceCheckList.tableContent.col7')"
        >
          <div
            class="input-box"
            slot-scope="scope"
          >
            <el-switch
              :disabled="type == 1"
              size="mini"
              v-model="scope.row.relatedDevice"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="addTableContent([scope.row])"
            ></el-switch>
            <i class="el-icon-share"></i>
          </div>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="relatedField"
          label="Related Field "
        >
          <template slot-scope="scope">
            <el-select
              :disabled="!scope.row.relatedDevice"
              size="mini"
              v-model="scope.row.relatedField"
              clearable
              filterable
              @change="addTableContent([scope.row])"
            >
              <el-option
                v-for="(item,index) in fieldData"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          min-width="240"
          prop="mainType"
          :label="$t('newMaintenanceCheckList.tableContent.col1')"
          sortable
        >
        </el-table-column>
      </el-table>
    </el-row>

    <!--  -->
    <check-dialog
      :visible.sync="CheckDialogVisible"
      :data="checkData"
      :is-copy="isCopy"
      :org-id="orgId"
      @submit="submitCheck"
    ></check-dialog>

    <!-- 导入弹框 -->
    <import-dialog
      :visible.sync="ImportDialogVisible"
      @submit="submitImport"
    ></import-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
import CheckDialog from './CheckDialog'
import ImportDialog from './ImportDialog'
// 接口
import {
  DelTableContent,
  AddTableContent,
  GetCheckList,
  AddRowDataList,
} from '@/api/warehouse/maintenance'
export default {
  components: {
    MySelect,
    CheckDialog,
    ImportDialog,
  },
  props: ['active', 'type', 'maintenanceId', 'elementId', 'orgId'],
  data () {
    return {
      CheckDialogVisible: false,
      ImportDialogVisible: false,
      tableLoading: false,
      tableSelected: [],
      tableHeight: null,
      dateFormats: [
        '2021-08-18',
        '2021/08/18',
        '08/18/2021',
        '2021年8月18日',
        '2021-08',
        '08-2021',
      ],
      timeFormats: [
        '10:28:28',
        '10:28:28AM',
      ],
      checkData: null,
      isCopy: false,

      fieldData: [{
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label1'),
        value: 0,
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label2'),
        value: 1,
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label3'),
        value: 2
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label4'),
        value: 3
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label5'),
        value: 4
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label6'),
        value: 5
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label7'),
        value: 6
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label8'),
        value: 7
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label9'),
        value: 8
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label10'),
        value: 9
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label11'),
        value: 10
      }, {
        label: this.$i18n.t('newMaintenanceCheckList.tableContent.field.label12'),
        value: 11
      }],
      relatedField: [
        'deviceInfoName',
        'brandName',
        'deviceTypeName',
        'deviceInfoModel',
        'location',
        'serialNumber',
        'label',
        'deviceId',
        'floor',
        'qrCode',
        'ip',
        'remark',
      ],
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'TableContent' && this.tableHeight === null) {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight;
      }
    }
  },
  created () {
  },
  methods: {
    // 显示新增弹框
    showAdd () {
      this.isCopy = false;
      this.checkData = null;
      this.CheckDialogVisible = true;
    },
    // 新增
    submitCheck (data) {
      if (!!this.checkData && !this.isCopy) {
        let index = this.$store.state.checkListData.findIndex(item => item.id === data.id);
        this.$set(this.$store.state.checkListData, index, data);
      } else {
        this.$store.state.checkListData.push(data);
      }
      if (!!this.elementId) {
        this.addTableContent([data]);
      }
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {

        if (!!this.elementId) {
          let ids = this.tableSelected.map(item => item.id);
          let param = {
            id: ids.join(',')
          }
          DelTableContent(param).then(res => {
            if (res.success) {
              this.$store.state.checkListData = this.$store.state.checkListData.filter(item => !ids.includes(item.id))
              this.resetSn()
            }
          })
        } else {
          let indexs = this.tableSelected.map(item => item.rowIndex);
          this.$store.state.checkListData = this.$store.state.checkListData.filter(item => !indexs.includes(item.rowIndex))
        }


      }).catch(() => { });
    },
    // 导入
    importItem () {
      this.ImportDialogVisible = true;
    },
    // 移动
    moveItem (type) {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let index = this.tableSelected[0].rowIndex;
      if (type === 1 && index !== 0) {
        this.$store.state.checkListData[index].sn -= 1;
        this.$store.state.checkListData[index - 1].sn += 1;
        this.addTableContent([this.$store.state.checkListData[index], this.$store.state.checkListData[index - 1]])
      } else if (type === 2 && index !== this.$store.state.checkListData.length - 1) {
        this.$store.state.checkListData[index].sn += 1;
        this.$store.state.checkListData[index + 1].sn -= 1;
        this.addTableContent([this.$store.state.checkListData[index], this.$store.state.checkListData[index + 1]])
      }
    },
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.isCopy = false;
      this.checkData = e;
      this.CheckDialogVisible = true;
    },
    // 显示复制弹框
    showCopy () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.isCopy = true;
      this.checkData = this.tableSelected[0];
      this.CheckDialogVisible = true;
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    // 
    addTableContent (data) {
      let param = data.map(item => {
        if (!item.id) {
          item.sn = this.$store.state.checkListData.length - 1
        }
        return {
          id: item.id,
          elementId: this.elementId,
          mainType: item.mainType,
          checkItem: item.checkItem,
          checkItemId: item.checkItemId,
          isCount: item.isCount,
          relatedDevice: item.relatedDevice,
          sn: item.sn,
          relatedField: item.relatedField,
          notes: item.notes,
        };
      })
      AddTableContent(param).then(res => {
        if (res.success) {
          this.$emit('update')
        }
      })
    },
    // 导入提交
    submitImport (data) {

      let param = {
        limit: 9999,
        page: 1,
        elementId: this.elementId,
        isRecord: this.type,
        maintenanceId: this.type === 1 ? this.maintenanceId : undefined
      }
      GetCheckList(param).then(res => {

        let relatedDeviceArr = [];
        this.$store.state.checkListData.forEach((item, index) => {
          if (item.relatedDevice) {
            relatedDeviceArr.push(index);
          }
        })

        let param = data.map((device, index) => {
          let rowData = {
            elementId: this.elementId,
            deviceId: device.id,
            sn: index,
          }
          if (!!res.list[index]) {
            rowData.id = res.list[index].id;
            rowData.itemList = res.list[index].itemList.map((item, i) => {
              let colData = {
                contentId: item.contentId,
                content: item.content,
                sn: i,
                isRecord: this.type,
                id: item.id,
              }
              if (relatedDeviceArr.includes(i)) {
                if (this.$store.state.checkListData[i].relatedField !== null) {
                  let prop = this.relatedField[this.$store.state.checkListData[i].relatedField];
                  colData.content = device[prop];
                } else {
                  colData.content = '';
                }
              }
              return colData;
            });
          } else {
            rowData.id = undefined;
            rowData.itemList = this.$store.state.checkListData.map((item, i) => {
              let colData = {
                contentId: item.id,
                content: '',
                sn: i,
                isRecord: this.type,
              }
              if (relatedDeviceArr.includes(i)) {
                if (this.$store.state.checkListData[i].relatedField !== null) {
                  let prop = this.relatedField[this.$store.state.checkListData[i].relatedField];
                  colData.content = device[prop];
                }
              }
              return colData;
            })
          }
          return rowData;
        })

        AddRowDataList(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.import'))
          }
        })
      })
    },
    // 重置序号
    resetSn () {
      let param = this.$store.state.checkListData.map((item, index) => {
        item.sn = index;
        return item;
      })
      AddTableContent(param).then(res => {
        if (res.success) {
          this.$emit('update')
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.table-row {
  height: calc(100% - 46px);
}
.input-box {
  display: flex;
  align-items: center;
  .el-switch {
    margin-right: 5px;
  }
  .el-icon-share {
    font-size: 18px;
    cursor: pointer;
  }
}

.el-tag {
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}
.signature {
  width: 40px;
  margin-top: 5px;
}
</style>