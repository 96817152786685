<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <div v-show="viewType === 1">
        <el-row>
          <div class="input-wrap">
            <el-button-group class="input-group">
              <el-input
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                v-if="isCreate"
                size="medium"
                @click="viewType = 2"
              >{{$t('equipmentDialog3.btn1')}}</el-button>
            </el-button-group>
          </div>
        </el-row>
        <el-row>
          <el-table
            v-loading="tableLoading"
            :data="tableData"
            @row-dblclick="dblclick"
          >
            <el-table-column
              :label="$t('equipmentDialog3.col1')"
              prop="deviceName"
            ></el-table-column>
            <el-table-column
              :label="$t('equipmentDialog3.col2')"
              prop="brandName"
            ></el-table-column>
            <el-table-column
              :label="$t('equipmentDialog3.col3')"
              prop="deviceModel"
            ></el-table-column>
            <el-table-column
              :label="$t('equipmentDialog3.col4')"
              prop="typeName"
            ></el-table-column>
          </el-table>
        </el-row>
        <el-row class="page-wrap">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.total"
            :current-page.sync="page.current"
            @current-change="getTableData"
          >
          </el-pagination>
        </el-row>
      </div>
      <div v-show="viewType === 2">
        <el-form
          :model="dialog"
          :rules="rules"
          ref="dialogForm"
          size="small"
          class="dialog-form"
        >
          <el-form-item
            :label="$t('equipmentDialog3.label1')"
            prop="deviceName"
          >
            <my-select
              :data="deviceNames"
              :value.sync="dialog.deviceName"
              size="small"
              :prop="{
                label:'name',
                value:'name'
              }"
              @change="deviceNameChange"
            ></my-select>
          </el-form-item>
          <el-form-item
            :label="$t('equipmentDialog3.label2')"
            prop="deviceType"
          >
            <my-select
              :disabled="!dialog.deviceName"
              :data="deviceTypes"
              :value.sync="dialog.deviceType"
              size="small"
              :prop="{
                label:'typeName',
                value:'typeName'
              }"
              bold-prop="isLink"
            ></my-select>
          </el-form-item>
          <el-form-item
            :label="$t('equipmentDialog3.label3')"
            prop="deviceBrand"
          >
            <my-select
              :disabled="!dialog.deviceType"
              :data="deviceBrands"
              :value.sync="dialog.deviceBrand"
              size="small"
              :prop="{
                label:'brandName',
                value:'brandName'
              }"
              bold-prop="isLink"
            ></my-select>
          </el-form-item>
          <el-form-item
            :label="$t('equipmentDialog3.label4')"
            prop="deviceModel"
          >
            <my-select
              :disabled="!dialog.deviceBrand"
              :data="deviceModels"
              :value.sync="dialog.deviceModel"
              size="small"
              :prop="{
                label:'model',
                value:'model'
              }"
              bold-prop="isLink"
            ></my-select>
          </el-form-item>
        </el-form>
        <div class="footer-box">
          <el-button
            size="medium"
            @click="viewType = 1"
          >{{$t('operation.cancel')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            :loading="dialog.loading"
            @click="submitCreateDevice"
          >{{$t('operation.confirm')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'

// 接口
// import { GetDeviceList } from '@/api/warehouse/deviceManagement'
import { GetDeviceList } from '@/api/warehouse/dataMaintenance/deviceType'
import {
  GetDeviceNameAll,
  GetDeviceModelAll,
  GetDeviceBrandAll,
  GetDeviceTypeAll,
  AddDevice,
} from '@/api/warehouse/dataMaintenance/deviceType'
export default {
  components: {
    MySelect
  },
  props: ['visible', 'isCreate', 'organic'],
  data () {
    return {
      dialogVisible: false,
      title: '',
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      viewType: 1,
      tableLoading: false,
      tableData: [],

      deviceNames: [],
      deviceTypes: [],
      deviceBrands: [],
      deviceModels: [],
      dialog: {
        loading: false,
        deviceName: '',
        deviceType: '',
        deviceBrand: '',
        deviceModel: '',
      },
      rules: {
        deviceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceBrand: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceModel: [
          { max: 64, message: 'A maximum of 64 characters', trigger: 'blur' },
          { required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.viewType = 1;
        this.title = this.$i18n.t('equipmentDialog3.title1')
        if (this.deviceNames.length === 0) this.initSelect();

        this.searchData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'viewType': function (newVal) {
      switch (newVal) {
        case 1:
          this.title = this.$i18n.t('equipmentDialog3.title1')
          break;
        case 2:
          this.dialog = {
            deviceName: '',
            deviceType: '',
            deviceBrand: '',
            deviceModel: '',
          };
          this.title = this.$i18n.t('equipmentDialog3.title2')
          break;
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        // branchId: this.organic,
        // all: 1,
        deviceTypeId: '',
        isShield: '1',
      }
      this.tableLoading = true;
      GetDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e);
      this.dialogVisible = false;
    },

    submitCreateDevice () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.deviceName,
          typeName: this.dialog.deviceType,
          brandName: this.dialog.deviceBrand,
          model: this.dialog.deviceModel,
        }
        this.dialog.loading = true;
        AddDevice(param).then(res => {
          if (res.success) {
            let data = JSON.parse(res.data)
            this.$emit('submit', data);
            this.dialogVisible = false;
          }
          this.dialog.loading = false;
        })
      });
    },
    // 初始化下拉框 
    initSelect () {
      this.queryDeviceName('');
      this.queryModel('');
      this.queryBrand('');
      this.queryType('');
    },
    // 查询设备名称
    queryDeviceName (query) {
      let param = { name: query }
      GetDeviceNameAll(param).then(res => this.deviceNames = res);
    },
    // 查询设备型号
    queryModel (query) {
      let param = {
        name: this.dialog.deviceName || undefined,
        // model: query
      }
      GetDeviceModelAll(param).then(res => this.deviceModels = res);
    },
    // 查询品牌
    queryBrand (query) {
      let param = {
        name: this.dialog.deviceName || undefined,
        // brand: query
      }
      GetDeviceBrandAll(param).then(res => this.deviceBrands = res);
    },
    // 查询设备类型
    queryType (query) {
      let param = {
        name: this.dialog.deviceName || undefined,
        // type: query
      }
      GetDeviceTypeAll(param).then(res => this.deviceTypes = res);
    },
    deviceNameChange () {
      let param = {
        name: this.dialog.deviceName,
      }
      GetDeviceModelAll(param).then(res => {
        this.deviceModels = res
        this.dialog.deviceModel = res.length > 0 && res[0].isLink === 1 ? res[0].model : '';
      });
      GetDeviceTypeAll(param).then(res => {
        this.deviceTypes = res
        this.dialog.deviceType = res.length > 0 && res[0].isLink === 1 ? res[0].typeName : '';
      });
      GetDeviceBrandAll(param).then(res => {
        this.deviceBrands = res
        this.dialog.deviceBrand = res.length > 0 && res[0].isLink === 1 ? res[0].brandName : '';
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.footer-box {
  text-align: center;
}
</style>