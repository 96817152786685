<template>
  <div class="wrap">
    <el-card class="card-wrap">
      <page-header :title="$t('fieldDisplaySet.title1')">
      </page-header>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table

    border
          :data="tableData"
          :v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            sortable
            :label="$t('fieldDisplaySet.col1')"
            prop="fieldName"
          ></el-table-column>
          <el-table-column
            sortable
            :label="$t('fieldDisplaySet.col2')"
            prop="displayName"
          ></el-table-column>
          <el-table-column
            sortable
            :label="$t('fieldDisplaySet.col3')"
            prop="dataCode"
          ></el-table-column>
          <el-table-column
            sortable
            :label="$t('fieldDisplaySet.col4')"
            prop="globalSettings"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.globalSettings === 1"
                type="success"
                size="small"
              >Enable</el-tag>
              <el-tag
                v-else
                type="danger"
                size="small"
              >Disable</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 添加弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('fieldDisplaySet.dialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        label-width="170px"
        size="small"
        class="dialog-form"
        label-position="left"
      >

        <el-form-item
          :label="$t('fieldDisplaySet.col1')"
          prop="dbKey"
        >
          <el-select v-model="dialog.dbKey">
            <el-option
              v-for="item in fields"
              :key="item.id"
              :value="item.dbKey"
              :label="item.mainFieText"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('fieldDisplaySet.col2')"
          prop="display"
        >
          <el-input v-model="dialog.display"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('fieldDisplaySet.col3')"
          prop="dataCode"
        >
          <el-button-group
            v-for="(item,index) in dialog.dataCode"
            :key="index"
            class="input-group"
          >
            <el-input
              v-model="dialog.dataCode[index]"
              class="search-input"
            ></el-input>
            <el-button
              icon="el-icon-delete"
              @click="dialog.dataCode.splice(index, 1)"
            ></el-button>
          </el-button-group>
          <el-button
            class="add-code"
            icon="el-icon-plus"
            @click="dialog.dataCode.push('')"
          ></el-button>
        </el-form-item>
        <el-form-item
          :label="$t('fieldDisplaySet.col4')"
          prop="global"
        >
          <el-switch
            v-model="dialog.global"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="dialog.global?'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  GetFieldDisplayList,
  AddFieldDisplay,
  DelFieldDisplay,
  fieldDisplaySet,
} from '@/api/reportDesigner'

import {
  GetTemplateParam
} from '@/api/counterMode'
export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      templatesId: null,
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      fields: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        dbKey: '',
        display: '',
        dataCode: [''],
        global: 0,
      },
      rules: {
        dbKey: [{ required: true, message: 'Please enter an Field Name.', trigger: 'blur' }],
      },
    }
  },
  computed: {
    // 当前选中的字段
    currentField: function () {
      let item = this.fields.filter(item => item.dbKey === this.dialog.dbKey);
      return item.length > 0 ? item[0] : null;
    },
  },
  created () {
    this.templatesId = this.$route.query.id;
    this.getTableData();
    this.getTemplateParam();

  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取字段集合
    getTemplateParam () {
      let param = {
        fieIdType: 0,
        templatesId: this.templatesId,
      }
      GetTemplateParam(param).then(res => this.fields = res)
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        templatesId: this.templatesId,
      }
      this.tableLoading = true;
      GetFieldDisplayList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增
    showAdd () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.visible = true;
    },
    // 显示修改 
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclick(item)
    },
    dblclick (item) {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.visible = true;

      this.$nextTick(() => {
        Object.assign(this.dialog, {
          id: item.id,
          dbKey: item.dbKey,
          display: item.displayName,
          dataCode: item.dataCodes,
          global: item.globalSettings,
        })
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelFieldDisplay(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          templatesId: this.templatesId,
          fieldName: this.currentField.subFieldText || this.currentField.mainFieText,
          dbKey: this.dialog.dbKey,
          displayName: this.dialog.display,
          dataCodes: this.dialog.dataCode,
          globalSettings: this.dialog.global,
        }
        let msg = this.$i18n.t('message.add');
        if (this.dialog.id) {
          param.id = this.dialog.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddFieldDisplay(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.getTableData();
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.input-group {
  margin-bottom: 18px;
}
.add-code {
  width: 100%;
}
.dialog-form {
  .el-switch {
    width: 100px;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
}
</style>