<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="userForm"
        label-width="170px"
        size="small"
        class="user-form"
        label-position="left"
      >
        <el-form-item
          label="Templeates Name"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          label="Brand"
          prop="brand"
        >
          <el-select v-model="dialog.brand">
            <el-option
              v-for="item in brands"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Description"
          prop="description"
        >
          <el-input v-model="dialog.description"></el-input>
        </el-form-item>
        <el-form-item prop="dataAccessGroup">
          <div slot="label">
            Data Access Group <br>
            (Data Permissions)
          </div>
          <el-select v-model="dialog.dataAccessGroup">
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Set as default">
          <el-switch
            v-model="dialog.isDefault"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="dialog.isDefault ? 'Enable':'Disable'"
            :disabled="dialog.isDefault"
          ></el-switch>
          <lock-module :state="dialog.isDefault"></lock-module>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >Save</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import LockModule from '@/components/LockModule'

// 接口
import {
  GetDataAccessGroupAll,
  GetBrandAll,
} from '@/api/organizations'

export default {
  components: {
    LockModule,
  },
  props: ['visible'],
  data () {
    return {
      dataAccessGroups: [],
      brands: [],
      dialog: {
        visible: false,
        loading: false,
        title: 'Save as new Templates',
        name: '',
        brand: '',
        description: '',
        dataAccessGroup: '',
        isDefault: false,
      },
      rules: {
        // user: [{ required: true, message: 'Please enter an User Account.', trigger: 'blur' }],

      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;
        this.activeTab = 'UserInformation';

        if (this.userData) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
            })
          })
        } else {

        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
    // 获取品牌集合
    GetBrandAll().then(res => this.brands = res)

  },
  methods: {
    // 提交
    submit () {
      this.$refs.userForm.validate(valid => {
        if (!valid) return;
        let param = {

        }

      })
    },


  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>