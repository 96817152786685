<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('allocateGoods.setLock.title1')"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-row :gutter="20">
        <el-col
          class="align-right"
          :span="8"
        >{{$t('allocateGoods.setLock.label1')}}：</el-col>
        <el-col :span="12">
          <el-input-number
            size="small"
            type="number"
            v-model="dialog.number"
            :controls="false"
            :min="0"
            :max="applyNum"
          ></el-input-number>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          class="align-right"
          :span="8"
        >{{$t('allocateGoods.setLock.label2')}}：</el-col>
        <el-col :span="12">
          <el-input
            size="small"
            type="textarea"
            v-model="dialog.cause"
          ></el-input>
        </el-col>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="getLog"
        >{{$t('allocateGoods.setLock.btn1')}}</el-button>
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('allocateGoods.setLock.title2')"
      :visible.sync="log.visible"
      center
      width="600px"
    >
      <el-row
        :gutter="20"
        v-for="(item,index) in log.data"
        :key="index"
      >
        <el-col :span="8">{{item.createTime}}</el-col>
        <el-col :span="8">{{item.fullname}}</el-col>
        <el-col :span="8">{{$t('allocateGoods.setLock.label3')}}{{item.count}}</el-col>
        <el-col :span="24">{{item.reason}}</el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetSetLockLog,
  AddSetLock,
} from '@/api/warehouse/purchaseRequisition'
export default {
  props: ['visible', 'applyId', 'deviceId', 'lockNum', 'applyNum'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        number: 0,
        cause: '',
      },
      log: {
        visible: false,
        data: null,
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.dialog.number = this.lockNum;
        this.dialog.cause = '';
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getLog () {
      let param = {
        applicationFormId: this.applyId,
        deviceInfoId: this.deviceId,
      }
      GetSetLockLog(param).then(res => {
        if (res.length > 0) {
          this.log.data = res;
          this.log.visible = true;
        } else {
          this.$message.warning('无锁定记录');
        }
      })
    },
    submit () {
      let param = {
        applicationFormId: this.applyId,
        deviceInfoId: this.deviceId,
        count: this.dialog.number,
        reason: this.dialog.cause,
      }
      AddSetLock(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.save'));
          this.dialog.visible = false;
          this.$emit('update')
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.align-right {
  text-align: right;
}
.el-input-number {
  width: 100%;
  ::v-deep input {
    width: 100%;
    text-align: left;
  }
}
</style>