import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/pages/login/login'
import Pdf from '@/pages/pdf/'


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const createRouter = () => new Router({
  routes: [{
      path: '/',
      redirect: '/home'
    }, {
      path: '/login',
      name: 'login',
      component: Login
    }, {
      path: '/pdf',
      name: 'pdf',
      component: Pdf
    }, 
  ]
})

const router = createRouter()

export function resetRouter() {
  let newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router
