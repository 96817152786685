<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <div class="content">

        <div class="top-box">
          <div class="number-box">
            <el-divider content-position="left">入库数量</el-divider>
            <el-form
              :model="groupForm"
              :rules="rules"
              ref="groupForm"
              class="group-form"
              size="small"
              label-width="200px"
            >
              <el-form-item label="可用库存/预分配/已入库">
                <div class="display-flex">
                  <el-input
                    disabled
                    v-model="currentRow.inventoryCount"
                  ></el-input>
                  <el-input
                    disabled
                    v-model="currentRow.allPreCount"
                  ></el-input>
                  <el-input
                    disabled
                    v-model="groupForm.inCount"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item :label="$t('addOrder.label4')">
                <div class="display-flex">
                  <el-input
                    disabled
                    v-model="currentRow.outCount"
                  ></el-input>
                  <el-input
                    disabled
                    v-model="currentRow.outCount2"
                  ></el-input>
                  <el-input
                    disabled
                    v-model="currentRow.outCount3"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item :label="$t('addOrder.label1')">
                <el-input
                  disabled
                  v-model="currentRow.appCount"
                ></el-input>
              </el-form-item>
              <el-form-item label="已预分配库存数量">
                <el-input
                  disabled
                  v-model="currentRow.preCount"
                ></el-input>
              </el-form-item>
              <el-form-item label="已订货数量">
                <el-input
                  disabled
                  v-model="currentRow.orderCount"
                ></el-input>
              </el-form-item>
              <el-form-item label="待订货数量">
                <el-input
                  v-model="groupForm.unorderCount"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="本次入库数量">
                <el-input-number
                  class="bg-success"
                  :controls="false"
                  :max="groupForm.numberMax"
                  :min="0"
                  v-model="groupForm.deviceCount"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="入库地址"
                prop="inOrgId"
              >
                <el-select
                  v-model="groupForm.inOrgId"
                  filterable
                >
                  <el-option
                    v-for="item in inWarehouseSites"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="入库方式"
                prop="inTypeId"
              >
                <el-select
                  v-model="groupForm.inTypeId"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in inWarehouseTypes"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="仓库位置">
                <el-input v-model="groupForm.warehouseLocation"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="full-width"
                  @click="showAdd"
                >确认并新增</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class=" table-box fill-height">
            <el-divider content-position="left">已订货项目</el-divider>
            <el-row>
              <div class="input-wrap">
                <el-button-group class="input-group">
                  <el-input
                    v-model="searchVal"
                    placeholder="Search"
                    class="search-input"
                    size="medium"
                    clearable
                  ></el-input>
                  <el-button
                    size="medium"
                    @click="searchData"
                  >{{$t('operation.search')}}</el-button>
                </el-button-group>
              </div>
            </el-row>
            <el-row
              class="table-row"
              ref="tableRow"
            >
              <el-table
                :data="tableData"
                :height="tableHeight"
                v-loading="tableLoading"
                highlight-current-row
                @current-change="handleCurrentChange"
              >
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col1')"
                  prop="typeName"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col2')"
                  prop="deviceName"
                >
                </el-table-column>

                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col3')"
                  prop="brandName"
                >
                </el-table-column>
                <el-table-column
                  min-width="180"
                  sortable
                  :label="$t('addOrder.table1.col4')"
                  prop="deviceModel"
                >
                </el-table-column>
                <el-table-column
                  min-width="190"
                  sortable
                  :label="$t('addOrder.table1.col5')"
                  prop="count"
                >
                </el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  :label="$t('addOrder.table1.col6')"
                  prop="orderCount"
                >
                </el-table-column>
                <el-table-column
                  min-width="160"
                  label="待订货数"
                >
                  <template slot-scope="scope">
                    <span v-text="scope.row.count - scope.row.orderCount < 0 ? 0:scope.row.count - scope.row.orderCount"></span>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  label="到货数"
                  prop="arrivalCount"
                ></el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  label="已入库数"
                  prop="inCount"
                ></el-table-column>
                <el-table-column
                  min-width="230"
                  sortable
                  :label="$t('addOrder.table1.col7')"
                  prop="applicationNumber"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col8')"
                  prop="projectName"
                  show-overflow-tooltip
                >
                </el-table-column>

              </el-table>
            </el-row>
            <div class="radio-box">
              <div class="label-item">
                <label>是否有序列号</label>
                <el-radio-group v-model="groupForm.isHasSerialNumber">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div class="label-item">
                <label>新设备</label>
                <el-radio-group v-model="groupForm.isNewDevice">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div class="label-item">
                <label>锁定</label>
                <el-radio-group v-model="groupForm.isLock">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div class="label-item">
                <label>借用厂家设备</label>
                <el-radio-group
                  v-model="groupForm.isBorrowFactoryDevice"
                  @change="isBorrowChange"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div class="label-item">
                <label>归还日期</label>
                <el-date-picker
                  size="small"
                  v-model="groupForm.returnDate"
                  type="date"
                  :disabled="groupForm.isBorrowFactoryDevice === 0"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="label-item fill-width">
              <label>入库备注</label>
              <el-input
                type="textarea"
                v-model="groupForm.inRemark"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="bottom-box">
          <el-row>
            <div class="input-wrap">
              <div class="btn-wrap">
                <el-button-group class="input-group">
                  <el-input
                    v-model="searchVal2"
                    placeholder="Search"
                    class="search-input"
                    size="medium"
                    clearable
                  ></el-input>
                  <el-button
                    size="medium"
                    @click="searchData2"
                  >{{$t('operation.search')}}</el-button>
                  <el-button
                    size="medium"
                    @click="showEdit"
                  >{{$t('operation.edit')}}</el-button>
                  <el-button
                    size="medium"
                    @click="delItem"
                  >{{$t('operation.del')}}</el-button>
                </el-button-group>
              </div>

              <my-page
                layout="prev, pager, next, jumper"
                :total="page.total"
                :page-size.sync="page.size"
                :current-page.sync="page.current"
                @current-change="getTableData"
                @size-change="getTableData"
              >
              </my-page>
            </div>
          </el-row>
          <el-row
            class="order-row"
            ref="orderRow"
          >
            <el-table
              :data="orderData"
              :height="orderHeight"
              :row-class-name="tableRowClassName"
              @selection-change="val => orderSelected = val"
              @row-dblclick="dblclickEdit"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col1')"
                prop="typeName"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col2')"
                prop="deviceName"
              >
              </el-table-column>

              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col3')"
                prop="brandName"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col4')"
                prop="deviceModel"
              >
              </el-table-column>
              <el-table-column
                min-width="120"
                :label="$t('addOrder.table2.col5')"
                prop="count"
              >
              </el-table-column>
              <el-table-column
                min-width="100"
                :label="$t('addOrder.table2.col6')"
                prop="singlePrice"
              >
              </el-table-column>

              <el-table-column
                min-width="130"
                label="入库数量"
                prop="deviceCount"
              >
              </el-table-column>
              <el-table-column
                min-width="200"
                label="序列号"
                prop="expecteBuyBackTime"
              >
                <div
                  class="serial-wrap"
                  slot-scope="scope"
                >
                  <div class="serial-box">
                    <div
                      v-for="(item,index) in scope.row.serialNumbers"
                      :key="index"
                    >{{item.serialNumber}}</div>
                  </div>
                  <el-button
                    size="mini"
                    @click="showNumber(scope.$index)"
                  >编辑</el-button>
                </div>
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col7')"
                prop="inRemark"
              >
              </el-table-column>
              <el-table-column
                min-width="200"
                :label="$t('addOrder.table2.col8')"
                prop="expecteBuyBackTime"
              >
              </el-table-column>

              <el-table-column
                min-width="210"
                :label="$t('addOrder.table2.col9')"
                prop="applicationNumber"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col11')"
                prop="purchaseUser"
              >
              </el-table-column>

            </el-table>
          </el-row>
        </div>
      </div>
    </el-card>
    <!-- 序列号弹框 -->
    <serial-dialog
      :visible.sync="SerialDialogVisible"
      :data="serialNumbers"
      :max-count="maxCount"
      @submit="serialSubmit"
    ></serial-dialog>
    <!-- 编辑弹框 -->
    <in-warehouse-dialog
      :visible.sync="InWarehouseDialogVisible"
      :data="currentOrder"
      :inWarehouseSites="inWarehouseSites"
      :inWarehouseTypes="inWarehouseTypes"
      @submit="editSubmit"
    ></in-warehouse-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import SerialDialog from '@/components/SerialDialog'
import InWarehouseDialog from './components/InWarehouseDialog'
// 接口
import { GetApplyDeviceList } from '@/api/warehouse/purchaseRequisition'
import { AddBatchInWarehouse } from '@/api/warehouse/inventoryManagement'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'BatchInWarehouse',
  components: {
    PageHeader,
    SerialDialog,
    InWarehouseDialog,
  },
  data () {
    return {
      SerialDialogVisible: false,
      InWarehouseDialogVisible: false,
      title: '批量入库',
      inWarehouseSites: [],
      inWarehouseTypes: [],

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableHeight: null,
      tableData: [],

      searchVal2: '',
      orderHeight: null,
      orderSelected: [],
      orderData: [],
      serialNumbers: [],
      groupForm: {
        deviceCount: 0,
        inCount: '',
        numberMax: 0,
        inOrgId: '',
        inTypeId: '',
        warehouseLocation: '',
        unorderCount: 0,
        isHasSerialNumber: 0,
        isNewDevice: 1,
        isLock: 0,
        isBorrowFactoryDevice: 0,
        returnDate: null,
        inRemark: '',
        serialNumbers: [],
      },
      rules: {
        // inOrgId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // inTypeId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      currentRow: {},
      currentOrder: {},
      maxCount: 0,
    }
  },
  watch: {
    'groupForm.inOrgId': function (newVal) {
      localStorage.batchInWarehouse_inOrgId = newVal
    },
    'groupForm.inTypeId': function (newVal) {
      localStorage.batchInWarehouse_inTypeId = newVal
    },
  },
  created () {
    // 获取入库地址
    GetDataFieldAll({ parent: '100000000000000000000000000000000015' }).then(res => {
      this.inWarehouseSites = res
      if (this.inWarehouseSites.some(item => item.id === localStorage.batchInWarehouse_inOrgId)) {
        this.groupForm.inOrgId = localStorage.batchInWarehouse_inOrgId;
      } else {
        this.groupForm.inOrgId = '';
      }
    });

    // 获取入库方式
    GetDataFieldAll({ parent: '100000000000000000000000000000000016' }).then(res => {
      this.inWarehouseTypes = res
      if (this.inWarehouseTypes.some(item => item.id === localStorage.batchInWarehouse_inTypeId)) {
        this.groupForm.inTypeId = localStorage.batchInWarehouse_inTypeId;
      } else {
        this.groupForm.inTypeId = '';
      }
    });
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.orderHeight = this.$refs.orderRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        isBatch: '1',
        isInventory: '1',
        isAbnormal: '1',
      };
      this.tableLoading = true;
      GetApplyDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      });
    },
    searchData () {
      this.page.current = 1;
      this.getTableData()
    },
    searchData2 () {
      // this.page.current = 1;
      // this.getTableData()
    },
    // 
    handleCurrentChange (val) {
      if (!val) return this.currentRow = {}
      this.currentRow = val;
      this.currentRow.appCount = this.currentRow.count;
      this.currentRow.stockout = this.currentRow.count > this.currentRow.inventoryCount
        ? this.currentRow.count - this.currentRow.inventoryCount
        : 0

      this.groupForm.inCount = this.currentRow.inCount + this.getTotal(this.currentRow.id)
      this.groupForm.numberMax = this.currentRow.orderCount - this.groupForm.inCount;
      this.groupForm.deviceCount = this.groupForm.numberMax;
      this.groupForm.unorderCount = this.currentRow.appCount - this.currentRow.count;

    },
    // 
    showAdd () {
      if (!this.currentRow.appCount) return this.$message.warning('Please select one item.')
      if (this.groupForm.deviceCount == 0) return;

      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        let data = JSON.parse(JSON.stringify(Object.assign(this.currentRow, this.groupForm)));
        this.orderData.push(data);
        this.groupForm.inCount += this.groupForm.deviceCount;
        this.groupForm.numberMax -= this.groupForm.deviceCount;
        this.groupForm.deviceCount = this.groupForm.deviceCount > this.groupForm.numberMax ? this.groupForm.numberMax : this.groupForm.deviceCount;

      });
    },
    showEdit () {
      if (this.orderSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.orderSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.dblclickEdit(this.orderSelected[0]);
    },
    dblclickEdit (e) {
      this.currentOrder = e;
      this.InWarehouseDialogVisible = true;
    },
    editSubmit (data) {
      let index = this.orderData.findIndex(item => item.id === data.id);
      let item = this.orderData[index];
      Object.assign(item, data);
      this.$set(this.orderData, this.tableIndex, item);
      this.$message.success(this.$i18n.t('message.edit'))

      if (data.id === this.currentRow.id) {
        this.handleCurrentChange(this.currentRow);
      }
    },
    delItem () {
      if (this.orderSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.orderSelected.map(item => item.rowIndex);
        this.orderData = this.orderData.filter(item => !checkIndex.includes(item.rowIndex));

        this.handleCurrentChange(this.currentRow);
      }).catch(() => { });
    },
    submit () {
      if (this.orderData.length === 0) return this.$message.warning('Please select one item.');
      if (this.orderData.some(item => item.isHasSerialNumber === 1 && item.serialNumbers.length !== item.deviceCount)) return this.$message.warning('序列号与设备数量有误差.');

      let param = this.orderData.map(item => {
        return {
          inOrgId: item.inOrgId,
          inTypeId: item.inTypeId,
          projectContractId: item.projectContractId,
          applicationFormNumber: item.applicationNumber,
          purchaseUserId: item.purchaseUserId,
          purchaseUser: item.purchaseUser,
          orderFormId: item.orderFormId,
          deviceInfoId: item.deviceInfoId,
          deviceCount: item.deviceCount,
          deviceUnit: item.unit,
          devicePrice: item.singlePrice,
          devicePriceUnit: item.singlePriceUnit,
          isNewDevice: item.isNewDevice,
          isHasSerialNumber: item.isHasSerialNumber,
          serialNumbers: item.serialNumbers.map(item => item.serialNumber).join(';'),
          supplierId: item.supplierId,
          inRemark: item.inRemark,
          warehouseLocation: item.warehouseLocation,
          isBorrowFactoryDevice: item.isBorrowFactoryDevice,
          returnDate: item.returnDate,
          isReturned: 0,
          returnedTime: null,
          deviceName: item.deviceName,
          deviceModel: item.deviceModel,
          deviceBrand: item.brandName,
          deviceType: item.typeName,
          supplierName: item.supplierName,
          isLock: item.isLock,
          // orderDeviceInfoId: item.orderDeviceInfoId,
          applicationFormInfoId: item.id,
          inventoryDevices: item.serialNumbers.map(item => {
            return {
              serialNumber: item.serialNumber
            }
          })
        }
      })
      AddBatchInWarehouse(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.add'))
          this.back();
        }
      })

    },
    // 显示序号弹框 
    showNumber (index) {
      this.tableIndex = index;
      this.serialNumbers = JSON.parse(JSON.stringify(this.orderData[index].serialNumbers));
      this.maxCount = this.orderData[index].deviceCount;
      this.SerialDialogVisible = true;
    },
    // 提交序号
    serialSubmit (data) {
      let item = this.orderData[this.tableIndex];
      item.serialNumbers = data;
      this.$set(this.orderData, this.tableIndex, item)
    },
    getTotal (_id) {
      let total = 0;
      this.orderData.forEach(item => {
        if (item.id === _id) {
          total += item.deviceCount;
        }
      })
      return total;
    },
    // 
    isBorrowChange () {
      this.groupForm.returnDate = null;
    },

    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
label {
  width: 100%;
  height: 32px;
  line-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex {
  display: flex;

  .el-input:first-child,
  .el-input-number:first-child {
    margin-left: 0px;
  }
  .el-input,
  .el-input-number {
    margin-left: 10px;
  }
}
.content {
  height: calc(100% - 36px - 20px);
}
.top-box {
  height: 65%;
  display: flex;
}
.bottom-box {
  height: 35%;
}
.fill-height {
  height: 100%;
}
.table-row {
  height: calc(100% - 220px);
}
.order-row {
  height: calc(100% - 46px);
}
.number-box {
  width: 380px;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 14px;
  .el-input-number,
  .el-input {
    flex: 1;
    width: 100%;
    ::v-deep .el-input__inner {
      padding: 0 5px;
      text-align: center;
    }
  }
  .el-button {
    margin-left: 10px;
  }
}
.table-box {
  width: calc(100% - 400px);
}

.el-radio-group {
  width: 100%;
  display: flex;
  label {
    margin-right: 10px;
  }
}
.full-width {
  width: 100%;
  margin-left: 0 !important;
}
.btn-wrap {
  display: flex;
  flex-direction: row;
}
.switch-box {
  width: 200px;
  height: 36px;
  line-height: 36px;
  margin-left: 20px;
  label {
    font-size: 14px;
    margin-right: 10px;
  }
}
.radio-box {
  display: flex;
  margin-bottom: 10px;
}
.label-item {
  width: 25%;
  font-size: 14px;
  display: flex;
  // align-items: center;
  label {
    width: 90px;
    flex-shrink: 0;
    padding-right: 10px;
  }
  .el-radio-group label {
    width: auto;
  }
  .el-input,
  .el-radio-group,
  .el-date-editor {
    width: calc(100% - 100px);
  }
}
.fill-width {
  width: 100%;
}

.serial-wrap {
  display: flex;
  align-items: center;
  .el-button {
    margin-left: 10px;
  }
}

.group-form {
  .el-form-item {
    margin-bottom: 10px;
  }
}

.bg-success {
  ::v-deep input {
    background-color: #caf982;
  }
}
</style>