<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
        label-position="left"
      >

        <el-form-item
          label="其他项名称"
          prop="name"
        >
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="dialog.name"
            :fetch-suggestions="querySearch"
          >
            <template slot-scope="{ item }">
              <span>{{ item.name }}</span>
              <i
                class="el-icon-error"
                @click.stop="delItem(1,item,index)"
              ></i>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          label="型号"
          prop="model"
        >
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="dialog.model"
            :fetch-suggestions="querySearch2"
          >
            <template slot-scope="{ item }">
              <span>{{ item.name }}</span>
              <i
                class="el-icon-error"
                @click.stop="delItem(2,item,index)"
              ></i>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          label="数量"
          prop="count"
        >
          <el-input-number
            v-model="dialog.count"
            :controls="false"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="单价"
          prop="singlePrice"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input-number
                v-model="dialog.singlePrice"
                :controls="false"
                :min="0"
              ></el-input-number>
            </el-col>
            <el-col :span="12">
              <el-input
                disabled
                value="RMB"
              ></el-input>
            </el-col>
          </el-row>

        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dialog.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  AddOtherName,
  AddOtherModel,
  DelOtherItem,
  GetOtherName,
  GetOtherModel,
} from '@/api/warehouse/purchaseOrders'

export default {
  props: ['visible', 'data'],
  data () {
    return {
      otherNames: [],
      otherModels: [],
      dialog: {
        visible: false,
        loading: false,
        title: '订购数量和价格编辑',
        id: null,
        name: '',
        model: '',
        count: 0,
        singlePrice: 0,
        singlePriceUnit: 1,
        remark: '',
      },
      rules: {
      },

    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();

        this.dialog.visible = this.visible;
        this.dialog.name = '';
        this.dialog.model = '';
        if (this.data) {
          Object.assign(this.dialog, this.data);
          this.dialog.title = '编辑其他项';
        } else {
          this.dialog.title = '新建其他项';
        }

        GetOtherName().then(res => this.otherNames = res.map(item => {
          item.value = item.name;
          return item;
        }));
        GetOtherModel().then(res => this.otherModels = res.map(item => {
          item.value = item.name;
          return item;
        }));
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        if (!!this.dialog.name) {
          AddOtherName({ name: this.dialog.name })
        }
        if (!!this.dialog.model) {
          AddOtherModel({ name: this.dialog.model })
        }
        this.$emit('submit', {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? 'edit' : 'add',
        });
        this.dialog.visible = false;
      });
    },
    querySearch (queryString, cb) {
      cb(this.otherNames);
    },
    querySearch2 (queryString, cb) {
      cb(this.otherModels);
    },
    delItem (data) {
      let param = { id: data.id }
      DelOtherItem(param).then(res => {
        if (res.success) {
          this.templates.splice(index, 1);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  ::v-deep .el-form-item__label {
    width: 100%;
  }
  .el-autocomplete {
    width: 100%;
  }
}
// .select-label {
//   width: calc(100% - 44px);
//   height: 34px;
//   line-height: 34px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: normal;
//   display: inline-block;
// }
// .select-icon {
//   width: 34px;
//   height: 34px;
//   line-height: 34px;
//   text-align: center;
//   display: inline-block;
//   vertical-align: top;
// }
</style>
