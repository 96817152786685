<template>
  <div class="wrap">
    <el-card>
      <page-header title="Import Stores">
      </page-header>

      <div class="radio-wrap">
        <div class="radio-left">
          <el-row>
            <el-radio
              v-model="radio"
              :label="1"
            >Import from a file</el-radio>
            <el-button
              size="medium"
              @click="$refs['file'].click()"
            >Browse...</el-button>
            <input
              ref="file"
              type="file"
              v-show="false"
              @change="fileChange"
            >
          </el-row>
          <el-row>
            <el-radio
              v-model="radio"
              :label="2"
            >Import from Table Name</el-radio>
          </el-row>
          <el-row>
            <el-select size="medium"> </el-select>
          </el-row>
        </div>
        <div class="radio-right">
          <el-row class="name-box">Selected Group：Name</el-row>
          <el-row>
            Fields
          </el-row>
          <el-row>
            <el-select size="medium"> </el-select>
          </el-row>
        </div>
      </div>
      <el-row>
        Store List
      </el-row>
      <el-row>
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            class="search-input"
            size="medium"
          ></el-input>
          <el-button size="medium">Import</el-button>
          <el-button size="medium">Close</el-button>
          <el-button size="medium">Delete</el-button>
          <el-button size="medium">Cancel</el-button>
        </el-button-group>
      </el-row>
      <el-row>

        <el-table
          border
          :data="tableData"
          @selection-change="selectedChange"
          stripe
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="ID"></el-table-column>
          <el-table-column label="Site ID"></el-table-column>
          <el-table-column label="Site Name"></el-table-column>
          <el-table-column label="Type"></el-table-column>
          <el-table-column label="Address"></el-table-column>
          <el-table-column label="Status">
            <template slot-scope="scope">
              <template v-if="scope.$index % 2 == 0">Open</template>
              <template v-else>Closed</template>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="100"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      radio: 1,
      searchVal: '',
      tableData: [{}, {}, {}],
      tableSelect: [],
      file: {},
    }
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    selectedChange (val) {
      this.tableSelect = val;

    },
    fileChange (e) {
    },
    open (scope) {
    },


  }
}
</script>
<style lang="scss" scoped>
.input-group {
  display: flex;
  .search-input {
    width: 200px;
    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
}

.radio-wrap {
  display: flex;
  margin-bottom: 40px;
  .el-select {
    width: 400px;
  }
  .radio-left {
    margin-right: 20px;
  }
  .radio-right {
    .name-box {
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>