var render = function render(){var _vm=this,_c=_vm._self._c;return _c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.elData.content),expression:"elData.content"}],class:{
    'active-input':!_vm.elData.readonly
  },style:({
      'font-size':_vm.elData.style.fontSize + 'px',
      'line-height':_vm.elData.style.lineHeight + 'px',
      color :_vm.elData.style.color ,
      'font-weight': _vm.elData.style.fontWeight,
      'font-style': _vm.elData.style.fontStyle,
      'text-decoration': _vm.elData.style.textDecoration,
      'text-align': _vm.elData.style.textAlign,
      'font-family':_vm.elData.style.fontFamily,
    }),attrs:{"readonly":_vm.elData.readonly},domProps:{"value":(_vm.elData.content)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.elData, "content", $event.target.value)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }