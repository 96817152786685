<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              class="search-input"
              size="medium"
            ></el-input>
            <el-button size="medium">Search</el-button>
            <el-button
              size="medium"
              @click="showAdd"
            >Add</el-button>
            <el-button size="medium">Edit</el-button>
            <el-button size="medium">Copy</el-button>
            <el-button size="medium">Delete</el-button>
          </el-button-group>

        </div>
      </el-row>
      <el-row>
        <el-table border          :data="tableData"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="ID"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="KPI Templates"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Description"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="System Default"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Data Access Group"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="KPI Type"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Status"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="100"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'KpiTemplate',
  data () {
    return {
      searchVal: '',
      tableSelected: [],
      tableData: [{}, {}, {}, {}],
    }
  },
  methods: {
    selectionChange (val) {
      this.tableSelected = val;
    },

    showAdd () {
      this.$router.push({ path: '/home/newKpiTemplate' })
    }
  }
}
</script>
<style lang="scss" scoped>


.user-form {
  .el-select {
    width: 100%;
  }

  .switch-item {
    text-align: right;
    .el-switch {
      width: 100px;
    }
  }
}
</style>
