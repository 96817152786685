<template>
  <div class="wrap">
    <el-row class="top-box">
      <el-card class="table-card">
        <el-row>
          <div class="input-wrap">
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </div>
        </el-row>
        <el-row
          class="table-row"
          ref="tableRow"
        >
          <el-table
            border
            :data="tableData"
            :height="tableHeight"
            v-loading="tableLoading"
            @selection-change="val => tableSelected = val"
            @row-click="rowClick"
            @row-dblclick="dblclickEdit"
            :row-class-name="tableRowClassName"
          >
            <el-table-column type="selection"></el-table-column>

            <el-table-column
              sortable
              prop="name"
              :label="$t('reportGroupEditor.col1')"
            >
            </el-table-column>
            <el-table-column
              sortable
              prop="accessGroupName"
              :label="$t('reportGroupEditor.col2')"
            >
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="page-wrap">
          <my-page
            layout="prev, pager, next, jumper"
            :total="page.total"
            :page-size.sync="page.size"
            :current-page.sync="page.current"
            @current-change="getTableData"
            @size-change="getTableData"
          >
          </my-page>
        </el-row>
      </el-card>
    </el-row>
    <el-row
      class="bottom-box"
      :gutter="10"
      v-if="checkedId"
    >
      <el-col :span="12">
        <el-card class="tree-wrap">
          <div class="title">
            <span>{{$t('reportGroupEditor.subtitle1')}}</span>

          </div>
          <el-tree
            :data="treeData"
            :expand-on-click-node="false"
            ref="tree"
            node-key="id"
            default-expand-all
            highlight-current
            show-checkbox
            @node-click="treeClick"
            @check-change="setSysReportGroup"
            v-loading="treeLoading"
          >
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="permission-wrap">
          <div class="title">
            <span>{{$t('reportGroupEditor.subtitle1')}}</span>

            <el-button-group class="input-group">
              <el-input
                v-model="serachReportVal"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="treeClick"
              >{{$t('operation.search')}}</el-button>
            </el-button-group>
          </div>
          <div
            class="report-row"
            ref="reportRow"
          >
            <el-table
              border
              :data="reportData"
              :height="reportHeight"
              ref="reportTable"
              stripe
              v-loading="reportLoading"
              @selection-change="setReport"
            >
              <el-table-column type="selection"> </el-table-column>
              <el-table-column
                sortable
                :label="$t('reportGroupEditor.col3')"
                prop="name"
              ></el-table-column>
              <el-table-column
                sortable
                :label="$t('reportGroupEditor.col4')"
                prop="description"
              ></el-table-column>
              <el-table-column
                sortable
                :label="$t('reportGroupEditor.col5')"
                prop="accessGroupName"
              ></el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加角色弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="groupDialog.title"
      :visible.sync="groupDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="groupDialog"
        :rules="rules"
        ref="groupForm"
        size="small"
      >
        <el-form-item
          :label="$t('reportGroupEditor.dialog.label1')"
          prop="name"
        >
          <el-input v-model="groupDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportGroupEditor.dialog.label2')"
          prop="dataAccessGroup"
        >
          <el-select
            v-model="groupDialog.dataAccessGroup"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="groupDialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="submitGroup"
          :loading="groupDialog.loading"
          size="medium"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  GetDataAccessGroupAll,
} from '@/api/organizations'
import {
  GetSysReportGroupList,
  AddSysReportGroup,
  DelSysReportGroup,
  GetReportGroupTree,
  SetSysReportGroup,
  SetReport,
  GetCheckedSysReportGroup,
  GetCheckedReport,
} from '@/api/reportGroup'
export default {
  name: 'ReportGroupEditor',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      checkedId: null,
      tableData: [],
      tableHeight: null,
      dataAccessGroups: [],
      groupForm: {
        loading: false,
      },
      groupDialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('reportGroupEditor.dialog.label1'), trigger: 'blur' }],
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeLoading: false,
      treeData: [],
      serachReportVal: '',
      reportLoading: false,
      reportData: [],
      reportHeight: null,

    }
  },
  watch: {

  },
  created () {
    // 获取树结构
    GetReportGroupTree().then(res => this.treeData = res)
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)

    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetSysReportGroupList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (this.tableData.length !== 0) {
          this.rowClick(this.tableData[0])
        }
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示添加
    showAdd () {
      this.$refs.groupForm && this.$refs.groupForm.resetFields();
      this.groupDialog.id = null;
      this.groupDialog.title = this.$i18n.t('reportGroupEditor.dialog.title1');
      this.groupDialog.visible = true;
    },
    // 显示编辑
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (item) {
      if (!this.permit.edit) return;
      this.$refs.groupForm && this.$refs.groupForm.resetFields();

      this.groupDialog.id = item.id;
      this.groupDialog.title = this.$i18n.t('reportGroupEditor.dialog.title2');
      this.$nextTick(() => {
        this.groupDialog.name = item.name;
        this.groupDialog.dataAccessGroup = item.accessGroupId;
      })
      this.groupDialog.visible = true;
    },
    // 添加、编辑角色
    submitGroup () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.groupDialog.name,
          accessGroupId: this.groupDialog.dataAccessGroup,
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupDialog.id) {
          param.id = this.groupDialog.id;
          msg = this.$i18n.t('message.edit');
        }
        this.groupDialog.loading = true;
        AddSysReportGroup(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.getTableData();
            this.groupDialog.visible = false;
          } else {
            if (res.status === 100) this.$message.warning('Role Name is occupied.')
          }
          this.groupDialog.loading = false;
        }).catch(error => {
          this.groupDialog.loading = false;
        })
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(','),
        }
        DelSysReportGroup(param).then(res => {
          if (res.success) {
            if (this.tableSelected.some(item => item.id === this.checkedId)) this.checkedId = null;
            this.getTableData()
            this.$message.success('deleted successfully.')
          }
        })
      }).catch(() => { });
    },

    // 获取报表
    treeClick (node) {
      this.getCheckedReport();
    },
    // 设置系统报表组
    setSysReportGroup: debounce(function () {
      if (this.treeLoading) return;
      let param = {
        reportGroupTreeId: this.$refs.tree.getCheckedKeys().join(','),
        reportGroupId: this.checkedId,
      }
      SetSysReportGroup(param)
    }, 300),
    // 设置报表
    setReport: debounce(function (val) {
      if (this.reportLoading) return;
      let param = {
        categoriesId: this.$refs.tree.getCurrentKey(),
        reportGroupId: this.checkedId,
        templateId: val.map(item => item.id).join(','),
      }
      SetReport(param).then(res => {
        if (res.success && param.templateId !== '') {
          this.$refs.tree.setChecked(param.categoriesId, true);
        }
      })
    }),

    // 获取选择中的系统报表组
    getCheckedSysReportGroup () {
      let param = {
        reportGroupId: this.checkedId,
      }
      this.treeLoading = true;
      GetCheckedSysReportGroup(param).then(res => {
        this.$refs.tree.setCheckedKeys(res);
        setTimeout(() => {
          this.treeLoading = false;
        }, 400)
      })
    },
    // 获取被选中的报表
    getCheckedReport () {
      let param = {
        groupTreeId: this.$refs.tree.getCurrentKey(),
        reportGroupId: this.checkedId,
      }
      this.reportLoading = true;
      GetCheckedReport(param).then(res => {
        this.reportData = res || [];
        this.$nextTick(() => {
          this.reportData.forEach((item, index) => {
            if (item.selection) this.$refs.reportTable.toggleRowSelection(this.reportData[index])
          })
          setTimeout(() => {
            this.reportLoading = false;
          }, 1)
        })
      })
    },
    // 获取角色id
    rowClick (row, column, event) {
      this.checkedId = row.id;
      this.getCheckedSysReportGroup();
      if (this.$refs.tree && this.$refs.tree.getCurrentKey() !== null)
        this.treeClick();


      if (!this.reportHeight)
        this.$nextTick(() => {
          this.reportHeight = this.$refs.reportRow.clientHeight;
        })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.id === this.checkedId) return 'checked-row';
      else return '';
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('ReportGroupEditorSearch');
        this.permit.add = permission.includes('ReportGroupEditorAdd');
        this.permit.edit = permission.includes('ReportGroupEditorEdit');
        this.permit.del = permission.includes('ReportGroupEditorDelete');
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.top-box {
  height: 50%;
  margin-bottom: 10px;
}

.bottom-box {
  height: calc(50% - 10px);
}

.table-row {
  height: calc(100% - 46px - 42px);
}
.report-row {
  height: calc(100% - 46px);
}
.el-dialog {
  .el-select {
    width: 100%;
  }
}
.el-col {
  height: 100%;
}
.el-tree {
  height: calc(100% - 46px);
  overflow: auto;
}
.permission-wrap {
  .btn-wrap {
    margin-top: 24px;
    text-align: center;
  }
}

.tree-wrap,
.permission-wrap {
  .title {
    height: 36px;
    border-bottom: 1px solid #dcdfe6;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

