<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="150px"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('newSLAPolicy.label1')"
              prop="name"
            >
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newSLAPolicy.label2')"
              prop="SLAType"
            >
              <el-select
                v-model="groupForm.SLAType"
                filterable
              >
                <el-option
                  v-for="item in SLATypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newSLAPolicy.label3')"
              prop="dataAccessGroup"
            >
              <el-select
                v-model="groupForm.dataAccessGroup"
                filterable
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!groupForm.id"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!groupForm.id && permit.priorityAdd"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              :disabled="!groupForm.id"
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.priorityDel"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          row-key="tableId"
          default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
          >
          <el-table-column type="selection"></el-table-column>
          <el-table-column :label="$t('newSLAPolicy.col1')">
            <el-color-picker
              slot-scope="scope"
              v-if="!!scope.row.children"
              size="mini"
              v-model="scope.row.color"
            ></el-color-picker>
          </el-table-column>

          <el-table-column :label="$t('newSLAPolicy.col2')">
            <el-table-column
              min-width="130"
              :label="$t('newSLAPolicy.col3')"
              prop="priorityIdName"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              :label="$t('newSLAPolicy.col4')"
              prop="name"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('newSLAPolicy.col5')">
            <el-table-column
              min-width="200"
              :label="$t('newSLAPolicy.col6')"
              prop="opHourName"
            >
            </el-table-column>
            <el-table-column
              min-width="100"
              :label="$t('newSLAPolicy.col7')"
            >
              <template
                slot-scope="scope"
                v-if="!!scope.row.response.slaTimer"
              >
                {{scope.row.response.slaTimer}}{{dateTypes[scope.row.response.slaTimeType]}}
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              :label="$t('newSLAPolicy.col8')"
              prop="response.isWarning"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.response.isWarning === 1"
                  size="small"
                  type="success"
                >Enable</el-tag>
                <el-tag
                  v-else-if="scope.row.response.isWarning === 0"
                  size="small"
                  type="danger"
                >Disable</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              min-width="130"
              :label="$t('newSLAPolicy.col9')"
              prop="response.level"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(item,index) in scope.row.response.levels"
                  :key="index"
                  class="cell-style"
                >
                  <i
                    v-if="scope.row.response.levelId"
                    class="el-icon-s-custom"
                    @click="showStaff(scope.row.response.levelId)"
                  ></i>
                  {{`L${item.level}`}} <span v-if="!!item.level && !!item.description">-</span> {{item.description}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              :label="$t('newSLAPolicy.col10')"
              prop="response.timer"
            >
              <template
                slot-scope="scope"
                v-if="!!scope.row.response.timer"
              >
                {{scope.row.response.timer}}{{dateTypes[scope.row.response.timeType]}}
              </template>
            </el-table-column>
            <el-table-column
              min-width="130"
              :label="$t('newSLAPolicy.col11')"
              prop="response.slaLevel"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(item,index) in scope.row.response.slaLevels"
                  :key="index"
                  class="cell-style"
                >
                  <i
                    v-if="scope.row.response.slaLevelId"
                    class="el-icon-s-custom"
                    @click="showStaff(scope.row.response.slaLevelId)"
                  ></i>
                  {{`Level${item.level}`}} <span v-if="!!item.level && !!item.description">-</span> {{item.description}}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('newSLAPolicy.col12')">
            <el-table-column
              min-width="100"
              :label="$t('newSLAPolicy.col13')"
            >
              <template
                slot-scope="scope"
                v-if="!!scope.row.resolution.slaTimer"
              >
                {{scope.row.resolution.slaTimer}}{{dateTypes[scope.row.resolution.slaTimeType]}}
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              :label="$t('newSLAPolicy.col14')"
              prop="resolution.isWarning"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.resolution.isWarning === 1"
                  size="small"
                  type="success"
                >Enable</el-tag>
                <el-tag
                  v-if="scope.row.resolution.isWarning === 0"
                  size="small"
                  type="danger"
                >Disable</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              min-width="130"
              :label="$t('newSLAPolicy.col15')"
              prop="resolution.level"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(item,index) in scope.row.resolution.levels"
                  :key="index"
                  class="cell-style"
                >
                  <i
                    v-if="scope.row.resolution.levelId"
                    class="el-icon-s-custom"
                    @click="showStaff(scope.row.resolution.levelId)"
                  ></i>
                  {{`L${item.level}`}} <span v-if="!!item.level && !!item.description">-</span> {{item.description}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              :label="$t('newSLAPolicy.col16')"
              prop="resolution.timer"
            >
              <template
                slot-scope="scope"
                v-if="!!scope.row.resolution.timer"
              >
                {{scope.row.resolution.timer}}{{dateTypes[scope.row.resolution.timeType]}}
              </template>
            </el-table-column>
            <el-table-column
              min-width="130"
              :label="$t('newSLAPolicy.col17')"
              prop="resolution.slaLevel"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(item,index) in scope.row.resolution.slaLevels"
                  :key="index"
                  class="cell-style"
                >
                  <i
                    v-if="scope.row.resolution.slaLevelId"
                    class="el-icon-s-custom"
                    @click="showStaff(scope.row.resolution.slaLevelId)"
                  ></i>
                  {{`Level${item.level}`}} <span v-if="!!item.level && !!item.description">-</span> {{item.description}}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row> -->
    </el-card>
    <!--  -->
    <staff-dialog
      :visible.sync="StaffDialogVisible"
      :policy-id="groupForm.id"
      :level-id="staffId"
    ></staff-dialog>

  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import StaffDialog from './components/StaffDialog'
// 接口
import { GetDataAccessGroupAll } from '@/api/organizations'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import {
  AddSLAPolicies,
  GetSLATriggerList,
  DelSLAPriority,
} from '@/api/warehouse/slaPolicies'
export default {
  name: 'NewSLAPolicy',
  components: {
    PageHeader,
    StaffDialog,
  },
  data () {
    return {
      permit: {
        edit: true,
        priorityAdd: true,
        priorityEdit: true,
        priorityDel: true,
      },
      StaffDialogVisible: false,
      title: '',
      dataAccessGroups: [],
      SLATypes: [],
      groupForm: {
        loading: false,
        id: null,
        name: '',
        SLAType: '',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        SLAType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      opHours: [
        '7x24 Hours',
        'Holiday Hours',
        'Holiday Working Hours',
        'Holiday Non-Working Hours',
        'Working Hours',
        'Non-Working Hours',
      ],
      dateTypes: ['Days', 'Hours', 'Mins'],
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      tableId: 1,
      staffId: null,
    }
  },
  watch: {
    '$route': function (to, form) {
      if (to.name === 'NewSLAPolicy' && form.name === 'NewSLAPriority') {
        this.getTableData();
      }
    }
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newSLAPolicy.title2') : this.$i18n.t('newSLAPolicy.title3');
      this.getItem();
    } else {
      this.title = this.$i18n.t('newSLAPolicy.title1');
    }
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
    // 获取SLA类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000020' }).then(res => this.SLATypes = res)

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    // this.getTableData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      let data = JSON.parse(sessionStorage.policyData);
      Object.assign(this.groupForm, {
        name: data.name,
        SLAType: data.type,
        dataAccessGroup: data.accessGroupId,
      })
      this.getTableData();
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.groupForm.name,
          type: this.groupForm.SLAType,
          accessGroupId: this.groupForm.dataAccessGroup,
        }

        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddSLAPolicies(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      });
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        policyId: this.groupForm.id,
      }
      this.tableLoading = true;
      GetSLATriggerList(param).then(res => {
        this.tableData = [];
        res.list.forEach(_item => {
          let priorityId = this.$global.priorityIds.find(priorityId => priorityId.id == _item.priorityId)
          let len = _item.stageList[0].triggers.length > _item.stageList[1].triggers.length ? _item.stageList[0].triggers.length : _item.stageList[1].triggers.length;
          let data = this.opHours.map((item, index) => {
            return {
              tableId: this.tableId++,
              id: _item.id,
              opHour: index,
              opHourName: item,
              resolution: null,
              response: null,
              color: _item.color,
            }
          })

          for (let i = 0; i < len; i++) {
            if (!!_item.stageList[0].triggers[i]) {
              data[_item.stageList[0].triggers[i].opHour].resolution = this.dealData(_item.stageList[0].triggers[i])
            }
            if (!!_item.stageList[1].triggers[i]) {
              data[_item.stageList[1].triggers[i].opHour].response = this.dealData(_item.stageList[1].triggers[i])
            }
          }

          data = data.map(item => {
            if (!!item.resolution || !!item.response) {
              item.resolution = !!item.resolution ? item.resolution : {};
              // item.resolution.description = _item.stageList[0].description;
              item.response = !!item.response ? item.response : {};
              // item.response.description = _item.stageList[1].description;
              return item;
            } else {
              return null;
            }
          }).filter(item => !!item)

          let tableItem = {
            id: _item.id,
            priorityIdName: priorityId.name,
            name: _item.name,
            children: [],
            ...data[0],
          }
          for (var i = 1; i < data.length; i++) {
            tableItem.children.push(data[i]);
          }

          this.tableData.push(tableItem)
        });
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newSLAPriority', query: { policyId: this.groupForm.id } })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      localStorage.setItem('userData', JSON.stringify(e));
      this.$router.push({ path: '/home/newSLAPriority', query: { policyId: this.groupForm.id, id: e.id } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        return;
        DelSLAPriority(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 
    dealData (data) {
      data.levelId = data.levels.map(item => item.id).join(',');
      data.slaLevelId = data.slaLevels.map(item => item.id).join(',');
      return data;
    },
    showStaff (id) {
      this.staffId = id;
      this.StaffDialogVisible = true;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.edit = permission.includes('SLAPolicies-SLAPoliciesEdit')
        this.permit.priorityAdd = permission.includes('SLAPolicies-SLAPriorityAdd')
        // this.permit.priorityEdit = permission.includes('SLAPolicies-SLAPriorityEdit')
        this.permit.priorityDel = permission.includes('SLAPolicies-SLAPriorityDelete')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 56px - 59px - 46px);
}
.el-tag {
  margin-right: 10px;
}
.el-tag:last-child {
  margin-right: 0;
}
.el-icon-s-custom {
  cursor: pointer;
}
.cell-style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>