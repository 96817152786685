import request from '@/utils/request'

// 查询购物申请单列表
export const GetPurchaseRequisitionList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加或修改购物申请单
export const AddPurchaseRequisition = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除购物申请单
export const DelPurchaseRequisition = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/del.do',
  data: JSON.stringify(data)
})

// 自动生成申请单号
export const AutoPurchaseRequisitionNum = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/getNum.do',
  data: JSON.stringify(data)
})

// 查询购物申请单详情
export const GetPurchaseRequisitionItem = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/queryById.do',
  data: JSON.stringify(data)
})

// 获取申请设备列表
export const GetApplyDeviceList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/queryByPage.do',
  data: JSON.stringify(data)
})

// 删除申请设备
export const DelApplyDevice = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/del.do',
  data: JSON.stringify(data)
})

// 获取购物申请单集合
export const GetPurchaseRequisitionAll = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/queryByCondition.do',
  data: JSON.stringify(data)
})

// 查询备货设备列表
export const GetStockUpDeviceList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/queryGroupByForm.do',
  data: JSON.stringify(data)
})
// 查询锁定调整记录
export const GetSetLockLog = (data) => request({
  method: 'post',
  url: '/WarehouseAdjustmentRecord/queryByCondition.do',
  data: JSON.stringify(data)
})

// 添加锁定调整
export const AddSetLock = (data) => request({
  method: 'post',
  url: '/WarehouseAdjustmentRecord/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取通过单号获取申请设备列表
export const GetApplyDeviceByNumber = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/queryByAppNumber.do',
  data: JSON.stringify(data)
})

// 购物申请单重新审批
export const ResubmitApply = (data) => request({
  method: 'post',
  url: '/WorkflowApprovalSymbolKeep/reSubmit.do',
  data: JSON.stringify(data)
})

// 购物申请单审批
export const ExamineApply = (data) => request({
  method: 'post',
  url: '/WorkflowApprovalSymbolKeep/examine.do',
  data: JSON.stringify(data)
})

// 审批流程记录
export const ExamineApplyLog = (data) => request({
  method: 'post',
  url: '/WorkflowApprovalSymbolKeep/queryByHistoryWebId.do',
  // params: data
  data: JSON.stringify(data)
})

// 根据订单查询购物申请单
export const GetPurchaseRequisitionByOrderList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationForm/queryByOrder.do',
  data: JSON.stringify(data)
})

// 替换申请设备
export const ReplaceApplyDevice = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取 签收记录 列表
export const GetReceiptRecordList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseReceiptRecord/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增 修改 签收记录
export const AddReceiptRecord = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseReceiptRecord/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 签收记录
export const DelReceiptRecord = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseReceiptRecord/del.do',
  data: JSON.stringify(data)
})

// 批量签收
export const AddBatchReceiptRecord = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseReceiptRecord/receiptAll.do',
  data: JSON.stringify(data)
})

// 获取 变更记录 列表
export const GetUpdateRecordList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseUpdateRecord/queryByPage.do',
  data: JSON.stringify(data)
})

// 获取 变更记录 详情
export const GetUpdateRecordItem = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseUpdateRecord/queryById.do',
  data: JSON.stringify(data)
})

// 获取 状态详情 列表
export const GetStatusRecordList = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseStatusRecord/queryByPage.do',
  data: JSON.stringify(data)
})


// 通过id获取申请设备
export const GetDeviceById = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/queryById.do',
  data: JSON.stringify(data)
})

// 获取设备收货信息
export const GetReceiptList = (data) => request({
  method: 'post',
  url: '/AppDeviceReceiptInfo/queryByCondition.do',
  data: JSON.stringify(data)
})

// 删除设备收货信息
export const DelReceipt = (data) => request({
  method: 'post',
  url: '/AppDeviceReceiptInfo/del.do',
  data: JSON.stringify(data)
})

// 新增 修改 设备收货信息
export const AddReceipt = (data) => request({
  method: 'post',
  url: '/AppDeviceReceiptInfo/addOrUpdateList.do',
  data: JSON.stringify(data)
})

// 修改申请设备
export const EditApplyDevice = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/update.do',
  data: JSON.stringify(data)
})


