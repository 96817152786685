<template>
  <div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      width="400px"
      center
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          label="Site Name"
          prop="site"
        >
          <el-select
            v-model="dialog.site"
            filterable
            @change="siteChange"
          >
            <el-option
              v-for="item in brands"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="isCopy"
          label="File Name"
          prop="fileId"
        >
          <el-select v-model="dialog.fileId">
            <el-option
              v-for="item in files"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-else
          label="File Name"
          prop="fileName"
        >
          <el-input v-model="dialog.fileName"></el-input>
        </el-form-item>
        <el-form-item
          label="Status"
          prop="status"
        >
          <el-switch
            v-model="dialog.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="dialog.status ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>

      </el-form>
      <template v-if="!!dialog.id">
        <el-divider content-position="left">Template</el-divider>
        <el-table
          border
          :data="tableData"
          :height="210"
        >
          <el-table-column
            min-width="150"
            show-overflow-tooltip
            sortable
            label="Temaplate Name"
            prop="name"
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            label="Enable/Disable"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isEnable"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-text="scope.row.isEnable ? 'Enable':'Disable'"
                :active-value="1"
                :inactive-value="0"
                @change="switchChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialog.loading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  AddMaintenanceFile,
  CopyToMaintenanceFile,
  GetMaintenanceFileList,
} from '@/api/warehouse/maintenance'
import { SearchBrand } from '@/api/stores'
import { EditPdfTemplateState } from '@/api/warehouse/pdf'
export default {
  props: ['visible', 'data', 'isCopy', 'branch'],
  data () {
    return {
      brands: [],
      files: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        site: '',
        fileName: '',
        fileId: '',
        status: 0,
      },
      rules: {
        fullName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      tableData: [],
      oldTemplate: null,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.data && !this.isCopy) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: 'Edit File',
              id: this.data.id,
              site: this.data.orgId,
              fileName: this.data.name,
              status: this.data.status,
            })
            this.tableData = this.data.templateList;
            this.oldTemplate = this.data.templateList.find(item => !!item.isEnable)
          })
        } else {
          this.dialog.title = 'Add New File';
          this.dialog.site = this.branch.id;
          this.dialog.id = null;
          this.dialog.fileId = null;
        }

        if (this.isCopy) {
          this.dialog.title = 'Copy File';
          this.dialog.site = this.data.orgId;
          this.siteChange();
        }
        SearchBrand({ name: '' }).then(res => this.brands = res);
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          orgId: this.dialog.site,
          name: this.dialog.fileName,
          status: this.dialog.status,
        }

        let msg = this.$i18n.t('message.add');
        if (this.data && !this.isCopy) {
          param.id = this.dialog.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        if (this.isCopy) {
          param.srcId = this.data.id;
          param.targetId = this.dialog.fileId;
          CopyToMaintenanceFile(param).then(res => {
            if (res.success) {
              this.$message.success(this.$i18n.t('message.copy'))
              this.$emit('update')
              this.dialog.visible = false;
            }
            this.dialog.loading = false;
          }).catch(error => {
            this.dialog.loading = false;
          })
        } else {
          AddMaintenanceFile(param).then(res => {
            if (res.success) {
              this.$message.success(msg)
              this.$emit('update')
              this.dialog.visible = false;
            }
            this.dialog.loading = false;
          }).catch(error => {
            this.dialog.loading = false;
          })

          // let newTemplate = this.data.templateList.find(item => !!item.isEnable)
          // if (!!newTemplate) this.saveTemplateState(newTemplate)
          // if (!!this.oldTemplate) this.saveTemplateState(this.oldTemplate)
        }
      });
    },
    // 开关切换
    switchChange (row) {
      this.saveTemplateState(row);
      
      // let isEnable = row.isEnable;

      // this.tableData = this.tableData.map(item => {
      //   item.isEnable = 0;
      //   return item;
      // });

      // row.isEnable = isEnable;
      
    },
    // 保存模板状态
    saveTemplateState (row) {
      let param = {
        id: row.id,
        isEnable: row.isEnable,
      }
      EditPdfTemplateState(param)
    },
    siteChange () {
      let param = {
        limit: 9999,
        page: 1,
        branchId: this.dialog.site,
      }
      GetMaintenanceFileList(param).then(res => this.files = res.list)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-switch {
    width: 97px;
    position: absolute;
    right: 0;
    top: 6px;
  }
}
</style>