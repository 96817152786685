import { render, staticRenderFns } from "./ExchangeRate.vue?vue&type=template&id=32bb5210&scoped=true&"
import script from "./ExchangeRate.vue?vue&type=script&lang=js&"
export * from "./ExchangeRate.vue?vue&type=script&lang=js&"
import style0 from "./ExchangeRate.vue?vue&type=style&index=0&id=32bb5210&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32bb5210",
  null
  
)

export default component.exports