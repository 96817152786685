<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('maintenanceCheckList.title1')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
      </page-header>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              v-if="viewType == 0"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              v-if="viewType == 0"
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              v-if="viewType == 0"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              v-if="viewType == 0"
              size="medium"
              @click="TemplateDialogVisible = true"
            >{{$t('maintenanceCheckList.btn1')}}</el-button>
            <el-button
              size="medium"
              @click="showPrint"
            >{{$t('maintenanceCheckList.btn2')}}</el-button>
            <el-button
              disabled
              size="medium"
            >{{$t('maintenanceCheckList.btn3')}}</el-button>
            <el-button
              v-if="viewType == 0"
              size="medium"
              @click="showPDF"
            >{{$t('maintenanceCheckList.btn4')}}</el-button>
          </el-button-group>
          <div
            v-if="viewType == 1"
            class="status-box"
          >
            {{$t('maintenanceCheckList.label1')}}
            <el-tag effect='dark'>{{total.count}}/{{total.countTotal}}</el-tag>
            <el-tag
              type="success"
              effect='dark'
            >{{total.normal}}</el-tag>
            <el-tag
              type="danger"
              effect='dark'
            >{{total.abnormal}}</el-tag>
          </div>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="80"
            :label="$t('maintenanceCheckList.col1')"
            prop="priorityIdName"
            sortable
          >
            <template slot-scope="scope">
              <el-image :src="baseUrl + '/Engineer/getImage.do?imageId=' + scope.row.icon"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('maintenanceCheckList.col2')"
            prop="title"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('maintenanceCheckList.col3')"
            prop="subTitle1"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('maintenanceCheckList.col4')"
            prop="subTitle2"
            sortable
          >
          </el-table-column>
          <template v-if="viewType == 1">
            <el-table-column
              min-width="80"
              :label="$t('maintenanceCheckList.col5')"
              prop="subTitle2"
            >
              <el-tag
                slot-scope="scope"
                effect='dark'
                size="small"
              >{{scope.row.count}}/{{scope.row.countTotal}}</el-tag>
            </el-table-column>
            <el-table-column
              min-width="80"
              :label="$t('maintenanceCheckList.col6')"
              prop="subTitle2"
            >
              <el-tag
                slot-scope="scope"
                type="success"
                effect='dark'
                size="small"
              >{{scope.row.normal}}</el-tag>
            </el-table-column>
            <el-table-column
              min-width="80"
              :label="$t('maintenanceCheckList.col7')"
              prop="subTitle2"
            >
              <el-tag
                slot-scope="scope"
                type="danger"
                effect='dark'
                size="small"
              >{{scope.row.abnormal}}</el-tag>
            </el-table-column>
          </template>
        </el-table>
      </el-row>
      <!-- <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row> -->
    </el-card>

    <!--  -->
    <template-dialog
      :visible.sync="TemplateDialogVisible"
      :file-id="fileId"
      @update="getTableData"
    ></template-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import TemplateDialog from './components/TemplateDialog'
// 接口
import {
  GetTableElementList,
  DelTableElement,
  GetCheckList,
} from '@/api/warehouse/maintenance'
export default {
  name: 'MaintenanceCheckList',
  components: {
    PageHeader,
    TemplateDialog,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      title: '',
      TemplateDialogVisible: false,

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      viewType: 0,
      fileId: null,
      maintenanceId: null,
      confirmOrderId: null,
      orgId: null,
      result: [],
      total: {
        count: 0,
        countTotal: 0,
        normal: 0,
        abnormal: 0,
      }
    }
  },
  watch: {
    '$route': function (to, form) {
      if (to.name === 'MaintenanceCheckList' && form.name === 'NewMaintenanceCheckList') {
        this.getTableData();
      }
    },
  },
  created () {
    this.dealPermission();

    this.viewType = this.$route.query.type;
    this.fileId = this.$route.query.fileId;
    this.maintenanceId = this.$route.query.id;
    this.confirmOrderId = this.$route.query.confirmOrderId;
    this.orgId = this.$route.query.orgId;
    if (this.viewType == 1) this.getStatusData();

    this.getTableData();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    // this.getTableData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getTableData () {
      let param = {
        // limit: this.page.size,
        // page: this.page.current,
        limit: 9999,
        page: 1,
        search: this.searchVal,
        fileId: this.fileId
      }
      this.tableLoading = true;
      GetTableElementList(param).then(res => {
        this.tableData = res.list;
        // this.page.total = res.total;
        this.tableLoading = false;

        if (this.viewType == 1) {
          this.tableData = this.tableData.map((item, index) => Object.assign(item, this.result[index]))
        }

      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newMaintenanceCheckList', query: { fileId: this.fileId, type: this.viewType, orgId: this.orgId } })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newMaintenanceCheckList', query: { maintenanceId: this.maintenanceId, id: e.id, type: this.viewType, orgId: this.orgId } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelTableElement(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 
    getStatusData () {
      let fileData = JSON.parse(sessionStorage.fileData)
      let promiseArr = [];
      this.result = [];
      fileData.elements.forEach((item, index) => {
        this.result[index] = {
          count: 0,
          countTotal: 0,
          normal: 0,
          abnormal: 0,
        }

        promiseArr.push(this.getPromise(item, index))
      })
      Promise.all(promiseArr).then(res => {
        this.total.count = this.result.reduce((a, b) => a + b.count, 0);
        this.total.countTotal = this.result.reduce((a, b) => a + b.countTotal, 0);
        this.total.normal = this.result.reduce((a, b) => a + b.normal, 0);
        this.total.abnormal = this.result.reduce((a, b) => a + b.abnormal, 0);

        this.tableData = this.tableData.map((item, index) => Object.assign(item, this.result[index]))
      })
    },
    getPromise (data, tableIndex) {
      return new Promise((resolve, reject) => {
        let param = {
          limit: 9999,
          page: 1,
          elementId: data.elementId,
          isRecord: 1,
          maintenanceId: this.maintenanceId,
        }
        GetCheckList(param).then(res => {
          data.tableContentList.forEach((check, colIndex) => {
            if (check.isCount === 1) {
              let normal = check.checkTypeItem.options.filter(item => item.isNormal === 0).map(item => item.id);
              let abnormal = check.checkTypeItem.options.filter(item => item.isNormal === 1).map(item => item.id);
              res.list.forEach(row => {
                let item = row.itemList;
                this.result[tableIndex].countTotal++;
                if (!item[colIndex].content) return;
                this.result[tableIndex].count++;
                if (normal.includes(item[colIndex].content)) this.result[tableIndex].normal++;
                if (abnormal.includes(item[colIndex].content)) this.result[tableIndex].abnormal++;
              })
            }
          })
          resolve()
        })
      })
    },

    showPDF () {
      this.$router.push({
        path: '/home/editPdf', query: {
          type: this.viewType,
          fileId: this.fileId,
          // id: this.tableSelected[0].id,
          confirmOrderId: this.confirmOrderId,
        }
      })
    },
    showPrint () {


    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.edit = permission.includes('SLAPolicies-SLAPoliciesEdit')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  // height: calc(100% - 56px - 46px - 42px);
  height: calc(100% - 56px - 46px);
}
.el-image {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}
.status-box {
  height: 36px;
  line-height: 36px;
}
.el-tag {
  width: 80px;
  text-align: center;
}
</style>