import { render, staticRenderFns } from "./contactList.vue?vue&type=template&id=6fd106ea&scoped=true&"
import script from "./contactList.vue?vue&type=script&lang=js&"
export * from "./contactList.vue?vue&type=script&lang=js&"
import style0 from "./contactList.vue?vue&type=style&index=0&id=6fd106ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd106ea",
  null
  
)

export default component.exports