<template>
  <div
    class="select-wrap"
    ref="mySelectWrap"
  >
    <el-input
      :disabled="disabled"
      v-model="inputLabel"
      :size="size"
      :placeholder="inputPlaceholder"
      @mousedown.native="inputClick"
      @blur="hidePanel"
      ref="input"
    >
      <!-- @mouseenter.native="inputEnter"
      @mouseleave.native="inputLeave" -->
      <i
        slot="suffix"
        :class="`el-input__icon ${suffixIcon}`"
      ></i>
      <!-- @click.stop="inputClear" -->
    </el-input>
    <div
      v-show="focus"
      class="option-box"
      ref="myOptionBox"
    >
      <div v-show="searchRelevanceData.length !== 0">
        <div
          v-for="(item,index) in searchRelevanceData"
          :key="index"
          class="option-item"
          :class="{
          'active':item[prop.value] === result.value,
          'bold':!!boldProp && item[boldProp] === 1
        }"
          @mousedown="itemClick(item[prop.label],item[prop.value])"
        >
          {{item[prop.label]}}
        </div>
        <hr>
      </div>
      <div
        v-for="(item,index) in searchData"
        :key="index"
        class="option-item"
        :class="{
          'active':item[prop.value] === result.value,
               'bold':!!boldProp && item[boldProp] === 1
        }"
        @mousedown="itemClick(item[prop.label],item[prop.value])"
      >
        {{item[prop.label]}}
      </div>
      <div
        v-show="searchData.length === 0"
        class="option-empty"
      >{{$t('mySelect.option1')}}</div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    relevanceData: Array,
    value: String,
    prop: Object,
    size: {
      type: String,
      default: 'medium'
    },
    disabled: Boolean,
    boldProp: String,
  },
  data () {
    return {
      focus: false,
      inputLabel: '',
      inputPlaceholder: '',
      result: {
        label: '',
        value: '',
      },
      searchData: [],
      searchRelevanceData: [],
      clock: null,
      suffixIcon: 'el-icon-arrow-down'
    }
  },
  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.searchData = this.data;
        this.getResult();
      },
      deep: true,
    },
    'relevanceData': {
      handler: function (newVal, oldVal) {
        this.searchRelevanceData = this.relevanceData;
      },
      deep: true,
    },
    'inputLabel': function (newVal, oldVal) {
      // if (!this.focus) return;
      if (this.inputLabel === '') {
        this.searchData = this.data;
        this.searchRelevanceData = this.relevanceData || [];
      } else {
        this.searchData = this.data.filter(item => {
          return !!item[this.prop.label] && !!this.inputLabel && item[this.prop.label].toString().toLowerCase().indexOf(this.inputLabel.toString().toLowerCase()) !== -1
        })
        if (!this.relevanceData) return;
        this.searchRelevanceData = this.relevanceData.filter(item => {
          return !!item[this.prop.label] && !!this.inputLabel && item[this.prop.label].toString().toLowerCase().indexOf(this.inputLabel.toString().toLowerCase()) !== -1
        })
      }
    },
    'value': {
      handler: function (newVal, oldVal) {
        if (newVal !== this.result.value) {
          this.getResult()
        }
      },
      immediate: true,
    },
  },
  created () {
  },
  mounted () {
    this.$refs.myOptionBox.style.minWidth = this.$refs.input.$el.offsetWidth + 'px';

  },
  methods: {
    // 点击输入框
    inputClick (event) {
      if (this.focus || this.disabled) return;

      this.searchData = this.data;
      this.$refs.input.focus();
      // 算显示位置
      let screenData = event.currentTarget.getBoundingClientRect()
      let optionHeight = this.searchData.length * 34 + 2;
      let bottomHeight = document.body.clientHeight - screenData.bottom;
      let wrapHeight = this.$refs.mySelectWrap.style.height;

      if (optionHeight > 280) optionHeight = 280;
      if (this.searchData.length === 0) optionHeight = 36;

      if (bottomHeight > 280) {
        this.$refs.myOptionBox.style.top = `${wrapHeight}px`;
      } else {
        this.$refs.myOptionBox.style.top = `-${optionHeight}px`;
      }

      this.$nextTick(() => {
        this.focus = true;
      })
    },
    // 点击选项
    itemClick (label, value) {
      this.inputLabel = label;
      this.inputPlaceholder = label;
      this.result = {
        label: label,
        value: value,
      }
      this.focus = false;
      this.submit();
    },
    hidePanel () {
      if (!this.focus) return;

      if (this.inputLabel === '') {
        this.inputLabel = this.result.label;
        this.inputPlaceholder = this.result.label;
      } else {
        let item = this.searchData.find(item => item[this.prop.label] === this.inputLabel)
        if (!!item) {
          this.result = {
            label: this.inputLabel,
            value: item[this.prop.value],
          }
        } else {
          this.result = {
            label: this.inputLabel,
            value: this.inputLabel,
          }
        }
        this.inputPlaceholder = this.inputLabel;

        this.submit();
      }
      this.focus = false;
    },
    submit () {
      if (this.value !== this.result.value) {
        this.$emit('update:value', this.result.value);
        this.$emit('change', this.result.value)
      }
    },
    getResult () {
      let item = this.data.find(item => item[this.prop.value] === this.value)
      this.result = {
        value: this.value,
        label: !!item ? item[this.prop.label] : this.value,
      }
      this.inputLabel = this.result.label;
      this.inputPlaceholder = this.result.label;
    },
    // inputEnter () {
    //   if (!!this.result.value) {
    //     this.suffixIcon = 'el-icon-circle-close'
    //   } else {
    //     this.suffixIcon = 'el-icon-arrow-down'
    //   }
    // },
    // inputLeave () {
    //   this.suffixIcon = 'el-icon-arrow-down'
    // },
    inputClear () {
      this.inputLabel = '';
      this.inputPlaceholder = '';
      this.result = {
        value: '',
        label: '',
      }
      this.suffixIcon = 'el-icon-arrow-down'

      this.submit();
    },
  }
}
</script>

<style lang="scss" scoped>
.select-wrap {
  padding: 0;
  position: relative;
}
.option-box {
  min-width: 200px;
  max-height: 280px;
  min-height: 34px;
  transform-origin: center top;
  z-index: 2017;
  position: absolute;
  left: 0px;

  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
}
.option-empty,
.option-item {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: left;
  &:hover {
    background: #f5f7fa;
  }
  &.active {
    color: #409eff;
    font-weight: 700;
  }
}
.option-empty {
  text-align: center;
  color: #999;
  cursor: default;
  &:hover {
    background: #fff;
  }
}
.el-input ::v-deep .el-input__icon.el-icon-circle-close {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
</style>