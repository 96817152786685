<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <sorting-table
        :data="sortingData"
        :template-id="templateId"
        @submit="submitSortingData"
      ></sorting-table>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SortingTable from '@/pages/reportDesigner/components/SortingTable'

export default {
  components: {
    SortingTable
  },
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Sorting',
      },
      templateId: null,
      sortingData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.templateId = this.data.data.templateId;
        this.sortingData = JSON.parse(this.data.data.sortingJson)
      }
      this.dialog.visible = this.visible;
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  methods: {
    submitSortingData (data) {
      this.sortingData = data;
    },
    submit () {
      this.$emit('submit', JSON.stringify(this.sortingData))
      this.dialog.visible = false;
    },
  }
}
</script>

<style>
</style>