<template>
  <div class="title-wrap">
    <div class="title-box">
      <i
        v-if="hideBack === undefined"
        class="el-icon-d-arrow-left icon"
        @click="back"
      ></i>
      <span class="text">{{title}}</span>
      <span
        class="text"
        v-for="(item,index) in titles"
        :key="index"
      > / {{item}}</span>
    </div>
    <el-button-group class="btn-box">
      <slot></slot>
    </el-button-group>
  </div>
</template>

<script>
export default {
  props: ['title', 'hideBack', 'titles'],
  data () {
    return {

    }
  },
  methods: {
    back () {
      if (!!this.$listeners.back) {
        this.$emit('back');
      } else {
        window.history.length <= 1
          ? this.$router.push({ path: '/' })
          : this.$router.go(-1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
// 次级页面页头
.title-wrap {
  height: 36px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-box {
    .icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 20px;
      margin-right: 5px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background: #f0f2f5;
      }
    }
    .text {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>