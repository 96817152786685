<template>
  <div>
    <div class="filer-wrap">
      <div class="head-box">
        <div class="head-item">
          <div class="head-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div
          class="head-item"
          @click="resetParam"
        >
          <i class="el-icon-refresh"></i>
        </div>
        <div
          class="head-item"
          @click="moveParam(0)"
        >
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          class="head-item"
          @click="moveParam(1)"
        >
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="filter-box"
        @wheel.prevent="wheelParam"
      >
        <div
          class="filter-slide"
          :style="{
            'margin-left':filterSlide.marginLeft + 'px'
          }"
        >
          <!-- 
            :reportId="$store.state.filterParamData[0].val.id"
         -->
          <filter-item
            v-for="(item,index) in filterParamData"
            :key="index"
            :data="item"
            :zindex.sync="zIndex"
            @submit="submitParam"
            @showQuery="showQueryData"
            @closeCondition="closeCondition"
            ref="filterItem"
          ></filter-item>
        </div>
      </div>

      <div
        v-show="isEdit"
        class="filter-item"
      >
        <div class="con">
          <div class="label-box">
            <label title="Layout Edit Mode">Layout Edit Mode</label>
            <div class="btn-box">
              <i
                class="el-icon-close"
                @click.stop="closeEdit"
              ></i>
            </div>
          </div>
          <div
            class="val-text"
            :title="currentEdit.name"
          >{{currentEdit.name}}</div>
        </div>
      </div>

      <el-dropdown
        trigger="click"
        @command="handleCommand"
      >
        <i class="el-icon-more foot-btn"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :disabled="!permit.riskLevelSettings"
            command="Risk Level Settings"
          > <i class="el-icon-s-operation"></i> {{$t('dashBoard.btn1')}}</el-dropdown-item>
          <el-dropdown-item
            :disabled="!permit.print"
            command="Print..."
          > <i class="el-icon-printer"></i> {{$t('dashBoard.btn2')}}</el-dropdown-item>
          <el-dropdown-item
            :disabled="!permit.export"
            command="Export"
          > <i class="el-icon-download"></i> {{$t('dashBoard.btn3')}}</el-dropdown-item>
          <el-dropdown-item
            :disabled="!permit.layoutView"
            command="Dashboard Templates"
          > <i class="el-icon-odometer"></i> {{$t('dashBoard.btn4')}}</el-dropdown-item>
          <template v-if="isEdit">
            <el-dropdown-item command="Layout Settings"> <i class="el-icon-menu"></i> {{$t('dashBoard.btn5')}}</el-dropdown-item>
            <el-dropdown-item
              :disabled="!permit.newLayout"
              command="Save as New"
            > <i class="el-icon-s-claim"></i> {{$t('dashBoard.btn6')}}</el-dropdown-item>
            <el-dropdown-item command="Save"> <i class="el-icon-s-claim"></i> {{$t('dashBoard.btn7')}}</el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- :report-template-id="$store.state.filterParamData[0].val.id" -->
    <!-- 查询弹窗 -->
    <query
      :visible.sync="QueryVisible"
      :data="queryData"
      @submit="submitQuery"
    ></query>
    <!-- 导出弹框 -->
    <export-reports
      :visible.sync="ExportReportsVisible"
      @submit="submitExport"
    ></export-reports>
    <!-- 表盘模板弹框 -->
    <dashboard-template
      :visible.sync="DashboardTemplateVisible"
      :layout-param="layoutParam"
      @submit="submitTemplate"
      @layoutEdit="layoutEdit"
    ></dashBoard-template>
    <!-- 新增表盘模板弹框 -->
    <add-dashboard
      :visible.sync="AddDashboardVisible"
      :layout-data="layoutData"
      :layout-param="currentEdit"
      :type="addType"
    ></add-dashboard>

  </div>
</template>

<script>
// 全局
import { formatDate } from '@/utils/common'
// 组件
import Query from '@/components/DashBoardDialog/Query'
import ExportReports from '@/components/DashBoardDialog/ExportReports'
import DashboardTemplate from '@/components/DashBoardDialog/DashboardTemplate'
import AddDashboard from '@/components/DashBoardDialog/AddDashboard'
import FilterItem from '@/components/FilterItem/'

// 接口
import { AddLayout } from '@/api/dashboard'

export default {
  components: {
    Query,
    ExportReports,
    DashboardTemplate,
    AddDashboard,
    FilterItem,
  },
  props: ['layoutData', 'layoutParam', 'commonParam'],
  data () {
    return {
      permit: {
        layoutView: true,
        newLayout: true,
        riskLevelSettings: true,
        print: true,
        export: true,
      },
      QueryVisible: false,
      ExportReportsVisible: false,
      DashboardTemplateVisible: false,
      AddDashboardVisible: false,
      filterParamData: [],
      filterSlide: {
        marginLeft: 0,
      },
      zIndex: 2,
      queryData: null,
      isEdit: false,
      addType: null,
      currentEdit: {},
    }
  },

  created () {
    this.filterParamData = JSON.parse(JSON.stringify(this.commonParam));
  },
  methods: {
    showQueryData (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);

      this.queryData = this.filterParamData[index];
      this.QueryVisible = true;
    },
    handleCommand (command) {
      switch (command) {
        case 'Risk Level Settings':
          this.$router.push({ path: '/home/riskLevelSettings', query: { type: 1 } })
          break;
        case 'Print...':
          break;
        case 'Export':
          this.ExportReportsVisible = true;
          break;
        case 'Dashboard Templates':
          this.DashboardTemplateVisible = true;
          break;
        case 'Layout Settings':
          this.$emit('showLayout');
          break;
        case 'Save as New':
          this.addType = 1;
          this.AddDashboardVisible = true;
          break;
        case 'Save':
          this.saveLayout();
          break;
      }
    },
    // 删除参数
    closeCondition (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      let dialogData = this.$refs.filterItem.map((item, index) => {
        let result = { visible: item.FilterDialogVisible };
        if (item.FilterDialogVisible) {
          let dialog = item.$refs.filterDialog.$el;
          result.left = dialog.style.left;
          result.top = dialog.style.top;
          result.prop = this.filterParamData[index].prop
        }
        return result;
      });
      this.filterParamData.splice(index, 1);
      this.$emit('submit', this.filterParamData);

      this.$nextTick(() => {
        dialogData.splice(index, 1);
        dialogData.forEach((item, index) => {
          this.$refs.filterItem[index].FilterDialogVisible = item.visible;
          if (item.visible) this.$refs.filterItem[index].setPosition(item);
        })
      })
    },
    // 提交参数
    submitParam (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      this.filterParamData.splice(index, 1, data)
      this.$emit('submit', this.filterParamData);
    },
    // 重置条件 
    resetParam () {
      this.filterParamData = [{
        mark: 'BU',
        prop: 'branchIds',
        label: 'Site Name',
        con: 'ALL',
        val: ''
      }, {
        "prop": "Date Time",
        "label": "Date",
        "con": "2020-10-22 00:00:00~2020-10-22 23:59:59",
        "data": {
          "visible": true,
          "activeTab": "New Query",
          "relation": "and",
          "queryType": 0,
          "field": "Date",
          "prop": "Date Time",
          "invert": false,
          "operator": 0,
          "condition": 13,
          "range": {
            "start": "",
            "end": ""
          },
          "dateRange": [
            "2020-10-22 00:00:00",
            "2020-10-22 23:59:59"
          ],
          "list": [],
          "description": "",
          "fieldType": "date"
        }
      }, {
        "prop": "branch_code",
        "label": "Branch Code",
        "con": "",
        "data": {
          "visible": true,
          "activeTab": "New Query",
          "relation": "and",
          "queryType": 0,
          "field": "Branch Code",
          "prop": "branch_code",
          "invert": false,
          "operator": 0,
          "condition": "",
          "range": {
            "start": "",
            "end": ""
          },
          "dateRange": [],
          "list": [],
          "description": "",
          "fieldType": "stores"
        }
      }];
      this.$emit('submit', this.filterParamData);
    },
    wheelParam (e) {
      if (e.wheelDelta > 0) {
        this.moveParam(0)
      } else {
        this.moveParam(1)
      }
    },
    moveParam (type) {
      if (type == 0) {
        if (this.filterSlide.marginLeft == 0) return;
        this.filterSlide.marginLeft += 265;
      } else {
        if (Math.abs(this.filterSlide.marginLeft / 265) + 1 >= this.filterParamData.length) return;
        this.filterSlide.marginLeft -= 265;
      }
    },
    submitQuery (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      if (index == -1) {
        this.filterParamData.push(data)
      } else {
        this.filterParamData[index] = Object.assign(this.filterParamData[index], data);
      }
      this.$emit('submit');
    },
    // 提交布局
    submitTemplate (data) {
      this.isEdit = false;
      this.$emit('submitLayout', 1, data)
    },
    // 保存当前布局
    saveLayout () {
      let param = {
        layoutJson: JSON.stringify(this.layoutData.map(item => {
          return {
            chartId: item.chartId,
            cardType: item.cardType || 0,
            i: item.i,
            w: item.w,
            h: item.h,
            initH: item.initH,
            x: item.x,
            y: item.y,
          };
        })),
        id: this.currentEdit.id,
        name: this.currentEdit.name,
        accessGroupId: this.currentEdit.accessGroupId,
        organizationId: this.currentEdit.organizationId,
        isDefaultEnable: this.currentEdit.isDefaultEnable,
      }
      AddLayout(param).then(res => {
        this.$message.success(this.$i18n.t('message.save'));
      })
    },
    // 提交编辑布局
    layoutEdit (data) {
      this.isEdit = true;
      this.currentEdit = data;
      this.$emit('submitLayout', 2, data)

    },
    // 关闭编辑 
    closeEdit () {
      this.isEdit = false;
      this.$emit('closeEdit')
    },
    // 导出
    submitExport (data) {
      this.$emit('submitExport', data)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        // this.permit.layoutView = permission.includes('b5a8ddd2-ff22-4cce-8c54-32da62b0fab7');
        // this.permit.newLayout = permission.includes('7204f9f4-3e3d-4fbc-8b30-3d1262832950');
        // this.permit.riskLevelSettings = permission.includes('OverviewLevelSettingsView');
        // this.permit.print = permission.includes('aaa46582-13f6-422e-8900-94253685169c');
        // this.permit.export = permission.includes('610f9696-a92d-4c82-a928-4a67697cca20');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filer-wrap {
  width: 100%;
  height: 50px;
  display: flex;
}
.head-box {
  border: 1px solid #e4e4e4;
  background: #fff;
  // box-shadow: 0 2px 4px 0 #0002;
  display: flex;
  .head-item {
    width: 40px;
    font-size: 18px;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #f0f2f5;
    }
    &.disable {
      color: #ccc;
    }
  }
}

.filter-box {
  margin: 0 5px;
  overflow: hidden;
  flex: 1;
  .filter-slide {
    height: 100%;
    margin-left: 0;
    transition: all 0.3s;
    display: flex;
    flex-wrap: nowrap;
  }
}
.foot-btn {
  width: 40px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  padding: 0 10px;
  border: 1px solid #e4e4e4;
  background: #fff;
  box-sizing: border-box;
  &:hover {
    background: #f0f2f5;
    cursor: pointer;
  }
}

.filter-item {
  width: 260px;
  height: 100%;
  border: 1px solid #e4e4e4;
  background: #fff;
  margin-right: 5px;
  cursor: pointer;
  float: left;
  display: flex;
  &:first-child {
    margin-left: 0;
  }
  .con {
    width: 100%;
    padding: 5px 5px 5px 10px;
    border-bottom: 2px solid #0085ff;

    .label-box {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      display: flex;
      label {
        width: calc(100% - 18px);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn-box {
        width: 18px;
        height: 20px;
        display: flex;
        i {
          width: 18px;
          height: 18px;
          text-align: center;
          line-height: 18px;
          cursor: pointer;
          &:hover {
            background: #f0f2f5;
          }
        }
      }
    }
    .val-text {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.head-icon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    height: 5px;
    margin: 1px 0;
    display: inline-block;
  }
  span:nth-of-type(1) {
    width: 100%;
    background-color: #00c875;
  }
  span:nth-of-type(2) {
    width: 70%;
    background-color: #ff0000;
  }
  span:nth-of-type(3) {
    width: 40%;
    background-color: #fdab3d;
  }
}
</style>