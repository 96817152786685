<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      width="400px"
      center
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="dialog.nameLabel"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          label="Type"
          prop="type"
        >
          <el-select
            v-model="dialog.type"
            @change="typeChange"
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="dialog.type === 4">
          <div class="input-label">Option</div>
          <div
            class="input-box"
            v-for="(item,index) in dialog.radioData"
            :key="index"
          >
            <el-radio
              v-model="dialog.radio"
              :label="index"
            >{{""}}</el-radio>
            <el-input
              size="small"
              v-model="item.name"
            ></el-input>
            <el-button
              :disabled="dialog.radioData.length === 2"
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="delRadio(index)"
            ></el-button>
            <el-button
              size="small"
              type="success"
              icon="el-icon-plus"
              @click="addRadio(index)"
            ></el-button>
          </div>
        </div>
        <el-form-item
          v-if="dialog.type === 5"
          label="Date Format"
          prop="dateFormat"
        >
          <el-select v-model="dialog.dateFormat">
            <el-option
              v-for="(item,index) in dateFormats"
              :key="index"
              :value="index"
              :label="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="dialog.type === 6"
          label="Time Format"
          prop="timeFormat"
        >
          <el-select v-model="dialog.timeFormat">
            <el-option
              label="10：28：28"
              :value="0"
            ></el-option>
            <el-option
              label="10：28：28AM"
              :value="1"
            ></el-option>

          </el-select>
        </el-form-item>
        <el-form-item
          v-if="dialog.type === 7"
          label="Charater Length"
          prop="charaterLength"
        >
          <el-select v-model="dialog.charaterLength">
            <el-option
              v-for="item in 5"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="dialog.type === 8 || dialog.type === 9 || dialog.type === 10"
          label="Unit"
          prop="unit"
        >
          <el-input v-model="dialog.unit"></el-input>
        </el-form-item>
        <div v-if="dialog.type === 11">
          <el-form-item
            label="IP"
            prop="ip"
          >
            <el-input v-model="dialog.ip"></el-input>
          </el-form-item>
          <el-form-item
            label="Mask"
            prop="mask"
          >
            <div class="input-box">
              <el-input
                :disabled="!dialog.isMask"
                v-model="dialog.mask"
              ></el-input>
              <el-switch
                v-model="dialog.isMask"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </div>
          </el-form-item>
          <el-form-item
            label="Gateway"
            prop="gateway"
          >
            <div class="input-box">
              <el-input
                :disabled="!dialog.isGateway"
                v-model="dialog.gateway"
              ></el-input>
              <el-switch
                v-model="dialog.isGateway"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </div>
          </el-form-item>
        </div>
        <div v-if="dialog.type === 12">
          <img
            class="signature"
            src="/static/image/signature.png"
          >
        </div>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="addSubmit"
          :loading="dialog.loading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'

// 接口
import { AddDataField } from '@/api/warehouse/dataMaintenance'

export default {

  props: ['visible', 'data', 'currentType'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      types: [],
      dateFormats: [
        '2021-08-18',
        '2021/08/18',
        '08/18/2021',
        '2021年8月18日',
        '2021-08',
        '08-2021',
      ],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        path: null,
        title: '',
        name: '',
        type: 1,
        radio: '',
        radioData: [],
        dateFormat: 0,
        timeFormat: 0,
        charaterLength: 1,
        unit: '',
        ip: '0.0.0.0',
        mask: '0.0.0.0',
        gateway: '0.0.0.0',
        isMask: 0,
        isGateway: 0,

      },
      rules: {
        name: [{ required: true, message: 'Please enter name.', trigger: 'blur' }]
      },
    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.radioData = [];
        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: `${this.$i18n.t('operation.edit')} ${this.currentType.name}`,
              nameLabel: this.$i18n.t('dataMaintenance.name'),
              id: this.data.id,
              name: this.data.name,
              path: this.data.path,
              type: this.data.type,
            })

            this.typeChange();

            if (this.dialog.type === 4) this.dialog.radioData = this.data.options;
            if (this.dialog.type === 5) this.dialog.dateFormat = Number(this.data.abbreviation);
            if (this.dialog.type === 6) this.dialog.timeFormat = Number(this.data.abbreviation);
            if (this.dialog.type === 7) this.dialog.charaterLength = this.data.abbreviation;
            if (this.dialog.type === 8 || this.dialog.type === 9 || this.dialog.type === 10) this.dialog.unit = this.data.abbreviation;
            if (this.dialog.type === 11) {
              Object.assign(this.dialog, {
                ip: this.data.options[0].name,
                mask: this.data.options[1].name,
                gateway: this.data.options[2].name,
                isMask: this.data.options[1].enable,
                isGateway: this.data.options[2].enable,
              });
            }
          })
        } else {
          Object.assign(this.dialog, {
            id: null,
            title: `${this.$i18n.t('operation.add')} ${this.currentType.name}`,
            nameLabel: this.$i18n.t('dataMaintenance.name'),
          })
        }
        if (this.types.length === 0) this.types = this.$global.checkTypes
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交添加
    addSubmit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          type: this.dialog.type,
          parent: this.currentType.id,
        }
        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.dialog.id;
          param.path = this.dialog.path;
          msg = this.$i18n.t('message.edit');
        }
        if (this.dialog.type === 4) {
          param.options = this.dialog.radioData.map((item, index) => {
            return {
              id: item.id,
              itemId: this.dialog.id,
              name: item.name,
              sn: index,
              isNormal: 0,
            }
          })
        }

        if (this.dialog.type === 5) param.abbreviation = this.dialog.dateFormat;
        if (this.dialog.type === 6) param.abbreviation = this.dialog.timeFormat;
        if (this.dialog.type === 7) param.abbreviation = this.dialog.charaterLength;
        if (this.dialog.type === 8 || this.dialog.type === 9 || this.dialog.type === 10) param.abbreviation = this.dialog.unit;
        if (this.dialog.type === 11)
          param.options = [
            { sn: 0, name: this.dialog.ip },
            { sn: 1, name: this.dialog.mask, enable: this.dialog.isMask },
            { sn: 2, name: this.dialog.gateway, enable: this.dialog.isGateway },
          ];

        this.dialog.loading = true;
        AddDataField(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.$emit('update')
            this.dialog.visible = false;
          }
          this.dialog.loading = false;
        }).catch(err => {
          this.dialog.loading = false;
        })
      });
    },
    typeChange () {
      switch (this.dialog.type) {
        case 4:
          this.dialog.radioData = [{ name: '', isNormal: 0 }, { name: '', isNormal: 0 }];
          break;
        case 5:
          this.dialog.dateFormat = 0;
          break;
        case 6:
          this.dialog.timeFormat = 0;
          break;
        case 8:
        case 9:
        case 10:
          this.dialog.unit = '';
          break;
        case 11:
          Object.assign(this.dialog, {
            ip: '0.0.0.0',
            mask: '0.0.0.0',
            gateway: '0.0.0.0',
            isMask: 0,
            isGateway: 0,
          });
          break;

      }
    },
    addRadio (index) {
      this.dialog.radioData.splice(index + 1, 0, {
        name: '',
      });
    },
    delRadio (index) {
      this.dialog.radioData.splice(index, 1);
    },
  },
}
</script>

<style lang="scss" scoped>
.input-label {
  height: 32px;
  line-height: 32px;
}
.input-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  .el-input {
    margin-right: 10px;
  }
  .el-radio {
    margin-right: 10px;
  }
}
.signature {
  width: 70%;
  margin: 20px 15% 0;
}
</style>