<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('appRelease.title1')"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        class="dialog-form"
        size="small"
      >
        <el-form-item
          :label="$t('appRelease.col1')"
          prop="platform"
        >
          <el-select
            v-model="dialog.platform"
            filterable
          >
            <el-option
              :value="0"
              label="Android"
            ></el-option>
            <el-option
              :value="1"
              label="iOS"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('appRelease.col2')"
          prop="versionType"
        >
          <el-select
            v-model="dialog.versionType"
            filterable
          >
            <el-option
              :value="0"
              label="AppRelease"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('appRelease.col3')"
          prop="versionNumber"
        >
          <el-input v-model="dialog.versionNumber"></el-input>
        </el-form-item>
        <el-form-item
          v-if="dialog.platform == 0"
          :label="$t('appRelease.label1')"
        >
          <el-button @click="$refs.fileApk.click()">{{dialog.fileName}}</el-button>
          <input
            type="file"
            ref="fileApk"
            hidden
            accept="application/vnd.android.package-archive"
            @change="fileChange"
          >
        </el-form-item>
        <el-form-item
          v-else
          :label="$t('appRelease.label2')"
          prop="path"
        >
          <el-input v-model="dialog.path"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          :loading="dialog.loading"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import {
  UploadApk
} from '@/api/warehouse/appRelease'
export default {
  props: ['visible',],
  data () {
    return {
      versionTypes: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        platform: 0,
        versionType: 0,
        versionNumber: '',
        fileName: 'Upload',
        path: '',
      },
      rules: {

      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.$refs.fileApk) {
          this.dialog.fileName = 'Upload';
          this.$refs.fileApk.value = '';
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    fileChange () {
      let file = this.$refs.fileApk.files[0];
      this.dialog.fileName = file.name
    },
    // 提交
    submit () {
      let formData = new FormData();
      if (this.dialog.platform == 0) {
        let file = this.$refs.fileApk.files[0];
        if (file === undefined) return this.$message.warning(this.$i18n.t('appRelease.message1'));
        if (file.type !== 'application/vnd.android.package-archive') return this.$message.warning(this.$i18n.t('appRelease.message2'));
        formData.append('file', file);
      } else {
        formData.append('file', new Blob());
      }
      formData.append('data', JSON.stringify({
        name: this.dialog.platform,
        type: this.dialog.versionType,
        version: this.dialog.versionNumber,
        apk: this.dialog.path,
      }));
      this.dialog.loading = true;
      UploadApk(formData).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.save'))
          this.$emit('update')
          this.dialog.visible = false;
        }
        this.dialog.loading = false;
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }
  .el-button {
    width: 100%;
  }
}
</style>