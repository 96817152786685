<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          prop="createTime"
          :label="$t('allocateGoods.logisticsRecord.col1')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="160"
          prop="isFactory"
          :label="$t('allocateGoods.logisticsRecord.col2')"
          sortable
        >
          <template slot-scope="scope">
            <template v-if="scope.row.type === 0">
              <span v-if="scope.row.isFactory ===  1">{{$t('allocateGoods.logisticsRecord.option1')}}</span>
              <span v-if="scope.row.isFactory === 2">{{$t('allocateGoods.logisticsRecord.option2')}}</span>
              <span v-if="scope.row.isFactory === 3">{{$t('allocateGoods.logisticsRecord.option3')}}</span>
            </template>
            <template v-if="scope.row.type === 1">
              <span v-if="scope.row.isFactory ===  1">{{$t('allocateGoods.logisticsRecord.option1')}}</span>
              <span v-if="scope.row.isFactory === 2">{{$t('allocateGoods.logisticsRecord.option3')}}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          :label="$t('allocateGoods.logisticsRecord.col3')"
          sortable
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0">{{$t('allocateGoods.logisticsRecord.option4')}}</span>
            <span v-if="scope.row.type === 1">{{$t('allocateGoods.logisticsRecord.option5')}}</span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="deviceName"
          :label="$t('allocateGoods.logisticsRecord.col4')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="brandName"
          :label="$t('allocateGoods.logisticsRecord.col5')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="deviceModel"
          :label="$t('allocateGoods.logisticsRecord.col6')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="180"
          prop="appCount"
          :label="$t('allocateGoods.logisticsRecord.col7')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="orderCount"
          :label="$t('allocateGoods.logisticsRecord.col8')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="status"
          :label="$t('allocateGoods.logisticsRecord.col9')"
          sortable
        >
          <template slot-scope="scope">
            <template v-if="scope.row.type === 0">
              <span v-if="scope.row.status === 0">{{$t('allocateGoods.logisticsRecord.option6')}}</span>
              <span v-if="scope.row.status === 1">{{$t('allocateGoods.logisticsRecord.option7')}}</span>
              <span v-if="scope.row.status === 2">{{$t('allocateGoods.logisticsRecord.option8')}}</span>
            </template>
            <template v-if="scope.row.type === 1">
              <span v-if="scope.row.status === 0">{{$t('allocateGoods.logisticsRecord.option7')}}</span>
              <span v-if="scope.row.status === 1">{{$t('allocateGoods.logisticsRecord.option8')}}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          prop="estimatedDate"
          :label="$t('allocateGoods.logisticsRecord.col10')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="180"
          prop="count1"
          :label="$t('allocateGoods.logisticsRecord.col11')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="count2"
          :label="$t('allocateGoods.logisticsRecord.col12')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="sendFirm"
          :label="$t('allocateGoods.logisticsRecord.col13')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="180"
          prop="logisticsCompany"
          :label="$t('allocateGoods.logisticsRecord.col14')"
          sortable
        ></el-table-column>
        <el-table-column
          min-width="150"
          prop="recipient"
          :label="$t('allocateGoods.logisticsRecord.col15')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="number"
          :label="$t('allocateGoods.logisticsRecord.col16')"
          sortable
        >
          <template slot-scope="scope">
            <span class="number-box">{{scope.row.number}}</span>
            <el-button
              v-if="!!scope.row.number"
              size="mini"
              @click="numberCopy(scope.row)"
            >{{$t('operation.copy')}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="xxx"
          :label="$t('allocateGoods.logisticsRecord.col17')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="fullname"
          :label="$t('allocateGoods.logisticsRecord.col18')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="remark"
          :label="$t('allocateGoods.logisticsRecord.col19')"
          sortable
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
  </div>
</template>

<script>
// 全局方法
import { copyText } from '@/utils/common'
// 接口
import { GetLogisticsInfoList } from '@/api/warehouse/purchaseOrders'

export default {
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      permit: {
        // request: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      equipmentData: {},
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab4') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
        // this.$refs.table.doLayout();
      }
    }
  },
  created () {
    this.dealPermission();

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        applicationFormId: this.$route.query.id
      }
      this.tableLoading = true;
      GetLogisticsInfoList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (item.type === 0) item.count1 = item.count;
          if (item.type === 1) item.count2 = item.count;
          return item;
        })
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    dblclickEdit (row) {
      this.equipmentData = row;
      if (row.operateFlag === 3) {
        this.ReplaceDialogVisible = true;
      } else {
        this.AddEquipmentVisible = true;
      }

    },
    // 复制运单号 
    numberCopy (row) {
      copyText(row.number)
      this.$message.success(this.$i18n.t('message.copy'))
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(",");
        // this.permit.request = permission.includes("PurchaseRequisitionRequestApproval");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.file-box {
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  .block {
    width: 50px;
    height: 50px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
</style>