var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":_vm.dialog.title,"visible":_vm.dialog.visible,"center":"","width":"400px"},on:{"update:visible":function($event){return _vm.$set(_vm.dialog, "visible", $event)}}},[_c('el-form',{ref:"userForm",staticClass:"user-form",attrs:{"model":_vm.dialog,"rules":_vm.rules,"size":"small"}},[_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label2'),"prop":"type"}},[_c('my-select',{attrs:{"data":_vm.taskType,"value":_vm.dialog.type,"size":"small","prop":{
            label:'name',
            value:'id'
          }},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "type", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label1'),"prop":"name"}},[_c('el-input',{model:{value:(_vm.dialog.name),callback:function ($$v) {_vm.$set(_vm.dialog, "name", $$v)},expression:"dialog.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label6'),"prop":"sequence"}},[_c('el-input',{model:{value:(_vm.dialog.sequence),callback:function ($$v) {_vm.$set(_vm.dialog, "sequence", $$v)},expression:"dialog.sequence"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label7'),"prop":"stage"}},[_c('el-input',{model:{value:(_vm.dialog.stage),callback:function ($$v) {_vm.$set(_vm.dialog, "stage", $$v)},expression:"dialog.stage"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label3'),"prop":"projectType"}},[_c('my-select',{attrs:{"data":_vm.projectTypes,"value":_vm.dialog.projectType,"size":"small","prop":{
              label:'name',
              value:'id'
            }},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "projectType", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label4'),"prop":"remark"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.dialog.remark),callback:function ($$v) {_vm.$set(_vm.dialog, "remark", $$v)},expression:"dialog.remark"}})],1),(!!_vm.data)?_c('el-form-item',{attrs:{"label":_vm.$t('standardTask.label5')}},[_c('div',{staticClass:"document-box"},_vm._l((_vm.data.pdNames.split(',')),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0)]):_vm._e()],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){_vm.dialog.visible = false}}},[_vm._v(_vm._s(_vm.$t('operation.cancel')))]),_c('el-button',{attrs:{"size":"medium","type":"primary","loading":_vm.dialog.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('operation.save')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }