<template>
  <div>
    <el-row>

      <el-button
        v-if="permit.editPoductInformation"
        size="medium"
        type="primary"
        @click="submit"
      >{{$t('operation.save')}}</el-button>
    </el-row>
    <editor
      id="tinymce"
      v-model="tinyValue"
      :init="editorInit"
    ></editor>
  </div>
</template>

<script>
// 插件
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'

import 'tinymce/themes/modern/theme'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'

// 接口
import { UploadImage } from '@/api'

export default {
  components: {
    Editor
  },
  props: ['data', 'permit'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      tinyValue: '',
      editorInit: {
        skin_url: './static/tinymce/skins/lightgray',
        language_url: './static/tinymce/zh_CN.js',
        language: localStorage.lang === 'en' ? 'en_US' : 'zh_CN',
        height: 300,
        plugins: 'lists image media table textcolor wordcount contextmenu',
        toolbar: 'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat',
        images_upload_handler: this.images_upload_handler,
        paste_data_images: true,
      }
    }
  },
  watch: {
    'data': function (newVal) {
      this.tinyValue = newVal;
    }
  },
  mounted () {
    tinymce.init({})
  },
  methods: {

    // 上传图片回调
    images_upload_handler (blobInfo, success, failure) {
      let file = blobInfo.blob();
      let formData = new FormData();
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          success(`${this.baseUrl}/Export/download.do?fileName=${res.message}`)
        }
      })
    },
    submit () {
      this.$emit('submit')
    }
  },
}
</script>

<style>
</style>