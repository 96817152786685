<template>
  <div class="model-wrap">
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
        <!-- <label>Category name</label> -->
      </div>
    </el-row>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showUpdate"
          >{{$t('operation.update')}}</el-button>
          <el-button
            size="medium"
            @click="showReplace(1)"
          > {{$t('dataMaintenance.deviceType.btn4')}} </el-button>
          <el-button
            size="medium"
            @click="showMerge"
          > {{$t('dataMaintenance.deviceType.btn5')}} </el-button>
          <el-dropdown
            size="medium"
            trigger="click"
            @command="showImport"
          >
            <el-button size="medium">{{$t('dataMaintenance.deviceType.btn6')}}</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">{{$t('dataMaintenance.deviceType.btn1')}}</el-dropdown-item>
              <el-dropdown-item command="2">{{$t('dataMaintenance.deviceType.btn2')}}</el-dropdown-item>
              <el-dropdown-item command="3">{{$t('dataMaintenance.deviceType.btn3')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
        <input
          type="file"
          ref="importFile"
          hidden
          @change="importFile"
        >
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :height="tableHeight"
        :data="tableData"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          prop="shortId"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col5')}}</div>
            <filter-popover
              prop="shortId"
              :filter.sync="filterData.prop.shortId"
              :sort.sync="filterData.sort"
              :disabled="true"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="typeName"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col6')}}</div>
            <filter-popover
              prop="typeName"
              :filter.sync="filterData.prop.typeName"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="name"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col1')}}</div>
            <filter-popover
              prop="name"
              :filter.sync="filterData.prop.name"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="brandName"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col2')}}</div>
            <filter-popover
              prop="brandName"
              :filter.sync="filterData.prop.brandName"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="model"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col3')}}</div>
            <filter-popover
              prop="model"
              :filter.sync="filterData.prop.model"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="unit"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col7')}}</div>
            <filter-popover
              prop="unit"
              :filter.sync="filterData.prop.unit"
              :sort.sync="filterData.sort"
              :disabled="true"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="supplierName"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col4')}}</div>
            <filter-popover
              prop="supplierName"
              :filter.sync="filterData.prop.supplierName"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
          <template slot-scope="scope">
            <div
              class="supplier-item"
              v-for="(item,index) in scope.row.supplierName"
              :key="index"
              :title="item"
            >{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="status"
        >
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('dataMaintenance.deviceType.col8')}}</div>
            <filter-popover
              prop="status"
              :filter.sync="filterData.prop.status"
              :sort.sync="filterData.sort"
              :disabled="true"
            ></filter-popover>
          </div>
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">Invalid</span>
            <span v-else>Valid</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 设备弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentVisible"
      :data="equipmentData"
      @update="updateData"
    ></equipment-dialog>

    <!-- 更新弹框 -->
    <update-dialog
      :visible.sync="UpdateDialogVisible"
      :ids="ids"
      @update="updateData"
    ></update-dialog>
    <!-- 替换弹框 -->
    <replace-dialog
      :visible.sync="ReplaceDialogVisible"
      :ids="ids"
      :items="items"
      @update="updateData"
    ></replace-dialog>
    <!-- 合并弹框 -->
    <merge-dialog
      :visible.sync="MergeDialogVisible"
      :data="tableSelected"
      @update="updateData"
    ></merge-dialog>
  </div>
</template>

<script>
// 组件
import EquipmentDialog from '../EquipmentDialog'
import UpdateDialog from './UpdateDialog'
import ReplaceDialog from './ReplaceDialog'
import MergeDialog from './MergeDialog'
import FilterPopover from '@/components/FilterPopover/index2'

// 接口
import {
  GetDeviceList,
  DelDevice,
  ImportProject,
  importCustomer,
  ImportOutInWarehouse,
} from '@/api/warehouse/dataMaintenance/deviceType'
export default {
  props: ['items'],
  components: {
    EquipmentDialog,
    UpdateDialog,
    ReplaceDialog,
    MergeDialog,
    FilterPopover
  },
  data () {
    return {
      EquipmentVisible: false,
      UpdateDialogVisible: false,
      ReplaceDialogVisible: false,
      MergeDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      equipmentData: null,
      name: '',
      searchVal: '',
      tableHeight: null,
      tableSelected: [],
      tableLoading: false,
      tableData: [],

      importType: null,
      ids: '',

      filterData: {
        prop: {
          shortId: '',
          typeName: '',
          name: '',
          brandName: '',
          model: '',
          unit: '',
          supplierName: '',
          status: '',
        },
        sort: {
          prop: '',
          order: ''
        },
      }
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.name = '';
        this.tableData = [];
      } else {
        this.name = this.items.length === 1 ? this.items[0].name : `${this.items[0].name}…`
        this.getTableData();
      }
    },

    'filterData.prop': {
      handler: function (newVal) {
        this.searchData();
      },
      deep: true,
    },
    'filterData.sort': {
      handler: function (newVal) {
        if (newVal.order === '')
          this.$refs.table.clearSort();
        else
          this.$refs.table.sort(newVal.prop, newVal.order)
      },
      deep: true,
    },
  },
  created () {
    // this.getTableData()
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.ceil((this.tableHeight - 36) / 35);
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        deviceTypeId: this.items.map(item => item.id).join(','),
        all: 1,
        deviceType: this.filterData.prop.typeName,
        deviceName: this.filterData.prop.name,
        deviceBrand: this.filterData.prop.brandName,
        deviceModel: this.filterData.prop.model,
        supplierName: this.filterData.prop.supplierName,
      }
      this.tableLoading = true;
      GetDeviceList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.supplierName = !!item.supplierName ? item.supplierName.split(',') : [];
          item.shortId = item.id.substr(0, 8)
          return item;
        });

        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.equipmentData = null;
      this.EquipmentVisible = true;
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (e) {
      this.equipmentData = e;
      this.EquipmentVisible = true;
    },
    // 删除设备
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelDevice(param).then(res => {
          if (res.success) {
            this.updateData(true);
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (row, column, event) {
    },
    // 显示导入
    showImport (e) {
      this.importType = e;
      this.$refs.importFile.value = '';
      this.$refs.importFile.click();
    },
    importFile () {
      let formData = new FormData();
      let file = this.$refs.importFile.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      switch (this.importType) {
        case 1:
          ImportProject(formData).then(res => {
            if (res.success) this.$message.success('上传成功');
          })
          break;
        case 2:
          importCustomer(formData).then(res => {
            if (res.success) this.$message.success('上传成功');
          })
          break;
        case 3:
          ImportOutInWarehouse(formData).then(res => {
            if (res.success) this.$message.success('上传成功');
          })
          break;
      }
    },
    // 显示更新弹框
    showUpdate () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.ids = this.tableSelected.map(item => item.id).join(',');
      this.UpdateDialogVisible = true;
    },
    // 更新数据
    updateData (isUpdate) {
      this.getTableData();
      if (isUpdate) this.$emit('update')
    },
    // 显示替换弹框
    showReplace () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.ids = this.tableSelected.map(item => item.id).join(',');
      this.ReplaceDialogVisible = true;
    },
    // 显示合并弹框
    showMerge () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.MergeDialogVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";
.model-wrap {
  height: calc(100% - 55px);
}
.table-row {
  height: calc(100% - 46px - 46px - 42px);
}

.el-button--medium {
  padding: 10px 10px;
}
.el-dropdown {
  border-left: 1px solid #dcdfe6;
}

.supplier-item {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>