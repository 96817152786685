import request from '@/utils/request'

// 查询确认单模板列表
export const GetConfirmationTemplateList = (data) => request({
  method: 'post',
  url: '/ConfirmationTemplate/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改确认单模板
export const AddConfirmationTemplate = (data) => request({
  method: 'post',
  url: '/ConfirmationTemplate/addOrUpdate.do',
  data: data
})

// 删除确认单模板
export const DelConfirmationTemplate = (data) => request({
  method: 'post',
  url: '/ConfirmationTemplate/delete.do',
  data: JSON.stringify(data)
})

// 模板配置下拉框
export const ConfirmationTemplates = (data) => request({
  method: 'post',
  url: '/sysStores/queryTypeCustomer.do',
  data: JSON.stringify(data)
})