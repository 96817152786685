<template>
  <div>
    <el-table
      :data="data"
      @row-dblclick="rowDblclick"
    >
      <el-table-column
        min-width="150"
        :label="$t('newDevice.installation.col1')"
        sortable
        prop="createTime"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        :label="$t('newDevice.installation.col2')"
        sortable
        prop="typeName"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        :label="$t('newDevice.installation.col3')"
        sortable
        prop="remark"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        :label="$t('newDevice.installation.col4')"
        sortable
        prop="number"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {

    }
  },
  methods: {
    rowDblclick (row) {
      this.$router.push({ path: '/home/newServiceConfirmationForm', query: { id: row.confirmOrderId, type: 1 } })
    }
  }
}
</script>

<style>
</style>