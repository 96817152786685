<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-row>
        <el-table
          border
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            label="Service Category"
            prop="name"
          ></el-table-column>
        </el-table>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
export default {
  props: ['visible', 'ids'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Service Category',
      },
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 386,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;

        if (this.tableData.length === 0) {
          this.getTableData();
        } else {
          this.setChecked();
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      GetDataFieldAll({ parent: '100000000000000000000000000000000006' }).then(res => {
        this.tableData = res;
        this.setChecked();
      });
    },
    setChecked () {
      this.$nextTick(() => {
        this.$refs.table.clearSelection();
        this.tableData.forEach(item => {
          if (this.ids.includes(item.id))
            this.$refs.table.toggleRowSelection(item)
        })
      })
    },
    // 提交
    submit () {
      // let ids = this.tableSelected.map(item => item.id).join(',');
      this.$emit('submit', this.tableSelected);
      this.dialog.visible = false;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>