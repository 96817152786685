<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="List"
      :visible.sync="dialog.visible"
      center
      width="650px"
      class="dialog"
    >
      <div>
        <el-row>
          <el-button-group class="input-group">
            <el-input
              class="search-input"
              v-model="dialog.searchVal"
              placeholder="Search"
              size="small"
              clearable
            ></el-input>
            <el-button
              size="small"
              @click="addItem"
            >Add</el-button>
            <el-button
              size="small"
              @click="delItem"
            >Rrmove</el-button>
          </el-button-group>

        </el-row>
        <el-row>
          <el-table
            class="list-table"
            :data="tableFilterData"
            @selection-change="val => dialog.tableSelected = val"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column :label="label">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.name"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="page-wrap">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="dialog.page.size"
            :current-page.sync="dialog.page.current"
          >
          </el-pagination>
        </el-row>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >OK</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ListDialog',
  props: ['visible', 'label', 'list'],
  data () {
    return {
      dialog: {
        visible: this.visible,
        searchVal: '',
        tableSelected: [],
        tableData: [],
        page: {
          size: 10,
          current: 1
        }
      },
    }
  },
  computed: {
    tableFilterData () {
      let start = (this.dialog.page.current - 1) * this.dialog.page.size;
      let end = start + this.dialog.page.size;
      let table = this.dialog.tableData.slice(start, end);

      return table.filter(item => !this.dialog.searchVal || item.name.toString().indexOf(this.dialog.searchVal) !== -1);
    },
    total () {
      return this.dialog.tableData.length;
    },
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.dialog.tableData = this.list.map(itme => Object.assign({ name: itme }))
      }
    }
  },
  methods: {
    addItem () {
      this.dialog.tableData.unshift({
        name: ''
      })
    },
    delItem () {
      this.dialog.title = '';
      this.dialog.tableData = this.dialog.tableData.filter(item => !this.dialog.tableSelected.includes(item));
    },
    submit () {
      this.$emit('listSubmit', this.dialog.tableData.map(item => item.name))
      this.dialog.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  display: flex;
  .search-input {
    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
}
.list-table {
  ::v-deep .el-input__inner {
    border: none;
  }
}
</style>