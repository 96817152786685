import request from '@/utils/request'

// 查询级别模板列表
export const GetLossLeveList = (data) => request({
  method: 'post',
  url: '/reportRiskoLevel/queryByPage.do',
  data: JSON.stringify(data)
})

// 用户新增和修改
export const AddLossLeve = (data) => request({
  method: 'post',
  url: '/reportRiskoLevel/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除用户
export const DelLossLeve = (data) => request({
  method: 'post',
  url: '/reportRiskoLevel/deleteById.do',
  data: JSON.stringify(data)
})

// 新增查询表头集合
export const GetAddHeader = (data) => request({
  method: 'post',
  url: '/reportRiskoLevel/queryAddById.do',
  data: JSON.stringify(data)
})

// 通过id查询级别模板
export const GetLeveListItem = (data) => request({
  method: 'post',
  url: '/reportRiskoLevel/queryUpdateById.do',
  data: JSON.stringify(data)
})

// 获取所有级别模板
export const GetLeveListAll = (data) => request({
  method: 'post',
  url: '/reportRiskoLevel/queryByAccessGroupId.do',
  data: JSON.stringify(data)
})
