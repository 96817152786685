<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-divider content-position="left">{{$t('quotation.options.subtitle1')}}</el-divider>
      <table class="table">
        <tr>
          <th>{{$t('quotation.options.col1')}}</th>
          <th>{{$t('quotation.options.col2')}}</th>
        </tr>
        <tr>
          <td>
            <el-select
              size="medium"
              v-model="mainKey"
              @change="mainKeyChange"
              filterable
            >
              <el-option
                v-for="(item,index) in currencys"
                :key="index"
                :value="index"
                :label="item"
              ></el-option>
            </el-select>
          </td>
          <td class="td-padding">1</td>
        </tr>
        <tr
          v-for="(item,index) in rateData"
          :key="index"
        >
          <td>
            <div class="td-padding">{{item.name}} </div>
          </td>
          <td>
            <el-input
              size="medium"
              v-model="item.rate"
            ></el-input>
          </td>
        </tr>
      </table>

      <el-divider content-position="left">{{$t('quotation.options.subtitle2')}}</el-divider>
      <el-select
        size="medium"
        v-model="decimalsVal"
        placeholder="小数位"
        filterable
      >
        <el-option value="2"></el-option>
        <el-option value="3"></el-option>
        <el-option value="4"></el-option>
        <el-option value="5"></el-option>
        <el-option value="6"></el-option>
        <el-option value="7"></el-option>
        <el-option value="8"></el-option>
      </el-select>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import {
  GetExchangeRate
} from '@/api/warehouse/quotation'
export default {
  props: ['visible', 'rateString', 'decimals'],
  data () {
    return {
      dialog: {
        visible: false,
        title: this.$i18n.t('quotation.options.title1'),
      },
      currencys: ['人民币', '美元', '港币', '泰铢', '迪拉姆', '新加坡元', '缅甸元', '马来西亚林吉特'],
      rateData: [
      ],
      mainKey: Number(localStorage.mainRate) || 0,
      exchangeRate: '',
      decimalsVal: this.decimals,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getExchangeRate(true)
  },
  methods: {
    // 获取汇率
    getExchangeRate (isUpdate = false) {
      let param = {
        key: this.mainKey,
      }
      GetExchangeRate(param).then(res => {
        if (!res) this.$message.warning(this.$i18n.t('quotation.options.message1'));

        let rates = res.split(';');

        let len = rates.length;
        let index;
        this.rateData = [];

        for (var i = 0; i < len - 1; i++) {
          index = param.key <= i ? i + 1 : i;
          this.rateData.push({
            id: index,
            rate: rates[i],
            name: this.currencys[index]
          })
        }
        if (isUpdate) {
          this.submit();
        }

      })
    },
    // 切换主货币
    mainKeyChange () {
      this.getExchangeRate()
    },

    // 提交
    submit () {
      let exchangeRate = `key=${this.mainKey}`;
      this.rateData.forEach(item => {
        exchangeRate += `@${item.id}=${item.rate}`;
      })
      localStorage.mainRate = this.mainKey;
      localStorage.quotationDecimals = this.decimalsVal;
      this.$emit('update:rateString', exchangeRate)
      this.$emit('update:decimals', this.decimalsVal)
      this.$emit('update')
      this.dialog.visible = false;
    },

  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    height: 40px;
  }
  th {
    color: #fff;
    background: #456189;
  }
  td {
    // text-align: center;
    border-bottom: 1px solid #cfe4f8;
  }
  .td-padding {
    padding: 0 15px;
  }
}
.el-select {
  width: 100%;
}
</style>