<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dataMaintenance.deviceType.title5')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
      >
        <el-form-item prop="name">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isName">{{$t('dataMaintenance.deviceType.col1')}}</el-checkbox>
          </div>
          <my-select
            :disabled="!dialog.isName"
            :data="deviceNames"
            :value.sync="dialog.name"
            :prop="{
              label:'name',
              value:'name'
            }"
            @change="deviceNameChange"
          ></my-select>
        </el-form-item>
        <el-form-item prop="type">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isType">{{$t('dataMaintenance.deviceType.col6')}}</el-checkbox>
          </div>
          <my-select
            :disabled="!dialog.isType"
            :data="types"
            :value.sync="dialog.type"
            size="small"
            :prop="{
              label:'name',
              value:'name'
            }"
          ></my-select>
        </el-form-item>
        <el-form-item prop="brand">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isBrand">{{$t('dataMaintenance.deviceType.col2')}}</el-checkbox>
          </div>
          <my-select
            :disabled="!dialog.isBrand"
            :data="brands"
            :value.sync="dialog.brand"
            size="small"
            :prop="{
              label:'name',
              value:'name'
            }"
          ></my-select>
        </el-form-item>
        <el-form-item prop="model">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isModel">{{$t('dataMaintenance.deviceType.col3')}}</el-checkbox>
          </div>
          <my-select
            :disabled="!dialog.isModel"
            :data="models"
            :value.sync="dialog.model"
            :prop="{
              label:'model',
              value:'model'
            }"
          ></my-select>
        </el-form-item>
        <el-form-item prop="unit">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isUnit">{{$t('dataMaintenance.deviceType.col7')}}</el-checkbox>
          </div>
          <my-select
            :disabled="!dialog.isUnit"
            :data="units"
            :value.sync="dialog.unit"
            size="small"
            :prop="{
              label:'name',
              value:'name'
            }"
          ></my-select>
        </el-form-item>
        <el-form-item prop="supplier">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isSupplier">{{$t('dataMaintenance.deviceType.col4')}}</el-checkbox>
          </div>
          <!-- <my-select
            :disabled="!dialog.isSupplier"
            :data="suppliers"
            :value.sync="dialog.supplier"
            size="small"
            :prop="{
              label:'name',
              value:'name'
            }"
          ></my-select> -->
          <el-select
            :disabled="!dialog.isSupplier"
            v-model="dialog.supplier"
            multiple
            filterable
            collapse-tags
          >
            <el-option
              v-for="(item,index) in suppliers"
              :key="index"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>

        </el-form-item>
        <el-form-item prop="status">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isStatus">{{$t('dataMaintenance.deviceType.col8')}}</el-checkbox>
          </div>
          <el-select
            :disabled="!dialog.isStatus"
            v-model="dialog.status"
          >
            <el-option
              :value="1"
              label="Valid"
            ></el-option>
            <el-option
              :value="0"
              label="Invalid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="sync">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isSync">{{$t('dataMaintenance.deviceType.col9')}}</el-checkbox>
          </div>
          <el-select
            :disabled="!dialog.isSync"
            v-model="dialog.sync"
          >
            <el-option
              :label="$t('dataMaintenance.deviceType.option1')"
              :value="1"
            ></el-option>
            <el-option
              :label="$t('dataMaintenance.deviceType.option2')"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import {
  EditDeviceInfoAll,
  GetDataFieldAll,
} from '@/api/warehouse/dataMaintenance'
import { GetDeviceNameAll, GetDeviceModelAll } from '@/api/warehouse/dataMaintenance/deviceType'

export default {
  components: {
    MySelect
  },
  props: ['visible', 'ids'],
  data () {
    return {
      deviceNames: [],
      models: [],
      types: [],
      brands: [],
      suppliers: [],
      units: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        isName: false,
        isType: false,
        isBrand: false,
        isModel: false,
        isSupplier: false,
        isStatus: false,
        isUnit: false,
        isSync: false,
        name: '',
        type: '',
        brand: '',
        model: '',
        supplier: [],
        status: 1,
        unit: '',
        sync: '',
      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col1'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        Object.assign(this.dialog, {
          isName: false,
          isType: false,
          isBrand: false,
          isModel: false,
          isSupplier: false,
          isStatus: false,
        });
        // 查询设备名称
        // if (this.deviceNames.length === 0)
        GetDeviceNameAll({ name: '' }).then(res => this.deviceNames = res);
        // 查询品牌
        if (this.brands.length === 0) GetDataFieldAll({ parent: '100000000000000000000000000000000002' }).then(res => this.brands = res);
        // 查询供应商
        if (this.suppliers.length === 0) GetDataFieldAll({ parent: '100000000000000000000000000000000003' }).then(res => this.suppliers = res);
        // 查询设备类型
        // if (this.types.length === 0) 
        GetDataFieldAll({ parent: '100000000000000000000000000000000001' }).then(res => this.types = res);
        // 查询模型
        if (this.types.length === 0) GetDeviceModelAll({ name: '' }).then(res => this.models = res);
        // 获取单位
        if (this.units.length === 0) GetDataFieldAll({ parent: '100000000000000000000000000000000011' }).then(res => this.units = res)

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          id: this.ids,
        }
        if (this.dialog.isName) param.name = this.dialog.name;
        if (this.dialog.isType) param.type = this.dialog.type;
        if (this.dialog.isBrand) param.brand = this.dialog.brand;
        if (this.dialog.isModel) param.model = this.dialog.model;
        if (this.dialog.isSupplier) param.supplierId = this.dialog.supplier.join(',');
        if (this.dialog.isStatus) param.status = this.dialog.status;
        if (this.dialog.isUnit) param.unit = this.dialog.unit;
        if (this.dialog.isSync) param.isOutSync = this.dialog.sync;

        let msg = this.$i18n.t('message.edit');

        this.dialog.loading = true;
        EditDeviceInfoAll(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update', this.dialog.isType)
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },

    deviceNameChange () {
      let param = {
        name: this.dialog.name,
      }
      GetDeviceModelAll(param).then(res => this.models = res);
    },
  }
}
</script>

<style lang="scss" scoped>
.label {
  position: relative;
  z-index: 1;
}

.el-select {
  width: 100%;
}
</style>