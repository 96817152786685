<template>
  <div class="wrap">
    <el-card>
      <page-header :title="view.title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="view.type !== 2"
          size="medium"
          type="primary"
          @click="submit(1)"
        >{{$t('inWarehouseDetails.btn1')}}</el-button>
        <el-button
          v-if="view.type !== 2"
          size="medium"
          type="primary"
          @click="submit(2)"
        >{{$t('inWarehouseDetails.btn2')}}</el-button>
        <el-button
          v-if="view.type !== 2"
          size="medium"
          type="primary"
          @click="submit(3)"
        >{{$t('inWarehouseDetails.btn3')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="250px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label1')"
              prop="inWarehouseSite"
            >
              <el-select
                v-model="groupForm.inWarehouseSite"
                filterable
              >
                <el-option
                  v-for="item in inWarehouseSites"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label2')"
              prop="inWarehouseType"
            >
              <el-select
                v-model="groupForm.inWarehouseType"
                filterable
              >
                <el-option
                  v-for="item in inWarehouseTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="有申购"
              prop="subscribe"
            >
              <el-radio-group v-model="groupForm.subscribe">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

        </el-row>
        <el-divider content-position="left">{{$t('inWarehouseDetails.subtitle1')}}</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label3')"
              prop="projectName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.projectName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button
                  @click="ProjectDialogVisible = true"
                  :disabled="view.type === 2"
                >{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label4')"
              prop="contractNo"
            >
              <el-input
                v-model="groupForm.contractNo"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('inWarehouseDetails.subtitle4')}}</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label6')"
              prop="applyFormNo"
            >
              <el-input
                class="search-input"
                v-model="groupForm.applyFormNo"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label5')"
              prop="requisitioner"
            >
              <el-select
                disabled
                v-model="groupForm.requisitioner"
                filterable
              >
                <el-option
                  v-for="item in requisitioners"
                  :key="item.id"
                  :value="item.id"
                  :label="item.fullname"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('inWarehouseDetails.subtitle2')}}</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label7')"
              prop="orderNumber"
            >
              <el-input
                v-model="groupForm.orderNumber"
                class="search-input"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label8')"
              prop="orderTotal"
            >
              <el-input
                v-model="groupForm.orderTotal"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label9')"
              prop="subscriber"
            >
              <el-input
                v-model="groupForm.subscriber"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label10')"
              prop="paymentDate"
            >
              <el-date-picker
                v-model="groupForm.paymentDate"
                type="date"
                disabled
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">{{$t('inWarehouseDetails.subtitle3')}}</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label11')"
              prop="deviceName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.deviceName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button
                  @click="showEquipment"
                  :disabled="view.type === 2"
                >{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label12')"
              prop="deviceModel"
            >
              <el-input
                v-model="groupForm.deviceModel"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label13')"
              prop="deviceBrand"
            >
              <el-input
                v-model="groupForm.deviceBrand"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label14')"
              prop="deviceType"
            >
              <el-input
                v-model="groupForm.deviceType"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label15')"
              prop="deviceCount"
            >
              <el-button-group class="input-group">
                <el-input-number
                  v-model="groupForm.deviceCount"
                  class="search-input"
                  :controls="false"
                  :min="0"
                ></el-input-number>
                <el-select
                  class="unit-select"
                  v-model="groupForm.unit"
                  filterable
                >
                  <el-option
                    v-for="item in units"
                    :key="item.id"
                    :value="item.name"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item
                  :label="$t('inWarehouseDetails.label16')"
                  prop="unitPrice"
                >
                  <el-input-number
                    v-model="groupForm.unitPrice"
                    class="search-input"
                    :controls="false"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-radio-group
                  class="input-radio-group"
                  v-model="groupForm.priceType"
                >
                  <el-radio :label="1">{{$t('inWarehouseDetails.new')}}</el-radio>
                  <el-radio :label="0">{{$t('inWarehouseDetails.old')}}</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="isSerialNumber">
              <div slot="label">
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="hover"
                  :content="$t('inWarehouseDetails.tip17')"
                >
                  <span slot="reference">{{$t('inWarehouseDetails.label17')}}<i class="el-icon-s-opportunity"></i></span>
                </el-popover>
              </div>
              <el-radio-group v-model="groupForm.isSerialNumber">
                <el-radio :label="1">{{$t('inWarehouseDetails.yes')}}</el-radio>
                <el-radio :label="0">{{$t('inWarehouseDetails.no')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label18')"
              prop="serialNumber"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.serialNumber"
                  :placeholder="$t('inWarehouseDetails.placeholder18')"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button
                  :disabled="groupForm.isSerialNumber === 0"
                  @click="SerialDialogVisible = true"
                >{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label19')"
              prop="supplierName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.supplierName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button
                  @click="SupplierDialogVisible = true"
                  :disabled="view.type === 2"
                >{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label20')"
              prop="remarks"
            >
              <el-input v-model="groupForm.remarks"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label21')"
              prop="warehouseSite"
            >
              <el-input v-model="groupForm.warehouseSite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label22')"
              prop="isBorrow"
            >
              <el-radio-group v-model="groupForm.isBorrow">
                <el-radio :label="1">{{$t('inWarehouseDetails.yes')}}</el-radio>
                <el-radio :label="0">{{$t('inWarehouseDetails.no')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('inWarehouseDetails.label23')"
              prop="appointDate"
            >
              <el-date-picker
                v-model="groupForm.appointDate"
                type="date"
                value-format="yyyy-MM-dd"
                :disabled="groupForm.isBorrow === 0"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="view.type !== 2"
          >
            <el-form-item
              :label="$t('inWarehouseDetails.label24')"
              prop="isLock"
            >
              <el-radio-group v-model="groupForm.isLock">
                <el-radio :label="1">{{$t('inWarehouseDetails.yes')}}</el-radio>
                <el-radio :label="0">{{$t('inWarehouseDetails.no')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="view.type === 3">
        <el-divider content-position="left">{{$t('inWarehouseDetails.subtitle5')}}</el-divider>
        <out-warehouse-table
          :table-data="tableData"
          :table-loading="tableLoading"
          :table-selected.sync="tableSelected"
          :permit="outPermit"
          :cols="colData"
        ></out-warehouse-table>
        <el-row class="page-wrap">
          <my-page
            layout="prev, pager, next, jumper"
            :total="page.total"
            :page-size.sync="page.size"
            :current-page.sync="page.current"
            @current-change="getTableData"
            @size-change="getTableData"
          >
          </my-page>
        </el-row>
      </div>
    </el-card>
    <!-- 项目弹框 -->
    <project-dialog
      :visible.sync="ProjectDialogVisible"
      @submit="projectSubmit"
    ></project-dialog>

    <!-- 设备弹框 -->
    <equipment-dialog
      ref="equipmentDialog"
      :visible.sync="EquipmentDialogVisible"
      :project-id="groupForm.projectId"
      :order-form-id="orderFormId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <equipment-dialog2
      :visible.sync="EquipmentDialog2Visible"
      :project-id="groupForm.projectId"
      @submit="equipmentSubmit"
    ></equipment-dialog2>
    <!-- 设备弹框 -->
    <!-- 供应商弹框 -->
    <supplier-dialog
      :visible.sync="SupplierDialogVisible"
      @submit="supplierSubmit"
    ></supplier-dialog>
    <!-- 序列号弹框 -->
    <serial-dialog
      :visible.sync="SerialDialogVisible"
      :data="groupForm.inventoryDevices"
      :max-count="groupForm.deviceCount"
      @submit="serialSubmit"
    ></serial-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import ProjectDialog from '@/components/ProjectDialog'
import EquipmentDialog from './components/EquipmentDialog'
import EquipmentDialog2 from './components/EquipmentDialog2'
import SupplierDialog from '@/components/SupplierDialog'
import SerialDialog from '@/components/SerialDialog'
import OutWarehouseTable from './components/OutWarehouseTable'

// 接口
import {
  AddInWarehouse,
  GetInWarehouseItem,
  GetOutWarehouseList,
} from '@/api/warehouse/inventoryManagement'
import { GetUserAll } from '@/api/userManagement'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'InWarehouseDetails',
  components: {
    PageHeader,
    ProjectDialog,
    EquipmentDialog,
    EquipmentDialog2,
    SupplierDialog,
    SerialDialog,
    OutWarehouseTable,
  },
  data () {
    return {
      view: {
        type: 1,
        title: '',
      },
      ProjectDialogVisible: false,
      EquipmentDialogVisible: false,
      EquipmentDialog2Visible: false,
      SupplierDialogVisible: false,
      SerialDialogVisible: false,
      inWarehouseSites: [],
      inWarehouseTypes: [],
      requisitioners: [],
      units: [],
      groupForm: {
        id: null,
        loading: false,
        inWarehouseSite: '',
        inWarehouseType: '',
        projectId: '',
        projectName: '',
        contractNo: '',
        requisitioner: '',
        applyFormNo: '',
        orderId: '',
        orderNumber: '',
        orderTotal: '',
        subscriber: '',
        paymentDate: null,
        deviceId: '',
        deviceName: '',
        deviceModel: '',
        deviceBrand: '',
        deviceType: '',
        deviceCount: 0,
        unit: '',
        unitPrice: '',
        priceType: 1,
        isSerialNumber: 0,
        serialNumber: '',
        supplierId: '',
        supplierName: '',
        remarks: '',
        warehouseSite: '',
        isBorrow: 0,
        appointDate: null,
        isLock: 0,
        outCount: 0,
        outSerialNumbers: '',
        orderDeviceInfoId: '',
        orderApplyFormId: null,
        applyFormId: null,
        inventoryDevices: [],
        subscribe: 0,
        applicationFormInfoId: undefined,
      },
      rules: {
        inWarehouseSite: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        inWarehouseType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        projectName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceCount: [
          { required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' },
          { validator: this.validateDeviceCount, trigger: 'blur' }
        ],
        serialNumber: [{ validator: this.validateSerialNumber, trigger: 'blur' }],
        supplierName: [{ validator: this.validateSupplier, trigger: 'blur' }],
      },

      outPermit: {
        edit: false,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      applyData: [],
      orderData: [],

      colData: {
        createTime: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col1'),
          visible: true,
        },
        deviceName: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col2'),
          visible: true,
        },
        deviceBrand: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col3'),
          visible: true,
        },
        deviceModel: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col4'),
          visible: true,
        },
        deviceType: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col5'),
          visible: true,
        },
        serialNumbers: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col6'),
          visible: true,
        },
        deviceUnit: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col7'),
          visible: true,
        },
        count: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col8'),
          visible: true,
        },
        receiverName: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col11'),
          visible: true,
        },
        projectName: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col12'),
          visible: true,
        },
        quotationNumber: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col13'),
          visible: true,
        },
        applicationFormNumber: {
          name: '购物申请单',
          visible: true,
        },
        orderNumber: {
          name: '订单编号',
          visible: true,
        },
        outRemark: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col14'),
          visible: true,
        },
        isBorrowOut: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col15'),
          visible: true,
        },
        returnDate: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col16'),
          visible: true,
        },
        returnedTime: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col17'),
          visible: true,
        },
        courierNumber: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col18'),
          visible: true,
        },
        courierCompany: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col19'),
          visible: true,
        },
        courierCost: {
          name: this.$i18n.t('inventoryManagement.outWarehouse.col20'),
          visible: true,
        }
      },

      orderFormId: null,
    }
  },
  computed: {
    'requisitionerName': function () {
      let requisitioner = this.requisitioners.find(item => item.id == this.groupForm.requisitioner);
      return requisitioner ? requisitioner.fullname : '';
    },
    'groupForm.isBorrow': function () {
      this.groupForm.appointDate = null;
    },
  },
  watch: {
    'groupForm.isSerialNumber': function (newVal) {
      if (newVal === 0) {
        this.groupForm.serialNumber = '';
        this.$refs.groupForm.validateField('serialNumber');
      }
    },
    'groupForm.isBorrow': function (newVal) {
      if (newVal === 0) this.groupForm.appointDate = null;
    },
    'groupForm.deviceCount': function (newVal) {
      this.$refs.groupForm.validateField('serialNumber')
    },
    'groupForm.serialNumber': function (newVal) {
      this.$refs.groupForm.validateField('serialNumber')
    },
  },
  created () {
    this.initView();
    // 获取入库地址
    GetDataFieldAll({ parent: '100000000000000000000000000000000015' }).then(res => this.inWarehouseSites = res);
    // 获取入库方式
    GetDataFieldAll({ parent: '100000000000000000000000000000000016' }).then(res => this.inWarehouseTypes = res);
    // 获取申购人
    GetUserAll({}).then(res => this.requisitioners = res);
    // 获取单位
    GetDataFieldAll({ parent: '100000000000000000000000000000000011' }).then(res => this.units = res);
  },
  methods: {
    initView () {
      this.view.type = Number(this.$route.query.type);

      switch (this.view.type) {
        case 1:
          this.view.title = this.$i18n.t('inWarehouseDetails.title1');
          this.$nextTick(() => {
            if (!!sessionStorage.deviceData) this.setItem();
          })
          break;
        case 2:
          this.getItem();
          break;
        case 3:
          this.view.title = this.$i18n.t('inWarehouseDetails.title3');
          this.getItem();
          // 出库列表
          this.getTableData();
          break;
      }
    },
    setItem () {
      let res = JSON.parse(sessionStorage.deviceData);
      Object.assign(this.groupForm, {
        projectId: res.projectContractId,
        projectName: res.projectName,
        contractNo: res.quotationNumber,
        // requisitioner: res.purchaseUserId,
        // applyFormNo: res.applicationNumber,
        // orderId: res.orderFormId.length > 0 ? res.orderFormId[0] : '',
        // orderNumber: res.orderNumber.length > 0 ? res.orderNumber[0] : '',
        // orderTotal: res.totalPrice,
        // subscriber: res.orderUser,
        // paymentDate: res.paymentDate,
        // deviceId: res.deviceInfoId,
        // deviceName: res.deviceName,
        // deviceModel: res.deviceModel,
        // deviceBrand: res.brandName,
        // deviceType: res.typeName,
        // deviceCount: res.count - res.inCount,
        // unit: res.unit,
        // unitPrice: res.singlePriceUnit,
        // applicationFormInfoId: res.id
      })
      this.orderFormId = res.orderFormId.length > 0 ? res.orderFormId[0] : '',
        sessionStorage.removeItem('deviceData');

      this.$nextTick(() => {
        this.$refs.equipmentDialog.autoSelect();
      })

    },
    getItem () {
      let param = { id: this.$route.query.id }
      GetInWarehouseItem(param).then(res => {
        if (this.view.type === 2) this.view.title = `${this.$i18n.t('inWarehouseDetails.title2')}：${res.createTime}`;
        Object.assign(this.groupForm, {
          id: res.id,
          inWarehouseSite: res.inOrgId,
          inWarehouseType: res.inTypeId,
          projectId: res.projectContractId,
          projectName: res.projectName,
          contractNo: res.quotationNumber,
          requisitioner: res.purchaseUserId,
          applyFormNo: res.applicationFormNumber,
          orderId: res.orderFormId,
          orderNumber: res.orderNumber,
          orderTotal: res.orderTotalPrice,
          subscriber: res.orderUser,
          paymentDate: res.paymentDate,
          deviceId: res.deviceInfoId,
          deviceName: res.deviceName,
          deviceModel: res.deviceModel,
          deviceBrand: res.deviceBrand,
          deviceType: res.deviceType,
          deviceCount: res.deviceCount + res.outCount,
          unit: res.deviceUnit,
          unitPrice: res.devicePrice,
          priceType: res.isNewDevice,
          isSerialNumber: res.isHasSerialNumber,
          serialNumber: res.serialNumbers + ';' + res.serialNumbers2,
          supplierId: res.supplierId,
          supplierName: res.supplierName,
          remarks: res.inRemark,
          warehouseSite: res.warehouseLocation,
          isBorrow: res.isBorrowFactoryDevice,
          appointDate: res.returnDate,
          isLock: res.isLock,
          outCount: res.outCount,
          outSerialNumbers: res.serialNumbers2 || '',
          orderDeviceInfoId: res.orderDeviceInfoId,
          inventoryDevices: res.inventoryDevices || [],
          subscribe: res.hasPO,
        })

        this.groupForm.serialNumber = this.groupForm.serialNumber.split(';').filter(item => !!item && item !== 'null').join(';')

        if (!!this.groupForm.serialNumber) {
          let serialArr = this.groupForm.inventoryDevices.map(item => item.serialNumber);
          this.groupForm.serialNumber.split(';').forEach(item => {
            if (!serialArr.includes(item)) {
              this.groupForm.inventoryDevices.push({ serialNumber: item })
            }
          });
        }
      })
    },
    // 提交
    submit (type) {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        if (!!this.groupForm.orderApplyFormId && !!this.groupForm.applyFormId && this.groupForm.orderApplyFormId !== this.groupForm.applyFormId)
          return this.$message.warning(this.$i18n.t('inWarehouseDetails.message3'));

        let param = {
          inOrgId: this.groupForm.inWarehouseSite,
          inTypeId: this.groupForm.inWarehouseType,
          projectContractId: this.groupForm.projectId,
          applicationFormNumber: this.groupForm.applyFormNo,
          purchaseUserId: this.groupForm.requisitioner,
          purchaseUser: this.requisitionerName,
          orderFormId: this.groupForm.orderId,
          deviceInfoId: this.groupForm.deviceId,
          deviceName: this.groupForm.deviceName,
          deviceModel: this.groupForm.deviceModel,
          deviceBrand: this.groupForm.deviceBrand,
          deviceType: this.groupForm.deviceType,
          deviceCount: this.groupForm.deviceCount - this.groupForm.outCount,
          deviceUnit: this.groupForm.unit,
          devicePriceUnit: 1,
          devicePrice: this.groupForm.unitPrice,
          isNewDevice: this.groupForm.priceType,
          isHasSerialNumber: this.groupForm.isSerialNumber,

          supplierId: this.groupForm.supplierId,
          supplierName: this.groupForm.supplierName,
          inRemark: this.groupForm.remarks,
          warehouseLocation: this.groupForm.warehouseSite,
          isBorrowFactoryDevice: this.groupForm.isBorrow,
          returnDate: this.groupForm.appointDate,
          isLock: this.groupForm.isLock,
          returnedTime: null,
          isReturned: 0,
          serialNumbers: this.groupForm.serialNumber.split(';').filter(item => !this.groupForm.outSerialNumbers.includes(item)).join(';'),
          orderDeviceInfoId: this.groupForm.orderDeviceInfoId,
          inventoryDevices: this.groupForm.inventoryDevices,
          hasPO: this.groupForm.subscribe,
          applicationFormInfoId: this.groupForm.applicationFormInfoId,
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }
        this.groupForm.loading = true;
        AddInWarehouse(param).then(res => {
          if (res.success) {
            switch (type) {
              case 1:
                this.back();
                break;
              case 2:
                this.$refs.groupForm.resetFields()
                break;
              case 3:
                this.$router.push({
                  path: '/home/outWarehouseDetails',
                  query: {
                    id: res.message,
                    deviceInfoId: this.groupForm.deviceId,
                    outCount: this.groupForm.deviceCount,
                    type: 5,
                  }
                })
                break;
            }
            this.$message.success(msg);
          } else {
            this.$message.success(res.message);
          }
          this.groupForm.loading = false;
        })
      })
    },
    // 项目提交
    projectSubmit (data) {
      Object.assign(this.groupForm, {
        projectId: data.id,
        projectName: data.name,
        contractNo: data.quotationNumber,

        // 清空订单
        orderApplyFormId: '',
        orderId: '',
        orderNumber: '',
        orderTotal: '',
        subscriber: '',
        paymentDate: null,
        supplierId: '',
        supplierName: '',

        // 清空购物申请单
        applyFormId: '',
        applyFormNo: '',
        requisitioner: '',

        // 清除设备
        orderDeviceInfoId: '',
        deviceId: '',
        deviceName: '',
        deviceModel: '',
        deviceBrand: '',
        deviceType: '',
        deviceCount: '',
        unit: '',
        unitPrice: '',
      })
      this.$refs.groupForm.validateField('projectName');
    },
    // 
    showEquipment () {
      if (this.groupForm.subscribe === 0) {
        this.EquipmentDialogVisible = true;
      } else {
        this.EquipmentDialog2Visible = true;
      }
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.groupForm, {
        // 设备
        orderDeviceInfoId: data.id,
        deviceId: data.deviceInfoId || data.id,
        deviceName: data.deviceName || data.name,
        deviceModel: data.deviceModel || data.model,
        deviceBrand: data.brandName,
        deviceType: data.typeName,
        deviceCount: data.count - data.inCount || '',
        unit: data.unit || '',
        unitPrice: data.singlePrice || '',
        // 订单
        orderApplyFormId: data.applicationFormId,
        orderId: data.orderFormId,
        orderNumber: data.orderNumber,
        orderTotal: data.totalPrice,
        subscriber: data.orderUser,
        paymentDate: data.paymentDate,
        supplierId: data.supplierId,
        supplierName: data.supplierName,
        // 申请
        applyFormId: data.applicationFormId,
        applyFormNo: data.applicationNumber,
        requisitioner: data.purchaseUserId,
      })
      this.$refs.groupForm.validateField('deviceName');
    },
    // 供应商提交
    supplierSubmit (data) {
      Object.assign(this.groupForm, {
        supplierId: data.id,
        supplierName: data.name,
      });

      this.$refs.groupForm.validateField('supplierName');
    },
    // 后退
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 验证数量
    validateDeviceCount (rule, value, callback) {
      if (this.groupForm.deviceCount < this.groupForm.outCount)
        callback(new Error(`设备已出库${this.groupForm.outCount}${this.groupForm.unit},入库数量不能少于${this.groupForm.outCount}`));
      else
        return callback();
    },
    // 验证序列号
    validateSerialNumber (rule, value, callback) {
      if (this.groupForm.isSerialNumber == 0)
        return callback();
      if (this.groupForm.isSerialNumber > 0 && this.groupForm.serialNumber === '') {
        callback(new Error(this.$i18n.t('message.notNull')));
      } else if (this.groupForm.serialNumber.split(';').length != this.groupForm.deviceCount) {
        callback(new Error(this.$i18n.t('inWarehouseDetails.message1')));
      } else if (!this.groupForm.outSerialNumbers.split(';').every(item => this.groupForm.serialNumber.includes(item))) {
        callback(new Error(`${this.groupForm.outSerialNumbers}${this.$i18n.t('inWarehouseDetails.message2')}`));
      } else {
        callback();
      }
    },
    // 验证供应商
    validateSupplier (rule, value, callback) {
      if (this.groupForm.subscribe === 0) return callback();
      if (/.+/.test(this.groupForm.supplierName))
        callback();
      else
        callback(new Error(this.$i18n.t('message.notNull')));
    },
    // 获取出库记录
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: '',
        inventoryId: this.$route.query.id,
      }
      this.tableLoading = true;
      GetOutWarehouseList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 序列号提交
    serialSubmit (data) {
      this.groupForm.inventoryDevices = data;
      this.groupForm.serialNumber = data.map(item => item.serialNumber).join(';');
    },

  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .el-date-editor,
  .el-cascader,
  .el-select {
    width: 100%;
  }
}
.upload-box {
  width: 100%;
  height: auto;
  &:before {
    content: "";
    width: 0;
    height: 0;
    clear: both;
    display: block;
  }
}
.input-group {
  .el-button {
    height: 36px;
  }
}
.el-message-box__message {
  img {
    width: 100%;
  }
}

.group-form {
  .el-select.unit-select {
    width: 200px;
    ::v-deep .el-input__inner {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .input-radio-group {
    line-height: 40px;
  }
}
</style>