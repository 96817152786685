<template>
  <div class="wrap">
    <el-card>
      <page-header
        :title="$t('accountPolicies.title1')"
        hide-back
      >
        <el-button
          size="medium"
          type="primary"
          @click="save"
          :disabled="!permit.add"
        >{{$t('operation.save')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="320px"
        label-position="left"
      >

        <el-form-item :label="$t('accountPolicies.label1')">
          <el-input v-model="groupForm.loginNum"></el-input>allowed retries in
          <el-input v-model="groupForm.intervalTime"></el-input>minutes
        </el-form-item>
        <el-form-item :label="$t('accountPolicies.label2')">
          <el-input v-model="groupForm.LockTime"></el-input>minutes
        </el-form-item>
        <el-form-item :label="$t('accountPolicies.label3')">
          <el-input v-model="groupForm.pawLen"></el-input>
        </el-form-item>
        <el-form-item :label="$t('accountPolicies.label4')">
          <el-input v-model="groupForm.expiration"></el-input>
        </el-form-item>
        <el-form-item label="">
          <div slot="label">
            <el-popover
              placement="bottom-start"
              width="550"
              trigger="hover"
              :title="$t('accountPolicies.subtitle1')"
            >
              <p>{{$t('accountPolicies.p1')}}</p>
              <p>{{$t('accountPolicies.p2')}}</p>
              <p>{{$t('accountPolicies.p3')}}</p>
              <p>{{$t('accountPolicies.p4')}}</p>
              <p>{{$t('accountPolicies.p5')}}</p>
              <p>{{$t('accountPolicies.p6')}}</p>
              <p>{{$t('accountPolicies.p7')}}</p>
              <span slot="reference">{{$t('accountPolicies.label5')}} <i class="el-icon-s-opportunity"></i></span>
            </el-popover>
          </div>
          <el-switch
            v-model="groupForm.requirements"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </el-form-item>
      </el-form>
      <el-divider content-position="left">{{$t('accountPolicies.subtitle2')}}</el-divider>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table border          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="lokedIp"
            :label="$t('accountPolicies.col1')"
            sortable
          ></el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('accountPolicies.col2')"
            sortable
            :formatter="$formatTableData"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  AddAccountPolicies,
  GetAccountPoliciesItem,
  GetSysLockedAccountList,
  DelSysLockedAccount,
} from '@/api/accountPolicies'
export default {
  name: 'AccountPolicies',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        add: true,
      },
      groupForm: {
        id: null,
        loginNum: '',
        intervalTime: '',
        LockTime: '',
        pawLen: '',
        expiration: '',
        requirements: false,
      },
      rules: {

      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      userData: null,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  created () {
    this.getItem();
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getItem () {
      GetAccountPoliciesItem().then(res => {
        Object.assign(this.groupForm, {
          id: res.id,
          loginNum: res.loginAttempts,
          intervalTime: res.retries,
          LockTime: res.lockoutduration,
          pawLen: res.miPasswordLength,
          expiration: res.passwordExpiration,
          requirements: !!res.requirements,
        })
      })
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetSysLockedAccountList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelSysLockedAccount(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 保存
    save () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          id: this.groupForm.id,
          loginAttempts: this.groupForm.loginNum,
          retries: this.groupForm.intervalTime,
          lockoutduration: this.groupForm.LockTime,
          miPasswordLength: this.groupForm.pawLen,
          passwordExpiration: this.groupForm.expiration,
          requirements: Number(this.groupForm.requirements),
        }
        AddAccountPolicies(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.save'))
          }
        })
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.add = permission.includes('AccountPoliciesAdd');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .el-select {
    width: 100%;
  }
  .el-input {
    width: 100px;
    margin-right: 10px;
  }
}
.el-popover {
  p {
    margin: 0;
  }
}

.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 56px - 272px - 49px - 46px - 42px);
}
</style>