<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="Overview Data Presentation"
      :visible.sync="dialog.visible"
      center
      width="700px"
    >
      <el-row :gutter="10">
        <el-table
          :data="tableData"
          @row-click="rowClick"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="Current"></el-table-column>
          <el-table-column
            label="ID"
            prop="id"
          ></el-table-column>
          <el-table-column label="Report Template"></el-table-column>
          <el-table-column label="Description"></el-table-column>
          <el-table-column label="Content">
            <template slot-scope="scope">
              <el-select
                size="small"
                v-model="scope.row.type"
              >
                <el-option
                  value="0"
                  label="Table"
                ></el-option>
                <el-option
                  value="2"
                  label="Chart"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="dialog.visible = false"
        >Set Current</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['visible'],
  data () {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      currentId: '1',
      checkedId: '',
      selected: ['2'],
      tableData: [{ id: '1', type: '0' }, { id: '2', type: '0' }, { id: '3', type: '0' }],
      dialog: {
        visible: false,
      },
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.getTableData();
      }
    }
  },

  methods: {
    getTableData () {

    },
    // 获取选中id
    rowClick (row, column, event) {
      this.checkedId = row.id;
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.id === this.checkedId) return 'checked-row';
      else if (row.id === this.currentId) return 'current-row';
      else if (this.selected.includes(row.id)) return 'selected-row';
      else return '';
    },
  }
}
</script>

<style lang="scss" scoped>
.radio-wrap .el-radio {
  display: block;
  margin-bottom: 10px;
}
.el-table--enable-row-hover ::v-deep .el-table__body tr:hover > td {
  background-color: #99ccff !important;
}

.el-table ::v-deep {
  .selected-row > td {
    background-color: #c9c9c9 !important;
  }
  .current-row > td {
    background-color: #ccffcc !important;
  }
  .checked-row > td {
    background-color: #99ccff !important;
  }
}
</style>