<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="reportForm"
        size="small"
        class="report-form"
        v-loading="formLoading"
      >
        <el-form-item
          :label="$t('reportDesigner.dialog.label1')"
          prop="fieldType"
        >
          <el-select v-model="dialog.fieldType">
            <el-option
              :value="0"
              label="Report Field"
            ></el-option>
            <el-option
              :value="1"
              label="Template Parameters"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="!reportData"
          :label="$t('reportDesigner.dialog.label2')"
          prop="headers"
          class="switch-item"
        >
          <el-switch
            v-model="dialog.headers"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="dialog.headers ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
            :disabled="dialog.fieldType === 1"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          v-if="dialog.headers === 1"
          :label="$t('reportDesigner.dialog.label3')"
          prop="mainField"
        >
          <el-select
            v-model="dialog.mainField"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in mainFields"
              :key="item.id"
              :label="item.mainFieText"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-else
          :label="dialog.fieldType == 0 ? $t('reportDesigner.dialog.label4') : $t('reportDesigner.dialog.label5')"
          prop="mainFieldName"
        >
          <el-input v-model="dialog.mainFieldName"> </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportDesigner.dialog.label6')"
          prop="secondLanguage1"
        >
          <el-input v-model="dialog.secondLanguage1"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportDesigner.dialog.label7')"
          prop="desciption1"
        >
          <el-input
            type="textarea"
            v-model="dialog.desciption1"
            :rows="3"
          ></el-input>
        </el-form-item>
        <div v-if="dialog.headers === 1">
          <el-form-item
            :label="$t('reportDesigner.dialog.label8')"
            prop="subField"
          >
            <el-input v-model="dialog.subField"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('reportDesigner.dialog.label6')"
            prop="secondLanguage2"
          >
            <el-input v-model="dialog.secondLanguage2"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('reportDesigner.dialog.label7')"
            prop="desciption2"
          >
            <el-input
              type="textarea"
              v-model="dialog.desciption2"
              :rows="3"
            ></el-input>
          </el-form-item>
        </div>
        <el-row :gutter="20">
          <el-col
            :span="12"
            v-if="dialog.fieldType == 1"
          >
            <el-form-item
              :label="$t('reportDesigner.dialog.label9')"
              prop="operator"
            >
              <el-select v-model="dialog.operator">
                <el-option
                  v-for="(item,index) in operators"
                  :key="index"
                  :value="index"
                  :label="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="dialog.fieldType == 1 ? 12 : 24">
            <el-form-item
              :label="$t('reportDesigner.dialog.label10')"
              prop="measure"
            >
              <el-select
                v-model="dialog.measure"
                @change="measureChange"
                filterable
              >
                <el-option
                  v-for="item in measures"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          v-if="dialog.measure == 12"
          :label="$t('reportDesigner.dialog.label11')"
          prop="options"
        >
          <div
            v-for="(item,index) in dialog.options"
            :key="index"
            class="option-item"
          >
            <el-input
              v-model="item.label"
              placeholder="Label"
            ></el-input>
            <el-input
              v-model="item.value"
              placeholder="Value"
            ></el-input>
            <el-button
              icon="el-icon-plus"
              type="success"
              @click="addOption(index)"
            ></el-button>
            <el-button
              :disabled="dialog.options.length == 1"
              icon="el-icon-delete"
              type="danger"
              @click="deleteOption(index)"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('reportDesigner.dialog.label12')"
          class="switch-item"
          prop="status"
          v-if="dialog.fieldType == 0"
        >
          <el-switch
            v-model="dialog.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="dialog.status ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('reportDesigner.dialog.label13')"
          class="switch-item"
          prop="queryFields"
          v-if="dialog.fieldType == 0"
        >
          <el-switch
            v-model="dialog.queryFields"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="dialog.queryFields ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  AddReportDesigner,
  GetFieldAll,
} from '@/api/reportDesigner'

export default {
  name: 'ReportDesignerDialog',
  props: ['visible', 'reportData', 'reportTemplateId', 'measures'],
  data () {
    return {
      formLoading: false,
      mainFields: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        fieldType: 0,
        headers: 1,
        mainFieldName: '',
        mainField: '',
        secondLanguage1: '',
        desciption1: '',
        subField: '',
        secondLanguage2: '',
        desciption2: '',
        kpiFields: 0,
        status: 0,
        queryFields: 0,
        measure: '1',
        fieldTable: [{ isEdit: false, value: 200 }, { isEdit: false, value: 200 }, { isEdit: false, value: 200 }, { isEdit: false, value: 200 }, { isEdit: false, value: 200 }, { isEdit: false, value: 200 }],
        options: [{
          label: '',
          value: '',
        }],
        operator: '',
        linkToJson: '',

        // isSearch: 0,
        // isSort: 0,
      },
      rules: {
        subField: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        mainField: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        mainFieldName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      }
    }
  },
  computed: {
    // 规则选项
    'operators': function () {
      return this.$global.typeOperators[this.currentMeasure.type];
    },
    // 当前字段类型
    'currentMeasure': function () {
      let result = this.measures.find(item => item.id === this.dialog.measure);
      if (!!result) {
        return result;
      } else {
        return { type: 'number' }
      }
    },
  },
  watch: {
    'dialog.fieldType': function (newVal) {
      if (newVal === 1) {
        this.dialog.headers = 0;
        this.dialog.kpiFields = 0;
        this.dialog.status = 0;
      }
    },
    'dialog.mainField': function (newVal) {
      if (this.formLoading) return;
      let current = this.mainFields.filter(item => item.id === newVal);

      if (current.length > 0) {
        this.dialog.secondLanguage1 = current[0].languageTextMain;
        this.dialog.desciption1 = current[0].descriptionMain;
      }

    },
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.reportForm && this.$refs.reportForm.resetFields();
        this.getOption();
        if (this.reportData) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              id: this.reportData.id,
              title: this.$i18n.t('reportDesigner.dialog.title2'),
              fieldType: this.reportData.fieIdType,
              headers: this.reportData.headers,
              mainFieldName: this.reportData.mainFieText,
              mainField: this.reportData.mainFieId,
              secondLanguage1: this.reportData.languageTextMain,
              desciption1: this.reportData.descriptionMain,
              subField: this.reportData.subFieldText,
              secondLanguage2: this.reportData.languageTextSub,
              desciption2: this.reportData.descriptionSub,
              kpiFields: this.reportData.kPIFields,
              status: this.reportData.riskLevelFields,
              measure: this.reportData.dataTypeId,
              queryFields: this.reportData.defaultQueryFields,
              operator: Number(this.reportData.defaultOperator),
              linkToJson: this.reportData.linkToJson,
              // isSearch: this.reportData.isSearch,
              // isSort: this.reportData.isSort,
            })
            if (this.reportData.optionJson == 'null' || !this.reportData.optionJson) {
              this.dialog.options = [{
                label: '',
                value: '',
              }]
            } else {
              this.dialog.options = JSON.parse(this.reportData.optionJson)
            }
          })
        } else {
          Object.assign(this.dialog, {
            mainFieldName: '',
            mainField: '',
            subField: '',
            secondLanguage2: '',
            desciption2: '',
            // options: [{
            //   label: '',
            //   value: '',
            // }],
          });

          this.dialog.title = this.$i18n.t('reportDesigner.dialog.title1')
        }
      }

      this.dialog.visible = this.visible;
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    }
  },
  created () {
  },
  methods: {
    // 获取下拉框数据
    getOption () {
      this.formLoading = true;
      Promise.all([
        // 获取所有字段
        GetFieldAll({ templatesId: this.reportTemplateId }).then(res => this.mainFields = res)
      ]).then((result) => {
        this.formLoading = false;
      })
    },
    // 添加或修改
    submit () {
      this.$refs.reportForm.validate(valid => {
        if (!valid) return;
        let param = {
          fieIdType: this.dialog.fieldType,
          headers: this.dialog.headers,
          languageTextMain: this.dialog.secondLanguage1,
          descriptionMain: this.dialog.desciption1,
          kPIFields: this.dialog.kpiFields,
          riskLevelFields: this.dialog.status,
          templatesId: this.reportTemplateId,
          dataTypeId: this.dialog.measure,
          defaultQueryFields: this.dialog.queryFields,
          defaultOperator: this.dialog.operator,
          linkToJson: this.dialog.linkToJson,
          // isSearch: this.dialog.isSearch,
          // isSort: this.dialog.isSort,
        }
        if (this.dialog.measure == 12) {
          param.optionJson = JSON.stringify(this.dialog.options);
        }
        if (this.dialog.headers) {
          Object.assign(param, {
            subFieldText: this.dialog.subField,
            languageTextSub: this.dialog.secondLanguage2,
            descriptionSub: this.dialog.desciption2,
          })

          if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.mainField)) {
            param.mainFieId = this.dialog.mainField;
          } else {
            param.mainFieText = this.dialog.mainField;
          }
        } else {
          param.mainFieText = this.dialog.mainFieldName;
        }
        let msg = this.$i18n.t('message.add');
        if (this.reportData) {
          param.id = this.reportData.id;
          msg = this.$i18n.t('message.edit');
        }
        this.dialog.loading = true;
        AddReportDesigner(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            if (res.status === 100) this.$message.warning('Report Sub Field is occupied.')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    addOption (index) {
      this.dialog.options.splice(index + 1, 0, {
        label: '',
        value: '',
      })
    },
    deleteOption (index) {
      this.dialog.options.splice(index, 1)
    },
    measureChange (e) {
      if (e == 12) {
        this.dialog.options = [{
          label: '',
          value: '',
        }]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.report-form {
  .el-select {
    width: 100%;
  }
  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-switch {
      width: 100px;
    }
  }
  .field-table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    td,
    th {
      min-width: 66px;
      line-height: 35px;
      border: 1px solid #797979;
      text-align: center;
      ::v-deep input {
        border: none;
        text-align: center;
      }
      ::v-deep :disabled {
        background: #fff;
        color: #000;
        cursor: default;
      }
    }
  }

  .switch-item {
    text-align: right;
    .el-switch {
      width: 100px;
    }
  }
}

.option-item {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  .el-input {
    margin-right: 5px;
  }
}
</style>