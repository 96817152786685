<template>
  <div>
    <el-dialog
      :visible.sync="dialog.visible"
      :close-on-click-modal="false"
      center
      width="450px"
      class="condition-dialog"
    >
      <el-form>
        <el-form-item>
          <el-select v-model="dialog.type">
            <el-option
              v-for="(item,index) in dateCondition"
              :key="index"
              :value="index"
              :label="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="dialog.type === 9">
          <el-date-picker
            v-model="dialog.dateRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            unlink-panels	
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/common'
export default {
  name: 'DateDialog',
  props: ['visible'],
  data () {
    return {
      dateCondition: ['Today', 'Yesterday', 'Last 3 Days', 'Last 5 Days', 'Last 1 Week', 'Last 30 Days', 'Last 90 Days', 'Last Year', 'All Time', 'Custom Range'],
      dialog: {
        visible: false,
        type: 8,
        dateRange: [],
      },
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    },
    'dialog.type': function (newVal) {
      let date = new Date();
      switch (newVal) {
        case 0:
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          break;
        case 1:
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          break;
        case 2:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 3:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 4);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 4:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 6);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 5:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 29);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 6:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 89);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 7:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 364);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 8:
        case 9:
          this.dialog.dateRange = [];
      }
    },
  },
  methods: {
    submit () {
      let label = this.dateCondition[this.dialog.type];
      this.$emit('submitTimeRange', this.dialog.dateRange, label)
    },
    reset () {
      this.dialog.type = 9;
      this.dialog.dateRange = [];
    },
  },
}
</script>

<style lang="scss" scoped>
.condition-dialog {
  ::v-deep .el-dialog__header {
    display: none;
  }
  .el-select {
    width: 100%;
  }
}
</style>