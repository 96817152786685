<template>
  <div class="wrap">
    <el-card>
      <page-header
        :title="$t('smtp.title1')"
        hide-back
      >
        <el-button
          :disabled="!permit.save"
          size="medium"
          type="primary"
          @click="save"
        >{{$t('operation.save')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="test"
        >{{$t('smtp.btn1')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item :label="$t('smtp.label1')">
              <el-input v-model="groupForm.user"></el-input>
            </el-form-item>
            <el-form-item :label="$t('smtp.label2')">
              <el-input v-model="groupForm.server"></el-input>
            </el-form-item>
            <el-form-item :label="$t('smtp.label3')">
              <el-select v-model="groupForm.connection">
                <el-option
                  :value="0"
                  label="None"
                ></el-option>
                <el-option
                  :value="1"
                  label="SSL"
                ></el-option>
                <el-option
                  :value="2"
                  label="TLS"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('smtp.label4')">
              <el-input
                v-model="groupForm.paw"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('smtp.label5')">
              <el-input v-model="groupForm.port"></el-input>
            </el-form-item>
            <el-form-item :label="$t('smtp.label6')">
              <el-input v-model="groupForm.timeouts">
                <span slot="suffix">minute</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  GetSMTP,
  SaveSMTP,
} from '@/api/SMTP'
export default {
  name: 'SMTP',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        save: true,
      },
      groupForm: {
        id: '',
        user: '',
        server: '',
        connection: 0,
        paw: '',
        port: '',
        timeouts: '',
      },
      rules: {

      },
    }
  },
  created () {
    this.getSMTP();
    this.dealPermission();

  },
  methods: {
    getSMTP () {
      GetSMTP().then(res => {
        Object.assign(this.groupForm, {
          id: res.id,
          user: res.userName,
          server: res.sever,
          connection: Number(res.connection),
          paw: res.password,
          port: res.port,
          timeouts: res.timeouts,
        })
      })
    },
    // 保存
    save () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          id: this.groupForm.id,
          userName: this.groupForm.user,
          password: this.groupForm.paw,
          sever: this.groupForm.server,
          port: this.groupForm.port,
          connection: this.groupForm.connection,
          timeouts: this.groupForm.timeouts,
        }
        SaveSMTP(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.save'))
          }
        })
      })
    },
    // 测试
    test () {

    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.save = permission.includes('SMTPEditSMTPSettings');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .el-select {
    width: 100%;
  }
}
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>