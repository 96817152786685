import twLocale from 'element-ui/lib/locale/lang/zh-TW'

const cn = {
  ...twLocale,
  login: {
    login: '登錄'
  },
  menu: {
    DashBoard: '概覽',
    KPIs: 'KPI',
    KpiDashboard: 'KPI概覽',
    KpiTemplate: 'KPI模板',
    CounterMode: '常用報表',
    DiningMode: '管理員報表',
    UserDefined: '自定義報表',

    SystemSettings: '系統配置',
    UserManagement: '用戶管理',
    Organizations: '組織機構',
    Roles: '角色',
    StoreGroupEditor: '網點組',
    ReportGroupEditor: '報表組',
    Stores: '網點管理',
    ContactList: '聯系人列表',
    SMTP: 'SMTP配置',
    AccountPolicies: '賬戶安全策略',

    ReportSettings: '報表配置',
    ReportTemplates: '報表模板',
    ReportGroup: '報表分類',
    ReportDesigner: '報表設計',
    RiskOfLossLevel: '風險等級',
    SystemSchedule: '系統任務',
    Schedule: '任務計劃',
    SystemLog: '系統日誌',

    BgbOasSettings: 'BGB OAS數據管理',
    DataMaintenance: '數據類型',
    ApprovalWorkflow: '審批流程',
    BgbProjectManagement: 'BGB項目管理',

    ProjectContract: '項目合同',
    Projects: '項目進度',
    StandardTask: '標準任務',
    ProjectDocuments: '項目文檔',
    SLAPolicies: 'SLA 政策',
    OperationalTime: '營業時間',

    BgbService: 'BGB服務與支持',
    SubmitRequest: '提交工單',
    Maintenance: '維護',
    ServiceConfirmationForm: '服務確認單',
    DeviceManagement: '設備管理',
    WorkOrderScheduling: '工單調度',
    EngineerManagement: '人員管理',

    BgbIms: 'BGB庫存管理',
    InventoryManagement: '庫存管理',
    PurchaseRequisition: '購物申請',
    PurchaseOrders: '采購訂單',
    InventoryStatistics: '庫存統計',
    Quotation: '報價管理',
    ProductInformation: '產品信息',

    Notifications: '通知',
    Notification: '系統通知',
    DailyDataSync: '數據同步',
    RequestForApproval: '申請與批復',

    ComplaintsEvaluation: '投訴與評價',

    AppReleaseManagement: '應用發行管理',
    AppRelease: '應用發行',

  },
  message: {
    add: '添加成功',
    edit: '編輯成功',
    del: '刪除成功',
    please: '請輸入',
    cancel: '撤銷成功',
    save: '保存成功',
    copy: '復製成功',
    update: '更新成功',
    notNull: '不能為空',
    download: '下載成功',
    export: '導出成功',
    import: '導入成功',
    upload: '上傳成功',
    receipt: '簽收成功',
  },
  operation: {
    search: '搜索',
    add: '新增',
    edit: '編輯',
    del: '刪除',
    import: '導入',
    cancel: '取消',
    submit: '提交',
    save: '保存',
    repeal: '撤銷',
    evaluate: '評價',
    complaint: '投訴',
    send: '派單',
    updateState: '狀態更新',
    staffStatus: '人員狀態',
    saveSend: '保存，並派單',
    reassign: '改派',
    auto: '自動',
    saveAdd: '保存並新增',
    refresh: '刷新',
    update: '更新',
    reset: '重置',
    copy: '復製',
    move: '移動',
    export: '導出',
    confirm: '確定',
    close: '關閉',
    filter: '篩選',
    date: {
      text1: '最近一周',
      text2: '最近一個月',
      text3: '最近三個月',
    }
  },
  home: {
    layer: {
      btn1: '隱藏菜單',
      btn2: '固定菜單',
      btn3: '折疊菜單',
    },
    menu: {
      btn1: '更改密碼',
      btn2: '我的個人資料',
      btn3: '幫助',
      btn4: '退出',
      btn5: '關閉其他標簽',
      btn6: '關閉所有標簽',
    },
    password: {
      title1: '更改密碼',
      label1: '當前密碼',
      label2: '新密碼',
      label3: '確認新密碼',
    },
    userInfo: {
      title1: '編輯個人資料',
      label1: '全名',
      label2: '郵箱1',
      label3: '郵箱2',
      label4: '電話號碼1',
      label5: '電話號碼2',
    },
    help: {
      title1: '幫助',
      content: '請您聯系系統管理員以尋求幫助。',
      contact: '請與我們聯系',
    }
  },

  userManagement: {
    col1: '用戶名',
    col2: '組織機構',
    col3: '角色',
    col4: '數據訪問組',
    col5: '網點訪問組',
    col6: '報表訪問組',
    col7: '郵箱',
    col8: '電話',
    col9: '狀態',
    col10: '全名',
  },
  newUser: {
    title1: '新增用戶',
    title2: '編輯用戶',
    title3: '查看用戶',
    subtitle1: '用戶信息',
    subtitle2: '權限',
    label1: '用戶名',
    label2: '密碼',
    label3: '全名',
    label4: '郵箱地址',
    label5: '備用郵箱地址',
    label6: '電話號碼',
    label7: '備用電話號碼',
    label8: '數據訪問組',
    label9: '角色',
    label10: '組織機構',
    label11: '網點訪問組',
    label12: '報表訪問組',
    label13: '概覽樣式模板',
    label14: ' KPI概覽樣式模板',
    label15: '需此用戶審批（編輯系統默認設置）',
    label16: '需此用戶審批（編輯樣式模板）',
    label17: '帳戶狀態',
    message1: '密碼包含英文大寫字符（A-Z）、英文小寫字母（a-z）、Base 10 Digits（0-9）、特殊字符、Unicode字符中至少三個的字符',
    message2: '密碼不能少於',
    message3: '個字符',
  },
  storeGroupEditor: {
    title1: '網點訪問組編輯',
    subtitle1: '選擇分類',
    subtitle2: '選擇網點',
    btn1: '切換',
    col1: '網點訪問組名稱',
    col2: '數據訪問組',
    col3: '網點ID',
    col4: '網點名稱',
    dialog: {
      title1: '新增網點訪問組',
      title2: '編輯網點訪問組',
      label1: '網點訪問組名稱',
      label2: '數據訪問組',
    },
  },
  reportGroupEditor: {
    title1: '報表訪問組編輯',
    subtitle1: '選擇分類',
    subtitle2: '選擇報表',
    col1: '報表訪問組名稱',
    col2: '數據訪問組',
    col3: '報表模板',
    col4: '描述',
    col5: '數據訪問組',
    dialog: {
      title1: '新增報表訪問組',
      title2: '編輯報表訪問組',
      label1: '報表訪問組名稱',
      label2: '數據訪問組',
    },
  },
  organizations: {
    tab1: '組織機構',
    tab2: '數據訪問組',
    subtitle1: '組織架構',
    subtitle2: '配置參數',
    col1: '組織機構名稱',
    col2: '描述',
    col3: '數據訪問組',
    col4: '創建人',
    col5: '創建日期',
    col6: '修改日期',
    label1: '主題顏色',
    label2: '單據模板',
    label3: '系統概覽模板',
    label4: ' KPI模板',
    label5: ' KPI概覽模板',
    dialog: {
      title1: '新建組織機構',
      title2: '編輯組織機構',
      label1: '組織機構名稱',
      label2: '描述',
      label3: '數據訪問組（數據權限）',
    },
    btn1: '共享',
    btn2: '添加子節點',
    nodeDialog: {
      title1: '添加子節點',
      title2: '編輯節點',
      label1: '節點類型',
      label2: '子節點名稱',
      label3: '節點名稱',
      label4: '描述',
    },
    shareDialog: {
      title1: '共享組織架構',
      col1: '組織機構名稱',
    },
  },
  dataAccessGroup: {
    label1: '數據訪問組名稱',
    label2: '描述',
    label3: '數據訪問組',
    label4: '創建人',
    label5: '創建日期',
    label6: '修改日期',
  },
  newDataAccessGroup: {
    title1: '新增數據訪問組',
    title2: '編輯數據訪問組',
    title3: '查看數據訪問組',
    label1: '數據訪問組名稱',
    label2: '數據訪問組（數據權限）',
    label3: '描述',
    label4: '組織機構列表',
    label5: '已選擇',
    col1: '序號',
    col2: '組織機構',
    col3: '描述',
  },
  roles: {
    col1: '角色名稱',
    col2: '描述',
    col3: '數據訪問組',
    col4: '創建人',
    col5: '創建日期',
    col6: '修改日期',
    subtitle1: '系統功能',
    subtitle2: '操作權限',
    btn1: '添加節點',
    btn2: '添加子節點',
    roleDialog: {
      title1: '新增角色',
      title2: '編輯角色',
      label1: '角色名稱',
      label2: '描述',
      label3: '數據訪問組（數據權限）',
    },
    itemDialog: {
      title1: '新增導航欄',
      title2: '編輯導航欄',
      label1: '導航欄名稱',
      label2: '導航欄排序',
    },
    permissionsDialog: {
      title1: '新增權限',
      title2: '編輯權限',
      label1: '權限名稱',
      label2: '權限排序',
    }
  },
  stores: {
    btn1: '添加主節點',
    btn2: '添加子節點',
    btn3: '全選',
    label1: '選定節點',
    label2: '描述',
    col1: '網點ID',
    col2: '網點名稱',
    col3: '類型',
    col4: '地址',
    col5: '狀態',
    col6: '上一節點',
    nodeDialog: {
      title1: '添加新節點',
      title2: '添加子節點',
      title3: '編輯節點',
      label1: '節點名稱',
      label2: '子節點名稱',
      label3: '描述',
      label4: '類型',
      label5: '數據訪問組（數據權限）',
    },
    storeDialog: {
      title1: '新建網點',
      title2: '編輯網點',
      label1: '網點ID',
      label2: '網點名稱',
      label3: '類型',
      label4: '地址',
      label5: '狀態',
      option1: '正常',
      option2: '未知',
      option3: '已結束',
      option4: '未生效',
    },
    grouping: {
      title1: '樹節點顯示',
      label1: '分組',
    },
    storeTree: {
      title1: '移動網點',
      label1: '選擇目標節點',
    }
  },
  contactList: {
    col1: '姓名',
    col2: '郵箱',
    col3: '備用郵箱',
    col4: '組織機構',
    col5: '網點訪問組',
    col6: '報表訪問組',
    col7: '系統用戶',
    col8: '職位',
    col9: '員工 ID',
    col10: '電話號碼',
    dialog: {
      title1: '新建聯系人',
      title2: '編輯聯系人',
      label1: '姓名',
      label2: '組織機構',
      label3: '數據訪問組',
      label4: '郵箱',
      label5: '備用郵箱',
      label6: '電話號碼',
      label7: '備用電話號碼',
      label8: '網點訪問組',
      label9: '報表訪問組',
      label10: '狀態',
      label11: '創建系統賬號',
      label12: '網點負責人',
      label13: '員工 ID',
      label14: '職位',
      label15: '網站',
      col1: '站點 ID',
      col2: '站點名稱',
    }
  },
  smtp: {
    title1: 'SMTP配置',
    btn1: '測試',
    label1: '用戶名',
    label2: 'SMTP服務器',
    label3: '安全連接',
    label4: '密碼',
    label5: '端口',
    label6: '服務器超時',
  },
  accountPolicies: {
    title1: '賬戶安全策略',
    label1: '嘗試登錄限製',
    label2: '賬戶鎖定持續時間',
    label3: '最小密碼長度',
    label4: '密碼有效期限（天）',
    label5: '密碼必須滿足復雜性要求',
    subtitle1: '密碼組合要求',
    subtitle2: '已被阻擋的IP',
    p1: '1.密碼長度至少為六個字符',
    p2: '2.密碼包含以下五個類別中至少三個的字符',
    p3: 'a.英文大寫字符（A-Z）',
    p4: 'b.英文小寫字母（a-z）',
    p5: 'c.Base 10 Digits（0-9）',
    p6: 'd.特殊非字母數字字符（例如：！，@，＃，$，％）',
    p7: 'e.Unicode字符',
    col1: 'IP地址',
    col2: '阻擋日期',
  },
  systemLog: {
    btn1: '打印',
    col1: '日期',
    col2: '類型',
    col3: '類別',
    col4: '描述',
    col5: '用戶',
  },
  schedule: {
    col1: '任務名稱',
    col2: '重復發生',
    col3: '數據訪問組',
    col4: '創作者',
    col5: '任務狀態',
    col6: '操作',
    col7: '報告模板',
  },
  newSchedule: {
    title1: '新增任務',
    title2: '編輯任務',
    title3: '查看任務',
    label1: '任務名稱',
    label2: '描述',
    label3: '數據訪問組',
    label4: '（數據權限）',
    label5: '時間',
    label6: '重復發生',
    label7: '特定事件',
    label8: '操作',
    label9: '報告模板',
    label10: '任務狀態',
    tab1: '觸發器',
    tab2: '操作',
    col1: '全名',
    col2: '網點組',
    col3: '電子郵件1',
    col4: '電話1',
    col5: '電子郵件2',
    col6: '電話2',
  },
  contactDialog: {
    title1: '添加聯系人',
    col1: '全名',
    col2: '網站組',
    col3: '電子郵件1',
    col4: '電話1',
    col5: '電子郵件2',
    col6: '電話2',
  },




  projectContractManagement: {
    tab1: '合同',
    tab2: 'SLA&維護',
    contract: {
      col1: '項目名稱',
      col2: '合同報價編號',
      col3: '項目類型',
      col4: '合同狀態',
      col5: '客戶名稱',
      col6: '簽署日期',
      col7: '開工',
      col8: '竣工',
      col9: '合同開始',
      col10: '合同結束',
      col11: '維保開始',
      col12: '維保結束',
      tag1: '未生效',
      tag2: '正常',
      tag3: '已結束',
      tag4: '未知',
    },
    sla: {
      col1: '網點名稱',
      col2: '網點類型',
      col3: 'SLA 狀態',
      col4: '維護類型',
      col5: 'SLA 開始',
      col6: 'SLA 結束',
      col7: '地址',
      col8: '客戶',
      col9: '項目名稱',
      col10: 'SLA 政策',
      col11: 'SLA 類型',
    }
  },
  newProjectContract: {
    title: '新增項目',
    title2: '編輯項目',
    title3: '添加網點',
    title4: '編輯網點',
    title5: '查看項目',
    subtitle1: '基本信息',
    subtitle2: '項目信息',
    subtitle3: '期限',
    subtitle4: '項目包含網點',
    btn1: '續簽',
    label1: '項目名稱',
    label2: '項目類型',
    label3: '簽署日期',
    label4: '合同報價編號',
    label5: '客戶名稱',
    label6: '數據訪問組',
    label7: '開工/竣工時間',
    label8: '合同起止日期',
    label9: '維保起止日期',
    label10: '備註',
    label11: 'SLA 政策',
    label12: 'SLA 類型',
    col1: '網點名稱',
    col2: '維保類型',
    col3: '維保開始',
    col4: '維保終止',
    col5: '聯系人',
    col6: '電話',
    col7: '地址',
    col8: '備註',
    col9: '網點類型',
    col10: '維保狀態',
    tag1: '未生效',
    tag2: '正常',
    tag3: '已結束',
    tag4: '未知',
    slaDialog: {
      title1: 'SLA 政策',
      col1: 'SLA 政策',
      col2: 'SLA 類型',
      col3: '數據訪問組',
    },
  },
  dataMaintenance: {
    name: '名稱',
    btn1: '全選',
    title1: '數據類型',
    title2: '設備類型',
    contactDialog: {
      title1: '新增聯系方式',
      title2: '編輯聯系方式',
      label1: '供應商',
      label2: '物流公司',
      label3: '收貨地址',
      label4: '聯系人',
      label5: '聯系電話',
      label6: '郵箱',
      label7: '傳真',
      label8: '職位',
    },
    deviceTypeDialog: {
      title: '新增設備類型',
      col1: '設備類型',
    },
    deviceType: {
      deviceType: '設備類型',
      title1: '新增設備',
      title2: '編輯設備',
      title3: '合並',
      title4: '代替',
      title5: '更新',
      col1: '設備名稱',
      col2: '設備品牌',
      col3: '規格型號',
      col4: '供應商',
      col5: '物品 ID',
      col6: '項目類型',
      col7: '單位',
      col8: '狀態',
      btn1: '導入項目合同',
      btn2: '導入項目客戶名稱',
      btn3: '導入出入庫信息',
      btn4: '替換',
      btn5: '合並',
      btn6: '更多...',
      label1: '所有其他項目將合並到所選項目中。',
    },
    equipmentBrand: {
      col1: '設備類型'
    },
    supplier: {
      abbreviation: '簡稱',
      tab1: '聯系方式',
      tab2: '供應設備',
      col1: '聯系人',
      col2: '聯系電話',
      col3: '郵箱',
      col4: '傳真',
      col5: '職位',
      col6: '設備類型'
    },
    systemType: {
      tab1: '設備類型',
      tab2: '服務事項分類',
      tab3: '故障現象',
      col1: '設備類型',
      col2: '服務類型',
      col3: '服務事項',
      col4: '熱度',
      col5: '故障現象',
      col6: '熱度',
      col7: '系統類型',
      title1: '新增服務事項',
      title2: '編輯服務事項',
      title3: '新增故障現象',
      title4: '編輯故障現象',
      systemType: '系統類型',
    },
    faultType: {
      col1: '問題 ID',
      col2: '問題 MainType',
      col3: '問題子類型',
      col4: '項目類型',
      col5: '問題 MainType',
      col6: '問題子類型',
      col7: '問題類型',
      col8: '關鍵字',
      col9: '排名',
      tab1: '按問題 MainType 分組',
      tab2: '按項目類型分組',
      btn1: '替換',
      dialog1: {
        title1: '添加問題類型',
        title2: '編輯問題類型',
        label1: '問題主要類型',
        label2: '問題子類型',
        label3: '相關項目類型',
        btn1: '查看全部',
        col1: '項目類型',
        col2: '問題類型',
        col3: '關鍵字',
        col4: '排名',
      },
      dialog2: {
        title1: '添加問題類型',
        title2: '編輯問題類型',
        label1: '物品類型',
        label2: '問題子類型',
        btn1: '查看全部',
        col1: '問題主要類型',
        col2: '問題子類型',
        col3: '問題類型',
        col4: '關鍵字',
        col5: '排名',
      },
    },
    logisticsCompany: {
      tab: '聯系方式',
      col1: '聯系人',
      col2: '聯系電話',
      col3: '郵箱',
      col4: '傳真',
      col5: '職位',
    },
    receiptAddress: {
      tab: '聯系方式',
      col1: '聯系人',
      col2: '聯系電話',
      col3: '郵箱',
      col4: '傳真',
      col5: '職位',
    },
    serviceConfirmationTemplate: {
      label1: '模板文件',
      label2: '應用此模板的客戶',
    },
    checkType: {
      col1: 'ID',
      col2: '檢查項目',
      col3: '檢查類型',
      col4: '狀態',
      col5: '選項',
    },
  },
  submitRequest: {
    col1: '提交時間',
    col2: '服務計時',
    col3: '城市',
    col4: '網點名稱',
    col5: '描述',
    col6: '已派單',
    col7: '工程師',
    col8: '服務類型',
    col9: '完成情況',
    status: '完成情況',
    complaint: {
      title: '投訴',
      content: '感謝您指出我們工作上的不足，由此給您帶來的不便，我們深表歉意，我們將及時跟進，並將結果反饋給您。',
      message1: '請寫下寶貴建議',
    }
  },
  newSubmitRequest: {
    title: '新建工單',
    title2: '編輯工單',
    title3: '查看工單',
    tab1: '基本信息',
    tab2: '進度',
    tab3: '服務人員',
    tab4: '服務單',
    tab5: 'SLA 跟蹤',
    btn1: '已確認',
    subtitle1: '基本信息',
    subtitle2: '故障設備',
    subtitle3: '服務人員',
    subtitle4: '附件',
    label1: '網點名稱',
    label2: '要求抵達時間',
    label3: '來源',
    label4: '客戶聯絡人',
    label5: '聯絡人電話',
    label6: '服務類型',
    label7: '描述',
    label8: '網點地址',
    label9: '客戶系統編號',
    label10: '合同報價編號',
    label11: '采購訂單號',
    label12: '負責人',
    col1: '設備名稱',
    col2: '故障描述',
    col3: '派單時間',
    col4: '服務人員',
    col5: '當前位置',
    col6: '狀態',
    col7: '未完成單數',
    col8: '要求抵達時間',
    col9: '工單狀態',
    col10: '服務單編號',
    col11: '故障現象',
    message1: '請選擇已派單的人員',
    tag1: '未完成',
    tag2: '已完成',
    tag3: '已派單',
    tag4: '改派',
    qrcode: {
      btn: '二維碼',
      title: '故障設備',
      label1: '二維碼',
      label2: '設備名稱',
      label3: '故障描述',
    },
    fault: {
      title1: '新增故障',
      title2: '編輯故障',
      title3: '請選擇故障現象',
      title4: '自定義故障現象',
      label1: '設備名稱',
      label2: '故障現象',
      label3: '詳細描述',
      btn1: '無匹配，新增',
      message1: '已經選過，不能重復選擇故障類型',
      message2: '請輸入故障類型',
    },
    progressLog: {
      state0: '已確認簽名',
      state1: '取消派工',
      state2: '已派工',
      state3: '改派',
      state4: '已派單',
      state5: '提交申請',
      state6: '未完成',
      state7: '已完成',
      state8: '進行中',
      state9: '待處理',
      state10: '待確認',
      state11: '已撤銷',
      state12: '已關閉',
      state13: '已歸類存檔',
    },
    number: {
      title1: '編輯客戶系統編號',
      title2: '編輯合同報價編號',
      title3: '編輯采購訂單號',
      col1: '編號',
      col2: '客戶系統編號',
      col3: '合同報價編號',
      col4: '采購訂單號',
    },
    faultItem: {
      title1: '請選擇常見問題',
      tab1: '選中',
      tab2: '全部',
      btn1: '新問題',
      label1: '新問題',
    },
  },
  servicesScheduling: {
    add: '工程師提交',
    col1: '提交時間',
    col2: '服務計時',
    col3: '城市',
    col4: '網點名稱',
    col5: '來源',
    col6: '已派單',
    col7: '服務類型',
    col8: '完成情況',
    col9: '客戶名稱',
    col10: '優先事項',
    status: '完成情況',
    cancel: {
      title: '取消工單原因',
      cause: '原因'
    }
  },
  newServicesScheduling: {
    title: '新增工單',
    title2: '編輯工單',
    title3: '查看工單',
    subtitle1: '故障設備',
    subtitle2: '現場圖片',
    customerName: '客戶名稱',
    contacts: '客戶聯絡人',
    telephone: '聯絡人電話',
    serviceType: '服務類型',
    requiredArrivalTime: '預定抵達時間',
    description: '描述',
    col1: '設備名稱',
    col2: '故障描述',
    AddEquipment: {
      title: '故障設備',
      code: '二維碼',
      deviceName: '設備名稱',
      description: '故障描述',
    },
  },
  sendOrder: {
    title: '派單',
    subtitle1: '基本信息',
    subtitle2: '服務人員',
    subtitle3: '通知客戶',
    label1: '網點名稱',
    label2: '預定抵達時間',
    col1: '客戶名稱',
    col2: '設備名稱',
    col3: '預定抵達時間',
    col4: '描述',
    col5: '派單時間',
    col6: '姓名',
    col7: '當前位置',
    col8: '人員狀態',
    col9: '未完成派單數',
    col10: '要求抵達時間',
    col11: '客戶聯系人',
    col12: '電話',
    col13: '郵箱',
    col14: '郵件通知',
    col15: '短信通知',
    message1: '請選擇已派單的人員',
    tag1: '已派單',
    tag2: '改派',
    staff: {
      title: '選擇服務人員',
      col1: '姓名',
      col2: '聯系電話',
      col3: '未完成派單數',
      col4: '處理中',
      col5: '當前位置',
      col6: '本月已派單數',
      col7: '出勤',
      col8: '請假時間',
      col9: '組織機構',
      yes: '是',
      no: '否',
    },
    dialog: {
      title1: '編輯要求抵達時間',
      title2: '改派原因',
      name1: '人員',
      name2: '改派人員',
      reason: '改派原因',
      date: '要求抵達時間',
    },
  },
  orderStateUpdate: {
    title: '工單狀態更新',
    subtitle1: '基本信息',
    subtitle2: '服務人員',
    label1: '網點名稱',
    label2: '預定抵達時間',
    col1: '客戶名稱',
    col2: '設備名稱',
    col3: '預定抵達時間',
    col4: '描述',
    col5: '派單時間',
    col6: '姓名',
    col7: '安裝位置',
    col8: '今天已派單數',
    col9: '要求抵達時間',
    col10: '時間',
    col11: '服務確認單編號',
    col12: '狀態',
    col13: '下次處理時間',
    col14: '未完成原因',
    col15: '操作',
    update: '更新',
    newFormBtn: '服務確認單編輯',
    newForm: {
      title: '狀態更新',
      number: '服務確認單編號',
      status: '狀態',
      date: '下次處理時間',
      Reasons: '未完成原因',
    },
  },
  servicesConfirmation: {
    downloadPDF: '下載',
    downloadWord: '附件下載',
    col1: "服務確認單編號",
    col2: "城市",
    col3: "網點名稱",
    col4: "描述",
    col5: "工程師",
    col6: "處理時間",
    col7: "來源",
    col8: "服務類型",
    col9: '創建時間',
    col10: '客戶名稱',
  },
  newServiceConfirmationForm: {
    title1: '編輯服務確認單',
    title2: '查看服務確認單',
    subtitle1: '確認評價',
    subtitle2: '現場維修',
    subtitle3: '更換設備',
    subtitle4: '拆除搬離',
    subtitle5: '其他事項',
    subtitle6: '基本信息',
    subtitle7: '設備安裝',
    subtitle8: '維護',
    btn1: '維修',
    btn2: '更換',
    btn3: '拆除',
    btn4: '安裝',
    btn5: '其他',
    btn6: '維護',
    btn7: '評分',

    label1: '網點名稱',
    label2: '網點地址',
    label3: '服務類型',
    label4: '服務確認單編號',
    label5: '派單時間',
    label6: '處理時間',
    label7: '客戶系統編號',
    label8: '合同報價編號',
    label9: '采購訂單號',
    label10: '上傳附件',
    label11: '描述',
    label12: '處理方法',
    label13: '描述',
    label14: '處理方法',
    label15: '完成狀態',
    label16: '下次處理時間',
    label17: '服務人員',
    label18: '負責人',
    label19: '未完成原因',
    label20: '客戶簽署人',
    label21: '工作職位',
    label22: '員工號',
    label23: '簽名',
    label24: '服務評價',
    label25: '評論',

    issueKeyword: {
      title1: '選擇問題類型相關的故障項',
      tab1: '選中',
      tab2: '全部',
      tab3: '物品',
      tab4: '問題',
      btn1: '新問題類型',
      label1: '新問題類型',
      label2: '服務確認',
      label3: '技術觀察',
      label4: '詳細流程',
    },
    serviceContent: {
      title1: '選擇匹配的服務內容',
      tab1: '選中',
      tab2: '全部',
      btn1: '新服務內容',
      label1: '新服務內容',
      label2: '服務確認',
      label3: '技術觀察',
      label4: '詳細流程',
    },
    tab2: {
      col1: '設備名稱',
      col2: '位置',
      col3: '技術觀察',
      col4: '詳細流程',
      col5: '問題類型',
    },
    dialog2: {
      label1: '問題類型',
      label2: '故障描述',
      label3: '處理方法',
      label4: '替換',
      label5: '移除',
      label6: '設備名稱',
      label7: '安裝位置',
      label8: '規格型號',
      label9: '序列號',
      label10: '二維碼',
      label11: '安裝編號',
      label12: '歸屬',
      label13: '系統類型',
      label14: '物品類型',
      label15: '問題主要類型',
      btn1: '下一個',
      title1: '新增現場維修記錄',
      title2: '編輯現場維修記錄',
      option1: '無',
      option2: 'ATM',
      option3: '支行',
    },
    tab3: {
      col1: '設備名稱',
      col2: '安裝位置',
      col3: '技術觀察',
      col4: '詳細流程',
      col5: '問題類型',
      col6: '舊 SN',
      col7: '新 SN',
      col8: '設備提供方',
    },
    dialog3: {
      label1: '更換原因',
      label2: '問題類型',
      label3: '技術觀察',
      label4: '詳細流程',
      label5: '舊物品名稱',
      label6: '位置',
      label7: '模型',
      label8: '序列號',
      label9: '二維碼',
      label10: '顯示名稱（標簽）',
      label11: '行蹤',
      label12: '所有權',
      label13: '新項目名稱',
      label14: '位置',
      label15: '模型',
      label16: '序列號',
      label17: '二維碼',
      label18: '顯示名稱（標簽）',
      label19: '提供者',
      label20: '參考號',
      label21: '合同和報價單號',
      label22: '采購訂單號',
      title1: '替換',
      option1: '無',
      option2: 'ATM',
      option3: '支行',
    },
    tab4: {
      col1: '物品名稱',
      col2: '位置',
      col3: '技術觀察',
      col4: '詳細流程',
      col5: '序列號',
      col6: '批準人',
      col7: '現場主管',
      col8: '行蹤',
    },
    dialog4: {
      label1: '移除原因',
      label2: '問題類型',
      label3: '技術觀察',
      label4: '詳細流程',
      label5: '物品名稱',
      label6: '位置',
      label7: '模型',
      label8: '序列號',
      label9: '二維碼',
      label10: '顯示名稱（標簽）',
      label11: '所有權',
      label12: '行蹤',
      label13: '批準人',
      label14: '現場主管',
      title1: '移除',
      option1: '無',
      option2: 'ATM',
      option3: '分支',
    },
    tab5: {
      col1: '物品名稱',
      col2: '位置',
      col3: '技術觀察',
      col4: '詳細流程',
      col5: '服務內容',
    },
    dialog5: {
      label1: '服務內容',
      label2: '技術觀察',
      label3: '詳細流程',
      label4: '物品名稱',
      label5: '位置',
      label6: '模型',
      label7: '序列號',
      label8: '二維碼',
      label9: '顯示名稱（標簽）',
      label10: '所有權',
      label11: '服務類別',
      label12: '系統類別',
      title1: '新服務內容',
      title2: '編輯服務內容',
      option1: '無',
      option2: 'ATM',
      option3: '分支',
    },
    tab6: {
      col1: '物品名稱',
      col2: '位置',
      col3: '技術觀察',
      col4: '詳細流程',
      col5: '安裝類型',
    },
    dialog6: {
      label1: '安裝類型',
      label2: '技術觀察',
      label3: '詳細流程',
      label4: '物品名稱',
      label5: '位置',
      label6: '模型',
      label7: '序列號',
      label8: '二維碼',
      label9: '顯示名稱（標簽）',
      label10: '所有權',
      label11: '提供者',
      label12: '參考號',
      label13: '合同和報價單號',
      label14: '采購訂單號',
      title1: '安裝',
      option1: '無',
      option2: 'ATM',
      option3: '分支',
    },
    tab7: {
      col1: '日期時間',
      col2: '文件名',
      col3: '工程師',
      col4: '服務確認',
      btn1: '打印',
      btn2: '導出',
    },
  },

  equipmentDialog: {
    title: '選擇設備',
    col1: '二維碼',
    col2: '分支機構',
    col3: '設備名稱',
    col4: '規格型號',
    col5: '序列號',
    col6: '安裝位置',
    col7: '安裝編號',
  },
  equipmentDialog2: {
    title: '選擇設備',
    col1: '設備名稱',
    col2: '規格型號',
    col3: '序列號',
    col4: '安裝位置',
    col5: '二維碼',
  },
  equipmentDialog3: {
    title1: '選擇設備',
    title2: '新增設備',
    btn1: '新設備',
    col1: '設備名稱',
    col2: '設備品牌',
    col3: '設備規格',
    col4: '設備類型',
    label1: '設備名稱',
    label2: '設備類型',
    label3: '設備品牌',
    label4: '規格型號',
  },
  equipmentDialog4: {
    title1: '選擇設備',
    title2: '新增設備',
    btn1: '新設備',
    col1: '設備名稱',
    col2: '設備品牌',
    col3: '規格型號',
    col4: '設備類型',
    col5: '申請數量',
    label1: '設備名稱',
    label2: '設備類型',
    label3: '設備品牌',
    label4: '規格型號',
  },
  equipmentDialog5: {
    title: '選擇出庫設備',
    col1: '設備名稱',
    col2: '設備品牌',
    col3: '規格型號',
    col4: '數量',
    col5: '購物申請單號',
  },
  equipmentDialog6: {
    title1: '選擇設備',
    label1: '購物申請單號',
    label2: '申購人',
    col1: '設備名稱',
    col2: '設備品牌',
    col3: '規格型號',
    col4: '設備類型',
    col5: '訂貨數量',
  },
  equipmentDialog7: {
    title1: '選擇設備',
    col1: '設備名稱',
    col2: '設備品牌',
    col3: '規格型號',
    col4: '設備類型',
    col5: '序列號',
  },
  deviceManagement: {
    tab1: '設備列表',
    btn1: '設備二維碼',
    btn2: '導出PDF',
    btn3: '導出PDF二維碼',
    col1: '設備名稱',
    col2: '狀態',
    col3: '規格型號',
    col4: '安裝位置',
    col5: '保修到期日',
    col6: '二維碼',
    col7: '序列號',
    col8: '數據源',
  },
  newDevice: {
    title1: '新增設備',
    title2: '編輯設備',
    title3: '查看設備',
    tab1: '基本信息',
    tab2: '參數',
    tab3: '項目記錄',
    tab4: '購買記錄',
    tab5: '合同&保修',
    tab6: '維護記錄',
    label1: '設備名稱',
    label2: '運行狀態',
    label3: '保修到期日',
    label4: '客戶名稱',
    label5: '網點名稱',
    label6: '設備品牌',
    label7: '設備類型',
    label8: '序列號',
    label9: '規格型號',
    label10: '安裝位置',
    label11: '設備標簽名稱',
    label12: '樓層',
    label13: '安裝編號',
    label14: '設備IP',
    label15: '安裝日期',
    label16: '驗收日期',
    label17: '已安裝註意',
    label18: '申購人',
    label19: '入庫日期',
    label20: '申請出庫人',
    label21: '出庫日期',
    label22: '生產日期',
    label23: '廠家質保到期',
    label24: '項目名稱',
    label25: '項目類型',
    label26: '合同報價編號',
    label27: '維保起止日期',
    label28: '合同狀態',
    label29: '設備質保方式',
    label30: '二維碼',
    repair: {
      title1: '修復',
      col1: '日期時間',
      col2: '工單狀態',
      col3: '服務類別',
      col4: '問題子類型',
      col5: '問題描述',
      col6: '詳細過程',
      col7: '工程師',
      col8: '服務確認表',
    },
    replace: {
      title1: '替換',
      col1: '日期時間',
      col2: '原因',
      col3: '問題',
      col4: '舊二維碼',
      col5: '舊序列號',
      col6: '新二維碼',
      col7: '新序列號',
      col8: '位置',
      col9: '提供者',
      col10: '評論',
      col11: '服務確認表',
    },
    remove: {
      title1: '刪除',
      col1: '日期時間',
      col2: '原因',
      col3: '問題',
      col4: '二維碼',
      col5: '序列號',
      col6: '位置',
      col7: '批準人',
      col8: '現場主管',
      col9: '去向',
      col10: '評論',
      col11: '服務確認表',
    },
    installation: {
      title1: '安裝',
      col1: '日期時間',
      col2: '安裝類型',
      col3: '評論',
      col4: '服務確認表',
    },
    maintain: {
      title1: '維護',
      col1: '日期時間',
      col2: '狀態',
      col3: '評論',
      col4: '服務確認表',
    },
    otherServices: {
      title1: '其他服務',
      col1: '日期時間',
      col2: '服務內容',
      col3: '服務確認表',
    },
    contractWarranty: {
      col1: '簽名日期',
      col2: '項目名稱',
      col3: '項目類別',
      col4: '合同和報價單號',
      col5: '合同期限',
      col6: '合同狀態',
      col7: '保修類型',
      tag1: '未生效',
      tag2: '正常',
      tag3: '已結束',
      tag4: '未知',
    },
    contractDialog: {
      title1: '選擇項目',
      col1: '簽署日期',
      col2: '項目名稱',
      col3: '合同報價編號',
      col4: '項目類型',
    },
  },
  projectDialog: {
    title: '選擇項目',
    col1: '合同報價編號',
    col2: '項目名稱',
  },
  deviceCode: {
    title: '二維碼分配',
    print: '打印',
    indexPrint: '索引打印',
    printInAdvance: '二維碼生成',
    batch: '批量分配',
    col1: '設備名稱',
    col2: '序列號',
    col3: '二維碼',
    btn1: '單獨分配',
    message1: '請移除未分配二維碼的設備，然後在打印。',
    advanceCode: {
      title1: '預先打印二維碼',
      content: '選擇打印個數',
      message1: '請輸入正整數',
    },
    batchSetQR: {
      title1: '提示',
      content: '設備已分配二維碼，請確定是否重新分配，重新分配將導致網點設備二維碼信息無法檢索，需將次新二維碼粘貼到設備上。',
      btn1: '確定，重新分配',
    },
  },
  engineerManagement: {
    col1: '姓名',
    col2: '員工號',
    col3: '工作電話',
    col4: '電子郵箱',
    col5: '狀態',
    col6: '派單次數',
    col7: '投訴次數',
    col8: '出勤',
    col9: '請假時間',
    col10: '系統用戶',
    col11: '組織機構',
    filter: {
      title1: '篩選',
      label1: '僅顯示在職人員',
    },
    import: {
      title1: '從釘釘導入',
      col1: '名稱',
      col2: '員工編號',
      col3: '部門',
      col4: '位置',
      col5: '工作電話',
      col6: '電子郵件',
      label1: '導入和創建用戶帳戶',
    }
  },
  newEngineer: {
    title1: '新增工程師',
    title2: '編輯工程師',
    title3: '查看工程師',
    label1: '姓名',
    label2: '員工編號',
    label3: '身份證',
    label4: '數據訪問組',
    label5: '職位',
    label6: '工作電話',
    label7: '家庭電話',
    label8: '電子郵箱',
    label9: '員工狀態',
    label10: '出勤情況',
    label11: '組織機構',
    label12: '部門',
    label13: '網點訪問組',
    label14: '報表訪問組',
    label15: '請假類型',
    label16: '創建系統賬號',
    label17: '請假時間',
    label18: '外勤',
    label19: '系統用戶',
    option1: '缺席',
    option2: '出席',
    option3: '請假',
  },
  inventoryManagement: {
    tab1: '庫存',
    tab2: '入庫',
    tab3: '出庫',
    inventory: {
      btn1: '二維碼',
      btn2: '導出EXCEL',
      btn3: '備貨',
      btn4: '入庫',
      btn5: '出庫',
      btn6: '按項目出庫',
      col1: '設備類型',
      col2: '設備名稱',
      col3: '規格型號',
      col4: '設備品牌',
      col5: '單位',
      col6: '庫存數量',
      col7: '核對狀態',
      option1: '所有',
      option2: '待核對',
      option3: '已核對',
    },
    inWarehouse: {
      btn1: '設備二維碼',
      btn2: '導出EXCEL',
      btn3: '入庫',
      btn4: '撤銷入庫',
      btn5: '出庫',
      btn6: '按項目出庫',
      btn7: '核對',
      col1: '入庫日期',
      col2: '入庫類型',
      col3: '供應商',
      col4: '設備名稱',
      col5: '設備品牌',
      col6: '規格型號',
      col7: '設備類型',
      col8: '序列號',
      col9: '單位',
      col10: '數量',
      col11: '單價',
      col12: '購物申請單號',
      col13: '訂單編號',
      col14: '訂單總額',
      col15: '申購人',
      col16: '訂購人',
      col17: '項目名稱',
      col18: '合同報價編號',
      col19: '設備新舊',
      col20: '借用廠家設備',
      col21: '入庫備註',
      col22: '核對狀態',
      col23: '核對人',
      col24: '核對時間',
      new: '新',
      old: '舊',
      yes: '是',
      no: '否',
    },
    outWarehouse: {
      btn1: '設備二維碼',
      btn2: '導出EXCEL',
      btn3: '撤銷出庫',
      btn4: '借用歸還',
      col1: '出庫日期',
      col2: '設備名稱',
      col3: '設備品牌',
      col4: '規格型號',
      col5: '設備類型',
      col6: '序列號',
      col7: '單位',
      col8: '數量',
      col9: '項目所在地',
      col10: '項目類型',
      col11: '收貨人',
      col12: '項目名稱',
      col13: '合同報價編號',
      col14: '出庫備註',
      col15: '借用出庫',
      col16: '約定歸還日期',
      col17: '歸還日期',
      col18: '快遞單號',
      col19: '物流公司',
      col20: '快遞費用',
      yes: '是',
      no: '否',
    },
  },
  inWarehouseDetails: {
    title1: '新增入庫',
    title2: '入庫日期',
    title3: '編輯入庫',
    subtitle1: '項目信息',
    subtitle2: '訂單信息',
    subtitle3: '設備信息',
    subtitle4: '申購信息',
    subtitle5: '出庫記錄',
    btn1: '保存退出',
    btn2: '保存並新增',
    btn3: '保存並出庫',
    yes: '是',
    no: '否',
    new: '新',
    old: '舊',
    label1: '入庫地址',
    label2: '入庫方式',
    label3: '項目名稱',
    label4: '合同報價編號',
    label5: '申購人',
    label6: '購物申請單號',
    label7: '訂單編號',
    label8: '訂單總額',
    label9: '訂購人',
    label10: '付款日期',
    label11: '設備名稱',
    label12: '規格型號',
    label13: '設備品牌',
    label14: '設備類型',
    label15: '數量',
    label16: '單價',
    label17: '是否有序列號',
    tip17: '(指不含序列號設備，例如線材，管材，螺絲，膠布)',
    label18: '序列號',
    placeholder18: '(多個序列號請用;分開)',
    label19: '供應商',
    label20: '入庫備註',
    label21: '倉庫位置',
    label22: '借用廠家設備',
    label23: '約定歸還日期',
    label24: '入庫後鎖定',
    message1: '序列號與設備數量有誤差',
    message2: '序列號已出庫，不可更改',
    message3: '購物申請單與訂單無關聯，不能入庫',
  },
  orderDialog: {
    title: '選擇訂單',
    col1: '訂單編號',
    col2: '付款日期',
    col3: '訂單總額',
    col4: '訂購人',
    col5: '創建時間',
    col6: '項目名稱',
  },
  outWarehouseDetails: {
    title1: '出庫',
    title2: '按項目出庫',
    title3: '出庫日期',
    title4: '提示',
    subtitle1: '搜索項目',
    subtitle2: '出庫項目信息',
    subtitle3: '出庫信息',
    btn1: '保存退出',
    btn2: '按項目出庫',
    btn3: '二維碼',
    yes: '是',
    no: '否',
    col1: '購物申請單號',
    col2: '設備名稱',
    col3: '設備品牌',
    col4: '規格型號',
    col5: '設備新舊',
    col6: '申請數量',
    col7: '項目名稱',
    col8: '庫存數量',
    col9: '已出庫數',
    col10: '鎖定庫存數',
    col11: '出庫數量',
    label1: '項目名稱',
    label2: '合同報價編號',
    label3: '項目名稱',
    label4: '合同報價編號',
    label5: '項目所在地',
    label6: '項目類型',
    label7: '借用出庫',
    label8: '約定歸還日期',
    label9: '出庫備註',
    label10: '快遞單號',
    label11: '物流公司',
    label12: '快遞費用',
    label13: '收貨人',
    label14: '電話',
    label15: '快遞備註',
    label16: '收貨地址',
    label17: '客戶名稱',
    label18: '網點名稱',
    message1: '請選擇出庫設備',
    message2: '出庫數量不能為0',
    message3: '設備未分配二維碼，請分配二維碼後再出庫。',
    message4: '數量不能超過',
    message5: '無序列設備數量不能超過',
    serial: {
      title1: '出庫數量編輯',
      tab1: '無序列號',
      tab2: '有序列號',
      label1: '數量',
      label2: '單位',
      col1: '序列號',
    },
  },
  quotation: {
    btn1: '設置',
    col1: '商品名稱',
    col2: '型號',
    col3: '品牌',
    col4: '類型',
    col5: '最新價格',
    col6: '價格波動',
    col7: '平均價格',
    col8: '最高價格',
    col9: '最低價格',
    col10: '詢價日期',
    col11: '停產',
    tag1: '正常',
    tag2: '停產',
    options: {
      title1: '設置',
      subtitle1: '匯率',
      subtitle2: '價格小數點位數',
      col1: '貨幣',
      col2: '匯率',
      message1: '請求次數過多，請於一小時後重新請求',
    }
  },
  newQuotation: {
    title1: '編輯商品信息',
    title2: '查看商品信息',
    label1: '商品名稱',
    label2: '型號',
    label3: '品牌',
    label4: '類型',
    label5: '停產',
    label6: '日期',
    btn1: '添加替代品',
    btn2: '顯示價格',
    tab1: '價格',
    tab2: '停產代替',
    tab3: '規格說明',
    col1: '狀態',
    col2: '創建日期',
    col3: '供應商',
    col4: '電話',
    col5: '貨幣',
    col6: '單價',
    col7: '描述',
    col8: '項目名稱',
    col9: '稅收',
    col10: '折扣',
    col11: '失效日期',
    col12: '附件',
    col13: '詢價日期',
    col14: '創建日期',
    col15: '停產',
    col16: '終止日期',
    col17: '商品名稱',
    col18: '型號',
    col19: '品牌',
    col20: '類型',
    tag1: '正常',
    tag2: '停產',
    tag3: '有效',
    tag4: '失效',
  },
  newQuote: {
    title1: '新增商品報價',
    title2: '編輯商品報價',
    subtitle1: '附件',
    label1: '商品名稱',
    label2: '品牌',
    label3: '供應商',
    label4: '聯系',
    label5: '電話',
    label6: '貨幣',
    label7: '單價',
    label8: '稅費',
    label9: '折扣',
    label10: '創建人',
    label11: '型號',
    label12: '類型',
    label13: '郵箱',
    label14: '傳真',
    label15: '職位',
    label16: '描述',
    label17: '項目名稱',
    label18: '失效日期',
    label19: '詢價日期',
    message1: '格式有誤，小數點最多精確到8位',
  },
  complaintsEvaluation: {
    col1: '時間',
    col2: '類型',
    col3: '評分',
    col4: '提交人',
    col5: '關聯ID',
    col6: '描述',
    col7: '網點名稱',
    complaints: {
      title1: '新增投訴建議',
      title2: '編輯投訴建議',
      label1: '網點名稱',
      label2: '投訴建議',
    }
  },

  purchaseOrders: {
    btn1: '打印',
    btn2: 'BGB打印',
    col1: '創建時間',
    col2: '訂單編號',
    col3: '付款日期',
    col4: '訂單總額',
    col5: '訂購人',
    col6: '項目名稱',
    col7: '合同報價編號',
    col8: '供應商',
  },
  purchaseRequisition: {
    col1: '創建時間',
    col2: '購物申請單號',
    col3: '項目名稱',
    col4: '合同報價編號',
    col5: '合同類型',
    col6: '客戶審批',
    col7: '申購人',
    col8: '部門經理',
    col9: '總經理',
    col10: '采購',
    col11: '驗收',
    col12: '財務',
    col13: '審批狀態',
    btn1: '打印',
    btn2: '重新提交',
    btn3: '駁回',
    btn4: '批準',
    title1: '拒絕說明',
    title2: '批準說明',
    tag1: '待審核',
    tag2: '審核通過',
    tag3: '審核未通過',
  },
  allocateGoods: {
    title1: '新建購物申請',
    title2: '編輯購物申請',
    title3: '備貨',
    title4: '查看購物申請單',
    tab1: '申請單',
    tab2: '審核',
    subtitle1: '項目信息',
    subtitle2: '設備信息',
    label1: '購物申請單號',
    label2: '申購人',
    label3: '數據訪問組',
    label4: '項目名稱',
    label5: '合同報價編號',
    label6: '合同類型',
    label7: '客戶審批',
    label8: '送貨地址',
    label9: '備註',
    btn1: '備貨',
    btn2: '鎖定',
    btn3: '保存退出',
    btn4: '打印',
    btn5: '駁回',
    btn6: '批準',
    col1: '設備類型',
    col2: '設備名稱',
    col3: '規格型號',
    col4: '設備品牌',
    col5: '單位',
    col6: '申請數量',
    col7: '庫存數量',
    col8: '缺貨數量',
    col9: '已入庫數',
    col10: '已出庫數1',
    col11: '已出庫數2',
    col12: '鎖定庫存數',
    col13: '單價',
    col14: '總價',
    col15: '項目名稱',
    col16: '報價單號',
    col17: '已出庫數3',
    message1: '購物申請單號重復',
    message2: '請選擇購物申請單',
    addEquipment: {
      title1: '新增設備',
      title2: '編輯設備',
      label1: '設備名稱',
      label2: '設備類型',
      label3: '設備品牌',
      label4: '規格型號',
      label5: '申購數量',
      label6: '用途',
      label7: '需購回時間',
      label8: '備註',
      label9: '單價',
      label10: '總價',
      label11: '詳細規格',
      label12: '單位',
      label13: '收件人',
    },
    setLock: {
      title1: '調整鎖定數量',
      title2: '調整記錄',
      btn1: '查看',
      label1: '調整鎖定數量',
      label2: '調整原因',
      label3: '鎖定數量',
      message1: '鎖定不能大於申請數量',
      message2: '鎖定不能小於0',
    }
  },
  newOrder: {
    title1: '新增訂貨單',
    title2: '編輯訂貨單',
    title3: '查看訂貨單',
    tab1: '訂貨單',
    tab2: '訂貨跟蹤',
    orderForm: {
      subtitle1: '訂貨單信息',
      subtitle2: '條款',
      subtitle3: '訂購內容',
      btn1: '保存退出',
      btn2: '打印',
      label1: '項目名稱',
      label2: '合同報價編號',
      label3: '數據訪問組',
      label4: '訂單編號',
      label5: '訂單類型',
      label6: '訂購人',
      label7: '供應商',
      label8: '付款日期',
      label9: '訂單總額',
      label10: '交貨日期',
      label11: '運費',
      label12: '收貨人',
      label13: '收貨地址',
      label14: '聯系方式',
      label15: '稅率',
      label16: '發票類型',
      label17: '合同日期',
      label18: '合並相同項',
      label19: '條款1補充',
      label20: '條款2補充',
      label21: '條款3補充',
      col1: '設備類型',
      col2: '設備名稱',
      col3: '設備品牌',
      col4: '規格型號',
      col5: '單位',
      col6: '單價',
      col7: '訂貨數量',
      col8: '到貨數量',
      col9: '需購回時間',
      col10: '購物申請單號',
      col11: '申購人',
      col12: '備註',
      col13: '項目名稱',
      addEquipment: {
        title1: '新增設備',
        title2: '編輯設備',
        label1: '購物申請單',
        label2: '購物申請單號',
        label3: '設備類型',
        label4: '設備名稱',
        label5: '設備品牌',
        label6: '規格型號',
        label7: '訂購數量',
        label8: '單價',
        label9: '需購回時間',
        label10: '備註',
        yes: '是',
        no: '否',
      },
      allocateGoods: {
        title1: '備貨',
        col1: '購物申請單號',
        col2: '設備名稱',
        col3: '規格型號',
        col4: '項目名稱',
        col5: '庫存數量',
        label1: '總庫存',
        label2: '申請數量',
        label3: '已出庫數量1（本項目）',
        label4: '庫存數量',
        label5: '已出庫數量2（其他項目）',
        label6: '缺貨數量',
        label7: '請輸入訂貨數量',
      },
    },
    orderTracking: {
      btn1: '訂貨狀態更新',
      btn2: '打印',
      col1: '更新日期',
      col2: '操作員',
      col3: '付款_狀態1',
      col4: '訂貨_狀態2',
      col5: '到貨_狀態3',
      updateState: {
        title1: '訂貨狀態更新',
        radio1: '付款',
        radio2: '訂貨',
        radio3: '到貨',
        label1: '付款狀態',
        label2: '付款日期',
        label3: '應付款總金額',
        label4: '總共已付金額',
        label5: '剩余未付金額',
        label6: '本期付款金額',
        label7: '備註',
        label8: '訂貨狀態',
        label9: '訂貨日期',
        label10: '預計到貨期',
        label11: '備註',
        label12: '到貨狀態',
        label13: '到貨日期',
        label14: '剩余預計到貨期',
        label15: '備註',
        message1: '請先保存訂貨單',
      }
    }
  },
  addOrder: {
    title1: '新增訂購',
    title2: '訂購數量和價格編輯',
    subtitle1: '訂貨數量',
    subtitle2: '待訂貨項目',
    label1: '申請數量',
    label2: '缺貨數量',
    label3: '庫存數量',
    label4: '出庫1/2/3',
    label5: '已訂貨數量',
    label6: '本次訂貨數量',
    label7: '單價',
    table1: {
      col1: '設備類型',
      col2: '設備名稱',
      col3: '品牌',
      col4: '規格型號',
      col5: '申請數量',
      col6: '已訂貨數量',
      col7: '購物申請單號',
      col8: '項目名稱',
    },
    table2: {
      col1: '設備類型',
      col2: '設備名稱',
      col3: '品牌',
      col4: '規格型號',
      col5: '訂貨數量',
      col6: '單價',
      col7: '備註',
      col8: '需購回時間',
      col9: '購物申請單號',
      col10: '項目名稱',
      col11: '申購人',
    },
  },
  inventoryStatistics: {
    label1: '設備類型',
    label2: '設備名稱',
    label3: '規格型號',
    label4: '設備品牌',
    label5: '單位',
    label6: '入庫數量',
    label7: '出庫數量',
    label8: '庫存數量',
  },
  deviceDetailsList: {
    title1: '列表詳情',
    col1: '出入庫',
    col2: '時間',
    col3: '序列號',
    col4: '數量',
    col5: '單位',
    col6: '設備類型',
    col7: '設備名稱',
    col8: '規格型號',
    col9: '工程項目',
    col10: '申購人',
  },
  score: {
    title1: '評分',
    subtitle1: '描述',
    label1: '總評分',
    label2: '得分',
    label3: '總得分',
    tag1: '極不滿意',
    tag2: '不滿意',
    tag3: '尚可',
    tag4: '滿意',
    tag5: '非常滿意',
  },
  ApplyDialog: {
    title1: '選擇購物申請單',
    col1: '項目名稱',
    col2: '合同報價編號',
    col3: '購物申請單號',
  },
  SupplierDialog: {
    title1: '供應商',
    title2: '新增供應商',
    col1: '供應商',
    col2: '簡稱',
  },
  inventoryDevice: {
    title1: '庫存 - 設備詳情',
    col1: '入庫日期',
    col2: '入庫地區',
    col3: '設備類型',
    col4: '設備名稱',
    col5: '規格型號',
    col6: '設備品牌',
    col7: '數量',
    col8: '單位',
    col9: '序列號',
    col10: '購物申請單號',
    col11: '項目名稱',
    col12: '合同報價編號',
    col13: '訂單編號',
  },
  approvalWorkflow: {
    col1: '申請名稱',
    col2: '工作流ID',
    col3: '審批步驟',
    col4: '流程圖',
    col5: '部門',
    col6: '審批人',
    col7: '抄送',
    workfow: {
      title1: '添加新批準工作流',
      title2: '編輯審批工作流',
      subtitle1: '審批流程',
      subtitle2: '抄送',
      subtitle3: '審批選項',
      label1: '請求名稱',
      label2: '工作流程ID',
      label3: 'Step',
      label4: '圖表',
      col1: '部門',
      col2: '批準者',
      col3: '部門',
      col4: 'CC',
      option1: '審核',
      option2: '審批',
    },
    person: {
      title1: '添加人員'
    }
  },
  appRelease: {
    col1: '平臺',
    col2: '版本類型',
    col3: '版本號',
    col4: '包名',
    col5: '安裝包大小',
    col6: '發布時間',
    col7: '創建人',
    title1: '新版本',
    label1: '上傳',
    label2: '路徑',
    message1: '請上傳安裝包',
    message2: '請上傳apk類型文件',
  },
  projects: {
    col1: '項目名稱',
    col2: '持續時間',
    col3: '開始',
    col4: '完成',
    col5: '完成百分比',
  },
  newProjects: {
    title1: '新增項目',
    title2: '編輯項目',
    tab1: '基本信息',
    tab2: '甘特圖',
    tab3: '列表',
    tab4: '文檔',
    info: {
      label1: '項目名稱',
      label2: '項目類型',
      label3: '數據訪問組',
      label4: '日期',
      label5: '合同編號',
      label6: '備註',
    },
    documents: {
      col1: '任務',
      col2: '所需文檔',
      col3: '提交文檔',
    },
    ganttChart: {
      col1: '任務',
      col2: '持續時間',
      col3: '開始',
      col4: '完成',
      col5: '人員分配',
      btn1: '菜單',
      btn2: '新任務',
      btn3: '新標準任務',
      btn4: '設置基準',
      btn5: '導出',
      btn6: '縮放',
      btn7: '天',
      btn8: '周',
      btn9: '月',
      task: {
        title1: '新任務',
        title2: '編輯任務',
        title3: '新標準任務',
        title4: '編輯標準任務',
        title5: '選擇任務',
        title6: '選擇文件',
        tab1: '常規',
        tab2: '資源',
        tab3: '文檔',
        label1: '任務名稱',
        label2: '開始日期',
        label3: '完成日期',
        label4: '持續時間',
        label5: '完成百分比',
        label6: '前置任務',
        label7: '註釋',
        label8: '裏程碑',
        subtitle1: '標準任務',
        col1: '人員',
        col2: '小時',
        col3: '編號',
        col4: '標準任務',
        col5: '類型',
        col6: '序列',
        col7: '任務階段',
      }
    },
  },
  documentsDetail: {
    title1: '文檔詳情',
    label1: '任務名稱',
  },
  standardTask: {
    title1: '新增標準任務',
    title2: '編輯標準任務',
    col1: '標準任務',
    col2: ' 編號',
    col3: '類型',
    col4: '項目類別',
    col5: '文檔',
    col6: '序列',
    label1: '標準任務',
    label2: '任務類型',
    label3: '項目類別',
    label4: '註釋',
    label5: '文檔',
    label6: '序列',
    label7: '任務階段',
    btn1: '上移',
    btn2: '下移',
  },
  projectDocuments: {
    col1: '文件名',
    col2: '格式',
    col3: '操作',
    btn1: '上傳',
    btn2: '下載',
    title1: '新文件夾',
    title2: '編輯文件夾',
    title3: '新文件',
    title4: '編輯文件',
    label1: '文件夾名稱',
    label2: '文件名',
    label3: '格式',
  },
  mySelect: {
    option1: '無匹配數據',
  },


  dashBoard: {
    btn1: '風險級別設置',
    btn2: '打印',
    btn3: '導出',
    btn4: '布局模板',
    btn5: '布局設置',
    btn6: '另存為',
    btn7: '保存',
    dashboardTemplate: {
      title1: '布局模板',
      col1: '模板名稱',
      col2: '系統默認值',
      col3: '組織',
      col4: '數據訪問組',
      col5: '當前',
      btn1: '設置當前',
      btn2: '布局編輯',
    },
    addDashboard: {
      title1: '另存為新的布局模板',
      title2: '編輯布局模板',
      label1: '模板名稱',
      label2: '數據訪問組（數據權限）',
      label3: '將模板分配給所選組織',
      label4: '設為默認值',
    },
    exportReports: {
      title1: '導出報告',
      label1: '文件名稱',
      label2: '文件格式',
    },
    overview: {
      title1: '概述數據呈現',
      label1: '報告模板',
      col1: '當前',
      col2: '標題',
      col3: '報告模板',
      col4: '圖表',
      col5: '表格',
    }
  },
  counterMode: {
    btn1: '風險級別設置',
    btn2: '打印',
    btn3: '導出',
    btn4: '布局設置',
    btn5: '保存',
    btn6: '添加至條件',
    btn7: '新查詢',
    btn8: '復製',
    filterPopover: {
      title1: '排序與篩選',
      btn1: '重置',
      btn2: 'A - Z (從小到大)',
      btn3: 'Z - A (從大到小)',
      btn4: '自定義排序...',
      btn5: '篩選',
    }
  },
  reportTemplates: {
    col1: '報告模板',
    col2: '說明',
    col3: '數據訪問組',
    col4: '模式',
    col5: '分析模式',
    col6: '創作者',
    dialog: {
      title1: '新報告模板',
      title2: '編輯報告模板',
      label1: '報告模板名稱',
      label2: '說明（預防位置損失分析）',
      label3: '數據訪問組（數據權限）',
      label4: '分析模式',
      label5: '計數器模式',
      label6: '用餐模式',
    }
  },
  reportGroup: {
    btn1: '添加節點',
    label1: '選定的節點',
    col1: '報告模板',
    col2: '說明',
    col3: '數據訪問組',
    nodeDialog: {
      title1: '添加新節點',
      title2: '編輯節點',
      label1: '節點名稱（報告類別）',
      label2: '描述',
      label3: '數據訪問組（數據權限）',
    },
  },
  newReportGroup: {
    title1: '報告類別分配',
    label1: '選定的組',
    label2: '數據訪問組（數據權限）',
    label3: '描述',
    label4: '報告模板列表',
    label5: '已選擇',
    col1: '編號',
    col2: '報告模板',
    col3: '說明',
  },
  reportDesigner: {
    label1: '報告模板',
    label2: '圖表類型',
    label3: '分析模式',
    label4: '顯示名稱設置',
    label5: '子字段第二語言',
    label6: '主要領域第二語言',
    col1: '報告主要字段',
    col2: '報告子字段',
    col3: '字段類型',
    col4: '測量',
    col5: '風險級別',
    col6: '概述字段',
    col7: '鏈接',
    col8: '手動排序',
    dialog: {
      title1: '新報告字段',
      title2: '編輯報告字段',
      label1: '字段類型',
      label2: '多列標題',
      label3: '報告主要字段',
      label4: '報告主要字段',
      label5: '參數字段',
      label6: '第二語言',
      label7: '說明',
      label8: '報告子字段',
      label9: '默認運算符',
      label10: '測量',
      label11: '選項值',
      label12: '風險級別字段',
      label13: '默認查詢字段',
    }
  },
  chartType: {
    title1: '圖表標題',
    label1: '所有圖表',
    label2: '圖表標題',
    btn1: '上傳',
    type1: '圖標',
    type2: '柱狀圖',
    type3: '餅圖',
    type4: '折線圖',
    tab1: '數據源',
    tab2: '查詢條件',
    tab3: '排序',
    tab4: '圖表屬性',
    dataSource: {
      title1: '選擇數據源',
      col1: '字段',
      col2: '類型',
      col3: '鏈接',
    },
    queryConditions: {
      btn1: '添加字段',
      btn2: '添加參數',
      col1: '與/或',
      col2: '查詢字段',
      col3: '字段類型',
      col4: '反轉',
      col5: '過濾運算符',
      col6: '條件',
    },
    sorting: {
      col1: '字段',
      col2: '訂單',
      col3: '狀態',
      title1: '排序',
    },
    chartElements: {
      col1: '圖表元素',
      col2: '選項',
      col3: '狀態',
    }
  },
  fieldDisplaySet: {
    title1: '顯示名稱設置',
    col1: '字段名稱',
    col2: '數據顯示名稱',
    col3: '數據代碼',
    col4: '全局設置',
    dialog: {
      title1: '數據顯示名稱設置',
    }
  },
  riskOfLossLevel: {
    col1: '風險級別模板',
    col2: '說明',
    col3: '系統默認值',
    col4: '數據訪問組',
    col5: '創作者',
    col6: '品牌',
    col7: '狀態',
  },
  newRiskOfLossLevel: {
    title1: '新增損失風險級別模板',
    title2: '編輯損失風險級別模板',
    label1: '模板名稱',
    label2: '數據訪問組（數據權限）',
    label3: '品牌',
    label4: '描述',
    label5: '設置成默認',
    col1: '報告模板',
    col2: '主要字段',
    col3: '子字段',
    col4: '測量',
  },
  slaPolicies: {
    tab1: 'SLA 政策',
    tab2: '升級級別',
    col1: 'SLA 政策',
    col2: 'SLA 類型',
    col3: '數據訪問組',
    col4: '客戶',
    col5: '分支/站點',
    col6: '創建日期',
    col7: '升級級別',
  },
  newSLAPolicy: {
    title1: '新 SLA 政策',
    title2: '編輯 SLA 政策',
    title3: '查看 SLA 政策',
    label1: 'SLA 政策',
    label2: 'SLA 類型',
    label3: '數據訪問組',
    col1: '顏色',
    col2: '優先級',
    col3: '優先級 ID',
    col4: '名稱',
    col5: '響應',
    col6: '營業時間',
    col7: '定時器',
    col8: '警告',
    col9: '發送到',
    col10: '計時器',
    col11: '升級',
    col12: '分辨率',
    col13: '計時器',
    col14: '警告',
    col15: '發送到',
    col16: '定時器',
    col17: '升級',
  },
  newSLAPriority: {
    title1: '新的 SLA 優先級',
    title2: '編輯 SLA 優先級',
    title3: '查看 SLA 優先級',
    tab1: '升級',
    tab2: '服務類別',
    label1: '優先級名稱',
    label2: '優先 ID',
    label3: 'SLA 階段類型',
    label4: '階段 ID',
    label5: '觸發器名稱',
    label6: '啟動計時器的時間',
    label7: '說明',
    label8: '停止計時器的時間',
    label9: '服務內容',
    label10: '啟用/禁用',
    col1: '觸發器ID',
    col2: '營業時間',
    col3: '定時器（在 SLA 內）',
    col4: '警告',
    col5: '定時器',
    col6: '升級（違反 SLA）',
  },
  newSLAEscalationLevel: {
    title1: '編輯 SLA 升級級別',
    label1: 'SLA',
    label2: '數據訪問組',
    col1: '級別',
    col2: '全名',
    col3: '站點組',
    col4: '組織',
    col5: '職位',
    col6: '電子郵件',
    col7: '電話號碼',
    col8: '備份電子郵件',
    col9: '備用電話號碼',
    contactDialog: {
      title1: '添加聯系人',
      label1: '類型',
      label2: '級別',
      label3: '說明',
    }
  },
  operationalTime: {
    col1: '組織名稱',
    col2: '數據訪問組',
    col3: '時區',
    col4: '國家',
    title1: '復製到',
    btn1: '復製到'
  },
  editOperationalTime: {
    title1: '編輯操作時間',
    tab1: '工作時間',
    tab2: '假期時間',
    label1: '組織名稱',
    label2: '描述',
    label3: '數據訪問組',
    label4: '國家',
    label5: '時區',
    col1: '名稱',
    col2: '類型',
    col3: '特殊工作時間',
  },
  newHolidayHours: {
    title1: '新假期時間',
    title2: '編輯假期時間',
    title3: '查看假期時間',
    label1: '姓名',
    label2: '類型',
    col1: '標題',
    col2: '日期',
  },
  maintenance: {
    tab1: '日歷',
    tab2: '維護',
    tab3: '記錄',
    btn1: '全選',
    calendar: {
      label1: '已完成',
      label2: '預定',
      label3: '不完整',
      label4: '日期',
      col1: '星期日',
      col2: '星期一',
      col3: '星期二',
      col4: '星期三',
      col5: '星期四',
      col6: '星期五',
      col7: '星期六',
    },
    maintenance: {
      btn1: '創建副本',
      btn2: '復製到',
      btn3: '打印',
      btn4: '出口',
      col1: '文件名',
      col2: '站點名稱',
      col3: '檢查清單',
      col4: '狀態',
      col5: '模板',
    },
    record: {
      btn1: '打印',
      btn2: '出口',
      col1: '日期時間',
      col2: '文件名',
      col3: '站點名稱',
      col4: '工程師',
      col5: '服務確認',
    }
  },
  maintenanceCheckList: {
    title1: '維護檢查清單',
    btn1: '模板',
    btn2: '打印',
    btn3: '出口',
    btn4: 'PDF 模板設計器',
    label1: '總計',
    col1: '圖標',
    col2: '標題',
    col3: '副標題 1',
    col4: '子標題 2',
    col5: '狀態',
    col6: '正常',
    col7: '異常',
  },
  newMaintenanceCheckList: {
    title1: '添加新的檢查清單',
    title2: '編輯檢查清單',
    title3: '查看檢查清單',
    tab1: '表格元素',
    tab2: '表格內容',
    tab3: '檢查清單',
    col1: '標題',
    col2: '副標題 1',
    col3: '副標題 2',
    col4: '圖標',
    tableContent: {
      btn1: '導入',
      btn2: '向上',
      btn3: '向下',
      btn4: '選項編輯',
      btn5: '復製',
      col1: '主類型',
      col2: '項目',
      col3: '註釋',
      col4: '檢查類型',
      col5: '選項',
      col6: '計數',
      col7: '相關設備',
      checkDialog: {
        title1: '添加項目',
        title2: '編輯項目',
        title3: '創建副本',
        label1: '主類型',
        label2: '註釋',
        label3: '檢查項目',
        label4: '檢查類型',
        label5: '選項',
        btn1: '創建一個副本',
      }
    },
    checkList: {
      btn1: '導入',
      btn2: '向上',
      btn3: '向下',
    }
  }
}

export default cn;
