<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="Item"
      :visible.sync="dialog.visible"
      width="80%"
    >
      <el-table :data="data">
        <el-table-column
          label="Item Name"
          width="130"
          prop="item_edesc"
        ></el-table-column>
        <el-table-column
          label="Amount"
          prop="amount"
        >
        </el-table-column>
        <el-table-column
          label="Unit Price"
          prop="unit_price"
          width="130"
        ></el-table-column>
        <el-table-column
          label="Quantity"
          prop="item_qty"
        ></el-table-column>
        <el-table-column
          label="PMT"
          prop="pmt"
        >
        </el-table-column>
        <el-table-column
          label="Description"
          width="120"
        ></el-table-column>

        <el-table-column
          label="Item ID"
          width="130"
          prop="item_code"
        ></el-table-column>

        <el-table-column
          width="100"
          label="PMT ID"
          prop="pmt_no"
        >
        </el-table-column>
        <el-table-column
          label="Discount Details"
          width="130"
        ></el-table-column>
        <el-table-column
          label="Discount Handler"
          width="130"
        ></el-table-column>
        <el-table-column
          label="Discount Approval code"
          width="170"
        ></el-table-column>
        <el-table-column
          label="Discount Type"
          width="110"
        ></el-table-column>
        <el-table-column
          label="Key in/card"
          width="100"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
      }
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  }
}
</script>

<style>
</style>