<template>
  <div class="wrap">
    <el-card v-loading="cardLoading">
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="150"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newHolidayHours.label1')"
              prop="name"
            >
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newHolidayHours.label2')"
              prop="type"
            >
              <el-select v-model="groupForm.type">
                <el-option
                  label="Regular Holidays"
                  :value="1"
                ></el-option>
                <el-option
                  label="Special Holidays"
                  :value="2"
                ></el-option>
                <el-option
                  label="Additional Special Holidays"
                  :value="3"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        border
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column
          min-width="130"
          prop="name"
          :label="$t('newHolidayHours.col1')"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.name"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          min-width="350"
          prop="userAccount"
          :label="$t('newHolidayHours.col2')"
        >
          <template slot-scope="scope">
            <el-date-picker
              size="mini"
              type="daterange"
              v-model="scope.row.date"
              :format="groupForm.type === 1 ? 'MM-dd':'yyyy-MM-dd'"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column min-width="130">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.$index === 0 && tableData.length === 1"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="delItem(scope.row,scope.$index)"
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-plus"
              type="success"
              @click="addItem(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
// 接口
import {
  AddHoliday,
  GetHolidayDateList,
  DelHolidayDate,
} from '@/api/warehouse/operationalTime'
// 组件
import PageHeader from '@/components/PageHeader'
export default {
  name: 'NewHolidayHours',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      cardLoading: false,
      title: '',
      groupForm: {
        loading: false,
        id: null,
        organizationId: null,
        name: '',
        type: 1,
      },
      rules: {
        // name: [{ required: true, message: 'Please enter an Group Name.', trigger: 'blur' }]
      },
      tableLoading: false,
      tableData: [{
        name: '',
        date: null,
        id: null,
      }],
    }
  },
  created () {
    this.dealPermission();

    this.groupForm.organizationId = this.$route.query.orgId;
    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newHolidayHours.title2') : this.$i18n.t('newHolidayHours.title3');
      this.getItem();
    } else {
      this.title = this.$i18n.t('newHolidayHours.title1');
    }

  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      let holidayDate = JSON.parse(sessionStorage.holidayDate);
      let param = { holidaySetId: this.groupForm.id };
      GetHolidayDateList(param).then(res => {
        this.tableData = res.map(item => {
          item.date = !!item.year ? [`${item.year}-${item.month}-${item.day}`, `${item.year2}-${item.month2}-${item.day2}`] : null;
          return item;
        });
      })

      Object.assign(this.groupForm, {
        name: holidayDate.name,
        type: holidayDate.type,
      })
    },
    // 添加
    addItem (index) {
      this.tableData.splice(index + 1, 0, {
        title: '',
        date: null,
        id: null,
      })
    },
    // 删除
    delItem (item, index) {
      if (item.id) {
        DelHolidayDate({ id: item.id }).then(res => {
          if (res.success) this.tableData.splice(index, 1);
        })
      } else {
        this.tableData.splice(index, 1);
      }
    },

    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = [{
          id: this.groupForm.id,
          name: this.groupForm.name,
          type: this.groupForm.type,
          orgZationId: this.groupForm.organizationId,
          holidays: this.tableData.map(item => {
            let date1 = !!item.date ? item.date[0].split('-') : [];
            let date2 = !!item.date ? item.date[1].split('-') : [];
            let holiday = {
              id: item.id,
              name: item.name,
              year: date1[0],
              month: date1[1],
              day: date1[2],
              year2: date2[0],
              month2: date2[1],
              day2: date2[2],
              holidaySetId: this.groupForm.id,
            }
            return holiday;
          })
        }]
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }
        this.groupForm.loading = true;
        AddHoliday(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.edit = permission.includes('678d9209-4d35-44d8-be8d-29a309d1792b');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>