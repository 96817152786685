<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Box Position X</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.left"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Box Position Y</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.top"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Box Width</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.width"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Box Height</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.height"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Font Size</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.fontSize"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Row Height</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.lineHeight"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Font Family</label>
        <el-select
          size="small"
          v-model="elData.style.fontFamily"
          filterable
        >
          <el-option
            v-for="(item,index) in fontFamilys"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
    <el-row :gutter="10">
    </el-row>
    <el-button-group class="btn-box">
      <el-button
        size="small"
        @click="changeFont('b')"
        :type="elData.style.fontWeight === 400 ? '':'primary'"
      >B</el-button>
      <el-button
        size="small"
        @click="changeFont('i')"
        :type="elData.style.fontStyle === 'normal' ? '':'primary'"
        class="text-italic"
      >I</el-button>
      <el-button
        size="small"
        @click="changeFont('u')"
        :type="elData.style.textDecoration === 'underline' ? 'primary':''"
        class="text-underline"
      >U</el-button>
      <el-button
        size="small"
        @click="changeFont('abc')"
        :type="elData.style.textDecoration === 'line-through' ? 'primary':''"
        class="text-line-through"
      >abc</el-button>
      <el-color-picker
        size="small"
        v-model="elData.style.color"
      ></el-color-picker>
      <el-button
        size="small"
        @click="changeFont('left')"
        icon="icon-text-left"
        class="align-btn"
      ></el-button>
      <el-button
        size="small"
        @click="changeFont('center')"
        icon="icon-text-center"
        class="align-btn"
      ></el-button>
      <el-button
        size="small"
        @click="changeFont('right')"
        icon="icon-text-right"
        class="align-btn"
      ></el-button>
    </el-button-group>
  </div>

</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {
      fontFamilys: [
        { label: '宋体', value: '宋体' },
        { label: '黑体', value: '黑体' },
        { label: '微软雅黑', value: '微软雅黑' },
        { label: '微软正黑体', value: '微软正黑体' },
        { label: '楷体', value: '楷体' },
        { label: '新宋体', value: '新宋体' },
        { label: '仿宋', value: '仿宋' },
        { label: '苹方', value: '苹方' },
        { label: '华文黑体', value: '华文黑体' },
        { label: '华文楷体', value: '华文楷体' },
        { label: '华文宋体', value: '华文宋体' },
        { label: '华文仿宋', value: '华文仿宋' },
        { label: '华文中宋', value: '华文中宋' },
        { label: '华文琥珀', value: '华文琥珀' },
        { label: '华文新魏', value: '华文新魏' },
        { label: '华文隶书', value: '华文隶书' },
        { label: '华文行楷', value: '华文行楷' },
        { label: '冬青黑体简', value: '冬青黑体简' },
        { label: '兰亭黑-简', value: '兰亭黑-简' },
        { label: '翩翩体-简', value: '翩翩体-简' },
        { label: '手札体-简', value: '手札体-简' },
        { label: '宋体-简', value: '宋体-简' },
        { label: '娃娃体-简', value: '娃娃体-简' },
        { label: '魏碑-简', value: '魏碑-简' },
        { label: '行楷-简', value: '行楷-简' },
        { label: '雅痞-简', value: '雅痞-简' },
        { label: '圆体-简', value: '圆体-简' },
        { label: '幼圆', value: '幼圆' },
        { label: '隶书', value: '隶书' },
        { label: '华文细黑', value: '华文细黑' },
        { label: '华文楷体', value: '华文楷体' },
        { label: '华文宋体', value: '华文宋体' },
        { label: '华文仿宋', value: '华文仿宋' },
        { label: '华文中宋', value: '华文中宋' },
        { label: '华文彩云', value: '华文彩云' },
        { label: '华文琥珀', value: '华文琥珀' },
        { label: '华文新魏', value: '华文新魏' },
        { label: '华文隶书', value: '华文隶书' },
        { label: '华文行楷', value: '华文行楷' },
        { label: '方正舒体', value: '方正舒体' },
        { label: '方正姚体', value: '方正姚体' },
        { label: '思源黑体', value: '思源黑体' },
        { label: '思源宋体', value: '思源宋体' },
        { label: '文泉驿微米黑', value: '文泉驿微米黑' },
      ]
    }
  },
  created () {

  },
  methods: {
    changeFont (type) {
      let item = this.elData;
      switch (type) {
        case 'b':
          item.style.fontWeight = item.style.fontWeight === 400 ? 700 : 400;
          break;
        case 'i':
          item.style.fontStyle = item.style.fontStyle === 'normal' ? 'italic' : 'normal';
          break;
        case 'u':
          item.style.textDecoration = item.style.textDecoration === 'underline' ? 'none' : 'underline';
          break;
        case 'abc':
          item.style.textDecoration = item.style.textDecoration === 'line-through' ? 'none' : 'line-through';
          break;
        case 'left':
          item.style.textAlign = 'left';
          break;
        case 'center':
          item.style.textAlign = 'center';
          break;
        case 'right':
          item.style.textAlign = 'right';
          break;
      }
      // this.$emit('update:elData', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>