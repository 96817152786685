<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="Layout Settings"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >

      <el-carousel
        type="card"
        trigger="click"
        height="270px"
        :autoplay="false"
        @change="carouselChange"
      >
        <el-carousel-item name="1">
          <el-row :gutter="10">
            <el-col
              :span="24"
              style="height:190px"
            ></el-col>
          </el-row>
        </el-carousel-item>
        <el-carousel-item name="3">
          <el-row :gutter="10">
            <el-col :span="24"></el-col>
            <el-col :span="24"></el-col>
          </el-row>
        </el-carousel-item>
        <el-carousel-item name="2">
          <el-row :gutter="10">
            <el-col :span="12"></el-col>
            <el-col :span="12"></el-col>
            <el-col :span="24"></el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="save"
        >OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LayoutSetting',
  props: ['visible', 'layout', 'parent'],
  data () {
    return {
      dialog: {
        index: 0,
        visible: this.visible,
      },
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    carouselChange (index) {
      this.dialog.index = index;
    },
    save () {
      this.dialog.visible = false;
      this.$emit('submit', this.dialog.index);
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0;
}
.el-carousel__item {
  padding: 40px;
  background-color: #d3dce6;
  &.is-active {
    background-color: #99a9bf;
  }
}

.el-col {
  height: 90px;
  position: relative;
  margin-bottom: 10px;
  &:before {
    content: "";
    height: 100%;
    width: 100%;
    background: #fff;
    display: block;
  }
}
.dash-board {
  .el-col {
    height: 45px;
  }
  &:last-child::after {
    content: "+";
    height: 100%;
    width: 100%;
    line-height: 45px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.tab {
  height: 25px !important;
  &:last-child::after {
    content: "";
  }
}

.kpi-wrap {
  .top {
    height: 60px;
  }
  .bottom {
    height: 150px;
  }
}
</style>