<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('organizations.shareDialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-row>
        <el-table border          :data="tableData"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="name"
            :label="$t('organizations.shareDialog.col1')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetOrganizationList,
} from '@/api/organizations'
export default {
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      };
      this.tableLoading = true;
      GetOrganizationList(param).then((res) => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交
    submit () {

    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>