<template>
  <div class="wrap">
    <el-card>
      <page-header
        :title="title"
        :titles="titles"
      >
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('newMaintenanceCheckList.tab1')"
          name="TableElements"
        >
          <el-form
            :model="groupForm"
            :rules="rules"
            ref="groupForm"
            class="group-form"
            size="medium"
            label-position="top"
          >
            <el-row :gutter="160">
              <el-col :span="12">
                <el-form-item
                  :label="$t('newMaintenanceCheckList.col1')"
                  prop="title"
                >
                  <el-input
                    :disabled="viewType == 1"
                    v-model="groupForm.title"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('newMaintenanceCheckList.col2')"
                  prop="subTitle1"
                >
                  <el-input
                    :disabled="viewType == 1"
                    v-model="groupForm.subTitle1"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('newMaintenanceCheckList.col3')"
                  prop="subTitle2"
                >
                  <el-input
                    :disabled="viewType == 1"
                    v-model="groupForm.subTitle2"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newMaintenanceCheckList.col4')"
                  prop="icon"
                >
                  <div class="img-box">
                    <el-image
                      lazy
                      :src="photo"
                      fit="cover"
                    >
                    </el-image>
                    <el-button
                      :disabled="viewType == 1"
                      @click="$refs.fileImage.click()"
                    >...</el-button>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('newMaintenanceCheckList.tab2')"
          name="TableContent"
        >
          <table-content
            ref="tableContent"
            :active="activeName"
            :type="viewType"
            :element-id="groupForm.id"
            :maintenance-id="maintenanceId"
            :org-id="orgId"
            @update="getItem"
          ></table-content>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!groupForm.id"
          :label="$t('newMaintenanceCheckList.tab3')"
          name="CheckList"
        >
          <check-list
            ref="checkList"
            :active="activeName"
            :type="viewType"
            :element-id="groupForm.id"
            :maintenance-id="maintenanceId"
          ></check-list>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <!-- 上传 -->
    <input
      type="file"
      ref="fileImage"
      @change="uploadImage"
      hidden
    >
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import TableElements from './components/TableElements'
import TableContent from './components/TableContent'
import CheckList from './components/CheckList'
// 接口
import {
  AddTableElement,
  GetTableElementItem,
  AddCheckListItem,
} from '@/api/warehouse/maintenance'
import { UploadImage } from '@/api'

export default {
  name: 'NewMaintenanceCheckList',
  components: {
    PageHeader,
    TableElements,
    TableContent,
    CheckList,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      title: '',
      activeName: 'TableElements',
      viewType: 0,
      maintenanceId: null,
      fileId: null,
      orgId: null,
      groupForm: {
        loading: false,
        id: null,
        title: '',
        subTitle1: '',
        subTitle2: '',
        icon: '',
      },
      rules: {
        title: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  computed: {
    titles: function () {
      let arr = [
        this.groupForm.title,
        this.groupForm.subTitle1,
        this.groupForm.subTitle2,
      ].filter(item => !!item);
      return arr;
    },
    photo () {
      if (!!this.groupForm.icon) {
        return this.baseUrl + '/Engineer/getImage.do?imageId=' + this.groupForm.icon;
      } else {
        return '';
      }
    }
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;
    this.maintenanceId = this.$route.query.maintenanceId;
    this.fileId = this.$route.query.fileId;
    this.viewType = parseInt(this.$route.query.type);
    this.orgId = this.$route.query.orgId;
    this.$store.state.checkListData = [];
    if (this.groupForm.id) {
      this.title = this.permit.edit && this.viewType != 1 ? this.$i18n.t('newMaintenanceCheckList.title2') : this.$i18n.t('newMaintenanceCheckList.title3');
      this.getItem();
    } else {
      this.title = this.$i18n.t('newMaintenanceCheckList.title1');
    }
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getItem (fun) {
      let param = {
        id: this.groupForm.id,
        isRecord: this.viewType,
        maintenanceId: this.maintenanceId,
      }
      GetTableElementItem(param).then(res => {
        this.groupForm = res;
        this.$store.state.checkListData = res.tableContentList.map(item => {
          item.checkTypeItem = item.checkTypeItem || {}
          return item;
        })
        this.$refs.checkList.syncData();
      })
    },
    submit () {
      this.groupForm.loading = true;
      this.$forceUpdate();

      // this.$refs.checkList.dealData();
      this.$store.state.checkListData.forEach((item, index) => item.sn = index);
      if (this.viewType == 0) {
        this.submit0();
      } else {
        this.submit1();
      }

    },
    submit0 () {
      let param = {
        fileId: this.fileId,
        title: this.groupForm.title,
        subTitle1: this.groupForm.subTitle1,
        subTitle2: this.groupForm.subTitle2,
        icon: this.groupForm.icon,
        tableContentList: this.$store.state.checkListData,
      };

      let msg = this.$i18n.t('message.add');
      if (this.groupForm.id) {
        param.id = this.groupForm.id;
        param.fileId = this.groupForm.fileId;
        msg = this.$i18n.t('message.edit');
      }

      AddTableElement(param).then(res => {
        if (res.success) {
          this.$message.success(msg)
          this.back();
        }
        this.groupForm.loading = false;
      }).catch(error => {
        this.groupForm.loading = false;
      })
    },
    submit1 () {
      let param = [];
      this.$store.state.checkListData.forEach(item => {
        item.itemList.forEach(item => {
          item.maintenanceId = this.maintenanceId
          param.push(item);
        })
      })

      if (param.length === 0) this.back();
      AddCheckListItem(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.edit'))
          this.back();
        }
        this.groupForm.loading = false;
      }).catch(error => {
        this.groupForm.loading = false;
      })
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.fileImage.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.groupForm.icon = res.message;
        }
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.edit = permission.includes('SLAPolicies-SLAPoliciesEdit')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-tabs {
  height: calc(100% - 56px);
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.img-box {
  display: flex;
  align-items: flex-start;
  .el-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
</style>