<template>
  <div>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="items.length !== 1"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row>
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.systemType.col1')"
          prop="name"
        ></el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 设备弹框 -->
    <add-device-type
      :visible.sync="AddDeviceTypeVisible"
      :item="items[0]"
      type="SystemType"
      @update="getTableData"
    ></add-device-type>
  </div>
</template>

<script>
// 组件
import AddDeviceType from '../AddDeviceType'
// 接口
import {
  GetBySystemDeviceList,
  GetNoSystemDeviceList,
  AddSystemDevice,
  DelSystemDevice,
} from '@/api/warehouse/dataMaintenance/systemType'
import {
  GetboundList,
  DelUnbound,
} from '@/api/warehouse/dataMaintenance'
export default {
  props: ['items', 'tableHeight'],
  components: {
    AddDeviceType
  },
  data () {
    return {
      AddDeviceTypeVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      name: '',
      searchVal: '',
      tableSelected: [],
      tableLoading: false,
      tableData: [],

    }
  },
  watch: {
    'tableHeight': function (newVal) {
      this.page.size = Math.ceil((newVal - 36) / 35);
    },
    'items': function () {
      if (this.items.length === 0) {
        this.tableData = [];
      } else {
        this.getTableData();
      }
    }
  },

  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        mainField: this.items.map(item => item.id).join(','),
      }
      this.tableLoading = true;
      GetboundList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.AddDeviceTypeVisible = true;
    },
    // 删除设备
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelUnbound(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },

  }
}
</script>

<style>
</style>