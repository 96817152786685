<template>
  <div>
    <!-- 现场维修设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <!-- 表单 -->
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item :label="$t('newServiceConfirmationForm.dialog2.label1')">
          <div class="label-wrap">
            <div class="add-box">
              <el-button
                icon="el-icon-plus"
                @click="IssueKeywordVisible = true"
              ></el-button>
            </div>
            <div class="tag-box">
              <el-tag
                v-for="(item,index) in tags"
                :key="index"
                closable
                @close="tagClose(index)"
              >
                {{item.description}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog2.label2')"
          prop="faultDescription"
        >
          <el-input
            type="textarea"
            v-model="dialog.faultDescription"
            :rows="3"
          ></el-input>
        </el-form-item>
        <div class="switch-wrap">
          <span>{{$t('newServiceConfirmationForm.dialog2.label3')}}</span>
          <div class="switch-box">
            <span>{{$t('newServiceConfirmationForm.dialog2.label4')}}</span>
            <el-switch
              v-model="dialog.isReplace"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div class="switch-box">
            <span>{{$t('newServiceConfirmationForm.dialog2.label5')}}</span>
            <el-switch
              v-model="dialog.isRemoval"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
        </div>
        <el-form-item prop="handleMethod">
          <el-input
            type="textarea"
            v-model="dialog.handleMethod"
            :rows="3"
          ></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label6')"
              prop="deviceInfoName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.deviceInfoName"
                  class="search-input"
                  @change="deviceChange"
                ></el-input>
                <el-button @click="EquipmentDialogVisible = true">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label7')"
              prop="installationSite"
            >
              <my-select
                :data="locations"
                :value.sync="dialog.installationSite"
                size="small"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="deviceChange"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label8')"
              prop="deviceInfoModel"
            >
              <el-input
                v-model="dialog.deviceInfoModel"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label9')"
              prop="serialNumber"
            >
              <el-input
                v-model="dialog.serialNumber"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label10')"
              prop="qrCode"
            >
              <el-input
                v-model="dialog.qrCode"
                @change="qrCodeChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label11')"
              prop="installationNumber"
            >
              <el-input v-model="dialog.installationNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog2.label12')"
              prop="attributable"
            >
              <el-select v-model="dialog.attributable">
                <el-option
                  :value="0"
                  :label="$t('newServiceConfirmationForm.dialog2.option1')"
                ></el-option>
                <el-option
                  :value="1"
                  :label="$t('newServiceConfirmationForm.dialog2.option2')"
                ></el-option>
                <el-option
                  :value="2"
                  :label="$t('newServiceConfirmationForm.dialog2.option3')"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="label-box">
          <div class="label">{{$t('newServiceConfirmationForm.dialog2.label13')}}</div>
          <div class="value"> {{dialog.systemTypeName}}</div>
        </div>
        <div class="label-box">
          <div class="label">{{$t('newServiceConfirmationForm.dialog2.label14')}}</div>
          <div class="value"> {{dialog.deviceInType}}</div>
        </div>
        <div class="label-box">
          <div class="label">{{$t('newServiceConfirmationForm.dialog2.label15')}}</div>
          <div class="value"> {{dialog.mainType}}</div>
        </div>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          :disabled="!dialog.isReplace && !dialog.isRemoval"
          size="medium"
          @click="showNext"
        >{{$t('newServiceConfirmationForm.dialog2.btn1')}}</el-button>
        <el-button
          size="medium"
          @click="copySubmit"
        >{{$t('newServiceConfirmationForm.dialog2.btn2')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :branch-id="branchId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <!-- 更换设备 -->
    <replace-equipment-dialog
      :visible.sync="ReplaceEquipmentVisble"
      :data="dialog"
      :describe="describe"
      :dispose="dispose"
      :isNext="true"
      :tags-data="tags"
      :branch-id="branchId"
      @submit="replaceSubmit"
    ></replace-equipment-dialog>
    <!-- 拆除/搬离设备 -->
    <demolition-equipment-dialog
      :visible.sync="DemolitionEquipmentVisble"
      :data="dialog"
      :describe="describe"
      :dispose="dispose"
      :isNext="true"
      :tags-data="tags"
      :branch-id="branchId"
      @submit="demolitionSubmit"
    ></demolition-equipment-dialog>
    <!--  -->
    <issue-keyword
      :visible.sync="IssueKeywordVisible"
      :tags-data="tags"
      :describe="describe"
      :dispose="dispose"
      @submit="issueSubmit"
    ></issue-keyword>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 组件
import EquipmentDialog from './EquipmentDialog'
import ReplaceEquipmentDialog from './ReplaceEquipmentDialog'
import DemolitionEquipmentDialog from './DemolitionEquipmentDialog'
import IssueKeyword from './IssueKeyword'
import MySelect from '@/components/MySelect'

// 接口
import { GetboundList2 } from '@/api/warehouse/dataMaintenance'
import { GetLocationAll } from '@/api/warehouse/deviceManagement'

export default {
  components: {
    EquipmentDialog,
    ReplaceEquipmentDialog,
    DemolitionEquipmentDialog,
    IssueKeyword,
    MySelect,
  },
  props: ['visible', 'data', 'describe', 'dispose', 'branchId'],
  data () {
    return {
      EquipmentDialogVisible: false,
      ReplaceEquipmentVisble: false,
      DemolitionEquipmentVisble: false,
      IssueKeywordVisible: false,
      tags: [],
      locations: [],
      dialog: {
        visible: false,
        // visible: this.visible,
        loading: false,
        title: '',
        maintenanceDeviceId: '',
        qrCode: '',
        deviceInfoName: '',
        faultDescription: '',
        handleMethod: '',
        deviceTypeId: '',
        deviceTypeName: '',
        systemType: '',
        deviceInfoModel: '',
        serialNumber: '',
        installationSite: '',
        installationNumber: '',
        attributable: 0,
        faultTypeName: '',
        isReplace: false,
        isRemoval: false,
        systemTypeName: 'Unknown',
        deviceInType: 'Unknown',
        mainType: 'Unknown',
        faultTypeItemList: [],
      },
      rules: {
        deviceInfoName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        installationSite: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      faultTypeName: '',
    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.isReplace = false;
        this.dialog.isRemoval = false;
        this.dialog.visible = this.visible;
        this.tags = [];
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, this.data)
            this.tags = !!this.data.faultTypeItemList ? JSON.parse(JSON.stringify(this.data.faultTypeItemList)) : [];

            this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog2.title2') + ` - ${this.data.rowIndex + 1}`;
            this.dialog.systemTypeName = this.dialog.systemTypeName || 'Unknown';
            this.dialog.deviceInType = this.dialog.deviceInType || 'Unknown';
            this.dialog.mainType = this.dialog.mainType || 'Unknown';
          })
          if (this.data.isDisabled === undefined) this.dialog.isDisabled = true;
        } else {
          this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog2.title1');
          this.dialog.isDisabled = false;
          this.dialog.faultDescription = sessionStorage.faultDescription;
          this.dialog.handleMethod = sessionStorage.handleMethod;
        }
        // 获取设备位置
        GetLocationAll({ branchId: this.branchId }).then(res => this.locations = res.map(item => Object.assign({ name: item })));
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.dialog, {
        maintenanceDeviceId: data.id,
        qrCode: data.qrCode,
        deviceInfoName: data.deviceInfoName,
        deviceTypeId: data.deviceTypeId,
        deviceTypeName: data.deviceTypeName,
        serialNumber: data.serialNumber,
        installationSite: data.location,
        installationNumber: data.label,
        deviceInfoModel: data.deviceInfoModel,
      })
      this.$refs.dialogForm.validateField('deviceInfoName');
      this.dialog.isDisabled = true;

      // 获取设备关联的系统类型
      let param = {
        subField: this.dialog.deviceTypeId,
        parent: '100000000000000000000000000000000004',
      };
      GetboundList2(param).then(res => {
        if (res.length > 0) {
          this.dialog.systemTypeName = res.map(item => item.name).join(',');
        } else {
          this.dialog.systemTypeName = 'Unknown';
        }
      })
    },
    // 提交
    submit (param) {
      if (this.tags.length === 0) return this.$message.warning('Issue Keyword not null');
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        this.dialog.faultTypeItemList = this.tags;
        this.dialog.faultTypeId = this.tags.map(item => item.id ? item.id : item.description).join(',');
        this.dialog.faultTypeName = this.tags.map(item => item.description).join('、');
        this.dialog.subType = Array.from(new Set(this.tags.map(item => item.subType).filter(item => !!item))).join('、');
        this.$emit('submit', {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? 'edit' : 'add',
          copy: param.copy
        });

        sessionStorage.faultDescription = this.dialog.faultDescription;
        sessionStorage.handleMethod = this.dialog.handleMethod;
        this.dialog.visible = false;
      });
    },
    // 复制
    copySubmit () {
      this.submit({ copy: true });
    },
    // 关闭故障标签
    tagClose (index) {
      this.tags.splice(index, 1);

      this.dialog.deviceInType = Array.from(new Set(this.tags.map(item => item.deviceName).filter(item => !!item))).join(',');
      this.dialog.mainType = Array.from(new Set(this.tags.map(item => item.mainType).filter(item => !!item))).join(',');

      this.dialog.deviceInType = this.dialog.deviceInType !== '' ? this.dialog.deviceInType : 'Unknown';
      this.dialog.mainType = this.dialog.mainType !== '' ? this.dialog.mainType : 'Unknown';
    },

    // 修改设备信息，清空设备id
    deviceChange () {
      this.dialog.maintenanceDeviceId = '';
      this.dialog.qrCode = '';
      this.dialog.systemTypeName = 'Unknown';
    },
    // 修改设备信息，清空设备id
    qrCodeChange () {
      this.dialog.maintenanceDeviceId = '';
      this.dialog.systemTypeName = 'Unknown';
    },
    // 替换提交
    replaceSubmit (data) {
      this.$emit('replaceSubmit', data)
    },
    // 拆除提交
    demolitionSubmit(data){
      this.$emit('demolitionSubmit', data)
    },
    // 提交故障类型
    issueSubmit (data) {
      this.tags = data;
    },
    // 显示下一个弹框
    showNext () {
      if (this.dialog.isReplace) {
        this.ReplaceEquipmentVisble = true;
      } else if (this.dialog.isRemoval) {
        this.DemolitionEquipmentVisble = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .add-box {
    width: 100px;
  }
  .label-wrap {
    display: flex;
  }
}
.el-tag {
  margin: 0 5px 5px;
  cursor: pointer;
}

.search-box {
  .tag-box {
    min-height: calc(256px - 42px - 36px);
  }
  .label-item {
    height: 32px;
    line-height: 32px;
  }
  .el-input {
    margin-bottom: 10px;
  }
  .tag {
    background-color: #ffffff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #606266;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
    &.active {
      color: #409eff;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
    }
  }
}
.add-box {
  .label-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      width: 150px;
      padding-right: 12px;
      text-align: right;
      display: inline-block;
    }
  }
}
.align-right {
  text-align: right;
}

.switch-wrap {
  width: 100%;
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: space-between;
}
.switch-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-right: 10px;
  }
}
.label-box {
  width: 100%;
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: space-between;
}
</style>