<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('home.password.title1')"
      :visible.sync="dialog.visible"
      width="600px"
      center
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="formDialog"
      >
        <el-form-item
          :label="$t('home.password.label1')"
          prop="oldPaw"
        >
          <el-input
            v-model="dialog.oldPaw"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('home.password.label2')"
          prop="newPaw1"
        >
          <el-input
            v-model="dialog.newPaw1"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('home.password.label3')"
          prop="newPaw2"
        >
          <el-input
            v-model="dialog.newPaw2"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialog.visible = false">{{$t('operation.cancel')}}</el-button>
        <el-button
          v-loading="dialog.loading"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import sha1 from 'js-sha1'
import { EditPaw } from '@/api'
export default {
  name: 'PasswordDialog',
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        oldPaw: '',
        newPaw1: '',
        newPaw2: '',
      },
      miPasswordLength: this.$cookies.get('miPasswordLength'),

      rules: {
        oldPaw: [{ required: true, message: 'Please enter an Current password', trigger: 'blur' }],
        newPaw1: [
          { required: true, message: 'Please enter an New password', trigger: 'blur' },
        ],
        newPaw2: [
          { required: true, message: 'Please enter an Confirm new password', trigger: 'blur' },
          { validator: this.validatePaw, trigger: 'blur' }
        ],
      }
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.$refs.formDialog && this.$refs.formDialog.resetFields();
      }
    }
  },
  methods: {
    submit () {
      this.$refs.formDialog.validate((valid) => {
        if (!valid) return;
        let param = {
          srcPassword: sha1(this.dialog.oldPaw),
          newPassword: sha1(this.dialog.newPaw1),
          reNewPassword: sha1(this.dialog.newPaw2),
        }
        this.dialog.loading = true;
        EditPaw(param).then(res => {
          if (res.success) {
            let msg = this.$i18n.t('message.edit');
            this.$message.success(msg)
            this.dialog.visible = false;
          } else {
            this.$message.warning('The original password is incorrect')
          }
          this.dialog.loading = false;
        })
      });
    },
    // 验证密码
    validatePaw (rule, value, callback) {
      if (this.dialog.newPaw1 !== this.dialog.newPaw2) {
        callback(new Error('Inconsistent password'));
      } else if (this.dialog.newPaw1.length < this.miPasswordLength) {
        callback(new Error(`Password cannot be less than ${this.miPasswordLength} characters`));
      } else {
        let count = 0;
        if (/\d/.test(this.dialog.newPaw1)) count++;
        if (/[a-z]/.test(this.dialog.newPaw1)) count++;
        if (/[A-Z]/.test(this.dialog.newPaw1)) count++;
        if (/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/.test(this.dialog.newPaw1)) count++;
        if (count < 3) {
          callback(new Error('密码包含英文大写字符（A-Z）、英文小写字母（a-z）、Base 10 Digits（0-9）、特殊字符、Unicode字符中至少三个的字符'));
        } else {
          callback();
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>