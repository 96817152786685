<template>
  <div class="wrap">
    <el-row :gutter="50">
      <el-col :span="12">
        <el-form-item :label="$t('newSLAPriority.label3')">
          <el-input
            disabled
            v-model="stageTypeName"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="$t('newSLAPriority.label4')">
          <el-select v-model="data.stageId">
            <el-option
              v-for="item in stageIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="12">
        <el-form-item :label="$t('newSLAPriority.label5')">
          <el-input v-model="data.triggerName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          :label="$t('newSLAPriority.label6')"
          prop="startTimer"
        >
          <el-select v-model="data.startTimer">
            <el-option
              label="Ticket Submitted"
              :value="0"
            ></el-option>
            <el-option
              label="Ticket Confirmed"
              :value="1"
            ></el-option>
            <el-option
              label="Ticket Scheduled"
              :value="2"
            ></el-option>
            <el-option
              label="Ticket Completed"
              :value="3"
            ></el-option>
            <el-option
              label="Ticket On Hold"
              :value="4"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="12">
        <el-form-item :label="$t('newSLAPriority.label7')">
          <el-input
            type="textarea"
            :rows="3"
            v-model="data.description"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="$t('newSLAPriority.label8')">
          <el-checkbox v-model="data.stopConfirmed">Ticket Confirmed</el-checkbox>
          <el-checkbox v-model="data.stopScheduled">Ticket Scheduled</el-checkbox>
          <el-checkbox v-model="data.stopCompleted">Ticket Completed</el-checkbox>
          <el-checkbox v-model="data.stopOnHold">Ticket On Hold</el-checkbox>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :data="data.triggers"
        :height="tableHeight"
        @selection-change="val => tableSelected = val"
      >
        <el-table-column
          min-width="80"
          prop="triggerId"
          :label="$t('newSLAPriority.col1')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newSLAPriority.col2')"
        >
          <template slot-scope="scope">
            <el-select
              size="mini"
              v-model="scope.row.opHour"
            >
              <el-option
                v-for="(item,index) in opHours"
                :key="index"
                :value="index"
                :label="item"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newSLAPriority.col3')"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.slaTimer"
            ></el-input>
            <el-select
              size="mini"
              v-model="scope.row.slaTimeType"
            >
              <el-option
                v-for="(item,index) in dateTypes"
                :key="index"
                :value="index"
                :label="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newSLAPriority.col4')"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isWarning"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <el-select
              :disabled="scope.row.isWarning === 0"
              size="mini"
              v-model="scope.row.levelId"
              multiple
            >
              <el-option
                v-for="item in warnings"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <i
              class="el-icon-edit-outline"
              @click="showEdit"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newSLAPriority.col5')"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.timer"
            ></el-input>
            <el-select
              size="mini"
              v-model="scope.row.timeType"
            >
              <el-option
                v-for="(item,index) in dateTypes"
                :key="index"
                :value="index"
                :label="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          :label="$t('newSLAPriority.col6')"
        >
          <template slot-scope="scope">
            <el-select
              size="mini"
              v-model="scope.row.slaLevelId"
              multiple
            >
              <el-option
                v-for="item in escalations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <i
              class="el-icon-edit-outline"
              @click="showEdit"
            ></i>
            <!-- <el-button
                      size="mini"
                      icon="el-icon-more"
                    ></el-button> -->
          </template>
        </el-table-column>
        <el-table-column min-width="80">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="delItem(scope.row,scope.$index)"
              :disabled="data.triggers.length === 1"
            ></el-button>
            <el-button
              type="success"
              size="mini"
              icon="el-icon-plus"
              @click="addItem"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['tabActive', 'data', 'triggerId', 'warnings', 'escalations', 'policyId', 'stageTypeName'],
  data () {
    return {
      stageIds: [
        { id: 1, name: 'S01' },
        { id: 2, name: 'S02' },
        { id: 3, name: 'S03' },
        { id: 4, name: 'S04' },
        { id: 5, name: 'S05' },
      ],
      opHours: [
        '7x24 Hours',
        'Holiday Hours',
        'Holiday Working Hours',
        'Holiday Non-Working Hours',
        'Working Hours',
        'Non-Working Hours',
      ],
      dateTypes: ['Days', 'Hours', 'Mins'],
      tableHeight: null,

    }
  },
  watch: {
    'tabActive': function () {
      this.tableHeight = this.$refs.tableRow.$el.clientHeight
    }
  },

  methods: {
    // 删除
    delItem (row, index) {
      if (row.id) {
        DelSLATrigger({ id: row.id })
      }
      this.data.triggers.splice(index, 1)
    },
    // 新增
    addItem (index) {
      let stageId = this.data.id
      this.data.triggers.push(this.addTriggers(stageId))
    },
    addTriggers (stageId) {
      let item = {
        stageId: stageId,
        priorityId: '',
        opHour: 0,
        slaTimer: '',
        isWarning: 0,
        timer: '',
        slaTimeType: 0,
        timeType: 0,
        levelId: [],
        slaLevelId: [],
        triggerId: this.triggerId,
      }
      let triggerId = 'T' + (parseInt(this.triggerId.substr(1)) + 1).toString().padStart(4, '0');
      this.$emit('update:triggerId', triggerId)
      return item;
    },
    // 
    showEdit (e) {
      this.$router.push({ path: '/home/newSLAEscalationLevel', query: { id: this.policyId } });
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.table-row {
  height: calc(100% - 382px);

  ::v-deep .cell {
    display: flex;
    align-items: center;
    .el-select,
    .el-input,
    .el-switch {
      margin-right: 10px;
    }
  }
}
.switch-wrap {
  height: 100%;
  .header {
    height: 36px;
  }
  .con {
    height: calc(100% - 36px);
    overflow: auto;
  }
  .item {
    width: 100%;
    height: 36px;
    line-height: 36px;
    // float: left;
    display: flex;
  }
  .left {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .right {
    width: 100px;
  }
}
.el-icon-edit-outline {
  cursor: pointer;
}
</style>