<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="$t('dashBoard.exportReports.title1')"
    :visible.sync="dialog.visible"
    center
    width="400px"
    class="dialog"
  >
    <el-form
      :model="dialog"
      :rules="rules"
      size="medium"
    >
      <el-form-item
        :label="$t('dashBoard.exportReports.label1')"
        prop="name"
      >
        <el-input v-model="dialog.name"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('dashBoard.exportReports.label2')"
        prop="format"
      >
        <el-select v-model="dialog.format">
          <el-option value="PDF"></el-option>
          <!-- <el-option value="EXCEL"></el-option> -->
          <!-- <el-option value="CSV"></el-option> -->
          <el-option value="JPG"></el-option>
          <el-option value="PNG"></el-option>
          <!-- <el-option value="HTML"></el-option> -->
        </el-select>
      </el-form-item>
      <!-- <el-form-item >
        <div slot="label">
          <el-checkbox v-model="dialog.isEmail">EMAIL to</el-checkbox>
        </div>
        <el-input v-model="dialog.email" :disabled="!dialog.isEmail"></el-input>
      </el-form-item> -->
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="medium"
        @click="dialog.visible = false"
      >{{$t('operation.cancel')}}</el-button>
      <el-button
        size="medium"
        type="primary"
        @click="submit"
      >{{$t('operation.save')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'ExportReports',
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        name: '',
        // isTable: true,
        // isChart: true,
        format: 'PDF',
        isEmail: false,
        email: '',
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    submit () {
      this.dialog.visible = false;
      setTimeout(() => {
        this.$emit('submit', this.dialog)
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-dialog {
  .el-select {
    width: 100%;
  }
}
</style>