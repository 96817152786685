<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newProjectContract.storeDialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-input
        size="small"
        v-model="searchVal"
      ></el-input>
      <el-tree
        :data="treeData"
        :default-expanded-keys="expandedList"
        :props="defaultProps"
        :filter-node-method="filterNode"
        node-key="id"
        ref="tree"
        highlight-current
        default-expand-all
        @node-click="nodeClick"
        @node-contextmenu="nodeContextmenu"
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
      >
        <!-- draggable -->
      </el-tree>
      <p class="tips">{{$t('newProjectContract.storeDialog.label1')}}</p>
      <p class="tips">{{$t('newProjectContract.storeDialog.label2')}}</p>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <!-- :disabled="!!currentNode.childNodes && currentNode.childNodes.length !== 0" -->
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- tab-dropdown -->
    <div
      class="tab-dropdown"
      ref="tabDropdown"
      v-show="dropdownVisble"
      @click.stop
    >
      <div
        class="item"
        @click="tabDropdown(1)"
      >Add Node</div>
      <div
        class="item"
        @click="tabDropdown(2)"
      >Edit Node</div>
      <div
        class="item"
        @click="tabDropdown(3)"
      >Delete</div>
      <div
        class="item"
        @click="tabDropdown(4)"
      >Outdent</div>
      <div
        class="item"
        @click="tabDropdown(5)"
      >Indent</div>
    </div>
    <!-- 添加商店弹框 -->
    <store-group-dialog
      :visible.sync="StoreGroupDialogVisible"
      :data="storeGroupData"
      :type="storeGroupType"
      @update="getTreeData"
    ></store-group-dialog>
  </div>
</template>

<script>
// 全局方法
import { dealTree } from "@/utils/common";
// 组件
import StoreGroupDialog from "../../../stores/components/StoreGroupDialog";

// 接口
import {
  GetStoreTree,
  DelStoreNode,
  MoveStores,
} from "@/api/stores";

export default {
  components: {
    StoreGroupDialog
  },
  props: ['visible', 'company'],
  data () {
    return {
      dropdownVisble: false,
      StoreGroupDialogVisible: false,
      dialog: {
        visible: false,
        loading: false,
        title: 'Site Tree'
      },
      searchVal: '',
      treeData: [],
      currentData: {},
      currentNode: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      storeGroupType: null,
      storeGroupData: null,
      expandedList: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;
        this.getTreeData();

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    searchVal (val) {
      this.$refs.tree.filter(val);
    },
  },
  created () {
    document.body.addEventListener('click', this.clearDropdown)
  },
  destroyed () {
    document.body.removeEventListener('click', this.clearDropdown)
  },
  methods: {
    getTreeData () {
      if (!this.company) return;

      let param = {
        type: '3',
        demId: this.company,
        isAll: '1',
      };
      this.treeLoading = true;
      GetStoreTree(param).then(res => {
        dealTree(res);
        this.treeData = res;
        this.treeLoading = false;

        this.$nextTick(() => {
          this.$refs.tree.filter(this.searchVal);
        })
      }).catch(error => {
        this.treeLoading = false;
      });
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 点击树节点
    nodeClick (data, node) {
      this.currentData = data;
      this.currentNode = node;
    },
    // 添加或修改用户
    submit () {
      let nodeName = this.getNodeName();
      let data = JSON.parse(JSON.stringify(this.currentData));
      data.label = nodeName;
      this.$emit('submit', data);
      this.dialog.visible = false;
    },
    // 显示树节点弹框
    showStore (type) {
      if (this.currentData === null && type !== 1)
        return this.$message.warning("Please select one item.");

      this.storeGroupType = type;
      this.storeGroupData = this.currentData;
      this.StoreGroupDialogVisible = true;
    },
    // 删除节点
    delNode () {
      if (this.currentData === null) return this.$message.warning("Please select one item.");

      this.$confirm("Delete operation, please confirm!", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        let param = {
          id: this.currentData.id
        };
        DelStoreNode(param).then(res => {
          if (res.success) {
            this.currentData = {
              label: "",
              description: ""
            };
            this.getTreeData();
            this.$message.success(this.$i18n.t("message.del"));
          }
        });
      }).catch(() => { });
    },
    nodeContextmenu (event, data, node) {
      this.$refs.tabDropdown.style.left = event.pageX + 'px';
      this.$refs.tabDropdown.style.top = event.pageY + 'px';
      this.currentData = data;
      this.dropdownVisble = true;
    },
    // tab下拉框
    tabDropdown (command) {
      switch (command) {
        case 1:
          this.showStore(2);
          break;
        case 2:
          this.showStore(3);
          break;
        case 3:
          this.delNode();
          break;
        case 4:
          if (!this.currentData.parentId) return;
          if (this.currentNode.level === 2) return this.$message.warning('不能跳出根节点');

          let node = this.$refs.tree.getNode(this.currentData.parentId)

          MoveStores({
            id: node.data.parentId,
            storesId: this.currentData.id,
          }).then(res => this.getTreeData())
          break;
        case 5:
          if (!this.currentData.prevId) return;
          MoveStores({
            id: this.currentData.prevId,
            storesId: this.currentData.id,
          }).then(res => this.getTreeData())
          break;
      }
      this.dropdownVisble = false;

    },
    clearDropdown () {
      this.dropdownVisble = false;
    },
    nodeExpand (data) {
      this.expandedList.push(data.id); // 在节点展开是添加到默认展开数组
    },
    nodeCollapse (data) {
      this.expandedList.splice(this.expandedList.indexOf(data.id), 1); // 收起时删除数组里对应选项
    },
    getNodeName () {
      let node = this.currentNode;
      let nodeName = [];
      while (node.level !== 0) {
        nodeName.push(node.data.label)
        node = node.parent;
      }
      return nodeName.reverse().join('/');
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}

.el-tree {
  height: 500px;
  overflow: auto;
  margin-top: 10px;
}
.tab-dropdown {
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 9999;
  .item {
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }
}
.tips {
  margin-bottom: 0;
  color: #909399;
  text-align: center;
}

.el-tree ::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: #c3dfff;
}
</style>