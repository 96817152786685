<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Box Position X</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.left"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Box Position Y</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.top"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Box Width</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.width"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Box Height</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.height"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Style</label>
        <el-select
          size="small"
          v-model="elData.style.objectFit"
        >
          <el-option
            value="contain"
            label="contain"
          ></el-option>
          <el-option
            value="cover"
            label="cover"
          ></el-option>
          <el-option
            value="fill"
            label="fill"
          ></el-option>
          <el-option
            value="none"
            label="none"
          ></el-option>
          <el-option
            value="scale-down"
            label="scale-down"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <label class="input-label"></label>
        <el-button
          class="upload-btn"
          size="small"
          @click="$refs.imgFile.click()"
        >Upload</el-button>
      </el-col>
    </el-row>
    <input
      type="file"
      ref="imgFile"
      @change="uploadImage"
      hidden
    >
  </div>
</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    // 上传图片 
    uploadImage () {
      let reads = new FileReader();
      let file = this.$refs.imgFile.files[0];
      reads.readAsDataURL(file);
      reads.onload = (e) => {
        this.elData.content = e.target.result;
      };



      // let formData = new FormData();
      // let file = this.$refs.logoFile.files[0];
      // if (file === undefined) return;
      // formData.append('file', file);
      // UploadLogoImage(formData).then(res => {
      //   if (res.success) {
      //     this.logoForm.logo = res.message;
      //   }
      // })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>