<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          label="Customer Name"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          label="Descirption"
          prop="description"
        >
          <el-input
            type="textarea"
            :rows="3"
            v-model="dialog.description"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Data Access Group (Data Permissions)"
          prop="dataAccessGroup"
        >
          <el-select
            v-model="dialog.dataAccessGroup"
            filterable
            disabled
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetDataAccessGroupAll,
  AddOrganization,
} from '@/api/organizations'
export default {
  props: ["visible"],
  data () {
    return {
      dataAccessGroups: [],
      dialog: {
        visible: false,
        loading: false,
        title: 'Add New Customer',
        name: '',
        description: '',
        type: '7',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Node Name.', trigger: 'blur' }],
      },

    };
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        // 获取数据访问组
        if (this.dataAccessGroups.length === 0)
          GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
      }
    },
    "dialog.visible": function (newVal) {
      if (!newVal) {
        this.$emit("update:visible", false);
      }
    },
  },

  created () { },
  methods: {
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          description: this.dialog.description,
          // type: this.dialog.type,
          accessGroupId: this.dialog.dataAccessGroup,
        }

        this.dialog.loading = true;
        AddOrganization(param).then(res => {
          if (res.success) {
            this.$emit('submit', res.message)
            this.$message.success(this.$i18n.t('message.add'));
            this.dialog.visible = false;
          }else{
            if (res.status === 100) this.$message.warning('Customer is occupied.')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>