import request from '@/utils/request'

// 获取商店树
export const GetStoreTree = (data) => request({
  method: 'post',
  url: '/sysStores/queryAll.do',
  data: JSON.stringify(data)
})

// 添加店铺tree根节点
export const AddStoreNode = (data) => request({
  method: 'post',
  url: '/sysStores/addNode.do',
  data: JSON.stringify(data),
})

// 添加店铺tree子节点
export const AddStoreClidNode = (data) => request({
  method: 'post',
  url: '/sysStores/addChild.do',
  data: JSON.stringify(data),
})

// 修改店铺节点
export const EditStoreNode = (data) => request({
  method: 'post',
  url: '/sysStores/updateById.do',
  data: JSON.stringify(data),
})

// 删除店铺节点
export const DelStoreNode = (data) => request({
  method: 'post',
  url: '/sysStores/deleteById.do',
  data: JSON.stringify(data),
})

// 获取商店集合
export const GetStoreAll = (data) => request({
  method: 'post',
  url: '/sysStores/selectAll.do',
  data: JSON.stringify(data)
})

// 获取商店列表
export const GetStoreList = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/queryByGroupId.do',
  data: JSON.stringify(data)
})

// 获取用户下店铺集合
export const GetUserStore = (data) => request({
  method: 'post',
  url: '/sysStores/queryUserStoresAll.do',
  data: JSON.stringify(data)
})

// 获取SiteGroup列表
export const GetSiteGroupList = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/queryByPage.do',
  data: JSON.stringify(data)
})

// SiteGroup新增和修改
export const AddSiteGroup = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除SiteGroup
export const DelSiteGroup = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/delete.do',
  data: JSON.stringify(data)
})

// 设置店铺组
export const SetCategories = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/setCategories.do',
  data: JSON.stringify(data)
})

// 设置分支机构组
export const SetSiteBrand = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/setSiteBrand.do',
  data: JSON.stringify(data)
})

// 获取被选中的店铺组
export const GetCheckedStore = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/getSiteGroupIds.do',
  data: JSON.stringify(data)
})

// 获取被选中的分支机构
export const GetCheckedBrand = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/queryByLeftSite.do',
  data: JSON.stringify(data)
})

// 获取店铺组集合
export const GetSiteGroupAll = (data) => request({
  method: 'post',
  url: '/SysSiteGroup/queryByList.do',
  data: JSON.stringify(data)
})

// 搜索网点
export const SearchBrand = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/queryByList.do',
  data: JSON.stringify(data)
})

// 添加和修改网点
export const AddSite = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除网点
export const DelSite = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/deleteById.do',
  data: JSON.stringify(data)
})

// 移动网点
export const MoveSite = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/move.do',
  data: JSON.stringify(data)
})

// 获取网点id
export const GetSiteId = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/getStoreId.do',
})

// 获取网点路径
export const GetSitePaht = (data) => request({
  method: 'post',
  url: '/sysStores/nodePath.do',
  data: JSON.stringify(data)
})

// 获取用户下店铺集合
export const GetUserStore2 = (data) => request({
  method: 'post',
  url: '/sysStores/queryUserStoresAll1.do',
  data: JSON.stringify(data)
})

// 获取BU集合
export const GetBUAll = (data) => request({
  method: 'post',
  url: '/sysStores/queryStoresBusinessUnit.do',
})

// 根据网点组获取网点
export const GetStoreByGroup = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/queryByPage.do',
  data: JSON.stringify(data)
})

// 移动店铺
export const MoveStores = (data) => request({
  method: 'post',
  url: '/sysStores/move.do',
  data: JSON.stringify(data)
})

// 根据网点名称获取网点
export const GetStoreByName = (data) => request({
  method: 'post',
  url: '/sysStoreBrand/queryByCondition.do',
  data: JSON.stringify(data)
})