<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              :disabled="!permit.update"
              size="medium"
            >{{$t('operation.update')}}</el-button>
            <el-button
              :disabled="!permit.edit"
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="name"
            :label="$t('contactList.col1')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="post"
            :label="$t('contactList.col8')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="number"
            :label="$t('contactList.col9')"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            sortable
            prop="email1"
            :label="$t('contactList.col2')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="phone"
            :label="$t('contactList.col10')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="orgName"
            :label="$t('contactList.col4')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="sgName"
            :label="$t('contactList.col5')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="rgName"
            :label="$t('contactList.col6')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="date"
            :label="$t('contactList.col7')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 添加联系人弹框 -->
    <contact-dialog
      :visible.sync="ContactDialogVisible"
      :data="contactData"
      @update="getTableData"
    ></contact-dialog>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
import ContactDialog from './components/ContactDialog'

// 接口
import {
  GetContactList,
  DelContact,
} from '@/api/contactList'


export default {
  name: 'ContactList',
  components: {
    ColSet,
    ContactDialog,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        update: true,
        edit: true,
        del: true,
      },
      ContactDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      contactData: null,
    }
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetContactList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    showAdd () {
      this.contactData = null;
      this.ContactDialogVisible = true;
    },
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      if (!this.permit.edit) return;
      this.contactData = e;
      this.ContactDialogVisible = true;
    },

    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelContact(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('ContactListSearchContact');
        this.permit.add = permission.includes('ContactListAddContact');
        this.permit.update = permission.includes('ContactListUpdateSystemUser');
        this.permit.edit = permission.includes('ContactListEditContact');
        this.permit.del = permission.includes('ContactListDeleteContact');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>
