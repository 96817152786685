<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          @row-click="rowClick"
          @row-dblclick="dblclickEdit"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            sortable
            prop="name"
            :label="$t('newProjectContract.slaDialog.col1')"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="typeName"
            :label="$t('newProjectContract.slaDialog.col2')"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="dag"
            :label="$t('newProjectContract.slaDialog.col3')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetSLAPoliciesList,
} from '@/api/warehouse/slaPolicies'
export default {
  props: ["visible"],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: this.$i18n.t('newProjectContract.slaDialog.title1'),
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      currentItem: {},
    };
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        if (this.tableData.length === 0) {
          this.getTableData()
        }
      }
    },
    "dialog.visible": function (newVal) {
      if (!newVal) {
        this.$emit("update:visible", false);
      }
    },
  },

  created () { },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      };
      this.tableLoading = true;
      GetSLAPoliciesList(param).then((res) => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    rowClick (item) {
      this.currentItem = item;
    },
    dblclickEdit (item) {
      this.currentItem = item;
      this.submit();
    },
    submit () {
      this.$emit('submit', this.currentItem)
      this.dialog.visible = false;
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.id === this.currentItem.id) return 'checked-row';
      else return '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>