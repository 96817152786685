<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-tabs
        v-model="activeName"
        @tab-click="tabClick"
      >
        <el-tab-pane
          :label="$t('newProjects.tab1')"
          name="tab1"
        >
          <project-info ref="projectInfo"></project-info>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!projectsId"
          :label="$t('newProjects.tab2')"
          name="tab2"
        >
          <gantt-chart ref="ganttChart"></gantt-chart>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!projectsId"
          :label="$t('newProjects.tab3')"
          name="tab3"
        >
        </el-tab-pane>
        <el-tab-pane
          :disabled="!projectsId"
          :label="$t('newProjects.tab4')"
          name="tab4"
        >
          <documents ref="documents"></documents>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>

</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import ProjectInfo from './components/ProjectInfo'
import Documents from './components/Documents'
import GanttChart from './components/GanttChart'
export default {
  name: 'NewProjects',
  components: {
    PageHeader,
    ProjectInfo,
    Documents,
    GanttChart,
  },
  data () {
    return {
      activeName: 'tab1',
      title: '',
      loading: false,
      projectsId: null,
    }
  },
  created () {
    this.initView();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    initView () {
      this.projectsId = this.$route.query.id;
      if (!!this.projectsId) {
        this.title = this.$i18n.t('newProjects.title2');
        this.activeName = 'tab2';
      } else {
        this.title = this.$i18n.t('newProjects.title1');
      }
    },
    tabClick (e) {
      if (this.activeName === 'tab4') {
        this.$refs.documents.getTableData();
      }
    },
    submit () {
      this.$refs.projectInfo.submit();
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-tabs {
  height: calc(100% - 56px);

  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
</style>