<template>
  <div class="wrap">
    <el-card>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              class="search-input"
              size="medium"
              placeholder="Search"
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >Search</el-button>
            <el-button
              :disabled="!permit.export"
              size="medium"
            >Export</el-button>

          </el-button-group>
          <div class="tab-box">
            <el-tag effect="plain">
              Store 10
            </el-tag>
            <el-tag effect="plain">Finished 9</el-tag>
            <el-tag effect="plain">Pending 1</el-tag>

            <col-set
              @save="saveColSet"
              @updata="getTableData"
            ></col-set>
          </div>
        </div>
      </el-row>
      <el-row>
        <el-table border          :data="tableData"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="ID"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Site ID"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="First Transaction"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Last Transcation"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Current Sync Status"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Current Status of Data Processing"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
  </div>
</template>


<script>
import ColSet from '@/components/ColSet'
export default {
  name: 'DailyDataSync',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        export: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableSelected: [],
      tableData: [{}, {}, {}, {}],
    }
  },
  created () {
    this.getTableData();
    this.dealPermission();
  },
  methods: {
    getTableData () {
      let param = {}

    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    //保存ColSet 
    saveColSet (data) {
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('DailyDataSyncSearch');
        this.permit.export = permission.includes('DailyDataSyncExport');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrap {
  .tab-box {
    display: flex;
    align-items: center;

    .el-tag {
      margin-right: 10px;
    }
  }
}
</style>