<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="saveState"
          size="medium"
          type="primary"
          @click="submit(1)"
        >{{$t('operation.save')}}</el-button>
        <el-button
          v-if="confirmState"
          size="medium"
          type="primary"
          @click="confirmedSubmitRequest"
        >{{$t('newSubmitRequest.btn1')}}</el-button>
        <el-button
          v-if="sendState"
          size="medium"
          type="primary"
          @click="submit(2)"
        >{{$t('operation.saveSend')}}</el-button>
      </page-header>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('newSubmitRequest.tab1')"
          name="base"
        >
          <basic-info
            ref="basicInof"
            :info.sync="info"
          ></basic-info>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!submitRequestId"
          :label="$t('newSubmitRequest.tab2')"
          name="progress"
        >
          <progress-log
            ref="progress"
            :info="info"
          ></progress-log>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!submitRequestId"
          :label="$t('newSubmitRequest.tab3')"
          name="serviceStaff"
        >
          <service-staff
            ref="serviceStaff"
            :info.sync="info.distributionOrderList"
          ></service-staff>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!submitRequestId"
          :label="$t('newSubmitRequest.tab4')"
          name="serviceForm"
        >
          <service-form
            ref="serviceForm"
            :info.sync="info"
            @update="getItem"
          ></service-form>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!submitRequestId"
          :label="$t('newSubmitRequest.tab5')"
          name="SLATracking"
        >
          <sla-tracking
            ref="slaTracking"
            :info="info"
          ></sla-tracking>
        </el-tab-pane>
      </el-tabs>

    </el-card>

  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import BasicInfo from './components/BasicInfo'
import ProgressLog from './components/ProgressLog'
import ServiceStaff from './components/ServiceStaff'
import ServiceForm from './components/ServiceForm'
import SlaTracking from './components/SLATracking'
// 接口
import {
  AddSubmitRequest,
  GetDistributeInfo,
  ConfirmedSubmitRequest,
} from '@/api/warehouse/submitRequest'
export default {
  name: 'NewSubmitRequest',
  components: {
    PageHeader,
    BasicInfo,
    ProgressLog,
    ServiceStaff,
    ServiceForm,
    SlaTracking,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      title: '',
      viewType: null,
      activeName: 'base',
      statusId: '',
      info: {},
      submitRequestId: null,
    }
  },
  computed: {
    saveState: function () {
      if (!this.submitRequestId) {
        return true;
      } else if (this.permit.edit) {
        return (this.statusId === '00000000000000000000000000000000001' && this.viewType == 1) || this.viewType == 2;
      }
    },
    confirmState: function () {
      if (!this.submitRequestId) {
        return this.statusId === '00000000000000000000000000000000001' && this.viewType == 2;
      } else if (this.permit.edit) {
        return this.statusId === '00000000000000000000000000000000001' && this.viewType == 2;
      }
    },
    sendState: function () {
      if (!this.submitRequestId) {
        return this.viewType == 2;
      } else if (this.permit.edit) {
        return this.viewType == 2;
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    if (from.name === 'NewServiceConfirmationForm') {
      next(vm => {
        vm.activeName = 'serviceForm'
      });
    } else {
      next();
    }
  },
  created () {
    this.dealPermission();
    this.initView();
  },

  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    initView () {
      this.submitRequestId = this.$route.query.id;
      this.viewType = Number(this.$route.query.type);
      switch (this.viewType) {
        case 1:
          // 提交工单
          if (this.$route.query.id) {
            this.title = this.permit.edit ? this.$i18n.t('newSubmitRequest.title2') : this.$i18n.t('newSubmitRequest.title3');
            this.getItem();
          } else {
            this.title = this.$i18n.t('newSubmitRequest.title');
          }
          break;
        case 2:
          if (this.$route.query.id) {
            this.title = this.permit.edit ? this.$i18n.t('newServicesScheduling.title2') : this.$i18n.t('newServicesScheduling.title3');
            this.getItem();
          } else {
            this.title = this.$i18n.t('newServicesScheduling.title');
          }
          // 工单调度
          break;
        case 3:
          // 投诉与评价
          this.title = this.$i18n.t('newSubmitRequest.title3');
          this.getItem();
          break;
      }
    },
    // 获取服务单信息
    getItem () {
      let param = { id: this.$route.query.id };

      GetDistributeInfo(param).then(res => {
        this.info = res;
        this.$nextTick(() => {
          this.$refs.basicInof.initData();
          this.$refs.progress.initData();
          this.$refs.serviceStaff.initData();
          this.$refs.serviceForm.initData();
          this.$refs.slaTracking.initData();
        })
        this.statusId = res.statusId;
      });
    },
    // 提交
    submit (type) {
      this.$refs.serviceStaff.submit();
      this.$refs.basicInof.submit(type);
    },
    // 确认服务单 
    confirmedSubmitRequest () {
      let param = { id: this.$route.query.id };

      ConfirmedSubmitRequest(param).then(res => {
        if (res.success) {
          this.$message.success('Service order confirmation')
          this.statusId = '00000000000000000000000000000000005'
        }
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        switch (Number(this.$route.query.type)) {
          case 1:
            this.permit.edit = permission.includes('SubmitaRequestEdit');
            break;
          case 2:
            this.permit.edit = permission.includes('WorkOrderSchedulingEdit');
            break;
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .el-date-editor,
  .el-cascader,
  .el-select {
    width: 100%;
  }
}
</style>