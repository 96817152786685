import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  filterData: {},
  condition: {},
  checkListData: {},
  orderContract: {
    id: '',
    templateId: 'default',
    attachment: [],
  },
  orderOriginData: {
    data:null,
    tableData:[],
    tableData2:[],
  },
}

const getters = {

}

const mutations = {
  setFilterData: (state, param) => {
    state.filterData[param.key] = {
      type: param.type,
      data: param.data
    }
  },
  removeFilterItem: (state, prop) => {
    delete state.filterData[prop]
  },
  clearFilterData: (state) => state.filterData = {},
  setCondition: (state, param) => {
    state.condition[param.key] = {
      type: param.type,
      data: param.data
    }
  },
  removeCondition: (state, prop) => {
    delete state.condition[prop]
  },
  clearCondition: (state) => state.condition = {},
}
export default new Vuex.Store({
  state,
  getters,
  mutations,
})
