<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newOrder.orderTracking.updateState.title1')"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item prop="type">
          <el-radio-group
            v-model="dialog.type"
            @change="statusTypeChange"
          >
            <el-radio :label="1">{{$t('newOrder.orderTracking.updateState.radio1')}}</el-radio>
            <el-radio :label="2">{{$t('newOrder.orderTracking.updateState.radio2')}}</el-radio>
            <el-radio :label="3">{{$t('newOrder.orderTracking.updateState.radio3')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="dialog.type === 1">
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label1')"
            prop="paymentStatus"
          >
            <el-select v-model="dialog.paymentStatus">
              <el-option
                :value="1"
                label="已付全款"
              ></el-option>
              <el-option
                :value="2"
                label="已部分付款"
              ></el-option>
              <el-option
                :value="3"
                label="待付款"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label2')"
            prop="paymentDate"
          >
            <el-date-picker
              v-model="dialog.paymentDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label3')"
            prop="paymentTotal"
          >
            <el-input v-model="dialog.paymentTotal"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label4')"
            prop="paidMoney"
          >
            <el-input v-model="dialog.paidMoney"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label5')"
            prop="unpaidMoney"
          >
            <el-input v-model="dialog.unpaidMoney"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label6')"
            prop="periodMoney"
          >
            <el-input v-model="dialog.periodMoney"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label7')"
            prop="paymentRemark"
          >
            <el-input v-model="dialog.paymentRemark"></el-input>
          </el-form-item>
        </div>
        <div v-else-if="dialog.type === 2">
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label8')"
            prop="orderStatus"
          >
            <el-select v-model="dialog.orderStatus">
              <el-option
                :value="1"
                label="已订货"
              ></el-option>
              <el-option
                :value="2"
                label="已部分订货"
              ></el-option>
              <el-option
                :value="3"
                label="待订货"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label9')"
            prop="orderDate"
          >
            <el-date-picker
              v-model="dialog.orderDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label10')"
            prop="expectedDate"
          >
            <el-date-picker
              v-model="dialog.expectedDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label11')"
            prop="orderRemark"
          >
            <el-input v-model="dialog.orderRemark">
            </el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label12')"
            prop="arrivalStatus"
          >
            <el-select v-model="dialog.arrivalStatus">
              <el-option
                :value="1"
                label="已全部到货"
              ></el-option>
              <el-option
                :value="2"
                label="已部分到货"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label13')"
            prop="arrivalDate"
          >
            <el-date-picker
              v-model="dialog.arrivalDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label14')"
            prop="expectedArrivalDate"
            value-format="yyyy-MM-dd"
          >
            <el-date-picker
              v-model="dialog.expectedArrivalDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('newOrder.orderTracking.updateState.label15')"
            prop="arrivalRemark"
          >
            <el-input v-model="dialog.arrivalRemark">
            </el-input>
          </el-form-item>
        </div>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  AddPaymentStatus,
  AddOrdersStatus,
  AddArrivalStatus,
} from '@/api/warehouse/purchaseOrders'
export default {

  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        id: null,
        type: 1,
        paymentStatus: 1,
        paymentDate: null,
        paymentTotal: '',
        paidMoney: '',
        unpaidMoney: '',
        periodMoney: '',
        paymentRemark: '',

        orderStatus: 1,
        orderDate: null,
        expectedDate: null,
        orderRemark: '',

        arrivalStatus: 1,
        arrivalDate: null,
        expectedArrivalDate: null,
        arrivalRemark: '',
      },
      rules: {
        paymentDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        paymentTotal: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        paidMoney: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        unpaidMoney: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        periodMoney: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        orderDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        expectedDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        arrivalDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        expectedArrivalDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {

        } else {

        }

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {

    // 提交
    submit () {
      if (!this.$route.query.id) return this.$message.warning(this.$i18n.t('newOrder.orderTracking.updateState.message1'))
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        switch (this.dialog.type) {
          case 1:
            this.paymentSubmit();
            break;
          case 2:
            this.orderSubmit();
            break;
          case 3:
            this.arrivalSubmit();
            break;
        }
      });
    },
    // 付款
    paymentSubmit () {
      let param = {
        orderFormId: this.$route.query.id,
        paymentStatus: this.dialog.paymentStatus,
        paymentDate: this.dialog.paymentDate,
        totalPrice: this.dialog.paymentTotal,
        paid: this.dialog.paidMoney,
        unpaid: this.dialog.unpaidMoney,
        currentPayment: this.dialog.periodMoney,
        remake: this.dialog.paymentRemark,
      }
      let msg = this.$i18n.t('message.add');
      if (this.dialog.id) {
        param.id = this.dialog.id;
        msg = this.$i18n.t('message.edit');
      }

      this.dialog.loading = true;
      AddPaymentStatus(param).then(res => {
        if (res.success) {
          this.$message.success(msg)
          this.dialog.visible = false;
          this.$emit('update')
        }
        this.dialog.loading = false;
      }).catch(error => {
        this.dialog.loading = false;
      })
    },
    // 订单
    orderSubmit () {
      let param = {
        orderFormId: this.$route.query.id,
        orderStatus: this.dialog.orderStatus,
        orderDate: this.dialog.orderDate,
        arrivalDate: this.dialog.expectedDate,
        remake: this.dialog.orderRemark,
      }
      let msg = this.$i18n.t('message.add');
      if (this.dialog.id) {
        param.id = this.dialog.id;
        msg = this.$i18n.t('message.edit');
      }

      this.dialog.loading = true;
      AddOrdersStatus(param).then(res => {
        if (res.success) {
          this.$message.success(msg)
          this.dialog.visible = false;
          this.$emit('update')
        }
        this.dialog.loading = false;
      }).catch(error => {
        this.dialog.loading = false;
      })
    },
    // 到货
    arrivalSubmit () {
      let param = {
        orderFormId: this.$route.query.id,
        arrivalStatus: this.dialog.arrivalStatus,
        arrivalDate: this.dialog.arrivalDate,
        expectedDate: this.dialog.expectedArrivalDate,
        remake: this.dialog.arrivalRemark,
      }
      let msg = this.$i18n.t('message.add');
      if (this.dialog.id) {
        param.form.id = this.dialog.id;
        msg = this.$i18n.t('message.edit');
      }

      this.dialog.loading = true;
      AddArrivalStatus(param).then(res => {
        if (res.success) {
          this.$message.success(msg)
          this.dialog.visible = false;
          this.$emit('update')
        }
        this.dialog.loading = false;
      }).catch(error => {
        this.dialog.loading = false;
      })
    },
    // 改变状态类型
    statusTypeChange () {
      let fields = [
        'paymentStatus',
        'paymentDate',
        'paymentTotal',
        'paidMoney',
        'unpaidMoney',
        'periodMoney',
        'orderDate',
        'expectedDate',
        'arrivalDate',
        'expectedArrivalDate',
      ]
      this.$refs.dialogForm.clearValidate(fields);
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>