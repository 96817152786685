import request from '@/utils/request'

// 保存
export const SavePdfTemplate = (data) => request({
  method: 'post',
  url: '/ElementTemplate/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelPdfTemplate = (data) => request({
  method: 'post',
  url: '/ElementTemplate/del.do',
  data: JSON.stringify(data)
})

// 获取模板
export const GetPdfList = (data) => request({
  method: 'post',
  url: '/ElementTemplate/queryByElementId.do',
  data: JSON.stringify(data)
})

// 修改状态
export const EditPdfTemplateState = (data) => request({
  method: 'post',
  url: '/ElementTemplate/updateEnable.do',
  data: JSON.stringify(data)
})
