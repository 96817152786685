<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-tabs v-model="tabActive">
        <el-tab-pane
          :label="$t('purchaseRequisition.tab1')"
          name="tab1"
        >
          <application-form :active="tabActive"></application-form>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('purchaseRequisition.tab2')"
          name="tab2"
        >
          <state-tracking :active="tabActive"></state-tracking>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import ApplicationForm from './components/ApplicationForm'
import StateTracking from './components/StateTracking'

export default {
  name: 'PurchaseRequisition',
  components: {
    ApplicationForm,
    StateTracking,
  },
  data () {
    return {
      tabActive: 'tab1',
    }
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>