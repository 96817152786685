<template>
  <div>
    <el-row>
      <el-table
        border
        :data="tableData"
        v-loading="tableLoading"
        ref="table"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
      >
        <!-- @row-dblclick="dblclickEdit" -->
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="sataus"
          :label="$t('newQuotation.col1')"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="success"
              v-if="scope.row.sataus == 1"
            >{{$t('newQuotation.tag3')}}</el-tag>
            <el-tag
              size="small"
              type="danger"
              v-else
            >{{$t('newQuotation.tag4')}}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column
            prop="createTime"
            :label="$t('newQuotation.col2')"
            sortable
          >
          </el-table-column> -->
        <!-- <el-table-column
          prop="inquiryDate"
          :label="$t('newQuotation.col13')"
          sortable
        >
        </el-table-column> -->
        <el-table-column
          prop="supplierName"
          :label="$t('newQuotation.col3')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="contactName"
          label="联系人"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="phoneNumber"
          :label="$t('newQuotation.col4')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="currency"
          :label="$t('newQuotation.col5')"
          sortable
        >
          <template slot-scope="scope">
            {{currencys[scope.row.currency]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="untilPrice"
          :label="$t('newQuotation.col6')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.untilPrice.toFixed(decimals)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          :label="$t('newQuotation.col7')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="projectName"
          :label="$t('newQuotation.col8')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="taxes"
          :label="$t('newQuotation.col9')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.taxes.toFixed(decimals)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="discount"
          :label="$t('newQuotation.col10')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.discount.toFixed(decimals)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="expireDate"
          :label="$t('newQuotation.col11')"
          sortable
        >
        </el-table-column>
        <el-table-column :label="$t('newQuotation.col12')">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click.stop="previewImage(scope.row.attachments)"
            >View</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="preview.visible"
      :on-close="previewClose"
      :url-list="preview.imageUrl"
    ></el-image-viewer>
  </div>
</template>

<script>

// 组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// 接口
import {
  GetQuotationDetailsList,
  DelQuotation,
  CopyQuotation,
} from '@/api/warehouse/quotation'
export default {
  components: {
    ElImageViewer,
  },
  props: ['permit', 'data', 'total', 'isShowMoney'],
  data () {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      decimals: localStorage.quotationDecimals || 2,
      currencys: ['人民币', '美元', '港币', '泰铢', '迪拉姆'],
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      preview: {
        visible: false,
        imageUrl: '',
      },
    }
  },
  watch: {
    'data': function (newVal) {
      this.tableData = newVal;
      this.page.total = this.total;
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      this.$emit('tableChange', this.page.size, this.page.current);
    },

    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newQuote', query: { type: 2 } })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      localStorage.setItem('userData', JSON.stringify(e));
      this.$router.push({ path: '/home/newQuote', query: { id: e.id, type: 2 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelQuotation(this.tableSelected[0].id).then(res => {
          if (res) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 复制 
    copyItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      CopyQuotation(this.tableSelected[0].id).then(res => {
        if (res) {
          this.$message.success(this.$i18n.t('message.copy'));
          this.getTableData();
        }
      })
    },

    // 图片预览
    previewImage (files) {
      if (files.length === 0) return this.$message.warning('没有附件')
      this.preview.imageUrl = files.split(',').map(item => `${this.baseUrl}/Export/download.do?fileName=${item}`);
      this.preview.visible = true;
    },
    previewClose () {
      this.preview.visible = false;
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
  },
}
</script>

<style>
</style>