<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="商品查找"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            label="商品名称"
            prop="itemName"
          ></el-table-column>
          <el-table-column
            label="品牌"
            prop="brand"
          ></el-table-column>
          <el-table-column
            label="规格型号"
            prop="model"
          ></el-table-column>
          <el-table-column
            label="设备类型"
            prop="type"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetRelevanceDeviveList } from '@/api/warehouse/quotation'

export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialogVisible: false,
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.searchData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        key: this.searchVal,
        id: this.data.infoId,
        itemName: this.data.itemName,
        model: this.data.model,
        type: this.data.type,
        brand: this.data.brand,

      }
      this.tableLoading = true;
      GetRelevanceDeviveList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交
    submit () {
      this.$emit('submit', this.tableSelected);
      this.dialogVisible = false;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>