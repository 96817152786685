<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <div class="left-box">
            <el-date-picker
              size="medium"
              v-model="dateRange"
              type="daterange"
              :picker-options="pickerOptions"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="searchData"
            >
            </el-date-picker>
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
            </el-button-group>
          </div>
          <div>
            <span>{{$t('submitRequest.status')}}：</span>
            <el-select
              v-model="state"
              size="medium"
              @change="searchData"
              multiple
              filterable
            >
              <el-option
                value=""
                label="All"
              ></el-option>
              <el-option
                v-for="item in states"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </div>
      </el-row>
      <el-row>
        <el-button-group class="input-group">
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="complaints"
          >{{$t('operation.complaint')}}</el-button>
          <el-button
            :disabled="!permit.repeal"
            size="medium"
            @click="cancel"
          >{{$t('operation.repeal')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
          @row-dblclick="dblclickEdit"
          :default-sort="{prop: 'createTime', order: 'descending'}"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            prop="createTime"
            :label="$t('submitRequest.col1')"
            sortable
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            prop="computeTime"
            :label="$t('submitRequest.col2')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            prop="priorityName"
            label="Priority"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="cityName"
            :label="$t('submitRequest.col3')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="branchName"
            :label="$t('submitRequest.col4')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="description"
            :label="$t('submitRequest.col5')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="engineerNames"
            :label="$t('submitRequest.col7')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="serviceTypeName"
            :label="$t('submitRequest.col8')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="120"
            prop="statusName"
            :label="$t('submitRequest.col9')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 投诉 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('submitRequest.complaint.title')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <p class="comment">{{$t('submitRequest.complaint.content')}}</p>
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item prop="comment">
          <el-input
            type="textarea"
            v-model="dialog.comment"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="complaintSubmit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { computeTime, formatUTCDate } from '@/utils/common'
// 接口
import {
  GetSubmitRequestList,
  RevokeSubmitRequest,
  Complaint,
} from '@/api/warehouse/submitRequest'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'SubmitRequest',
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
        repeal: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      states: [],
      searchVal: '',
      dateRange: null,
      state: [],
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      dialog: {
        loading: false,
        visible: false,
        comment: '',
      },
      rules: {
        comment: [{ required: true, message: this.$i18n.t('submitRequest.complaint.message1'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'SubmitRequest') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
      // 获取完成状态
    GetDataFieldAll({ parent: '100000000000000000000000000000000007' }).then(res => this.states = res)
    
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        statusId: this.state.find(item => item === 'all') ? this.states.map(item => item.id).join(',') : this.state.join(','),
        date: this.dateRange !== null ? `${formatUTCDate(this.dateRange[0])}~${formatUTCDate(this.dateRange[1] + ' 23:59:59')}` : '',
      }
      this.tableLoading = true;
      GetSubmitRequestList(param).then(res => {
        let noStatus = ['00000000000000000000000000000000002', '00000000000000000000000000000000004', '00000000000000000000000000000000006', '00000000000000000000000000000000007'];

        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        this.tableData.forEach((item, index) => {
          if (noStatus.includes(item.statusId))
            this.$set(this.tableData[index], 'computeTime', computeTime(item.createTime, item.updateTime));
          else {
            this.$set(this.tableData[index], 'computeTime', computeTime(item.createTime));
          }
        });
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newSubmitRequest', query: { type: 1 } })
    },
    // 显示编辑
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newSubmitRequest', query: { id: e.id, type: 1 } })
    },
    // 投诉
    complaints () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.visible = true;
    },
    // 提交投诉
    complaintSubmit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let parma = {
          workOrderId: this.tableSelected.map(item => item.id).join(','),
          content: this.dialog.comment,
        };
        this.dialog.loading = true;
        Complaint(parma).then(res => {
          if (res.success) {
            this.dialog.visible = false;
            this.dialog.loading = false;
            this.$message.success(this.$i18n.t('message.save'))
          }
        })
      })
    },
    // 撤销
    cancel () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Confirm the cancellation of the selected service order', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          workOrderId: this.tableSelected.map(item => item.id).join(','),
          revokeReason: '',
        }
        RevokeSubmitRequest(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.cancel'))

          }
        })
      }).catch(() => { });
    },

    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('SubmitaRequestSearch1');
        this.permit.add = permission.includes('SubmitaRequestAdd');
        // this.permit.edit = permission.includes('SubmitaRequestEdit');
        this.permit.del = permission.includes('SubmitaRequestDelete');
        this.permit.repeal = permission.includes('SubmitaRequestCancelOrder');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.comment {
  text-align: center;
}
.left-box {
  display: flex;
  .el-date-editor {
    margin-right: 10px;
  }
}

.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 46px - 42px);
}
</style>
