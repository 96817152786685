<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="500px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        class="dialog-form"
        size="small"
        label-width="200px"
      >
        <el-form-item label="可用库存/预分配/已入库">
          <div class="display-flex">
            <el-input
              disabled
              v-model="dialog.inventoryCount"
            ></el-input>
            <el-input
              disabled
              v-model="dialog.allPreCount"
            ></el-input>
            <el-input
              disabled
              v-model="dialog.inCount"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('addOrder.label4')">
          <div class="display-flex">
            <el-input
              disabled
              v-model="dialog.outCount"
            ></el-input>
            <el-input
              disabled
              v-model="dialog.outCount2"
            ></el-input>
            <el-input
              disabled
              v-model="dialog.outCount3"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('addOrder.label1')">
          <el-input
            disabled
            v-model="dialog.appCount"
          ></el-input>
        </el-form-item>
        <el-form-item label="已预分配库存数量">
          <el-input
            disabled
            v-model="dialog.preCount"
          ></el-input>
        </el-form-item>
        <el-form-item label="已订货数量">
          <el-input
            disabled
            v-model="dialog.orderCount"
          ></el-input>
        </el-form-item>
        <el-form-item label="待订货数量">
          <el-input
            v-model="dialog.unorderCount"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="本次入库数量">
          <el-input-number
            :controls="false"
            :max="dialog.numberMax"
            :min="0"
            v-model="dialog.deviceCount"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="入库地址"
          prop="inOrgId"
        >
          <el-select
            v-model="dialog.inOrgId"
            filterable
          >
            <el-option
              v-for="item in inWarehouseSites"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="入库方式"
          prop="inTypeId"
        >
          <el-select
            v-model="dialog.inTypeId"
            filterable
            size="small"
          >
            <el-option
              v-for="item in inWarehouseTypes"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库位置">
          <el-input v-model="dialog.warehouseLocation"></el-input>
        </el-form-item>
        <el-form-item label="是否有序列号">
          <el-radio-group v-model="dialog.isHasSerialNumber">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="新设备">
          <el-radio-group v-model="dialog.isNewDevice">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="锁定">
          <el-radio-group v-model="dialog.isLock">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="借用厂家设备">
          <el-radio-group
            v-model="dialog.isBorrowFactoryDevice"
            @change="isBorrowChange"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="归还日期">
          <el-date-picker
            v-model="dialog.returnDate"
            type="date"
            :disabled="dialog.isBorrowFactoryDevice === 0"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="入库备注">
          <el-input
            type="textarea"
            v-model="dialog.inRemark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口

export default {
  props: ['visible', 'data',
    'inWarehouseSites',
    'inWarehouseTypes',
  ],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '库存详情',
        deviceCount: 0,
        orderCount: 0,
        numberMax: 0,
        inOrgId: '',
        inTypeId: '',
        warehouseLocation: '',
        unorderCount: 0,
        isHasSerialNumber: 1,
        isNewDevice: 1,
        isLock: 0,
        isBorrowFactoryDevice: 0,
        returnDate: null,
        inRemark: '',
        serialNumbers: [],
      },
      rules: {
        inOrgId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        inTypeId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        Object.assign(this.dialog, this.data);
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 
    isBorrowChange () {
      this.dialog.returnDate = null;
    },
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        this.$emit('submit', this.dialog);
        this.dialog.visible = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {

  .el-input-number {
    width: 100%;
  }
}

.display-flex {
  display: flex;

  .el-input:first-child,
  .el-input-number:first-child {
    margin-left: 0px;
  }
  .el-input,
  .el-input-number {
    margin-left: 10px;
  }
}
</style>