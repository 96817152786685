<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="branch.type !== 'brand'"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="createCopy"
          >{{$t('maintenance.maintenance.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="showCopy"
          >{{$t('maintenance.maintenance.btn2')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          sortable
          :label="$t('maintenance.maintenance.col1')"
          prop="name"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          sortable
          :label="$t('maintenance.maintenance.col2')"
          prop="siteName"
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          :label="$t('maintenance.maintenance.col3')"
          prop="opStatus"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click.stop="showCheckList(scope.row)"
            >{{$t('operation.edit')}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          sortable
          :label="$t('maintenance.maintenance.col4')"
          prop="deviceInfoModel"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status"
              type="success"
              size="small"
            >Enable</el-tag>
            <el-tag
              v-else
              type="danger"
              size="small"
            >Disable</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          :label="$t('maintenance.maintenance.col5')"
        >
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.templateEnable"
              :key="index"
              class="cell-style"
            >
              <span>
                {{item.name}}
              </span>
              <el-button
                size="mini"
                @click="printItem(item)"
              >{{$t('operation.print')}}</el-button>
              <!-- <el-button
                size="mini"
                @click="exportItem(item)"
              >{{$t('operation.export')}}</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!--  -->
    <add-file
      :visible.sync="AddFileVisible"
      :data="fileData"
      :is-copy="isCopy"
      :branch="branch"
      @update="getTableData"
    ></add-file>

  </div>
</template>

<script>

// 组件
import AddFile from './AddFile'
// 接口
import {
  GetMaintenanceFileList,
  CopyMaintenanceFile,
  DelMaintenanceFile,
} from '@/api/warehouse/maintenance'
export default {
  components: {
    AddFile
  },
  props: ['active', 'branch'],
  data () {
    return {
      permit: {

      },
      AddFileVisible: false,
      baseURL: process.env.VUE_APP_BASE_URL,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      userData: null,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      fileData: null,
      isCopy: false,
    }
  },
  watch: {
    'branch': function () {
      if (this.active === 'Maintenance') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
    'active': function () {
      if (this.active === 'Maintenance') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
    '$route': function (to, from) {
      if (to.name === 'Maintenance' && this.active === 'Maintenance') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);

        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });

      }
    },
  },
  created () {
    this.dealPermission();
  },
  mounted () {
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        branchId: this.branch.id
      }
      this.tableLoading = true;
      GetMaintenanceFileList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.templateEnable = item.templateList.filter(item => !!item.isEnable)
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.isCopy = false;
      this.fileData = null;
      this.AddFileVisible = true;
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0])
    },
    dblclickEdit (e) {
      this.isCopy = false;
      this.fileData = e;
      this.AddFileVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelMaintenanceFile(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 显示复制弹框
    showCopy () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.isCopy = true;
      this.fileData = this.tableSelected[0];
      this.AddFileVisible = true;
    },
    // 复制
    createCopy () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let param = {
        srcId: this.tableSelected[0].id
      }
      CopyMaintenanceFile(param).then(res => {
        if (res.success) {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.copy'))
        }
      })
    },
    // 进入维保检查表
    showCheckList (row) {
      sessionStorage.checkOrgId = row.orgId;
      this.$router.push({ path: '/home/maintenanceCheckList', query: { id: row.id, fileId: row.id, type: 0, orgId: row.orgId } })
    },

    // 打印
    printItem (item) {
      sessionStorage.pdfData = item.template;
      let { href } = this.$router.resolve({ path: `/pdf` });
      window.open(href, '_blank');
    },
    // 导出
    exportItem () {

    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        // this.permit.search = permission.includes('DeviceManagementSearch');
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.cell-style {
  width: 100%;
  height: 28px;
  span {
    width: 100px;
    height: 28px;
    line-height: 28px;

    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-button {
    vertical-align: top;
  }
}
</style>
