import request from '@/utils/request'

// 查询角色列表
export const GetRoleList = (data) => request({
  method: 'post',
  url: '/sysRole/queryByPage.do',
  data: JSON.stringify(data),
})

// 角色添加和修改
export const AddRole = (data) => request({
  method: 'post',
  url: '/sysRole/addOrUpdate.do',
  data: JSON.stringify(data),
})
// 角色删除
export const DelRole = (data) => request({
  method: 'post',
  url: '/sysRole/deleteById.do',
  data: JSON.stringify(data),
})

// 获取导航栏
export const GetMenuTree = (data) => request({
  method: 'post',
  url: '/sysSubFunction/queryAll.do',
  data: JSON.stringify(data),
})

// 获取角色导航栏权限 废弃
export const GetRoleMenuPermissions = (data) => request({
  method: 'post',
  url: '/sysSubFunction/queryByRoleId.do',
  data: JSON.stringify(data),
})

// 获取角色具体权限
export const GetRolePermissions = (data) => request({
  method: 'post',
  url: '/sysSubButton/queryByFunctionId.do',
  data: JSON.stringify(data),
})

// 设置角色导航栏权限 废弃
export const SetRoleMenu = (data) => request({
  method: 'post',
  url: '/sysRole/setFunction.do',
  data: JSON.stringify(data),
})

// 设置角色具体权限
export const SetRolePermissions = (data) => request({
  method: 'post',
  url: '/sysRole/setButton.do',
  data: JSON.stringify(data),
})

// 获取角色集合
export const GetRoleAll = (data) => request({
  method: 'post',
  url: '/sysRole/queryRole.do',
  data: JSON.stringify(data),
})

// 添加Item
export const AddItem = (data) => request({
  method: 'post',
  url: '/sysSubFunction/add.do',
  data: JSON.stringify(data),
})

// 编辑Item
export const EditItem = (data) => request({
  method: 'post',
  url: '/sysSubFunction/update.do',
  data: JSON.stringify(data),
})

// 删除Item
export const DeleteItem = (data) => request({
  method: 'post',
  url: '/sysSubFunction/delete.do',
  data: JSON.stringify(data),
})

// 添加Permissions
export const AddPermissions = (data) => request({
  method: 'post',
  url: '/sysSubButton/add.do',
  data: JSON.stringify(data),
})

// 编辑Permissions
export const EditPermissions = (data) => request({
  method: 'post',
  url: '/sysSubButton/update.do',
  data: JSON.stringify(data),
})

// 删除Permissions
export const DeletePermissions = (data) => request({
  method: 'post',
  url: '/sysSubButton/delete.do',
  data: JSON.stringify(data),
})

// 获取角色拥有的导航
export const GetRoleMenu = (data) => request({
  method: 'post',
  url: '/sysSubFunction/queryBySelectFunctionId.do',
  data: JSON.stringify(data),
})
