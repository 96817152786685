import { render, staticRenderFns } from "./NumberDialog2.vue?vue&type=template&id=42da9b7e&scoped=true&"
import script from "./NumberDialog2.vue?vue&type=script&lang=js&"
export * from "./NumberDialog2.vue?vue&type=script&lang=js&"
import style0 from "./NumberDialog2.vue?vue&type=style&index=0&id=42da9b7e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42da9b7e",
  null
  
)

export default component.exports