<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      center
      width="900px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          @row-dblclick="dblclick"
        >
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog4.col4')"
            prop="typeName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog4.col1')"
            prop="deviceName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog4.col2')"
            prop="brandName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog4.col3')"
            prop="deviceModel"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            min-width="130"
            label="申请数量"
            prop="appCount"
          ></el-table-column>
          <el-table-column
            min-width="130"
            label="已订货数"
            prop="count"
          ></el-table-column>
          <el-table-column
            min-width="130"
            label="待订货数"
            prop="overCount"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            label="到货数"
            prop="arrivalCount"
          ></el-table-column>
          <el-table-column
            min-width="130"
            label="已入库数"
            prop="inCount"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            label="购物申请单号"
            prop="applicationNumber"
          ></el-table-column>
          <el-table-column
            min-width="170"
            label="订单编号"
            prop="orderNumber"
          ></el-table-column>
          <el-table-column
            min-width="150"
            label="项目名称"
            prop="projectName"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import { GetOrdersDeviceList } from '@/api/warehouse/purchaseOrders'
import { GetApplyDeviceList } from '@/api/warehouse/purchaseRequisition'
export default {
  components: {
    MySelect
  },
  props: ['visible', 'projectId', 'orderFormId'],
  data () {
    return {
      dialogVisible: false,
      title: '选择待入库设备',

      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;

        this.searchData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },

  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        projectContractId: this.projectId,
        isInventory: '1',
        isAbnormal: '1',
      }
      this.tableLoading = true;
      GetOrdersDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e);
      this.dialogVisible = false;
    },
    // 自动选择第一项
    autoSelect () {
      this.getTableData(() => {
        this.$emit('submit', this.tableData[0])
      })

      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        projectContractId: this.projectId,
        isInventory: '1',
        isAbnormal: '1',
        orderFormId: this.orderFormId,
      }
      GetOrdersDeviceList(param).then(res => {
        if (res.list.length > 0)
          this.$emit('submit', res.list[0])
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>