import request from '@/utils/request'

// 查询报表字段
export const GetReportDesignerList = (data) => request({
  method: 'post',
  url: '/reportDesigner/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加、编辑
export const AddReportDesigner = (data) => request({
  method: 'post',
  url: '/reportDesigner/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelReportDesigner = (data) => request({
  method: 'post',
  url: '/reportDesigner/deleteById.do',
  data: JSON.stringify(data)
})

// 获取多表头字段
export const GetFieldAll = (data) => request({
  method: 'post',
  url: '/reportDesigner/queryBySelect.do',
  data: JSON.stringify(data)
})

// 修改模板第二语言状态
export const EditLangState = (data) => request({
  method: 'post',
  url: '/reportTemplates/updateLanguage.do',
  data: JSON.stringify(data)
})

// 获取字段类型集合
export const GetFieldTypeAll = (data) => request({
  method: 'post',
  url: '/reportDataType/queryBySelect.do',
  data: JSON.stringify(data)
})

// 移动
export const MoveItem = (data) => request({
  method: 'post',
  url: '/reportDesigner/updateSn.do',
  data: JSON.stringify(data)
})

// 字段显示设置添加和修改
export const AddFieldDisplay = (data) => request({
  method: 'post',
  url: '/dataDisplayNameSettings/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 字段显示设置添加和修改
export const DelFieldDisplay = (data) => request({
  method: 'post',
  url: '/dataDisplayNameSettings/delete.do',
  data: JSON.stringify(data)
})

// 查询字段显示
export const GetFieldDisplayList = (data) => request({
  method: 'post',
  url: '/dataDisplayNameSettings/queryByPage.do',
  data: JSON.stringify(data)
})

// 获取指定模板的图表
export const GetReportChart = (id) => request({
  method: 'get',
  url: `/reportTemplatesChartType/list/${id}.do`,
})

// 新增、修改图表配置
export const AddReportChart = (data) => request({
  method: 'post',
  url: `/reportTemplatesChartType/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 新增、修改图表配置
export const DelReportChart = (id) => request({
  method: 'post',
  url: `/reportTemplatesChartType/delete/${id}.do`,
})

// 获取模板图表集合
export const GetReportChartAll = () => request({
  method: 'get',
  url: `/reportTemplatesChartType/queryAll.do`,
})

// 获取模板图表
export const GetReportChartList = (data) => request({
  method: 'post',
  url: `/reportTemplatesChartType/queryByPage.do`,
  data: JSON.stringify(data)
})

// 根据图表id获取图表
export const GetChart = (id) => request({
  method: 'get',
  url: `/reportTemplatesChartType/get/${id}.do`,
})

// 上传图片
export const UploadImage = (data) => request({
  method: 'post',
  url: `/reportTemplatesChartType/fileUpload.do`,
  data: data,
  headers: {
    "Content-Type": "multipart/form-data"
  },
})

// 获取图片合集
export const GetImageAll = () => request({
  method: 'get',
  url: `/reportTemplatesIcon/queryAll.do`,
})

// 删除图片
export const DelImage = (id) => request({
  method: 'post',
  url: `/reportTemplatesIcon/delete/${id}.do`,
})