<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <!-- <el-button
            size="medium"
            @click="deviceCode"
          >{{$t('inventoryManagement.inventory.btn1')}}</el-button> -->
          <el-button
            :loading="exportLoading"
            size="medium"
            @click="exportExcel"
          >{{$t('inventoryManagement.inventory.btn2')}}</el-button>
          <!-- <el-button
            size="medium"
            type="primary"
            @click="allocateGoods"
          >{{$t('inventoryManagement.inventory.btn3')}}</el-button> -->
          <el-button
            size="medium"
            type="primary"
            @click="showInWarehouse"
          >批量入库</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="inWarehouse"
          >{{$t('inventoryManagement.inventory.btn4')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="outWarehouse"
          >{{$t('inventoryManagement.inventory.btn5')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="projectOut"
          >{{$t('inventoryManagement.inventory.btn6')}}</el-button>

        </el-button-group>
        <div>
          <el-select
            class="filter-select"
            size="medium"
            v-model="checkState"
            @change="searchData"
          >
            <el-option
              value=""
              :label="$t('inventoryManagement.inventory.option1')"
            ></el-option>
            <el-option
              :value="0"
              :label="$t('inventoryManagement.inventory.option2')"
            ></el-option>
            <el-option
              :value="1"
              :label="$t('inventoryManagement.inventory.option3')"
            ></el-option>
          </el-select>
          <el-select
            class="filter-select address-select"
            size="medium"
            v-model="address"
            multiple
            @change="searchData"
            filterable
            collapse-tags
          >
            <el-option
              v-for="item in inWarehouseSites"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="showDetails"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="deviceType">
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('inventoryManagement.inventory.col1')}}</div>
            <filter-popover
              prop="deviceType"
              :filter.sync="filterData.prop.deviceType"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column prop="deviceName">
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('inventoryManagement.inventory.col2')}}</div>
            <filter-popover
              prop="deviceName"
              :filter.sync="filterData.prop.deviceName"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column prop="deviceModel">
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('inventoryManagement.inventory.col3')}}</div>
            <filter-popover
              prop="deviceModel"
              :filter.sync="filterData.prop.deviceModel"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column prop="deviceBrand">
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('inventoryManagement.inventory.col4')}}</div>
            <filter-popover
              prop="deviceBrand"
              :filter.sync="filterData.prop.deviceBrand"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column prop="deviceUnit">
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('inventoryManagement.inventory.col5')}}</div>
            <filter-popover
              prop="deviceUnit"
              :filter.sync="filterData.prop.deviceUnit"
              :sort.sync="filterData.sort"
              :disabled="true"
            ></filter-popover>
          </div>
        </el-table-column>
        <el-table-column prop="deviceCount">
          <div
            slot="header"
            slot-scope="scope"
            class="table-header"
          >
            <div class="label">{{$t('inventoryManagement.inventory.col6')}}</div>
            <filter-popover
              prop="deviceCount"
              :filter.sync="filterData.prop.deviceCount"
              :sort.sync="filterData.sort"
            ></filter-popover>
          </div>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

  </div>
</template>

<script>
// 全局方法
import { downFile } from '@/utils/common'
// 组件
import FilterPopover from '@/components/FilterPopover/index2'
// 接口
import {
  GetInventoryList,
  ExportInventory,
} from '@/api/warehouse/inventoryManagement'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    FilterPopover,
  },
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      checkState: '',
      inWarehouseSites: [],
      address: [],
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      filterData: {
        prop: {
          deviceType: '',
          deviceBrand: '',
          deviceName: '',
          deviceModel: '',
          deviceCount: '',
        },
        sort: {
          prop: '',
          order: ''
        },
      },
      exportLoading: false,
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === "InventoryManagement" && this.active === 'inventory') {
        let ids = this.tableSelected.map(item => item.deviceInfoId)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.deviceInfoId))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }

    },
    'active': function (newVal) {
      if (newVal === 'inventory')
        this.getTableData();
    },
    'filterData.prop': {
      handler: function (newVal) {
        this.searchData();
      },
      deep: true,
    },
    'filterData.sort': {
      handler: function (newVal) {
        if (newVal.order === '')
          this.$refs.table.clearSort();
        else
          this.$refs.table.sort(newVal.prop, newVal.order)
      },
      deep: true,
    },

  },
  created () {
    // 获取入库地址
    GetDataFieldAll({ parent: '100000000000000000000000000000000015' }).then(res => this.inWarehouseSites = res);

    this.dealPermission();

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        orgId: this.address.join(','),
        deviceType: this.filterData.prop.deviceType,
        deviceBrand: this.filterData.prop.deviceBrand,
        deviceName: this.filterData.prop.deviceName,
        deviceModel: this.filterData.prop.deviceModel,
        deviceCount: parseInt(this.filterData.prop.deviceCount),
      }

      if (this.checkState !== '') {
        param.isConfirm = this.checkState
      }
      this.tableLoading = true;
      GetInventoryList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 设备二维码
    deviceCode () {
      this.$router.push({
        path: '/home/inventoryDeviceCode',
        query: {
          type: 2,
          id: this.tableSelected.length === 0 ? undefined : this.tableSelected.map(item => item.deviceInfoId).join(',')
        }
      })
    },
    // 导出Excel
    exportExcel () {
      let param = {
        search: this.searchVal,
        orgId: this.address.join(','),
        fieldMap: {
          deviceType: this.$i18n.t('inventoryManagement.inventory.col1'),
          deviceName: this.$i18n.t('inventoryManagement.inventory.col2'),
          deviceModel: this.$i18n.t('inventoryManagement.inventory.col3'),
          deviceBrand: this.$i18n.t('inventoryManagement.inventory.col4'),
          deviceUnit: this.$i18n.t('inventoryManagement.inventory.col5'),
          deviceCount: this.$i18n.t('inventoryManagement.inventory.col6'),
        }
      }
      // window.open(`${this.baseUrl}/WarehouseInventory/exportAllGroup.do`);
      this.exportLoading = true;
      ExportInventory(param).then(res => {
        downFile(res, 'inventory.xls')
        this.exportLoading = false;
      })
    },
    // 详情
    showDetails (e) {
      // if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      // if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      // this.$router.push({ path: '/home/inventoryDevice', query: { id: e.deviceInfoId } })
      this.$router.push({ path: '/home/deviceDetailsList', query: { id: e.deviceInfoId, type: 1 } })

    },
    // 备调货
    allocateGoods () {
      this.$router.push({ path: '/home/allocateGoods', query: { type: 1 } })
    },
    // 入库
    inWarehouse () {
      this.$router.push({ path: '/home/inWarehouseDetails', query: { type: 1 } })
    },
    // 出库
    outWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (this.tableSelected[0].deviceCount === 0) return this.$message.warning('库存数量为0！')
      this.$router.push({ path: '/home/outWarehouseDetails', query: { id: this.tableSelected[0].deviceInfoId, type: 1 } })
    },
    // 按项目出库
    projectOut () {
      this.$router.push({ path: '/home/outWarehouseDetails', query: { type: 2 } })
    },
    // 批量入库
    showInWarehouse () {
      this.$router.push({ path: '/home/batchInWarehouse', query: {} })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('InventoryManagementSearch');
        this.permit.add = permission.includes('InventoryManagementAdd');
        this.permit.edit = permission.includes('InventoryManagementEdit');
        this.permit.del = permission.includes('InventoryManagementDelete');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: calc(100% - 36px - 10px - 32px - 10px);
}
.input-wrap {
  .filter-select {
    width: 120px;
  }
  .address-select {
    width: 220px;
  }
  .el-button {
    padding: 0 15px;
  }
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>