<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        class="dialog-form"
        size="small"
      >
        <el-form-item
          :label="dialog.label  + typeName"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('stores.nodeDialog.label3')"
          prop="description"
        >
          <el-input
            type="textarea"
            :rows="3"
            v-model="dialog.description"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('stores.nodeDialog.label4')"
          prop="type"
        >
          <el-select v-model="dialog.type">
            <el-option
              v-for="item in types"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>

          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('stores.nodeDialog.label5')"
          prop="dataAccessGroup"
        >
          <el-select
            v-model="dialog.dataAccessGroup"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  AddStoreNode,
  AddStoreClidNode,
  EditStoreNode,
} from '@/api/stores'
import { GetDataAccessGroup } from '@/api/organizations'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import {
  AddOrganization,
} from '@/api/organizations'
export default {
  props: ['visible', 'data', 'type'],
  data () {
    return {
      userDataAccessGroup: localStorage.accessGroupId,
      dataAccessGroups: [],
      types: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        label: '',
        parentId: null,
        id: null,
        name: '',
        description: '',
        type: '',
        dataAccessGroup: '',
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        type: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

    }
  },
  computed: {
    typeName () {
      let type = this.types.find(item => item.id === this.dialog.type);
      return !!type ? `(${type.name})` : '';
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        this.initData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    // 获取店铺类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000028' }).then(res => this.types = res)

  },
  methods: {
    initData () {
      this.dataAccessGroups = [];
      switch (this.type) {
        case 1:
          this.dialog.title = this.$i18n.t('stores.nodeDialog.title1')
          this.dialog.label = this.$i18n.t('stores.nodeDialog.label1')
          this.dialog.id = null;
          this.dialog.parentId = null;
          // GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)
          GetDataAccessGroup({ accessGroupId: this.userDataAccessGroup }).then(res => this.dataAccessGroups = res)
          break;
        case 2:
          this.dialog.title = this.$i18n.t('stores.nodeDialog.title2')
          this.dialog.label = this.$i18n.t('stores.nodeDialog.label2')
          this.dialog.id = null;
          this.dialog.parentId = this.data.id;
          GetDataAccessGroup({ accessGroupId: this.data.accessGroupId }).then(res => this.dataAccessGroups = res)
          break;
        case 3:
          this.dialog.title = this.$i18n.t('stores.nodeDialog.title3')
          this.dialog.label = this.$i18n.t('stores.nodeDialog.label1')
          this.dialog.id = this.data.id;
          this.dialog.parentId = this.data.parentId || null;
          if (this.data.parentSccessGroupId) {
            GetDataAccessGroup({ accessGroupId: this.data.parentSccessGroupId }).then(res => this.dataAccessGroups = res)
          } else {
            // GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)
            GetDataAccessGroup({ accessGroupId: this.userDataAccessGroup }).then(res => this.dataAccessGroups = res)
          }
          this.$nextTick(() => {
            this.dialog.name = this.data.label;
            this.dialog.description = this.data.description;
            this.dialog.type = this.data.type;
            this.dialog.dataAccessGroup = this.data.accessGroupId;
          })
          break;
      }
    },
    // 添加、编辑节点
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          description: this.dialog.description,
          type: this.dialog.type,
          accessGroupId: this.dialog.dataAccessGroup,
        }
        let msg = this.$i18n.t('message.add');
        this.dialog.loading = true;

        if (this.dialog.id) {
          // 编辑
          param.id = this.dialog.id;
          msg = this.$i18n.t('message.edit');

          EditStoreNode(param).then(res => {
            if (res.success) {
              this.$emit('update')
              this.$message.success(msg);
              this.dialog.visible = false;
            }
            this.dialog.loading = false;
          }).catch(error => {
            this.dialog.loading = false;
          })
        } else {
          // 添加
          if (this.dialog.parentId) {
            // 子节点
            param.parentId = this.dialog.parentId;
            AddStoreClidNode(param).then(res => {
              if (res.success) {
                this.$emit('update')
                this.$message.success(msg);
                this.dialog.visible = false;
              }
              this.dialog.loading = false;
            }).catch(error => {
              this.dialog.loading = false;
            })
          } else {
            // 根节点
            AddOrganization(param).then(res => {
              if (res.success) {
                this.$emit('update')
                this.$message.success(msg);
                this.dialog.visible = false;
              }
              this.dialog.loading = false;
            }).catch(error => {
              this.dialog.loading = false;
            })
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  el-select {
    width: 100%;
  }
}
</style>