<template>
  <div class="wrap">
    <el-row class="top-box">
      <el-card class="table-card">
        <el-row>
          <div class="input-wrap">
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </div>
        </el-row>
        <el-row
          class="table-row"
          ref="tableRow"
        >
          <el-table
            border
            :data="tableData"
            :height="tableHeight"
            v-loading="tableLoading"
            @selection-change="val => tableSelected = val"
            @row-click="rowClick"
            @row-dblclick="dblclickEdit"
            :row-class-name="tableRowClassName"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              sortable
              prop="name"
              :label="$t('storeGroupEditor.col1')"
            >
            </el-table-column>
            <el-table-column
              sortable
              prop="accessGroupName"
              :label="$t('storeGroupEditor.col2')"
            >
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="page-wrap">
          <my-page
            layout="prev, pager, next, jumper"
            :total="page.total"
            :page-size.sync="page.size"
            :current-page.sync="page.current"
            @current-change="getTableData"
            @size-change="getTableData"
          >
          </my-page>
        </el-row>
      </el-card>
    </el-row>
    <el-row
      class="bottom-box"
      :gutter="10"
      v-if="checkedId"
    >
      <el-col :span="12">
        <el-card class="tree-wrap">
          <div class="title">
            <span>{{$t('storeGroupEditor.subtitle1')}}</span>
            <el-input
              class="tree-search"
              v-model="treeSerachVal"
              size="medium"
            ></el-input>
            <i
              class="icon-branch"
              @click="showGrouping"
            ></i>

          </div>
          <el-tree
            :data="treeData"
            :expand-on-click-node="false"
            ref="tree"
            node-key="id"
            highlight-current
            show-checkbox
            check-strictly
            @node-click="treeClick"
            @check="treeCheck"
            v-loading="treeLoading"
            :filter-node-method="filterNode"
          >
            <!-- default-expand-all -->
            <!-- @check-change="setStore" -->
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="permission-wrap">
          <div class="title">
            <span>{{$t('storeGroupEditor.subtitle2')}}</span>
            <el-button-group class="input-group">
              <el-input
                v-model="serachBrandVal"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="getCheckedBrand"
              >{{$t('operation.search')}}</el-button>
            </el-button-group>
          </div>
          <div
            class="brand-row"
            ref="brandRow"
          >
            <el-table
              border
              :data="brandData"
              :height="brandHeight"
              ref="brandTable"
              stripe
              v-loading="brandLoading"
              @selection-change="setBrand"
            >
              <el-table-column type="selection"> </el-table-column>
              <el-table-column
                :label="$t('storeGroupEditor.col3')"
                prop="storeId"
              ></el-table-column>
              <el-table-column
                :label="$t('storeGroupEditor.col4')"
                prop="name"
                sortable
              ></el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加角色弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="groupDialog.title"
      :visible.sync="groupDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="groupDialog"
        :rules="rules"
        ref="groupForm"
        size="small"
      >
        <el-form-item
          :label="$t('storeGroupEditor.dialog.label1')"
          prop="name"
        >
          <el-input v-model="groupDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('storeGroupEditor.dialog.label2')"
          prop="dataAccessGroup"
        >
          <el-select
            v-model="groupDialog.dataAccessGroup"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="groupDialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="submitGroup"
          :loading="groupDialog.loading"
          size="medium"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <grouping-dialog
      :visible.sync="GroupingDialogVisible"
      :type.sync="groupingType"
    ></grouping-dialog>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 组件
import PageHeader from '@/components/PageHeader'
import GroupingDialog from '@/pages/stores/components/GroupingDialog'
// 接口
import {
  GetDataAccessGroupAll,
} from '@/api/organizations'
import {
  GetSiteGroupList,
  AddSiteGroup,
  DelSiteGroup,
  GetStoreTree,
  SetCategories,
  SetSiteBrand,
  GetCheckedStore,
  GetCheckedBrand,
} from '@/api/stores'
export default {
  name: 'StoreGroupEditor',
  components: {
    PageHeader,
    GroupingDialog,
  },
  data () {
    return {
      GroupingDialogVisible: false,
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      checkedId: null,
      tableData: [],
      tableHeight: null,
      dataAccessGroups: [],
      groupForm: {
        loading: false,
      },
      groupDialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('storeGroupEditor.dialog.label1'), trigger: 'blur' }],
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeLoading: false,
      treeData: [],
      serachBrandVal: '',
      brandLoading: false,
      brandData: [],
      brandHeight: null,
      treeNode: {
        id: null,
      },

      groupingType: '1',
      treeSerachVal: '',
    }
  },
  watch: {
    'treeSerachVal': function (val) {
      this.$refs.tree.filter(val);
    },
    'groupingType': function (newVal) {
      GetStoreTree({ type: newVal }).then(res => this.treeData = res)
    },
    '$route': function (to) {
      if (to.name === 'StoreGroupEditor')
        GetStoreTree({ type: '1' }).then(res => this.treeData = res)
    },
  },
  created () {
    // 获取树结构
    GetStoreTree({ type: '1' }).then(res => this.treeData = res)
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)

    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetSiteGroupList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (this.tableData.length !== 0) {
          this.rowClick(this.tableData[0])
        }
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示添加
    showAdd () {
      this.$refs.groupForm && this.$refs.groupForm.resetFields();
      this.groupDialog.id = null;
      this.groupDialog.title = this.$i18n.t('storeGroupEditor.dialog.title1');
      this.groupDialog.visible = true;
    },
    // 显示编辑
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },

    dblclickEdit (item) {
      if (!this.permit.edit) return;
      this.$refs.groupForm && this.$refs.groupForm.resetFields();

      this.groupDialog.id = item.id;
      this.groupDialog.title = this.$i18n.t('storeGroupEditor.dialog.title2');
      this.$nextTick(() => {
        this.groupDialog.name = item.name;
        this.groupDialog.dataAccessGroup = item.accessGroupId;
      })
      this.groupDialog.visible = true;
    },

    // 添加、编辑角色
    submitGroup () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.groupDialog.name,
          accessGroupId: this.groupDialog.dataAccessGroup,
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupDialog.id) {
          param.id = this.groupDialog.id;
          msg = this.$i18n.t('message.edit');
        }
        this.groupDialog.loading = true;
        AddSiteGroup(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.getTableData();
            this.groupDialog.visible = false;
          } else {
            if (res.status === 100) this.$message.warning('Role Name is occupied.')
          }
          this.groupDialog.loading = false;
        }).catch(error => {
          this.groupDialog.loading = false;
        })
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(','),
        }
        DelSiteGroup(param).then(res => {
          if (res.success) {
            if (this.tableSelected.some(item => item.id === this.checkedId)) this.checkedId = null;

            this.getTableData()
            this.$message.success('deleted successfully.')
          }
        })
      }).catch(() => { });
    },

    // 获取分支机构
    treeClick (node) {
      this.treeNode = node;
      this.getCheckedBrand();
    },
    //  
    treeCheck (node, data) {
      let check = data.checkedKeys.includes(node.id);
      if (this.treeNode.id === node.id) {
        if (check) this.$refs.brandTable.toggleAllSelection();
        else this.$refs.brandTable.clearSelection();
      } else {
        let brandParam = {
          siteGroupId: this.checkedId,
          // categoriesId: node.id,
          categoriesId: this.recursionNodeId([node]).join(','),
          brandId: '',
        }
        if (check) {
          let param = {
            id: node.id,
            siteGroupId: this.checkedId,
          }
          GetCheckedBrand(param).then(res => {
            brandParam.brandId = res.map(item => item.id).join(',');
            SetSiteBrand(brandParam).then(res => {
              if (res.success) this.getCheckedStore();
            })
          });
        } else {
          SetSiteBrand(brandParam).then(res => {
            if (res.success) this.getCheckedStore();
          })
        }
      }
    },
    recursionNodeId (data) {
      let arr = [];
      let ids = data.map(item => {
        if (!!item.children) arr = arr.concat(this.recursionNodeId(item.children))
        return item.id;
      });
      return ids.concat(arr);
    },
    // 设置分支机构
    setBrand: debounce(function (val) {
      if (this.brandLoading) return;
      let param = {
        siteGroupId: this.checkedId,
        // categoriesId: this.treeNode.id,
        categoriesId: this.recursionNodeId([this.treeNode]).join(','),
        brandId: val.map(item => item.id).join(','),
      }
      SetSiteBrand(param).then(res => {
        if (res.success) {
          this.getCheckedStore();
        }
      })
    }),

    // 获取选择中的店铺组
    getCheckedStore () {
      let param = {
        siteGroupId: this.checkedId,
      }
      this.treeLoading = true;
      GetCheckedStore(param).then(res => {
        this.$refs.tree.setCheckedKeys(res);
        setTimeout(() => {
          this.treeLoading = false;
        }, 400)
      })
    },

    // 获取被选中的分支机构
    getCheckedBrand () {
      let param = {
        id: this.treeNode.id,
        siteGroupId: this.checkedId,
        search: this.serachBrandVal,
      }
      this.brandLoading = true;
      GetCheckedBrand(param).then(res => {
        this.brandData = res || [];
        this.$nextTick(() => {
          this.brandData.forEach((item, index) => {
            if (item.selection) this.$refs.brandTable.toggleRowSelection(this.brandData[index])
          })
          setTimeout(() => {
            this.brandLoading = false;
          }, 1)
        })
      })
    },
    // 获取角色id
    rowClick (row, column, event) {
      this.checkedId = row.id;
      this.getCheckedStore();
      if (this.$refs.tree) {
        this.$refs.tree.setCurrentKey(null);
        this.treeClick({ id: '' });
      }

      if (!this.brandHeight)
        this.$nextTick(() => {
          this.brandHeight = this.$refs.brandRow.clientHeight;
        })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.id === this.checkedId) return 'checked-row';
      else return '';
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('SiteGroupEditorSearch');
        this.permit.add = permission.includes('SiteGroupEditorAdd');
        this.permit.edit = permission.includes('SiteGroupEditorEdit');
        this.permit.del = permission.includes('SiteGroupEditorDelete');
      }
    },

    // 
    showGrouping () {
      this.GroupingDialogVisible = true
    },
    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.top-box {
  height: 50%;
  margin-bottom: 10px;
}

.bottom-box {
  height: calc(50% - 10px);
}

.table-row {
  height: calc(100% - 46px - 42px);
}
.brand-row {
  height: calc(100% - 46px);
}
.el-dialog {
  .el-select {
    width: 100%;
  }
}
.el-col {
  height: 100%;
}
.el-tree {
  // height: 20px;
  height: calc(100% - 46px);
  overflow: auto;
}
.permission-wrap {
  .btn-wrap {
    margin-top: 24px;
    text-align: center;
  }
}

.tree-wrap,
.permission-wrap {
  .title {
    height: 36px;
    border-bottom: 1px solid #dcdfe6;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.icon-branch {
  cursor: pointer;
  color: #777;
}
.tree-search {
  width: 50%;
}
</style>
