<template>
  <div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <p>{{$t('stores.grouping.title1')}}</p>
      <el-select v-model="dialog.type">
        <el-option
          label="Custom Region"
          value="3"
          :disabled='true'
        ></el-option>
        <el-option
          label="Country"
          value="7"
          :disabled='true'
        ></el-option>
        <el-option
          label="City"
          value="2"
        ></el-option>
        <el-option
          label="District"
          value="4"
          :disabled='true'
        ></el-option>
        <el-option
          label="Site Name"
          value="5"
          :disabled='true'
        ></el-option>
        <el-option
          label="Brand"
          value="6"
          :disabled='true'
        ></el-option>
        <el-option
          label="Customer"
          value="1"
        ></el-option>
      </el-select>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { AddSite } from '@/api/stores'
export default {
  props: ['visible', 'type'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: this.$i18n.t('stores.grouping.title1'),
        type: '1',
      },

    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交
    submit () {
      this.$emit('update:type', this.dialog.type)
      this.dialog.visible = false;
    },

  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>