import request from '@/utils/request'

// 查询用户
export const GetUserList = (data) => request({
  method: 'post',
  url: '/SysUser/queryByPage.do',
  data: JSON.stringify(data)
})

// 用户新增和修改
export const AddUser = (data) => request({
  method: 'post',
  url: '/SysUser/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除用户
export const DelUser = (data) => request({
  method: 'post',
  url: '/SysUser/del.do',
  data: JSON.stringify(data)
})

// 获取用户集合
export const GetUserAll = (data) => request({
  method: 'post',
  url: '/SysUser/queryByList.do',
  data: JSON.stringify(data)
})
