<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">Equals</div>
      <el-date-picker
        :disabled="type != 12"
        v-model="dateRange"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        unlink-panels
        size="small"
      >
      </el-date-picker>
      <div class="option-box">
        <div
          v-for="(item,index) in dateCondition"
          :key="index"
          class="date-item"
          :class="{'active':index == type}"
          @click="selectType(index)"
        >{{item}}</div>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <el-col
          :offset="8"
          :span="8"
        >
          <el-button
            size="small"
            @click="showQuery"
          >Detail</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 全局
import { formatDate } from '@/utils/common'
// 接口
import { GetLastOrderDate } from '@/api/counterMode'

export default {
  props: ['visible', 'zindex', 'data'],
  data () {
    return {
      dateCondition: ['Last 30 minutes', 'Last 1 hour', 'Last 4 hours', 'Today', 'Yesterday', 'Last 3 Days', 'Last 5 Days', 'Last 1 Week', 'Last 30 Days', 'Last 90 Days', 'Last Year', 'All Time', 'Custom Range', 'Test Time'],
      type: 0,
      dateRange: [],
      title: '',
    }
  },

  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      this.title = this.data.data.field;
      this.type = this.data.data.condition || 0;
      if (this.type === 12 || this.type === 13) this.dateRange = this.data.data.dateRange;
    },
    dialogClose () {
      this.$emit('update:visible', false);
    },
    selectType (index) {
      this.type = index;
    },
    submit () {
      let label = this.dateCondition[this.type];

      if (this.type !== 0) {
        let date = new Date();
        switch (this.type) {
          case 0:
            this.dateRange[0] = formatDate(new Date(date.getTime() - 30 * 60 * 1000));
            this.dateRange[1] = formatDate(date);
            break;
          case 1:
            this.dateRange[0] = formatDate(new Date(date.getTime() - 60 * 60 * 1000));
            this.dateRange[1] = formatDate(date);
            break;
          case 2:
            this.dateRange[0] = formatDate(new Date(date.getTime() - 4 * 60 * 60 * 1000));
            this.dateRange[1] = formatDate(date);
            break;
          case 3:
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            break;
          case 4:
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            break;
          case 5:
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 6:
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 4);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 7:
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 6);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 8:
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 29);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 9:
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 89);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 10:
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 364);
            this.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 11:
            this.dateRange[0] = formatDate(new Date('2020-10-1'), 'yyyy-MM-dd 00:00:00');
            this.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            break;
          case 12:
            label = `${this.dateRange[0]}~${this.dateRange[1]}`
            break;
          case 13:
            this.dateRange[0] = '2020-10-22 00:00:00'
            this.dateRange[1] = '2020-10-22 23:59:59'
            label = `${this.dateRange[0]}~${this.dateRange[1]}`
            break;
        }

        this.$emit('submit', Object.assign(this.data, {
          con: label,
          data: Object.assign(this.data.data, {
            condition: this.type,
            dateRange: this.dateRange,
            operator: 0,
          })
        }));
        this.dialogClose();
      } else {
        GetLastOrderDate().then(res => {
          let dateRange = res.split('~')
          this.$emit('submit', Object.assign(this.data, {
            con: label,
            data: Object.assign(this.data.data, {
              condition: this.type,
              dateRange: dateRange,
              operator: 0,
            })
          }));
          this.dialogClose();
        })
      }
    },
    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    showQuery () {
      this.$emit('showQuery');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
</style>