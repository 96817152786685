<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('sendOrder.title')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>

      <el-divider content-position="left">{{$t('sendOrder.subtitle1')}}</el-divider>
      <el-form
        size="medium"
        label-width="170px"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item :label="$t('sendOrder.label1')">
              <el-input
                readonly
                v-model="customerName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('sendOrder.label2')">
              <el-input
                readonly
                v-model="arrivalDate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-table
          border
          :data="tableData1"
          v-loading="tableLoading1"
        >
          <el-table-column
            prop="deviceInfoName"
            :label="$t('sendOrder.col2')"
          >
          </el-table-column>
          <el-table-column
            prop="faultDescription"
            :label="$t('sendOrder.col4')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-divider content-position="left">{{$t('sendOrder.subtitle2')}}</el-divider>
      <el-row>
        <el-button-group>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEidt"
          >{{$t('operation.reassign')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row>
        <el-table
          border
          :data="tableData2"
          :row-class-name="tableRowClassName"
          v-loading="tableLoading2"
          @row-click="editClick"
          @selection-change="val => tableSelected2 = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            prop="createTime"
            :label="$t('sendOrder.col5')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="serviceUserName"
            :label="$t('sendOrder.col6')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('sendOrder.col7')"
            sortable
          >
            <template>Unknown</template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="status"
            :label="$t('sendOrder.col8')"
            sortable
          >
            <template slot-scope="scope">
              {{scope.row.status == 0 ? $t('sendOrder.tag1') : $t('sendOrder.tag2')}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="dispatchToday"
            :label="$t('sendOrder.col9')"
            sortable
            width="300"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="planArrivalTime"
            :label="$t('sendOrder.col10')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-divider content-position="left">{{$t('sendOrder.subtitle3')}}</el-divider>
      <el-row>
        <el-table
          border
          :data="tableData3"
          v-loading="tableLoading3"
        >
          <el-table-column
            prop="userAccount"
            :label="$t('sendOrder.col11')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="organizationName"
            :label="$t('sendOrder.col12')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="organizationName"
            :label="$t('sendOrder.col13')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="organizationName"
            :label="$t('sendOrder.col14')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="organizationName"
            :label="$t('sendOrder.col15')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <!-- 人员列表弹框 -->
    <staff-dialog
      :visible.sync="staff.visible"
      :type="staff.type"
      :data="tableData2"
      @submit="staffSubmit"
    ></staff-dialog>

    <!-- 编辑预计抵达时间 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.type === 1? $t('sendOrder.dialog.title2') : $t('sendOrder.dialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item :label="dialog.type === 1? $t('sendOrder.dialog.name2') : $t('sendOrder.dialog.name1') ">
          <span>{{dialog.name}}</span>
        </el-form-item>
        <el-form-item
          v-if="dialog.type === 1"
          :label="$t('sendOrder.dialog.reason')"
          prop="cause"
        >
          <el-input v-model="dialog.cause"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('sendOrder.dialog.date')"
          prop="date"
        >
          <el-date-picker
            v-model="dialog.date"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="editTime"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate, toLocaleDate, formatUTCDate } from '@/utils/common'
// 组件
import PageHeader from '@/components/PageHeader'
import StaffDialog from './components/StaffDialog'
// 接口
import {
  DistributeSubmitRequest,
  GetDistributeInfo,
} from '@/api/warehouse/submitRequest'

export default {
  components: {
    PageHeader,
    StaffDialog
  },
  data () {
    return {
      staff: {
        visible: false,
        type: 'add'
      },
      loading: false,
      tableLoading1: false,
      arrivalDate: '',
      customerName: '',
      tableData1: [],

      tableLoading2: false,
      tableSelected2: [],
      tableData2: [],

      tableLoading3: false,
      tableData3: [],

      dialog: {
        visible: false,
        loading: false,
        index: null,
        type: 0,
        cause: '',
        name: '',
        date: '',
      },
      rules: {
        date: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        cause: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      checkedTabel: [],
    }
  },
  watch: {

  },
  created () {
    this.getItem();
  },
  methods: {
    back () {
      this.$router.push({ path: '/home/workOrderScheduling' })
    },
    // 获取单项信息
    getItem () {
      // 故障设备
      let param = { id: this.$route.query.id };
      GetDistributeInfo(param).then(res => {
        this.customerName = res.branchName;
        this.arrivalDate = toLocaleDate(res.requireArrivalTime);
        this.tableData1 = res.workOrderDeviceList;
        this.tableData2 = res.distributionOrderList.map(item => {
          item.createTime = toLocaleDate(item.createTime);
          item.planArrivalTime = toLocaleDate(item.planArrivalTime);
          return item;
        });
      });
    },

    //新增
    showAdd () {
      this.staff.type = 'add';
      this.staff.visible = true;
    },
    // 显示改派
    showEidt () {
      if (this.tableSelected2.length === 0) return this.$message.warning('Please select at least one item.')
      if (!this.tableSelected2.every(item => item.status == 0)) return this.$message.warning(this.$i18n.t('sendOrder.message1'))
      this.staff.type = 'edit';
      this.staff.visible = true;
    },
    // 编辑预计抵达时间
    editClick (e) {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.index = e.rowIndex;
      this.dialog.type = 0;
      this.dialog.date = e.planArrivalTime;
      this.dialog.visible = true;
    },

    // 编辑抵达时间
    editTime () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        if (this.dialog.type === 0) {
          this.tableData2[this.dialog.index].planArrivalTime = this.dialog.date
          this.$forceUpdate();
        } else {
          this.tableSelected2.forEach(item => {
            item.status = 1;
            item.reason = this.dialog.cause;
          })
          this.tableData2 = this.tableData2.concat(this.checkedTabel.filter(item1 => {
            if (this.tableData2.find(item2 => (item2.serviceUserName === item1.name) && (item2.workStatusId === '00000000000000000000000000000000002' || item2.workStatusId == null)) === undefined) {
              return true;
            } else {
              this.$message.warning(`${item1.name}已派单`);
              return false;
            }
          }).map(item => {
            item.serviceUserName = item.name;
            item.dispatchToday = item.currentSum;
            item.planArrivalTime = this.dialog.date;
            item.serviceUserId = item.id;
            item.createTime = formatDate(new Date());
            item.status = 0;
            return item;
          }))
        }
        this.dialog.visible = false;
      })
    },
    // 人员提交
    staffSubmit (data) {
      if (this.staff.type === 'add') {
        this.tableData2 = this.tableData2.concat(data.filter(item1 => {
          if (this.tableData2.find(item2 => (item2.serviceUserName === item1.name) && (item2.workStatusId === '00000000000000000000000000000000002' || item2.workStatusId == null)) === undefined) {
            return true;
          } else {
            this.$message.warning(`${item1.name}已派单`);
            return false;
          }
        }).map(item => {
          item.serviceUserName = item.name;
          item.dispatchToday = item.currentSum;
          item.serviceUserId = item.id;
          item.status = 0;
          item.createTime = formatDate(new Date());
          item.planArrivalTime = formatDate(new Date(Date.parse(new Date) + 3600 * 1000));
          return item;
        }));
      } else {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.type = 1;
        this.dialog.name = data.map(item => item.name).join(',')
        this.checkedTabel = data;
        this.dialog.visible = true;
      }
    },
    // 提交
    submit () {
      if (this.tableData2.length === 0) return this.$message.warning('无服务人员')
      let workOrderId = this.$route.query.id;
      let parma = this.tableData2.map(item => {
        return {
          workOrderId: workOrderId,
          serviceUserId: item.serviceUserId,
          status: item.status,
          reason: item.reason || '',
          planArrivalTime: formatUTCDate(item.planArrivalTime),
          location: item.location,
        }
      })

      DistributeSubmitRequest(parma).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.save'))
          this.back();
        }
      })
    },
    // 删除
    delItem () {
      let checkids = this.tableSelected2.map(item => item.id);
      this.tableData2 = this.tableData2.filter(item => !checkids.includes(item.id))
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog-form {
  .el-date-editor,
  .el-cascader,
  .el-select {
    width: 100%;
  }
}
</style>