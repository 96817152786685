<template>
  <div class="img-box">
    <img
      v-if="!!elData.content"
      class="img"
      :src="elData.content"
      :style="{
        'object-fit':elData.style.objectFit,
      }"
    >
  </div>
</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.img-box {
  width: 100%;
  height: 100%;
}
.img {
  width: 100%;
  height: 100%;
  outline: none;
  user-select: none;
  display: block;
}
</style>