<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row :gutter="100">
        <el-col :span="12">
          <div class="select-item">
            <label> {{$t('reportDesigner.label1')}} </label>
            <el-select
              size="medium"
              v-model="reportTemplateId"
              filterable
            >
              <el-option
                v-for="(item,index) in reportTemplates"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="100">
        <el-col :span="12">
          <div class="label-item">
            <label>{{$t('reportDesigner.label2')}}</label>
            <el-button
              :disabled="!permit.chartType"
              size="medium"
              @click="showChartType(null)"
              icon="el-icon-plus"
            ></el-button>
            <el-button
              :disabled="!permit.chartType"
              v-for="(item,index) in chartData"
              v-show="item.chartType !== 999"
              :title="item.chartTitle"
              :key="index"
              :type="item.enables === 1 ? 'primary' : 'info'"
              size="medium"
              @click="showChartType(index)"
            >
              <i
                v-if="item.chartType === 0"
                class="icon-bar"
              ></i>
              <i
                v-else-if="item.chartType === 1"
                class="icon-pie"
              ></i>
              <i
                v-else-if="item.chartType === 2"
                class="icon-line"
              ></i>
            </el-button>
          </div>
        </el-col>
        <el-col
          :span="12"
          v-if="reportTemplateId"
        >
          <span class="select-property">{{$t('reportDesigner.label3')}}</span>
          <span class="select-property">{{analysisMode}}</span>
        </el-col>
      </el-row>
      <el-row :gutter="100">
        <el-col :span="12">
          <div class="label-item">
            <label>{{$t('reportDesigner.label4')}}</label>
            <el-button
              :disabled="reportTemplateId === '' || !permit.displayNameSettings"
              size="medium"
              icon="el-icon-more"
              @click="showDisplaySet"
            ></el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="switch-item">
            <span>{{$t('reportDesigner.label5')}}</span>
            <el-switch
              :disabled="!permit.lang"
              v-model="isLang2"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="isLang2  ? 'Enable':'Disable'"
              :active-value="1"
              :inactive-value="0"
              @change="langChange"
            >
            </el-switch>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="100">
        <el-col
          :span="12"
          :offset="12"
        >
          <div class="switch-item">
            <span>{{$t('reportDesigner.label6')}}</span>
            <el-switch
              :disabled="!permit.lang"
              v-model="isLang1"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="isLang1  ? 'Enable':'Disable'"
              :active-value="1"
              :inactive-value="0"
              @change="langChange"
            >
            </el-switch>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              :disabled="!permit.edit"
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              size="medium"
              @click="save"
            >{{$t('operation.save')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="160"
            sortable
            prop="mainFieText"
            :label="$t('reportDesigner.col1')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="subFieldText"
            :label="$t('reportDesigner.col2')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="fieIdType"
            :label="$t('reportDesigner.col3')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.fieIdType === 0">Report Field</span>
              <span v-if="scope.row.fieIdType === 1">Template Parameters</span>
              <!-- {{scope.row.fieIdType === 0 ? 'Report Field' : 'Template Parameters'}} -->
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="dataTypeId"
            :label="$t('reportDesigner.col4')"
          >
            <template slot-scope="scope">
              {{tableMeasures[scope.row.dataTypeId]}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="160"
            sortable
            prop="riskLevelFields"
            :label="$t('reportDesigner.col5')"
          >
            <template slot-scope="scope">
              <el-tag
                size="small"
                type="success"
                v-if="scope.row.riskLevelFields"
              >Enable</el-tag>
              <el-tag
                size="small"
                type="danger"
                v-else
              >Disable</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            v-if="permit.overviewFieldsSettings"
            min-width="130"
            prop="date"
            :label="$t('reportDesigner.col6')"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="overviewFields(scope.row)"
                :type="!!scope.row.chartData ? 'primary' : ''"
              >Config</el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-if="permit.linkTo"
            min-width="130"
            prop="date"
            :label="$t('reportDesigner.col7')"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="showLinkTo(scope.row)"
                :type="!!scope.row.linkToJson ? 'primary' : ''"
              >Config</el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-if="permit.sort"
            min-width="170"
            prop="date"
            :label="$t('reportDesigner.col8')"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="moveItem(scope.row.id,'up',scope)"
              >Up</el-button>
              <el-button
                size="mini"
                @click="moveItem(scope.row.id,'down',scope)"
              >Down</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 添加报表字段弹框 -->
    <report-designer-dialog
      :visible.sync="ReportDesignerVisible"
      :report-data="reportData"
      :report-template-id="reportTemplateId"
      :measures="measures"
      @update="getTableData"
    ></report-designer-dialog>
    <!--  跳转配置弹框 -->
    <link-dialog
      :visible.sync="LinkDialogVisible"
      :report-data="reportData"
      @submit="submitLink"
      ref="linkDialog"
    ></link-dialog>
  </div>
</template>

<script>
// 组件
import ReportDesignerDialog from './components/ReportDesignerDialog'
import LinkDialog from './components/LinkDialog'
// 接口
import {
  GetReportDesignerList,
  AddReportDesigner,
  DelReportDesigner,
  EditLangState,
  MoveItem,
  GetFieldTypeAll,
  GetReportChart,
} from '@/api/reportDesigner'
import { GetTypeReport } from '@/api/reportTemplates'
export default {
  name: 'ReportDesigner',
  components: {
    ReportDesignerDialog,
    LinkDialog,
  },
  data () {
    return {
      permit: {
        chartType: true,
        displayNameSettings: true,
        lang: true,
        documentTemplateSettings: true,

        search: true,
        add: true,
        edit: true,
        del: true,

        overviewFieldsSettings: true,
        linkTo: true,
        sort: true,
      },
      ReportDesignerVisible: false,
      LinkDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      isLang1: 0,
      isLang2: 0,
      reportTemplates: [],
      reportTemplateId: '',
      documentTemplate: '',
      searchVal: '',
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      reportData: null,
      measures: [],
      tableMeasures: {},
      chartData: [],
    }
  },
  computed: {
    analysisMode () {
      let current = this.reportTemplates.filter(item => item.id === this.reportTemplateId)[0];
      if (current !== undefined) {
        this.isLang1 = current.mainLanguage;
        this.isLang2 = current.subLanguage;
        return current.analysisMode === 1 ? 'User Defined' : 'System';
      } else {
        return '';
      }
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.name === 'ReportDesigner') {
        this.getTypeReport(this.reportTemplateId);
        this.getChartData();

      }
    },
    'reportTemplateId': function () {
      this.getChartData();

    }

  },
  created () {
    this.dealPermission();
    this.getTypeReport();
    GetFieldTypeAll().then(res => {
      this.measures = res
      this.tableMeasures = {};
      res.forEach(item => {
        this.tableMeasures[item.id] = item.name;
      })
    })

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
  },
  methods: {
    // 获取报表模板
    getTypeReport (id) {
      let param = {
        mode: ''
      }
      GetTypeReport(param).then(res => {
        this.reportTemplates = res
        if (res.length != 0) {
          this.reportTemplateId = res.some(item => item.id === id) ? id : res[0].id;
        }
      })
    },
    getChartData () {
      GetReportChart(this.reportTemplateId).then(res => {
        this.chartData = res;
        this.getTableData();
      })
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        templatesId: this.reportTemplateId,
      }
      this.tableLoading = true;
      GetReportDesignerList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.chartData = this.chartData.find(chart => {
            if (chart.chartType !== 999) return;

            let dataSource = JSON.parse(chart.dataSource)[0];
            return dataSource.fieldId === item.id
          })
          return item;
        });

        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加弹框
    showAdd () {
      this.reportData = null;
      this.ReportDesignerVisible = true;
    },
    // 显示编辑弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.reportData = this.tableSelected[0];
      this.ReportDesignerVisible = true;
    },
    dblclickEdit (item) {
      if (!this.permit.edit) return;

      this.reportData = item;
      this.ReportDesignerVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelReportDesigner(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 移动
    moveItem (id, type, scope) {
      let param = {
        id: id,
        type: type
      }
      MoveItem(param).then(res => {
        if (res.success) this.getTableData();
      })
    },

    // 预览
    preview () {

    },
    // 保存
    save () {

    },
    overviewFields (item) {
      if (!!item.chartData) {
        let dataSource = JSON.parse(item.chartData.dataSource);
        dataSource[0].measure = item.dataTypeId;
        item.chartData.dataSource = JSON.stringify(dataSource);
        sessionStorage.chartData = JSON.stringify(item.chartData);
      } else {
        sessionStorage.chartData = JSON.stringify({
          enables: 1,
          chartTitle: '',
          chartType: 999,
          dataSource: JSON.stringify([{
            fieldId: item.id,
            fieldName: item.mainFieText,
            measure: item.dataTypeId,
            key: item.dbKey,
            type: 0,
          }]),
          queryConditions: '[]',
          sortingJson: '[]',
          chartElements: '{}',
        });
      }
      this.$router.push({ path: '/home/chartType', query: { id: this.reportTemplateId, type: 2 } })
    },
    // 修改第二语言状态
    langChange () {
      let param = {
        id: this.reportTemplateId,
        mainLanguage: this.isLang1,
        subLanguage: this.isLang2,
      }
      EditLangState(param)
    },
    // 显示图表设置
    showChartType (index) {
      if (index !== null) {
        sessionStorage.chartData = JSON.stringify(this.chartData[index]);
      } else {
        localStorage.removeItem('chartData')
      }
      this.$router.push({ path: '/home/chartType', query: { id: this.reportTemplateId, type: 1 } })
    },
    // 显示字段设置
    showDisplaySet () {
      this.$router.push({ path: '/home/fieldDisplaySet', query: { id: this.reportTemplateId } })
    },
    // 显示跳转配置
    showLinkTo (item) {
      this.reportData = item;
      this.LinkDialogVisible = true;
    },
    // 提交跳转配置
    submitLink (data) {
      let param = Object.assign(this.reportData, data);
      AddReportDesigner(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.save'))
          this.LinkDialogVisible = false;
          this.getTableData();
        }
        this.$refs.linkDialog.dialog.loading = false;
      })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        // this.permit.search = permission.includes('18e82d6f-bec6-4eaa-b562-bdd766c91e42');
        // this.permit.add = permission.includes('58bcdfe8-07f6-4050-a70b-1a17fa6d9034');
        // this.permit.edit = permission.includes('6f845cf1-4de9-44bf-b7d8-cd60c1437a79');
        // this.permit.del = permission.includes('7c47939a-0e44-435e-b80d-a6077d66795a');

        // this.permit.sort = permission.includes('01ae879b-691c-407b-bfd0-3a34f99a6311');
        // this.permit.linkTo = permission.includes('14c64292-69e9-4481-a918-2b3014600b8f');
        // this.permit.lang = permission.includes('24e0b62d-3c1a-4c3e-8c41-398fec0e7ee4');
        // this.permit.chartType = permission.includes('dddc5823-cc86-43b4-86ee-8283ee6885f2');
        // this.permit.displayNameSettings = permission.includes('b4559a1b-c0b7-4c0c-9177-b8269a4b6511');
        // this.permit.documentTemplateSettings = permission.includes('e0187650-fa6a-47d7-bdef-b425393fb32a');
        // this.permit.overviewFieldsSettings = permission.includes('f07a7ffe-382d-407c-a410-3805e1434f68');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 75px - 50px - 46px - 46px - 46px - 42px);
}
.select-item {
  label {
    display: inline-block;
    margin-bottom: 10px;
  }
  .el-select {
    width: 100%;
  }
}
.select-property {
  margin-right: 100px;
}

.label-item {
  display: flex;
  align-items: center;
  label {
    width: 200px;
    flex-shrink: 0;
  }
}
.switch-item {
  height: 36px;
  line-height: 36px;
  span {
    width: 220px;
    display: inline-block;
  }
}
.Display-btn {
  margin-left: 20px;
}
</style>
