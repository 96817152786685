<template>
  <div class="wrap">
    <el-card>
      <page-header title="New KPI Templates">
        <el-button
          size="medium"
          @click="back"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
        >Submit</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        class="group-form"
        size="medium"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item label="KPI Templates Name">
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
            <el-form-item label="KPI Type">
              <el-select v-model="groupForm.type">
                <el-option value="Store KPI"> </el-option>
                <el-option value="Cashier KPI"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Data Access Group ( Data Permissions)">
              <el-select v-model="groupForm.dataAccessGroup">
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Descirption">
              <el-input
                type="textarea"
                class="describe"
                v-model="groupForm.descirption"
              ></el-input>
            </el-form-item>
            <el-form-item class="switch-box">
              <el-switch
                v-model="groupForm.isDafault"
                active-text="Set as default"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="groupForm.isDefaultLock"
              ></el-switch>
              <lock-module :state="groupForm.isDefaultLock"></lock-module>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="search-box">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
            ></el-input>
            <el-button
              size="medium"
              @click="showAdd"
            >Add</el-button>
            <el-button size="medium">Remove</el-button>
            <el-button size="medium">Assign Weight</el-button>
            <el-button size="medium">Save</el-button>
          </el-button-group>
          <div class="color-wrap">
            <el-color-picker v-model="color1"></el-color-picker>
            <el-color-picker v-model="color2"></el-color-picker>
            <el-color-picker v-model="color3"></el-color-picker>
          </div>
        </el-row>
        <el-row>
          <el-table :data="tableData">

            <el-table-column type="selection"></el-table-column>
            <el-table-column label="Key Performance Indicator">
              <el-table-column label="KPI Report Templates"></el-table-column>
              <el-table-column label="Description"></el-table-column>
              <el-table-column label="Main Fields"></el-table-column>
              <el-table-column label="Sub Fields"></el-table-column>
            </el-table-column>
            <el-table-column>
              <el-table-column label="Weight of KPIs %"></el-table-column>
              <el-table-column
                label="Measure"
                width="120"
              >
                <template slot-scope="scope">
                  <el-select v-model="scope.row.measure">
                    <el-option value="%Ratio"></el-option>
                    <el-option value="$Amount"></el-option>
                    <el-option value="Time range"></el-option>
                    <el-option value="Frequency"></el-option>
                    <el-option value="Minutes"></el-option>
                    <el-option value="Hout"></el-option>
                    <el-option value="HH:MM"></el-option>
                    <el-option value="No of TC"></el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column>
              <el-table-column label="Baseline"></el-table-column>
              <el-table-column label="Target"></el-table-column>
            </el-table-column>
            <el-table-column label="KPI Score1">
              <el-table-column label="Min"></el-table-column>
              <el-table-column label="Max"></el-table-column>
              <el-table-column label="Score"></el-table-column>
            </el-table-column>
            <el-table-column label="KPI Score2">
              <el-table-column label="Min"></el-table-column>
              <el-table-column label="Max"></el-table-column>
              <el-table-column label="Score"></el-table-column>
            </el-table-column>
            <el-table-column label="KPI Score3">
              <el-table-column label="Min"></el-table-column>
              <el-table-column label="Max"></el-table-column>
              <el-table-column label="Score"></el-table-column>
            </el-table-column>
            <el-table-column label="KPI Score4">
              <el-table-column label="Min"></el-table-column>
              <el-table-column label="Max"></el-table-column>
              <el-table-column label="Score"></el-table-column>
            </el-table-column>
            <el-table-column label="KPI Score5">
              <el-table-column label="Min"></el-table-column>
              <el-table-column label="Max"></el-table-column>
              <el-table-column label="Score"></el-table-column>
            </el-table-column>
          </el-table>

        </el-row>
      </el-form>
    </el-card>

    <!-- 添加kpi弹窗 -->
    <add-kpi :visible.sync="AddKpiVisible"></add-kpi>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import LockModule from '@/components/LockModule'
import AddKpi from './components/AddKpi'
export default {
  components: {
    PageHeader,
    LockModule,
    AddKpi,
  },
  data () {
    return {
      AddKpiVisible: false,
      types: [],
      dataAccessGroups: [],
      searchVal: '',
      groupForm: {
        name: '',
        dataAccessGroup: '',
        report: '',
        descirption: '',
        isDafault: true,
        isDefaultLock: true,
      },
      color1: '#FF0000',
      color2: '#FF9900',
      color3: '#00CC00',
      tableData: [{
        measure: '%Ratio',
      }, {
        measure: '%Ratio',
      }, {
        measure: '%Ratio',
      }],
    }
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },

    dblclickInput (e) {
      let el = e.target;
      if (el.disabled) {
        el.disabled = '';
        el.focus();
      }
    },
    showAdd () {
      this.AddKpiVisible = true;
    }
  }
}
</script>
<style lang="scss" scoped>


.group-form {
  .el-select {
    width: 100%;
  }
  .describe {
    ::v-deep .el-textarea__inner {
      height: 36px;
    }
  }
  .switch-box {
    margin-top: 58px;
    .el-switch {
      margin-right: 30px;
    }
  }

  .table-wrap {
    .color-item {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      ::v-deep .el-color-picker__trigger {
        padding: 4px;
      }
    }
    .cell {
      padding: 0;
    }
    .el-input {
      width: 100%;
      ::v-deep .el-input__inner {
        text-align: center;
        border: none;
      }
    }
    .is-disabled ::v-deep .el-input__inner {
      color: #606266;
      background-color: #fff;
      cursor: default;
    }
  }
}
.search-box {
  display: flex;
  .color-wrap {
    display: flex;
    position: relative;
    font-size: 14px;
    .el-color-picker {
      height: 36px;
    }
    ::v-deep .el-color-picker__trigger {
      width: 100px;
      height: 36px;
      padding: 0;
    }
    ::v-deep .el-color-picker__color {
      border: none;
    }
    &:before {
      content: "Lagging Baseline";
      position: absolute;
      top: -20px;
      left: 0;
    }
    &:after {
      content: "Leading Target";
      position: absolute;
      top: -20px;
      right: 0;
    }
  }
}
.input-group {
  width: calc(50% - 75px);
  margin-right: 150px;
  display: flex;
  .search-input {
    min-width: 150px;
    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
  .el-button {
    height: 36px;
  }
}
</style>  