<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.searchStroes"
              type="text"
              placeholder="Search"
              size="medium"
              class="search-input"
              v-model="tableSerachVal"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.searchStroes"
              size="medium"
              @click="searchData"
            >{{ $t("operation.search") }}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table border          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => (tableSelected = val)"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            :label="$t('stores.col6')"
            prop="parentNode"
          ></el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :label="$t('stores.col1')"
            prop="storeId"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            min-width="120"
            sortable
            :label="$t('stores.col2')"
            prop="name"
          ></el-table-column>
          <el-table-column
            min-width="120"
            sortable
            :label="$t('stores.col3')"
            prop="typeName"
          ></el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :label="$t('stores.col5')"
            prop="status"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">{{
                    $t("stores.storeDialog.option2")
                  }}</span>
              <span v-else-if="scope.row.status == 1">{{
                    $t("stores.storeDialog.option1")
                  }}</span>
              <span v-else-if="scope.row.status == 2">{{
                    $t("stores.storeDialog.option3")
                  }}</span>
              <span v-else-if="scope.row.status == 3">{{
                    $t("stores.storeDialog.option4")
                  }}</span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            min-width="100"
            sortable
            :label="$t('stores.col4')"
            prop="address"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 接口
import { GetStoreByGroup } from "@/api/stores";

export default {
  props: ['visible', 'id'],
  data () {
    return {
      permit: {
        searchStroes: true
      },
      dialog: {
        visible: false,
        title: 'Store',

      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableSerachVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTableData () {
      let param = {
        mySiteGroupId: this.id,
        limit: this.page.size,
        page: this.page.current,
        search: this.tableSerachVal
      };
      this.tableLoading = true;
      GetStoreByGroup(param)
        .then(res => {
          this.tableData = res.list;
          this.page.total = res.total;
          this.tableLoading = false;
        })
        .catch(error => {
          this.tableLoading = false;
        });
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    rowClick (row) {
    },
    // 提交
    submit () {
      this.$emit('submit', this.tableSelected)
      this.dialog.visible = false;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(",");
        this.permit.searchStroes = permission.includes("StoresSearchStores");

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    min-width: 160px;
  }
}
</style>