<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('ApplyDialog.title1')"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table border          v-loading="tableLoading"
          :data="tableData"
          @row-dblclick="dblclick"
        >
          <el-table-column
            :label="$t('ApplyDialog.col1')"
            prop="projectName"
          ></el-table-column>
          <el-table-column
            :label="$t('ApplyDialog.col2')"
            prop="quotationNumber"
          ></el-table-column>
          <el-table-column
            :label="$t('ApplyDialog.col3')"
            prop="applicationNumber"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetPurchaseRequisitionList,
  GetPurchaseRequisitionByOrderList,
} from '@/api/warehouse/purchaseRequisition'
export default {
  props: ['visible', 'projectId', 'orderId'],
  data () {
    return {
      dialogVisible: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.getTableData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        startDate: '',
        endDate: '',
        projectContractId: this.projectId,
      }
      this.tableLoading = true;

      if (!!this.orderId) {
        param.orderFormId = this.orderId;
        GetPurchaseRequisitionByOrderList(param).then(res => {
          this.tableData = res.list;
          this.page.total = res.total;
          this.tableLoading = false;
          fun();
        }).catch(error => {
          this.tableLoading = false;
        })
      } else {
        GetPurchaseRequisitionList(param).then(res => {
          this.tableData = res.list;
          this.page.total = res.total;
          this.tableLoading = false;
          fun();
        }).catch(error => {
          this.tableLoading = false;
        })
      }
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e);
      this.dialogVisible = false;
    },
    // 自动选择第一项
    autoSelect (fun) {
      this.getTableData(() => {
        this.$emit('submit', this.tableData[0])
        this.$nextTick(() => {
          fun();
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>