<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('home.help.title1')"
      :visible.sync="dialog.visible"
      center
      width="500px"
    >
      <div class="help-wrap">
        <p class="content">{{$t('home.help.content')}}</p>
        <p class="contact">{{$t('home.help.contact')}}: tech@bgb.com.cn</p>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          type="primary"
          @click="dialog.visible = false"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HelpDialog',
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
      },
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.help-wrap {
  font-size: 16px;
  line-height: 25px;
}
.content {
  text-align: center;
  margin-bottom: 20px;
}
.contact {
  margin-bottom: 0;
}
</style>