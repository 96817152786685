var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdf-main",staticStyle:{"display":"none"},attrs:{"id":"content-box"}},_vm._l((_vm.pdfData.page),function(page,pageIndex){return _c('div',{key:pageIndex,staticClass:"pdf-page",style:({
          width: _vm.pdfData.width + 'px',
          height: _vm.pdfData.height + 'px',
          'padding-top':Math.round(_vm.pdfData.paddingTop * _vm.pdfData.rate) + 'px',
          'padding-bottom':Math.round(_vm.pdfData.paddingBottom * _vm.pdfData.rate) + 'px',
          'padding-left':Math.round(_vm.pdfData.paddingLeft * _vm.pdfData.rate) + 'px',
          'padding-right':Math.round(_vm.pdfData.paddingRight * _vm.pdfData.rate) + 'px',
        })},[_c('div',{staticClass:"pdf-page-content",attrs:{"data-op-type":"block"}},[(_vm.pdfData.header.show)?_c('div',{staticClass:"pdf-header",style:({
              height: _vm.pdfData.header.height + 'px',
            })},[_c('div',{staticClass:"pdf-content"},_vm._l((_vm.pdfData.header.elements),function(el,elIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!el.hide),expression:"!el.hide"}],key:elIndex,staticClass:"pdf-el-box",style:({
                  left :el.style.left + 'px',
                  top :el.style.top + 'px',
                  width :el.style.width + 'px',
                  height :el.style.height + 'px',
                })},[_c(el.name,{tag:"component",attrs:{"el-data":el,"index":pageIndex}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"pdf-content"},_vm._l((page.elements),function(el,elIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!el.hide),expression:"!el.hide"}],key:elIndex,staticClass:"pdf-el-box",style:({
            left :el.style.left + 'px',
            top :el.style.top + 'px',
            width :el.style.width + 'px',
            height :el.style.height + 'px',
          })},[_c(el.name,{tag:"component",attrs:{"el-data":el,"index":pageIndex}})],1)}),0),(_vm.pdfData.footer.show)?_c('div',{staticClass:"pdf-footer",style:({
              height :_vm.pdfData.footer.height + 'px',
            })},[_c('div',{staticClass:"pdf-content"},_vm._l((_vm.pdfData.footer.elements),function(el,elIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!el.hide),expression:"!el.hide"}],key:elIndex,staticClass:"pdf-el-box",style:({
                left :el.style.left + 'px',
                top :el.style.top + 'px',
                width :el.style.width + 'px',
                height :el.style.height + 'px',
              })},[_c(el.name,{tag:"component",attrs:{"el-data":el,"index":pageIndex}})],1)}),0)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pdfData.pageIndex.show),expression:"pdfData.pageIndex.show"}],staticClass:"page-index",style:({
                'text-align':_vm.pdfData.pageIndex.style.textAlign,
              })},[_vm._v(_vm._s(pageIndex + 1)+"/"+_vm._s(_vm.pdfData.page.length))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }