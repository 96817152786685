<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="800px"
    >
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
        >
          <el-table-column
            min-width="130"
            prop="deviceInfoName"
            label="Date"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceInfoModel"
            label="Engineer"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="brandName"
            label="Site Name"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="120"
            prop="location"
            label="Maintenance File"
          >
            <template slot-scope="scope">
              <el-button size="small">View</el-button>
            </template>
          </el-table-column>
          <el-table-column
            min-width="180"
            prop="label"
            label="Service Confirmation Form"
          >
            <template slot-scope="scope">
              <el-button size="small">View</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="page.size"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row> -->
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetCalendarRecord } from '@/api/warehouse/maintenance'
export default {
  props: ['visible', 'date', 'branch'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Maintenance Record',
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 385,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },

  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        date: `${this.date}~${this.date}`,
        branchId: this.branch,
      }
      this.tableLoading = true;
      GetCalendarRecord(param).then(res => {
        this.tableData = res;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>