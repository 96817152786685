<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="1000px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-row :gutter="50">
          <el-col :span="8">
            <h4>原设备信息</h4>
            <el-form-item label="设备类型">
              <el-input
                v-model="oldRecord.typeName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="设备名称">
              <el-input
                v-model="oldRecord.deviceName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="设备品牌">
              <el-input
                v-model="oldRecord.brandName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="规格型号">
              <el-input
                v-model="oldRecord.deviceModel"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="详细规格">
              <el-input
                type="textarea"
                v-model="oldRecord.detailed"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="申购数量">
              <el-input
                v-model="oldRecord.count"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="需购回时间">
              <el-input
                v-model="oldRecord.expecteBuyBackTime"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="用途">
              <el-input
                v-model="oldRecord.useTo"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="oldRecord.remark"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <h4>变更后设备信息</h4>
            <el-form-item
              label="设备类型"
              prop="typeName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.typeName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button @click="searchData">{{$t("operation.search")}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              label="设备名称"
              prop="deviceName"
            >
              <el-input
                v-model="dialog.deviceName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="设备品牌"
              prop="brandName"
            >
              <el-input
                v-model="dialog.brandName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="规格型号"
              prop="deviceModel"
            >
              <el-input
                v-model="dialog.deviceModel"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="详细规格"
              prop="detailed"
            >
              <el-input
                type="textarea"
                v-model="dialog.detailed"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="申购数量"
              prop="count"
            >
              <el-input-number
                v-model="dialog.count"
                :controls="false"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="需购回时间"
              prop="expecteBuyBackTime"
            >
              <el-date-picker
                v-model="dialog.expecteBuyBackTime"
                type="date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="用途"
              prop="useTo"
            >
              <el-input v-model="dialog.useTo"></el-input>
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remark"
            >
              <el-input v-model="dialog.remark"></el-input>
            </el-form-item>
            <el-form-item
              label="停厂替代"
              prop="isDiscontinued"
            >
              <el-radio-group v-model="dialog.isDiscontinued">
                <el-radio :label="1">Yes</el-radio>
                <el-radio :label="0">No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目名称">
              <el-input
                v-model="oldRecord.projectName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="合同编号">
              <el-input
                v-model="oldRecord.quotationNumber"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="合同类型">
              <el-input
                v-model="oldRecord.quotationType"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="客户审批">
              <el-input
                v-model="oldRecord.customerApproval"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="送货地址">
              <el-input
                v-model="oldRecord.address"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="收件人">
              <el-input
                v-model="oldRecord.consignee"
                disabled
              ></el-input>
            </el-form-item>
            <!-- <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="收件人">
                  <el-input
                    v-model="oldRecord.consignee"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电话"
                  prop="name"
                >
                  <el-input
                    v-model="dialog.name"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-form-item
              label="原因"
              prop="reason"
            >
              <el-input
                type="textarea"
                v-model="dialog.reason"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="附件"
              prop="attachment"
            >
              <div class="file-box">
                <div
                  v-for="(item,index) in dialog.attachment"
                  :key="index"
                  class="file-item"
                >
                  <div
                    class="block"
                    @click="previewFile(item)"
                  >
                    <img :src="'./static/image/fileType/' + item.formats + '.png'">
                    <i
                      class="el-icon-delete"
                      @click.stop="delFile(index)"
                    ></i>
                  </div>
                </div>
                <div
                  class="file-item add-item"
                  @click="showUpload"
                >
                  <div class="block">
                    <i class="el-icon-plus"></i>
                  </div>
                  <label></label>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!isRecord"
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
    <!-- 图片上传 -->
    <input
      type="file"
      ref="fileInput"
      hidden
      @change="fileInputChange"
    >
    <!-- 设备列表弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :is-create="true"
      @submit="equipmentSubmit"
    ></equipment-dialog>
  </div>
</template>

<script>
// 组件
import EquipmentDialog from "@/components/EquipmentDialog/EquipmentDialog3";
// 接口
import { UploadImage } from '@/api'
import {
  ReplaceApplyDevice,
  GetUpdateRecordItem,
  GetDeviceById,
} from '@/api/warehouse/purchaseRequisition'

export default {
  components: {
    EquipmentDialog
  },
  props: ['visible', 'isRecord', 'data', 'applicationFormId', 'deviceId'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      EquipmentDialogVisible: false,
      dialog: {
        visible: false,
        loading: false,
        title: '设备替换',
        deviceInfoId: '',
        deviceName: '',
        typeName: '',
        brandName: '',
        deviceModel: '',
        count: '',
        unit: '',
        useTo: '',
        expecteBuyBackTime: null,
        detailed: '',
        remark: '',
        isDiscontinued: 0,
        reason: '',
        attachment: [],
      },
      rules: {
        typeName: [{ required: true, message: this.$i18n.t("message.notNull"), trigger: "blur" }],
        count: [{ required: true, message: this.$i18n.t("message.notNull"), trigger: "blur" }],
        isDiscontinued: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        reason: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      oldRecord: {},
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.isRecord) {
          this.dialog.title = '替换记录';
          this.getLog();
        } else {
          if (!!this.deviceId) {
            this.getDeviceData();
          } else {
            this.oldRecord = this.data;
          }
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 获取设备信息
    getDeviceData () {
      let param = { id: this.deviceId }
      GetDeviceById(param).then(res => {
        this.oldRecord = res;
      })
    },
    searchData () {
      this.EquipmentDialogVisible = true;
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.dialog, {
        deviceInfoId: data.id,
        typeName: data.typeName,
        deviceName: data.name,
        brandName: data.brandName,
        deviceModel: data.model,
        unit: data.unit,
      });
      this.$refs.dialogForm.validateField("typeName");
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        if (
          this.oldRecord.count !== this.dialog.count &&
          (
            this.data.inCount > 0 ||
            this.data.preCount > 0 ||
            this.data.receiptCount > 0
          )
        ) return this.$message.warning('请撤销出入库后，在替换所选项目.');
        let param = {
          id: this.oldRecord.id,
          applicationFormId: this.applicationFormId,
          deviceInfoId: this.dialog.deviceInfoId,
          count: this.dialog.count,
          unit: this.dialog.unit,
          useTo: this.dialog.useTo,
          expecteBuyBackTime: this.dialog.expecteBuyBackTime,
          remark: this.dialog.remark,
          lockCount: this.oldRecord.lockCount,
          singlePrice: this.oldRecord.singlePrice,
          singlePriceUnit: this.oldRecord.singlePriceUnit,
          total: parseInt(this.dialog.count) * parseFloat(this.oldRecord.singlePrice),
          projectContractId: this.oldRecord.projectContractId,
          quotationTypeId: this.oldRecord.quotationTypeId,
          customerApprovalId: this.oldRecord.customerApprovalId,
          addressId: this.oldRecord.addressId,
          consigneeId: this.oldRecord.consigneeId,
          detailed: this.dialog.detailed,
          isDiscontinued: this.dialog.isDiscontinued,
          reason: this.dialog.reason,
          attachment: this.dialog.attachment.map(item => item.name).join(','),
        }
        ReplaceApplyDevice(param).then(res => {
          if (res.success) {
            this.$emit('update');
            this.$message.success(this.$i18n.t("message.edit"));
            this.dialog.visible = false;
          }
        })
      });
    },
    // 删除文件
    delFile (index) {
      this.dialog.attachment.splice(index, 1)
    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 显示上传
    showUpload () {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    fileInputChange () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let formats = res.message.match(/[^.]*$/)[0];
          this.dialog.attachment.push({
            name: res.message,
            formats: formats,
          });
        }
      })
    },
    getLog () {
      let param = { id: this.data.id }
      GetUpdateRecordItem(param).then(res => {
        if (!!res.attachment) {
          res.attachment = res.attachment.split(',').map(item => {
            let formats = item.match(/[^.]*$/)[0];
            return { name: item, formats: formats }
          })
        } else {
          res.attachment = [];
        }
        Object.assign(this.dialog, res);
        this.oldRecord = res.oldRecord;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
h4 {
  line-height: 32px;
  margin: 0;
}

.file-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 120px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: static;
    }
  }
}
</style>