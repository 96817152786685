<template>
  <div class="wrap">
    <el-card>
      <el-container>
        <el-aside width="350px">
          <el-row>
            <el-button-group class="input-group">
              <el-input
                v-model="treeSerachVal"
                class="search-input"
                size="medium"
                placeholder="Search"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="checkAll"
              >{{ $t("stores.btn3") }}</el-button>
            </el-button-group>
          </el-row>
          <el-tree
            :class="{'all': allState}"
            :data="treeData"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            v-loading="treeLoading"
            node-key="id"
            ref="tree"
            highlight-current
            @node-click="nodeClick"
          ></el-tree>
        </el-aside>
        <el-main>
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="$t('deviceManagement.tab1')"
              name="DeviceList"
            >
              <device-list
                ref="deviceList"
                :active="activeName"
                :branch="currentNode.id"
                :type=" currentNode.type"
                :company="rootNode.id"
              ></device-list>
            </el-tab-pane>
            <!-- <el-tab-pane
              label="Regular Maintenance"
              name="RegularMaintenance"
            >
               <regular-maintenance :active="activeName"></regular-maintenance> 
            </el-tab-pane> -->
          </el-tabs>
        </el-main>
      </el-container>
    </el-card>
  </div>
</template>

<script>
// 组件
import DeviceList from './components/DeviceList'
// import RegularMaintenance from './components/RegularMaintenance'
// 接口
import {
  GetStoreTree,
} from "@/api/stores";
export default {
  name: 'DeviceManagement',
  components: {
    DeviceList,
    // RegularMaintenance,
  },
  data () {
    return {
      permit: {
      },
      treeSerachVal: '',
      treeLoading: false,
      treeData: [],
      currentNode: {},
      rootNode: {},
      allState: true,

      activeName: 'DeviceList',
    }
  },
  watch: {
    treeSerachVal: function (val) {
      this.$refs.tree.filter(val);
    },
  },
  created () {
    this.getTreeData();
    this.dealPermission();
  },
  methods: {
    getTreeData () {
      let param = {
        type: '2',
      };
      this.treeLoading = true;
      GetStoreTree(param).then(res => {
        this.treeData = res;
        this.treeLoading = false;
      }).catch(error => {
        this.treeLoading = false;
      });
    },
    checkAll () {
      this.allState = true;
      this.currentNode = {};
      this.rootNode = {};
      this.$nextTick(() => {
        this.$refs.deviceList.getTableData();
      })
    },
    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 点击树节点
    nodeClick (data, node) {
      this.allState = false;
      this.currentNode = data;
      this.rootNode = this.getRootNode(node);
      this.$nextTick(() => {
        this.$refs.deviceList.getTableData();
      })
    },
    // 获取根节点
    getRootNode (node) {
      if (node.level === 1) {
        return node.data;
      } else {
        return this.getRootNode(node.parent);
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.update = permission.includes('ProjectContractUpdate');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-container {
  height: 100%;
}
.el-aside {
  margin-right: 20px;
}
.el-main {
  padding: 0;
}

.el-tree {
  height: calc(100% - 46px);
  border: 1px solid #e4e4e4;
  overflow: auto;
  &.all {
    background-color: #f0f7ff;
  }
}

.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
</style>