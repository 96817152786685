<template>
  <div>
    <div class="filer-wrap">
      <div class="filer-box">
        <div
          v-if="isBack"
          class="filer-item"
          @click="back"
        >
          <i class="el-icon-d-arrow-left"></i>
        </div>
        <div
          v-if="parent === 'CounterMode'"
          class="filer-item"
        >
          <i class="el-icon-s-data"></i>
        </div>

        <!-- 筛选条件 -->
        <template v-for="(item,index) in filterItem">
          <div
            v-if="item.prop !== 'templatesId'"
            :key="index"
            class="filer-item"
            @click="showDialog(item)"
          >
            <span>{{item.label}}</span>
            <i
              class="el-icon-close"
              @click.stop="closeCondition(index)"
            ></i>
          </div>
          <el-select
            v-else
            :key="index"
            class="filer-item"
            v-model="filterItem[0].val.id"
            size="medium"
            @change="submitReportTemplate"
            filterable
          >
            <el-option
              v-for="item in reportTemplates"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </template>
        <i
          class="el-icon-refresh filer-item filer-btn"
          @click="$emit('update')"
        ></i>
        <i
          class="el-icon-search filer-item filer-btn"
          @click="showQuery"
        ></i>
      </div>
      <div class="filer-btn-wrap">
        <el-dropdown
          class="filer-btn"
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            <i class="el-icon-more filer-btn"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <template v-if="parent === 'CounterMode'">
              <el-dropdown-item command="Unhide Table"> <i class="el-icon-s-grid"></i> Unhide Table</el-dropdown-item>
              <el-dropdown-item command="Unhide Charts"> <i class="el-icon-s-data"></i> Unhide Charts</el-dropdown-item>
              <el-dropdown-item command="Risk Level Settings"> <i class="el-icon-s-operation"></i> Risk Level Settings</el-dropdown-item>
              <el-dropdown-item command="Print..."> <i class="el-icon-printer"></i> Print...</el-dropdown-item>
              <el-dropdown-item command="Export"> <i class="el-icon-download"></i> Export</el-dropdown-item>
              <el-dropdown-item command="Layout Settings"> <i class="el-icon-menu"></i> Layout Settings</el-dropdown-item>
              <el-dropdown-item command="Save"> <i class="el-icon-s-claim"></i> Save</el-dropdown-item>
            </template>
            <template v-if="parent === 'KPI'">
              <el-dropdown-item command="Reset Charts"> <i class="el-icon-s-grid"></i> Reset Charts</el-dropdown-item>
              <el-dropdown-item command="Print..."> <i class="el-icon-printer"></i> Print...</el-dropdown-item>
              <el-dropdown-item command="Export"> <i class="el-icon-download"></i> Export</el-dropdown-item>
              <el-dropdown-item command="Layout Settings"> <i class="el-icon-menu"></i> Layout Settings</el-dropdown-item>
              <el-dropdown-item command="KPI Dashboard Templates"> <i class="el-icon-odometer"></i> KPI Dashboard Templates</el-dropdown-item>
              <el-dropdown-item command="Save"> <i class="el-icon-s-claim"></i> Save</el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 时间弹框 -->
    <date-dialog
      :visible.sync="DateDialogVisible"
      @submitTimeRange="submitTimeRange"
      ref="dateDialog"
    ></date-dialog>
    <!-- 店铺弹框 -->
    <store-dialog
      :visible.sync="StoreDialogVisible"
      :type-report="typeReport"
      @submitStore="submitStore"
      ref="storeDialog"
    ></store-dialog>
    <!-- 查询弹窗 -->
    <query
      :visible.sync="QueryVisible"
      :report-template-id="filterItem[0].val.id"
      :data="queryData"
      @submit="submitQuery"
    ></query>
    <!-- 导出弹框 -->
    <export-reports :visible.sync="ExportReportsVisible"></export-reports>
    <!-- 布局弹窗 -->
    <layout-setting
      :visible.sync="LayoutSettingVisible"
      @submit="submitLayout"
      :parent="parent"
    ></layout-setting>
    <!-- 表盘模板弹框 -->
    <dashboard-template :visible.sync="DashboardTemplateVisible"></dashBoard-template>
    <!-- 新增表盘模板弹框 -->
    <add-dashboard :visible.sync="AddDashboardVisible"></add-dashboard>
  </div>
</template>

<script>
import { debounce } from '@/utils/common'
import DateDialog from '@/components/DashBoardDialog/DateDialog'
import StoreDialog from '@/components/DashBoardDialog/StoreDialog'
import Query from '@/components/DashBoardDialog/Query'
import ExportReports from '@/components/DashBoardDialog/ExportReports'
import LayoutSetting from '@/components/DashBoardDialog/LayoutSetting'
import DashboardTemplate from '@/components/DashBoardDialog/DashboardTemplate'
import AddDashboard from '@/components/DashBoardDialog/AddDashboard'

import { GetTypeReport } from '@/api/reportTemplates'

export default {
  name: 'DashBoardCondition',
  components: {
    DateDialog,
    StoreDialog,
    Query,
    ExportReports,
    LayoutSetting,
    DashboardTemplate,
    AddDashboard,
  },
  props: {
    'isBack': {
      type: Boolean,
      default: false,
    },
    layout: [String, Number],
    parent: {
      type: String,
      default: 'Dashboard',
    },
    typeReport: [String, Number],
    reportTemplateId: [String],
    param: [Object],
  },
  data () {
    return {
      DateDialogVisible: false,
      StoreDialogVisible: false,
      QueryVisible: false,
      ExportReportsVisible: false,
      LayoutSettingVisible: false,
      DashboardTemplateVisible: false,
      AddDashboardVisible: false,
      layoutIndex: this.layout,
      filterItem: [{
        prop: 'templatesId',
        val: {
          id: '',
          mainLanguage: 0,
          subLanguage: 0,
        },
      }, {
        prop: 'date',
        label: 'Date',
        val: ''
      }, {
        prop: 'storesId',
        label: 'Stores',
        val: ''
      }],
      queryData: null,
      reportTemplates: [],
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.name === 'CounterMode' && this.typeReport == 1 || to.name === 'DiningMode' && this.typeReport == 2 || to.name === 'UserDefined' && this.typeReport == 3) {
        this.getTypeReport();
        if (!!this.reportTemplateId)
          this.filterItem[0].val.id = this.reportTemplateId;
      }
    },
  },
  created () {

    if (!!this.reportTemplateId)
      this.filterItem[0].val.id = this.reportTemplateId;

    if (this.typeReport !== undefined)
      this.getTypeReport()

  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 提交布局
    submitLayout (type) {
      this.$emit('update:layout', type);
    },
    showDialog (filterItem) {
      if (filterItem.label == 'Date' && filterItem.data === undefined)
        this.DateDialogVisible = true;
      else if (filterItem.label == 'Stores' && filterItem.data === undefined)
        this.StoreDialogVisible = true;
      else {
        this.QueryVisible = true;
        this.queryData = JSON.parse(JSON.stringify(filterItem.data));
      }
    },
    // 关闭条件筛选
    closeCondition (index) {
      this.filterItem.splice(index, 1)
      this.submitParam();
    },
    showQuery () {
      this.queryData = null;
      this.QueryVisible = true;
    },
    handleCommand (command) {
      switch (command) {
        case 'Risk Level Settings':
          this.$router.push({ path: '/home/riskLevelSettings', query: { isshow: 1 } })
          break;
        case 'Print...':
          break;
        case 'Export':
          this.ExportReportsVisible = true;
          break;
        case 'Layout Settings':
          this.LayoutSettingVisible = true;
          break;
        case 'Dashboard Templates':
          this.DashboardTemplateVisible = true;
          break;
        case 'Save':
          // this.saveDashboardDialog.title = 'New Dashboard Templates';
          this.AddDashboardVisible = true;
          break;
        case 'Edit':
          // this.saveDashboardDialog.title = 'Edit Dashboard Templates';
          this.AddDashboardVisible = true;
          break;
        case 'KPI Dashboard Templates':
          break;
      }
    },
    // 获取报表模板
    getTypeReport () {
      let param = {
        mode: this.typeReport
      };
      GetTypeReport(param).then(res => {
        this.reportTemplates = res;
        let arr = this.reportTemplates.filter(item => item.id === this.filterItem[0].val.id);
        if (arr.length === 0 && res.length !== 0) arr = [res[0]];

        if (res.length !== 0 || arr.length !== 0) {
          this.filterItem[0].val.id = arr[0].id;
          this.filterItem[0].val.mainLanguage = arr[0].mainLanguage;
          this.filterItem[0].val.subLanguage = arr[0].subLanguage;
          this.submitParam();
        }
      })
    },
    // 提交报表模板
    submitReportTemplate (id) {
      let template = this.reportTemplates.filter(item => item.id === id)[0]

      this.$refs.dateDialog.reset();
      this.$refs.storeDialog.reset();
      this.filterItem = [{
        prop: 'templatesId',
        val: {
          id: id,
          mainLanguage: template.mainLanguage,
          subLanguage: template.subLanguage,
        },
      }, {
        prop: 'date',
        label: 'Date',
        val: ''
      }, {
        prop: 'storesId',
        label: 'Stores',
        val: ''
      }];
      this.submitParam();
    },
    // 提交时间段
    submitTimeRange (dateRang) {
      let data = this.filterItem.filter(item => item.label === 'Date' && item.data === undefined)[0];
      if (dateRang === null || dateRang.length !== 2)
        data.val = '';
      else
        data.val = dateRang[0] + '~' + dateRang[1];
      this.DateDialogVisible = false;
      this.submitParam();
    },
    // 提交店铺
    submitStore (stores) {
      let data = this.filterItem.filter(item => item.label === 'Stores' && item.data === undefined)[0];
      data.val = stores.join(',');
      this.StoreDialogVisible = false;
      this.submitParam();
    },
    // 提交查询
    submitQuery (data) {
      let index = this.filterItem.findIndex(item => item.prop === data.field);
      let item = {
        prop: data.field,
        label: data.field,
        data: data,
      };
      if (index == -1)
        this.filterItem.push(item)
      else
        this.filterItem.splice(index, 1, item)
      this.submitParam();
    },
    // 提交参数
    submitParam () {
      let paramCom = {};
      this.$store.commit('clearCondition');
      this.filterItem.forEach(item => {
        if (item.data === undefined) {
          paramCom[item.prop] = item.val;
        } else {
          if (item.data.queryType === 0) {
            this.$store.commit('setCondition', {
              key: item.prop,
              type: item.data.fieldType,
              data: item.data,
            })
          } else {
            if (item.data.fieldType === 'date') {
              paramCom[item.prop] = item.data.dateRange.length > 0 ? item.data.dateRange[0] + '~' + item.data.dateRange[1] : '';
            } else {
              paramCom[item.prop] = item.data.condition;
            }
          }
        }
      })
      this.$emit('update:param', JSON.parse(JSON.stringify(paramCom)));
    },
  }
}
</script>

<style lang="scss" scoped>
.filer-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .filer-box {
    max-width: calc(100% - 120px);
    font-size: 14px;
    display: flex;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    overflow: hidden;
    .filer-item {
      line-height: 36px;
      border-left: 1px solid #e4e4e4;
      padding: 0 10px;
      cursor: pointer;
      &:first-child {
        border-left: none;
      }
      &:active {
        background: #e4e4e4;
      }
      .el-icon-close {
        margin-left: 5px;
        font-size: 14px;
        border-radius: 50%;
        &:hover {
          background-color: #b4bccc;
        }
      }
    }
    .filer-item.el-select {
      padding: 0;
      ::v-deep .el-input__inner {
        // padding:0;
        border: none;
      }
    }
    .filer-btn {
      height: 36px;
      line-height: 36px;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      border-left: 1px solid #e4e4e4;
      &:first-child {
        border-left: none;
      }
      &:hover {
        background: #f0f2f5;
      }
    }
  }
  .filer-btn-wrap {
    display: flex;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    .filer-btn {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      border-left: 1px solid #e4e4e4;
      &:first-child {
        border-left: none;
      }
      &:hover {
        background: #f0f2f5;
      }
    }
  }
}
</style>