<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newServiceConfirmationForm.issueKeyword.title1')"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <i
            v-show="activeName === 'tab3'||activeName === 'tab4'"
            class="icon-filter"
            @click="showType"
          ></i>
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="`${$t('newServiceConfirmationForm.issueKeyword.tab1')}(${tags.length})`"
              name="tab1"
            >
              <el-table
                border
                :data="tags"
                :height="350"
                :show-header="false"
                @row-click="deselect"
              >
                <el-table-column width="50">
                  <i
                    slot-scope="scope"
                    class="el-icon-check"
                  ></i>
                </el-table-column>
                <el-table-column prop="description">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('newServiceConfirmationForm.issueKeyword.tab2')"
              name="tab2"
            >
              <el-table
                border
                :data="faultTypeLabels"
                :height="350"
                :show-header="false"
                v-loading="tableLoading"
                @row-click="rowClick"
              >
                <el-table-column width="50">
                  <i
                    slot-scope="scope"
                    class="el-icon-check"
                    v-if="scope.row.checked"
                  ></i>
                </el-table-column>
                <el-table-column prop="description">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('newServiceConfirmationForm.issueKeyword.tab3')"
              name="tab3"
            >
              <el-table
                border
                :data="faultTypeLabels"
                :height="350"
                :show-header="false"
                v-loading="tableLoading"
                @row-click="rowClick"
              >
                <el-table-column width="50">
                  <i
                    slot-scope="scope"
                    class="el-icon-check"
                    v-if="scope.row.checked"
                  ></i>
                </el-table-column>
                <el-table-column prop="description">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('newServiceConfirmationForm.issueKeyword.tab4')"
              name="tab4"
            >
              <el-table
                border
                :data="faultTypeLabels"
                :height="350"
                :show-header="false"
                v-loading="tableLoading"
                @row-click="rowClick"
              >
                <el-table-column width="50">
                  <i
                    slot-scope="scope"
                    class="el-icon-check"
                    v-if="scope.row.checked"
                  ></i>
                </el-table-column>
                <el-table-column prop="description">
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="12">
          <label class="title">{{$t('newServiceConfirmationForm.issueKeyword.label2')}}</label>
          <div class="label-item">{{$t('newServiceConfirmationForm.issueKeyword.label3')}}</div>
          <el-input
            class="textarea"
            type="textarea"
            v-model="describe"
            disabled
          ></el-input>
          <div class="label-item">{{$t('newServiceConfirmationForm.issueKeyword.label4')}}</div>
          <el-input
            class="textarea"
            type="textarea"
            v-model="dispose"
            disabled
          ></el-input>
        </el-col>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="showCreate"
        >{{$t('newServiceConfirmationForm.issueKeyword.btn1')}}</el-button>
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>

    <!-- 故障类型 -->
    <fault-type
      :visible.sync="FaultTypeVisible"
      @submit="faultTypeSubmit"
    ></fault-type>
    <!-- 设备类型  -->
    <device-type
      :visible.sync="DeviceTypeVisible"
      @submit="deviceTypeSubmit"
    ></device-type>
  </div>
</template>

<script>
// 组件
import FaultType from './FaultType'
import DeviceType from './DeviceType'
// 接口
import { GetFaultTypeByDeviceType } from '@/api/warehouse/dataMaintenance/faultType'

export default {
  components: {
    FaultType,
    DeviceType,
  },
  props: ['visible', 'tagsData', 'describe', 'dispose'],
  data () {
    return {
      FaultTypeVisible: false,
      DeviceTypeVisible: false,
      dialog: {
        visible: false,
        title: '',
      },
      activeName: 'tab2',
      searchVal: '',
      tableLoading: false,
      tags: [],
      faultTypes: [],
      faultTypeLabels: [],
      deviceType: [],
      faultType: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.tags = !!JSON.parse(JSON.stringify(this.tagsData)) ? JSON.parse(JSON.stringify(this.tagsData)) : [];
        this.searchVal = '';
        this.deviceType = [];
        this.faultType = [];
        this.initData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'searchVal': function (newVal) {
      this.searchData();
    },
    'activeName': function () {
      if (this.activeName === 'tab1') return;
      this.searchData();
    },
  },
  created () {

  },
  methods: {
    initData () {
      GetFaultTypeByDeviceType({ search: '' }).then(res => {
        let ids = this.tags.map(item => item.id);
        this.faultTypes = res.map(item => {
          item.checked = ids.includes(item.id);
          return item;
        });
        this.faultTypeLabels = this.faultTypes.slice(0, 100);
      })
    },
    searchData () {
      let tempData
      switch (this.activeName) {
        case 'tab2':
          tempData = this.faultTypes;
          break;
        case 'tab3':
          let deviceTypeId = this.deviceType.map(item => item.id);
          tempData = this.faultTypes.filter(item => deviceTypeId.length === 0 || deviceTypeId.includes(item.deviceTypeId));
          break;
        case 'tab4':
          let faultTypeId = this.faultType.map(item => item.id);
          tempData = this.faultTypes.filter(item => faultTypeId.length === 0 || faultTypeId.includes(item.faultTypeId));
          break;
      }

      this.faultTypeLabels = !!this.searchVal ? tempData.filter(item => item.description.indexOf(this.searchVal) !== -1) : tempData.slice(0, 100);
    },
    rowClick (row) {
      row.checked = !row.checked;
      if (row.checked) {
        this.tags.push(row);
      } else {
        let index = this.tags.findIndex(item => item.id === row.id);
        this.tags.splice(index, 1);
      }
    },
    submit () {
      this.$emit('submit', this.tags);
      this.dialog.visible = false;
    },
    showType () {
      switch (this.activeName) {
        case 'tab3':
          this.DeviceTypeVisible = true;
          break;
        case 'tab4':
          this.FaultTypeVisible = true;
          break;
      }
    },
    // 故障类型提交
    faultTypeSubmit (data) {
      this.faultType = data;
      this.searchData();
    },
    // 设备类型提交
    deviceTypeSubmit (data) {
      this.deviceType = data;
      this.searchData();
    },
    // 
    showCreate () {
      this.$prompt(this.$i18n.t('newServiceConfirmationForm.issueKeyword.label1'), {
        confirmButtonText: this.$i18n.t('operation.confirm'),
        cancelButtonText: this.$i18n.t('operation.cancel'),
        inputPattern: /^.+$/,
        inputErrorMessage: this.$i18n.t('message.notNull')
      }).then(({ value }) => {
        this.faultTypes.push({ description: value, id: '', checked: true })
        this.tags.push({ description: value, id: '' });
        this.searchData();
      }).catch(() => { });
    },
    deselect (row) {
      let index = this.tags.findIndex(item => item.id === row.id);
      this.tags.splice(index, 1);

      let fault = this.faultTypeLabels.find(item => item.id === row.id)
      if (!!fault) {
        fault.checked = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-box {
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-filter {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 370px;
  z-index: 1;
}
.title {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}
.label-item {
  height: 40px;
  line-height: 40px;
}
.textarea {
  ::v-deep .el-textarea__inner {
    height: 162px;
  }
}
</style>