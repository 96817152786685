<template>
  <div>
    <el-table
      border
      ref="table"
      :data="tableData"
      :height="tableHeight"
      v-loading="tableLoading"
      @selection-change="selectionChange"
      @row-dblclick="rowDblclick"
      @row-click="rowClick"
      :default-sort="{prop: 'createTime', order: 'descending'}"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        min-width="150"
        sortable
        prop="createTime"
        :label="$t('purchaseOrders.col1')"
        :formatter="$formatTableData"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        sortable
        prop="orderNumber"
        :label="$t('purchaseOrders.col2')"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        sortable
        prop="supplierName"
        :label="$t('purchaseOrders.col8')"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        sortable
        prop="paymentDate"
        :label="$t('purchaseOrders.col3')"
      >
      </el-table-column>
      <el-table-column
        min-width="120"
        sortable
        v-if="permit.showPOAmount"
        prop="totalPrice"
        :label="$t('purchaseOrders.col4')"
      >
      </el-table-column>
      <el-table-column
        min-width="100"
        sortable
        prop="orderUser"
        :label="$t('purchaseOrders.col5')"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        sortable
        show-overflow-tooltip
        prop="projectName"
        :label="$t('purchaseOrders.col6')"
      >
        <template slot-scope="scope">
          <div
            class="cell-style"
            v-for="(item,index) in scope.row.projectName"
            :key="index"
          >{{item}}</div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="170"
        sortable
        prop="quotationNumber"
        :label="$t('purchaseOrders.col7')"
      >
        <template slot-scope="scope">
          <div
            class="cell-style"
            v-for="(item,index) in scope.row.quotationNumber"
            :key="index"
          >{{item}}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['tableLoading', 'tableSelected', 'tableData', 'permit', 'tableHeight'],
  data () {
    return {

    }
  },
  methods: {
    selectionChange (val) {
      this.$emit('update:tableSelected', val)
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    rowDblclick (e) {
      this.$emit('row-dblclick', e)
    }
  }
}
</script>

<style>
</style>