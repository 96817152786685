<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="deviceCode"
          >{{$t('deviceManagement.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="showImportFile"
          >{{$t('operation.import')}}</el-button>
          <input
            type="file"
            hidden
            ref="importFile"
            @change="importDevice"
          >
          <el-button
            size="medium"
            @click="exportPDF"
          >{{$t('deviceManagement.btn2')}}</el-button>
          <el-button
            size="medium"
            @click="exportPDFCode"
          >{{$t('deviceManagement.btn3')}}</el-button>
          <el-button
            size="medium"
            @click="showUpdate"
          >{{$t('operation.update')}}</el-button>
          <el-button
            size="medium"
            @click="showMerge"
          >{{$t('operation.merge')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="deviceInfoName"
          :label="$t('deviceManagement.col1')"
        >
        </el-table-column>
        <el-table-column
          min-width="220"
          :label="$t('deviceManagement.col2')"
          prop="opStatus"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          show-overflow-tooltip
          prop="deviceInfoModel"
          :label="$t('deviceManagement.col3')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="location"
          :label="$t('deviceManagement.col4')"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="warrantyExpiryDate"
          :label="$t('deviceManagement.col5')"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="qrCode"
          :label="$t('deviceManagement.col6')"
        >
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <span slot="reference">{{scope.row.qrCode}}</span>
              <qrcode :value="scope.row.qrCode"></qrcode>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="serialNumber"
          :label="$t('deviceManagement.col7')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('deviceManagement.col8')"
          prop="src"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 更新弹框 -->
    <update-dialog
      :visible.sync="UpdateDialogVisible"
      :ids="ids"
      @update="getTableData"
    ></update-dialog>
    <!-- 合并弹框 -->
    <merge-dialog
      :visible.sync="MergeDialogVisible"
      :data="tableSelected"
      @update="getTableData"
    ></merge-dialog>
  </div>
</template>

<script>

// 全局方法
import { downFile } from '@/utils/common'
// 组件
import qrcode from '@/components/qrcode'
import UpdateDialog from './UpdateDialog'
import MergeDialog from './MergeDialog'
// 接口
import {
  GetDeviceList,
  DelDevice,
  ImprotDevice,
  ExportPDF,
  ExportQRCode,
} from '@/api/warehouse/deviceManagement'
export default {
  components: {
    qrcode,
    UpdateDialog,
    MergeDialog,
  },
  props: ['active', 'branch', 'company', 'type'],
  data () {
    return {
      UpdateDialogVisible: false,
      MergeDialogVisible: false,
      permit: {
        search: true,
        add: true,
        del: true,
      },
      baseURL: process.env.VUE_APP_BASE_URL,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      userData: null,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      ids: '',
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'DeviceManagement') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }

    },
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        branchId: this.branch,
        all: 1,
      }
      this.tableLoading = true;
      GetDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({
        path: '/home/newDevice', query: {
          company: this.company,
          organic: this.branch,
          type: this.type,
        }
      })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0])
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newDevice', query: { id: e.id, organic: this.branch } })

    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelDevice(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          } else {
            this.$message.warning(res.data)
          }
        })
      }).catch(() => { });
    },
    // 设备二维码
    deviceCode () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      localStorage.deviceData = JSON.stringify(this.tableSelected);
      this.$router.push({ path: '/home/deviceCode', query: { type: 1 } })
    },
    showImportFile () {
      this.$refs.importFile.value = null;
      this.$refs.importFile.click()
    },
    // 导入
    importDevice () {
      let formData = new FormData();
      let file = this.$refs.importFile.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      formData.append('name', 'xxx');
      formData.append('demId', 'xxx');
      ImprotDevice(formData).then(res => {
        if (res.success) {
          this.$message.success('导入成功');
        }
      })
    },
    // 导出PDF
    exportPDF () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      let param = { codeIds: this.tableSelected.map(item => item.id).join(',') };
      ExportPDF(param).then(res => {
        downFile(res, '导出PDF.pdf')
        this.$message.success('导出成功');
      })
    },
    // 导出PDF二维码
    exportPDFCode () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      let param = { codeIds: this.tableSelected.map(item => item.id).join(',') };
      ExportQRCode(param).then(res => {
        downFile(res, '导出PDF二维码.pdf');
        this.$message.success('导出成功');
      })
    },
    // 显示更新弹框
    showUpdate () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.ids = this.tableSelected.map(item => item.id).join(',');
      this.UpdateDialogVisible = true;
    },
    // 显示合并弹框
    showMerge () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.some(item => item.branchDomId !== this.tableSelected[0].branchDomId)) return this.$message.warning('设备网点不同不能合并.')
      this.MergeDialogVisible = true;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('DeviceManagementSearch');
        this.permit.add = permission.includes('DeviceManagementAdd');
        // this.permit.edit = permission.includes('DeviceManagementEdit');
        this.permit.del = permission.includes('DeviceManagementDelete');
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>
