<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="800px"
    >
      <el-row>
        <div class="input-wrap">
          <div class="left-box">
            <el-button-group class="input-group">
              <el-input
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
            </el-button-group>
          </div>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @row-click="rowClick"
          @selection-change="val => tableSelected = val"
          row-key="id"
        >
          <el-table-column
            type="selection"
            reserve-selection
          ></el-table-column>
          <el-table-column
            min-width="80"
            prop="sn"
            label="No."
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceInfoName"
            label="Item Name"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceInfoModel"
            label="Model"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="brandName"
            label="Brand"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="location"
            label="Location"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="label"
            label="DispalyName"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceId"
            label="DeviceID"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="serialNumber"
            label="Serial Number"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <!-- <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="page.size"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row> -->
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

// 接口
import {
  GetDeviceList,
} from '@/api/warehouse/deviceManagement'
export default {
  props: ['visible', 'deviceId'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Related with device',
      },
      page: {
        total: 0,
        size: 9999,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 385,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.$refs.table && this.$refs.table.clearSelection();
        this.searchVal = '';
        this.searchData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        branchId: sessionStorage.checkOrgId,
        // all: 1,
      }
      this.tableLoading = true;
      GetDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 行点击
    rowClick (row, column, event) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 取消
    submit () {
      let data = this.tableSelected.sort((a, b) => a.sn - b.sn);
      this.$emit('submit', data)
      this.dialog.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>