<template>
  <div class="wrap">
    <el-card>
      <el-tabs v-model="tabActive">
        <el-tab-pane
          :label="$t('projectContractManagement.tab1')"
          name="Contract"
        >
          <contract :active="tabActive"></contract>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('projectContractManagement.tab2')"
          name="SLAMaintenance"
        >
          <sla-maintenance :active="tabActive"></sla-maintenance>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import Contract from './components/ProjectContract'
import SlaMaintenance from './components/SLAMaintenance'
// 接口
// import { GetLeveListAll } from '@/api/riskOfLossLevel'

export default {
  name: 'ProjectContract',
  components: {
    Contract,
    SlaMaintenance,
  },
  data () {
    return {
      permit: {
      },
      tabActive: 'Contract',
    }
  },

  created () {
    this.dealPermission();
  },
  methods: {
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.orgTab = permission.includes('OrganizationsView');
        // this.permit.dataTab = permission.includes('AccessGroupsView');
        // this.tabActive = this.permit.orgTab ? 'Organizations' : 'DataAccessGroups';
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>
