import cnLocale from 'element-ui/lib/locale/lang/zh-CN'

const cn = {
  ...cnLocale,
  login: {
    login: '登录'
  },
  menu: {
    DashBoard: '概览',
    KPIs: 'KPI',
    KpiDashboard: 'KPI概览',
    KpiTemplate: 'KPI模板',
    CounterMode: '常用报表',
    DiningMode: '管理员报表',
    UserDefined: '自定义报表',

    SystemSettings: '系统配置',
    UserManagement: '用户管理',
    Organizations: '组织机构',
    Roles: '角色',
    StoreGroupEditor: '网点组',
    ReportGroupEditor: '报表组',
    Stores: '网点管理',
    ContactList: '联系人列表',
    SMTP: 'SMTP配置',
    AccountPolicies: '账户安全策略',

    ReportSettings: '报表配置',
    ReportTemplates: '报表模板',
    ReportGroup: '报表分类',
    ReportDesigner: '报表设计',
    RiskOfLossLevel: '风险等级',
    SystemSchedule: '系统任务',
    Schedule: '任务计划',
    SystemLog: '系统日志',

    BgbOasSettings: 'BGB OAS数据管理',
    DataMaintenance: '数据类型',
    ApprovalWorkflow: '审批流程',
    BgbProjectManagement: 'BGB项目管理',

    ProjectContract: '项目合同',
    Projects: '项目进度',
    StandardTask: '标准任务',
    ProjectDocuments: '项目文档',
    SLAPolicies: 'SLA 政策',
    OperationalTime: '营业时间',

    BgbService: 'BGB服务与支持',
    SubmitRequest: '提交工单',
    Maintenance: '维护',
    ServiceConfirmationForm: '服务确认单',
    DeviceManagement: '设备管理',
    WorkOrderScheduling: '工单调度',
    EngineerManagement: '人员管理',

    BgbIms: 'BGB库存管理',
    InventoryManagement: '库存管理',
    PurchaseRequisition: '购物申请',
    PurchaseOrders: '采购订单',
    InventoryStatistics: '库存统计',
    Quotation: '报价管理',
    ProductInformation: '产品信息',

    Notifications: '通知',
    Notification: '系统通知',
    DailyDataSync: '数据同步',
    RequestForApproval: '申请与批复',

    ComplaintsEvaluation: '投诉与评价',

    AppReleaseManagement: '应用发行管理',
    AppRelease: '应用发行',

  },
  message: {
    add: '添加成功',
    edit: '编辑成功',
    del: '删除成功',
    please: '请输入',
    cancel: '撤销成功',
    save: '保存成功',
    copy: '复制成功',
    update: '更新成功',
    notNull: '不能为空',
    download: '下载成功',
    export: '导出成功',
    import: '导入成功',
    upload: '上传成功',
    receipt: '签收成功',
  },
  operation: {
    search: '搜索',
    add: '新增',
    edit: '编辑',
    del: '删除',
    import: '导入',
    cancel: '取消',
    submit: '提交',
    save: '保存',
    repeal: '撤销',
    evaluate: '评价',
    complaint: '投诉',
    send: '派单',
    updateState: '状态更新',
    staffStatus: '人员状态',
    saveSend: '保存，并派单',
    reassign: '改派',
    auto: '自动',
    saveAdd: '保存并新增',
    refresh: '刷新',
    update: '更新',
    reset: '重置',
    copy: '复制',
    move: '移动',
    export: '导出',
    confirm: '确定',
    close: '关闭',
    filter: '筛选',
    merge: '合并',
    print: '打印',
    date: {
      text1: '最近一周',
      text2: '最近一个月',
      text3: '最近三个月',
    }
  },
  home: {
    layer: {
      btn1: '隐藏菜单',
      btn2: '固定菜单',
      btn3: '折叠菜单',
    },
    menu: {
      btn1: '更改密码',
      btn2: '我的个人资料',
      btn3: '帮助',
      btn4: '退出',
      btn5: '关闭其他标签',
      btn6: '关闭所有标签',
    },
    password: {
      title1: '更改密码',
      label1: '当前密码',
      label2: '新密码',
      label3: '确认新密码',
    },
    userInfo: {
      title1: '编辑个人资料',
      label1: '全名',
      label2: '邮箱1',
      label3: '邮箱2',
      label4: '电话号码1',
      label5: '电话号码2',
    },
    help: {
      title1: '帮助',
      content: '请您联系系统管理员以寻求帮助。',
      contact: '请与我们联系',
    }
  },

  userManagement: {
    col1: '用户名',
    col2: '组织机构',
    col3: '角色',
    col4: '数据访问组',
    col5: '网点访问组',
    col6: '报表访问组',
    col7: '邮箱',
    col8: '电话',
    col9: '状态',
    col10: '全名',
  },
  newUser: {
    title1: '新增用户',
    title2: '编辑用户',
    title3: '查看用户',
    subtitle1: '用户信息',
    subtitle2: '权限',
    label1: '用户名',
    label2: '密码',
    label3: '全名',
    label4: '邮箱地址',
    label5: '备用邮箱地址',
    label6: '电话号码',
    label7: '备用电话号码',
    label8: '数据访问组',
    label9: '角色',
    label10: '组织机构',
    label11: '网点访问组',
    label12: '报表访问组',
    label13: '概览样式模板',
    label14: ' KPI概览样式模板',
    label15: '需此用户审批（编辑系统默认设置）',
    label16: '需此用户审批（编辑样式模板）',
    label17: '帐户状态',
    message1: '密码包含英文大写字符（A-Z）、英文小写字母（a-z）、Base 10 Digits（0-9）、特殊字符、Unicode字符中至少三个的字符',
    message2: '密码不能少于',
    message3: '个字符',
  },
  storeGroupEditor: {
    title1: '网点访问组编辑',
    subtitle1: '选择分类',
    subtitle2: '选择网点',
    btn1: '切换',
    col1: '网点访问组名称',
    col2: '数据访问组',
    col3: '网点ID',
    col4: '网点名称',
    dialog: {
      title1: '新增网点访问组',
      title2: '编辑网点访问组',
      label1: '网点访问组名称',
      label2: '数据访问组',
    },
  },
  reportGroupEditor: {
    title1: '报表访问组编辑',
    subtitle1: '选择分类',
    subtitle2: '选择报表',
    col1: '报表访问组名称',
    col2: '数据访问组',
    col3: '报表模板',
    col4: '描述',
    col5: '数据访问组',
    dialog: {
      title1: '新增报表访问组',
      title2: '编辑报表访问组',
      label1: '报表访问组名称',
      label2: '数据访问组',
    },
  },
  organizations: {
    tab1: '组织机构',
    tab2: '数据访问组',
    subtitle1: '组织架构',
    subtitle2: '配置参数',
    col1: '组织机构名称',
    col2: '描述',
    col3: '数据访问组',
    col4: '创建人',
    col5: '创建日期',
    col6: '修改日期',
    label1: '主题颜色',
    label2: '单据模板',
    label3: '系统概览模板',
    label4: ' KPI模板',
    label5: ' KPI概览模板',
    dialog: {
      title1: '新建组织机构',
      title2: '编辑组织机构',
      label1: '组织机构名称',
      label2: '描述',
      label3: '数据访问组（数据权限）',
    },
    btn1: '共享',
    btn2: '添加子节点',
    nodeDialog: {
      title1: '添加子节点',
      title2: '编辑节点',
      label1: '节点类型',
      label2: '子节点名称',
      label3: '节点名称',
      label4: '描述',
    },
    shareDialog: {
      title1: '共享组织架构',
      col1: '组织机构名称',
    },
  },
  dataAccessGroup: {
    label1: '数据访问组名称',
    label2: '描述',
    label3: '数据访问组',
    label4: '创建人',
    label5: '创建日期',
    label6: '修改日期',
  },
  newDataAccessGroup: {
    title1: '新增数据访问组',
    title2: '编辑数据访问组',
    title3: '查看数据访问组',
    label1: '数据访问组名称',
    label2: '数据访问组（数据权限）',
    label3: '描述',
    label4: '组织机构列表',
    label5: '已选择',
    col1: '序号',
    col2: '组织机构',
    col3: '描述',
  },
  roles: {
    col1: '角色名称',
    col2: '描述',
    col3: '数据访问组',
    col4: '创建人',
    col5: '创建日期',
    col6: '修改日期',
    subtitle1: '系统功能',
    subtitle2: '操作权限',
    btn1: '添加节点',
    btn2: '添加子节点',
    roleDialog: {
      title1: '新增角色',
      title2: '编辑角色',
      label1: '角色名称',
      label2: '描述',
      label3: '数据访问组（数据权限）',
    },
    itemDialog: {
      title1: '新增导航栏',
      title2: '编辑导航栏',
      label1: '导航栏名称',
      label2: '导航栏排序',
    },
    permissionsDialog: {
      title1: '新增权限',
      title2: '编辑权限',
      label1: '权限名称',
      label2: '权限排序',
    }
  },
  stores: {
    btn1: '添加主节点',
    btn2: '添加子节点',
    btn3: '全选',
    label1: '选定节点',
    label2: '描述',
    col1: '网点ID',
    col2: '网点名称',
    col3: '类型',
    col4: '地址',
    col5: '状态',
    col6: '上一节点',
    nodeDialog: {
      title1: '添加新节点',
      title2: '添加子节点',
      title3: '编辑节点',
      label1: '节点名称',
      label2: '子节点名称',
      label3: '描述',
      label4: '类型',
      label5: '数据访问组（数据权限）',
    },
    storeDialog: {
      title1: '新建网点',
      title2: '编辑网点',
      label1: '网点ID',
      label2: '网点名称',
      label3: '类型',
      label4: '地址',
      label5: '状态',
      option1: '正常',
      option2: '未知',
      option3: '已结束',
      option4: '未生效',
    },
    grouping: {
      title1: '树节点显示',
      label1: '分组',
    },
    storeTree: {
      title1: '移动网点',
      label1: '选择目标节点',
    }
  },
  contactList: {
    col1: '姓名',
    col2: '邮箱',
    col3: '备用邮箱',
    col4: '组织机构',
    col5: '网点访问组',
    col6: '报表访问组',
    col7: '系统用户',
    col8: '职位',
    col9: '员工 ID',
    col10: '电话号码',
    dialog: {
      title1: '新建联系人',
      title2: '编辑联系人',
      label1: '姓名',
      label2: '组织机构',
      label3: '数据访问组',
      label4: '邮箱',
      label5: '备用邮箱',
      label6: '电话号码',
      label7: '备用电话号码',
      label8: '网点访问组',
      label9: '报表访问组',
      label10: '状态',
      label11: '创建系统账号',
      label12: '网点负责人',
      label13: '员工 ID',
      label14: '职位',
      label15: '网站',
      col1: '站点 ID',
      col2: '站点名称',
    }
  },
  smtp: {
    title1: 'SMTP配置',
    btn1: '测试',
    label1: '用户名',
    label2: 'SMTP服务器',
    label3: '安全连接',
    label4: '密码',
    label5: '端口',
    label6: '服务器超时',
  },
  accountPolicies: {
    title1: '账户安全策略',
    label1: '尝试登录限制',
    label2: '账户锁定持续时间',
    label3: '最小密码长度',
    label4: '密码有效期限（天）',
    label5: '密码必须满足复杂性要求',
    subtitle1: '密码组合要求',
    subtitle2: '已被阻挡的IP',
    p1: '1.密码长度至少为六个字符',
    p2: '2.密码包含以下五个类别中至少三个的字符',
    p3: 'a.英文大写字符（A-Z）',
    p4: 'b.英文小写字母（a-z）',
    p5: 'c.Base 10 Digits（0-9）',
    p6: 'd.特殊非字母数字字符（例如：！，@，＃，$，％）',
    p7: 'e.Unicode字符',
    col1: 'IP地址',
    col2: '阻挡日期',
  },
  systemLog: {
    btn1: '打印',
    col1: '日期',
    col2: '类型',
    col3: '类别',
    col4: '描述',
    col5: '用户',
  },
  schedule: {
    col1: '任务名称',
    col2: '重复发生',
    col3: '数据访问组',
    col4: '创作者',
    col5: '任务状态',
    col6: '操作',
    col7: '报告模板',
  },
  newSchedule: {
    title1: '新增任务',
    title2: '编辑任务',
    title3: '查看任务',
    label1: '任务名称',
    label2: '描述',
    label3: '数据访问组',
    label4: '（数据权限）',
    label5: '时间',
    label6: '重复发生',
    label7: '特定事件',
    label8: '操作',
    label9: '报告模板',
    label10: '任务状态',
    tab1: '触发器',
    tab2: '操作',
    col1: '全名',
    col2: '网点组',
    col3: '电子邮件1',
    col4: '电话1',
    col5: '电子邮件2',
    col6: '电话2',
  },
  contactDialog: {
    title1: '添加联系人',
    col1: '全名',
    col2: '网站组',
    col3: '电子邮件1',
    col4: '电话1',
    col5: '电子邮件2',
    col6: '电话2',
  },




  projectContractManagement: {
    tab1: '合同',
    tab2: 'SLA&维护',
    contract: {
      col1: '项目名称',
      col2: '合同报价编号',
      col3: '项目类型',
      col4: '合同状态',
      col5: '客户名称',
      col6: '签署日期',
      col7: '开工',
      col8: '竣工',
      col9: '合同开始',
      col10: '合同结束',
      col11: '维保开始',
      col12: '维保结束',
      tag1: '未生效',
      tag2: '正常',
      tag3: '已结束',
      tag4: '未知',
    },
    sla: {
      col1: '网点名称',
      col2: '网点类型',
      col3: 'SLA 状态',
      col4: '维护类型',
      col5: 'SLA 开始',
      col6: 'SLA 结束',
      col7: '地址',
      col8: '客户',
      col9: '项目名称',
      col10: 'SLA 政策',
      col11: 'SLA 类型',
    }
  },
  newProjectContract: {
    title: '新增项目',
    title2: '编辑项目',
    title3: '添加网点',
    title4: '编辑网点',
    title5: '查看项目',
    subtitle1: '基本信息',
    subtitle2: '项目信息',
    subtitle3: '期限',
    subtitle4: '项目包含网点',
    btn1: '续签',
    label1: '项目名称',
    label2: '项目类型',
    label3: '签署日期',
    label4: '合同报价编号',
    label5: '客户名称',
    label6: '数据访问组',
    label7: '开工/竣工时间',
    label8: '合同起止日期',
    label9: '维保起止日期',
    label10: '备注',
    label11: 'SLA 政策',
    label12: 'SLA 类型',
    label13: 'SLA起止日期',
    label14: '保修类型',
    col1: '网点名称',
    col2: '维保类型',
    col3: '维保开始',
    col4: '维保终止',
    col5: '联系人',
    col6: '电话',
    col7: '地址',
    col8: '备注',
    col9: '网点类型',
    col10: '维保状态',
    col11: 'SLA开始',
    col12: 'SLA结束',
    tag1: '未生效',
    tag2: '正常',
    tag3: '已结束',
    tag4: '未知',
    tips1: '(请填写此合同所包含或对应的网点信息，填写后才能提交。)',
    tips2: '修改客户将清空项目所包含的网点，请确定！',
    tips3: '(请模糊查找并选择已有网点，避免创建同一个网点不同名称)',
    slaDialog: {
      title1: 'SLA 政策',
      col1: 'SLA 政策',
      col2: 'SLA 类型',
      col3: '数据访问组',
    },
    storeDialog: {
      title1: '网点树',
      label1: '（选中已有，点击保存）',
      label2: '（如需新增，选中节点，右键新增）',
    },
    addBranch: {
      label1: '网点',
      label2: '网点名称',
      label3: '网点类型',
      label4: '竣工日期',
      label5: '维保类型',
      label6: 'SLA 政策',
      label7: 'SLA 类型',
      label8: 'SLA开始',
      label9: 'SLA结束',
      label10: '联系人',
      label11: '电话',
      label12: '地址',
      label13: '备注',
      label14: '网点类型',
    },
  },
  dataMaintenance: {
    name: '名称',
    btn1: '全选',
    title1: '数据类型',
    title2: '设备类型',
    contactDialog: {
      title1: '新增联系方式',
      title2: '编辑联系方式',
      label1: '供应商',
      label2: '物流公司',
      label3: '收货地址',
      label4: '联系人',
      label5: '联系电话',
      label6: '邮箱',
      label7: '传真',
      label8: '职位',
    },
    deviceTypeDialog: {
      title: '新增设备类型',
      col1: '设备类型',
    },
    deviceType: {
      deviceType: '设备类型',
      title1: '新增设备',
      title2: '编辑设备',
      title3: '合并',
      title4: '代替',
      title5: '更新',
      col1: '设备名称',
      col2: '设备品牌',
      col3: '规格型号',
      col4: '供应商',
      col5: '物品 ID',
      col6: '项目类型',
      col7: '单位',
      col8: '状态',
      col9: '出库同步',
      btn1: '导入项目合同',
      btn2: '导入项目客户名称',
      btn3: '导入出入库信息',
      btn4: '替换',
      btn5: '合并',
      btn6: '更多...',
      label1: '所有其他项目将合并到所选项目中。',
      option1: '启用',
      option2: '禁用',
    },
    equipmentBrand: {
      col1: '设备类型'
    },
    supplier: {
      abbreviation: '简称',
      tab1: '联系方式',
      tab2: '供应设备',
      col1: '联系人',
      col2: '联系电话',
      col3: '邮箱',
      col4: '传真',
      col5: '职位',
      col6: '设备类型'
    },
    systemType: {
      tab1: '设备类型',
      tab2: '服务事项分类',
      tab3: '故障现象',
      col1: '设备类型',
      col2: '服务类型',
      col3: '服务事项',
      col4: '热度',
      col5: '故障现象',
      col6: '热度',
      col7: '系统类型',
      title1: '新增服务事项',
      title2: '编辑服务事项',
      title3: '新增故障现象',
      title4: '编辑故障现象',
      systemType: '系统类型',
      label1: '简称',
      label2: '图标',
    },
    faultType: {
      col1: '问题 ID',
      col2: '问题 MainType',
      col3: '问题子类型',
      col4: '项目类型',
      col5: '问题 MainType',
      col6: '问题子类型',
      col7: '问题类型',
      col8: '关键字',
      col9: '排名',
      tab1: '按问题 MainType 分组',
      tab2: '按项目类型分组',
      btn1: '替换',
      dialog1: {
        title1: '添加问题类型',
        title2: '编辑问题类型',
        label1: '问题主要类型',
        label2: '问题子类型',
        label3: '相关项目类型',
        btn1: '查看全部',
        col1: '项目类型',
        col2: '问题类型',
        col3: '关键字',
        col4: '排名',
      },
      dialog2: {
        title1: '添加问题类型',
        title2: '编辑问题类型',
        label1: '物品类型',
        label2: '问题子类型',
        btn1: '查看全部',
        col1: '问题主要类型',
        col2: '问题子类型',
        col3: '问题类型',
        col4: '关键字',
        col5: '排名',
      },
    },
    logisticsCompany: {
      tab: '联系方式',
      col1: '联系人',
      col2: '联系电话',
      col3: '邮箱',
      col4: '传真',
      col5: '职位',
    },
    receiptAddress: {
      tab: '联系方式',
      col1: '联系人',
      col2: '联系电话',
      col3: '邮箱',
      col4: '传真',
      col5: '职位',
    },
    serviceConfirmationTemplate: {
      label1: '模板文件',
      label2: '应用此模板的客户',
    },
    checkType: {
      title1: '更新项目',
      col1: 'ID',
      col2: '检查项目',
      col3: '检查类型',
      col4: '状态',
      col5: '选项',
    },
    warrantyType: {
      label1: '持续时间',
      option1: '月',
      option2: '年',
      option3: '天',
    },
    securityType: {
      label1: '持续时间',
      label2: '次',
      option1: '月',
      option2: '年',
      option3: '天',
    }
  },
  submitRequest: {
    col1: '提交时间',
    col2: '服务计时',
    col3: '城市',
    col4: '网点名称',
    col5: '描述',
    col6: '已派单',
    col7: '工程师',
    col8: '服务类型',
    col9: '完成情况',
    status: '完成情况',
    complaint: {
      title: '投诉',
      content: '感谢您指出我们工作上的不足，由此给您带来的不便，我们深表歉意，我们将及时跟进，并将结果反馈给您。',
      message1: '请写下宝贵建议',
    }
  },
  newSubmitRequest: {
    title: '新建工单',
    title2: '编辑工单',
    title3: '查看工单',
    tab1: '基本信息',
    tab2: '进度',
    tab3: '服务人员',
    tab4: '服务单',
    tab5: 'SLA 跟踪',
    btn1: '已确认',
    subtitle1: '基本信息',
    subtitle2: '故障设备',
    subtitle3: '服务人员',
    subtitle4: '附件',
    label1: '网点名称',
    label2: '要求抵达时间',
    label3: '来源',
    label4: '客户联络人',
    label5: '联络人电话',
    label6: '服务类型',
    label7: '描述',
    label8: '网点地址',
    label9: '客户系统编号',
    label10: '合同报价编号',
    label11: '采购订单号',
    label12: '负责人',
    col1: '设备名称',
    col2: '故障描述',
    col3: '派单时间',
    col4: '服务人员',
    col5: '当前位置',
    col6: '状态',
    col7: '未完成单数',
    col8: '要求抵达时间',
    col9: '工单状态',
    col10: '服务单编号',
    col11: '故障现象',
    message1: '请选择已派单的人员',
    tag1: '未完成',
    tag2: '已完成',
    tag3: '已派单',
    tag4: '改派',
    qrcode: {
      btn: '二维码',
      title: '故障设备',
      label1: '二维码',
      label2: '设备名称',
      label3: '故障描述',
    },
    fault: {
      title1: '新增故障',
      title2: '编辑故障',
      title3: '请选择故障现象',
      title4: '自定义故障现象',
      label1: '设备名称',
      label2: '故障现象',
      label3: '详细描述',
      btn1: '无匹配，新增',
      message1: '已经选过，不能重复选择故障类型',
      message2: '请输入故障类型',
    },
    progressLog: {
      state0: '已确认签名',
      state1: '取消派工',
      state2: '已派工',
      state3: '改派',
      state4: '已派单',
      state5: '提交申请',
      state6: '未完成',
      state7: '已完成',
      state8: '进行中',
      state9: '待处理',
      state10: '待确认',
      state11: '已撤销',
      state12: '已关闭',
      state13: '已归类存档',
    },
    number: {
      title1: '编辑客户系统编号',
      title2: '编辑合同报价编号',
      title3: '编辑采购订单号',
      col1: '编号',
      col2: '客户系统编号',
      col3: '合同报价编号',
      col4: '采购订单号',
    },
    faultItem: {
      title1: '请选择常见问题',
      tab1: '选中',
      tab2: '全部',
      btn1: '新问题',
      label1: '新问题',
    },
  },
  servicesScheduling: {
    add: '工程师提交',
    col1: '提交时间',
    col2: '服务计时',
    col3: '城市',
    col4: '网点名称',
    col5: '来源',
    col6: '已派单',
    col7: '服务类型',
    col8: '完成情况',
    col9: '客户名称',
    col10: '优先事项',
    status: '完成情况',
    cancel: {
      title: '取消工单原因',
      cause: '原因'
    }
  },
  newServicesScheduling: {
    title: '新增工单',
    title2: '编辑工单',
    title3: '查看工单',
    subtitle1: '故障设备',
    subtitle2: '现场图片',
    customerName: '客户名称',
    contacts: '客户联络人',
    telephone: '联络人电话',
    serviceType: '服务类型',
    requiredArrivalTime: '预定抵达时间',
    description: '描述',
    col1: '设备名称',
    col2: '故障描述',
    AddEquipment: {
      title: '故障设备',
      code: '二维码',
      deviceName: '设备名称',
      description: '故障描述',
    },
  },
  sendOrder: {
    title: '派单',
    subtitle1: '基本信息',
    subtitle2: '服务人员',
    subtitle3: '通知客户',
    label1: '网点名称',
    label2: '要求抵达时间',
    col1: '客户名称',
    col2: '设备名称',
    col3: '预定抵达时间',
    col4: '描述',
    col5: '派单时间',
    col6: '姓名',
    col7: '当前位置',
    col8: '人员状态',
    col9: '未完成派单数',
    col10: '要求抵达时间',
    col11: '客户联系人',
    col12: '电话',
    col13: '邮箱',
    col14: '邮件通知',
    col15: '短信通知',
    message1: '请选择已派单的人员',
    tag1: '已派单',
    tag2: '改派',
    staff: {
      title: '选择服务人员',
      col1: '姓名',
      col2: '联系电话',
      col3: '未完成派单数',
      col4: '处理中',
      col5: '当前位置',
      col6: '本月已派单数',
      col7: '出勤',
      col8: '请假时间',
      col9: '组织机构',
      yes: '是',
      no: '否',
    },
    dialog: {
      title1: '编辑要求抵达时间',
      title2: '改派原因',
      name1: '人员',
      name2: '改派人员',
      reason: '改派原因',
      date: '要求抵达时间',
    },
  },
  orderStateUpdate: {
    title: '工单状态更新',
    subtitle1: '基本信息',
    subtitle2: '服务人员',
    label1: '网点名称',
    label2: '预定抵达时间',
    col1: '客户名称',
    col2: '设备名称',
    col3: '预定抵达时间',
    col4: '描述',
    col5: '派单时间',
    col6: '姓名',
    col7: '安装位置',
    col8: '今天已派单数',
    col9: '要求抵达时间',
    col10: '时间',
    col11: '服务确认单编号',
    col12: '状态',
    col13: '下次处理时间',
    col14: '未完成原因',
    col15: '操作',
    update: '更新',
    newFormBtn: '服务确认单编辑',
    newForm: {
      title: '状态更新',
      number: '服务确认单编号',
      status: '状态',
      date: '下次处理时间',
      Reasons: '未完成原因',
    },
  },
  servicesConfirmation: {
    downloadPDF: '下载',
    downloadWord: '附件下载',
    col1: "服务确认单编号",
    col2: "城市",
    col3: "网点名称",
    col4: "描述",
    col5: "工程师",
    col6: "处理时间",
    col7: "来源",
    col8: "服务类型",
    col9: '创建时间',
    col10: '客户名称',
  },
  newServiceConfirmationForm: {
    title1: '编辑服务确认单',
    title2: '查看服务确认单',
    subtitle1: '确认评价',
    subtitle2: '现场维修',
    subtitle3: '更换设备',
    subtitle4: '拆除搬离',
    subtitle5: '其他事项',
    subtitle6: '基本信息',
    subtitle7: '设备安装',
    subtitle8: '维护',
    btn1: '维修',
    btn2: '更换',
    btn3: '拆除',
    btn4: '安装',
    btn5: '其他',
    btn6: '维护',
    btn7: '评分',
    btn8: '保存并发邮件',

    label1: '网点名称',
    label2: '网点地址',
    label3: '服务类型',
    label4: '服务确认单编号',
    label5: '派单时间',
    label6: '处理时间',
    label7: '客户系统编号',
    label8: '合同报价编号',
    label9: '采购订单号',
    label10: '上传附件',
    label11: '描述',
    label12: '处理方法',
    label13: '描述',
    label14: '处理方法',
    label15: '完成状态',
    label16: '下次处理时间',
    label17: '服务人员',
    label18: '负责人',
    label19: '未完成原因',
    label20: '客户签署人',
    label21: '工作职位',
    label22: '员工号',
    label23: '签名',
    label24: '服务评价',
    label25: '评论',

    issueKeyword: {
      title1: '选择问题类型相关的故障项',
      tab1: '选中',
      tab2: '全部',
      tab3: '物品',
      tab4: '问题',
      btn1: '新问题类型',
      label1: '新问题类型',
      label2: '服务确认',
      label3: '技术观察',
      label4: '详细流程',
    },
    serviceContent: {
      title1: '选择匹配的服务内容',
      tab1: '选中',
      tab2: '全部',
      btn1: '新服务内容',
      label1: '新服务内容',
      label2: '服务确认',
      label3: '技术观察',
      label4: '详细流程',
    },
    tab2: {
      col1: '设备名称',
      col2: '位置',
      col3: '技术观察',
      col4: '详细流程',
      col5: '问题类型',
    },
    dialog2: {
      label1: '问题类型',
      label2: '故障描述',
      label3: '处理方法',
      label4: '替换',
      label5: '移除',
      label6: '设备名称',
      label7: '安装位置',
      label8: '规格型号',
      label9: '序列号',
      label10: '二维码',
      label11: '安装编号',
      label12: '归属',
      label13: '系统类型',
      label14: '物品类型',
      label15: '问题主要类型',
      btn1: '下一个',
      btn2: '保存并复制（多个设备）',
      title1: '新增现场维修记录',
      title2: '编辑现场维修记录',
      option1: '无',
      option2: 'ATM',
      option3: '支行',
    },
    tab3: {
      col1: '设备名称',
      col2: '安装位置',
      col3: '技术观察',
      col4: '详细流程',
      col5: '问题类型',
      col6: '旧 SN',
      col7: '新 SN',
      col8: '设备提供方',
    },
    dialog3: {
      label1: '更换原因',
      label2: '问题类型',
      label3: '技术观察',
      label4: '详细流程',
      label5: '旧物品名称',
      label6: '位置',
      label7: '模型',
      label8: '序列号',
      label9: '二维码',
      label10: '显示名称（标签）',
      label11: '行踪',
      label12: '所有权',
      label13: '新项目名称',
      label14: '位置',
      label15: '模型',
      label16: '序列号',
      label17: '二维码',
      label18: '显示名称（标签）',
      label19: '提供者',
      label20: '参考号',
      label21: '合同和报价单号',
      label22: '采购订单号',
      title1: '替换',
      option1: '无',
      option2: 'ATM',
      option3: '支行',
      btn1: '保存并复制（多个设备）',
    },
    tab4: {
      col1: '物品名称',
      col2: '位置',
      col3: '技术观察',
      col4: '详细流程',
      col5: '序列号',
      col6: '批准人',
      col7: '现场主管',
      col8: '行踪',
    },
    dialog4: {
      label1: '移除原因',
      label2: '问题类型',
      label3: '技术观察',
      label4: '详细流程',
      label5: '物品名称',
      label6: '位置',
      label7: '模型',
      label8: '序列号',
      label9: '二维码',
      label10: '显示名称（标签）',
      label11: '所有权',
      label12: '行踪',
      label13: '批准人',
      label14: '现场主管',
      title1: '移除',
      option1: '无',
      option2: 'ATM',
      option3: '分支',
      btn1: '保存并复制（多个设备）',
    },
    tab5: {
      col1: '物品名称',
      col2: '位置',
      col3: '技术观察',
      col4: '详细流程',
      col5: '服务内容',
    },
    dialog5: {
      label1: '服务内容',
      label2: '技术观察',
      label3: '详细流程',
      label4: '物品名称',
      label5: '位置',
      label6: '模型',
      label7: '序列号',
      label8: '二维码',
      label9: '显示名称（标签）',
      label10: '所有权',
      label11: '服务类别',
      label12: '系统类别',
      title1: '新服务内容',
      title2: '编辑服务内容',
      option1: '无',
      option2: 'ATM',
      option3: '分支',
      btn1: '保存并复制（多个设备）',
    },
    tab6: {
      col1: '物品名称',
      col2: '位置',
      col3: '技术观察',
      col4: '详细流程',
      col5: '安装类型',
    },
    dialog6: {
      label1: '安装类型',
      label2: '技术观察',
      label3: '详细流程',
      label4: '物品名称',
      label5: '位置',
      label6: '模型',
      label7: '序列号',
      label8: '二维码',
      label9: '显示名称（标签）',
      label10: '所有权',
      label11: '提供者',
      label12: '参考号',
      label13: '合同和报价单号',
      label14: '采购订单号',
      title1: '安装',
      option1: '无',
      option2: 'ATM',
      option3: '分支',
      btn1: '保存并复制（多个设备）',
    },
    tab7: {
      col1: '日期时间',
      col2: '文件名',
      col3: '工程师',
      col4: '服务确认',
      btn1: '打印',
      btn2: '导出',
    },
  },

  equipmentDialog: {
    title: '选择设备',
    col1: '二维码',
    col2: '分支机构',
    col3: '设备名称',
    col4: '规格型号',
    col5: '序列号',
    col6: '安装位置',
    col7: '安装编号',
  },
  equipmentDialog2: {
    title: '选择设备',
    col1: '设备名称',
    col2: '规格型号',
    col3: '序列号',
    col4: '安装位置',
    col5: '二维码',
  },
  equipmentDialog3: {
    title1: '选择设备',
    title2: '新增设备',
    btn1: '新设备',
    col1: '设备名称',
    col2: '设备品牌',
    col3: '设备规格',
    col4: '设备类型',
    label1: '设备名称',
    label2: '设备类型',
    label3: '设备品牌',
    label4: '规格型号',
  },
  equipmentDialog4: {
    title1: '选择设备',
    title2: '新增设备',
    btn1: '新设备',
    col1: '设备名称',
    col2: '设备品牌',
    col3: '规格型号',
    col4: '设备类型',
    col5: '申请数量',
    label1: '设备名称',
    label2: '设备类型',
    label3: '设备品牌',
    label4: '规格型号',
  },
  equipmentDialog5: {
    title: '选择出库设备',
    col1: '设备名称',
    col2: '设备品牌',
    col3: '规格型号',
    col4: '数量',
    col5: '购物申请单号',
  },
  equipmentDialog6: {
    title1: '选择设备',
    label1: '购物申请单号',
    label2: '申购人',
    col1: '设备名称',
    col2: '设备品牌',
    col3: '规格型号',
    col4: '设备类型',
    col5: '订货数量',
  },
  equipmentDialog7: {
    title1: '选择设备',
    col1: '设备名称',
    col2: '设备品牌',
    col3: '规格型号',
    col4: '设备类型',
    col5: '序列号',
  },
  deviceManagement: {
    tab1: '设备列表',
    btn1: '设备二维码',
    btn2: '导出PDF',
    btn3: '导出PDF二维码',
    col1: '设备名称',
    col2: '状态',
    col3: '规格型号',
    col4: '安装位置',
    col5: '保修到期日',
    col6: '二维码',
    col7: '序列号',
    col8: '数据源',
  },
  newDevice: {
    title1: '新增设备',
    title2: '编辑设备',
    title3: '查看设备',
    tab1: '基本信息',
    tab2: '参数',
    tab3: '项目记录',
    tab4: '购买记录',
    tab5: '合同&保修',
    tab6: '维护记录',
    label1: '设备名称',
    label2: '运行状态',
    label3: '保修到期日',
    label4: '客户名称',
    label5: '网点名称',
    label6: '设备品牌',
    label7: '设备类型',
    label8: '序列号',
    label9: '规格型号',
    label10: '安装位置',
    label11: '设备标签名称',
    label12: '楼层',
    label13: '安装编号',
    label14: '设备IP',
    label15: '安装日期',
    label16: '验收日期',
    label17: '已安装注意',
    label18: '申购人',
    label19: '入库日期',
    label20: '申请出库人',
    label21: '出库日期',
    label22: '生产日期',
    label23: '厂家质保到期',
    label24: '项目名称',
    label25: '项目类型',
    label26: '合同报价编号',
    label27: '维保起止日期',
    label28: '合同状态',
    label29: '设备质保方式',
    label30: '二维码',
    repair: {
      title1: '修复',
      col1: '日期时间',
      col2: '工单状态',
      col3: '服务类别',
      col4: '问题子类型',
      col5: '问题描述',
      col6: '详细过程',
      col7: '工程师',
      col8: '服务确认表',
    },
    replace: {
      title1: '替换',
      col1: '日期时间',
      col2: '原因',
      col3: '问题',
      col4: '旧二维码',
      col5: '旧序列号',
      col6: '新二维码',
      col7: '新序列号',
      col8: '位置',
      col9: '提供者',
      col10: '评论',
      col11: '服务确认表',
    },
    remove: {
      title1: '删除',
      col1: '日期时间',
      col2: '原因',
      col3: '问题',
      col4: '二维码',
      col5: '序列号',
      col6: '位置',
      col7: '批准人',
      col8: '现场主管',
      col9: '去向',
      col10: '评论',
      col11: '服务确认表',
    },
    installation: {
      title1: '安装',
      col1: '日期时间',
      col2: '安装类型',
      col3: '评论',
      col4: '服务确认表',
    },
    maintain: {
      title1: '维护',
      col1: '日期时间',
      col2: '状态',
      col3: '评论',
      col4: '服务确认表',
    },
    otherServices: {
      title1: '其他服务',
      col1: '日期时间',
      col2: '服务内容',
      col3: '服务确认表',
    },
    contractWarranty: {
      col1: '签名日期',
      col2: '项目名称',
      col3: '项目类别',
      col4: '合同和报价单号',
      col5: '合同期限',
      col6: '合同状态',
      col7: '保修类型',
      tag1: '未生效',
      tag2: '正常',
      tag3: '已结束',
      tag4: '未知',
    },
    contractDialog: {
      title1: '选择项目',
      col1: '签署日期',
      col2: '项目名称',
      col3: '合同报价编号',
      col4: '项目类型',
    },
  },
  projectDialog: {
    title: '选择项目',
    col1: '合同报价编号',
    col2: '项目名称',
  },
  deviceCode: {
    title: '二维码分配',
    print: '打印',
    indexPrint: '索引打印',
    printInAdvance: '二维码生成',
    batch: '批量分配',
    col1: '设备名称',
    col2: '序列号',
    col3: '二维码',
    btn1: '单独分配',
    message1: '请移除未分配二维码的设备，然后在打印。',
    advanceCode: {
      title1: '预先打印二维码',
      content: '选择打印个数',
      message1: '请输入正整数',
    },
    batchSetQR: {
      title1: '提示',
      content: '设备已分配二维码，请确定是否重新分配，重新分配将导致网点设备二维码信息无法检索，需将次新二维码粘贴到设备上。',
      btn1: '确定，重新分配',
    },
  },
  engineerManagement: {
    col1: '姓名',
    col2: '员工号',
    col3: '工作电话',
    col4: '电子邮箱',
    col5: '状态',
    col6: '派单次数',
    col7: '投诉次数',
    col8: '出勤',
    col9: '请假时间',
    col10: '系统用户',
    col11: '组织机构',
    filter: {
      title1: '筛选',
      label1: '仅显示在职人员',
    },
    import: {
      title1: '从钉钉导入',
      col1: '名称',
      col2: '员工编号',
      col3: '部门',
      col4: '位置',
      col5: '工作电话',
      col6: '电子邮件',
      label1: '导入和创建用户帐户',
    }
  },
  newEngineer: {
    title1: '新增工程师',
    title2: '编辑工程师',
    title3: '查看工程师',
    label1: '姓名',
    label2: '员工编号',
    label3: '身份证',
    label4: '数据访问组',
    label5: '职位',
    label6: '工作电话',
    label7: '家庭电话',
    label8: '电子邮箱',
    label9: '员工状态',
    label10: '出勤情况',
    label11: '组织机构',
    label12: '部门',
    label13: '网点访问组',
    label14: '报表访问组',
    label15: '请假类型',
    label16: '创建系统账号',
    label17: '请假时间',
    label18: '外勤',
    label19: '系统用户',
    option1: '缺席',
    option2: '出席',
    option3: '请假',
  },
  inventoryManagement: {
    tab1: '库存',
    tab2: '入库',
    tab3: '出库',
    inventory: {
      btn1: '二维码',
      btn2: '导出EXCEL',
      btn3: '备货',
      btn4: '入库',
      btn5: '出库',
      btn6: '按项目出库',
      col1: '设备类型',
      col2: '设备名称',
      col3: '规格型号',
      col4: '设备品牌',
      col5: '单位',
      col6: '库存数量',
      col7: '核对状态',
      option1: '所有',
      option2: '待核对',
      option3: '已核对',
    },
    inWarehouse: {
      btn1: '设备二维码',
      btn2: '导出EXCEL',
      btn3: '入库',
      btn4: '撤销入库',
      btn5: '出库',
      btn6: '按项目出库',
      btn7: '核对',
      col1: '入库日期',
      col2: '入库类型',
      col3: '供应商',
      col4: '设备名称',
      col5: '设备品牌',
      col6: '规格型号',
      col7: '设备类型',
      col8: '序列号',
      col9: '单位',
      col10: '数量',
      col11: '单价',
      col12: '购物申请单号',
      col13: '订单编号',
      col14: '订单总额',
      col15: '申购人',
      col16: '订购人',
      col17: '项目名称',
      col18: '合同报价编号',
      col19: '设备新旧',
      col20: '借用厂家设备',
      col21: '入库备注',
      col22: '核对状态',
      col23: '核对人',
      col24: '核对时间',
      new: '新',
      old: '旧',
      yes: '是',
      no: '否',
    },
    outWarehouse: {
      btn1: '设备二维码',
      btn2: '导出EXCEL',
      btn3: '撤销出库',
      btn4: '借用归还',
      col1: '出库日期',
      col2: '设备名称',
      col3: '设备品牌',
      col4: '规格型号',
      col5: '设备类型',
      col6: '序列号',
      col7: '单位',
      col8: '数量',
      col9: '项目所在地',
      col10: '项目类型',
      col11: '收货人',
      col12: '项目名称',
      col13: '合同报价编号',
      col14: '出库备注',
      col15: '借用出库',
      col16: '约定归还日期',
      col17: '归还日期',
      col18: '快递单号',
      col19: '物流公司',
      col20: '快递费用',
      yes: '是',
      no: '否',
    },
  },
  inWarehouseDetails: {
    title1: '新增入库',
    title2: '入库日期',
    title3: '编辑入库',
    subtitle1: '项目信息',
    subtitle2: '订单信息',
    subtitle3: '设备信息',
    subtitle4: '申购信息',
    subtitle5: '出库记录',
    btn1: '保存退出',
    btn2: '保存并新增',
    btn3: '保存并出库',
    yes: '是',
    no: '否',
    new: '新',
    old: '旧',
    label1: '入库地址',
    label2: '入库方式',
    label3: '项目名称',
    label4: '合同报价编号',
    label5: '申购人',
    label6: '购物申请单号',
    label7: '订单编号',
    label8: '订单总额',
    label9: '订购人',
    label10: '付款日期',
    label11: '设备名称',
    label12: '规格型号',
    label13: '设备品牌',
    label14: '设备类型',
    label15: '数量',
    label16: '单价',
    label17: '是否有序列号',
    tip17: '(指不含序列号设备，例如线材，管材，螺丝，胶布)',
    label18: '序列号',
    placeholder18: '(多个序列号请用;分开)',
    label19: '供应商',
    label20: '入库备注',
    label21: '仓库位置',
    label22: '借用厂家设备',
    label23: '约定归还日期',
    label24: '入库后锁定',
    message1: '序列号与设备数量有误差',
    message2: '序列号已出库，不可更改',
    message3: '购物申请单与订单无关联，不能入库',
  },
  orderDialog: {
    title: '选择订单',
    col1: '订单编号',
    col2: '付款日期',
    col3: '订单总额',
    col4: '订购人',
    col5: '创建时间',
    col6: '项目名称',
  },
  outWarehouseDetails: {
    title1: '出库',
    title2: '按项目出库',
    title3: '出库日期',
    title4: '提示',
    subtitle1: '搜索项目',
    subtitle2: '出库项目信息',
    subtitle3: '出库信息',
    btn1: '保存退出',
    btn2: '按项目出库',
    btn3: '二维码',
    yes: '是',
    no: '否',
    col1: '购物申请单号',
    col2: '设备名称',
    col3: '设备品牌',
    col4: '规格型号',
    col5: '设备新旧',
    col6: '申请数量',
    col7: '项目名称',
    col8: '库存数量',
    col9: '已出库数',
    col10: '锁定库存数',
    col11: '出库数量',
    label1: '项目名称',
    label2: '合同报价编号',
    label3: '项目名称',
    label4: '合同报价编号',
    label5: '项目所在地',
    label6: '项目类型',
    label7: '借用出库',
    label8: '约定归还日期',
    label9: '出库备注',
    label10: '快递单号',
    label11: '物流公司',
    label12: '快递费用',
    label13: '收货人',
    label14: '电话',
    label15: '快递备注',
    label16: '收货地址',
    label17: '客户名称',
    label18: '网点名称',
    message1: '请选择出库设备',
    message2: '出库数量不能为0',
    message3: '设备未分配二维码，请分配二维码后再出库。',
    message4: '数量不能超过',
    message5: '无序列设备数量不能超过',
    serial: {
      title1: '出库数量编辑',
      tab1: '无序列号',
      tab2: '有序列号',
      label1: '数量',
      label2: '单位',
      col1: '序列号',
    },
  },
  quotation: {
    btn1: '设置',
    col1: '商品名称',
    col2: '型号',
    col3: '品牌',
    col4: '类型',
    col5: '最新价格',
    col6: '价格波动',
    col7: '平均价格',
    col8: '最高价格',
    col9: '最低价格',
    col10: '询价日期',
    col11: '停产',
    tag1: '正常',
    tag2: '停产',
    options: {
      title1: '设置',
      subtitle1: '汇率',
      subtitle2: '价格小数点位数',
      col1: '货币',
      col2: '汇率',
      message1: '请求次数过多，请于一小时后重新请求',
    }
  },
  newQuotation: {
    title1: '编辑商品信息',
    title2: '查看商品信息',
    label1: '商品名称',
    label2: '型号',
    label3: '品牌',
    label4: '类型',
    label5: '停产',
    label6: '日期',
    btn1: '添加替代品',
    btn2: '显示价格',
    tab1: '价格',
    tab2: '停产代替',
    tab3: '规格说明',
    col1: '状态',
    col2: '创建日期',
    col3: '供应商',
    col4: '电话',
    col5: '货币',
    col6: '单价',
    col7: '描述',
    col8: '项目名称',
    col9: '税收',
    col10: '折扣',
    col11: '失效日期',
    col12: '附件',
    col13: '询价日期',
    col14: '创建日期',
    col15: '停产',
    col16: '终止日期',
    col17: '商品名称',
    col18: '型号',
    col19: '品牌',
    col20: '类型',
    tag1: '正常',
    tag2: '停产',
    tag3: '有效',
    tag4: '失效',
  },
  newQuote: {
    title1: '新增商品报价',
    title2: '编辑商品报价',
    subtitle1: '附件',
    label1: '商品名称',
    label2: '品牌',
    label3: '供应商',
    label4: '联系',
    label5: '电话',
    label6: '货币',
    label7: '单价',
    label8: '税费',
    label9: '折扣',
    label10: '创建人',
    label11: '型号',
    label12: '类型',
    label13: '邮箱',
    label14: '传真',
    label15: '职位',
    label16: '描述',
    label17: '项目名称',
    label18: '失效日期',
    label19: '询价日期',
    message1: '格式有误，小数点最多精确到8位',
  },
  complaintsEvaluation: {
    col1: '时间',
    col2: '类型',
    col3: '评分',
    col4: '提交人',
    col5: '关联ID',
    col6: '描述',
    col7: '网点名称',
    complaints: {
      title1: '新增投诉建议',
      title2: '编辑投诉建议',
      label1: '网点名称',
      label2: '投诉建议',
    }
  },

  purchaseOrders: {
    btn1: '打印',
    btn2: 'BGB打印',
    col1: '创建时间',
    col2: '订单编号',
    col3: '付款日期',
    col4: '订单总额',
    col5: '订购人',
    col6: '项目名称',
    col7: '合同报价编号',
    col8: '供应商',
  },
  purchaseRequisition: {
    tab1: '购物申请',
    tab2: '状态跟踪',

    applicationForm: {

    },
    stateTracking: {
      btn1: '物流信息',
      btn2: '签收',
      btn3: '状态详情',
      col1: '状态',
      col2: '审批状态',
      col3: '设备类型',
      col4: '设备名称',
      col5: '品牌',
      col6: '规格型号',
      col7: '申请数量',
      col8: '已订货数',
      col9: '待订货数',
      col10: '到货数',
      col11: '可用库存数量',
      col12: '预分配库存数',
      col13: '已入库总数',
      col14: '出库1',
      col15: '出库2',
      col16: '出库3',
      col17: '已签收',
      col18: '锁定',
      col19: '申请人',
      col20: '项目名称',
      col21: '合同编号',
      col22: '购物申请单号',
      col23: '订单编号',
    },
    col1: '创建时间',
    col2: '购物申请单号',
    col3: '项目名称',
    col4: '合同报价编号',
    col5: '合同类型',
    col6: '客户审批',
    col7: '申购人',
    col8: '部门经理',
    col9: '总经理',
    col10: '采购',
    col11: '验收',
    col12: '财务',
    col13: '审批状态',
    btn1: '打印',
    btn2: '重新提交',
    btn3: '驳回',
    btn4: '批准',
    title1: '拒绝说明',
    title2: '批准说明',
    tag1: '待审核',
    tag2: '审核通过',
    tag3: '审核未通过',
  },
  allocateGoods: {
    title1: '新建购物申请',
    title2: '编辑购物申请',
    title3: '备货',
    title4: '查看购物申请单',
    tab1: '申请单',
    tab2: '审核',
    tab3: '变更记录',
    tab4: '物流记录',
    tab5: '签收记录',

    subtitle1: '项目信息',
    subtitle2: '设备信息',
    label1: '购物申请单号',
    label2: '申购人',
    label3: '数据访问组',
    label4: '项目名称',
    label5: '合同报价编号',
    label6: '合同类型',
    label7: '客户审批',
    label8: '送货地址',
    label9: '备注',
    btn1: '备货',
    btn2: '锁定',
    btn3: '保存退出',
    btn4: '打印',
    btn5: '驳回',
    btn6: '批准',

    col1: '设备类型',
    col2: '设备名称',
    col3: '规格型号',
    col4: '设备品牌',
    col5: '单位',
    col6: '申请数量',
    col7: '库存数量',
    col8: '缺货数量',
    col9: '已入库数',
    col10: '已出库数1',
    col11: '已出库数2',
    col12: '锁定库存数',
    col13: '单价',
    col14: '总价',
    col15: '项目名称',
    col16: '报价单号',
    col17: '已出库数3',
    message1: '购物申请单号重复',
    message2: '请选择购物申请单',
    addEquipment: {
      title1: '新增设备',
      title2: '编辑设备',
      label1: '设备名称',
      label2: '设备类型',
      label3: '设备品牌',
      label4: '规格型号',
      label5: '申购数量',
      label6: '用途',
      label7: '需购回时间',
      label8: '备注',
      label9: '单价',
      label10: '总价',
      label11: '详细规格',
      label12: '单位',
      label13: '收件人',
    },
    setLock: {
      title1: '调整锁定数量',
      title2: '调整记录',
      btn1: '查看',
      label1: '调整锁定数量',
      label2: '调整原因',
      label3: '锁定数量',
      message1: '锁定不能大于申请数量',
      message2: '锁定不能小于0',
    },
    applyFrom: {
      label1: '购物申请单号',
      label2: '申购人',
      label3: '数据访问组',
      label4: '审批状态',
      label5: '创建方式',
      subtitle1: '设备信息',
      option1: '正常',
      option2: '无申购入库',
      option3: '无申购出库',
      btn1: '锁定',
      btn2: '替换',
      btn3: '签收',
      col1: '设备类型',
      col2: '设备名称',
      col3: '规格型号',
      col4: '设备品牌',
      col5: '单位',
      col6: '申请数量',
      col7: '单价',
      col8: '总价',
      col9: '已订货数',
      col10: '预分配库存数',
      col11: '项目名称',
      col12: '合同编号',
    },
    changeRecord: {
      col1: '日期',
      col2: '状态',
      col3: '项目名称',
      col4: '设备名称',
      col5: '品牌',
      col6: '规格',
      col7: '申购数量',
      col8: '原因',
      col9: '操作员',
      col10: '附件',
    },
    logisticsRecord: {
      col1: '日期',
      col2: '寄件方式',
      col3: '类型',
      col4: '设备名称',
      col5: '品牌',
      col6: '型号',
      col7: '申购数量',
      col8: '订购数量',
      col9: '状态',
      col10: '预计发货日期',
      col11: '发货数量',
      col12: '到货数量',
      col13: '寄件方',
      col14: '物流公司',
      col15: '收件人',
      col16: '运单号',
      col17: '签收状态',
      col18: '操作员',
      col19: '备注',
      option1: '厂家直发项目',
      option2: 'BGB仓库发货',
      option3: '厂家发BGB仓库',
      option4: '发货',
      option5: '到货',
      option6: '未发货',
      option7: '已发货-部分',
      option8: '已发货-全部',
    },
    signInRecord: {
      col1: '签收日期',
      col2: '签收人',
      col3: '项目名称',
      col4: '设备名称',
      col5: '品牌',
      col6: '规格',
      col7: '申请数量',
      col8: '签收数量',
      col9: '附件',
    },
  },
  newOrder: {
    title1: '新增订货单',
    title2: '编辑订货单',
    title3: '查看订货单',
    tab1: '订货单',
    tab2: '订货跟踪',
    orderForm: {
      subtitle1: '订货单信息',
      subtitle2: '条款',
      subtitle3: '订购内容',
      btn1: '保存退出',
      btn2: '打印',
      label1: '项目名称',
      label2: '合同报价编号',
      label3: '数据访问组',
      label4: '订单编号',
      label5: '订单类型',
      label6: '订购人',
      label7: '供应商',
      label8: '付款日期',
      label9: '订单总额',
      label10: '交货日期',
      label11: '运费',
      label12: '收货人',
      label13: '收货地址',
      label14: '联系方式',
      label15: '税率',
      label16: '发票类型',
      label17: '合同日期',
      label18: '合并相同项',
      label19: '条款1补充',
      label20: '条款2补充',
      label21: '条款3补充',
      col1: '设备类型',
      col2: '设备名称',
      col3: '设备品牌',
      col4: '规格型号',
      col5: '单位',
      col6: '单价',
      col7: '订货数量',
      col8: '到货数量',
      col9: '需购回时间',
      col10: '购物申请单号',
      col11: '申购人',
      col12: '备注',
      col13: '项目名称',
      addEquipment: {
        title1: '新增设备',
        title2: '编辑设备',
        label1: '购物申请单',
        label2: '购物申请单号',
        label3: '设备类型',
        label4: '设备名称',
        label5: '设备品牌',
        label6: '规格型号',
        label7: '订购数量',
        label8: '单价',
        label9: '需购回时间',
        label10: '备注',
        yes: '是',
        no: '否',
      },
      allocateGoods: {
        title1: '备货',
        col1: '购物申请单号',
        col2: '设备名称',
        col3: '规格型号',
        col4: '项目名称',
        col5: '库存数量',
        label1: '总库存',
        label2: '申请数量',
        label3: '已出库数量1（本项目）',
        label4: '库存数量',
        label5: '已出库数量2（其他项目）',
        label6: '缺货数量',
        label7: '请输入订货数量',
      },
    },
    orderTracking: {
      btn1: '订货状态更新',
      btn2: '打印',
      col1: '更新日期',
      col2: '操作员',
      col3: '付款_状态1',
      col4: '订货_状态2',
      col5: '到货_状态3',
      updateState: {
        title1: '订货状态更新',
        radio1: '付款',
        radio2: '订货',
        radio3: '到货',
        label1: '付款状态',
        label2: '付款日期',
        label3: '应付款总金额',
        label4: '总共已付金额',
        label5: '剩余未付金额',
        label6: '本期付款金额',
        label7: '备注',
        label8: '订货状态',
        label9: '订货日期',
        label10: '预计到货期',
        label11: '备注',
        label12: '到货状态',
        label13: '到货日期',
        label14: '剩余预计到货期',
        label15: '备注',
        message1: '请先保存订货单',
      }
    }
  },
  addOrder: {
    title1: '新增订购',
    title2: '订购数量和价格编辑',
    subtitle1: '订货数量',
    subtitle2: '待订货项目',
    label1: '申请数量',
    label2: '缺货数量',
    label3: '库存数量',
    label4: '出库1/2/3',
    label5: '已订货数量',
    label6: '本次订货数量',
    label7: '单价',
    table1: {
      col1: '设备类型',
      col2: '设备名称',
      col3: '品牌',
      col4: '规格型号',
      col5: '申请数量',
      col6: '已订货数量',
      col7: '购物申请单号',
      col8: '项目名称',
    },
    table2: {
      col1: '设备类型',
      col2: '设备名称',
      col3: '品牌',
      col4: '规格型号',
      col5: '订货数量',
      col6: '单价',
      col7: '备注',
      col8: '需购回时间',
      col9: '购物申请单号',
      col10: '项目名称',
      col11: '申购人',
    },
  },
  inventoryStatistics: {
    label1: '设备类型',
    label2: '设备名称',
    label3: '规格型号',
    label4: '设备品牌',
    label5: '单位',
    label6: '入库数量',
    label7: '出库数量',
    label8: '库存数量',
  },
  deviceDetailsList: {
    title1: '列表详情',
    col1: '出入库',
    col2: '时间',
    col3: '序列号',
    col4: '数量',
    col5: '单位',
    col6: '设备类型',
    col7: '设备名称',
    col8: '规格型号',
    col9: '工程项目',
    col10: '申购人',
  },
  score: {
    title1: '评分',
    subtitle1: '描述',
    label1: '总评分',
    label2: '得分',
    label3: '总得分',
    tag1: '极不满意',
    tag2: '不满意',
    tag3: '尚可',
    tag4: '满意',
    tag5: '非常满意',
  },
  ApplyDialog: {
    title1: '选择购物申请单',
    col1: '项目名称',
    col2: '合同报价编号',
    col3: '购物申请单号',
  },
  SupplierDialog: {
    title1: '供应商',
    title2: '新增供应商',
    col1: '供应商',
    col2: '简称',
  },
  inventoryDevice: {
    title1: '库存 - 设备详情',
    col1: '入库日期',
    col2: '入库地区',
    col3: '设备类型',
    col4: '设备名称',
    col5: '规格型号',
    col6: '设备品牌',
    col7: '数量',
    col8: '单位',
    col9: '序列号',
    col10: '购物申请单号',
    col11: '项目名称',
    col12: '合同报价编号',
    col13: '订单编号',
  },
  approvalWorkflow: {
    col1: '申请名称',
    col2: '工作流ID',
    col3: '审批步骤',
    col4: '流程图',
    col5: '部门',
    col6: '审批人',
    col7: '抄送',
    workfow: {
      title1: '添加新批准工作流',
      title2: '编辑审批工作流',
      subtitle1: '审批流程',
      subtitle2: '抄送',
      subtitle3: '审批选项',
      label1: '请求名称',
      label2: '工作流程ID',
      label3: 'Step',
      label4: '图表',
      col1: '部门',
      col2: '批准者',
      col3: '部门',
      col4: 'CC',
      option1: '审核',
      option2: '审批',
    },
    person: {
      title1: '添加人员'
    }
  },
  appRelease: {
    col1: '平台',
    col2: '版本类型',
    col3: '版本号',
    col4: '包名',
    col5: '安装包大小',
    col6: '发布时间',
    col7: '创建人',
    title1: '新版本',
    label1: '上传',
    label2: '路径',
    message1: '请上传安装包',
    message2: '请上传apk类型文件',
  },
  projects: {
    col1: '项目名称',
    col2: '持续时间',
    col3: '开始',
    col4: '完成',
    col5: '完成百分比',
  },
  newProjects: {
    title1: '新增项目',
    title2: '编辑项目',
    tab1: '基本信息',
    tab2: '甘特图',
    tab3: '列表',
    tab4: '文档',
    info: {
      label1: '项目名称',
      label2: '项目类型',
      label3: '数据访问组',
      label4: '日期',
      label5: '合同编号',
      label6: '备注',
    },
    documents: {
      col1: '任务',
      col2: '所需文档',
      col3: '提交文档',
    },
    ganttChart: {
      col1: '任务',
      col2: '持续时间',
      col3: '开始',
      col4: '完成',
      col5: '人员分配',
      btn1: '菜单',
      btn2: '新任务',
      btn3: '新标准任务',
      btn4: '设置基准',
      btn5: '导出',
      btn6: '缩放',
      btn7: '天',
      btn8: '周',
      btn9: '月',
      task: {
        title1: '新任务',
        title2: '编辑任务',
        title3: '新标准任务',
        title4: '编辑标准任务',
        title5: '选择任务',
        title6: '选择文件',
        tab1: '常规',
        tab2: '资源',
        tab3: '文档',
        label1: '任务名称',
        label2: '开始日期',
        label3: '完成日期',
        label4: '持续时间',
        label5: '完成百分比',
        label6: '前置任务',
        label7: '注释',
        label8: '里程碑',
        subtitle1: '标准任务',
        col1: '人员',
        col2: '小时',
        col3: '编号',
        col4: '标准任务',
        col5: '类型',
        col6: '序列',
        col7: '任务阶段',
      }
    },
  },
  documentsDetail: {
    title1: '文档详情',
    label1: '任务名称',
  },
  standardTask: {
    title1: '新增标准任务',
    title2: '编辑标准任务',
    col1: '标准任务',
    col2: ' 编号',
    col3: '类型',
    col4: '项目类别',
    col5: '文档',
    col6: '序列',
    label1: '标准任务',
    label2: '任务类型',
    label3: '项目类别',
    label4: '注释',
    label5: '文档',
    label6: '序列',
    label7: '任务阶段',
    btn1: '上移',
    btn2: '下移',
  },
  projectDocuments: {
    col1: '文件名',
    col2: '格式',
    col3: '操作',
    btn1: '上传',
    btn2: '下载',
    title1: '新文件夹',
    title2: '编辑文件夹',
    title3: '新文件',
    title4: '编辑文件',
    label1: '文件夹名称',
    label2: '文件名',
    label3: '格式',
  },
  mySelect: {
    option1: '无匹配数据',
  },


  dashBoard: {
    btn1: '风险级别设置',
    btn2: '打印',
    btn3: '导出',
    btn4: '布局模板',
    btn5: '布局设置',
    btn6: '另存为',
    btn7: '保存',
    dashboardTemplate: {
      title1: '布局模板',
      col1: '模板名称',
      col2: '系统默认值',
      col3: '组织',
      col4: '数据访问组',
      col5: '当前',
      btn1: '设置当前',
      btn2: '布局编辑',
    },
    addDashboard: {
      title1: '另存为新的布局模板',
      title2: '编辑布局模板',
      label1: '模板名称',
      label2: '数据访问组（数据权限）',
      label3: '将模板分配给所选组织',
      label4: '设为默认值',
    },
    exportReports: {
      title1: '导出报告',
      label1: '文件名称',
      label2: '文件格式',
    },
    overview: {
      title1: '概述数据呈现',
      label1: '报告模板',
      col1: '当前',
      col2: '标题',
      col3: '报告模板',
      col4: '图表',
      col5: '表格',
    }
  },
  counterMode: {
    btn1: '风险级别设置',
    btn2: '打印',
    btn3: '导出',
    btn4: '布局设置',
    btn5: '保存',
    btn6: '添加至条件',
    btn7: '新查询',
    btn8: '复制',
    filterPopover: {
      title1: '排序与筛选',
      btn1: '重置',
      btn2: 'A - Z (从小到大)',
      btn3: 'Z - A (从大到小)',
      btn4: '自定义排序...',
      btn5: '筛选',
    }
  },
  reportTemplates: {
    col1: '报告模板',
    col2: '说明',
    col3: '数据访问组',
    col4: '模式',
    col5: '分析模式',
    col6: '创作者',
    dialog: {
      title1: '新报告模板',
      title2: '编辑报告模板',
      label1: '报告模板名称',
      label2: '说明（预防位置损失分析）',
      label3: '数据访问组（数据权限）',
      label4: '分析模式',
      label5: '计数器模式',
      label6: '用餐模式',
    }
  },
  reportGroup: {
    btn1: '添加节点',
    label1: '选定的节点',
    col1: '报告模板',
    col2: '说明',
    col3: '数据访问组',
    nodeDialog: {
      title1: '添加新节点',
      title2: '编辑节点',
      label1: '节点名称（报告类别）',
      label2: '描述',
      label3: '数据访问组（数据权限）',
    },
  },
  newReportGroup: {
    title1: '报告类别分配',
    label1: '选定的组',
    label2: '数据访问组（数据权限）',
    label3: '描述',
    label4: '报告模板列表',
    label5: '已选择',
    col1: '编号',
    col2: '报告模板',
    col3: '说明',
  },
  reportDesigner: {
    label1: '报告模板',
    label2: '图表类型',
    label3: '分析模式',
    label4: '显示名称设置',
    label5: '子字段第二语言',
    label6: '主要领域第二语言',
    col1: '报告主要字段',
    col2: '报告子字段',
    col3: '字段类型',
    col4: '测量',
    col5: '风险级别',
    col6: '概述字段',
    col7: '链接',
    col8: '手动排序',
    dialog: {
      title1: '新报告字段',
      title2: '编辑报告字段',
      label1: '字段类型',
      label2: '多列标题',
      label3: '报告主要字段',
      label4: '报告主要字段',
      label5: '参数字段',
      label6: '第二语言',
      label7: '说明',
      label8: '报告子字段',
      label9: '默认运算符',
      label10: '测量',
      label11: '选项值',
      label12: '风险级别字段',
      label13: '默认查询字段',
    }
  },
  chartType: {
    title1: '图表标题',
    label1: '所有图表',
    label2: '图表标题',
    btn1: '上传',
    type1: '图标',
    type2: '柱状图',
    type3: '饼图',
    type4: '折线图',
    tab1: '数据源',
    tab2: '查询条件',
    tab3: '排序',
    tab4: '图表属性',
    dataSource: {
      title1: '选择数据源',
      col1: '字段',
      col2: '类型',
      col3: '链接',
    },
    queryConditions: {
      btn1: '添加字段',
      btn2: '添加参数',
      col1: '与/或',
      col2: '查询字段',
      col3: '字段类型',
      col4: '反转',
      col5: '过滤运算符',
      col6: '条件',
    },
    sorting: {
      col1: '字段',
      col2: '订单',
      col3: '状态',
      title1: '排序',
    },
    chartElements: {
      col1: '图表元素',
      col2: '选项',
      col3: '状态',
    }
  },
  fieldDisplaySet: {
    title1: '显示名称设置',
    col1: '字段名称',
    col2: '数据显示名称',
    col3: '数据代码',
    col4: '全局设置',
    dialog: {
      title1: '数据显示名称设置',
    }
  },
  riskOfLossLevel: {
    col1: '风险级别模板',
    col2: '说明',
    col3: '系统默认值',
    col4: '数据访问组',
    col5: '创作者',
    col6: '品牌',
    col7: '状态',
  },
  newRiskOfLossLevel: {
    title1: '新增损失风险级别模板',
    title2: '编辑损失风险级别模板',
    label1: '模板名称',
    label2: '数据访问组（数据权限）',
    label3: '品牌',
    label4: '描述',
    label5: '设置成默认',
    col1: '报告模板',
    col2: '主要字段',
    col3: '子字段',
    col4: '测量',
  },
  slaPolicies: {
    tab1: 'SLA 政策',
    tab2: '升级级别',
    col1: 'SLA 政策',
    col2: 'SLA 类型',
    col3: '数据访问组',
    col4: '客户',
    col5: '分支/站点',
    col6: '创建日期',
    col7: '升级级别',
  },
  newSLAPolicy: {
    title1: '新 SLA 政策',
    title2: '编辑 SLA 政策',
    title3: '查看 SLA 政策',
    label1: 'SLA 政策',
    label2: 'SLA 类型',
    label3: '数据访问组',
    col1: '颜色',
    col2: '优先级',
    col3: '优先级 ID',
    col4: '名称',
    col5: '响应',
    col6: '营业时间',
    col7: '定时器',
    col8: '警告',
    col9: '发送到',
    col10: '计时器',
    col11: '升级',
    col12: '分辨率',
    col13: '计时器',
    col14: '警告',
    col15: '发送到',
    col16: '定时器',
    col17: '升级',
  },
  newSLAPriority: {
    title1: '新的 SLA 优先级',
    title2: '编辑 SLA 优先级',
    title3: '查看 SLA 优先级',
    tab1: '升级',
    tab2: '服务类别',
    label1: '优先级名称',
    label2: '优先 ID',
    label3: 'SLA 阶段类型',
    label4: '阶段 ID',
    label5: '触发器名称',
    label6: '启动计时器的时间',
    label7: '说明',
    label8: '停止计时器的时间',
    label9: '服务内容',
    label10: '启用/禁用',
    col1: '触发器ID',
    col2: '营业时间',
    col3: '定时器（在 SLA 内）',
    col4: '警告',
    col5: '定时器',
    col6: '升级（违反 SLA）',
  },
  newSLAEscalationLevel: {
    title1: '编辑 SLA 升级级别',
    label1: 'SLA',
    label2: '数据访问组',
    col1: '级别',
    col2: '全名',
    col3: '站点组',
    col4: '组织',
    col5: '职位',
    col6: '电子邮件',
    col7: '电话号码',
    col8: '备份电子邮件',
    col9: '备用电话号码',
    col10: '描述',
    col11: '类型',
    contactDialog: {
      title1: '添加联系人',
      label1: '类型',
      label2: '级别',
      label3: '说明',
      col1: '全名',
      col2: '职位',
      col3: '邮箱',
      col4: '电话',
    }
  },
  operationalTime: {
    col1: '组织名称',
    col2: '数据访问组',
    col3: '时区',
    col4: '国家',
    title1: '复制到',
    btn1: '复制到'
  },
  editOperationalTime: {
    title1: '编辑操作时间',
    tab1: '工作时间',
    tab2: '假期时间',
    label1: '组织名称',
    label2: '描述',
    label3: '数据访问组',
    label4: '国家',
    label5: '时区',
    col1: '名称',
    col2: '类型',
    col3: '特殊工作时间',
  },
  newHolidayHours: {
    title1: '新假期时间',
    title2: '编辑假期时间',
    title3: '查看假期时间',
    label1: '姓名',
    label2: '类型',
    col1: '标题',
    col2: '日期',
  },
  maintenance: {
    tab1: '日历',
    tab2: '维护',
    tab3: '记录',
    btn1: '全选',
    calendar: {
      label1: '已完成',
      label2: '预定',
      label3: '不完整',
      label4: '日期',
      col1: '星期日',
      col2: '星期一',
      col3: '星期二',
      col4: '星期三',
      col5: '星期四',
      col6: '星期五',
      col7: '星期六',
    },
    maintenance: {
      btn1: '创建副本',
      btn2: '复制到',
      btn3: '打印',
      btn4: '导出',
      col1: '文件名',
      col2: '站点名称',
      col3: '检查清单',
      col4: '状态',
      col5: '模板',
    },
    record: {
      btn1: '打印',
      btn2: '导出',
      col1: '日期时间',
      col2: '文件名',
      col3: '站点名称',
      col4: '工程师',
      col5: '服务确认',
    }
  },
  maintenanceCheckList: {
    title1: '维护检查清单',
    btn1: '模板',
    btn2: '打印',
    btn3: '导出',
    btn4: 'PDF 模板设计器',
    label1: '总计',
    col1: '图标',
    col2: '标题',
    col3: '副标题 1',
    col4: '子标题 2',
    col5: '状态',
    col6: '正常',
    col7: '异常',
  },
  newMaintenanceCheckList: {
    title1: '添加新的检查清单',
    title2: '编辑检查清单',
    title3: '查看检查清单',
    tab1: '表格元素',
    tab2: '表格内容',
    tab3: '检查清单',
    col1: '标题',
    col2: '副标题 1',
    col3: '副标题 2',
    col4: '图标',
    tableContent: {
      btn1: '导入',
      btn2: '向上',
      btn3: '向下',
      btn4: '选项编辑',
      btn5: '复制',
      col1: '主类型',
      col2: '项目',
      col3: '注释',
      col4: '检查类型',
      col5: '选项',
      col6: '计数',
      col7: '相关设备',
      checkDialog: {
        title1: '添加项目',
        title2: '编辑项目',
        title3: '创建副本',
        label1: '主类型',
        label2: '注释',
        label3: '检查项目',
        label4: '检查类型',
        label5: '选项',
        btn1: '创建一个副本',
      },
      field: {
        label1: '设备名称',
        label2: '设备品牌',
        label3: '设备类型',
        label4: '规格型号',
        label5: '安装位置',
        label6: '序列号',
        label7: '设备标签名称',
        label8: '安装编号',
        label9: '楼层',
        label10: '二维码',
        label11: '设备IP',
        label12: '备注',
      },
    },
    checkList: {
      btn1: '导入',
      btn2: '向上',
      btn3: '向下',
    }
  }
}

export default cn;
