<template>
  <div class="wrap">
    <el-card>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              size="medium"
              @click="showRate"
            >{{$t('quotation.btn1')}}</el-button>

          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <quotation-table
          ref="quotationTable"
          :table-data="tableData"
          :table-loading="tableLoading"
          :table-selected.sync="tableSelected"
          :table-height="tableHeight"
          :decimals="decimals"
          @row-dblclick="dblclickEdit"
        ></quotation-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 汇率弹框 -->
    <exchange-rate
      :visible.sync="ExchangeRateVisible"
      :rate-string.sync="exchangeRate"
      :decimals.sync="decimals"
      @update="searchData"
    ></exchange-rate>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import QuotationTable from '@/pages/warehouse/quotation/components/QuotationTable'
import ColSet from '@/components/ColSet'
import ExchangeRate from './components/ExchangeRate'
// 接口
import {
  GetQuotationList,
  DelQuotationCollection,
} from '@/api/warehouse/quotation'
export default {
  name: 'Quotation',
  components: {
    ColSet,
    ExchangeRate,
    QuotationTable,
  },
  data () {
    return {
      ExchangeRateVisible: false,
      permit: {
        search: true,
        add: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      exchangeRate: '',
      // exchangeRate: 'key=0@1=0.14144472@2=1.09620928@3=4.36653984@4=0.519512@5=0.19730548@6=194.91081911@7=0.60524194',
      decimals: Number(localStorage.quotationDecimals) || 2,
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.name === 'Quotation') {
        let ids = this.tableSelected.map(item => item.infoId)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.infoId))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    }
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      if (this.exchangeRate === '') return;
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        exchangeRate: this.exchangeRate,
      }
      this.tableLoading = true;
      GetQuotationList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.inquiryDate = formatDate(item.inquiryDate, 'yyyy-MM-dd')
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newQuote', type: 1 })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },

    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {

        let arr = this.tableSelected.map(item => this.delPromise({
          infoId: item.infoId,
        }))
        Promise.all(arr).then((res) => {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.del'))
        })
      }).catch(() => { });
    },
    delPromise (param) {
      return new Promise((resolve, reject) => {
        DelQuotationCollection(param).then(res => {
          if (res) resolve();
        })
      })
    },
    // 显示汇率
    showRate () {
      this.ExchangeRateVisible = true
    },
    // 行双击
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newQuotation', query: { id: e.infoId } })
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('QuotationSearch');
        this.permit.add = permission.includes('QuotationAdd');
        // this.permit.edit = permission.includes('QuotationEdit');
        this.permit.del = permission.includes('QuotationDelete');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>