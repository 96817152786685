<template>
  <div class="wrap">
    <el-card>
      <page-header :title="view.title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="view.type !== 3"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('outWarehouseDetails.btn1')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="200px"
      >

        <el-divider content-position="left">待出库</el-divider>
        <el-row
          v-if="view.type === 2 "
          :gutter="50"
        >
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label1')"
              prop="projectName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.projectName"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showProject('search')">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label2')"
              prop="contractNo"
            >
              <el-input
                disabled
                v-model="groupForm.contractNo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="input-wrap">
            <el-button-group v-if="view.type !== 3">
              <el-button
                :disabled="isMerge"
                v-if="view.type != 4"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
              <el-button
                :disabled="isMerge || (view.type === 2 && !groupForm.projectId)"
                v-if="view.type != 4"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                :disabled="isMerge"
                v-if="view.type === 2"
                size="medium"
                @click="showOutWarehouse"
              >库存出库</el-button>
            </el-button-group>
            <div v-else></div>
            <div class="switch-box">
              <el-button size="medium">Merge Same Items</el-button>
              <el-switch
                v-model="isMerge"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="mergeChange"
              ></el-switch>
            </div>
          </div>
        </el-row>
        <el-row>
          <el-table
            border
            :data="tableData"
            :row-class-name="tableRowClassName"
            ref="table1"
            v-loading="tableLoading"
            @selection-change="val => tableSelected = val"
            @cell-click="cellClick"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              min-width="180"
              prop="applicationFormNumber"
              :label="$t('outWarehouseDetails.col1')"
            >
              <template slot-scope="scope">
                <div v-html="scope.row.applicationFormNumber"></div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="180"
              prop="projectName"
              :label="$t('outWarehouseDetails.col7')"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div v-html="scope.row.projectName"></div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="deviceName"
              :label="$t('outWarehouseDetails.col2')"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="deviceBrand"
              :label="$t('outWarehouseDetails.col3')"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="deviceModel"
              :label="$t('outWarehouseDetails.col4')"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="appCount"
              :label="$t('outWarehouseDetails.col6')"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="inCount"
              label="已入库总数"
            >
            </el-table-column>
            <el-table-column
              v-if="view.type === 5 ||view.type === 4"
              min-width="130"
              prop="curInCount"
              label="当次入库数量"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="allPreCount"
              label="已预分配"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="outCount"
              label="出库1"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="outCount2"
              label="出库2"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="outCount3"
              label="出库3"
            >
            </el-table-column>
            <el-table-column
              min-width="130"
              prop="lockCount"
              label="锁定"
            >
            </el-table-column>
            <el-table-column
              label-class-name="fixed"
              fixed="right"
              min-width="130"
              label="剩余未出库"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.outCount < scope.row.lockCount">
                  {{scope.row.inCount - scope.row.lockCount - scope.row.outCount2}}
                </span>
                <span v-else>
                  {{scope.row.inCount - scope.row.outCount - scope.row.outCount2}}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label-class-name="fixed"
              fixed="right"
              min-width="130"
              prop="inventoryCount"
              label="可用库存总数"
            >
            </el-table-column>
            <el-table-column
              label-class-name="fixed"
              fixed="right"
              v-if="view.type == 2"
              min-width="130"
              prop="unstockedCount"
              label="待出库数"
            >
            </el-table-column>
            <el-table-column
              label-class-name="fixed"
              fixed="right"
              min-width="130"
              label="本次出库"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click.stop="showNumber(scope.row)"
                >{{scope.row.deviceCount2}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <template v-if="view.type !== 2">
          <el-divider content-position="left">
            {{$t('outWarehouseDetails.subtitle2')}}
          </el-divider>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item
                :label="$t('outWarehouseDetails.label3')"
                prop="outProjectName"
              >

                <el-button-group class="input-group">
                  <el-input
                    v-model="groupForm.outProjectName"
                    class="search-input"
                    readonly
                  ></el-input>
                  <el-button
                    :disabled="view.type === 3 || view.type === 4"
                    @click="showProject('out')"
                  >{{$t('operation.search')}}</el-button>
                </el-button-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('outWarehouseDetails.label4')"
                prop="outContractNo"
              >
                <el-input
                  v-model="groupForm.outContractNo"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-button-group v-if="view.type !== 3">
              <el-button
                :disabled="isMerge"
                v-if="view.type != 4"
                size="medium"
                @click="delItem2"
              >{{$t('operation.del')}}</el-button>
              <el-button
                :disabled="isMerge"
                v-if="view.type != 4"
                size="medium"
                @click="showAdd2"
              >{{$t('operation.add')}}</el-button>
            </el-button-group>
          </el-row>

          <el-row v-if="!((this.view.type == 3 || this.view.type == 4) && !this.applicationFormInfoId)">
            <el-table
              border
              :data="tableData2"
              :row-class-name="tableRowClassName"
              v-loading="tableLoading2"
              @selection-change="val => tableSelected2 = val"
              ref="table2"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="180"
                prop="applicationNumber"
                :label="$t('outWarehouseDetails.col1')"
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.applicationNumber"></div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="180"
                prop="projectName"
                :label="$t('outWarehouseDetails.col7')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.projectName"></div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="deviceName"
                :label="$t('outWarehouseDetails.col2')"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="brandName"
                :label="$t('outWarehouseDetails.col3')"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="deviceModel"
                :label="$t('outWarehouseDetails.col4')"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="count"
                :label="$t('outWarehouseDetails.col6')"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="inCount"
                label="已入库总数"
              >
              </el-table-column>
              <el-table-column
                v-if="view.type === 5 ||view.type === 4"
                min-width="130"
                label="当次入库数量"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="preCount"
                label="已预分配"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="outCount"
                label="出库1"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="outCount2"
                label="出库2"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="outCount3"
                label="出库3"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="lockCount"
                label="锁定"
              >
              </el-table-column>
              <el-table-column
                label-class-name="fixed"
                fixed="right"
                min-width="130"
                label="剩余未出库"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.outCount < scope.row.lockCount">
                    {{scope.row.inCount - scope.row.lockCount - scope.row.outCount2}}
                  </span>
                  <span v-else>
                    {{scope.row.inCount - scope.row.outCount - scope.row.outCount2}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label-class-name="fixed"
                fixed="right"
                min-width="130"
                prop="inventoryCount"
                label="可用库存总数"
              >
              </el-table-column>
              <el-table-column
                label-class-name="fixed"
                fixed="right"
                min-width="130"
                label="待出库数"
              >
                <template slot-scope="scope">
                  <el-input
                    disabled
                    size="mini"
                    v-model="scope.row.unstockedCount"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-row>

        </template>

        <el-divider content-position="left">{{$t('outWarehouseDetails.subtitle3')}}</el-divider>
        <el-row
          :gutter="50"
          style="margin-bottom:0"
        >
          <!-- <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label5')"
              prop="projectLocation"
            >
              <el-select v-model="groupForm.projectLocation">
                <el-option
                  v-for="item in projectLocations"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label6')"
              prop="contractType"
            >
              <el-input
                v-model="groupForm.contractType"
                readonly
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label7')"
              prop="isBorrow"
            >
              <el-radio-group
                v-model="groupForm.isBorrow"
                @change="isBorrowChnage"
              >
                <el-radio :label="0">{{$t('outWarehouseDetails.no')}}</el-radio>
                <el-radio :label="1">{{$t('outWarehouseDetails.yes')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label8')"
              prop="appointDate"
            >
              <el-date-picker
                v-model="groupForm.appointDate"
                type="date"
                value-format="yyyy-MM-dd"
                :disabled="groupForm.isBorrow === 0"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label9')"
              prop="remarks"
            >
              <el-input v-model="groupForm.remarks"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="50"
          style="margin-bottom:0"
        >
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label10')"
              prop="expressNumber"
            >
              <el-input v-model="groupForm.expressNumber "></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label11')"
              prop="logisticsCompany"
            >
              <el-select
                v-model="groupForm.logisticsCompany"
                filterable
              >
                <el-option
                  v-for="item in logisticsCompanys"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label12')"
              prop="courierFees"
            >
              <el-input v-model="groupForm.courierFees"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label16')"
              prop="shippingAddress"
            >
              <my-select
                :data="addresss"
                :value.sync="groupForm.shippingAddress"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="shippingAddressChange"
              ></my-select>
              <!-- <el-select
                v-model="groupForm.shippingAddress"
                filterable
                allow-create
                @change="shippingAddressChange"
              >
                <el-option
                  v-for="item in addresss"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label15')"
              prop="expressRemarks"
            >
              <el-input v-model="groupForm.expressRemarks"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('outWarehouseDetails.label13')"
              prop="consignee"
            >
              <el-row>
                <el-col :span="12">
                  <my-select
                    :data="contacts"
                    :value.sync="groupForm.consignee"
                    :prop="{
                      label:'name',
                      value:'name'
                    }"
                  ></my-select>
                  <!-- <el-select
                    v-model="groupForm.consignee"
                    filterable
                    allow-create
                  >
                    <el-option
                      v-for="item in contacts"
                      :key="item.id"
                      :value="item.name"
                      :label="item.name"
                    ></el-option>
                  </el-select> -->
                </el-col>
                <el-col :span="12">
                  <el-input
                    v-model="groupForm.tel"
                    :placeholder="$t('outWarehouseDetails.label14')"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </el-card>
    <!-- 项目弹框 -->
    <project-dialog
      ref="projectDialog"
      :visible.sync="ProjectDialogVisible"
      @submit="projectSubmit"
      :project-id="groupForm.outProjectId"
    ></project-dialog>
    <!-- 设备弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :ids="inIds"
      :project-id="groupForm.projectId"
      :type="view.type"
      :device-info-id="deviceInfoId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <equipment-dialog2
      ref="equipmentDialog2"
      :visible.sync="EquipmentDialog2Visible"
      :project-id="groupForm.outProjectId"
      :device-info-id="deviceInfoId"
      @submit="equipmentSubmit2"
    ></equipment-dialog2>
    <!-- 序列号弹框 -->
    <number-dialog
      :visible.sync="NumberDialogVisible"
      :data="numberData"
      @submit="numberSubmit"
    ></number-dialog>

    <!-- 其他项目出库弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="其他项目出库记录"
      :visible.sync="elseDialog.visible"
      center
      width="900px"
    >
      <out-warehouse-table :table-data="elseDialog.tableData"></out-warehouse-table>
    </el-dialog>
    <!-- 库存弹框 -->
    <inventory-dialog
      :visible.sync="InventoryDialogVisible"
      :data="inventoryData"
    ></inventory-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
import PageHeader from '@/components/PageHeader'
import ProjectDialog from '@/components/ProjectDialog'
import EquipmentDialog from '@/components/EquipmentDialog/EquipmentDialog5'
import EquipmentDialog2 from './components/EquipmentDialog3'
import OutWarehouseTable from './components/OutWarehouseTable'
import NumberDialog from './components/NumberDialog'
import InventoryDialog from './components/InventoryDialog'
// 接口
import {
  AddOutWarehouse,
  GetOutWarehouseItem,
  GetOutWarehouseDevice,
  GetOutWarehouseDeviceByDeviceId,
  GetOutWarehouseElseList,
} from '@/api/warehouse/inventoryManagement'
import { GetReceiptContactAll } from '@/api/warehouse/dataMaintenance/receiptAddress'
import { GetLogisticsCompanyAll } from '@/api/warehouse/dataMaintenance/logisticsCompany'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import { GetApplyDeviceList } from '@/api/warehouse/purchaseRequisition'
export default {
  name: 'OutWarehouseDetails',
  components: {
    MySelect,
    PageHeader,
    ProjectDialog,
    EquipmentDialog,
    EquipmentDialog2,
    OutWarehouseTable,
    NumberDialog,
    InventoryDialog,
  },
  data () {
    return {
      ProjectDialogVisible: false,
      EquipmentDialogVisible: false,
      EquipmentDialog2Visible: false,
      NumberDialogVisible: false,
      InventoryDialogVisible: false,
      view: {
        type: 1,
        title: '',
      },
      projectType: 'out',
      inIds: [],
      projectLocations: [],
      logisticsCompanys: [],
      addresss: [],
      contacts: [],
      groupForm: {
        id: null,
        loading: false,
        projectId: '',
        projectName: '',
        contractNo: '',
        outProjectId: '',
        outProjectName: '',
        outContractNo: '',
        projectLocation: '',
        contractType: '',
        isBorrow: 0,
        appointDate: null,
        remarks: '',
        expressNumber: '',
        logisticsCompany: '',
        courierFees: '',
        consignee: '',
        tel: '',
        expressRemarks: '',
        shippingAddress: '',
        customerName: '',
        branch: '',
        applyFormNo: '',
      },
      rules: {
        outProjectName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      tableLoading: false,
      tableSelected: [],
      originData: [],
      tableData: [],
      tableIndex: null,

      serialData: {},
      itemId: '',

      elseDialog: {
        visible: false,
        tableData: [],
      },
      numberData: {},
      inventoryData: {},

      tableLoading2: false,
      tableSelected2: [],
      originData2: [],
      tableData2: [],
      deviceInfoId: null,
      applicationFormInfoId: null,
      isMerge: false,

      outCount: null,
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.name === 'OutWarehouseDetails' && from.name === 'InventoryDeviceCode') {
        this.serialData = JSON.parse(localStorage.serialData)
        this.tableData.forEach(item => {
          item.maxCount = item.inCount - item.outCount - item.outCount2;
          item.deviceCount2 = item.maxCount;

          item.inventoryDevices.forEach(device => {
            if (item.serialNumbers.indexOf(device.serialNumber) !== -1 && !device.qrCode) {
              device.qrCode = this.serialData[device.id];
            }
          })
        });
      }
      if (to.name === 'OutWarehouseDetails' && from.name === to.name) {
        this.initView();
        if (to.query.type == 2) {
          this.getTableData();
        }
      }
    },
  },
  created () {
    this.initView();

    // 获取物流公司
    GetLogisticsCompanyAll({}).then(res => this.logisticsCompanys = res)
    // 获取收货地址
    GetDataFieldAll({ parent: '100000000000000000000000000000000014' }).then(res => this.addresss = res);
  },
  mounted () {

    this.tableScroll();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    initView () {
      this.view.type = Number(this.$route.query.type);
      this.applicationFormInfoId = this.$route.query.applicationFormInfoId

      switch (this.view.type) {
        case 1://库存出库
          this.view.title = this.$i18n.t('outWarehouseDetails.title1');
          this.getTableData();
          break;
        case 2://按项目出库
          this.view.title = this.$i18n.t('outWarehouseDetails.title2');
          break;
        case 3://查看出库
          this.getItem()
          this.getOutWarehouseDevice();
          break;
        case 4://编辑出库
          this.view.title = this.$i18n.t('outWarehouseDetails.title1');
          this.getItem()
          this.getOutWarehouseDevice();
          break;
        case 5://入库管理出库
          this.view.title = this.$i18n.t('outWarehouseDetails.title1');
          this.outCount = this.$route.query.outCount;
          this.getTableData();
          break;
        case 6://项目出库 转 库存出库
          this.view.title = this.$i18n.t('outWarehouseDetails.title1');
          this.projectType = 'out';
          this.deviceInfoId = this.$route.query.deviceInfoId;
          this.tableData = [];
          this.$nextTick(() => {
            this.$refs.equipmentDialog2.autoSelect(this.$route.query.id);
          })
          break;
      }
    },
    // 获出库设备列表
    getTableData () {
      let param = {
        limit: 9999,
        page: 1,
        search: '',
      }
      let type = Number(this.$route.query.type);

      switch (type) {
        case 1:
          this.deviceInfoId = this.$route.query.id;
          param.deviceInfoId = this.deviceInfoId;
          break;
        case 2:
          param.projectContractId = this.groupForm.projectId;
          break;
        case 5:
          this.deviceInfoId = this.$route.query.deviceInfoId;
          param.id = this.$route.query.id;
          break;
      }

      this.tableLoading = true;
      GetOutWarehouseDeviceByDeviceId(param).then(res => {
        this.tableData = res.list;
        this.itemId = this.tableData[0].id;
        this.tableLoading = false;

        if (type == 1 || type == 5 || type === 2) {
          if (type !== 2) {
            this.groupForm.outProjectId = this.tableData[0].projectContractId;
            this.$nextTick(() => {
              this.$refs.projectDialog.autoSelect();
              // this.$refs.equipmentDialog2.autoSelect();
            })
          }

          this.tableData = this.tableData.map(row => {
            row.unstockedCount = row.appCount - row.outCount - row.outCount3
            row.maxCount = row.inCount - row.outCount - row.outCount2;
            row.deviceCount2 = !!this.outCount ? this.outCount : row.maxCount;
            row.inventoryDevices = row.inventoryDevices.filter(item => {
              let re = new RegExp(`(?<=^|;)${item.serialNumber}(?=;|$)`);
              if (!item.serialNumber) return false;
              if (row.serialNumbers3) {
                return !re.test(row.serialNumbers3);
              } else {
                return true;
              }
            });
            row.serialNumbers = row.inventoryDevices.map(item => item.serialNumber).join(';');
            return row;
          });
        }
      }).catch(error => {
        this.tableLoading = false;
      })
    },

    getItem () {
      let param = { id: this.$route.query.id }
      GetOutWarehouseItem(param).then(res => {
        if (this.view.type == 3) this.view.title = `${this.$i18n.t('outWarehouseDetails.title3')}：${res.createTime}`;
        Object.assign(this.groupForm, {
          id: res.id,
          projectId: '',
          projectName: '',
          contractNo: '',
          outProjectId: res.projectContractId,
          outProjectName: res.projectName,
          outContractNo: res.quotationNumber,
          projectLocation: res.projectOrgId,
          contractType: res.projectType,
          isBorrow: res.isBorrowOut,
          appointDate: res.returnDate,
          remarks: res.outRemark,
          expressNumber: res.courierNumber,
          logisticsCompany: res.courierCompany,
          courierFees: res.courierCost,
          consignee: res.receiverName,
          tel: res.receiverPhoneNumber,
          expressRemarks: res.courierRemark,
          shippingAddress: res.receiverAddress,
        })
      });

    },
    // 获取出库详情设备列表
    getOutWarehouseDevice () {
      let param = { outId: this.$route.query.id };
      let deviceCount2 = 0;
      GetOutWarehouseDevice(param).then(res => {
        this.itemId = res.list[0].id;
        this.tableData = res.list.map(item => {
          item.maxCount = item.inCount - item.outCount - item.outCount2 + item.deviceCount2;
          item.deviceCount += item.deviceCount2;
          deviceCount2 += item.deviceCount2;
          return item;
        });

        if (!this.applicationFormInfoId) return;
        let param2 = {
          limit: 9999,
          page: 1,
          search: '',
          deviceInfoId: this.applicationFormInfoId
        }
        GetApplyDeviceList(param2).then(res => {
          this.tableData2 = res.list.map(item => {
            item.unstockedCount = item.count - item.outCount - item.outCount3 + deviceCount2;
            return item;
          });
        })
      })
    },
    // 提交
    submit () {
      if (this.isMerge) {
        this.isMerge = false;
        this.mergeChange();
      }

      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        if (this.tableData.length === 0) return this.$message.warning(this.$i18n.t('outWarehouseDetails.message1'));
        // if (this.view.type != 2 && this.tableData2.length === 0) return this.$message.warning('请选择出库申请单');

        // 出库数量验证
        let count1 = this.tableData.reduce((total, item) => {
          return total += item.deviceCount2
        }, 0)

        if (count1 === 0) return this.$message.warning('出库数量不能为0');

        if (this.view.type != 2 && this.tableData2.length !== 0) {
          let count2 = this.tableData2.reduce((total, item) => {
            return total += item.unstockedCount
          }, 0)
          if (count1 > count2) return this.$message.warning('出库数量超出');
        }
        // 无二维码需要分配二维码再出库
        // if (this.isQRcode())
        //   return this.$confirm(this.$i18n.t('outWarehouseDetails.message3'), '提示', {
        //     confirmButtonText: this.$i18n.t('outWarehouseDetails.btn3'),
        //     cancelButtonText: this.$i18n.t('operation.cancel'),
        //     type: 'warning',
        //     center: true,
        //   }).then(() => {
        //     localStorage.serialData = JSON.stringify(this.serialData);
        //     this.$router.push({ path: '/home/inventoryDeviceCode', query: { id: null, type: 4 } });
        //   }).catch(() => { });

        let param = [];
        if (this.view.type != 2) {
          if ((this.view.type == 3 || this.view.type == 4) && !this.applicationFormInfoId) {
            param = this.dealParam2();
          } else {
            if (this.tableData2.length !== 0) {
              param = this.dealParam();
            } else {
              param = this.dealParam2();
            }
          }
        } else {
          param = this.dealParam2();
        }

        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.forEach(item => {
            if (item.inventoryId === this.itemId)
              item.id = this.groupForm.id
          })

          msg = this.$i18n.t('message.edit');
        }
        // return ;
        this.groupForm.loading = true;
        AddOutWarehouse(param).then(res => {
          if (res.success) {
            this.back();
            this.$message.success(msg);
          }
          this.groupForm.loading = false;
        })
      })
    },
    dealParam () {
      let param = [];
      this.tableData2.forEach(appForm => {

        for (var i = 0; i < this.tableData.length; i++) {
          let item = this.tableData[i];
          let serialNumbers = !!item.serialNumbers ? item.serialNumbers.split(';') : [];
          let count2 = serialNumbers.length;
          let count1 = item.deviceCount2 - count2;
          let deviceCount2 = 0;
          let serialNumber = '';

          if (appForm.unstockedCount <= count1) {
            deviceCount2 += appForm.unstockedCount;
            item.deviceCount2 -= appForm.unstockedCount;
            appForm.unstockedCount = 0;
          } else {
            deviceCount2 += count1;
            appForm.unstockedCount -= count1;
            item.deviceCount2 -= count1;
            if (appForm.unstockedCount <= count2) {
              deviceCount2 += appForm.unstockedCount;
              item.deviceCount2 -= appForm.unstockedCount;
              serialNumber = serialNumbers.slice(0, appForm.unstockedCount).join(';');
              appForm.unstockedCount = 0;
            } else {
              deviceCount2 += count2;
              appForm.unstockedCount -= count2;
              item.deviceCount2 -= count2;
              serialNumber = serialNumbers.join(';');
            }
          }
          param.push({
            inventoryId: item.id,
            count2: item.inCount - item.outCount - item.outCount2,
            count: deviceCount2,
            serialNumbers2: item.serialNumbers2 || '',
            serialNumbers: serialNumber,
            projectContractId: this.groupForm.outProjectId,
            projectOrgId: this.groupForm.projectLocation,
            isBorrowOut: this.groupForm.isBorrow,
            outRemark: this.groupForm.remarks,
            courierCompany: this.groupForm.logisticsCompany,
            courierCost: this.groupForm.courierFees,
            courierNumber: this.groupForm.expressNumber,
            receiverName: this.groupForm.consignee,
            receiverPhoneNumber: this.groupForm.tel,
            receiverAddress: this.groupForm.shippingAddress,
            courierRemark: this.groupForm.expressRemarks,
            isReturned: 0,
            deviceInfoId: item.deviceInfoId,
            applicationFormNumber: item.applicationFormNumber,
            applicationFormInfoId: appForm.id,
            returnDate: this.groupForm.appointDate,
            isProject: this.view.type === 2 ? 1 : 0,
          })

          if (appForm.unstockedCount === 0) break;
        }

        let delIndex = null;
        this.tableData.some((item, index) => {
          if (item.deviceCount2 === 0) {
            delIndex = index;
          } else {
            return true;
          }
        })

        if (delIndex !== null) {
          this.tableData.splice(0, delIndex + 1)
        }
      })
      param = param.filter(item => item.count !== 0);
      return param;
    },
    dealParam2 () {
      let param = this.tableData.map(item => {
        return {
          inventoryId: item.id,
          count2: item.inCount - item.outCount - item.outCount2,
          count: item.deviceCount2,
          serialNumbers2: item.serialNumbers2 || '',
          serialNumbers: item.serialNumbers,
          projectContractId: this.groupForm.outProjectId,
          projectOrgId: this.groupForm.projectLocation,
          isBorrowOut: this.groupForm.isBorrow,
          outRemark: this.groupForm.remarks,
          courierCompany: this.groupForm.logisticsCompany,
          courierCost: this.groupForm.courierFees,
          courierNumber: this.groupForm.expressNumber,
          receiverName: this.groupForm.consignee,
          receiverPhoneNumber: this.groupForm.tel,
          receiverAddress: this.groupForm.shippingAddress,
          courierRemark: this.groupForm.expressRemarks,
          isReturned: 0,
          deviceInfoId: item.deviceInfoId,
          applicationFormNumber: item.applicationFormNumber,
          returnDate: this.groupForm.appointDate,
          isProject: this.view.type === 2 ? 1 : 0,
        }
      });
      return param;
    },
    // 显示添加
    showAdd () {
      this.inIds = this.tableData.map(item => item.id).join(',');

      this.EquipmentDialogVisible = true
    },
    showAdd2 () {
      this.EquipmentDialog2Visible = true
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
      }).catch(() => { });
    },
    delItem2 () {
      if (this.tableSelected2.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected2.map(item => item.rowIndex);
        this.tableData2 = this.tableData2.filter(item => !checkIndex.includes(item.rowIndex));
      }).catch(() => { });
    },
    showNumber (row) {
      this.numberData = row;
      this.NumberDialogVisible = true;
    },
    numberSubmit (data) {
      let item = this.tableData[data.index];
      item.deviceCount2 = data.count;
      item.serialNumbers = data.tableSelected.map(item => item.serialNumber).join(';');
      this.$set(this.tableData, data.index, item);
    },
    // 显示项目 
    showProject (type) {
      this.projectType = type;
      this.ProjectDialogVisible = true;
    },
    // 项目提交
    projectSubmit (data) {
      if (this.projectType === 'search') {
        Object.assign(this.groupForm, {
          projectId: data.id,
          projectName: data.name,
          contractNo: data.quotationNumber,

          outProjectId: data.id,
          outProjectName: data.name,
          outContractNo: data.quotationNumber,
          contractType: data.projectType,
          customerName: data.customerName,
          branch: data.org,
        });
        this.getTableData();
      } else if (this.projectType === 'out') {
        Object.assign(this.groupForm, {
          outProjectId: data.id,
          outProjectName: data.name,
          outContractNo: data.quotationNumber,
          contractType: data.projectType,
          customerName: data.customerName,
          branch: data.org,
        })
        this.$refs.groupForm.validateField('outProjectName');
        this.tableData2 = [];

        this.$nextTick(() => {
          this.$refs.equipmentDialog2.autoSelect();
        })
      }
    },
    // 设备提交
    equipmentSubmit (data) {
      if (this.tableData.find(item => item.applicationFormNumber === data.applicationFormNumber && item.deviceInfoId === data.deviceInfoId)) {
        this.$message.warning(`${data.applicationFormNumber}申请单的${data.deviceName}设备已存在`)
      } else {
        data.unstockedCount = data.appCount - data.outCount - data.outCount3
        data.maxCount = data.inCount - data.outCount - data.outCount2;
        data.deviceCount2 = data.maxCount;

        this.tableData.push(data)
      }
    },
    // 设备提交
    equipmentSubmit2 (data) {
      if (this.tableData2.some(item => item.id === data.id)) {
        this.$message.warning(`${data.applicationNumber}申请单的${data.deviceName}设备已存在`)
      } else {
        data.unstockedCount = data.count - data.outCount - data.outCount3
        this.tableData2.push(data)
      }
    },
    // 是否借用
    isBorrowChnage () {
      this.groupForm.appointDate = null;
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    // 收货地址变更 
    shippingAddressChange (e) {
      let shippingAddress = this.addresss.find(item => item.name === e)
      if (!!shippingAddress) {
        let param = {
          name1: this.groupForm.consignee,
          addressId: shippingAddress.id,
        }
        GetReceiptContactAll(param).then(res => this.contacts = res)
      }
    },
    // 验证是否分配二维码
    isQRcode () {
      let result = false;
      this.serialData = {};
      this.tableData.forEach(item => {
        item.inventoryDevices.forEach(device => {
          let re = new RegExp(`(?<=^|;)${device.serialNumber}(?=;|$)`);
          if (re.test(item.serialNumbers)) {
            if (!device.qrCode) result = true;
            this.serialData[device.id] = !!device.qrCode ? device.qrCode : '';
          }
        })
      });
      return result
    },

    // 
    cellClick (row, column, cell, event) {
      if (column.property === 'outCount2' && row.outCount2 !== 0) {
        GetOutWarehouseElseList({
          deviceInfoId: row.deviceInfoId,
          applicationFormNumber: row.applicationFormNumber
        }).then(res => {
          this.elseDialog.tableData = res;
          this.elseDialog.visible = true;
        })
      } else {
        this.inventoryData = row;
        this.InventoryDialogVisible = true;
      }
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e);
    },
    // 申请单提交
    applySubmit (data) {
      if (!!data) {
        if (!this.groupForm.projectId) {
          Object.assign(this.groupForm, {
            projectId: data.projectContractId,
            projectName: data.projectName,
            contractNo: data.quotationNumber,
          });
        }
        Object.assign(this.groupForm, {
          applyFormId: data.id,
          applyFormNo: data.applicationNumber,
          requisitioner: data.purchaseUserId,
        });
      } else {
        Object.assign(this.groupForm, {
          applyFormId: '',
          applyFormNo: '',
          requisitioner: '',
        });
      }
    },
    tableScroll () {
      if (!this.$refs.table1 || !this.$refs.table2) return;

      let table1 = this.$refs.table1.bodyWrapper
      let table2 = this.$refs.table2.bodyWrapper

      table1.addEventListener('scroll', () => {
        table2.scrollLeft = table1.scrollLeft;
      })
      table2.addEventListener('scroll', () => {
        table1.scrollLeft = table2.scrollLeft;
      })
    },
    showOutWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.$router.push({
        path: '/home/outWarehouseDetails',
        query: {
          id: this.tableSelected[0].id,
          deviceInfoId: this.tableSelected[0].deviceInfoId,
          // appNumber: this.tableSelected[0].applicationFormNumber,
          // projectId: this.tableSelected[0].projectContractId,
          type: 6
        }
      })
    },
    // 合并开关
    mergeChange () {
      if (this.isMerge) {
        let data = {};
        let data2 = {};
        let tempData = [];
        let tempData2 = [];
        this.originData = JSON.parse(JSON.stringify(this.tableData));
        this.originData2 = JSON.parse(JSON.stringify(this.tableData2));

        this.tableData.forEach(item => {
          if (!data[item.deviceInfoId]) {
            data[item.deviceInfoId] = [item]
          } else {
            data[item.deviceInfoId].push(item);
          }
        })

        this.tableData2.forEach(item => {
          if (!data2[item.deviceInfoId]) {
            data2[item.deviceInfoId] = [item]
          } else {
            data2[item.deviceInfoId].push(item);
          }
        })

        for (var i in data) {
          let temp = data[i][0];
          for (var j = 1; j < data[i].length; j++) {
            if (!!data[i][j].applicationFormNumber) temp.applicationFormNumber += `<br>${data[i][j].applicationFormNumber}`
            temp.projectName += `<br>${data[i][j].projectName}`;
            temp.appCount += parseInt(data[i][j].appCount);
            temp.inCount += parseInt(data[i][j].inCount);
            temp.outCount += parseInt(data[i][j].outCount);
            temp.outCount2 += parseInt(data[i][j].outCount2);
            temp.outCount3 += parseInt(data[i][j].outCount3);
            temp.lockCount += parseInt(data[i][j].lockCount);
            temp.inventoryCount += parseInt(data[i][j].inventoryCount);
            temp.unstockedCount += parseInt(data[i][j].unstockedCount);
            temp.deviceCount2 += parseInt(data[i][j].deviceCount2);
            temp.maxCount += parseInt(data[i][j].maxCount);
            temp.orderCount += parseInt(data[i][j].orderCount);

            temp.inventoryDevices = temp.inventoryDevices.concat(data[i][j].inventoryDevices);
            if (!!data[i][j].serialNumbers) temp.serialNumbers += `;${data[i][j].serialNumbers}`
          }
          tempData.push(temp);
        }

        for (var i in data2) {
          let temp = data2[i][0];
          for (var j = 1; j < data2[i].length; j++) {
            if (!!data2[i][j].applicationNumber) temp.applicationNumber += `<br>${data2[i][j].applicationNumber}`
            temp.projectName += `<br>${data2[i][j].projectName}`;
            temp.count += parseInt(data2[i][j].count);
            temp.inCount += parseInt(data2[i][j].inCount);
            temp.preCount += parseInt(data2[i][j].preCount);
            temp.outCount += parseInt(data2[i][j].outCount);
            temp.outCount2 += parseInt(data2[i][j].outCount2);
            temp.outCount3 += parseInt(data2[i][j].outCount3);
            temp.lockCount += parseInt(data2[i][j].lockCount);
            temp.inventoryCount += parseInt(data2[i][j].inventoryCount);
            temp.unstockedCount += parseInt(data2[i][j].unstockedCount);
          }
          tempData2.push(temp);
        }
        this.tableData = tempData;
        this.tableData2 = tempData2;
      } else {
        let _deviceCount2 = this.tableData[0].deviceCount2;
        let _serialNumbers = !!this.tableData[0].serialNumbers ? this.tableData[0].serialNumbers.split(';') : [];
        let _count1 = _deviceCount2 - _serialNumbers.length;

        for (var i = 0; i < this.originData.length; i++) {
          let item = this.originData[i];
          let count2 = item.inventoryDevices.length;
          let count1 = item.maxCount - count2;

          item.serialNumbers = '';
          item.deviceCount2 = 0;

          if (_count1 === 0 && _serialNumbers.length === 0) continue;

          if (_count1 !== 0) {
            if (count1 <= _count1) {
              item.deviceCount2 = count1;
              _count1 -= count1
            } else if (count1 > _count1) {
              item.deviceCount2 = _count1;
              _count1 = 0;
            }
          }

          if (_serialNumbers.length !== 0) {
            let inventoryDevices = item.inventoryDevices.filter(item => {
              let index = _serialNumbers.indexOf(item.serialNumber);
              if (index !== -1) {
                _serialNumbers.splice(index, 1)
                return true;
              } else {
                return false;
              }
            })
            item.serialNumbers = inventoryDevices.map(item => item.serialNumber).join(';');
            item.deviceCount2 += inventoryDevices.length;
          }
        }

        this.tableData = this.originData
        this.tableData2 = this.originData2;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  height: 36px;
}
.label-item {
  display: flex;
  align-items: center;
  label {
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
}

.el-table {
  .el-button {
    width: 100%;
  }
  ::v-deep .fixed {
    // background-color: red;
    background-color: #b2d9ff;
  }
}
</style>