<template>
  <div>
    <el-table border      :data="tableData"
      v-loading="tableLoading"
      @row-dblclick="dblclick"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        min-width="150"
        prop="name"
        :label="$t('newProjects.documents.col1')"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="pdNames"
        :label="$t('newProjects.documents.col2')"
        sortable
      >
      </el-table-column>
      <!-- <el-table-column
        min-width="180"
        prop="xxx"
        :label="$t('newProjects.documents.col3')"
        sortable
      >
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import {
  GetProjectsDocumentList,
} from '@/api/warehouse/projects'
export default {
  data () {
    return {
      projectsId: null,
      tableLoading: false,
      tableData: [],
    }
  },
  created () {
    this.projectsId = this.$route.query.id
    if (!!this.projectsId) {
      this.getTableData();
    }
  },
  methods: {
    getTableData () {
      this.tableLoading = true;
      GetProjectsDocumentList(this.projectsId).then(res => {
        this.tableData = res;
        this.tableLoading = false;
      })
    },
    // 双击 显示文档详情
    dblclick (item) {
      this.$router.push({ path: '/home/documentsDetail', query: { id: item.id, name: item.name } })
    }
  }
}
</script>

<style>
</style>