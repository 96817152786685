<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="700px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          :data="tableData"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="name"
            label="Issue SubType"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="Item Type"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="Issue Description"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Replace with',
      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col1'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData () {
      return;
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        faultItemId: this.data.id,
      }
      this.tableLoading = true;
      GetByFaultDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          faultTypeId: this.dialog.faultType,
          sn: 1,
          description: this.dialog.description,
          indexKeyword: this.dialog.key,
          heat: this.dialog.heat,
        }

        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        } else {
          param.deviceTypeId = this.tableData.map(item => item.id).join(',')
        }

        this.dialog.loading = true;
        AddFaultTypeItem(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            if (res.status === 100) this.$message.warning('User account is occupied.')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
</style>