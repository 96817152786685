<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              :disabled="!permit.edit"
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            sortable
            prop="name"
            :label="$t('reportTemplates.col1')"
            min-width="250"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="description"
            :label="$t('reportTemplates.col2')"
            min-width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="accessGroupName"
            :label="$t('reportTemplates.col3')"
            min-width="170"
          >
          </el-table-column>
          <el-table-column
            sortable
            :label="$t('reportTemplates.col4')"
            min-width="210"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.counterMode"
                size="small"
              >Counter Mode</el-tag>
              <el-tag
                v-if="scope.row.diningMode"
                size="small"
              >Dining Mode</el-tag>
              <el-tag
                v-if="scope.row.userDefined"
                size="small"
              >User Defined</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            sortable
            prop="analysisMode"
            :label="$t('reportTemplates.col5')"
            min-width="140"
          >
            <template slot-scope="scope">
              {{scope.row.analysisMode === 1 ? 'User Defined' : 'System'}}
            </template>
          </el-table-column>
          <el-table-column
            sortable
            prop="createByName"
            :label="$t('reportTemplates.col6')"
            min-width="130"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 报表弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="reportDialog.title"
      :visible.sync="reportDialog.visible"
      center
      width="600px"
    >
      <el-form
        :model="reportDialog"
        :rules="rules"
        size="small"
        class="user-form"
        ref="reportForm"
        v-loading="reportDialog.formLoading"
      >
        <el-form-item
          :label="$t('reportTemplates.dialog.label1')"
          prop="name"
        >
          <el-input v-model="reportDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportTemplates.dialog.label2')"
          prop="description"
        >
          <el-input v-model="reportDialog.description"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportTemplates.dialog.label3')"
          prop="dataAccessGroup"
        >
          <el-select
            :disabled="!permit.editDataAccessGroup"
            v-model="reportDialog.dataAccessGroup"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('reportTemplates.dialog.label4')"
          prop="analysisMode"
        >
          <el-select v-model="reportDialog.analysisMode">
            <el-option
              label="User Defined"
              :value="1"
            > </el-option>
            <el-option
              label="System"
              :value="2"
            > </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('reportTemplates.dialog.label5')"
          class="switch-item"
          prop="counterMode"
        >
          <el-switch
            v-model="reportDialog.counterMode"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="reportDialog.counterMode ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('reportTemplates.dialog.label6')"
          class="switch-item"
          prop="diningMode"
        >
          <el-switch
            v-model="reportDialog.diningMode"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="reportDialog.diningMode ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!-- <el-form-item
          :label="$t('reportTemplates.dialog.label7')"
          class="switch-item"
          prop="userDefined"
        >
          <el-switch
            v-model="reportDialog.userDefined"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="reportDialog.userDefined ? 'Enable':'Disable'"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('reportTemplates.dialog.label8')"
          class="switch-item"
          prop="isKpi"
        >
          <el-switch
            v-model="reportDialog.isKpi"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="reportDialog.isKpi ? 'Enable':'Disable'"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </el-form-item> -->
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="reportDialog.visible = false">{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          :loading="reportDialog.loading"
          @click="submitReport"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ColSet from '@/components/ColSet'
import {
  GetReportList,
  AddReport,
  DelReport,
} from '@/api/reportTemplates'
import { GetDataAccessGroupAll } from '@/api/organizations'
export default {
  name: 'ReportTemplates',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
        copy: true,
        editDataAccessGroup: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      dataAccessGroups: [],
      reportDialog: {
        visible: false,
        formLoading: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        description: '',
        dataAccessGroup: localStorage.accessGroupId,
        analysisMode: 1,
        counterMode: 1,
        diningMode: 0,
        userDefined: 0,
        isKpi: 0,
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Report Templates Name.', trigger: 'blur' }],
      }
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'ReportTemplates') {
        this.getTableData();
      }
    },
    'reportDialog.visible': function (newVal) {
      if (newVal) {
        this.getOption();
      }
    },
    'reportDialog.counterMode': function (newVal) {
      if (newVal) {
        this.reportDialog.userDefined = 0;
      }
    },
    'reportDialog.diningMode': function (newVal) {
      if (newVal) {
        this.reportDialog.userDefined = 0;
      }
    },
    'reportDialog.userDefined': function (newVal) {
      if (newVal) {
        this.reportDialog.counterMode = 0;
        this.reportDialog.diningMode = 0;
      }
    },
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetReportList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 获取下拉框数据
    getOption () {
      this.reportDialog.formLoading = true;
      Promise.all([
        // 获取数据访问组
        GetDataAccessGroupAll().then(res => this.dataAccessGroups = res),
      ]).then((result) => {
        this.reportDialog.formLoading = false;
      })
    },
    showAdd () {
      this.$refs.reportForm && this.$refs.reportForm.resetFields()
      this.reportDialog.id = null;
      this.reportDialog.title = this.$i18n.t('reportTemplates.dialog.title1');
      this.reportDialog.visible = true;

    },
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (item) {
      if (!this.permit.edit) return;

      this.$refs.reportForm && this.$refs.reportForm.resetFields();
      this.reportDialog.visible = true;
      this.$nextTick(() => {
        Object.assign(this.reportDialog, {
          id: item.id,
          title: this.$i18n.t('reportTemplates.dialog.title2'),
          name: item.name,
          description: item.description,
          dataAccessGroup: item.accessGroupId,
          analysisMode: item.analysisMode,
          counterMode: item.counterMode,
          diningMode: item.diningMode,
          userDefined: item.userDefined,
          isKpi: item.kpi,
        })
      })

    },
    // 添加、编辑报表
    submitReport () {
      this.$refs.reportForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.reportDialog.name,
          description: this.reportDialog.description,
          accessGroupId: this.reportDialog.dataAccessGroup,
          analysisMode: this.reportDialog.analysisMode,
          counterMode: this.reportDialog.counterMode,
          diningMode: this.reportDialog.diningMode,
          userDefined: this.reportDialog.userDefined,
          kpi: this.reportDialog.isKpi,
        }
        let msg = this.$i18n.t('message.add')
        if (this.reportDialog.id) {
          param.id = this.reportDialog.id;
          msg = this.$i18n.t('message.edit')
        }

        this.reportDialog.loading = true;
        AddReport(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.reportDialog.visible = false;
            this.getTableData();
          } else {
            if (res.status === 100) this.$message.warning('Report Templates Name is occupied.')
          }
          this.reportDialog.loading = false;
        }).catch(error => {
          this.reportDialog.loading = false;
        })
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelReport(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('ReportTemplatesSearchReportTemplates');
        this.permit.add = permission.includes('ReportTemplatesAddReportTemplates');
        this.permit.edit = permission.includes('ReportTemplatesEditReportTemplates');
        this.permit.del = permission.includes('ReportTemplatesDeleteReportTemplates');
        this.permit.copy = permission.includes('ReportTemplatesCopyReportTemplates');
        this.permit.editDataAccessGroup = permission.includes('ReportTemplatesEditDataAccessGroups(DataPermissions)');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.user-form {
  .el-select {
    width: 100%;
  }

  .switch-item {
    text-align: right;
    .el-switch {
      width: 100px;
    }
  }
}
</style>
