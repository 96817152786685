<template>
  <div>
    <el-row>
      <el-button-group>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="delItem"
        >{{$t('operation.del')}}</el-button>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="addItem"
        >{{$t('operation.add')}}</el-button>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="showEdit"
        >{{$t('operation.edit')}}</el-button>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="copyItem"
        >{{$t('operation.copy')}}</el-button>
      </el-button-group>
    </el-row>
    <el-table
      border
      ref="table"
      :data="tableData"
      :row-class-name="tableRowClassName"
      @selection-change="val => tableSelected = val"
      @row-dblclick="dblclickEdit"
      @row-click="rowClick"
    >
      <el-table-column type="selection"></el-table-column>

      <el-table-column
        prop="deviceInfoName"
        :label="$t('newServiceConfirmationForm.tab6.col1')"
      >
      </el-table-column>
      <el-table-column
        prop="location"
        :label="$t('newServiceConfirmationForm.tab6.col2')"
      >
      </el-table-column>
      <el-table-column
        prop="faultDescription"
        :label="$t('newServiceConfirmationForm.tab6.col3')"
      >
      </el-table-column>
      <el-table-column
        prop="remark"
        :label="$t('newServiceConfirmationForm.tab6.col4')"
      >
      </el-table-column>

      <el-table-column
        prop="typeName"
        :label="$t('newServiceConfirmationForm.tab6.col5')"
      >
      </el-table-column>
    </el-table>
    <!-- 现场维修设备 -->
    <installation-dialog
      :visible.sync="InstallationDialogVisble"
      :data="maintenanceData"
      :describe="describe"
      :dispose="dispose"
      :branch-id="branchId"
      @submit="maintenanceSubmit"
    ></installation-dialog>
  </div>
</template>

<script>
// 组件
import InstallationDialog from './InstallationDialog'

export default {
  components: {
    InstallationDialog
  },
  props: ['data', 'permit', 'describe', 'dispose', 'branchId'],
  data () {
    return {
      InstallationDialogVisble: false,

      tableSelected: [],
      tableData: [],
      maintenanceData: null,
    }
  },
  watch: {
    'data': function (newVal) {
      this.tableData = newVal;
    }
  },
  methods: {
    // 新增
    addItem () {
      this.maintenanceData = null
      this.InstallationDialogVisble = true;
    },
    // 修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      if (!this.permit.editInfo) return;
      this.maintenanceData = e;
      this.InstallationDialogVisble = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
      }).catch(() => { });
    },
    // 复制
    copyItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.copyData(this.tableSelected[0])
    },
    copyData (data) {
      let item = JSON.parse(JSON.stringify(data));
      item.id = '';
      item.location = '';
      item.serialNumber = '';
      item.qrCode = '';
      this.tableData.push(item)

      this.$nextTick(() => {
        // 重新打开弹框
        this.maintenanceData = item;
        this.InstallationDialogVisble = true;
      })
    },
    // 新增现场维修设备
    maintenanceSubmit ({ data, type, copy }) {
      switch (type) {
        case 'add':
          this.tableData.push(data);
          break
        case 'edit':
          this.$set(this.tableData, data.rowIndex, data);
          break
      }

      if (copy) this.copyData(data);
    },
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  },
}
</script>

<style>
</style>