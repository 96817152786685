import request from '@/utils/request'

// 新增和修改
export const AddAccountPolicies = (data) => request({
  method: 'post',
  url: '/SysAccountPolicies/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取账号安全策略
export const GetAccountPoliciesItem = (data) => request({
  method: 'post',
  url: '/SysAccountPolicies/getTop1.do',
  data: JSON.stringify(data)
})

// 获取被拦截的ip记录
export const GetSysLockedAccountList = (data) => request({
  method: 'post',
  url: '/sysLockedAccount/queryByPage.do',
  data: JSON.stringify(data)
})

// 删除
export const DelSysLockedAccount = (data) => request({
  method: 'post',
  url: '/sysLockedAccount/delete.do',
  data: JSON.stringify(data)
})
