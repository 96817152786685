<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="220px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuote.label1')"
              prop="deviceName"
            >
              <my-select
                :disabled="viewType == 2 || viewType == 3"
                :data="deviceNames"
                :value.sync="groupForm.deviceName"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="deviceNameChange"
              ></my-select>

              <!-- <el-select
                :disabled="viewType == 2 || viewType == 3"
                v-model="groupForm.deviceName"
                allow-create
                filterable
                remote
                :remote-method="queryDeviceName"
                @change="deviceNameChange"
              >
                <el-option
                  v-for="item in deviceNames"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label2')"
              prop="brand"
            >
              <my-select
                :disabled="viewType == 2 || viewType == 3"
                :data="brands"
                :value.sync="groupForm.brand"
                :prop="{
                  label:'name',
                  value:'name'
                }"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label3')"
              prop="supplierId"
            >
              <my-select
                :data="suppliers"
                :value.sync="groupForm.supplierId"
                :prop="{
                  label:'name',
                  value:'id'
                }"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label4')"
              prop="contact"
            >
              <my-select
                :data="contacts"
                :value.sync="groupForm.contact"
                :prop="{
                  label:'name',
                  value:'name'
                }"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label5')"
              prop="phone"
            >
              <el-input v-model="groupForm.phone"></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('newQuote.label6')"
              prop="currency"
            >
              <el-select v-model="groupForm.currency">
                <el-option
                  value="0"
                  label="人民币"
                >
                </el-option>
                <el-option
                  value="1"
                  label="美元"
                >
                </el-option>
                <el-option
                  value="2"
                  label="港币"
                >
                </el-option>
                <el-option
                  value="3"
                  label="泰铢"
                >
                </el-option>
                <el-option
                  value="4"
                  label="迪拉姆"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label7')"
              prop="unitPrice"
            >
              <el-input v-model="groupForm.unitPrice"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label8')"
              prop="taxes"
            >
              <el-input v-model="groupForm.taxes"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label9')"
              prop="discount"
            >
              <el-input v-model="groupForm.discount"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label10')"
              prop="creator"
            >
              <el-select
                v-model="groupForm.creator"
                filterable
              >
                <el-option
                  v-for="item in creators"
                  :key="item.id"
                  :value="item.id"
                  :label="item.fullname"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuote.label11')"
              prop="model"
            >
              <my-select
                :disabled="viewType == 2"
                :data="models"
                :value.sync="groupForm.model"
                :prop="{
                label:'model',
                value:'model'
              }"
              ></my-select>
              <!-- <el-select
                :disabled="viewType == 2"
                v-model="groupForm.model"
                allow-create
                filterable
                remote
                :remote-method="queryModel"
              >
                <el-option
                  v-for="item in models"
                  :key="item.id"
                  :value="item.model"
                  :label="item.model"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label12')"
              prop="type"
            >
              <my-select
                :disabled="viewType == 2 || viewType == 3"
                :data="types"
                :value.sync="groupForm.type"
                :prop="{
                label:'name',
                value:'name'
              }"
              ></my-select>

              <!-- <el-select
                :disabled="viewType == 2 || viewType == 3"
                v-model="groupForm.type"
                allow-create
                filterable
                remote
                :remote-method="queryType"
              >
                <el-option
                  v-for="item in types"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label13')"
              prop="email"
            >
              <el-input v-model="groupForm.email"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label14')"
              prop="fax"
            >
              <el-input v-model="groupForm.fax"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label15')"
              prop="position"
            >
              <el-input v-model="groupForm.position"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label16')"
              prop="description"
            >
              <el-input
                type="textarea"
                :rows="4"
                v-model="groupForm.description"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label17')"
              prop="projectName"
            >
              <el-input v-model="groupForm.projectName"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label18')"
              prop="expiringDate"
            >
              <el-date-picker
                v-model="groupForm.expiringDate"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              :label="$t('newQuote.label19')"
              prop="inquiryDate"
            >
              <el-date-picker
                v-model="groupForm.inquiryDate"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('newQuote.subtitle1')}}</el-divider>
        <el-row>
          <el-upload
            ref=""
            :action="baseUrl + '/Engineer/fileUpload.do'"
            :headers="headers"
            list-type="picture-card"
            :file-list="groupForm.fileList"
            :on-preview="previewImage"
            :on-success="uploadCall"
            :on-remove="delCall"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-row>
      </el-form>
    </el-card>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="preview.visible"
      :on-close="previewClose"
      :url-list="preview.imageUrl"
    ></el-image-viewer>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
import PageHeader from '@/components/PageHeader'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// 接口
import {
  AddQuotation,
  GetQuotationItem,
  GetSupplierContact,
} from '@/api/warehouse/quotation'
import { GetDeviceNameAll, GetDeviceModelAll } from '@/api/warehouse/dataMaintenance/deviceType'
import { GetUserAll } from '@/api/userManagement'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'NewQuote',
  components: {
    MySelect,
    PageHeader,
    ElImageViewer,
  },
  data () {
    return {
      viewType: 1,
      title: '',
      deviceNames: [],
      models: [],
      brands: [],
      types: [],
      contacts: [],
      suppliers: [],
      creators: [],
      groupForm: {
        loading: false,
        id: null,
        deviceName: '',
        brand: '',
        model: '',
        type: '',
        supplierId: '',
        supplierName: '',
        contact: '',
        phone: '',
        currency: '0',
        unitPrice: '',
        taxes: '',
        discount: '',
        creator: this.$cookies.get('userId'),
        email: '',
        fax: '',
        position: '',
        description: '',
        projectName: '',
        expiringDate: null,
        inquiryDate: null,
        fileList: [],
        infoId: null,
      },
      rules: {
        deviceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        brand: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        devimodelceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        type: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        supplierId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        currency: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        unitPrice: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }, { validator: this.validateNumber, trigger: 'blur' }],
        expiringDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        inquiryDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        taxes: [{ validator: this.validateNumber, trigger: 'blur' }],
        discount: [{ validator: this.validateNumber, trigger: 'blur' }],


      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      headers: {
        token: this.$cookies.get('token')
      },
      preview: {
        visible: false,
        imageUrl: '',
      },
    }
  },
  watch: {
    'groupForm.supplierId': function (newVal) {
      this.queryContact('');
    },

    'groupForm.contact': function (newVal) {
      let contact = this.contacts.find(item => item.name === newVal);

      if (contact) {
        Object.assign(this.groupForm, {
          phone: contact.phoneNumber,
          email: contact.email,
          fax: contact.fax,
          position: contact.postName,
        })
      }

    },
  },
  created () {
    this.initView();



    // 获取用户集合
    GetUserAll({}).then(res => this.creators = res)

    this.queryDeviceName('');
    this.queryModel('');
    // 品牌
    GetDataFieldAll({ parent: '100000000000000000000000000000000002' }).then(res => this.brands = res)
    // 设备类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000001' }).then(res => this.types = res)

    // 供应商
    GetDataFieldAll({ parent: '100000000000000000000000000000000003' }).then(res => this.suppliers = res)
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    initView () {

      if (this.$route.query.id) {
        this.groupForm.id = this.$route.query.id;
        this.getItem();
        this.title = this.$i18n.t('newQuote.title2');
      } else {
        this.title = this.$i18n.t('newQuote.title1');
      }

      this.viewType = Number(this.$route.query.type);
      switch (this.viewType) {
        case 1:
          // 报价管理-新增
          break;
        case 2:
          // 价格-新增
          var data = JSON.parse(localStorage.quotationData);
          Object.assign(this.groupForm, {
            deviceName: data.itemName,
            model: data.model,
            brand: data.brand,
            type: data.type,
          })
          break;
        case 3:
          // 停产代替-新增
          var data = JSON.parse(localStorage.quotationData);
          Object.assign(this.groupForm, {
            deviceName: data.itemName,
            brand: data.brand,
            type: data.type,
            infoId: data.id,
          })
          break;
      }
    },
    // 获取报价单详情
    getItem () {
      GetQuotationItem(this.groupForm.id).then(res => {
        let data = res[0];
        Object.assign(this.groupForm, {
          deviceName: data.itemName,
          brand: data.brand,
          model: data.model,
          type: data.type,
          supplierId: data.supplier,
          contact: data.contactName,
          phone: data.phoneNumber,
          currency: data.currency.toString(),
          unitPrice: data.untilPrice.toString(),
          taxes: data.taxes,
          discount: data.discount,
          creator: this.$cookies.get('userId'),
          email: data.email,
          fax: data.fax,
          position: data.postName,
          description: data.description,
          projectName: data.projectName,
          expiringDate: data.expireDate,
          fileList: !!data.attachments ? data.attachments.split(',').map(item => Object.create({ name: item, url: `${this.baseUrl}/Export/download.do?fileName=${item}` })) : [],
          inquiryDate: data.inquiryDate,
        })
      })
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          itemName: this.groupForm.deviceName,
          model: this.groupForm.model,
          type: this.groupForm.type,
          brand: this.groupForm.brand,
          contactName: this.groupForm.contact,
          phoneNumber: this.groupForm.phone,
          email: this.groupForm.email,
          fax: this.groupForm.fax,
          postName: this.groupForm.position,
          currency: this.groupForm.currency,
          untilPrice: this.groupForm.unitPrice,
          taxes: this.groupForm.taxes || '0',
          discount: this.groupForm.discount || '0',
          description: this.groupForm.description,
          projectName: this.groupForm.projectName,
          expireDate: this.groupForm.expiringDate,
          creatorId: this.groupForm.creator,
          attachments: this.groupForm.fileList.map(item => item.name).join(','),
          inquiryDate: this.groupForm.inquiryDate,
        }
        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.supplierId)) {
          param.supplier = this.suppliers.find(item => item.id === this.groupForm.supplierId).name;
        } else {
          param.supplier = this.groupForm.supplierId;
        }
        if (this.viewType === 3) param.mainQuotationId = this.groupForm.infoId;

        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddQuotation(param).then(res => {
          if (res) {
            this.$message.success(msg)
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })

      });
    },
    // 查询设备名称
    queryDeviceName (query) {
      let param = { name: query }
      GetDeviceNameAll(param).then(res => this.deviceNames = res);
    },
    // 查询设备型号
    queryModel (query) {
      let param = {
        name: this.groupForm.deviceName || undefined,
        model: query
      }
      GetDeviceModelAll(param).then(res => this.models = res);
      //  let param = {
      //   key: this.groupForm.deviceName || undefined,
      // }
      // GetModel(param).then(res=>this.models = res);
    },
    //查询联系人
    queryContact (query) {
      if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.supplierId)) {
        let param = {
          id: this.groupForm.supplierId,
          key: query
        }
        GetSupplierContact(param).then(res => this.contacts = res)
      } else {
        this.contacts = [];
      }
    },
    deviceNameChange () {
      let param = {
        name: this.groupForm.deviceName,
      }
      GetDeviceModelAll(param).then(res => this.models = res);
    },

    // 图片上传回调
    uploadCall (res, file, fileList) {
      if (res.success) {
        this.groupForm.fileList.push({
          name: res.message,
          url: `${this.baseUrl}/Export/download.do?fileName=${res.message}`
        });
      }
    },
    // 删除图片回调
    delCall (file, fileList) {
      this.groupForm.fileList = fileList;
    },
    // 图片预览
    previewImage (file) {
      let urls = this.groupForm.fileList.map(item => item.url);
      let index = this.groupForm.fileList.findIndex(item => item.name === file.name);
      let result = urls.splice(0, index)
      urls = urls.concat(result);
      this.preview.imageUrl = urls;
      this.preview.visible = true;
    },
    previewClose () {
      this.preview.visible = false;
    },
    // 验证金额数值 
    validateNumber (rule, value, callback) {
      if (/^\d*(\.\d{1,8})?$/.test(value)) {
        callback()
      } else {
        callback(new Error(this.$i18n.t('newQuote.message1')));
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>