<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="permit.editInfo || permit.editGrade"
          size="medium"
          type="primary"
          @click="submit(1)"
          :loading="groupForm.loading"
        >{{$t('newServiceConfirmationForm.btn8')}}</el-button>
        <el-button
          v-if="permit.editInfo || permit.editGrade"
          size="medium"
          type="primary"
          @click="submit(0)"
          :loading="groupForm.loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>

      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="170px"
      >
        <el-tabs v-model="activeName">
          <el-tab-pane name="tabs1">
            <span slot="label"> {{$t('newServiceConfirmationForm.subtitle6')}}</span>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label1')"
                  prop="name"
                >
                  <el-input
                    v-model="groupForm.name"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label2')"
                  prop="address"
                >
                  <el-input
                    v-model="groupForm.address"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label3')"
                  prop="serverType"
                >
                  <el-select
                    :disabled="!permit.editInfo"
                    v-model="groupForm.serverType"
                    multiple
                    filterable
                  >
                    <el-option
                      v-for="item in serviceTypes"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label4')"
                  prop="number"
                >
                  <el-button-group class="input-group">
                    <el-input
                      readonly
                      v-model="groupForm.number"
                      class="search-input"
                    ></el-input>
                    <el-button disabled>{{$t('operation.auto')}}</el-button>
                  </el-button-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label5')"
                  prop="orderDate"
                >
                  <el-date-picker
                    :readonly="!permit.editInfo"
                    v-model="groupForm.orderDate"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label6')"
                  prop="dealDate"
                >
                  <div class="date-box">

                    <el-date-picker
                      :readonly="!permit.editInfo"
                      v-model="groupForm.startDate"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                    >
                    </el-date-picker>
                    <span>
                      --
                    </span>
                    <el-date-picker
                      :readonly="!permit.editInfo"
                      v-model="groupForm.endDate"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                    >
                    </el-date-picker>
                  </div>

                  <!-- <el-date-picker
                    :readonly="!permit.editInfo"
                    v-model="groupForm.dealDate"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('newServiceConfirmationForm.label7')">
                  <el-button-group class="input-group">
                    <el-input
                      v-model="referenceNumber"
                      class="search-input"
                      readonly
                    ></el-input>
                    <el-button @click="showNumber(1)">{{$t('operation.edit')}}</el-button>
                  </el-button-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="permit.number">
              <el-col :span="12">
                <el-form-item :label="$t('newServiceConfirmationForm.label8')">
                  <el-button-group class="input-group">
                    <el-input
                      v-model="quotationNumber"
                      class="search-input"
                      readonly
                    ></el-input>
                    <el-button @click="showNumber(2)">{{$t('operation.edit')}}</el-button>
                  </el-button-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('newServiceConfirmationForm.label9')">
                  <el-button-group class="input-group">
                    <el-input
                      v-model="purchaseNumber"
                      class="search-input"
                      readonly
                    ></el-input>
                    <el-button @click="showNumber(3)">{{$t('operation.edit')}}</el-button>
                  </el-button-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('newServiceConfirmationForm.label10')">
                  <div class="file-box">
                    <div
                      v-for="(item,index) in groupForm.fileList"
                      :key="index"
                      class="file-item"
                    >
                      <div
                        class="block"
                        @click="previewFile(item)"
                      >
                        <img :src="'./static/image/fileType/' + item.formats + '.png'">
                        <i
                          v-if="permit.editInfo"
                          class="el-icon-delete"
                          @click.stop="delFile(index)"
                        ></i>
                      </div>
                    </div>
                    <div
                      v-if="permit.editInfo"
                      class="file-item add-item"
                      @click="showUpload"
                    >
                      <div class="block">
                        <i class="el-icon-plus"></i>
                      </div>
                      <label></label>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item>
                <el-badge
                  :hidden="maintenanceEquipmentTable.length === 0"
                  :value="maintenanceEquipmentTable.length"
                  class="badge-btn"
                >
                  <el-button @click="switchTab(2)">{{$t('newServiceConfirmationForm.btn1')}}</el-button>
                </el-badge>
                <el-badge
                  :hidden="replaceEquipmentTable.length === 0"
                  :value="replaceEquipmentTable.length"
                  class="badge-btn"
                >
                  <el-button @click="switchTab(3)">{{$t('newServiceConfirmationForm.btn2')}}</el-button>
                </el-badge>
                <el-badge
                  :hidden="demolitionEquipmentTable.length === 0"
                  :value="demolitionEquipmentTable.length"
                  class="badge-btn"
                >
                  <el-button @click="switchTab(4)">{{$t('newServiceConfirmationForm.btn3')}}</el-button>
                </el-badge>
                <el-badge
                  :hidden="installationTable.length === 0"
                  :value="installationTable.length"
                  class="badge-btn"
                >
                  <el-button @click="switchTab(6)">{{$t('newServiceConfirmationForm.btn4')}}</el-button>
                </el-badge>
                <el-badge
                  :hidden="configEidtTable.length === 0"
                  :value="configEidtTable.length"
                  class="badge-btn"
                >
                  <el-button @click="switchTab(5)">{{$t('newServiceConfirmationForm.btn5')}}</el-button>
                </el-badge>
                <el-badge
                  :hidden="maintenanceTable.length === 0"
                  :value="maintenanceTable.length"
                  class="badge-btn"
                >
                  <el-button @click="switchTab(8)">{{$t('newServiceConfirmationForm.btn6')}}</el-button>
                </el-badge>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label11')"
                  prop="describe"
                >
                  <div class="textarea-box">
                    <div
                      v-for="(item,index) in groupForm.describeAll"
                      :key="index"
                    >{{item}}</div>
                  </div>
                  <!-- <el-input
                    disabled
                    type="textarea"
                    v-model="groupForm.describeAll"
                    :rows="3"
                  ></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label12')"
                  prop="dispose"
                >
                  <div class="textarea-box">
                    <div
                      v-for="(item,index) in groupForm.disposeAll"
                      :key="index"
                    >{{item}}</div>
                  </div>
                  <!-- <el-input
                    disabled
                    type="textarea"
                    v-model="groupForm.disposeAll"
                    :rows="3"
                  ></el-input> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label13')"
                  prop="describe"
                >
                  <el-input
                    :readonly="!permit.editInfo"
                    type="textarea"
                    v-model="groupForm.describe"
                    :rows="3"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label14')"
                  prop="dispose"
                >
                  <el-input
                    :readonly="!permit.editInfo"
                    type="textarea"
                    v-model="groupForm.dispose"
                    :rows="3"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label15')"
                  prop="state"
                >
                  <el-select
                    :disabled="!permit.editInfo"
                    v-model="groupForm.state"
                    filterable
                  >
                    <el-option
                      v-for="item in completionStatuss"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>

              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label16')"
                  prop="nextDealDate"
                >
                  <el-date-picker
                    :disabled="groupForm.state === '00000000000000000000000000000000002'"
                    :readonly="!permit.editInfo"
                    v-model="groupForm.nextDealDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label17')"
                  prop="staff"
                >
                  <el-select
                    :disabled="!permit.editInfo"
                    v-model="groupForm.staff"
                    multiple
                    filterable
                  >
                    <el-option
                      v-for="(item,index) in engineers"
                      :key="index"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label18')"
                  prop="director"
                >
                  <el-button-group class="input-group">
                    <el-select
                      :disabled="!permit.editInfo"
                      class="search-input"
                      v-model="groupForm.directorId"
                    >
                      <el-option
                        v-for="item in directors"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                    <el-button
                      :disabled="!permit.editInfo"
                      @click="showeSigntory(1)"
                    >New</el-button>
                  </el-button-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label19')"
                  prop="cause"
                >
                  <el-input
                    :disabled="groupForm.state === '00000000000000000000000000000000002'"
                    :readonly="!permit.editInfo"
                    type="textarea"
                    v-model="groupForm.cause"
                    :rows="3"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            v-if="maintenanceEquipment"
            name="tabs2"
          >
            <span slot="label">
              <el-badge
                :hidden="maintenanceEquipmentTable.length === 0"
                :value="maintenanceEquipmentTable.length"
                class="badge-btn"
              >
                {{$t('newServiceConfirmationForm.subtitle2')}}
              </el-badge>
            </span>
            <maintenance-equipment
              ref="maintenanceEquipment"
              :data="maintenanceEquipmentTable"
              :permit="permit"
              :describe="groupForm.describe"
              :dispose="groupForm.dispose"
              :branch-id="groupForm.branchId"
              @replaceSubmit="replaceSubmit"
              @demolitionSubmit="demolitionSubmit"
            ></maintenance-equipment>
          </el-tab-pane>
          <el-tab-pane
            v-if="replaceEquipment"
            name="tabs3"
          >
            <span slot="label">
              <el-badge
                :hidden="replaceEquipmentTable.length === 0"
                :value="replaceEquipmentTable.length"
                class="badge-btn"
              >
                {{$t('newServiceConfirmationForm.subtitle3')}}
              </el-badge>
            </span>
            <replace-equipment
              ref="replaceEquipment"
              :data="replaceEquipmentTable"
              :permit="permit"
              :describe="groupForm.describe"
              :dispose="groupForm.dispose"
              :branch-id="groupForm.branchId"
            ></replace-equipment>
          </el-tab-pane>
          <el-tab-pane
            v-if="demolitionEquipment"
            name="tabs4"
          >
            <span slot="label">
              <el-badge
                :hidden="demolitionEquipmentTable.length === 0"
                :value="demolitionEquipmentTable.length"
                class="badge-btn"
              >
                {{$t('newServiceConfirmationForm.subtitle4')}}
              </el-badge>
            </span>
            <demolition-equipment
              ref="demolitionEquipment"
              :data="demolitionEquipmentTable"
              :permit="permit"
              :describe="groupForm.describe"
              :dispose="groupForm.dispose"
              :branch-id="groupForm.branchId"
            ></demolition-equipment>
          </el-tab-pane>
          <el-tab-pane
            v-if="configEidt"
            name="tabs5"
          >
            <span slot="label">
              <el-badge
                :hidden="configEidtTable.length === 0"
                :value="configEidtTable.length"
                class="badge-btn"
              >
                {{$t('newServiceConfirmationForm.subtitle5')}}
              </el-badge>
            </span>
            <config-eidt
              ref="configEidt"
              :data="configEidtTable"
              :permit="permit"
              :describe="groupForm.describe"
              :dispose="groupForm.dispose"
              :branch-id="groupForm.branchId"
            ></config-eidt>
          </el-tab-pane>
          <el-tab-pane name="tabs6">
            <span slot="label">
              <el-badge
                :hidden="installationTable.length === 0"
                :value="installationTable.length"
                class="badge-btn"
              >
                {{$t('newServiceConfirmationForm.subtitle7')}}
              </el-badge>
            </span>
            <installation
              ref="installation"
              :data="installationTable"
              :permit="permit"
              :describe="groupForm.describe"
              :dispose="groupForm.dispose"
              :branch-id="groupForm.branchId"
            ></installation>
          </el-tab-pane>
          <el-tab-pane name="tabs8">
            <span slot="label">
              <el-badge
                :hidden="maintenanceTable.length === 0"
                :value="maintenanceTable.length"
                class="badge-btn"
              >
                {{$t('newServiceConfirmationForm.subtitle8')}}
              </el-badge>
            </span>
            <maintenance
              ref="maintenance"
              :data="maintenanceTable"
              :permit="permit"
              :describe="groupForm.describe"
              :dispose="groupForm.dispose"
              :branch-id="groupForm.branchId"
            ></maintenance>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('newServiceConfirmationForm.subtitle1')"
            name="tabs7"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label20')"
                  prop="signtory"
                >
                  <el-button-group class="input-group">
                    <el-select
                      :disabled="!permit.editGrade"
                      class="search-input"
                      v-model="groupForm.signtory"
                      @change="signtoryChange"
                    >
                      <el-option
                        v-for="item in signtorys"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                    <el-button
                      :disabled="!permit.editGrade"
                      @click="showeSigntory(0)"
                    >New</el-button>
                  </el-button-group>
                </el-form-item>
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label21')"
                  prop="position"
                >
                  <el-input
                    disabled
                    v-model="groupForm.position"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label22')"
                  prop="staffNumber"
                >
                  <el-input
                    disabled
                    v-model="groupForm.staffNumber"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('newServiceConfirmationForm.label23')">
                  <el-button
                    :disabled="!permit.editGrade"
                    @click="showSignature"
                  >sign</el-button>
                  <el-button
                    :disabled="!permit.editGrade"
                    icon="el-icon-refresh-left"
                    @click="rotateSignature"
                  ></el-button>
                </el-form-item>
                <el-form-item>
                  <el-image
                    fit="contain"
                    class="signature"
                    :src="signaturePhoto"
                  ></el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newServiceConfirmationForm.label24')"
                  prop="evaluate"
                >
                  <el-button-group class="input-group">
                    <el-input
                      v-model="groupForm.evaluate"
                      class="search-input"
                      readonly
                    ></el-input>
                    <el-button
                      :disabled="!permit.editGrade"
                      @click="showeEvaluate"
                    >{{$t('newServiceConfirmationForm.btn7')}}</el-button>
                  </el-button-group>
                </el-form-item>

                <el-form-item
                  :label="$t('newServiceConfirmationForm.label25')"
                  prop="opinion"
                >
                  <el-input
                    :readonly="!permit.editGrade"
                    type="textarea"
                    v-model="groupForm.opinion"
                    :rows="12"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>

    </el-card>

    <!-- 签名弹框 -->
    <signature-dialog
      :visible.sync="SignatureDialogVisible"
      @submit="signatureSubmit"
    ></signature-dialog>

    <!-- 编号弹框 -->
    <number-dialog
      :visible.sync="NumberDialogVisble"
      :data="numberData"
      :type="numberType"
      :branch-id="groupForm.branchId"
      @submit="numberSubmit"
    ></number-dialog>
    <!-- 图片上传 -->
    <input
      type="file"
      ref="fileInput"
      hidden
      @change="fileInputChange"
    >
    <!-- 新客户签署人弹框 -->
    <signtory-dialog
      :visible.sync="SigntoryDialogVisible"
      :branch-id="groupForm.branchId"
      :org-id="groupForm.customerOrgId"
      :type="submitSigntoryType"
      @submit="submitSigntory"
    ></signtory-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatUTCDate, toLocaleDate } from '@/utils/common'
// 组件
import MySelect from '@/components/MySelect'
import PageHeader from '@/components/PageHeader'
import SignatureDialog from '@/components/SignatureDialog'
import MaintenanceEquipment from './components/MaintenanceEquipment'
import ReplaceEquipment from './components/ReplaceEquipment'
import DemolitionEquipment from './components/DemolitionEquipment'
import ConfigEidt from './components/ConfigEidt'
import NumberDialog from '@/pages/warehouse/submitRequest/components/NumberDialog'
import Installation from './components/Installation'
import SigntoryDialog from './components/SigntoryDialog'
import Maintenance from './components/Maintenance'

// 接口
import {
  GetConfirmOrderItem,
  EditConfirmOrder,
  GetConfirmOrderStatus,
  GetDetailsByNumber,
} from '@/api/warehouse/serviceConfirmation'
import { GetContactAll2 } from '@/api/contactList'
import { GetEngineerAll } from '@/api/warehouse/engineerManagement'
import { UploadImage } from '@/api'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'NewServiceConfirmationForm',
  components: {
    MySelect,
    PageHeader,
    MaintenanceEquipment,
    ReplaceEquipment,
    DemolitionEquipment,
    ConfigEidt,
    SignatureDialog,
    NumberDialog,
    Installation,
    SigntoryDialog,
    Maintenance,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      NumberDialogVisble: false,
      SignatureDialogVisible: false,
      SigntoryDialogVisible: false,
      permit: {
        editInfo: true,
        editGrade: true,
        number: true,
      },
      activeName: 'tabs1',
      viewType: 1,
      title: '',
      headers: {
        token: this.$cookies.get('token')
      },
      clients: [],
      serviceTypes: [],
      completionStatuss: [],
      signtorys: [],
      engineers: [],
      directors: [],
      groupForm: {
        id: null,
        loading: false,
        name: '',
        serverType: [],
        number: '',
        orderDate: '',
        startDate: null,
        endDate: null,
        dealDate: null,
        describe: '',
        dispose: '',
        state: '',
        nextDealDate: '',
        cause: '',
        staff: [],
        signtory: '',
        evaluate: '',
        staffNumber: '',
        opinion: '',
        signaturePhoto: '',
        fileList: [],
        workOrderId: null,
        confirmOrderDetailId: null,
        address: '',
        director: '',
        directorId: '',
        position: '',
        branchId: '',
        customerOrgId: '',
      },
      rules: {
        staff: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      maintenanceEquipment: true,
      replaceEquipment: true,
      demolitionEquipment: true,
      configEidt: true,
      maintenanceEquipmentTable: [],
      replaceEquipmentTable: [],
      demolitionEquipmentTable: [],
      configEidtTable: [],
      installationTable: [],
      maintenanceTable: [],
      numberType: null,
      numberData: null,
      reference: [],
      quotation: [],
      purchase: [],

      isNewSigntory: true,
      submitSigntoryType: null,
    }
  },
  watch: {
    'groupForm.state': function (newVal) {
      if (newVal === '00000000000000000000000000000000002') {
        this.groupForm.nextDealDate = '';
        this.groupForm.cause = '';
      }
    },
    'activeName': function (newVal) {
      this.maintenanceEquipmentTable = this.$refs.maintenanceEquipment.tableData;
      this.replaceEquipmentTable = this.$refs.replaceEquipment.tableData;
      this.demolitionEquipmentTable = this.$refs.demolitionEquipment.tableData;
      this.configEidtTable = this.$refs.configEidt.tableData;
      this.installationTable = this.$refs.installation.tableData;
      this.maintenanceTable = this.$refs.maintenance.tableData;

    },
  },
  computed: {
    signaturePhoto () {
      if (!!this.groupForm.signaturePhoto) {
        return this.baseUrl + '/Engineer/getImage.do?imageId=' + this.groupForm.signaturePhoto
      } else {
        return '';
      }
    },
    'referenceNumber': function () {
      let result = this.reference.map(item => {
        if (!!item.name) {
          return item.name;
        } else {
          return item.id;
        }
      })
      return result.join(',')
    },
    'quotationNumber': function () {
      let result = this.quotation.map(item => {
        if (!!item.name) {
          return item.name;
        } else {
          return item.id;
        }
      })
      return result.join(',')
    },
    'purchaseNumber': function () {
      let result = this.purchase.map(item => {
        if (!!item.name) {
          return item.name;
        } else {
          return item.id;
        }
      })
      return result.join(',')
    },
  },
  beforeRouteEnter (to, from, next) {
    if (from.name === 'Score') {
      next(vm => {
        vm.activeName = 'tabs7'
      });
    } else {
      next();
    }
  },
  created () {
    // 获取服务类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000006' }).then(res => this.serviceTypes = res)

    // 获取完成情况
    GetConfirmOrderStatus({}).then(res => this.completionStatuss = res);

    // 获取服务人员
    GetEngineerAll({}).then(res => this.engineers = res);

    this.dealPermission();

    this.initView();
  },
  methods: {
    initView () {
      this.viewType = Number(this.$route.query.type);
      this.title = this.$i18n.t('newServiceConfirmationForm.title1');
      switch (this.viewType) {
        case 1:
          this.getItem();
          // 服务确认
          break;
        case 2:
          this.getItem();
          // 工单调度-状态更新
          break;
        case 3:
          this.getItem();
          //服务请求
          break;
        case 4:
          // 投诉与评价
          this.title = this.$i18n.t('newServiceConfirmationForm.title2');
          this.permit.editInfo = false;
          this.permit.editGrade = false;
          this.getItemByNumber()
          break;
      }
    },
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getItem () {
      let param = { id: this.$route.query.id }
      GetConfirmOrderItem(param).then(this.dealData)
    },
    getItemByNumber () {
      let param = { number: this.$route.query.num }
      GetDetailsByNumber(param).then(this.dealData)
    },
    dealData (res) {
      let data = res.maintenanceServiceConfirmOrderDetail || {};
      Object.assign(this.groupForm, {
        id: res.id,
        name: res.branchName,
        serverType: res.serviceTypeId !== null ? res.serviceTypeId.split(',') : [],
        number: res.number,
        orderDate: toLocaleDate(data.receiveOrderTime),
        startDate: toLocaleDate(data.currentHandleStartTime),
        endDate: toLocaleDate(data.currentHandleEndTime),
        // dealDate: !!data.currentHandleStartTime ? [data.currentHandleStartTime, data.currentHandleEndTime] : null,
        describe: data.description,
        dispose: data.handleMethod,
        state: res.statusId,
        nextDealDate: toLocaleDate(res.nextHandleTime),
        cause: res.unfinishedReason,
        staff: data.serviceUserId ? data.serviceUserId.split(',') : [],
        signtory: data.signatureCustomerUserId,
        evaluate: data.serviceEvaluationScore,
        staffNumber: data.signatureCustomerUserNo,
        position: data.signatureCustomerUserPost,
        opinion: data.customerComments,
        signaturePhoto: data.signatureCustomerPictureName,
        workOrderId: res.workOrderId,
        fileList: [],
        confirmOrderDetailId: res.confirmOrderDetailId,
        address: res.address,
        director: res.director,
        directorId: res.directorId,
        branchId: res.branchId,
        customerOrgId: res.customerOrgId,
        describeAll: [],
        disposeAll: [],
      });
      // this.maintenanceEquipment = !!data.isSceneRepairDevice;
      // this.replaceEquipment = !!data.isReplaceDevice;
      // this.demolitionEquipment = !!data.isRemoveDevice;
      // this.configEidt = !!data.isWorkOrderClassified;
      this.maintenanceEquipmentTable = data.repairDeviceList || [];
      this.replaceEquipmentTable = data.replaceDeviceList || [];
      this.demolitionEquipmentTable = data.removeDeviceList || [];
      this.configEidtTable = data.classifiedList || [];
      this.installationTable = data.installationDeviceList || [];
      this.maintenanceTable = data.maintenanceList || [];

      this.maintenanceEquipmentTable.forEach(item => {
        if (!!item.faultDescription) this.groupForm.describeAll.push(item.faultDescription);
        if (!!item.handleMethod) this.groupForm.disposeAll.push(item.handleMethod);
      })
      this.replaceEquipmentTable.forEach(item => {
        if (!!item.faultDescription) this.groupForm.describeAll.push(item.faultDescription);
        if (!!item.remark) this.groupForm.disposeAll.push(item.remark);
      })
      this.demolitionEquipmentTable.forEach(item => {
        if (!!item.faultDescription) this.groupForm.describeAll.push(item.faultDescription);
        if (!!item.remark) this.groupForm.disposeAll.push(item.remark);
      })
      this.configEidtTable.forEach(item => {
        if (!!item.faultDescription) this.groupForm.describeAll.push(item.faultDescription);
        if (!!item.remark) this.groupForm.disposeAll.push(item.remark);
      })
      this.installationTable.forEach(item => {
        if (!!item.faultDescription) this.groupForm.describeAll.push(item.faultDescription);
        if (!!item.remark) this.groupForm.disposeAll.push(item.remark);
      })
      
      this.groupForm.describeAll = new Set(this.groupForm.describeAll);
      this.groupForm.disposeAll = new Set(this.groupForm.disposeAll);

      res.referenceId = !!res.referenceId ? res.referenceId.split(',') : [];
      res.quotationId = !!res.quotationId ? res.quotationId.split(',') : [];
      res.purchaseId = !!res.purchaseId ? res.purchaseId.split(',') : [];
      res.referenceNo = !!res.referenceNo ? res.referenceNo.split(',') : [];
      res.quotationNo = !!res.quotationNo ? res.quotationNo.split(',') : [];
      res.purchaseNo = !!res.purchaseNo ? res.purchaseNo.split(',') : [];

      this.reference = res.referenceId.map((item, index) => {
        return {
          id: item,
          name: res.referenceNo[index],
        }
      })
      this.quotation = res.quotationId.map((item, index) => {
        return {
          id: item,
          name: res.quotationNo[index],
        }
      })
      this.purchase = res.purchaseId.map((item, index) => {
        return {
          id: item,
          name: res.purchaseNo[index],
        }
      })

      // 获取签署客户
      // GetContactAll({ brandId: res.branchId }).then(res => this.signtorys = res);

      this.getContact1();
      this.getContact0();

      if (!!data.photoList) {
        this.groupForm.fileList = data.photoList.map(item => {
          let formats = item.match(/[^.]*$/)[0];
          return { name: item, formats: formats }
        })
      }
      this.groupForm.fileList
    },
    // 获取负责人
    getContact1 () {
      GetContactAll2({ customerOrgId: this.groupForm.branchId, supervisor: 1 }).then(res => {
        this.directors = res
        if (!!this.groupForm.director && !this.directors.some(item => item.id === this.groupForm.directorId)) {
          this.directors.push({
            id: this.groupForm.directorId,
            name: this.groupForm.director,
          })
        }
      });
    },
    // 获取联系人
    getContact0 () {
      GetContactAll2({ customerOrgId: this.groupForm.branchId, supervisor: 0 }).then(res => {
        this.signtorys = res
        if (!!this.groupForm.director && !this.signtorys.some(item => item.id === this.groupForm.directorId)) {
          this.signtorys.push({
            id: this.groupForm.directorId,
            name: this.groupForm.director,
          })
        }
      });
    },
    // 提交
    submit (isSendEmail) {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        this.maintenanceEquipmentTable = this.$refs.maintenanceEquipment.tableData;
        this.replaceEquipmentTable = this.$refs.replaceEquipment.tableData;
        this.demolitionEquipmentTable = this.$refs.demolitionEquipment.tableData;
        this.configEidtTable = this.$refs.configEidt.tableData;
        this.installationTable = this.$refs.installation.tableData;
        this.maintenanceTable = this.$refs.maintenance.tableData;

        let param = {
          isSendEmail: isSendEmail,
          id: this.groupForm.id,
          confirmOrderId: this.$route.query.id,
          receiveOrderTime: formatUTCDate(this.groupForm.orderDate),
          currentHandleStartTime: formatUTCDate(this.groupForm.startDate),
          currentHandleEndTime: formatUTCDate(this.groupForm.endDate),
          // currentHandleStartTime: this.groupForm.dealDate !== null ? this.groupForm.dealDate[0] : '',
          // currentHandleEndTime: this.groupForm.dealDate !== null ? this.groupForm.dealDate[1] : '',
          description: this.groupForm.describe,
          handleMethod: this.groupForm.dispose,
          isSceneRepairDevice: this.maintenanceEquipment ? 1 : 0,
          isReplaceDevice: this.replaceEquipment ? 1 : 0,
          isRemoveDevice: this.demolitionEquipment ? 1 : 0,
          isWorkOrderClassified: this.configEidt ? 1 : 0,
          signatureCustomerUserNo: this.groupForm.staffNumber,
          signatureCustomerUserPost: this.groupForm.position,
          signatureCustomerPictureName: this.groupForm.signaturePhoto,
          serviceEvaluationScore: this.groupForm.evaluate,
          // serviceEvaluationScore: this.groupForm.evaluate || 0,
          customerComments: this.groupForm.opinion,
          nextHandleTime: formatUTCDate(this.groupForm.nextDealDate),
          unfinishedReason: this.groupForm.cause,
          statusId: this.groupForm.state,
          photoList: this.groupForm.fileList.map(item => item.name),
          serviceUserId: this.groupForm.staff.join(','),
          serviceTypeId: this.groupForm.serverType.join(','),
          repairDeviceList: this.maintenanceEquipmentTable.map(item => {
            return {
              maintenanceDeviceId: item.maintenanceDeviceId,
              faultTypeId: item.faultTypeId,
              deviceTypeId: item.deviceTypeId,
              deviceInfoName: item.deviceInfoName,
              faultDescription: item.faultDescription,
              handleMethod: item.handleMethod,
              deviceInfoModel: item.deviceInfoModel,
              installationSite: item.installationSite,
              serialNumber: item.serialNumber,
              installationNumber: item.installationNumber,
              attributable: item.attributable,
              qrCode: item.qrCode,
              // reasonId: item.reasonId,
            }
          }),
          replaceDeviceList: this.replaceEquipmentTable.map(item => {
            return {
              deviceInfoId: item.deviceInfoId,
              deviceInfoName: item.deviceInfoName,
              deviceTypeName: item.deviceTypeName,
              deviceInfoModel: item.deviceInfoModel,
              installationSite: item.installationSite,
              oldQrcode: item.oldQrcode,
              oldSerialNumber: item.oldSerialNumber,
              newQrcode: item.newQrcode,
              newSerialNumber: item.newSerialNumber,
              deviceSupplier: item.deviceSupplier,
              deviceTo: item.deviceTo,
              remark: item.remark,
              attributable: item.attributable,
              reasonId: item.reasonId,
              faultTypeId: item.faultTypeId,
              oldDeviceId: item.oldDeviceId,
              oldDeviceInfoModel: item.oldDeviceInfoModel,
              oldDeviceName: item.oldDeviceName,
              oldInstallationSite: item.oldInstallationSite,
              newLabel: item.newLabel,
              oldDeviceTypeName: item.oldDeviceTypeName,
              oldLabel: item.oldLabel,
              referenceId: item.referenceId,
              quotationId: item.quotationId,
              purchaseId: item.purchaseId,
              referenceNo: item.referenceNo,
              quotationNo: item.quotationNo,
              purchaseNo: item.purchaseNo,
              faultDescription: item.faultDescription,
            }
          }),
          removeDeviceList: this.demolitionEquipmentTable.map(item => {
            return {
              deviceInfoId: item.deviceInfoId,
              deviceInfoName: item.deviceInfoName,
              deviceTypeName: item.deviceTypeName,
              deviceInfoModel: item.deviceInfoModel,
              installationSite: item.installationSite,
              serialNumber: item.serialNumber,
              qrCode: item.qrCode,
              customerConfirmUserName: item.customerConfirmUserName,
              sceneConfirmUserName: item.sceneConfirmUserName,
              deviceTo: item.deviceTo,
              attributable: item.attributable,
              remark: item.remark,
              reasonId: item.reasonId,
              faultTypeId: item.faultTypeId,
              label: item.label,
              faultDescription: item.faultDescription,
            }
          }),
          classifiedList: this.configEidtTable.map(item => {
            return {
              servicesId: item.servicesId,
              attributable: item.attributable,
              remark: item.remark,
              deviceId: item.deviceId,
              serialNumber: item.serialNumber,
              location: item.location,
              qrCode: item.qrCode,
              deviceInfoModel: item.deviceInfoModel,
              deviceInfoName: item.deviceInfoName,
              deviceTypeName: item.deviceTypeName,
              label: item.label,
              faultDescription: item.faultDescription,
            }
          }),
          installationDeviceList: this.installationTable.map(item => {
            return {
              deviceId: item.deviceId,
              deviceInfoName: item.deviceInfoName,
              deviceInfoModel: item.deviceInfoModel,
              deviceTypeName: item.deviceTypeName,
              qrCode: item.qrCode,
              serialNumber: item.serialNumber,
              location: item.location,
              referenceId: item.referenceId,
              quotationId: item.quotationId,
              purchaseId: item.purchaseId,
              referenceNo: item.referenceNo,
              purchaseNo: item.purchaseNo,
              quotationNo: item.quotationNo,
              typeId: item.typeId,
              remark: item.remark,
              label: item.label,
              attributable: item.attributable,
              deviceSupplier: item.deviceSupplier,
              faultDescription: item.faultDescription,
            }
          }),
          maintenanceList: this.maintenanceTable.map(item => {
            return {
              confirmOrderDetailId: item.confirmOrderDetailId,
              fileId: item.fileId,
              itemList: item.itemList,
            }
          }),
          referenceId: this.reference.map(item => item.id).join(','),
          quotationId: this.quotation.map(item => item.id).join(','),
          purchaseId: this.purchase.map(item => item.id).join(','),
          referenceNo: this.referenceNumber,
          quotationNo: this.quotationNumber,
          purchaseNo: this.purchaseNumber,
        }

        // 验证是否是新负责人
        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.directorId)) {
          param.directorId = this.groupForm.directorId;
          let item = this.directors.find(item => item.id === this.groupForm.directorId);
          param.director = !!item ? item.name : '';
        } else {
          param.directorId = '';
          param.director = this.groupForm.directorId;
        }
        // 验证是否是新客户签署人

        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.signtory)) {
          param.signatureCustomerUserId = this.groupForm.signtory;
          let item = this.directors.find(item => item.id === this.groupForm.signtory);
          param.signatureCustomerUserName = !!item ? item.name : '';
        } else {
          param.signatureCustomerUserId = '';
          param.signatureCustomerUserName = this.groupForm.signtory;
        }

        let msg = this.$i18n.t('message.edit');

        this.groupForm.loading = true;
        EditConfirmOrder(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 显示评分弹框
    showeEvaluate () {
      this.$router.push({ path: '/home/score', query: { id: this.groupForm.confirmOrderDetailId } })
    },
    // 显示签名弹框 
    showSignature () {
      this.SignatureDialogVisible = true;
    },

    // 签名提交
    signatureSubmit (signaturePhoto) {
      this.groupForm.signaturePhoto = signaturePhoto;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.editInfo = permission.includes('ServiceConfirmationFormEdit(ExcludeServiceConfirmationandEvaluation)');
        this.permit.editGrade = permission.includes('ServiceConfirmationFormEditServiceConfirmationandEvaluation');
        this.permit.number = permission.includes('WorkOrderSchedulingBeallowedtoseeQuotationNo');
      }
    },
    // 显示上传
    showUpload () {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    fileInputChange () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let formats = res.message.match(/[^.]*$/)[0];
          this.groupForm.fileList.push({
            name: res.message,
            formats: formats,
          });
        }
      })
    },
    // 删除文件
    delFile (index) {
      this.groupForm.fileList.splice(index, 1)
    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 显示编号弹框
    showNumber (type) {
      switch (type) {
        case 1:
          this.numberData = this.reference;
          break;
        case 2:
          this.numberData = this.quotation;
          break;
        case 3:
          this.numberData = this.purchase;
          break;
      }
      this.numberType = type;
      this.NumberDialogVisble = true;
    },
    // 提交编号
    numberSubmit (type, data) {
      switch (type) {
        case 1:
          this.reference = data;
          break;
        case 2:
          this.quotation = data;
          break;
        case 3:
          this.purchase = data;
          break;
      }
    },
    // 切换标签页 
    switchTab (index) {
      this.activeName = 'tabs' + index;
    },
    // 提交替换数据 
    replaceSubmit (data) {
      this.$refs.replaceEquipment.tableData.push(data.data);
    },
    // 提交拆除数据
    demolitionSubmit (data) {
      this.$refs.demolitionEquipment.tableData.push(data.data);
    },
    // 客户变更
    signtoryChange () {
      if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.signtory)) {
        let signtory = this.signtorys.find(item => item.id === this.groupForm.signtory)
        this.groupForm.position = signtory.post;
        this.groupForm.staffNumber = signtory.number;
        this.isNewSigntory = false;
      } else {
        this.groupForm.position = '';
        this.groupForm.staffNumber = '';
        this.isNewSigntory = true;
      }
    },
    // 显示新客户签署人弹框
    showeSigntory (type) {
      this.submitSigntoryType = type;
      this.SigntoryDialogVisible = true;
    },

    // 提交客户签署人
    submitSigntory (id, data) {
      if (this.submitSigntoryType === 1) {
        this.groupForm.directorId = id;
        this.getContact1();
      } else if (this.submitSigntoryType === 0) {
        this.groupForm.signtory = id;
        this.groupForm.staffNumber = data.staffId;
        this.groupForm.position = data.jobTitle;
        this.getContact0();
      }
    },
    // 选装签名
    rotateSignature () {
      var img = document.createElement("img");
      let can = document.createElement('canvas');
      let ctx = can.getContext("2d");

      img.src = this.baseUrl + '/Engineer/getImage.do?imageId=' + this.groupForm.signaturePhoto;
      can.width = img.height;
      can.height = img.width;

      img.onload = () => {
        ctx.translate(can.width / 2, can.height / 2);
        ctx.rotate(-Math.PI / 2)
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        // 重置
        // ctx.translate(can.width / 2, can.height / 2);
        // ctx.rotate(-Math.PI / 2)

        this.imgSubmit(can);
        can.remove();
      }
    },
    // 图片提交
    imgSubmit (canvas) {
      let formData = new FormData();
      let file = this.dataURLtoFile(canvas.toDataURL(), 'signature.png');

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.groupForm.signaturePhoto = res.message;
        }
      })
    },
    // 图片转文件
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  position: relative;
  .el-date-editor,
  .el-cascader,
  .el-select {
    width: 100%;
  }
  .tab-btn {
    position: absolute;
    right: 0px;
    z-index: 1;
  }
}
.tab-checkbox {
  display: block;
}
.upload-box {
  width: 100%;
  height: auto;
  &:before {
    content: "";
    width: 0;
    height: 0;
    clear: both;
    display: block;
  }
}
.input-group {
  height: 36px;
}
.el-message-box__message {
  img {
    width: 100%;
  }
}

.signature {
  width: 100%;
  height: 205px;
  border: 1px solid #dcdfe6;
}
.date-box {
  width: 100%;
  display: flex;
  .el-date-editor {
    width: calc(50% - 10px);
  }
  span {
    width: 20px;
    text-align: center;
    display: inline-block;
  }
}

.file-box {
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 120px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: static;
    }
  }
}
.badge-btn {
  margin-right: 30px;
}
.textarea-box {
  width: 100%;
  min-height: 36px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  background: #f5f7fa;
  div {
    // height: 20px;
    line-height: 20px;
  }
}

.el-tabs {
  ::v-deep .el-tabs__header {
    // height: 51px;
    .el-tabs__nav-wrap {
      // height: 51px;
    }
  }
  ::v-deep .el-tabs__item {
    margin-top: 10px;
  }
}
</style>