<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="1000px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
          <p>（请使用名称、型号、品牌或类型进行查找）</p>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <quotation-table
          ref="quotationTable"
          :table-data="tableData"
          :table-loading="tableLoading"
          :table-selected.sync="tableSelected"
          :table-height="tableHeight"
          :decimals="decimals"
          @row-dblclick="dblclickEdit"
        ></quotation-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-dialog>

    <!--  -->
    <goods-dialog
      :visible.sync="GoodsDialogVisible"
      :data="goodsData"
    ></goods-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import QuotationTable from '@/pages/warehouse/quotation/components/QuotationTable'
import GoodsDialog from './GoodsDialog'
// 接口
import { GetQuotationList } from '@/api/warehouse/quotation'
export default {
  components: {
    QuotationTable,
    GoodsDialog,
  },
  props: ['visible', 'data'],
  data () {
    return {
      GoodsDialogVisible: false,
      dialog: {
        visible: false,
        title: '历史价格',
      },
      permit: {
        search: true,
        add: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      exchangeRate: 'key=0@1=0.14144472@2=1.09620928@3=4.36653984@4=0.519512@5=0.19730548@6=194.91081911@7=0.60524194',
      decimals: Number(localStorage.quotationDecimals) || 2,
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 385,

      goodsData: null,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;

        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTableData () {
      if (this.searchVal === '') {
        this.tableData = [];
        this.page.total = 0;
        return;
      }

      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        exchangeRate: this.exchangeRate,
      }
      this.tableLoading = true;
      GetQuotationList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.inquiryDate = formatDate(item.inquiryDate, 'yyyy-MM-dd')
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    dblclickEdit (e) {
      this.goodsData = e;
      this.GoodsDialogVisible = true;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('QuotationSearch');
        this.permit.add = permission.includes('QuotationAdd');
        // this.permit.edit = permission.includes('QuotationEdit');
        this.permit.del = permission.includes('QuotationDelete');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrap {
  p {
    line-height: 36px;
    margin: 0;
  }
}
</style>