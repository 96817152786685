import Router from '@/router'

import Home from '@/pages/home/home'
import DashBoard from '@/pages/dashBoard/dashBoard'
import RiskLevelSettings from '@/pages/dashBoard/riskLevelSettings'

import UserManagement from '@/pages/userManagement/userManagement'
import NewUser from '@/pages/userManagement/newUser'
import StoreGroupEditor from '@/pages/userManagement/storeGroupEditor'
import ReportGroupEditor from '@/pages/userManagement/reportGroupEditor'

import Organizations from '@/pages/organizations'
import NewDataAccessGroup from '@/pages/organizations/newDataAccessGroup'

import Roles from '@/pages/roles/roles'

import Stores from '@/pages/stores/stores'
import ImportStores from '@/pages/stores/importStores'

import ContactList from '@/pages/contactList/contactList'

import SMTP from '@/pages/SMTP/SMTP'

import AccountPolicies from '@/pages/accountPolicies'


import ReportTemplates from '@/pages/reportTemplates/reportTemplates'
import ReportGroup from '@/pages/reportGroup/reportGroup'
import NewReportGroup from '@/pages/reportGroup/newReportGroup'
import ReportDesigner from '@/pages/reportDesigner/reportDesigner'
import OverviewField from '@/pages/reportDesigner/overviewField'
import FieldDisplaySet from '@/pages/reportDesigner/fieldDisplaySet'
import ChartType from '@/pages/reportDesigner/chartType'
import RiskOfLossLevel from '@/pages/riskOfLossLevel/riskOfLossLevel'
import NewRiskOfLossLevel from '@/pages/riskOfLossLevel/newRiskOfLossLevel'

import Schedule from '@/pages/schedule/schedule'
import NewSchedule from '@/pages/schedule/newSchedule'

import SystemLog from '@/pages/systemLog/systemLog'

import Notification from '@/pages/notification'
import RequestForApproval from '@/pages/requestForApproval'
import DailyDataSync from '@/pages/dailyDataSync'

import CounterMode from '@/pages/counterMode/counterMode'
import DiningMode from '@/pages/diningMode/diningMode'
import UserDefined from '@/pages/userDefined/userDefined'
import OrderDetails from '@/pages/counterMode/orderDetails'
import KpiDashboard from '@/pages/kpiDashboard/kpiDashboard'
import KpiTemplate from '@/pages/kpiTemplate/kpiTemplate'
import NewKpiTemplate from '@/pages/kpiTemplate/newKpiTemplate'

import ProjectContract from '@/pages/warehouse/projectContract'
import Projects from '@/pages/warehouse/projects'
import NewProjects from '@/pages/warehouse/projects/newProjects'
import DocumentsDetail from '@/pages/warehouse/projects/documentsDetail'
import StandardTask from '@/pages/warehouse/standardTask'
import ProjectDocuments from '@/pages/warehouse/projectDocuments'
import SLAPolicies from '@/pages/warehouse/slaPolicies'
import NewSLAPolicy from '@/pages/warehouse/slaPolicies/newSLAPolicy'
import NewSLAPriority from '@/pages/warehouse/slaPolicies/newSLAPriority'
import NewSLAEscalationLevel from '@/pages/warehouse/slaPolicies/newSLAEscalationLevel'
import OperationalTime from '@/pages/warehouse/operationalTime'
import EditOperationalTime from '@/pages/warehouse/operationalTime/editOperationalTime'
import NewHolidayHours from '@/pages/warehouse/operationalTime/newHolidayHours'

import NewProjectContract from '@/pages/warehouse/projectContract/newProjectContract'
import DataMaintenance from '@/pages/warehouse/dataMaintenance/dataMaintenance'
import SubmitRequest from '@/pages/warehouse/submitRequest/submitRequest'
import NewSubmitRequest from '@/pages/warehouse/submitRequest/newSubmitRequest'
import Maintenance from '@/pages/warehouse/maintenance'
import MaintenanceCheckList from '@/pages/warehouse/maintenance/maintenanceCheckList'
import NewMaintenanceCheckList from '@/pages/warehouse/maintenance/newMaintenanceCheckList'
import WorkOrderScheduling from '@/pages/warehouse/workOrderScheduling'
import Score from '@/pages/warehouse/workOrderScheduling/score'

import SendOrder from '@/pages/warehouse/workOrderScheduling/sendOrder'
import OrderStateUpdate from '@/pages/warehouse/workOrderScheduling/orderStateUpdate'
import NewServiceConfirmationForm from '@/pages/warehouse/workOrderScheduling/newServiceConfirmationForm'
import ServiceConfirmationForm from '@/pages/warehouse/serviceConfirmationForm/serviceConfirmationForm'
import DeviceManagement from '@/pages/warehouse/deviceManagement'
import NewDevice from '@/pages/warehouse/deviceManagement/newDevice'
import DeviceCode from '@/pages/warehouse/deviceManagement/deviceCode'

import InventoryManagement from '@/pages/warehouse/inventoryManagement'
import InventoryDevice from '@/pages/warehouse/inventoryManagement/inventoryDevice'
import InWarehouseDetails from '@/pages/warehouse/inventoryManagement/inWarehouseDetails'
import AllocateGoods from '@/pages/warehouse/inventoryManagement/allocateGoods'
import OutWarehouseDetails from '@/pages/warehouse/inventoryManagement/outWarehouseDetails'
import InventoryDeviceCode from '@/pages/warehouse/inventoryManagement/inventoryDeviceCode'
import BatchInWarehouse from '@/pages/warehouse/inventoryManagement/batchInWarehouse'


import PurchaseRequisition from '@/pages/warehouse/purchaseRequisition'
import PurchaseOrders from '@/pages/warehouse/purchaseOrders'
import NewOrder from '@/pages/warehouse/purchaseOrders/newOrder'
import AddOrder from '@/pages/warehouse/purchaseOrders/addOrder'

import InventoryStatistics from '@/pages/warehouse/inventoryStatistics'
import DeviceDetailsList from '@/pages/warehouse/inventoryStatistics/deviceDetailsList'

import EngineerManagement from '@/pages/warehouse/engineerManagement'
import NewEngineer from '@/pages/warehouse/engineerManagement/newEngineer'

import Quotation from '@/pages/warehouse/quotation'
import NewQuotation from '@/pages/warehouse/quotation/newQuotation'
import NewQuote from '@/pages/warehouse/quotation/newQuote'

import ProductInformation from '@/pages/warehouse/productInformation'
import CommodityInfo from '@/pages/warehouse/productInformation/newQuotation'

import ComplaintsEvaluation from '@/pages/warehouse/complaintsEvaluation'

import AppRelease from '@/pages/warehouse/appRelease'
import ApprovalWorkflow from '@/pages/warehouse/approvalWorkflow'

import EditPdf from '@/pages/pdf/editPdf'
import Error404 from '@/pages/error/404'

function initRouter() {
  let router = [{
    path: '/home',
    component: Home,
    children: [{
        path: '/',
        redirect: 'dashBoard',
        meta: {
          permitName: 'OverviewView',
        },
      }, {
        path: 'dashBoard',
        name: 'DashBoard',
        component: DashBoard,
        meta: {
          permitName: 'OverviewView',
        }
      }, {
        path: 'riskLevelSettings',
        name: 'RiskLevelSettings',
        component: RiskLevelSettings,
        meta: {
          permitName: 'OverviewLevelSettingsView,LevelSettingsView',
          isChildren: true,
        }
      }, {
        path: 'userManagement',
        name: 'UserManagement',
        component: UserManagement,
        meta: {
          permitName: 'UserView',
        }
      }, {
        path: 'newUser',
        name: 'NewUser',
        component: NewUser,
        meta: {
          permitName: 'UserView',
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'storeGroupEditor',
        name: 'StoreGroupEditor',
        component: StoreGroupEditor,
        meta: {
          permitName: 'SiteGroupEditorView',
        }
      }, {
        path: 'reportGroupEditor',
        name: 'ReportGroupEditor',
        component: ReportGroupEditor,
        meta: {
          permitName: 'ReportGroupEditorView',
        }
      }, {
        path: 'organizations',
        name: 'Organizations',
        component: Organizations,
        meta: {
          permitName: 'OrganizationsView,AccessGroupsView',
        }
      }, {
        path: 'newDataAccessGroup',
        name: 'NewDataAccessGroup',
        component: NewDataAccessGroup,
        meta: {
          permitName: 'AccessGroupsView',
          isChildren: true,
        }
      }, {
        path: 'roles',
        name: 'Roles',
        component: Roles,
        meta: {
          permitName: 'RolesView',
        }
      }, {
        path: 'stores',
        name: 'Stores',
        component: Stores,
        meta: {
          permitName: 'StoresView',
        }
      }, {
        path: 'importStores',
        name: 'ImportStores',
        component: ImportStores,
        meta: {
          permitName: 'StoresImportStroes',
          isChildren: true,
        }
      }, {
        path: 'reportTemplates',
        name: 'ReportTemplates',
        component: ReportTemplates,
        meta: {
          permitName: 'ReportTemplatesView',
        }
      }, {
        path: 'reportGroup',
        name: 'ReportGroup',
        component: ReportGroup,
        meta: {
          permitName: 'GroupView',
        }
      }, {
        path: 'newReportGroup',
        name: 'NewReportGroup',
        component: NewReportGroup,
        meta: {
          permitName: 'GroupAddReportGroup,GroupEditReportGroup',
          isChildren: true,
        }
      }, {
        path: 'reportDesigner',
        name: 'ReportDesigner',
        component: ReportDesigner,
        meta: {
          permitName: 'DesignerView',
        }
      }, {
        path: 'overviewField',
        name: 'OverviewField',
        component: OverviewField,
        meta: {
          permitName: '',
          isChildren: true,
        }
      }, {
        path: 'fieldDisplaySet',
        name: 'FieldDisplaySet',
        component: FieldDisplaySet,
        meta: {
          permitName: '',
          isChildren: true,
        }
      }, {
        path: 'chartType',
        name: 'ChartType',
        component: ChartType,
        meta: {
          permitName: '',
          isChildren: true,
        }
      }, {
        path: 'riskOfLossLevel',
        name: 'RiskOfLossLevel',
        component: RiskOfLossLevel,
        meta: {
          permitName: 'LevelView',
        }
      }, {
        path: 'newRiskOfLossLevel',
        name: 'NewRiskOfLossLevel',
        component: NewRiskOfLossLevel,
        meta: {
          permitName: 'LevelAddRiskLevelTemplates,LevelEditRiskLevelTemplates',
          isChildren: true,
        }
      }, {
        path: 'contactList',
        name: 'ContactList',
        component: ContactList,
        meta: {
          permitName: 'ContactListView'
        }
      }, {
        path: 'schedule',
        name: 'Schedule',
        component: Schedule,
        meta: {
          permitName: 'ScheduleView'
        }
      }, {
        path: 'newSchedule',
        name: 'NewSchedule',
        component: NewSchedule,
        meta: {
          permitName: 'ScheduleView',
          isChildren: true,
        }
      }, {
        path: 'SMTP',
        name: 'SMTP',
        component: SMTP,
        meta: {
          permitName: 'SMTPView'
        }
      }, {
        path: 'systemLog',
        name: 'SystemLog',
        component: SystemLog,
        meta: {
          permitName: 'SystemLogView'
        }
      }, {
        path: 'notification',
        name: 'Notification',
        component: Notification,
        meta: {
          permitName: 'NotificationView'
        }
      }, {
        path: 'requestForApproval',
        name: 'RequestForApproval',
        component: RequestForApproval,
        meta: {
          permitName: ''
        }
      }, {
        path: 'dailyDataSync',
        name: 'DailyDataSync',
        component: DailyDataSync,
        meta: {
          permitName: ''
        }
      }, {
        path: 'counterMode',
        name: 'CounterMode',
        component: CounterMode,
        meta: {
          permitName: 'DailyReportView'
        }
      }, {
        path: 'diningMode',
        name: 'DiningMode',
        component: DiningMode,
        meta: {
          permitName: 'AdministrationReportView'
        }
      }, {
        path: 'userDefined',
        name: 'UserDefined',
        component: UserDefined,
        meta: {
          permitName: 'UserDefinedView'
        }
      }, {
        path: 'orderDetails',
        name: 'OrderDetails',
        component: OrderDetails,
        meta: {
          permitName: 'UserDfinedView',
          isChildren: true,
        }
      }, {
        path: 'kpiDashboard',
        name: 'KpiDashboard',
        component: KpiDashboard,
        meta: {
          permitName: 'KPIDashboardTemplatesView',
        }
      }, {
        path: 'kpiTemplate',
        name: 'KpiTemplate',
        component: KpiTemplate,
        meta: {
          permitName: 'KPITemplatesView',
        }
      }, {
        path: 'newkpiTemplate',
        name: 'New KPI Template',
        component: NewKpiTemplate,
        meta: {
          permitName: 'KPITemplatesAddKPITemplates,KPITemplatesEditKPITemplates',
          isChildren: true,
        }
      }, {
        path: 'projectContract',
        name: 'ProjectContract',
        component: ProjectContract,
        meta: {
          permitName: 'ProjectContractView'
        }
      }, {
        path: 'newProjectContract',
        name: 'NewProjectContract',
        component: NewProjectContract,
        meta: {
          permitName: 'ProjectContractView',
          isChildren: true,
        }
      }, {
        path: 'dataMaintenance',
        name: 'DataMaintenance',
        component: DataMaintenance,
        meta: {
          permitName: 'DataMaintenanceView'
        }
      }, {
        path: 'submitRequest',
        name: 'SubmitRequest',
        component: SubmitRequest,
        meta: {
          permitName: 'SubmitaRequestView'
        }
      }, {
        path: 'newSubmitRequest',
        name: 'NewSubmitRequest',
        component: NewSubmitRequest,
        meta: {
          permitName: 'SubmitaRequestView,WorkOrderSchedulingView',
          isChildren: true,
        }
      }, {
        path: 'maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
          permitName: '',
        }
      }, {
        path: 'maintenanceCheckList',
        name: 'MaintenanceCheckList',
        component: MaintenanceCheckList,
        meta: {
          permitName: '',
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'newMaintenanceCheckList',
        name: 'NewMaintenanceCheckList',
        component: NewMaintenanceCheckList,
        meta: {
          permitName: '',
          isChildren: true,
        }
      }, {
        path: 'workOrderScheduling',
        name: 'WorkOrderScheduling',
        component: WorkOrderScheduling,
        meta: {
          permitName: 'WorkOrderSchedulingView'
        }
      }, {
        path: 'score',
        name: 'Score',
        component: Score,
        meta: {
          isChildren: true,
        }
      },
      {
        path: 'sendOrder',
        name: 'SendOrder',
        component: SendOrder,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'orderStateUpdate',
        name: 'OrderStateUpdate',
        component: OrderStateUpdate,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'newServiceConfirmationForm',
        name: 'NewServiceConfirmationForm',
        component: NewServiceConfirmationForm,
        meta: {
          permitName: 'ServiceConfirmationFormView',
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'engineerManagement',
        name: 'EngineerManagement',
        component: EngineerManagement,
        meta: {
          permitName: 'PersonnelManagementView'
        }
      }, {
        path: 'newEngineer',
        name: 'NewEngineer',
        component: NewEngineer,
        meta: {
          permitName: 'PersonnelManagementView',
          isChildren: true,
        }
      }, {
        path: 'serviceConfirmationForm',
        name: 'ServiceConfirmationForm',
        component: ServiceConfirmationForm,
        meta: {
          permitName: 'ServiceConfirmationFormView'
        }
      }, {
        path: 'deviceManagement',
        name: 'DeviceManagement',
        component: DeviceManagement,
        meta: {
          permitName: 'DeviceManagementView'
        }
      }, {
        path: 'newDevice',
        name: 'NewDevice',
        component: NewDevice,
        meta: {
          permitName: 'DeviceManagementView',
          isChildren: true,
        }
      }, {
        path: 'deviceCode',
        name: 'DeviceCode',
        component: DeviceCode,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'inventoryManagement',
        name: 'InventoryManagement',
        component: InventoryManagement,
        meta: {
          permitName: 'InventoryManagementView'
        }
      }, {
        path: 'inventoryDevice',
        name: 'InventoryDevice',
        component: InventoryDevice,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'inWarehouseDetails',
        name: 'InWarehouseDetails',
        component: InWarehouseDetails,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'allocateGoods',
        name: 'AllocateGoods',
        component: AllocateGoods,
        meta: {
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'outWarehouseDetails',
        name: 'OutWarehouseDetails',
        component: OutWarehouseDetails,
        meta: {
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'inventoryDeviceCode',
        name: 'InventoryDeviceCode',
        component: InventoryDeviceCode,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'batchInWarehouse',
        name: 'BatchInWarehouse',
        component: BatchInWarehouse,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'purchaseRequisition',
        name: 'PurchaseRequisition',
        component: PurchaseRequisition,
        meta: {
          permitName: 'PurchaseRequisitionView-Allitems,PurchaseRequisitionView-Toonlyallowausertoviewhisownitems'
        }
      }, {
        path: 'purchaseOrders',
        name: 'PurchaseOrders',
        component: PurchaseOrders,
        meta: {
          permitName: 'PurchaseOrdersView'
        }
      }, {
        path: 'newOrder',
        name: 'NewOrder',
        component: NewOrder,
        meta: {
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'addOrder',
        name: 'AddOrder',
        component: AddOrder,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'inventoryStatistics',
        name: 'InventoryStatistics',
        component: InventoryStatistics,
        meta: {
          permitName: 'InventoryStatisticsView'
        }
      }, {
        path: 'deviceDetailsList',
        name: 'DeviceDetailsList',
        component: DeviceDetailsList,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'quotation',
        name: 'Quotation',
        component: Quotation,
      }, {
        path: 'newQuotation',
        name: 'NewQuotation',
        component: NewQuotation,
        meta: {
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'newQuote',
        name: 'NewQuote',
        component: NewQuote,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'productInformation',
        name: 'ProductInformation',
        component: ProductInformation,
        meta: {
          permitName: 'ProductinformationView'
        }
      }, {
        path: 'commodityInfo',
        name: 'CommodityInfo',
        component: CommodityInfo,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'accountPolicies',
        name: 'AccountPolicies',
        component: AccountPolicies,
        meta: {
          permitName: 'AccountPoliciesView'
        }
      }, {
        path: 'complaintsEvaluation',
        name: 'ComplaintsEvaluation',
        component: ComplaintsEvaluation,
        meta: {
          permitName: 'ComplaintsandEvaluationView'
        }
      }, {
        path: 'appRelease',
        name: 'AppRelease',
        component: AppRelease,
        meta: {
          permitName: 'appReleaseView'
        }
      }, {
        path: 'approvalWorkflow',
        name: 'ApprovalWorkflow',
        component: ApprovalWorkflow,
        meta: {
          permitName: 'ApprovalWorkflowView'
        }
      }, {
        path: 'projects',
        name: 'Projects',
        component: Projects,
        meta: {
          permitName: 'ProjectsView'
        }
      }, {
        path: 'newProjects',
        name: 'NewProjects',
        component: NewProjects,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'documentsDetail',
        name: 'DocumentsDetail',
        component: DocumentsDetail,
        meta: {
          isChildren: true,
        }
      }, {
        path: 'standardTask',
        name: 'StandardTask',
        component: StandardTask,
        meta: {
          permitName: 'StandardTaskView'
        }
      }, {
        path: 'projectDocuments',
        name: 'ProjectDocuments',
        component: ProjectDocuments,
        meta: {
          permitName: 'ProjectDocumentsView'
        }
      }, {
        path: 'slaPolicies',
        name: 'SLAPolicies',
        component: SLAPolicies,
        meta: {
          permitName: 'SLAPolicies-SLAPoliciesView,SLAPolicies-EscalationLevelView'
        }
      }, {
        path: 'newSLAPolicy',
        name: 'NewSLAPolicy',
        component: NewSLAPolicy,
        meta: {
          permitName: 'SLAPolicies-SLAPoliciesView,SLAPolicies-EscalationLevelView',
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'newSLAPriority',
        name: 'NewSLAPriority',
        component: NewSLAPriority,
        meta: {
          permitName: 'SLAPolicies-SLAPoliciesView,SLAPolicies-EscalationLevelView',
          isChildren: true,
          // isKeepAlive: true,
        }
      }, {
        path: 'newSLAEscalationLevel',
        name: 'NewSLAEscalationLevel',
        component: NewSLAEscalationLevel,
        meta: {
          permitName: 'SLAPolicies-SLAPoliciesView,SLAPolicies-EscalationLevelView',
          isChildren: true,
        }
      }, {
        path: 'operationalTime',
        name: 'OperationalTime',
        component: OperationalTime,
        meta: {
          permitName: 'OperationalTimeView'
        }
      }, {
        path: 'editOperationalTime',
        name: 'EditOperationalTime',
        component: EditOperationalTime,
        meta: {
          permitName: '',
          isChildren: true,
          isKeepAlive: true,
        }
      }, {
        path: 'newHolidayHours',
        name: 'NewHolidayHours',
        component: NewHolidayHours,
        meta: {
          permitName: '',
          isChildren: true,
        }
      }, {
        path: 'editPdf',
        name: 'EditPdf',
        component: EditPdf,
        meta: {
          permitName: '',
          isChildren: true,
        }
      },
    ]
  }, {
    path: '*',
    name: 'Error404',
    component: Error404,
  }];
  return router;
}

// 加载路由
export function loadRouter() {
  let _router = initRouter();

  if (!localStorage.isSuper) {
    filterAsyncRoutes(_router)
  }

  Router.addRoutes(_router);
}

// 递归遍历路由
function filterAsyncRoutes(routers) {
  let permission = localStorage.permission.split(',');
  routers.forEach(item => {
    if (!!item.meta !== false && !!item.meta.permitName !== false) {
      let permitName = item.meta.permitName.split(',');
      item.meta.permit = permitName.some(_permitName => permission.includes(_permitName));

      if (item.path === '/' && item.meta.permit === false) {
        delete item.redirect;
        delete item.meta.permit;
      }
    }
    if (!!item.children)
      filterAsyncRoutes(item.children);
  })
}
