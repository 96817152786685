<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        class="dialog-form"
        size="small"
      >
        <el-form-item
          :label="$t('complaintsEvaluation.complaints.label1')"
          prop="branch"
        >
          <el-select
            v-model="dialog.branch"
            filterable
            remote
            reserve-keyword
            :remote-method="queryBrand"
          >
            <el-option
              v-for="item in brands"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('complaintsEvaluation.complaints.label2')"
          prop="complaints"
        >
          <el-input
            type="textarea"
            :rows="8"
            v-model="dialog.complaints"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import {
  AddComplaintsEvaluation
} from '@/api/warehouse/complaintsEvaluation'
import { SearchBrand } from '@/api/stores'

export default {
  props: ['visible', 'data'],
  data () {
    return {
      brands: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        branch: '',
        complaints: '',
      },
      rules: {
        branch: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('complaintsEvaluation.complaints.title2'),
              branch: this.data.branchId,
              complaints: this.data.description,
            })
            this.brands = [{ name: this.data.branchName, id: this.data.branchId }]
          })
        } else {
          this.dialog.title = this.$i18n.t('complaintsEvaluation.complaints.title1')

        }

        if (this.brands.length === 0) {
          this.queryBrand('');
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 查询网点
    queryBrand (query) {
      let param = { name: query }
      SearchBrand(param).then(res => this.brands = res);
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          branchId: this.dialog.branch,
          description: this.dialog.complaints,
        }
        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddComplaintsEvaluation(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }
  .el-button {
    width: 100%;
  }
}
</style>