<template>
  <div>
    <div class="filer-wrap">
      <div class="head-box">
        <div
          v-if="isBack"
          class="head-item"
          @click="back"
        >
          <i class="el-icon-d-arrow-left"></i>
        </div>
        <div
          v-else
          class="head-item"
          @click="paramBack"
        >
          <i
            v-show="historyParam.length > 0"
            class="el-icon-d-arrow-left"
          ></i>
          <div
            v-show="historyParam.length == 0"
            class="head-icon"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div
          class="head-item"
          @click="showQuery(0)"
        >
          <i class="el-icon-search"></i>
        </div>
        <div
          class="head-item"
          @click="showQuery(1)"
        >
          <i class="icon-search"></i>
        </div>
        <div
          class="head-item"
          @click="resetParam"
        >
          <i class="el-icon-refresh"></i>
        </div>
        <div
          v-show="sortId.includes(templatesId)"
          class="head-item"
          @click="showSort"
        >
          <el-badge
            :is-dot="!!sortParam && sortParam.length !== 0"
            class="item"
          >
            <i class="el-icon-sort"></i>
          </el-badge>
        </div>
        <div
          class="head-item"
          :class="{
            'disable':!orderState
          }"
          @click="orderChange"
        >
          <i class="icon-receipt"></i>
        </div>
        <div
          class="head-item"
          :class="{
            'disable':!bgcolorState
          }"
          @click="bgcolorChange"
        >
          <i class="icon-level"></i>
        </div>
        <div
          class="head-item"
          @click="moveParam(0)"
        >
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          class="head-item"
          @click="moveParam(1)"
        >
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="filter-box"
        @wheel="wheelParam"
      >
        <div
          class="filter-slide"
          :style="{
            'margin-left':filterSlide.marginLeft + 'px'
          }"
        >
          <filter-item
            v-for="(item,index) in filterParamData"
            :key="index"
            :data="item"
            :reportId="templatesId"
            :zindex.sync="zIndex"
            @submit="submitParam"
            @showQuery="showQueryData"
            @closeCondition="closeCondition"
            ref="filterItem"
          ></filter-item>

        </div>
      </div>

      <el-dropdown
        trigger="click"
        @command="handleCommand"
      >
        <i class="el-icon-more foot-btn"></i>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="Unhide Table"> <i class="el-icon-s-grid"></i> Unhide Table</el-dropdown-item> -->
          <!-- <el-dropdown-item command="Unhide Charts"> <i class="el-icon-s-data"></i> Unhide Charts</el-dropdown-item> -->
          <el-dropdown-item command="Risk Level Settings"> <i class="el-icon-s-operation"></i>{{$t('counterMode.btn1')}}</el-dropdown-item>
          <el-dropdown-item command="Print..."> <i class="el-icon-printer"></i>{{$t('counterMode.btn2')}}</el-dropdown-item>
          <el-dropdown-item
            :disabled="exportLoading"
            command="Export"
          >
            <i :class="{
            'el-icon-download':!exportLoading,
            'el-icon-loading':exportLoading
            }"></i>{{$t('counterMode.btn3')}}
          </el-dropdown-item>
          <el-dropdown-item command="Layout Settings"> <i class="el-icon-menu"></i>{{$t('counterMode.btn4')}}</el-dropdown-item>
          <el-dropdown-item
            :disabled="!templatesId"
            command="Save"
          > <i class="el-icon-s-claim"></i>{{$t('counterMode.btn5')}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 查询弹窗 -->
    <query
      :visible.sync="QueryVisible"
      :data="queryData"
      :report-template-id="templatesId"
      @submit="submitQuery"
    ></query>
    <!-- 导出弹框 -->
    <export-reports
      :visible.sync="ExportReportsVisible"
      @submit="submitExport"
    ></export-reports>
    <!-- 布局弹窗 -->
    <layout-setting
      :visible.sync="LayoutSettingVisible"
      @submit="submitLayout"
    ></layout-setting>
    <!-- 排序弹框 -->
    <sort-dialog
      ref="SortDialog"
      :visible.sync="SortDialogVisible"
      :template-fields="templateFields"
      @submit="submitSort"
    ></sort-dialog>
  </div>
</template>

<script>
// 组件
import Query from '@/components/DashBoardDialog/Query'
import ExportReports from './ExportReports'
import LayoutSetting from '@/components/DashBoardDialog/LayoutSetting'
import FilterItem from '@/components/FilterItem/'
import SortDialog from './SortDialog'

// 接口
import {
  GetParamRecord,
  AddParamRecord,
} from '@/api/counterMode'

export default {
  components: {
    Query,
    ExportReports,
    LayoutSetting,
    FilterItem,
    SortDialog,
  },
  props: {
    isBack: Boolean,
    orderState: Boolean,
    bgcolorState: Boolean,
    templatesId: String,
    filterParam: Object,
    isDataNull: Boolean,
    commonParam: Array,
    historyParam: Array,
    exportLoading: Boolean,
    templateFields: Array,
    sortParam: Array,
  },
  data () {
    return {
      QueryVisible: false,
      ExportReportsVisible: false,
      LayoutSettingVisible: false,
      SortDialogVisible: false,
      filterSlide: {
        marginLeft: 0,
      },
      zIndex: 2,
      queryData: null,
      filterParamData: [],
      sortId: [
        '58c9a3d5-22e5-4ab7-ae39-16bef91f1336',
        '2501e3c6-a2d7-4f5f-9965-28f860e2c24f',
        '928390db-6c69-483e-9649-83c51f2e9252',
        '2f0316a9-1906-4cf0-9734-db6a4ca1026c',
        '49f2c525-2e70-4573-8e1d-04341f802061',
        'cd418eef-c96f-46e6-b0b5-dbbf8a3ff905',
        '4e1dab28-567c-4247-bc27-a80ba35eaee2',
        '2c6e9cd9-79d8-4c28-821f-17f8df6990f0',
        '1e8284cd-98b8-420c-b9ca-b4d6b7f65b0e',
        // '285b7fab-a632-4ba2-81ee-6ee2533fcffc',
        '4d5ded7d-1cfd-44c0-a9d4-6c9c97bdb48b',
        '1fb7c26c-141b-44fe-8e17-e0122d51292b',
        '37f55abe-b92c-4463-9a33-0052e1f118ac',
        '2c38fb01-f683-46d7-a0fb-298a75c9645e',
      ],
    }
  },
  watch: {
    'commonParam': function () {
      this.filterParamData = JSON.parse(JSON.stringify(this.commonParam))
    },
  },
  created () {
    this.filterParamData = JSON.parse(JSON.stringify(this.commonParam))
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    paramBack () {
      this.$emit('paramBack');
    },
    // 提交布局
    submitLayout (index) {
      this.$emit('submitLayout', index);
    },
    showQuery (type) {
      if (!this.templatesId) return this.$message.warning('Please select the report.');
      this.queryData = {
        data: {
          queryType: type
        }
      }
      this.QueryVisible = true;
    },
    showQueryData (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);

      this.queryData = this.filterParamData[index];
      this.QueryVisible = true;
    },
    handleCommand (command) {
      switch (command) {
        case 'Risk Level Settings':
          this.$router.push({ path: '/home/riskLevelSettings', query: { isshow: 1 } })
          break;
        case 'Print...':
          break;
        case 'Export':
          if (!this.templatesId) return this.$message.warning('Please select the report.');
          this.ExportReportsVisible = true;
          break;
        case 'Layout Settings':
          this.LayoutSettingVisible = true;
          break;
        case 'Save':
          this.$emit('saveLayout')
          break;
      }
    },
    // 删除参数
    closeCondition (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      let dialogData = this.$refs.filterItem.map((item, index) => {
        let result = { visible: item.FilterDialogVisible };
        if (item.FilterDialogVisible) {
          let dialog = item.$refs.filterDialog.$el;
          result.left = dialog.style.left;
          result.top = dialog.style.top;
          result.prop = this.filterParamData[index].prop
        }
        return result;
      });
      this.filterParamData.splice(index, 1);
      this.$emit('submit', {}, this.filterParamData);

      this.$nextTick(() => {
        dialogData.splice(index, 1);
        dialogData.forEach((item, index) => {
          this.$refs.filterItem[index].FilterDialogVisible = item.visible;
          if (item.visible) this.$refs.filterItem[index].setPosition(item);
        })
      })
    },
    // 提交参数
    submitParam (data, type) {
      let param = {
        isReport: false,
      }
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      let result
      if (index === 0) {
        result = [
          data,
          Object.assign(this.filterParamData[1], {
            mark: "BU",
            prop: "branchIds",
            label: "Site Name",
            con: "ALL",
            val: '',
          })
        ]
        this.getRecord(0, data.val.id);
        param.isReport = true;

        this.$refs.SortDialog.resetData();
        this.$emit('update:sortParam', [])
      } else {
        this.$set(this.filterParamData, index, data);
        result = this.filterParamData
      }

      if (index >= 2 && !type) {
        this.addRecord(0, data)
      }

      this.$emit('submit', param, result);
    },
    // 重置条件 
    resetParam () {
      if (!this.templatesId) return this.$message.warning('Please select the report.');
      this.$emit('resetParam');
    },
    wheelParam (e) {
      if (e.wheelDelta > 0) {
        this.moveParam(0)
      } else {
        this.moveParam(1)
      }
    },
    moveParam (type) {
      if (type == 0) {
        if (this.filterSlide.marginLeft == 0) return;
        this.filterSlide.marginLeft += 265;
      } else {
        if (Math.abs(this.filterSlide.marginLeft / 265) + 1 >= this.filterParamData.length) return;
        this.filterSlide.marginLeft -= 265;
      }
    },
    submitQuery (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      if (index == -1) {
        this.filterParamData.push(data)
      } else {
        Object.assign(this.filterParamData[index], data)
      }
      this.$emit('submit', {}, this.filterParamData);
    },
    orderChange () {
      this.$emit('update:orderState', !this.orderState);
    },
    bgcolorChange () {
      this.$emit('update:bgcolorState', !this.bgcolorState);
    },
    addRecord (type, condition) {
      let param = {
        templateId: this.templatesId,
        condition: JSON.stringify(condition),
        type: type,
      }
      AddParamRecord(param).then(res => {
        this.getRecord(0, this.templatesId);
      })
    },
    getRecord (type, id) {
      let param = {
        templateId: id,
        type: type,
      }
      GetParamRecord(param).then(res => {
        let data = res.map(item => {
          item.name = JSON.parse(item.condition).label
          return item;
        });
        this.$store.state.filterRequestHistory = data;
      })
    },
    // 导出
    submitExport (data) {
      this.$emit('submitExport', data)
    },
    // 显示排序
    showSort () {
      if (!this.templatesId) return this.$message.warning('Please select the report.');
      this.SortDialogVisible = true;
    },
    // 提交排序 
    submitSort (data) {
      this.$emit('update:sortParam', data)
      this.$emit('submit', {}, this.filterParamData);
    }
  }
}
</script>

<style lang="scss" scoped>
.filer-wrap {
  width: 100%;
  height: 50px;
  display: flex;
}
.head-box {
  border: 1px solid #e4e4e4;
  background: #fff;
  display: flex;
  // box-shadow: 0 2px 4px 0 #0002;

  .head-item {
    width: 40px;
    font-size: 18px;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #f0f2f5;
    }
    &.disable {
      color: #ccc;
    }
  }
}

.filter-box {
  width: calc(100% - 290px);
  margin: 0 5px;
  overflow: hidden;
  flex: 1;
  .filter-slide {
    height: 100%;
    margin-left: 0;
    transition: all 0.3s;
    display: flex;
    flex-wrap: nowrap;
  }
}
.foot-btn {
  width: 40px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  padding: 0 10px;
  border: 1px solid #e4e4e4;
  background: #fff;
  box-sizing: border-box;
  &:hover {
    background: #f0f2f5;
    cursor: pointer;
  }
}

.head-icon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    height: 5px;
    margin: 1px 0;
    display: inline-block;
  }
  span:nth-of-type(1) {
    width: 100%;
    background-color: #00c875;
  }
  span:nth-of-type(2) {
    width: 70%;
    background-color: #ff0000;
  }
  span:nth-of-type(3) {
    width: 40%;
    background-color: #fdab3d;
  }
}
</style>