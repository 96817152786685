import request from '@/utils/request'

// 查询报表列表模板
export const GetReportList = (data) => request({
  method: 'post',
  url: '/reportTemplates/queryByPage.do',
  data: JSON.stringify(data),
})

// 添加、修改报表模板
export const AddReport = (data) => request({
  method: 'post',
  url: '/reportTemplates/addOrUpdate.do',
  data: JSON.stringify(data),
})

// 删除报表模板
export const DelReport = (data) => request({
  method: 'post',
  url: '/reportTemplates/deleteById.do',
  data: JSON.stringify(data),
})

// 按模式类型查询报表模板
export const GetTypeReport = (data) => request({
  method: 'post',
  url: '/reportTemplates/mode.do',
  data: JSON.stringify(data),
})
