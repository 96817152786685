<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="Filters"
      :visible.sync="dialog.visible"
      center
      width="650px"
      class="query-dialog"
    >
      <div>
        <el-form
          size="small"
          :model="dialog"
          :rules="rules"
          ref="queryForm"
          class="query-form"
        >
          <el-form-item>
            <div slot="label">
              <span>Current Field</span>
              <span class="sort-id">{{this.fieldLabel}}</span>
            </div>
          </el-form-item>

          <el-form-item
            label="Filter Operator"
            prop="operator"
          >
            <el-select v-model="dialog.operator">
              <el-option
                v-for="(item,index) in operators"
                :key="index"
                :value="index"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <template v-if="fieldType === 'date'">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item
                  label="Condition"
                  prop="condition"
                >
                  <el-select
                    v-model="dialog.condition"
                    @change="changeDataType"
                  >
                    <el-option
                      v-for="(item,index) in dateCondition"
                      :key="index"
                      :value="index"
                      :label="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Measure">
                  <el-input
                    v-model="fieldMeasure"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item
                  v-show=" dialog.condition === 12"
                  prop="dateRange"
                >
                  <div class="range-box">
                    <el-date-picker
                      v-model="dialog.dateRange"
                      type="datetimerange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      unlink-panels
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <template v-else-if="fieldType === 'option'">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item
                  label="Condition"
                  prop="condition"
                >
                  <el-select v-model="dialog.condition">
                    <el-option
                      v-for="(item,index) in optionData"
                      :key="index"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Measure">
                  <el-input
                    v-model="fieldMeasure"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </template>
          <template v-else-if="fieldType === 'time'">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item
                  label="Condition"
                  prop="dateRange"
                >
                  <div class="range-box">
                    <el-time-picker
                      :disabled="dialog.operator === 0"
                      class="range-input"
                      v-model="dialog.dateRange[0]"
                      size="small"
                      value-format="HH:mm"
                    >
                    </el-time-picker>
                    <span class="range-line"></span>
                    <el-time-picker
                      :disabled="dialog.operator === 1"
                      class="range-input"
                      v-model="dialog.dateRange[1]"
                      size="small"
                      value-format="HH:mm"
                    >
                    </el-time-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Measure">
                  <el-input
                    v-model="fieldMeasure"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <template v-else-if="fieldType === 'mins'">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item
                  v-if=" dialog.operator !== 2"
                  label="Condition"
                  prop="condition"
                >
                  <el-input v-model="dialog.condition"></el-input>
                </el-form-item>
                <el-form-item
                  v-else
                  label="Condition"
                  prop="range"
                >
                  <div class="range-box">
                    <el-input
                      class="range-input"
                      v-model="dialog.range.start"
                    ></el-input>
                    <span class="range-line"></span>
                    <el-input
                      class="range-input"
                      v-model="dialog.range.end"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Measure">
                  <el-input
                    v-model="fieldMeasure"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <template v-else-if="fieldType === 'stores'">
            <el-row
              :gutter="20"
              v-if="dialog.operator != 2"
            >
              <el-col :span="16">
                <el-form-item
                  label="Condition"
                  prop="condition"
                >
                  <el-input v-model="dialog.condition"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Measure">
                  <el-input
                    v-model="fieldMeasure"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              v-else
            >
              <el-col>
                <el-button
                  class="list-btn"
                  @click="showList"
                >List...</el-button>
              </el-col>
            </el-row>
          </template>
          <!-- number string stores days LowValues -->
          <template v-else>
            <el-row
              :gutter="20"
              v-if="dialog.operator != 6"
            >
              <el-col :span="16">
                <el-form-item
                  v-if=" dialog.operator !== 7"
                  label="Condition"
                  prop="condition"
                >
                  <el-input v-model="dialog.condition"></el-input>
                </el-form-item>
                <el-form-item
                  v-if=" dialog.operator === 7 && fieldType === 'number'"
                  label="Condition"
                  prop="range"
                >
                  <div class="range-box">
                    <el-input
                      class="range-input"
                      v-model="dialog.range.start"
                    ></el-input>
                    <span class="range-line"></span>
                    <el-input
                      class="range-input"
                      v-model="dialog.range.end"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Measure">
                  <el-input
                    v-model="fieldMeasure"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              v-else
            >
              <el-col>
                <el-button
                  class="list-btn"
                  @click="showList"
                >List...</el-button>
              </el-col>

            </el-row>
          </template>

        </el-form>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >OK</el-button>
      </div>
    </el-dialog>

    <!-- 列表弹框 -->
    <list-dialog
      :visible.sync="ListDialogVisible"
      :label="fieldLabel"
      :list="dialog.list"
      @listSubmit="listSubmit"
    ></list-dialog>
  </div>
</template>

<script>
// 组件
import ListDialog from '@/components/DashBoardDialog/ListDialog'

export default {
  name: 'FilterDialog',
  props: ['visible', 'prop', 'data'],
  components: {
    ListDialog,
  },
  data () {
    return {
      ListDialogVisible: false,
      dateCondition: ['Last 30 minutes', 'Last 1 hour', 'Last 4 hours', 'Today', 'Yesterday', 'Last 3 Days', 'Last 5 Days', 'Last 1 Week', 'Last 30 Days', 'Last 90 Days', 'Last Year', 'All Time', 'Custom Range', 'Test Time'],
      dialog: {
        visible: false,
        operator: 0,
        condition: '',
        range: {
          start: '',
          end: '',
        },
        dateRange: [],
        list: [],
      },
      rules: {
        condition: [{ required: true, message: 'Please enter an Condition.', trigger: 'blur' }],
        // range: [{ validator: this.validateRange, trigger: 'blur' }]
      },
      fieldLabel: null,
      fieldType: null,
      fieldMeasure: null,
    }
  },
  computed: {
    // 规则选项
    'operators': function () {
      return this.$global.typeOperators[this.fieldType];
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible;
    },
    'dialog.visible': function () {

      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.$refs.queryForm && this.$refs.queryForm.resetFields();
        this.dialog.list = [];
        this.fieldLabel = this.data.filter.label;
        this.fieldType = this.data.filter.type;
        this.fieldMeasure = this.data.filter.measure;

        if (!!this.data.filter.data) {
          this.dialog = JSON.parse(JSON.stringify(this.data.filter.data));
        }
      }
    }
  },
  methods: {
    // 切换时间选项
    changeDataType (newVal) {
        let date = new Date();
        switch (newVal) {
          case 0:
            this.dialog.dateRange = this.$global.TheLastThirtyMinutes.split('~')
            // this.dialog.dateRange[0] = formatDate(new Date(date.getTime() - 30 * 60 * 1000));
            // this.dialog.dateRange[1] = formatDate(date);
            break;
          case 1:
            this.dialog.dateRange[0] = formatDate(new Date(date.getTime() - 60 * 60 * 1000));
            this.dialog.dateRange[1] = formatDate(date);
            break;
          case 2:
            this.dialog.dateRange[0] = formatDate(new Date(date.getTime() - 4 * 60 * 60 * 1000));
            this.dialog.dateRange[1] = formatDate(date);
            break;
          case 3:
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            break;
          case 4:
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            break;
          case 5:
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 6:
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 4);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 7:
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 6);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 8:
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 29);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 9:
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 89);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 10:
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 364);
            this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
            break;
          case 11:
            this.dialog.dateRange[0] = formatDate(new Date('2020-10-1'), 'yyyy-MM-dd 00:00:00');
            this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
            break;
          case 13:
            this.dialog.dateRange[0] = '2020-10-22 00:00:00'
            this.dialog.dateRange[1] = '2020-10-22 23:59:59'
            break;
        }

    },
    submit () {
      this.$refs.queryForm.validate(valid => {
        if (!valid) return;
        let result = JSON.parse(JSON.stringify(this.data));
        result.filter.data = JSON.parse(JSON.stringify(this.dialog));
        result.status = true;
        this.$emit('submit', result);
        this.dialog.visible = false;
      })
    },

    listSubmit (data) {
      let result = JSON.parse(JSON.stringify(this.data));
      this.dialog.list = data;
      result.filter.data = JSON.parse(JSON.stringify(this.dialog));
      result.status = true;
      this.$emit('submit', result);
      this.dialog.visible = false;
    },
    showList () {
      this.ListDialogVisible = true;
    },
    // 验证数值范围
    validateRange (rule, value, callback) {
      if (this.dialog.range.state === '' || this.dialog.range.end === '') {
        callback(new Error('Value range cannot be empty.'));
      } else if (parseFloat(this.dialog.range.state) > parseFloat(this.dialog.range.end)) {
        callback(new Error('The initial value cannot be greater than the end value.'));
      } else {
        callback();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.query-dialog {
  .el-select {
    width: 100%;
  }

  .list-btn {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
  .sort-id {
    margin-left: 100px;
  }
}
.range-box {
  width: 100%;
  display: flex;
  align-items: center;
  .range-line {
    width: 15px;
    border-bottom: 1px solid #000;
    margin: 0 5px;
  }
}
</style>