import request from '@/utils/request'

// 获取项目进度列表
export const GetProjectsList = (data) => request({
  method: 'post',
  url: `/project/management/queryByPage.do`,
  data: JSON.stringify(data)
})

// 新增和修改
export const AddProjects = (data) => request({
  method: 'post',
  url: `/project/management/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 删除
export const DelProjects = (id) => request({
  method: 'post',
  url: `/project/management/deleted/${id}.do`,
})

// 获取项目信息
export const GetProjectsInfo = (id) => request({
  method: 'get',
  url: `/project/management/get/${id}.do`,
})

// 获取项目文档列表
export const GetProjectsDocumentList = (id) => request({
  method: 'get',
  url: `/project/ganTt/task/document/project/${id}.do`,
})

// 获取任务树
export const GetTaskTree1 = (id) => request({
  method: 'get',
  url: `/project/ganTt/task/list/${id}.do`,
})

// 获取任务树
export const GetTaskTree2 = (id) => request({
  method: 'get',
  url: `/project/ganTt/task/zTree/${id}.do`,
})

// 新增修改任务
export const AddTask = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 删除任务
export const DelTask = (id) => request({
  method: 'post',
  url: `/project/ganTt/task/deleted/${id}.do`,
})

// 获取任务人员列表
export const GetTaskPersonList = (id) => request({
  method: 'get',
  url: `/project/ganTt/task/person/list/${id}.do`,
})

// 新增和修改任务人员
export const AddTaskPerson = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/person/save.do`,
  data: JSON.stringify(data)
})

// 修改任务人员时间
export const EditTaskPerson = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/person/edit.do`,
  data: JSON.stringify(data)
})

// 删除任务人员
export const DelTaskPerson = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/person/del.do`,
  data: JSON.stringify(data)
})

// 获取任务文档列表
export const GetTaskDocumentList = (id) => request({
  method: 'get',
  url: `/project/ganTt/task/document/task/${id}.do`,
})

// 新增任务文档
export const AddTaskDocument = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/document/save.do`,
  data: JSON.stringify(data)
})

// 删除任务人员
export const DelTaskDocument = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/document/del.do`,
  data: JSON.stringify(data)
})

// 获取已提交任务文档
export const GetSubmitDocument = (id) => request({
  method: 'get',
  url: `/project/ganTt/task/document/task/document/${id}.do`,
})

// 新增任务文档
export const AddSubmitDocument = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/document/refer/save.do`,
  data: JSON.stringify(data)
})

// 删除已提交任务文档
export const DelSubmitDocument = (id) => request({
  method: 'post',
  url: `/project/ganTt/task/document/refer/del/${id}.do`,
  data: id
})

// 批量导入标准任务
export const ImportStandardTask = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/task/batch/import.do`,
  data: JSON.stringify(data)
})

// 设置基线
export const SetBase = (id) => request({
  method: 'post',
  url: `/project/ganTt/task/baseline/${id}.do`,
})

// 设置基线
export const EditTaskTree = (data) => request({
  method: 'post',
  url: `/project/ganTt/task/edit.do`,
  data: JSON.stringify(data)
})
