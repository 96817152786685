<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('equipmentDialog.title')"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table border          v-loading="tableLoading"
          :data="tableData"
          @row-dblclick="dblclick"
        >
          <el-table-column
            min-width="130"
            :label="$t('equipmentDialog.col1')"
            prop="qrCode"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('equipmentDialog.col3')"
            prop="deviceInfoName"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('equipmentDialog.col4')"
            prop="deviceInfoModel"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('equipmentDialog.col5')"
            prop="serialNumber"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('equipmentDialog.col6')"
            prop="location"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('equipmentDialog.col7')"
            prop="label"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetDeviceList,
} from '@/api/warehouse/deviceManagement'
export default {
  props: ['visible', 'branchId'],
  data () {
    return {
      dialogVisible: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.searchData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    // this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        branchId: this.branchId,
      }
      this.tableLoading = true;
      GetDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e);
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>