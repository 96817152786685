<template>
  <div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-checkbox v-model="dialog.filter.contains">{{$t('engineerManagement.filter.label1')}}</el-checkbox>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { AddSite } from '@/api/stores'
export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: this.$i18n.t('engineerManagement.filter.title1'),
        filter: {
          contains: false,
        },
      },

    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.dialog.filter = this.data;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交
    submit () {
      this.$emit('update', this.dialog.filter)
      this.dialog.visible = false;
    },

  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>