<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('home.userInfo.title1')"
      :visible.sync="dialog.visible"
      width="600px"
      center
    >
      <el-form
        :model="dialog"
        ref="dialogForm"
        size="medium"
      >
        <el-form-item :label="$t('home.userInfo.label1')">
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.userInfo.label2')">
          <el-input v-model="dialog.email1"></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.userInfo.label3')">
          <el-input v-model="dialog.email2"></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.userInfo.label4')">
          <el-input v-model="dialog.phone1"></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.userInfo.label5')">
          <el-input v-model="dialog.phone2"></el-input>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialog.visible = false">{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetCurrentUser,
  EditCurrentUser,
} from '@/api'
export default {
  name: 'UserInfo',
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        name: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
      },
    }
  },
  watch: {
    'visible': function () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.visible = this.visible
      this.getUser();
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    getUser () {
      GetCurrentUser().then(res => {
        Object.assign(this.dialog, {
          name: res.fullname,
          email1: res.emailAddress,
          email2: res.secondEmailAddress,
          phone1: res.phoneNumber,
          phone2: res.secondPhoneNumber,
        })
      })
    },
    submit () {
      let param = {
        fullname: this.dialog.name,
        emailAddress: this.dialog.email1,
        secondEmailAddress: this.dialog.email2,
        phoneNumber: this.dialog.phone1,
        secondPhoneNumber: this.dialog.phone2,
      }
      EditCurrentUser(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.edit'));
          this.dialog.visible = false;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>