<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showStatus"
          >{{$t('newOrder.orderTracking.btn1')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('newOrder.orderTracking.col1')"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          :label="$t('newOrder.orderTracking.col2')"
        >
        </el-table-column>
        <el-table-column
          prop="rolesName"
          :label="$t('newOrder.orderTracking.col3')"
        >
          <template
            slot-scope="scope"
            v-if="scope.row.type === 1"
          >

            {{paymentStatus[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="rolesName"
          :label="$t('newOrder.orderTracking.col4')"
        >
          <template
            slot-scope="scope"
            v-if="scope.row.type === 2"
          >
            {{orderStatus[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="rolesName"
          :label="$t('newOrder.orderTracking.col5')"
        >
          <template
            slot-scope="scope"
            v-if="scope.row.type === 3"
          >
            {{arrivalStatus[scope.row.status]}}
          </template>
        </el-table-column>

      </el-table>
    </el-row>

    <!-- 更新状态弹框 -->
    <update-state
      :visible.sync="UpdateStateVisible"
      @update="getTableData"
    ></update-state>
  </div>
</template>

<script>
// 组件
import UpdateState from './UpdateState'
// 接口
import {
  GetOrdersStatusList,
} from '@/api/warehouse/purchaseOrders'
export default {
  components: {
    UpdateState,
  },
  props: ['active', 'orderId'],
  data () {
    return {
      UpdateStateVisible: false,
      paymentStatus: ['', '已付全款', '已部分付款', '待付款'],
      orderStatus: ['', '已订货', '已部分订货', '待订货'],
      arrivalStatus: ['', '已全部到货', '已部分到货'],
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab3') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.getTableData();
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        orderFormId: this.orderId,
      }
      this.tableLoading = true;
      GetOrdersStatusList(param).then(res => {
        this.tableData = res;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 显示状态
    showStatus () {
      this.UpdateStateVisible = true
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.bottom-btn {
  text-align: center;
}
.input-group {
  height: 36px;
}
.table-row {
  height: calc(100% - 46px);
}
</style>