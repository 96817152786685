<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="合并"
      :visible.sync="dialog.visible"
      center
      width="500px"
    >
      <p>所有其他项目将合并到所选项目中。</p>
      <el-table
        border
        ref="table"
        :data="data"
        :height="385"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @select="rowSelect"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          :label="tableLable"
          prop="name"
          sortable
        >
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  MergeItemType,
  MergeBrand,
  MergeSupplier,
  MergeSystemCategory,
  MergeIssueMainType,
  MergeServiceCategory,
  MergeDeliverAddress,
} from '@/api/warehouse/dataMaintenance'
export default {
  props: ['visible', 'data', 'tableLable', 'type'],
  data () {
    return {
      tableSelected: [],
      dialog: {
        visible: false,
        loading: false,
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.$refs.table && this.$refs.table.clearSelection();

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 
    rowClick (row, column, event) {
      this.$refs.table.clearSelection();
      this.$nextTick(() => {
        this.$refs.table.toggleRowSelection(row)
      })
    },
    // 
    rowSelect (selection, row) {
      this.$refs.table.clearSelection();
      this.$nextTick(() => {
        this.$refs.table.toggleRowSelection(row)
      })
    },
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let param = {
        src: this.data.filter(item => item.id !== this.tableSelected[0].id).map(item => item.id).join(','),
        target: this.tableSelected[0].id,
      }
      this.dialog.loading = true;

      switch (this.type) {
        case '100000000000000000000000000000000001':
          MergeItemType(param).then(this.dealMerge)
          break;
        case '100000000000000000000000000000000002':
          MergeBrand(param).then(this.dealMerge)
          break;
        case '100000000000000000000000000000000003':
          MergeSupplier(param).then(this.dealMerge)
          break;
        case '100000000000000000000000000000000004':
          MergeSystemCategory(param).then(this.dealMerge)
          break;
        case '100000000000000000000000000000000005':
          MergeIssueMainType(param).then(this.dealMerge)
          break;
        case '100000000000000000000000000000000006':
          MergeServiceCategory(param).then(this.dealMerge)
          break;
        case '100000000000000000000000000000000014':
          MergeDeliverAddress(param).then(this.dealMerge)
          break;
      }
    },
    dealMerge (res) {
      if (res.success) {
        this.$message.success(this.$i18n.t('message.edit'))
        this.dialog.visible = false;
        this.$emit('update')
      }
      this.dialog.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.input-wrap .input-group {
  width: 100%;
}
</style>