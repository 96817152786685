<template>
  <div>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            :disabled="!organic"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>

      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        @selection-change="val => tableSelected = val"
        :data="tableData"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('newDevice.contractWarranty.col1')"
          prop="signingDate"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          :label="$t('newDevice.contractWarranty.col2')"
          prop="name"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('newDevice.contractWarranty.col3')"
          prop="projectType"
        >
        </el-table-column>
        <el-table-column
          min-width="170"
          sortable
          :label="$t('newDevice.contractWarranty.col4')"
          prop="quotationNumber"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          :label="$t('newDevice.contractWarranty.col5')"
          prop="serialNumber"
        >
          <template slot-scope="scope">
            {{scope.row.contractStartDate}} - {{scope.row.contractEndDate}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('newDevice.contractWarranty.col6')"
          prop="state"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.state === 1"
              size="small"
            >{{$t('newDevice.contractWarranty.tag1')}}</el-tag>
            <el-tag
              v-else-if="scope.row.state === 2"
              size="small"
              type="success"
            >{{$t('newDevice.contractWarranty.tag2')}}</el-tag>
            <el-tag
              v-else-if="scope.row.state === 3"
              size="small"
              type="danger"
            >{{$t('newDevice.contractWarranty.tag3')}}</el-tag>
            <el-tag
              v-else-if="scope.row.state === 4"
              size="small"
              type="info"
            >{{$t('newDevice.contractWarranty.tag4')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('newDevice.contractWarranty.col7')"
          prop="warrantyTypeName"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <contract-dialog
      :visible.sync="ContractDialogVisible"
      :organic="organic"
      @submit="submitContract"
    ></contract-dialog>
  </div>
</template>

<script>
// 组件
import ContractDialog from './ContractDialog'

export default {
  components: {
    ContractDialog,
  },
  props: ['data', 'organic'],
  data () {
    return {
      ContractDialogVisible: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'data': function () {
      let toDay = new Date().getTime()
      this.tableData = this.data.map(item => {
        let start = new Date(item.contractStartDate).getTime();
        let end = new Date(item.contractEndDate).getTime();
        if (item.contractStartDate === null) {
          item.state = 4;
        } else if (toDay < start) {
          item.state = 1;
        } else if (toDay < end) {
          item.state = 2;
        } else if (end < toDay) {
          item.state = 3;
        }
        return item;
      });
    }
  },
  methods: {
    showAdd () {
      this.ContractDialogVisible = true;
    },
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let ids = this.tableSelected.map(item => item.id)
        this.tableData = this.tableData.filter(item => !ids.includes(item.id));
      }).catch(() => { });
    },
    // 提交项目合同
    submitContract (data) {
      let ids = this.tableData.map(item => item.id);
      this.tableData = this.tableData.concat(data.filter(item => !ids.includes(item.id)));
    },

  }
}
</script>

<style>
</style>