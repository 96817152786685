<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <div class="left-box">
          <el-date-picker
            size="medium"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            unlink-panels
            value-format="yyyy-MM-dd"
            @change="searchData"
          >
          </el-date-picker>
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>

        <div class="right-box">
          <span>状态</span>
          <el-select
            size="medium"
            v-model="status"
            @change="searchData"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in $global.deviceStates"
              :key="index"
              :value="index"
              :label="item"
            ></el-option>
          </el-select>
        </div>
      </div>
    </el-row>
    <el-row>
      <el-button-group class="input-group">
        <el-button
          size="medium"
          @click="showLogistics"
        >{{$t('purchaseRequisition.stateTracking.btn1')}}</el-button>
        <el-button
          :disabled="signState"
          size="medium"
          @click="showSignIn"
        >{{$t('purchaseRequisition.stateTracking.btn2')}}</el-button>
        <el-button
          size="medium"
          @click="showStatus"
        >{{$t('purchaseRequisition.stateTracking.btn3')}}</el-button>
      </el-button-group>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="status"
          :label="$t('purchaseRequisition.stateTracking.col1')"
        >
          <template slot-scope="scope">
            {{$global.deviceStates[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          :label="$t('purchaseRequisition.stateTracking.col2')"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.approvalStatus === null || scope.row.approvalStatus === 0">待审批</div>
            <div v-else>
              <div>{{scope.row.approvalSymbol}}</div>
              <div>{{scope.row.opName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="typeName"
          :label="$t('purchaseRequisition.stateTracking.col3')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="deviceName"
          :label="$t('purchaseRequisition.stateTracking.col4')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="brandName"
          :label="$t('purchaseRequisition.stateTracking.col5')"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="deviceModel"
          :label="$t('purchaseRequisition.stateTracking.col6')"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          prop="count"
          :label="$t('purchaseRequisition.stateTracking.col7')"
        >
        </el-table-column>
        <el-table-column
          min-width="160"
          sortable
          prop="orderCount"
          :label="$t('purchaseRequisition.stateTracking.col8')"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          :label="$t('purchaseRequisition.stateTracking.col9')"
        >
          <template slot-scope="scope">
            <span v-text="scope.row.count - scope.row.orderCount < 0 ? 0:scope.row.count - scope.row.orderCount"></span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="170"
          sortable
          prop="arrivalCount"
          :label="$t('purchaseRequisition.stateTracking.col10')"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          prop="inventoryCount"
          :label="$t('purchaseRequisition.stateTracking.col11')"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          prop="preCount"
          :label="$t('purchaseRequisition.stateTracking.col12')"
        >
        </el-table-column>
        <el-table-column
          min-width="300"
          sortable
          prop="inCount"
          :label="$t('purchaseRequisition.stateTracking.col13')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="outCount"
          :label="$t('purchaseRequisition.stateTracking.col14')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="outCount2"
          :label="$t('purchaseRequisition.stateTracking.col15')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="outCount3"
          :label="$t('purchaseRequisition.stateTracking.col16')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="receiptCount"
          :label="$t('purchaseRequisition.stateTracking.col17')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="lockCount"
          :label="$t('purchaseRequisition.stateTracking.col18')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="purchaseUser"
          :label="$t('purchaseRequisition.stateTracking.col19')"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="projectName"
          :label="$t('purchaseRequisition.stateTracking.col20')"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="quotationNumber"
          :label="$t('purchaseRequisition.stateTracking.col21')"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="250"
          sortable
          prop="applicationNumber"
          :label="$t('purchaseRequisition.stateTracking.col22')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="orderNumber"
          :label="$t('purchaseRequisition.stateTracking.col23')"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 物流弹框 -->
    <logistics-dialog
      :visible.sync="LogisticsDialogVisible"
      :id="deviceInfoId"
    ></logistics-dialog>
    <!-- 签收弹框 -->
    <sign-in-dialog
      :visible.sync="SignInDialogVisible"
      :data="signInData"
      :type="signInType"
      @update="getTableData"
    ></sign-in-dialog>
    <!-- 批量签收弹框 -->
    <batch-sign-in-dialog
      :visible.sync="BatchSignInDialogVisible"
      :data="signInData"
      @update="getTableData"
    ></batch-sign-in-dialog>

    <!-- 状态详情 -->
    <state-details
      :visible.sync="StateDetailsVisible"
      :id="deviceInfoId"
    ></state-details>
  </div>
</template>

<script>
// 插件
import axios from 'axios'
// 组件
import SignInDialog from '@/pages/warehouse/inventoryManagement/components/SignInDialog'
import BatchSignInDialog from '@/pages/warehouse/inventoryManagement/components/BatchSignInDialog'
import LogisticsDialog from './LogisticsDialog'
import StateDetails from './StateDetails'

// 接口
import {
  GetApplyDeviceList,
} from '@/api/warehouse/purchaseRequisition'

export default {
  components: {
    SignInDialog,
    LogisticsDialog,
    StateDetails,
    BatchSignInDialog,
  },
  props: ['active'],
  data () {
    return {
      permit: {
        search: true,
        add: true,

        delAll: true,
        delSelf: true,
        resubmit: true,
        request: true,
      },
      SignInDialogVisible: false,
      BatchSignInDialogVisible: false,
      LogisticsDialogVisible: false,
      StateDetailsVisible: false,

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      dateRange: null,
      searchVal: '',
      status: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      signInData: null,
      signInType: null,
      deviceInfoId: null,

      source: null,

    }
  },
  computed: {
    signState () {
      if (this.tableSelected.length === 0) return true;

      return this.tableSelected.some(item => item.status === 13)
    },
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab2') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
        // this.$refs.table.doLayout();
      }
    },
    '$route': function (to) {
      if (to.name === 'PurchaseRequisition' && this.active === 'tab2') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    }
  },
  created () {
    this.dealPermission();

  },
  mounted () {

  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        startDate: this.dateRange !== null ? this.dateRange[0] + ' 00:00:00' : '',
        endDate: this.dateRange !== null ? this.dateRange[1] + ' 23:59:59' : '',
        statusList: this.status,
      }
      this.tableLoading = true;
      // GetApplyDeviceList(param).then(res => {
      //   this.tableData = res.list;
      //   this.page.total = res.total;
      //   this.tableLoading = false;
      //   if (typeof fun === 'function') fun();
      // }).catch(error => {
      //   this.tableLoading = false;
      // })

      // 中断请求
      if (!!this.source) {
        this.source.cancel('interrupt');
      }
      let CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      axios({
        url: process.env.VUE_APP_BASE_URL + '/WarehousePurchaseApplicationFormDeviceInfo/queryByPage.do',
        method: 'post',
        data: JSON.stringify(param),
        headers: {
          token: this.$cookies.get('token') || '',
          'content-type': 'application/json;charset=UTF-8',
        },
        cancelToken: this.source.token
      }).then(response => {
        let res = response.data;
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示修改
    dblclickEdit (e) {
      this.$router.push({ path: '/home/allocateGoods', query: { id: e.applicationFormId, type: 2 } })
    },
    // 显示签收弹框
    showSignIn () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')

      if (this.tableSelected.length > 1) {
        this.signInData = this.tableSelected;
        this.BatchSignInDialogVisible = true;
      } else {
        this.signInType = 2;
        this.signInData = this.tableSelected[0];
        this.SignInDialogVisible = true;
      }
    },
    // 显示物流弹框
    showLogistics () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.deviceInfoId = this.tableSelected[0].id;
      this.LogisticsDialogVisible = true;
    },
    // 显示状态弹框
    showStatus () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.deviceInfoId = this.tableSelected[0].id;
      this.StateDetailsVisible = true;
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },

    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        // this.permit.search = permission.includes('PurchaseRequisitionvSearch');

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-wrap {
  justify-content: space-between;
  .el-date-editor {
    margin-right: 10px;
  }
}

.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 46px - 42px);
}
.cell-style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left-box {
  display: flex;
}
</style>