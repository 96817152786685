<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('addOrder.title1')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <div class="content">
        <div class="top-box">
          <div class="number-box">
            <el-divider content-position="left">{{$t('addOrder.subtitle1')}}</el-divider>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>可用库存/预分配/已入库</label>
              </el-col>
              <el-col
                :span="12"
                class="display-flex"
              >
                <el-input
                  size="small"
                  disabled
                  v-model="inventoryCount"
                ></el-input>
                <el-input
                  size="small"
                  disabled
                  v-model="allPreCount"
                ></el-input>
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.inCount"
                ></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label4')}}</label>
              </el-col>
              <el-col
                :span="12"
                class="display-flex"
              >
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.outCount"
                ></el-input>
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.outCount2"
                ></el-input>
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.outCount3"
                ></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>借调补仓</label>
              </el-col>
              <el-col :span="12">
                <el-radio-group v-model="isSecondment">
                  <el-radio :label="1">Yes</el-radio>
                  <el-radio :label="0">No</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label1')}}</label>
              </el-col>
              <el-col :span="12">
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.appCount"
                ></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>已预分配库存数量</label>
              </el-col>
              <el-col
                :span="12"
                class="display-flex"
              >
                <el-input
                  size="small"
                  disabled
                  v-model="preCount"
                ></el-input>
                <el-input-number
                  size="small"
                  :controls="false"
                  v-model="number"
                  :min="0"
                  :max="maxPreCount"
                ></el-input-number>
                <el-button
                  size="small"
                  @click="okClick"
                >OK</el-button>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label2')}}</label>
              </el-col>
              <el-col :span="12">
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.stockout"
                ></el-input>
              </el-col>
            </el-row> -->
            <!-- <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label3')}}</label>
              </el-col>
              <el-col :span="12">
                <el-input
                  size="small"
                  disabled
                  v-model="currentRow.inventoryCount"
                ></el-input>
              </el-col>
            </el-row> -->

            <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label5')}}</label>
              </el-col>
              <el-col :span="12">
                <el-input
                  size="small"
                  disabled
                  v-model="orderCount"
                ></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>待订货数量</label>
              </el-col>
              <el-col :span="12">
                <el-input
                  size="small"
                  disabled
                  v-model="unorderCount"
                ></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label6')}}</label>
              </el-col>
              <el-col :span="12">
                <el-input-number
                  class="bg-success"
                  size="small"
                  :controls="false"
                  :max="numberMax"
                  :min="0"
                  v-model="count"
                ></el-input-number>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>{{$t('addOrder.label7')}}</label>
              </el-col>
              <el-col
                :span="12"
                class="display-flex"
              >
                <el-input-number
                  class="bg-warning"
                  size="small"
                  v-model="singlePrice"
                  :controls="false"
                  :min="0"
                ></el-input-number>
                <el-select
                  size="small"
                  v-model="singlePriceUnit"
                  filterable
                >
                  <el-option
                    v-for="item in $global.currencysData"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <label>单位</label>
              </el-col>
              <el-col :span="12">
                <el-select
                  v-model="unit"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in units"
                    :key="item.id"
                    :value="item.name"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col
                :span="12"
                :offset="12"
              >
                <el-button
                  class="full-width"
                  size="small"
                  @click="showAdd"
                >确认并新增</el-button>
              </el-col>
            </el-row>
          </div>
          <div class=" table-box fill-height">
            <el-divider content-position="left">{{$t('addOrder.subtitle2')}}</el-divider>
            <el-row>
              <div class="input-wrap">
                <el-button-group class="input-group">
                  <el-input
                    v-model="searchVal"
                    placeholder="Search"
                    class="search-input"
                    size="medium"
                    clearable
                  ></el-input>
                  <el-button
                    size="medium"
                    @click="searchData"
                  >{{$t('operation.search')}}</el-button>
                  <el-button
                    size="medium"
                    @click="showReplace"
                  >替换</el-button>
                  <el-button
                    size="medium"
                    @click="showHistoricalPrice"
                  >查看历史价格</el-button>
                </el-button-group>
              </div>
            </el-row>
            <el-row
              class="table-row"
              ref="tableRow"
            >
              <el-table
                :data="tableData"
                :height="tableHeight"
                v-loading="tableLoading"
                highlight-current-row
                @current-change="handleCurrentChange"
              >
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col1')"
                  prop="typeName"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col2')"
                  prop="deviceName"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col3')"
                  prop="brandName"
                >
                </el-table-column>
                <el-table-column
                  min-width="180"
                  sortable
                  :label="$t('addOrder.table1.col4')"
                  prop="deviceModel"
                >
                </el-table-column>
                <el-table-column
                  min-width="190"
                  sortable
                  :label="$t('addOrder.table1.col5')"
                  prop="count"
                >
                </el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  :label="$t('addOrder.table1.col6')"
                  prop="orderCount"
                >
                </el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  label="预分配库存"
                  prop="preCount"
                ></el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  label="出库1"
                  prop="outCount"
                ></el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  label="出库2"
                  prop="outCount2"
                ></el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  label="出库3"
                  prop="outCount3"
                ></el-table-column>
                <el-table-column
                  min-width="230"
                  sortable
                  :label="$t('addOrder.table1.col7')"
                  prop="applicationNumber"
                >
                </el-table-column>
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('addOrder.table1.col8')"
                  prop="projectName"
                  show-overflow-tooltip
                >
                </el-table-column>

              </el-table>
            </el-row>
          </div>
        </div>
        <div class="bottom-box">
          <el-row>
            <div class="input-wrap">
              <div class="btn-wrap">
                <el-button-group class="input-group">
                  <el-input
                    v-model="searchVal2"
                    placeholder="Search"
                    class="search-input"
                    size="medium"
                    clearable
                  ></el-input>
                  <el-button
                    size="medium"
                    @click="searchData2"
                  >{{$t('operation.search')}}</el-button>
                  <el-button
                    size="medium"
                    @click="showEdit"
                  >{{$t('operation.edit')}}</el-button>
                  <el-button
                    :disabled="isMerge"
                    size="medium"
                    @click="delItem"
                  >{{$t('operation.del')}}</el-button>
                  <el-button size="medium">Merge Same Items</el-button>
                </el-button-group>
                <div class="switch-box">
                  <!-- <label>{{$t('newOrder.orderForm.label18')}}</label> -->
                  <el-switch
                    v-model="isMerge"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="mergeChange"
                  ></el-switch>
                </div>
              </div>

              <my-page
                layout="prev, pager, next, jumper"
                :total="page.total"
                :page-size.sync="page.size"
                :current-page.sync="page.current"
                @current-change="getTableData"
                @size-change="getTableData"
              >
              </my-page>
            </div>
          </el-row>
          <el-row
            class="order-row"
            ref="orderRow"
          >
            <el-table
              :data="orderData"
              :height="orderHeight"
              :row-class-name="tableRowClassName"
              @selection-change="val => orderSelected = val"
              @row-dblclick="dblclickEdit"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col1')"
                prop="typeName"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col2')"
                prop="deviceName"
              >
              </el-table-column>

              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col3')"
                prop="brandName"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col4')"
                prop="deviceModel"
              >
              </el-table-column>
              <el-table-column
                min-width="120"
                :label="$t('addOrder.table2.col5')"
                prop="count"
              >
              </el-table-column>
              <el-table-column
                min-width="100"
                :label="$t('addOrder.table2.col6')"
                prop="singlePrice"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col7')"
                prop="remark"
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.remark"></div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="200"
                :label="$t('addOrder.table2.col8')"
                prop="expecteBuyBackTime"
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.expecteBuyBackTime"></div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="210"
                :label="$t('addOrder.table2.col9')"
                prop="applicationNumber"
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.applicationNumber"></div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col10')"
                prop="projectName"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="$t('addOrder.table2.col11')"
                prop="purchaseUser"
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.purchaseUser"></div>
                </template>
              </el-table-column>

            </el-table>
          </el-row>
        </div>
      </div>
    </el-card>

    <!--  -->
    <number-dialog
      :visible.sync="NumberDialogVisible"
      :data="currentOrder"
      :total="orderTotal"
      @submit="numberSubmit"
    ></number-dialog>
    <!-- 价格弹框 -->
    <price-dialog :visible.sync="PriceDialogVisible"></price-dialog>
    <!-- 替换弹框 -->
    <replace-dialog
      :visible.sync="ReplaceDialogVisible"
      :application-form-id="currentRow.applicationFormId"
      :data="currentRow"
      @update="getTableData"
    ></replace-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import NumberDialog from './components/NumberDialog2'
import PriceDialog from './components/PriceDialog'
import ReplaceDialog from "@/pages/warehouse/inventoryManagement/components/ReplaceDialog";
// 接口
import {
  GetApplyDeviceList,
  GetReceiptList,
} from '@/api/warehouse/purchaseRequisition'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import { GetReceiptContactList } from '@/api/warehouse/dataMaintenance/receiptAddress'

export default {
  name: 'AddOrder',
  components: {
    PageHeader,
    NumberDialog,
    PriceDialog,
    ReplaceDialog,
  },
  data () {
    return {
      NumberDialogVisible: false,
      PriceDialogVisible: false,
      ReplaceDialogVisible: false,
      title: '',

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableHeight: null,
      tableData: [],

      searchVal2: '',
      orderHeight: null,
      orderSelected: [],
      orderData: [],

      count: 0,
      singlePrice: 0,
      singlePriceUnit: 1,
      orderCount: 0,
      numberMax: 0,

      isSecondment: 0,
      inventoryCount: 0,
      allPreCount: 0,
      preCount: 0,
      unorderCount: 0,
      number: 0,
      maxPreCount: 0,

      currentRow: {},
      currentOrder: {},
      orderTotal: 0,
      isMerge: false,

      units: [],
      unit: '',
    }
  },
  // watch: {
  //   'preCount': function (newVal, oldVal) {
  //     this.unorderCount = this.unorderCount + oldVal - newVal;
  //     this.count = this.count + oldVal - newVal;
  //   }
  // },
  created () {
    // 获取单位
    GetDataFieldAll({ parent: '100000000000000000000000000000000011' }).then(res => this.units = res)

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.orderHeight = this.$refs.orderRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        applicationFormId: '',
        applicationFormNumber: '',
        all: 1,
        isAbnormal: '1',
      };
      this.tableLoading = true;
      GetApplyDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      });
    },
    searchData () {
      this.page.current = 1;
      this.getTableData()
    },
    searchData2 () {
      // this.page.current = 1;
      // this.getTableData()
    },
    // 
    handleCurrentChange (val) {
      if (!val) return this.currentRow = {}
      this.currentRow = val;
      this.currentRow.appCount = this.currentRow.count;
      this.currentRow.stockout = this.currentRow.count > this.currentRow.inventoryCount
        ? this.currentRow.count - this.currentRow.inventoryCount
        : 0

      this.isSecondment = 0;
      this.inventoryCount = this.currentRow.inventoryCount;
      this.allPreCount = this.currentRow.allPreCount;
      this.preCount = this.currentRow.preCount;
      this.number = 0;

      this.orderCount = this.currentRow.orderCount + this.getTotal(this.currentRow.id)
      // this.numberMax = this.currentRow.count - this.orderCount - this.currentRow.outCount;
      this.numberMax = this.currentRow.count - this.orderCount - this.preCount;
      this.count = this.numberMax;
      this.singlePrice = this.currentRow.singlePrice;
      this.singlePriceUnit = 1;

      this.unorderCount = this.currentRow.appCount - this.orderCount - this.preCount;
      this.maxPreCount = this.currentRow.inventoryCount + this.currentRow.preCount

      this.unit = this.currentRow.unit
    },
    // 
    showAdd () {
      if (!this.currentRow.appCount) return this.$message.warning('Please select one item.')
      if (this.count == 0) return;

      if (this.isMerge) {
        this.isMerge = false;
        this.mergeChange();
        this.isMerge = true;
      }
      let order = this.orderData.find(item => item.applicationFormInfoId === this.currentRow.id && item.singlePrice == this.singlePrice && item.singlePriceUnit == this.singlePriceUnit)
      let data = JSON.parse(JSON.stringify(this.currentRow));
      data.applicationFormInfoId = data.id;
      data.preCount = this.preCount;
      data.allPreCount = this.allPreCount;
      data.id = null;
      data.isSecondment = this.isSecondment;
      data.unit = this.unit;
      data.deliveryInfos = [];


      if (!!order) {
        order.count += this.count;

      } else {
        data.count = this.count;
        data.singlePrice = this.singlePrice;
        data.singlePriceUnit = this.singlePriceUnit;
        data.oldData = {
          count: data.oldData,
          detailed: data.detailed,
          remark: data.remark,
        }
        this.getReceiptList(data);

        this.orderData.push(data);
      }

      this.orderCount = this.orderCount + this.count;
      this.numberMax = this.numberMax - this.count;
      this.count = this.count > this.numberMax ? this.numberMax : this.count;

      if (this.isMerge) {
        this.mergeChange();
      }

    },
    showEdit () {
      if (this.orderSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.orderSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.dblclickEdit(this.orderSelected[0]);
    },
    dblclickEdit (e) {
      this.orderTotal = this.getTotal(e.applicationFormInfoId);
      this.currentOrder = e;
      this.NumberDialogVisible = true;
    },
    numberSubmit (data) {
      let item = JSON.parse(JSON.stringify(this.currentOrder));
      item.count = data.count;
      item.singlePrice = data.singlePrice;
      item.addressId = data.addressId;
      item.consigneeId = data.consigneeId;
      item.detailed = data.detailed;
      item.remark = data.remark;
      this.$set(this.orderData, this.currentOrder.rowIndex, item)

      if (data.id === this.currentRow.id) {
        this.handleCurrentChange(this.currentRow);
      }
    },
    delItem () {
      if (this.orderSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.orderSelected.map(item => item.rowIndex);
        this.orderData = this.orderData.filter(item => !checkIndex.includes(item.rowIndex));

        this.handleCurrentChange(this.currentRow);
      }).catch(() => { });
    },
    submit () {
      sessionStorage.orderData = JSON.stringify(this.orderData);
      this.back();
    },
    getTotal (_id) {
      let total = 0;
      this.orderData.forEach(item => {
        if (item.applicationFormInfoId === _id) {
          total += item.count;
        }
      })
      return total;
    },
    // 合并开关
    mergeChange () {
      if (this.isMerge) {
        let data = {};
        let tempData = [];
        this.originData = JSON.parse(JSON.stringify(this.orderData));
        this.orderData.forEach(item => {
          if (!data[item.deviceInfoId + item.singlePrice + item.singlePriceUnit]) {
            data[item.deviceInfoId + item.singlePrice + item.singlePriceUnit] = [item]
          } else {
            data[item.deviceInfoId + item.singlePrice + item.singlePriceUnit].push(item);
          }
        })
        for (var i in data) {
          let temp = data[i][0];
          temp.expecteBuyBackTime = temp.expecteBuyBackTime || '';
          for (var j = 1; j < data[i].length; j++) {
            temp.count += parseInt(data[i][j].count);
            // temp.singlePrice += `<br>${data[i][j].singlePrice}`;
            temp.remark += `<br>${data[i][j].remark}`;
            temp.expecteBuyBackTime += `<br>${data[i][j].expecteBuyBackTime || ''}`;
            temp.applicationNumber += `<br>${data[i][j].applicationNumber}`;
            temp.purchaseUser += `<br>${data[i][j].purchaseUser}`;
          }
          tempData.push(temp);
        }
        this.orderData = tempData;
      } else {
        this.orderData = this.originData;
      }
    },
    okClick () {
      this.unorderCount = this.unorderCount + this.preCount - this.number;
      this.unorderCount = this.unorderCount < 0 ? 0 : this.unorderCount;
      this.numberMax = this.numberMax + this.preCount - this.number;
      this.count = this.count > this.numberMax ? this.numberMax : this.count;

      this.inventoryCount = this.inventoryCount + this.preCount - this.number;
      this.allPreCount = this.allPreCount - this.preCount + this.number;
      this.preCount = this.number;
      this.number = 0;
    },

    // 显示价格弹框
    showHistoricalPrice () {
      this.PriceDialogVisible = true;
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    // 显示替换
    showReplace () {
      if (!this.currentRow.id) return this.$message.warning('Please select one item.')

      this.ReplaceDialogVisible = true;
    },
    // 获取收货信息
    getReceiptList (data) {
      if (!data.applicationFormInfoId) return;

      GetReceiptList({ appFormDeviceId: data.applicationFormInfoId }).then(res => {
        data.deliveryInfos = res.map(item => {
          item.checked = false;
          item.recipients = [];
          item.id = '';
          return item;
        });
        data.deliveryInfos.forEach(item => {
          this.addressChange(item, false)
        })
      })
    },
    addressChange (data, reset) {
      if (!/.{8}(-.{4}){3}-.{12}/.test(data.addressId)) {
        data.consigneeId = '';
        data.recipients = [];
        return
      }
      if (reset) {
        data.consigneeId = '';
      }
      let param = {
        limit: 9999,
        page: 1,
        search: '',
        addressId: data.addressId,
      }
      GetReceiptContactList(param).then(res => data.recipients = res.list)
    },
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
label {
  width: 100%;
  height: 32px;
  line-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex {
  display: flex;

  .el-select:first-child,
  .el-button:first-child,
  .el-input:first-child,
  .el-input-number:first-child {
    margin-left: 0px;
  }
  .el-select,
  .el-button,
  .el-input,
  .el-input-number {
    margin-left: 10px;
    flex: 1;
    padding: 0;
  }
}
.content {
  height: calc(100% - 36px - 20px);
}
.top-box {
  height: 65%;
  display: flex;
}
.bottom-box {
  height: 35%;
}
.fill-height {
  height: 100%;
}
.table-row {
  height: calc(100% - 46px - 49px - 10px);
}
.order-row {
  height: calc(100% - 46px);
}
.number-box {
  width: 380px;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 14px;
  .el-input-number,
  .el-input {
    flex: 1;
    width: 100%;
    ::v-deep .el-input__inner {
      padding: 0 5px;
      text-align: center;
    }
  }
  .el-button {
    margin-left: 10px;
  }
}
.table-box {
  width: calc(100% - 400px);
}

.el-radio-group {
  width: 100%;
  display: flex;
  label {
    margin-right: 10px;
  }
}
.full-width {
  width: 100%;
  margin-left: 0 !important;
}
.btn-wrap {
  display: flex;
  flex-direction: row;
}
.switch-box {
  width: 70px;
  height: 36px;
  line-height: 36px;
  margin-left: 20px;
  label {
    font-size: 14px;
    margin-right: 10px;
  }
}
.bg-success {
  ::v-deep input {
    background-color: #caf982;
  }
}
.bg-warning {
  ::v-deep input {
    background-color: #facd91;
  }
}
</style>