<template>
  <div class="wrap">
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('inventoryManagement.tab1')"
          name="inventory"
        >
          <inventory :active="activeName"></inventory>
        </el-tab-pane>
        <el-tab-pane
          label="状态跟踪"
          name="orderStatus"
        >
          <order-status
            type="inventory"
            :active="activeName"
          ></order-status>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('inventoryManagement.tab2')"
          name="inWarehouse"
        >
          <in-warehouse :active="activeName"></in-warehouse>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('inventoryManagement.tab3')"
          name="outWarehouse"
        >
          <out-warehouse :active="activeName"></out-warehouse>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Inventory from './components/Inventory'
import InWarehouse from './components/InWarehouse'
import OutWarehouse from './components/OutWarehouse'
import OrderStatus from '@/pages/warehouse/purchaseOrders/components/OrderStatus'


export default {
  name: 'InventoryManagement',
  components: {
    Inventory,
    InWarehouse,
    OutWarehouse,
    OrderStatus,
  },
  data () {
    return {
      
      activeName: 'inventory'
      // activeName: 'orderStatus'
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
</style>