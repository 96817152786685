<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
      >
        <el-form-item
          :label="$t('newDevice.label15')"
          prop="installedDate"
        >
          <el-date-picker
            v-model="dialog.installedDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label16')"
          prop="acceptanceDate"
        >
          <el-date-picker
            v-model="dialog.acceptanceDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label17')"
          prop="remark"
        >
          <el-input
            v-model="dialog.remark"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { AddProjectRecord } from '@/api/warehouse/deviceManagement'

export default {
  props: ['visible', 'data', 'deviceId'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '项目记录',
        installedDate: null,
        acceptanceDate: null,
        remark: '',
      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col1'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              installedDate: this.data.installedDate,
              acceptanceDate: this.data.acceptanceDate,
              remark: this.data.remark,
            })
          })
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          deviceId: this.deviceId,
          installedDate: this.dialog.installedDate,
          acceptanceDate: this.dialog.acceptanceDate,
          remark: this.dialog.remark,
        }


        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddProjectRecord(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
</style>