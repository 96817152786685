<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
        <col-set @update="getTableData"></col-set>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>

        <el-table-column
          min-width="150"
          sortable
          prop="name"
          :label="$t('dataAccessGroup.label1')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="description"
          :label="$t('dataAccessGroup.label2')"
        >
        </el-table-column>
        <el-table-column
          min-width="170"
          sortable
          prop="accessGroupName"
          :label="$t('dataAccessGroup.label3')"
        >
        </el-table-column>
        <el-table-column
          min-width="120"
          sortable
          prop="createByName"
          :label="$t('dataAccessGroup.label4')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="createTime"
          :label="$t('dataAccessGroup.label5')"
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="updateTime"
          :label="$t('dataAccessGroup.label6')"
          :formatter="$formatTableData"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
  </div>
</template>

<script>
import ColSet from '@/components/ColSet'

import {
  GetDataAccessGroupList,
  DelDataAccessGroup,
} from '@/api/organizations'

export default {
  components: {
    ColSet,
  },
  props: ['active'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        // edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.path === '/home/organizations' && from.path === '/home/newDataAccessGroup') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
    'active': function (newVal) {
      if (newVal === 'DataAccessGroups') {
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tableRow.$el.clientHeight
          this.page.size = Math.floor(this.tableHeight / 35);
          this.getTableData();
          this.$refs.table.doLayout();
        })
      }
    }
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      };
      this.tableLoading = true;
      GetDataAccessGroupList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;

        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增
    showAdd () {
      this.$router.push({ path: '/home/newDataAccessGroup' })
    },
    // 显示编辑
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.$router.push({ path: '/home/newDataAccessGroup', query: { id: this.tableSelected[0].id } })
    },
    dblclickEdit (item) {
      this.$router.push({ path: '/home/newDataAccessGroup', query: { id: item.id } })
    },
    // 删除 
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(','),
        }
        DelDataAccessGroup(param).then(res => {
          this.getTableData()
          this.$message.success('deleted successfully.')
        }).catch(error => {

        })
      }).catch(() => { });

    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.search = permission.includes('AccessGroupsSearchDataAccessGroups');
        this.permit.add = permission.includes('AccessGroupsAddDataAccessGroups');
        // this.permit.edit = permission.includes('AccessGroupsEditDataAccessGroups');
        this.permit.del = permission.includes('AccessGroupsDeleteDataAccessGroups');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>