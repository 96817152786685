<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!this.groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="180px"
      >
        <el-divider content-position="left">{{$t('newProjectContract.subtitle1')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label1')"
              prop="projectName"
            >
              <el-input v-model="groupForm.projectName"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newProjectContract.label2')"
              prop="projectType"
            >
              <my-select
                :data="projectTypes"
                :value.sync="groupForm.projectType"
                :prop="{
                  label:'name',
                  value:'id'
                }"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newProjectContract.label3')"
              prop="signingDate"
            >
              <el-date-picker
                unlink-panels
                v-model="groupForm.signingDate"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label4')"
              prop="offerCode"
            >
              <el-input v-model="groupForm.offerCode"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newProjectContract.label5')"
              prop="company"
            >
              <el-button-group class="input-group">
                <el-select
                  v-model="groupForm.company"
                  filterable
                  default-first-option
                  class="search-input"
                  @change="companyChnage"
                >
                  <el-option
                    v-for="item in companys"
                    :key="item.id"
                    :value="item.id"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
                <el-button
                  size="medium"
                  @click="showCompany"
                >New</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newProjectContract.label10')"
              prop="remark"
            >
              <el-input v-model="groupForm.remark"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label6')"
              prop="dataAccessGroup"
            >
              <el-select
                v-model="groupForm.dataAccessGroup"
                filterable
                disabled
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('newProjectContract.subtitle2')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label7')"
              prop="projectDate"
            >
              <el-date-picker
                unlink-panels
                v-model="groupForm.projectDate"
                type="daterange"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

        </el-row>
        <el-divider content-position="left">SLA</el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label11')"
              prop="slaPolicy"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.slaPolicy"
                  class="search-input"
                  disabled
                ></el-input>
                <!-- <el-button @click="ApplyDialogVisible = true">{{$t("operation.search")}}</el-button> -->
                <el-button @click="showsla">{{$t("operation.search")}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label12')"
              prop="slaType"
            >
              <el-input
                disabled
                v-model="groupForm.slaTypeName"
              ></el-input>
              <!-- <el-select
                v-model="groupForm.slaType"
              >
                <el-option
                  v-for="item in slaTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('newProjectContract.subtitle3')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label8')"
              prop="contractDate"
            >
              <el-date-picker
                unlink-panels
                v-model="groupForm.contractDate"
                type="daterange"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label13')"
              prop="maintenanceDate"
            >
              <el-date-picker
                unlink-panels
                v-model="groupForm.maintenanceDate"
                type="daterange"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newProjectContract.label14')"
              prop="maintenanceDate"
            >
              <el-select
                v-model="groupForm.warrantyType"
                filterable
              >
                <el-option
                  v-for="item in warrantyTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('newProjectContract.subtitle4')}}</el-divider>
        <el-row>
          <div class="input-wrap">
            <div class="left-box">
              <el-button-group class="input-group">
                <el-button
                  size="medium"
                  @click="showAdd"
                >{{$t('operation.add')}}</el-button>
                <el-button
                  size="medium"
                  @click="showEdit"
                >{{$t('operation.edit')}}</el-button>
                <el-button
                  size="medium"
                  @click="delItem"
                >{{$t('operation.del')}}</el-button>
              </el-button-group>
              <span class="tips">
                {{$t('newProjectContract.tips1')}}
              </span>
            </div>
          </div>

        </el-row>
        <el-row>
          <el-table
            border
            ref="table"
            :data="tableData"
            :row-class-name="tableRowClassName"
            v-loading="tableLoading"
            @selection-change="val => tableSelected = val"
            @row-dblclick="dblclickEdit"
            @row-click="rowClick"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              sortable
              min-width="130"
              :label="$t('newProjectContract.col1')"
              prop="org"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="130"
              :label="$t('newProjectContract.col9')"
              prop="orgType"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="190"
              :label="$t('newProjectContract.col2')"
              prop="maintenanceType"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="170"
              label="SLA状态"
              prop="state"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.state === 1"
                  size="small"
                >{{$t('newProjectContract.tag1')}}</el-tag>
                <el-tag
                  v-else-if="scope.row.state === 2"
                  size="small"
                  type="success"
                >{{$t('newProjectContract.tag2')}}</el-tag>
                <el-tag
                  v-else-if="scope.row.state === 3"
                  size="small"
                  type="danger"
                >{{$t('newProjectContract.tag3')}}</el-tag>
                <el-tag
                  v-else-if="scope.row.state === 4"
                  size="small"
                  type="info"
                >{{$t('newProjectContract.tag4')}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              min-width="140"
              :label="$t('newProjectContract.col11')"
              prop="maintenanceValidStartDate"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="140"
              :label="$t('newProjectContract.col12')"
              prop="maintenanceValidEndDate"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="130"
              show-overflow-tooltip
              :label="$t('newProjectContract.col5')"
              prop="contact"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="130"
              :label="$t('newProjectContract.col6')"
              prop="telephoneNumber"
            >
            </el-table-column>
            <el-table-column
              sortable
              min-width="130"
              :label="$t('newProjectContract.col7')"
              prop="address"
            >
            </el-table-column>

          </el-table>
        </el-row>
      </el-form>

    </el-card>

    <!-- 网点弹框 -->
    <add-branch
      :visible.sync="AddBranchVisible"
      :data="branchData"
      :maintenance="groupForm.maintenanceDate"
      :company="groupForm.company"
      :sla-policy="groupForm.slaPolicy"
      :sla-policy-id="groupForm.slaPolicyId"
      :sla-type="groupForm.slaType"
      :sla-type-name="groupForm.slaTypeName"
      :table-data="tableData"
      :customer-name="customerName"
      @submit="branchSubmit"
    ></add-branch>
    <!-- sla 弹框 -->
    <sla-dialog
      :visible.sync="SlaDialogVisible"
      @submit="slaSubmit"
    ></sla-dialog>
    <!--  弹框 -->
    <add-company
      :visible.sync="AddCompanyVisible"
      @submit="companySubmit"
    ></add-company>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
import PageHeader from '@/components/PageHeader'
import AddBranch from './components/AddBranch'
import SlaDialog from './components/SlaDialog'
import AddCompany from './components/AddCompany'

// 接口
import {
  GetProjectContractItem,
  AddProjectContract,
  GetContractBranch,
  DelContractBranch,
} from '@/api/warehouse/projectContract'
import { GetDataAccessGroupAll } from '@/api/organizations'
import { GetStoreTree } from '@/api/stores'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    MySelect,
    PageHeader,
    AddBranch,
    SlaDialog,
    AddCompany,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      AddBranchVisible: false,
      SlaDialogVisible: false,
      AddCompanyVisible: false,
      title: '',
      projectTypes: [],
      companys: [],
      dataAccessGroups: [],
      warrantyTypes: [],
      groupForm: {
        id: null,
        loading: false,
        projectName: '',
        projectType: '',
        offerCode: '',
        company: '',
        oldCompany: '',
        remark: '',
        dataAccessGroup: localStorage.accessGroupId,
        projectDate: null,
        signingDate: null,
        contractDate: null,
        maintenanceDate: null,
        slaPolicy: '',
        slaPolicyId: '',
        slaType: '',
        slaTypeName: '',
        warrantyType: '',
      },
      rules: {
        projectName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        offerCode: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        company: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      branchData: null,

      slaTypes: [],
      customerName: null,
    }
  },
  created () {
    this.dealPermission()

    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newProjectContract.title2') : this.$i18n.t('newProjectContract.title5');
      this.getItem();
      this.getTableData()
    } else {
      this.title = this.$i18n.t('newProjectContract.title');
    }
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
    // 获取合同类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000008' }).then(res => this.projectTypes = res)
    // 获取客户 
    GetStoreTree({ type: '4' }).then(res => this.companys = res)
    // 获取sla类型
    // GetDataFieldAll({ parent: '100000000000000000000000000000000020' }).then(res => this.slaTypes = res)
    // 获取保修类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000027' }).then(res => this.warrantyTypes = res)
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      let param = { id: this.groupForm.id }
      GetProjectContractItem(param).then(res => {
        Object.assign(this.groupForm, {
          projectName: res.name,
          projectType: res.projectTypeId,
          offerCode: res.quotationNumber,
          company: res.customerId,
          oldCompany: res.customerId,
          dataAccessGroup: res.accessGroupId,
          projectDate: res.projectStartDate ? [res.projectStartDate, res.projectEndDate] : null,
          signingDate: res.signingDate,
          contractDate: res.contractStartDate ? [res.contractStartDate, res.contractEndDate] : null,
          maintenanceDate: res.maintenanceValidStartDate ? [res.maintenanceValidStartDate, res.maintenanceValidEndDate] : null,
          remark: res.remark,
          slaPolicyId: res.policyId,
          slaPolicy: res.slaName,
          warrantyType: res.warrantyTypeId,
          slaTypeName: res.slaTypeName,
        });

        this.customerName = res.customerName;
      })
    },
    // 获取合同网点
    getTableData () {
      let param = { projectContractId: this.groupForm.id }
      this.tableLoading = true;
      GetContractBranch(param).then(res => {
        let toDay = new Date().getTime()
        this.tableData = res.list.map(item => {
          let start = new Date(item.maintenanceValidStartDate).getTime();
          let end = new Date(item.maintenanceValidEndDate).getTime();
          if (item.maintenanceValidStartDate === null) {
            item.state = 4;
          } else if (toDay < start) {
            item.state = 1;
          } else if (toDay < end) {
            item.state = 2;
          } else if (end < toDay) {
            item.state = 3;
          }
          return item;
        });
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        if (this.tableData.length === 0) return this.$message.warning('Branch Site not Null');
        let param = {
          projectContract: {
            name: this.groupForm.projectName,
            quotationNumber: this.groupForm.offerCode,
            projectTypeId: this.groupForm.projectType,
            projectStartDate: this.groupForm.projectDate ? this.groupForm.projectDate[0] : null,
            projectEndDate: this.groupForm.projectDate ? this.groupForm.projectDate[1] : null,
            maintenanceValidStartDate: this.groupForm.maintenanceDate ? this.groupForm.maintenanceDate[0] : null,
            maintenanceValidEndDate: this.groupForm.maintenanceDate ? this.groupForm.maintenanceDate[1] : null,
            signingDate: this.groupForm.signingDate,
            contractStartDate: this.groupForm.contractDate ? this.groupForm.contractDate[0] : null,
            contractEndDate: this.groupForm.contractDate ? this.groupForm.contractDate[1] : null,
            accessGroupId: this.groupForm.dataAccessGroup,
            remark: this.groupForm.remark,
            policyId: this.groupForm.slaPolicyId,
            warrantyTypeId: this.groupForm.warrantyType,
          },
          projectContractBranches: this.tableData.map(item => {
            if (!/.{8}(-.{4}){3}-.{12}/.test(item.orgId)) {
              item.orgId = '';
            }
            let temp = {
              id: item.id,
              projectContractId: item.projectContractId,
              maintenanceTypeId: item.maintenanceTypeId,
              maintenanceValidStartDate: item.maintenanceValidStartDate,
              maintenanceValidEndDate: item.maintenanceValidEndDate,
              orgId: item.orgId,
              org: item.org,
              telephoneNumber: item.telephoneNumber,
              address: item.address,
              contact: item.contact,
              maintenanceType: item.maintenanceType,
              remark: item.remark,
              policyId: item.policyId,
              nodeId: item.nodeId,
              completionDate: item.completionDate,
            }
            // 
            if (!/.{8}(-.{4}){3}-.{12}/.test(item.orgTypeId) && !!item.orgTypeId) {
              temp.orgType = item.orgTypeId;
              temp.orgTypeId = '';
            } else {
              temp.orgTypeId = item.orgTypeId;
              temp.orgType = item.orgType;
            }

            return temp
          }),
        }
        // 验证是否是新项目类型
        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.projectType)) {
          param.projectContract.projectTypeId = this.groupForm.projectType;
        } else {
          param.projectContract.projectTypeName = this.groupForm.projectType;
          param.projectContract.projectTypeId = '';
        }
        // 验证是否是新客户
        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.company)) {
          param.projectContract.customerId = this.groupForm.company;
          param.projectContract.customerName = this.companys.find(item => item.id === this.groupForm.company).label;
        } else {
          param.projectContract.customerId = '';
          param.projectContract.customerName = this.groupForm.company;
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.projectContract.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddProjectContract(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          } else {
            if (res.status === 100) this.$message.warning('Group name is occupied.')
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 显示新增弹框
    showAdd () {
      this.branchData = null;
      this.AddBranchVisible = true;
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.branchData = e;
      this.AddBranchVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        let param = { id: this.tableSelected.map(item => item.id).join(',') }
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));

        if (param.id == '') return this.$i18n.t('message.del');
        DelContractBranch(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 网点提交
    branchSubmit ({ data, type }) {
      switch (type) {
        case 'add':
          this.tableData.push(data);
          break
        case 'edit':
          this.$set(this.tableData, data.rowIndex, data);
          break
      }
    },
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    // 显示sla弹框
    showsla () {
      this.SlaDialogVisible = true;
    },
    // sla提交
    slaSubmit (data) {
      this.groupForm.slaPolicy = data.name;
      this.groupForm.slaPolicyId = data.id;
      this.groupForm.slaType = data.type;
      this.groupForm.slaTypeName = data.typeName;
    },
    // 显示新客户弹框
    showCompany () {
      this.AddCompanyVisible = true;
    },
    // 提交客户
    companySubmit (data) {
      GetStoreTree({ type: '4' }).then(res => this.companys = res)
      this.groupForm.company = data;
      this.companyChnage();
    },
    companyChnage () {
      if (this.tableData.length === 0) return this.groupForm.oldCompany = this.groupForm.company;

      this.$confirm(this.$i18n.t('newProjectContract.tips1'), 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: this.tableData.map(item => item.id).join(',') }
        DelContractBranch(param);
        this.tableData = [];
      }).catch(() => {
        this.groupForm.company = this.groupForm.oldCompany;
      });

    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.edit = permission.includes('ProjectContractEdit');
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .el-select {
    width: 100%;
  }
  .select-table {
    margin-top: 20px;
    ::v-deep td,
    ::v-deep th {
      height: 35px;
      line-height: 35px;
      padding: 0;
    }
  }

  .el-date-editor {
    width: 100%;
  }

  .select-box {
    display: flex;
  }
}
.align-right {
  text-align: right;
}

.left-box {
  width: 100%;
  display: flex;
  .tips {
    color: #f56c6c;
    line-height: 36px;
    margin-left: 20px;
    font-size: 16px;
  }
  .input-group {
    width: auto;
  }
}
</style>