<template>
  <div class="wrap">
    <el-card>

      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              class="search-input"
              size="medium"
              placeholder="Search"
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >Search</el-button>
            <el-button
              size="medium"
              @click="DateDialogVisible = true"
            >Date</el-button>

          </el-button-group>
          <el-button size="medium">Clear all</el-button>
        </div>
      </el-row>
      <el-row>
        <el-table :data="tableData"
        @row-click="rowClick">
          <el-table-column
            sortable
            prop="date"
            label="Time"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Content"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="date"
            label="Type"
          >

            <template slot-scope="scope">
              {{scope.row.type == 0 ? 'Daily Data Sync':'Request for Approval'}}
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
    <!-- 时间弹框 -->
    <date-dialog
      :visible.sync="DateDialogVisible"
      @submitTimeRange="submitTimeRange"
      ref="dateDialog"
    ></date-dialog>
  </div>
</template>

<script>
import DateDialog from '@/components/DashBoardDialog/DateDialog'
export default {
  name: 'Notification',
  components: {
    DateDialog
  },
  data () {
    return {
      DateDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableData: [{ type: 0 }, { type: 1 }],
    }
  },
  created () {
    this.getTableData();

  },
  methods: {
    getTableData () {
      let param = {}

    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交时间
    submitTimeRange (dateRang, label) {
      this.DateDialogVisible = false;
    },
    // 行点击
    rowClick(e){
      if(e.type == 0){
        this.$router.push({path:'/home/dailyDataSync'})
      }else{
        this.$router.push({path:'/home/requestForApproval'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100%;
}

</style>