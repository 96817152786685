<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dataMaintenance.checkType.title1')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        label-position="left"
      >
        <el-form-item
          :label="$t('dataMaintenance.checkType.col2')"
          prop="item"
        >
          <el-input v-model="dialog.item"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.checkType.col3')"
          prop="checkType"
        >
          <el-input
            disabled
            v-model="dialog.checkType"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="itemType === 1 ||itemType === 5||itemType === 6"
          :label="$t('dataMaintenance.checkType.col5')"
          prop="checkType"
        >
          <el-input
            disabled
            v-model="dialog.optionInput"
          ></el-input>
        </el-form-item>
        <div v-if="itemType === 2 || itemType === 3">
          <div class="input-label">{{$t('dataMaintenance.checkType.col5')}}</div>
          <div
            class="input-box"
            v-for="(item,index) in dialog.options"
            :key="index"
          >
            <el-checkbox
              v-if="itemType === 3"
              disabled
            ></el-checkbox>
            <el-input
              size="small"
              v-model="item.name"
            ></el-input>
            <el-select
              size="small"
              v-model="item.isNormal"
            >
              <el-option
                label="Normal"
                :value="0"
              ></el-option>
              <el-option
                label="Abnormal"
                :value="1"
              ></el-option>
              <el-option
                label="N/A"
                :value="2"
              ></el-option>
            </el-select>
            <el-button
              :disabled="dialog.options.length === 2"
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="delRadio(index)"
            ></el-button>
            <el-button
              size="small"
              type="success"
              icon="el-icon-plus"
              @click="addRadio(index)"
            ></el-button>
          </div>
        </div>
        <div v-if="itemType === 4">
          <div class="input-label">{{$t('dataMaintenance.checkType.col5')}}</div>
          <div
            class="input-box"
            v-for="(item,index) in dialog.options"
            :key="index"
          >
            <el-radio disabled></el-radio>
            <el-input
              disabled
              size="small"
              v-model="item.name"
            ></el-input>
            <el-select
              size="small"
              v-model="item.isNormal"
            >
              <el-option
                label="Normal"
                :value="0"
              ></el-option>
              <el-option
                label="Abnormal"
                :value="1"
              ></el-option>
              <el-option
                label="N/A"
                :value="2"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div v-if="itemType === 7">
          <div class="input-label">{{$t('dataMaintenance.checkType.col5')}}</div>
          <div class="input-box">
            <el-input-number
              size="small"
              :max="dialog.end"
              :controls="false"
              v-model="dialog.start"
            ></el-input-number>
            <span>to</span>
            <el-input-number
              size="small"
              :max="dialog.max"
              :controls="false"
              v-model="dialog.end"
            ></el-input-number>
          </div>
        </div>
        <el-form-item
          v-if="itemType === 8 || itemType === 9 || itemType === 10"
          :label="$t('dataMaintenance.checkType.col5')"
          prop="checkType"
        >
          <el-input disabled>
            <template slot="append">{{dialog.unit}}</template>
          </el-input>
        </el-form-item>
        <div v-if="itemType === 11">
          <el-form-item
            label="IP"
            prop="ip"
          >
            <el-input
              disabled
              v-model="dialog.ip"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Mask"
            prop="mask"
          >
            <el-input
              disabled
              v-model="dialog.mask"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Gateway"
            prop="gateway"
          >
            <el-input
              disabled
              v-model="dialog.gateway"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="itemType === 12">
          <div class="input-label">{{$t('dataMaintenance.checkType.col5')}}</div>
          <img
            class="signature"
            src="/static/image/signature.png"
          >
        </div>
        <div v-if="itemType === 13">
          <div class="input-label">Option</div>
          <el-form-item
            label="Site"
            prop="siteId"
          >
            <el-select
              size="small"
              v-model="dialog.siteId"
              filterable
              @change="clearOption"
              clearable
            >
              <el-option
                v-for="(item,index) in branchs"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div
              slot="label"
              class="label-box"
            >
              <span>DataSource</span>
              <div class="switch-box">
                <span>Linked</span>
                <el-switch
                  v-model="dialog.isLinked"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  @change="linkedChange"
                ></el-switch>
              </div>
            </div>
            <el-select
              v-if="!!dialog.isLinked"
              size="small"
              v-model="dialog.filterData"
              @change="clearOption"
              clearable
            >
              <el-option
                v-for="(item,index) in dataSources"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              size="small"
              v-model="dialog.showData"
              class="margin-top"
              @change="clearOption"
              clearable
            >
              <el-option
                v-for="(item,index) in dataSources"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div
              slot="label"
              class="label-box"
            >
              <span>Dropdown</span>
              <el-button-group>
                <el-button
                  size="mini"
                  @click="EquipmentDialogVisible = true"
                >{{$t('operation.add')}}</el-button>
                <el-button
                  size="mini"
                  @click="delOption"
                >{{$t('operation.del')}}</el-button>
              </el-button-group>
            </div>
            <el-table
              border
              :data="dialog.options"
              @selection-change="val => tableSelected = val"
              :row-class-name="tableRowClassName"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="150"
                prop="name"
                label="list"
              >
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>

    <!--  -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :branch="dialog.siteId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import EquipmentDialog from '@/components/EquipmentDialog/EquipmentDialog10'
// 接口
import {
  AddCheckTypeItem,
  DelOption,
} from '@/api/warehouse/dataMaintenance/checkType'
// 接口
import { GetStoreList } from "@/api/stores";
export default {
  components: {
    EquipmentDialog
  },
  props: ['visible', 'data', 'checkItem'],
  data () {
    return {
      EquipmentDialogVisible: false,

      deviceNames: [],
      models: [],
      types: [],
      brands: [],
      suppliers: [],
      units: [],
      branchs: [],
      dialog: {
        visible: false,
        loading: false,
        title: 'Update Item',
        id: null,
        itemId: '',
        item: '',
        checkType: '',
        checkTypeId: '',
        options: [],
        optionInput: '',
        charaterLength: 1,
        start: 0,
        end: 9,
        max: 9,
        unit: '',
        ip: '0.0.0.0',
        mask: '0.0.0.0',
        gateway: '0.0.0.0',
        dataSource: '',
        isLinked: 0,
        siteId: '',
        showData: '',
        filterData: '',
      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col1'), trigger: 'blur' }],
      },
      itemType: '',
      dateFormats: [
        '2021-08-18',
        '2021/08/18',
        '08/18/2021',
        '2021年8月18日',
        '2021-08',
        '08-2021',
      ],
      timeFormats: [
        '10:28:28',
        '10:28:28AM',
      ],

      tableSelected: [],

      dataSources: [
        { label: 'Item Name', value: 0, prop: 'deviceInfoName' },
        { label: 'Brand', value: 1, prop: 'brandName' },
        { label: 'Item Type', value: 2, prop: 'deviceTypeName' },
        { label: 'Model', value: 3, prop: 'deviceInfoModel' },
        { label: 'Location', value: 4, prop: 'location' },
        { label: 'Serical Number', value: 5, prop: 'serialNumber' },
        { label: 'Display Name(Label)', value: 6, prop: 'label' },
        { label: 'Device ID', value: 7, prop: 'deviceId' },
        { label: 'Floor', value: 8, prop: 'floor' },
        { label: 'QR Code', value: 9, prop: 'qrCode' },
        { label: 'IP Address', value: 10, prop: 'ip' },
        { label: 'Connected to', value: 11, prop: 'connectedName' },
        { label: 'Remarks', value: 12, prop: 'remark' },
      ]
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        Object.assign(this.dialog, {
          item: '',
          isLinked: 0,
          showData: '',
          filterData: '',
          siteId: '',
        })

        this.dialog.visible = this.visible;
        if (this.data) {
          // this.$nextTick(() => {
          this.itemType = this.data.type;
          Object.assign(this.dialog, {
            itemId: this.data.itemId,
            id: this.data.id,
            item: this.data.item,
            checkType: this.data.checkType,
            checkTypeId: this.data.checkTypeId,
            options: this.data.options,
            isLinked: this.data.isLinked,
            siteId: this.data.siteId,
            showData: this.data.showData,
            filterData: this.data.filterData,
          });
          if (this.itemType === 5) this.dialog.optionInput = this.dateFormats[this.dialog.options[0].name]
          if (this.itemType === 6) this.dialog.optionInput = this.timeFormats[this.dialog.options[0].name]
          if (this.itemType === 7) {
            this.dialog.charaterLength = Number(this.dialog.options[0].name);
            this.dialog.start = Number(this.dialog.options[1].name);
            this.dialog.end = Number(this.dialog.options[2].name);
            this.dialog.max = Number(new Array(this.dialog.charaterLength).fill(9).join(''));

          }
          if (this.itemType === 8 || this.itemType === 9 || this.itemType === 10) {
            this.dialog.unit = this.dialog.options[0].name;
          }
          if (this.itemType === 11) {
            Object.assign(this.dialog, {
              ip: this.dialog.options[0].name,
              mask: this.dialog.options[1].name,
              gateway: this.dialog.options[2].name,
            });
          }
          // })
        } else {
          this.itemType = this.checkItem.type
          Object.assign(this.dialog, {
            id: null,
            checkType: this.checkItem.name,
            checkTypeId: this.checkItem.id,
          });
          if (this.itemType === 4) {
            this.dialog.options = JSON.parse(JSON.stringify(this.checkItem.options.map(item => {
              item.id = null
              return item;
            })));
          } else if (this.itemType === 13) {
            this.dialog.options = [];
          } else {
            this.dialog.options = [{ name: '', isNormal: 0, enable: 1 }, { name: '', isNormal: 0, enable: 1 }];
          }
          if (this.itemType === 5) this.dialog.optionInput = this.dateFormats[this.checkItem.abbreviation]
          if (this.itemType === 6) this.dialog.optionInput = this.timeFormats[this.checkItem.abbreviation]
          if (this.itemType === 7) {
            this.dialog.charaterLength = Number(this.checkItem.abbreviation);
            this.dialog.start = 0;
            this.dialog.end = Number(new Array(this.dialog.charaterLength).fill(9).join(''))
            this.dialog.max = max;
          }
          if (this.itemType === 8 || this.itemType === 9 || this.itemType === 10) {
            this.dialog.unit = this.checkItem.abbreviation;
          }
          if (this.itemType === 11) {
            Object.assign(this.dialog, {
              ip: this.checkItem.options[0].name,
              mask: this.checkItem.options[1].name,
              gateway: this.checkItem.options[2].name,
            });
          }
        }
        if (this.itemType === 1) this.dialog.optionInput = 'Type here';

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getStoreData();
  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          item: this.dialog.item,
          checkTypeId: this.dialog.checkTypeId,
        }
        if ([2, 3, 4, 13].includes(this.itemType)) {
          param.options = this.dialog.options.map((item, index) => {
            item.itemId = this.dialog.id;
            item.sn = index;
            return item;
          })
        }

        if (this.itemType === 7) {
          param.options = [
            { sn: 0, name: this.dialog.charaterLength },
            { sn: 1, name: this.dialog.start },
            { sn: 2, name: this.dialog.end },
          ];
        }
        if (this.itemType === 11) {
          param.options = [
            { sn: 0, name: this.dialog.ip },
            { sn: 1, name: this.dialog.mask },
            { sn: 2, name: this.dialog.gateway },
          ];
        }
        if (this.itemType === 13) {
          param.siteId = this.dialog.siteId;
          param.isLinked = this.dialog.isLinked;
          param.showData = this.dialog.showData;
          param.filterData = this.dialog.filterData;
        }
        let msg = this.$i18n.t('message.edit');
        if (this.data) {
          param.itemId = this.dialog.itemId;
          param.id = this.dialog.id;
          this.msg = this.$i18n.t('message.edit');

          if ([5, 6, 8, 9, 10].includes(this.itemType)) {
            param.options = [{ name: this.dialog.options[0].name }];
          }
        } else {
          if ([5, 6, 8, 9, 10].includes(this.itemType)) {
            param.options = [{ name: this.checkItem.abbreviation }];
          }
        }

        this.dialog.loading = true;
        AddCheckTypeItem(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update', this.dialog.isType)
          } else {
            this.$message.warning(res.data)
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    // 添加单选
    addRadio (index) {
      this.dialog.options.splice(index + 1, 0, {
        name: '',
        isNormal: 0,
        enable: 1,
      });
    },
    // 删除单选
    delRadio (index) {
      this.dialog.options.splice(index, 1);
    },
    // 删除option
    delOption () {
      let indexs = this.tableSelected.map(item => item.rowIndex);
      this.dialog.options = this.dialog.options.filter(item => !indexs.includes(item.rowIndex));

      let ids = this.tableSelected.map(item => item.id);
      if (ids.length > 0) {
        let param = { id: ids.join(',') }
        DelOption(param)
      }
    },
    equipmentSubmit (data) {
      data.forEach(item => {
        let temp = { name: item[this.dataSources[this.dialog.showData].prop] }
        if (this.dialog.isLinked) {
          temp.filterName = item[this.dataSources[this.dialog.filterData].prop]
        }
        this.dialog.options.push(temp)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    // 获取网点
    getStoreData () {
      let param = {
        groupId: '',
        limit: 9999,
        page: 1,
        search: '',
      };
      GetStoreList(param).then((res) => {
        this.branchs = !!res ? res.list : [];
      });
    },
    linkedChange () {
      this.dialog.filterData = '';
      this.clearOption();
    },
    clearOption () {
      let temp = this.dialog.options.filter(item => !!item.id)
      let ids = temp.map(item => item.id);

      this.dialog.options = [];
      if (ids.length > 0) {
        let param = { id: ids.join(',') }
        DelOption(param)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-label {
  height: 32px;
  line-height: 32px;
}
.input-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  .el-select {
    margin-right: 10px;
  }
  .el-input {
    margin-right: 10px;
  }
  .el-radio {
    margin-right: 10px;
  }
  .el-checkbox {
    margin-right: 5px;
  }
  span {
    text-align: center;
    width: 50px;
  }
}
.signature {
  width: 70%;
  margin: 20px 15% 0;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100%;
    padding: 0;
  }
}

.el-select {
  width: 100%;
}
.label-box {
  display: flex;
  justify-content: space-between;
  .el-switch,
  .el-button-group {
    position: relative;
    z-index: 1;
  }
}
.margin-top {
  margin-top: 10px;
}
</style>