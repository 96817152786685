<template>
  <div class="wrap">
    <el-card>
      <div class="card-wrap">
        <div class="tree-wrap">
          <el-row>
            <el-button-group class="btn-box">
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showStore(1)"
              >{{ $t("stores.btn1") }}</el-button>
              <el-button
                :disabled="!permit.addChild"
                size="medium"
                @click="showStore(2)"
              >{{ $t("stores.btn2") }}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showStore(3)"
              >{{ $t("operation.edit") }}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delNode"
              >{{ $t("operation.del") }}</el-button>
              <el-button
                :disabled="!permit.moveNode"
                size="medium"
                @click="showMove(1)"
              >{{ $t("operation.move") }}</el-button>
              <el-button
                :disabled="!permit.copyNode"
                size="medium"
                @click="nodeCopy"
              >{{ $t("operation.copy") }}</el-button>
            </el-button-group>
          </el-row>
          <el-row>
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.searchNode"
                v-model="treeSearchVal"
                class="search-input"
                size="medium"
                placeholder="Search"
                ref="treeSearch"
              ></el-input>
              <el-button
                size="medium"
                icon="icon-branch"
                class="branch"
                @click="showGrouping"
              ></el-button>
              <el-button
                size="medium"
                @click="checkAll"
              >{{$t("stores.btn3")}}</el-button>
            </el-button-group>
          </el-row>
          <el-tree
            :data="treeData"
            :default-expanded-keys="expandedList"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            v-loading="treeLoading"
            node-key="id"
            ref="tree"
            highlight-current
            @node-click="nodeClick"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
            @node-contextmenu="nodeContextmenu"
          ></el-tree>
        </div>
        <div
          class="table-wrap"
          ref="tableWrap"
        >
          <el-row>
            <div class="info-box">
              <span class="label">{{ $t("stores.label1") }}：</span>
              <span>{{ currentPath }}</span>
              <!-- <span
                class="name"
                :title="currentNode ? currentNode.label : ''"
              >{{ currentNode ? currentNode.label : "" }}</span>
              <span class="label">{{ $t("stores.label2") }}：</span>
              <span
                class="description"
                :title="currentNode ? currentNode.description : ''"
              >{{ currentNode ? currentNode.description : "" }}</span> -->
            </div>
          </el-row>
          <el-row>
            <div class="input-wrap">
              <el-button-group class="input-group">
                <el-input
                  :disabled="!permit.searchStroes"
                  type="text"
                  placeholder="Search"
                  size="medium"
                  class="search-input"
                  v-model="tableSearchVal"
                  clearable
                ></el-input>
                <el-button
                  :disabled="!permit.searchStroes"
                  size="medium"
                  @click="searchData"
                >{{ $t("operation.search") }}</el-button>
                <el-button
                  :disabled="!permit.searchStroes || !currentNode"
                  size="medium"
                  @click="showAddStore"
                >{{ $t("operation.add") }}</el-button>
                <el-button
                  :disabled="!permit.searchStroes"
                  size="medium"
                  @click="showEditStore"
                >{{ $t("operation.edit") }}</el-button>
                <el-button
                  :disabled="!permit.searchStroes"
                  size="medium"
                  @click="delStore"
                >{{ $t("operation.del") }}</el-button>
                <el-button
                  :disabled="!permit.import"
                  size="medium"
                  @click="$router.push('/home/importStores')"
                >{{ $t("operation.import") }}</el-button>
                <el-button
                  :disabled="!permit.export"
                  size="medium"
                >{{
                  $t("operation.export")
                }}</el-button>
                <el-button
                  :disabled="!permit.moveStroes"
                  size="medium"
                  @click="showMove(2)"
                >{{ $t("operation.move") }}</el-button>
                <el-button
                  :disabled="!permit.copyStroes"
                  size="medium"
                >{{
                  $t("operation.copy")
                }}</el-button>
              </el-button-group>
            </div>
          </el-row>
          <el-row>
            <el-table
              border
              ref="table"
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
              @selection-change="val => (tableSelected = val)"
              @row-dblclick="dblclickEdit"
              @row-click="rowClick"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="130"
                sortable
                :label="$t('stores.col6')"
                prop="parentNode"
              ></el-table-column>
              <el-table-column
                min-width="100"
                sortable
                :label="$t('stores.col1')"
                prop="storeId"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                min-width="120"
                sortable
                :label="$t('stores.col2')"
                prop="name"
              ></el-table-column>
              <el-table-column
                min-width="120"
                sortable
                :label="$t('stores.col3')"
                prop="typeName"
              ></el-table-column>
              <el-table-column
                min-width="100"
                sortable
                :label="$t('stores.col5')"
                prop="status"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 0">{{
                    $t("stores.storeDialog.option2")
                  }}</span>
                  <span v-else-if="scope.row.status == 1">{{
                    $t("stores.storeDialog.option1")
                  }}</span>
                  <span v-else-if="scope.row.status == 2">{{
                    $t("stores.storeDialog.option3")
                  }}</span>
                  <span v-else-if="scope.row.status == 3">{{
                    $t("stores.storeDialog.option4")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                min-width="100"
                sortable
                :label="$t('stores.col4')"
                prop="address"
              ></el-table-column>
            </el-table>
          </el-row>
          <el-row class="page-wrap">
            <my-page
              layout="prev, pager, next, jumper"
              :total="page.total"
              :page-size.sync="page.size"
              :current-page.sync="page.current"
              @current-change="getTableData"
              @size-change="getTableData"
            ></my-page>
          </el-row>
        </div>
      </div>
    </el-card>

    <!-- 添加商店弹框 -->
    <store-group-dialog
      :visible.sync="StoreGroupDialogVisible"
      :data="storeGroupData"
      :type="storeGroupType"
      @update="getTreeData"
    ></store-group-dialog>

    <store-dialog
      :visible.sync="StoreDialogVisible"
      :data="storeData"
      :node="currentNode"
      @update="getTableData"
    ></store-dialog>

    <grouping-dialog
      :visible.sync="GroupingDialogVisible"
      :type.sync="groupingType"
    ></grouping-dialog>

    <store-tree
      :visible.sync="StoreTreeVisible"
      :data="treeData"
      :move-ids="moveIds"
      :type="moveType"
      @update="moveUpdate"
    ></store-tree>

    <!-- tab-dropdown -->
    <div
      class="tab-dropdown"
      ref="tabDropdown"
      v-show="dropdownVisble"
      @click.stop
    >
      <div
        class="item"
        @click="tabDropdown(1)"
      >Add Node</div>
      <div
        class="item"
        @click="tabDropdown(2)"
      >Edit Node</div>
      <div
        class="item"
        @click="tabDropdown(3)"
      >Delete</div>
      <div
        class="item"
        @click="tabDropdown(4)"
      >Outdent</div>
      <div
        class="item"
        @click="tabDropdown(5)"
      >Indent</div>
    </div>
  </div>
</template>

<script>
// 全局方法
import { dealTree } from "@/utils/common";
// 组件
import StoreGroupDialog from "./components/StoreGroupDialog";
import StoreDialog from "./components/StoreDialog";
import GroupingDialog from "./components/GroupingDialog";
import StoreTree from "./components/StoreTree";
// 接口
import {
  GetStoreTree,
  DelStoreNode,
  GetStoreList,
  DelSite,
  GetSitePaht,
  MoveStores,
} from "@/api/stores";

export default {
  name: "Stores",
  components: {
    StoreGroupDialog,
    StoreDialog,
    GroupingDialog,
    StoreTree
  },
  data () {
    return {
      StoreGroupDialogVisible: false,
      StoreDialogVisible: false,
      GroupingDialogVisible: false,
      StoreTreeVisible: false,
      dropdownVisble: false,
      permit: {
        add: true,
        addChild: true,
        edit: true,
        del: true,
        moveNode: true,
        copyNode: true,
        searchNode: true,
        searchStroes: true,
        import: true,
        export: true,
        moveStroes: true,
        copyStroes: true
      },
      page: {
        total: 0,
        size: 10,
        current: 1
      },

      treeLoading: false,
      currentNode: null,
      treeTime: null,
      treeSearchVal: "",
      expandedList: [],
      treeData: [],
      tableLoading: false,
      tableSearchVal: "",
      tableSelected: [],
      tableHeight: null,
      tableData: [],

      storeGroupType: null,
      storeGroupData: null,
      storeData: null,

      groupingType: "1",
      currentPath: '',

      moveType: null,
      moveIds: null,
    };
  },
  watch: {
    treeSearchVal: function (val) {
      this.$refs.tree.filter(val);
    },
    "storeDialog.visible": function (newVal) {
      if (newVal) {
        if (this.storeDialog.parentId) {
        } else {
          // 获取数据访问组
          GetDataAccessGroupAll().then(res => (this.dataAccessGroups = res));
        }
      }
    },
    groupingType: function (newVal) {
      this.getTreeData();
    },
    '$route': function (to) {
      if (to.name === 'Stores') this.getTreeData()
    },
  },
  created () {
    this.getTreeData();
    this.dealPermission();

    document.body.addEventListener('click', this.clearDropdown)
  },
  destroyed () {
    document.body.removeEventListener('click', this.clearDropdown)
  },
  mounted () {
    this.tableHeight =
      this.$refs.tableWrap.offsetHeight - 36 - 15 - 36 - 15 - 32 - 15;
    this.page.size = Math.floor(this.tableHeight / 35);
  },
  methods: {
    getTreeData () {
      let param = {
        type: this.groupingType
      };
      this.treeLoading = true;
      GetStoreTree(param).then(res => {
        dealTree(res);
        this.treeData = res;
        this.treeLoading = false;

        this.$nextTick(() => {
          this.$refs.tree.filter(this.treeSearchVal);
        })
      }).catch(error => {
        this.treeLoading = false;
      });
    },
    getTableData () {
      let param = {
        groupId: this.currentNode ? this.currentNode.id : "",
        limit: this.page.size,
        page: this.page.current,
        search: this.tableSearchVal
      };
      this.tableLoading = true;
      GetStoreList(param)
        .then(res => {
          this.tableData = res.list;
          this.page.total = res.total;
          this.tableLoading = false;
        })
        .catch(error => {
          this.tableLoading = false;
        });
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    checkAll () {
      this.currentNode = null;
      this.page.current = 1;
      this.getTableData();
    },
    // 显示树节点弹框
    showStore (type) {
      if (this.currentNode === null && type !== 1)
        return this.$message.warning("Please select one item.");

      this.storeGroupType = type;
      this.storeGroupData = this.currentNode;
      this.StoreGroupDialogVisible = true;
    },

    // 删除节点
    delNode () {
      if (this.currentNode === null)
        return this.$message.warning("Please select one item.");

      this.$confirm("Delete operation, please confirm!", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        let param = {
          id: this.currentNode.id
        };
        DelStoreNode(param).then(res => {
          if (res.success) {
            this.currentNode = {
              label: "",
              description: ""
            };
            this.getTreeData();
            this.$message.success(this.$i18n.t("message.del"));
          }
        });
      }).catch(() => { });
    },
    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 点击树节点
    nodeClick (node) {
      let oldid = this.currentNode === null ? null : this.currentNode.id;
      this.currentNode = node;
      this.getTableData();
      GetSitePaht({ groupId: node.id }).then(res => this.currentPath = res.substring(0, res.length - 1))

      if (this.treeTime === null) {
        this.treeTime = new Date();
      } else {
        let interval = new Date() - this.treeTime;
        this.treeTime = null;
        if (interval < 1000 && oldid === this.currentNode.id) this.showStore(3);
      }
      this.dropdownVisble = false;
    },
    nodeContextmenu (event, data, node) {
      this.$refs.tabDropdown.style.left = event.pageX + 'px';

      if (202 < window.innerHeight - event.pageY) {
        this.$refs.tabDropdown.style.top = event.pageY + 'px';
      } else {
        this.$refs.tabDropdown.style.top = event.pageY - 202 + 'px';
      }
      this.currentNode = data;
      this.dropdownVisble = true;
    },
    // 复制
    nodeCopy () { },
    // 显示添加
    showAddStore () {
      this.storeData = null;
      this.StoreDialogVisible = true;
    },
    // 显示编辑
    showEditStore () {
      if (this.tableSelected.length === 0)
        return this.$message.warning("Please select one item.");
      if (this.tableSelected.length > 1)
        return this.$message.warning("You can only choose one.");
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (item) {
      this.storeData = item;
      this.StoreDialogVisible = true;
    },
    // 删除
    delStore () {
      if (this.tableSelected.length === 0)
        return this.$message.warning("Please select at least one item.");

      this.$confirm("Delete operation, please confirm!", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          let param = {
            id: this.tableSelected.map(item => item.id).join(",")
          };
          DelSite(param).then(res => {
            if (res.success) {
              this.getTableData();
              this.$message.success(this.$i18n.t("message.del"));
            }
          });
        })
        .catch(() => { });
    },
    // tab下拉框
    tabDropdown (command) {
      switch (command) {
        case 1:
          this.showStore(2);
          break;
        case 2:
          this.showStore(3);
          break;
        case 3:
          this.delNode();
          break;
        case 4:
          if (!this.currentNode.parentId) return;

          let node = this.$refs.tree.getNode(this.currentNode.parentId)
          MoveStores({
            id: node.data.parentId,
            storesId: this.currentNode.id,
          }).then(res => this.getTreeData())
          break;
        case 5:
          if (!this.currentNode.prevId) return;
          MoveStores({
            id: this.currentNode.prevId,
            storesId: this.currentNode.id,
          }).then(res => this.getTreeData())
          break;
      }
      this.dropdownVisble = false;
    },
    // 显示移动弹框
    showMove (type) {
      if (type === 1) {
        if (this.currentNode === null) return this.$message.warning("Please select at least one item.");
        this.moveIds = this.currentNode.id;
      } else {
        if (this.tableSelected.length === 0) return this.$message.warning("Please select at least one item.");
        this.moveIds = this.tableSelected.map(item => item.id).join(',');
      }
      this.moveType = type;
      this.StoreTreeVisible = true;
    },
    // 移动后更新数据
    moveUpdate () {
      if (this.moveType === 1) {
        this.getTreeData()
      } else {
        this.getTableData()
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {

        let permission = localStorage.permission.split(",");

        this.permit.add = permission.includes("StoresAddNode");
        this.permit.addChild = permission.includes("StoresAddChild");
        this.permit.edit = permission.includes("StoresEditNode");
        this.permit.del = permission.includes("StoresDeleteNode");
        this.permit.moveNode = permission.includes("StoresMoveNode");
        this.permit.copyNode = permission.includes("StoresCopyNode");
        this.permit.searchNode = permission.includes("StoresSearchNode");

        this.permit.searchStroes = permission.includes("StoresSearchStores");
        this.permit.import = permission.includes("StoresImportStroes");
        this.permit.export = permission.includes("StoresExportStores");
        this.permit.moveStroes = permission.includes("StoresMoveStores");
        this.permit.copyStroes = permission.includes("StoresCopyStores");
      }
    },
    showGrouping () {
      this.GroupingDialogVisible = true;
    },
    clearDropdown () {
      this.dropdownVisble = false;
    },
    nodeExpand (data) {
      this.expandedList.push(data.id); // 在节点展开是添加到默认展开数组
    },
    nodeCollapse (data) {
      this.expandedList.splice(this.expandedList.indexOf(data.id), 1); // 收起时删除数组里对应选项
    },
    rowClick (row) {
      GetSitePaht({ groupId: row.groupId }).then(res => {
        this.currentPath = res.substring(0, res.length - 1)
      })
      this.$refs.tree.setCurrentKey(row.groupId)
      this.expandedList.push(row.groupId)
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.card-wrap {
  height: 100%;
  display: flex;
}
.tree-wrap {
  width: 500px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;

  .btn-box {
    width: 100%;
    display: flex;
    .el-button {
      flex: 1;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.table-wrap {
  width: calc(100% - 522px);
  .info-box {
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    .label {
      flex-shrink: 0;
    }
    .name {
      width: 150px;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 0;
    }
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.el-dialog {
  .el-select {
    width: 100%;
  }
}

.el-tree {
  height: calc(100% - 36px - 36px - 20px);
  border: 1px solid #e4e4e4;
  overflow: auto;
  padding: 10px;
  .icon-box {
    margin-left: 10px;
  }
  i {
    width: 15px;
    height: 15px;
    line-height: 15px;
    border: 1px solid #9d9d9d;
    text-align: center;
    font-size: 12px;

    &:hover {
      background: #9d9d9d;
      color: #fff;
    }
  }
}

.branch {
  height: 36px;
}

.tab-dropdown {
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  .item {
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }
}

@media only screen and (max-width: 1550px) {
  .input-wrap {
    .el-button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
