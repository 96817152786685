import request from '@/utils/request'

// 获取列表
export const GetApprovalWorkflowList = (data) => request({
  method: 'post',
  url: '/WorkflowTable/queryByPage.do',
  data: JSON.stringify(data)
})

// 获取详情
export const GetApprovalWorkflowItem = (data) => request({
  method: 'post',
  url: '/WorkflowTable/queryById.do',
  data: JSON.stringify(data)
})

// 新增和修改
export const AddApprovalWorkflow = (data) => request({
  method: 'post',
  url: '/WorkflowTable/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelApprovalWorkflow = (data) => request({
  method: 'post',
  url: '/WorkflowTable/delete.do',
  data: JSON.stringify(data)
})

// 获取部门树
export const GetDepartmentTree = (data) => request({
  method: 'post',
  url: '/sysDep/queryAllDep.do',
  data: JSON.stringify(data)
})

// 获取审批选项列表
export const GetApprovalOptionList = (data) => request({
  method: 'post',
  url: '/WorkflowApprovalOption/queryByCondition.do',
  data: JSON.stringify(data)
})

// 删除审批选项
export const DelApprovalOption = (data) => request({
  method: 'post',
  url: '/WorkflowApprovalOption/delete.do',
  data: JSON.stringify(data)
})

// 新增、修改 审批选项
export const AddApprovalOption = (data) => request({
  method: 'post',
  url: '/WorkflowApprovalOption/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取审批按钮
export const GetApprovalBtn = (data) => request({
  method: 'get',
  url: '/WorkflowApprovalOption/queryButton.do',
  params: data
})
