<template>
  <div>
    <el-row>
      <el-table
        border
        :data="tableData"
        v-loading="tableLoading"
        ref="table"
        @selection-change="val => tableSelected = val"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          prop="sataus"
          :label="$t('newQuotation.col1')"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="success"
              v-if="scope.row.sataus == 1"
            >{{$t('newQuotation.tag3')}}</el-tag>
            <el-tag
              size="small"
              type="danger"
              v-else
            >{{$t('newQuotation.tag4')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="inquiryDate"
          :label="$t('newQuotation.col13')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="supplierName"
          :label="$t('newQuotation.col3')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="contactName"
          label="联系人"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="phoneNumber"
          :label="$t('newQuotation.col4')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="currency"
          :label="$t('newQuotation.col5')"
          sortable
        >
          <template slot-scope="scope">
            {{currencys[scope.row.currency]}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="untilPrice"
          :label="$t('newQuotation.col6')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.untilPrice.toFixed(decimals)}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="description"
          :label="$t('newQuotation.col7')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="projectName"
          :label="$t('newQuotation.col8')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="taxes"
          :label="$t('newQuotation.col9')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.taxes.toFixed(decimals)}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="discount"
          :label="$t('newQuotation.col10')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.discount.toFixed(decimals)}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="expireDate"
          :label="$t('newQuotation.col11')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          :label="$t('newQuotation.col12')"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click.stop="previewImage(scope.row.attachments)"
            >View</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="preview.visible"
      :on-close="previewClose"
      :url-list="preview.imageUrl"
    ></el-image-viewer>
  </div>
</template>

<script>

// 组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// 接口
import {
  GetQuotationDetailsList,
  DelQuotation,
  CopyQuotation,
} from '@/api/warehouse/quotation'
export default {
  components: {
    ElImageViewer,
  },
  props: ['permit', 'data', 'total'],
  data () {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },

      decimals: localStorage.quotationDecimals || 2,
      currencys: ['人民币', '美元', '港币', '泰铢', '迪拉姆'],
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      preview: {
        visible: false,
        imageUrl: '',
      },
    }
  },
  watch: {
    'data': function (newVal) {
      this.tableData = newVal;
      this.page.total = this.total;
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      this.$emit('tableChange', this.page.size, this.page.current);
    },
    // 图片预览
    previewImage (files) {
      if (!files || files.length === 0) return this.$message.warning('没有附件')
      this.preview.imageUrl = files.split(',').map(item => `${this.baseUrl}/Export/download.do?fileName=${item}`);
      this.preview.visible = true;
    },
    previewClose () {
      this.preview.visible = false;
    },
  },
}
</script>

<style>
</style>