<template>
  <div>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      name: '',
      loading: false,
    }
  },
  watch: {
    'item': function () {
      this.name = this.item.name;
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../../css/index.scss'

</style>