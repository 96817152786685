<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('inventoryDevice.title1')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
      </page-header>

      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table border          :data="tableData"
          v-loading="tableLoading"
          @row-dblclick="dblclick"
        >
          <el-table-column
            min-width="150"
            prop="createTime"
            :label="$t('inventoryDevice.col1')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="inOrg"
            :label="$t('inventoryDevice.col2')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="deviceType"
            :label="$t('inventoryDevice.col3')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="deviceName"
            :label="$t('inventoryDevice.col4')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="deviceModel"
            :label="$t('inventoryDevice.col5')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="deviceBrand"
            :label="$t('inventoryDevice.col6')"
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="deviceCount"
            :label="$t('inventoryDevice.col7')"
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="deviceUnit"
            :label="$t('inventoryDevice.col8')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="serialNumbers"
            :label="$t('inventoryDevice.col9')"
          >
          </el-table-column>
          <el-table-column
            min-width="220"
            prop="applicationFormNumber"
            :label="$t('inventoryDevice.col10')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="projectName"
            :label="$t('inventoryDevice.col11')"
          >
          </el-table-column>
          <el-table-column
            min-width="220"
            prop="quotationNumber"
            :label="$t('inventoryDevice.col12')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="orderNumber"
            :label="$t('inventoryDevice.col13')"
          >
          </el-table-column>

        </el-table>
      </el-row>
    </el-card>

  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import { GetInWarehouseList } from '@/api/warehouse/inventoryManagement'
export default {
  name: 'InventoryDevice',
  components: {
    PageHeader,
  },
  data () {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableData: [],
    }
  },
  created () {

    this.getTableData()
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        deviceInfoId: this.$route.query.id,
      }
      this.tableLoading = true;
      GetInWarehouseList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    dblclick (e) {
      this.$router.push({ path: '/home/inWarehouseDetails', query: { id: e.id, type: 2 } })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>