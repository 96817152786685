<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('equipmentDialog5.title')"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <el-row>

        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          @row-dblclick="dblclick"
        >
          <el-table-column
            min-width="160"
            :label="$t('equipmentDialog5.col5')"
            prop="applicationFormNumber"
          ></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog5.col1')"
            prop="deviceName"
          ></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog5.col2')"
            prop="deviceBrand"
          ></el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('equipmentDialog5.col3')"
            prop="deviceModel"
          ></el-table-column>
          <el-table-column
            min-width="80"
            :label="$t('equipmentDialog5.col4')"
            prop="inCount"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetOutWarehouseDeviceByDeviceId } from '@/api/warehouse/inventoryManagement'
export default {
  name: 'EquipmentDialog5',
  props: ['visible', 'ids', 'projectId', 'type', 'deviceInfoId'],
  data () {
    return {
      dialogVisible: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.searchData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        inIds2: this.ids,
        deviceInfoId: this.deviceInfoId,
        isAbnormal: '1',
      }
      if (this.type == 2) {
        param.projectContractId = this.projectId;
      }
      this.tableLoading = true;
      GetOutWarehouseDeviceByDeviceId(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e);
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>