<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="user-form"
      >
        <el-form-item :label="label">
          {{dialog.itemName}}
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.contactDialog.label4')"
          prop="contact"
        >
          <el-input v-model="dialog.contact"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.contactDialog.label5')"
          prop="phone"
        >
          <el-input v-model="dialog.phone"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.contactDialog.label6')"
          prop="mail"
        >
          <el-input v-model="dialog.mail"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.contactDialog.label7')"
          prop="fax"
        >
          <el-input v-model="dialog.fax"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.contactDialog.label8')"
          prop="position"
        >
          <el-input v-model="dialog.position"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import { AddSupplierContact } from '@/api/warehouse/dataMaintenance/supplier'
import { AddLogisticsContact } from '@/api/warehouse/dataMaintenance/logisticsCompany'
import { AddReceiptContact } from '@/api/warehouse/dataMaintenance/receiptAddress'

export default {
  props: ['visible', 'data', 'item', 'type'],
  data () {
    return {
      types: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        contact: '',
        phone: '',
        mail: '',
        fax: '',
        position: '',
        itemName: '',
      },
      rules: {
        // contact: [{ required: true, message: 'Please enter contact.', trigger: 'blur' }],
        contact: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.contactDialog.label4'), trigger: 'blur' }],
      },
      msg: '',
      label: '',
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('dataMaintenance.contactDialog.title2'),
              contact: this.data.name,
              phone: this.data.phoneNumber,
              mail: this.data.email,
              fax: this.data.fax,
              position: this.data.postName,
            })
            switch (this.type) {
              case 'Supplier':
                this.dialog.itemName = this.data.supplierName;
                break;
              case 'LogisticsCompany':
                this.dialog.itemName = this.data.companyName;
                break;
              case 'ReceiptAddress':
                this.dialog.itemName = this.data.address;
                break;
            }
          })
        } else {
          this.dialog.title = this.$i18n.t('dataMaintenance.contactDialog.title1')
          this.dialog.itemName = this.item.name;
        }
        this.initLabel();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 添加或修改
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.dialog.contact,
          phoneNumber: this.dialog.phone,
          email: this.dialog.mail,
          fax: this.dialog.fax,
          postName: this.dialog.position,
        }

        this.msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          this.msg = this.$i18n.t('message.edit');
        }

        switch (this.type) {
          case 'Supplier':
            param.supplierId = !!this.data ? this.data.supplierId : this.item.id;
            AddSupplierContact(param).then(this.dealSubmit);
            break;
          case 'LogisticsCompany':
            param.companyId = !!this.data ? this.data.companyId : this.item.id;
            AddLogisticsContact(param).then(this.dealSubmit);
            break;
          case 'ReceiptAddress':
            param.addressId = !!this.data ? this.data.addressId : this.item.id;
            AddReceiptContact(param).then(this.dealSubmit);
            break;
        }
      })
    },
    dealSubmit (res) {
      if (res.success) {
        this.$message.success(this.msg)
        this.dialog.visible = false;
        this.$emit('update')
      } else {
        if (res.status === 100) this.$message.warning('User account is occupied.')
      }
      this.dialog.loading = false;
    },
    initLabel () {
      switch (this.type) {
        case 'Supplier':
          this.label = this.$i18n.t('dataMaintenance.contactDialog.label1');
          break;
        case 'LogisticsCompany':
          this.label = this.$i18n.t('dataMaintenance.contactDialog.label2');
          break;
        case 'ReceiptAddress':
          this.label = this.$i18n.t('dataMaintenance.contactDialog.label3');
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>