import timeZones from './timeZones'

const typeOperators = {
  number: ['=', '<', '>', '<=', '>=', '<>', 'In List', 'Custom Range'],
  string: ['Equals', 'Does Not Equal', 'Begins With', 'Ends With', 'Contains', 'Does Not Contain', 'In List'],
  // stores: ['Equals', 'Does Not Equal', 'Begins With', 'Ends With', 'Contains', 'Does Not Contain', 'In List'],
  stores: ['Equals', 'Does Not Equal', 'In List'],
  date: ['='],
  option: ['='],
  time: ['Before Specific Time', 'After Specific Time', 'Between Specific Time'],
  mins: ['>', '<', 'between'],
  days: ['='],
  LowValues: ['<'],
  hhmm: ['='],
  sla: ['='],
  device: ['='],
  system: ['='],
  engineer: ['='],
};
const typeOperatorsText = {
  number: ['Equals', 'Less than', 'Greater than', 'Less than or equal to', 'Greater than or equal to', 'Not equal to', 'In List', 'Custom Range'],
  string: ['Equals', 'Does Not Equal', 'Begins With', 'Ends With', 'Contains', 'Does Not Contain', 'In List'],
  // stores: ['Equals', 'Does Not Equal', 'Begins With', 'Ends With', 'Contains', 'Does Not Contain', 'In List'],
  stores: ['Equals', 'Does Not Equal', 'In List'],
  date: ['Equals'],
  option: ['Equals'],
  time: ['Before Specific Time', 'After Specific Time', 'Between Specific Time'],
  mins: ['Greater than', 'Less than', 'between'],
  days: ['Equals'],
  LowValues: ['Less than'],
  hhmm: ['Equals'],
  sla: ['Equals'],
  device: ['Equals'],
  system: ['Equals'],
  engineer: ['Equals'],
};
// 正则验证
const pattern = {
  tel: /^(\+?0?86\-?)?1[345789]\d{9}$|^(\+852\-?)?[569]\d{3}\d{4}$/,
  // tel: /^(\+?213|0)(5|6|7)\d{8}$|^(!?(\+?963)|0)?9\d{8}$|^(!?(\+?966)|0)?5\d{8}$|^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$|^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$|^(\+?49[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$|^(\+?45)?(\d{8})$|^(\+?30)?(69\d{8})$|^(\+?61|0)4\d{8}$|^(\+?44|0)7\d{9}$|^(\+?852\-?)?[569]\d{3}\-?\d{4}$|^(\+?91|0)?[789]\d{9}$|^(\+?64|0)2\d{7,9}$|^(\+?27|0)\d{9}$|^(\+?26)?09[567]\d{7}$|^(\+?34)?(6\d{1}|7[1234])\d{7}$|^(\+?358|0)\s?(4(0|1|2|4|5)?|50)\s?(\d\s?){4,8}\d$|^(\+?33|0)[67]\d{8}$|^(\+972|0)([23489]|5[0248]|77)[1-9]\d{6}|^(\+?36)(20|30|70)\d{7}$|^(\+?39)?\s?3\d{2} ?\d{6,7}$|^(\+?81|0)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$|^(\+?6?01){1}(([145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$|^(\+?47)?[49]\d{7}$|^(\+?32|0)4?\d{8}$|^(\+?47)?[49]\d{7}$|^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$|^(\+?55|0)\-?[1-9]{2}\-?[2-9]{1}\d{3,4}\-?\d{4}$|^(\+?351)?9[1236]\d{7}$|^(\+?7|8)?9\d{9}$|^(\+3816|06)[- \d]{5,9}$|^(\+?90|0)?5\d{9}$|^(\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$|^(\+?0?86\-?)?1[345789]\d{9}$|^(\+?886\-?|0)?9\d{8}$/,
}

const priorityIds = [{
    id: 1,
    name: '001(Highest)'
  },
  {
    id: 2,
    name: '002'
  },
  {
    id: 3,
    name: '003'
  },
  {
    id: 4,
    name: '004'
  },
  {
    id: 5,
    name: '005'
  },
  {
    id: 6,
    name: '006'
  },
  {
    id: 7,
    name: '007'
  },
  {
    id: 8,
    name: '008'
  },
  {
    id: 9,
    name: '009'
  },
  {
    id: 10,
    name: '010'
  },
  {
    id: 11,
    name: '011'
  },
  {
    id: 12,
    name: '012'
  },
  {
    id: 13,
    name: '013'
  },
  {
    id: 14,
    name: '014'
  },
  {
    id: 15,
    name: '015'
  },
  {
    id: 16,
    name: '016'
  },
  {
    id: 17,
    name: '017'
  },
  {
    id: 18,
    name: '018'
  },
  {
    id: 19,
    name: '019'
  },
  {
    id: 20,
    name: '020'
  },
  {
    id: 21,
    name: '021'
  },
  {
    id: 22,
    name: '022'
  },
  {
    id: 23,
    name: '023'
  },
  {
    id: 24,
    name: '024'
  },
  {
    id: 25,
    name: '025'
  },
  {
    id: 26,
    name: '026'
  },
  {
    id: 27,
    name: '027'
  },
  {
    id: 28,
    name: '028'
  },
  {
    id: 29,
    name: '029'
  },
  {
    id: 30,
    name: '030'
  },
  {
    id: 31,
    name: '031'
  },
  {
    id: 32,
    name: '032(Lowest)'
  }
];
const warnings = [{
    id: 1,
    name: 'L1'
  },
  {
    id: 2,
    name: 'L2'
  },
  {
    id: 3,
    name: 'L3'
  },
  {
    id: 4,
    name: 'L4'
  },
  {
    id: 5,
    name: 'L5'
  },
  {
    id: 6,
    name: 'L6'
  },
  {
    id: 7,
    name: 'L7'
  },
  {
    id: 8,
    name: 'L8'
  },
  {
    id: 9,
    name: 'L9'
  },
  {
    id: 10,
    name: 'L10'
  }
];
const escalations = [{
    id: 1,
    name: 'Level1'
  },
  {
    id: 2,
    name: 'Level2'
  },
  {
    id: 3,
    name: 'Level3'
  },
  {
    id: 4,
    name: 'Level4'
  },
  {
    id: 5,
    name: 'Level5'
  },
  {
    id: 6,
    name: 'Level6'
  },
  {
    id: 7,
    name: 'Level7'
  },
  {
    id: 8,
    name: 'Level8'
  },
  {
    id: 9,
    name: 'Level9'
  },
  {
    id: 10,
    name: 'Level10'
  }
];

const checkTypes = [{
    id: 1,
    name: 'Input Box'
  },
  {
    id: 2,
    name: 'Drop Down'
  },
  {
    id: 3,
    name: 'Check Box'
  },
  {
    id: 4,
    name: 'Single Choice'
  },
  {
    id: 5,
    name: 'Date'
  },
  {
    id: 6,
    name: 'Time'
  },
  {
    id: 7,
    name: 'Number'
  },
  {
    id: 8,
    name: 'Quantity'
  },
  {
    id: 9,
    name: 'Day'
  },
  {
    id: 10,
    name: 'Disk Capacity'
  },
  {
    id: 11,
    name: 'IP Address'
  },
  {
    id: 12,
    name: 'Singnature'
  },
  {
    id: 13,
    name: 'Drop Down(internal DataSource)'
  },
]

const deviceStates = [
  '待处理',
  '已订货-部分',
  '已订货-全部',
  '无需订货-部分',
  '无需订货-全部',
  '已到货-部分',
  '已到货-全部',
  '已入库-部分',
  '已入库-全部',
  '已出库-部分',
  '已出库-全部',
  '已签收-部分',
  '已签收-全部',
  '异常',
  '已撤销-出入库',
  '已撤销-出库',
  '已发货-部分',
  '已发货-全部',
]
const currencysData = [{
  id: 1,
  name: '人民币'
}, {
  id: 2,
  name: '美元'
}, {
  id: 3,
  name: '港币'
}, {
  id: 4,
  name: '泰铢'
}, {
  id: 5,
  name: '迪拉姆'
}, {
  id: 6,
  name: '新加坡元'
}, {
  id: 7,
  name: '缅甸元'
}, {
  id: 8,
  name: '马来西亚林吉特'
}]
var BusinessData = [];
var BranchData = [];
var date1 = new Date();
var date2 = new Date(date1.getTime() - 1800000);
var TheLastThirtyMinutes = `${date2.getFullYear()}-${date2.getMonth()}-${date2.getDate()} ${date2.getHours()}:${date2.getMinutes()}:${date2.getSeconds()}~${date1.getFullYear()}-${date1.getMonth()}-${date1.getDate()} ${date1.getHours()}:${date1.getMinutes()}:${date1.getSeconds()}`;
var ExamineData = {};

export default {
  typeOperators,
  typeOperatorsText,
  pattern,
  BusinessData,
  BranchData,
  TheLastThirtyMinutes,
  priorityIds,
  warnings,
  escalations,
  timeZones,
  checkTypes,
  deviceStates,
  ExamineData,
  currencysData,
}
