<template>
  <div>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}</label>
        <el-input
          v-model="item.name"
          size="medium"
          readonly
        ></el-input>
      </div>
    </el-row>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.serviceConfirmationTemplate.label1')}}</label>
        <el-input
          v-model="item.flieName"
          size="medium"
          readonly
        ></el-input>
      </div>
    </el-row>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.serviceConfirmationTemplate.label2')}}</label>
        <el-select
          size="medium"
          multiple
          v-model="template"
          disabled
          filterable
        >
          <el-option
            v-for="item in templates"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
    </el-row>
  </div>
</template>

<script>
import {
  ConfirmationTemplates,
} from '@/api/warehouse/dataMaintenance/serviceConfirmationTemplate'
export default {
  props: ['items'],
  data () {
    return {
      templates: [],
      template: [],
      loading: false,
      item: {},
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.item = {};
      } else {
        this.item = this.items[0]
        this.template = !!this.item.demId ? this.item.demId.split(',') : '';
      }
    }
  },
  created () {
    ConfirmationTemplates().then(res => this.templates = res);
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.label-item {
  label {
    width: 170px;
    margin-right: 10px;
    display: inline-block;
    text-align: right;
  }
  .el-select,
  .el-input {
    width: 400px;
  }
}
.text-align {
  text-align: center;
}
</style>