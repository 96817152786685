<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('contactDialog.title1')"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
          <el-select
            size="medium"
            v-model="tableType"
            @change="tableTypeChange"
          >
            <el-option
              :value="0"
              label="联系人"
            ></el-option>
            <el-option
              :value="1"
              label="人员管理"
            ></el-option>
            <el-option
              :value="2"
              label="系统用户"
            ></el-option>
          </el-select>
        </div>
      </el-row>
      <el-row>
        <el-table
          v-if="isTable"
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <template v-if="tableType == 0">
            <el-table-column
              :label="$t('contactDialog.col1')"
              prop="name"
            ></el-table-column>
            <el-table-column
              :label="$t('contactDialog.col2')"
              prop="sgName"
            ></el-table-column>
            <el-table-column
              :label="$t('contactDialog.col3')"
              prop="email1"
            ></el-table-column>
            <el-table-column
              :label="$t('contactDialog.col4')"
              prop="phone"
            ></el-table-column>
            <el-table-column
              :label="$t('contactDialog.col5')"
              prop="email2"
            ></el-table-column>
            <el-table-column
              :label="$t('contactDialog.col6')"
              prop="secondPhone"
            ></el-table-column>
          </template>
          <template v-else-if="tableType == 1">
            <el-table-column
              min-width="120"
              sortable
              :label="$t('sendOrder.staff.col1')"
              prop="name"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('sendOrder.staff.col2')"
              prop="homePhone"
            ></el-table-column>
            <el-table-column
              min-width="180"
              sortable
              :label="$t('sendOrder.staff.col3')"
              prop="currentSum"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('sendOrder.staff.col4')"
              prop="processing"
            >
              <template slot-scope="scope">
                {{scope.row.processing == 1 ? $t('sendOrder.staff.yes') : $t('sendOrder.staff.no')}}
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('sendOrder.staff.col7')"
              prop="attendance"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.attendance == 0">{{$t('newEngineer.option1')}}</span>
                <span v-else-if="scope.row.attendance == 2">{{$t('newEngineer.option3')}}</span>
                <span v-else>{{$t('newEngineer.option2')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="220"
              sortable
              :label="$t('sendOrder.staff.col6')"
              prop="thisMonth"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('sendOrder.staff.col5')"
              prop="location"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('sendOrder.staff.col8')"
              prop="leavestatus"
            ></el-table-column>
          </template>
          <template v-else>
            <el-table-column
              min-width="150"
              prop="userAccount"
              :label="$t('userManagement.col1')"
              sortable
            >
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="fullname"
              :label="$t('userManagement.col10')"
              sortable
            >
            </el-table-column>
            <el-table-column
              min-width="180"
              prop="organizationName"
              :label="$t('userManagement.col2')"
              sortable
            >
            </el-table-column>
            <el-table-column
              min-width="180"
              prop="emailAddress"
              :label="$t('userManagement.col7')"
              sortable
            >
            </el-table-column>
            <el-table-column
              min-width="180"
              prop="phoneNumber"
              :label="$t('userManagement.col8')"
              sortable
            >
            </el-table-column>
          </template>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetContactList } from '@/api/contactList'
import { GetEngineerStateList } from '@/api/warehouse/engineerManagement'
import { GetUserList } from '@/api/userManagement'

export default {
  props: ['visible'],
  data () {
    return {
      dialogVisible: false,
      isTable: true,
      tableType: 0,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      switch (this.tableType) {
        case 0:
          GetContactList(param).then(this.dealTable)
          break;
        case 1:
          GetEngineerStateList(param).then(this.dealTable)
          break;
        case 2:
          GetUserList(param).then(this.dealTable)
          break;
      }
    },
    dealTable (res) {
      this.tableData = res.list;
      this.page.total = res.total;
      this.tableLoading = false;
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交
    submit () {
      let result
      if (this.tableType === 2) {
        result = this.tableSelected.map(item => {
          return {
            id: item.id,
            name: item.fullname,
            sgName: item.storeGroupName,
            email1: item.emailAddress,
            phone: item.phoneNumber,
            email2: item.secondEmailAddress,
            secondPhone: item.secondPhoneNumber,
          }
        })
      } else {
        result = this.tableSelected;
      }
      this.$emit('submit', result);
      this.dialogVisible = false;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 切换表格
    tableTypeChange () {
      this.getTableData();
      this.isTable = false;
      this.$nextTick(() => {
        this.isTable = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>