<template>
  <div>
    <el-form
      :model="groupForm"
      :rules="rules"
      ref="groupForm"
      class="group-form"
      size="medium"
      label-width="170px"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label1')"
            prop="brand"
          >
            <el-select
              v-model="groupForm.brand"
              filterable
              remote
              reserve-keyword
              :remote-method="queryBrand"
              @change="brandChange"
            >
              <el-option
                v-for="item in brands"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label2')"
            prop="requiredTime"
          >
            <el-date-picker
              v-model="groupForm.requiredTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label3')"
            prop="source"
          >
            <el-select
              v-model="groupForm.source"
              filterable
            >
              <el-option
                v-for="item in sources"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="$t('newSubmitRequest.label8')">
            <el-input
              disabled
              v-model="brandAddress"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="$t('newSubmitRequest.label9')">
            <el-button-group class="input-group">
              <el-input
                v-model="referenceNumber"
                class="search-input"
                disabled
              ></el-input>
              <el-button
                :disabled="!groupForm.brand"
                @click="showNumber(1)"
              >{{$t('operation.edit')}}</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="permit.number"
        :gutter="20"
      >
        <el-col :span="8">
          <el-form-item :label="$t('newSubmitRequest.label10')">
            <el-button-group class="input-group">
              <el-input
                v-model="quotationNumber"
                class="search-input"
                disabled
              ></el-input>
              <el-button
                :disabled="!groupForm.brand"
                @click="showNumber(2)"
              >{{$t('operation.edit')}}</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('newSubmitRequest.label11')">
            <el-button-group class="input-group">
              <el-input
                v-model="purchaseNumber"
                class="search-input"
                disabled
              ></el-input>
              <el-button
                :disabled="!groupForm.brand"
                @click="showNumber(3)"
              >{{$t('operation.edit')}}</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label4')"
            prop="contactId"
          >
            <el-button-group class="input-group">
              <el-select
                v-model="groupForm.contactId"
                class="search-input"
                filterable
                @change="contactChange"
              >
                <el-option
                  v-for="item in contacts"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
              <el-button @click="showeSigntory(0)">New</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label5')"
            prop="tel"
          >
            <el-input v-model="groupForm.tel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label12')"
            prop="directorId"
          >
            <el-button-group class="input-group">
              <el-select
                v-model="groupForm.directorId"
                class="search-input"
                filterable
              >
                <el-option
                  v-for="item in directors"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
              <el-button @click="showeSigntory(1)">New</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            :label="$t('newSubmitRequest.label6')"
            prop="serverType"
          >
            <el-select
              v-model="groupForm.serverType"
              multiple
              filterable
              @change="serviceTypeChange"
            >
              <el-option
                v-for="item in serviceTypes"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Priority"
            prop="priority"
          >
            <el-select
              :disabled="groupForm.serverType.length === 0"
              v-model="groupForm.priority"
              filterable
            >
              <el-option
                v-for="item in prioritys"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        :label="$t('newSubmitRequest.label7')"
        prop="describe"
      >
        <el-input
          type="textarea"
          :rows="4"
          v-model="groupForm.describe"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">{{$t('newSubmitRequest.subtitle2')}}</el-divider>
      <el-row>
        <el-button-group>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd(1)"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd(2)"
          >{{$t('newSubmitRequest.qrcode.btn')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row>
        <el-table
          border
          :data="tableData"
          :row-class-name="tableRowClassName"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="deviceInfoName"
            :label="$t('newSubmitRequest.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="faultTypeName"
            :label="$t('newSubmitRequest.col11')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="faultDescription"
            :label="$t('newSubmitRequest.col2')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-divider content-position="left">{{$t('newSubmitRequest.subtitle4')}}</el-divider>
      <el-row>
        <el-upload
          ref=""
          :action="baseUrl + '/Engineer/fileUpload.do'"
          :headers="headers"
          list-type="picture-card"
          :file-list="groupForm.fileList"
          :on-preview="previewImage"
          :on-success="uploadCall"
          :on-remove="delCall"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-row>
    </el-form>
    <!-- 扫码新增 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newSubmitRequest.qrcode.title')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="dialogRules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          prop="code"
          :label="$t('newSubmitRequest.qrcode.label1')"
        >
          <el-input v-model="dialog.code">
            <el-button
              slot="append"
              @click="EquipmentDialogVisble = true"
            >{{$t('operation.search')}}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="name"
          :label="$t('newSubmitRequest.qrcode.label2')"
        >
          <el-input
            disabled
            v-model="dialog.name"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          prop="describe"
          :label="$t('newSubmitRequest.qrcode.label3')"
        >
          <el-input
            type="textarea"
            v-model="dialog.describe"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submitDevice"
        >{{$t('operation.save')}}</el-button>
        <!-- @click="submit" -->
      </div>
    </el-dialog>

    <!-- 设备弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisble"
      type="1"
      :branch-id="groupForm.brand"
      @submit="equipmentSubmit"
    ></equipment-dialog>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="preview.visible"
      :on-close="previewClose"
      :url-list="preview.imageUrl"
    ></el-image-viewer>

    <!-- 故障弹框 -->
    <fault-dialog
      :visible.sync="FaultDialogVisble"
      :data="faultData"
      @submit="faultSubmit"
    ></fault-dialog>
    <!-- 编号弹框 -->
    <number-dialog
      :visible.sync="NumberDialogVisble"
      :data="numberData"
      :type="numberType"
      :branch-id="groupForm.brand"
      @submit="numberSubmit"
    ></number-dialog>
    <!-- 新客户签署人弹框 -->
    <signtory-dialog
      :visible.sync="SigntoryDialogVisible"
      :branch-id="groupForm.brand"
      :org-id="groupForm.customerOrgId"
      :group-id="groupForm.groupId"
      :type="submitSigntoryType"
      @submit="submitSigntory"
    ></signtory-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatUTCDate, toLocaleDate } from '@/utils/common'
// 组件
import MySelect from '@/components/MySelect'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import EquipmentDialog from '@/components/EquipmentDialog'
import FaultDialog from './FaultDialog'
import NumberDialog from '@/pages/warehouse/submitRequest/components/NumberDialog'
import SigntoryDialog from '@/pages/warehouse/workOrderScheduling/components/SigntoryDialog'

// 接口
import {
  AddSubmitRequest,
  EngineerSubmit,
} from '@/api/warehouse/submitRequest'
import {
  GetSLAPriorityByserverType
} from '@/api/warehouse/slaPolicies'
import { SearchBrand } from '@/api/stores'
import { GetUserAll } from '@/api/userManagement'
import { GetContactAll2 } from '@/api/contactList'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    MySelect,
    ElImageViewer,
    EquipmentDialog,
    FaultDialog,
    NumberDialog,
    SigntoryDialog,
  },
  props: ['info'],
  data () {
    return {
      permit: {
        number: true,
      },
      NumberDialogVisble: false,
      EquipmentDialogVisble: false,
      FaultDialogVisble: false,
      SigntoryDialogVisible: false,
      activeName: 'base',
      type: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      headers: {
        token: this.$cookies.get('token')
      },
      brands: [],
      serviceTypes: [],
      sources: [],
      contacts: [],
      directors: [],
      prioritys: [],
      groupForm: {
        id: null,
        loading: false,
        brand: '',
        contact: '',
        contactId: '',
        serverType: [],
        tel: '',
        requiredTime: '',
        describe: '',
        fileList: [],
        source: '',
        director: '',
        directorId: '',
        priority: '',
        customerOrgId: '',
        groupId: '',
      },
      rules: {
        brand: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // contactId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        serverType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        name: '',
        code: '',
        describe: '',
      },
      dialogRules: {
        code: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      preview: {
        visible: false,
        imageUrl: '',
      },
      faultData: null,
      numberType: null,
      numberData: [],
      reference: [],
      quotation: [],
      purchase: [],
      submitSigntoryType: null,
    }
  },

  computed: {
    'brandAddress': function () {
      let item = this.brands.find(item => this.groupForm.brand === item.id)
      if (item) return item.address;
    },
    'referenceNumber': function () {
      let result = this.reference.map(item => {
        if (!!item.name) {
          return item.name;
        } else {
          return item.id;
        }
      })
      return result.join(',')
    },
    'quotationNumber': function () {
      let result = this.quotation.map(item => {
        if (!!item.name) {
          return item.name;
        } else {
          return item.id;
        }
      })
      return result.join(',')
    },
    'purchaseNumber': function () {
      let result = this.purchase.map(item => {
        if (!!item.name) {
          return item.name;
        } else {
          return item.id;
        }
      })
      return result.join(',')
    },
  },
  created () {
    this.type = this.$route.query.type;
    if (this.$route.query.id) {
      this.groupForm.id = this.$route.query.id;
    } else {
      this.queryBrand('');
      // this.getCurrentUser();
    }
    // 获取服务类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000006' }).then(res => this.serviceTypes = res)
    // 获取工单来源
    GetDataFieldAll({ parent: '100000000000000000000000000000000017' }).then(res => {
      this.sources = res
      if (!this.$route.query.id) {
        let item = res.find(item => item.name.toLowerCase().indexOf('web') != -1);
        this.groupForm.source = !!item ? item.id : '';
      }
    })

    this.dealPermission();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 初始化数据
    initData () {
      Object.assign(this.groupForm, {
        brand: this.info.branchId,
        contactId: this.info.customerContactId,
        contact: this.info.customerContactName,
        serverType: this.info.serviceTypeId !== null ? this.info.serviceTypeId.split(',') : [],
        tel: this.info.customerContactPhoneNo,
        requiredTime: toLocaleDate(this.info.requireArrivalTime),
        describe: this.info.description,
        fileList: this.info.photoList.map(item => Object.create({ name: item, url: `${this.baseUrl}/Export/download.do?fileName=${item}` })),
        source: this.info.source,
        director: this.info.director,
        directorId: this.info.directorId,
        priority: this.info.policyId,
        customerOrgId: this.info.customerOrgId,
      })

      this.info.referenceId = !!this.info.referenceId ? this.info.referenceId.split(',') : [];
      this.info.quotationId = !!this.info.quotationId ? this.info.quotationId.split(',') : [];
      this.info.purchaseId = !!this.info.purchaseId ? this.info.purchaseId.split(',') : [];
      this.info.referenceNo = !!this.info.referenceNo ? this.info.referenceNo.split(',') : [];
      this.info.quotationNo = !!this.info.quotationNo ? this.info.quotationNo.split(',') : [];
      this.info.purchaseNo = !!this.info.purchaseNo ? this.info.purchaseNo.split(',') : [];

      this.reference = this.info.referenceId.map((item, index) => {
        return {
          id: item,
          name: this.info.referenceNo[index],
        }
      })
      this.quotation = this.info.quotationId.map((item, index) => {
        return {
          id: item,
          name: this.info.quotationNo[index],
        }
      })
      this.purchase = this.info.purchaseId.map((item, index) => {
        return {
          id: item,
          name: this.info.purchaseNo[index],
        }
      })

      this.tableData = this.info.workOrderDeviceList;
      this.brands = [{ name: this.info.branchName, id: this.info.branchId }]
      this.brandChange();
      this.serviceTypeChange();
      // this.queryBrand(this.info.branchName || '');
    },
    // 获取当前用户
    getCurrentUser () {
      GetUserAll({}).then(res => {
        let user = res.find(item => item.id === this.$cookies.get('userId'))
        this.groupForm.contact = user.fullname;
        this.groupForm.tel = user.phoneNumber;
      })
    },
    // 提交
    submit (type) {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          customerOrgId: this.groupForm.brand,
          customerContactId: this.groupForm.contactId,
          customerContactPhoneNo: this.groupForm.tel,
          customerContactEmail: '',
          serviceTypeId: this.groupForm.serverType.join(','),
          requireArrivalTime: formatUTCDate(this.groupForm.requiredTime),
          description: this.groupForm.describe,
          photoList: this.groupForm.fileList.map(item => item.name),
          source: this.groupForm.source,
          policyId: this.groupForm.priority,
          workOrderDeviceList: this.tableData.map(item => {
            return {
              maintenanceDeviceId: item.maintenanceDeviceId,
              deviceInfoName: item.deviceInfoName,
              faultDescription: item.faultDescription,
              faultTypeId: item.faultTypeId,
            }
          }),
          listModel: this.info.distributionOrderList.map(item => {
            return {
              id: item.id,
              status: item.status,
              reason: item.reason || '',
              planArrivalTime: item.planArrivalTime,
              serviceUserId: item.serviceUserId,
              location: item.location,
            }
          }),
          referenceId: this.reference.map(item => item.id).join(','),
          quotationId: this.quotation.map(item => item.id).join(','),
          purchaseId: this.purchase.map(item => item.id).join(','),
          referenceNo: this.referenceNumber,
          quotationNo: this.quotationNumber,
          purchaseNo: this.purchaseNumber,
        }
        // 验证是否是新联系人
        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.contactId)) {
          param.customerContactId = this.groupForm.contactId;
          param.customerContactName = this.contacts.find(item => item.id === this.groupForm.contactId).name;
        } else {
          param.customerContactId = '';
          param.customerContactName = this.groupForm.contactId;
        }
        // 验证是否是新负责人
        if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.directorId)) {
          param.directorId = this.groupForm.directorId;
          param.director = this.directors.find(item => item.id === this.groupForm.directorId).name;
        } else {
          param.directorId = '';
          param.director = this.groupForm.directorId;
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        if (this.type == 1) {
          AddSubmitRequest(param).then(res => {
            if (res.success) {
              this.$message.success(msg);
              this.back();
            }
            this.groupForm.loading = false;
          })
        } else {
          EngineerSubmit(param).then(res => {
            if (res.success) {
              this.$message.success(msg);
              if (type === 1) {
                this.back();
              } else {
                this.$router.push({ path: '/home/sendOrder', query: { id: res.message } })
              }
            }
            this.groupForm.loading = false;
          })
        }
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
        this.$message.success(this.$i18n.t('message.del'))
      }).catch(() => { });
    },
    // 新增扫码
    showAdd (type) {
      if (type == 1) {
        this.faultData = null;
        this.FaultDialogVisble = true;
      } else {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.id = null;
        this.dialog.visible = true;
      }
    },
    // 修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.faultData = e;
      this.FaultDialogVisble = true;
    },
    // 添加故障设备
    submitDevice () {
      if (!this.tableData.every(item => item.maintenanceDeviceId !== this.dialog.id || this.dialog.id === null)) return this.$message.warning('The device has been added.')

      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        this.tableData.push({
          maintenanceDeviceId: this.dialog.id,
          deviceInfoName: this.dialog.name,
          faultDescription: this.dialog.describe,
        })
        this.dialog.visible = false;
      })
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.dialog, {
        id: data.id,
        name: data.deviceInfoName,
        code: data.qrCode,
      })
    },
    // 查询网点
    queryBrand (query) {
      let param = { name: query }
      SearchBrand(param).then(res => this.brands = res);
    },
    // 图片上传回调
    uploadCall (res, file, fileList) {
      if (res.success) {
        this.groupForm.fileList.push({
          name: res.message,
          url: `${this.baseUrl}/Export/download.do?fileName=${res.message}`
        });
      }
    },

    // 删除图片回调
    delCall (file, fileList) {
      this.groupForm.fileList = fileList;
    },
    // 图片预览
    previewImage (file) {
      let urls = this.groupForm.fileList.map(item => item.url);
      let index = this.groupForm.fileList.findIndex(item => item.name === file.name);
      let result = urls.splice(0, index)
      urls = urls.concat(result);
      this.preview.imageUrl = urls;
      this.preview.visible = true;
    },
    previewClose () {
      this.preview.visible = false;
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },

    faultSubmit ({ data, type }) {
      switch (type) {
        case 'add':
          this.tableData.push(data);
          break
        case 'edit':
          this.$set(this.tableData, data.rowIndex, data);
          break
      }
    },
    // 切换网点
    brandChange () {
      let brand = this.brands.find(item => item.id === this.groupForm.brand)
      this.groupForm.groupId = brand.groupId;

      this.getContact1();
      this.getContact0();
      this.serviceTypeChange();
    },
    // 获取负责人
    getContact1 () {
      GetContactAll2({
        customerOrgId: this.groupForm.brand,
        supervisor: 1,
      }).then(res => {
        this.directors = res
        if (!!this.groupForm.director && !this.directors.some(item => item.id === this.groupForm.directorId)) {
          this.directors.push({
            id: this.groupForm.directorId,
            name: this.groupForm.director,
          })
        }
      })
    },
    // 获取联系人
    getContact0 () {
      GetContactAll2({
        customerOrgId: this.groupForm.brand,
        supervisor: 0,
      }).then(res => {
        this.contacts = res
        if (!!this.groupForm.contactId && !this.contacts.some(item => item.id === this.groupForm.contactId)) {
          this.contacts.push({
            id: this.groupForm.contactId,
            name: this.groupForm.contact,
            phone: this.groupForm.tel,
          })
        }
      })
    },
    // 切换联系人
    contactChange (e) {
      if (/.{8}(-.{4}){3}-.{12}/.test(e)) {
        this.groupForm.tel = this.contacts.find(item => item.id === e).phone
      } else {
        this.groupForm.tel = '';
      }
    },
    // 显示编号弹框
    showNumber (type) {
      switch (type) {
        case 1:
          this.numberData = this.reference;
          break;
        case 2:
          this.numberData = this.quotation;
          break;
        case 3:
          this.numberData = this.purchase;
          break;
      }
      this.numberType = type;
      this.NumberDialogVisble = true;
    },
    // 提交编号
    numberSubmit (type, data) {
      switch (type) {
        case 1:
          this.reference = data;
          break;
        case 2:
          this.quotation = data;
          break;
        case 3:
          this.purchase = data;
          break;
      }
    },
    serviceTypeChange () {
      let param = {
        pathList: this.groupForm.serverType,
        brandId: this.groupForm.brand,
      }
      GetSLAPriorityByserverType(param).then(res => this.prioritys = res);
      this.groupForm.priority = '';
    },
    // 显示新客户签署人弹框
    showeSigntory (type) {
      this.submitSigntoryType = type;
      this.SigntoryDialogVisible = true;
    },
    submitSigntory (id, data) {
      if (this.submitSigntoryType === 1) {
        this.groupForm.directorId = id;
        this.getContact1();
      } else if (this.submitSigntoryType === 0) {
        this.groupForm.contactId = id;
        this.getContact0();
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.number = permission.includes('WorkOrderSchedulingBeallowedtoseeQuotationNo');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>