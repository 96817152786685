<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-row>
        <el-table
          border
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
        >
          <el-table-column
            min-width="130"
            prop="levelName"
            :label="$t('newSLAEscalationLevel.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="description"
            label="Description"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.col2')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.name}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.col5')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.position}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            show-overflow-tooltip
            :label="$t('newSLAEscalationLevel.col6')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.email1}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('newSLAEscalationLevel.col7')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.phone}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import { GetSLALevelList } from '@/api/warehouse/slaPolicies'

export default {

  props: ['visible', 'policyId', 'levelId'],
  data () {
    return {
      dialog: {
        visible: false,
        title: 'Contact List',
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: 9999,
        page: 1,
        search: '',
        policyId: this.policyId,
        levelId: this.levelId,
      }
      this.tableLoading = true;
      GetSLALevelList(param).then(res => {
        this.tableData = res.list.map(item => {
          let optionItem;
          if (item.type == 0) {
            optionItem = this.$global.warnings.find(warning => warning.id == item.level)
          } else {
            optionItem = this.$global.escalations.find(escalation => escalation.id == item.level)
          }
          item.levelName = optionItem.name;
          return item;
        });
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.label-item {
  label {
    height: 36px;
    line-height: 36px;
    display: block;
  }
  .el-select {
    width: 100%;
  }
}
</style>