<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dashBoard.overview.title1')"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-row :gutter="10">
        <div class="select-item">
          <label> {{$t('dashBoard.overview.label1')}} </label>
          <el-select
            :disabled="!!templatesId"
            size="medium"
            v-model="reportTemplateId"
            @change="reportTemplateChange"
            filterable
          >
            <el-option
              v-for="(item,index) in reportTemplates"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-row>
      <el-row :gutter="10">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
        >
          <el-table-column
            :label="$t('dashBoard.overview.col1')"
            width="80"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row.isCurrent"
                class="el-icon-check"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('dashBoard.overview.col2')"
            prop="chartTitle"
          ></el-table-column>
          <el-table-column
            min-width="150"
            show-overflow-tooltip
            sortable
            :label="$t('dashBoard.overview.col3')"
            prop="templatesName"
          >

          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :label="$t('dashBoard.overview.col4')"
            prop="chartType"
          >
            <template slot-scope="scope">
              <div
                class="chart-item"
                @click="submit(0, scope.row)"
              >
                <div
                  class="icon"
                  :class="charts[scope.row.chartType].icon"
                ></div>
                <span class="label">{{charts[scope.row.chartType].name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!templatesId"
            min-width="100"
            :label="$t('dashBoard.overview.col5')"
          >
            <template slot-scope="scope">
              <div
                class="chart-item"
                @click="submit(1, scope.row)"
              >
                <div class="icon icon-table"></div>
                <span class="label">Table</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <!-- <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >Set Current</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetReportChartList } from '@/api/reportDesigner'
import { GetTypeReport } from '@/api/reportTemplates'
export default {
  props: ['visible', 'layoutIndex', 'chartId', 'templatesId'],
  data () {
    return {
      dialog: {
        visible: false,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
      reportTemplates: [{ name: 'All', id: '' }],
      reportTemplateId: '',
      charts: {
        // { name: 'Area', icon: 'fa-area-chart', value: 'area', },
        0: { name: 'Bar', icon: 'icon-bar', value: 0, },
        1: { name: 'Pie', icon: 'icon-pie', value: 1, },
        2: { name: 'Line', icon: 'icon-line', value: 2, },
        999: { name: 'Icon', icon: 'icon-statistics', value: 999, },
      },
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.tableData = this.tableData.map(item => {
          item.isCurrent = item.id === this.chartId;
          return item;
        });
      }
    },
    '$route': function (to) {
      if (to.name === 'DashBoard') {
        this.getTableData();
      }
    },
    'templatesId': function () {
      this.reportTemplateId = this.templatesId
      this.reportTemplateChange();
    },
  },
  created () {
    this.getTableData();
    this.getTypeReport();
  },
  methods: {
    // 获取报表模板
    getTypeReport (id) {
      let param = {
        mode: ''
      }
      GetTypeReport(param).then(res => {
        this.reportTemplates = this.reportTemplates.concat(res);
      })
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        templatesId: this.reportTemplateId,
      }
      this.tableLoading = true;
      GetReportChartList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (item.id === this.chartId) {
            item.isCurrent = true;
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    reportTemplateChange () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交
    submit (type, data) {
      this.$emit('submit', type, data, this.layoutIndex);
      this.dialog.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.radio-wrap .el-radio {
  display: block;
  margin-bottom: 10px;
}
.el-table--enable-row-hover ::v-deep .el-table__body tr:hover > td {
  background-color: #99ccff !important;
}

.select-item {
  label {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;
  }
}
.chart-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  .icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    // background: #f2f2f2;
    margin-right: 10px;
    font-size: 20px;
  }
}
</style>