<template>
  <div class="card-wrap">
    <el-card
      class="chart-card"
      v-resize="resize"
      ref="card"
    >
      <div
        class="title"
        :class="{
          'title-icon':seriesType === 999
        }"
      >
        <span v-if="seriesType !== 999">{{chartTitle}}</span>
        <div class="tooltip">
          <el-dropdown
            trigger="click"
            @command="command"
          >
            <i class="btn el-icon-setting"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :disabled="!permit.dataPresentation"
                command="1"
              >Data Presentation</el-dropdown-item>
              <el-dropdown-item
                v-if="!report"
                command="2"
                :disabled="queryState"
              >Query Conditions</el-dropdown-item>
              <el-dropdown-item
                command="3"
                :disabled="sortingState"
              >Sorting</el-dropdown-item>
              <el-dropdown-item
                v-if="!report"
                command="4"
              >Risk Map</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <i
            class="btn el-icon-close"
            @click="cardClose(index)"
          ></i>
        </div>
      </div>
      <div
        class="con"
        :class="{
          'icon':seriesType === 999
        }"
      >
        <!-- <video
          controls
          autoplay
          style="width:100%;height:100%"
          src="https://cdn.cnbj1.fds.api.mi-img.com/mi-mall/aea7cbd55da35fda4e2c96b9d52a198a.mp4"
        ></video> -->
        <component
          ref="cell"
          :is="componentName"
          :data="data"
          :data-origin="dataOrigin"
          :cell-loading="cellLoading"
          @loadMore="loadMore"
        ></component>
      </div>

      <!-- <div
        v-if="data.type === 'map'"
        class="chart"
      >
        <div
          class="map"
          ref="map"
        ></div>
        <div
          class="risk"
          ref="risk"
        >
        </div>
      </div> -->
    </el-card>

  </div>
</template>

<script>
// 资源
// import HongKong from '../../../../static/map/HK.json'
// import HongKong from '../../../static/map/hongkong.json'

// 全局方法
import { formatDate } from '@/utils/common'

// 组件
import IconCell from '@/pages/dashBoard/components/IconCell'
import ChartCell from '@/pages/dashBoard/components/ChartCell'
import TableCell from '@/pages/dashBoard/components/TableCell'
// import TableCell from '@/pages/counterMode/components/TableCell'
// 接口
export default {
  components: {
    IconCell,
    ChartCell,
    TableCell,
  },
  props: ['data', 'index', 'commonParam', 'cellLoading', 'report'],
  data () {
    return {
      permit: {
        dataPresentation: true,
      },
      myMap: null,
      myRisk: null,
      // jsonMap: {
      //   'HongKong': HongKong,
      // },
      clock: null,

      page: {
        total: 0,
        size: 100,
        current: 1,
      },
      templatesId: '',
      chartTitle: '',
      iconCount: 0,
      seriesType: 0,
      dataOrigin: null,

      queryState: true,
      sortingState: true,
      componentName: '',
    }
  },
  watch: {
    'data': function () {
      // this.chartTitle = '';
      // this.queryState = true;
      // this.sortingState = true;
      // this.componentName = '';
    },
  },
  created () {
    this.dealPermission();
  },
  methods: {
    initData () {
      let chartData = this.data.data;
      let cardType = this.data.cardType || 0;
      this.myChart && this.myChart.clear();

      if (!!chartData) {
        this.templatesId = chartData.templateId;
        this.chartTitle = chartData.chartTitle;
        this.seriesType = chartData.chartType;
        this.dataOrigin = this.data.dataOrigin;
        this.queryState = false;
        this.sortingState = this.seriesType === 999;

        if (cardType === 0) {
          this.componentName = this.seriesType === 999 ? 'IconCell' : 'ChartCell';
        } else {
          this.seriesType = 0;
          this.componentName = 'TableCell';
        }
        this.$nextTick(() => {
          this.$refs.cell.init();
        })
      }
    },


    // 关闭卡片
    cardClose (index) {
      this.$confirm('Close operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$emit('cardClose', this.index)
      }).catch(() => { });
    },
    command (type) {
      switch (type) {
        case '1':
          this.$emit('showDialog', 1, {
            layoutIndex: this.index
          })
          break;
        case '2':
          this.$emit('showDialog', 2, {
            layoutIndex: this.index
          })
          break;
        case '3':
          this.$emit('showDialog', 3, {
            layoutIndex: this.index
          })
          break;
        case '4':
          break;
      }
    },
    resize () {
      if (this.$refs.card.$el.offsetWidth <= 0) return;
      clearTimeout(this.clock)
      this.clock = setTimeout(() => {
        switch (this.componentName) {
          case 'IconCell':
            this.$refs.cell.computeIcon();
            break;
          case 'ChartCell':
            if (!!this.$refs.cell.myChart) this.$refs.cell.myChart.resize();
            break;
          case 'TableCell':
            this.$refs.cell.resize();
            break;
        }
      }, 300);

    },
    // 放大地图
    screenFull () {
      let el = this.$refs.card.$el.parentElement.parentElement;
      if (el.classList.contains('screen-full'))
        el.classList.remove('screen-full')
      else
        el.classList.add('screen-full')
      this.resize();
    },

    mapChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myMap = this.$echarts.init(this.$refs.map);

      var areas = ['Central and Western', 'Wan Chai', 'Eastern', 'Southern', 'Yau Tsim Mong', 'Kowloon City', 'Sham Shui Po', 'Wong Tai Sin', 'Kwun Tong', 'Sai Kung', 'Sha Tin', 'Kwai Tsing', 'Tsuen Wan', 'Tuen Mun', 'Yuen Long', 'North', 'Tai Po', 'Islands']
      var _data = areas.map(item => {
        return { name: item, value: Math.floor(Math.random() * 100) }
      })
      _data[0].selected = true;
      // 指定图表的配置项和数据
      var option = { // 进行相关配置
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            var value = (params.value + '').split('.');
            value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
            return params.seriesName + '<br/>' + params.name + ': ' + value;
          }
        },
        visualMap: {
          show: false,
          left: 'right',
          min: 0,
          max: 100,
          inRange: {
            color: ['#80CAFF', '#003860']
          },
          text: ['High', 'Low'],           // 文本，默认为数值文本
          calculable: true
        },
        toolbox: {
          show: false,
        },
        series: [{
          name: 'Hong Kong',
          type: 'map',
          map: 'HongKong',
          roam: true,
          aspectScale: 1,
          itemStyle: {
            emphasis: {
              label: { show: true },
              areaColor: '#26B99A',
            }
          },
          data: _data
        }]
      }

      this.myMap.setOption(option, true);
      this.myMap.off('click')
      this.myMap.on('click', e => {
        // this.riskChart('risk');
        _data.forEach(item => {
          item.selected = item.name === e.name ? true : false;
        })
        this.myMap.setOption(option);
      })
    },

    riskChart (_data) {
      // 基于准备好的dom，初始化echarts实例
      this.myRisk = this.$echarts.init(this.$refs.risk);
      var data = JSON.parse(_data.pie)

      // 指定图表的配置项和数据
      var option = {
        color: [data[0].Color, data[1].Color, data[2].Color],
        tooltip: {
          confine: true,
        },
        title: {
          text: _data.name,
          x: 'center',
          top: '75%',
          textStyle: {
            fontSize: 16,
            fontWeight: 300,
          }
        },
        legend: {
          orient: 'horizontal',
          top: '85%',
          data: ['Level1', 'Level2', 'Level3']
        },
        series: [{
          type: 'pie',
          radius: ['40%', '60%'],
          label: {
            formatter: '{c}'
          },
          hoverAnimation: false,
          labelLine: {
            show: false,
            length: 5,
            length2: 5,
          },
          data: [
            { value: data[0].value || 0, name: 'Level1' },
            { value: data[1].value || 0, name: 'Level2' },
            { value: data[2].value || 0, name: 'Level3' },
          ]
        }]
      };
      // 使用刚指定的配置项和数据显示图表。
      this.myRisk.setOption(option, true);
      this.myRisk.off('click')
      this.myRisk.on('click', e => {
        let path = {
          1: '/home/counterMode',
          2: '/home/diningMode',
          3: '/home/userDefined'
        };
        let item = data[e.dataIndex];

        this.$router.push({
          path: path[_data.mode],
          query: {
            templatesId: _data.templatesId,
            name: _data.name,
            min: item.min,
            max: item.max,
          }
        })
      })
    },
    loadMore () {
      this.$emit('loadMore', this.index)
    },
    addData () {
      this.$refs.cell.addData();
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        // this.permit.dataPresentation = permission.includes('11550316-b8f8-4739-8cb9-3f8df170dca1');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-wrap {
  height: 100%;
}
.chart-card {
  height: 100%;
  border-radius: 0;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
  ::v-deep .el-card__body {
    height: 100%;
    // border-radius: 0;
  }

  .con {
    height: calc(100% - 24px);
    overflow: hidden;
  }
  .icon {
    height: 100%;
  }
}
.title {
  height: 24px;
  position: relative;
  padding-bottom: 5px;
  // border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tooltip {
    width: 42px;
    color: #535353;
    background: #fff;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    user-select: none;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    .el-dropdown {
      font-size: 0;
    }
    .btn {
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 5px;
      text-align: center;
      margin-left: 5px;
      font-size: 14px;
      &:hover {
        background: #b4bccc;
        color: #fff;
      }
    }
  }
  &.title-icon {
    border-radius: 5px;
    position: absolute;
    right: 10px;
  }
}

.screen-full {
  width: 100vw;
  height: 100vh;
  transform: none;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 999;
}
</style>