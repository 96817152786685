<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="150px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newSLAEscalationLevel.label1')"
              prop="name"
            >
              <el-input
                disabled
                v-model="groupForm.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newSLAEscalationLevel.label2')"
              prop="dataAccessGroup"
            >
              <el-select
                v-model="groupForm.dataAccessGroup"
                disabled
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.col11')"
            sortable
          >
            <template slot-scope="scope">
              <label v-if="scope.row.type === 0">Warning</label>
              <label v-if="scope.row.type === 1">Escalation</label>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="levelName"
            :label="$t('newSLAEscalationLevel.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="description"
            :label="$t('newSLAEscalationLevel.col10')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.col2')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.name}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.col5')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.position}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            show-overflow-tooltip
            :label="$t('newSLAEscalationLevel.col6')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.email1}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('newSLAEscalationLevel.col7')"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.phone}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 联系人弹框 -->
    <contact-dialog
      :visible.sync="contactDialogVisible"
      :policy-id="groupForm.id"
      :data="contactData"
      @update="getTableData"
    ></contact-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import ContactDialog from './components/ContactDialog'

// 接口
import {
  GetDataAccessGroupAll,
} from '@/api/organizations'
import {
  DelSLALevel,
  GetSLALevelList,
} from '@/api/warehouse/slaPolicies'

export default {
  name: 'newSLAEscalationLevel',
  components: {
    PageHeader,
    ContactDialog,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      contactDialogVisible: false,
      title: this.$i18n.t('newSLAEscalationLevel.title1'),
      dataAccessGroups: [],
      groupForm: {
        loading: false,
        id: null,
        name: '',
        dataAccessGroup: '',
      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      contactData: null,
      copyId: null,
    }
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;

    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getItem();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      let data = JSON.parse(sessionStorage.policyData);
      Object.assign(this.groupForm, {
        name: data.name,
        dataAccessGroup: data.accessGroupId,
      })
      this.getTableData();
    },
    // 提交
    submit () {
      this.back();
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        policyId: this.groupForm.id,
      }
      this.tableLoading = true;
      GetSLALevelList(param).then(res => {
        this.tableData = res.list.map(item => {
          let optionItem;
          if (item.type == 0) {
            optionItem = this.$global.warnings.find(warning => warning.id == item.level)
          } else {
            optionItem = this.$global.escalations.find(escalation => escalation.id == item.level)
          }
          item.levelName = optionItem.name;
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.contactData = null;
      this.contactDialogVisible = true;
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.contactData = e;
      this.contactDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelSLALevel(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.search = permission.includes('SLAPolicies-EscalationLevelSearch');
        this.permit.add = permission.includes('SLAPolicies-EscalationLevelAdd');
        this.permit.edit = permission.includes('SLAPolicies-EscalationLevelEdit');
        this.permit.del = permission.includes('SLAPolicies-EscalationLevelDelete');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 56px - 59px - 46px - 42px);
}
</style>