<template>
  <div class="wrap">
    <el-card>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          ref="table"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            prop="name"
            :label="$t('appRelease.col1')"
            sortable
          >
            <template slot-scope="scope">
              {{scope.row.name == 0 ? 'Android' : 'iOS'}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="type"
            :label="$t('appRelease.col2')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="version"
            :label="$t('appRelease.col3')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="apk"
            :label="$t('appRelease.col4')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="size"
            :label="$t('appRelease.col5')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="createTime"
            :label="$t('appRelease.col6')"
            sortable
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="createByName"
            :label="$t('appRelease.col7')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 汇率弹框 -->
    <add-release
      :visible.sync="AddReleaseVisible"
      @update="getTableData"
    ></add-release>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
import AddRelease from './components/AddRelease'
// 接口
import {
  GetApkList,
  DelApk,
} from '@/api/warehouse/appRelease'
export default {
  name: 'AppRelease',
  components: {
    ColSet,
    AddRelease,
  },
  data () {
    return {
      AddReleaseVisible: false,
      permit: {
        add: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        // search: this.searchVal,
      }
      this.tableLoading = true;
      GetApkList(param).then(res => {

        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示新增弹框
    showAdd () {
      this.AddReleaseVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelApk(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.add = permission.includes('appReleaseUploadNewapppackage');
        this.permit.del = permission.includes('appReleaseDeleteapppackage');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>