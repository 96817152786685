<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.export"
              size="medium"
            >{{$t('operation.export')}}</el-button>
            <el-button
              :disabled="!permit.print"
              size="medium"
            >{{$t('systemLog.btn1')}}</el-button>
          </el-button-group>
          <col-set @update="getTableData"></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            sortable
            prop="createTime"
            :label="$t('systemLog.col1')"
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="type"
            :label="$t('systemLog.col2')"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="category"
            :label="$t('systemLog.col3')"
          >
          </el-table-column>
          <el-table-column
            sortable
            show-overflow-tooltip
            prop="description"
            :label="$t('systemLog.col4')"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="userName"
            :label="$t('systemLog.col5')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
// 接口
import {
  GetSysLogList
} from '@/api/systemLog'
export default {
  name: 'SystemLog',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        export: true,
        print: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetSysLogList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    showUserDialog () {
      this.userDialog.visible = true;
    },

    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('SystemLogSearch');
        this.permit.export = permission.includes('SystemLogExport');
        this.permit.print = permission.includes('SystemLogPrint');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>
