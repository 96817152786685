var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('el-card',[_c('page-header',{attrs:{"title":_vm.title}},[_c('el-button',{attrs:{"size":"medium"},on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t('operation.cancel')))]),(_vm.permit.edit)?_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('operation.save')))]):_vm._e()],1),_c('el-form',{ref:"groupForm",staticClass:"group-form",attrs:{"model":_vm.groupForm,"rules":_vm.rules,"size":"medium","label-width":"120px"}},[_c('el-row',{attrs:{"gutter":50}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('newQuotation.label1'),"prop":"name"}},[_c('my-select',{attrs:{"data":_vm.deviceNames,"value":_vm.groupForm.itemName,"prop":{
                label:'name',
                value:'name'
              }},on:{"update:value":function($event){return _vm.$set(_vm.groupForm, "itemName", $event)},"change":_vm.deviceNameChange}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('newQuotation.label2'),"prop":"model"}},[_c('my-select',{attrs:{"data":_vm.models,"value":_vm.groupForm.model,"prop":{
              label:'model',
              value:'model'
            }},on:{"update:value":function($event){return _vm.$set(_vm.groupForm, "model", $event)}}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('newQuotation.label3'),"prop":"brand"}},[_c('my-select',{attrs:{"data":_vm.brands,"value":_vm.groupForm.brand,"prop":{
                label:'name',
                value:'name'
              }},on:{"update:value":function($event){return _vm.$set(_vm.groupForm, "brand", $event)}}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('newQuotation.label4'),"prop":"type"}},[_c('my-select',{attrs:{"data":_vm.types,"value":_vm.groupForm.type,"prop":{
                label:'name',
                value:'name'
              }},on:{"update:value":function($event){return _vm.$set(_vm.groupForm, "type", $event)}}})],1)],1)],1)],1),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('newQuotation.tab1'),"name":"tab1"}},[_c('quotation-list',{ref:"quotationList",attrs:{"data":_vm.quotationData,"total":_vm.total,"permit":_vm.permit},on:{"tableChange":_vm.initData}})],1),_c('el-tab-pane',{attrs:{"label":_vm.$t('newQuotation.tab2'),"name":"tab2"}},[_c('eol-alternative',{ref:"eolAlternative",attrs:{"data":_vm.groupForm,"permit":_vm.permit}})],1),_c('el-tab-pane',{attrs:{"label":_vm.$t('newQuotation.tab3'),"name":"tab3"}},[_c('specifications',{ref:"specifications",attrs:{"data":_vm.groupForm.specifications}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }