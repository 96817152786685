<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('engineerManagement.import.title1')"
      :visible.sync="dialog.visible"
      center
      width="1000px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table border          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>

          <el-table-column
            min-width="100"
            sortable
            :label="$t('engineerManagement.import.col1')"
            prop="name"
          ></el-table-column>
          <el-table-column
            min-width="170"
            sortable
            :label="$t('engineerManagement.import.col2')"
            prop="jobnumber"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('engineerManagement.import.col3')"
            prop="depName"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('engineerManagement.import.col4')"
            prop="position"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('engineerManagement.import.col5')"
            prop="mobile"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('engineerManagement.import.col6')"
            prop="email"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <el-row>
        <el-checkbox v-model="isCreate">{{$t('engineerManagement.import.label1')}}</el-checkbox>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import {
  GetDingtalkList,
  AddEngineer,
} from '@/api/warehouse/engineerManagement'
export default {
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
      },
      isCreate: true,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        startDate: '',
        endDate: '',
      }
      this.tableLoading = true;
      GetDingtalkList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 提交
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let data = this.tableSelected[0];
      let param = {
        name: data.name,
        number: data.jobnumber,
        personalID: data.personalID,
        position: data.position,
        workPhone: data.mobile,
        homePhone: '',
        email: data.email,
        status: 0,
        attendance: 0,
        leaveType: 0,
        startDate: '',
        endDate: '',
        photo: '',
        orgId: data.asOrgId,
        depId: '',
        dagId: '',
        sgId: '',
        rgId: '',
        userid: data.userid,
        source: 'Dingding',
        userAccout: Number(this.isCreate),
        depName: data.depName,
      }
      AddEngineer(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.import'))
          this.dialog.visible = false;
        } else {
          this.$message.warning(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>