<template>
  <div class="wrap">
    <div class="style-title">Page Orientation Style</div>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          size="small"
          icon="icon-portrait"
          @click="directionPage(0)"
        >Portrait</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          size="small"
          icon="icon-landscape"
          @click="directionPage(1)"
        >Landscape</el-button>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {

    }
  },
  created () {

  },
  methods: {
    // 修改方向
    directionPage (type) {
      this.elData.direction = type;
      if (type) {
        this.elData.width = 1123;
        this.elData.height = 794;
      } else {
        this.elData.width = 794;
        this.elData.height = 1123;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>