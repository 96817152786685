<template>
  <div
    class="wrap"
    ref="warp"
  >
    <el-table border      :data="tableData"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      :height="tableHeight"
      row-key="tableId"
      class="mode-table"
      stripe
      v-loadmore="loadMore"
      ref="table"
    >
      <el-table-column
        v-for="(item,index) in tableHeader"
        :key="index"
        :prop="item.prop"
        :min-width="item.width"
        :header-align="item.children!==null && item.children.length > 0 ? 'center' : ''"
        :label="item.label"
      >
        <el-table-column
          v-for="(childItem,childIndex) in item.children"
          :key="index + '-' +childIndex"
          :prop="childItem.prop"
          :min-width="childItem.width"
          :label="childItem.label"
        >
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['data', 'dataOrigin'],
  data () {
    return {
      page: {
        total: 0,
        size: 100,
        current: 1,
      },
      loadSign: true,
      tableHeader: [],
      tableData: [],
      tableLocalData: [],
      tableHeight: null,
      serialNum: 1,
      paging: [
        "58c9a3d5-22e5-4ab7-ae39-16bef91f1336",
        "2501e3c6-a2d7-4f5f-9965-28f860e2c24f",
        "227fa213-e969-4bba-be8a-80c443ee0cfb",
        "b685a572-fd1c-4113-8e71-a1dd700a90a5",
        "928390db-6c69-483e-9649-83c51f2e9252",
      ],
    }
  },
  methods: {
    init () {
      this.tableHeight = this.$refs.warp.offsetHeight;
      this.tableHeader = this.dataOrigin.header;
      this.tableLocalData = this.dealData(JSON.parse(JSON.stringify(this.dataOrigin.data.data)), this.dataOrigin.header);
      this.page.total = parseInt(this.dataOrigin.data.total);

      let start = (this.page.current - 1) * this.page.size;
      let end = start + this.page.size;
      this.tableData = this.tableLocalData.slice(0, end);
    },
    addData () {
      let data = this.dealData(JSON.parse(JSON.stringify(this.dataOrigin.data.data)), this.dataOrigin.header);
      this.tableLocalData = this.tableLocalData.concat(data)
      this.tableData = JSON.parse(JSON.stringify(this.tableLocalData));
      this.loadSign = true;
    },
    loadMore (resolve) {
      if (this.loadSign) {
        this.loadSign = false;
        this.page.current++;

        if (Math.ceil(this.page.total / this.page.size) < this.page.current) return this.$message.success('All data has been loaded');

        if (this.paging.includes(this.data.data.templateId)) {
          this.$emit('loadMore');
        } else {
          let start = (this.page.current - 1) * this.page.size;
          let end = start + this.page.size;
          this.tableData = this.tableLocalData.slice(0, end);
          this.loadSign = true;
        }
      }
    },
    resize () {
      this.tableHeight = this.$refs.warp.offsetHeight;
      this.$refs.table.doLayout();
    },
    // 处理数据
    dealData (data, header) {
      let jsonVar = {};
      this.headerTraversal(header, jsonVar)
      data = data.map(item => {
        item.children = [];
        item.tableId = this.serialNum++;
        let count = 0;

        for (let key in jsonVar) {
          if (count < item[key].length) count = item[key].length;
        }

        if (count > 0) {
          for (let key in jsonVar) {
            for (let i = 0; i < count - 1; i++) {
              if (!item.children[i]) item.children[i] = { tableId: this.serialNum++ };
              item.children[i].order_no = null;
              item.children[i][key] = item[key][i + 1] || {};
              if (!!item.children[i][key].card_no) item.children[i][key].card_no = item.children[i][key].card_no.replace(/.{4}$/, '****');
            }
            item[key] = item[key][0] || {};
            if (!!item[key].card_no) item[key].card_no = item[key].card_no.replace(/.{4}$/, '****');
          }
        }

        if (!!item.card_no) item.card_no = item.card_no.replace(/.{4}$/, '****');
        return item;
      })
      return data;
    },
    // 遍历出需要解析的字段
    headerTraversal (data, result) {
      data.forEach(item => {
        if (/^(.+)\.(.+)$/.test(item.prop)) {
          var key = item.prop.split('.');
          result[key[0]] = 1;
        }
        if (item.children.length > 0) {
          this.headerTraversal(item.children, result)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 0;
}
</style>