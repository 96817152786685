<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          prop="createTime"
          :label="$t('allocateGoods.changeRecord.col1')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="operateFlag"
          :label="$t('allocateGoods.changeRecord.col2')"
          sortable
        >
          <template slot-scope="scope">
            <span v-if="scope.row.operateFlag === 1">新增</span>
            <span v-if="scope.row.operateFlag === 2">修改</span>
            <span v-if="scope.row.operateFlag === 3">替换</span>
            <span v-if="scope.row.operateFlag === 4">删除</span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="projectName"
          :label="$t('allocateGoods.changeRecord.col3')"
          sortable
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="deviceName"
          :label="$t('allocateGoods.changeRecord.col4')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="brandName"
          :label="$t('allocateGoods.changeRecord.col5')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="deviceModel"
          :label="$t('allocateGoods.changeRecord.col6')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="count"
          :label="$t('allocateGoods.changeRecord.col7')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="reason"
          :label="$t('allocateGoods.changeRecord.col8')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="fullname"
          :label="$t('allocateGoods.changeRecord.col9')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          prop="accountStatus"
          :label="$t('allocateGoods.changeRecord.col10')"
          sortable
        >
          <div
            class="file-box"
            slot-scope="scope"
          >
            <div
              v-for="(item,index) in scope.row.attachment"
              :key="index"
              class="file-item"
            >
              <div
                class="block"
                @click="previewFile(item)"
              >
                <img :src="'./static/image/fileType/' + item.formats + '.png'">
              </div>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
    <!-- 新增设备 -->
    <add-equipment
      :visible.sync="AddEquipmentVisible"
      :permit="permit"
      :is-record="true"
      :data="equipmentData"
    ></add-equipment>
    <!-- 替换弹框 -->
    <replace-dialog
      :visible.sync="ReplaceDialogVisible"
      :data="equipmentData"
      :is-record="true"
    ></replace-dialog>
  </div>
</template>

<script>
// 组件
import AddEquipment from "./AddEquipment";
import ReplaceDialog from "./ReplaceDialog";
// 接口
import { GetUpdateRecordList } from '@/api/warehouse/purchaseRequisition'

export default {
  components: {
    AddEquipment,
    ReplaceDialog,
  },
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      permit: {
        request: true,
        showPOAmount: true,
        editAll: true,
        editSelf: true,
      },
      AddEquipmentVisible: false,
      ReplaceDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      equipmentData: {},
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab3') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
        // this.$refs.table.doLayout();
      }
    }
  },
  created () {
    this.dealPermission();

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        applicationFormId: this.$route.query.id
      }
      this.tableLoading = true;
      GetUpdateRecordList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (!item.attachment) {
            item.attachment = [];
          } else {
            item.attachment = item.attachment.split(',').map(item => {
              let formats = item.match(/[^.]*$/)[0];
              return { name: item, formats: formats }
            })
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    dblclickEdit (row) {
      this.equipmentData = row;
      if(row.operateFlag === 3){
        this.ReplaceDialogVisible = true;
      }else{
        this.AddEquipmentVisible = true;
      }

    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(",");
        this.permit.request = permission.includes("PurchaseRequisitionRequestApproval");
        this.permit.showPOAmount = permission.includes('PurchaseRequisitionBeallowedtoseetheprice');
        this.permit.editAll = permission.includes('PurchaseRequisitionEdit-Allitems');
        this.permit.editSelf = permission.includes('PurchaseRequisitionEdit-Toonlyallowausertoedithisownitems')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.file-box {
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  .block {
    width: 50px;
    height: 50px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
</style>