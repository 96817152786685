import request from '@/utils/request'

// 查询数据类型列表
export const GetDataFieldList = (data) => request({
  method: 'post',
  url: '/DataField/queryByPage.do',
  data: JSON.stringify(data)
})

// 查询数据类型集合
export const GetDataFieldAll = (data) => request({
  method: 'post',
  url: '/DataField/queryByCondition.do',
  data: JSON.stringify(data)
})

// 添加、修改数据类型
export const AddDataField = (data) => request({
  method: 'post',
  url: '/DataField/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除数据类型
export const DelDataField = (data) => request({
  method: 'post',
  url: '/DataField/del.do',
  data: JSON.stringify(data)
})

// 集体添加、修改数据类型
export const AddDataFieldAll = (data) => request({
  method: 'post',
  url: '/DataField/addOrUpdateList.do',
  data: JSON.stringify(data)
})

// 批量修改设备
export const EditDeviceInfoAll = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/updateData.do',
  data: JSON.stringify(data)
})

// 查询未绑定数据类型
export const GetUnboundList = (data) => request({
  method: 'post',
  url: '/DataField/queryNoMainFieldId.do',
  data: JSON.stringify(data)
})

// 查询关联数据
export const GetboundList = (data) => request({
  method: 'post',
  url: '/DataField/queryMainFieldId.do',
  data: JSON.stringify(data)
})

// 添加绑定
export const AddUnbound = (data) => request({
  method: 'post',
  url: '/DataFieldLink/add.do',
  data: JSON.stringify(data)
})

// 删除绑定
export const DelUnbound = (data) => request({
  method: 'post',
  url: '/DataFieldLink/del.do',
  data: JSON.stringify(data)
})

// 查询关联数据2
export const GetboundList2 = (data) => request({
  method: 'post',
  url: '/DataField/querySubFieldId.do',
  data: JSON.stringify(data)
})

// 合并设备类型
export const MergeItemType = (data) => request({
  method: 'post',
  url: '/DataField/mergeItemType.do',
  data: JSON.stringify(data)
})
// 合并品牌
export const MergeBrand = (data) => request({
  method: 'post',
  url: '/DataField/mergeBrand.do',
  data: JSON.stringify(data)
})
// 合并供应商
export const MergeSupplier = (data) => request({
  method: 'post',
  url: '/DataField/mergeSupplier.do',
  data: JSON.stringify(data)
})
// 合并系统类型
export const MergeSystemCategory = (data) => request({
  method: 'post',
  url: '/DataField/mergeSystemCategory.do',
  data: JSON.stringify(data)
})
// 合并故障主类型
export const MergeIssueMainType = (data) => request({
  method: 'post',
  url: '/DataField/mergeIssueMainType.do',
  data: JSON.stringify(data)
})
// 合并服务类型
export const MergeServiceCategory = (data) => request({
  method: 'post',
  url: '/DataField/mergeServiceCategory.do',
  data: JSON.stringify(data)
})
// 合并收货地址
export const MergeDeliverAddress = (data) => request({
  method: 'post',
  url: '/DataField/mergeDeliverAddress.do',
  data: JSON.stringify(data)
})
