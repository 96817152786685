<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="1000px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-row :gutter="20">
          <el-col :span="8">

            <el-form-item
              :label="$t('allocateGoods.addEquipment.label1')"
              prop="deviceName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.deviceName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button @click="searchData">{{$t("operation.search")}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.addEquipment.label2')"
              prop="typeName"
            >
              <el-input
                v-model="dialog.typeName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.addEquipment.label3')"
              prop="brandName"
            >
              <el-input
                v-model="dialog.brandName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.addEquipment.label4')"
              prop="deviceModel"
            >
              <el-input
                v-model="dialog.deviceModel"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.addEquipment.label11')"
              prop="detailed"
            >
              <el-input
                class="detailed"
                type="textarea"
                v-model="dialog.detailed"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item
                  :label="$t('allocateGoods.addEquipment.label5')"
                  prop="count"
                >
                  <el-input-number
                    v-model="dialog.count"
                    class="search-input"
                    :controls="false"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">

                <el-form-item
                  :label="$t('allocateGoods.addEquipment.label12')"
                  prop="unit"
                >
                  <el-select
                    v-model="dialog.unit"
                    filterable
                  >
                    <el-option
                      v-for="item in units"
                      :key="item.id"
                      :value="item.name"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="permit.showPOAmount"
              :gutter="10"
            >
              <el-col :span="12">
                <el-form-item
                  :label="$t('allocateGoods.addEquipment.label9')"
                  prop="singlePrice"
                >
                  <el-input-number
                    v-model="dialog.singlePrice"
                    :controls="false"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('allocateGoods.addEquipment.label10')">
                  <el-input
                    v-model="totalPrices"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item
                  :label="$t('allocateGoods.addEquipment.label6')"
                  prop="useTo"
                >
                  <el-input v-model="dialog.useTo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('allocateGoods.addEquipment.label7')"
                  prop="expecteBuyBackTime"
                >
                  <el-date-picker
                    v-model="dialog.expecteBuyBackTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-row
                v-if="!!data && data.operateFlag === 4"
                :gutter="10"
              >
                <el-col :span="24">
                  <el-form-item
                    label="删除原因"
                    prop="useTo"
                  >
                    <el-input v-model="dialog.deleteReason"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <!-- <el-form-item
              :label="$t('allocateGoods.addEquipment.label8')"
              prop="remark"
            >
              <el-input v-model="dialog.remark"></el-input>
            </el-form-item> -->
          </el-col>
          <el-col :span="16 ">
            <el-form-item
              :label="$t('allocateGoods.label4')"
              prop="projectName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.projectName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button @click="ProjectDialogVisible = true">{{ $t("operation.search") }}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.label5')"
              prop="quotationNumber"
            >
              <el-input
                disabled
                v-model="dialog.quotationNumber"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.label6')"
              prop="quotationTypeId"
            >
              <el-select
                v-model="dialog.quotationTypeId"
                filterable
              >
                <el-option
                  v-for="item in contractTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.label7')"
              prop="customerApprovalId"
            >
              <el-select
                v-model="dialog.customerApprovalId"
                filterable
              >
                <el-option
                  v-for="item in clientApprovals"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('allocateGoods.addEquipment.label8')"
              prop="remark"
            >
              <el-input
                class="remark"
                type="textarea"
                v-model="dialog.remark"
                :rows="4"
              ></el-input>
            </el-form-item>
            <div class="recipient-box">
              <div class="label-box">
                <span>
                  收货信息
                </span>
                <div
                  v-if="!isRecord"
                  class="btn-box"
                >
                  <el-button
                    size="small"
                    @click="addItem"
                  >增加</el-button>
                  <el-button
                    size="small"
                    @click="delItem"
                  >删除</el-button>
                </div>
              </div>
              <table class="recipient-table">
                <tr>
                  <th></th>
                  <th>数量</th>
                  <th>收货地址</th>
                  <th>联系人</th>
                  <th>电话</th>
                </tr>
                <tr
                  v-for="(item,index) in dialog.deliveryInfos"
                  :key="index"
                >
                  <td>
                    <el-checkbox v-model="item.checked"></el-checkbox>
                  </td>
                  <td>
                    <el-input-number
                      size="small"
                      v-model="item.count"
                      :controls="false"
                      :min="0"
                    ></el-input-number>
                  </td>
                  <td>
                    <my-select
                      size="small"
                      :data="addresss"
                      :value.sync="item.addressId"
                      :prop="{
                        label:'name',
                        value:'id'
                      }"
                      @change="addressChange(item, true)"
                    ></my-select>
                  </td>
                  <td>
                    <my-select
                      size="small"
                      :disabled="!item.addressId"
                      :data="item.recipients"
                      :value.sync="item.consigneeId"
                      :prop="{
                        label:'name',
                        value:'id'
                      }"
                      @change="consigneeChange(item)"
                    ></my-select>
                  </td>
                  <td>
                    <el-input
                      size="small"
                      v-model="item.phone"
                    ></el-input>
                  </td>
                </tr>
              </table>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t("operation.cancel")}}</el-button>
        <el-button
          v-if="!isRecord && permit.edit"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t("operation.save")}}</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :is-create="true"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <!-- 项目弹框 -->
    <project-dialog
      :visible.sync="ProjectDialogVisible"
      @submit="projectSubmit"
    ></project-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
import EquipmentDialog from "@/components/EquipmentDialog/EquipmentDialog3";
import ProjectDialog from "@/components/ProjectDialog";
// 接口
import { GetReceiptContactList } from '@/api/warehouse/dataMaintenance/receiptAddress'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import {
  GetUpdateRecordItem,
  DelReceipt,
  GetReceiptList,
  AddReceipt,
  EditApplyDevice,
} from '@/api/warehouse/purchaseRequisition'

export default {
  components: {
    MySelect,
    EquipmentDialog,
    ProjectDialog,
  },
  props: ["visible", "data", 'permit', 'isRecord'],
  data () {
    return {
      EquipmentDialogVisible: false,
      ProjectDialogVisible: false,
      units: [],
      recipients: [],
      contractTypes: [],
      addresss: [],
      clientApprovals: [],
      dialog: {
        visible: false,
        title: '',
        deviceInfoId: '',
        deviceName: '',
        typeName: '',
        brandName: '',
        deviceModel: '',
        count: '',
        unit: '',
        useTo: '',
        expecteBuyBackTime: null,
        detailed: '',

        singlePrice: 0,
        projectName: '',
        projectContractId: '',
        quotationNumber: '',
        quotationTypeId: '',
        customerApprovalId: '',
        addressId: '',
        consigneeId: '',
        remark: '',
        deliveryInfos: [],
      },
      rules: {
        deviceName: [{ required: true, message: this.$i18n.t("message.notNull"), trigger: "blur" }],
        count: [{ required: true, message: this.$i18n.t("message.notNull"), trigger: "blur" }],
        projectName: [{ required: true, message: this.$i18n.t("message.notNull"), trigger: "blur" }],
      },
    };
  },
  computed: {
    totalPrices () {
      if (!!this.dialog.count && !!this.dialog.singlePrice) {
        return (
          parseInt(this.dialog.count) * parseFloat(this.dialog.singlePrice)
        );
      } else {
        return '';
      }
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.dialog.id = null;
        if (this.isRecord) {
          switch (this.data.operateFlag) {
            case 1:
              this.dialog.title = '新增记录';
              break;
            case 2:
              this.dialog.title = '修改记录';
              break;
            case 4:
              this.dialog.title = '删除记录';
              break;
          }
          this.getLog();
        } else {
          if (!!this.data) {
            this.$nextTick(() => {
              Object.assign(this.dialog, this.data);
              if (!this.permit.edit) {
                this.dialog.title = '查看设备';
              } else {
                this.dialog.title = this.$i18n.t("allocateGoods.addEquipment.title2");
              }
              this.getReceiptList();
            });
          } else {
            this.dialog.title = this.$i18n.t("allocateGoods.addEquipment.title1");
            this.dialog.deliveryInfos = this.dialog.deliveryInfos.map(item => {
              item.id = null;
              return item;
            })
          }
        }

        // 获取计数单位
        if (this.units.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000011' }).then(res => this.units = res);
        // 获取合同报价类型
        if (this.contractTypes.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000012' }).then(res => this.contractTypes = res);
        // 获取收货地址
        if (this.addresss.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000014' }).then(res => this.addresss = res);
        // 获取客户审批
        if (this.clientApprovals.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000013' }).then(res => this.clientApprovals = res);
      }
    },
    "dialog.visible": function (newVal) {
      if (!newVal) {
        this.$emit("update:visible", false);
      }
    }
  },
  created () {

  },
  methods: {
    searchData () {
      this.EquipmentDialogVisible = true;
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.dialog, {
        deviceInfoId: data.id,
        typeName: data.typeName,
        deviceName: data.name,
        brandName: data.brandName,
        deviceModel: data.model,
        singlePrice: data.singlePrice || 0,
        unit: data.unit,
      });
      this.$refs.dialogForm.validateField("deviceName");
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        if (!!this.data && !!this.data.id) {
          this.saveReceipt(this.submitData);
        } else {
          this.submitData();
        }
      });
    },
    submitData () {
      let data = JSON.parse(JSON.stringify(this.dialog));
      // if (data.id) data.operateFlag = 2;
      this.$emit("submit", {
        data: data,
        type: !!this.data ? "edit" : "add"
      });
      this.dialog.visible = false;
    },
    // 合同提交
    projectSubmit (data) {
      this.dialog.projectName = data.name;
      this.dialog.quotationNumber = data.quotationNumber;
      this.dialog.projectContractId = data.id;
    },
    addressChange (data, reset) {
      if (!/.{8}(-.{4}){3}-.{12}/.test(data.addressId)) {
        data.consigneeId = '';
        data.recipients = [];
        return
      }
      if (reset) {
        data.consigneeId = '';
      }
      let param = {
        limit: 9999,
        page: 1,
        search: '',
        addressId: data.addressId,
      }
      GetReceiptContactList(param).then(res => data.recipients = res.list)
    },
    consigneeChange (data) {
      if (!/.{8}(-.{4}){3}-.{12}/.test(data.addressId)) return;
      let item = data.recipients.find(item => item.id === data.consigneeId);
      data.phone = !!item ? item.phoneNumber : '';
    },
    getLog () {
      GetUpdateRecordItem({ id: this.data.id }).then(res => {
        Object.assign(this.dialog, res);
        this.getReceiptList();
      })
    },
    // 获取收货信息
    getReceiptList () {
      if (!this.data.id) return;
      GetReceiptList({ appFormDeviceId: this.data.id }).then(res => {
        this.dialog.deliveryInfos = res.map(item => {
          item.checked = false;
          item.recipients = [];
          return item;
        });
        this.dialog.deliveryInfos.forEach(item => {
          this.addressChange(item, false)
        })
      })
    },
    addItem () {
      this.dialog.deliveryInfos.push({
        checked: false,
        count: 0,
        addressId: '',
        consigneeId: '',
        phone: '',
        recipients: [],
      })
    },
    delItem () {
      let param = {
        id: this.dialog.deliveryInfos.filter(item => item.checked).map(item => item.id).join(',')
      }
      DelReceipt(param).then(res => {
        if (res.success) {
          this.dialog.deliveryInfos = this.dialog.deliveryInfos.filter(item => !item.checked)
        }
      })
    },
    // 保存收货信息
    saveReceipt (fun) {
      let param = this.dialog;
      param.deliveryInfos = this.dialog.deliveryInfos.map(item => {
        item.appFormDeviceId = this.data.id;
        // 验证是否是新收货地址
        if (!/.{8}(-.{4}){3}-.{12}/.test(item.addressId)) {
          item.address = item.addressId;
          item.addressId = '';
        }
        // 验证是否是新收件人
        if (!/.{8}(-.{4}){3}-.{12}/.test(item.consigneeId)) {
          item.consignee = item.consigneeId;
          item.consigneeId = '';
        }
        return item
      })

      EditApplyDevice(param).then(res => {
        if (res.success) {
          let data = JSON.parse(res.message);
          data.deliveryInfos = data.deliveryInfos.map(item => {
            item.checked = false;
            item.recipients = [];
            return item;
          })
          Object.assign(this.dialog, data);

          fun();
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }

  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-input-number {
    width: 100%;
    ::v-deep .el-input__inner {
      text-align: left;
    }
  }
}

.recipient-box {
  .label-box {
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
  }
  .recipient-table {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
