import request from '@/utils/request'

// 获取面板数据
export const GetPanelData = (data) => request({
  method: 'post',
  url: '/dashBoard/queryDashboardRequest01.do',
  data: JSON.stringify(data)
})

// 风险分析
export const GetRiskAnalytics = (data) => request({
  method: 'post',
  url: '/dashBoard/queryDashboardPieGraph.do',
  data: JSON.stringify(data)
})

// 获取默认布局
export const GetLayout = () => request({
  method: 'get',
  url: `/sysUserPanelLayout/getLayout.do`,
})

// 获取布局列表
export const GetLayoutList = (data) => request({
  method: 'post',
  url: `/sysUserPanelLayout/queryByPage.do`,
  data: JSON.stringify(data),
})

// 新增、修改布局
export const AddLayout = (data) => request({
  method: 'post',
  url: `/sysUserPanelLayout/addOrUpdate.do`,
  data: JSON.stringify(data),
})

// 删除布局
export const DelLayout = (id) => request({
  method: 'post',
  url: `/sysUserPanelLayout/deleted/${id}.do`,
})

// 记录用户布局
export const LogUserLayout = (id) => request({
  method: 'post',
  url: `/sysUserPanelLayout/save/${id}.do`,
})

// 根据组织查询布局
export const GetLayoutByOrg = (id) => request({
  method: 'get',
  url: `/sysUserPanelLayout/queryByOrganization/${id}.do`,
})

// 根据数据访问组查询布局
export const GetLayoutByDag = (id) => request({
  method: 'get',
  url: `/sysUserPanelLayout/queryByAccessGroup/${id}.do`,
})