<template>
  <div class="wrap">
    <div class="style-title">Page Number</div>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          size="small"
          @click="setTextAlign('left')"
        >Left -1-</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          size="small"
          @click="setTextAlign('center')"
        >Center -1-</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          size="small"
          @click="setTextAlign('right')"
        >Right -1-</el-button>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {

    }
  },
  created () {

  },
  methods: {
    setTextAlign (type) {
      this.elData.pageIndex.style.textAlign = type;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>