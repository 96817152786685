import request from '@/utils/request'

// 获取文件夹树
export const GetFolderTree = (data) => request({
  method: 'get',
  url: `/project/document/queryDocumentation.do`,
})
// 获取文件树
export const GetFileTree = (data) => request({
  method: 'get',
  url: `/project/document/queryAll.do`,
})

// 查询文件夹的文件
export const GetFileList = (id) => request({
  method: 'get',
  url: `/project/document/parent/${id}.do`,
})

// 新增和编辑
export const AddDocument = (data) => request({
  method: 'post',
  url: `/project/document/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 删除
export const DelDocument = (id) => request({
  method: 'post',
  url: `/project/document/deleted/${id}.do`,
})