<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('score.title1')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>
      <div
        class="score-item"
        v-for="(item,index) in score"
        :key="index"
      >
        <div class="score-label">{{item.title}}</div>
        <div class="score-box">
          <div class="score-text">{{scoreText[item.star]}}</div>
          <div class="score-star">
            <span
              v-for="star in 5"
              :key="index + '-' + star"
              class="star"
              :class="{'active':star <= item.star}"
              @click="clickStar(index,star)"
            >★</span>
          </div>
        </div>
      </div>
      <div class="result">
        {{$t('score.label1')}}：<span class="orange">{{points}} ({{$t('score.label2')}})</span> ÷ <span class="orange">30 ({{$t('score.label3')}})</span> × 100% = <span class="orange">{{result}}</span>
      </div>
      <!-- <el-divider content-position="left">Description</el-divider> -->
      <el-divider content-position="left">{{$t('score.subtitle1')}}</el-divider>
      <el-input
        type="textarea"
        :rows="4"
        v-model="groupForm.description"
      ></el-input>
    </el-card>

  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  GetEvaluationList,
  Evaluation,
} from '@/api/warehouse/score'
export default {
  name: 'Score',
  components: {
    PageHeader,
  },
  data () {
    return {
      groupForm: {
        loading: false,
        id: null,
        description: '',
      },
      scoreText: [
        '',
        this.$i18n.t('score.tag1'),
        this.$i18n.t('score.tag2'),
        this.$i18n.t('score.tag3'),
        this.$i18n.t('score.tag4'),
        this.$i18n.t('score.tag5')
      ],
      score: [],
    }
  },
  computed: {
    'points': function () {
      return this.score.reduce((total, item) => total + item.star, 0)
    },
    'result': function () {
      return (this.points / 30 * 100).toFixed(2);
    }
  },
  created () {
    this.groupForm.id = this.$route.query.id;
    this.getEvaluationList();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getEvaluationList () {
      let param = { id: this.groupForm.id }
      GetEvaluationList(param).then(res => {
        this.groupForm.description = res[0].descripionItem
        this.score = res.map(item => {
          return {
            id: item.id,
            title: item.description,
            star: item.score || 5,
          }
        });
      })
    },
    // 点击星星
    clickStar (index, star) {
      let item = this.score[index];
      item.star = star;
    },
    // 提交
    submit () {
      let param = this.score.map(item => {
        return {
          confirmOrderDetailId: this.groupForm.id,
          evaluationItemId: item.id,
          score: item.star,
          description: this.groupForm.description,
        }
      })

      this.groupForm.loading = true;
      Evaluation(param).then(res => {
        if (res.success) {
          this.$message.success('Successful evaluation') //评价成功
          this.back();
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.score-item {
  padding: 20px 0px;
  border-bottom: solid 1px #eee;

  .score-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .score-box {
    display: flex;
    align-items: center;

    .score-text {
      width: 120px;
      height: 32px;
      line-height: 32px;
      border: solid 1px #f5732c;
      margin-right: 20px;
      color: #f5732c;
      font-size: 14px;
      text-align: center;
    }
  }
  .score-star {
    font-size: 36px;
    .star {
      cursor: pointer;
      margin: 0 3px;
      color: #e3e3e3;
    }
    .active {
      color: #f5732c;
    }
  }
}
.orange {
  color: #f5732c;
}
.result {
  margin: 20px 0;
}
</style>