<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newSLAEscalationLevel.contactDialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-row :gutter="100">
        <el-col :span="12">
          <div class="label-item">
            <label>{{$t('newSLAEscalationLevel.contactDialog.label1')}}</label>
            <el-select
              size="medium"
              v-model="dialog.type"
            >
              <el-option
                label="Warning"
                :value="0"
              ></el-option>
              <el-option
                label="Escalation"
                :value="1"
              ></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="label-item">
            <label>{{$t('newSLAEscalationLevel.contactDialog.label2')}}</label>
            <el-select
              size="medium"
              v-model="dialog.level"
            >
              <el-option
                v-for="item in levels"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="label-item">
          <label>{{$t('newSLAEscalationLevel.contactDialog.label3')}}</label>
          <el-input
            size="medium"
            v-model="dialog.description"
          ></el-input>
        </div>
      </el-row>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.contactDialog.col1')"
            prop="name"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.contactDialog.col2')"
            prop="post"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.contactDialog.col3')"
            prop="email1"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('newSLAEscalationLevel.contactDialog.col4')"
            prop="phone"
          ></el-table-column>
        </el-table>
      </el-row>
      <!-- <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row> -->
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialog.loading"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
    <!-- 联系人弹框 -->
    <contact-dialog
      :visible.sync="ContactDialogVisible"
      @submit="contactSubmit"
    ></contact-dialog>
  </div>
</template>

<script>
// 组件
import ContactDialog from '@/components/ContactDialog'
// 接口
import {
  AddSLALevel,
  DelContract,
} from '@/api/warehouse/slaPolicies'
export default {
  components: {
    ContactDialog
  },
  props: ['visible', 'policyId', 'data'],
  data () {
    return {
      ContactDialogVisible: false,
      dialog: {
        visible: false,
        loading: false,
        type: 0,
        level: 1,
        description: '',
        contactList: [],
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  computed: {
    levels: function () {
      return this.dialog.type === 0 ? this.$global.warnings : this.$global.escalations;
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.searchVal = '';
        this.tableSelected = [];

        if (this.data) {
          Object.assign(this.dialog, {
            type: this.data.type,
            level: parseInt(this.data.level),
            description: this.data.description,
            contactList: this.data.contactList,
          })
        } else {
          Object.assign(this.dialog, {
            type: 0,
            level: 1,
            description: '',
            contactList: [],
          })
        }

        this.dialog.visible = this.visible;
        this.searchData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    searchData () {
      this.tableData = this.dialog.contactList.filter(item =>
        !this.searchVal
        || (!!item.name && item.name.indexOf(this.searchVal) !== - 1)
        || (!!item.post && item.post.indexOf(this.searchVal) !== - 1)
        || (!!item.email1 && item.email1.indexOf(this.searchVal) !== - 1)
        || (!!item.phone && item.phone.indexOf(this.searchVal) !== - 1))
    },
    // 提交
    submit () {
      if (this.dialog.contactList.length === 0) return this.$message.warning('Please select one item.')

      let param = {
        type: this.dialog.type,
        level: this.dialog.level,
        description: this.dialog.description,
        policyId: this.policyId,
        contactIds: this.dialog.contactList.map(item => item.id).join('.'),
      }
      this.dialog.loading = true;
      AddSLALevel(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.add'))
          this.dialog.visible = false;
          this.dialog.loading = false;
          this.$emit('update');
        }
      })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },

    showAdd () {
      this.ContactDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkids = this.tableSelected.map(item => item.id);
        this.dialog.contactList = this.dialog.contactList.filter(item => !checkids.includes(item.id))

        let param = {
          type: this.dialog.type,
          level: this.dialog.level,
          policyId: this.policyId,
          contactIds: checkids.join(','),
        }
        DelContract(param).then(res => {
          if (res.success) {
            this.dialog.contactList = this.dialog.contactList.filter(item => !checkids.includes(item.id))
            this.searchData();
            this.$emit('update')
          }
        })
      }).catch(() => { });
    },
    // 联系人提交
    contactSubmit (data) {
      let ids = this.dialog.contactList.map(item => item.id).join(',');
      data = data.filter(item => {
        if (ids.includes(item.id)) {
          this.$message.warning(`${item.name}已添加`)
          return false;
        } else {
          return true;
        }
      })
      this.dialog.contactList = this.dialog.contactList.concat(data);
      this.searchData();
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.label-item {
  label {
    height: 36px;
    line-height: 36px;
    display: block;
  }
  .el-select {
    width: 100%;
  }
}
</style>