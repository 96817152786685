<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('SupplierDialog.title2')"
      :visible.sync="dialogVisible"
      center
      width="950px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          @row-dblclick="dblclick"
        >
          <el-table-column
            min-width="220"
            :label="$t('SupplierDialog.col1')"
            prop="name"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('SupplierDialog.col2')"
            prop="abbreviation"
          ></el-table-column>
          <el-table-column
            min-width="130"
            label="联系人"
          >
            <template slot-scope="scope">
              <div
                class="td-row"
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.name}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            label="电话"
          >
            <template slot-scope="scope">
              <div
                class="td-row"
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.phoneNumber}}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            label="职位"
          >
            <template slot-scope="scope">
              <div
                class="td-row"
                v-for="(item,index) in scope.row.contactList"
                :key="index"
              >{{item.postName}}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="70">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="showEdit(scope.row)"
              >{{$t('operation.edit')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
    </el-dialog>

    <!-- 添加弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      width="400px"
      center
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="this.$i18n.t('dataMaintenance.name')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.supplier.abbreviation')"
          prop="abbreviation"
        >
          <el-input v-model="dialog.abbreviation"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人"
          prop="contactName"
        >
          <el-input v-model="dialog.contactName"></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="phone"
        >
          <el-input v-model="dialog.phone"></el-input>
        </el-form-item>
        <el-form-item
          label="邮箱"
          prop="email"
        >
          <el-input v-model="dialog.email"></el-input>
        </el-form-item>
        <el-form-item
          label="传真"
          prop="fax"
        >
          <el-input v-model="dialog.fax"></el-input>
        </el-form-item>
        <el-form-item
          label="职位"
          prop="postName"
        >
          <el-input v-model="dialog.postName"></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          class="add-btn"
          @click="addContact"
        >新增其他联系人</el-button>
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="addSubmit"
          :loading="dialog.loading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetDataFieldList,
  AddDataField,
} from '@/api/warehouse/dataMaintenance'

export default {
  props: ['visible'],
  data () {
    return {
      dialogVisible: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],

      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
        abbreviation: '',
        contactName: '',
        phone: '',
        email: '',
        fax: '',
        postName: '',
      },
      rules: {
        name: [{ required: true, message: 'Please enter name.', trigger: 'blur' }]
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.searchVal = '';
        this.page.current = 1;
        this.getTableData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        parent: '100000000000000000000000000000000003',
        isSearchContact: '1',
      }
      this.tableLoading = true;
      GetDataFieldList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },

    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增
    showAdd () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.title = this.$i18n.t('SupplierDialog.title2');
      this.dialog.visible = true;
    },
    // 添加供应商
    addSubmit () {
      let param = {
        name: this.dialog.name,
        abbreviation: this.dialog.abbreviation,
        parent: '100000000000000000000000000000000003',
        contact: {
          name: this.dialog.contactName,
          phoneNumber: this.dialog.phone,
          email: this.dialog.email,
          fax: this.dialog.fax,
          postName: this.dialog.postName,
        }
      }
      this.dialog.loading = true;
      AddDataField(param).then(res => {
        this.$message.success(this.$i18n.t('message.add'))
        let data = JSON.parse(res.message)
        data.contactList = [data.contact];
        this.$emit('submit', data)
        this.dialog.loading = false;
        this.dialog.visible = false;
        this.dialogVisible = false;
      })
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e)
      this.dialogVisible = false;
    },
    // 编辑
    showEdit (row) {
      let item = row.contactList[0] || {};
      Object.assign(this.dialog, {
        title: '供应商编辑',
        name: row.name,
        abbreviation: row.abbreviation,
        contactName: item.name,
        phone: item.phoneNumber,
        email: item.email,
        fax: item.fax,
        postName: item.postName,
      })
      this.dialog.visible = true;
    },
    // 新增其他联系人
    addContact () {
      Object.assign(this.dialog, {
        title: '新增其他联系人',
        contactName: '',
        phone: '',
        email: '',
        fax: '',
        postName: '',
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right !important;
  }
  .el-switch {
    width: 100px;
  }
}
.input-wrap .input-group {
  width: 100%;
}
.td-row {
  height: 23px;
}
.add-btn {
  float: left;
}
</style>