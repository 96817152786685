import request from '@/utils/request'

// 获取标准任务列表
export const GetStandardTaskList = (data) => request({
  method: 'post',
  url: `/project/standard/task/queryByPage.do`,
  data: JSON.stringify(data)
})

// 新增和修改
export const AddStandardTask = (data) => request({
  method: 'post',
  url: `/project/standard/task/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 删除
export const DelStandardTask = (id) => request({
  method: 'post',
  url: `/project/standard/task/deleted/${id}.do`,
})

// 获取集合
export const GetStandardTaskAll = () => request({
  method: 'get',
  url: `/project/standard/task/queryALl.do`,
})

// 调整排序
export const SetStandardTaskOrder = (data) => request({
  method: 'post',
  url: `/project/standard/task/sequence/adjustment`,
  data: JSON.stringify(data)
})
