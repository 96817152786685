<template>
  <div class="wrap">
    <el-card>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-button
              :disabled="tableData.length !== 0"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          ref="table"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            prop="name"
            :label="$t('approvalWorkflow.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="200"
            prop="workFlowId"
            :label="$t('approvalWorkflow.col2')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="step"
            :label="$t('approvalWorkflow.col3')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            :label="$t('approvalWorkflow.col4')"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="showView(scope.row)"
              >View</el-button>
            </template>
          </el-table-column>
          <el-table-column
            min-width="220"
            prop="dep"
            :label="$t('approvalWorkflow.col5')"
            sortable
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column
            min-width="150"
            prop="xxx"
            :label="$t('approvalWorkflow.col6')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="xxx"
            :label="$t('approvalWorkflow.col7')"
            sortable
          >
          </el-table-column> -->
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 流程弹框 -->
    <workfow-dialog
      :visible.sync='WorkfowDialogVisible'
      :id="workfowId"
      @update="getTableData"
    ></workfow-dialog>
    <!-- 流程图 -->
    <view-dialog
      :visible.sync="ViewDialogVisible"
      :id="workfowId"
    ></view-dialog>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
import WorkfowDialog from './components/WorkfowDialog'
import ViewDialog from './components/ViewDialog'
// 接口
import {
  GetApprovalWorkflowList,
  DelApprovalWorkflow,
} from '@/api/warehouse/approvalWorkflow'
export default {
  components: {
    ColSet,
    WorkfowDialog,
    ViewDialog,
  },
  data () {
    return {
      permit: {
        add: true,
        edit: true,
        del: true,
      },
      WorkfowDialogVisible: false,
      ViewDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      workfowId: null,
    }
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
    // this.WorkfowDialogVisible = true;
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
      }
      this.tableLoading = true;
      GetApprovalWorkflowList(param).then(res => {

        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示新增弹框
    showAdd () {
      this.workfowId = null;
      this.WorkfowDialogVisible = true;
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      if (!this.permit.edit) return;
      this.workfowId = e.id;
      this.WorkfowDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelApprovalWorkflow(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 显示流程图
    showView (e) {
      this.workfowId = e.id;
      this.ViewDialogVisible = true;
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.add = permission.includes('ApprovalWorkflowAdd');
        this.permit.edit = permission.includes('ApprovalWorkflowEdit');
        this.permit.del = permission.includes('ApprovalWorkflowDelete');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>