<template>
  <div class="wrap">
    <el-form
      :model="groupForm"
      :rules="rules"
      ref="groupForm"
      class="group-form"
      size="medium"
      label-width="170px"
    >
      <el-divider content-position="left">{{$t('newOrder.orderForm.subtitle1')}}</el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label4')"
            prop="orderNumber"
          >
            <el-button-group class="input-group">
              <el-input
                v-model="groupForm.orderNumber"
                class="search-input"
              ></el-input>
              <el-button @click="autoNumber">{{$t('operation.auto')}}</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label5')"
            prop="orderType"
          >
            <el-select
              disabled
              v-model="groupForm.orderType"
            >
              <el-option
                :value="1"
                label="手动订单"
              ></el-option>
              <el-option
                :value="2"
                label="自动订单"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label3')"
            prop="dataAccessGroup"
          >
            <el-select
              v-model="groupForm.dataAccessGroup"
              filterable
            >
              <el-option
                v-for="item in dataAccessGroups"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label7')"
            prop="supplierName"
          >
            <el-button-group class="input-group">
              <el-input
                v-model="groupForm.supplierName"
                class="search-input"
                readonly
              ></el-input>
              <el-button @click="SupplierDialogVisible = true">{{$t('operation.search')}}</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item
            label="供应商联系人"
            prop="contactName"
          >
            <el-input
              disabled
              v-model="groupForm.contactName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item
            label="联系电话"
            prop="contactPhone"
          >
            <el-input
              disabled
              v-model="groupForm.contactPhone"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label6')"
            prop="subscriber"
          >
            <el-select
              v-model="groupForm.subscriber"
              filterable
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :value="item.id"
                :label="item.fullname"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item
            :label="$t('newOrder.orderForm.label8')"
            prop="paymentDate"
          >
            <el-date-picker
              v-model="groupForm.paymentDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item
            :label="$t('newOrder.orderForm.label10')"
            prop="deliveryDate"
          >
            <el-date-picker
              v-model="groupForm.deliveryDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label17')"
            prop="contractDate"
          >
            <el-date-picker
              v-model="groupForm.contractDate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            v-if="permit.showPOAmount"
            :label="$t('newOrder.orderForm.label9')"
            prop="orderTotal"
          >
            <el-input
              v-model="groupForm.orderTotal"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item prop="receiver">
            <div slot="label">
              <el-switch
                v-model="groupForm.isEnableContacts"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <label>{{$t('newOrder.orderForm.label12')}}</label>
            </div>
            <my-select
              :data="receivers"
              :value.sync="groupForm.receiver"
              size="medium"
              :prop="{
                label:'name',
                value:'name'
              }"
            ></my-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item
            label="联系电话"
            prop="phoneNumber"
          >
            <my-select
              :data="phoneNumbers"
              :value.sync="groupForm.phoneNumber"
              size="medium"
              :prop="{
                label:'name',
                value:'name'
              }"
            ></my-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label13')"
            prop="address"
          >
            <my-select
              :data="addresss"
              :value.sync="groupForm.address"
              size="medium"
              :prop="{
                label:'name',
                value:'name'
              }"
            ></my-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label11')"
            prop="fare"
          >
            <el-input-number
              v-model="groupForm.fare"
              :controls="false"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item prop="taxRate">
            <div slot="label">
              <el-switch
                v-model="groupForm.isTaxRate"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="isTaxRateChange"
              ></el-switch>
              <label>{{$t('newOrder.orderForm.label15')}}</label>
            </div>
            <el-select
              :disabled="!groupForm.isTaxRate"
              v-model="groupForm.taxRate"
            >
              <el-option
                label="1%"
                :value="1"
              ></el-option>
              <el-option
                label="3%"
                :value="3"
              ></el-option>
              <el-option
                label="6%"
                :value="6"
              ></el-option>
              <el-option
                label="9%"
                :value="9"
              ></el-option>
              <el-option
                label="13%"
                :value="13"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('newOrder.orderForm.label16')"
            prop="invoiceType"
          >
            <div slot="label">
              <el-switch
                v-model="groupForm.isInvoiceType"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="isInvoiceTypeChange"
              ></el-switch>
              <label>{{$t('newOrder.orderForm.label16')}}</label>
            </div>
            <el-select
              :disabled="!groupForm.isInvoiceType"
              v-model="groupForm.invoiceType"
            >
              <el-option
                label="普通"
                :value="0"
              ></el-option>
              <el-option
                label="专用"
                :value="1"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">{{$t('newOrder.orderForm.subtitle3')}}</el-divider>
    <el-row v-if="!isCancel">
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            :disabled="!!groupForm.id && !permit.edit"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            :disabled="(!!groupForm.id && !permit.edit) || isMerge"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            :disabled="(!!groupForm.id && !permit.edit) || isMerge"
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            :disabled="(!!groupForm.id && !permit.edit) || isMerge"
            size="medium"
            @click="showReplace"
          >替换</el-button>
          <el-button
            size="medium"
            @click="showLogistics"
          >发货信息</el-button>
          <el-button
            size="medium"
            @click="showArrival"
          >到货确认</el-button>
        </el-button-group>
        <div class="switch-box">
          <label>{{$t('newOrder.orderForm.label18')}}</label>
          <el-switch
            v-model="isMerge"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="mergeChange"
          ></el-switch>
        </div>
      </div>
    </el-row>

    <el-row>
      <el-table
        border
        :data="tableData"
        :row-class-name="tableRowClassName"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          prop="typeName"
          :label="$t('newOrder.orderForm.col1')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="deviceName"
          :label="$t('newOrder.orderForm.col2')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="brandName"
          :label="$t('newOrder.orderForm.col3')"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="deviceModel"
          :label="$t('newOrder.orderForm.col4')"
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          prop="count"
          :label="$t('newOrder.orderForm.col7')"
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          v-if="permit.showPOAmount"
          prop="singlePrice"
          :label="$t('newOrder.orderForm.col6')"
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          prop="unit"
          label="单位"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="remark"
          :label="$t('newOrder.orderForm.col12')"
        >
          <template slot-scope="scope">
            <div v-html="scope.row.remark"></div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="expecteBuyBackTime"
          :label="$t('newOrder.orderForm.col9')"
        >
          <template slot-scope="scope">
            <div v-html="scope.row.expecteBuyBackTime"></div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="140"
          prop="applicationNumber"
          :label="$t('newOrder.orderForm.col10')"
        >
          <template slot-scope="scope">
            <div v-html="scope.row.applicationNumber"></div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="purchaseUser"
          :label="$t('newOrder.orderForm.col11')"
        >
          <template slot-scope="scope">
            <div v-html="scope.row.purchaseUser"></div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-divider content-position="left">其他项</el-divider>
    <el-row v-if="!isCancel">
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showAdd2"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem2"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit2"
          >{{$t('operation.edit')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row>
      <el-table
        border
        :data="tableData2"
        :row-class-name="tableRowClassName"
        v-loading="tableLoading2"
        @selection-change="val => tableSelected2 = val"
        @row-dblclick="dblclickEdit2"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          prop="name"
          label="其他项名称"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="model"
          label="型号"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="count"
          label="数量"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="singlePrice"
          label="单价"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          prop="remark"
          label="备注"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 项目弹框 -->
    <project-dialog
      :visible.sync="ProjectDialogVisible"
      @submit="projectSubmit"
    ></project-dialog>
    <!-- 供应商弹框 -->
    <supplier-dialog
      :visible.sync="SupplierDialogVisible"
      @submit="supplierSubmit"
    ></supplier-dialog>
    <!--  -->
    <number-dialog
      :visible.sync="NumberDialogVisible"
      :data="currentOrder"
      :total="orderTotal"
      @submit="numberSubmit"
    ></number-dialog>
    <!-- 其他弹框 -->
    <other-dialog
      :visible.sync="OtherDialogVisible"
      :data="otherData"
      @submit="otherSubmit"
    ></other-dialog>
    <!-- 替换弹框 -->
    <replace-dialog
      :visible.sync="ReplaceDialogVisible"
      :data="currentOrder"
      :index="replaceIndex"
      :device-id="currentOrder.applicationFormInfoId"
      :application-form-id="currentOrder.applicationFormId"
      @submit="replaceSubmit"
    ></replace-dialog>
    <!-- 到货弹框 -->
    <arrival-dialog
      :visible.sync="ArrivalDialogVisible"
      :data="currentOrder"
      :application-form-info-id="currentOrder.applicationFormInfoId"
    ></arrival-dialog>
    <!-- 物流弹框 -->
    <logistics-dialog
      :visible.sync="LogisticsDialogVisible"
      :data="currentOrder"
      :application-form-info-id="currentOrder.applicationFormInfoId"
    ></logistics-dialog>
  </div>
</template>

<script>
// 组件
import ProjectDialog from '@/components/ProjectDialog'
import SupplierDialog from '@/components/SupplierDialog'
import MySelect from '@/components/MySelect'
import NumberDialog from './NumberDialog2'
import OtherDialog from './OtherDialog'
import ReplaceDialog from "@/pages/warehouse/inventoryManagement/components/ReplaceDialog";
import ArrivalDialog from "./ArrivalDialog"
import LogisticsDialog from './LogisticsDialog'
// 接口
import {
  AddPurchaseOrders,
  GetPurchaseOrdersItem,
  AutoPurchaseOrdersNumber,
  GetOrdersDeviceList,
  DelOrdersDevice,
  GetReceiver,
  GetAddress,
  GetPhoneNumber,
  GetOtherList,
  DelOther,
} from '@/api/warehouse/purchaseOrders'
import { GetUserAll } from '@/api/userManagement'
import { GetDataAccessGroupAll } from '@/api/organizations'
export default {
  components: {
    ProjectDialog,
    SupplierDialog,
    MySelect,
    NumberDialog,
    OtherDialog,
    ReplaceDialog,
    ArrivalDialog,
    LogisticsDialog,
  },
  props: ['orderId', 'type', 'applicationFormId', 'isCancel'],
  data () {
    return {
      permit: {
        showPOAmount: true,
        edit: true,
      },
      ProjectDialogVisible: false,
      SupplierDialogVisible: false,
      NumberDialogVisible: false,
      OtherDialogVisible: false,
      ReplaceDialogVisible: false,
      ArrivalDialogVisible: false,
      LogisticsDialogVisible: false,
      viewType: null,
      users: [],
      dataAccessGroups: [],
      receivers: [],
      addresss: [],
      phoneNumbers: [],
      groupForm: {
        id: null,
        applicationFormId: '',
        projectId: '',
        projectName: '',
        contractNo: '',
        dataAccessGroup: localStorage.accessGroupId,
        orderNumber: '',
        orderType: 1,
        subscriber: this.$cookies.get('userId'),
        orderUser: '',
        supplierId: '',
        supplierName: '',
        paymentDate: null,
        orderTotal: '',
        deliveryDate: null,
        fare: null,
        receiver: '',
        address: '',
        phoneNumber: '',
        taxRate: '',
        invoiceType: '',
        contractDate: null,
        isTaxRate: false,
        isInvoiceType: false,
        additional1: '',
        additional2: '',
        additional3: '',
        contactId: '',
        contactName: '',
        contactPhone: '',
        isEnableContacts: false,
      },
      rules: {
        projectName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        orderNumber: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        subscriber: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        supplierName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],

      originData: [],
      isMerge: false,
      currentOrder: {},
      orderTotal: 0,

      tableLoading2: false,
      tableSelected2: [],
      tableData2: [],
      otherData: null,
      replaceIndex: null,

    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler: function (to, from) {
        if (to.name === 'NewOrder' && !!sessionStorage.orderData) {
          let data = JSON.parse(sessionStorage.orderData)
          // 同一申购单同一设备，以新设备信息为主
          data = data.map(item1 => {
            let index = this.originData.findIndex(item2 => !!item2 && item1.deviceInfoId === item2.deviceInfoId && item1.applicationNumber === item2.applicationNumber)
            if (index != -1) {
              item1.id = this.originData[index].id;
              item1.count += this.originData[index].count;
              item1.orderCount = item1.count;
              this.originData[index] = null;
            }
            return item1;
          })
          this.originData = this.originData.filter(item => !!item).concat(...data);
          // 
          if (this.isMerge) {
            this.isMerge = false;
            this.mergeChange();
            this.isMerge = true;
            this.mergeChange();
          } else {
            this.tableData = JSON.parse(JSON.stringify(this.originData));
          }

          sessionStorage.removeItem('orderData');
        }
      },
    },
    'tableData': {
      handler: function () {
        this.computeTotal();
      },
      deep: true,
    }
  },
  computed: {
    'orderUser': function () {
      return this.users.find(item => item.id === this.groupForm.subscriber).fullname;
    }
  },
  created () {
    this.initView();

    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
    // 获取用户
    GetUserAll({}).then(res => this.users = res);
    // 获取收货人
    GetReceiver().then(res => {
      // this.receivers = [{ name: '' }];
      this.receivers = [];
      return this.receivers = this.receivers.concat(res.map(item => Object.assign({ name: item })))
    });
    // 获取收货地址
    GetAddress().then(res => {
      // this.addresss = [{ name: '' }];
      this.addresss = [];
      return this.addresss = this.addresss.concat(res.map(item => Object.assign({ name: item })))
    });
    // 获取联系方式
    GetPhoneNumber().then(res => {
      // this.phoneNumbers = [{ name: '' }];
      this.phoneNumbers = [];
      return this.phoneNumbers = this.phoneNumbers.concat(res.map(item => Object.assign({ name: item })))
    });

    this.dealPermission();
  },
  methods: {
    initView () {
      this.viewType = Number(this.type);
      switch (this.viewType) {
        case 1:
          // 订货单
          if (this.orderId) {
            this.groupForm.id = this.orderId;
            this.getItem();
            this.getOrdersDevice();
            this.getOtherList();
          } else {
            this.autoNumber();
          }
          this.groupForm.orderType = 1;
          this.$store.state.orderContract = {
            id: '',
            templateId: 'default',
            attachment: [],
          };
          break;
        case 2:
          // 购物申请单
          this.groupForm.applicationFormId = this.applicationFormId;
          this.groupForm.orderType = 2;
          this.autoNumber();
          break;
        case 3:
          // 备货
          let data = JSON.parse(sessionStorage.applyData);
          this.groupForm.orderType = 2;
          this.autoNumber();

          Object.assign(this.groupForm, {
            applicationFormId: data.applyData.id,
            projectId: data.applyData.projectContractId,
            projectName: data.applyData.projectName,
            contractNo: data.applyData.quotationNumber,
            dataAccessGroup: localStorage.accessGroupId,
          })
          data.deviceData.forEach(item => {
            item.appCount = item.count;
            item.applicationFormInfoId = item.id;
            item.id = null;
          })
          this.tableData = this.tableData.concat(data.deviceData);
          break;
      }
    },
    getItem () {
      let param = { id: this.groupForm.id };
      GetPurchaseOrdersItem(param).then(res => {
        Object.assign(this.groupForm, {
          applicationFormId: res.applicationFormId,
          projectId: res.projectContractId,
          projectName: res.projectName,
          contractNo: res.quotationNumber,
          dataAccessGroup: res.accessGroupId,
          orderNumber: res.orderNumber,
          orderType: res.orderType,
          subscriber: res.orderUserId,
          orderUser: res.orderUser,
          supplierId: res.supplierId,
          supplierName: res.supplierName,
          paymentDate: res.paymentDate,
          orderTotal: res.totalPrice,
          deliveryDate: res.deliveryDate,
          fare: res.fare,
          receiver: res.receiver,
          address: res.address,
          phoneNumber: res.phoneNumber,
          taxRate: res.taxRate,
          invoiceType: res.invoiceType,
          contractDate: res.contractDate,
          additional1: res.additional1,
          additional2: res.additional2,
          additional3: res.additional3,
          contactId: res.contactId,
          contactName: res.contactName,
          contactPhone: res.contactPhone,
          isEnableContacts: res.isEnableContacts,
        })
        this.groupForm.isTaxRate = !!this.groupForm.taxRate;
        this.groupForm.isInvoiceType = this.groupForm.invoiceType === 0 || this.groupForm.invoiceType === 1;

        res.contract = res.contract || {};

        res.contract.attachmentName = !!res.contract.attachmentName ? res.contract.attachmentName.split(',') : [];

        if (!!res.contract.attachment) {
          res.contract.attachment = res.contract
            .attachment.split(',')
            .map((item, index) => {
              let formats = item.match(/[^.]*$/)[0];
              return {
                name: item,
                formats: formats,
                fileName: res.contract.attachmentName[index]
              }
            })
        } else {
          res.contract.attachment = [];
        }

        res.contract.templateId = res.contract.templateId || 'default';
        this.$store.state.orderContract = res.contract;
        this.$store.state.orderOriginData.data = JSON.parse(JSON.stringify(this.groupForm));
      })
    },
    // 合并开关
    mergeChange () {
      if (this.isMerge) {
        let data = {};
        let tempData = [];
        this.originData = JSON.parse(JSON.stringify(this.tableData));
        this.tableData.forEach(item => {
          if (!data[item.deviceInfoId + item.singlePrice]) {
            data[item.deviceInfoId + item.singlePrice] = [item]
          } else {
            data[item.deviceInfoId + item.singlePrice].push(item);
          }
        })
        for (var i in data) {
          let temp = data[i][0];
          temp.expecteBuyBackTime = temp.expecteBuyBackTime || '';
          for (var j = 1; j < data[i].length; j++) {
            temp.count += parseInt(data[i][j].count);
            // temp.singlePrice += `<br>${data[i][j].singlePrice}`;
            temp.remark += `<br>${data[i][j].remark}`;
            temp.expecteBuyBackTime += `<br>${data[i][j].expecteBuyBackTime || ''}`;
            temp.applicationNumber += `<br>${data[i][j].applicationNumber}`;
            temp.purchaseUser += `<br>${data[i][j].purchaseUser}`;
          }
          tempData.push(temp);
        }
        this.tableData = tempData;
      } else {
        this.tableData = this.originData;
      }
    },
    // 获取申请设备
    getOrdersDevice () {
      let param = {
        orderFormId: this.groupForm.id,
        limit: 9999,
        page: 1,
      }
      GetOrdersDeviceList(param).then(res => {
        this.tableData = res.list.map(item => {
          item.oldData = {
            count: item.count,
            detailed: item.detailed,
            remark: item.remark,
          }
          return item;
        });
        this.originData = JSON.parse(JSON.stringify(this.tableData));
        this.$store.state.orderOriginData.tableData = JSON.parse(JSON.stringify(this.tableData));
      })
    },

    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/addOrder' })
    },
    // 编辑
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      if (this.isMerge) return;
      this.orderTotal = this.getTotal(e.applicationFormInfoId);
      this.currentOrder = e;
      this.NumberDialogVisible = true;
    },
    numberSubmit (data) {
      let order = JSON.parse(JSON.stringify(this.currentOrder));
      order.count = data.count;
      order.singlePrice = data.singlePrice;
      order.isSecondment = data.isSecondment;
      order.preCount = data.preCount;
      order.allPreCount = data.allPreCount;
      order.inventoryCount = data.inventoryCount;
      order.addressId = data.addressId;
      order.consigneeId = data.consigneeId;
      order.detailed = data.detailed;
      order.remark = data.remark;
      order.unit = data.unit;
      order.deliveryInfos = data.deliveryInfos;

      this.$set(this.tableData, this.currentOrder.rowIndex, order)

      if (!!this.currentOrder.id) {
        let orderCount = this.currentOrder.orderCount - this.currentOrder.count + data.count;
        this.tableData.forEach(item => {
          if (order.applicationFormInfoId === item.applicationFormInfoId) {
            item.orderCount = orderCount;
          }
        })
      }

      this.tableData.forEach(item => {
        if (order.applicationFormInfoId === item.applicationFormInfoId && order.id !== item.id) {
          item.allPreCount = order.allPreCount;
          item.inventoryCount = order.inventoryCount
        }
      })

      this.originData = JSON.parse(JSON.stringify(this.tableData));
    },
    // 显示替换
    showReplace () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.');
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.');
      if (!this.tableSelected[0].id) return this.$message.warning('New equipment cannot be replaced.');
      this.currentOrder = this.tableSelected[0];
      this.replaceIndex = this.tableData.findIndex(item => item.id === this.tableSelected[0].id);
      this.ReplaceDialogVisible = true;
    },
    // 
    replaceSubmit (data, index) {
      let item = this.tableData[index];
      item.deviceInfoId = data.deviceInfoId;
      item.typeName = data.typeName;
      item.deviceName = data.deviceName;
      item.brandName = data.brandName;
      item.deviceModel = data.deviceModel;
      item.unit = data.unit;
      this.$set(this.tableData, index, item);

      this.originData = JSON.parse(JSON.stringify(this.tableData));
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        let param = { id: this.tableSelected.map(item => item.id).join(',') }
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));

        if (param.id == '') return this.$i18n.t('message.del');
        DelOrdersDevice(param).then(res => {
          if (res.success) {
            this.originData = JSON.parse(JSON.stringify(this.tableData));
            this.$message.success(this.$i18n.t('message.del'))
            this.submit(true);
          }
        })
      }).catch(() => { });
    },

    // 提交
    submit (notBack) {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        this.computeTotal();

        let totalPriceUnit = this.originData.length > 0 ? this.originData[0].singlePriceUnit : 1;
        let param = {
          form: {
            accessGroupId: this.groupForm.dataAccessGroup,
            projectContractId: this.groupForm.projectId,
            // applicationFormId: this.groupForm.applicationFormId,
            // applicationFormId: this.originData.map(item => item.applicationFormId).join(','),
            orderNumber: this.groupForm.orderNumber,
            orderType: this.groupForm.orderType,
            orderUserId: this.groupForm.subscriber,
            orderUser: this.orderUser,
            supplierId: this.groupForm.supplierId,
            paymentDate: this.groupForm.paymentDate,
            totalPrice: this.groupForm.orderTotal,
            totalPriceUnit: totalPriceUnit,
            deliveryDate: this.groupForm.deliveryDate,
            fare: this.groupForm.fare,
            receiver: this.groupForm.receiver,
            address: this.groupForm.address,
            phoneNumber: this.groupForm.phoneNumber,
            taxRate: this.groupForm.taxRate,
            invoiceType: this.groupForm.invoiceType,
            contractDate: this.groupForm.contractDate,
            additional1: this.groupForm.additional1,
            additional2: this.groupForm.additional2,
            additional3: this.groupForm.additional3,
            contactId: this.groupForm.contactId,
            isEnableContacts: this.groupForm.isEnableContacts,
          },
        }
        param.infos = this.originData.map(item => {
          item.deliveryInfos = item.deliveryInfos || [];
          let data = {
            applicationFormId: item.applicationFormId,
            orderFormId: this.groupForm.id,
            deviceInfoId: item.deviceInfoId,
            unit: item.unit,
            singlePrice: item.singlePrice,
            singlePriceUnit: item.singlePriceUnit,
            count: item.count,
            expecteBuyBackTime: item.expecteBuyBackTime,
            id: item.id || '',
            typeName: item.typeName,
            deviceName: item.deviceName,
            brandName: item.brandName,
            deviceModel: item.deviceModel,
            supplierName: item.supplierName,
            applicationFormInfoId: item.applicationFormInfoId,
            projectName: item.projectName,
            preCount: item.preCount,
            isSecondment: item.isSecondment,
            detailed: item.detailed,
            detailed2: item.detailed,
            remark: item.remark,
            remark2: item.remark,
            unit: item.unit,
            deliveryInfos: item.deliveryInfos.map(item => {
              // 验证是否是新收货地址
              if (!/.{8}(-.{4}){3}-.{12}/.test(item.addressId)) {
                item.address = item.addressId;
                item.addressId = '';
              }
              // 验证是否是新收件人
              if (!/.{8}(-.{4}){3}-.{12}/.test(item.consigneeId)) {
                item.consignee = item.consigneeId;
                item.consigneeId = '';
              }
              return item;
            })
          }
          // 验证设备否修改过
          data.operateFlag = !data.id || (item.oldData.count === data.count &&
            item.oldData.detailed === data.detailed &&
            item.oldData.remark === data.remark) ?
            undefined : 2;
          return data
        })

        param.otherItems = this.tableData2.map(item => {
          return {
            id: item.id || '',
            name: item.name,
            model: item.model,
            orderFormId: this.groupForm.id,
            count: item.count,
            singlePrice: item.singlePrice,
            singlePriceUnit: item.singlePriceUnit,
            remark: item.remark,
          }
        })

        if (!!this.$store.state.orderContract) {
          param.contract = {
            id: this.$store.state.orderContract.id,
            orderFormId: this.groupForm.id,
            templateId: this.$store.state.orderContract.templateId,
            attachment: this.$store.state.orderContract.attachment.map(item => item.name).join(','),
            attachmentName: this.$store.state.orderContract.attachment.map(item => item.fileName).join(','),
          }
        }

        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.form.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddPurchaseOrders(param).then(res => {
          if (res.success) {
            if (notBack !== true) {
              this.$message.success(msg)
              this.back();
            }
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 计算订单总额
    computeTotal () {
      let total = this.groupForm.fare;

      this.tableData.forEach(item => {
        total += item.count * item.singlePrice;
      })

      this.tableData2.forEach(item => {
        total += item.count * item.singlePrice;
      })

      this.groupForm.orderTotal = total.toFixed(3);
    },
    // 项目提交
    projectSubmit (data) {
      Object.assign(this.groupForm, {
        projectId: data.id,
        projectName: data.name,
        contractNo: data.quotationNumber,
      })
    },
    // 供应商提交
    supplierSubmit (data) {
      if (!!data.contactList && data.contactList.length > 0) {
        Object.assign(this.groupForm, {
          contactId: data.contactList[0].id,
          contactName: data.contactList[0].name,
          contactPhone: data.contactList[0].phoneNumber,
        })
      } else {
        Object.assign(this.groupForm, {
          contactId: '',
          contactName: '',
          contactPhone: '',
        })
      }
      Object.assign(this.groupForm, {
        supplierId: data.id,
        supplierName: data.name,
      })
    },
    // 自动订单号
    autoNumber () {
      AutoPurchaseOrdersNumber().then(res => {
        this.groupForm.orderNumber = res.data;
        this.$refs.groupForm.validateField('orderNumber');
      })
    },

    // 返回订货单列表
    back () {
      this.$router.push({ path: '/home/purchaseOrders' })
    },
    isTaxRateChange () {
      this.groupForm.taxRate = '';
    },
    isInvoiceTypeChange () {
      this.groupForm.invoiceType = '';
    },
    // 
    getTotal (_id) {
      let total = 0;
      this.tableData.forEach(item => {
        if (item.applicationFormInfoId === _id && !item.id) {
          total += item.count;
        }
      })
      return total;
    },

    // 显示新增弹框
    showAdd2 () {
      this.otherData = null;
      this.OtherDialogVisible = true;
    },
    // 编辑
    showEdit2 () {
      if (this.tableSelected2.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected2.length > 1) return this.$message.warning('You can only choose one.')

      this.dblclickEdit(this.tableSelected2[0]);
    },
    dblclickEdit2 (e) {

      this.otherData = e;
      this.OtherDialogVisible = true;
    },
    // 删除
    delItem2 () {
      if (this.tableSelected2.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected2.map(item => item.rowIndex);
        let param = { id: this.tableSelected2.map(item => item.id).join(',') }
        this.tableData2 = this.tableData2.filter(item => !checkIndex.includes(item.rowIndex));

        if (param.id == '') return this.$i18n.t('message.del');
        DelOther(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.del'))
            this.submit(true);
          }
        })
      }).catch(() => { });
    },
    // 获取 其他项 列表
    getOtherList () {
      let param = {
        orderFormId: this.groupForm.id,
        limit: 9999,
        page: 1,
      }
      GetOtherList(param).then(res => {
        this.tableData2 = res.list;
        this.$store.state.orderOriginData.tableData2 = JSON.parse(JSON.stringify(this.tableData2));
      })
    },
    // 其他项提交
    otherSubmit ({ data, type }) {
      switch (type) {
        case 'add':
          this.tableData2.push(data);
          break
        case 'edit':
          this.$set(this.tableData2, data.rowIndex, data);
          break
      }
    },
    // 物流信息
    showLogistics () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (!this.tableSelected[0].id) return this.$message.warning('新设备无法添加物流信息.')

      this.currentOrder = this.tableSelected[0];
      this.LogisticsDialogVisible = true
    },
    // 到货确认
    showArrival () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (!this.tableSelected[0].id) return this.$message.warning('新设备无法添加到货确认.')

      this.currentOrder = this.tableSelected[0];
      this.ArrivalDialogVisible = true
    },

    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.showPOAmount = permission.includes('PurchaseOrdersBeallowedtoseetheprice');
        this.permit.edit = permission.includes('PurchaseOrdersEdit');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
  overflow: auto;
}
.bottom-btn {
  text-align: center;
}
.input-group {
  .el-button {
    height: 36px;
  }
}
.input-wrap {
  justify-content: flex-start;
}

.switch-box {
  width: 200px;
  height: 36px;
  line-height: 36px;
  margin-left: 20px;
  label {
    font-size: 14px;
    margin-right: 10px;
  }
}
</style>
