import request from '@/utils/request'

// 查询供应商列表
export const GetSupplierList = (data) => request({
  method: 'post',
  url: '/WarehouseSupplier/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改供应商
export const AddSupplier = (data) => request({
  method: 'post',
  url: '/WarehouseSupplier/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除供应商
export const DelSupplier = (data) => request({
  method: 'post',
  url: '/WarehouseSupplier/del.do',
  data: JSON.stringify(data)
})

// 查询供应商联系人列表
export const GetSupplierContactList = (data) => request({
  method: 'post',
  url: '/WarehouseSupplierContact/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加和修改供应商联系人
export const AddSupplierContact = (data) => request({
  method: 'post',
  url: '/WarehouseSupplierContact/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除供应商联系人
export const DelSupplierContact = (data) => request({
  method: 'post',
  url: '/WarehouseSupplierContact/del.do',
  data: JSON.stringify(data)
})

// 查询供应商已关联的设备类型列表
export const GetBySupplieDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryBySupplierId.do',
  data: JSON.stringify(data)
})

// 查询供应商未关联的设备类型列表
export const GetNoSupplieDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryNoSupplierId.do',
  data: JSON.stringify(data)
})

// 关联供应商与设备类型
export const AddSupplieDevice = (data) => request({
  method: 'post',
  url: '/WarehouseSupplierDeviceType/add.do',
  data: JSON.stringify(data)
})

// 删除供应商关联的设备类型
export const DelSupplieDevice = (data) => request({
  method: 'post',
  url: '/WarehouseSupplierDeviceType/del.do',
  data: JSON.stringify(data)
})
