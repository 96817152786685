import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
  ...enLocale,
  login: {
    login: 'Sign in'
  },
  menu: {
    DashBoard: 'Overview',
    KPIs: 'KPIs',
    KpiDashboard: 'KPI Dashhomeboard',
    KpiTemplate: 'KPI Template',
    CounterMode: 'Daily Report',
    DiningMode: 'Administration Report',
    UserDefined: 'User Defined',

    SystemSettings: 'System Settings',
    UserManagement: 'User Management',
    Organizations: 'Organizations',
    Roles: 'Roles',
    StoreGroupEditor: 'Site Group Editor',
    ReportGroupEditor: 'Report Group Editor',
    Stores: 'Site Management',
    ContactList: 'Contact List',
    SMTP: 'SMTP',
    AccountPolicies: 'Account Policies',

    ReportSettings: 'Report Settings',
    ReportTemplates: 'Report Templates',
    ReportGroup: 'Report Categories',
    ReportDesigner: 'Report Designer',
    RiskOfLossLevel: 'Risk Level',
    SystemSchedule: 'System Tasks',
    Schedule: 'Task Schedule',
    SystemLog: 'System Log',

    BgbOasSettings: 'BGB OAS Settings',
    DataMaintenance: 'Data Field',
    ApprovalWorkflow: 'Approval Workflow',

    BgbProjectManagement: 'BGB PMIS',
    ProjectContract: 'Project Contract',
    Projects: 'Projects',
    StandardTask: 'Standard Task',
    ProjectDocuments: 'Project Documents',
    SLAPolicies: 'SLA Policies',
    OperationalTime: 'Operational Time',

    BgbService: 'BGB Service',
    SubmitRequest: 'Ticket',
    Maintenance: 'Maintenance',
    ServiceConfirmationForm: 'Services Confirmation',
    DeviceManagement: 'Device Management',
    WorkOrderScheduling: 'Work Order Scheduling',
    EngineerManagement: 'Personnel Managment',

    BgbIms: 'BGB IMS',
    InventoryManagement: 'Inventory Management',
    PurchaseRequisition: 'Purchase Requisition',
    PurchaseOrders: 'Purchase Orders',
    InventoryStatistics: 'Inventory Statistics',
    Quotation: 'Quotation',
    ProductInformation: 'Product Information',

    Notifications: 'Notifications',
    Notification: 'Notification',
    DailyDataSync: 'Daily Data Sync',
    RequestForApproval: 'Request&Approval',

    ComplaintsEvaluation: 'Complaint&Suggestion',

    AppReleaseManagement: 'App Releases',
    AppRelease: 'App Release',
  },
  message: {
    add: 'Successfully Added',
    edit: 'Successfully Changed',
    del: 'Successfully Deleted',
    please: 'Please Enter ',
    cancel: 'Successfully Cancelled',
    save: 'Successfully Saved ',
    copy: 'Successfully Copied',
    update: 'Successfully Updated',
    notNull: 'Not Null',
    download: 'Download Successfully',
    export: 'Export Successfully',
    import: 'Import Successfully',
    upload: 'Upload Successfully',
    receipt: 'Signed Successfully',
  },
  operation: {
    search: 'Search',
    add: 'Add',
    edit: 'Edit',
    del: 'Delete',
    import: 'Import',
    cancel: 'Cancel',
    submit: 'Submit',
    save: 'Save',
    repeal: 'Cancel',
    evaluate: 'Evaluate',
    complaint: 'Complaints',
    send: 'Assign',
    updateState: 'Status Update',
    staffStatus: 'Personnel Status',
    saveSend: 'Save and Assign',
    reassign: 'Reassign',
    auto: 'Auto Generate',
    saveAdd: 'Save and Add',
    refresh: 'Refresh',
    update: 'Update',
    reset: 'Reset',
    copy: 'Copy',
    move: 'Move',
    export: 'Export',
    confirm: 'Confirm',
    close: 'Close',
    filter: 'Filter',
    merge: 'Merge',
    print: 'Print',
    date: {
      text1: 'Last week',
      text2: 'Last month',
      text3: 'Last three months',
    }
  },
  home: {
    layer: {
      btn1: 'Hide the Menu',
      btn2: 'Pin the Menu',
      btn3: 'Collapse the Menu',
    },
    menu: {
      btn1: 'Change Password',
      btn2: 'My Profile',
      btn3: 'Help',
      btn4: 'Sign Out',
      btn5: 'Close Other Tabs',
      btn6: 'Close All Tabs',
    },
    password: {
      title1: 'Change your Password',
      label1: 'Current password',
      label2: 'New password',
      label3: 'Confirm new password',
    },
    userInfo: {
      title1: 'Edit My Profile Information',
      label1: 'Full Name',
      label2: 'Email Address',
      label3: 'Backup Email Address',
      label4: 'Phone Number',
      label5: 'Backup Phone Number',
    },
    help: {
      title1: 'Help',
      content: 'Should you require any further assistance, you are welcome to contact our support team. Thanks.',
      contact: 'Please Contact Us',
    }
  },

  userManagement: {
    col1: 'Username',
    col2: 'Organization',
    col3: 'Roles',
    col4: 'Data Access Group',
    col5: 'Site Group',
    col6: 'Report Group',
    col7: 'Email',
    col8: 'Phone',
    col9: 'Status',
    col10: 'Full Name',
  },
  newUser: {
    title1: 'Add New User',
    title2: 'Edit User',
    title3: 'View User',
    subtitle1: 'User Information',
    subtitle2: 'Permissions',
    label1: 'User Account',
    label2: 'Password',
    label3: 'Full Name',
    label4: 'Email Address',
    label5: 'Backup Email Address',
    label6: 'Phone Number',
    label7: 'Backup Phone Number',
    label8: 'Data Access Group',
    label9: 'Roles',
    label10: 'Organization',
    label11: 'Site Group',
    label12: 'Report Group',
    label13: 'Overview DashBoard Templates',
    label14: 'KPI DashBoard Templates',
    label15: 'To Approve System Default Modification',
    label16: 'To Approve Templates Modification',
    label17: 'Account status',
    message1: 'The password contains at least three of English uppercase characters (A-Z), English lowercase letters (a-z), Base 10 Digits (0-9), special characters, and Unicode characters',
    message2: 'Password cannot be less than ',
    message3: ' characters',
  },
  storeGroupEditor: {
    title1: 'Site Group Editor',
    subtitle1: 'Select Site Categories',
    subtitle2: 'Select Site',
    btn1: 'Switch',
    col1: 'Site Group Name',
    col2: 'Data Access Group',
    col3: 'Site ID',
    col4: 'Site Name',
    dialog: {
      title1: 'New Site Group',
      title2: 'Edit Site Group',
      label1: 'Site Group Name',
      label2: 'Data Access Group',
    },
  },
  reportGroupEditor: {
    title1: 'Report Group Editor',
    subtitle1: 'Select Report Categories',
    subtitle2: 'Select Report Templates',
    col1: 'Report Group Name',
    col2: 'Data Access Group',
    col3: 'Report Templates',
    col4: 'Description',
    col5: 'Data Access Group',
    dialog: {
      title1: 'New Report Group',
      title2: 'Edit Report Group',
      label1: 'Report Group Name',
      label2: 'Data Access Group',
    },
  },
  organizations: {
    tab1: 'Organizations',
    tab2: 'Data Access Groups',
    subtitle1: 'Organization Chart',
    subtitle2: 'Organization Parameters',
    col1: 'Organization Name',
    col2: 'Description',
    col3: 'Data Access Group',
    col4: 'Creator',
    col5: 'Date Created',
    col6: 'Date Modified',
    label1: 'Theme colors',
    label2: 'Receipt Templates',
    label3: 'Overview Templates',
    label4: 'KPI Templates',
    label5: 'KPI Dashboard Templates',
    dialog: {
      title1: 'Add New Organization',
      title2: 'Edit Organization',
      label1: 'Organization Name',
      label2: 'Descirption',
      label3: 'Data Access Group (Data Permissions)',
    },
    btn1: 'Share',
    btn2: 'Add Child',
    nodeDialog: {
      title1: 'Add Child Node',
      title2: 'Edit Node',
      label1: 'Node type',
      label2: 'Child Node Name',
      label3: 'Node Name',
      label4: 'Descirption',
    },
    shareDialog: {
      title1: 'Share Organization Chart',
      col1: 'Organization Name',
    },
  },
  dataAccessGroup: {
    label1: 'Data Access Group Name',
    label2: 'Description',
    label3: 'Data Access Group(DAG)',
    label4: 'Creator',
    label5: 'Date Created',
    label6: 'Date Modified',
  },
  newDataAccessGroup: {
    title1: 'Add Data Access Group',
    title2: 'Edit Data Access Group',
    title3: 'View Data Access Group',
    label1: 'Group Name',
    label2: 'Data Access Group ( Data Permissions)',
    label3: 'Descirption',
    label4: 'Organizations',
    label5: 'Selected',
    col1: 'No.',
    col2: 'Organization',
    col3: 'Description',
  },
  roles: {
    col1: 'Role Name',
    col2: 'Description',
    col3: 'Data Access Group',
    col4: 'Creator',
    col5: 'Date Created',
    col6: 'Date Modified',
    subtitle1: 'System Modules',
    subtitle2: 'Permissions',
    btn1: 'Add Node',
    btn2: 'Add Child',
    roleDialog: {
      title1: 'New Role',
      title2: 'Edit Role',
      label1: 'Role Name',
      label2: 'Description',
      label3: 'Data Access Group (Data Permissions)',
    },
    itemDialog: {
      title1: 'New System Module',
      title2: 'Edit System Module',
      label1: 'System Module Name',
      label2: 'Display order Number',
    },
    permissionsDialog: {
      title1: 'New Permissions',
      title2: 'Edit Permissions',
      label1: 'Permissions Name',
      label2: 'Display order Number',
    }
  },
  stores: {
    btn1: 'Add Node',
    btn2: 'Add Child',
    btn3: 'Select all',
    label1: 'Selected Node',
    label2: 'Description',
    col1: 'Site ID',
    col2: 'Site Name',
    col3: 'Site Type',
    col4: 'Address',
    col5: 'State',
    col6: 'Parent Node',
    nodeDialog: {
      title1: 'Add New Node',
      title2: 'Add Child Node',
      title3: 'Edit Node',
      label1: 'Node Name',
      label2: 'Child Node Name',
      label3: 'Descirption',
      label4: 'Node Types',
      label5: 'Data Access Group (Data Permissions)',
    },
    storeDialog: {
      title1: 'New Site',
      title2: 'Edit Site',
      label1: 'Site ID',
      label2: 'Site Name',
      label3: 'Site Type',
      label4: 'Address',
      label5: 'Status',
      option1: 'Normal',
      option2: 'Unknown',
      option3: 'Ended',
      option4: 'Not effective',
    },
    grouping: {
      title1: 'Tree Node  Dispaly',
      label1: 'Group by',
    },
    storeTree: {
      title1: 'Site Move',
      label1: 'Select Destination Node',
    }
  },
  contactList: {
    col1: 'Full Name',
    col2: 'Email Address',
    col3: 'Backup Email Address',
    col4: 'Organizations',
    col5: 'Site Group',
    col6: 'Report Group',
    col7: 'System User',
    col8: 'Jobtitle',
    col9: 'StaffID',
    col10: 'Phone Number',
    dialog: {
      title1: 'New Contact',
      title2: 'Edit Contact',
      label1: 'Full Name',
      label2: 'Organization',
      label3: 'Data Access Group',
      label4: 'Email Address',
      label5: 'Backup Email Address',
      label6: 'Phone Number',
      label7: 'Backup Phone Number',
      label8: 'Site Group',
      label9: 'Report Group',
      label10: 'Employee Status',
      label11: 'Create System Account',
      label12: 'Site Director',
      label13: 'StaffID',
      label14: 'Jobtitle',
      label15: 'Site',
      col1: 'Site ID',
      col2: 'Site Name',
    }
  },
  smtp: {
    title1: 'SMTP Server Settings',
    btn1: 'Test',
    label1: 'Username',
    label2: 'SMTP Server',
    label3: 'Secure Connection',
    label4: 'Password',
    label5: 'Port',
    label6: 'Server Timeouts',
  },
  accountPolicies: {
    title1: 'Account Policies',
    label1: 'Login Attempts',
    label2: 'Lockout duration',
    label3: 'Minimum password length',
    label4: 'Password expiration (days)',
    label5: 'Password must meet complexity requirements',
    subtitle1: 'Complex Password',
    subtitle2: 'Blocked IP Addresses',
    p1: '1.The password is at least six characters long',
    p2: '2.The password contains characters from at least three of following five categoreis',
    p3: 'a.English Uppercase Characters(A-Z)',
    p4: 'b.English Lowercase Characters(a-z)',
    p5: 'c.Base 10 Digits(0-9)',
    p6: 'd.Special Non-alphanumeric Characters(for examples:!,@,#,$,%)',
    p7: 'e.Unicode Characters  ',
    col1: 'IP Address',
    col2: 'Date Blocked',
  },
  systemLog: {
    btn1: 'Print',
    col1: 'Date',
    col2: 'Type',
    col3: 'Category',
    col4: 'Description',
    col5: 'User',
  },
  schedule: {
    col1: 'Task Name',
    col2: 'Recurrences',
    col3: 'Data Access Group',
    col4: 'Creator',
    col5: 'Task Status',
    col6: 'Action',
    col7: 'Report Template',
  },
  newSchedule: {
    title1: 'New Task',
    title2: 'Edit Task',
    title3: 'View Task',
    label1: 'Task Name',
    label2: 'Descirption',
    label3: 'Data Access Group',
    label4: '(Data Permissions)',
    label5: 'Date&Time',
    label6: 'Recurrences',
    label7: 'Specific Event',
    label8: 'Action',
    label9: 'Email template',
    label10: 'Task Status',
    tab1: 'Triggers',
    tab2: 'Action',
    col1: 'Full Name',
    col2: 'Site Group',
    col3: 'Email Address',
    col4: 'Phone Number',
    col5: 'Backup Email Address',
    col6: 'Backup Phone Number',
  },
  contactDialog: {
    title1: 'Add Contacts',
    col1: 'Full Name',
    col2: 'Site Group',
    col3: 'Email Address',
    col4: 'Phone Number',
    col5: 'Backup Email Address',
    col6: 'Backup Phone Number',
  },






  projectContractManagement: {
    tab1: 'Contract',
    tab2: 'SLA&Maintenance',
    contract: {
      col1: 'Project Name',
      col2: 'Contract&Quote No.',
      col3: 'Project Category',
      col4: 'Contract Status',
      col5: 'Customer',
      col6: 'Signature Date',
      col7: 'Start work',
      col8: 'Completed',
      col9: 'Contract start',
      col10: 'Contract end',
      col11: 'Maintenance start',
      col12: 'Maintenance end',
      tag1: 'Executory',
      tag2: 'Normal',
      tag3: 'End',
      tag4: 'Unknown',
    },
    sla: {
      col1: 'Site Name',
      col2: 'Site Type',
      col3: 'SLA Status',
      col4: 'Maintenance Type',
      col5: 'SLA Start',
      col6: 'SLA End',
      col7: 'Address',
      col8: 'Customer',
      col9: 'Project Name',
      col10: 'SLA Policy',
      col11: 'Type of SLA',
    }
  },
  newProjectContract: {
    title: 'New Project',
    title2: 'Edit Project',
    title3: 'Add New Site',
    title4: 'Edit Site',
    title5: 'View Project',
    subtitle1: 'Basic Information',
    subtitle2: 'Project Information',
    subtitle3: 'Terms',
    subtitle4: 'Site List',
    btn1: 'Renew',
    label1: 'Project Name',
    label2: 'Project Category',
    label3: 'Signature Date',
    label4: 'Contract&Quote No.',
    label5: 'Customer',
    label6: 'Data Access Group',
    label7: 'Project Start&End Date',
    label8: 'Contract Start&End Date',
    label9: 'SLA Start&End Date',
    label10: 'Notes',
    label11: 'SLA Policy',
    label12: 'Type of SLA',
    label13: 'SLA Start&End Date',
    label14: 'Warranty Type',
    col1: 'Site Name',
    col2: 'Maintenance Category',
    col3: 'SLA Start Date',
    col4: 'SLA End Date',
    col5: 'Contacts',
    col6: 'Phone',
    col7: 'Address',
    col8: 'Notes',
    col9: 'Site Type',
    col10: 'Maintenance Status',
    col11: 'Start Date',
    col12: 'End Date',
    tag1: 'Executory',
    tag2: 'Normal',
    tag3: 'End',
    tag4: 'Unknown',
    tips1: '(Please add the sites be involved in the contract, at least one site be inculded in the list.)',
    tips2: 'All of currently selected sites will be removed when the cutstomer be changed, Are you sure you want to change it?',
    tips3: "(Please select existing site, Don't create a duplicate data of one site)",
    slaDialog: {
      title1: 'SLA Policies',
      col1: 'SLA Policy',
      col2: 'Type of SLA',
      col3: 'DAG',
    },
    storeDialog: {
      title1: 'Store Tree',
      label1: ' (select the existing one, click save)',
      label2: '(If you need to add, select the node, right click to add)',
    },
    addBranch: {
      label1: 'Site Node',
      label2: 'Site Name',
      label3: 'Site Type',
      label4: 'Completion date',
      label5: 'Maintenance Category',
      label6: 'SLA Policy',
      label7: 'Type of SLA',
      label8: 'Start Date',
      label9: 'End Date',
      label10: 'Contacts',
      label11: 'Phone',
      label12: 'Address',
      label13: 'Notes',
      label14: 'Site Type',
    },
  },
  dataMaintenance: {
    name: 'Name',
    btn1: 'Select all',
    title1: 'Data Field',
    title2: 'Item Types',
    contactDialog: {
      title1: 'New Contacts',
      title2: 'Edit Contacts',
      label1: 'Supplier',
      label2: 'Logistics Company',
      label3: 'Delivery Address',
      label4: 'Contacts',
      label5: 'Phone Number',
      label6: 'Email',
      label7: 'Fax Number',
      label8: 'Job Title',
    },
    deviceTypeDialog: {
      title: 'New Item Type',
      col1: 'Item Type',
    },
    deviceType: {
      deviceType: 'Item Types',
      title1: 'New Item',
      title2: 'Edit Item',
      title3: 'Merge into one Item',
      title4: 'Replace with',
      title5: 'Update Item',
      col1: 'Item Name',
      col2: 'Brand',
      col3: 'Model',
      col4: 'Supplier',
      col5: 'ItemID',
      col6: 'Item Types',
      col7: 'Unit',
      col8: 'Status',
      col9: 'Outbound synchronization',
      btn1: 'Import Contract',
      btn2: 'Import Customer Name',
      btn3: 'Import IMS Data',
      btn4: 'Replace',
      btn5: 'Merge',
      btn6: 'More…',
      label1: 'All others item will be merged into selected item.',
      option1: 'Enable',
      option2: 'Disable',
    },
    equipmentBrand: {
      col1: 'Item Types'
    },
    supplier: {
      abbreviation: 'Abbreviation',
      tab1: 'Contacts',
      tab2: 'Supply List',
      col1: 'Contacts',
      col2: 'Phone Number',
      col3: 'Email',
      col4: 'Fax Number',
      col5: 'Job Title',
      col6: 'Item Types'
    },
    systemType: {
      tab1: 'Item Types',
      tab2: 'Service Content',
      tab3: 'Common Issues',
      col1: 'Item Types',
      col2: 'Service Category',
      col3: 'Service Content',
      col4: 'Rank',
      col5: 'Common Issues',
      col6: 'Rank',
      col7: 'System Type',
      title1: 'New Service Content',
      title2: 'Edit Service Content',
      title3: 'New Common Issue',
      title4: 'Edit Common Issue',
      systemType: 'System Type',
      label1: 'Short Name',
      label2: 'Icon',

    },
    faultType: {
      col1: 'Issue ID',
      col2: 'Issue MainType',
      col3: 'Issue SubType',
      col4: 'Item Types',
      col5: 'Issue MainType',
      col6: 'Issue SubType',
      col7: 'Issue Type',
      col8: 'Keyword',
      col9: 'Rank',
      tab1: 'Group by Issue MainType',
      tab2: 'Group by Item Type',
      btn1: 'Replace',
      dialog1: {
        title1: 'Add Issue Type',
        title2: 'Edit Issue Type',
        label1: 'Issue MainType',
        label2: 'Issue SubType',
        label3: 'Related Item Types',
        btn1: 'View All',
        col1: 'Item Types',
        col2: 'Issue Type',
        col3: 'Keyword',
        col4: 'Rank',
      },
      dialog2: {
        title1: 'Add Issue Type',
        title2: 'Edit Issue Type',
        label1: 'Item Types',
        label2: 'Issue SubType',
        btn1: 'View All',
        col1: 'Issue MainType',
        col2: 'Issue SubType',
        col3: 'Issue Type',
        col4: 'Keyword',
        col5: 'Rank',
      },
    },
    logisticsCompany: {
      tab: 'Contacts',
      col1: 'Contacts',
      col2: 'Phone Number',
      col3: 'Email',
      col4: 'Fax Number',
      col5: 'Job Title',
    },
    receiptAddress: {
      tab: 'Contacts',
      col1: 'Contacts',
      col2: 'Phone Number',
      col3: 'Email',
      col4: 'Fax Number',
      col5: 'Job Title',
    },
    serviceConfirmationTemplate: {
      label1: 'Template Files',
      label2: 'Select Customer',
    },
    checkType: {
      title1: 'Update Item',
      col1: 'ID',
      col2: 'Check Item',
      col3: 'Check Type',
      col4: 'Status',
      col5: 'Option',
    },
    warrantyType: {
      label1: 'Duration',
      option1: 'Months',
      option2: 'Years',
      option3: 'Days',
    },
    securityType: {
      label1: 'Duration',
      label2: 'Times',
      option1: 'Months',
      option2: 'Years',
      option3: 'Days',
    }
  },
  submitRequest: {
    col1: 'Date Created',
    col2: 'Duration',
    col3: 'City',
    col4: 'Site',
    col5: 'Description',
    col6: 'Scheduled',
    col7: 'Engineer',
    col8: 'Service Category',
    col9: 'Status',
    status: 'Status',
    complaint: {
      title: 'Complaints',
      content: 'We would like to apologize for the inconvenience and disappointment that you experienced dealing with our service recently. We assure you that your complaint and feedback will give the opportunity to remedy any problem that may exist and help to improve our srevice further. Should you require any further assistance, you are welcome to contact our support team. Thanks.',
      message1: 'Please write down valuable suggestions',
    }
  },
  newSubmitRequest: {
    title: 'Add New Ticket',
    title2: 'Edit Ticket',
    title3: 'View Ticket',
    tab1: 'Basic Information',
    tab2: 'Tracking Progress',
    tab3: 'Engineer',
    tab4: 'Service Confirmation',
    tab5: 'SLA Tracking',
    btn1: 'Confirmed',
    subtitle1: 'Basic Information',
    subtitle2: 'Issue',
    subtitle3: 'Engineer',
    subtitle4: 'Attachment',
    label1: 'Site Name',
    label2: 'Required Time of Arrival',
    label3: 'Source',
    label4: 'Client Contact',
    label5: 'Phone Number',
    label6: 'Service Category',
    label7: 'Description',
    label8: 'Site Address',
    label9: 'Reference No.',
    label10: 'Contract&Quote No.',
    label11: 'Purchase Order No.',
    label12: 'Site Director',
    col1: 'Item Name',
    col2: 'Issue Description',
    col3: 'Date Created',
    col4: 'Engineer',
    col5: 'GPS',
    col6: 'Status',
    col7: 'Uncompleted Orders',
    col8: 'Estimated Time of Arrival',
    col9: 'Work order status',
    col10: 'Service Confirmation Number',
    col11: 'Common Issue',
    message1: 'Please select the person who has been dispatched',
    tag1: 'Uncompleted',
    tag2: 'Completed',
    tag3: 'Assigned',
    tag4: 'Resend',
    qrcode: {
      btn: 'QR Code',
      title: 'Issue',
      label1: 'QR Code',
      label2: 'Item Name',
      label3: 'Description',
    },
    fault: {
      title1: 'Add New Issue',
      title2: 'Edit Issue',
      title3: 'Please select Common Issue',
      title4: 'Customized Common Issue',
      label1: 'Item Name',
      label2: 'Common Issue',
      label3: 'Detail',
      btn1: 'No Content Matched, Add new',
      message1: 'Already selected, the failure type cannot be selected repeatedly',
      message2: 'Please enter the fault type',
    },
    progressLog: {
      state0: 'Signature and confirmed',
      state1: 'Assignment Cancelled',
      state2: 'Assigned',
      state3: 'Reassign',
      state4: 'Scheduled',
      state5: 'Request',
      state6: 'On Hold',
      state7: 'Completed',
      state8: 'In Progress',
      state9: 'Awaiting Schedule',
      state10: 'Open',
      state11: 'Cancelled',
      state12: 'Closed',
      state13: 'Archived',
    },
    number: {
      title1: 'Edit Reference No.',
      title2: 'Edit Quotation No.',
      title3: 'Edit Purchase Order No.',
      col1: 'ID',
      col2: 'Reference No.',
      col3: 'Contract&Quote No.',
      col4: 'Purchase Order No.',
    },
    faultItem: {
      title1: 'Please Select Common Issue',
      tab1: 'Selected',
      tab2: 'All',
      btn1: 'New Issue',
      label1: 'New Issue',
    },
  },
  servicesScheduling: {
    add: 'Submitted By',
    col1: 'Date Created',
    col2: 'Duration',
    col3: 'City',
    col4: 'Site Name',
    col5: 'Source',
    col6: 'Scheduled',
    col7: 'Service Category',
    col8: 'Status',
    col9: 'Customer Name',
    col10: 'Priority',
    status: 'Status',
    cancel: {
      title: 'Cancellation Reason',
      cause: 'Reason'
    }
  },
  newServicesScheduling: {
    title: 'Add New Ticket',
    title2: 'Edit Ticket',
    title3: 'View Ticket',
    subtitle1: 'Issue',
    subtitle2: 'Attachment',
    customerName: 'Site Name',
    contacts: 'Contacts',
    telephone: 'Phone Number',
    serviceType: 'Service Category',
    requiredArrivalTime: 'Required Time of Arrival',
    description: 'Description',
    col1: 'Item Name',
    col2: 'Description',
    AddEquipment: {
      title: 'Issue',
      code: 'QR Code',
      deviceName: 'Issue Name',
      description: 'Issue Description',
    },
  },
  sendOrder: {
    title: 'Assign',
    subtitle1: 'Basic Information',
    subtitle2: 'Engineer',
    subtitle3: 'Send an notification to the customer',
    label1: 'Site Name',
    label2: 'Required Time of Arrival',
    col1: 'Customer Name',
    col2: 'Issue Name',
    col3: 'Required Time of Arrival',
    col4: 'Description',
    col5: 'Date Created',
    col6: 'Full Name',
    col7: 'Location',
    col8: 'Status',
    col9: 'Uncompleted Orders',
    col10: 'Estimated Time of Arrival',
    col11: 'Customer Contacts',
    col12: 'Phone Number',
    col13: 'Email',
    col14: 'Email Notification',
    col15: 'SMS Notification',
    message1: 'Please select the person who has been dispatched',
    tag1: 'Assigned',
    tag2: 'Resend',
    staff: {
      title: 'Select Engineer',
      col1: 'Full name',
      col2: 'Phone Number',
      col3: 'Uncompleted Orders',
      col4: 'In Process',
      col5: 'Location',
      col6: 'Order Count/Current Month',
      col7: 'Attendance',
      col8: 'Leave',
      col9: 'Organization',
      yes: 'Yes',
      no: 'No',
    },
    dialog: {
      title1: 'Edit Estimated Time of Arrival',
      title2: 'Reason for Reassign',
      name1: 'Engineer',
      name2: 'Reassign Engineer',
      reason: 'Reason for Reassign',
      date: 'Estimated Time of Arrival',
    },
  },
  orderStateUpdate: {
    title: 'Update Status',
    subtitle1: 'Basic Information',
    subtitle2: 'Engineer',
    label1: 'Site Name',
    label2: 'Required Time of Arrival',
    col1: 'Customer Name',
    col2: 'Item Name',
    col3: 'Required Time of Arrival',
    col4: 'Description',
    col5: 'Date Created',
    col6: 'Full name',
    col7: 'Install Location',
    col8: 'Order Count/Today',
    col9: 'Estimated Time of Arrival',
    col10: 'Time',
    col11: 'Services Confirmation ID',
    col12: 'Status',
    col13: 'Rescheduling',
    col14: 'Reasons for Uncompleted',
    col15: 'Operation',
    update: 'Update',
    newFormBtn: 'Edit Service Confirmation Form',
    newForm: {
      title: 'Status Update',
      number: 'Services Confirmation ID',
      status: 'Status',
      date: 'Rescheduling',
      Reasons: 'Reasons for Uncompleted',
    },
  },
  servicesConfirmation: {
    downloadPDF: 'Download PDF',
    downloadWord: 'Download Attachment',
    col1: "Services Confirmation ID",
    col2: "City",
    col3: "Site Name",
    col4: "Description",
    col5: "Engineer",
    col6: "Processing Time",
    col7: "Source",
    col8: "Service Type",
    col9: "Date Created",
    col10: 'Customer Name',
  },
  newServiceConfirmationForm: {
    title1: 'Edit Service Confirmation Form',
    title2: 'View Service Confirmation Form',
    subtitle1: 'Confirmation',
    subtitle2: 'On-Site Repair',
    subtitle3: 'Replace Device',
    subtitle4: 'Remove Device',
    subtitle5: 'Other Services',
    subtitle6: 'Basic Information',
    subtitle7: 'Installation',
    subtitle8: 'Maintenance',
    btn1: 'Repair',
    btn2: 'Replace',
    btn3: 'Remove',
    btn4: 'Installation',
    btn5: 'Other',
    btn6: 'Maintenance',
    btn7: 'grade',
    btn8: 'Save and Send Emails',

    label1: 'Site Name',
    label2: 'Site Address',
    label3: 'Service Category',
    label4: 'Services Confirmation ID',
    label5: 'Date Created',
    label6: 'Processing Time',
    label7: 'Reference No.',
    label8: 'Contract&Quote No.',
    label9: 'Purchase Order No.',
    label10: 'Upload Attachment',
    label11: 'Description',
    label12: 'Detail Process',
    label13: 'Description',
    label14: 'Detail Process',
    label15: 'Status',
    label16: 'Rescheduling',
    label17: 'Engineer',
    label18: 'Site Director',
    label19: 'Reasons for Uncompleted',
    label20: 'Client Contact',
    label21: 'JobTitle',
    label22: 'Staff ID',
    label23: 'Signature',
    label24: 'Evaluation',
    label25: 'Comments',

    issueKeyword: {
      title1: 'Choose the Issue Type related faulty item',
      tab1: 'Selected',
      tab2: 'All',
      tab3: 'Items',
      tab4: 'Issues',
      btn1: 'New Issue Type',
      label1: 'New Issue Type',
      label2: 'Service Confirmation',
      label3: 'Technical Observation',
      label4: 'Detail Process',
    },
    serviceContent: {
      title1: 'Choose matched Service Content',
      tab1: 'Selected',
      tab2: 'All',
      btn1: 'New Service Content',
      label1: 'New Service Content',
      label2: 'Service Confirmation',
      label3: 'Technical Observation',
      label4: 'Detail Process',
    },
    tab2: {
      col1: 'Item Name',
      col2: 'Location',
      col3: 'Technical Observation',
      col4: 'Detail Process',
      col5: 'Issue Type',
    },
    dialog2: {
      label1: 'Issue Type',
      label2: 'Fault description',
      label3: 'Processing method',
      label4: 'Replace',
      label5: 'Removal',
      label6: 'Device name',
      label7: 'Installation location',
      label8: 'Specification model',
      label9: 'Serial Number',
      label10: 'QR code',
      label11: 'Installation number',
      label12: 'Attribution',
      label13: 'System Type',
      label14: 'Item Types',
      label15: 'Issue MainType',
      btn1: 'Next',
      btn2: 'Save and copy (multiple devices)',
      title1: 'Add Repair Record',
      title2: 'Edit Repair Record',
      option1: 'None',
      option2: 'ATM',
      option3: 'Branch',
    },
    tab3: {
      col1: 'Device name',
      col2: 'Installation location',
      col3: 'Technical Observation',
      col4: 'Detail Process',
      col5: 'Issue Type',
      col6: 'Old SN',
      col7: 'New SN',
      col8: 'Equipment provider',
    },
    dialog3: {
      label1: 'Reason of Replace',
      label2: 'Issue Type',
      label3: 'Technical Observation',
      label4: 'Detail Process',
      label5: 'Old Item Name',
      label6: 'Location',
      label7: 'Model',
      label8: 'Serical Number',
      label9: 'QR Code',
      label10: 'Display name(label)',
      label11: 'Whereabouts',
      label12: 'Ownership',
      label13: 'New Item Name',
      label14: 'Location',
      label15: 'Model',
      label16: 'Serical Number',
      label17: 'QR Code',
      label18: 'Display name(label)',
      label19: 'Provider',
      label20: 'Reference No.',
      label21: 'Contract&Quote No.',
      label22: 'Purchase Order No.',
      title1: 'Replace',
      option1: 'None',
      option2: 'ATM',
      option3: 'Branch',
      btn1: 'Save and copy (multiple devices)',
    },
    tab4: {
      col1: 'Item Name',
      col2: 'Location',
      col3: 'Technical Observation',
      col4: 'Detail Process',
      col5: 'Serial Number',
      col6: 'Approved By',
      col7: 'On-Site Supervisor',
      col8: 'Whereabouts',
    },
    dialog4: {
      label1: 'Reason of Removal',
      label2: 'Issue Type',
      label3: 'Technical Observation',
      label4: 'Detail Process',
      label5: 'Item Name',
      label6: 'Location',
      label7: 'Model',
      label8: 'Serical Number',
      label9: 'QR Code',
      label10: 'Display name(label)',
      label11: 'Ownership',
      label12: 'Whereabouts',
      label13: 'Approved By',
      label14: 'On-Site Supervisor',
      title1: 'Removal',
      option1: 'None',
      option2: 'ATM',
      option3: 'Branch',
      btn1: 'Save and copy (multiple devices)',
    },
    tab5: {
      col1: 'Item Name',
      col2: 'Location',
      col3: 'Technical Observation',
      col4: 'Detail Process',
      col5: 'Service Content',
    },
    dialog5: {
      label1: 'Service Content',
      label2: 'Technical Observation',
      label3: 'Detail Process',
      label4: 'Item Name',
      label5: 'Location',
      label6: 'Model',
      label7: 'Serical Number',
      label8: 'QR Code',
      label9: 'Display name(label)',
      label10: 'Ownership',
      label11: 'Service Category',
      label12: 'System Category',
      title1: 'New Service Content',
      title2: 'Edit Service Content',
      option1: 'None',
      option2: 'ATM',
      option3: 'Branch',
      btn1: 'Save and copy (multiple devices)',
    },
    tab6: {
      col1: 'Item Name',
      col2: 'Location',
      col3: 'Technical Observation',
      col4: 'Detail Process',
      col5: 'Type of installation',
    },
    dialog6: {
      label1: 'Type of Installation',
      label2: 'Technical Observation',
      label3: 'Detail Process',
      label4: 'Item Name',
      label5: 'Location',
      label6: 'Model',
      label7: 'Serical Number',
      label8: 'QR Code',
      label9: 'Display name(label)',
      label10: 'Ownership',
      label11: 'Provider',
      label12: 'Reference No.',
      label13: 'Contract&Quote No.',
      label14: 'Purchase Order No.',
      title1: 'Installation',
      option1: 'None',
      option2: 'ATM',
      option3: 'Branch',
      btn1: 'Save and copy (multiple devices)',
    },
    tab7: {
      col1: 'Date Time',
      col2: 'File Name',
      col3: 'Engineer',
      col4: 'Service Confrimation',
      btn1: 'Print',
      btn2: 'Export',
    },
  },

  equipmentDialog: {
    title: 'Select Item',
    col1: 'QR Code',
    col2: 'Branch',
    col3: 'Item Name',
    col4: 'Model',
    col5: 'Serial Number',
    col6: 'Location',
    col7: 'Label Text',
  },
  equipmentDialog2: {
    title: 'Select Item',
    col1: 'Item Name',
    col2: 'Model',
    col3: 'Serial Number',
    col4: 'Location',
    col5: 'QR Code',
  },
  equipmentDialog3: {
    title1: 'Select Item',
    title2: 'New Equipment',
    btn1: 'New Equipment',
    col1: 'Item Name',
    col2: 'Brand',
    col3: 'Model',
    col4: 'Item Type',
    label1: 'Device name',
    label2: 'Device type',
    label3: 'Device brand',
    label4: 'Specification model',
  },
  equipmentDialog4: {
    title1: 'Select Item',
    title2: 'New Equipment',
    btn1: 'New Equipment',
    col1: 'Item Name',
    col2: 'Brand',
    col3: 'Model',
    col4: 'Item Type',
    col5: 'Quantity',
    label1: 'Device name',
    label2: 'Device type',
    label3: 'Device brand',
    label4: 'Specification model',
  },
  equipmentDialog5: {
    title: 'Select Stock Item',
    col1: 'Item Name',
    col2: 'Brand',
    col3: 'Model',
    col4: 'Quantity',
    col5: 'PR Number',
  },
  equipmentDialog6: {
    title1: 'Select Device',
    label1: 'Shopping application number',
    label2: 'Subscriber',
    col1: 'Device name',
    col2: 'Equipment brand',
    col3: 'Specification model',
    col4: 'Device Type',
    col5: 'Order quantity',
  },
  equipmentDialog7: {
    title1: 'Select Device',
    col1: 'Device name',
    col2: 'Equipment brand',
    col3: 'Specification model',
    col4: 'Device Type',
    col5: 'Serial Number',
  },
  deviceManagement: {
    tab1: 'Device List',
    btn1: 'QRC Assign',
    btn2: 'Export',
    btn3: 'Export with QRC',
    col1: 'Item Name',
    col2: 'Status',
    col3: 'Model',
    col4: 'Location',
    col5: 'Warranty Exprity Date',
    col6: 'QR Code',
    col7: 'Serial Number',
    col8: 'Data Source',
  },
  newDevice: {
    title1: 'New Item',
    title2: 'Edit Item',
    title3: 'View Item',
    tab1: 'Base Infomation',
    tab2: 'Parameters',
    tab3: 'Project Records',
    tab4: 'Purchase Records',
    tab5: 'Contract&Warranty',
    tab6: 'Records of maintenance',
    label1: 'Item Name',
    label2: 'Operating Status',
    label3: 'Warranty Expiry Date',
    label4: 'Customer',
    label5: 'Site',
    label6: 'Brand',
    label7: 'Item Type',
    label8: 'Serial Number',
    label9: 'Model',
    label10: 'Location',
    label11: 'Display Name(Label)',
    label12: 'Floor',
    label13: 'Device ID',
    label14: 'Device IP',
    label15: 'Installation Date',
    label16: 'Acceptance Date',
    label17: 'Installed Note',
    label18: 'Requested by',
    label19: 'Date Received',
    label20: 'Delivered By',
    label21: 'Delivery Date',
    label22: 'Production Date',
    label23: 'Original Manufacturers Warranty',
    label24: 'Project Name',
    label25: 'Project Category',
    label26: 'Contract&Quote No.',
    label27: 'Contract Term',
    label28: 'Contract Status',
    label29: 'Warranty type',
    label30: 'QR Code',
    repair: {
      title1: 'Repair',
      col1: 'Date Time',
      col2: 'Ticket Status',
      col3: 'Service Category',
      col4: 'Issue SubType',
      col5: 'Issue Desciption',
      col6: 'Detailed Process',
      col7: 'Engineer',
      col8: 'Services Confirmation Form',
    },
    replace: {
      title1: 'Replace',
      col1: 'Date Time',
      col2: 'Reason',
      col3: 'Issue',
      col4: 'Old QRCode',
      col5: 'Old Serial Number',
      col6: 'New QRCode',
      col7: 'New Serial Number',
      col8: 'Location',
      col9: 'Provider',
      col10: 'Comment',
      col11: 'Services Confirmation Form',
    },
    remove: {
      title1: 'Remove',
      col1: 'Date Time',
      col2: 'Reason',
      col3: 'Issue',
      col4: 'QRCode',
      col5: 'Serial Number',
      col6: 'Location',
      col7: 'Approval By',
      col8: 'On-Site Supervisor',
      col9: 'Whereabout',
      col10: 'Comment',
      col11: 'Services Confirmation Form',
    },
    installation: {
      title1: 'Installation',
      col1: 'Date Time',
      col2: 'Type of Installation',
      col3: 'Comment',
      col4: 'Services Confirmation Form',
    },
    maintain: {
      title1: 'Maintain',
      col1: 'Date Time',
      col2: 'Status',
      col3: 'Comment',
      col4: 'Services Confirmation Form',
    },
    otherServices: {
      title1: 'Other Services',
      col1: 'Date Time',
      col2: 'Service Content',
      col3: 'Sercies confirmation Form',
    },
    contractWarranty: {
      col1: 'Signature Date',
      col2: 'Project Name',
      col3: 'Project Category',
      col4: 'Contract&Quote No.',
      col5: 'Contract Term',
      col6: 'Contract Status',
      col7: 'Warranty Type',
      tag1: 'Executory',
      tag2: 'Normal',
      tag3: 'End',
      tag4: 'Unknown',
    },
    contractDialog: {
      title1: 'Select Project',
      col1: 'Signature Date',
      col2: 'Project Name',
      col3: 'Contract&Quote No.',
      col4: 'Project Category',
    },
  },
  projectDialog: {
    title: 'Select Project',
    col1: 'Contract&Quote No.',
    col2: 'Project Name',
  },
  deviceCode: {
    title: 'QRC Assgin',
    print: 'QRC Print',
    indexPrint: 'Index Print',
    printInAdvance: 'QRC Generator',
    batch: 'Batch Assign QRC',
    col1: 'Item Name',
    col2: 'Serial Number',
    col3: 'Assign QRC',
    btn1: 'Generate a QR Code',
    message1: 'Please remove the device that has not been assigned a QR code, and then print it. ',
    advanceCode: {
      title1: 'Automatically generate QR code and print the label out',
      content: 'Select the number of QRC to print',
      message1: 'Please enter a positive integers',
    },
    batchSetQR: {
      title1: 'Reminder',
      content: 'QR code already exists. Are you sure you want to replace it? ',
      btn1: 'OK, Regenerate',
    },
  },
  engineerManagement: {
    col1: 'Full Name',
    col2: 'Employee ID',
    col3: 'Phone Number',
    col4: 'Email',
    col5: 'Status',
    col6: 'Order Count',
    col7: 'Complaint',
    col8: 'Attendance',
    col9: 'Leave',
    col10: 'System User',
    col11: 'Organization',
    filter: {
      title1: 'Filter',
      label1: 'Only show active person',
    },
    import: {
      title1: 'Import from DingDing',
      col1: 'Name',
      col2: 'Employee Number',
      col3: 'Department',
      col4: 'Position',
      col5: 'Work Phone',
      col6: 'Email',
      label1: 'Import and Create User Account',
    }
  },
  newEngineer: {
    title1: 'Add New Engineer',
    title2: 'Edit Engineer',
    title3: 'View Engineer',
    label1: 'Full Name',
    label2: 'Employee ID',
    label3: 'Personal ID',
    label4: 'Data Access Group',
    label5: 'Job Title',
    label6: 'Phone Number',
    label7: 'Backup Phone Number',
    label8: 'Email',
    label9: 'Employee Status',
    label10: 'Attendance',
    label11: 'Organization',
    label12: 'Department',
    label13: 'Site Group',
    label14: 'Report Group',
    label15: 'Leave Type',
    label16: 'Create System Account',
    label17: 'Leave Time',
    label18: 'Outwork',
    label19: 'System User',
    option1: 'Absent',
    option2: 'Present',
    option3: 'Leave',
  },
  inventoryManagement: {
    tab1: 'Inventory',
    tab2: 'Receive Item',
    tab3: 'Deliver Item',
    inventory: {
      btn1: 'QR Code',
      btn2: 'Export',
      btn3: 'Adjustment',
      btn4: 'Receive',
      btn5: 'Deliver',
      btn6: 'Project',
      col1: 'Item Type',
      col2: 'Item Name',
      col3: 'Model',
      col4: 'Brand',
      col5: 'Unit',
      col6: 'Quantity',
      col7: 'Status',
      option1: 'All',
      option2: 'Pending for Check',
      option3: 'Checked',
    },
    inWarehouse: {
      btn1: 'QR Code',
      btn2: 'Export',
      btn3: 'Receive',
      btn4: 'Undo Delivery',
      btn5: 'Delivery',
      btn6: 'Project',
      btn7: 'Review',
      col1: 'Date Received',
      col2: 'Transfer Types',
      col3: 'Supplier',
      col4: 'Item Name',
      col5: 'Brand',
      col6: 'Model',
      col7: 'Item type',
      col8: 'Serial Number',
      col9: 'Unit',
      col10: 'Qty',
      col11: 'Unit',
      col12: 'PR Number',
      col13: 'PO Number',
      col14: 'Order Total',
      col15: 'Requested by',
      col16: 'Buyer',
      col17: 'Project Name',
      col18: 'Contract&Quote No.',
      col19: 'New or Old',
      col20: 'Borrowed Item',
      col21: 'Received Notes',
      col22: 'Review Status',
      col23: 'Review By',
      col24: 'Review Date',
      new: 'New',
      old: 'Old',
      yes: 'Yes',
      no: 'No',
    },
    outWarehouse: {
      btn1: 'QR Code',
      btn2: 'Export',
      btn3: 'Undo Delivery',
      btn4: 'Borrowed',
      col1: 'Delivery Date',
      col2: 'Item Name',
      col3: 'Brand',
      col4: 'Model',
      col5: 'Item type',
      col6: 'Serial Mumber',
      col7: 'Unit',
      col8: 'Qty',
      col9: 'Project Location',
      col10: 'Project Categroy',
      col11: 'Contact Person',
      col12: 'Project Name',
      col13: 'Contract&Quote No.',
      col14: 'Delivery Notes',
      col15: 'Borrow',
      col16: 'Due Date',
      col17: 'Return Date',
      col18: 'Tracking Number',
      col19: 'Logistics Company',
      col20: 'Shipping Cost',
      yes: 'Yes',
      no: 'No',
    },
  },
  inWarehouseDetails: {
    title1: 'Receive',
    title2: 'Date Received',
    title3: 'Edit received Item',
    subtitle1: 'Project Information',
    subtitle2: 'PO Information',
    subtitle3: 'Item Information',
    subtitle4: 'Delivery Information',
    subtitle5: 'Delivery Records',
    btn1: 'Save and Close',
    btn2: 'Save and Add New',
    btn3: 'Save and Deliver',
    yes: 'Yes',
    no: 'No',
    new: 'New',
    old: 'Old',
    label1: 'Warehouse',
    label2: 'Transfer Types',
    label3: 'Project Name',
    label4: 'Contract&Quote No.',
    label5: 'Requested by',
    label6: 'PR Number',
    label7: 'PO Number',
    label8: 'Order Total',
    label9: 'Buyer',
    label10: 'Payment Date',
    label11: 'Item Name',
    label12: 'Model',
    label13: 'Brand',
    label14: 'Item type',
    label15: 'Qty',
    label16: 'Unit',
    label17: 'Serial Number',
    tip17: '(Refers to item without Serial Number, e.g. wire, pipe, screw, tape etc.)',
    label18: 'Serial Number',
    placeholder18: '(Multiple Serial Numbers separated by";")',
    label19: 'Supplier',
    label20: 'Receive Notes',
    label21: 'Warehouse Location',
    label22: 'Borrow',
    label23: 'Return Date',
    label24: 'Lock after Received',
    message1: 'There is an error between the serial number and the number of devices',
    message2: 'The serial number is out of the library and cannot be changed',
    message3: 'The shopping request form is not related to the order and cannot be stored',
  },
  orderDialog: {
    title: 'Select PO',
    col1: 'PO Number',
    col2: 'Payment Date',
    col3: 'Order Total',
    col4: 'Buyer',
    col5: 'Date Created',
    col6: 'Project Name',
  },
  outWarehouseDetails: {
    title1: 'Delivery',
    title2: 'Project',
    title3: 'Date Delivered',
    title4: 'Prompt',
    subtitle1: 'Search',
    subtitle2: 'Project information',
    subtitle3: 'Delivery information',
    btn1: 'Save and Close',
    btn2: 'Project',
    btn3: 'QR code',
    yes: 'Yes',
    no: 'No',
    col1: 'PR Number',
    col2: 'Item Name',
    col3: 'Brand',
    col4: 'Model',
    col5: 'New or Old',
    col6: 'Qty(PR)',
    col7: 'Project Name',
    col8: 'Stock',
    col9: 'Delivered',
    col10: 'Locked',
    col11: 'Delivery',
    label1: 'Project Name',
    label2: 'Contract&Quote No.',
    label3: 'Project Name',
    label4: 'Contract&Quote No.',
    label5: 'Project Location',
    label6: 'Project Category',
    label7: 'Borrow',
    label8: 'Return Date',
    label9: 'Delivery Notes',
    label10: 'Tracking Number',
    label11: 'Logistics Company',
    label12: 'Shipping Cost',
    label13: 'Contact Person',
    label14: 'Phone Number',
    label15: 'Notes',
    label16: 'Shipping Address',
    label17: 'Customer Name',
    label18: 'Site Name',
    message1: 'Please select the outbound device',
    message2: 'The number of shipments cannot be 0',
    message3: 'The device is not assigned a QR code, please assign the QR code before leaving the library. ',
    message4: 'The quantity cannot exceed',
    message5: 'The number of unsequenced devices cannot exceed',
    serial: {
      title1: 'Edit Outbound Quantity',
      tab1: 'No serial number',
      tab2: 'Serial number',
      label1: 'Quantity',
      label2: 'Unit',
      col1: 'Serial Number',
    },
  },
  quotation: {
    btn1: 'Options',
    col1: 'Item Name',
    col2: 'Model',
    col3: 'Brand',
    col4: 'Type',
    col5: 'Latest Price',
    col6: 'Fluctuation',
    col7: 'Average Price',
    col8: 'High Price',
    col9: 'Low Price',
    col10: 'Inquiry Date',
    col11: 'EOL Product',
    tag1: 'Normal',
    tag2: 'EOL',
    options: {
      title1: 'Settings',
      subtitle1: 'Exchange rate',
      subtitle2: 'Price decimal places',
      col1: 'currency',
      col2: 'Exchange rate',
      message1: 'Too many requests, please request again in one hour',
    }
  },
  newQuotation: {
    title1: 'Edit Item Price',
    title2: 'View Item Price',
    label1: 'Item Name',
    label2: 'Model',
    label3: 'Brand',
    label4: 'Type',
    label5: 'EOL Product',
    label6: 'Date',
    btn1: 'Add Alternative',
    btn2: 'Display price',
    tab1: 'Price',
    tab2: 'EOL and Alternative Product',
    tab3: 'Specifications',
    col1: 'Status',
    col2: 'Date Created',
    col3: 'Vendor',
    col4: 'Phone',
    col5: 'Currency',
    col6: 'Unit Price',
    col7: 'Description',
    col8: 'Project Name',
    col9: 'Taxes',
    col10: 'Discount',
    col11: 'Expiring Date',
    col12: 'Attachments',
    col13: 'Inquiry Date',
    col14: 'Create Date',
    col15: 'EOL',
    col16: 'EOL Date',
    col17: 'Item Name',
    col18: 'Model',
    col19: 'Brand',
    col20: 'Type',
    tag1: 'Normal',
    tag2: 'EOL',
    tag3: 'Valid',
    tag4: 'Expired',
  },
  newQuote: {
    title1: 'Add New Quote',
    title2: 'Edit Quote',
    subtitle1: 'Attachments',
    label1: 'Item Name',
    label2: 'Brand',
    label3: 'Vendor',
    label4: 'Contact',
    label5: 'Phone',
    label6: 'Currency',
    label7: 'Unit Price',
    label8: 'Taxes',
    label9: 'Discount',
    label10: 'Creator',
    label11: 'Model',
    label12: 'Type',
    label13: 'Email',
    label14: 'Fax',
    label15: 'Position',
    label16: 'Description',
    label17: 'Project Name',
    label18: 'Expiring Date',
    label19: 'Inquity Date',
    message1: 'Format error, decimal up to 8 digits'
  },
  complaintsEvaluation: {
    col1: 'Date',
    col2: 'Types',
    col3: 'Score',
    col4: 'Submitted By',
    col5: 'Related Information',
    col6: 'Description',
    col7: 'Site Name',
    complaints: {
      title1: 'New Complaint&Suggestion',
      title2: 'Edit Complaint&Suggestion',
      label1: 'Site Name',
      label2: 'Detail',
    }
  },

  purchaseOrders: {
    btn1: 'Print',
    btn2: 'BGB Print',
    col1: 'Date Created',
    col2: 'PO Number',
    col3: 'Payment Terms',
    col4: 'Total',
    col5: 'Buyer',
    col6: 'Project name',
    col7: 'Contract&Quote No.',
    col8: 'Supplier',
  },

  purchaseRequisition: {
    tab1: 'Shopping application',
    tab2: 'Status Tracking',

    applicationForm: {

    },
    stateTracking: {
      btn1: 'Logistics information',
      btn2: 'Sign for',
      btn3: 'Status Details',
      col1: 'Status',
      col2: 'Approval status',
      col3: 'device type',
      col4: 'device name',
      col5: 'brand',
      col6: 'Specification model',
      col7: 'Number of applications',
      col8: 'Number of Orders',
      col9: 'Number of pending orders',
      col10: 'Number of arrivals',
      col11: 'Available stock quantity',
      col12: 'Pre-allocated inventory',
      col13: 'Total number of stocks already in stock',
      col14: 'Out of stock 1',
      col15: 'Out of stock 2',
      col16: 'Out of stock 3',
      col17: 'Signed for',
      col18: 'lock',
      col19: 'Applicant',
      col20: 'Project name',
      col21: 'Contract number',
      col22: 'Shopping application number',
      col23: 'Order number',
    },
    col1: 'Date Created',
    col2: 'PR Number',
    col3: 'Project Name',
    col4: 'Contract&Quote No.',
    col5: 'Contract&Quote Type',
    col6: 'Customer Approval',
    col7: 'Requested by',
    col8: 'Dept Manager',
    col9: 'General Manager',
    col10: 'Purchasing Manager',
    col11: 'Acceptance',
    col12: 'Finance',
    col13: 'Approval Status',
    btn1: 'Print',
    btn2: 'Resubmit',
    btn3: 'Reject',
    btn4: 'Approve',
    title1: 'Rejection Note',
    title2: 'Approval Note',
    tag1: 'Pending for approval',
    tag2: 'Approved',
    tag3: 'Rejected',
  },
  allocateGoods: {
    title1: 'New Purchase Requisition',
    title2: 'Edit Purchase Requisition',
    title3: 'Adjustment',
    title4: 'View Purchase Requisition',
    tab1: 'Purchase Requisition',
    tab2: 'Approval',
    tab3: 'Change log',
    tab4: 'Logistics record',
    tab5: 'Signing record',

    subtitle1: 'Project Information',
    subtitle2: 'Item Information',
    label1: 'PR Number',
    label2: 'Requested by',
    label3: 'Data Access Group',
    label4: 'Project Name',
    label5: 'Contract&Quote No.',
    label6: 'Contract&Quote Type',
    label7: 'Customer Approval',
    label8: 'Shipping Address',
    label9: 'Remark',
    btn1: 'Adjustment',
    btn2: 'Lock',
    btn3: 'Save and Exit',
    btn4: 'Print',
    btn5: 'Reject',
    btn6: 'Approve',

    col1: 'Item Type',
    col2: 'Item Name',
    col3: 'Model',
    col4: 'Brand',
    col5: 'Unit',
    col6: 'Qty(PR)',
    col7: 'Stock',
    col8: 'OutofStock',
    col9: 'Received',
    col10: 'Delivered 1',
    col11: 'Delivered 2',
    col12: 'Locked',
    col13: 'Single Price',
    col14: 'Total Price',
    col15: 'Project Name',
    col16: 'Quotation Number',
    col17: 'Delivered 3',
    message1: 'The purchase request number is duplicate',
    message2: 'Please select a shopping application form',
    addEquipment: {
      title1: 'New Item',
      title2: 'Edit Item',
      label1: 'Item Name',
      label2: 'Item Type',
      label3: 'Brand',
      label4: 'Model',
      label5: 'Qty',
      label6: 'Reason',
      label7: 'Delivery Date',
      label8: 'Notes',
      label9: 'Single Price',
      label10: 'Total Price',
      label11: 'Detailed specifications',
      label12: 'Unit',
      label13: 'Recipient',
    },
    setLock: {
      title1: 'Adjust the number of locks',
      title2: 'Adjustment record',
      btn1: 'View',
      label1: 'Adjust the number of locks',
      label2: 'Reason for adjustment',
      label3: 'Locked quantity',
      message1: 'The lock cannot be greater than the number of applications',
      message2: 'Lock cannot be less than 0',
    },
    applyFrom: {
      label1: 'PR Number',
      label2: 'Requested by',
      label3: 'Data Access Group',
      label4: 'Approval status',
      label5: 'Create method',
      subtitle1: 'Item Information',
      option1: 'normal',
      option2: 'No subscription for storage',
      option3: 'No subscription and delivery',
      btn1: 'Locked',
      btn2: 'Replace',
      btn3: 'Sign For',
      col1: 'Item Type',
      col2: 'Item Name',
      col3: 'Model',
      col4: 'Brand',
      col5: 'Unit',
      col6: 'Qty(PR)',
      col7: 'Unit price',
      col8: 'Total price',
      col9: 'Number of orders',
      col10: 'Pre-allocated inventory',
      col11: 'Project name',
      col12: 'Contract number',
    },
    changeRecord: {
      col1: 'date',
      col2: 'Status',
      col3: 'Project name',
      col4: 'device name',
      col5: 'brand',
      col6: 'Specification',
      col7: 'Subscription quantity',
      col8: 'reason',
      col9: 'operator',
      col10: 'Attachment',
    },
    logisticsRecord: {
      col1: 'date',
      col2: 'Shipment method',
      col3: 'type',
      col4: 'device name',
      col5: 'brand',
      col6: 'Model',
      col7: 'Subscription quantity',
      col8: 'Order Quantity',
      col9: 'Status',
      col10: 'Estimated delivery date',
      col11: 'Shipping Quantity',
      col12: 'Arrival quantity',
      col13: 'Sender',
      col14: 'Logistics company',
      col15: 'Recipient',
      col16: 'Consignment number',
      col17: 'Signing status',
      col18: 'operator',
      col19: 'Remarks',
      option1: 'Factory direct delivery project',
      option2: 'BGB warehouse delivery',
      option3: 'Manufacturer sends BGB warehouse',
      option4: 'Shipping',
      option5: 'arrival',
      option6: 'Not shipped',
      option7: 'shipped-part',
      option8: 'shipped-all',
    },
    signInRecord: {
      col1: 'Signing date',
      col2: 'signee',
      col3: 'Project name',
      col4: 'device name',
      col5: 'brand',
      col6: 'Specification',
      col7: 'Number of applications',
      col8: 'Number of receipts',
      col9: 'attachment',
    },
  },
  newOrder: {
    title1: 'Add New Purchase Order',
    title2: 'Edit Purchase Order',
    title3: 'View Purchase Order',
    tab1: 'Purchase Order',
    tab2: 'Purchase Order Tracker',
    orderForm: {
      subtitle1: 'PO Information',
      subtitle2: 'Terms',
      subtitle3: 'Order Content',
      btn1: 'Save and Exit',
      btn2: 'Print',
      label1: 'Project Name',
      label2: 'Contract&Quote No.',
      label3: 'Data Access Group',
      label4: 'PO Number',
      label5: 'PO Type',
      label6: 'Buyer',
      label7: 'Supplier',
      label8: 'Payment Date',
      label9: 'Order Total',
      label10: 'Delivery Date',
      label11: 'Freight',
      label12: 'Consignee',
      label13: 'Receiving Address',
      label14: 'Contact Information',
      label15: 'Tax Rate',
      label16: 'Invoice Type',
      label17: 'Contract Date',
      label18: 'Combine same items',
      label19: 'Article 1 Supplement',
      label20: 'Article 2 Supplement',
      label21: 'Article 3 Supplement',
      col1: 'Item Type',
      col2: 'Item Name',
      col3: 'Brand',
      col4: 'Model',
      col5: 'Unit',
      col6: 'Unit Price',
      col7: 'Qty',
      col8: 'Qty Received',
      col9: 'Delivery Date',
      col10: 'PR Number',
      col11: 'Requested by',
      col12: 'Remarks',
      col13: 'Project Name',
      addEquipment: {
        title1: 'Add New Item',
        title2: 'Edit Item',
        label1: 'Purchase Requisition',
        label2: 'PR Number',
        label3: 'Item Type',
        label4: 'Item Name',
        label5: 'Brand',
        label6: 'Model',
        label7: 'Qty',
        label8: 'Unit Price',
        label9: 'Delivery Date',
        label10: 'Notes',
        yes: 'Yes',
        no: 'No',
      },
      allocateGoods: {
        title1: 'Adjustment',
        col1: 'Purchase Requisition Number',
        col2: 'Item Name',
        col3: 'Model',
        col4: 'Project Name',
        col5: 'Stock',
        label1: 'Total',
        label2: 'Qty(PR)',
        label3: 'Delivered 1 (Same Project)',
        label4: 'Stock',
        label5: 'Delivered 2 (Others)',
        label6: 'Out of Stock',
        label7: 'Please enter the quantity to create new Purchase Order',
      },
    },
    orderTracking: {
      btn1: 'Status Update',
      btn2: 'Print',
      col1: 'Updated Date',
      col2: 'Operator',
      col3: 'Payment_Status 1',
      col4: 'Order_Status 2',
      col5: 'Received_Status 3',
      updateState: {
        title1: 'Purchase Order Status Update',
        radio1: 'Payment',
        radio2: 'Order',
        radio3: 'Received',
        label1: 'Payment Status',
        label2: 'Payment Date',
        label3: 'Total PO Amount',
        label4: 'Total Paid',
        label5: 'Total Outstanding',
        label6: 'Paid of this Installment',
        label7: 'Notes',
        label8: 'Order Status',
        label9: 'Order Date',
        label10: 'Expected Delivery Date',
        label11: 'Notes',
        label12: 'Received Status',
        label13: 'Received Date',
        label14: 'The Remaining items expected Delivery Date',
        label15: 'Notes',
        message1: 'Please save the order form first',
      }
    }
  },
  addOrder: {
    title1: 'New order',
    title2: 'Order quantity and priceedit',
    subtitle1: 'Order Quantity',
    subtitle2: 'Items to be ordered',
    label1: 'Number of applications',
    label2: 'Out of stock quantity',
    label3: 'Stock quantity',
    label4: 'Out of library 1/2/3',
    label5: 'Ordered quantity',
    label6: 'This order quantity',
    label7: 'Unit price',
    table1: {
      col1: 'device type',
      col2: 'device name',
      col3: 'brand',
      col4: 'Specification model',
      col5: 'Number of applications',
      col6: 'Ordered quantity',
      col7: 'Shopping application number',
      col8: 'Project name',
    },
    table2: {
      col1: 'device type',
      col2: 'device name',
      col3: 'brand',
      col4: 'Specification model',
      col5: 'Order quantity',
      col6: 'Unit price',
      col7: 'Remarks',
      col8: 'Repurchase time required',
      col9: 'Shopping application number',
      col10: 'Project name',
      col11: 'Subscriber',
    },
  },
  inventoryStatistics: {
    label1: 'Item Type',
    label2: 'Item Name',
    label3: 'Model',
    label4: 'Brand',
    label5: 'Unit',
    label6: 'Received',
    label7: 'Delivered',
    label8: 'Stock',
  },
  deviceDetailsList: {
    title1: 'List',
    col1: 'Receive&Deliver',
    col2: 'Time',
    col3: 'Serial Number',
    col4: 'Quantity',
    col5: 'Unit',
    col6: 'Item Type',
    col7: 'Item Name',
    col8: 'Model',
    col9: 'Project',
    col10: 'Requested by',
  },
  score: {
    title1: 'Customer Satisfaction Score',
    subtitle1: 'Description',
    label1: 'Total Score',
    label2: 'Score',
    label3: 'Total Score',
    tag1: 'Very Dissatisfied',
    tag2: 'Dissatisfied',
    tag3: 'Neutral',
    tag4: 'Satisfied',
    tag5: 'Very Satisfied',
  },
  ApplyDialog: {
    title1: 'Select Purchase Requisition Form',
    col1: 'Project Name',
    col2: 'Contract&Quote No.',
    col3: 'Purchase Requisition Number',
  },
  SupplierDialog: {
    title1: 'Supplier',
    title2: 'Add New Supplier',
    col1: 'Supplier',
    col2: 'Abbreviation',
  },
  inventoryDevice: {
    title1: 'Inventory - Equipment Details',
    col1: 'Stock Date',
    col2: 'Warehouse area',
    col3: 'Device type',
    col4: 'Device name',
    col5: 'Specification model',
    col6: 'equipment brand',
    col7: 'quantity',
    col8: 'Unit',
    col9: 'Serial Number',
    col10: 'Shopping application number',
    col11: 'Project name',
    col12: 'Contract Quotation Number',
    col13: 'Order number',
  },
  approvalWorkflow: {
    col1: 'Request Name',
    col2: 'Workflow ID',
    col3: 'Step',
    col4: 'Diagram',
    col5: 'Department',
    col6: 'Approved By',
    col7: 'Carbon Copy(CC)',
    workfow: {
      title1: 'Add New Approvel Workfow',
      title2: 'Edit Approvel Workfow',
      subtitle1: 'Approval process',
      subtitle2: 'Carbon Copy',
      subtitle3: 'Approval options',
      label1: 'Request Name',
      label2: 'WorkFlow ID',
      label3: 'Step',
      label4: 'Diagram',
      col1: 'Department',
      col2: 'Approved by',
      col3: 'Department',
      col4: 'CC',
      option1: 'Review',
      option2: 'Approve',
    },
    person: {
      title1: 'Add Person'
    }
  },
  appRelease: {
    col1: 'Platform',
    col2: 'Version Type',
    col3: 'Version Number',
    col4: 'File Name',
    col5: 'File Size',
    col6: 'Release Date',
    col7: 'Creator',
    title1: 'New Release',
    label1: 'Upload',
    label2: 'Path',
    message1: 'Please upload the installation package',
    message2: 'Please upload apk type file',
  },
  projects: {
    col1: 'Project Name',
    col2: 'Duration',
    col3: 'Start',
    col4: 'Finish',
    col5: 'Percent Complete',
  },
  newProjects: {
    title1: 'New item',
    title2: 'Edit project',
    tab1: 'Basic information',
    tab2: 'Gantt Chart',
    tab3: 'list',
    tab4: 'Document',
    info: {
      label1: 'Project Name',
      label2: 'Project Category',
      label3: 'Data Access Group',
      label4: 'Date',
      label5: 'Contract No',
      label6: 'Note',
    },
    documents: {
      col1: 'Task',
      col2: 'Required Documents',
      col3: 'Submitted  Documents',
    },
    ganttChart: {
      col1: 'Task',
      col2: 'Duration',
      col3: 'Start',
      col4: 'Finish',
      col5: 'People Assigned',
      btn1: 'Menu',
      btn2: 'New Task',
      btn3: 'New Standard Task',
      btn4: 'Set Baseline',
      btn5: 'Export',
      btn6: 'Zoom',
      btn7: 'Day',
      btn8: 'Week',
      btn9: 'Month',
      task: {
        title1: 'New Task',
        title2: 'Edit Task',
        title3: 'New Standard Task',
        title4: 'Edit Standard Task',
        title5: 'Select Task',
        title6: 'Select Documents',
        tab1: 'General',
        tab2: 'Resources',
        tab3: 'Documents',
        label1: 'Task Name',
        label2: 'Start Date',
        label3: 'Finish Date',
        label4: 'Duration',
        label5: 'Percent Complete',
        label6: 'Predecessor',
        label7: 'Note',
        label8: 'Milestone',
        subtitle1: 'Standard Task',
        col1: 'Person',
        col2: 'Hours',
        col3: 'Number',
        col4: 'Standard Task',
        col5: 'Type',
        col6: 'Sequence',
        col7: 'Stage',
      }
    },
  },
  documentsDetail: {
    titile1: 'Documents Detail',
    label1: 'Task Name',
  },
  standardTask: {
    title1: 'New Standard Task',
    title2: 'Edit Standard Task',
    col1: 'Standard Task',
    col2: 'Nubmer',
    col3: 'Task Type',
    col4: 'Project Category',
    col5: 'Documents',
    col6: 'Sequence',
    label1: 'Standard Task',
    label2: 'Task Type',
    label3: 'Project Category',
    label4: 'Note',
    label5: 'Documents',
    label6: 'Sequence',
    label7: 'Stage',
    btn1: 'Sort Up',
    btn2: 'Sort Down',
  },
  projectDocuments: {
    col1: 'File Name',
    col2: 'Format',
    col3: 'Operation',
    btn1: 'Upload',
    btn2: 'Download',
    title1: 'New Folder',
    title2: 'Edit Folder',
    title3: 'New File',
    title4: 'Edit File',
    label1: 'Folder Name',
    label2: 'File Name',
    label3: 'Format',
  },
  mySelect: {
    option1: 'No matching data',
  },

  dashBoard: {
    btn1: 'Risk Level Settings',
    btn2: 'Print...',
    btn3: 'Export',
    btn4: 'Layout Templates',
    btn5: 'Layout Settings',
    btn6: 'Save as New',
    btn7: 'Save',
    dashboardTemplate: {
      title1: 'Layout Templates',
      col1: 'Templates Name',
      col2: 'System Default',
      col3: 'Organization',
      col4: 'Data Access Group',
      col5: 'Current',
      btn1: 'Set Current',
      btn2: 'Layout Edit',
    },
    addDashboard: {
      title1: 'Save as New Layout Template',
      title2: 'Edit Layout Template',
      label1: 'Templates Name',
      label2: 'Data Access Group(Data Permissions)',
      label3: 'Assign template to the selected organization',
      label4: 'Set as default',
    },
    exportReports: {
      title1: 'Export Reports',
      label1: 'File Name',
      label2: 'File Format',
    },
    overview: {
      title1: 'Overview Data Presentation',
      label1: 'Report Template',
      col1: 'Current',
      col2: 'Title',
      col3: 'Report Template',
      col4: 'Chart',
      col5: 'Table',
    }
  },
  counterMode: {
    btn1: 'Risk Level Settings',
    btn2: 'Print...',
    btn3: 'Export',
    btn4: 'Layout Settings',
    btn5: 'Save',
    btn6: 'Add to Condition',
    btn7: 'New Query',
    btn8: 'Copy',
    filterPopover: {
      title1: 'Sort & Filter',
      btn1: 'Reset',
      btn2: 'Sort A to Z (Smallest to Largest)',
      btn3: 'Sort Z to A (Largest to Smallest)',
      btn4: 'Custom Sort...',
      btn5: 'Filters',
    }
  },
  reportTemplates: {
    col1: 'Report templates',
    col2: 'Description',
    col3: 'Data Access Group',
    col4: 'Mode',
    col5: 'Analysis Mode',
    col6: 'Creator',
    dialog: {
      title1: 'New Report Templates',
      title2: 'Edit Report Templates',
      label1: 'Report Templates Name',
      label2: 'Description（pos loss prevention Analytics）',
      label3: 'Data Access Group (Data Permissions)',
      label4: 'Analysis Mode',
      label5: 'Counter Mode',
      label6: 'Dining Mode',
    }
  },
  reportGroup: {
    btn1: 'Add Node',
    label1: 'Selected Node',
    col1: 'Report templates',
    col2: 'Description',
    col3: 'Data Access Group',
    nodeDialog: {
      title1: 'Add new Node',
      title2: 'Edit Node',
      label1: 'Node Name (Report Categories)',
      label2: 'Descirption',
      label3: 'Data Access Group (Data Permissions)',
    }
  },
  newReportGroup: {
    title1: 'Report Categories Allocation',
    label1: 'Selected Group',
    label2: 'Data Access Group (Data Permissions)',
    label3: 'Descirption',
    label4: 'Report Templates List',
    label5: 'Selected',
    col1: 'Number',
    col2: 'Report Templates',
    col3: 'Description',
  },
  reportDesigner: {
    label1: 'Report Template',
    label2: 'Charts Types',
    label3: 'Analysis Mode',
    label4: 'Display Name Settings',
    label5: 'Sub Field Second Language',
    label6: 'Main Field Second Language',
    col1: 'Report Main Field',
    col2: 'Report Sub Field',
    col3: 'Field Type',
    col4: 'Measure',
    col5: 'Risk Level Fiields',
    col6: 'Overview Fields',
    col7: 'Link to',
    col8: 'Manually Sort',
    dialog: {
      title1: 'New Report Field',
      title2: 'Edit Report Field',
      label1: 'Field Type',
      label2: 'Multiple Column Headers',
      label3: 'Report Main Field',
      label4: 'Report Main Field',
      label5: 'Parmeters Field',
      label6: 'Second Language',
      label7: 'Description',
      label8: 'Report Sub Field',
      label9: 'Default Operator',
      label10: 'Measure',
      label11: 'Options Value',
      label12: 'Risk Level Fields',
      label13: 'Default Query Fileds',
    }
  },
  chartType: {
    title1: 'Chart Title',
    label1: 'All Charts',
    label2: 'Chart Title',
    btn1: 'Upload',
    type1: 'Icon',
    type2: 'Bar',
    type3: 'Pie',
    type4: 'Line',
    tab1: 'Data Source',
    tab2: 'Query Conditions',
    tab3: 'Sorting',
    tab4: 'Chart Elements',
    dataSource: {
      title1: 'Select Data Source',
      col1: 'Field',
      col2: 'Type',
      col3: 'Link to',
    },
    queryConditions: {
      btn1: 'Add Field',
      btn2: 'Add Parameters',
      col1: 'AND/OR',
      col2: 'Query Fields',
      col3: 'Field Type',
      col4: 'Invert',
      col5: 'Filter Operator',
      col6: 'Condition',
    },
    sorting: {
      col1: 'Field',
      col2: 'Order',
      col3: 'Status',
      title1: 'Soritng'
    },
    chartElements: {
      col1: 'Chart Elements',
      col2: 'Options',
      col3: 'Status',
    }
  },
  fieldDisplaySet: {
    title1: 'Display Name Settings',
    col1: 'Field Name',
    col2: 'Data Display Name',
    col3: 'Data Code',
    col4: 'Global Settings',
    dialog: {
      title1: 'Data Display Name Settings',
    },
  },
  riskOfLossLevel: {
    col1: 'Risk Level Templates',
    col2: 'Description',
    col3: 'System Default',
    col4: 'Data Access Group',
    col5: 'Creator',
    col6: 'Brand',
    col7: 'Status',
  },
  newRiskOfLossLevel: {
    title1: 'New Risk of Loss Level Templates',
    title2: 'Edit Risk of Loss Level Templates',
    label1: 'Templeates Name',
    label2: 'Data Access Group ( Data Permissions)',
    label3: 'Brand',
    label4: 'Descirption',
    label5: 'Set as default',
    col1: 'Report Templates',
    col2: 'Main Fields',
    col3: 'Sub Fields',
    col4: 'Measure',
  },
  slaPolicies: {
    tab1: 'SLA Policies',
    tab2: 'Escalation Level',
    col1: 'SLA Policy',
    col2: 'Type of SLA',
    col3: 'DAG',
    col4: 'Customer',
    col5: 'Branch/Site',
    col6: 'Date Created',
    col7: 'Escalation Level',
  },
  newSLAPolicy: {
    title1: 'New SLA Policy',
    title2: 'Edit SLA Policy',
    title3: 'View SLA Policy',
    label1: 'SLA Policy',
    label2: 'Type of SLA',
    label3: 'DAG',
    col1: 'color',
    col2: 'Priority',
    col3: 'Priority ID',
    col4: 'Name',
    col5: 'Response',
    col6: 'Operational Hours',
    col7: 'Timers',
    col8: 'Warning',
    col9: 'Send to',
    col10: 'Timers',
    col11: 'Escalation',
    col12: 'Resolution',
    col13: 'Timers',
    col14: 'Warning',
    col15: 'Send to',
    col16: 'Timers',
    col17: 'Escalation',
  },
  newSLAPriority: {
    title1: 'New SLA Priority',
    title2: 'Edit SLA Priority',
    title3: 'View SLA Priority',
    tab1: 'Escalation',
    tab2: 'Service Category',
    label1: 'Priority Name',
    label2: 'Priority ID',
    label3: 'SLA Stage Type',
    label4: 'Stage ID',
    label5: 'Trigger Name',
    label6: 'Start Timer when',
    label7: 'Description',
    label8: 'Stop Timer when',
    label9: 'Service content ',
    label10: 'Enable/Disable',
    col1: 'TriggerID',
    col2: 'Operational hours',
    col3: 'Timer(Within SLA)',
    col4: 'Warning',
    col5: 'Timer',
    col6: 'Escalation(Breached SLA)',
  },
  newSLAEscalationLevel: {
    title1: 'Edit SLA Escalation Level',
    label1: 'SLA',
    label2: 'DAG',
    col1: 'Level',
    col2: 'Full Name',
    col3: 'SiteGroup',
    col4: 'Organization',
    col5: 'Job Title',
    col6: 'Email',
    col7: 'Phone Number',
    col8: 'Backup Email',
    col9: 'Backup Phone Number',
    col10: 'Description',
    col11: 'Type',
    contactDialog: {
      title1: 'Add Contacts',
      label1: 'Type',
      label2: 'Level',
      label3: 'Description',
      col1: 'Full Name',
      col2: 'Job Title',
      col3: 'Email',
      col4: 'Phone',
    }
  },
  operationalTime: {
    col1: 'Organization Name',
    col2: 'DAG',
    col3: 'TimZone',
    col4: 'Country',
    title1: 'Copy to',
    btn1: 'Copy to'
  },
  editOperationalTime: {
    title1: 'Edit Operational Time',
    tab1: 'Working Hours',
    tab2: 'Holiday Hours',
    label1: 'Organization Name',
    label2: 'Descritipn',
    label3: 'DAG',
    label4: 'Country',
    label5: 'TimeZone',
    col1: 'Holiday Group Name',
    col2: 'Type',
    col3: 'Special Working Hours',
  },
  newHolidayHours: {
    title1: 'New Holiday Group Name',
    title2: 'Edit Holiday Group Name',
    title3: 'View Holiday Group Name',
    label1: 'Holiday Group Name',
    label2: 'Type',
    col1: 'Title',
    col2: 'Date',
  },
  maintenance: {
    tab1: 'Calendar',
    tab2: 'Maintenance',
    tab3: 'Record',
    calendar: {
      label1: 'Completed',
      label2: 'Scheduled',
      label3: 'Incomplete',
      label4: 'Date',
      col1: 'Sunday',
      col2: 'Monday',
      col3: 'Tuesday',
      col4: 'Wednesday',
      col5: 'Thursday',
      col6: 'Friday',
      col7: 'Saturday',
    },
    maintenance: {
      btn1: 'Create a Copy',
      btn2: 'Copy to',
      btn3: 'Print',
      btn4: 'Export',
      col1: 'File Name',
      col2: 'Site Name',
      col3: 'Check List',
      col4: 'Status',
      col5: 'Template',
    },
    record: {
      btn1: 'Print',
      btn2: 'Export',
      col1: 'Date Time',
      col2: 'File Name',
      col3: 'Site Name',
      col4: 'Engineer',
      col5: 'Service Confrimation',
    }
  },
  maintenanceCheckList: {
    title1: 'Maintenance Check List',
    btn1: 'Template',
    btn2: 'Print',
    btn3: 'Export',
    btn4: 'PDF Template Designer',
    label1: 'Total',
    col1: 'Icon',
    col2: 'Title',
    col3: 'Sub Title 1',
    col4: 'Sub Titile 2',
    col5: 'Status',
    col6: 'Normal',
    col7: 'Abnormal',
  },
  newMaintenanceCheckList: {
    title1: 'Add New Check List',
    title2: 'Edit Check List',
    title3: 'View Check List',
    tab1: 'Table Elements',
    tab2: 'Table Content',
    tab3: 'Check List',
    col1: 'Title',
    col2: 'Sub Title 1',
    col3: 'Sub Title 2',
    col4: 'Icon',
    tableContent: {
      btn1: 'Import',
      btn2: 'Up',
      btn3: 'Down',
      btn4: 'Option Edit',
      btn5: 'Copy',
      col1: 'MainType(Column MainTitle)',
      col2: 'Check Item(Column Name)',
      col3: 'Notes',
      col4: 'Check Type',
      col5: 'Option',
      col6: 'Count',
      col7: 'Related Device',
      checkDialog: {
        title1: 'Add Item',
        title2: 'Edit Item',
        title3: 'Create a copy',
        label1: 'MainType(Column MainTitle)',
        label2: 'Notes',
        label3: 'Check Item(Column Name)',
        label4: 'Check Type',
        label5: 'Option',
        btn1: 'Create a copy',
      },
      field: {
        label1: 'Item Name',
        label2: 'Brand',
        label3: 'Item Type',
        label4: 'Model',
        label5: 'Location',
        label6: 'Serical Number',
        label7: 'Display Name(Label)',
        label8: 'Device ID',
        label9: 'Floor',
        label10: 'QR Code',
        label11: 'IP Address',
        label12: 'Remarks',
      },
    },
    checkList: {
      btn1: 'Import',
      btn2: 'Up',
      btn3: 'Down',
    }
  }
}

export default en;
