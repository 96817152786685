<template>
  <div>
    <div
      id="qrcode"
      ref="qrcode"
    ></div>
  </div>
</template>

<script>
// 插件
import QRCode from 'qrcodejs2'
export default {
  props: ['value', 'width', 'height'],
  data () {
    return {
      qrcode: null,
    }
  },
  watch: {
    'value': {
      handler: function (newVal) {
        this.init();
      },
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (!!this.value && !this.qrcode) {
        this.initQrcode();
      } else if (!!this.value && !!this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(this.value);
      }
    },
    initQrcode () {
      this.qrcode = new QRCode(this.$refs.qrcode, {
        width: this.width || 132,
        height: this.height || 132,
        text: this.value, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
      })
    },
  }
}
</script>

<style>
</style>