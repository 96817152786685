<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          :data="tableData"
          ref="table"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            label="Site Name"
            prop="siteName"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            label="File Name"
            prop="fileName"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="80"
            label="Icon"
            prop="priorityIdName"
            sortable
          >
            <template slot-scope="scope">
              <el-image :src="baseUrl + '/Engineer/getImage.do?imageId=' + scope.row.icon"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            label="Title"
            prop="title"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            label="Sub Title 1"
            prop="subTitle1"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            label="Sub Titile 2"
            prop="subTitle2"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >Create a Copy</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  BatchAddTableElement,
  GetTableElementList
} from '@/api/warehouse/maintenance'
export default {
  props: ['visible', 'fileId'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      dialog: {
        visible: false,
        loading: false,
        title: 'Check List Template',
      },
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.searchData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        // fileId: this.fileId
      }
      this.tableLoading = true;
      GetTableElementList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')

      let param = this.tableSelected.map(item => {
        item.id = null;
        item.fileId = this.fileId;
        item.tableContentList = item.tableContentList.map(item => {
          item.id = null;
          return item;
        })
        return item;
      })

      this.dialog.loading = true;
      BatchAddTableElement(param).then(res => {
        if (res.success) {
          this.$emit('update')
          this.dialog.loading = false;
          this.dialog.visible = false;
        }
      }).catch(error => {
        this.dialog.loading = false;
      })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}
</style>