<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="dialog.queryType == 0 ? 'Query' : 'Template Parameters'"
      :visible.sync="dialog.visible"
      center
      width="650px"
      class="query-dialog"
    >
      <div>
        <el-tabs
          type="border-card"
          v-model="dialog.activeTab"
        >
          <el-tab-pane
            label="New Query"
            name="New Query"
          >
            <el-form
              size="small"
              :model="dialog"
              :rules="rules"
              ref="queryForm"
              class="query-form"
            >
              <el-form-item
                v-show="dialog.queryType == 0"
                prop="relation"
              >
                <el-radio-group v-model="dialog.relation">
                  <el-radio label="and">And</el-radio>
                  <el-radio label="or">Or</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                :label="dialog.queryType == 0 ? 'Query Field':'Parmeters Field'"
                prop="field"
              >
                <el-select
                  v-model="dialog.field"
                  @change="fieldChange"
                >
                  <el-option
                    v-for="item in fields[dialog.queryType]"
                    :key="item.id"
                    :value="item.mainFieText"
                    :label="item.mainFieText"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="Description"
                prop="description"
              >
                <el-input
                  type="textarea"
                  v-model="dialog.description"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item
                v-show="dialog.queryType == 0"
                prop="invert"
              >
                <el-checkbox v-model="dialog.invert">Invert</el-checkbox>
              </el-form-item>
              <el-form-item
                label="Filter Operator"
                prop="operator"
              >
                <el-select
                  v-model="dialog.operator"
                  @change="operatorChange"
                >
                  <el-option
                    v-for="(item,index) in operators"
                    :key="index"
                    :value="index"
                    :label="item"
                  ></el-option>
                </el-select>
              </el-form-item>

              <template v-if="currentMeasure.type === 'date'">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-form-item
                      label="Condition"
                      prop="condition"
                    >
                      <el-select
                        v-model="dialog.condition"
                        @change="changeDataType"
                      >
                        <el-option
                          v-for="(item,index) in dateCondition"
                          :key="index"
                          :value="index"
                          :label="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Measure">
                      <el-input
                        v-model="currentMeasure.name"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-form-item
                      v-show=" dialog.condition === 12"
                      prop="dateRange"
                    >
                      <div class="range-box">
                        <el-date-picker
                          v-model="dialog.dateRange"
                          type="datetimerange"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          unlink-panels
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
              <template v-else-if="currentMeasure.type === 'option'">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-form-item
                      label="Condition"
                      prop="condition"
                    >
                      <el-select v-model="dialog.condition">
                        <el-option
                          v-for="(item,index) in optionData"
                          :key="index"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Measure">
                      <el-input
                        v-model="currentMeasure.name"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

              </template>
              <template v-else-if="currentMeasure.type === 'time'">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-form-item
                      label="Condition"
                      prop="dateRange"
                    >
                      <div class="range-box">
                        <el-time-picker
                          :disabled="dialog.operator === 0"
                          class="range-input"
                          v-model="dialog.dateRange[0]"
                          size="small"
                          value-format="HH:mm"
                        >
                        </el-time-picker>
                        <span class="range-line"></span>
                        <el-time-picker
                          :disabled="dialog.operator === 1"
                          class="range-input"
                          v-model="dialog.dateRange[1]"
                          size="small"
                          value-format="HH:mm"
                        >
                        </el-time-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Measure">
                      <el-input
                        v-model="currentMeasure.name"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
              <template v-else-if="currentMeasure.type === 'mins'">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-form-item
                      v-if=" dialog.operator !== 2"
                      label="Condition"
                      prop="condition"
                    >
                      <el-input v-model="dialog.condition"></el-input>
                    </el-form-item>
                    <el-form-item
                      v-else
                      label="Condition"
                      prop="range"
                    >
                      <div class="range-box">
                        <el-input
                          class="range-input"
                          v-model="dialog.range.start"
                        ></el-input>
                        <span class="range-line"></span>
                        <el-input
                          class="range-input"
                          v-model="dialog.range.end"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Measure">
                      <el-input
                        v-model="currentMeasure.name"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
              <template v-else-if="currentMeasure.type === 'stores'">
                <el-row
                  :gutter="20"
                  v-if="dialog.operator != 2"
                >
                  <el-col :span="16">
                    <el-form-item
                      label="Condition"
                      prop="condition"
                    >
                      <el-input v-model="dialog.condition"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Measure">
                      <el-input
                        v-model="currentMeasure.name"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row
                  :gutter="20"
                  v-else
                >
                  <el-col>
                    <el-button
                      class="list-btn"
                      @click="showList"
                    >List...</el-button>
                  </el-col>

                </el-row>
              </template>
              <!-- number string stores days LowValues -->
              <template v-else>
                <el-row
                  :gutter="20"
                  v-if="dialog.operator != 6"
                >
                  <el-col :span="16">
                    <el-form-item
                      v-if=" dialog.operator !== 7"
                      label="Condition"
                      prop="condition"
                    >
                      <el-input v-model="dialog.condition"></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if=" dialog.operator === 7 && currentMeasure.type === 'number'"
                      label="Condition"
                      prop="range"
                    >
                      <div class="range-box">
                        <el-input
                          class="range-input"
                          v-model="dialog.range.start"
                        ></el-input>
                        <span class="range-line"></span>
                        <el-input
                          class="range-input"
                          v-model="dialog.range.end"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Measure">
                      <el-input
                        v-model="currentMeasure.name"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row
                  :gutter="20"
                  v-else
                >
                  <el-col>
                    <el-button
                      class="list-btn"
                      @click="showList"
                    >List...</el-button>
                  </el-col>

                </el-row>
              </template>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            label="History"
            name="History"
          >
            <div class="history-wrap">
              <div
                class="history-item"
                v-for="(item,index) in recordData"
                :key="index"
                @click="showQueriest(item)"
              >
                <span>{{item.createTime}}</span>
                <span>{{item.name}}</span>
                <span>{{item.condition.con}}</span>
                <span>
                  <i
                    class="el-icon-close"
                    @click.stop="delRecord(item)"
                  ></i>
                </span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Favorites"
            name="Favorites"
          >
            <div class="history-wrap">

              <div
                class="history-item"
                v-for="(item,index) in collectData"
                :key="index"
                @click="showQueriest(item)"
              >
                <span>{{item.createTime}}</span>
                <span>{{item.name}}</span>
                <span>{{item.condition.con}}</span>
                <span>
                  <i
                    class="el-icon-close"
                    @click.stop="delRecord(item)"
                  ></i>
                </span>
              </div>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          v-show="dialog.activeTab === 'New Query'"
          @click="submit(2)"
        >Add to Favorites</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit(1)"
        >Submit</el-button>
      </div>
    </el-dialog>

    <!-- 列表弹框 -->
    <list-dialog
      :visible.sync="ListDialogVisible"
      :label="dialog.field"
      :list="dialog.list"
      @listSubmit="listSubmit"
    ></list-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'

// 组件
import ListDialog from '@/components/DashBoardDialog/ListDialog'

// 接口
import {
  GetTemplateParam,
  GetParamRecord,
  AddParamRecord,
  DelParamRecord,
} from '@/api/counterMode'
import { GetFieldTypeAll } from '@/api/reportDesigner'

export default {
  name: 'Query',
  props: ['visible', 'reportTemplateId', 'data'],
  components: {
    ListDialog,
  },
  data () {
    return {
      ListDialogVisible: false,
      measures: [],
      fields: [[], []],
      // dateCondition: ['Last 30 minutes', 'Last 1 hour', 'Last 4 hours', 'Today', 'Yesterday', 'Last 3 Days', 'Last 5 Days', 'Last 1 Week', 'Last 30 Days', 'Last 90 Days', 'Last Year', 'All Time', 'Custom Range'],
      dateCondition: ['Last 30 minutes', 'Last 1 hour', 'Last 4 hours', 'Today', 'Yesterday', 'Last 3 Days', 'Last 5 Days', 'Last 1 Week', 'Last 30 Days', 'Last 90 Days', 'Last Year', 'All Time', 'Custom Range', 'Test Time'],
      dialog: {
        visible: this.visible,
        activeTab: 'New Query',
        relation: 'and',
        queryType: 0,
        field: '',
        prop: '',
        invert: false,
        operator: '',
        condition: '',
        range: {
          start: '',
          end: '',
        },
        dateRange: [],
        list: [],
        description: '',
      },
      rules: {
        condition: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        field: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        dateRange: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }]
      },
      collectData: [],
      recordData: [],
      optionData: [],
      currentField: null,
      oldReportId: null,
      oldQueryType: null,
    }
  },
  computed: {
    // 规则选项
    'operators': function () {
      return this.$global.typeOperators[this.currentMeasure.type];
    },

    // 当前字段类型
    'currentMeasure': function () {
      let dataTypeId = '';
      if (!!this.currentField) dataTypeId = this.currentField.dataTypeId
      else return {};

      return this.measures.find(item => item.id === dataTypeId)
    },
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        if (this.oldReportId !== this.reportTemplateId) {
          this.getRecord(1);
          this.getRecord(0);
          this.getTemplateParam(this.data.data.queryType, this.initData);
        } else {
          if (this.oldQueryType !== this.data.data.queryType) {
            this.getTemplateParam(this.data.data.queryType, this.initData);
          } else {
            this.initData();
          }
        }
        this.dialog.visible = this.visible
        this.oldReportId = this.reportTemplateId;
        this.oldQueryType = this.data.data.queryType;
      }
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    GetFieldTypeAll().then(res => this.measures = res)
  },
  methods: {
    initData () {
      this.$refs.queryForm && this.$refs.queryForm.resetFields();
      this.dialog.list = [];
      if (!!this.data.data.activeTab) {
        this.$nextTick(() => {
          this.dialog = JSON.parse(JSON.stringify(this.data.data));
          // 当前字段
          this.currentField = this.fields[this.dialog.queryType].find(item => {
            return item.mainFieText === this.dialog.field
          })
          this.optionData = !!this.currentField.optionJson ? JSON.parse(this.currentField.optionJson) : [];
          this.operatorChange();
        })
      } else {
        this.dialog = Object.assign(this.dialog, JSON.parse(JSON.stringify(this.data.data)));
      }
    },
    getRecord (type) {
      let param = {
        templateId: this.reportTemplateId,
        type: type,
      }
      GetParamRecord(param).then(res => {
        let data = res.map(item => {
          item.name = JSON.parse(item.condition).label
          return item;
        });
        if (type === 1) {
          this.collectData = data.map(item => {
            item.condition = JSON.parse(item.condition)
            return item;
          });
        } else {
          this.$store.state.filterRequestHistory = JSON.parse(JSON.stringify(data));
          let result = {};
          let temp = [];
          data.forEach(item => {
            let condition = item.condition;
            if (!result[condition]) {
              item.condition = JSON.parse(item.condition)
              result[condition] = item;
            }
          })

          for (var i in result) {
            temp.push(result[i]);
          }
          this.recordData = temp
        }
      })
    },
    addRecord (type, condition) {
      let param = {
        templateId: this.reportTemplateId,
        condition: JSON.stringify(condition),
        type: type,
      }
      AddParamRecord(param).then(res => {
        if (type === 1) {
          this.getRecord(1);
          this.$message.success(this.$i18n.t('message.save'))
        } else {
          this.getRecord(0);
        }
      })
    },
    delRecord (data) {
      DelParamRecord(data.id).then(res => {
        if (res) {
          this.getRecord(data.type);
          this.$message.success(this.$i18n.t('message.del'));
        }
      })
    },

    // 获取模板参数
    getTemplateParam (type, fun) {
      let param = {
        templatesId: this.reportTemplateId,
        fieIdType: type,
      }
      GetTemplateParam(param).then(res => {
        if (type === 0 && this.reportTemplateId === '4cc26fab-6d91-4bd1-a962-dcbab3b27c52') {
          this.fields[type] = res.slice(0, 1);
        } else if (type === 0 && (this.reportTemplateId === '1c80f635-515d-4417-aaea-e5c8b6be233e' || this.reportTemplateId === 'b84486e0-3adc-4557-98c6-58bb826ace5a')) {
          this.fields[type] = res.slice(0, 3);
        } else {
          this.fields[type] = res.filter(item => !/.+\..+/.test(item.dbKey));
        }
        fun();
      })
    },
    showList () {
      this.ListDialogVisible = true;
    },

    // 提交筛选 
    submit (type) {
      this.$refs.queryForm.validate(valid => {
        if (!valid) return;
        let con;
        let condition;
        this.dialog.fieldType = this.currentMeasure.type;
        this.dialog.prop = this.dialog.queryType === 0 ? this.currentField.dbKey : this.dialog.field;
        switch (this.dialog.fieldType) {
          case 'date':
            con = this.dialog.condition === 12 ? `${this.dialog.dateRange[0]}~${this.dialog.dateRange[1]}` : this.dateCondition[this.dialog.condition]
            break;
          case 'number':
          case 'string':
            switch (this.dialog.operator) {
              case 6:
                con = this.dialog.list.join(',');
                break;
              case 7:
                data.value = `${item.range.start}~${item.range.end}`;
                break;
              default:
                con = this.dialog.condition
            }
            break;
          case 'stores':
            switch (this.dialog.operator) {
              case 2:
                con = this.dialog.list.join(',');
                break;
              default:
                con = this.dialog.condition
            }
            break;
          case 'option':
            con = this.dialog.condition
            break;
          case 'time':
            con = `${this.dialog.dateRange[0]} - ${this.dialog.dateRange[1]}`;
            break;
          case 'mins':
            con = this.dialog.operator !== 2 ? this.dialog.condition : `${this.dialog.range.start} - ${this.dialog.range.end}`;
            break;
        }
        condition = JSON.parse(JSON.stringify({
          prop: this.dialog.prop,
          label: this.dialog.field,
          con: con,
          data: this.dialog,
        }))

        if (type === 1) {
          // 提交
          this.$emit('submit', condition);
          this.dialog.visible = false;

          this.addRecord(0, condition);
        } else {
          // 收藏
          this.addRecord(1, condition);
        }
      })
    },
    listSubmit (data) {
      this.dialog.list = data;
      this.submit(1);
    },
    // 切换时间选项
    changeDataType (newVal) {
      let date = new Date();
      switch (newVal) {
        case 0:
          this.dialog.dateRange = this.$global.TheLastThirtyMinutes.split('~')
          // this.dialog.dateRange[0] = formatDate(new Date(date.getTime() - 30 * 60 * 1000));
          // this.dialog.dateRange[1] = formatDate(date);
          break;
        case 1:
          this.dialog.dateRange[0] = formatDate(new Date(date.getTime() - 60 * 60 * 1000));
          this.dialog.dateRange[1] = formatDate(date);
          break;
        case 2:
          this.dialog.dateRange[0] = formatDate(new Date(date.getTime() - 4 * 60 * 60 * 1000));
          this.dialog.dateRange[1] = formatDate(date);
          break;
        case 3:
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          break;
        case 4:
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          break;
        case 5:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 6:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 4);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 7:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 6);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 8:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 29);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 9:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 89);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 10:
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 364);
          this.dialog.dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
          break;
        case 11:
          this.dialog.dateRange[0] = formatDate(new Date('2020-10-1'), 'yyyy-MM-dd 00:00:00');
          this.dialog.dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
          break;
        case 13:
          this.dialog.dateRange[0] = '2020-10-22 00:00:00'
          this.dialog.dateRange[1] = '2020-10-22 23:59:59'
          break;
      }
    },
    // 改变字段 
    fieldChange (val) {
      // 当前字段
      this.currentField = this.fields[this.dialog.queryType].find(item => {
        return item.mainFieText === val
      })
      this.optionData = !!this.currentField.optionJson ? JSON.parse(this.currentField.optionJson) : [];
      this.dialog.description = this.currentField.descriptionMain;
      this.dialog.condition = '';

      if (this.dialog.queryType === 0) {
        this.dialog.operator = !!val ? 0 : '';
      } else {
        this.dialog.operator = !!this.currentField.defaultOperator ? Number(this.currentField.defaultOperator) : 0;
      }

      this.operatorChange();

    },
    // 
    showQueriest (item) {
      let condition = item.condition

      this.$emit('submit', condition);
      this.dialog.visible = false;
    },
    // 
    operatorChange () {
      if (this.currentMeasure.type === 'time') {
        switch (this.dialog.operator) {
          case 0:
            this.$set(this.dialog.dateRange, 0, '00:00')
            break;
          case 1:
            this.$set(this.dialog.dateRange, 1, '23:59')
            break;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.query-dialog {
  ::v-deep .el-tabs--border-card {
    box-shadow: none;
  }
  ::v-deep .el-tabs__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .el-select {
    width: 100%;
  }
  .query-form {
    padding: 0 15px;
  }
  .list-btn {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
  .history-wrap {
    height: 346px;
    overflow: auto;
  }
  .history-item {
    width: 100%;
    padding: 0 20px;
    line-height: 32px;
    font-size: 0;
    cursor: pointer;

    span {
      width: 30%;
      font-size: 14px;
      display: inline-block;
    }
    span:last-child {
      width: 10%;
      text-align: right;
    }
    i {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      padding: 2px;
      cursor: pointer;
      &:hover {
        background-color: #b4bccc;
      }
    }
    &:hover {
      background: #f7f7f7;
    }
  }
}

.range-box {
  width: 100%;
  display: flex;
  align-items: center;
  .range-line {
    width: 15px;
    border-bottom: 1px solid #000;
    margin: 0 5px;
  }
}
</style>