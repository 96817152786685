<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item
              :label="$t('newDataAccessGroup.label1')"
              prop="name"
            >
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newDataAccessGroup.label2')"
              prop="dataAccessGroup"
            >
              <el-select
                :disabled="!permit.editDataAccessGroup"
                v-model="groupForm.dataAccessGroup"
                filterable
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newDataAccessGroup.label3')"
              prop="descirption"
            >
              <el-input
                v-model="groupForm.descirption"
                type="textarea"
                class="describe"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row
          :gutter="150"
          class="table-row"
          ref="tableRow"
        >
          <el-col :span="12">
            <el-form-item :label="$t('newDataAccessGroup.label4')">
              <el-input
                placeholder="Search"
                v-model="groupForm.organicSearch"
              ></el-input>
              <el-table border                ref="organicTable"
                :data="organicData"
                :height="tableHeight"
                @selection-change="val => organicSelect = val"
                @row-click="rowClick1"
                class="select-table"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column
                  width="80"
                  type="index"
                  :label="$t('newDataAccessGroup.col1')"
                ></el-table-column>
                <el-table-column
                  :label="$t('newDataAccessGroup.col2')"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  :label="$t('newDataAccessGroup.col3')"
                  prop="description"
                ></el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('newDataAccessGroup.label5')">
              <el-input
                placeholder="Search"
                v-model="groupForm.selectedSearch"
              ></el-input>
              <el-table border                ref="selectedTable"
                :data="selectedData"
                :height="tableHeight"
                @selection-change="val => selected = val"
                @row-click="rowClick2"
                class="select-table"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column
                  width="80"
                  type="index"
                  :label="$t('newDataAccessGroup.col1')"
                ></el-table-column>
                <el-table-column
                  :label="$t('newDataAccessGroup.col2')"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  :label="$t('newDataAccessGroup.col3')"
                  prop="description"
                ></el-table-column>
              </el-table>
            </el-form-item>
            <div class="select-box">
              <el-button
                size="medium"
                class="select-btn"
                @click="addItem"
              >Add<i class="el-icon-arrow-right"></i></el-button>
              <el-button
                size="medium"
                class="select-btn"
                @click="removeItem"
              ><i class="el-icon-arrow-left"></i>Remove</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  AddDataAccessGroup,
  GetOrganizationAll,
  GetDataAccessGroupAll,
  GetDataAccessGroupItem,
} from '@/api/organizations'
export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        editDataAccessGroup: true,
        edit: true,
      },
      title: '',
      dataAccessGroups: [],
      organicData: [],
      selectedData: [],
      organicSelect: [],
      selected: [],
      groupForm: {
        loading: false,
        id: null,
        name: '',
        dataAccessGroup: localStorage.accessGroupId,
        descirption: '',
        organicSearch: '',
        selectedSearch: '',
        organicData: [],
        selectedData: [],
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Group Name.', trigger: 'blur' }]
      },
      tableHeight: null,

    }
  },
  watch: {
    'groupForm.organicSearch': function (newVal) {
      // 组织表格筛选
      this.organicData = this.groupForm.organicData.filter(item => item.name.toLowerCase().indexOf(newVal.toLowerCase()) !== -1)
    },
    'groupForm.selectedSearch': function (newVal) {
      // 选中表格筛选
      this.selectedData = this.groupForm.selectedData.filter(item => item.name.toLowerCase().indexOf(newVal.toLowerCase()) !== -1)
    },
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newDataAccessGroup.title2') : this.$i18n.t('newDataAccessGroup.title3');
      this.getItem();
    } else {
      this.title = this.$i18n.t('newDataAccessGroup.title1');
      // 获取组织
      GetOrganizationAll().then(res => {
        this.organicData = res;
        this.groupForm.organicData = res;
      })
    }
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight;
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      let param = {
        id: this.groupForm.id
      }
      GetDataAccessGroupItem(param).then(res => {
        this.groupForm.name = res.name;
        this.groupForm.dataAccessGroup = res.accessGroupId;
        this.groupForm.descirption = res.description;
        this.groupForm.organicData = res.allSysOrgZarions;
        this.groupForm.selectedData = res.distributionSysOrgZarions;
        this.organicData = JSON.parse(JSON.stringify(this.groupForm.organicData));
        this.selectedData = JSON.parse(JSON.stringify(this.groupForm.selectedData));
      })
    },
    // 添加选中组织
    addItem () {
      this.groupForm.selectedData = this.groupForm.selectedData.concat(this.organicSelect);
      this.groupForm.organicData = this.groupForm.organicData.filter(item1 => {
        return !this.organicSelect.some(item2 => item2.id === item1.id);
      })
      this.syncItem();
    },
    // 移除选中组织
    removeItem () {
      this.groupForm.organicData = this.groupForm.organicData.concat(this.selected);
      this.groupForm.selectedData = this.groupForm.selectedData.filter(item1 => {
        return !this.selected.some(item2 => item2.id === item1.id)
      })
      this.syncItem();
    },
    // 同步
    syncItem () {
      this.organicData = JSON.parse(JSON.stringify(this.groupForm.organicData));
      this.selectedData = JSON.parse(JSON.stringify(this.groupForm.selectedData));
      this.organicData = this.groupForm.organicData.filter(item => item.name.indexOf(this.groupForm.organicSearch) !== -1)
      this.selectedData = this.groupForm.selectedData.filter(item => item.name.indexOf(this.groupForm.selectedSearch) !== -1)
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.groupForm.name,
          description: this.groupForm.descirption,
          accessGroupId: this.groupForm.dataAccessGroup,
          listSysOrgZarions: this.groupForm.selectedData.map(item => item.id).join(','),
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddDataAccessGroup(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          } else {
            if (res.status === 100) this.$message.warning('Group name is occupied.')
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 行点击
    rowClick1 (row) {
      this.$refs.organicTable.toggleRowSelection(row)
    },
    rowClick2 (row) {
      this.$refs.selectedTable.toggleRowSelection(row)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.editDataAccessGroup = permission.includes('AccessGroupsEditDataAccessGroups(DataPermissions)');
        this.permit.edit = permission.includes('AccessGroupsEditDataAccessGroups');
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 332px);
}
.group-form {
  height: calc(100% - 56px);
  .el-select {
    width: 100%;
  }
  .describe {
    ::v-deep .el-textarea__inner {
      height: 132px;
    }
  }
  .select-table {
    margin-top: 20px;
    ::v-deep td,
    ::v-deep th {
      height: 35px;
      line-height: 35px;
      padding: 0;
    }
  }
  .select-box {
    width: 150px;
    position: absolute;
    top: 100px;
    left: calc(50% - 75px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .select-btn {
      width: 100px;
      margin: 10px 0;
    }
  }
}
</style>