<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('newProjectContract.addBranch.label1')"
          prop="nodeName"
        >
          <el-button-group class="input-group">
            <el-input
              class="search-input"
              v-model="dialog.nodeName"
              disabled
            ></el-input>
            <el-button @click="showSiteNode">{{$t("operation.search")}}</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item prop="orgId">
          <label slot="label">
            {{$t('newProjectContract.addBranch.label2')}}
            <span class="tips">
              {{$t('newProjectContract.tips3')}}
            </span>
          </label>
          <my-select
            :data="branchs"
            :value.sync="dialog.orgId"
            size="small"
            :prop="{
              label:'name',
              value:'id'
            }"
            @change="branchChange"
          ></my-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newProjectContract.addBranch.label3')"
              prop="orgTypeId"
            >
              <my-select
                :disabled="!isNewBranch"
                :data="branchTypes"
                :value.sync="dialog.orgTypeId"
                size="small"
                :prop="{
                  label:'name',
                  value:'id'
                }"
              ></my-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newProjectContract.addBranch.label4')"
              prop="completionDate"
            >
              <el-date-picker
                :disabled="!isNewBranch"
                v-model="dialog.completionDate"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          :label="$t('newProjectContract.addBranch.label5')"
          prop="maintenanceTypeId"
        >
          <el-select
            v-model="dialog.maintenanceTypeId"
            filterable
          >
            <el-option
              v-for="item in maintenanceTypes"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('newProjectContract.addBranch.label6')"
          prop="slaName"
        >
          <el-button-group class="input-group">
            <el-input
              disabled
              class="search-input"
              v-model="dialog.slaName"
            ></el-input>
            <el-button @click="showsla">{{$t("operation.search")}}</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item
          :label="$t('newProjectContract.addBranch.label7')"
          prop="slaType"
        >
          <el-input
            disabled
            v-model="dialog.slaTypeName"
          > </el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newProjectContract.addBranch.label8')"
              prop="maintenanceValidStartDate"
            >
              <el-date-picker
                v-model="dialog.maintenanceValidStartDate"
                type="date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newProjectContract.addBranch.label9')"
              prop="maintenanceValidEndDate"
            >
              <el-date-picker
                v-model="dialog.maintenanceValidEndDate"
                type="date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newProjectContract.addBranch.label10')"
              prop="contact"
            >
              <el-input v-model="dialog.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newProjectContract.addBranch.label11')"
              prop="telephoneNumber"
            >
              <el-input v-model="dialog.telephoneNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          :label="$t('newProjectContract.addBranch.label13')"
          prop="address"
        >
          <el-input v-model="dialog.address"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('newProjectContract.addBranch.label14')"
          prop="remark"
        >
          <el-input v-model="dialog.remark"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="sameName"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- sla 弹框 -->
    <sla-dialog
      :visible.sync="SlaDialogVisible"
      @submit="slaSubmit"
    ></sla-dialog>
    <!-- 节点弹框 -->
    <store-tree
      :visible.sync="StoreTreeVisible"
      :company="company"
      @submit="siteNodeSubmit"
    ></store-tree>
  </div>
</template>

<script>

// 组件
import MySelect from '@/components/MySelect'
import SlaDialog from './SlaDialog'
import StoreTree from './StoreTree'
// 接口
import {
  GetStoreList,
  GetStoreByName,
} from "@/api/stores";
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance';

export default {
  components: {
    MySelect,
    SlaDialog,
    StoreTree,
  },
  props: ["visible", "data", "maintenance", "company", 'customerName', 'slaPolicy', 'slaPolicyId', 'slaType', 'slaTypeName', 'tableData'],
  data () {
    return {
      SlaDialogVisible: false,
      StoreTreeVisible: false,
      branchs: [],
      branchTypes: [],
      maintenanceTypes: [],
      slaTypes: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        id: null,
        projectContractId: null,
        org: '',
        orgId: '',
        orgType: '',
        orgTypeId: '',
        maintenanceType: '',
        maintenanceTypeId: '',
        maintenanceValidStartDate: null,
        maintenanceValidEndDate: null,
        contact: '',
        telephoneNumber: '',
        address: '',
        remark: '',
        slaType: '',
        slaTypeName: '',
        slaName: '',
        policyId: '',
        nodeId: '',
        nodeName: '',
        completionDate: null,
      },
      rules: {
        orgId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        nodeName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    };
  },
  computed: {
    isNewBranch () {
      return !/.{8}(-.{4}){3}-.{12}/.test(this.dialog.orgId);
    },
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            this.dialog.title = this.$i18n.t("newProjectContract.title4");
            Object.assign(this.dialog, this.data);
            this.dialog.nodeName = `${this.customerName}/${this.data.nodeName}`;
            this.getStoreData();
          });
        } else {
          Object.assign(this.dialog, {
            title: this.$i18n.t("newProjectContract.title3"),
            id: null,
            slaType: this.slaType,
            slaTypeName: this.slaTypeName,
            slaName: this.slaPolicy,
            policyId: this.slaPolicyId,

          });
          if (this.maintenance) {
            this.dialog.maintenanceValidStartDate = this.maintenance[0];
            this.dialog.maintenanceValidEndDate = this.maintenance[1];
          }
          this.getStoreData();
        }
        // 获取维保类型
        if (this.maintenanceTypes.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000009' }).then(res => this.maintenanceTypes = res)
        // 获取网点类型
        if (this.branchTypes.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000019' }).then(res => this.branchTypes = res)

        // 获取SLA类型
        // if (this.slaTypes.length === 0)
        //   GetDataFieldAll({ parent: '100000000000000000000000000000000020' }).then(res => this.slaTypes = res)
      }
    },
    "dialog.visible": function (newVal) {
      if (!newVal) {
        this.$emit("update:visible", false);
      }
    },
  },

  created () { },
  methods: {
    sameName () {
      if (!/.{8}(-.{4}){3}-.{12}/.test(this.dialog.orgId)) {
        let param = {
          name: this.dialog.orgId
        }
        GetStoreByName(param).then(res => {
          if (res.length === 0) {
            this.submit();
          } else {
            this.$message.warning('site is occupied')
          }
        })
      } else {
        this.submit();
      }
    },
    // 提交
    submit () {
      if (!this.data && this.tableData.some(item => item.orgId === this.dialog.orgId))
        return this.$message.warning('site is occupied')

      this.$refs.dialogForm.validate((valid) => {
        if (!valid) return;

        if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.orgId)) {
          this.dialog.org = this.branchs.find(
            (item) => item.id === this.dialog.orgId
          ).name;
        } else {
          this.dialog.org = this.dialog.orgId;
        }

        if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.orgTypeId)) {
          this.dialog.orgType = this.branchTypes.find(
            (item) => item.id === this.dialog.orgTypeId
          ).name;
        } else {
          this.dialog.orgType = this.dialog.orgTypeId;
        }

        if (this.dialog.maintenanceTypeId) {
          this.dialog.maintenanceType = this.maintenanceTypes.find(
            (item) => item.id === this.dialog.maintenanceTypeId
          ).name;
        }

        this.$emit("submit", {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? "edit" : "add",
        });
        this.dialog.visible = false;
      });
    },
    getStoreData () {
      if (!this.dialog.nodeId) return this.branchs = [];
      let param = {
        groupId: this.dialog.nodeId,
        limit: 9999,
        page: 1,
        search: '',
      };
      GetStoreList(param).then((res) => {
        this.branchs = !!res ? res.list : [];
        // this.branchChange();
      });
    },
    branchChange () {
      if (this.isNewBranch) {
        this.dialog.orgType = '';
        this.dialog.orgTypeId = '';
        this.dialog.completionDate = null;
      } else {
        let item = this.branchs.find((item) => item.id === this.dialog.orgId);
        if (!item) return;
        this.dialog.orgType = item.typeName;
        this.dialog.orgTypeId = item.type;
        this.dialog.completionDate = item.completionDate;
      }
    },
    // 显示sla弹框
    showsla () {
      this.SlaDialogVisible = true;
    },
    // sla提交
    slaSubmit (data) {
      this.dialog.slaName = data.name;
      this.dialog.policyId = data.id;
      this.dialog.slaType = data.type;
      this.dialog.slaTypeName = data.typeName;
    },
    // 显示节点弹框
    showSiteNode () {
      this.StoreTreeVisible = true;
    },
    // 提交节点
    siteNodeSubmit (data) {
      this.dialog.orgId = '';
      this.dialog.orgTypeId = '';
      this.dialog.nodeId = data.id;
      this.dialog.nodeName = data.label;
      this.getStoreData();
      this.$refs.dialogForm.validateField('nodeName');

    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  color: #f56c6c;
  line-height: 36px;
  margin-left: 20px;
  font-size: 14px;
}
</style>