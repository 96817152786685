/**
 * 处理导航栏
 */
export function getMenu() {
  let menu = [{
    label: 'KPIs',
    name: 'KPIView',
    icon: 'icon-117',
    children: [{
      label: 'KpiDashboard',
      name: 'KPIDashboardView',
      path: '/home/kpiDashboard',
    }, {
      label: 'KpiTemplate',
      name: 'KPITemplateView',
      path: '/home/kpiTemplate',
    }]
  }, {
    label: 'CounterMode',
    name: 'DailyReportView',
    icon: 'icon-118',
    path: '/home/counterMode'
  }, {
    label: 'DiningMode',
    name: 'AdministrationReportView',
    icon: 'icon-119',
    path: '/home/diningMode'
  }, {
    label: 'UserDefined',
    name: 'UserDefinedView',
    icon: 'icon-120',
    path: '/home/userDefined'
  }, {
    label: 'SystemSettings',
    name: 'SystemSettingsView',
    icon: 'icon-121',
    children: [{
      label: 'UserManagement',
      name: 'UserView',
      path: '/home/userManagement',
    }, {
      label: 'Organizations',
      name: 'OrganizationsView,AccessGroupsView',
      path: '/home/organizations',
    }, {
      label: 'Roles',
      name: 'RolesView',
      path: '/home/roles',
    }, {
      label: 'StoreGroupEditor',
      name: 'SiteGroupEditorView',
      path: '/home/storeGroupEditor',
    }, {
      label: 'ReportGroupEditor',
      name: 'ReportGroupEditorView',
      path: '/home/reportGroupEditor',
    }, {
      label: 'Stores',
      name: 'StoresView',
      path: '/home/stores',
    }, {
      label: 'ContactList',
      name: 'ContactListView',
      path: '/home/contactList',
    }, {
      label: 'SMTP',
      name: 'SMTPView',
      path: '/home/SMTP',
    }, {
      label: 'AccountPolicies',
      name: 'AccountPoliciesView',
      path: '/home/accountPolicies',
    }]
  }, {
    label: 'ReportSettings',
    name: 'ReportSettingsView',
    icon: 'icon-122',
    children: [{
      label: 'ReportTemplates',
      name: 'ReportTemplatesView',
      path: '/home/reportTemplates',
    }, {
      label: 'ReportGroup',
      name: 'GroupView',
      path: '/home/reportGroup',
    }, {
      label: 'ReportDesigner',
      name: 'DesignerView',
      path: '/home/reportDesigner',
    }, {
      label: 'RiskOfLossLevel',
      name: 'LevelView',
      path: '/home/riskOfLossLevel',
    }]
  }, {
    label: 'SystemSchedule',
    name: 'SystemScheduleView',
    icon: 'icon-123',
    children: [{
      label: 'Schedule',
      name: 'ScheduleView',
      path: '/home/schedule',
    }]
  }, {
    label: 'Notifications',
    name: 'NotificationView',
    icon: 'icon-124',
    children: [{
      label: 'Notification',
      name: 'NotificationView',
      path: '/home/notification',
    }, {
      label: 'DailyDataSync',
      name: 'DailyDataSyncView',
      path: '/home/dailyDataSync',
    }, {
      label: 'RequestForApproval',
      name: 'RequestforApprovalView',
      path: '/home/requestForApproval',
    }]
  }, {
    label: 'SystemLog',
    name: 'SystemLogView',
    icon: 'icon-125',
    path: '/home/systemLog',
  }, {
    label: 'BgbOasSettings',
    name: 'BGBOASSettingsView',
    icon: 'icon-126',
    children: [{
      label: 'DataMaintenance',
      name: 'DataMaintenanceView',
      path: '/home/dataMaintenance',
    }, {
      label: 'ApprovalWorkflow',
      name: 'ApprovalWorkflowView',
      path: '/home/approvalWorkflow',
    }, {
      label: 'EngineerManagement',
      name: 'PersonnelManagementView',
      path: '/home/engineerManagement',
    }],
  }, {
    label: 'BgbProjectManagement',
    name: 'BGBProjectManagementView',
    icon: 'icon-127',
    children: [{
      label: 'ProjectContract',
      name: 'ProjectContractView',
      path: '/home/projectContract',
    }, {
      label: 'Projects',
      name: 'ProjectsView',
      path: '/home/projects',
    }, {
      label: 'StandardTask',
      name: 'StandardTaskView',
      path: '/home/standardTask',
    }, {
      label: 'ProjectDocuments',
      name: 'ProjectDocumentsView',
      path: '/home/projectDocuments',
    }, {
      label: 'SLAPolicies',
      name: 'SLAPolicies-SLAPoliciesView,SLAPolicies-EscalationLevelView',
      path: '/home/slaPolicies',
    }, {
      label: 'OperationalTime',
      name: 'OperationalTimeView',
      path: '/home/operationalTime',
    }]
  }, {
    label: 'BgbService',
    name: 'BGBServiceView',
    icon: 'icon-128',
    children: [{
      label: 'SubmitRequest',
      name: 'SubmitaRequestView',
      path: '/home/submitRequest',
    }, {
      label: 'Maintenance',
      name: '',
      path: '/home/maintenance',
    }, {
      label: 'ServiceConfirmationForm',
      name: 'ServiceConfirmationFormView',
      path: '/home/serviceConfirmationForm',
    }, {
      label: 'DeviceManagement',
      name: 'DeviceManagementView',
      path: '/home/deviceManagement',
    }, {
      label: 'WorkOrderScheduling',
      name: 'WorkOrderSchedulingView',
      path: '/home/workOrderScheduling',
    }, {
      label: 'ComplaintsEvaluation',
      name: 'ComplaintsandEvaluationView',
      path: '/home/complaintsEvaluation',
    }, ]
  }, {
    label: 'BgbIms',
    name: 'BGBIMSView',
    icon: 'icon-129',
    children: [{
      label: 'PurchaseRequisition',
      name: 'PurchaseRequisitionView-Allitems,PurchaseRequisitionView-Toonlyallowausertoviewhisownitems',
      path: '/home/purchaseRequisition',
    }, {
      label: 'PurchaseOrders',
      name: 'PurchaseOrdersView',
      path: '/home/purchaseOrders',
    }, {
      label: 'InventoryManagement',
      name: 'InventoryManagementView',
      path: '/home/inventoryManagement',
    }, {
      label: 'InventoryStatistics',
      name: 'InventoryStatisticsView',
      path: '/home/inventoryStatistics',
    }, {
      label: 'Quotation',
      name: 'QuotationView',
      path: '/home/quotation',
    }, {
      label: 'ProductInformation',
      name: 'ProductinformationView',
      path: '/home/productInformation',
    }]
  }, {
    label: 'AppReleaseManagement',
    name: 'AppReleaseManagementView',
    icon: 'icon-130',
    children: [{
      label: 'AppRelease',
      name: 'appReleaseView',
      path: '/home/appRelease',
    }]
  }];

  if (!localStorage.isSuper) {
    let permission = localStorage.permission.split(',');
    permission.push('xxx');
    menu.forEach(item => {
      item.hide = !permission.includes(item.name)

      if (item.children) {
        item.children.forEach(childItem => {
          if (/,/.test(childItem.name)) {
            let name = childItem.name.split(',');
            childItem.hide = !permission.includes(name[0]) && !permission.includes(name[1]);
          } else {
            childItem.hide = !permission.includes(childItem.name)
          }
        })
      }
    })
  }
  return menu;
}
