<template>

  <div class="home-wrap">
    <div
      class="menu-wrap"
      :class="{'active':drawer, 'pin':menuStatus == 2, 'coll':menuStatus == 3}"
      @mouseover="drawer = true"
      @mouseout="drawer = false"
    >
      <div class="logo-wrap menu-bg">
        <i
          v-if="menuStatus == 3"
          class="menu-icon icon-menu"
          :style="{
            'color':theme === 'grey' ? '#409EFF': '#ffffff'
          }"
        ></i>
        <div
          v-else
          class="logo-box"
        >
          <img
            v-if="!!logo"
            class="logo"
            :src="`${baseUrl}/Engineer/getImage.do?imageId=${logo}`"
          >
          <div
            v-else
            class="icon-box"
          >
            <i
              class="icon-logo"
              :style="{
              'color':theme === 'grey' ? '#1c2a48': '#ffffff'
            }"
            ></i>
            <i
              class="icon-menu"
              :style="{
                'color':theme === 'grey' ? '#409EFF': '#ffffff'
              }"
            ></i>
          </div>
        </div>
      </div>
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo menu-bg"
        :collapse="menuStatus ==  3"
        unique-opened
        router
      >
        <template
          v-for="(item,index) in menuData"
          :tag="item.children !== undefined?'el-submenu':'el-menu-item'"
        >
          <el-submenu
            v-if="item.children !== undefined && !item.hide"
            :index="index.toString()"
            :key="index"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span class="title">{{$t('menu.' + item.label)}}</span>
            </template>
            <template v-for="(childItem,childIndex) in item.children">
              <el-menu-item
                v-if="!childItem.hide"
                :key="childIndex"
                :index="childItem.path"
                :title="$t('menu.' + childItem.label)"
              >
                <i class="el-icon-arrow-right"></i>
                <span>{{$t('menu.' + childItem.label)}}</span>
              </el-menu-item>
            </template>

          </el-submenu>
          <el-menu-item
            v-else-if="!item.hide"
            :key="index"
            :index="item.path"
          >
            <i :class="item.icon"></i>
            <span
              class="title"
              slot="title"
            >{{$t('menu.' + item.label)}}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>

    <el-container :class="{'pin':menuStatus == 2, 'coll':menuStatus == 3}">
      <el-main>
        <div class="tab-wrap tab-bg">
          <div
            v-if="menuStatus == 1"
            class="logo"
            @mouseover="drawer = true"
            @mouseout="drawer = false"
          >
            <i
              class="icon-menu"
              :style="{
                'color':theme === 'grey' ? '#409EFF': '#ffffff'
              }"
            ></i>
          </div>
          <div
            class="tab-box"
            ref="tabBox"
            @mouseenter="isTabover = true"
            @mouseleave="mouserTabout"
          >
            <div
              v-for="(item, index) in tabList"
              :key="index"
              class="tab-item tab-item-bg"
              :class="{'active':item.active}"
              :title="$t('menu.' + item.name)"
              :style="{'width':item.width}"
              @mouseup.prevent="tabClick(item.path, index)"
              @contextmenu.prevent
            >
              <span class="label">{{$t('menu.' + item.name)}}</span>
              <i
                v-if="item.name !== 'DashBoard'"
                class="el-icon-close"
                @click.stop="tabClose(index)"
                @mouseup.stop
              ></i>
            </div>
          </div>

          <div class="right-box">
            <div
              class="head-btn"
              v-if="permit.dashBoard"
              @click="tabClick('/home/dashBoard')"
            >
              <i class="el-icon-s-home"></i>
            </div>
            <div
              class="head-btn"
              v-if="permit.kpiDashboard"
              @click="tabClick('/home/kpiDashboard')"
              style="font-size:16px"
            >
              <i class="icon-117"></i>
            </div>
            <el-dropdown
              trigger="click"
              @command="langChange"
            >
              <span class="el-dropdown-link">
                <div class=" head-btn">
                  <i class="icon-global"></i>
                  <!-- 
                  <img
                    class="icon"
                    src="/static/image/globe-icon.png"
                  > -->
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="cn"
                  :class="{'active':$i18n.locale == 'cn'}"
                >简体中文</el-dropdown-item>
                <el-dropdown-item
                  command="tw"
                  :class="{'active':$i18n.locale == 'tw'}"
                >繁體中文</el-dropdown-item>
                <el-dropdown-item
                  command="en"
                  :class="{'active':$i18n.locale == 'en'}"
                >English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              trigger="click"
              @command="menuChange"
              :tabindex="1"
            >
              <span class="el-dropdown-link">
                <div class="head-btn">
                  <i class="icon-layout"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="1"
                  :class="{'active':this.menuStatus == 1}"
                >{{$t('home.layer.btn1')}}</el-dropdown-item>
                <el-dropdown-item
                  command="2"
                  :class="{'active':this.menuStatus == 2}"
                >{{$t('home.layer.btn2')}}</el-dropdown-item>
                <el-dropdown-item
                  command="3"
                  :class="{'active':this.menuStatus == 3}"
                >{{$t('home.layer.btn3')}}</el-dropdown-item>
                <el-dropdown-item command="4">
                  <ul
                    class="theme-box"
                    @click.stop="themeChange"
                  >
                    <li class="black"></li>
                    <li class="blue"></li>
                    <li class="orange"></li>
                    <li class="green"></li>
                    <li class="grey"></li>
                  </ul>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div
              v-if="permit.notification"
              class="head-btn badge"
              @click="tabClick('/home/notification')"
            >
              <i class="el-icon-bell"></i>
            </div>
            <el-dropdown
              trigger="click"
              @command="userDropdown"
            >
              <span class="el-dropdown-link">
                <div
                  class="head-btn"
                  :title="userName"
                >
                  <div class="user-name">{{userName.substr(0,2)}}</div>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="ChangePassword"
                  v-if="permit.changePassword"
                >{{$t('home.menu.btn1')}}</el-dropdown-item>
                <el-dropdown-item
                  command="MyProfile"
                  v-if="permit.myProfile"
                >{{$t('home.menu.btn2')}}</el-dropdown-item>
                <el-dropdown-item command="Help">{{$t('home.menu.btn3')}}</el-dropdown-item>
                <el-dropdown-item command="SignOut">{{$t('home.menu.btn4')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </div>
        <div class="router-wrap">
          <div
            class="suspend"
            @mouseover="drawer = true"
          ></div>
          <keep-alive :include="cacheList">
            <router-view></router-view>
          </keep-alive>
        </div>
      </el-main>

    </el-container>

    <!-- 修改密码弹框 -->
    <password-dialog :visible.sync="PasswordDialogVisible"></password-dialog>
    <!-- 个人资料弹框 -->
    <user-info :visible.sync="UserInfoVisible"></user-info>
    <!-- 帮助弹框 -->
    <help-dialog :visible.sync="HelpDialogVisible"></help-dialog>

    <!-- tab-dropdown -->
    <div
      class="tab-dropdown"
      ref="tabDropdown"
      v-show="dropdownVisble"
      @click.stop
    >
      <div
        class="item"
        @click="tabDropdown(1)"
      >{{$t('home.menu.btn5')}}</div>
      <div
        class="item"
        @click="tabDropdown(2)"
      >{{$t('home.menu.btn6')}}</div>
    </div>
  </div>
</template>

<script>
// 全局方法
import { logout } from '@/utils/common'
import { getMenu } from '@/utils/permission'
// 组件
import PasswordDialog from './components/PasswordDialog'
import UserInfo from './components/UserInfo'
import HelpDialog from './components/HelpDialog'
export default {
  name: 'Home',
  components: {
    PasswordDialog,
    UserInfo,
    HelpDialog,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      logo: this.$cookies.get('logo'),
      theme: this.$cookies.get('theme') || 'grey',
      permit: {
        dashBoard: true,
        kpiDashboard: true,
        notification: true,
        changePassword: true,
        myProfile: true,
      },
      menuStatus: localStorage.menuStatus || 1,
      drawer: false,
      dropdownVisble: false,
      PasswordDialogVisible: false,
      UserInfoVisible: false,
      HelpDialogVisible: false,
      menuData: null,
      userName: this.$cookies.get('userAccount') || '',
      tabList: [],
      isTabover: false,
      cacheList: [],
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.meta.isKeepAlive) this.cacheList.push(to.name);
      if (to.meta.isChildren || to.fullPath === "/home") return;

      let current = this.tabList.filter(item => item.name === to.name);
      this.tabList.forEach(item => item.active = false);
      if (current.length == 0) {
        this.tabList.push({ name: to.name, path: to.path, active: true, width: '0px' })
      } else {
        current[0].path = to.path;
        current[0].active = true;
      }
    },
    tabList: {
      handler: function () {
        this.cacheList = this.tabList.map(item => item.name);
        sessionStorage.setItem('tabList', JSON.stringify(this.tabList));
        this.$nextTick(() => {
          if (this.isTabover) return;
          this.tabItemChange()
        })
      },
      deep: true
    }
  },
  created () {
    // 主题
    document.body.className = this.theme;
    // 权限
    this.dealPermission();
    // 获取缓存tab
    let tab = JSON.parse(sessionStorage.getItem('tabList'));
    if (!!tab) this.tabList = tab;
    // 获取菜单
    this.menuData = getMenu();

    document.body.addEventListener('click', this.clearDropdown)
    // this.switchLang();
  },
  methods: {
    tabClick (path, index) {
      switch (event.button) {
        case 0: // 左键
          if (path === this.$route.path) return;
          this.$router.push({ path: path })
          break;
        case 1: // 滑轮
          this.tabClose(index);
          break;
        case 2: // 右键
          this.rightClick(event);
          break;
      }
    },
    // 关闭导航标签
    tabClose (index) {
      if (index == 0) return;

      if (this.tabList[index].active) this.$router.push({ path: '/home' });

      this.tabList.splice(index, 1)
    },
    // 用户下拉框
    userDropdown (command) {
      switch (command) {
        case 'ChangePassword':
          this.PasswordDialogVisible = true;
          break;
        case 'MyProfile':
          this.UserInfoVisible = true;
          break;
        case 'Help':
          this.HelpDialogVisible = true;
          break;
        case 'SignOut':
          logout();
          this.$router.push({ path: '/login' })
          break;
      }
    },
    // tab下拉框
    tabDropdown (command) {
      switch (command) {
        case 1:
          if (this.permit.dashBoard) {
            if (this.tabList.length <= 1) return;
            let item = this.tabList.filter(item => item.active)[0]
            if (item.name === 'DashBoard') {
              this.tabList = [{ name: 'DashBoard', path: '/home/dashBoard', active: true, width: '160px' }];
            } else {
              this.tabList = [{ name: 'DashBoard', path: '/home/dashBoard', active: false, width: '160px' }, item];
            }
          } else {
            if (this.tabList.length == 0) return;
            let item = this.tabList.filter(item => item.active)[0]
            this.tabList = [item];
          }
          break;
        case 2:
          this.tabList = this.permit.dashBoard
            ? [{ name: 'DashBoard', path: '/home/dashBoard', active: true, width: '160px' }]
            : [];
          this.$router.push({ path: '/' });
          break;
      }
      this.dropdownVisble = false;
    },
    // 切换语言
    langChange (e) {
      localStorage.setItem('lang', e);
      this.$i18n.locale = e;
    },
    // 切换菜单栏
    menuChange (e) {
      if (e == 4) return;
      localStorage.menuStatus = e;
      this.menuStatus = e;
    },
    // 切换主题 
    themeChange (e) {
      let target = e.target;
      if (target.nodeName !== 'LI') return;

      this.theme = e.target.className;
      document.body.className = this.theme;
    },
    // 鼠标移出tab
    mouserTabout () {
      this.isTabover = false
      this.tabItemChange()
    },
    // 鼠标右键
    rightClick (e) {
      this.$refs.tabDropdown.style.left = e.pageX + 'px';
      this.$refs.tabDropdown.style.top = e.pageY + 'px';
      this.dropdownVisble = true;
    },
    // 计算tab-item长度
    tabItemChange () {
      let _width = Math.floor(this.$refs.tabBox.clientWidth * 100) / 100;

      if (_width / this.tabList.length > 200) {
        _width = '200px'
      } else if (_width / this.tabList.length < 30) {
        _width = '30px'
      } else {
        _width = Math.floor(_width / this.tabList.length * 100) / 100 + 'px';
      }
      this.tabList.forEach(item => item.width = _width);
    },
    clearDropdown () {
      this.dropdownVisble = false;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.dashBoard = permission.includes('OverviewView');
        this.permit.kpiDashboard = permission.includes('KPIView') && permission.includes('KPIDashboardTemplatesView');
        this.permit.notification = permission.includes('NotificationView');
        this.permit.changePassword = permission.includes('UserInfoView') && permission.includes('UserInfoView');
        this.permit.myProfile = permission.includes('UserInfoView') && permission.includes('UserInfoView');
      }
      if (this.permit.dashBoard) this.tabList = [{ name: 'DashBoard', path: '/home/dashBoard', active: true, width: '160px' }];
    },
    switchLang () {
      document.body.addEventListener('keydown', (event) => {
        if (event.keyCode === 112) {
          event.preventDefault();
          let lang = localStorage.lang === 'en' ? 'cn' : 'en';
          localStorage.setItem('lang', lang);
          this.$i18n.locale = lang;
        }
      })
    },
  }
}
</script>


<style lang="scss" scoped>
.home-wrap {
  height: 100%;
  background: #f2f7fb;
}
.el-container {
  height: 100%;
  overflow: auto;
  &.pin {
    margin-left: 220px;
    width: calc(100% - 220px);
  }
  &.coll {
    margin-left: 64px;
    width: calc(100% - 64px);
  }
}
.menu-wrap {
  width: 220px;
  height: 100%;
  // background: #1d2939;
  position: fixed;
  left: -220px;
  z-index: 2001;
  transition: left 0.3s;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  &.active {
    left: 0;
  }
  &.pin {
    left: 0;
  }
  &.coll {
    width: 64px;
    left: 0;
  }
  ul {
    height: calc(100% - 80px);
    border: none;
    overflow-y: auto;
    ::v-deep .el-submenu__title,
    .el-menu-item {
      font-size: 13px;
    }
  }
  .logo-wrap {
    // width: 220px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.3s ease-in-out;
    .menu-icon {
      font-size: 26px;
    }
    .logo {
      max-width: 220px;
    }

    .logo-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon-box {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      font-size: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-logo {
        color: #1c2a48;
        font-size: 42px;
      }
    }
  }
}

.coll .tab-box,
.pin .tab-box {
  width: calc(100% - 240px) !important;
}

.el-main {
  width: 100%;
  height: 100%;
  padding: 0;
  .tab-wrap {
    height: 50px;
    line-height: 50px;
    display: flex;

    .logo {
      width: 50px;
      // background: #1d2939;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      color: #fc1d1d;
    }
    .tab-box {
      width: calc(100% - 290px);
      // width: calc(100% - 240px);
      // width: calc(100% - 266px);
      height: 100%;

      display: flex;
      align-items: flex-end;

      .tab-item {
        height: 100%;
        padding-left: 8px;
        font-size: 14px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        transition: width 0.2s;
        // &:last-child {
        //   border-right: none;
        // }
        .label {
          width: calc(100% - 22px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          text-align: center;
        }

        &.active {
          box-shadow: none;
        }
        &:hover {
          i {
            display: inline-block;
          }
        }
        i {
          display: none;
          position: absolute;
          right: 8px;
          top: calc(50% - 7px);
          font-size: 14px;
          border-radius: 50%;
          &:hover {
            background-color: #b4bccc;
            color: #304156;
          }
        }
      }
    }

    .right-box {
      width: 240px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .user-name {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #000;
        background: #eee;
        overflow: hidden;
        box-sizing: border-box;
      }
      .el-dropdown {
        height: 100%;
      }
      .head-btn {
        width: 40px;
        height: 100%;
        cursor: pointer;
        font-size: 18px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: #f0f2f5;
          color: rgb(83, 83, 83);
          .user-name {
            color: #fff;
            background: #000;
          }
        }
        &.badge {
          &::before {
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 50%;
            border: 1px solid #fff;
            background-color: #f56c6c;
            display: block;
            position: absolute;
            right: 5px;
            top: 3px;
          }
        }
      }
    }
  }
}

.router-wrap {
  height: calc(100% - 50px);
  background: #f2f7fb;
  overflow: auto;
}

// 侧边栏
.el-menu {
  height: calc(100% - 50px);
  //第一层菜单
  .el-menu-item,
  ::v-deep .el-submenu__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    i {
      color: inherit;
    }
    &.is-active {
      color: #00bfa5;
    }
    &:focus {
      background: none;
    }
    &:hover {
      color: #fff;
    }
  }
  // 子菜单
  .el-submenu {
    .el-menu-item {
      padding-left: 30px !important;
      padding-right: 20px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      i {
        width: 15px;
        color: inherit;
        font-size: 14px;
        font-weight: bold;
      }
      &.is-active {
        color: #00bfa5;
      }

      &:hover {
        color: #fff;
      }
    }
  }
  .el-submenu [class^="el-icon-"] {
    text-align: left;
  }
  [class*="icon-"] {
    width: 25px;
    font-size: 14px;
    margin-right: 5px;
    display: inline-block;
  }
  .title {
    // font-size: 14px;
  }
}
.tab-dropdown {
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  .item {
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }
}
.suspend {
  width: 2px;
  height: 100%;
  opacity: 0;
  position: fixed;
  z-index: 99;
}

.theme-box {
  height: 36px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  li {
    width: 20px;
    height: 20px;
    list-style: none;
  }
}
.black {
  background-color: #1d2939;
}
.blue {
  background-color: #2b579a;
}
.orange {
  background-color: #b7472a;
}
.green {
  background-color: #217346;
}
.grey {
  background-color: #e6e5e5;
}

.el-dropdown-menu {
  width: 170px;
  .active {
    background-color: #ecf5ff;
    color: #66b1ff;
  }
}
</style>
