<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="userForm"
        size="small"
        class="user-form"
      >
        <el-form-item
          :label="$t('projectDocuments.label2')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('projectDocuments.label3')"
          prop="format"
        >
          <my-select
            :data="formats"
            :value.sync="dialog.format"
            size="small"
            :prop="{
              label:'item',
              value:'item'
            }"
          ></my-select>
          <!-- <el-select
            v-model="dialog.format"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item,index) in formats"
              :key="index"
              :value="item"
              :label="item"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-button
          class="upload-btn"
          @click="upload"
          size="small"
        >{{uploadName}}</el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 上传文件 -->
    <input
      type="file"
      hidden
      ref="fileInput"
      @change="uploadFile"
    >
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'

// 接口
import { AddDocument } from '@/api/warehouse/projectDocuments'
import { UploadImage } from '@/api'
export default {
  components: {
    MySelect,
  },
  props: ['visible', 'data', 'parentData'],
  data () {
    return {
      formats: ['avi', 'doc', 'docx', 'dwg', 'jpg', 'mp4', 'mpp', 'pdf', 'png', 'ppt', 'pptx', 'txt', 'vsd', 'vsdx', 'wma', 'xls', 'xlsx', 'xml'],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
        format: '',
        paths: '',
      },
      rules: {
        // user: [{ required: true, message: 'Please enter an User Account.', trigger: 'blur' }],
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('projectDocuments.title4'),
              name: this.data.name,
              format: this.data.formats,
              paths: this.data.paths,
            })
          })
        } else {
          this.dialog.title = this.$i18n.t('projectDocuments.title3')
          this.dialog.paths = '';
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  computed: {
    uploadName () {
      if (!this.dialog.paths) return this.$i18n.t('projectDocuments.btn1');

      return `${this.dialog.name}.${this.dialog.format}`;
    }
  },
  created () {
  },
  methods: {
    // 显示上传
    upload () {
      this.$refs.fileInput.click();
    },
    // 上传文件
    uploadFile () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let fileProperty = file.name.match(/^(.*)\.([^.]+)$/);
          this.dialog.name = fileProperty[1];
          this.dialog.format = fileProperty[2];
          this.dialog.uploadName = file.name;
          this.dialog.paths = res.message;
        }
      })
    },
    // 添加或修改用户
    submit () {
      this.$refs.userForm.validate(valid => {
        if (!valid) return;

        let param = {
          taskId: this.parentData.taskId,
          parentId: this.parentData.id,
          name: this.dialog.name,
          paths: this.dialog.paths,
          formats: this.dialog.format,
          type: 1,
        }
        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddDocument(param).then(res => {
          if (res) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        })
      })
    },



  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.upload-btn {
  width: 100%;
}
</style>