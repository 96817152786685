<template>
  <div>
    <div
      v-for="(item,index) in data"
      :key="index"
      class="progress-item"
    >
      <div class="progress">
        <template v-if="!!item.startDate">
          <div
            v-if="item.milestone == 1"
            class="milestone"
            :style="{
                  'margin-left':parseInt(item.left) * itemWidth + 'px',
                }"
          ></div>
          <div
            v-else-if="item.childrens.length != 0"
            class="bar1"
            :style="{
              'margin-left':parseInt(item.left) * itemWidth + 'px',
              'width':parseInt(item.duration) * itemWidth + 'px'
            }"
            @click="open(item)"
          ></div>
          <div
            v-else
            class="bar2"
            :style="{
              'margin-left':parseInt(item.left) * itemWidth + 'px',
              'width':parseInt(item.duration) * itemWidth + 'px'
            }"
            @click="open(item)"
          >
            <div
              class="con"
              :style="{
                'width':item.percents +'%'
              }"
            >
              {{item.percents}}%
            </div>
          </div>
        </template>

        <div
          v-if="!!item.baseStartDate && isBase"
          class="bar3"
          :style="{
                'margin-left':parseInt(item.baseLeft) * itemWidth + 'px',
                'width':parseInt(item.baseDuration) * itemWidth + 'px'
              }"
        ></div>
      </div>
      <div
        v-show="!item.collapse"
        class="children-box"
      >
        <progress-tree
          :data="item.childrens"
          :is-base="isBase"
          :item-width="itemWidth"
        ></progress-tree>
      </div>
    </div>
  </div>
</template>

<script>
// 接口
import {
  AddTask
} from '@/api/warehouse/projects'
export default {
  name: 'ProgressTree',
  props: ['data', 'isBase', 'itemWidth'],
  data () {
    return {
    }
  },
  watch: {

  },
  created () {

  },
  methods: {
    open (item) {
      this.$prompt('', '任务进度', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(\d|[1-9]\d|100)$/,
        inputErrorMessage: '只能输入0到100'
      }).then(({ value }) => {
        let param = {
          id: item.id,
          parentId: item.parentId,
          notes: item.notes,
          projectId: item.projectId,
          name: item.name,
          startDate: item.startDate,
          endDate: item.endDate,
          percents: Number(value),
          predecessorId: item.predecessorId,
          milestone: item.milestone,
          baseStartDate: item.baseStartDate,
          baseEndDate: item.baseEndDate,
        }
        AddTask(param).then(res => {
          if (res) item.percents = value;
        })
      }).catch(() => { });
    }
  },
}
</script>

<style lang="scss" scoped>
.progress {
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .milestone {
    height: 12px;
    width: 12px;
    background: #9932cc;
    transform: rotate(45deg);
  }
  .bar1 {
    height: 0px;
    border: 10px solid #49b1f3;
    border-bottom: 10px solid transparent;
    cursor: pointer;
  }
  .bar2 {
    height: 20px;
    line-height: 20px;
    color: #fff;
    text-align: right;
    background: #a4d246;
    cursor: pointer;
    .con {
      height: 100%;
      background: #63ae76;
    }
  }
  .bar3 {
    height: 10px;
    margin-top: 2px;
    background: #999999;
  }
}
</style>