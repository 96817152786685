import request from '@/utils/request'

// 查询工程师列表
export const GetEngineerList = (data) => request({
  method: 'post',
  url: '/Engineer/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改工程师
export const AddEngineer = (data) => request({
  method: 'post',
  url: '/Engineer/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除工程师
export const DelEngineer = (data) => request({
  method: 'post',
  url: '/Engineer/delete.do',
  data: JSON.stringify(data)
})

// 获取人员状态列表
export const GetEngineerStateList = (data) => request({
  method: 'post',
  url: '/Engineer/queryByForm.do',
  data: JSON.stringify(data)
})

// 获取工程师详情
export const GetEngineerStateItem = (data) => request({
  method: 'post',
  url: '/Engineer/queryById.do',
  data: JSON.stringify(data)
})

// 获取钉钉列表
export const GetDingtalkList = (data) => request({
  method: 'post',
  url: '/SysDingtalkUser/queryByPage.do',
  data: JSON.stringify(data)
})

// 获取工程师集合
export const GetEngineerAll = (data) => request({
  method: 'post',
  url: '/Engineer/queryByList.do',
  data: JSON.stringify(data)
})

// 更新
export const UpdateDingtalk = (data) => request({
  method: 'post',
  url: '/SysDingtalkUser/add.do',
  data: JSON.stringify(data)
})