<template>
  <div class="wrap">
    <el-card>
      <el-tabs v-model="tabActive">
        <el-tab-pane
          v-if="permit.slaTab"
          :label="$t('slaPolicies.tab1')"
          name="SLAPolicies"
        >
          <sla-policies :active="tabActive"></sla-policies>
        </el-tab-pane>
        <el-tab-pane
          v-if="permit.levelTab"
          :label="$t('slaPolicies.tab2')"
          name="EscalationLevel"
        >
          <escalation-level :active="tabActive"></escalation-level>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import SlaPolicies from './components/SLAPolicies'
import EscalationLevel from './components/EscalationLevel'

export default {
  name: 'SLAPolicies',
  components: {
    SlaPolicies,
    EscalationLevel,
  },
  data () {
    return {
      permit: {
        slaTab: true,
        levelTab: true,
      },
      tabActive: 'SLAPolicies',
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'SLAPolicies') { }
    },
  },
  created () {
    this.dealPermission();

    if (this.permit.slaTab) {
      this.tabActive = 'SLAPolicies';
    } else {
      this.tabActive = 'EscalationLevel';
    }
  },
  methods: {
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.slaTab = permission.includes('SLAPolicies-SLAPoliciesView');
        this.permit.levelTab = permission.includes('SLAPolicies-EscalationLevelView');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>
