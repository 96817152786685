<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
        label-width="120px"
      >

        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dialog.remark"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="attachment"
          label="上传文件"
        >
          <div class="file-box">
            <div
              v-for="(item,index) in dialog.attachment"
              :key="index"
              class="file-item"
            >
              <div
                class="block"
                @click="previewFile(item)"
              >
                <img :src="'./static/image/fileType/' + item.formats + '.png'">
                <i
                  class="el-icon-delete"
                  @click.stop="delFile(index)"
                ></i>
              </div>
            </div>
            <div
              class="file-item add-item"
              @click="showUpload"
            >
              <div class="block">
                <i class="el-icon-plus"></i>
              </div>
              <label></label>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t("operation.cancel")}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t("operation.confirm")}}</el-button>
      </div>
    </el-dialog>
    <!-- 图片上传 -->
    <input
      type="file"
      ref="fileInput"
      hidden
      @change="fileInputChange"
    >
  </div>
</template>

<script>
// 接口
import { UploadImage } from '@/api'
import {
  AddBatchReceiptRecord,
} from "@/api/warehouse/purchaseRequisition";
export default {
  props: ['visible', 'data'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      dialog: {
        visible: false,
        title: '签收',
        remark: '',
        attachment: [],
      },
      rules: {
      },
    };
  },

  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
      }
    },
    "dialog.visible": function (newVal) {
      if (!newVal) {
        this.$emit("update:visible", false);
      }
    },
  },
  created () {
  },
  methods: {
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = this.data.map(item => {
          return {
            infoId: item.id,
            count: item.count - item.receiptCount,
            attachment: this.dialog.attachment.map(item => item.name).join(','),
            remark: this.dialog.remark,
          }
        })
        if (this.type === 1) {
          param.id = this.dialog.id;
          param.receiptDate = this.dialog.receiptDate;
          param.receiptBy = this.dialog.receiptBy;
        }
        AddBatchReceiptRecord(param).then(res => {
          if (res.success) {
            this.$emit('update');
            this.$message.success(this.$i18n.t("message.receipt"));
            this.dialog.visible = false;
          }
        })
      });
    },
    // 删除文件
    delFile (index) {
      this.dialog.attachment.splice(index, 1)
    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 显示上传
    showUpload () {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    fileInputChange () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let formats = res.message.match(/[^.]*$/)[0];
          this.dialog.attachment.push({
            name: res.message,
            formats: formats,
          });
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }

  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-input-number {
    width: 100%;
    ::v-deep .el-input__inner {
      text-align: left;
    }
  }
}

.file-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 120px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: static;
    }
  }
}

.el-table {
  .file-item {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .block {
    width: 50px;
    height: 50px;
    img {
      width: 40px;
    }
  }
}
</style>
