<template>
  <div
    class="wrap"
    ref="myChart"
  >
  </div>
</template>

<script>
export default {
  props: ['data', 'dataOrigin'],
  data () {
    return {
      myChart: null,
      colors: [
        [],
        ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
        ['#dd6b66', '#759aa0', '#e69d87', '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab', '#91ca8c', '#f49f42'],
        ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa', '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050', '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'],
        ['#C1232B', '#27727B', '#FCCE10', '#E87C25', '#B5C334', '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD', '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0'],
        ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8', '#cda819', '#32a487'],
        ['#E01F54', '#001852', '#f5e8c8', '#b8d2c7', '#c6b38e', '#a4d8c2', '#f3d999', '#d3758f', '#dcc392', '#2e4783', '#82b6e9', '#ff6347', '#a092f1', '#0a915d', '#eaf889', '#6699FF', '#ff6666', '#3cb371', '#d5b158', '#38b6b6'],
      ],
      seriesTypes: ['bar', 'pie', 'line'],

      axisData: [],
      sortingData: [],
      chartConfig: {},

    }
  },
  methods: {
    init () {
      let chartData = this.data.data
      if (!chartData) return;

      this.seriesType = chartData.chartType;
      this.axisData = JSON.parse(chartData.dataSource);
      this.sortingData = JSON.parse(chartData.sortingJson);
      this.chartConfig = JSON.parse(chartData.chartElements);

      if (this.axisData.length === 0) return;
      let isColumn = false;
      let isRow = false;
      let rowData;
      let columnData = [];

      this.axisData.forEach(item => {
        if (item.type === 0) {
          columnData.push(item);
          return isColumn = true;
        }
        if (item.type === 1) {
          rowData = item;
          return isRow = true;
        }
      })

      if (!isColumn || !isRow) return;

      let xAxisData = [];
      let tempData = {};
      let sortingItem = this.sortingData.find(item => item.status);

      this.dataOrigin.data.data.forEach(item => {
        if (!tempData[this.getCellVal(item, rowData.key)]) {
          tempData[this.getCellVal(item, rowData.key)] = {};
          for (let i in columnData) {
            tempData[this.getCellVal(item, rowData.key)][columnData[i].key] = parseFloat(this.getCellVal(item, columnData[i].key))
          }
          if (!!sortingItem && !columnData.some(item => sortingItem.key === item.key))
            tempData[this.getCellVal(item, rowData.key)][sortingItem.key] = this.getCellVal(item, sortingItem.key)
        } else {
          for (let i in columnData) {
            tempData[this.getCellVal(item, rowData.key)][columnData[i].key] += parseFloat(this.getCellVal(item, columnData[i].key))
          }
        }
      })
      let tempData2 = [];
      for (let i in tempData) {
        tempData2.push(Object.assign({ key: i }, tempData[i]))
      }
      tempData = JSON.parse(JSON.stringify(tempData2))
      if (!!sortingItem) {
        switch (sortingItem.type) {
          case 'date':
            if (sortingItem.order === 0) {
              tempData.sort((a, b) => new Date(a[sortingItem.key]).getTime() - new Date(b[sortingItem.key]).getTime())
            } else {
              tempData.sort((a, b) => new Date(b[sortingItem.key]).getTime() - new Date(a[sortingItem.key]).getTime())
            }
            break;
          default:
            if (sortingItem.order === 0) {
              tempData.sort((a, b) => a[sortingItem.key] - b[sortingItem.key])
            } else {
              tempData.sort((a, b) => b[sortingItem.key] - a[sortingItem.key])
            }
        }
      }

      let option = {
        tooltip: {
          show: this.chartConfig.tooltip.status,
          confine: true,
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          show: this.chartConfig.legend.status,
          type: 'scroll',
          data: [],
        },
        xAxis: {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            interval: 0,
          },
        },
        dataZoom: [{
          type: 'slider',
          show: false,
          start: 0,
        }],
        series: []
      };
      // 类型配置
      if (this.seriesType === 0 || this.seriesType === 2) {
        for (let i in columnData) {
          option.legend.data.push(columnData[i].fieldName);
          option.series[i] = {
            name: columnData[i].fieldName,
            data: [],
            type: this.seriesTypes[this.seriesType]
          };
          option.series[i].data = tempData.map(item => item[columnData[i].key])
        }
        xAxisData = tempData.map(item => item.key)

        option.dataZoom[0].show = this.chartConfig.dataZoom.status;

        if (this.chartConfig.gridlines.status) {
          switch (this.chartConfig.gridlines.value) {
            case 0:
              option.xAxis.splitLine.show = true;
              break;
            case 1:
              option.yAxis.splitLine.show = true;
              break;
          }
        }
        if (this.chartConfig.axes.status) {
          switch (this.chartConfig.axes.value) {
            case 0:
              option.xAxis.type = 'category';
              option.yAxis.type = 'value';
              option.xAxis.data = xAxisData;
              break;
            case 1:
              option.yAxis.type = 'category';
              option.xAxis.type = 'value';
              option.yAxis.data = xAxisData;
              break;
          }
        }
        if (this.chartConfig.dataZoom.status) {
          option.dataZoom[0].end = 10 / xAxisData.length * 100;
          switch (this.chartConfig.dataZoom.value) {
            case 0:
              option.dataZoom[0].height = 20;
              option.dataZoom[0].top = 0;
              break;
            case 1:
              option.dataZoom[0].orient = 'vertical';
              option.dataZoom[0].width = 20;
              option.dataZoom[0].right = 0;
              break;
            case 2:
              option.dataZoom[0].height = 20;
              option.dataZoom[0].bottom = 0;
              break;
            case 3:
              option.dataZoom[0].orient = 'vertical';
              option.dataZoom[0].width = 20;
              option.dataZoom[0].left = 0;
              break;
          }
        }
        if (this.chartConfig.tooltip.status) {
          option.tooltip.trigger = 'axis';
        }
      } else if (this.seriesType === 1) {
        // 兼容代码
        this.chartConfig.pieLabel = !!this.chartConfig.pieLabel ? this.chartConfig.pieLabel : {};

        option.series = [{
          data: [],
          radius: '50%',
          type: this.seriesTypes[this.seriesType],
          label: {
            show: this.chartConfig.pieLabel.status,
          },
        }]

        tempData.forEach(item => {
          option.legend.data.push(item.key);
          option.series[0].data.push({
            value: item[columnData[0].key],
            name: item.key,
          })
        })

        option.xAxis.show = false;
        option.yAxis.show = false;

        if (this.chartConfig.tooltip.status) {
          option.tooltip.trigger = 'item';
        }
        if (this.chartConfig.pieSize.status && !isNaN(this.chartConfig.pieSize.value)) {
          option.series[0].radius = this.chartConfig.pieSize.value + '%';
        }
      }
      // 公共配置

      if (this.chartConfig.legend.status) {
        switch (this.chartConfig.legend.value) {
          case 0:
            break;
          case 1:
            option.legend.orient = 'vertical';
            option.legend.y = 'center';
            option.legend.x = 'right';
            break;
          case 2:
            option.legend.y = 'bottom';
            option.legend.x = 'center';
            break;
          case 3:
            option.legend.orient = 'vertical';
            option.legend.y = 'center';
            option.legend.x = 'left';
            break;
        }
      }
      if (this.chartConfig.theme.status && this.chartConfig.theme.value !== 0) {
        option.color = this.colors[this.chartConfig.theme.value];
      }
      this.myChart = this.$echarts.init(this.$refs.myChart);
      this.myChart.setOption(option, true);

      if (!rowData.linkToJson) return;
      this.myChart.off('click')
      this.myChart.on('click', e => {
        let branchCodeProps = ['void_branch_code', 'branch_code', 'storeId'];
        let val = e.name;
        let linkToJson = JSON.parse(rowData.linkToJson);
        let index = linkToJson.data.findIndex(item => item.prop === rowData.key)

        if (index !== -1) {
          linkToJson.data[index].con = val;
          linkToJson.data[index].data.condition = val;
        } else if (branchCodeProps.includes(rowData.key)) {
          index = linkToJson.data.findIndex(item => branchCodeProps.includes(item.prop))
          if (index !== -1) {
            linkToJson.data[index].con = val;
            linkToJson.data[index].data.condition = val;
          }
        }

        sessionStorage.linkToData = JSON.stringify(linkToJson.data);
        this.$router.push({ path: '/home/counterMode' })
      })
    },
    // 获取单元格的值
    getCellVal (data, property) {
      let value = 0;
      if (/^(.+)\.(.+)$/.test(property)) {
        let key = property.split('.');
        value += parseFloat(data[key[0]][key[1]]);
        data.children.forEach(item => {
          value += parseFloat(item[key[0]][key[1]]);
        })
      } else {
        value = data[property];
      }
      return value;
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 0;
}
</style>