<template>
  <div>
    <el-dialog
      :visible.sync="dialog.visible"
      :close-on-click-modal="false"
      center
      width="450px"
      class="condition-dialog"
    >
      <div class="tree-box">
        <el-tree
          :data="dialog.treeData"
          :props="dialog.defaultProps"
          node-key="id"
          ref="tree"
          show-checkbox
        >
        </el-tree>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetUserStore } from '@/api/stores'

export default {
  name: 'StoreDialog',
  props: ['visible', 'typeReport'],
  data () {
    return {
      dialog: {
        visible: false,
        stores: [],
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      },
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'CounterMode' && this.typeReport == 1 || to.name === 'DiningMode' && this.typeReport == 2 || to.name === 'UserDefined' && this.typeReport == 3 || to.name === 'DashBoard' && this.typeReport == 0) {
        this.getUserStore();
      }
    },
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.dialog.treeData.length === 0 && this.getUserStore();
      }
    },
  },
  methods: {
    getUserStore () {
      this.$refs.tree && (this.dialog.stores = this.$refs.tree.getCheckedKeys())
      GetUserStore().then(res => {
        if (this.dialog.treeData.length === 0 && res.length > 0) {
          this.dialog.stores = res.map(item => item.id)
        }
        this.dialog.treeData = res
        this.$refs.tree && this.$refs.tree.setCheckedKeys(this.dialog.stores)
      })
    },
    submit () {
      let label = this.$refs.tree.getCheckedNodes().map(item => item.label).join('、');
      this.dialog.stores = this.$refs.tree.getCheckedKeys();
      this.$emit('submitStore', this.dialog.stores, label)
    },
    reset () {
      this.$refs.tree && this.$refs.tree.setCheckedKeys([])
    },
  },
}
</script>

<style lang="scss" scoped>
.condition-dialog {
  ::v-deep .el-dialog__header {
    display: none;
  }
  .el-select {
    width: 100%;
  }
}
</style>