var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":_vm.title,"visible":_vm.dialog.visible,"center":"","width":"400px"},on:{"update:visible":function($event){return _vm.$set(_vm.dialog, "visible", $event)}}},[_c('el-form',{ref:"dialogForm",staticClass:"dialog-form",attrs:{"model":_vm.dialog,"rules":_vm.rules,"size":"small"}},[_c('el-form-item',{attrs:{"label":_vm.$t('equipmentDialog3.label1'),"prop":"deviceName"}},[_c('my-select',{attrs:{"data":_vm.deviceNames,"value":_vm.dialog.deviceName,"size":"small","prop":{
              label:'name',
              value:'name'
            }},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "deviceName", $event)},"change":_vm.deviceNameChange}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('equipmentDialog3.label2'),"prop":"deviceType"}},[_c('my-select',{attrs:{"disabled":!_vm.dialog.deviceName,"data":_vm.deviceTypes,"value":_vm.dialog.deviceType,"size":"small","prop":{
              label:'typeName',
              value:'typeName'
            },"bold-prop":"isLink"},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "deviceType", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('equipmentDialog3.label3'),"prop":"deviceBrand"}},[_c('my-select',{attrs:{"disabled":!_vm.dialog.deviceType,"data":_vm.deviceBrands,"value":_vm.dialog.deviceBrand,"size":"small","prop":{
              label:'brandName',
              value:'brandName'
            },"bold-prop":"isLink"},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "deviceBrand", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('equipmentDialog3.label4'),"prop":"deviceModel"}},[_c('my-select',{attrs:{"disabled":!_vm.dialog.deviceBrand,"data":_vm.deviceModels,"value":_vm.dialog.deviceModel,"size":"small","prop":{
              label:'model',
              value:'model'
            },"bold-prop":"isLink"},on:{"update:value":function($event){return _vm.$set(_vm.dialog, "deviceModel", $event)}}})],1)],1),_c('div',{staticClass:"footer-box"},[_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){_vm.dialog.visible = false}}},[_vm._v(_vm._s(_vm.$t('operation.cancel')))]),_c('el-button',{attrs:{"size":"medium","type":"primary","loading":_vm.dialog.loading},on:{"click":_vm.submitCreateDevice}},[_vm._v(_vm._s(_vm.$t('operation.confirm')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }