<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>

          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
        </el-button-group>
        <col-set @update="getTableData"></col-set>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :data="tableData"
        :height="tableHeight"
        ref="table"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          sortable
          prop="name"
          :label="$t('slaPolicies.col1')"
        >
        </el-table-column>
        <el-table-column
          sortable
          prop="levelAll"
          :label="$t('slaPolicies.col7')"
        >
        </el-table-column>
        <el-table-column
          sortable
          prop="dag"
          :label="$t('slaPolicies.col3')"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
// 接口
import {
  GetSLAPoliciesList,
} from '@/api/warehouse/slaPolicies'
export default {
  components: {
    ColSet,
  },
  props: ['active'],
  data () {
    return {
      permit: {
        search: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'EscalationLevel') {
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tableRow.$el.clientHeight
          this.page.size = Math.floor(this.tableHeight / 35);

          let ids = this.tableSelected.map(item => item.id)
          this.getTableData(() => {
            this.$nextTick(() => {
              this.tableData.forEach(item => {
                if (ids.includes(item.id))
                  this.$refs.table.toggleRowSelection(item, true)
              })
            })
          });

          this.$refs.table.doLayout();
        })
      }
    }
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      };
      this.tableLoading = true;
      GetSLAPoliciesList(param).then((res) => {

        this.tableData = res.list.map(item => {
          let slaLevel = !!item.slaLevel ? item.slaLevel.split(',').map(item => `Level${item}`).join(',') : '';
          let level = !!item.level ? item.level.split(',').map(item => `L${item}`).join(',') : '';
          if (!!slaLevel) slaLevel += ',';
          item.levelAll = slaLevel + level;
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item);
    },
    dblclickEdit (item) {
      sessionStorage.setItem('policyData', JSON.stringify(item));
      this.$router.push({ path: '/home/newSLAEscalationLevel', query: { id: item.id } })

    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.search = permission.includes('SLAPolicies-SLAPoliciesSearch');
        // this.permit.add = permission.includes('SLAPolicies-EscalationLevelAdd');
        // this.permit.edit = permission.includes('SLAPolicies-EscalationLevelEdit');
        // this.permit.del = permission.includes('SLAPolicies-EscalationLevelDelete');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>