<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      width="400px"
      center
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="dialog.nameLabel"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          v-show="currentType.id === '100000000000000000000000000000000003'"
          :label="$t('dataMaintenance.supplier.abbreviation')"
          prop="abbreviation"
        >
          <el-input v-model="dialog.abbreviation"></el-input>
        </el-form-item>
        <el-form-item
          v-show="currentType.id === '100000000000000000000000000000000007'"
          prop="color"
        >
          <el-color-picker v-model="dialog.color"></el-color-picker>
        </el-form-item>
        <div v-show="currentType.id === '0'">
          <el-form-item
            :label="$t('dataMaintenance.serviceConfirmationTemplate.label1')"
            prop="formFormat"
          >
            <el-input
              v-model="dialog.fileName"
              size="medium"
              readonly
            >
              <el-button
                slot="append"
                icon="el-icon-upload2"
                @click="$refs.file.click()"
              ></el-button>
            </el-input>
            <input
              ref="file"
              type="file"
              @change="dialog.fileName = $event.target.files[0].name"
              hidden
            >
          </el-form-item>
          <el-form-item
            :label="$t('dataMaintenance.serviceConfirmationTemplate.label2')"
            prop="template"
          >
            <el-select
              size="medium"
              multiple
              v-model="dialog.template"
              filterable
            >
              <el-option
                v-for="item in templates"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-show="currentType.id === '100000000000000000000000000000000006'">
          <el-form-item
            :label="$t('dataMaintenance.systemType.label1')"
            prop="abbreviation"
          >
            <el-input v-model="dialog.abbreviation"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('dataMaintenance.systemType.label2')"
            prop="path"
          >
            <el-image
              class="icon-img"
              lazy
              :src="photo"
              fit="cover"
              @click.native="$refs.fileImage.click()"
            ></el-image>
            <input
              type="file"
              ref="fileImage"
              @change="uploadImage"
              hidden
            >
          </el-form-item>
        </div>
        <div v-show="currentType.id === '100000000000000000000000000000000009'">
          <el-form-item
            :label="$t('dataMaintenance.securityType.label1')"
            prop="duration"
          >
            <div class="input-box">
              <el-input
                type="number"
                v-model="dialog.duration"
                min="1"
                max="99"
              ></el-input>
              <el-select v-model="dialog.type">
                <el-option
                  :value="0"
                  :label="$t('dataMaintenance.securityType.option1')"
                ></el-option>
                <el-option
                  :value="1"
                  :label="$t('dataMaintenance.securityType.option2')"
                ></el-option>
                <el-option
                  :value="2"
                  :label="$t('dataMaintenance.securityType.option3')"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('dataMaintenance.securityType.label2')"
            prop="times"
          >
            <el-input
              type="number"
              v-model="dialog.times"
              min="1"
              max="99"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item
          v-show="currentType.id === '100000000000000000000000000000000027'"
          :label="$t('dataMaintenance.warrantyType.label1')"
          prop="duration"
        >
          <div class="input-box">
            <el-input
              type="number"
              v-model="dialog.duration"
              min="0"
            ></el-input>
            <el-select v-model="dialog.type">
              <el-option
                :value="0"
                :label="$t('dataMaintenance.warrantyType.option1')"
              ></el-option>
              <el-option
                :value="1"
                :label="$t('dataMaintenance.warrantyType.option2')"
              ></el-option>
              <el-option
                :value="2"
                :label="$t('dataMaintenance.warrantyType.option3')"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="addSubmit"
          :loading="dialog.loading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { AddConfirmationTemplate, ConfirmationTemplates } from '@/api/warehouse/dataMaintenance/serviceConfirmationTemplate'
import { AddDataField } from '@/api/warehouse/dataMaintenance'
import { UploadImage } from '@/api'

export default {

  props: ['visible', 'data', 'currentType'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      templates: [],
      msg: '',
      dialog: {
        visible: false,
        loading: false,
        id: null,
        path: null,
        title: '',
        nameLabel: '',
        name: '',
        abbreviation: '',
        color: '#F63A3A',
        fileName: '',
        template: [],
        icon: '',
        duration: '',
        times: '',
        type: 0,
      },
      rules: {
        name: [{ required: true, message: 'Please enter name.', trigger: 'blur' }]
      },
      types: [],
    }
  },
  computed: {
    photo () {
      if (!!this.dialog.icon) {
        return this.baseUrl + '/Engineer/getImage.do?imageId=' + this.dialog.icon;
      } else {
        return '';
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: `${this.$i18n.t('operation.edit')} ${this.currentType.name}`,
              nameLabel: this.$i18n.t('dataMaintenance.name'),
              id: this.data.id,
              name: this.data.name,
              path: this.data.path,
            })

            if (this.currentType.id === '100000000000000000000000000000000003') {
              this.dialog.abbreviation = this.data.abbreviation;
            }
            if (this.currentType.id === '100000000000000000000000000000000007') {
              this.dialog.color = this.data.color;
            }
            if (this.currentType.id === '0') {
              this.dialog.fileName = this.data.flieName;
              this.dialog.template = this.data.demId.split(',');
              this.$refs.file.value = '';
            }
            if (this.currentType.id === '100000000000000000000000000000000006') {
              this.dialog.abbreviation = this.data.abbreviation;
              this.dialog.icon = this.data.icon;
            }
            if (this.currentType.id === '100000000000000000000000000000000009') {
              this.dialog.duration = this.data.duration;
              this.dialog.times = this.data.times;
              this.dialog.type = this.data.type;
            }
            if (this.currentType.id === '100000000000000000000000000000000027') {
              this.dialog.duration = this.data.duration;
              this.dialog.type = this.data.type;
            }

          })
        } else {
          this.dialog.title = `${this.$i18n.t('operation.add')} ${this.currentType.name}`;
          this.dialog.nameLabel = this.$i18n.t('dataMaintenance.name');
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    ConfirmationTemplates().then(res => this.templates = res)
  },
  methods: {
    // 提交添加
    addSubmit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
        }
        if (this.currentType.id !== '0') {
          param.parent = this.currentType.id;
          if (this.currentType.id === '100000000000000000000000000000000003') {
            param.abbreviation = this.dialog.abbreviation;
          }
          if (this.currentType.id === '100000000000000000000000000000000007') {
            param.color = this.dialog.color;
          }
          if (this.currentType.id === '100000000000000000000000000000000006') {
            param.abbreviation = this.dialog.abbreviation;
            param.icon = this.dialog.icon;
          }
          if (this.currentType.id === '100000000000000000000000000000000009') {
            param.duration = this.dialog.duration;
            param.times = this.dialog.times;
            param.type = this.dialog.type;
          }
          if (this.currentType.id === '100000000000000000000000000000000027') {
            param.duration = this.dialog.duration;
            param.type = this.dialog.type;
          }

          this.msg = this.$i18n.t('message.add');
          if (this.data) {
            param.id = this.dialog.id;
            param.path = this.dialog.path;
            this.msg = this.$i18n.t('message.edit');
          }

          this.dialog.loading = true;
          AddDataField(param).then(this.dealSubmit)
        } else {
          let formData = new FormData();
          let file = this.$refs.file.files[0] || '';

          if (this.dialog.id) {
            formData.append('id', this.dialog.id);
          }
          formData.append('file', file);
          formData.append('name', this.dialog.name);
          formData.append('demId', this.dialog.template.join(','));
          AddConfirmationTemplate(formData).then(this.dealSubmit)
        }
      });
    },
    dealSubmit (res) {
      if (res.success) {
        this.$message.success(this.msg)
        this.$emit('update')
        this.dialog.visible = false;
      }
      this.dialog.loading = false;
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.fileImage.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.dialog.icon = res.message;
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-form ::v-deep .el-form-item__label {
  width: 100%;
  text-align: left;
}
.icon-img {
  width: 80px;
  height: 80px;
}
.input-box {
  width: 100%;
  display: flex;
  .el-input {
    margin-right: 10px;
  }
}
</style>