<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showAdd(0)"
          >{{$t('chartType.queryConditions.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd(1)"
          >{{$t('chartType.queryConditions.btn2')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        @sort-change="sortChange"
        :data="filterParamData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="100"
          prop="data.relation"
          :label="$t('chartType.queryConditions.col1')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="data.field"
          :label="$t('chartType.queryConditions.col2')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="140"
          prop="data.queryType"
          :label="$t('chartType.queryConditions.col3')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.data.queryType === 0 ? 'Report Field':'Template Parameters'}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="80"
          prop="data.invert"
          :label="$t('chartType.queryConditions.col4')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.data.invert?'Yes':'No'}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="data.operator"
          :label="$t('chartType.queryConditions.col5')"
          sortable
        >
          <template slot-scope="scope">
            {{$global.typeOperatorsText[scope.row.data.fieldType][scope.row.data.operator]}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="data.condition"
          :label="$t('chartType.queryConditions.col6')"
          sortable
        >
          <template slot-scope="scope">
            <template v-if="scope.row.data.fieldType === 'date'">
              {{dateCondition[scope.row.data.condition]}}
            </template>
            <template v-else>
              {{scope.row.data.condition}}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <!-- 查询弹窗 -->
    <query
      :visible.sync="QueryVisible"
      :data="queryData"
      :report-template-id="templateId"
      @submit="submitQuery"
    ></query>
  </div>
</template>

<script>
// 组件
import Query from '@/components/DashBoardDialog/Query'

// 接口
import {
  GetReportData,
} from '@/api/counterMode'
export default {
  components: {
    Query
  },
  props: ['data', 'templateId', 'notRequset'],
  data () {
    return {
      dateCondition: ['Last 30 minutes', 'Last 1 hour', 'Last 4 hours', 'Today', 'Yesterday', 'Last 3 Days', 'Last 5 Days', 'Last 1 Week', 'Last 30 Days', 'Last 90 Days', 'Last Year', 'All Time', 'Custom Range', 'Test Time'],
      QueryVisible: false,
      tableLoading: false,
      tableSelected: [],
      tableHeight: null,
      page: {
        total: 0,
        size: 100,
        current: 1,
      },
      filterParam: null,
      filterParamData: [],
      queryData: null,
    }
  },
  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        if (!this.templateId) return;
        this.filterParamData = this.data;
        this.submitParam();
        this.getData();
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    showTab () {
      this.tableHeight = this.$refs.tableRow.$el.clientHeight;
    },
    getData () {
      if (this.notRequset) return;
      let param = {
        limit: this.page.size,
        page: this.page.current,
        templatesId: this.templateId,
      }
      for (let i in this.filterParam) {
        if (i !== 'templateId')
          param[i] = this.filterParam[i];
      }
      GetReportData(param).then(res => {
        this.$emit('submit', res, this.filterParamData)
      });
    },
    // 提交条件
    submitQuery (data) {
      let index = this.filterParamData.findIndex(item => item.prop === data.prop);
      if (index == -1) {
        this.filterParamData.push(data)
      } else {
        this.filterParamData.splice(index, 1, data);
      }
      this.submitParam();
      this.getData();
    },
    // 提交条件
    submitParam (param) {
      param = param || {}
      this.page.current = 1;
      let paramCom = {
        parameters: []
      };
      // 初始化筛选条件
      if (param.isReport) {
        this.getLinkConfig()
      }
      this.filterParamData.forEach((item, index) => {
        if (item.mark === 'Report') {
          paramCom[item.prop] = item.val;
        } else if (item.mark === 'BU') {
          if (item.con !== 'ALL') {
            paramCom[item.prop] = item.val;
          } else {
            paramCom[item.prop] = '';
            // paramCom[item.prop] = '0f458006-7267-4643-944e-d2f5cb427564,1d9a50ec-bd16-4f20-bea7-d271a5ea12a0,1f5c3afe-41a0-448c-893a-8f33a9609170,28dab1c9-7f58-481a-a4f3-d2f9ac61bc54,2eda1607-9ec4-49e9-8c7d-b0de602b8735,4abd3e12-e1b8-4779-9b35-fc54da65493a,4ac4f9c1-2354-4e07-9246-2586e008f20a,52fe49a6-005f-45f8-9f39-52fae9fa22d1,5c555ef5-ebbe-4f47-9883-10dbc85a6067,5e1d3352-82a3-4bc7-ad41-e8a60c3ac203,5eee1d90-a0da-4eb7-96c1-41221b81e6cc,65f4b5c1-8f3b-41f0-9e42-7cbd9771cbb4,662006ef-9caf-469b-b00e-b2b073961f24,689165ae-a4c6-4f12-befc-7723b65bb870,6eba7d01-03f9-49e7-aaae-b68c8fd21fc4,70c24bad-ec94-4eb8-9a77-16136d364308,714b2241-2036-42be-898b-883360fba8fc,807ece25-8677-4b02-a1ea-207eb2e17d12,85056551-a53b-487e-9f75-20a9481c949a,8aca4e25-46cb-4f64-90dd-b66cbe62118a,8b3d876d-a5d7-4ca6-8b28-2c37fec9e7ae,a3af44b1-bb24-41db-a26c-0aa404617377,a980c247-d133-4b20-a0db-3536885d84f0,af6a5e52-056a-464a-9778-60ecf493f552,bea30e50-36a3-4aa5-9260-335b291aa812,c645d79a-fc8c-403b-8340-cc8fcf17626f,c711f5b8-ce4f-436f-bc9d-0d608a7051f3,cdfe9b66-a332-4566-ad61-69065bf058d6,ce21ee88-6de1-4e53-8586-1aea57e5bdbf,d2d89266-9e40-4b20-9677-6c261bb027fd,d9a3c566-1287-4312-bf82-cbf3955f99ba,dd411008-bb02-4b5d-9eda-71705932a18e,e947853a-eee5-4194-a498-7fb144697ddd,f1479c52-8d79-408c-afd2-1e8c59175d40';
          }
        } else {
          if (item.data.condition === '' && item.data.list.length === 0 && item.data.dateRange.length === 0 && item.data.range.start === '') return;

          this.dealParam(paramCom, item.data);
        }
      })
      // paramCom.branchIds = "0f458006-7267-4643-944e-d2f5cb427564,1d9a50ec-bd16-4f20-bea7-d271a5ea12a0,1f5c3afe-41a0-448c-893a-8f33a9609170,28dab1c9-7f58-481a-a4f3-d2f9ac61bc54,2eda1607-9ec4-49e9-8c7d-b0de602b8735,4abd3e12-e1b8-4779-9b35-fc54da65493a,4ac4f9c1-2354-4e07-9246-2586e008f20a,52fe49a6-005f-45f8-9f39-52fae9fa22d1,5c555ef5-ebbe-4f47-9883-10dbc85a6067,5e1d3352-82a3-4bc7-ad41-e8a60c3ac203,5eee1d90-a0da-4eb7-96c1-41221b81e6cc,65f4b5c1-8f3b-41f0-9e42-7cbd9771cbb4,662006ef-9caf-469b-b00e-b2b073961f24,689165ae-a4c6-4f12-befc-7723b65bb870,6eba7d01-03f9-49e7-aaae-b68c8fd21fc4,70c24bad-ec94-4eb8-9a77-16136d364308,714b2241-2036-42be-898b-883360fba8fc,807ece25-8677-4b02-a1ea-207eb2e17d12,85056551-a53b-487e-9f75-20a9481c949a,8aca4e25-46cb-4f64-90dd-b66cbe62118a,8b3d876d-a5d7-4ca6-8b28-2c37fec9e7ae,a3af44b1-bb24-41db-a26c-0aa404617377,a980c247-d133-4b20-a0db-3536885d84f0,af6a5e52-056a-464a-9778-60ecf493f552,bea30e50-36a3-4aa5-9260-335b291aa812,c645d79a-fc8c-403b-8340-cc8fcf17626f,c711f5b8-ce4f-436f-bc9d-0d608a7051f3,cdfe9b66-a332-4566-ad61-69065bf058d6,ce21ee88-6de1-4e53-8586-1aea57e5bdbf,d2d89266-9e40-4b20-9677-6c261bb027fd,d9a3c566-1287-4312-bf82-cbf3955f99ba,dd411008-bb02-4b5d-9eda-71705932a18e,e947853a-eee5-4194-a498-7fb144697ddd,f1479c52-8d79-408c-afd2-1e8c59175d40";
      this.filterParam = paramCom;
    },
    // 处理条件
    dealParam (paramCom, item) {
      let value;
      let data = {
        key: item.prop,
        operator: this.$global.typeOperators[item.fieldType][item.operator],
        relation: item.relation,
        invert: Number(!item.invert),
      }
      switch (item.fieldType) {
        case 'number':
        case 'string':
        case 'stores':
          switch (item.operator) {
            case 6:
              data.value = item.list.join(',');
              break;
            case 7:
              data.value = `${item.range.start}~${item.range.end}`;
              break;
            default:
              data.value = item.condition;
          }
          break;
        case 'date':
          // data.value = item.condition !== 11 ? item.dateRange[0] + '~' + item.dateRange[1] : '';
          data.value = item.dateRange[0] + '~' + item.dateRange[1];
          break;
        case 'time':
          data.value = `${item.dateRange[0]}~${item.dateRange[1]}`;
          break;
        case 'mins':
          data.value = item.operator !== 2 ? `${item.condition}~0` : `${item.range.start}~${item.range.end}`;
          break;
        default:
          data.value = item.condition;
      }
      data.type = item.fieldType;
      data.value = data.value.toString().trim();
      paramCom.parameters.push(data)
    },
    showAdd (type) {
      this.queryData = {
        data: {
          queryType: type
        }
      }
      this.QueryVisible = true;
    },
    dblclick (item) {
      this.queryData = item;
      this.QueryVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkids = this.tableSelected.map(item => item.prop);
        this.filterParamData = this.filterParamData.filter(item => !checkids.includes(item.prop))
        this.getData();

      }).catch(() => { });
    },
    //排序触发函数
    sortChange (column) {
      this.tableData = this.$sortChange(column, this.tableData)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: calc(100% - 46px);
}
</style>