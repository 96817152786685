<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dashBoard.dashboardTemplate.title1')"
      :visible.sync="dialog.visible"
      center
      width="1000px"
      class="dashboard-dialog"
    >
      <div>
        <el-row>
          <div class="input-wrap">
            <el-button-group class="input-group">
              <el-input
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </div>
        </el-row>
        <el-row>
          <el-table
            :data="tableData"
            @row-click="rowClick"
            @selection-change="val => tableSelected = val"
            :row-class-name="tableRowClassName"
            v-loading="tableLoading"
            :height="386"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              :label="$t('dashBoard.dashboardTemplate.col1')"
              sortable
              min-width="250"
              prop="name"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              :label="$t('dashBoard.dashboardTemplate.col2')"
              sortable
              min-width="130"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isDefaultEnable === 0"
                  size="small"
                  type="danger"
                >No</el-tag>
                <el-tag
                  v-else
                  size="small"
                  type="success"
                >Yes</el-tag>

              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('dashBoard.dashboardTemplate.col3')"
              sortable
              min-width="150"
              prop="orgZarionsName"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('dashBoard.dashboardTemplate.col4')"
              sortable
              min-width="150"
              prop="accessGroupName"
            ></el-table-column>
            <el-table-column
              :label="$t('dashBoard.dashboardTemplate.col5')"
              min-width="80"
            >
              <template slot-scope="scope">
                <i
                  v-if="scope.row.isCurrent"
                  class="el-icon-check"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="page-wrap">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.current"
            @current-change="getTableData"
          >
          </el-pagination>
        </el-row>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          :disabled="!permit.submit"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('dashBoard.dashboardTemplate.btn1')}}</el-button>
        <el-button
          :disabled="!permit.edit"
          size="medium"
          @click="layoutEdit"
        >{{$t('dashBoard.dashboardTemplate.btn2')}}</el-button>
      </div>
    </el-dialog>

    <!-- 新增表盘模板弹框 -->
    <add-dashboard
      :visible.sync="AddDashboardVisible"
      :layout-data="layoutTempData"
      :layout-param="layoutTempParam"
      :type="2"
      @update="getTableData"
    ></add-dashboard>
  </div>
</template>

<script>
// 组件
import AddDashboard from '@/components/DashBoardDialog/AddDashboard'

// 接口
import {
  GetLayoutList,
  LogUserLayout,
  DelLayout,
} from '@/api/dashboard'
export default {
  components: {
    AddDashboard,
  },
  props: ['visible', 'layoutParam'],
  data () {
    return {
      permit: {
        edit: true,
        del: true,
        submit: true,
      },
      AddDashboardVisible: false,
      dialog: {
        visible: false,
      },
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      currentData: {},

      layoutTempData: null,
      layoutTempParam: null,
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      } else {
        this.currentData = {};
        this.getTableData();
      }
    }
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetLayoutList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (!!this.layoutParam && item.id === this.layoutParam.id) {
            item.isCurrent = true;
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 编辑属性
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      // if (this.tableSelected[0].isCurrent) return this.$message.warning('Cannot edit in current use.')

      this.layoutTempData = JSON.parse(this.tableSelected[0].layoutJson)
      this.layoutTempParam = this.tableSelected[0];
      this.AddDashboardVisible = true;
    },

    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.some(item => item.isCurrent)) return this.$message.warning('Cannot delete current use.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {

        let arr = this.tableSelected.map(item => this.delPromise(item.id))
        Promise.all(arr).then(res => {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.del'))
        })
      }).catch(() => { });
    },
    delPromise (id) {
      return new Promise((resolve, reject) => {
        DelLayout(id).then(res => {
          if (res) resolve()
        })
      })
    },
    // 获取选中id
    rowClick (row, column, event) {
      this.currentData = row;
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.id === this.currentData.id) return 'checked-row';
      else return '';
    },
    // 提交
    submit () {
      if (!this.currentData.id) return this.$message.warning('Please select at least one item.')
      this.$emit('submit', this.currentData);
      LogUserLayout(this.currentData.id)
      this.dialog.visible = false;
    },
    // 编辑布局
    layoutEdit () {
      if (!this.currentData.id) return this.$message.warning('Please select at least one item.')
      // if (this.currentData.isCurrent) return this.$message.warning('Cannot edit in current use.')

      this.$emit('layoutEdit', this.currentData)
      this.dialog.visible = false;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.edit = permission.includes('a26e38ab-f579-46d2-af93-c3f05f5efb62');
        // this.permit.del = permission.includes('ce2cb6d2-738f-49cf-b70b-7b8782c9ab4a');
        // this.permit.submit = permission.includes('bb328a9a-9e1b-41f5-bf8c-832a53278750');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>