<template>
  <div class="wrap">
    <el-card>
      <el-row>
        <div class="input-wrap">
          <div class="left-box">

            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
              <el-button
                :disabled="taskType === ''"
                size="medium"
                @click="sortMove(1)"
              >{{$t('standardTask.btn1')}}</el-button>
              <el-button
                :disabled="taskType === ''"
                size="medium"
                @click="sortMove(2)"
              >{{$t('standardTask.btn2')}}</el-button>
            </el-button-group>
            <el-select
              v-model="taskType"
              size="medium"
              @change="searchData"
              filterable
            >
              <el-option
                value=""
                label="All"
              ></el-option>
              <el-option
                v-for="item in taskTypes"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="projectType"
              size="medium"
              @change="searchData"
              filterable
            >
              <el-option
                value=""
                label="All"
              ></el-option>
              <el-option
                v-for="item in projectTypes"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
          @row-dblclick="dblclickEdit"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            prop="name"
            :label="$t('standardTask.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('standardTask.col6')"
            prop="sort"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="typeName"
            :label="$t('standardTask.col3')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="projectTypeName"
            :label="$t('standardTask.col4')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="pdNames"
            :label="$t('standardTask.col5')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <task-dialog
      :visible.sync="TaskDialogVisible"
      :data="taskData"
      @update="getTableData"
    ></task-dialog>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
import TaskDialog from './components/TaskDialog'
// 接口
import {
  GetStandardTaskList,
  DelStandardTask,
  SetStandardTaskOrder,
} from '@/api/warehouse/standardTask'
import { GetTaskTypeAll } from '@/api/warehouse/dataMaintenance/projectTaskType'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'StandardTask',
  components: {
    ColSet,
    TaskDialog,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      TaskDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      taskTypes: [],
      projectTypes: [],
      searchVal: '',
      taskType: '',
      projectType: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      taskData: null
    }
  },
  created () {
    this.dealPermission();
    // 获取任务类型
    GetTaskTypeAll().then(res => this.taskTypes = res);
    // 获取合同类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000008' }).then(res => this.projectTypes = res)
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();

  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        taskTypeId: this.taskType,
        projectTypeId: this.projectType,
      }
      this.tableLoading = true;
      GetStandardTaskList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示新增弹框
    showAdd () {
      this.taskData = null;
      this.TaskDialogVisible = true;
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      if (!this.permit.edit) return;

      this.taskData = e;
      this.TaskDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let arr = this.tableSelected.map(item => this.delPromise(item.id));
        Promise.all(arr).then(res => {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.del'))
        })
      }).catch(() => { });
    },
    delPromise (id) {
      return new Promise((resolve, reject) => {
        DelStandardTask(id).then(res => {
          if (res) resolve();
        })
      })
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('StandardTaskSearch');
        this.permit.add = permission.includes('StandardTaskAdd');
        this.permit.edit = permission.includes('StandardTaskEdit');
        this.permit.del = permission.includes('StandardTaskDelete');
      }
    },

    sortMove (type) {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let param;
      let index = this.tableData.findIndex(item => item.id === this.tableSelected[0].id);
      switch (type) {
        case 1:
          if (index === 0) return;
          param = {
            id: this.tableSelected[0].id,
            sort: parseInt(this.tableSelected[0].sort) - 1,
            anotherId: this.tableData[index - 1].id,
            anotherSort: parseInt(this.tableData[index - 1].sort) + 1,
          }
          break;
        case 2:
          if (index === this.tableData.length - 1) return;
          param = {
            id: this.tableSelected[0].id,
            sort: parseInt(this.tableSelected[0].sort) + 1,
            anotherId: this.tableData[index + 1].id,
            anotherSort: parseInt(this.tableData[index + 1].sort) - 1,
          }
          break;
      }
      SetStandardTaskOrder(param).then(res => {
        if (res) {
          switch (type) {
            case 1:
              this.tableData[index].sort = param.sort;
              this.tableData[index - 1].sort = param.anotherSort;
              [this.tableData[index], this.tableData[index - 1]] = [this.tableData[index - 1], this.tableData[index]]
              break
            case 2:
              this.tableData[index].sort = param.sort;
              this.tableData[index + 1].sort = param.anotherSort;
              [this.tableData[index], this.tableData[index + 1]] = [this.tableData[index + 1], this.tableData[index]]
              break;
          }
          this.tableData = JSON.parse(JSON.stringify(this.tableData))
          this.$nextTick(() => {
            this.$refs.table.toggleRowSelection(this.tableData[type === 1 ? index - 1 : index + 1], true)
          })
        }

        // this.getTableData();
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.taskType !== '' && this.tableSelected.length === 1 && row.id === this.tableSelected[0].id) {
        return 'checked-row';
      }
      return '';
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.left-box {
  display: flex;
  .el-select {
    width: 120px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1920px) {
}
</style>