import request from '@/utils/request'

// 获取报表数据
export const GetReportData = (data) => request({
  method: 'post',
  url: '/sounterMode/queryOrders.do',
  data: JSON.stringify(data)
})

// 获取报表表头
export const GetReportHeader = (data) => request({
  method: 'post',
  url: '/reportDesigner/queryByTemplatesId.do',
  data: JSON.stringify(data)
})

// 获取指定订单
export const GetOrder = (data) => request({
  method: 'post',
  url: '/sounterMode/queryOrder.do',
  data: JSON.stringify(data)
})

// 获取指定时间段订单
export const GetTimeOrder = (data) => request({
  method: 'post',
  url: '/sounterMode/queryOrdersNear.do',
  data: JSON.stringify(data)
})

// 获取模板参数
export const GetTemplateParam = (data) => request({
  method: 'post',
  url: '/reportDesigner/queryByParameters.do',
  data: JSON.stringify(data)
})

// 获取参数记录
export const GetParamRecord = (data) => request({
  method: 'get',
  url: `/ReportConditionRecord/queryBySelect/${data.templateId}/${data.type}.do`,
})

// 新增参数记录
export const AddParamRecord = (data) => request({
  method: 'post',
  url: `/ReportConditionRecord/add.do`,
  data: JSON.stringify(data)
})

// 删除参数记录
export const DelParamRecord = (id) => request({
  method: 'post',
  url: `/ReportConditionRecord/deleted/${id}.do`,
})


// 获取订单最后时间
export const GetLastOrderDate = () => request({
  method: 'get',
  url: `/sounterMode/theLastThirtyMinutes.do`,
})

// 导出报表
export const ExportReport = (data) => request({
  method: 'post',
  url: '/sounterMode/exportOrders.do',
  data: JSON.stringify(data),
  responseType: 'blob'
})

// 保存报表布局
export const SaveLayout = (data) => request({
  method: 'post',
  url: `/SysUserTemplatesLayout/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 获取报表布局
export const GetLayout = (data) => request({
  method: 'post',
  url: `/SysUserTemplatesLayout/queryByTemplatesId.do`,
  data: JSON.stringify(data)
})

// 导出pdf
export const ExportReportPDF = (data) => request({
  method: 'post',
  url: `/sounterMode/exportPdf.do`,
  data: JSON.stringify(data),
  responseType: 'blob'
})
