import { render, staticRenderFns } from "./CopyDialog.vue?vue&type=template&id=88585d28&scoped=true&"
import script from "./CopyDialog.vue?vue&type=script&lang=js&"
export * from "./CopyDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88585d28",
  null
  
)

export default component.exports