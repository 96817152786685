<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        label-width="170px"
        size="small"
        class="user-form"
        label-position="left"
      >
        <el-form-item
          :label="$t('approvalWorkflow.workfow.label1')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('approvalWorkflow.workfow.label2')"
          prop="workFlowId"
        >
          <el-input v-model="dialog.workFlowId"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('approvalWorkflow.workfow.label3')"
              prop="step"
            >
              <el-input-number
                v-model="dialog.step"
                :controls="false"
                :min="1"
                :max="50"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('approvalWorkflow.workfow.label4')">
              <el-button @click="ViewDialogVisible = true">View</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-box">
          <div
            v-for="item in parseInt(dialog.step)"
            :key="item"
            :class="{'active': currentStep === item - 1}"
            class="step-btn"
            @click="stepClick(item)"
          >{{item}}</div>
        </div>
        <div class="table-title">
          <span>{{$t('approvalWorkflow.workfow.subtitle3')}}</span>
          <el-select
            size="small"
            v-model="checkType[currentStep]"
          >
            <el-option
              v-for="item in optionList[currentStep]"
              :key="item.id"
              :value="item.status"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-button
            size="small"
            @click="optionEdit"
          >{{$t('operation.edit')}}</el-button>
        </div>
        <div class="table-title">
          <span>{{$t('approvalWorkflow.workfow.subtitle1')}}</span>
          <el-input
            size="small"
            v-model="signData[currentStep]"
          ></el-input>
          <el-button
            size="small"
            @click="showAdd(1)"
          >{{$t('operation.add')}}</el-button>
        </div>
        <el-table :data="tableData1[currentStep]">
          <el-table-column
            prop="department"
            :label="$t('approvalWorkflow.workfow.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="label"
            :label="$t('approvalWorkflow.workfow.col2')"
            sortable
          >
          </el-table-column>
        </el-table>
        <div class="table-title">
          <div class="title">
            <span>{{$t('approvalWorkflow.workfow.subtitle2')}}</span>
          </div>
          <el-button-group>
            <el-button
              size="small"
              @click="showAdd(2)"
            >{{$t('operation.add')}}</el-button>
            <!-- <el-button
              size="small"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button> -->
          </el-button-group>

        </div>
        <el-table :data="tableData2[currentStep]">
          <el-table-column
            prop="department"
            :label="$t('approvalWorkflow.workfow.col3')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="label"
            :label="$t('approvalWorkflow.workfow.col4')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>

    <!-- 部门弹框 -->
    <department-tree
      :visible.sync="DepartmentTreeVisible"
      :checked-keys="staffIds"
      @submit="staffSubmit"
    ></department-tree>

    <!-- 选项弹框 -->
    <option-dialog
      :visible.sync="OptionDialogVisible"
      :ids="optionIds"
      @submit="optionSubmit"
    ></option-dialog>
    <!-- 流程图 -->
    <view-dialog
      :visible.sync="ViewDialogVisible"
      :id="id"
    ></view-dialog>
  </div>
</template>

<script>
// 组件
import DepartmentTree from '@/components/DepartmentTree'
import OptionDialog from './OptionDialog'
import ViewDialog from './ViewDialog'
// 接口
import {
  AddApprovalWorkflow,
  GetApprovalWorkflowItem,
} from '@/api/warehouse/approvalWorkflow'
export default {
  components: {
    DepartmentTree,
    OptionDialog,
    ViewDialog,
  },
  props: ['visible', 'id'],
  data () {
    return {
      DepartmentTreeVisible: false,
      OptionDialogVisible: false,
      ViewDialogVisible: false,
      currentStep: 0,
      tableType: null,
      staffIds: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
        workFlowId: '',
        step: 1,
      },
      rules: {
        user: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      checkType: [],
      signData: [''],
      tableData1: [[]],
      tableData2: [[]],
      optionList: [[]],
      optionIds: [],

      onload: false,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.dialog.id = this.id;
        if (this.id) {
          this.getItem();
        } else {
          this.dialog.title = this.$i18n.t('approvalWorkflow.workfow.title1');
          this.signData = [''];
          this.checkType = [''];
          this.tableData1 = [[]];
          this.tableData2 = [[]];
          this.optionList = [[]];
          this.onload = true;
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'dialog.step': function (newVal, oldVal) {
      if (!this.onload) return;

      if (newVal > oldVal) {
        for (var i = oldVal; i < newVal; i++) {
          this.signData.push('')
          this.checkType.push('')
          this.tableData1.push([])
          this.tableData2.push([])
          this.optionList.push([])
        }
      } else {
        this.signData = this.signData.slice(0, newVal)
        this.checkType = this.checkType.slice(0, newVal)
        this.tableData1 = this.tableData1.slice(0, newVal)
        this.tableData2 = this.tableData2.slice(0, newVal)
        this.optionList = this.optionList.slice(0, newVal)
        if (this.currentStep > newVal - 1) this.currentStep = 0;
      }
    }
  },
  created () {

  },
  methods: {
    getItem () {
      let param = {
        id: this.id,
      }
      GetApprovalWorkflowItem(param).then(res => {
        Object.assign(this.dialog, {
          title: this.$i18n.t('approvalWorkflow.workfow.title2'),
          name: res.name,
          workFlowId: res.workFlowId,
          step: res.step,
        })
        this.signData = new Array(res.step).fill('')
        this.checkType = new Array(res.step).fill('')
        this.tableData1 = new Array(res.step).fill('').map(item => [])
        this.tableData2 = new Array(res.step).fill('').map(item => [])
        this.optionList = new Array(res.step).fill('').map(item => [])
        res.workflowApprovalSymbolList.forEach(item => {
          this.tableData1[item.step - 1].push({
            department: item.department,
            label: item.approvedBy,
            id: item.approvedById,
          })
          if (this.signData[item.step - 1] === '')
            this.signData[item.step - 1] = item.approvalSymbol
          if (this.checkType[item.step - 1] === '')
            this.checkType[item.step - 1] = item.status

          // if (this.optionList[item.step - 1] === '')
          this.optionList[item.step - 1] = item.optionList
        })
        res.workflowApprovalCarboncopyList.forEach(item => {
          this.tableData2[item.step - 1].push({
            department: item.department,
            label: item.approvedBy,
            id: item.approvedById,
          })
        })

        this.$nextTick(() => {
          this.onload = true;
        })
      })
    },
    stepClick (index) {
      this.currentStep = index - 1;
    },
    showAdd (type) {
      this.tableType = type;
      switch (this.tableType) {
        case 1:
          this.staffIds = this.tableData1[this.currentStep].map(item => item.id);
          break;
        case 2:
          this.staffIds = this.tableData2[this.currentStep].map(item => item.id);
          break;
      }
      this.DepartmentTreeVisible = true;
    },
    // 人员提交
    staffSubmit (data) {
      switch (this.tableType) {
        case 1:
          this.$set(this.tableData1, this.currentStep, data)
          break;
        case 2:
          this.$set(this.tableData2, this.currentStep, data)
          break;
      }
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          step: this.dialog.step,
          workFlowId: this.dialog.workFlowId,
          workflowApprovalSymbolList: [],
          workflowApprovalCarboncopyList: [],
        }
        this.tableData1.forEach((data, index) => {
          data.forEach(item => {
            param.workflowApprovalSymbolList.push({
              approvalSymbol: this.signData[index],
              step: index + 1,
              department: item.department,
              approvedBy: item.label,
              approvedById: item.id,
              status: this.checkType[index],
              optionList: this.optionList[index],
            })
          })
        })

        this.tableData2.forEach((data, index) => {
          data.forEach(item => {
            param.workflowApprovalCarboncopyList.push({
              step: index + 1,
              department: item.department,
              approvedBy: item.label,
              approvedById: item.id,
            })
          })
        })

        let msg = this.$i18n.t('message.add');
        if (this.id) {
          param.id = this.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddApprovalWorkflow(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    optionEdit () {
      this.optionIds = this.optionList[this.currentStep].map(item => item.id);
      this.OptionDialogVisible = true;
    },
    optionSubmit (data) {
      this.optionList[this.currentStep] = data;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.table-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .el-select,
  .el-input {
    width: 270px;
  }
}
.el-table {
  margin-bottom: 10px;
}

.step-box {
  width: 100%;
  overflow: auto;
  margin-bottom: 10px;
  white-space: nowrap;
  .step-btn {
    width: auto;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    border: 1px solid #dcdfe6;
    border-left: none;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    word-wrap: nowrap;
    &:first-child {
      border: 1px solid #dcdfe6;
    }
    &.active,
    &:hover {
      color: #fff;
      background: #29e0bb;
    }
  }
}
</style>