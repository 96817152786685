<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('deviceDetailsList.title1')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
      </page-header>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          ref="table"
          :height="tableHeight"
          v-loading="tableLoading"
          @row-dblclick="rowClick"
        >
          <el-table-column
            min-width="220"
            sortable
            prop="phoneNumber"
            :label="$t('deviceDetailsList.col1')"
          >
            <template slot-scope="scope">
              {{scope.row.outId === null ? '入库' : '出库'}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="createTime"
            :label="$t('deviceDetailsList.col2')"
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="serialNumbers"
            :label="$t('deviceDetailsList.col3')"
          >
            <template slot-scope="scope">
              {{ [scope.row.serialNumbers,scope.row.serialNumbers3].join(';').replace(/^;|;$/g,'') }}
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            prop="phoneNumber"
            :label="$t('deviceDetailsList.col4')"
          >
            <template slot-scope="scope">
              {{scope.row.outId === null ? scope.row.deviceCount + scope.row.outCount : scope.row.outCount }}
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            prop="deviceUnit"
            :label="$t('deviceDetailsList.col5')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="deviceType"
            :label="$t('deviceDetailsList.col6')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="deviceName"
            :label="$t('deviceDetailsList.col7')"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            sortable
            prop="deviceModel"
            :label="$t('deviceDetailsList.col8')"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            sortable
            prop="projectName"
            :label="$t('deviceDetailsList.col9')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="purchaseUser"
            :label="$t('deviceDetailsList.col10')"
          >
          </el-table-column>

        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import { GetInventoryDeviceList } from '@/api/warehouse/inventoryManagement'
export default {
  name: 'DeviceDetailsList',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableData: [],
      tableHeight: null,
      applicationFormNumber: null,
      projectContractId: null,
    }
  },
  created () {
    this.dealPermission();
    
    this.applicationFormNumber = this.$route.query.applicationFormNumber;
    this.projectContractId = this.$route.query.projectContractId;
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        deviceInfoId: this.$route.query.id,
        applicationFormNumber: this.applicationFormNumber,
        projectContractId: this.projectContractId,
      }
      this.tableLoading = true;
      GetInventoryDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    rowClick (e) {
      if (e.outId) {
        this.$router.push({
          path: '/home/outWarehouseDetails',
          query: {
            id: e.outId,
            applicationFormInfoId: e.applicationFormInfoId,
            type: this.$route.query.type == 1 && this.permit.edit ? 4 : 3
          }
        })
      } else {
        this.$router.push({ path: '/home/inWarehouseDetails', query: { id: e.id, type: this.$route.query.type == 1 && this.permit.edit ? 3 : 2 } })
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.edit = permission.includes('InventoryManagementEdit');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 36px - 20px - 36px - 10px - 32px - 10px);
}
</style>