<template>
  <div>
    <!-- 现场维修设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item :label="$t('newServiceConfirmationForm.dialog5.label1')">
          <div class="label-wrap">
            <div class="add-box">
              <el-button
                icon="el-icon-plus"
                @click="ServiceContentVisible = true"
              ></el-button>
            </div>
            <div class="label-box">
              <el-tag
                v-for="(item,index) in tags"
                :key="index"
                closable
                @close="tagClose(item)"
              >
                {{item.name}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog5.label2')"
          prop="faultDescription"
        >
          <el-input
            type="textarea"
            v-model="dialog.faultDescription"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog5.label3')"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dialog.remark"
            :rows="3"
          ></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label4')"
              prop="deviceInfoName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.deviceInfoName"
                  class="search-input"
                  @change="deviceChange"
                ></el-input>
                <el-button @click="searchData">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label5')"
              prop="location"
            >
              <my-select
                :data="locations"
                :value.sync="dialog.location"
                size="small"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="deviceChange"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label6')"
              prop="deviceInfoModel"
            >
              <el-input
                v-model="dialog.deviceInfoModel"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label7')"
              prop="serialNumber"
            >
              <el-input
                v-model="dialog.serialNumber"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label8')"
              prop="qrCode"
            >
              <el-input
                v-model="dialog.qrCode"
                @change="qrCodeChange"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label9')"
              prop="label"
            >
              <el-input
                v-model="dialog.label"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label10')"
              prop="attributable"
            >
              <el-select v-model="dialog.attributable">
                <el-option
                  :value="0"
                  :label="$t('newServiceConfirmationForm.dialog5.option1')"
                ></el-option>
                <el-option
                  :value="1"
                  :label="$t('newServiceConfirmationForm.dialog5.option2')"
                ></el-option>
                <el-option
                  :value="2"
                  :label="$t('newServiceConfirmationForm.dialog5.option3')"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label11')"
              prop="serviceTypeName"
            >
              {{dialog.serviceTypeName}}
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog5.label12')"
              prop="systemTypeName"
            >
              {{dialog.systemTypeName}}
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          @click="copySubmit"
        >{{$t('newServiceConfirmationForm.dialog5.btn1')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :branch-id="branchId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <!--  -->
    <service-content
      :visible.sync="ServiceContentVisible"
      :tags-data="tags"
      :describe="describe"
      :dispose="dispose"
      @submit="serviceSubmit"
    ></service-content>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 组件
import EquipmentDialog from './EquipmentDialog'
import ServiceContent from './ServiceContent'
import MySelect from '@/components/MySelect'

// 接口
import { GetConfigTypeList } from '@/api/warehouse/dataMaintenance/systemType'
import { GetLocationAll } from '@/api/warehouse/deviceManagement'

export default {
  components: {
    EquipmentDialog,
    ServiceContent,
    MySelect,
  },
  props: ['visible', 'data', 'describe', 'dispose', 'branchId'],
  data () {
    return {
      EquipmentDialogVisible: false,
      ServiceContentVisible: false,
      tags: [],
      faultTypes: [],
      locations: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        attributable: 0,
        serviceType: '',
        systemType: '',
        faultDescription: '',
        remark: '',
        servicesName: '',
        serviceTypeName: '',
        systemTypeName: '',
        qrCode: '',
        deviceInfoName: '',
        deviceTypeName: '',
        deviceInfoModel: '',
        location: '',
        serialNumber: '',
        deviceInfoId: '',
        label: '',
      },
      rules: {
        // deviceInfoName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      faultTypeName: '',
    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.tags = [];
        if (this.data) {
          this.$nextTick(() => {
            this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog5.title2') + ` - ${this.data.rowIndex + 1}`;
            Object.assign(this.dialog, this.data)
            this.dialog.isQRcode = !!this.data.maintenanceDeviceId
            this.getFaultNameById(this.data.servicesId);
          })
          if (this.data.isDisabled === undefined) this.dialog.isDisabled = true;
        } else {
          this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog5.title1');
          this.dialog.isDisabled = false;
          this.dialog.faultDescription = sessionStorage.faultDescription;
          this.dialog.remark = sessionStorage.handleMethod;
        }
        // 获取故障类型
        if (this.faultTypes.length === 0) this.getFaultLabel();
        // 获取设备位置
        GetLocationAll({ branchId: this.branchId }).then(res => this.locations = res.map(item => Object.assign({ name: item })));

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'tags': function (newVal) {
      let serviceType = [];
      let systemType = [];
      let services = [];
      newVal.forEach(item => {
        serviceType.push(item.serviceType);
        systemType.push(item.systemType);
        services.push(item.name);
      })
      this.dialog.serviceTypeName = Array.from(new Set(serviceType)).join(' ')
      this.dialog.systemTypeName = Array.from(new Set(systemType)).join(' ')
      this.dialog.servicesName = services.join(' ')
    },
    'faultTypes': function (newVal) {
      if (!!this.data) this.getFaultNameById(this.data.servicesId);
    },
  },
  created () {
  },
  methods: {
    searchData () {
      this.EquipmentDialogVisible = true;
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.dialog, {
        qrCode: data.qrCode,
        deviceInfoName: data.deviceInfoName,
        deviceTypeName: data.deviceTypeName,
        deviceInfoModel: data.deviceInfoModel,
        location: data.location,
        serialNumber: data.serialNumber,
        deviceInfoId: data.id,
        label: data.label,
      })
      this.$refs.dialogForm.validateField('deviceInfoName');
    },
    getFaultLabel () {
      let param = {
        limit: 9999,
        page: 1,
        search: '',
      }
      GetConfigTypeList(param).then(res => {
        this.faultTypes = res.list;
      })
    },
    // 提交
    submit (param) {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        this.dialog.servicesId = this.tags.map(item => item.id ? item.id : item.name).join(',');
        this.$emit('submit', {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? 'edit' : 'add',
          copy: param.copy
        });

        sessionStorage.faultDescription = this.dialog.faultDescription;
        sessionStorage.handleMethod = this.dialog.remark;
        this.dialog.visible = false;
      });
    },
    // 复制
    copySubmit () {
      this.submit({ copy: true })
    },
    // 获取故障名称
    getFaultNameById (ids) {
      if (ids === '') return;
      let len = ids.split(',').length;

      this.faultTypes.some(item => {
        if (ids.includes(item.id)) {
          this.tags.push({
            id: item.id,
            name: item.name,
            serviceType: item.serviceTypeName,
            systemType: item.systemTypeName,
          })
          return this.tags.length === len;
        }
      })
    },

    // 关闭故障标签
    tagClose (tag) {
      this.tags.splice(this.tags.findIndex(item => item.id === tag.id), 1);
    },
    // 修改设备信息，清空设备id 
    deviceChange () {
      this.dialog.deviceInfoId = '';
      this.dialog.qrCode = '';
    },
    qrCodeChange () {
      this.dialog.deviceInfoId = '';
    },
    serviceSubmit (data) {
      this.tags = data;
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .add-box {
    width: 100px;
  }
  .label-wrap {
    display: flex;
  }
}
.el-tag {
  margin: 0 5px 5px;
  cursor: pointer;
}
.footer-box {
  text-align: center;
}
.search-box {
  .label-item {
    height: 32px;
    line-height: 32px;
  }
  .el-input {
    margin-bottom: 10px;
  }
  .tag {
    background-color: #ffffff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #606266;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
    &.active {
      color: #409eff;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
    }
  }
}
.add-box {
  .label-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      width: 120px;
      padding-right: 12px;
      text-align: right;
      display: inline-block;
    }
  }
}
</style>