<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dataMaintenance.deviceTypeDialog.title')"
      :visible.sync="dialogVisible"
      center
      width="500px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            :label="$t('dataMaintenance.deviceTypeDialog.col1')"
            prop="name"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialogLoading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetUnboundList,
  AddUnbound,
} from '@/api/warehouse/dataMaintenance'
export default {
  props: ['visible', 'item', 'type'],
  data () {
    return {
      dialogVisible: false,
      dialogLoading: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.searchData();

      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        mainField: this.item.id,
        parent: '100000000000000000000000000000000001',
      }
      this.tableLoading = true;
      GetUnboundList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      if (this.type === 'FaultType') {
        this.$emit('submit', this.tableSelected);
        this.dialogLoading = false;
        this.dialogVisible = false;
      } else {
        let param = {
          mainField: this.item.id,
          id: this.tableSelected.map(item => item.id).join(','),
        }
        this.dialogLoading = true;
        AddUnbound(param).then(res => {
          if (res.success) {
            this.dialogLoading = false;
            this.dialogVisible = false;
            this.$emit('update');
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}
</style>