<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="700px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="user-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="faultTypeId"
              :label="$t('dataMaintenance.faultType.dialog1.label1')"
            >
              <el-select
                v-model="dialog.faultTypeId"
                filterable
              >
                <el-option
                  v-for="item in faultTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="subType"
              :label="$t('dataMaintenance.faultType.dialog1.label2')"
            >
              <el-input
                v-model="dialog.subType"
                @change="subTypeChange"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('dataMaintenance.faultType.dialog1.label3')">
          <el-button
            :disabled="!dialog.faultTypeId"
            @click="showAdd"
          > {{$t('operation.edit')}} </el-button>
          <el-button @click="delItem"> {{$t('operation.del')}} </el-button>
          <el-button @click="viewAll"> {{$t('dataMaintenance.faultType.dialog1.btn1')}} </el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-table
          border
          :data="tableData"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            prop="deviceName"
            :label="$t('dataMaintenance.faultType.dialog1.col1')"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            prop="description"
            :label="$t('dataMaintenance.faultType.dialog1.col1')"
          >
            <template slot-scope="scope">
              <div class="update-box">
                <el-input
                  size="mini"
                  v-model="scope.row.description"
                ></el-input>
                <i
                  class="el-icon-refresh"
                  @click="updateKey(scope.$index)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="indexKeyword"
            :label="$t('dataMaintenance.faultType.dialog1.col3')"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.indexKeyword"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            min-width="70"
            prop="heat"
            :label="$t('dataMaintenance.faultType.dialog1.col4')"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.heat"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <!-- 设备弹框 -->
    <add-device-type
      :visible.sync="AddDeviceTypeVisible"
      :fault-type-id="dialog.faultTypeId"
      :sub-type="dialog.subType"
      :sub-type-id="dialog.subTypeId"
      :ids="ids"
      @submit="deviceSubmit"
    ></add-device-type>
  </div>
</template>

<script>
// 组件
import AddDeviceType from './AddDeviceType'
// 接口
import {
  AddFaultTypeItem,
  DelFaultDevice,
  GetFaultTypeItem,
} from '@/api/warehouse/dataMaintenance/faultType'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    AddDeviceType
  },
  props: ['visible', 'data', 'total', 'items'],
  data () {
    return {
      AddDeviceTypeVisible: false,
      faultTypes: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        faultTypeId: '',
        subType: '',
        subTypeId: null,
        issId: null,
      },
      rules: {
        faultTypeId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        subType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      ids: [],
    }
  },
  computed: {

  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('dataMaintenance.faultType.dialog1.title2'),
              faultTypeId: this.data.faultTypeId,
              subType: this.data.subType,
              subTypeId: this.data.subTypeId,
              issId: this.data.issId,
            });
            this.tableData = [this.data]
          })
        } else {
          this.tableData = [];
          this.dialog.subTypeId = null;
          this.dialog.title = this.$i18n.t('dataMaintenance.faultType.dialog1.title1');
          if (this.items.length > 0) this.dialog.faultTypeId = this.items[0].id;
        }

        // 获取故障类型
        GetDataFieldAll({ parent: '100000000000000000000000000000000005' }).then(res => this.faultTypes = res)

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 显示设备类型弹框
    showAdd () {
      this.ids = this.tableData.map(item => item.deviceTypeId)
      this.AddDeviceTypeVisible = true;
    },
    // 解除设备类型绑定
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      let checkIndex = this.tableSelected.map(item => item.rowIndex);
      this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
    },
    // 设备类型提交
    deviceSubmit (data) {
      let ids = this.tableData.map(item => item.deviceTypeId);
      let tdata = data.filter(item => !ids.includes(item.id)).map(item => {
        return {
          id: null,
          deviceTypeId: item.id,
          deviceName: item.name,
          description: item.name + ' ' + this.dialog.subType,
          indexKeyword: '',
          heat: '',
        }
      })

      this.tableData = this.tableData.concat(tdata);
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = this.tableData.map(item => {
          let data = {
            id: item.id,
            faultTypeId: this.dialog.faultTypeId,
            subType: this.dialog.subType,
            deviceTypeId: item.deviceTypeId,
            deviceName: item.deviceName,
            description: item.description,
            indexKeyword: item.indexKeyword,
            heat: item.heat,
            // subTypeId: this.dialog.subTypeId,
            issId: item.issId,
          }
          return data;
        });

        this.dialog.loading = true;
        AddFaultTypeItem(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.add'))
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            this.$message.success(res.data)
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    subTypeChange () {
      this.tableData.forEach(item => item.description = item.deviceName + ' ' + this.dialog.subType)
    },
    // 更新key
    updateKey (index) {
      let item = this.tableData[index];
      item.description = item.deviceName + ' ' + this.dialog.subType;
      this.$set(this.tableData, index, item);
    },
    viewAll () {
      let param = {
        faultTypeId: this.dialog.faultTypeId,
        subType: this.dialog.subType,
        subTypeId: this.dialog.subTypeId,
      }
      GetFaultTypeItem(param).then(res => {
        this.tableData = res;
      })
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    width: 150px;
    text-align: left;
  }
  .el-switch {
    width: 100px;
  }
}

.update-box {
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
    cursor: pointer;
    font-size: 18px;
  }
}
</style>