<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">Equals</div>
      <div class="range-box">
        <el-time-picker
          :disabled="type === 0"
          class="range-input"
          v-model="dateRange[0]"
          size="small"
          value-format="HH:mm"
        >
        </el-time-picker>
        <span class="range-line"></span>
        <el-time-picker
          :disabled="type === 1"
          class="range-input"
          v-model="dateRange[1]"
          size="small"
          value-format="HH:mm"
        >
        </el-time-picker>
      </div>
      <div class="option-box">
        <div
          v-for="(item,index) in dateCondition"
          :key="index"
          class="date-item"
          :class="{'active':index == type}"
          @click="selectType(index)"
        >{{item}}</div>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <el-col
          :offset="8"
          :span="8"
        >
          <el-button
            size="small"
            @click="showQuery"
          >Detail</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  props: ['visible', 'zindex', 'data'],
  data () {
    return {
      dateCondition: ['Before Specific Time', 'After Specific Time', 'Between Specific Time'],
      type: 0,
      dateRange: [],
      title: '',
    }
  },

  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      this.title = this.data.data.field;
      this.type = this.data.data.operator;
      this.dateRange = JSON.parse(JSON.stringify(this.data.data.dateRange));
      this.selectType(this.type)
    },
    dialogClose () {
      this.$emit('update:visible', false);
    },
    selectType (index) {
      this.type = index;
      switch (this.type) {
        case 0:
          this.$set(this.dateRange, 0, '00:00')
          break;
        case 1:
          this.$set(this.dateRange, 1, '23:59')
          break;
        case 2:
          break;
      }
    },
    submit () {
      if (!this.dateRange[0] || !this.dateRange[1]) return;
      let label = this.dateRange[0] + ' - ' + this.dateRange[1];

      this.$emit('submit', Object.assign(this.data, {
        con: label,
        data: Object.assign(this.data.data, {
          condition: '',
          dateRange: this.dateRange,
          operator: this.type,
        })
      }));
      this.dialogClose();
    },
    reset () {
      this.type = 8;
      this.dateRange = [];
    },
    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    showQuery () {
      this.$emit('showQuery');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
.range-box {
  width: 100%;
  display: flex;
  align-items: center;
  .range-line {
    width: 15px;
    border-bottom: 1px solid #000;
    margin: 0 5px;
  }
}
</style>