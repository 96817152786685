import request from '@/utils/request'

// 查询设备类型列表
export const GetDeviceTypeList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改设备类型
export const AddDeviceType = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除设备类型
export const DelDeviceType = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/del.do',
  data: JSON.stringify(data)
})

// 查询设备列表
export const GetDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改设备
export const AddDevice = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除设备
export const DelDevice = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/del.do',
  data: JSON.stringify(data)
})

// 设备类型集合
export const GetDeviceAll = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryByCondition.do',
  data: JSON.stringify(data)
})

// 获取设备名称集合
export const GetDeviceNameAll = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/queryGroupName.do',
  data: JSON.stringify(data)
})

// 获取设备型号集合
export const GetDeviceModelAll = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/queryGroupModel.do',
  data: JSON.stringify(data)
})

// 获取设备品牌集合
export const GetDeviceBrandAll = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/queryGroupBrand.do',
  data: JSON.stringify(data)
})

// 获取设备类型集合
export const GetDeviceTypeAll = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/queryGroupType.do',
  data: JSON.stringify(data)
})

// 导入项目合同
export const ImportProject = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/importProject.do',
  data: data
})

// 导入项目客户名称
export const importCustomer = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/importCustomer.do',
  data: data
})
// 导入出入库信息
export const ImportOutInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/importExcl.do',
  data: data
})

// 替换
export const ItemTypeReplace = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceInfo/replaceData.do',
  data: JSON.stringify(data)
})
 