import request from '@/utils/request'

// 查询收货地址列表
export const GetReceiptAddressList = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddress/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改收货地址
export const AddReceiptAddress = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddress/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除收货地址
export const DelReceiptAddress = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddress/del.do',
  data: JSON.stringify(data)
})

// 查询收货地址联系人列表
export const GetReceiptContactList = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddressContact/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改收货地址联系人
export const AddReceiptContact = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddressContact/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除收货地址联系人
export const DelReceiptContact = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddressContact/del.do',
  data: JSON.stringify(data)
})

// 获取收货地址集合
export const GetReceiptAddressAll = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddress/queryByCondition.do',
  data: JSON.stringify(data)
})

// 获取收货地址联系人
export const GetReceiptContactAll = (data) => request({
  method: 'post',
  url: '/WarehouseReceiverAddressContact/queryByCondition.do',
  data: JSON.stringify(data)
})

// 根据联系人查地址
export const GetAddressByContact = (data) => request({
  method: 'post',
  url: '/DataField/queryAddress.do',
  data: JSON.stringify(data)
})
