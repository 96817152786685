<template>
  <div class="wrap">
    <div class="top-box">
      <div class="left">
        <i
          class="el-icon-d-arrow-left"
          @click="back"
        ></i>
        <span>PDF Template Designer</span>
        <span
          v-show="!!currentPdf.name"
          class="pdf-name"
        >{{currentPdf.name}}</span>
      </div>
      <div class="right">
        <div class="size-box">
          <i
            class="el-icon-zoom-out"
            @click="subtractScale"
          ></i>
          <span class="size-number">{{scale}}%</span>
          <i
            class="el-icon-zoom-in"
            @click="addScale"
          ></i>
        </div>
        <div class="direction-box">
          <i class="el-icon-top-left"></i>
          <i class="el-icon-top-right"></i>
        </div>
        <el-button
          size="small"
          @click="topSavePdf"
        >Save</el-button>
        <el-button
          size="small"
          @click="back"
        >Close</el-button>
      </div>
    </div>
    <div class="main-box">
      <div
        class="main"
        @contextmenu.prevent
      >
        <div
          class="pdf-wrap"
          ref="pdfWrap"
          :style="`transform: scale(${scale / 100})`"
        >
          <div
            class="page"
            v-for="(page,pageIndex) in pdfData.page"
            :key="pageIndex"
            :style="{
              width: pdfData.width + 'px',
              height: pdfData.height + 'px',
              'padding-top':Math.round(pdfData.paddingTop * pdfData.rate) + 'px',
              'padding-bottom':Math.round(pdfData.paddingBottom * pdfData.rate) + 'px',
              'padding-left':Math.round(pdfData.paddingLeft * pdfData.rate) + 'px',
              'padding-right':Math.round(pdfData.paddingRight * pdfData.rate) + 'px',
            }"
            @click="pageClick(pageIndex)"
            @mousedown.self="cancelPage"
          >
            <a :id="'page' + pageIndex"></a>

            <!--  -->
            <div class="page-content">
              <!-- 页眉 -->
              <div
                v-if="pdfData.header.show"
                class="header "
                :class="{
                  'active':pdfData.header.active,
                }"
                :style="{
                  height: pdfData.header.height + 'px',
                }"
                @click.stop="headerClick"
              >
                <div
                  class="content"
                  @click.self="cancelHeader"
                >
                  <div
                    v-show="!el.hide"
                    v-for="(el,elIndex) in pdfData.header.elements"
                    :key="elIndex"
                    class="el-box"
                    :class="{
                      'active-move':el.active,
                    }"
                    :style="{
                      left :el.style.left + 'px',
                      top :el.style.top + 'px',
                      width :el.style.width + 'px',
                      height :el.style.height + 'px',
                    }"
                  >
                    <component
                      :is="el.name"
                      :el-data="el"
                      :index="pageIndex"
                      @click.native="elClick(el,1)"
                      @dblclick.native="elDblclick(el)"
                      @mousedown.native="elMove($event,el)"
                    ></component>
                    <div
                      v-if="el.active"
                      class="side"
                      @mousedown.stop="elResize($event,el)"
                    ></div>
                    <div
                      v-if="el.active"
                      class="close el-icon-close"
                      @click="elClose({
                        elIndex:elIndex
                      })"
                    ></div>
                  </div>
                </div>
              </div>
              <!-- 内容 -->
              <div
                class="content"
                @mousedown.self="cancelPage"
              >
                <div
                  v-show="!el.hide"
                  v-for="(el,elIndex) in page.elements"
                  :key="elIndex"
                  class="el-box"
                  :class="{
                'active-move':el.active,
              }"
                  :style="{
                left :el.style.left + 'px',
                top :el.style.top + 'px',
                width :el.style.width + 'px',
                height :el.style.height + 'px',
              }"
                >
                  <component
                    :is="el.name"
                    :el-data="el"
                    :index="pageIndex"
                    @click.native.stop="elClick(el,0)"
                    @dblclick.native="elDblclick(el)"
                    @mousedown.native="elMousedown($event,el)"
                  ></component>
                  <div
                    v-if="el.active"
                    class="side"
                    @mousedown.stop="elResize($event,el)"
                  ></div>
                  <div
                    v-if="el.active"
                    class="close el-icon-close"
                    @click="elClose({
                      index: pageIndex,
                      elIndex: elIndex,
                    })"
                  ></div>
                </div>
              </div>
              <!-- 页脚 -->
              <div
                v-if="pdfData.footer.show"
                class="footer"
                :class="{
                  'active':pdfData.footer.active,
                }"
                :style="{
                  height :pdfData.footer.height + 'px',
                }"
                @click.stop="footerClick"
              >
                <div
                  class="content"
                  @click.self="cancelFooter"
                >
                  <div
                    v-show="!el.hide"
                    v-for="(el,elIndex) in pdfData.footer.elements"
                    :key="elIndex"
                    class="el-box"
                    :class="{
                    'active-move':el.active,
                  }"
                    :style="{
                    left :el.style.left + 'px',
                    top :el.style.top + 'px',
                    width :el.style.width + 'px',
                    height :el.style.height + 'px',
                  }"
                  >
                    <component
                      :is="el.name"
                      :el-data="el"
                      :index="pageIndex"
                      @click.native="elClick(el,1)"
                      @dblclick.native="elDblclick(el)"
                      @mousedown.native="elMove($event,el)"
                    ></component>
                    <div
                      v-if="el.active"
                      class="side"
                      @mousedown.stop="elResize($event,el)"
                    ></div>
                    <div
                      v-if="el.active"
                      class="close el-icon-close"
                      @click="elClose({
                        elIndex:elIndex
                      })"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                v-show="pdfData.pageIndex.show"
                class="page-index"
                :style="{
                  'text-align':pdfData.pageIndex.style.textAlign,
                }"
              >{{pageIndex + 1}}/{{pdfData.page.length}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <el-tabs
          type="border-card"
          v-model="menuTabs"
        >
          <el-tab-pane
            label="File"
            name="file"
          >
            <div
              class="btn-item option-btn"
              @click="addPdf"
            >
              <i class="el-icon-document-add"></i> New
            </div>
            <div
              class="btn-item option-btn"
              @click="savePdf"
            >
              <i class="el-icon-document-checked"></i> Save
            </div>
            <div
              class="btn-item option-btn"
              @click="saveAsPdf"
            >
              <i class="el-icon-document-checked"></i> Save as
            </div>
            <div
              class="btn-item option-btn"
              @click="printPdf"
            >
              <i class="el-icon-printer"></i> Print
            </div>
            <hr>
            <el-button-group>
              <el-button
                icon="el-icon-edit"
                size="small"
                @click="editPdf"
              >Edit</el-button>
              <el-button
                icon="el-icon-delete"
                size="small"
                @click="delPdf"
              >Delete</el-button>
              <el-button
                icon="el-icon-edit-outline"
                size="small"
                @click="renamePdf"
              >Rename</el-button>
            </el-button-group>
            <div class="pdf-list-box">
              <div
                class="btn-item user-select-none"
                v-for="(item,index) in pdfList"
                :key="index"
                @click="pdfTemplageClick(item)"
                @dblclick="pdfTemplageDblclick(item)"
                :class="{
                  'active':selectPdf.id === item.id,
                }"
              >
                <span class="template-name">
                  {{item.name}}
                </span>
                <i
                  v-if="currentPdf.name === item.name"
                  class="el-icon-circle-check current-icon"
                ></i>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Layout"
            name="layout"
          >
            <div class="btn-item option-btn">
              Header&Footer
            </div>
            <div
              class="btn-item option-btn flex-space-between retract"
              :class="{'active':styleBoxName === 'HeaderStyle'}"
              @click.stop="showStyleBox('HeaderStyle')"
            >
              Header
              <el-switch
                v-model="pdfData.header.show"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="switchHeader"
                @click.native.stop
              ></el-switch>
            </div>
            <div
              class="btn-item option-btn flex-space-between retract"
              :class="{'active':styleBoxName === 'FooterStyle'}"
              @click.stop="showStyleBox('FooterStyle')"
            >
              Footer
              <el-switch
                v-model="pdfData.footer.show"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="switchFooter"
                @click.native.stop
              ></el-switch>
            </div>
            <div
              class="btn-item option-btn flex-space-between retract"
              :class="{'active':styleBoxName === 'PageNumberStyle'}"
              @click.stop="showStyleBox('PageNumberStyle')"
            >
              PageNumber
              <el-switch
                v-model="pdfData.pageIndex.show"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @click.native.stop
              ></el-switch>
            </div>
            <div
              class="btn-item option-btn"
              :class="{'active':styleBoxName === 'MarginStyle'}"
              @click.stop="showStyleBox('MarginStyle')"
            >
              Margins
            </div>
            <div
              class="btn-item option-btn"
              :class="{'active':styleBoxName === 'PageOrientationStyle'}"
              @click.stop="showStyleBox('PageOrientationStyle')"
            >
              Page Orientation
            </div>
            <el-divider content-position="left">Page Settings</el-divider>
            <el-button-group>
              <el-tooltip
                effect="dark"
                content="新增"
                placement="bottom-start"
              >
                <el-button
                  size="small"
                  icon="el-icon-plus"
                  @click="addPage"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="复制"
                placement="bottom-start"
              >
                <el-button
                  size="small"
                  icon="el-icon-document-copy"
                  @click="copyPage"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="删除"
                placement="bottom-start"
              >
                <el-button
                  size="small"
                  icon="el-icon-delete"
                  @click="delPage"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="更新缩略图"
                placement="bottom-start"
              >
                <el-button
                  :loading="drawLoading"
                  size="small"
                  icon="el-icon-refresh"
                  @click="drawPage"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="上移"
                placement="bottom-start"
              >
                <el-button
                  size="small"
                  icon="el-icon-top"
                  @click="movePage(0)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="下移"
                placement="bottom-start"
              >
                <el-button
                  size="small"
                  icon="el-icon-bottom"
                  @click="movePage(1)"
                ></el-button>
              </el-tooltip>

            </el-button-group>
            <div class="page-box">
              <div
                v-for="(page,index) in pdfData.page"
                :key="index"
                class="page-thumb"
                :class="{
                'col': pdfData.direction === 0,
                'row': pdfData.direction === 1,
                'active': index === currentPage,
                }"
                @click="setCurrentPage(index)"
              >
                <a @click="anchorPoint('#page' + index)">
                  <img
                    v-if="!!page.img"
                    :src="page.img"
                  >
                </a>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Elements"
            name="elements"
          >
            <el-tabs v-model="elementTabs">
              <el-tab-pane
                label="Default"
                name="default"
              >
                <div class="tree-box">
                  <el-tree
                    :data="treeData"
                    :props="{
                      children: 'children',
                      label: 'label'
                    }"
                    :expand-on-click-node="false"
                    @node-click="nodeClick"
                  ></el-tree>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="Selected"
                name="selected"
              >
                <el-button-group>
                  <el-button
                    size="small"
                    @click="addElement({type:'text'})"
                  >T</el-button>
                  <el-button
                    size="small"
                    icon="el-icon-picture-outline"
                    @click="addElement({type:'img'})"
                  ></el-button>
                  <el-button
                    size="small"
                    icon="el-icon-document-copy"
                    @click="copyBatchElement"
                  ></el-button>
                  <el-button
                    size="small"
                    icon="el-icon-delete"
                    @click="delBatchElement"
                  ></el-button>
                </el-button-group>
                <div class="element-box">
                  <div v-show="pdfData.header.show">
                    <div
                      class="btn-item"
                      :class="{'active':pdfData.header.active}"
                      @click="headerClick"
                    >
                      <!-- @click="changeHeader" -->
                      <i
                        class=" tree-arrow"
                        :class="{
                        'el-icon-caret-bottom':pdfData.header.expand,
                        'el-icon-caret-right':!pdfData.header.expand,
                      }"
                        @click.stop="headerTreeClick"
                      ></i>
                      Header
                    </div>
                    <div
                      v-show="pdfData.header.expand"
                      v-for="(el,elIndex) in pdfData.header.elements"
                      :key="'header' + elIndex"
                      class="btn-item tree-retract"
                      :class="{'active':el.active}"
                      @click="elBtnClick(el)"
                    >{{elName[el.name]}}
                      <span v-if="!!el.content && el.name === 'TextLabel'">- {{el.content}}</span>
                      <i
                        class="el-icon-circle-check switch-btn"
                        :class="{ 'hide': el.hide }"
                        @click.stop="elSwitch({
                          type:'header',
                          elIndex:elIndex,
                        })"
                      ></i>
                    </div>
                  </div>
                  <div
                    v-for="(page,pageIndex) in pdfData.page"
                    :key="pageIndex"
                  >
                    <div
                      class="btn-item"
                      :class="{'active':pageIndex === currentPage}"
                      @click="pageSwitch(pageIndex)"
                    >
                      <i
                        class=" tree-arrow"
                        :class="{
                          'el-icon-caret-bottom':page.expand,
                          'el-icon-caret-right':!page.expand,
                        }"
                        @click.stop="pageTreeClick(page,pageIndex)"
                      ></i>
                      Page {{pageIndex + 1}}
                    </div>
                    <div
                      v-show="page.expand"
                      v-for="(el,elIndex) in page.elements"
                      :key="elIndex"
                      class="btn-item tree-retract"
                      :class="{'active':el.active}"
                      @click="elBtnClick(el)"
                    >
                      <div class="text">
                        {{elName[el.name]}}
                        <span v-if="!!el.content && el.name === 'TextLabel'">- {{el.content}}</span>
                      </div>
                      <i
                        class="el-icon-circle-check switch-btn"
                        :class="{ 'hide': el.hide }"
                        @click.stop="elSwitch({
                          type:'page',
                          pageIndex:pageIndex,
                          elIndex:elIndex,
                        })"
                      ></i>
                    </div>
                  </div>
                  <div v-show="pdfData.footer.show">
                    <div
                      class="btn-item"
                      :class="{'active':pdfData.footer.active}"
                      @click="footerClick"
                    >
                      <i
                        class=" tree-arrow"
                        :class="{
                        'el-icon-caret-bottom':pdfData.footer.expand,
                        'el-icon-caret-right':!pdfData.footer.expand,
                      }"
                        @click.stop="footerTreeClick"
                      ></i>
                      Footer
                    </div>
                    <div
                      v-show="pdfData.footer.expand"
                      v-for="(el,elIndex) in pdfData.footer.elements"
                      :key="'footer' + elIndex"
                      class="btn-item tree-retract"
                      :class="{'active':el.active}"
                      @click="elBtnClick(el)"
                    >{{elName[el.name]}}
                      <span v-if="!!el.content && el.name === 'TextLabel'">- {{el.content}}</span>
                      <i
                        class="el-icon-circle-check switch-btn"
                        :class="{ 'hide': el.hide }"
                        @click.stop="elSwitch({
                          type:'footer',
                          elIndex:elIndex,
                        })"
                      ></i>
                    </div>
                  </div>
                </div>
                <el-divider content-position="left">Parameters</el-divider>
                <component
                  v-if="styleName !== null"
                  :is="styleName"
                  :el-data="styleData"
                  @trHeightChange="moveTable"
                ></component>
                <hr>
                <el-button-group class="page-btn-box">
                  <el-tooltip
                    effect="dark"
                    content="上对齐"
                    placement="bottom-start"
                  >
                    <el-button
                      size="small"
                      icon="icon-el-top"
                      @click="elTop"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="水平居中"
                    placement="bottom-start"
                  >
                    <el-button
                      size="small"
                      icon="icon-horizontal-center"
                      @click="elHorizontalCenter"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="下对齐"
                    placement="bottom-start"
                  >
                    <el-button
                      size="small"
                      icon="icon-el-bottom"
                      @click="elBottom"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="左对齐"
                    placement="bottom-start"
                  >
                    <el-button
                      size="small"
                      icon="icon-el-left"
                      @click="elLeft"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="垂直居中"
                    placement="bottom-start"
                  >
                    <el-button
                      size="small"
                      icon="icon-vertical-center"
                      @click="elVerticalCenter"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="右对齐"
                    placement="bottom-start"
                  >
                    <el-button
                      size="small"
                      icon="icon-el-right"
                      @click="elRight"
                    ></el-button>
                  </el-tooltip>
                </el-button-group>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <component
      class="style-box"
      v-if="styleBoxName !== null"
      :is="styleBoxName"
      :el-data="pdfData"
      @click.native.stop
    ></component>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'

// 插件
import domtoimage from 'dom-to-image';
// 组件
import TextLabel from './components/TextLabel'
import TextStyle from './components/TextStyle'
import ImageLabel from './components/ImageLabel'
import ImageStyle from './components/ImageStyle'
import TableLabel from './components/TableLabel'
import TableStyle from './components/TableStyle'
import IndexLabel from './components/IndexLabel'
import IndexStyle from './components/IndexStyle'
import HeaderStyle from './components/HeaderStyle'
import FooterStyle from './components/FooterStyle'
import PageNumberStyle from './components/PageNumberStyle'
import MarginStyle from './components/MarginStyle'
import PageOrientationStyle from './components/PageOrientationStyle'

// 接口
import {
  SavePdfTemplate,
  GetPdfList,
  DelPdfTemplate,
} from '@/api/warehouse/pdf'
import {
  GetTableElementAll,
  GetCheckList,
} from '@/api/warehouse/maintenance'
import { GetConfirmOrderItem } from '@/api/warehouse/serviceConfirmation'
export default {
  name: 'EditPdf',
  components: {
    TextLabel,
    TextStyle,
    ImageLabel,
    ImageStyle,
    TableLabel,
    TableStyle,
    IndexLabel,
    IndexStyle,
    HeaderStyle,
    FooterStyle,
    PageNumberStyle,
    MarginStyle,
    PageOrientationStyle,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      pdfData: {
        width: 794,
        height: 1123,
        direction: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        rate: 3.779,
        header: {
          active: false,
          show: false,
          expand: true,
          height: 100,
          elements: [],
        },
        footer: {
          active: false,
          show: false,
          expand: true,
          height: 100,
          elements: [],
        },
        page: [{
          active: false,
          expand: true,
          style: {},
          elements: []
        }],
        pageIndex: {
          show: false,
          style: {
            textAlign: 'left',
          }
        }
      },
      styleName: null,
      styleData: {},
      styleBoxName: null,
      styleBoxData: {},
      currentPage: 0,
      clock: null,
      drawLoading: false,
      elName: {
        'TextLabel': '文本',
        'ImageLabel': '图片',
        'TableLabel': '表格',
        'IndexLabel': '序号',
      },
      numberStart: 0,
      numberEnd: 999,

      fileId: null,
      viewType: null,
      menuTabs: 'file',
      elementTabs: 'default',
      treeData: [],
      pdfList: [],
      currentPdf: {},
      selectPdf: {},
      scale: 100,
    }
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.viewType = parseInt(this.$route.query.type);
    this.fileId = this.$route.query.fileId;
    this.confirmOrderId = this.$route.query.confirmOrderId;

    this.getTableElement();
    this.getPdfList();

    document.body.addEventListener('click', this.clearStyleBox)
  },
  destroyed () {
    document.body.removeEventListener('click', this.clearStyleBox)
  },
  methods: {
    getTableElement () {
      let param = {
        isRecord: this.viewType,
        maintenanceId: this.fileId,
        fileId: this.fileId,
      }
      GetTableElementAll(param).then(res => {
        this.treeData = res.map(item => {
          item.label = `Title - ${item.title}`;
          item.value = item.title;
          item.children = [{
            label: `Sub Title 1 - ${item.subTitle1}`,
            value: item.subTitle1,
          }, {
            label: `Sub Title 2 - ${item.subTitle2}`,
            value: item.subTitle2,
          }, {
            label: 'Table',
            value: item.tableContentList,
            type: 'table',
            elementId: item.id,
          }]
          return item;
        })

        this.treeData.push({
          label: 'Service Confirmation',
          disabled: true,
          children: [{
            label: '[ Site Name ]',
            value: '[ Site Name ]',
            alt: '[ Site Name ]',
          }, {
            label: '[ Site Address ]',
            value: '[ Site Address ]',
            alt: '[ Site Address ]',
          }, {
            label: '[ Engineer ]',
            value: '[ Engineer ]',
            alt: '[ Engineer ]',
          }, {
            label: '[ Phone Number ]',
            value: '[ Phone Number ]',
            alt: '[ Phone Number ]',
          }, {
            label: '[ Processing Time ]',
            value: '[ Processing Time ]',
            alt: '[ Processing Time ]',
          }, {
            label: '[ Processing Time (Y-M-D) ]',
            value: '[ Processing Time (Y-M-D) ]',
            alt: '[ Processing Time (Y-M-D) ]',
          }, {
            label: '[ Processing Start Time ]',
            value: '[ Processing Start Time ]',
            alt: '[ Processing Start Time ]',
          }, {
            label: '[ Processing End Time ]',
            value: '[ Processing End Time ]',
            alt: '[ Processing End Time ]',
          }, {
            label: '[ Description ]',
            value: '[ Description ]',
            alt: '[ Description ]',
          }, {
            label: '[ Detail Process ]',
            value: '[ Detail Process ]',
            alt: '[ Detail Process ]',
          }, {
            label: '[ Signature ]',
            value: '[ Signature ]',
            alt: '[ Signature ]',
            type: 'img'
          }, {
            label: '[ Client Contact ]',
            value: '[ Client Contact ]',
            alt: '[ Client Contact ]',
          }, {
            label: '[ Jobtitle ]',
            value: '[ Jobtitle ]',
            alt: '[ Jobtitle ]',
          }, {
            label: '[ Staff ID ]',
            value: '[ Staff ID ]',
            alt: '[ Staff ID ]',
          }]
        })

        if (!!this.confirmOrderId) this.getConfirmOrder();
      })
    },
    // 获取pdf模板列表
    getPdfList () {
      let param = { elementId: this.fileId }
      GetPdfList(param).then(res => {
        // this.pdfList = res.filter(item => !!item.isEnable);
        this.pdfList = res;
      })
    },
    // 保存
    savePort (_param) {
      let param = {
        id: _param.id,
        elementId: this.fileId,
        name: _param.name,
        isEnable: this.currentPdf.isEnable,
      }
      if (!_param.template) {
        let template = JSON.parse(JSON.stringify(this.pdfData));
        template.page.forEach(item => {
          item.img = '';
          // item.elements.forEach(item => {
          //   if (item.name === 'TableLabel') {
          //     item.tableData = [];
          //     item.originData = [];
          //   }
          // })
        })
        param.template = JSON.stringify(template);
      } else {
        param.template = _param.template;
      }

      SavePdfTemplate(param).then(res => {
        if (res.success) {
          // this.currentPdf = {};
          // this.selectPdf = {};
          this.getPdfList();
          this.$message.success(this.$i18n.t('message.save'));
        }
      })
    },
    // 新增空白页
    addPage () {
      let data = {
        active: false,
        expand: true,
        style: {},
        elements: []
      }
      if (this.currentPage === null) {
        this.pdfData.page.push(data)
      } else {
        this.pdfData.page.splice(this.currentPage + 1, 0, data);
      }
    },
    // 复制
    copyPage () {
      if (this.currentPage === null) return this.$message.warning('Please select one item.')
      let item = JSON.parse(JSON.stringify(this.pdfData.page[this.currentPage]));
      item.elements = item.elements.map(el => {
        el.active = false;
        return el;
      })
      this.pdfData.page.splice(this.currentPage + 1, 0, item);
    },
    // 删除
    delPage () {
      if (this.currentPage === null) return this.$message.warning('Please select one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.pdfData.page.splice(this.currentPage, 1);
        this.currentPage = null;
      }).catch(() => { });
    },
    // 移动页面
    movePage (type) {
      if (this.currentPage === null) return this.$message.warning('Please select one item.')
      let item;
      if (type === 0 && this.currentPage !== 0) {
        item = this.pdfData.page.splice(this.currentPage, 1);
        this.pdfData.page.splice(this.currentPage - 1, 0, ...item)
        this.currentPage--;
      } else if (type === 1 && this.currentPage !== this.pdfData.page.length - 1) {
        item = this.pdfData.page.splice(this.currentPage, 1);
        this.pdfData.page.splice(this.currentPage + 1, 0, ...item)
        this.currentPage++;
      }
    },
    // 新增元素
    addElement (param) {
      let elItem;
      switch (param.type) {
        case 'text':
          elItem = {
            name: 'TextLabel',
            content: param.value,
            active: false,
            readonly: true,
            hide: false,
            alt: param.alt,
            style: {
              width: 100,
              height: 30,
              fontSize: 12,
              fontFamily: '微软雅黑',
              lineHeight: 30,
              color: '#000000',
              fontWeight: 400,
              fontStyle: 'normal',
              textDecoration: 'none',
              textAlign: 'left',
            }
          }
          break
        case 'img':
          elItem = {
            name: 'ImageLabel',
            content: param.value,
            active: false,
            hide: false,
            alt: param.alt,
            style: {
              width: 100,
              height: 100,
              objectFit: 'contain',
            }
          }
          break;
        case 'table':
          elItem = {
            name: 'TableLabel',
            content: '',
            active: false,
            tableHeader: [],
            tableData: [],
            originData: [],
            colWidth: [],
            checkListData: [],
            id: new Date().getTime(),
            hide: false,
            style: {
              width: Math.round(this.pdfData.width - this.pdfData.paddingLeft * this.pdfData.rate - this.pdfData.paddingRight * this.pdfData.rate),
              height: 500,
              fontSize: 12,
              lineHeight: 20,
              trHeight: 30,
              color: '#000000',
              fontWeight: 400,
              fontStyle: 'normal',
              textDecoration: 'none',
              textAlign: 'center',
            }
          }
          break;
        case 'index':
          elItem = {
            name: 'IndexLabel',
            contentStart: '',
            contentEnd: '',
            active: false,
            hide: false,
            style: {
              width: 100,
              height: 30,
              fontSize: 12,
              lineHeight: 30,
              color: '#000000',
              fontWeight: 400,
              fontStyle: 'normal',
              textDecoration: 'none',
              textAlign: 'left',
            }
          }
          break;
      }
      elItem.style.left = 0;
      elItem.style.top = 0;

      if (this.pdfData.header.active) {
        this.pdfData.header.elements.push(elItem);
      } else if (this.pdfData.footer.active) {
        this.pdfData.footer.elements.push(elItem);
      } else {
        if (this.currentPage === null) return this.$message.warning('Please select one page.')

        if (param.type === 'table') {
          this.initTable(param.value, elItem);
          this.$forceUpdate()
          this.getTableData(param, elItem, (isData) => {
            if (isData) return this.$message.warning('表格没有数据.');

            this.pdfData.page[this.currentPage].elements.push(elItem);
            this.$nextTick(() => {
              this.createTable(elItem);
              this.elClick(elItem);
            })
          })
        } else {
          this.pdfData.page[this.currentPage].elements.push(elItem);
          this.elClick(elItem);
        }
      }


    },
    // 删除元素
    elClose (param) {
      if (this.pdfData.header.active) {
        this.pdfData.header.elements.splice(param.elIndex, 1);
      } else if (this.pdfData.footer.active) {
        this.pdfData.footer.elements.splice(param.elIndex, 1);
      } else {
        let elements = this.pdfData.page[param.index].elements;
        if (elements[param.elIndex].name === 'TableLabel') {
          let tableId = elements[param.elIndex].id;
          this.pdfData.page.forEach(item => {
            let index = item.elements.findIndex(item => item.id === tableId);
            if (index !== -1) item.elements.splice(index, 1);
          })
        } else {
          this.pdfData.page[param.index].elements.splice(param.elIndex, 1);
        }
      }
    },
    // 设置当前页
    setCurrentPage (index) {
      this.currentPage = this.currentPage === index ? null : index
    },
    pageClick (index) {
      this.styleName = null;
      this.styleData = {};
      this.pdfData.header.active = false;
      this.pdfData.footer.active = false;
      this.currentPage = index;
    },
    // 锚点跳转
    anchorPoint (idName) {
      document.querySelector(idName).scrollIntoView(true)
    },
    // 绘制图片
    drawPage () {
      this.drawLoading = true;
      clearTimeout(this.clock)
      this.clock = setTimeout(() => {
        domtoimage.toPng(this.$refs.pdfWrap).then((dataUrl) => {
          let gridArr = Array.from(document.querySelectorAll('.page'));
          let screenshot = new Image();
          screenshot.src = dataUrl;
          screenshot.onload = () => {
            var tnCanvas = document.createElement('canvas');
            var tnCanvasContext = tnCanvas.getContext('2d');
            var bufferCanvas = document.createElement('canvas');
            var bufferContext = bufferCanvas.getContext('2d');
            bufferCanvas.width = screenshot.naturalWidth;
            bufferCanvas.height = screenshot.naturalHeight;
            bufferContext.drawImage(screenshot, 0, 0);
            gridArr.forEach((item, index) => {
              if (item.clientWidth === 0) return;
              tnCanvas.width = item.clientWidth;
              tnCanvas.height = item.clientHeight;
              tnCanvasContext.drawImage(bufferCanvas, item.offsetLeft, item.offsetTop - 100, item.clientWidth, item.clientHeight, 0, 0, item.clientWidth, item.clientHeight);
              let temp = this.pdfData.page[index];
              temp.img = tnCanvas.toDataURL();
              this.$set(this.pdfData.page, index, temp)
            })
            this.drawLoading = false;
          }
        })
      }, 500)
    },
    // 打印
    printPdf () {
      sessionStorage.pdfData = JSON.stringify(this.pdfData);
      let { href } = this.$router.resolve({ path: `/pdf` })
      window.open(href, '_blank')
    },
    // 
    topSavePdf () {
      if (this.currentPdf.id) {
        this.savePdf();
      } else {
        this.addPdf();
      }
    },
    // 保存
    savePdf () {
      if (!this.currentPdf.id) return;

      this.savePort({
        id: this.currentPdf.id,
        name: this.currentPdf.name
      });
    },
    // 新增
    addPdf () {
      this.$prompt('Template Name', 'New Template', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        inputPattern: /.+/,
        inputErrorMessage: this.$i18n.t('message.notNull')
      }).then(({ value }) => {
        this.savePort({
          id: null,
          name: value
        });
      }).catch(() => { });
    },
    // 另存为
    saveAsPdf () {
      this.$prompt('New Template Name', 'Save as New Template', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        inputPattern: /.+/,
        inputErrorMessage: this.$i18n.t('message.notNull')
      }).then(({ value }) => {
        this.savePort({
          id: null,
          name: value
        });
      }).catch(() => { });
    },
    editPdf () {
      if (!this.selectPdf.id) return this.$message.warning('Please select at least one item.')

      this.pdfTemplageDblclick(this.selectPdf);
    },
    delPdf () {
      if (!this.selectPdf.id) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.selectPdf.id
        }
        DelPdfTemplate(param).then(res => {
          if (res.success) {
            this.selectPdf = {};
            this.getPdfList();
            this.$message.success(this.$i18n.t('message.del'))

            if (param.id === this.currentPdf.id) this.currentPdf = {}
          }
        })
      }).catch(() => { });
    },
    // 重命名
    renamePdf () {
      if (!this.selectPdf.id) return this.$message.warning('Please select at least one item.')

      this.$prompt('Template Name', 'Rename Template Name', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        inputPattern: /.+/,
        inputErrorMessage: this.$i18n.t('message.notNull')
      }).then(({ value }) => {
        this.savePort({
          id: this.selectPdf.id,
          name: value,
          template: this.selectPdf.template,
        })
      }).catch(() => { });
    },
    // 
    elBtnClick (el) {
      this.elClick(el);

      // el.active = !el.active;
      // if (el.active) {
      //   this.elClick(el);
      // } else {
      //   this.styleName = null;
      //   this.styleData = {};
      // }
    },
    // 批量复制
    copyBatchElement () {
      this.pdfData.page.forEach(page => {
        let els = page.elements.filter(item => item.active);
        page.elements = page.elements.concat(JSON.parse(JSON.stringify(els)));
      })
    },
    // 批量删除
    delBatchElement () {
      this.pdfData.page.forEach((page, pageIndex) => {
        // 删除表格
        page.elements.filter((item, elIndex) => {
          if (!!item.active && item.name === 'TableLabel') {
            this.elClose({
              index: pageIndex,
              elIndex: elIndex,
            })
          }
        })
        // 删除普通元素
        page.elements = page.elements.filter(item => !item.active);
      })
      this.pdfData.header.elements = this.pdfData.header.elements.filter(item => !item.active);
      this.pdfData.footer.elements = this.pdfData.footer.elements.filter(item => !item.active);

      if (this.currentPage !== null) {
        this.pdfData.page.splice(this.currentPage, 1);
        // this.currentPage = null;
      }
    },
    cancelPage () {
      // this.styleName = null;
      // this.styleData = {};
      // this.pdfData.header.active = false;
      // this.pdfData.footer.active = false;
      this.cancelPageEl();
      // 页眉
      this.cancelHeaderEl()
      // 页脚
      this.cancelFooterEl()
    },
    // 选中元素
    elClick (el, type) {
      if (el.name === 'TableLabel' && el.originData.length === 0) return;
      if (el.name === 'TextLabel' && !el.readonly) return;
      if (el.active) {
        el.active = false;
        this.styleName = null;
        this.styleData = {};
      } else {
        el.active = true;
        this.styleData = el;
        switch (el.name) {
          case 'TextLabel':
            this.styleName = 'TextStyle';
            break;
          case 'ImageLabel':
            this.styleName = 'ImageStyle';
            break;
          case 'TableLabel':
            this.styleName = 'TableStyle';
            break;
          case 'IndexLabel':
            this.styleName = 'IndexStyle';
            break;
        }
        if (type === 0) {
          if (this.pdfData.header.active) {
            this.pdfData.header.active = false;
            this.cancelHeaderEl();
          }
          if (this.pdfData.footer.active) {
            this.pdfData.footer.active = false;
            this.cancelFooterEl();
          }
        }

        this.menuTabs = 'elements';
        this.elementTabs = 'selected';
      }
    },
    elDblclick (el) {
      if (el.name === 'TextLabel') {
        el.readonly = false;
        el.active = false;
      }
    },
    // 
    elMousedown (e, el) {
      if (el.name === 'TableLabel' && e.target.classList.contains('slide')) {
        this.dragColumn(e, el)
      } else {
        this.elMove(e, el)
      }
    },
    // 拖动
    elMove (e, el) {
      if (el.name === 'TableLabel' && el.originData.length === 0) return;

      let content = this.queryParentNode(e.target, 'content');
      let box = this.queryParentNode(e.target, 'el-box');
      let disx = e.pageX - box.offsetLeft;
      let disy = e.pageY - box.offsetTop;
      let tableHeaderRow;
      if (el.name === 'TableLabel') tableHeaderRow = el.tableHeader.length === 1 && el.tableHeader[0].mainType === '' ? 1 : 2;
      if (!el.active) return;
      document.onmousemove = (e) => {
        e.preventDefault();
        let x = e.pageX - disx;
        let y = e.pageY - disy;
        let minLeft = 0;
        let minTop = 0;
        let maxLeft = content.offsetWidth;
        let maxTop = content.offsetHeight;

        x = x < minLeft ? minLeft : x;
        y = y < minTop ? minTop : y;
        x = el.style.width + x > maxLeft ? maxLeft - el.style.width : x;

        if (el.name !== 'TableLabel') {
          y = el.style.height + y > maxTop ? maxTop - el.style.height : y;
        } else {
          y = (el.style.trHeight + 1) * tableHeaderRow + 1 + y > maxTop ? maxTop - (el.style.trHeight + 1) * tableHeaderRow - 1 : y;
        }

        el.style.left = Math.round(x);
        el.style.top = Math.round(y);
      }
      document.onmouseup = (e) => {
        if (el.name === 'TableLabel') {
          this.moveTable(el);
        }
        document.onmousemove = document.onmouseup = null;
      }
    },
    // 改变列宽
    dragColumn (e, el) {
      let disx = e.pageX;
      let table = document.querySelector(`.table${el.id}`);
      let td = e.target.parentElement;
      let tdIndex = td.cellIndex;
      let tdWidth = td.offsetWidth;
      let w;
      let rowIndex = el.tableHeader.length === 1 && el.tableHeader[0].mainType === '' ? 0 : 1;
      document.onmousemove = (e) => {
        e.preventDefault();
        w = e.pageX - disx;
        table.rows[rowIndex].cells[tdIndex].style.width = tdWidth + w + 'px'
      }
      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
        el.colWidth = [];
        Array.from(table.rows[rowIndex].cells).forEach(cell => {
          el.colWidth.push(cell.offsetWidth - 1);
        })
        this.asyncTableCol(el)
      }
    },

    // 改变大小
    elResize (e, el) {
      e.preventDefault();
      let content = this.queryParentNode(e.target, 'content');
      let side = e.target;
      let box = e.target.parentElement;
      let disx = e.pageX - side.offsetLeft - 6;
      let disy = e.pageY - side.offsetTop - 6;
      document.onmousemove = (e) => {
        let x = e.pageX - disx;
        let y = e.pageY - disy;

        x = x + box.offsetLeft > content.offsetWidth ? content.offsetWidth - box.offsetLeft : x;
        y = y + box.offsetTop > content.offsetHeight ? content.offsetHeight - box.offsetTop : y;

        if (el.name === 'TableLabel') {
          this.pdfData.page.forEach(item => {
            let index = item.elements.findIndex(item => item.id === el.id);
            if (index !== -1) item.elements[index].style.width = Math.round(x);
          })
        } else {
          el.style.width = Math.round(x);
          el.style.height = Math.round(y);
        }
      }
      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
      }
    },
    // 查找el-box
    queryParentNode (el, _class) {
      while (!el.classList.contains(_class)) {
        el = el.parentNode;
      }
      return el;
    },
    switchHeader () {
      if (!this.pdfData.header.show) return;

      this.pdfData.footer.active = false;
      Object.assign(this.pdfData.header, {
        show: true,
        width: this.pdfData.width - parseInt((this.pdfData.paddingLeft + this.pdfData.paddingRight) * this.pdfData.rate),
        active: true,
        top: Math.round(this.pdfData.paddingTop * this.pdfData.rate),
        left: Math.round(this.pdfData.paddingLeft * this.pdfData.rate),
      })
      this.currentPage = null;
      this.pdfData.page.forEach(page => {
        page.elements.forEach(el => {
          if (el.name === 'TableLabel' && el.originData.length > 0) {
            this.moveTable(el);
          }
        })
      })
    },
    switchFooter () {
      if (!this.pdfData.footer.show) return;

      this.pdfData.header.active = false;
      Object.assign(this.pdfData.footer, {
        show: true,
        width: this.pdfData.width - parseInt((this.pdfData.paddingLeft + this.pdfData.paddingRight) * this.pdfData.rate),
        active: true,
        bottom: Math.round(this.pdfData.paddingBottom * this.pdfData.rate),
        left: Math.round(this.pdfData.paddingLeft * this.pdfData.rate),
      })
      this.currentPage = null;
      this.pdfData.page.forEach(page => {
        page.elements.forEach(el => {
          if (el.name === 'TableLabel' && el.originData.length > 0) {
            this.moveTable(el);
          }
        })
      })
    },
    changeHeader () {
      this.pdfData.header.active = !this.pdfData.header.active;

      if (this.pdfData.header.active) {
        this.pdfData.footer.active = false;
      } else {
        this.cancelHeader();
      }
    },
    changeFooter () {
      this.pdfData.footer.active = !this.pdfData.footer.active;
      if (this.pdfData.header.active) {
        this.pdfData.header.active = false;
      } else {
        this.cancelFooter();
      }
    },
    headerClick () {
      this.pdfData.header.active = true;
      this.pdfData.footer.active = false;
      this.currentPage = null;
      this.cancelPageEl();
    },
    footerClick () {
      this.pdfData.footer.active = true;
      this.pdfData.header.active = false;
      this.currentPage = null;
      this.cancelPageEl();
    },
    cancelHeader () {
      this.styleName = null;
      this.styleData = {};
      this.cancelHeaderEl();
    },
    cancelFooter () {
      this.styleName = null;
      this.styleData = {};
      this.cancelFooterEl();
    },
    cancelPageEl () {
      this.pdfData.page.forEach(page => {
        page.elements.forEach(el => {
          if (el.name === 'TextLabel') {
            el.readonly = true;
          }
          el.active = false;
        })
      })
    },
    cancelHeaderEl () {
      this.pdfData.header.elements.forEach(el => {
        if (el.name === 'TextLabel') {
          el.readonly = true;
        }
        el.active = false;
      })
    },
    cancelFooterEl () {
      this.pdfData.footer.elements.forEach(el => {
        if (el.name === 'TextLabel') {
          el.readonly = true;
        }
        el.active = false;
      })
    },
    // 处理表格
    createTable (el) {
      let pageHeight = this.pdfData.height - Math.round((this.pdfData.paddingTop + this.pdfData.paddingBottom) * this.pdfData.rate);
      let data = [];
      let maxLen;
      let tableNumber;
      let number;

      if (this.pdfData.header.show) pageHeight -= this.pdfData.header.height;
      if (this.pdfData.footer.show) pageHeight -= this.pdfData.footer.height;

      number = parseInt((pageHeight - 1) / (el.style.trHeight + 1));

      if (el.tableHeader.length === 1 && el.tableHeader[0].mainType === '') {
        maxLen = (el.originData.length + 1) * (el.style.trHeight + 1) + 1;
        tableNumber = number - 1;
      } else {
        maxLen = (el.originData.length + 2) * (el.style.trHeight + 1) + 1;
        tableNumber = number - 2;
      }
      if (pageHeight > maxLen) {
        el.style.height = maxLen;
        el.tableData = JSON.parse(JSON.stringify(el.originData));
      } else {
        el.style.height = number * (el.style.trHeight + 1) + 1;
        el.tableData = JSON.parse(JSON.stringify(el.originData.slice(0, tableNumber)));

        let remainingQuantity = el.originData.length - tableNumber;
        let start = tableNumber;
        while (remainingQuantity > 0) {
          let elData = JSON.parse(JSON.stringify(el));
          elData.originData = [];
          elData.tableData = el.originData.slice(start, start + number);
          elData.style.height = elData.tableData.length * elData.style.trHeight + elData.tableData.length + 1;
          elData.active = false;
          data.push({
            style: {},
            elements: [elData]
          })
          start = start + number;
          remainingQuantity = remainingQuantity - number;
        }
        this.pdfData.page.splice(this.currentPage + 1, 0, ...data)

        this.$nextTick(() => {
          this.asyncTableCol(el);
        })
      }
    },
    moveTable (el) {
      let pageHeight = this.pdfData.height - Math.round((this.pdfData.paddingTop + this.pdfData.paddingBottom) * this.pdfData.rate) - el.style.top;
      let maxLen;
      let tableNumber;
      let number;

      if (this.pdfData.header.show) pageHeight -= this.pdfData.header.height;
      if (this.pdfData.footer.show) pageHeight -= this.pdfData.footer.height;

      number = parseInt((pageHeight - 1) / (el.style.trHeight + 1));

      if (el.tableHeader.length === 1 && el.tableHeader[0].mainType === '') {
        maxLen = (el.originData.length + 1) * (el.style.trHeight + 1) + 1;
        tableNumber = number - 1;
      } else {
        maxLen = (el.originData.length + 2) * (el.style.trHeight + 1) + 1;
        tableNumber = number - 2;
      }

      if (pageHeight > maxLen) {
        el.style.height = maxLen;
        el.tableData = JSON.parse(JSON.stringify(el.originData));
      } else {
        el.style.height = number * (el.style.trHeight + 1) + 1;
        el.tableData = JSON.parse(JSON.stringify(el.originData.slice(0, tableNumber)));
      }

      this.pdfData.page.forEach(page => {
        let itemIndex = page.elements.findIndex(item => item.id === el.id && item.originData.length === 0);
        if (itemIndex !== -1) {
          page.elements.splice(itemIndex, 1);
        }
      })
      let remainingQuantity = el.originData.length - tableNumber;
      let start = tableNumber;
      let index = 1;
      while (remainingQuantity > 0) {
        let pageHeight = this.pdfData.height - Math.round((this.pdfData.paddingTop + this.pdfData.paddingBottom) * this.pdfData.rate)

        if (this.pdfData.header.show) pageHeight -= this.pdfData.header.height;
        if (this.pdfData.footer.show) pageHeight -= this.pdfData.footer.height;

        let number = parseInt((pageHeight - 1) / (el.style.trHeight + 1));
        let pageItem = this.pdfData.page[this.currentPage + index];
        let elData = JSON.parse(JSON.stringify(el));
        elData.originData = [];
        elData.tableData = el.originData.slice(start, start + number);
        elData.style.height = elData.tableData.length * elData.style.trHeight + elData.tableData.length + 1;
        elData.style.top = 0;
        elData.active = false;
        if (!pageItem) {
          this.pdfData.page.push({
            style: {},
            elements: [elData]
          })
        } else {
          pageItem.elements.push(elData);
        }

        start = start + number;
        remainingQuantity = remainingQuantity - number;
        index++;
      }
      this.$nextTick(() => {
        this.asyncTableCol(el);
      })
    },
    initTable (data, el) {
      el.checkListData = data;
      el.checkListData.forEach((item, i) => {
        if (item.checkTypeItem.type === 7) {
          this.numberStart = Number(item.checkTypeItem.options[1].name);
          this.numberEnd = Number(item.checkTypeItem.options[2].name);
        }
        // 表头
        item.index = i;
        if (el.tableHeader.length > 0 && el.tableHeader[el.tableHeader.length - 1].mainType === item.mainType) {
          el.tableHeader[el.tableHeader.length - 1].children.push(item);
        } else {
          el.tableHeader.push({
            mainType: item.mainType,
            children: [item],
          })
        }
      })
    },
    dealData (checkTypeItem, content, j) {
      let result;
      let item;
      switch (checkTypeItem.type) {
        case 1:
          result = content;
          break;
        case 2:
          item = checkTypeItem.options.find(item => item.id === content);
          result = !!item ? item.name : '';
          break;
        case 3:
          content = !!content ? content.split(',') : [];
          item = checkTypeItem.options.filter(item => content.includes(item.id));
          result = item.length > 0 ? item.map(checkbox => checkbox.name).join('、') : '';
          break;
        case 4:
          // result = '';
          // checkTypeItem.options.forEach(item => {
          //   if (item.id === content) {
          //     result += `√${item.name} `
          //   } else {
          //     result += ` ${item.name} `
          //   }
          // })

          result = content;
          break;
        case 5:
        case 6:
          result = content;
          break;
        case 7:
          result = this.numberStart + j;
          break;
        case 8:
        case 9:
        case 10:
          result = content;
          break;
        case 11:
          break;
        case 12:
          break;
        case 13:
          item = checkTypeItem.options.find(item => item.id === content);
          result = !!item ? item.name : '';
          break;
      }
      return result;
    },
    // 同步表格列宽
    asyncTableCol (el) {
      let els = Array.from(document.querySelectorAll(`.table${el.id}`));
      let originTable = els.shift();
      let rowIndex = el.tableHeader.length === 1 && el.tableHeader[0].mainType === '' ? 0 : 1;

      els.forEach(item => {
        for (let i = 0; i < item.rows[0].cells.length; i++) {
          item.rows[0].cells[i].style.width = originTable.rows[rowIndex].cells[i].offsetWidth + 'px'
        }
      })
    },
    // 返回
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 点击元素节点 
    nodeClick (data, node) {
      if (data.disabled) return;
      if (data.type === 'table' && (this.pdfData.header.active || this.pdfData.footer.active)) return;
      let param = {
        value: data.value,
        alt: data.alt,
        elementId: data.elementId,
      }
      param.type = !!data.type ? data.type : 'text';
      this.addElement(param)
    },
    // 单击pdf模板
    pdfTemplageClick (data) {
      this.selectPdf = data;
      // this.pdfData = JSON.parse(data.template);
    },
    // 双击pdf模板
    pdfTemplageDblclick (data) {
      this.currentPdf = data;
      let template = JSON.parse(data.template);
      // 兼容
      if (!template.pageIndex) {
        template.pageIndex = {
          show: false,
          style: {
            textAlign: 'left',
          }
        }
      }
      // 兼容end

      let confirmOrderIndex = this.treeData.length - 1;
      this.pdfData = template;
      this.$nextTick(() => {
        this.pdfData.page.forEach(page => {
          page.elements.forEach(el => {
            if (el.name === 'TableLabel' && el.originData.length !== 0) {
              this.asyncTableCol(el);
            }

            if (el.alt === '[ Site Name ]') el.content = this.treeData[confirmOrderIndex].children[0].value;
            if (el.alt === '[ Site Address ]') el.content = this.treeData[confirmOrderIndex].children[1].value;
            if (el.alt === '[ Engineer ]') el.content = this.treeData[confirmOrderIndex].children[2].value;
            if (el.alt === '[ Phone Number ]') el.content = this.treeData[confirmOrderIndex].children[3].value;
            if (el.alt === '[ Processing Time ]') el.content = this.treeData[confirmOrderIndex].children[4].value;
            if (el.alt === '[ Processing Time (Y-M-D) ]') el.content = this.treeData[confirmOrderIndex].children[5].value;
            if (el.alt === '[ Processing Start Time ]') el.content = this.treeData[confirmOrderIndex].children[6].value;
            if (el.alt === '[ Processing End Time ]') el.content = this.treeData[confirmOrderIndex].children[7].value;
            if (el.alt === '[ Description ]') el.content = this.treeData[confirmOrderIndex].children[8].value;
            if (el.alt === '[ Detail Process ]') el.content = this.treeData[confirmOrderIndex].children[9].value;
            if (el.alt === '[ Signature ]') el.content = this.treeData[confirmOrderIndex].children[10].value;
            if (el.alt === '[ Client Contact ]') el.content = this.treeData[confirmOrderIndex].children[11].value;
            if (el.alt === '[ Jobtitle ]') el.content = this.treeData[confirmOrderIndex].children[12].value;
            if (el.alt === '[ Staff ID ]') el.content = this.treeData[confirmOrderIndex].children[13].value;
          })
        });
      })
    },
    showStyleBox (name) {
      if (this.styleBoxName === name) {
        this.styleBoxName = null
      } else {
        this.styleBoxName = name;
      }
    },
    clearStyleBox () {
      this.styleBoxName = null
    },
    pageTreeClick (page, index) {
      page.expand = !page.expand;
      this.$set(this.pdfData.page, index, page)
    },
    headerTreeClick () {
      this.pdfData.header.expand = !this.pdfData.header.expand;
    },
    footerTreeClick () {
      this.pdfData.footer.expand = !this.pdfData.footer.expand;
    },
    getConfirmOrder () {
      let param = { id: this.confirmOrderId }
      GetConfirmOrderItem(param).then(res => {
        let data = res.maintenanceServiceConfirmOrderDetail || {};
        let confirmOrderIndex = this.treeData.length - 1;
        let maintenanceEquipmentTable = data.repairDeviceList || [];
        let replaceEquipmentTable = data.replaceDeviceList || [];
        let demolitionEquipmentTable = data.removeDeviceList || [];
        let configEidtTable = data.classifiedList || [];
        let installationTable = data.installationDeviceList || [];
        let describeAll = [];
        let disposeAll = [];
        maintenanceEquipmentTable.forEach(item => {
          if (!!item.faultDescription) describeAll.push(item.faultDescription);
          if (!!item.handleMethod) disposeAll.push(item.handleMethod);
        })
        replaceEquipmentTable.forEach(item => {
          if (!!item.faultDescription) describeAll.push(item.faultDescription);
          if (!!item.remark) disposeAll.push(item.remark);
        })
        demolitionEquipmentTable.forEach(item => {
          if (!!item.faultDescription) describeAll.push(item.faultDescription);
          if (!!item.remark) disposeAll.push(item.remark);
        })
        configEidtTable.forEach(item => {
          if (!!item.faultDescription) describeAll.push(item.faultDescription);
          if (!!item.remark) disposeAll.push(item.remark);
        })
        installationTable.forEach(item => {
          if (!!item.faultDescription) describeAll.push(item.faultDescription);
          if (!!item.remark) disposeAll.push(item.remark);
        })
        this.treeData[confirmOrderIndex].children[0].value = res.branchName;
        this.treeData[confirmOrderIndex].children[1].value = res.address;
        this.treeData[confirmOrderIndex].children[2].value = data.serviceUserName;
        this.treeData[confirmOrderIndex].children[3].value = data.signatureCustomerUserPhone
        this.treeData[confirmOrderIndex].children[4].value = data.currentHandleStartTime + ' - ' + data.currentHandleEndTime;
        this.treeData[confirmOrderIndex].children[5].value = formatDate(new Date(data.currentHandleStartTime), 'yyyy-MM-dd') + ' - ' + formatDate(new Date(data.currentHandleEndTime), 'yyyy-MM-dd');
        this.treeData[confirmOrderIndex].children[6].value = data.currentHandleStartTime;
        this.treeData[confirmOrderIndex].children[7].value = data.currentHandleEndTime;
        this.treeData[confirmOrderIndex].children[8].value = describeAll.join('\n');
        this.treeData[confirmOrderIndex].children[9].value = disposeAll.join('\n');
        this.treeData[confirmOrderIndex].children[10].value = this.baseUrl + '/Engineer/getImage.do?imageId=' + data.signatureCustomerPictureName;
        this.treeData[confirmOrderIndex].children[11].value = data.signatureCustomerUserId;
        this.treeData[confirmOrderIndex].children[12].value = data.signatureCustomerUserPost;
        this.treeData[confirmOrderIndex].children[13].value = data.signatureCustomerUserNo;

      })
    },
    // 上对齐
    elTop () {
      let top;
      let temp = this.pdfData.page[this.currentPage].elements.filter(item => item.active);
      temp.forEach(item => {
        if (top === undefined) return top = item.style.top;
        if (top > item.style.top) {
          top = item.style.top
        }
      })
      temp.forEach(item => item.style.top = top)
    },
    // 水平居中
    elHorizontalCenter () {
      let top;
      let bottom = 0;
      let center;
      let temp = this.pdfData.page[this.currentPage].elements.filter(item => item.active);
      temp.forEach(item => {
        if (top === undefined)
          top = item.style.top;
        else if (top > item.style.top)
          top = item.style.top

        if (bottom < item.style.top + item.style.height) {
          bottom = item.style.top + item.style.height;
        }
      })
      center = top + (bottom - top) / 2;
      temp.forEach(item => item.style.top = center - item.style.height / 2)
    },
    // 下对齐
    elBottom () {
      let bottom = 0;
      let temp = this.pdfData.page[this.currentPage].elements.filter(item => item.active);
      temp.forEach(item => {
        if (bottom < item.style.top + item.style.height) {
          bottom = item.style.top + item.style.height
        }
      })
      temp.forEach(item => item.style.top = bottom - item.style.height)
    },
    // 左对齐
    elLeft () {
      let left;
      let temp = this.pdfData.page[this.currentPage].elements.filter(item => item.active);
      temp.forEach(item => {
        if (left === undefined) return left = item.style.left;
        if (left > item.style.left) {
          left = item.style.left
        }
      })
      temp.forEach(item => item.style.left = left)
    },
    // 垂直居中
    elVerticalCenter () {
      let left;
      let right = 0;
      let center;
      let temp = this.pdfData.page[this.currentPage].elements.filter(item => item.active);
      temp.forEach(item => {
        if (left === undefined)
          left = item.style.left;
        else if (left > item.style.left)
          left = item.style.left

        if (right < item.style.left + item.style.width) {
          right = item.style.left + item.style.width;
        }
      })
      center = left + (right - left) / 2;
      temp.forEach(item => item.style.left = center - item.style.width / 2)
    },
    // 右对齐
    elRight () {
      let right = 0;
      let temp = this.pdfData.page[this.currentPage].elements.filter(item => item.active);
      temp.forEach(item => {
        if (right < item.style.left + item.style.width) {
          right = item.style.left + item.style.width
        }
      })
      temp.forEach(item => item.style.left = right - item.style.width)
    },

    // 切换元素显示状态
    pageSwitch (pageIndex) {
      if (pageIndex === this.currentPage) {
        this.currentPage = null;
      } else {
        this.currentPage = pageIndex;
      }
    },
    elSwitch (param) {
      let item
      switch (param.type) {
        case 'page':
          item = this.pdfData.page[param.pageIndex].elements[param.elIndex]
          item.hide = !item.hide;
          this.$set(this.pdfData.page[param.pageIndex].elements, param.elIndex, item)
          break;
        case 'header':
          item = this.pdfData.header.elements[param.elIndex]
          item.hide = !item.hide;
          this.$set(this.pdfData.header.elements, param.elIndex, item)
          break;
        case 'footer':
          item = this.pdfData.footer.elements[param.elIndex]
          item.hide = !item.hide;
          this.$set(this.pdfData.footer.elements, param.elIndex, item)
          break;
      }
    },
    addScale () {
      if (this.scale < 500) this.scale += 5;
    },
    subtractScale () {
      if (this.scale > 10) this.scale -= 5;
    },
    getTableData (param, elItem, fun) {
      let tableParam = {
        limit: 9999,
        page: 1,
        elementId: param.elementId,
        isRecord: 0,
      }
      GetCheckList(tableParam).then(res => {
        let isData = res.list.length === 0;
        res.list.forEach((row, rowIndex) => {
          elItem.originData[rowIndex] = {};
          row.itemList.forEach((item, colIndex) => {
            elItem.originData[rowIndex][`content${colIndex}`] = this.dealData(param.value[colIndex].checkTypeItem, item.content, rowIndex);
          });
        });
        if (typeof fun === 'function') fun(isData);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 0px;
  background-color: #f0f0f0;
}
.top-box {
  width: 100%;
  height: 50px;
  background-color: #f5f7fa;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  .left {
    line-height: 50px;
    .el-icon-d-arrow-left {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 20px;
      margin-right: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
    .pdf-name {
      font-size: 20px;
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid #000;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .size-box {
      margin-right: 20px;
      font-size: 20px;
      user-select: none;
      i {
        cursor: pointer;
      }
      .size-number {
        width: 50px;
        margin: 0 5px;
        text-align: center;
        display: inline-block;
      }
    }
    .direction-box {
      margin-right: 20px;
      font-size: 20px;
      i {
        cursor: pointer;
        margin: 0 5px;
      }
    }
  }
}
.main-box {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}
.right-box {
  width: 350px;
  height: 100%;
  // border-left: 1px solid #e0e0e0;
  // background-color: #fafafa;
}

.main {
  width: calc(100% - 350px);
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;

  .pdf-wrap {
    padding-top: 10px;
    // padding: 10px 0;
    // margin: 0 auto 10px;
    transform-origin: 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page {
    // width: 794px;
    // height: 1123px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    position: relative;
    .page-content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    .el-box {
      position: absolute;
    }
    .active-move {
      border: 1px solid #00c875;
      user-select: none;
      cursor: move;
    }
    .side {
      width: 7px;
      height: 7px;
      background-color: #00c875;
      position: absolute;
      right: -4px;
      bottom: -4px;
      cursor: nw-resize;
      z-index: 1;
    }
    .close {
      width: 14px;
      height: 14px;
      line-height: 14px;
      text-align: center;
      border-radius: 50%;
      font-size: 12px;
      color: #fff;
      background-color: #f56c6c;
      cursor: pointer;
      position: absolute;
      top: -7px;
      right: -7px;
    }
  }
}

.el-tabs {
  height: 100%;
  .el-tab-pane {
    height: 100%;
  }
  ::v-deep .el-tabs__content {
    height: calc(100% - 39px);
    padding: 0px;
    background-color: #fafafa;
  }
}
.btn-box {
  .el-button {
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
}
.page-box {
  height: calc(100% - 306px);
  margin-top: 10px;
  overflow: auto;
  .page-thumb {
    border: 1px solid #e0e0e0;
    background-color: #fff;
    margin: 0 auto 10px;
    cursor: pointer;
    a {
      width: 100%;
      height: 100%;
      display: block;
    }
    &.active {
      border: 1px solid #00c875;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .col {
    width: calc(79px * 2);
    height: calc(112px * 2);
  }
  .row {
    width: calc(112px * 2);
    height: calc(79px * 2);
  }
}
.hr {
  border-bottom: 1px solid #000;
  margin: 10px 0;
}

.input-box {
  height: 32px;
  line-height: 32px;
  display: flex;
  label {
    width: 60px;
    font-size: 14px;
    text-align: right;
    padding-right: 5px;
  }
  .el-input-number {
    width: calc(100% - 65px);
  }
  .el-color-picker {
    width: calc(100% - 65px);
  }
  .el-select {
    width: calc(100% - 65px);
  }
}
.el-btn-box {
  height: calc(100% - 42px - 42px - 42px - 18px - 18px - 33px - 300px);
  margin-top: 10px;
  overflow: auto;
  .el-button {
    width: 100%;
    margin: 0 0 10px;
  }
}

.header,
.footer {
  width: 100%;
  flex-shrink: 0;
  &.active {
    border: 1px solid #00c875;
  }
}
.content {
  width: 100%;
  height: 100%;
  position: relative;
}
.tree-box {
  height: 100%;
  overflow: auto;
  background: #fff;
}
.btn-item {
  width: 100%;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  &.option-btn {
    height: 36px;
    line-height: 36px;
  }
  .text {
    width: calc(100% - 15px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
  i {
    margin-right: 10px;
    font-size: 16px;
  }
  .tree-arrow {
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    margin-left: -10px;
    margin-right: 0px;
  }
  &:hover {
    background-color: #fff;
  }
  &.active {
    padding-left: 10px;
    border-left: 10px solid #b3e3f9;
    &.retract {
      padding-left: 30px;
    }
    &.tree-retract {
      padding-left: 50px;
    }
  }

  &.retract {
    padding-left: 40px;
  }
  &.tree-retract {
    padding-left: 60px;
  }
  &.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.user-select-none {
    user-select: none;
  }
  .current-icon {
    color: #02a7f0;
    float: right;
    line-height: 26px;
  }
  .switch-btn {
    position: absolute;
    right: 0;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    margin-right: 0px;
    color: #02a7f0;

    &.hide {
      color: #818181;
    }
  }
}
.el-button-group {
  width: 100%;
  // padding: 0 10px;
  display: flex;
  .el-button {
    flex: 1;
  }
}
.style-box {
  width: 300px;
  height: calc(100% - 100px);
  background-color: #fff;
  border: 1px solid #dcdfe6;
  position: fixed;
  right: 350px;
  top: 100px;
  z-index: 2;
}

.el-tabs {
  ::v-deep .el-tabs__header {
    margin: 0;
    padding: 0 10px;
  }
}

.element-box {
  height: calc(100% - 445px);
  overflow: auto;
}
.pdf-list-box {
  height: calc(100% - 193px);
  overflow: auto;
}

.page-index {
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  position: absolute;
  bottom: -24px;
}

.template-name {
  width: calc(100% - 40px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>