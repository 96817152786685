<template>
  <div>
    <el-table
      border
      ref="table"
      :data="tableData"
      :height="tableHeight"
      v-loading="tableLoading"
      @selection-change="selectionChange"
      @row-click="rowClick"
      @row-dblclick="showDetails"
      :default-sort="{prop: 'createTime', order: 'descending'}"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.createTime.visible"
        sortable
        prop="createTime"
        :label="cols.createTime.name"
        :formatter="$formatTableData"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.deviceName.visible"
        sortable
        prop="deviceName"
        :label="cols.deviceName.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.deviceBrand.visible"
        sortable
        prop="deviceBrand"
        :label="cols.deviceBrand.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.deviceModel.visible"
        sortable
        prop="deviceModel"
        :label="cols.deviceModel.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.deviceType.visible"
        sortable
        prop="deviceType"
        :label="cols.deviceType.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.serialNumbers.visible"
        sortable
        prop="serialNumbers"
        :label="cols.serialNumbers.name"
      >
      </el-table-column>
      <el-table-column
        min-width="100"
        v-if="cols.deviceUnit.visible"
        sortable
        prop="deviceUnit"
        :label="cols.deviceUnit.name"
      >
      </el-table-column>
      <el-table-column
        min-width="100"
        v-if="cols.count.visible"
        sortable
        prop="count"
        :label="cols.count.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.receiverName.visible"
        sortable
        prop="receiverName"
        :label="cols.receiverName.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.projectName.visible"
        sortable
        show-overflow-tooltip
        prop="projectName"
        :label="cols.projectName.name"
        show-overflowname
      >
      </el-table-column>
      <el-table-column
        min-width="200"
        v-if="cols.quotationNumber.visible"
        sortable
        prop="quotationNumber"
        :label="cols.quotationNumber.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.applicationFormNumber.visible"
        sortable
        prop="applicationFormNumber"
        :label="cols.applicationFormNumber.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.orderNumber.visible"
        sortable
        prop="orderNumber"
        :label="cols.orderNumber.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.outRemark.visible"
        sortable
        show-overflow-tooltip
        prop="outRemark"
        :label="cols.outRemark.name"
        show-overflowname
      >
      </el-table-column>
      <el-table-column
        min-width="110"
        v-if="cols.isBorrowOut.visible"
        sortable
        prop="isBorrowOut"
        :label="cols.isBorrowOut.name"
      >
        <template slot-scope="scope">
          {{scope.row.isBorrowOut === 1 ? $t('inventoryManagement.outWarehouse.yes'):$t('inventoryManagement.outWarehouse.no')}}
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.returnDate.visible"
        sortable
        prop="returnDate"
        :label="cols.returnDate.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.returnedTime.visible"
        sortable
        prop="returnedTime"
        :label="cols.returnedTime.name"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        v-if="cols.courierNumber.visible"
        sortable
        prop="courierNumber"
        :label="cols.courierNumber.name"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        v-if="cols.courierCompany.visible"
        sortable
        prop="courierCompany"
        :label="cols.courierCompany.name"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        v-if="cols.courierCost.visible"
        sortable
        prop="courierCost"
        :label="cols.courierCost.name"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['tableLoading', 'tableSelected', 'tableData', 'permit', 'tableHeight', 'cols'],
  watch: {
    'cols': function () {
      this.$refs.table.doLayout();
    }
  },
  methods: {
    selectionChange (val) {
      this.$emit('update:tableSelected', val)
    },
    // 详情
    showDetails (e) {
      this.$router.push({
        path: '/home/outWarehouseDetails',
        query: {
          inId: e.inventoryId,
          id: e.id,
          applicationFormInfoId: e.applicationFormInfoId,
          type: this.permit.edit ? 4 : 3
        }
      })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>

<style>
</style>