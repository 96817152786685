<template>
  <div class="wrap">
    <el-row
      :gutter="10"
      class="row-box"
    >
      <el-col
        :span="8"
        v-show="visible"
        class="col-box"
      >
        <el-card>
          <div class="title-box">
            <span>{{$t('dataMaintenance.title1')}}</span>
            <i
              class="el-icon-close close-btn"
              @click="visible = false"
            ></i>
          </div>
          <el-row>
            <el-button-group class="condition-box">
              <div
                class="setting"
                @click="showFieldEditor"
              >
                <i class="el-icon-s-tools"></i>
              </div>
              <el-select
                size="medium"
                v-model="type"
                @change="typeChange"
                filterable
              >
                <el-option
                  v-for="(item,index) in types"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-button-group>
          </el-row>
          <el-row>
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
                @input="searchData"
              ></el-input>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                v-if="mergeItem.includes(type)"
                size="medium"
                @click="showMerge"
              >合并</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </el-row>
          <el-row
            class="type-table"
            ref="typeTable"
          >
            <el-table
              border
              ref="table"
              :height="tableHeight"
              v-loading="tableLoading"
              :data="tableData"
              :row-class-name="tableRowClassName"
              @selection-change="val => tableSelected = val"
              @row-click="rowClick"
              @row-dblclick="dblclickEdit"
              stripe
              tabindex="1"
              row-key="id"
            >
              <el-table-column
                type="selection"
                reserve-selection
              ></el-table-column>
              <el-table-column
                min-width="150"
                :label="tableLable"
                prop="name"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="70"
                v-if="countItems.includes(type)"
                label="Count"
                :prop="countProp"
                sortable
              >
              </el-table-column>
            </el-table>
          </el-row>
        </el-card>
      </el-col>
      <el-col
        class="col-box"
        :span="visible ? 16 : 24"
      >
        <el-card>
          <div class="title-box">
            <span>{{currentType.name}}</span>
            <i
              class="el-icon-notebook-2 title-btn"
              @click="visible = true"
            ></i>
          </div>
          <component
            :is="componentName"
            :items="tableSelected"
            :count-prop.sync="countProp"
            @update="getTableData"
          ></component>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加弹框 -->
    <add-dialog
      :visible.sync="AddDialogVisible"
      :data="itemData"
      :current-type="currentType"
      @update="getTableData"
    ></add-dialog>
    <!-- 字段弹框 -->
    <data-field-editor
      :visible.sync="DataFieldEditorVisible"
      :data="types"
      @update="getField"
    ></data-field-editor>
    <!-- 添加 CheckType 弹框-->
    <add-check-type
      :visible.sync="AddCheckTypeVisible"
      :data="itemData"
      :current-type="currentType"
      @update="getTableData"
    ></add-check-type>

    <merge-dialog
      :visible.sync="MergeDialogVisible"
      :data="tableSelected"
      :table-lable="tableLable"
      :type="type"
      @update="mergeSubmit"
    ></merge-dialog>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 组件
import DeviceType from './components/DeviceType/'
import EquipmentBrand from './components/EquipmentBrand/'
import Supplier from './components/Supplier/'
import SystemType from './components/SystemType/'
import FaultType from './components/FaultType/'
import ServiceType from './components/ServiceType/'
import CompleteStatus from './components/CompleteStatus/'
import ProjectType from './components/ProjectType/'
import SecurityType from './components/SecurityType/'
import LogisticsCompany from './components/LogisticsCompany/'
import CountUnit from './components/CountUnit/'
import ContractQuotationType from './components/ContractQuotationType/'
import CustomerApproval from './components/CustomerApproval/'
import ReceiptAddress from './components/ReceiptAddress/'
import InSite from './components/InSite/'
import InType from './components/InType/'
import ServiceConfirmationTemplate from './components/ServiceConfirmationTemplate/'
import TicketSource from './components/TicketSource'
import ProjectTaskType from './components/ProjectTaskType'
import SiteCategory from './components/SiteCategory'
import AddDialog from './components/AddDialog'
import DataFieldEditor from './components/DataFieldEditor'
import WarrantyType from './components/WarrantyType'
import CheckType from './components/CheckType/'
import AddCheckType from './components/AddCheckType'
import MergeDialog from './components/MergeDialog'

// 接口
import {
  GetConfirmationTemplateList,
  DelConfirmationTemplate,
} from '@/api/warehouse/dataMaintenance/serviceConfirmationTemplate'
import {
  GetDataFieldList,
  GetDataFieldAll,
  DelDataField,
} from '@/api/warehouse/dataMaintenance'

export default {
  name: 'DataMaintenance',
  components: {
    DeviceType,
    EquipmentBrand,
    Supplier,
    SystemType,
    FaultType,
    ServiceType,
    CompleteStatus,
    ProjectType,
    ProjectTaskType,
    SecurityType,
    LogisticsCompany,
    CountUnit,
    ContractQuotationType,
    CustomerApproval,
    ReceiptAddress,
    InSite,
    InType,
    ServiceConfirmationTemplate,
    TicketSource,
    ProjectTaskType,
    SiteCategory,
    AddDialog,
    DataFieldEditor,
    WarrantyType,
    CheckType,
    AddCheckType,
    MergeDialog,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      AddDialogVisible: false,
      AddCheckTypeVisible: false,
      DataFieldEditorVisible: false,
      MergeDialogVisible: false,
      page: {
        total: 0,
        size: 9999,
        current: 1,
      },
      visible: true,
      types: [],
      type: '',
      templates: [],
      searchVal: '',
      tableLable: '',
      tableHeight: null,
      tableLoading: false,
      tableSelected: [],
      tableData: [],

      itemData: null,
      componentNames: {
        '0': 'ServiceConfirmationTemplate',
        '100000000000000000000000000000000001': 'DeviceType',
        '100000000000000000000000000000000002': 'EquipmentBrand',
        '100000000000000000000000000000000003': 'Supplier',
        '100000000000000000000000000000000004': 'SystemType',
        '100000000000000000000000000000000005': 'FaultType',
        '100000000000000000000000000000000006': 'ServiceType',
        '100000000000000000000000000000000007': 'CompleteStatus',
        '100000000000000000000000000000000008': 'ProjectType',
        '100000000000000000000000000000000009': 'SecurityType',
        '100000000000000000000000000000000010': 'LogisticsCompany',
        '100000000000000000000000000000000011': 'CountUnit',
        '100000000000000000000000000000000012': 'ContractQuotationType',
        '100000000000000000000000000000000013': 'CustomerApproval',
        '100000000000000000000000000000000014': 'ReceiptAddress',
        '100000000000000000000000000000000015': 'InSite',
        '100000000000000000000000000000000016': 'InType',
        '100000000000000000000000000000000017': 'TicketSource',
        '100000000000000000000000000000000018': 'ProjectTaskType',
        '100000000000000000000000000000000019': 'SiteCategory',
        '100000000000000000000000000000000026': 'CheckType',
        '100000000000000000000000000000000027': 'WarrantyType',
      },
      unalterable: [
        '202000000000000000000000000000000001',
        '303000000000000000000000000000000001',
        '400000000000000000000000000000000001',
        '500000000000000000000000000000000001',
        '600000000000000000000000000000000000',
      ],
      countItems: [
        '100000000000000000000000000000000001',
        '100000000000000000000000000000000002',
        '100000000000000000000000000000000003',
        '100000000000000000000000000000000004',
        '100000000000000000000000000000000005',
        '100000000000000000000000000000000006',
      ],
      mergeItem: [
        '100000000000000000000000000000000001',
        '100000000000000000000000000000000002',
        '100000000000000000000000000000000003',
        '100000000000000000000000000000000004',
        '100000000000000000000000000000000005',
        '100000000000000000000000000000000006',
        '100000000000000000000000000000000014',
      ],
      countProp: 'count'
    }
  },
  computed: {
    currentType: function () {
      let item = this.types.find(item => item.id === this.type);
      return item || {};
    },
    componentName: function () {
      return this.componentNames[this.type];
    },
  },
  watch: {
    'currentType': function () {
      this.tableLable = this.currentType.name;
      this.$refs.table.clearSelection();
    },
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.typeTable.$el.clientHeight
    })
    // this.page.size = Math.ceil(this.tableHeight / 35);
    this.getField();
  },
  methods: {
    getField () {
      let param = {
        parent: '0',
        search: '',
      }
      GetDataFieldAll(param).then(res => {
        res.push({
          id: '0',
          name: 'Confirmation Template',
        })
        this.types = res;
        this.type = res.length > 0 ? res[0].id : '';
        // this.type = res.length > 0 ?  res[25].id : '';
        this.type = '100000000000000000000000000000000026';
        this.getTableData();
      })
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        parent: this.type,
      }
      this.tableLoading = true;
      if (this.type !== '0') {
        GetDataFieldList(param).then(this.dealTableData)
      } else {
        GetConfirmationTemplateList(param).then(this.dealTableData)
      }
    },
    dealTableData (res) {
      this.tableData = res.list;
      this.page.total = res.total;
      if (this.tableData.length > 0 && this.tableSelected.length === 0) {
        this.$nextTick(() => {
          this.$refs.table.toggleRowSelection(this.tableData[0])
        })
      }
      this.$refs.table.doLayout();
      this.tableLoading = false;
    },
    searchData: debounce(function () {
      this.page.current = 1;
      this.$refs.table.clearSelection();
      this.getTableData();

    }, 300),
    // 切换类型
    typeChange () {
      this.searchVal = '';
      this.page.current = 1;
      if (this.type === '100000000000000000000000000000000004') {
        this.countProp = 'count2';
      } else {
        this.countProp = 'count';
      }
      // this.countProp = 'count';

      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.itemData = null;
      if (this.type === '100000000000000000000000000000000026') {
        this.AddCheckTypeVisible = true;
      } else {
        this.AddDialogVisible = true;
      }
    },
    // 显示编辑
    dblclickEdit (e) {
      if (!this.permit.edit) return;

      this.itemData = e;
      if (this.type === '100000000000000000000000000000000026') {
        this.AddCheckTypeVisible = true;
      } else {
        this.AddDialogVisible = true;
      }
    },
    // 删除设备类型
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.some(item => this.unalterable.includes(item.id))) return this.$message.warning('Other cannot be deleted.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: this.tableSelected.map(item => item.id).join(',') }

        if (this.type !== '0') {
          DelDataField(param).then(this.dealDel)
        } else {
          DelConfirmationTemplate(param).then(this.dealDel)
        }
      }).catch(() => { });
    },
    dealDel (res) {
      if (res.success) {
        this.$refs.table.clearSelection();
        this.getTableData();
        this.$message.success(this.$i18n.t('message.del'))
      }
    },
    // 显示合并弹框
    showMerge () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.MergeDialogVisible = true;
    },
    // 获取id
    rowClick (row, column, event) {
      this.$refs.table.clearSelection();
      this.$refs.table.toggleRowSelection(row)
    },

    // 显示字段编辑弹框 
    showFieldEditor () {
      this.DataFieldEditorVisible = true;
    },
    // 
    mergeSubmit () {
      this.$refs.table.clearSelection();
      this.getTableData();
    },
    //把每一行的索引放进row
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('DataMaintenanceSearch');
        this.permit.add = permission.includes('DataMaintenanceAdd');
        this.permit.edit = permission.includes('DataMaintenanceEdit');
        this.permit.del = permission.includes('DataMaintenanceDelete');
      }
    }

  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.col-box,
.row-box {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
    padding: 10px;
  }
}

.title-box {
  line-height: 19px;
  border-bottom: 1px solid #e6e9ed;
  padding: 10px 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-btn {
    cursor: pointer;
  }
  .close-btn {
    cursor: pointer;
    &:hover {
      background: #b4bccc;
      color: #fff;
      border-radius: 5px;
    }
  }
}

.condition-box {
  display: flex;
  .el-select {
    flex: 1;
    ::v-deep .el-input__inner {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
  .el-button {
    border-left: none;
    margin: 0;
  }
}

.dialog-form {
  .el-select {
    width: 100%;
  }
}

.type-table {
  height: calc(100% - 32px - 46px - 46px - 55px - 10px);
}
.setting {
  width: 50px;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
.el-table {
  outline: none;
  ::v-deep .current-row td {
    background-color: #91e8d9 !important;
  }
}
</style>