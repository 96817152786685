<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="700px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="user-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="deviceTypeId"
              :label="$t('dataMaintenance.faultType.dialog2.label1')"
            >
              <el-select
                v-model="dialog.deviceTypeId"
                filterable
              >
                <el-option
                  v-for="item in itemTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('dataMaintenance.faultType.dialog2.label2')">
          <el-button @click="showAdd"> {{$t('operation.edit')}} </el-button>
          <el-button @click="delItem"> {{$t('operation.del')}} </el-button>
          <el-button @click="viewAll"> {{$t('dataMaintenance.faultType.dialog2.btn1')}} </el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-table
          border
          :data="tableData"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            prop="faultTypeId"
            :label="$t('dataMaintenance.faultType.dialog2.col1')"
          >
            <template slot-scope="scope">
              <el-select
                size="mini"
                v-model="scope.row.faultTypeId"
                filterable
              >
                <el-option
                  v-for="item in faultTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="subType"
            :label="$t('dataMaintenance.faultType.dialog2.col2')"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.subType"
                @change="updateKey(scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="description"
            :label="$t('dataMaintenance.faultType.dialog2.col3')"
          >
            <template slot-scope="scope">
              <div class="update-box">
                <el-input
                  size="mini"
                  v-model="scope.row.description"
                ></el-input>
                <i
                  class="el-icon-refresh"
                  @click="updateKey(scope.$index)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="70"
            prop="indexKeyword"
            :label="$t('dataMaintenance.faultType.dialog2.col4')"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.indexKeyword"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            min-width="70"
            prop="heat"
            :label="$t('dataMaintenance.faultType.dialog2.col5')"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.heat"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <!-- 设备弹框 -->
    <add-issue
      :visible.sync="AddIssueVisible"
      :device-type-id="dialog.deviceTypeId"
      :ids="ids"
      @submit="deviceSubmit"
    ></add-issue>
  </div>
</template>

<script>
// 组件
import AddIssue from './AddIssue'
// 接口
import {
  AddFaultTypeItem,
  DelFaultDevice,
  GetFaultTypeItem,
} from '@/api/warehouse/dataMaintenance/faultType'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    AddIssue
  },
  props: ['visible', 'data', 'items', 'total'],
  data () {
    return {
      AddIssueVisible: false,
      itemTypes: [],
      faultTypes: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        deviceTypeId: '',
      },
      rules: {
        deviceTypeId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      ids: [],
    }
  },
  computed: {
    deviceName: function () {
      return this.itemTypes.find(item => item.id === this.dialog.deviceTypeId).name;
    },
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('dataMaintenance.faultType.dialog2.title2'),
              deviceTypeId: this.data.deviceTypeId,
            });
            this.tableData = [this.data]
          })
        } else {
          this.tableData = [];
          this.dialog.title = this.$i18n.t('dataMaintenance.faultType.dialog2.title1');
        }
        // 获取故障类型
        GetDataFieldAll({ parent: '100000000000000000000000000000000005' }).then(res => this.faultTypes = res)

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    // 获取设备类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000001' }).then(res => this.itemTypes = res)

  },
  methods: {
    // 显示设备类型弹框
    showAdd () {
      this.ids = this.tableData.map(item => item.faultTypeId)
      this.AddIssueVisible = true;
    },
    // 解除设备类型绑定
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      let checkIndex = this.tableSelected.map(item => item.rowIndex);
      this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
    },
    // 设备类型提交
    deviceSubmit (data) {
      let ids = this.tableData.map(item => item.faultTypeId);
      let tdata = data.filter(item => !ids.includes(item.id)).map(item => {
        return {
          id: null,
          faultTypeId: item.id,
          faultTypeName: item.name,
          description: '',
          indexKeyword: '',
          heat: '',
          subType: '',
        }
      })
      this.tableData = this.tableData.concat(tdata);
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = this.tableData.map(item => {
          let data = {
            id: item.id,
            deviceTypeId: this.dialog.deviceTypeId,
            deviceName: this.deviceName,
            faultTypeId: item.faultTypeId,
            subType: item.subType,
            description: item.description,
            indexKeyword: item.indexKeyword,
            heat: item.heat,
            // subTypeId: this.dialog.subTypeId,
            issId: item.issId,
          }
          return data;
        });

        this.dialog.loading = true;
        AddFaultTypeItem(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.add'))
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            this.$message.success(res.data)
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    // 更新key
    updateKey (index) {
      let item = this.tableData[index];
      item.description = this.deviceName + ' ' + item.subType;
      this.$set(this.tableData, index, item);
    },
    viewAll () {
      let param = {
        deviceTypeId: this.dialog.deviceTypeId,
      }
      GetFaultTypeItem(param).then(res => {
        this.tableData = res;
      })
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    width: 150px;
    text-align: left;
  }
  .el-switch {
    width: 100px;
  }
}

.update-box {
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
    cursor: pointer;
    font-size: 18px;
  }
}
</style>