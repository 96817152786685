<template>
  <div class="wrap">
    <el-row>
      <dash-board-condition
        :layout.sync="layoutData.layoutIndex"
        parent="KPI"
      ></dash-board-condition>
    </el-row>
    <el-row :gutter="20">
      <el-col
        v-for="(item,index) in layoutData.content"
        :key="index"
        :span="layoutData.layout[layoutData.layoutIndex]"
        v-resize="resize"
      >
        <el-card class="chart-card">
          <div class="title">
            <div class="select-wrap">
              <el-select
                v-model="layoutData.content[index].type"
                size="small"
              >
                <el-option value="Store KPI"></el-option>
                <el-option value="Cashier KPI"></el-option>
              </el-select>
              <div class="top-wrap">
                <span>Top</span>
                <el-select
                  v-model="layoutData.content[index].top"
                  size="small"
                >
                  <el-option :value="10"></el-option>
                </el-select>
              </div>
            </div>
            <div class="tooltip">
              <i
                class="btn el-icon-setting"
                @click="showChartSet"
              ></i>
              <i
                class="btn el-icon-arrow-down"
                @click="collapseChange"
              ></i>
              <i
                class="btn el-icon-close"
                @click="cardClose(index)"
              ></i>
            </div>
          </div>
          <div
            class="chart"
            :id="'chart' + index"
          ></div>
        </el-card>

        <el-card class="chart-card">
          <div class="title">
            <div class="input-box">
              <span>KPI Item</span>
              <el-input
                v-model="layoutData.content[index].searchVal"
                placeholder="Search"
                size="small"
                class="search-input"
              ></el-input>
            </div>
            <div class="tooltip">
              <i
                class="btn el-icon-setting"
                @click="showChartSet"
              ></i>
              <i
                class="btn el-icon-arrow-down"
                @click="collapseChange"
              ></i>
              <i
                class="btn el-icon-close"
                @click="cardClose(index)"
              ></i>
            </div>
          </div>
          <el-collapse v-model="layoutData.content[index].collapseActive">
            <el-collapse-item
              :title="'KPI Item'+collapseItem"
              v-for="collapseItem in 3"
              :key="collapseItem"
              :name="collapseItem"
            >
              <div
                class="left-chart"
                :id="'gauge' +( collapseItem + 3 * index)"
              ></div>
              <div
                class="right-chart"
                :id="'line' + (collapseItem + 3 * index)"
              ></div>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
    </el-row>

    <!-- 总览设置弹框 -->
    <overview :visible.sync="OverviewVisible"></overview>
    <!-- 图表设置弹窗 -->
    <chart-setting :visible.sync="ChartSettingVisible"></chart-setting>

  </div>

</template>

<script>
import Overview from '@/components/DashBoardDialog/Overview'
import ChartSetting from '@/components/DashBoardDialog/ChartSetting'
import DashBoardCondition from '@/components/DashBoardCondition'
export default {
  components: {
    Overview,
    ChartSetting,
    DashBoardCondition,
  },
  name: 'KpiDashboard',
  data () {
    return {
      OverviewVisible: false,
      ChartSettingVisible: false,
      layoutData: {
        content: [],
        layout: [24, 12, 8],
        layoutIndex: null,
      },
      charts: [],
      resizeClock: null,
      dataAccessGroups: [],
    }
  },
  watch: {
    'layoutData.layoutIndex': function (newVal) {
      this.layoutData.content = [];
      for (var i = 0; i <= newVal; i++) {
        this.layoutData.content.push({
          type: 'Store KPI',
          top: '10',
          searchVal: '',
          collapseActive: [1, 2, 3]
        })
      }
      this.changeLayout();
    }
  },
  created () {
    this.layoutData.layoutIndex = 1;
  },
  mounted () {
    this.changeLayout();
  },
  methods: {
    // 布局变换
    changeLayout () {
      this.$nextTick(() => {
        this.layoutData.content.forEach((item, index) => {
          this.barChart('chart' + index);
          for (var i = 0; i < 3; i++) {
            this.gaugeChart('gauge' + (i + 3 * index + 1))
            this.lineChart('line' + (i + 3 * index + 1))
          }

        })
      });
    },
    // 关闭卡片
    cardClose (index) {
      this.layoutData.layoutIndex--;
    },
    // 页面变化
    resize () {
      clearTimeout(this.resizeClock);
      this.resizeClock = setTimeout(() => {
        this.charts.forEach(item => item.resize())
      }, 300)
    },

    lineChart (id) {
      // 基于准备好的dom，初始化echarts实例
      var myCharts = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: 0,
          right: 10,
          top: 10,
          bottom: 10,
          containLabel: true,
        },
        color: ['#26B99A', '#176475'],
        tooltip: {
          confine: true,
        },
        xAxis: {
          type: 'category',
          splitLine: {
            show: true,
          },
          boundaryGap: false,
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
        },
        yAxis: {
          show: true,
          type: 'value'
        },
        series: [{
          name: '邮件营销',
          type: 'line',
          areaStyle: { opacity: 0.3 },
          smooth: true,
          data: [100, 20, 100, 20, 100, 20, 100]
        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      myCharts.setOption(option, true);
      this.charts.push(myCharts)
    },
    barChart (id) {
      // 基于准备好的dom，初始化echarts实例
      var myCharts = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: 0,
          right: 20,
          top: 20,
          bottom: 0,
          containLabel: true,
        },
        color: ['#26B99A', '#176475'],
        tooltip: {
          confine: true,
        },
        xAxis: {
          type: 'category',
          splitLine: {
            show: true,
          },
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
        },
        yAxis: {
          show: true,
          type: 'value'
        },
        series: [{
          name: '邮件营销',
          type: 'bar',
          areaStyle: { opacity: 0.3 },
          smooth: true,
          data: [100, 20, 100, 20, 100, 20, 100]
        }, {
          name: '联盟广告',
          type: 'bar',
          areaStyle: { opacity: 0.3 },
          smooth: true,
          data: [20, 100, 20, 100, 20, 100, 20]
        },]
      };

      // 使用刚指定的配置项和数据显示图表。
      myCharts.setOption(option, true);
      this.charts.push(myCharts)
    },
    gaugeChart (id) {
      // 基于准备好的dom，初始化echarts实例
      var myCharts = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: 0,
          right: 20,
          top: 20,
          bottom: 0,
          containLabel: true,
        },
        title: {
          text: 'Actual：' + '90%',
          x: 'center',
          bottom: 10,
          textStyle: { fontSize: 14 },
        },

        series: [{
          name: '邮件营销',
          type: 'gauge',
          min: 0,
          max: 100,
          detail: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 20,
              color: [[0.9, '#71B37C'], [1, '#CCCCCC']]
            }
          },
          itemStyle: {
            color: '#000',
          },
          tooltip: {
            confine: true,
          },
          data: [{ value: 90 }]
        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      myCharts.setOption(option, true);
      this.charts.push(myCharts)
    },
    // 图表设置
    showChartSet () {
      this.ChartSettingVisible = true;
    },

    // 卡片收缩
    collapseChange (e) {
      let el = e.path[4];

      if (el.classList.contains('collapse')) {
        el.classList.remove('collapse')
        setTimeout(() => {
          el.style.height = '';
        }, 300);
      } else {
        el.style.height = el.offsetHeight + 'px';
        setTimeout(() => {
          el.classList.add('collapse')
        }, 1);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100%;
  padding: 15px;
}

.chart-card {
  margin-bottom: 15px;
  ::v-deep .el-card__body {
    padding-top: 7px;
  }
  .chart {
    height: 335px;
  }
}

.title {
  padding-bottom: 5px;
  // border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tooltip {
    color: #535353;
    right: 10px;
    top: 5px;
    cursor: pointer;
    user-select: none;
    .btn {
      border-radius: 5px;
      &:hover {
        background: #b4bccc;
        color: #fff;
      }
    }
  }

  .select-wrap {
    display: flex;
    .el-select {
      width: 120px;
    }
    .top-wrap {
      margin-left: 20px;
      .el-select {
        width: 80px;
      }
    }
  }
}
.input-box {
  width: 60%;
  .search-input {
    width: calc(100% - 100px);
    margin-left: 20px;
  }
}
.collapse {
  height: 45px !important;
}
.right-chart,
.left-chart {
  width: 50%;
  height: 200px;
  float: left;
}
.left-chart {
  width: 200px;
}
.right-chart {
  width: calc(100% - 200px);
}
</style>