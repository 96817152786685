<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <div class="left-box">
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            @command="menuClick"
          >
            <el-button
              class="menu-btn"
              size="medium"
            >{{$t('newProjects.ganttChart.btn1')}}</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="import">{{$t('newProjects.ganttChart.btn3')}}</el-dropdown-item>
              <el-dropdown-item command="setBase">{{$t('newProjects.ganttChart.btn4')}}</el-dropdown-item>
              <el-dropdown-item command="export">{{$t('newProjects.ganttChart.btn5')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button-group class="input-group">
            <el-button
              size="medium"
              icon="el-icon-plus"
              @click="treeOperate('add')"
            ></el-button>
            <el-button
              size="medium"
              icon="el-icon-top"
              @click="treeOperate('top')"
            ></el-button>
            <el-button
              size="medium"
              icon="el-icon-bottom"
              @click="treeOperate('bottom')"
            ></el-button>
            <el-button
              size="medium"
              icon="el-icon-back"
              @click="treeOperate('left')"
            ></el-button>
            <el-button
              size="medium"
              icon="el-icon-right"
              @click="treeOperate('right')"
            ></el-button>
            <el-button
              size="medium"
              icon="el-icon-delete"
              @click="treeOperate('del')"
            ></el-button>
            <el-button
              size="medium"
              icon="el-icon-set-up"
              @click="treeOperate('base')"
            ></el-button>
          </el-button-group>
        </div>
        <el-dropdown
          trigger="click"
          placement="bottom-start"
          @command="zoomClick"
        >
          <el-button size="medium">{{$t('newProjects.ganttChart.btn6')}}</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="day">{{$t('newProjects.ganttChart.btn7')}}</el-dropdown-item>
            <el-dropdown-item command="week">{{$t('newProjects.ganttChart.btn8')}}</el-dropdown-item>
            <el-dropdown-item command="month">{{$t('newProjects.ganttChart.btn9')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-row>
    <div class="chart-box">
      <div class="tree-box">
        <div
          class="header-box"
          @mouseup="headerClick"
          @contextmenu.prevent
        >
          <div class="item">#</div>
          <div
            v-show="header.col1"
            class="item"
          >{{$t('newProjects.ganttChart.col1')}}</div>
          <div
            v-show="header.col2"
            class="item"
          >{{$t('newProjects.ganttChart.col2')}}</div>
          <div
            v-show="header.col3"
            class="item"
          >{{$t('newProjects.ganttChart.col3')}}</div>
          <div
            v-show="header.col4"
            class="item"
          >{{$t('newProjects.ganttChart.col4')}}</div>
          <div
            v-show="header.col5"
            class="item"
          >{{$t('newProjects.ganttChart.col5')}}</div>
        </div>
        <My-tree
          ref="taskTree"
          class="task-tree"
          :data="treeData"
          :header="header"
          @treeClick="treeClick"
          @treeDblclick="treeDblclick"
          @scroll.native="berforScrollEvent1"
          @update="getTreeData"
        ></My-tree>
      </div>
      <div
        class="progress-wrap"
        ref="progressWrap"
      >
        <div
          class="timeline"
          ref="timeline"
        >
          <div
            v-for="(item,index) in monthLine"
            :key="index"
            class="month-item"
          >
            <div class="month">{{item.text}}</div>
            <div class="day-line">
              <div
                v-for="(item,index) in item.children"
                :key="index"
                class="day-item"
                :style="{
                  'width':timeWidth + 'px'
                }"
              >
                {{item.text}}
              </div>
            </div>
          </div>
        </div>

        <progress-tree
          ref="progressTree"
          class="progress-box"
          :data="treeData"
          :is-base="isBase"
          :item-width="itemWidth"
          :style="{
           'width':timelineWidth + 'px'
          }"
          @scroll.native="berforScrollEvent2"
        ></progress-tree>
      </div>
    </div>

    <!-- 任务弹框 -->
    <new-task
      :visible.sync="NewTaskVisible"
      :data="taskData"
      :type="taskType"
      @update="getTreeData"
    ></new-task>
    <!-- 标准任务 -->
    <standard-dialog
      :visible.sync="StandardDialogVisible"
      :id="projectsId"
      @update="getTreeData"
    ></standard-dialog>

    <!-- tab-dropdown -->
    <div
      class="tab-dropdown"
      ref="tabDropdown"
      v-show="dropdownVisble"
      @click.stop
      @contextmenu.prevent
    >
      <div
        v-for="item in 5"
        :key="item"
        class="item"
        @click="tabDropdown(item)"
      >
        <div class="check"><i
            v-show="header['col' + item]"
            class="el-icon-check"
          ></i></div>
        <span>{{$t('newProjects.ganttChart.col' + item)}}</span>
      </div>
    </div>
  </div>
</template>

<script>

// 组件
import MyTree from './MyTree'
import NewTask from './NewTask'
import StandardDialog from './StandardDialog'
import ProgressTree from './ProgressTree'
// 接口
import {
  GetTaskTree1,
  DelTask,
  SetBase,
  EditTaskTree,
} from '@/api/warehouse/projects'
export default {
  components: {
    MyTree,
    NewTask,
    StandardDialog,
    ProgressTree
  },
  data () {
    return {
      dropdownVisble: false,
      NewTaskVisible: false,
      StandardDialogVisible: false,
      projectsId: null,
      DateStart: null,
      DateEnd: null,
      monthLine: [],
      dayLine: [],
      commonYear: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      leapYear: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      treeIndex: 1,
      treeData: [],

      isBase: true,
      progressData: [],

      taskType: null,
      taskData: null,

      progressWidth: 0,
      itemWidth: 20,
      timeWidth: 0,
      timelineWidth: 0,

      header: {
        col1: true,
        col2: true,
        col3: true,
        col4: true,
        col5: false,
      },
      sing1: false,
      sing2: false,
      zoomType: 'day',
    }
  },
  watch: {
    'zoomType': function () {
      this.initTimeline();
    },
  },
  created () {
    this.projectsId = this.$route.query.id
    if (!!this.projectsId) {
      this.getTreeData()
    }

    document.body.addEventListener('click', this.showDropdown)
  },
  mounted () {
    this.progressWidth = this.$refs.progressWrap.clientWidth;
  },
  destroyed () {
    document.body.removeEventListener('click', this.showDropdown)
  },
  methods: {
    // 获取数据
    getTreeData () {
      GetTaskTree1(this.projectsId).then(res => {
        this.treeData = res;
        this.treeIndex = 1;
        this.progressData = [];
        if (this.treeData.length > 0) {
          this.initTimeline();
          this.initTraversal(this.treeData)
        }
      })
    },
    // 初始化数据
    initTraversal (data, level = 0) {
      data.forEach(item => {
        item.index = this.treeIndex++;
        item.level = level;
        item.check = false;
        item.active = false;
        item.editStart = false;
        item.editEnd = false;
        if (!!item.startDate) {
          switch (this.zoomType) {
            case 'day':
              item.left = this.computeTime(this.monthLine[0].date, item.startDate) - 1;
              break;
            case 'week':
              break;
            case 'month':
              item.left = this.computeTime(this.monthLine[0].children[0].date, item.startDate) - 1;
              break;
          }
          item.duration = this.computeTime(item.startDate, item.endDate);
        }
        if (!!item.baseStartDate) {
          switch (this.zoomType) {
            case 'day':
              item.baseLeft = this.computeTime(this.monthLine[0].date, item.baseStartDate) - 1;
              break;
            case 'week':
              break;
            case 'month':
              item.baseLeft = this.computeTime(this.monthLine[0].children[0].date, item.baseStartDate) - 1;
              break;
          }
          item.baseDuration = this.computeTime(item.baseStartDate, item.baseEndDate);
        }
        this.progressData.push(item);

        if (!!item.childrens) {
          this.initTraversal(item.childrens, level + 1)
        }
      })
    },
    // 初始化时间轴
    initTimeline () {
      let item = this.treeData.find(item => !!item.startDate);
      if (!item) {
        let date = new Date();
        this.monthLine = [{
          date: `${date.getFullYear()}-${date.getMonth()}`,
          year: date.getFullYear(),
          month: date.getMonth(),
        }]
        return;
      }
      this.monthLine = [];
      this.DateStart = item.startDate;
      this.DateEnd = 0;
      this.treeData.forEach(item => {
        if (new Date(this.DateEnd) < new Date(item.endDate)) {
          this.DateEnd = item.endDate;
        }
      })
      let start = this.DateStart.split('-').map(item => parseInt(item));
      let end = this.DateEnd.split('-').map(item => parseInt(item));
      let count = 0;

      switch (this.zoomType) {
        case 'day':
          // let start = this.DateStart.split('-');
          // let end = this.DateEnd.split('-');
          for (var i = start[0]; i <= end[0]; i++) {
            for (var j = 1; j <= 12; j++) {
              let day = (i % 4 == 0 && i % 100 != 0) || (i % 400 == 0) ? this.leapYear[j - 1] : this.commonYear[j - 1];
              this.monthLine.push({
                text: `${i}-${j}`,
                date: `${i}-${j}`,
                year: i,
                month: j,
                children: new Array(day).fill(1).map((item, index) => {
                  return {
                    text: index + 1
                  }
                })
              })
            }
          }
          this.monthLine = this.monthLine.slice(start[1] - 1, (end[0] - start[0]) * 12 + end[1])

          this.monthLine.forEach(item => {
            count += (item.year % 4 == 0 && item.year % 100 != 0) || (item.year % 400 == 0) ? this.leapYear[item.month - 1] : this.commonYear[item.month - 1];
          })
          this.timeWidth = this.progressWidth > count * 20 ? this.progressWidth / count : 20;
          this.itemWidth = this.timeWidth;
          break;
        case 'week':
          break;
        case 'month':
          for (var i = start[0]; i <= end[0]; i++) {
            this.monthLine.push({
              text: i,
              year: i,
              children: new Array(12).fill(1).map((item, index) => {
                return {
                  text: `${index + 1}月`,
                  date: `${i}-${index + 1}`,
                }
              })
            })
          }

          this.monthLine[this.monthLine.length - 1].children = this.monthLine[this.monthLine.length - 1].children.slice(0, end[1])
          this.monthLine[0].children = this.monthLine[0].children.slice(start[1] - 1)
          this.monthLine.forEach(item => {
            count += item.children.length;
          })
          this.timeWidth = this.progressWidth > count * 20 ? this.progressWidth / count : 20;
          this.itemWidth = this.timeWidth / 30;
          break;
      }
      this.$nextTick(() => {
        this.timelineWidth = this.$refs.timeline.clientWidth;
      })

    },
    // 点击任务树
    treeClick (e, data) {
      let el = document.querySelector('.task-tree .item-box.active');
      if (el && data.id !== el.dataset.id) {
        let item = this.getNode(el.dataset.id);
        item.active = false;
        item.editStart = false;
        item.editEnd = false;
      }
      data.active = true;
      this.treeData = JSON.parse(JSON.stringify(this.treeData));
      this.taskData = data;
    },
    // 双击任务树
    treeDblclick (data) {
      this.taskType = 2;
      this.NewTaskVisible = true;
    },
    treeOperate (type) {
      if (!this.taskData && (type !== 'add' && type !== 'base' && type !== 'del')) return this.$message.warning('Please select at least one item.');

      switch (type) {
        case 'add': //添加
          this.taskType = 1;
          this.NewTaskVisible = true;
          break;
        case 'top':
          var rootChild;
          if (this.taskData.parentId == 0) {
            rootChild = this.treeData
          } else {
            rootChild = this.getNode(this.taskData.parentId).childrens;
          }
          var old = rootChild.findIndex(item => item.id === this.taskData.id)
          if (old === 0) {
            if (this.taskData.parentId == 0) return;
            var parentNode = this.getNode(this.taskData.parentId);
            var root;
            rootChild.splice(old, 1)
            if (parentNode.parentId == 0) {
              rootChild = this.treeData;
            } else {
              root = this.getNode(parentNode.parentId);
              rootChild = root.childrens;
            }
            var parentIndex = rootChild.findIndex(item => item.id === parentNode.id);

            if (parentNode.parentId == 0) {
              this.taskData.parentId = 0;
            } else {
              this.taskData.parentId = root.id;
            }
            rootChild.splice(parentIndex, 0, this.taskData);
          } else {
            var temp = rootChild[old - 1];
            this.$set(rootChild, old - 1, this.taskData)
            this.$set(rootChild, old, temp)
          }
          this.updateData();
          this.saveTree();
          break;
        case 'bottom':
          var rootChild;
          if (this.taskData.parentId == 0) {
            rootChild = this.treeData
          } else {
            rootChild = this.getNode(this.taskData.parentId).childrens;
          }
          var old = rootChild.findIndex(item => item.id === this.taskData.id)
          if (old === rootChild.length - 1) {
            if (this.taskData.parentId == 0) return;
            var parentNode = this.getNode(this.taskData.parentId);
            var root;
            rootChild.splice(old, 1)
            if (parentNode.parentId == 0) {
              rootChild = this.treeData;
            } else {
              root = this.getNode(parentNode.parentId);
              rootChild = root.childrens;
            }
            var parentIndex = rootChild.findIndex(item => item.id === parentNode.id);

            if (parentNode.parentId == 0) {
              this.taskData.parentId = 0;
            } else {
              this.taskData.parentId = root.id;
            }
            rootChild.splice(parentIndex + 2, 0, this.taskData);
          } else {
            var temp = rootChild[old + 1];
            this.$set(rootChild, old + 1, this.taskData)
            this.$set(rootChild, old, temp)
          }
          this.updateData();
          this.saveTree();
          break;
        case 'left':
          if (this.taskData.parentId == 0) return;
          var parentNode = this.getNode(this.taskData.parentId);
          var root;
          var rootChild;
          if (parentNode.parentId == 0) {
            rootChild = this.treeData;
          } else {
            root = this.getNode(parentNode.parentId);
            rootChild = root.childrens;
          }
          var parentIndex = rootChild.findIndex(item => item.id === parentNode.id);
          var old = parentNode.childrens.findIndex(item => item.id === this.taskData.id)
          var newTree = parentNode.childrens.splice(old).map(item => {
            item.parentId = this.taskData.id;
            return item;
          });
          newTree.shift();
          this.taskData.childrens.push(...newTree)
          if (parentNode.parentId == 0) {
            this.taskData.parentId = 0;
          } else {
            this.taskData.parentId = root.id;
          }
          rootChild.splice(parentIndex + 1, 0, this.taskData);
          this.updateData();
          this.saveTree();
          break;
        case 'right':
          var rootChild;
          if (this.taskData.parentId == 0) {
            rootChild = this.treeData;
          } else {
            rootChild = this.getNode(this.taskData.parentId).childrens;
          }
          var old = rootChild.findIndex(item => item.id === this.taskData.id);
          if (old === 0) return;
          this.taskData.parentId = rootChild[old - 1].id;
          rootChild[old - 1].childrens.push(this.taskData);
          rootChild.splice(old, 1);

          this.updateData();
          this.saveTree();
          break;
        case 'del': // 删除
          var ids = [];
          var arr = [];
          this.checkTraversal(this.treeData, ids);
          if (ids.length === 0) return this.$message.warning('Please select at least one item.');
          arr = ids.map(item => this.delPromise(item));
          Promise.all(arr).then(res => {
            this.getTreeData();
            this.$message.success(this.$i18n.t('message.del'))
          })

          break;
        case 'base':
          this.isBase = !this.isBase;
          break;
      }
    },
    menuClick (command) {
      switch (command) {
        case 'import': // 导入标准任务
          this.StandardDialogVisible = true;
          break;
        case 'setBase': // 设置基线
          SetBase(this.projectsId).then(res => {
            if (res) {
              this.$message.success(this.$i18n.t('message.save'))
              this.getTreeData();
            }
          })
          break;
        case 'export': // 导出

          break;
      }
    },
    zoomClick (command) {
      this.zoomType = command;
    },
    computeTime (startDate, endData) {
      //开始时间
      let BirthDay = new Date(startDate);
      // 结束时间
      endData = !!endData ? new Date(endData) : new Date();

      //获取当前时间
      let timeold = (endData.getTime() - BirthDay.getTime()); //总豪秒数
      let e_daysold = timeold / (24 * 60 * 60 * 1000);
      let daysold = Math.floor(e_daysold); //相差天数
      return daysold + 1;
    },
    getNode (id, data = this.treeData) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          return data[i]
        } else if (data[i].childrens.length !== 0) {
          var result = this.getNode(id, data[i].childrens);
          if (result) return result;
        }
      }
    },
    // 提交遍历
    submitTraversal (data, result) {
      data.forEach((item, index) => {
        result.push({
          id: item.id,
          children: [],
        })
        if (item.childrens.length !== 0) {
          this.submitTraversal(item.childrens, result[index].children)
        }
      })
    },
    // 保存任务树结构
    saveTree () {
      let param = {
        projectId: this.projectsId,
        json: [],
      };
      this.submitTraversal(this.treeData, param.json);
      param.json = JSON.stringify(param.json)
      EditTaskTree(param)
    },
    updateData () {
      this.treeIndex = 1;
      this.progressData = [];
      this.initTraversal(this.treeData)
      this.taskData.active = true;
    },
    headerClick () {
      if (event.button == 2) {
        this.$refs.tabDropdown.style.left = event.layerX + 'px';
        this.$refs.tabDropdown.style.top = event.layerY + 'px';
        this.dropdownVisble = true;
      }
    },
    tabDropdown (type) {
      this.header[`col${type}`] = !this.header[`col${type}`]
    },
    showDropdown () {
      this.dropdownVisble = false;
    },

    berforScrollEvent1 () {
      if (!this.sing1) {
        this.$refs.taskTree.$el.addEventListener('scroll', this.scrollEvent1);
        this.sign1 = true;
        this.$refs.progressTree.$el.removeEventListener('scroll', this.scrollEvent2);
        this.sing2 = false;
      }
    },

    berforScrollEvent2 () {
      if (!this.sing2) {
        this.$refs.progressTree.$el.addEventListener('scroll', this.scrollEvent2);
        this.sign2 = true;
        this.$refs.taskTree.$el.removeEventListener('scroll', this.scrollEvent1);
        this.sing1 = false;
      }
    },

    scrollEvent1 () {
      this.$refs.progressTree.$el.scrollTo(0, this.$refs.taskTree.$el.scrollTop);
    },

    scrollEvent2 () {
      this.$refs.taskTree.$el.scrollTo(0, this.$refs.progressTree.$el.scrollTop);
    },
    // 获取选中遍历
    checkTraversal (data, result) {
      data.forEach((item, index) => {
        if (item.check) result.push(item.id);

        if (item.childrens.length !== 0) {
          this.checkTraversal(item.childrens, result)
        }
      })
    },
    // 删除Promise
    delPromise (id) {
      return new Promise((resolve, reject) => {
        DelTask(id).then(res => {
          if (res) resolve();
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.left-box {
  display: flex;
  .menu-btn {
    margin-right: 10px;
  }
}
.chart-box {
  height: calc(100% - 46px);
  display: flex;
  .tree-box {
    width: 700px;

    .task-tree {
      height: calc(100% - 40px);
      overflow: auto;
    }
    .header-box {
      height: 40px;
      border: 1px solid #dcdfe6;
      display: flex;
      .item {
        width: 100px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border-left: 1px solid #dcdfe6;
        text-align: center;
      }
      .item:nth-of-type(1) {
        width: 50px;
        border-left: none;
      }
      .item:nth-of-type(2) {
        // width: 200px;
        flex-grow: 1;
      }
    }
  }
  .progress-wrap {
    width: calc(100% - 500px);
    height: 100%;
    font-size: 14px;
    overflow-y: hidden;
    .timeline {
      height: 40px;
      white-space: nowrap;
      display: inline-block;
    }
    .month-item {
      height: 40px;
      text-align: center;
      display: inline-block;
      .month {
        height: 20px;
        line-height: 20px;
        border: 1px solid #dcdfe6;
        border-left: none;
      }
      .day-line {
        height: 20px;
        .day-item {
          width: 20px;
          height: 20px;
          line-height: 20px;
          border: 1px solid #dcdfe6;
          border-top: none;
          border-left: none;

          // border-right: 1px solid #dcdfe6;
          text-align: center;
          flex-shrink: 0;
          display: inline-block;
        }
      }
    }
  }
}
.progress-box {
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
  .progress {
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .milestone {
      height: 12px;
      width: 12px;
      background: #9932cc;
      transform: rotate(45deg);
    }
    .bar1 {
      height: 0px;
      border: 10px solid #49b1f3;
      border-bottom: 10px solid transparent;
    }
    .bar2 {
      height: 20px;
      line-height: 20px;
      color: #fff;
      text-align: right;
      background: #a4d246;
      .con {
        height: 100%;
        background: #63ae76;
      }
    }
    .bar3 {
      height: 10px;
      margin-top: 2px;
      background: #999999;
    }
  }
}
.tab-dropdown {
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  .item {
    display: flex;
    align-items: center;
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
    .check {
      width: 20px;
      margin-right: 5px;
      color: #66b1ff;
    }
  }
}
</style>