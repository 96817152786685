<template>
  <div class="wrap">
    <el-row>
      <condition
        :is-back="true"
        :templates-id="reportTemplateId"
        :common-param="commonParam"
      ></condition>
    </el-row>
    <el-row>
      <el-card>
        <div class="title">
          <span>Order Details</span>
          <div class="tooltip">
            <i
              class="btn"
              :class="{'el-icon-s-fold':!isOpen,'el-icon-s-unfold':isOpen}"
              @click="isOpenChange"
            ></i>
            <i
              class="btn el-icon-arrow-left"
              @click="scorllChange('prev')"
            ></i>
            <i
              class="btn el-icon-arrow-right"
              @click="scorllChange('next')"
            ></i>
            <!-- <i
              class="btn"
              :class="{'el-icon-picture-outline':openDiff,'el-icon-picture':!openDiff}"
              @click="openDiff = !openDiff"
            ></i> -->
            <!-- <el-checkbox v-model="openDiff"></el-checkbox>
            <el-color-picker
              class=""
              v-model="diffColor"
              size="mini"
            ></el-color-picker>
            <i
              class="btn el-icon-setting"
              @click="showChartSet"
            ></i> -->
            <i
              class="btn el-icon-arrow-down"
              @click="collapseChange"
            ></i>
            <i
              class="btn el-icon-close"
              @click="cardClose"
            ></i>
          </div>
        </div>
        <div
          class="scorll-wrap"
          ref="scorllWrap"
        >
          <div
            class="scorll-inner"
            ref="inner"
          >
            <el-card
              class="card-wrap"
              :class="{'collapse-order': !Order.visible}"
            >

              <div
                class="card-header list-header"
                :style="{background:Order.headerColor}"
              >
                <span class="card-title">Order List</span>
                <i
                  :class="{'el-icon-arrow-left': Order.visible,'el-icon-arrow-right': !Order.visible}"
                  @click="Order.visible = !Order.visible"
                ></i>
              </div>
              <el-row>
                <el-table
                  border
                  :row-class-name="tableRowClassName"
                  :data="Order.table1"
                  stripe
                  :height="200"
                >
                  <el-table-column
                    sortable
                    prop="branch_code"
                    label="Store"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    sortable
                    prop="order_no"
                    label="Order"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    sortable
                    label="Trans"
                    min-width="160"
                  >
                    <template slot-scope="scope">
                      {{scope.row.trans_datetime.replace('T',' ')}}
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>

              <el-row>
                <div class="condition-wrap">
                  <div class="condition-box">
                    <el-switch
                      v-model="Order.condition.enable"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    ></el-switch>
                    <div class="input-box">
                      <span class="label">Pre-Trans</span>
                      <el-input v-model="Order.condition.pre"></el-input>
                      <span>Mins</span>
                    </div>
                    <div class="input-box">
                      <span class="label">Post-Trans</span>
                      <el-input v-model="Order.condition.post"></el-input>
                      <span>Mins</span>
                    </div>
                    <el-button @click="getTimeOrder">OK</el-button>
                  </div>
                </div>
              </el-row>
              <el-row>
                <el-table
                  border
                  :data="Order.table2"
                  :row-class-name="tableRowClassName"
                  @row-click="rowClick($event,'table2')"
                  stripe
                >
                  <el-table-column
                    sortable
                    prop="branch_code"
                    label="Store"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    sortable
                    prop="order_no"
                    label="Order"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    sortable
                    label="Trans"
                    min-width="160"
                    prop="trans_datetime"
                  >
                    <template slot-scope="scope">
                      {{scope.row.trans_datetime.replace('T',' ')}}
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>

            </el-card>

            <el-card
              v-for="(item,index) in card"
              :key="index"
              v-show="item.show"
              class="card-wrap"
              :class="{
                'collapse-order': !item.visible,
                'order-wrap':index === 1 && item.visible,
              }"
            >
              <div
                class="card-header"
                :style="{background:item.headerColor}"
              >
                <span class="card-title">{{item.title}}</span>
                <i
                  :class="{'el-icon-arrow-left': item.visible,'el-icon-arrow-right': !item.visible}"
                  @click="item.visible = !item.visible"
                ></i>
              </div>
              <el-row>
                <el-collapse v-model="activeNames">
                  <el-collapse-item
                    title="Order Summary"
                    name="1"
                  >
                    <table class="summary-table">
                      <tr>
                        <td>Order Type</td>
                        <td>{{item.title}}</td>
                      </tr>
                      <tr>
                        <td>Order No</td>
                        <td>{{item.orderSummary.orderNo}}</td>
                      </tr>
                      <tr>
                        <td>Order Time</td>
                        <td>{{item.orderSummary.orderTime}}</td>
                      </tr>
                      <tr>
                        <td>Close Time</td>
                        <td>{{item.orderSummary.closeTime}}</td>
                      </tr>
                      <tr>
                        <td>Store ID</td>
                        <td>{{item.orderSummary.storeID}}</td>
                      </tr>
                      <tr>
                        <td>Staff Close ID</td>
                        <td>{{item.orderSummary.close_uid}}</td>
                      </tr>
                      <tr>
                        <td>Cashier ID</td>
                        <td>{{item.orderSummary.cashierID}}</td>
                      </tr>
                      <!-- <tr>
                        <td>Transaction Handler</td>
                        <td>{{item.orderSummary.transactionHandler}}</td>
                      </tr> -->
                      <tr>
                        <td>Tips</td>
                        <td>{{item.orderSummary.tips}}</td>
                      </tr>
                      <tr>
                        <td>Total Service Charge</td>
                        <td>{{item.orderSummary.serv_chg_amt}}</td>
                      </tr>
                      <tr>
                        <td class="total-amount">Total Amount</td>
                        <td>{{item.orderSummary.totalAmount}}</td>
                      </tr>
                      <tr>
                        <td>Total Payment</td>
                        <td>{{item.orderSummary.tender}}</td>
                      </tr>
                      <tr>
                        <td>Change</td>
                        <td>{{item.orderSummary.change}}</td>
                      </tr>
                    </table>
                  </el-collapse-item>
                  <el-collapse-item name="2">
                    <div
                      slot="title"
                      class="title"
                    >
                      <span>Item</span>
                      <i
                        class="el-icon-full-screen"
                        @click.stop="showItemData(item.itemData)"
                      ></i>
                    </div>
                    <el-table
                      border
                      :data="item.itemData"
                      :height="height.item"
                    >
                      <el-table-column
                        fixed
                        width="130"
                        label="Item Name"
                      >
                        <template slot-scope="scope">{{scope.row.subitem_seq > 0 ? '&nbsp;&nbsp;&nbsp;' + scope.row.item_edesc : scope.row.item_edesc}}</template>
                      </el-table-column>
                      <el-table-column
                        width="80"
                        label="Amount"
                        prop="amount"
                      >
                      </el-table-column>
                      <el-table-column
                        width="110"
                        label="Unit Price"
                        prop="unit_price"
                      ></el-table-column>
                      <el-table-column
                        width="70"
                        label="Quantity"
                        prop="item_qty"
                      ></el-table-column>
                      <el-table-column
                        label="PMT"
                        prop="pmt"
                      >
                      </el-table-column>
                      <el-table-column
                        width="120"
                        label="Services Chg(%)"
                        prop="serv_chg_amt"
                      >
                      </el-table-column>
                      <el-table-column
                        label="Description"
                        width="100"
                      ></el-table-column>
                      <!-- prop="item_edesc" -->

                      <el-table-column
                        label="Item ID"
                        width="130"
                        prop="item_code"
                      ></el-table-column>

                      <el-table-column
                        width="100"
                        label="PMT ID"
                        prop="pmt_no"
                      >
                      </el-table-column>
                      <el-table-column
                        label="Discount Details"
                        width="115"
                      ></el-table-column>
                      <el-table-column
                        label="Discount Handler"
                        width="115"
                      ></el-table-column>
                      <el-table-column
                        label="Discount Approval code"
                        width="160"
                      ></el-table-column>
                      <el-table-column
                        label="Discount Type"
                        width="105"
                      ></el-table-column>
                      <el-table-column
                        label="Key in/card"
                        width="100"
                      ></el-table-column>
                    </el-table>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Payment"
                    name="3"
                  >
                    <el-table
                      border
                      :data="item.paymentData"
                      :height="height.payment"
                    >
                      <el-table-column
                        width="130"
                        label="Type"
                        prop="pay_edesc"
                      ></el-table-column>
                      <el-table-column
                        width="80"
                        label="Amount"
                        prop="pay_amt"
                      ></el-table-column>
                      <el-table-column
                        width="110"
                        label="Card ID"
                        show-overflow-tooltip
                        prop="card_no"
                      >
                      </el-table-column>
                      <el-table-column
                        width="70"
                        label="Remain"
                      ></el-table-column>

                      <el-table-column
                        label="PayNO."
                        width="130"
                      ></el-table-column>
                    </el-table>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Line Void"
                    name="4"
                  >
                    <el-table
                      border
                      :data="item.lineVoidData"
                      :height="height.lvs"
                    >
                      <el-table-column
                        label="Line void time"
                        width="130"
                        prop="trans_datetime"
                        show-overflow-tooltip
                      ></el-table-column>
                      <el-table-column
                        label="Line void item Code"
                        width="130"
                        prop="item_code"
                      ></el-table-column>
                      <el-table-column
                        label="Item Qty"
                        width="130"
                        prop="item_qty"
                      ></el-table-column>
                      <el-table-column
                        label="Unit Price"
                        width="130"
                        prop="unit_price"
                      ></el-table-column>
                      <el-table-column
                        label="Prn cdesc"
                        width="130"
                        prop="prn_cdesc"
                      ></el-table-column>
                      <el-table-column
                        label="Prn edesc"
                        width="130"
                        prop="prn_edesc"
                      ></el-table-column>
                      <el-table-column
                        label="Field void"
                        width="130"
                        prop="field_void"
                      ></el-table-column>
                      <el-table-column
                        label="Trans type"
                        width="130"
                        prop="trans_type"
                      ></el-table-column>
                      <el-table-column
                        label="Field Recall"
                        width="130"
                        prop="field_recall"
                      ></el-table-column>
                      <el-table-column
                        label="Field Refund"
                        width="130"
                        prop="field_refund"
                      ></el-table-column>
                      <el-table-column
                        label="UID"
                        width="130"
                        prop="uid"
                      ></el-table-column>
                      <el-table-column
                        label="Item details"
                        width="100"
                        prop="xxx"
                      ></el-table-column>
                      <el-table-column
                        label="Handler and Approver"
                        width="140"
                        prop="xxx"
                      ></el-table-column>
                      <el-table-column
                        label="reason"
                        width="140"
                        prop="xxx"
                      ></el-table-column>
                    </el-table>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Release"
                    name="5"
                  >
                    <el-table
                      border
                      :data="item.releaseData"
                    >
                      <el-table-column
                        label="Releas time"
                        width="130"
                      ></el-table-column>
                      <el-table-column
                        label="Release item ID"
                        width="130"
                      ></el-table-column>
                      <el-table-column
                        label="Release Item details"
                        width="130"
                      ></el-table-column>
                      <el-table-column
                        label="Handler and Approver"
                        width="140"
                      ></el-table-column>
                      <el-table-column label="Reason"></el-table-column>
                    </el-table>
                  </el-collapse-item>
                  <el-collapse-item
                    title="CRM"
                    name="6"
                  >
                    <el-table
                      border
                      :data="item.CRMData"
                    >
                      <el-table-column
                        label="CRM ID"
                        width="130"
                      ></el-table-column>
                      <el-table-column
                        label="Action details"
                        width="130"
                      ></el-table-column>
                      <el-table-column label=""></el-table-column>
                    </el-table>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Open Key"
                    name="7"
                  >
                    <el-table
                      border
                      :data="item.openKeys"
                    >
                      <el-table-column
                        label="Open key type"
                        width="130"
                      ></el-table-column>
                      <el-table-column
                        label="Key descriptions"
                        width="130"
                      ></el-table-column>
                      <el-table-column label=""></el-table-column>
                    </el-table>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Table"
                    name="8"
                  >
                    <el-table
                      border
                      :data="item.tableData"
                    >
                      <el-table-column
                        label="Table No."
                        width="130"
                        prop="table_id"
                      ></el-table-column>
                      <el-table-column
                        label="Number of people"
                        width="130"
                        prop="nop"
                      ></el-table-column>
                      <el-table-column
                        label="Details"
                        width="130"
                        prop="xxx"
                      ></el-table-column>

                      <!-- <el-table-column
                        label="service charge"
                        width="130"
                        prop="serv_chg_amt"
                      ></el-table-column>
                      <el-table-column
                        label="Checkout clerk"
                        width="130"
                        prop="close_uid"
                      ></el-table-column> -->
                    </el-table>
                  </el-collapse-item>
                </el-collapse>
              </el-row>
            </el-card>
          </div>
        </div>
      </el-card>
    </el-row>

    <!-- 图表设置弹窗 -->
    <chart-setting :visible.sync="ChartSettingVisible"></chart-setting>

    <!-- item表格弹框 -->
    <item-dialog
      :visible.sync="ItemDialogVisible"
      :data="itemData"
    ></item-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import ChartSetting from '@/components/DashBoardDialog/ChartSetting'
import Condition from '@/pages/counterMode/components/Condition'
import ItemDialog from './components/ItemDialog'
// 接口
import { GetTimeOrder } from '@/api/counterMode'
export default {
  components: {
    ChartSetting,
    Condition,
    ItemDialog,
  },
  data () {
    return {
      ChartSettingVisible: false,
      ItemDialogVisible: false,
      reportTemplateId: null,
      activeNames: ['1', '2', '3'],

      openDiff: false,
      isOpen: false,
      diffColor: '#409EFF',
      scorllIndex: 0,
      height: {
        item: 78,
        payment: 78,
        lvs: 78,
      },
      Order: {
        visible: true,
        headerColor: '#BFD6EA',
        table1: [],
        table2: [],
        condition: {
          enable: true,
          orderNo: null,
          storeId: null,
          time: null,
          pre: 5,
          post: 5,
        },
      },
      card: [{
        visible: true,
        show: true,
        headerColor: '#3399FF',
        title: 'Pre Order',
        orderSummary: {},
        itemData: [],
        paymentData: [],
        lineVoidData: [],
        releaseData: [],
        CRMData: [],
        openKeys: [],
        tableData: [],
      }, {
        visible: true,
        show: true,
        headerColor: '#1ECCA9',
        title: 'Original Order',
        orderSummary: {},
        itemData: [],
        paymentData: [],
        lineVoidData: [],
        releaseData: [],
        CRMData: [],
        openKeys: [],
        tableData: [],
      }, {
        visible: true,
        show: true,
        headerColor: '#006CD9',
        title: 'Post Order',
        orderSummary: {},
        itemData: [],
        paymentData: [],
        lineVoidData: [],
        releaseData: [],
        CRMData: [],
        openKeys: [],
        tableData: [],
      }],
      commonParam: [],
      itemData: null,
    }
  },
  watch: {
    'Order.condition.enable': function (newVal) {
      if (!newVal) {
        this.card[1].visible = false;
        this.card[1].show = false;
      } else {
        this.card[1].show = true;
      }
    }
  },
  created (e) {
    this.commonParam = JSON.parse(sessionStorage.paramData)
    this.reportTemplateId = this.$route.query.templatesId;

    Object.assign(this.Order.condition, {
      orderNo: this.$route.query.no,
      storeId: this.$route.query.id,
      time: this.$route.query.time,
      date: formatDate(new Date(this.$route.query.time), 'yyyy-MM-dd 00:00:00'),
    })

    this.getTimeOrder();
  },
  methods: {
    // 点击表格
    rowClick (item, type) {
      switch (type) {
        case 'table1':
          break;
        case 'table2':
          if (item.order_no === this.Order.condition.orderNo) return;
          if (new Date(item.trans_datetime) < new Date(this.Order.condition.time)) {
            this.dealOrder(JSON.parse(JSON.stringify(item)), 0);
          } else {
            this.dealOrder(JSON.parse(JSON.stringify(item)), 2);
          }
          break;
      }
    },
    getTimeOrder () {
      let param = {
        orderNo: this.Order.condition.orderNo,
        storeId: this.Order.condition.storeId,
        transDatetime: this.Order.condition.time,
        preMins: parseInt(this.Order.condition.pre),
        postMins: parseInt(this.Order.condition.post),
      }
      GetTimeOrder(param).then(res => {
        let index = -1;
        res = res.map((item, itemIndex) => {
          item.items = !!item.items ? JSON.parse(item.items) : [];
          item.payments = !!item.payments ? JSON.parse(item.payments) : [];
          item.lvs = !!item.lvs ? JSON.parse(item.lvs) : [];
          if (item.order_no === this.Order.condition.orderNo) {
            index = itemIndex
          }
          return item;
        })

        if (index !== -1) {
          this.Order.table1 = [res[index]];
          this.dealOrder(res[index], 1);
        }
        if (res[index - 1]) {
          this.dealOrder(JSON.parse(JSON.stringify(res[index - 1])), 0);
        }
        if (res[index + 1]) {
          this.dealOrder(JSON.parse(JSON.stringify(res[index + 1])), 2);
        }
        this.Order.table2 = res;
      })
    },
    // 处理订单详情
    dealOrder (data, index) {
      for (let i = data.items.length - 1; i >= 0; i--) {
        data.items[i].amount = (data.items[i].unit_price * data.items[i].item_qty).toFixed(2);
        // 优惠券折扣信息
        if (data.items[i].deduct_amt !== 0) {
          let item = JSON.parse(JSON.stringify(data.items[i]));
          data.items[i].pmt_no = '';
          item.pmt = 'PMT';
          item.item_qty = 1;
          item.unit_price = 0 - item.deduct_amt;
          item.amount = 0 - item.deduct_amt;
          data.items.push(item)
        }
      }
      // 员工折扣信息
      if (data.deduct_amt !== 0) {
        data.items.push({
          item_edesc: 'Discount',
          item_qty: 1,
          unit_price: 0 - data.deduct_amt,
          amount: 0 - data.deduct_amt,
        })
      }
      this.card[index].orderSummary = {
        orderNo: data.order_no,
        orderTime: data.trans_datetime,
        closeTime: data.close_datetime,
        storeID: data.branch_code,
        cashierID: data.uid,
        transactionHandler: '',
        totalAmount: data.grand_total,
        close_uid: data.close_uid,
        serv_chg_amt: data.serv_chg_amt,
        tips: data.payments.reduce((total, item) => total + item.tips, 0).toFixed(2),
        change: data.payments.reduce((total, item) => total + item.change, 0).toFixed(2),
        tender: data.payments.reduce((total, item) => total + item.tender, 0).toFixed(2),
      }

      this.card[index].itemData = data.items;
      this.card[index].paymentData = data.payments;
      this.card[index].lineVoidData = data.lvs;

      this.card[index].tableData = [{
        table_id: data.table_id,
        nop: data.nop,
        serv_chg_amt: data.serv_chg_amt,
        close_uid: data.close_uid,
      }]
      this.height.item = this.height.item < (data.items.length + 1) * 35 + 8 ? (data.items.length + 1) * 35 + 8 : this.height.item;
      this.height.payment = this.height.payment < (data.payments.length + 1) * 35 + 8 ? (data.payments.length + 1) * 35 + 8 : this.height.payment;
      this.height.lvs = this.height.lvs < (data.lvs.length + 1) * 35 + 8 ? (data.lvs.length + 1) * 35 + 8 : this.height.lvs;
    },

    // 图表设置
    showChartSet () {
      this.ChartSettingVisible = true;
    },
    scorllChange (type) {
      let left = this.$refs.inner.style.left || 0;
      let _w = (this.$refs.scorllWrap.offsetWidth - 45) / 4 + 15;

      if (type === 'prev') {
        this.$refs.inner.style.left = parseInt(left) + _w + 'px';
        this.scorllIndex--;

      } else {
        this.$refs.inner.style.left = parseInt(left) - _w + 'px';
        this.scorllIndex++;
      }
    },
    collapseChange (e) {
      let el = e.path[4];

      if (el.classList.contains('collapse')) {
        el.classList.remove('collapse')
        setTimeout(() => {
          el.style.height = '';
        }, 300);
      } else {
        el.style.height = el.offsetHeight + 'px';
        setTimeout(() => {
          el.classList.add('collapse')
        }, 1);
      }
    },
    // 折叠面板全展开、收缩
    isOpenChange () {
      this.isOpen = !this.isOpen;
      if (this.isOpen)
        this.activeNames = ['1', '2', '3', '4', '5', '6', '7', '8']
      else
        this.activeNames = [];
    },
    cardClose (e) {
      e.path[5].remove();
    },
    // 显示表格弹框 
    showItemData (data) {
      this.itemData = data;
      this.ItemDialogVisible = true;
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.card[1].orderSummary.orderNo === row.order_no) return 'original-order';
      else if (this.card[0].orderSummary.orderNo === row.order_no) return 'pre-order';
      else if (this.card[2].orderSummary.orderNo === row.order_no) return 'post-order';
    }
  },

}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100%;
}
.chart-card {
  height: 400px;
  margin-bottom: 15px;
}
.title {
  padding-bottom: 5px;
  // border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  .tooltip {
    color: #535353;
    right: 10px;
    top: 5px;
    cursor: pointer;
    display: flex;
    user-select: none;
    .btn {
      margin: 0 3px;
      border-radius: 5px;
      &:hover {
        background: #b4bccc;
        color: #fff;
      }
    }
    .el-color-picker {
      width: 16px;
      height: 16px;
      margin: 0 3px;
      ::v-deep .el-color-picker__trigger {
        width: 16px;
        height: 16px;
        padding: 0;
      }
    }
  }
}

.table-wrap {
  padding-top: 15px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  .label {
    padding: 0;
    width: calc(100% - 30px);
  }
  .filter {
    padding: 0;
  }
}

.scorll-wrap {
  margin-top: 10px;
  overflow: hidden;
}
.scorll-inner {
  display: flex;
  transition: 0.5s;
  position: relative;
  left: 0;
}
.order-wrap {
  flex: 1;
}
.card-wrap {
  width: calc(100% / 4 - 11.25px);
  // width: 420px;
  margin-right: 15px;
  background: #f2f7fb;
  flex-wrap: nowrap;
  flex-shrink: 0;
  .el-table {
    ::v-deep .cell {
      font-size: 12px;
    }
  }
  ::v-deep .el-card__body {
    padding: 0;
  }
  &.collapse-order {
    width: 40px !important;
    ::v-deep .el-card__body {
      height: 100%;
      overflow: hidden;
    }
    .card-header {
      height: 100%;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
      i {
        margin: 0;
      }
      .card-title {
        transform: rotate(90deg);
        width: 200px;
        position: relative;
        top: 115px;
        height: 0;
        line-height: 0;
      }
    }
    .el-row {
      // display: none;
      opacity: 0;
    }
  }

  .card-header {
    width: 100%;
    padding: 15px;
    color: #fff;
    display: flex;
    justify-content: space-between;

    .card-title {
      font-size: 16px;
    }
    i {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-left: 5px;
      font-size: 14px;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: #b4bccc;
      }
    }
  }
  .list-header {
    color: #000;
  }
}
.condition-wrap {
  overflow: auto;
}
.condition-box {
  min-width: 360px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input-box {
    display: flex;
    .label {
      font-weight: bold;
    }
    .el-input {
      margin: 0 5px;
      width: 40px;
      height: 30px;
    }

    ::v-deep .el-input__inner {
      width: 40px;
      height: 30px;
      padding: 2px;
    }
  }
  .el-button {
    height: 30px;
    width: 30px;
    padding: 0;
  }
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  tr:nth-of-type(2n - 1) {
    background-color: #fff !important;
  }
  tr:nth-of-type(2n) {
    background-color: #e0ecf5 !important;
  }
  td:first-child {
    width: 110px;
  }
  td {
    font-size: 12px;
    // border: 1px solid #b9bcc1;
    height: 40px;
    color: #000;
    text-align: left;
    padding: 0 10px;
  }
  .total-amount {
    background-color: #aaffaa;
  }
}

.collapse {
  height: 35px !important;
}
.el-collapse-item {
  .title {
    width: 100%;
    padding: 0;
    padding-right: 5px;
    display: flex;
    align-items: center;
  }
}
::v-deep .el-collapse-item__header {
  padding: 0 15px;
  font-size: 14px;
}
::v-deep .el-collapse-item__content {
  padding: 0;
}
.el-table {
  ::v-deep tr {
    background-color: #fff !important;
  }
  ::v-deep td {
    border: none;
    text-align: left;
    color: #555;
  }
  ::v-deep th {
    color: #555 !important;
    text-align: left;
    background-color: #e0ecf5 !important;
  }

  ::v-deep .el-table__empty-block {
    .el-table__empty-text {
      color: #000;
    }
  }
  ::v-deep .original-order {
    background-color: #1ecca9 !important;
    td {
      background-color: #1ecca9 !important;
      color: #fff;
    }
  }
  ::v-deep .pre-order {
    background-color: #39f !important;
    td {
      background-color: #39f !important;
      color: #fff;
    }
  }
  ::v-deep .post-order {
    background-color: #006cd9 !important;
    td {
      background-color: #006cd9 !important;
      color: #fff;
    }
  }
}

::v-deep .el-collapse-item__wrap,
::v-deep .el-collapse-item__header {
  background: none;
  border-bottom: none;
}

.el-collapse-item:nth-of-type(1) {
  background-color: #e0ecf5;
}

.el-collapse-item:nth-of-type(2) {
  background-color: #bcd7ed;
}

.el-collapse-item:nth-of-type(3) {
  background-color: #abcde9;
}

.el-collapse-item:nth-of-type(4) {
  background-color: #8fbce0;
}

.el-collapse-item:nth-of-type(5) {
  background-color: #6fa8d7;
}
.el-collapse-item:nth-of-type(6) {
  background-color: #5f9fd3;
}
.el-collapse-item:nth-of-type(7) {
  background-color: #4992cd;
}
.el-collapse-item:nth-of-type(8) {
  background-color: #3584c4;
}

.el-collapse-item:nth-of-type(4),
.el-collapse-item:nth-of-type(5),
.el-collapse-item:nth-of-type(6),
.el-collapse-item:nth-of-type(7),
.el-collapse-item:nth-of-type(8) {
  ::v-deep .el-collapse-item__header {
    color: #fff;
  }
}
</style>