<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="序列号"
      :visible.sync="dialogVisible"
      center
      width="500px"
    >
      <div class="top-box">
        <div class="num-box">
          数量：{{page.total}}
        </div>
        <el-button
          class="add-btn"
          size="medium"
          @click="addItem"
        >{{$t('operation.add')}}</el-button>
      </div>
      <el-row>
        <el-table
          :row-class-name="tableRowClassName"
          :data="tableData.slice((page.current - 1) * page.size, page.current * page.size)"
        >
          <el-table-column
            :width="100"
            label="编号"
            type="index"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            label="序列号"
            prop="serialNumber"
          >
            <template slot-scope="scope">
              <el-input
                size="medium"
                v-model="scope.row.serialNumber"
              >
                <el-button
                  slot="append"
                  icon="el-icon-delete"
                  @click="delItem(scope.row.rowIndex)"
                  :tabindex="scope.row.rowIndex + 1"
                ></el-button>
              </el-input>
            </template>
          </el-table-column>
        </el-table>

      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口

export default {
  props: ['visible', 'data', 'maxCount'],
  data () {
    return {
      dialogVisible: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.tableData = this.data;
        this.page.total = this.tableData.length;
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 添加
    addItem () {
      if ((!!this.maxCount || this.maxCount === 0) && this.maxCount <= this.tableData.length) return;
      this.tableData.push({
        serialNumber: '',
      });
      this.page.total++;
    },
    // 删除
    delItem (index) {
      this.tableData.splice(index, 1);
      this.page.total--;
    },
    // 提交序列号
    submit () {
      if (this.isRepetition()) return this.$message.warning('序列号存在重复');
      if (this.tableData.some(item => item.serialNumber === '')) return this.$message.warning('有序列号未填');
      this.$emit('submit', this.tableData);
      this.dialogVisible = false;
    },
    // 检测序列号重复
    isRepetition () {
      let result = false;
      let serialNumber = this.tableData.map(item => item.serialNumber).join(';');
      result = this.tableData.some(item => {
        let re = new RegExp(`(?<=^|;)${item.serialNumber}(?=;|$)`, 'g');
        return serialNumber.match(re).length > 1;
      })
      return result;
    },
    indexMethod (index) {
      return (this.page.current - 1) * this.page.size + index + 1;
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  line-height: 36px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
</style>