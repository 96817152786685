import request from '@/utils/request'

// 查询故障类型列表
export const GetFaultTypeList = (data) => request({
  method: 'post',
  url: '/WarehouseFaultType/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改故障类型
export const AddFaultType = (data) => request({
  method: 'post',
  url: '/WarehouseFaultType/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除故障类型
export const DelFaultType = (data) => request({
  method: 'post',
  url: '/WarehouseFaultType/del.do',
  data: JSON.stringify(data)
})

// 查询故障类型子项列表
export const GetFaultTypeItemList = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItem/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增或修改故障类型子项
export const AddFaultTypeItem = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItem/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除故障类型子项
export const DelFaultTypeItem = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItem/del.do',
  data: JSON.stringify(data)
})

// 获取故障类型集合
export const GetFaultTypeAll = (data) => request({
  method: 'post',
  url: '/WarehouseFaultType/queryByCondition.do',
  data: JSON.stringify(data)
})

// 查询故障类型已关联的设备类型列表
export const GetByFaultDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryByFaultItemId.do',
  data: JSON.stringify(data)
})

// 查询故障类型未关联的设备类型列表
export const GetNoFaultDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseDeviceType/queryNoFaultItemId.do',
  data: JSON.stringify(data)
})


// 关联故障类型与设备类型
export const AddFaultDevice = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItemDeviceType/add.do',
  data: JSON.stringify(data)
})

// 删除故障类型关联的设备类型
export const DelFaultDevice = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItemDeviceType/del.do',
  data: JSON.stringify(data)
})

// 根据设备类型查询故障类型子项
export const GetFaultTypeByDeviceType = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItem/queryAllByDevice.do',
  data: JSON.stringify(data)
})

// 故障类型关联设备查询
export const GetItemTypeByFaultType = (data) => request({
  method: 'post',
  url: '/DataField/querySelectByISS.do',
  data: JSON.stringify(data)
})

// 设备关联故障类型查询
export const GetFaultTypeByItemType = (data) => request({
  method: 'post',
  url: '/DataField/querySelectByIT.do',
  data: JSON.stringify(data)
})

// 查询故障类型子项
export const GetFaultTypeItem = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItem/queryByCondition.do',
  data: JSON.stringify(data)
})

// 批量删除故障类型子项
export const BatchDelFaultTypeItem = (data) => request({
  method: 'post',
  url: '/WarehouseFaultTypeItem/deleteByMap.do',
  data: JSON.stringify(data)
})
