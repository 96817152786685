<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
        :timestamp="activity.createTime"
      >
        {{activity.msg}}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
// 全局方法
import { toLocaleDate } from '@/utils/common'
// 接口
import { GetSLATracking } from '@/api/warehouse/slaPolicies'

export default {
  props: ['info'],
  data () {
    return {
      activities: []
    }
  },
  methods: {
    // 初始化数据
    initData () {
      let param = { workOrderId: this.info.id }
      GetSLATracking(param).then(res => {
        this.activities = res;
      })
    },


  }
}
</script>

<style>
</style>