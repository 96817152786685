<template>
  <div>
    <el-row v-if="type == 2">
      <el-button-group>
        <el-button
          size="medium"
          @click="delItem"
        >{{$t('operation.del')}}</el-button>
        <el-button
          size="medium"
          @click="showAdd"
        >{{$t('operation.add')}}</el-button>
        <!-- <el-button
          size="medium"
          @click="showEidt"
        >{{$t('operation.edit')}}</el-button> -->
      </el-button-group>
    </el-row>
    <el-row>
      <el-table border        :data="tableData"
        @selection-change="val => tableSelected = val"
        v-loading="tableLoading"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          prop="createTime"
          :label="$t('orderStateUpdate.col10')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          prop="number"
          :label="$t('orderStateUpdate.col11')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          prop="statusName"
          :label="$t('orderStateUpdate.col12')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="nextHandleTime"
          :label="$t('orderStateUpdate.col13')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="210"
          prop="unfinishedReason"
          :label="$t('orderStateUpdate.col14')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          :label="$t('orderStateUpdate.col15')"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="showOrder(scope.row)"
            >{{$t('orderStateUpdate.newFormBtn')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <!-- 新增服务确认单 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('orderStateUpdate.newForm.title')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('orderStateUpdate.newForm.number')"
          prop="number"
        >
          <el-button-group class="input-group">
            <el-input
              v-model="dialog.number"
              class="search-input"
            ></el-input>
            <el-button @click="autoNumber">{{$t('operation.auto')}}</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item
          :label="$t('orderStateUpdate.newForm.status')"
          prop="state"
        >
          <el-select
            class="state-select"
            v-model="dialog.state"
            size="medium"
            filterable
          >
            <el-option
              v-for="item in states"
              :key="item.id"
              :value="item.id"
              :label="lang == 'en' ? item.enName:item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('orderStateUpdate.newForm.date')"
          prop="date"
        >
          <el-date-picker
            v-model="dialog.date"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="dialog.state === '00000000000000000000000000000000002'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('orderStateUpdate.newForm.Reasons')"
          prop="cause"
        >
          <el-input
            type="textarea"
            :rows="4"
            v-model="dialog.cause"
            :disabled="dialog.state === '00000000000000000000000000000000002'"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="statusSubmit(1)"
        >{{$t('operation.save')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="statusSubmit(2)"
        >{{$t('operation.saveAdd')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatUTCDate } from '@/utils/common'
// 接口
import {
  StatusUpdate,
  AutoConfirmOrderNumber,
  DelConfirmOrder,
} from '@/api/warehouse/serviceConfirmation'
import { GetConfirmOrderStatus } from '@/api/warehouse/serviceConfirmation'

export default {
  props: ['info'],
  data () {
    return {
      lang: localStorage.lang,
      type: null,
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      states: [],
      dialog: {
        visible: false,
        loading: false,
        number: '',
        state: '',
        name: 'zyl',
        date: null,
        cause: '',
      },
      rules: {
        state: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    'dialog.state': function (newVal) {
      if (newVal === "00000000000000000000000000000000002") {
        this.dialog.date = null;
        this.dialog.cause = '';
      }
    }
  },
  created () {
    this.type = this.$route.query.type;
    // 获取状态
    GetConfirmOrderStatus({}).then(res => this.states = res);
  },
  methods: {
    // 初始化数据
    initData () {
      this.tableData = this.info.confirmOrderList;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelConfirmOrder(param).then(res => {
          if (res.success) {
            this.$emit('update');
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    //显示新增
    showAdd () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.visible = true;
    },
    // 提交
    statusSubmit (type) {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          workOrderId: this.$route.query.id,
          number: this.dialog.number,
          statusId: this.dialog.state,
          nextHandleTime: formatUTCDate(this.dialog.date),
          unfinishedReason: this.dialog.cause,
        }
        this.dialog.loading = true;
        StatusUpdate(param).then(res => {
          if (res.success) {
            if (type == 1) {
              this.dialog.visible = false;
            } else {
              this.$refs.dialogForm.resetFields();
            }
            this.$emit('update');
            this.dialog.loading = false;
            this.$message.success(this.$i18n.t('message.save'))
          } else {
            this.dialog.loading = false;
            this.$message.warning(res.message)
          }
        })
      })
    },
    // 自动编号
    autoNumber () {
      AutoConfirmOrderNumber().then(res => {
        this.dialog.number = res;
      })
    },
    // 显示新增服务确认单页面
    showOrder (e) {
      this.$router.push({ path: '/home/newServiceConfirmationForm', query: { id: e.id, type: 3 } })
    }
  },

}
</script>

<style>
</style>