import request from '@/utils/request'

// 登录
export const Login = (data) => request({
  method: 'post',
  url: '/SysUser/login.do',
  data: JSON.stringify(data)
})

// 修改密码
export const EditPaw = (data) => request({
  method: 'post',
  url: '/SysUser/updatePwd.do',
  data: JSON.stringify(data)
})

// 上传图片
export const UploadImage = (data) => request({
  method: 'post',
  url: '/Engineer/fileUpload.do',
  data: data,
  headers: {
    "Content-Type": "multipart/form-data"
  },
})

// 查询当前用户
export const GetCurrentUser = (data) => request({
  method: 'post',
  url: '/SysUser/getCurrentUser.do',
  data: data
})

// 修改当前用户
export const EditCurrentUser = (data) => request({
  method: 'post',
  url: '/SysUser/update.do',
  data: data
})

//下载文件
export const DownloadFile = (data) => request({
  method: 'get',
  url: '/Engineer/getImage.do',
  params: data,
  responseType: 'arraybuffer'
})