<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
        >
          <el-table-column
            min-width="130"
            prop="createTime"
            label="日期"
            sortable
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="status"
            label="状态"
            sortable
          >
            <template slot-scope="scope">
              {{$global.deviceStates[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="count"
            label="数量"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="fullname"
            label="操作员"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetStatusRecordList } from '@/api/warehouse/purchaseRequisition'
export default {
  props: ['visible', 'id'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '状态详情',
      },
      rules: {
        // reason: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableData: [],
      tableHeight: 385,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {

    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        deviceInfoId: this.id,
      }
      this.tableLoading = true;
      GetStatusRecordList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }
  .el-button {
    width: 100%;
  }
}
</style>