<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
        :timestamp="activity.timestamp"
      >
        {{activity.content}}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
// 全局方法
import { toLocaleDate } from '@/utils/common'
export default {
  props: ['info'],
  data () {
    return {
      activities: []
    }
  },
  methods: {
    // 初始化数据
    initData () {
      this.activities = this.info.speedModelList.map(item => {
        let state = ['已确认签名', '取消派工', '已派工', '改派', '已派单', '提交申请', '未完成', '已完成', '进行中', '待处理', '待确认', '已撤销', '已关闭', '已归类存档',];
        // let state = ['Signature confirmed', 'Cancel dispatch', 'Work dispatched', 'Reschedule', 'Order dispatched', 'Submit application', 'Incomplete', 'Completed', 'Proceed Medium', 'Pending', 'To be confirmed', 'Canceled', 'Closed', 'Classified and archived',];
        let texts = item.text.split(',');
        return {
          content: this.$i18n.t(`newSubmitRequest.progressLog.state${state.indexOf(texts[0].trim())}`) + ` ${texts[1] || ''}， ${item.name}`,
          timestamp: toLocaleDate(item.date),
        }
      })
    },
  }
}
</script>

<style>
</style>