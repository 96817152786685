<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('deviceCode.title')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.save')}}</el-button>

      </page-header>
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <!-- <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button> -->
            <el-button
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              size="medium"
              @click="advanceCode"
            >{{$t('deviceCode.printInAdvance')}}</el-button>
            <el-button
              size="medium"
              @click="batchSetQR"
            >{{$t('deviceCode.batch')}}</el-button>
            <el-button
              size="medium"
              @click="printQRcode"
            >{{$t('deviceCode.print')}}</el-button>
            <el-button
              size="medium"
              @click="printIndex"
            >{{$t('deviceCode.indexPrint')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table border          ref="table"
          :data="tables.slice((page.current - 1) * page.size, page.current * page.size)"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="deviceInfoName"
            :label="$t('deviceCode.col1')"
          >
          </el-table-column>
          <el-table-column
            prop="serialNumber"
            :label="$t('deviceCode.col2')"
          >
          </el-table-column>
          <el-table-column
            prop="qrCode"
            :label="$t('deviceCode.col3')"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.qrCode"
              >
                <el-button
                  slot="append"
                  icon="el-icon-refresh"
                  :title="$t('deviceCode.btn1')"
                  @click="setQR(scope)"
                ></el-button>
              </el-input>
            </template>

          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 设备弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :serial="tableSerial"
      @submit="equipmentSubmit"
    ></equipment-dialog>
  </div>
</template>
<script>
// 全局方法
import { downFile } from '@/utils/common'
// 组件
import PageHeader from '@/components/PageHeader'
import EquipmentDialog from '@/components/EquipmentDialog/EquipmentDialog7'
// 接口
import {
  BatchSetQR,
  SetQR,
  ExportBeforehandBarCode,
  PrintIndex,
  ExportQRCode,
  SaveQR,
} from '@/api/warehouse/deviceManagement'
export default {
  components: {
    PageHeader,
    EquipmentDialog,
  },
  data () {
    return {
      EquipmentDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableSelected: [],
      tableData: [],
      groupForm: {
        loading: false,
      },
      tableSerial: '',
    }
  },
  computed: {
    // 前端过滤
    tables () {
      if (this.searchVal) {
        return this.tableData.filter(item => item.deviceInfoName.indexOf(this.searchVal) !== -1 || item.serialNumber.indexOf(this.searchVal) !== -1 || item.qrCode.indexOf(this.searchVal) !== -1)
      }
      return this.tableData
    },
  },
  watch: {
    'tables': function (newVal) {
      this.page.total = newVal.length;
    },
    'tableData': function (newVal) {
      this.tableSerial = newVal.map(item => item.id).join(',');
    },
  },
  created () {
    this.getTableData();
  },

  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getTableData () {
      this.tableData = JSON.parse(localStorage.deviceData);
    },
    // 提交
    submit (isBack = true, callback) {
      let param = {};
      this.tableData.forEach(item => param[item.id] = item.qrCode);
      this.groupForm.loading = true;
      SaveQR(param).then(res => {
        this.groupForm.loading = false;
        if (isBack) {
          this.$message.success(this.$i18n.t('message.save'))
          this.back();
        } else {
          callback()
        }
      })
    },

    // 预先打印二维码
    advanceCode () {
      this.$prompt(this.$i18n.t('deviceCode.advanceCode.content'), this.$i18n.t('deviceCode.advanceCode.title1'), {
        confirmButtonText: this.$i18n.t('operation.confirm'),
        cancelButtonText: this.$i18n.t('operation.cancel'),
        inputPattern: /^\d+$/,
        inputErrorMessage: this.$i18n.t('deviceCode.advanceCode.message1')
      }).then(({ value }) => {
        let param = { count: value }
        ExportBeforehandBarCode(param).then(res => {
          downFile(res, '预先打印二维码.pdf');
          this.$message.success(this.$i18n.t('message.download'));
        })
      }).catch(() => { });
    },
    // 显示新增
    showAdd () {
      this.EquipmentDialogVisible = true;
    },
    // 设备提交
    equipmentSubmit (data) {
      this.tableData = this.tableData.concat(data);
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: this.$i18n.t('operation.confirm'),
        cancelButtonText: this.$i18n.t('operation.cancel'),
        type: 'warning'
      }).then(() => {
        let checkids = this.tableSelected.map(item => item.id);
        this.tableData = this.tableData.filter(item => !checkids.includes(item.id))
      }).catch(() => { });
    },
    // 批量设置二维码
    batchSetQR () {
      if (this.tableData.some(item => !!item.qrCode)) {
        this.$confirm(this.$i18n.t('deviceCode.batchSetQR.content'), this.$i18n.t('deviceCode.batchSetQR.title1'), {
          confirmButtonText: this.$i18n.t('deviceCode.batchSetQR.btn1'),
          cancelButtonText: this.$i18n.t('operation.cancel'),
          type: 'warning',
          center: true,
        }).then(() => {
          this.getBatchQR();
        }).catch(() => { });
      } else {
        this.getBatchQR();
      }
    },
    getBatchQR () {
      let param = { count: this.tableData.length }
      BatchSetQR(param).then(res => {
        this.tableData.forEach((item, index) => item.qrCode = res[index]);
      })
    },
    // 单独分配二维码
    setQR (data) {
      if (!!data.row.qrCode) {
        this.$confirm(this.$i18n.t('deviceCode.batchSetQR.content'), this.$i18n.t('deviceCode.batchSetQR.title1'), {
          confirmButtonText: this.$i18n.t('deviceCode.batchSetQR.btn1'),
          cancelButtonText: this.$i18n.t('operation.cancel'),
          type: 'warning',
          center: true,
        }).then(() => {
          this.getQR(data.$index)
        }).catch(() => { });
      } else {
        this.getQR(data.$index)
      }
    },
    getQR (index) {
      SetQR().then(res => {
        this.$set(this.tableData[(this.page.current - 1) * this.page.size + index], 'qrCode', res)
      })
    },
    // 打印
    printQRcode () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.');
      if (this.tableSelected.some(item => !item.qrCode)) return this.$message.warning(this.$i18n.t('deviceCode.message1'));
      let param = { codeIds: this.tableSelected.map(item => item.qrCode).join(',') };
      this.submit(false, () => {
        ExportQRCode(param).then(res => {
          downFile(res, '打印.pdf');
          this.$message.success(this.$i18n.t('message.download'));
        })
      })
    },
    // 索引打印
    printIndex () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      let param = { codeIds: this.tableSelected.map(item => item.qrCode).join(',') };
      this.submit(false, () => {
        PrintIndex(param).then(res => {
          downFile(res, '索引打印.pdf');
          this.$message.success(this.$i18n.t('message.download'));
        })
      })
    },

    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>
<style lang="scss" scoped>
</style>