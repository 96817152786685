<template>
  <el-dialog
    :close-on-click-modal="false"
    title="Export Reports"
    :visible.sync="dialog.visible"
    center
    width="650px"
    class="dialog"
  >
    <el-form
      ref="dialog"
      :model="dialog"
      :rules="rules"
      label-width="170px"
      label-position="left"
      size="medium"
    >
      <el-form-item
        label="File Name"
        prop="name"
      >
        <el-input v-model="dialog.name"></el-input>
      </el-form-item>

      <el-form-item
        label="File Format"
        prop="format"
      >
        <el-select v-model="dialog.format">
          <el-option value="PDF"></el-option>
          <el-option value="EXCEL"></el-option>
          <!-- <el-option value="CSV"></el-option> -->
          <el-option value="JPG"></el-option>
          <el-option value="PNG"></el-option>
          <!-- <el-option value="HTML"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="dialog.format === 'PDF'"
        label="Report Orientation"
        prop="orientation"
      >
        <el-switch
          v-model="dialog.orientation"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-text="dialog.orientation ? 'Horizontal':'Vertical'"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item
        v-show="dialog.format === 'PDF'"
        label="Report Chart"
        prop="showChart"
      >
        <el-switch
          v-model="dialog.showChart"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-text="dialog.showChart?'Enable':'Disable'"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <!-- <el-form-item >
        <div slot="label">
          <el-checkbox v-model="dialog.isEmail">EMAIL to</el-checkbox>
        </div>
        <el-input v-model="dialog.email" :disabled="!dialog.isEmail"></el-input>
      </el-form-item> -->
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="medium"
        @click="dialog.visible = false"
      >Cancel</el-button>
      <el-button
        size="medium"
        type="primary"
        @click="submit"
      >OK</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'ExportReports',
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        name: '',
        orientation: 1,
        showChart: 1,
        format: 'PDF',
        isEmail: false,
        email: '',
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialog && this.$refs.dialog.resetFields();
        this.dialog.visible = this.visible
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    submit () {
      this.$refs.dialog.validate(valid => {
        if (!valid) return;

        this.dialog.visible = false;
        this.$emit('submit', this.dialog)
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>