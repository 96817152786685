<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="userForm"
        size="small"
        class="user-form"
      >
        <el-form-item
          :label="$t('stores.storeDialog.label1')"
          prop="storeId"
        >
          <el-input v-model="dialog.storeId"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('stores.storeDialog.label2')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('stores.storeDialog.label3')"
          prop="type"
        >
          <el-select
            v-model="dialog.type"
            filterable
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('stores.storeDialog.label4')"
          prop="address"
        >
          <el-input v-model="dialog.address"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('stores.storeDialog.label5')"
          prop="status"
        >
          <el-select v-model="dialog.status">
            <el-option
              :value="1"
              :label="$t('stores.storeDialog.option1')"
            ></el-option>
            <el-option
              :value="0"
              :label="$t('stores.storeDialog.option2')"
            ></el-option>
            <el-option
              :value="2"
              :label="$t('stores.storeDialog.option3')"
            ></el-option>
            <el-option
              :value="3"
              :label="$t('stores.storeDialog.option4')"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="竣工日期"
          prop="completionDate"
        >
          <el-date-picker
            v-model="dialog.completionDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input v-model="dialog.remark"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  AddSite,
  GetSiteId,
} from '@/api/stores'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance';
export default {
  props: ['visible', 'data', 'node'],
  data () {
    return {
      types: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        storeId: '',
        name: '',
        type: '',
        address: '',
        status: 1,
        remark: '',
        completionDate: null,
        groupId: null,
      },
      rules: {
        name: [{ required: true, message: 'Please enter an User Account.', trigger: 'blur' }],
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('stores.storeDialog.title2'),
              id: this.data.id,
              storeId: this.data.storeId,
              name: this.data.name,
              type: this.data.type || '',
              address: this.data.address || '',
              status: this.data.status,
              groupId: this.data.groupId,
              remark: this.data.remark,
              completionDate: this.data.completionDate,
            })
          })
        } else {
          this.dialog.title = this.$i18n.t('stores.storeDialog.title1')
          this.getbranchId();
        }

        // 获取网点类型
        if (this.types.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000019' }).then(res => this.types = res)
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getbranchId () {
      GetSiteId().then(res => {
        this.dialog.storeId = res;
      })
    },
    // 添加或修改用户
    submit () {
      this.$refs.userForm.validate(valid => {
        if (!valid) return;
        let param = {
          groupId: !!this.dialog.groupId ? this.dialog.groupId : this.node.id,
          storeId: this.dialog.storeId,
          name: this.dialog.name,
          status: this.dialog.status,
          address: this.dialog.address.trim(),
          type: this.dialog.type.trim(),
          remark: this.dialog.remark,
          completionDate: this.dialog.completionDate,
        }
        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddSite(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            this.$message.warning(res.message);
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },



  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.el-date-editor {
  width: 100%;
}
</style>