<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        @sort-change="sortChange"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="fieldName"
          :label="$t('chartType.sorting.col1')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="order"
          :label="$t('chartType.sorting.col2')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.order === 0 ? 'A to Z' : 'Z to A'}}
          </template>
        </el-table-column>
        <el-table-column :label="$t('chartType.sorting.col3')">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :title="scope.row.status ? 'Enable':'Disable'"
              @change="submit(scope.row.fieldId)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <!--  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('chartType.sorting.col1')"
          prop="fieldId"
        >
          <el-select v-model="dialog.fieldId">
            <el-option
              v-for="item in fields"
              :key="item.id"
              :value="item.id"
              :label="item.mainFieText"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('chartType.sorting.col2')"
          prop="order"
        >
          <el-select v-model="dialog.order">
            <el-option
              :value="0"
              label="A to Z"
            ></el-option>
            <el-option
              :value="1"
              label="Z to A"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="sortingSubmit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetTemplateParam } from '@/api/counterMode'
import { GetFieldTypeAll } from '@/api/reportDesigner'
export default {
  props: ['data', 'templateId'],
  data () {
    return {
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      fields: [],
      measures: [],
      dialog: {
        visible: false,
        title: this.$i18n.t('chartType.sorting.title1'),
        fieldId: '',
        order: 0,
      },
      rules: {
        fieldId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.tableData = this.data;
      },
      deep: true,
      immediate: true,
    },
    'templateId': function () {
      this.getTemplateParam(0);
    },
  },
  created () {
    this.getMeasure();
  },
  methods: {
    showTab () {
      this.tableHeight = this.$refs.tableRow.$el.clientHeight;
    },
    // 获取模板参数
    getTemplateParam (type) {
      let param = {
        templatesId: this.templateId,
        fieIdType: type,
      }
      GetTemplateParam(param).then(res => {
        this.fields = res;
      })
    },
    getMeasure () {
      GetFieldTypeAll().then(res => {
        this.measures = res
      })
    },
    showAdd () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.visible = true;
    },
    dblclickEdit (e) {
      Object.assign(this.dialog, {
        fieldId: e.fieldId,
        order: e.order,
        visible: true,
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkids = this.tableSelected.map(item => item.fieldId);
        this.tableData = this.tableData.filter(item => !checkids.includes(item.fieldId))
        this.$emit('submit', this.tableData)

      }).catch(() => { });
    },
    // 提交
    sortingSubmit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let index = this.tableData.findIndex(item => item.fieldId === this.dialog.fieldId);
        let currentField = this.fields.find(item => item.id === this.dialog.fieldId);
        let currentMeasure = this.measures.find(item => item.id == currentField.dataTypeId);
        let data = {
          fieldId: this.dialog.fieldId,
          fieldName: currentField.mainFieText,
          order: this.dialog.order,
          key: currentField.dbKey,
          type: currentMeasure.type,
        }
        if (index == -1) {
          data.status = false;
          this.tableData.push(data)
        } else {
          data.status = this.tableData[index].status;
          this.tableData.splice(index, 1, data);
        }
        this.dialog.visible = false;
        this.$emit('submit', this.tableData)
      })
    },
    submit (id) {
      this.tableData.forEach(item => {
        if (item.fieldId !== id) item.status = false;
      })
      this.$emit('submit', this.tableData);
    },
    //排序触发函数
    sortChange (column) {
      this.tableData = this.$sortChange(column, this.tableData)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: calc(100% - 46px);
}
</style>