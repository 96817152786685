<template>
  <el-container class="wrap">
    <el-aside width="350px">
      <el-row>
        <el-button-group class="input-group">
          <el-input
            v-model="treeSerachVal"
            class="search-input"
            size="medium"
            placeholder="Search"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="checkAll"
          >{{  $t("stores.btn3") }}</el-button>
        </el-button-group>
      </el-row>
      <el-tree
        :class="{ 'all': allState}"
        :data="treeData"
        :filter-node-method="filterNode"
        :expand-on-click-node="false"
        v-loading="treeLoading"
        node-key="id"
        ref="tree"
        highlight-current
        @node-click="nodeClick"
      ></el-tree>
    </el-aside>
    <el-main>

      <el-row>
        <div class="input-wrap">
          <div class="left-box">
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
              <el-button
                :disabled="!permit.update"
                size="medium"
                @click="update"
                :loading="updateLoading"
              >{{$t('operation.update')}}</el-button>
            </el-button-group>
          </div>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            prop="org2"
            :label="$t('projectContractManagement.sla.col1')"
            sortable
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="projectType"
            :label="$t('projectContractManagement.sla.col2')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('projectContractManagement.sla.col3')"
            sortable
            prop="state"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.state === 1"
                size="small"
              >{{$t('newProjectContract.tag1')}}</el-tag>
              <el-tag
                v-else-if="scope.row.state === 2"
                size="small"
                type="success"
              >{{$t('newProjectContract.tag2')}}</el-tag>
              <el-tag
                v-else-if="scope.row.state === 3"
                size="small"
                type="danger"
              >{{$t('newProjectContract.tag3')}}</el-tag>
              <el-tag
                v-else-if="scope.row.state === 4"
                size="small"
                type="info"
              >{{$t('newProjectContract.tag4')}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            min-width="170"
            prop="maintenanceType"
            :label="$t('projectContractManagement.sla.col4')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="maintenanceValidStartDate"
            :label="$t('projectContractManagement.sla.col5')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="maintenanceValidEndDate"
            :label="$t('projectContractManagement.sla.col6')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="address"
            :label="$t('projectContractManagement.sla.col7')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="customerName"
            :label="$t('projectContractManagement.sla.col8')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="name"
            :label="$t('projectContractManagement.sla.col9')"
            sortable
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="slaName"
            :label="$t('projectContractManagement.sla.col10')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            prop="slaTypeName"
            :label="$t('projectContractManagement.sla.col11')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            prop="quotationNumber"
            label="Contract&Quote No."
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-main>

  </el-container>
</template>

<script>
// 接口
import {
  DelProjectContract,
  ProjectUpdate,
  GetProjectContractByBranchList,
} from '@/api/warehouse/projectContract'
import {
  GetStoreTree,
} from "@/api/stores";
export default {
  props: ['active'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
        update: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      updateLoading: false,


      treeSerachVal: '',
      treeLoading: false,
      treeData: [],
      currentNode: null,
      allState: true,
    }
  },
  watch: {
    // '$route': function (to) {
    //   if (to.name === 'ProjectContract') this.getTableData();
    // },
    'active': function (newVal) {
      if (newVal === 'SLAMaintenance') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTreeData();

        let ids = this.tableSelected.map(item => item.projectContactBranchId)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.projectContactBranchId))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });

        this.$refs.table.doLayout();
      }
    },
    treeSerachVal: function (val) {
      this.$refs.tree.filter(val);
    },
  },
  created () {
    this.dealPermission();
  },
  methods: {
    getTreeData () {
      let param = {
        type: '2'
      };
      this.treeLoading = true;
      GetStoreTree(param).then(res => {
        // dealTree(res);
        this.treeData = res;
        this.treeLoading = false;
      }).catch(error => {
        this.treeLoading = false;
      });
    },
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        groupId: !!this.currentNode ? this.currentNode.id : '',
      }
      this.tableLoading = true;
      GetProjectContractByBranchList(param).then(res => {
        let toDay = new Date().getTime()
        this.tableData = res.list.map(item => {
          let start = new Date(item.maintenanceValidStartDate2).getTime();
          let end = new Date(item.maintenanceValidEndDate2).getTime();
          if (item.maintenanceValidStartDate2 === null) {
            item.state = 4;
          } else if (toDay < start) {
            item.state = 1;
          } else if (toDay < end) {
            item.state = 2;
          } else if (end < toDay) {
            item.state = 3;
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;

        if (typeof fun === 'function') fun();
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: '/home/newProjectContract' })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0])
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newProjectContract', query: { id: e.id } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelProjectContract(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },

    // 更新
    update () {
      this.updateLoading = true;
      ProjectUpdate().then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.update'))
        }
        this.updateLoading = false;
      })
    },
    // 选中所有
    checkAll () {
      this.allState = true;
      this.currentNode = null;
      this.page.current = 1;
      this.getTableData();
    },
    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 点击树节点
    nodeClick (node) {
      this.allState = false;
      this.currentNode = node;
      this.searchData();
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('ProjectContractSearch');
        this.permit.add = permission.includes('ProjectContractAdd');
        // this.permit.edit = permission.includes('ProjectContractEdit');
        this.permit.del = permission.includes('ProjectContractDelete');
        this.permit.update = permission.includes('ProjectContractUpdate');
      }
    },


  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-aside {
  margin-right: 20px;
}
.el-main {
  padding: 0;
}
.table-row {
  height: calc(100% - 46px - 45px);
}
.left-box {
  display: flex;
  .el-select {
    margin-left: 10px;
  }
}

.el-tree {
  height: calc(100% - 46px);
  border: 1px solid #e4e4e4;
  overflow: auto;
  &.all {
    background-color: #f0f7ff;
  }
}
</style>
