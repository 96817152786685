<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      center
      width="400px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            :label="$t('dataMaintenance.deviceTypeDialog.col1')"
            prop="name"
          ></el-table-column>
        </el-table>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialogLoading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetItemTypeByFaultType,
  BatchDelFaultTypeItem,
} from '@/api/warehouse/dataMaintenance/faultType'
export default {
  props: ['visible', 'faultTypeId', 'subType', 'subTypeId', 'ids'],
  data () {
    return {
      dialogVisible: false,
      dialogLoading: false,
      title: 'Select Item Type',
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableLocalData:[],
      tableHeight: 386,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.getTableData()

      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        faultTypeId: this.faultTypeId,
        subTypeId: this.subTypeId,
        subType: this.subType,
      }
      this.tableLoading = true;
      GetItemTypeByFaultType(param).then(res => {
        this.tableLocalData = res;
        this.$nextTick(() => {
          this.tableLocalData.forEach(item => {
            if (item.isSelect || this.ids.includes(item.id)) this.$refs.table.toggleRowSelection(item, true)
          })
          this.tableData = this.tableLocalData;
          this.tableLoading = false;
        })
      })
    },
    searchData () {
      this.tableData = this.tableLocalData.filter(item =>
        !this.searchVal
        || (!!item.name && item.name.indexOf(this.searchVal) !== - 1)
      )
    },
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$emit('submit', this.tableSelected);
      this.dialogLoading = false;
      this.dialogVisible = false;
    },
    selectionChange (selection, row) {
      if (!this.tableLoading) {
        let ids = this.tableSelected.map(item => item.id);
        let selectionIds = selection.map(item => item.id);
        ids = ids.filter(item => !selectionIds.includes(item));
        if (ids.length !== 0) {
          let param = {
            faultTypeIds: [this.faultTypeId],
            deviceTypeIds: ids,
            subTypeId: this.subTypeId,
            subType: this.subType,
          }
          BatchDelFaultTypeItem(param)
        }
      }
      this.tableSelected = selection;
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}
</style>