<template>
  <div
    class="wrap"
    v-show="visible"
    @click.stop
    v-drag
  >
    <div class="title">{{$t('counterMode.filterPopover.title1')}}</div>
    <div
      class="con"
      @mousedown.stop
    >
      <div class="btn-box">
        <div
          class="btn"
          @click="reset"
        >
          <i class="el-icon-refresh-left"></i>
          <span>{{$t('counterMode.filterPopover.btn1')}}</span>
        </div>
        <div
          class="btn"
          @click="sortChange(0)"
        >
          <i :class="{
          'el-icon-check':sort.prop === prop && sort.order === 'ascending'
        }"></i>
          <span>{{$t('counterMode.filterPopover.btn2')}}</span>
        </div>
        <div
          class="btn"
          @click="sortChange(1)"
        >
          <i :class="{
          'el-icon-check':sort.prop === prop && sort.order === 'descending'
        }"></i>
          <span>{{$t('counterMode.filterPopover.btn3')}}</span>
        </div>
        <div
          class="btn"
          @click="customSort"
        >
          <i></i>
          <span>{{$t('counterMode.filterPopover.btn4')}}</span>
        </div>
        <div
          class="btn"
          @click="showFilter"
        >
          <i class="icon-filter"></i>
          <span>{{$t('counterMode.filterPopover.btn5')}}</span>
        </div>
      </div>
      <el-input
        class="search-input"
        placeholder="Search"
        type="text"
        v-model="searchVal"
        @keyup.native.enter="searchEnter"
        @input="searchData"
        size="mini"
      ></el-input>
      <div
        class="table-wrap"
        ref="tableWrap"
      >
        <u-table
          ref="table"
          :height="tableHeight"
          :row-height="30"
          use-virtual
        >
          <u-table-column width="50">
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-checkbox
                v-model="all"
                @change="allChange"
              ></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
                @change="checkChange"
              ></el-checkbox>
            </template>
          </u-table-column>
          <u-table-column
            label="Select All"
            prop="label"
          ></u-table-column>
        </u-table>
      </div>
    </div>

  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
export default {
  props: ['visible', 'data', 'prop', 'type', 'filterState', 'itemFilter', 'sort'],
  data () {
    return {
      searchVal: '',
      all: true,
      tableData: [],
      sortData: [],
      tableHeight: null,
      paramData: null,
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.searchVal = '';
        this.paramData = JSON.parse(JSON.stringify(this.data));
        this.all = this.paramData.itemNumber === 0;

        this.$nextTick(() => {
          this.tableHeight = this.$refs.tableWrap.offsetHeight;
          this.$refs.table.reloadData(this.paramData.items);
        })
      }
    },
  },
  methods: {
    searchData: debounce(function () {
      this.tableData = this.paramData.items.filter(item => !this.searchVal || item.label.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) !== -1);
      this.$refs.table.reloadData(this.tableData);
    }, 100),
    searchEnter () {
      if (!this.searchVal) return;

      this.paramData.items.filter(item => {
        item.checked = item.label.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) !== -1;
      });
      this.paramData.itemNumber = this.paramData.items.length - this.tableData.length;

      this.updateStatus();
      this.$nextTick(() => {
        this.$emit('submit', JSON.parse(JSON.stringify(this.paramData)));
      })
    },
    allChange (val) {
      this.paramData.items.forEach(item => item.checked = val)
      if (val) {
        this.paramData.itemNumber = 0;
      } else {
        this.paramData.itemNumber = this.paramData.items.length;
      }
      this.updateStatus();
      this.$nextTick(() => {
        this.$emit('submit', JSON.parse(JSON.stringify(this.paramData)));
      });
    },
    // 复选框
    checkChange (val) {
      if (val) {
        this.paramData.itemNumber--;
      } else {
        this.paramData.itemNumber++;
      }
      this.all = this.paramData.itemNumber === 0;

      this.updateStatus();
      this.$nextTick(() => {
        this.$emit('submit', JSON.parse(JSON.stringify(this.paramData)));
      })
    },
    // 排序
    sortChange (type) {
      let order;

      if (type === 0) {
        order = 'ascending';
      } else {
        order = 'descending';
      }

      this.$emit('update:sort', {
        prop: this.prop,
        order: order,
      });
    },
    // 多列排序
    customSort () {
      this.$emit('showSort')
    },
    // 重置
    reset () {
      this.searchVal = '';
      this.all = true;
      this.paramData.status = false;
      this.paramData.itemNumber = 0;
      this.paramData.items.forEach(item => item.checked = true);
      this.$refs.table.reloadData(this.paramData.items);
      this.paramData.filter.data = null;

      this.$nextTick(() => {
        this.$emit('submit', JSON.parse(JSON.stringify(this.paramData)));
        this.$emit('update:sort', { prop: '', order: '' });
      })
    },
    // 更新状态
    updateStatus () {
      let count = 0;
      if (this.paramData.itemNumber !== 0) count++;
      if (!!this.paramData.filter.data) count++;

      this.paramData.status = count !== 0;
    },
    // 显示规则筛选弹框
    showFilter () {
      this.$emit('showFilter')
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 300px;
  // height: 600px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
}
.con {
  height: calc(100% - 34px);
}
.title {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  cursor: move;
}
.search-input {
  text-align: center;
  margin-bottom: 10px;
  ::v-deep .el-input__inner {
    text-align: center;
  }
}
.table-wrap {
  height: calc(100% - 126px - 42px);

  ::v-deep th,
  ::v-deep td {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px !important;
  }
  ::v-deep th {
    // border: 1px solid #5072a62e;
  }
  ::v-deep td {
    border: none;
    border-bottom: 1px solid #ebeef5;
  }
}
.btn-box {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.btn {
  width: 100%;
  height: 24px;
  color: #606266;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  user-select: none;
  &:hover {
    background: #409eff;
    color: #fff;
    .el-icon-check {
      color: #fff;
    }
  }
  i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  span {
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
  }
  .el-icon-check {
    color: #409eff;
  }
}
</style>