<template>
  <div class="wrap">
    <div class="top-row">

      <el-card class="table-card">
        <el-row>
          <div class="input-wrap">
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delItem"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
            <col-set
              @update="getTableData"
              url="/"
            ></col-set>
          </div>
        </el-row>
        <el-row
          class="table-row"
          ref="tableRow"
        >
          <el-table
            border
            :data="tableData"
            :height="tableHeight"
            v-loading="tableLoading"
            @selection-change="val => tableSelected = val"
            @row-click="rowClick"
            @row-dblclick="dblclickEdit"
            :row-class-name="tableRowClassName"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              prop="name"
              :label="$t('roles.col1')"
            >
            </el-table-column>
            <el-table-column
              min-width="150"
              sortable
              prop="describe"
              :label="$t('roles.col2')"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              min-width="170"
              sortable
              prop="accessGroupName"
              :label="$t('roles.col3')"
            >
            </el-table-column>
            <el-table-column
              min-width="120"
              sortable
              prop="createByName"
              :label="$t('roles.col4')"
            >
            </el-table-column>
            <el-table-column
              min-width="150"
              sortable
              prop="createTime"
              :label="$t('roles.col5')"
              :formatter="$formatTableData"
            >
            </el-table-column>
            <el-table-column
              min-width="150"
              sortable
              prop="updateTime"
              :label="$t('roles.col6')"
              :formatter="$formatTableData"
            >
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="page-wrap">
          <my-page
            layout="prev, pager, next, jumper"
            :total="page.total"
            :page-size.sync="page.size"
            :current-page.sync="page.current"
            @current-change="getTableData"
            @size-change="getTableData"
          >
          </my-page>
        </el-row>
      </el-card>
    </div>

    <el-row
      class="button-row"
      :gutter="10"
      v-if="checkedRoleId"
    >
      <el-col :span="12">
        <el-card class="tree-wrap">
          <div class="title">
            <span>{{$t('roles.subtitle1')}}</span>
            <el-button-group
              class="btn-group"
              v-if="permit.systemPrivilegesManagement"
            >
              <el-button
                @click="itemAddNode"
                size="medium"
              >{{$t('roles.btn1')}}</el-button>
              <el-button
                @click="itemClick('Add')"
                size="medium"
              >{{$t('roles.btn2')}}</el-button>
              <el-button
                @click="itemClick('Edit')"
                size="medium"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                @click="itemClick('Delete')"
                size="medium"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </div>
          <el-tree
            :data="treeData"
            :expand-on-click-node="false"
            ref="tree"
            node-key="id"
            default-expand-all
            highlight-current
            show-checkbox
            check-strictly
            @node-click="treeClick"
            @check="treeCheck"
          >
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="permission-wrap">
          <div class="title">
            <span>{{$t('roles.subtitle2')}}</span>
            <el-button-group
              class="btn-group"
              v-if="permit.systemPrivilegesManagement"
            >
              <el-button
                @click="permissionsClick('Add')"
                size="medium"
              >{{$t('operation.add')}}</el-button>
              <el-button
                @click="permissionsClick('Edit')"
                size="medium"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                @click="permissionsClick('Delete')"
                size="medium"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </div>
          <div
            class="permission-box"
            ref="permissionBox"
          >
            <el-table
              border
              :data="permissionData"
              :height="permissionHeight"
              stripe
              ref="permission"
              v-loading="permissionLoading"
              @selection-change="setPermission"
              @row-click="perRowClick"
              @row-dblclick="perDblclickEdit"
              :row-class-name="perTableRowClassName"
            >
              <el-table-column type="selection"> </el-table-column>
              <el-table-column
                label="All"
                prop="name"
                sortable
              ></el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加角色弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="roleDialog.title"
      :visible.sync="roleDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="roleDialog"
        :rules="rules"
        ref="roleForm"
        size="small"
      >
        <el-form-item
          :label="$t('roles.roleDialog.label1')"
          prop="name"
        >
          <el-input v-model="roleDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('roles.roleDialog.label2')"
          prop="description"
        >
          <el-input
            type="textarea"
            :rows="4"
            v-model="roleDialog.description"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('roles.roleDialog.label3')"
          prop="dataAccessGroup"
        >
          <el-select
            :disabled=" !permit.editDataAccessGroup"
            v-model="roleDialog.dataAccessGroup"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="roleDialog.visible = false">{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="submitRole"
          :loading="roleDialog.loading"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>

    <!-- 添加Item弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="itemDialog.title"
      :visible.sync="itemDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="itemDialog"
        :rules="rules"
        ref="itemForm"
        size="small"
      >
        <el-form-item
          :label="$t('roles.itemDialog.label1')"
          prop="name"
        >
          <el-input v-model="itemDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('roles.itemDialog.label2')"
          prop="sort"
        >
          <el-input
            type="number"
            v-model="itemDialog.sort"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="itemDialog.visible = false">{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="submitItem"
          :loading="itemDialog.loading"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 添加permissions弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="permissionsDialog.title"
      :visible.sync="permissionsDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="permissionsDialog"
        :rules="rules"
        ref="permissionsForm"
        size="small"
      >
        <el-form-item
          :label="$t('roles.permissionsDialog.label1')"
          prop="name"
        >
          <el-input v-model="permissionsDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('roles.permissionsDialog.label2')"
          prop="sort"
        >
          <el-input
            type="number"
            v-model="permissionsDialog.sort"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="permissionsDialog.visible = false">{{$t('operation.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="permissionsItem"
          :loading="permissionsDialog.loading"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ColSet from '@/components/ColSet'
import { debounce } from '@/utils/common'
import {
  GetRoleList,
  AddRole,
  DelRole,
  GetMenuTree,
  GetRolePermissions,
  SetRolePermissions,
  AddItem,
  EditItem,
  DeleteItem,
  AddPermissions,
  EditPermissions,
  DeletePermissions,
  GetRoleMenu,
} from '@/api/roles'
import {
  // GetDataAccessGroupAll,
  GetDataAccessGroup,
} from '@/api/organizations'
export default {
  name: 'Roles',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
        editDataAccessGroup: true,
        systemPrivilegesManagement: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      checkedRoleId: null,
      tableData: [],
      tableHeight: null,

      dataAccessGroups: [],
      roleDialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        description: '',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Role Name.', trigger: 'blur' }],
      },

      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeData: [],

      permissionLoading: false,
      permissionData: [],
      permissionHeight: null,

      itemDialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        sort: '',
        root: null,
        type: 1,
      },
      itemRules: {
        name: [{ required: true, message: 'Please enter an Item Name.', trigger: 'blur' }],
      },
      permissionsDialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        sort: '',
        type: 1,
      },
      permissionsRules: {
        name: [{ required: true, message: 'Please enter an Item Name.', trigger: 'blur' }],
      },
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'Roles') {
        this.getTableData();
        // 获取数据访问组
        GetDataAccessGroup({ accessGroupId: this.roleDialog.dataAccessGroup }).then(res => this.dataAccessGroups = res);
      };
    },
    // 获取角色导航栏权限
    'checkedRoleId': function (newVal) {
      // 显示角色具体权限
      if (this.$refs.tree && this.$refs.tree.getCurrentNode() !== null) {
        this.treeClick(this.$refs.tree.getCurrentNode())
      }
    }
  },
  created () {
    this.getTreeData();
    // 获取数据访问组
    GetDataAccessGroup({ accessGroupId: this.roleDialog.dataAccessGroup }).then(res => this.dataAccessGroups = res);

    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();

  },
  methods: {
    // 获取树结构
    getTreeData () {
      GetMenuTree().then(res => {
        this.treeData = res;
      })
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetRoleList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (this.tableData.length !== 0) {
          this.rowClick(this.tableData[0])
        }
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.$refs.roleForm && this.$refs.roleForm.resetFields();
      this.roleDialog.id = null;
      this.roleDialog.title = this.$i18n.t('roles.roleDialog.title1');
      this.roleDialog.visible = true;
    },
    // 显示编辑
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (item) {
      if (!this.permit.edit) return;

      this.$refs.roleForm && this.$refs.roleForm.resetFields();

      this.roleDialog.id = item.id;
      this.roleDialog.title = this.$i18n.t('roles.roleDialog.title2');
      this.$nextTick(() => {
        this.roleDialog.name = item.name;
        this.roleDialog.description = item.describe;
        this.roleDialog.dataAccessGroup = item.accessGroupId;
      })
      this.roleDialog.visible = true;
    },
    // 添加、编辑角色
    submitRole () {
      this.$refs.roleForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.roleDialog.name,
          describe: this.roleDialog.description,
          accessGroupId: this.roleDialog.dataAccessGroup,
        }
        let msg = this.$i18n.t('message.add');
        if (this.roleDialog.id) {
          param.id = this.roleDialog.id;
          msg = this.$i18n.t('message.edit');
        }
        this.roleDialog.loading = true;
        AddRole(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.getTableData();
            this.roleDialog.visible = false;
          } else {
            if (res.status === 100) this.$message.warning('Role Name is occupied.')
          }
          this.roleDialog.loading = false;
        }).catch(error => {
          this.roleDialog.loading = false;
        })
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(','),
        }
        DelRole(param).then(res => {
          if (res.success) {
            this.getTableData()
            this.$message.success('deleted successfully.')
          }
        })
      }).catch(() => { });
    },
    //  
    treeCheck (node, data) {
      let check = data.checkedKeys.includes(node.id);
      if (this.itemDialog.id === node.id) {
        if (check)
          this.$refs.permission.toggleAllSelection();
        else
          this.$refs.permission.clearSelection();
      } else {
        let permissionParam = {
          roleId: this.checkedRoleId,
          functionId: node.id,
          buttonS: '',
        }
        if (check) {
          let param = {
            roleId: this.checkedRoleId,
            functionId: node.id
          }
          GetRolePermissions(param).then(res => {
            permissionParam.buttonS = res.map(item => item.id).join(',');
            SetRolePermissions(permissionParam).then(res => {
              if (res.success) this.getRoleMenu();
            })
          })
        } else {
          SetRolePermissions(permissionParam).then(res => {
            if (res.success) this.getRoleMenu();
          })
        }
      }
    },
    // 获取角色具体权限
    treeClick (node) {
      let param = {
        roleId: this.checkedRoleId,
        functionId: node.id
      }
      this.itemDialog.id = node.id;
      this.itemDialog.name = node.label;
      this.permissionsDialog.id = null;
      this.permissionLoading = true;
      GetRolePermissions(param).then(res => {
        this.permissionData = res;
        this.$nextTick(() => {
          this.permissionData.forEach((item, index) => {
            if (item.checked) this.$refs.permission.toggleRowSelection(this.permissionData[index])
          })
          setTimeout(() => {
            this.permissionLoading = false;
          }, 1)
        })
      })
    },
    // 设置角色具体权限 
    setPermission: debounce(function (val) {
      if (this.permissionLoading) return;
      let param = {
        roleId: this.checkedRoleId,
        functionId: this.itemDialog.id,
        buttonS: val.map(item => item.id).join(','),
      }
      SetRolePermissions(param).then(res => {
        if (res.success) this.getRoleMenu();
      })
    }),

    // item按钮
    itemClick (type) {
      if (!this.itemDialog.id) return this.$message.warning('Please select one item.');
      switch (type) {
        case 'Add':
          this.itemDialog.title = this.$i18n.t('roles.itemDialog.title1');
          this.itemDialog.name = '';
          this.itemDialog.sort = '';
          this.itemDialog.root = false;
          this.itemDialog.type = 1;
          this.itemDialog.visible = true;
          break;
        case 'Edit':
          this.itemDialog.title = this.$i18n.t('roles.itemDialog.title2');
          this.itemDialog.type = 2;
          this.itemDialog.visible = true;
          break;
        case 'Delete':
          this.$confirm('Delete operation, please confirm!', 'Prompt', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            let param = {
              id: this.itemDialog.id
            }
            DeleteItem(param).then(res => {
              if (res.success) {
                this.getTreeData()
                this.itemDialog.id = null;
                this.$message.success('deleted successfully.')
              }
            })
          }).catch(() => { });
          break;
      }
    },
    // 增加Item根节点
    itemAddNode () {
      this.itemDialog.title = this.$i18n.t('roles.itemDialog.title1');
      this.itemDialog.name = '';
      this.itemDialog.sort = '';
      this.itemDialog.root = true;
      this.itemDialog.type = 1;
      this.itemDialog.visible = true;
    },
    // 添加、编辑Item
    submitItem (data) {
      this.$refs.itemForm.validate(valid => {
        if (!valid) return;

        let param;
        this.itemDialog.loading = true;
        if (this.itemDialog.type === 1) {
          param = {
            name: this.itemDialog.name,
            parentId: this.itemDialog.root ? null : this.itemDialog.id,
            sn: Number(this.itemDialog.sort),
          }
          AddItem(param).then(res => {
            this.$message.success(this.$i18n.t('message.add'))
            this.getTreeData();
            this.itemDialog.visible = false;
            this.itemDialog.loading = false;
          }).catch(error => {
            this.itemDialog.loading = false;
          })
        } else {
          param = {
            name: this.itemDialog.name,
            id: this.itemDialog.id,
            sn: Number(this.itemDialog.sort),
          }
          EditItem(param).then(res => {
            this.$message.success(this.$i18n.t('message.edit'))
            this.getTreeData();
            this.itemDialog.visible = false;
            this.itemDialog.loading = false;
          }).catch(error => {
            this.itemDialog.loading = false;
          })
        }
      });
    },
    // Permissions按钮
    permissionsClick (type) {
      switch (type) {
        case 'Add':
          if (this.itemDialog.id === null) return this.$message.warning('Please select one item.');
          this.permissionsDialog.title = this.$i18n.t('roles.permissionsDialog.title1');
          this.permissionsDialog.name = '';
          this.permissionsDialog.sort = '';
          this.permissionsDialog.type = 1;
          this.permissionsDialog.visible = true;
          break;
        case 'Edit':
          if (this.permissionsDialog.id === null) return this.$message.warning('Please select one item.');
          this.permissionsDialog.title = this.$i18n.t('roles.permissionsDialog.title2');
          this.permissionsDialog.type = 2;
          this.permissionsDialog.visible = true;
          break;
        case 'Delete':
          if (this.permissionsDialog.id === null) return this.$message.warning('Please select one item.');

          this.$confirm('Delete operation, please confirm!', 'Prompt', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            let param = {
              id: this.permissionsDialog.id
            }
            DeletePermissions(param).then(res => {
              if (res.success) {
                this.treeClick(this.$refs.tree.getCurrentNode())
                this.$message.success('deleted successfully.')
              }
            })
          }).catch(() => { });
          break;
      }
    },
    // 添加、编辑Item
    permissionsItem () {
      this.$refs.permissionsForm.validate(valid => {
        if (!valid) return;

        let param;
        this.permissionsDialog.loading = true;
        if (this.permissionsDialog.type === 1) {
          param = {
            name: this.permissionsDialog.name,
            functionId: this.itemDialog.id,
            sn: Number(this.permissionsDialog.sort),
          }
          AddPermissions(param).then(res => {
            this.$message.success(this.$i18n.t('message.add'))
            this.treeClick(this.$refs.tree.getCurrentNode())

            this.permissionsDialog.visible = false;
            this.permissionsDialog.loading = false;
          }).catch(error => {
            this.permissionsDialog.loading = false;
          })
        } else {
          param = {
            name: this.permissionsDialog.name,
            id: this.permissionsDialog.id,
            sn: Number(this.permissionsDialog.sort),
          }

          EditPermissions(param).then(res => {
            this.$message.success(this.$i18n.t('message.edit'))
            this.treeClick(this.$refs.tree.getCurrentNode())

            this.permissionsDialog.visible = false;
            this.permissionsDialog.loading = false;
          }).catch(error => {
            this.permissionsDialog.loading = false;
          })
        }
      })

    },
    // 获取角色id
    rowClick (row, column, event) {
      this.checkedRoleId = row.id;

      this.getRoleMenu()

      if (!this.permissionHeight)
        this.$nextTick(() => {
          this.permissionHeight = this.$refs.permissionBox.clientHeight;
        })
    },
    // 获取角色拥有导航
    getRoleMenu () {
      let param = {
        roleId: this.checkedRoleId,
      }
      GetRoleMenu(param).then(res => {
        this.$refs.tree.setCheckedKeys(res);
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.id === this.checkedRoleId) return 'checked-row';
      else return '';
    },
    // 获取Permissions id
    perRowClick (row, column, event) {
      this.permissionsDialog.id = row.id;
      this.permissionsDialog.name = row.name;
      this.permissionsDialog.sn = row.sn;
    },
    perTableRowClassName ({ row, rowIndex }) {
      if (row.id === this.permissionsDialog.id) return 'checked-row';
      else return '';
    },
    perDblclickEdit (e) {
      this.permissionsDialog.type = 2;
      this.permissionsDialog.id = e.id;
      this.permissionsDialog.name = e.name;
      this.permissionsDialog.sn = e.sn;
      this.permissionsDialog.title = this.$i18n.t('roles.permissionsDialog.title2');
      this.permissionsDialog.visible = true;
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('RolesSearchRole');
        this.permit.add = permission.includes('RolesAddRole');
        this.permit.edit = permission.includes('RolesEditRole');
        this.permit.del = permission.includes('RolesDeleteRole');
        this.permit.editDataAccessGroup = permission.includes('RolesEditDataAccessGroups(DataPermissions)');
        this.permit.systemPrivilegesManagement = permission.includes('RolesSystemPrivilegesmanagement');

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}

.top-row {
  height: 50%;
  margin-bottom: 10px;
}
.button-row {
  height: calc(50% - 10px);
  .el-col {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}

.el-tree {
  height: calc(100% - 47px - 10px);
  overflow: auto;
}
.permission-wrap {
  .permission-box {
    height: calc(100% - 47px - 10px);
  }
}
.el-dialog {
  .el-select {
    width: 100%;
  }
}

.title {
  border-bottom: 1px solid #dcdfe6;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
