// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {
  LineChart,
  BarChart,
  RadarChart,
  MapChart,
  PieChart,
  GaugeChart,
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  LegendScrollComponent,
  VisualMapComponent,
  GridComponent,
  // RadiusAxisComponent,
  // AngleAxisComponent,
  PolarComponent,
  DataZoomComponent,
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import {
  LabelLayout,
  UniversalTransition
} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
  CanvasRenderer
} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  // @chart
  LineChart,
  BarChart,
  RadarChart,
  MapChart,
  PieChart,
  GaugeChart,

  // @component
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  LegendScrollComponent,
  VisualMapComponent,
  GridComponent,
  // RadiusAxisComponent,
  // AngleAxisComponent,
  PolarComponent,
  DataZoomComponent,

  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);
export default echarts

// @chart

// 'bar',
// 'boxplot',
// 'candlestick',
// 'chord',
// 'custom',
// 'effectScatter',
// 'funnel',
// 'gauge',
// 'graph',
// 'heatmap',
// 'line',
// 'lines',
// 'map',
// 'parallel',
// 'pictorialBar',
// 'pie',
// 'radar',
// 'sankey',
// 'scatter',
// 'themeRiver',
// 'treemap'

// @component

// 'angleAxis',
// 'axis',
// 'axisPointer',
// 'brush',
// 'calendar',
// 'dataZoom',
// 'dataZoomInside',
// 'dataZoomSelect',
// 'geo',
// 'graphic',
// 'grid',
// 'gridSimple',
// 'legend',
// 'markArea',
// 'markLine',
// 'markPoint',
// 'parallel',
// 'parallelAxis',
// 'polar',
// 'radar',
// 'radiusAxis',
// 'single',
// 'singleAxis',
// 'timeline',
// 'title',
// 'toolbox',
// 'tooltip',
// 'visualMap',
// 'visualMapContinuous',
// 'visualMapPiecewise'