<template>
  <div class="page-box">
    <span class="total">Total Entries {{total}}</span>
    <div class="limit-page-wrap">
      <span>Show</span>
      <el-select
        v-model="size"
        class="limit-page"
        size="mini"
        @change="sizeChange"
      >
        <el-option
          v-for="item in limitPage"
          :key="item"
          :value="item"
          :label="item"
        ></el-option>
      </el-select>
      <span>entries</span>
    </div>

    <el-pagination
      background
      :layout="layout"
      :total="total"
      :page-size.sync="size"
      :current-page.sync="current"
      @current-change="$emit('current-change', $event)"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'MyPage',
  props: ['layout', 'total', 'pageSize', 'currentPage'],
  data () {
    return {
      // limitPage: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      limitPage: [],
      size: this.pageSize,
      current: this.currentPage,
    }
  },
  watch: {
    'total': function (newVal) {
      this.limitPage = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 500, 1000].filter(item => item < newVal);
      this.limitPage.push(newVal);

      // if (newVal < 100) {
      //   [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].some(item => {
      //     if (item < newVal) {
      //       this.limitPage.push(item)
      //     } else {
      //       this.limitPage.push(item)
      //       return true;
      //     }
      //   });
      // } else if (newVal < 500) {
      //   let item = parseInt(newVal / 10);
      //   for (var i = 1; i <= 10; i++) {
      //     this.limitPage.push(item * i)
      //   }
      //   this.limitPage[9] = newVal;
      // } else {
      //   this.limitPage = [50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
      // }
    },
    'pageSize': function (newVal) {
      this.size = newVal
    },
    'currentPage': function (newVal) {
      this.current = newVal
    },
    'size': function (newVal) {
      this.$emit('update:pageSize', newVal)
    },
    'current': function (newVal) {
      this.$emit('update:currentPage', newVal)
    }
  },
  created () {
  },
  methods: {
    sizeChange () {
      this.current = 1;
      this.$emit('update:pageSize', this.size);
      this.$emit('update:currentPage', this.current);
      this.$emit('size-change', this.size);
    }
  }

}
</script>

<style lang="scss" scoped>
.page-box {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #606266;
  .total {
    margin-right: 20px;
  }
  .limit-page-wrap {
    display: flex;
    align-items: center;
  }
  .limit-page {
    width: 100px;
    margin: 0 10px;
  }
}
</style>