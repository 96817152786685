<template>
  <div class="wrap">
    <el-card v-loading="cardLoading">
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="150px"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('editOperationalTime.label1')"
              prop="organization"
            >
              <el-input
                disabled
                v-model="groupForm.organization"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('editOperationalTime.label2')"
              prop="descritipn"
            >
              <el-input
                disabled
                v-model="groupForm.descritipn"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('editOperationalTime.label3')"
              prop="dataAccessGroup"
            >
              <el-input
                disabled
                v-model="groupForm.dataAccessGroup"
              ></el-input>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="$t('editOperationalTime.label4')"
              prop="country"
            >
              <el-input v-model="groupForm.country"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('editOperationalTime.label5')"
              prop="timeZone"
            >
              <el-select
                v-model="groupForm.timeZone"
                filterable
              >
                <el-option
                  v-for="item in $global.timeZones"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('editOperationalTime.tab1')"
          name="WorkingHours"
        >
          <div class="working-tab">
            <div
              class="item"
              v-for="(item,index) in workingHours"
              :key="index"
            >
              <div class="week">{{item.week}}</div>
              <!-- <el-time-picker
                size="medium"
                is-range
                v-model="item.range"
                value-format="HH:mm:ss"
              >
              </el-time-picker> -->
              <div class="time-box">
                <input
                  type="time"
                  v-model="item.startTime"
                  step="1"
                >
                <span>-</span>
                <input
                  type="time"
                  v-model="item.endTime"
                  step="1"
                >
              </div>
              <el-switch
                v-model="item.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('editOperationalTime.tab2')"
          name="HolidayHours"
        >
          <el-row>
            <div class="input-wrap">
              <el-button-group class="input-group">
                <el-button
                  size="medium"
                  @click="showAdd"
                >{{$t('operation.add')}}</el-button>
                <el-button
                  size="medium"
                  @click="showEdit"
                >{{$t('operation.edit')}}</el-button>
                <el-button
                  size="medium"
                  @click="delItem"
                >{{$t('operation.del')}}</el-button>
              </el-button-group>
            </div>
          </el-row>
          <el-row
            class="table-row"
            ref="tableRow"
          >
            <el-table
              border
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
              @selection-change="val => tableSelected = val"
              @row-dblclick="dblclickEdit"
            >
              <el-table-column type="selection"></el-table-column>
              <el-table-column
                min-width="150"
                prop="name"
                :label="$t('editOperationalTime.col1')"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="type"
                :label="$t('editOperationalTime.col2')"
                sortable
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.type === 1"
                    size="small"
                  >Regular Holidays</el-tag>
                  <el-tag
                    v-if="scope.row.type === 2"
                    size="small"
                  >Special Holidays</el-tag>
                  <el-tag
                    v-if="scope.row.type === 3"
                    size="small"
                  >Additional Special Holidays</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                min-width="370"
                prop="userAccount"
                :label="$t('editOperationalTime.col3')"
              >
                <template slot-scope="scope">
                  <!-- <el-time-picker
                    size="mini"
                    is-range
                    v-model="scope.row.range"
                    value-format="HH:mm:ss"
                  >
                  </el-time-picker> -->
                  <div class="time-box mini">
                    <input
                      type="time"
                      v-model="scope.row.startTime"
                      step="1"
                    >
                    <span>-</span>
                    <input
                      type="time"
                      v-model="scope.row.endTime"
                      step="1"
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="150">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.isEnable"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  AddTimeZone,
  GetTimeZoneByOrg,
  AddHoliday,
  DelHoliday,
  GetHolidayList,
} from '@/api/warehouse/operationalTime'
export default {
  name: 'EditOperationalTime',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      title: this.$i18n.t('editOperationalTime.title1'),
      cardLoading: false,
      activeName: 'WorkingHours',
      groupForm: {
        id: null,
        loading: false,
        organizationId: null,
        organization: '',
        descritipn: '',
        dataAccessGroup: '',
        country: '',
        timeZone: 0,
      },
      rules: {
        // name: [{ required: true, message: 'Please enter an Group Name.', trigger: 'blur' }]
      },

      workingHours: [{
        week: 'Sunday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }, {
        week: 'Monday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }, {
        week: 'Tuesday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }, {
        week: 'Wednesday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }, {
        week: 'Thursday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }, {
        week: 'Friday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }, {
        week: 'Saturday',
        endTime: 'null',
        isEnable: 'null',
        status: 0,
      }],

      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  watch: {
    '$route': function (to, form) {
      if (to.name === 'EditOperationalTime' && form.name === 'NewHolidayHours') {
        this.getHoliday();
      }
    }
  },
  created () {
    this.dealPermission();
    this.getItem();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      this.groupForm.organizationId = this.$route.query.orgId;
      // 基本信息
      GetTimeZoneByOrg({ orgZationId: this.groupForm.organizationId })
        .then(res => {
          Object.assign(this.groupForm, {
            id: res.id,
            organization: res.name,
            descritipn: res.description,
            dataAccessGroup: res.dag,
            country: res.country,
            timeZone: !!res.timeZone ? Number(res.timeZone) : 0,
          })
        })
      // 工作日
      let workParam = {
        orgZationId: this.groupForm.organizationId,
        type: 0
      }
      GetHolidayList(workParam).then(res => {
        res.forEach((item, index) => {
          this.workingHours[index].id = item.id;
          this.workingHours[index].startTime = item.startTime
          this.workingHours[index].endTime = item.endTime
          this.workingHours[index].status = item.isEnable;
        })
      })
      // 假日
      this.getHoliday();
    },
    // 获取假日
    getHoliday () {
      let holidayParam = {
        orgZationId: this.groupForm.organizationId,
        type2: 0,
      }
      GetHolidayList(holidayParam).then(res => {
        this.tableData = res;
      })
    },
    // 显示新增弹框
    showAdd () {
      this.$router.push({
        path: '/home/newHolidayHours',
        query: { orgId: this.groupForm.organizationId }
      })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      sessionStorage.setItem('holidayDate', JSON.stringify(e));
      this.$router.push({
        path: '/home/newHolidayHours',
        query: {
          orgId: this.groupForm.organizationId,
          id: e.id,
        }
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelHoliday(param).then(res => {
          if (res.success) {
            this.getHoliday();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        // 基本信息
        let param = {
          id: this.groupForm.id,
          country: this.groupForm.country,
          timeZone: this.groupForm.timeZone,
          orgZationId: this.groupForm.organizationId,
        }

        this.groupForm.loading = true;
        AddTimeZone(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.edit'))
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })

        // 工作日
        let workParam = this.workingHours.map((item, index) => {
          let work = {
            id: item.id,
            week: index,
            startTime: item.startTime,
            endTime: item.endTime,
            isEnable: item.status,
            orgZationId: this.groupForm.organizationId,
            type: 0,
          }
          return work;
        })

        AddHoliday(workParam.concat(this.tableData))
      });
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.edit = permission.includes('OperationalTimeEdit');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}

.working-tab {
  .item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .week {
      width: 150px;
      height: 36px;
      line-height: 36px;
      padding-right: 20px;
      font-size: 14px;
      text-align: right;
      display: inline-block;
    }
    .el-date-editor {
      margin-right: 10px;
    }
  }
}
.time-box {
  width: 320px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  span {
    width: 20px;
    text-align: center;
  }
  input {
    width: 150px;
    height: 36px;
    line-height: 36px;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 14px;
  }
  &.mini {
    input {
      height: 28px;
      line-height: 28px;
    }
  }
}

input[type="time"] {
  font-family: roboto, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial,
    sans-serif;
}
</style>