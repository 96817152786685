import request from '@/utils/request'

// 查询报表组列表
export const GetReportGroupList = (data) => request({
  method: 'post',
  url: '/reportGroup/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改
export const AddReportGroup = (data) => request({
  method: 'post',
  url: '/reportGroup/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelReportGroup = (data) => request({
  method: 'post',
  url: '/reportGroup/deleteById.do',
  data: JSON.stringify(data)
})

// 获取所有报表组
export const GetReportGroupAll = (data) => request({
  method: 'post',
  url: '/reportGroup/queryReportGroup.do',
  data: JSON.stringify(data)
})

// 新增查询模板集合
export const GetAllReportTemplate = (data) => request({
  method: 'post',
  url: '/reportGroup/queryAddById.do',
  data: JSON.stringify(data)
})

// 通过id查询报表组
export const GetReportGroupItem = (data) => request({
  method: 'post',
  url: '/reportGroup/queryUpdateById.do',
  data: JSON.stringify(data)
})

// 获取报表组树（包含模板）
export const GetReportTree = (data) => request({
  method: 'post',
  url: '/reportGroupTree/queryList.do',
  data: JSON.stringify(data)
})

// 获取报表组树
export const GetReportGroupTree = (data) => request({
  method: 'post',
  url: '/reportGroupTree/queryAll.do',
  data: JSON.stringify(data)
})

// 添加报表组根节点
export const AddReportGroupNode = (data) => request({
  method: 'post',
  url: '/reportGroupTree/addNode.do',
  data: JSON.stringify(data),
})

// 添加报表组子节点
export const AddReportGroupClidNode = (data) => request({
  method: 'post',
  url: '/reportGroupTree/addChild.do',
  data: JSON.stringify(data),
})

// 修改报表组节点
export const EditReportGroupNode = (data) => request({
  method: 'post',
  url: '/reportGroupTree/update.do',
  data: JSON.stringify(data),
})

// 删除报表组节点
export const DelReportGroupNode = (data) => request({
  method: 'post',
  url: '/reportGroupTree/deleteById.do',
  data: JSON.stringify(data),
})


// 获取系统报表组列表
export const GetSysReportGroupList = (data) => request({
  method: 'post',
  url: '/SysReportGroup/queryByPage.do',
  data: JSON.stringify(data)
})

// 系统报表组新增和修改
export const AddSysReportGroup = (data) => request({
  method: 'post',
  url: '/SysReportGroup/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除系统报表组
export const DelSysReportGroup = (data) => request({
  method: 'post',
  url: '/SysReportGroup/delete.do',
  data: JSON.stringify(data)
})

// 设置系统报表组
export const SetSysReportGroup = (data) => request({
  method: 'post',
  url: '/SysReportGroup/setCategories.do',
  data: JSON.stringify(data)
})

// 设置报表
export const SetReport = (data) => request({
  method: 'post',
  url: '/SysReportGroup/setSelectReport.do',
  data: JSON.stringify(data)
})

// 获取被选中的系统报表组
export const GetCheckedSysReportGroup = (data) => request({
  method: 'post',
  url: '/SysReportGroup/getReportGroupIds.do',
  data: JSON.stringify(data)
})

// 获取被选中的报表
export const GetCheckedReport = (data) => request({
  method: 'post',
  url: '/reportTemplates/queryTemplatesId.do',
  data: JSON.stringify(data)
})

// 获取系统报表组集合
export const GetSysReportGroupAll = (data) => request({
  method: 'post',
  url: '/SysReportGroup/queryByList.do',
  data: JSON.stringify(data)
})