<template>
  <div class="wrap">
    <div class="left-box">
      <div class="label-item">
        <div class="label-box">
          <label>合同模板</label>
          <el-button-group>
            <el-button
              size="small"
              icon="el-icon-upload2"
              @click="collspaeAll(0)"
            ></el-button>
            <el-button
              size="small"
              icon="el-icon-download"
              @click="collspaeAll(1)"
            ></el-button>
          </el-button-group>
        </div>
        <el-select
          :disabled="!!isCancel"
          size="medium"
          v-model="templateId"
          @change="templateChange"
          filterable
        >
          <el-option
            v-for="(item,index) in templates"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
            <span class="select-label">{{ item.name }}</span>
            <i
              v-if="item.id !== 'default'"
              class="el-icon-error select-icon"
              @click.stop="delContractTemplate(item,index)"
            ></i>
          </el-option>
        </el-select>
      </div>
      <div class="option-box">
        <el-card
          v-for="(item,index) in elements"
          :key="index"
        >
          <div class="title">
            <label>{{item.label}}</label>
            <div class="btn-box">
              <i
                class="el-icon-arrow-down"
                @click="collspae(item)"
              ></i>
              <!-- <i
              class="el-icon-top"
              @click="elUp(index)"
            ></i>
            <i
              class="el-icon-bottom"
              @click="elDown(index)"
            ></i> -->
              <!-- <i
                class="el-icon-close"
                @click="elDel(index)"
              ></i> -->
            </div>
          </div>
          <div
            class="content"
            v-show="item.collspae"
          >
            <template v-if="item.elType === 1">
              <el-input
                size="medium"
                v-model="item.title"
              ></el-input>
            </template>
            <template v-if="item.elType === 2">
              <el-input
                size="medium"
                v-model="item.title"
              ></el-input>
              <div class="label-item">
                <label>内容</label>
                <el-input
                  size="medium"
                  type="textarea"
                  :rows="4"
                  v-model="item.content"
                ></el-input>
              </div>
            </template>
            <template v-if="item.elType === 3">
              <div class="switch-item">
                <label>启用自动</label>
                <el-switch
                  v-model="item.isNormal"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </div>
              <div class="label-item">
                <label>内容</label>
                <el-input
                  :disabled="item.isNormal === 1"
                  size="medium"
                  type="textarea"
                  :rows="4"
                  v-model="item.content"
                ></el-input>
              </div>
            </template>
          </div>
        </el-card>
      </div>
      <div class="footer">
        <el-button
          disabled
          size="medium"
          @click="resetContent"
        >New Content</el-button>
        <el-button
          :disabled="isCancel"
          size="medium"
          @click="addTemplate"
        >Save as New Template</el-button>
        <el-button
          :disabled="templateId === 'default'"
          size="medium"
          @click="editTemplate"
        >Save</el-button>
      </div>
    </div>
    <div class="right-box">
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="预览"
          name="tab1"
          ref="tab1"
        >
          <!-- <iframe
            :src="`${baseUrl}/Export/exportPurchase1.do?id=${orderId}&templateId=${templateId}`"
            frameborder="0"
          ></iframe> -->
        </el-tab-pane>
        <el-tab-pane
          label="合同附件"
          name="tab2"
        >
          <div class="file-box">
            <div
              v-for="(item,index) in $store.state.orderContract.attachment"
              :key="index"
              class="file-item"
            >
              <div
                class="block"
                @click="previewFile(item)"
              >
                <img :src="'./static/image/fileType/' + item.formats + '.png'">
                <label :title="item.fileName">{{item.fileName}}</label>
                <i
                  class="el-icon-delete"
                  @click.stop="delFile(index)"
                ></i>
              </div>
            </div>
            <div
              class="file-item add-item"
              @click="showUpload"
            >
              <div class="block">
                <i class="el-icon-plus"></i>
              </div>
              <label></label>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 图片上传 -->
    <input
      type="file"
      ref="fileInput"
      hidden
      @change="fileInputChange"
    >
  </div>
</template>

<script>
// 接口
import { UploadImage } from '@/api'
import {
  GetContractTemplateAll,
  GetContractTemplate,
  AddContractTemplate,
  DelContractTemplate,
  AddPurchaseOrders
} from '@/api/warehouse/purchaseOrders'

export default {
  props: ['active', 'orderId', 'isCancel'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      templates: [],
      templateId: 'default',
      activeName: 'tab1',
      elements: [{
        label: '主题',
        elType: 1,
        collspae: false,
        title: '',
      }, {
        label: '标题1',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }, {
        label: '税费条款',
        elType: 3,
        collspae: false,
        isNormal: 1,
        content: '',
      }, {
        label: '标题2',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }, {
        label: '标题3',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }, {
        label: '收货信息',
        elType: 3,
        collspae: false,
        isNormal: 1,
        content: '',
      }, {
        label: '标题4',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }, {
        label: '标题5',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }, {
        label: '标题6',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }, {
        label: '标题7',
        elType: 2,
        collspae: false,
        title: '',
        content: '',
      }],
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab2') {
        this.templateId = this.$store.state.orderContract.templateId;
        GetContractTemplateAll().then(res => {
          this.templates = res;
          this.templates.unshift({
            id: 'default',
            name: 'Bgb Standard contract PR v1.0',
          })
          this.templateChange();
          this.iframeReload();
        })
      }
    },
    'templateId': function (newVal) {
      this.$store.state.orderContract.templateId = this.templateId;
      this.iframeReload();
    },
  },
  created () {
  },
  methods: {
    // 
    templateChange () {
      let param = { templateId: this.templateId }
      GetContractTemplate(param).then(res => {
        res.forEach((item, index) => {
          this.elements[index].title = item.title;
          this.elements[index].content = item.content;
          this.elements[index].isNormal = item.isNormal;
          this.elements[index].id = item.id;
        })
      })
    },
    // 重置合同
    resetContent () {
      this.templateId = '';
      this.elements = [{
        label: '主题',
        elType: 1,
        collspae: false,
        input: '',
      }, {
        label: '标题1',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }, {
        label: '税费条款',
        elType: 3,
        collspae: false,
        isNormal: 1,
        content: '',
      }, {
        label: '标题2',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }, {
        label: '标题3',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }, {
        label: '收货信息',
        elType: 3,
        collspae: false,
        isNormal: 1,
        content: '',
      }, {
        label: '标题4',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }, {
        label: '标题5',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }, {
        label: '标题6',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }, {
        label: '标题7',
        elType: 2,
        collspae: false,
        input: '',
        content: '',
      }];
    },
    // 新增合同
    addTemplate () {
      this.$prompt('Save as New Template', 'Tempalte Name', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        inputPattern: /.+/,
        inputErrorMessage: this.$i18n.t('message.notNull')
      }).then(({ value }) => {
        let param = {
          id: '',
          name: value,
        }
        this.saveTemplate(param);
      }).catch(() => { });
    },
    // 修改合同
    editTemplate () {
      let param = {
        id: this.templateId,
        name: this.templates.find(item => item.id === this.templateId).name,
      }
      this.saveTemplate(param);
    },
    // 保存合同
    saveTemplate (param) {
      param.orderFormId = this.orderId;
      param.contentList = this.elements.map((item, index) => {
        item.sn = index;
        item.templateId = param.id;
        return item;
      })

      AddContractTemplate(param).then(res => {
        if (res.success) {
          let msg = !!param.id ? this.$i18n.t('message.edit') : this.$i18n.t('message.add');
          this.$message.success(msg)
          // this.$emit('saveTemplate')
          GetContractTemplateAll().then(res => {
            this.templates = res
            this.templates.unshift({
              id: 'default',
              name: 'Bgb Standard contract PR v1.0',
            })
          })

          this.templateId = !!param.id ? param.id : res.data;
          this.templateChange();
          this.iframeReload();
        }
      })
    },
    // 
    iframeReload () {
      let iframe = document.createElement('iframe');
      iframe.src = `${this.baseUrl}/Export/exportPurchase1.do?id=${this.orderId}&templateId=${this.templateId}`
      iframe.style = 'width: 100%;  height: 100%;  min-height: 678px;border:0';
      // iframe.frameBorder = '0';

      let oldIframe = this.$refs.tab1.$el.querySelector('iframe');
      if (oldIframe) oldIframe.remove();

      this.$refs.tab1.$el.append(iframe);
    },
    // 
    collspae (item) {
      item.collspae = !item.collspae
    },
    // 
    collspaeAll (type) {
      this.elements.forEach(item => item.collspae = !!type)
    },
    // 移动
    elUp (index) {
      if (index === 0) return;
      [this.elements[index], this.elements[index - 1]] = [this.elements[index - 1], this.elements[index]]
      this.$forceUpdate();
    },
    elDown (index) {
      if (index === this.elements.length - 1) return;
      [this.elements[index], this.elements[index + 1]] = [this.elements[index + 1], this.elements[index]]
      this.$forceUpdate();
    },
    elDel (index) {
      this.elements.splice(index, 1)
    },
    // 删除文件
    delFile (index) {
      this.$store.state.orderContract.attachment.splice(index, 1)
      if (!!this.isCancel) this.submit();
    },
    // 预览文件
    previewFile (file) {
      // window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 显示上传
    showUpload () {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    fileInputChange () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];

      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let formats = res.message.match(/[^.]*$/)[0];
          this.$store.state.orderContract.attachment.push({
            name: res.message,
            formats: formats,
            fileName: file.name
          });
          if (!!this.isCancel) this.submit();
        }
      })
    },
    // 删除合同模板
    delContractTemplate (data, index) {
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: data.id }
        DelContractTemplate(param).then(res => {
          if (res.success) {
            this.templates.splice(index, 1);
            this.$message.success(this.$i18n.t('message.del'))
          } else {
            this.$message.warning('已有订单使用此模板，无法删除！')
          }
        })
      }).catch(() => { });
    },
    // 提交
    submit () {
      let groupForm = this.$store.state.orderOriginData.data;
      let tableData = this.$store.state.orderOriginData.tableData;
      let tableData2 = this.$store.state.orderOriginData.tableData2;

      let totalPriceUnit = tableData.length > 0 ? tableData[0].singlePriceUnit : 1;
      let param = {
        form: {
          isRevoke: 1,
          id: groupForm.id,
          accessGroupId: groupForm.dataAccessGroup,
          projectContractId: groupForm.projectId,
          orderNumber: groupForm.orderNumber,
          orderType: groupForm.orderType,
          orderUserId: groupForm.subscriber,
          orderUser: groupForm.orderUser,
          supplierId: groupForm.supplierId,
          paymentDate: groupForm.paymentDate,
          totalPrice: groupForm.orderTotal,
          totalPriceUnit: totalPriceUnit,
          deliveryDate: groupForm.deliveryDate,
          fare: groupForm.fare,
          receiver: groupForm.receiver,
          address: groupForm.address,
          phoneNumber: groupForm.phoneNumber,
          taxRate: groupForm.taxRate,
          invoiceType: groupForm.invoiceType,
          contractDate: groupForm.contractDate,
          additional1: groupForm.additional1,
          additional2: groupForm.additional2,
          additional3: groupForm.additional3,
          contactId: groupForm.contactId,
          isEnableContacts: groupForm.isEnableContacts,
        },
      }
      param.infos = tableData.map(item => {
        item.deliveryInfos = item.deliveryInfos || [];
        let data = {
          isRevoke: 1,
          applicationFormId: item.applicationFormId,
          orderFormId: groupForm.id,
          deviceInfoId: item.deviceInfoId,
          unit: item.unit,
          singlePrice: item.singlePrice,
          singlePriceUnit: item.singlePriceUnit,
          count: item.count,
          expecteBuyBackTime: item.expecteBuyBackTime,
          id: item.id || '',
          typeName: item.typeName,
          deviceName: item.deviceName,
          brandName: item.brandName,
          deviceModel: item.deviceModel,
          supplierName: item.supplierName,
          applicationFormInfoId: item.applicationFormInfoId,
          projectName: item.projectName,
          preCount: item.preCount,
          isSecondment: item.isSecondment,
          detailed: item.detailed,
          detailed2: item.detailed,
          remark: item.remark,
          remark2: item.remark,
          unit: item.unit,
          deliveryInfos: item.deliveryInfos.map(item => {
            // 验证是否是新收货地址
            if (!/.{8}(-.{4}){3}-.{12}/.test(item.addressId)) {
              item.address = item.addressId;
              item.addressId = '';
            }
            // 验证是否是新收件人
            if (!/.{8}(-.{4}){3}-.{12}/.test(item.consigneeId)) {
              item.consignee = item.consigneeId;
              item.consigneeId = '';
            }
            return item;
          })
        }

        return data
      })

      param.otherItems = tableData2.map(item => {
        return {
          id: item.id || '',
          name: item.name,
          model: item.model,
          orderFormId: groupForm.id,
          count: item.count,
          singlePrice: item.singlePrice,
          singlePriceUnit: item.singlePriceUnit,
          remark: item.remark,
        }
      })

      if (!!this.$store.state.orderContract) {
        param.contract = {
          id: this.$store.state.orderContract.id,
          orderFormId: groupForm.id,
          templateId: this.$store.state.orderContract.templateId,
          attachment: this.$store.state.orderContract.attachment.map(item => item.name).join(','),
          attachmentName: this.$store.state.orderContract.attachment.map(item => item.fileName).join(','),
        }
      }

      AddPurchaseOrders(param).then(res => {
        if (res.success) {

        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  display: flex;
  padding: 0;
  font-size: 14px;
}

.left-box {
  width: 430px;
  margin-right: 20px;
}
.right-box {
  width: calc(100% - 450px);
}
.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.label-item {
  label {
    width: 100%;
    height: 36px;
    line-height: 36px;
    display: block;
  }
  .el-select {
    width: 100%;
  }
}

.title {
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-box {
    i {
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      background-color: #181818;
      color: #fff;
      border-radius: 50%;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.switch-item {
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-card {
  margin-top: 10px;
}
.footer {
  margin-top: 10px;
  text-align: center;
}

.file-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 150px;
    background: #f2f2f2;
    padding-top: 20px;
    position: relative;
    img {
      width: 80px;
      margin: 0 auto;
      display: block;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    label {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0 5px;
      text-align: center;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -8px;
      margin-left: -8px;
    }
  }
}
.option-box {
  height: calc(100% - 118px);
  overflow: auto;
}
.label-box {
  display: flex;
  justify-content: space-between;
  label {
    width: auto;
  }
}

.select-label {
  width: calc(100% - 44px);
  height: 34px;
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: inline-block;
}
.select-icon {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}
</style>