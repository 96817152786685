<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          label="Name"
          prop="supervisor"
        >
          <el-input v-model="dialog.supervisor"></el-input>
        </el-form-item>
        <el-form-item
          label="JobTitle"
          prop="jobTitle"
        >
          <el-input v-model="dialog.jobTitle"></el-input>
        </el-form-item>
        <el-form-item
          label="Staff ID"
          prop="staffId"
        >
          <el-input v-model="dialog.staffId"></el-input>
        </el-form-item>
        <el-form-item
          label="Email"
          prop="email"
        >
          <el-input v-model="dialog.email"></el-input>
        </el-form-item>
        <el-form-item
          label="Phone"
          prop="phone"
        >
          <el-input v-model="dialog.phone"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { AddContact2 } from '@/api/contactList'
export default {
  props: ['visible', 'branchId', 'orgId', 'groupId', 'type'],
  data () {
    return {
      dialog: {
        visible: false,
        title: '',
        supervisor: '',
        jobTitle: '',
        staffId: '',
        email: '',
        phone: '',
        orgId: '',
      },
      rules: {
        supervisor: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        if (this.type === 1) {
          this.dialog.title = 'New Site Director';
        } else {
          this.dialog.title = 'New Client Contact';
        }
        this.dialog.orgId = !!this.orgId ? this.orgId : this.groupId;
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {

    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.dialog.supervisor,
          email1: this.dialog.email,
          phone: this.dialog.phone,
          orgId: this.dialog.orgId,
          post: this.dialog.jobTitle,
          number: this.dialog.staffId,
          supervisor: this.type === 1 ? 1 : 0,
          brandIds: this.branchId,
        }
        AddContact2(param).then(res => {

          this.$emit('submit', res.data, this.dialog);
          this.dialog.visible = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>