import request from '@/utils/request'

// 获取应用列表
export const GetApkList = (data) => request({
  method: 'post',
  url: '/AppRelease/queryByPage.do',
  data: data
})

// 上传APK
export const UploadApk = (data) => request({
  method: 'post',
  url: '/AppRelease/add.do',
  data: data,
  headers: {
    "Content-Type": "multipart/form-data"
  },
})

// 删除
export const DelApk = (data) => request({
  method: 'post',
  url: '/AppRelease/delete.do',
  data: data
})