import request from '@/utils/request'

// 查询项目任务类型列表
export const GetTaskTypeList = (data) => request({
  method: 'post',
  url: `/ProjectStandardTaskType/queryByPage.do`,
  data: JSON.stringify(data)
})

// 新增和修改
export const AddTaskType = (data) => request({
  method: 'post',
  url: `/ProjectStandardTaskType/addOrUpdate.do`,
  data: JSON.stringify(data)
})

// 删除
export const DelTaskType = (id) => request({
  method: 'post',
  url: `/ProjectStandardTaskType/deleted/${id}.do`,
})

// 获取任务类型集合
export const GetTaskTypeAll = () => request({
  method: 'get',
  url: `/ProjectStandardTaskType/queryAll.do`,
})
