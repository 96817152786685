<template>
  <div>
    <div
      v-for="(item,index) in data"
      :key="index"
    >
      <div
        class="item-box"
        :class="{'active':item.active}"
        :data-id="item.id"
        @click="treeClick($event,item)"
        @dblclick="treeDblclick(item)"
        :key="item.id"
      >
        <div class="item">
          <el-checkbox v-model="item.check"></el-checkbox>
        </div>
        <div class="item">{{item.index}}</div>
        <div
          v-show="header.col1"
          class="item label-item"
          :style="{
            'padding-left':item.level * 24 + 10 + 'px'
          }"
        >
          <div
            class="arrow"
            :class="{'collapse':item.collapse}"
            @click="collapse(item)"
            @dblclick.stop
          >
            <i
              v-if="item.childrens.length > 0"
              class="el-icon-caret-bottom"
            ></i>
          </div>
          <label>{{item.name}}</label>
        </div>
        <div
          v-show="header.col2"
          class="item"
        >
          {{!!item.duration?item.duration + 'days':''}}
        </div>
        <div
          v-show="header.col3"
          class="item"
          @dblclick.stop="item.editStart = !item.editStart"
          style="user-select:none"
        >
          <el-date-picker
            v-if="item.editStart"
            v-model="item.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            size="small"
            @change="editDate(item)"
            :clearable="false"
          >
          </el-date-picker>
          <span v-else>{{item.startDate}}</span>
        </div>
        <div
          v-show="header.col4"
          class="item"
          @dblclick.stop="item.editEnd = !item.editEnd"
          style="user-select:none"
        >
          <el-date-picker
            v-if="item.editEnd"
            v-model="item.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            size="small"
            @change="editDate(item)"
            :clearable="false"
          >
          </el-date-picker>
          <span v-else>{{item.endDate}}</span>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.personNames"
          placement="top"
        >
          <div
            v-show="header.col5"
            class="item"
          >
            {{item.personNames}}
          </div>
        </el-tooltip>
      </div>
      <div
        v-show="!item.collapse"
        class="children-box"
      >
        <my-tree
          :data="item.childrens"
          :header="header"
          @treeClick="treeClick"
          @treeDblclick="treeDblclick"
          @update="update"
        ></my-tree>
      </div>
    </div>
  </div>
</template>

<script>
// 接口
import {
  AddTask
} from '@/api/warehouse/projects'

export default {
  name: 'MyTree',
  props: ['data', 'header'],
  data () {
    return {
    }
  },
  watch: {

  },
  created () {

  },
  methods: {
    // 缩放
    collapse (item) {
      item.collapse = !item.collapse;
    },
    // 单击
    treeClick (e, data) {
      this.$emit('treeClick', e, data)
    },
    // 双击
    treeDblclick (data) {
      this.$emit('treeDblclick', data)
    },
    // 修改事件
    editDate (item) {
      let param = {
        id: item.id,
        parentId: item.parentId,
        notes: item.notes,
        projectId: item.projectId,
        name: item.name,
        startDate: item.startDate,
        endDate: item.endDate,
        percents: item.percents,
        predecessorId: item.predecessorId,
        milestone: item.milestone,
        baseStartDate: item.baseStartDate,
        baseEndDate: item.baseEndDate,
      }
      AddTask(param).then(res => {
        if (res) this.$emit('update');
      })
    },
    update () {
      this.$emit('update');
    },
  },
}
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;

  &.active {
    background-color: #91e8d9;
  }
  .item {
    width: 100px;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #dcdfe6;
    font-size: 14px;

    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item:nth-of-type(2),
  .item:nth-of-type(1) {
    width: 50px;
  }
  .item:nth-of-type(3) {
    // width: 200px;
    flex-grow: 1;
  }
  .label-item {
    display: flex;
    align-items: center;
    .arrow {
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: #c0c4cc;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: transform 0.3s;
      &.collapse {
        transform: rotate(-90deg);
      }
    }
  }
}

.el-date-editor {
  width: 100%;
  ::v-deep .el-input__inner {
    padding: 0 5px;
  }
  ::v-deep .el-input__prefix {
    display: none;
  }
}
</style>