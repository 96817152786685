<template>
  <div class="model-wrap">
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
      </div>
    </el-row>
    <el-tabs v-model="activeName">
      <el-tab-pane
        :label="$t('dataMaintenance.faultType.tab1')"
        name="tab1"
      ></el-tab-pane>
      <el-tab-pane
        :label="$t('dataMaintenance.faultType.tab2')"
        name="tab2"
      ></el-tab-pane>
    </el-tabs>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showReplace"
          >{{$t('dataMaintenance.faultType.btn1')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        v-if="isTable"
        :height="tableHeight"
        :data="tableData"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="100"
          sortable
          :label="$t('dataMaintenance.faultType.col1')"
          prop="issId"
        ></el-table-column>
        <template v-if="activeName === 'tab1'">
          <el-table-column
            min-width="150"
            sortable
            :label="$t('dataMaintenance.faultType.col2')"
            prop="mainType"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('dataMaintenance.faultType.col3')"
            prop="subType"
          ></el-table-column>
          <el-table-column
            min-width="130"
            :label="$t('dataMaintenance.faultType.col4')"
            sortable
            prop="deviceName"
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in (typeof scope.row.deviceName === 'string' ? scope.row.deviceName.split(','): [])"
                :key="index"
              >{{ item }}</div>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            min-width="130"
            :label="$t('dataMaintenance.faultType.col4')"
            sortable
            prop="deviceName"
          >
            <template slot-scope="scope">
              <div
                v-for="(item,index) in (typeof scope.row.deviceName === 'string' ? scope.row.deviceName.split(','): [])"
                :key="index"
              >{{ item }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('dataMaintenance.faultType.col5')"
            prop="mainType"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('dataMaintenance.faultType.col6')"
            prop="subType"
          ></el-table-column>
        </template>
        <el-table-column
          min-width="170"
          sortable
          :label="$t('dataMaintenance.faultType.col7')"
          prop="description"
        ></el-table-column>
        <el-table-column
          min-width="130"
          sortable
          :label="$t('dataMaintenance.faultType.col8')"
          prop="indexKeyword"
        ></el-table-column>
        <el-table-column
          min-width="80"
          sortable
          :label="$t('dataMaintenance.faultType.col9')"
          prop="heat"
        >
        </el-table-column>
      </el-table>
    </el-row>

    <!-- 故障弹框 -->
    <fault-dialog1
      :visible.sync="FaultDialog1Visible"
      :data="faultData"
      :total="page.total"
      :items="items"
      @update="getTableData"
    ></fault-dialog1>

    <fault-dialog2
      :visible.sync="FaultDialog2Visible"
      :data="faultData"
      :total="page.total"
      :items="items"
      @update="getTableData"
    ></fault-dialog2>
    <replace-dialog :visible.sync="ReplaceDialogVisible"></replace-dialog>
  </div>
</template>

<script>
// 组件
import FaultDialog1 from './FaultDialog1'
import FaultDialog2 from './FaultDialog2'
import ReplaceDialog from './ReplaceDialog'

// 接口
import {
  GetFaultTypeItemList,
  AddFaultTypeItem,
  DelFaultTypeItem,
} from '@/api/warehouse/dataMaintenance/faultType'

export default {
  props: ['items'],
  components: {
    FaultDialog1,
    FaultDialog2,
    ReplaceDialog,
  },
  data () {
    return {
      FaultDialog1Visible: false,
      FaultDialog2Visible: false,
      ReplaceDialogVisible: false,
      page: {
        total: 0,
        size: 999,
        current: 1,
      },
      name: '',
      searchVal: '',
      faultData: null,
      tableHeight: null,
      tableSelected: [],
      tableLoading: false,
      tableData: [],

      activeName: 'tab1',
      isTable: true,
    }
  },
  watch: {
    'activeName': function () {
      this.isTable = false;
      this.$nextTick(() => {
        this.isTable = true;
      })
    },
    'items': function () {
      if (this.items.length === 0) {
        this.name = '';
        this.tableData = [];
      } else {
        this.name = this.items.length === 1 ? this.items[0].name : `${this.items[0].name}…`
        this.getTableData();
      }
    }
  },
  created () {

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    // this.page.size = Math.ceil((this.tableHeight - 36) / 35);
  },
  methods: {
    getTableData () {
      let param = {
        limit: 9999,
        page: this.page.current,
        search: this.searchVal,
        faultTypeId: this.items.map(item => item.id).join(','),
      }
      this.tableLoading = true;
      GetFaultTypeItemList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      // this.activeName = 'tab1';
      this.faultData = null;
      if (this.activeName === 'tab1') {
        this.FaultDialog1Visible = true;
      } else {
        this.FaultDialog2Visible = true;
      }
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (e) {
      this.faultData = e;
      if (this.activeName === 'tab1') {
        this.FaultDialog1Visible = true;
      } else {
        this.FaultDialog2Visible = true;
      }
    },
    // 删除设备
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelFaultTypeItem(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },

    // 上移
    moveUp (data) {
      if (data.sn === 0) return this.$message.warning('Already is the first, cannot move again!');
      Promise.all([
        this.editSn(data.sn, this.tableData[data.sn - 1]),
        this.editSn(data.sn - 1, this.tableData[data.sn]),
      ]).then(res => {
        this.getTableData();
      })
    },
    // 下移
    moveDown (data) {
      if (data.sn === this.tableData.length - 1) return this.$message.warning('It\'s the last one. It can\'t be moved!');

      Promise.all([
        this.editSn(data.sn, this.tableData[data.sn + 1]),
        this.editSn(data.sn + 1, this.tableData[data.sn]),
      ]).then(res => {
        this.getTableData();
      })
    },
    editSn (sn, data) {
      return new Promise((resolve, reject) => {
        Object.assign(data, { sn: sn })
        AddFaultTypeItem(data).then(res => {
          resolve();
        })
      })
    },
    // 显示替换弹框
    showReplace () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.ReplaceDialogVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.label-item {
  height: 36px;
  line-height: 36px;
  label {
    margin-right: 10px;
  }
  .el-input {
    width: 200px;
  }
}
.model-wrap {
  height: calc(100% - 55px);
}
.table-row {
  height: calc(100% - 46px - 46px - 46px - 55px);
}
</style>