<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          label="原因"
          prop="reason"
        >
          <el-input v-model="dialog.reason"></el-input>
        </el-form-item>
        <el-form-item
          label="是否已付款"
          prop="isPayment"
        >
          <el-radio-group v-model="dialog.isPayment">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dialog.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  RevokeOrder,
  EditRevokeOrderRecord,
} from '@/api/warehouse/purchaseOrders'
export default {
  props: ['visible', 'data', 'isEdit'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '撤销订单',
        id: null,
        reason: '',
        remark: '',
        isPayment: 0,
      },
      rules: {
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible

        if (this.isEdit) {
          Object.assign(this.dialog, {
            id: this.data.id,
            reason: this.data.reason,
            remark: this.data.remark,
            isPayment: this.data.isPayment,
          })
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        if (this.isEdit) {
          this.editRecord();
        } else {
          this.addRecord();
        }
      });
    },
    // 添加记录
    addRecord () {
      this.$confirm('撤销订单后，此订货单关联的所有订货，入库，出库记录将会同时被撤销，请再次确定是否执行撤销操作？', '撤销订单', {
        confirmButtonText: '是，撤销',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          orderFormId: this.data.id,
          reason: this.dialog.reason,
          remark: this.dialog.remark,
          isPayment: this.dialog.isPayment,
        }
        this.dialog.loading = true;
        RevokeOrder(param).then(res => {
          if (res.success) {
            this.$emit('update')
            this.$message.success(this.$i18n.t('message.edit'))
            this.dialog.visible = false;
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      }).catch(() => {

      });
    },
    // 编辑记录
    editRecord () {
      let param = {
        orderFormId: this.data.orderFormId,
        reason: this.dialog.reason,
        isPayment: this.dialog.isPayment,
        remark: this.dialog.remark,
        id: this.dialog.id,
      }
      this.dialog.loading = true;
      EditRevokeOrderRecord(param).then(res => {
        if (res.success) {
          this.$emit('update')
          this.$message.success(this.$i18n.t('message.edit'))
          this.dialog.visible = false;
        }
        this.dialog.loading = false;
      }).catch(error => {
        this.dialog.loading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>