<template>
  <div class="wrap">
    <el-card v-loading="cardLoading">
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!userData || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="220px"
      >
        <el-divider content-position="left">{{$t('newUser.subtitle1')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newUser.label1')"
              prop="user"
            >
              <el-input v-model="groupForm.user"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newUser.label4')"
              prop="email1"
            >
              <el-input
                v-model="groupForm.email1"
                readonly
                onfocus="this.removeAttribute('readonly');"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="permit.paw"
              :label="$t('newUser.label2')"
              prop="paw"
            >
              <el-input
                v-model="groupForm.paw"
                show-password
              ></el-input>
                <!-- readonly
                onfocus="this.removeAttribute('readonly');" -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newUser.label5')"
              prop="email2"
            >
              <el-input v-model="groupForm.email2"></el-input>
            </el-form-item>
          </el-col>

          <el-col
            :span="12"
            :offset="12"
          >
            <el-form-item
              :label="$t('newUser.label6')"
              prop="phone1"
            >
              <el-input v-model="groupForm.phone1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newUser.label3')"
              prop="fullName"
            >
              <el-input v-model="groupForm.fullName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">

            <el-form-item
              :label="$t('newUser.label7')"
              prop="phone2"
            >
              <el-input v-model="groupForm.phone2"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-divider content-position="left">{{$t('newUser.subtitle2')}}</el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newUser.label8')"
              prop="dataAccessGroup"
            >
              <el-select
                :disabled="!permit.dataAccessGroup"
                v-model="groupForm.dataAccessGroup"
                @change="dataAccessGroupChange"
                filterable
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('newUser.label9')"
              prop="role"
            >
              <el-select
                v-model="groupForm.role"
                filterable
              >
                <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('newUser.label10')"
              prop="organic"
            >
              <el-select
                v-model="groupForm.organic"
                filterable
              >
                <el-option
                  v-for="item in organizations"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('newUser.label11')"
              prop="storeGroup"
            >
              <el-button-group class="input-group">
                <el-select
                  class="search-input"
                  v-model="groupForm.storeGroup"
                  filterable
                >
                  <el-option
                    v-for="item in stores"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="!permit.siteView"
                  @click.stop="showEdit('store')"
                >...</el-button>
                <!-- <el-button @click.stop="showEdit('store')">{{$t('operation.edit')}}</el-button> -->
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newUser.label12')"
              prop="reportGroup"
            >
              <el-button-group class="input-group">
                <el-select
                  class="search-input"
                  v-model="groupForm.reportGroup"
                  filterable
                >
                  <el-option
                    v-for="item in reports"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="!permit.reportView"
                  @click.stop="showEdit('report')"
                >...</el-button>
                <!-- <el-button @click.stop="showEdit('report')">{{$t('operation.edit')}}</el-button> -->
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newUser.label13')"
              prop="layout"
              label-width="230px"
            >
              <el-select
                v-model="groupForm.layout"
                filterable
              >
                <el-option
                  v-for="item in layouts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('newUser.label14')"
              prop="kpi"
              label-width="230px"
            >
              <el-select
                v-model="groupForm.kpis"
                filterable
              >
                <el-option
                  v-for="item in layouts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label-width="300px"
              :label="$t('newUser.label15')"
              prop="isSystem"
            >
              <el-switch
                v-model="groupForm.isSystem"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-text="groupForm.isSystem?'Enable':'Disable'"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label-width="300px"
              :label="$t('newUser.label16')"
              prop="isTemplate"
            >
              <el-switch
                v-model="groupForm.isTemplate"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-text="groupForm.isTemplate?'Enable':'Disable'"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label-width="300px"
              :label="$t('newUser.label17')"
              prop="isUseable"
            >
              <el-switch
                v-model="groupForm.isUseable"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-text="groupForm.isUseable?'Enable':'Disable'"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </el-card>
  </div>
</template>

<script>
// 插件
import sha1 from 'js-sha1'
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import { AddUser } from '@/api/userManagement'
import {
  GetOrganization,
  GetDataAccessGroupAll,
} from '@/api/organizations'
import { GetRoleAll } from '@/api/roles'
import { GetSysReportGroupAll } from '@/api/reportGroup'
import { GetSiteGroupAll } from '@/api/stores'

export default {
  name: 'NewUser',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        paw: true,
        dataAccessGroup: true,
        siteView: true,
        reportView: true,
        edit: true,
      },

      cardLoading: false,
      title: '',
      organizations: [],
      dataAccessGroups: [],
      roles: [],
      stores: [],
      reports: [],
      layouts: [],
      kpis: [],
      groupForm: {
        loading: false,
        id: null,
        user: '',
        paw: '',
        fullName: '',
        organic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        role: '',
        dataAccessGroup: localStorage.accessGroupId,
        storeGroup: '',
        reportGroup: '',
        layout: '',
        kpi: '',
        isSystem: 1,
        isTemplate: 1,
        isUseable: 1,
      },
      rules: {
        user: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        paw: [{ required: true, validator: this.validatePass, trigger: 'blur' }],
        fullName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      miPasswordLength: this.$cookies.get('miPasswordLength'),

      userData: null,
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.name === 'NewUser' && from.name === 'StoreGroupEditor')
        GetSiteGroupAll({ accessGroupId: this.groupForm.dataAccessGroup }).then(res => this.stores = res)
      if (to.name === 'NewUser' && from.name === 'ReportGroupEditor')
        GetSysReportGroupAll({ accessGroupId: this.groupForm.dataAccessGroup }).then(res => this.reports = res);
    },
  },
  created () {
    this.dealPermission();

    this.initData();
    this.dataAccessGroupChange();
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 回显数据
    initData () {
      this.userData = localStorage.getItem('userData');
      if (!!this.userData) {
        this.userData = JSON.parse(this.userData);
        this.title = this.permit.edit ? this.$i18n.t('newUser.title2') : this.$i18n.t('newUser.title3');
        Object.assign(this.groupForm, {
          id: this.userData.id,
          user: this.userData.userAccount,
          fullName: this.userData.fullname,
          organic: this.userData.organizationId,
          email1: this.userData.emailAddress,
          email2: this.userData.secondEmailAddress,
          phone1: this.userData.phoneNumber,
          phone2: this.userData.secondPhoneNumber,
          role: this.userData.roles,
          dataAccessGroup: this.userData.accessGroupId,
          storeGroup: this.userData.storeGroupId,
          reportGroup: this.userData.reportGroupId,
          layout: this.userData.dashBoardLayout,
          isSystem: this.userData.isSystem,
          isTemplate: this.userData.isTemplates,
          isUseable: this.userData.accountStatus,
        })
      } else {
        this.title = this.$i18n.t('newUser.title1');
      }
    },
    // 添加或修改用户
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        let param = {
          userAccount: this.groupForm.user.trim(),
          password: sha1(this.groupForm.paw),
          fullname: this.groupForm.fullName,
          organizationId: this.groupForm.organic,
          emailAddress: this.groupForm.email1,
          secondEmailAddress: this.groupForm.email2,
          phoneNumber: this.groupForm.phone1,
          secondPhoneNumber: this.groupForm.phone2,
          roles: this.groupForm.role,
          accessGroupId: this.groupForm.dataAccessGroup,
          storeGroupId: this.groupForm.storeGroup,
          reportGroupId: this.groupForm.reportGroup,
          dashBoardLayout: this.groupForm.layout,
          isSystem: this.groupForm.isSystem,
          isTemplates: this.groupForm.isTemplate,
          accountStatus: this.groupForm.isUseable,
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }
        if (this.groupForm.paw === '') param.password = '';

        this.groupForm.loading = true;
        AddUser(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.back();
          } else {
            if (res.status === 100) this.$message.warning('User account is occupied.')
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 密码验证
    validatePass (rule, value, callback) {
      if (!!this.groupForm.id && this.groupForm.paw.length === 0) {
        callback();
      } else {
        if (this.groupForm.paw.length < this.miPasswordLength) {
          callback(new Error(`${this.$i18n.t('newUser.message2')}${this.miPasswordLength}this.$i18n.t('newUser.message3')`));
          // callback(new Error(`The password is at least ${this.miPasswordLength} characters long`));
        } else {
          let count = 0;
          if (/\d/.test(this.groupForm.paw)) count++;
          if (/[a-z]/.test(this.groupForm.paw)) count++;
          if (/[A-Z]/.test(this.groupForm.paw)) count++;
          if (/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/.test(this.groupForm.paw)) count++;
          if (count < 3) {
            callback(new Error(this.$i18n.t('newUser.message1')));
          } else {
            callback();
          }
        }
      }
    },
    // 数据访问组变更
    dataAccessGroupChange (e) {
      let param = { accessGroupId: this.groupForm.dataAccessGroup }
      if (e !== undefined) {
        this.groupForm.organic = '';
        this.groupForm.role = '';
        this.groupForm.storeGroup = '';
        this.groupForm.reportGroup = '';
      }
      // 获取组织
      GetOrganization(param).then(res => this.organizations = res)
      // 获取角色
      GetRoleAll(param).then(res => this.roles = res)
      // 获取店铺组
      GetSiteGroupAll(param).then(res => this.stores = res)
      // 获取系统报表组
      GetSysReportGroupAll(param).then(res => this.reports = res);
    },
    // 
    showEdit (type) {
      switch (type) {
        case 'store':
          this.$router.push({ path: '/home/storeGroupEditor' })
          break;
        case 'report':
          this.$router.push({ path: '/home/reportGroupEditor' })
          break;
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.dataAccessGroup = permission.includes('UserEditDataAccessGroups(DataPermissions)')
        this.permit.paw = permission.includes('UserChangeUserPassword')
        this.permit.siteView = permission.includes('SiteGroupEditorView')
        this.permit.reportView = permission.includes('ReportGroupEditorView')
        this.permit.edit = permission.includes('UserEditUser');
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.group-form {
  .el-select {
    width: 100%;
  }
  .select-table {
    margin-top: 20px;
    ::v-deep td,
    ::v-deep th {
      height: 35px;
      line-height: 35px;
      padding: 0;
    }
  }

  .el-date-editor {
    width: 100%;
  }

  .select-box {
    display: flex;
  }
}
</style>