import request from '@/utils/request'

// 查询确认单列表
export const GetConfirmOrderList = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/queryByPage.do',
  data: JSON.stringify(data)
})

// 修改确认单
export const EditConfirmOrder = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/update.do',
  data: JSON.stringify(data)
})

// 删除确认单
export const DelConfirmOrder = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/delete.do',
  data: JSON.stringify(data)
})

// 状态更新
export const StatusUpdate = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/updateStatus.do',
  data: JSON.stringify(data)
})

// 自动生成确认单号
export const AutoConfirmOrderNumber = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/getNumberId.do',
  data: JSON.stringify(data)
})

// 获取确认单详情
export const GetConfirmOrderItem = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/queryById.do',
  data: JSON.stringify(data)
})

// 获取确认单记录
export const GetConfirmOrderLog = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/queryByConfirmOrderList.do',
  data: JSON.stringify(data)
})

// 获取确认单状态集合
export const GetConfirmOrderStatus = (data) => request({
  method: 'post',
  url: '/WarehouseCompletionStatus/queryByConfirmStatus.do',
  data: JSON.stringify(data)
})

// 下载PDF
export const DownLoadPDF = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/exportPDF.do',
  data: JSON.stringify(data),
  responseType: 'arraybuffer',
})


// 根据服务单号查询详情
export const GetDetailsByNumber = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/queryByNumber.do',
  data: JSON.stringify(data),
})

// 附件下载
export const AccessoryDownload = (data) => request({
  method: 'get',
  url: '/Export/downloadAnnex.do',
  params: data,
  responseType: 'arraybuffer'
})

//服务确认单导出
export const ExportConfirmOrder = (data) => request({
  method: 'post',
  url: '/ConfirmOrder/exportExcel.do',
  data: JSON.stringify(data),
})
