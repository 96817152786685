var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
      'font-size':_vm.elData.style.fontSize + 'px',
      'line-height':_vm.elData.style.lineHeight + 'px',
      color :_vm.elData.style.color ,
      'font-weight': _vm.elData.style.fontWeight,
      'font-style': _vm.elData.style.fontStyle,
      'text-decoration': _vm.elData.style.textDecoration,
      'text-align': _vm.elData.style.textAlign,
    })},[_vm._v(" "+_vm._s(_vm.elData.contentStart)+_vm._s(_vm.index + 1)+_vm._s(_vm.elData.contentEnd)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }