<template>
  <div
    v-show="visible"
    class="menu-box"
  >
    <div
      v-show="!type"
      class="item"
      @click="submit(1)"
    >{{$t('counterMode.btn6')}}</div>
    <div
      v-show="!type"
      class="item"
      @click="submit(2)"
    >{{$t('counterMode.btn7')}}</div>
    <div
      class="item"
      @click="submit(3)"
    >{{$t('counterMode.btn8')}}</div>
  </div>
</template>

<script>
export default {
  props: ['visible', 'type'],
  data () {
    return {

    }
  },
  methods: {
    submit (type) {
      this.$emit('submit', type)
      this.$emit('update:visible', false)
    }
  }

}
</script>

<style lang="scss" scoped>
.menu-box {
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 999;
  .item {
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }
}
</style>