<template>
  <div>
    <!-- 拆除/搬离设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog6.label1')"
          prop="typeId"
        >
          <el-select v-model="dialog.typeId">
            <el-option
              v-for="item in installations"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog6.label2')"
          prop="faultDescription"
        >
          <el-input
            type="textarea"
            v-model="dialog.faultDescription"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog6.label3')"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dialog.remark"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label4')"
              prop="deviceInfoName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.deviceInfoName"
                  class="search-input"
                  @change="deviceChange"
                ></el-input>
                <el-button @click="searchData">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label5')"
              prop="location"
            >
              <my-select
                :data="locations"
                :value.sync="dialog.location"
                size="small"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="deviceChange"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label6')"
              prop="deviceInfoModel"
            >
              <el-input
                v-model="dialog.deviceInfoModel"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label7')"
              prop="serialNumber"
            >
              <el-input
                v-model="dialog.serialNumber"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label8')"
              prop="qrCode"
            >
              <el-input
                v-model="dialog.qrCode"
                @change="qrCodeChange"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label9')"
              prop="label"
            >
              <el-input
                v-model="dialog.label"
                @change="deviceChange"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label10')"
              prop="attributable"
            >
              <el-select v-model="dialog.attributable">
                <el-option
                  :value="0"
                  :label="$t('newServiceConfirmationForm.dialog6.option1')"
                ></el-option>
                <el-option
                  :value="1"
                  :label="$t('newServiceConfirmationForm.dialog6.option2')"
                ></el-option>
                <el-option
                  :value="2"
                  :label="$t('newServiceConfirmationForm.dialog6.option3')"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog6.label11')"
              prop="deviceSupplier"
            >
              <el-input v-model="dialog.deviceSupplier"></el-input>
            </el-form-item>
            <el-form-item :label="$t('newServiceConfirmationForm.dialog6.label12')">
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.referenceNo"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showNumber(1)">{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item :label="$t('newServiceConfirmationForm.dialog6.label13')">
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.quotationNo"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showNumber(2)">{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item :label="$t('newServiceConfirmationForm.dialog6.label14')">
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.purchaseNo"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showNumber(3)">{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          @click="copySubmit"
        >{{$t('newServiceConfirmationForm.dialog6.btn1')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :branch-id="branchId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <!-- 编号弹框 -->
    <number-dialog
      :visible.sync="NumberDialogVisble"
      :data="numberData"
      :type="numberType"
      :branch-id="branchId"
      @submit="numberSubmit"
    ></number-dialog>
  </div>
</template>

<script>
// 组件
import EquipmentDialog from './EquipmentDialog'
import NumberDialog from '@/pages/warehouse/submitRequest/components/NumberDialog'
import MySelect from '@/components/MySelect'

// 接口
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import { GetLocationAll } from '@/api/warehouse/deviceManagement'

export default {
  components: {
    EquipmentDialog,
    NumberDialog,
    MySelect
  },
  props: ['visible', 'data', 'describe', 'dispose', 'branchId'],
  data () {
    return {
      EquipmentDialogVisible: false,
      NumberDialogVisble: false,

      installations: [],
      locations: [],
      dialog: {
        visible: false,
        oading: false,
        title: '',
        qrCode: '',
        deviceInfoName: '',
        deviceInfoModel: '',
        location: '',
        serialNumber: '',
        deviceId: '',
        faultDescription:'',
        remark: '',
        typeInstallation: '',
        deviceTypeName: '',
        typeId: '',
        typeName: '',
        label: '',
        deviceSupplier: '',
        attributable: '',
        referenceId: '',
        quotationId: '',
        purchaseId: '',
        referenceNo: '',
        quotationNo: '',
        purchaseNo: '',
      },
      rules: {
        typeId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceInfoName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        location: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      faultTypeName: '',
      numberType: null,
      numberData: null,
      reference: [],
      quotation: [],
      purchase: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog6.title1');

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, this.data)
            this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog6.title1') + ` - ${this.data.rowIndex + 1}`;
            let referenceId = !!this.dialog.referenceId ? this.dialog.referenceId.split(',') : [];
            let quotationId = !!this.dialog.quotationId ? this.dialog.quotationId.split(',') : [];
            let purchaseId = !!this.dialog.purchaseId ? this.dialog.purchaseId.split(',') : [];
            let referenceNo = !!this.dialog.referenceNo ? this.dialog.referenceNo.split(',') : [];
            let quotationNo = !!this.dialog.quotationNo ? this.dialog.quotationNo.split(',') : [];
            let purchaseNo = !!this.dialog.purchaseNo ? this.dialog.purchaseNo.split(',') : [];
            referenceId.forEach((item, index) => {
              this.reference.push({
                name: referenceNo[index],
                id: item,
              })
            })
            quotationId.forEach((item, index) => {
              this.quotation.push({
                name: quotationNo[index],
                id: item,
              })
            })
            purchaseId.forEach((item, index) => {
              this.purchase.push({
                name: purchaseNo[index],
                id: item,
              })
            })
          })
        } else {
          this.dialog.faultDescription = sessionStorage.faultDescription;
          this.dialog.remark = sessionStorage.handleMethod;
        }
        // 获取设备位置
        GetLocationAll({ branchId: this.branchId }).then(res => this.locations = res.map(item => Object.assign({ name: item })));

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'reference': {
      handler: function () {
        this.dialog.referenceId = this.reference.map(item => item.id).join(',');
        this.dialog.referenceNo = this.reference.map(item => item.name || item.id).join(',');
      },
      deep: true
    },
    'quotation': {
      handler: function () {
        this.dialog.quotationId = this.quotation.map(item => item.id).join(',');
        this.dialog.quotationNo = this.quotation.map(item => item.name || item.id).join(',');
      },
      deep: true
    },
    'purchase': {
      handler: function () {
        this.dialog.purchaseId = this.purchase.map(item => item.id).join(',');
        this.dialog.purchaseNo = this.purchase.map(item => item.name || item.id).join(',');
      },
      deep: true
    },
  },
  created () {
    // 获取安装原因
    GetDataFieldAll({ parent: '100000000000000000000000000000000024' }).then(res => this.installations = res)

  },
  methods: {
    searchData () {
      this.EquipmentDialogVisible = true;
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.dialog, {
        qrCode: data.qrCode,
        deviceInfoName: data.deviceInfoName,
        deviceInfoModel: data.deviceInfoModel,
        location: data.location,
        serialNumber: data.serialNumber,
        deviceId: data.id,
        deviceTypeName: data.deviceTypeName,
        label: data.label,
        deviceSupplier: data.supplierName,
      })
      this.$refs.dialogForm.validateField('deviceInfoName');
    },
    // 提交
    submit (param) {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        this.dialog.typeName = this.installations.find(item => item.id === this.dialog.typeId).name;

        this.$emit('submit', {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? 'edit' : 'add',
          copy: param.copy
        });

        sessionStorage.faultDescription = this.dialog.faultDescription;
        sessionStorage.handleMethod = this.dialog.remark;
        this.dialog.visible = false;
      });
    },
    // 复制
    copySubmit () {
      this.submit({ copy: true })
    },
    // 修改设备信息，清空设备id 
    deviceChange () {
      this.dialog.deviceId = '';
      this.dialog.qrCode = '';
    },
    qrCodeChange () {
      this.dialog.deviceId = '';
    },
    // 显示编号弹框
    showNumber (type) {
      switch (type) {
        case 1:
          this.numberData = this.reference;
          break;
        case 2:
          this.numberData = this.quotation;
          break;
        case 3:
          this.numberData = this.purchase;
          break;
      }
      this.numberType = type;
      this.NumberDialogVisble = true;
    },
    // 提交编号
    numberSubmit (type, data) {
      switch (type) {
        case 1:
          this.reference = data;
          break;
        case 2:
          this.quotation = data;
          break;
        case 3:
          this.purchase = data;
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>