<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dataMaintenance.deviceType.title5')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
      >
        <el-form-item prop="company">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isStore">客户名称</el-checkbox>
          </div>
          <el-select
            :disabled="!dialog.isStore"
            v-model="dialog.company"
            filterable
            @change="companyChange"
          >
            <el-option
              v-for="item in companys"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="organic">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isStore">网点名称</el-checkbox>
          </div>
          <el-select
            :disabled="!dialog.isStore"
            v-model="dialog.organic"
            filterable
          >
            <el-option
              v-for="item in organics"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="operatingStatus">
          <div
            slot="label"
            class="label"
          >
            <el-checkbox v-model="dialog.isState">运行状态</el-checkbox>
          </div>
          <el-select
            :disabled="!dialog.isState"
            v-model="dialog.operatingStatus"
            filterable
          >
            <el-option
              v-for="item in operatingStatus"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import { GetCompanyBranch } from '@/api/warehouse/projectContract'
import { ConfirmationTemplates } from '@/api/warehouse/dataMaintenance/serviceConfirmationTemplate'
import { BatchEditDeviceInfo } from '@/api/warehouse/deviceManagement'

export default {
  components: {
    MySelect
  },
  props: ['visible', 'ids'],
  data () {
    return {
      companys: [],
      organics: [],
      operatingStatus: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        isCompany: false,
        isStore: false,
        isState: false,
        company: '',
        organic: '',
        operatingStatus: '',
      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col1'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.organics = [];
        Object.assign(this.dialog, {
          isCompany: false,
          isStore: false,
          isState: false,
        });
        // 获取客户
        ConfirmationTemplates().then(res => this.companys = res);
        // 获取状态
        GetDataFieldAll({ parent: '100000000000000000000000000000000025' }).then(res => this.operatingStatus = res);

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 获取客户对应网点
    companyChange () {
      this.dialog.organic = '';
      GetCompanyBranch({ groupId: this.dialog.company }).then(res => this.organics = res);
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        if (this.dialog.isStore && !this.dialog.organic) return this.$message.warning('网点名称不能为空.')

        let param = {
          src: this.ids,
        }
        if (this.dialog.isStore) param.orgId = this.dialog.organic;
        if (this.dialog.isState) param.opStatusId = this.dialog.operatingStatus;

        this.dialog.loading = true;
        BatchEditDeviceInfo(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.edit'))
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            this.$message.warning(res.data)
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.label {
  position: relative;
  z-index: 1;
}

.el-select {
  width: 100%;
}
</style>