<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dataMaintenance.deviceType.title3')"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <p>{{$t('dataMaintenance.deviceType.label1')}}</p>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
          @select="rowSelect"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            label="设备名称"
            prop="deviceInfoName"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            label="品牌"
            prop="brandName"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            label="规格型号"
            prop="deviceInfoModel"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            label="序列号"
            prop="serialNumber"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            label="安装位置"
            prop="location"
          ></el-table-column>
        </el-table>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  MergeDevice,
} from '@/api/warehouse/deviceManagement'

export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '',

      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 386,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.$refs.table && this.$refs.table.clearSelection();
        this.tableData = this.data;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 
    rowClick (row, column, event) {
      this.$refs.table.clearSelection();
      this.$nextTick(() => {
        this.$refs.table.toggleRowSelection(row)
      })
    },
    // 
    rowSelect (selection, row) {
      this.$refs.table.clearSelection();
      this.$nextTick(() => {
        this.$refs.table.toggleRowSelection(row)
      })
    },
    // 提交
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      let param = {
        src: this.tableData.filter(item => item.id !== this.tableSelected[0].id).map(item => item.id).join(','),
        target: this.tableSelected[0].id,
      }
      this.dialog.loading = true;
      MergeDevice(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.edit'))
          this.dialog.visible = false;
          this.$emit('update')
        }
        this.dialog.loading = false;
      }).catch(error => {
        this.dialog.loading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-table {
  outline: none;
  ::v-deep .current-row td {
    background-color: #91e8d9 !important;
  }
}
.supplier-item {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>