<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="updateDingtalk"
              :loading="updateLoading"
            >{{$t('operation.update')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              size="medium"
              @click="importEngineer"
            >{{$t('operation.import')}}</el-button>
            <el-button
              size="medium"
              @click="showFilter"
            >{{$t('operation.filter')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="name"
            :label="$t('engineerManagement.col1')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="number"
            :label="$t('engineerManagement.col2')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="workPhone"
            :label="$t('engineerManagement.col3')"
          >
          </el-table-column>
          <el-table-column
            min-width="220"
            sortable
            prop="email"
            :label="$t('engineerManagement.col4')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="status"
            :label="$t('engineerManagement.col5')"
          >
            <template slot-scope="scope">
              {{scope.row.status == 0 ? 'Active' : 'Terminated'}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="orgName"
            :label="$t('engineerManagement.col11')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="serviceCount"
            :label="$t('engineerManagement.col6')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="comlaint"
            :label="$t('engineerManagement.col7')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="attendance"
            :label="$t('engineerManagement.col8')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.attendance == 0">{{$t('newEngineer.option1')}}</span>
              <span v-else-if="scope.row.attendance == 2">{{$t('newEngineer.option3')}}</span>
              <span v-else>{{$t('newEngineer.option2')}}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="leavestatus"
            :label="$t('engineerManagement.col9')"
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="userAccout"
            :label="$t('engineerManagement.col10')"
            sortable
          >
            <template slot-scope="scope">
              {{scope.row.userAccout ? 'Yes':'No'}}
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
    <!-- 引入弹框 -->
    <import-dialog :visible.sync="ImportDialogVisible"></import-dialog>
    <!-- 筛选弹框 -->
    <filter-dialog
      :visible.sync="FilterDialogVisible"
      :data.sync="filterData"
      @update="searchData"
    ></filter-dialog>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
import ImportDialog from './components/ImportDialog'
import FilterDialog from './components/FilterDialog'
// 接口
import {
  GetEngineerList,
  AddEngineer,
  DelEngineer,
  UpdateDingtalk,
} from '@/api/warehouse/engineerManagement'
export default {
  name: 'EngineerManagement',
  components: {
    ColSet,
    ImportDialog,
    FilterDialog,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
      },
      ImportDialogVisible: false,
      FilterDialogVisible: false,
      // attendance: ["Absent", "Present", "Leave"],
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      updateLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      filterData: {
        contains: true
      },
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'EngineerManagement') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      if (this.filterData.contains) param.contains = Number(this.filterData.contains);

      this.tableLoading = true;
      GetEngineerList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增
    showAdd () {
      this.$router.push({ path: '/home/newEngineer' })
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newEngineer', query: { id: e.id } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelEngineer(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 更新
    updateDingtalk () {
      this.updateLoading = true;
      UpdateDingtalk().then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('operation.update'))
        }
        this.updateLoading = false;
      })
    },
    // 导入
    importEngineer () {
      this.ImportDialogVisible = true;
    },
    // 显示筛选
    showFilter () {
      this.FilterDialogVisible = true;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('PersonnelManagementSearch');
        this.permit.add = permission.includes('PersonnelManagementAdd');
        // this.permit.edit = permission.includes('PersonnelManagementEdit');
        this.permit.del = permission.includes('PersonnelManagementDelete');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>
