<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          prop="createTime"
          label="撤销日期"
          :formatter="$formatTableData"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="fullName"
          label="操作员"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="reason"
          label="撤销原因"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="isPayment"
          label="是否已付款"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-if="scope.row.isPayment === 1"
            >是</el-tag>
            <el-tag
              size="small"
              v-else
            >否</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="remark"
          label="备注"
          sortable
        >
        </el-table-column>
      </el-table>
    </el-row>

    <!-- 撤销弹框 -->
    <cancel-dialog
      :visible.sync="CancelDialogVisible"
      :data="orderData"
      :is-edit="true"
      @update="getTableData"
    ></cancel-dialog>
  </div>
</template>

<script>
// 组件
import CancelDialog from './CancelDialog'
// 接口
import {
  RevokeOrderRecord,
  DelRevokeOrderRecord,
} from '@/api/warehouse/purchaseOrders'
export default {
  components: {
    CancelDialog
  },
  props: ['active', 'orderId'],
  data () {
    return {
      CancelDialogVisible: false,
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      orderData: null,
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab4') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.getTableData();
      }
    },
  },
  created () {
  },
  methods: {

    getTableData () {
      let param = {
        orderFormId: this.orderId,
      }
      this.tableLoading = true;
      RevokeOrderRecord(param).then(res => {
        this.tableData = res;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.orderData = e;
      this.CancelDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelRevokeOrderRecord(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.input-group {
  height: 36px;
}
.table-row {
  height: calc(100% - 46px);
}
</style>