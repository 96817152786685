<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="到货"
          name="tab1"
        >
          <el-form
            :model="dialog"
            :rules="rules"
            ref="dialogForm"
            size="small"
            class="dialog-form"
          >
            <el-form-item prop="isFactory">
              <el-radio-group v-model="dialog.isFactory">
                <el-radio :label="2">厂家发BGB仓库</el-radio>
                <el-radio :label="1">厂家直发项目</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="设备名称">
                  <el-input
                    disabled
                    v-model="data.deviceName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="品牌">
                  <el-input
                    disabled
                    v-model="data.brandName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="型号">
                  <el-input
                    disabled
                    v-model="data.deviceModel"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="申购数量">
                  <el-input
                    disabled
                    v-model="dialog.appCount"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="订购数量">
                  <el-input
                    disabled
                    v-model="data.orderCount"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="状态"
                  prop="status"
                >
                  <el-select v-model="dialog.status">
                    <el-option
                      label="已到货-全部"
                      :value="1"
                    ></el-option>
                    <el-option
                      label="已到货-部分"
                      :value="0"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="本次到货数量"
                  prop="count"
                >
                  <el-input-number
                    v-model="dialog.count"
                    :controls="false"
                    :min="0"
                    :max="dialog.maxCount"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="累计到货数量">
                  <el-input
                    disabled
                    v-model="dialog.arrivalCount"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col
                v-if="dialog.status === 0"
                :span="4"
              >
                <el-form-item
                  label="剩余预计发货日期"
                  prop="estimatedDate"
                >
                  <el-date-picker
                    v-model="dialog.estimatedDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="物流信息">
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-checkbox
                    v-model="dialog.isFactory"
                    :true-label="1"
                    :false-label="2"
                  >采购厂家发货</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row> -->

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="寄件方"
                  prop="sendFirm"
                >
                  <el-input v-model="dialog.sendFirm"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="收件方"
                  prop="recipientFirm"
                >
                  <el-input v-model="dialog.recipientFirm"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="寄件人"
                  prop="sender"
                >
                  <el-input v-model="dialog.sender"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="电话"
                  prop="sendPhone"
                >
                  <el-input v-model="dialog.sendPhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="收件人"
                  prop="recipient"
                >
                  <el-input v-model="dialog.recipient"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="电话"
                  prop="recipientPhone"
                >
                  <el-input v-model="dialog.recipientPhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="地址"
                  prop="sendAddress"
                >
                  <el-input v-model="dialog.sendAddress"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="地址"
                  prop="recipientAddress"
                >
                  <el-input v-model="dialog.recipientAddress"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="物流公司"
                  prop="logisticsCompany"
                >
                  <!-- <el-input v-model="dialog.logisticsCompany"></el-input> -->
                  <my-select
                    :data="logisticsCompanys"
                    :value.sync="dialog.logisticsCompany"
                    size="small"
                    :prop="{
                      label:'name',
                      value:'name'
                    }"
                  ></my-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="运单号"
                  prop="number"
                >
                  <el-input v-model="dialog.number"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              label="备注"
              prop="remark"
            >
              <el-input
                v-model="dialog.remark"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item prop="attachment">
              <div class="file-box">
                <div
                  v-for="(item,index) in dialog.attachment"
                  :key="index"
                  class="file-item"
                >
                  <div
                    class="block"
                    @click="previewFile(item)"
                  >
                    <img :src="'./static/image/fileType/' + item.formats + '.png'">
                    <label :title="item.fileName">{{item.fileName}}</label>
                    <i
                      class="el-icon-delete"
                      @click.stop="delFile(index)"
                    ></i>
                  </div>
                </div>
                <div
                  class="file-item add-item"
                  @click="showUpload"
                >
                  <div class="block">
                    <i class="el-icon-plus"></i>
                  </div>
                  <label></label>
                </div>
              </div>
            </el-form-item>

          </el-form>
        </el-tab-pane>
        <el-tab-pane
          label="历史"
          name="tab2"
        >
          <el-row
            class="table-row"
            ref="tableRow"
          >
            <el-table
              border
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
            >
              <el-table-column
                min-width="150"
                prop="createTime"
                label="日期"
                sortable
                :formatter="$formatTableData"
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="isFactory"
                label="寄件方式"
                sortable
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.isFactory ===  1">厂家直发项目</span>
                  <span v-if="scope.row.isFactory === 2">厂家发BGB仓库</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="130"
                label="类型"
                sortable
              >
                <template>
                  到货
                </template>
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="deviceName"
                label="设备名称"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="brandName"
                label="品牌"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="deviceModel"
                label="型号"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="appCount"
                label="申购数量"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="orderCount"
                label="订购数量"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="status"
                label="状态"
                sortable
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 0">已到货-部分</span>
                  <span v-if="scope.row.status === 1">已到货-全部</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="estimatedDate"
                label="预计发货日期"
                sortable
              ></el-table-column>
              <!-- <el-table-column
                min-width="130"
                prop="xxx"
                label="发货数量"
                sortable
              ></el-table-column> -->
              <el-table-column
                min-width="130"
                prop="count"
                label="到货数量"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="sendFirm"
                label="寄件方"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="logisticsCompany"
                label="物流公司"
                sortable
              ></el-table-column>
              <el-table-column
                min-width="130"
                prop="recipient"
                label="收件人"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="180"
                prop="number"
                label="运单号"
                sortable
              >
                <template slot-scope="scope">
                  <span class="number-box">{{scope.row.number}}</span>
                  <el-button
                    v-if="!!scope.row.number"
                    size="mini"
                    @click="numberCopy(scope.row)"
                  >复制</el-button>
                </template>
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="xxx"
                label="签收状态"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="fullname"
                label="操作员"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="130"
                prop="remark"
                label="备注"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="200"
                label="操作"
                sortable
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="showEdit(scope.row)"
                  >编辑</el-button>
                  <el-button
                    size="mini"
                    @click="delItem(scope.row)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-row class="page-wrap">
            <my-page
              layout="prev, pager, next, jumper"
              :total="page.total"
              :page-size.sync="page.size"
              :current-page.sync="page.current"
              @current-change="getTableData"
              @size-change="getTableData"
            >
            </my-page>
          </el-row>
        </el-tab-pane>

      </el-tabs>

      <div
        v-show="activeName === 'tab1'"
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
    <!-- 图片上传 -->
    <input
      type="file"
      ref="fileInput"
      hidden
      @change="fileInputChange"
    >
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import { UploadImage } from '@/api'

import {
  GetArrivalRecordList,
  AddArrivalRecord,
  DelArrivalRecord,
  GetArrivalRecordItem,
  GetShipmentRecordList,
  GetSendAndRecipient,
} from '@/api/warehouse/purchaseOrders'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    MySelect
  },
  props: ['visible', 'data', 'applicationFormInfoId'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      activeName: 'tab1',
      logisticsCompanys: [],
      dialog: {
        visible: false,
        loading: false,
        title: '到货确认',
        id: null,
        status: 0,
        appCount: 0,
        count: 0,
        maxCount: 0,
        isFactory: 2,
        sender: '',
        sendFirm: '',
        sendPhone: '',
        sendAddress: '',
        recipient: '',
        recipientFirm: '',
        recipientPhone: '',
        recipientAddress: '',
        logisticsCompany: '',
        number: '',
        remark: '',
        attachment: [],
        attachmentName: [],
        arrivalCount: 0,
        estimatedDate: null,
      },
      rules: {
        count: [{ type: 'number', min: 1, message: '不能为0', trigger: 'blur' }],
        // number: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
      tableHeight: 385,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.activeName = 'tab1';
        this.dialog.id = null;
        this.dialog.arrivalCount = this.data.arrivalCount;
        this.dialog.maxCount = this.data.orderCount - this.data.arrivalCount;
        this.dialog.visible = this.visible;

        if (!!this.applicationFormInfoId) {
          this.dialog.appCount = this.data.appCount;
        } else {
          this.dialog.appCount = this.data.count;
        }

        this.init();

        // 获取物流公司
        if (this.logisticsCompanys.length === 0)
          GetDataFieldAll({ parent: '100000000000000000000000000000000010' }).then(res => this.logisticsCompanys = res);

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'activeName': function (newVal) {
      if (newVal === 'tab2') {
        this.getTableData();
      }
    }
  },
  created () {

  },
  methods: {
    init () {
      // 如果此设备，有发货信息，自动填入寄件、收件、单号
      // 如果没有，寄件，自动填入供应商信息， 收件自动填入BGB信息
      let param = {
        limit: 9999,
        page: 1,
      }
      GetShipmentRecordList(param).then(res => {
        let item = res.list.find(item => item.status !== 0)
        if (!!item) {
          Object.assign(this.dialog, {
            sender: item.sender,
            sendFirm: item.sendFirm,
            sendPhone: item.sendPhone,
            sendAddress: item.sendAddress,
            recipient: item.recipient,
            recipientFirm: item.recipientFirm,
            recipientPhone: item.recipientPhone,
            recipientAddress: item.recipientAddress,
            number: item.number,
          })
        } else {
          // 获取供应商信息和收货人信息
          this.getSendAndRecipient();
        }
      })
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
      }

      if (!!this.applicationFormInfoId) {
        param.deviceInfoId = this.applicationFormInfoId
      } else {
        param.deviceInfoId = this.data.id
      }

      this.tableLoading = true;
      GetArrivalRecordList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 编辑
    showEdit (row) {
      let param = { id: row.id }
      GetArrivalRecordItem(param).then(res => {
        Object.assign(this.dialog, res)
        this.dialog.count = res.count;
        this.dialog.maxCount = this.data.orderCount - this.data.arrivalCount + res.count;
        this.dialog.attachmentName = !this.dialog.attachmentName ? [] : this.dialog.attachmentName.split(',');
        this.dialog.attachment = !this.dialog.attachment ? [] : this.dialog.attachment.split(',').map((item, index) => {
          let formats = item.match(/[^.]*$/)[0];
          return {
            name: item,
            formats: formats,
            fileName: this.dialog.attachmentName[index]
          }
        })

        this.activeName = 'tab1';
      })
    },
    // 删除
    delItem (row) {
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: row.id }
        DelArrivalRecord(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
            this.$emit('update');
            this.dialog.arrivalCount -= row.count;
          }
        })
      }).catch(() => { });
    },
    // 提交
    submit () {
      if (this.activeName === 'tab2') return this.dialog.visible = false;

      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          infoId: this.data.id,
          status: this.dialog.status,
          count: this.dialog.count,
          remark: this.dialog.remark,
          isFactory: this.dialog.isFactory,
          sender: this.dialog.sender,
          sendFirm: this.dialog.sendFirm,
          sendPhone: this.dialog.sendPhone,
          sendAddress: this.dialog.sendAddress,
          recipient: this.dialog.recipient,
          recipientFirm: this.dialog.recipientFirm,
          recipientPhone: this.dialog.recipientPhone,
          recipientAddress: this.dialog.recipientAddress,
          logisticsCompany: this.dialog.logisticsCompany,
          number: this.dialog.number,
          attachment: this.dialog.attachment.map(item => item.name).join(','),
          attachmentName: this.dialog.attachment.map(item => item.fileName).join(','),
        }
        if (this.dialog.status === 0) param.estimatedDate = this.dialog.estimatedDate;
        if (!!this.applicationFormInfoId) {
          param.infoId = this.applicationFormInfoId
        } else {
          param.infoId = this.data.id
        }
        let msg = this.$i18n.t('message.add');
        if (!!this.dialog.id) {
          param.id = this.dialog.id;
          msg = this.$i18n.t('message.edit');
        }
        AddArrivalRecord(param).then(res => {
          if (res.success) {
            this.$emit('update')
            this.$message.success(msg)
            this.dialog.visible = false;
          }
        })
      });
    },
    // 获取供应商信息和收货人信息
    getSendAndRecipient () {
      let param = {}

      if (!!this.applicationFormInfoId) {
        param.orderFormInfoId = this.data.id
      } else {
        param.infoId = this.data.id
      }

      GetSendAndRecipient(param).then(res => {
        if (res.success) {
          this.sendAndRecipient = res.data;
          this.setSendAndRecipient();
        }
      })
    },
    // 删除文件
    delFile (index) {
      this.dialog.attachment.splice(index, 1)
    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 显示上传
    showUpload () {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    fileInputChange () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let formats = res.message.match(/[^.]*$/)[0];
          this.dialog.attachment.push({
            name: res.message,
            formats: formats,
            fileName: file.name,
          });
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
  .el-input:first-child,
  .el-input-number:first-child {
    margin-left: 0px;
  }
  .el-input,
  .el-input-number {
    flex: 1;
    margin-left: 10px;
  }
  .el-button {
    flex: 1;
    margin-left: 10px;
    padding: 0;
  }
}
.el-input-number,
.el-input {
  width: 100%;
  // ::v-deep .el-input__inner {
  //   padding: 0 5px;
  //   text-align: left;
  // }
}

.file-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 150px;
    background: #f2f2f2;
    padding-top: 20px;
    position: relative;
    img {
      width: 80px;
      margin: 0 auto;
      display: block;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    label {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0 5px;
      text-align: center;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -8px;
      margin-left: -8px;
    }
  }
}
</style>