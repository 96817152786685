<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('newProjects.ganttChart.task.tab1')"
          name="tab1"
        >
          <el-form
            :model="dialog"
            :rules="rules"
            ref="userForm"
            size="small"
            class="user-form"
          >
            <el-form-item
              :label="$t('newProjects.ganttChart.task.label1')"
              prop="name"
            >
              <el-input v-model="dialog.name"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  :label="$t('newProjects.ganttChart.task.label2')"
                  prop="startData"
                >
                  <el-date-picker
                    v-model="dialog.startData"
                    type="date"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('newProjects.ganttChart.task.label3')"
                  prop="finishDate"
                >
                  <el-date-picker
                    v-model="dialog.finishDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('newProjects.ganttChart.task.label4')"
                  prop="duration"
                >
                  <el-input v-model="duration"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-show="!!dialog.id && !!dialog.baseStartDate"
              :gutter="20"
            >
              <el-col :span="8">
                <el-form-item prop="baseStartDate">
                  <el-date-picker
                    v-model="dialog.baseStartDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    disabled
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="baseEndDate">
                  <el-date-picker
                    v-model="dialog.baseEndDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    disabled
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-input
                    v-model="baseDuration"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              v-if="!!dialog.id && !!dialog.baseStartDate"
              :label="$t('newProjects.ganttChart.task.label5')"
              prop="percent"
            >
              <el-input v-model="dialog.percent"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newProjects.ganttChart.task.label6')"
              prop="predecessorName"
            >
              <el-button-group class="input-group">
                <el-input
                  class="search-input"
                  v-model="dialog.predecessorName"
                  readonly
                ></el-input>
                <el-button @click="showTask">Select</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newProjects.ganttChart.task.label7')"
              prop="remark"
            >
              <el-input
                type="textarea"
                v-model="dialog.remark"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newProjects.ganttChart.task.label8')"
              prop="milestone"
            >
              <el-checkbox
                v-model="dialog.milestone"
                @change="milestoneChange"
              ></el-checkbox>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!dialog.id"
          :label="$t('newProjects.ganttChart.task.tab2')"
          name="tab2"
        >
          <el-row>
            <el-button-group>
              <el-button
                size="medium"
                @click="showStaff"
              >{{$t('operation.add')}}</el-button>
              <el-button
                size="medium"
                @click="delStaff"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </el-row>
          <el-table border            :data="tableData"
            @selection-change="val => tableSelected = val"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              :label="$t('newProjects.ganttChart.task.col1')"
              prop="name"
              sortable
            >
            </el-table-column>
            <el-table-column
              :label="$t('newProjects.ganttChart.task.col2')"
              prop="hour"
              sortable
            >
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.hour"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!dialog.id"
          :label="$t('newProjects.ganttChart.task.tab3')"
          name="tab3"
        >
          <div class="file-box">
            <div
              v-for="(item,index) in fileData"
              :key="index"
              class="file-item"
            >
              <div class="block">
                <img :src="'./static/image/fileType/' + item.formats + '.png'">
                <i
                  class="el-icon-delete"
                  @click="delFile(item.id)"
                ></i>
              </div>
              <label>{{item.name + '.' + item.formats}}</label>
            </div>
            <div
              class="file-item add-item"
              @click="showDocument"
            >
              <div class="block">
                <i class="el-icon-plus"></i>
              </div>
              <label></label>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 部门弹框 -->
    <department-tree
      :visible.sync="DepartmentTreeVisible"
      :checked-keys="[]"
      @submit="staffSubmit"
    ></department-tree>
    <!-- 任务树弹框 -->
    <task-tree
      :visible.sync="TaskTreeVisible"
      @submit="taskSubmit"
    ></task-tree>
    <!-- 文档树弹框 -->
    <document-tree
      :visible.sync="DocumentTreeVisible"
      @submit="documentSubmit"
    ></document-tree>
  </div>
</template>

<script>

// 组件
import DepartmentTree from '@/components/DepartmentTree'
import TaskTree from './TaskTree'
import DocumentTree from './DocumentTree'
// 接口
import {
  AddTask,
  GetTaskPersonList,
  AddTaskPerson,
  EditTaskPerson,
  DelTaskPerson,
  GetTaskDocumentList,
  DelTaskDocument,
  AddTaskDocument,
} from '@/api/warehouse/projects'

export default {
  components: {
    DepartmentTree,
    TaskTree,
    DocumentTree,
  },
  props: ['visible', 'data', 'type'],
  data () {
    return {
      DepartmentTreeVisible: false,
      TaskTreeVisible: false,
      DocumentTreeVisible: false,
      activeName: 'tab1',
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        name: '',
        startData: null,
        finishDate: null,
        baseStartDate: null,
        baseEndDate: null,
        duration: '',
        baseDuration: '',
        percent: '',
        predecessor: '',
        predecessorName: '',
        remark: '',
        milestone: false,
        type: 0,
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        startData: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        finishDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        duration: [],
      },
      tableSelected: [],
      tableData: [],

      fileData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;
        this.activeName = 'tab1';
        if (this.type == 2) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('newProjects.ganttChart.task.title2'),
              id: this.data.id,
              name: this.data.name,
              startData: this.data.startDate,
              finishDate: this.data.endDate,
              baseStartDate: this.data.baseStartDate,
              baseEndDate: this.data.baseEndDate,
              duration: this.data.baseEndDate,
              percent: this.data.percents,
              predecessor: this.data.predecessorId,
              predecessorName: this.data.predecessorName,
              remark: this.data.notes,
              milestone: Boolean(this.data.milestone),
              type: this.data.type,
            })

            this.getTableData();
            this.getDocumentData();
          })

        } else {
          this.dialog.title = this.$i18n.t('newProjects.ganttChart.task.title1')
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'dialog.startData': function (newVal) {
      if (this.dialog.milestone) {
        this.dialog.finishDate = this.dialog.startData;
      }
    },
  },
  computed: {
    duration: function () {
      if (!this.dialog.startData || !this.dialog.finishDate) return;
      return this.computeTime(this.dialog.startData, this.dialog.finishDate) + 'day';
    },
    baseDuration: function () {
      if (!this.dialog.baseStartDate || !this.dialog.baseEndDate) return;
      return this.computeTime(this.dialog.baseStartDate, this.dialog.baseEndDate) + 'day';
    }
  },
  created () {
  },
  methods: {
    // 获取任务人员
    getTableData () {
      GetTaskPersonList(this.dialog.id).then(res => {
        this.tableData = res;
      })
    },
    getDocumentData () {
      GetTaskDocumentList(this.dialog.id).then(res => {
        this.fileData = res;
      })
    },
    // 显示任务树弹框
    showTask () {
      this.TaskTreeVisible = true;
    },
    // 提交任务
    taskSubmit (data) {
      this.dialog.predecessor = data.id;
      this.dialog.predecessorName = data.name;
    },
    // 显示文档树弹框
    showDocument () {
      this.DocumentTreeVisible = true;
    },
    // 提交文档
    documentSubmit (data) {
      let param = {
        id: this.dialog.id,
        documents: data.map(item => item.id).join(','),
      }
      AddTaskDocument(param).then(res => {
        if (res) {
          this.getDocumentData()
          this.$message.success(this.$i18n.t('message.add'))
        }
      })
    },
    // 删除文件
    delFile (id) {
      let param = { ids: id }
      DelTaskDocument(param).then(res => {
        if (res) {
          this.getDocumentData();
        }
      })
    },
    // 显示人员弹框
    showStaff () {
      this.DepartmentTreeVisible = true;
    },
    // 删除人员
    delStaff () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      let param = {
        ids: this.tableSelected.map(item => item.id).join(',')
      }
      DelTaskPerson(param).then(res => {
        if (res) {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.del'))
        }
      })
    },
    // 提交人员
    staffSubmit (data) {
      let param = {
        taskId: this.dialog.id,
        userIds: data.map(item => item.id).join(',')
      }
      AddTaskPerson(param).then(res => {
        if (res) {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.add'))
        }
      })
    },
    // 保存人员
    saveStaff () {
      if (!this.dialog.id) {
        this.$emit('update')
        this.dialog.visible = false
      } else {
        let arr = this.tableData.map(item => this.editPromise({
          id: item.id,
          hour: item.hour,
        }))
        Promise.all(arr).then((res) => {
          this.dialog.visible = false;
          this.$emit('update')
        })
      }
    },
    editPromise (param) {
      return new Promise((resolve, reject) => {
        EditTaskPerson(param).then(res => {
          if (res) resolve();
        })
      })
    },
    // 提交
    submit () {
      this.$refs.userForm.validate(valid => {
        if (!valid) return;
        let param = {
          notes: this.dialog.remark,
          projectId: this.$route.query.id,
          name: this.dialog.name,
          startDate: this.dialog.startData,
          endDate: this.dialog.finishDate,
          percents: this.dialog.percent,
          predecessorId: this.dialog.predecessor,
          milestone: Number(this.dialog.milestone),
          baseStartDate: this.dialog.baseStartDate,
          baseEndDate: this.dialog.baseEndDate,
        }
        if (!this.data) {
          param.parentId = 0
        } else if (this.type == 1) {
          param.parentId = this.data.id;
        } else {
          param.parentId = this.data.parentId;
        }

        let msg = this.$i18n.t('message.add');
        if (this.type == 2) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddTask(param).then(res => {
          if (res) {
            this.$message.success(msg)
            this.saveStaff();
          }
          this.dialog.loading = false;
        })
      })
    },
    // 里程碑变更
    milestoneChange (e) {
      if (e) {
        this.dialog.finishDate = this.dialog.startData;
      }
    },
    // 计算时间
    computeTime (startDate, endData) {
      //开始时间
      let BirthDay = new Date(startDate);
      // 结束时间
      endData = !!endData ? new Date(endData) : new Date();

      //获取当前时间
      let timeold = (endData.getTime() - BirthDay.getTime()); //总豪秒数
      let e_daysold = timeold / (24 * 60 * 60 * 1000);
      let daysold = Math.floor(e_daysold); //相差天数
      return daysold + 1;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-button-group,
  .el-select {
    width: 100%;
  }
  .el-switch {
    width: 100px;
  }
}
.file-box {
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 120px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 80px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: static;
    }
  }
}
</style>