<template>
  <div class="wrap">
    <el-popover
      class="popover-btn"
      @show="showPopover"
    >
      <div class="popover-wrap">
        <div class="title">Sort & Filter </div>
        <el-button-group class="btn-box">
          <el-button
            size="small"
            @click="sortChange"
          >
            <i :class="{
                'el-icon-sort':sortState === '',
                'el-icon-sort-up':sortState === 'ascending',
                'el-icon-sort-down': sortState === 'descending',
              }"></i>
            <span>Sort</span>
          </el-button>
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset"
          >Reset</el-button>
        </el-button-group>
        <el-input
          :disabled="disabled"
          size="small"
          placeholder="Search"
          v-model="searchVal"
          @keyup.enter.native="searchEnter"
        ></el-input>
        <!-- <input
          :disabled="disabled"
          class="search-input"
          placeholder="Search"
          type="text"
          v-model="searchVal"
          @keyup.enter="searchEnter"
        > -->
      </div>
      <div
        class="btn"
        slot="reference"
      >
        <i :class="{'el-icon-caret-bottom':!filterState, 'icon-filter':filterState}"></i>
      </div>
    </el-popover>

  </div>
</template>

<script>

export default {
  props: ['prop', 'filter', 'sort', 'disabled'],
  data () {
    return {
      sortState: '',
      searchVal: '',
      filterState: false,
    }
  },

  computed: {

  },
  watch: {
    'sort': function (newVal) {
      if (newVal.prop !== this.prop) {
        this.sortState = '';
        this.checkState();
      }
    }
  },
  created () {


  },
  methods: {
    showPopover () {

    },
    searchEnter () {
      this.$emit('update:filter', this.searchVal)
      this.checkState();
    },
    sortChange () {
      if (this.sortState === '') {
        this.sortState = 'ascending';
      } else if (this.sortState === 'ascending') {
        this.sortState = 'descending';
      } else {
        this.sortState = '';
      }
      this.$emit('update:sort', {
        prop: this.prop,
        order: this.sortState,
      });
      this.checkState();
    },
    reset () {
      this.sortState = '';
      this.searchVal = '';
      this.searchEnter();
    },
    checkState () {
      this.filterState = !(this.sortState === '' && this.searchVal === '');
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.popover-btn {
  display: flex;
  align-items: center;
  // color: #fff;
  color: #c0c4cc;
}

.popover-wrap {
  width: 240px;
  .title {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .btn-box {
    margin-bottom: 10px;
    display: flex;
    .el-button {
      padding-left: 2px;
      padding-right: 2px;
      font-size: 12px;
      flex: 1;
      ::v-deep span {
        margin-left: 5px;
      }
    }
  }

  ::v-deep .el-input__inner {
    text-align: center;
  }
  ::v-deep th,
  ::v-deep td {
    height: 30px;
    line-height: 30px;
  }
}

.btn {
  width: 20px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  padding: 0;
}
</style>