<template>
  <div>
    <el-button-group class="btn-box">
      <el-button
        size="medium"
        @click="showColSet"
      ><i class="el-icon-reading"></i></el-button>
      <el-button
        size="medium"
        @click="upDate"
      ><i class="el-icon-refresh-right"></i></el-button>
    </el-button-group>
    <!-- 列设置弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="Column Settings"
      :visible.sync="dialog.visible"
      center
      width="350px"
    >
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        max-height="300"
        ref="table"
        border
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="name"
          label="列名"
        ></el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="save"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ColSet',
  props: ['data'],
  data () {
    return {
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      dialog: {
        visible: false,
        loading: false,
      },
    }
  },
  created () {
  },
  methods: {
    // 更新数据
    upDate () {
      this.$emit('update')
    },
    // 显示列设置弹框
    showColSet () {
      this.tableData = [];
      this.dialog.visible = true;
      this.$nextTick(() => {
        for (var i in this.data) {
          this.tableData.push({
            prop: i,
            name: this.data[i].name,
            visible: this.data[i].visible,
          });
        }
        this.tableData.forEach(row => {
          if (row.visible)
            this.$refs.table.toggleRowSelection(row)
        })
      })
    },
    // 保存 
    save () {
      let selected = this.tableSelected.map(item => item.name);
      let data = {};
      this.tableData.forEach(item => {
        data[item.prop] = {
          name: item.name,
          visible: selected.includes(item.name)
        }
      })
      this.$emit('update:data', data);
      this.dialog.visible = false;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },

  }
}
</script>

<style lang="scss" scoped>
.btn-box {
  .el-button {
    height: 36px;
    font-size: 20px;
    padding: 0 10px;
  }
}
</style>