<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              class="search-input"
              size="medium"
              placeholder="Search"
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              :disabled="!permit.add"
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.del"
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="170"
            sortable
            prop="name"
            :label="$t('schedule.col1')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="type"
            :label="$t('schedule.col2')"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            sortable
            prop="accessGroupName"
            :label="$t('schedule.col3')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="createByName"
            :label="$t('schedule.col4')"
          >
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="status"
            :label="$t('schedule.col5')"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.status == 1"
                size="small"
                type="success"
              >Enable</el-tag>
              <el-tag
                v-else
                size="small"
                type="danger"
              >Disable</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130"
            sortable
            prop="action"
            :label="$t('schedule.col6')"
          >
            <template slot-scope="scope">
              {{actions[scope.row.action]}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            sortable
            prop="xxx"
            :label="$t('schedule.col7')"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'

// 接口
import {
  GetScheduleList,
  DelSchedule,
} from '@/api/schedule'
export default {
  name: 'Schedule',
  components: {
    ColSet
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      actions: ['Send Report', 'Send Notification', 'Send SMS Message'],
      searchVal: '',
      tableSelected: [],
      tableData: [],
      tableHeight: null,
    }
  },
  watch: {
    '$route': function (to) {
       if (to.name === 'Schedule') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
    this.dealPermission();

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetScheduleList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 添加
    showAdd () {
      this.$router.push('/home/newSchedule')
    },
    // 编辑
    showEdit () {
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newSchedule', query: { id: e.id } });

    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelSchedule(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('ScheduleSearchSchedule');
        this.permit.add = permission.includes('ScheduleAddSchedule');
        // this.permit.edit = permission.includes('ScheduleEditSchedule');
        this.permit.del = permission.includes('ScheduleDeleteSchedule');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>
