<template>
  <div>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：</label>
        {{item.name}}
      </div>
    </el-row>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.securityType.label1')}}：</label>
        <el-input
          disabled
          v-model="item.duration"
          size="medium"
          type="number"
          min="1"
          max="99"
        ></el-input>
        <el-select
          disabled
          size="medium"
          v-model="item.type"
        >
          <el-option
            :value="0"
            :label="$t('dataMaintenance.securityType.option1')"
          ></el-option>
          <el-option
            :value="1"
            :label="$t('dataMaintenance.securityType.option2')"
          ></el-option>
          <el-option
            :value="2"
            :label="$t('dataMaintenance.securityType.option3')"
          ></el-option>
        </el-select>
      </div>
    </el-row>
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.securityType.label2')}}：</label>
        <el-input
          disabled
          v-model="item.times"
          size="medium"
          type="number"
          min="1"
          max="99"
        ></el-input>
      </div>
    </el-row>
  </div>
</template>

<script>

export default {
  props: ['items'],
  data () {
    return {
      item: {},
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.item = {};
      } else {
        this.item = this.items[0]
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";
.label-item {
  label {
    width: 170px;
    margin-right: 10px;
    display: inline-block;
    text-align: right;
  }
  .el-select,
  .el-input {
    width: 100px;
  }
}
</style>