<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
      >
        <el-form-item
          :label="$t('newDevice.label18')"
          prop="purchaseUserId"
        >
          <!-- <my-select
            :data="requisitioners"
            :value.sync="dialog.purchaseUserId"
            :prop="{
              label:'fullname',
              value:'id'
            }"
          ></my-select> -->

          <el-select
            v-model="dialog.purchaseUserId"
            filterable
            clearable
          >
            <el-option
              v-for="(item,index) in requisitioners"
              :key="index"
              :value="item.id"
              :label="item.fullname"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label20')"
          prop="outUserId"
        >
          <!-- <my-select
            :data="requisitioners"
            :value.sync="dialog.outUserId"
            :prop="{
              label:'fullname',
              value:'id'
            }"
          ></my-select> -->
          <el-select
            v-model="dialog.outUserId"
            filterable
            clearable
          >
            <el-option
              v-for="(item,index) in requisitioners"
              :key="index"
              :value="item.id"
              :label="item.fullname"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label19')"
          prop="inDate"
        >
          <el-date-picker
            v-model="dialog.inDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label21')"
          prop="outDate"
        >
          <el-date-picker
            v-model="dialog.outDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label22')"
          prop="productionDate"
        >
          <el-date-picker
            v-model="dialog.productionDate"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('newDevice.label23')"
          prop="warrantyPeriod"
        >
          <el-date-picker
            v-model="dialog.warrantyPeriod"
            type="date"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

// 接口
import { GetUserAll } from '@/api/userManagement'
import { AddPurchaseRecord } from '@/api/warehouse/deviceManagement'

export default {
  props: ['visible', 'data', 'deviceId'],
  data () {
    return {
      requisitioners: [],
      dialog: {
        visible: false,
        loading: false,
        title: '购买记录',
        purchaseUserId: null,
        purchaseUser: null,
        outUserId: null,
        outUser: null,
        inDate: null,
        outDate: null,
        productionDate: null,
        warrantyPeriod: null,

      },
      rules: {
        // name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col1'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              purchaseUserId: this.data.purchaseUserId,
              outUserId: this.data.outUserId,
              inDate: this.data.inDate,
              outDate: this.data.outDate,
              productionDate: this.data.productionDate,
              warrantyPeriod: this.data.warrantyPeriod,
            })
          })
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    // 获取申购人
    GetUserAll({}).then(res => this.requisitioners = res);
  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          purchaseUserId: this.dialog.purchaseUserId,
          outUserId: this.dialog.outUserId,
          deviceId: this.deviceId,
          inDate: this.dialog.inDate,
          outDate: this.dialog.outDate,
          productionDate: this.dialog.productionDate,
          warrantyPeriod: this.dialog.warrantyPeriod,
        }

        let purchaseUser = this.requisitioners.find(item => item.id === this.dialog.purchaseUserId)
        if (purchaseUser) param.purchaseUser = purchaseUser.fullname;

        let outUser = this.requisitioners.find(item => item.id === this.dialog.outUserId)
        if (outUser) param.outUser = outUser.fullname;

        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddPurchaseRecord(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update', this.dialog.isType)
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
</style>