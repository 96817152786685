<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
    >
      <el-table :data="tableData">
        <el-table-column
          prop="name"
          label="Field Name"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.name"
            ></el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="type"
          label="Data Type"
        >
          <template slot-scope="scope">
            <el-select
              size="mini"
              v-model="scope.row.type"
            >
              <el-option
                v-for="(item,index) in dataTypes"
                :key="index"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column> -->
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              :disabled="!!scope.row.id"
              @click="delItem(scope.$index)"
            ></el-button>
            <el-button
              size="mini"
              type="success"
              icon="el-icon-plus"
              :disabled="!!scope.row.id && scope.$index !== (tableData.length - 1)"
              @click="addItem(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  AddDataFieldAll
} from '@/api/warehouse/dataMaintenance'

export default {
  props: ['visible', 'data'],
  data () {
    return {
      dataTypes: [],
      dialog: {
        visible: false,
        title: 'Data Field Editor',
      },
      tableData: [{ name: '', type: '' }],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.tableData = JSON.parse(JSON.stringify(this.data));
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  methods: {
    // 删除
    delItem (index) {
      this.tableData.splice(index, 1);
    },
    // 添加
    addItem (index) {
      this.tableData.splice(index + 1, 0, {
        name: '',
        type: '',
        parent: '0',
      });
    },
    // 提交
    submit () {
      let data = JSON.parse(JSON.stringify(this.tableData.filter(item => item.id !== '0')));
      AddDataFieldAll(data).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.edit'))
          this.$emit('update');
          this.dialog.visible = false;
        }
      })
    },
  },
}
</script>

<style>
</style>