<template>
  <div
    class="wrap"
    ref="wrap"
  >
    <div
      class="panel-item"
      ref="panelItem"
      @click="iconLinkto"
    >
      <img
        v-show="!!iconImg && isImage"
        class="icon"
        :src="`${this.baseUrl}/reportTemplatesChartType/getImage.do?imageId=${iconImg}`"
      >
      <div class="value">
        <span
          ref="amountIcon"
          v-if="isAmount"
        >HK$</span>
        <span>{{iconCount}}</span>
      </div>
      <div class="name">{{chartTitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'dataOrigin'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      isImage: true,
      isAmount: false,
      iconImg: '',
      iconCount: 0,
      chartTitle: '',
      axisData: null,
    }
  },
  methods: {
    init () {
      let chartData = this.data.data

      this.iconImg = chartData.mapAddress;
      this.iconCount = 0;
      this.chartTitle = chartData.chartTitle;
      this.axisData = JSON.parse(chartData.dataSource);
      this.dataOrigin.data.data.forEach(item => {
        let val = this.getCellVal(item, this.axisData[0].key);

        if (/,/.test(val)) val = val.replace(/,/g, '');
        if (isNaN(val)) {
          this.iconCount += 1;
        } else {
          this.iconCount += parseFloat(val) || 0;
        }
      });
      if (this.axisData[0].measure == 2) {
        this.iconCount = this.iconCount.toFixed(2).replace(/(?=(?!\b)(?:\d{3})+(?!\d))/g, ',')
        this.isAmount = true;
      } else {
        this.iconCount = this.iconCount.toFixed(0).replace(/(?=(?!\b)(?:\d{3})+(?!\d))/g, ',')
        this.isAmount = false;
      }
      this.computeIcon();
    },
    computeIcon () {
      let iconBox = this.$refs.wrap;
      let h = iconBox.clientHeight;
      let w = iconBox.clientWidth;
      let panelItem = this.$refs.panelItem;
      let bassLen = h > w ? w : h;

      panelItem.style.width = bassLen + 'px';
      panelItem.style.height = bassLen + 'px';
      panelItem.style.fontSize = bassLen / 115 * 12 + 'px';

      this.isImage = bassLen * 0.3 > 25;
      this.$nextTick(() => {
        if (this.isAmount)
          this.$refs.amountIcon.style.fontSize = bassLen / 200 * 12 + 'px';
      })
    },
    // 图标跳转
    iconLinkto () {
      if (!this.axisData[0].linkToJson) return;

      let linkToJson = JSON.parse(this.axisData[0].linkToJson);
      sessionStorage.linkToData = JSON.stringify(linkToJson.data);
      this.$router.push({ path: '/home/counterMode' })
    },
    // 获取单元格的值
    getCellVal (data, property) {
      let value = 0;
      if (/^(.+)\.(.+)$/.test(property)) {
        let key = property.split('.');
        data[key[0]].forEach(item => {
          value += parseFloat(item[key[1]]);
        })
      } else {
        value = data[property];
      }
      return value;
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 0;
}
.panel-item {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon {
    height: 30%;
    width: 30%;
  }
  .value {
    margin: 5px 0;
    font-weight: bold;
    display: flex;
    align-items: baseline;
    span {
      margin: 0 2px;
    }
  }
  .name {
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }
}
</style>