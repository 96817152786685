<template>
  <div class="wrap">
    <div class="date-box">
      <div class="left-box">
        <h1>{{currentMonth}}</h1>
        <div class="left-date-box">
          <i class="el-icon-date"></i>
          <el-date-picker
            class="left-date"
            v-model="currentMonth"
            type="month"
            value-format="yyyy-MM"
            :clearable="false"
            @change="getCalendarData"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="right-box">
        <div class="item">
          <label>{{$t('maintenance.calendar.label1')}}</label>
          <el-tag
            type="success"
            effect="dark"
            size="medium"
          >{{totalData.completed}}</el-tag>
        </div>
        <div class="item">
          <label>{{$t('maintenance.calendar.label2')}}</label>
          <el-tag
            type="info"
            effect="dark"
            size="medium"
          >{{totalData.planned}}</el-tag>
        </div>
        <div class="item">
          <label>{{$t('maintenance.calendar.label3')}}</label>
          <el-tag
            type="warning"
            effect="dark"
            size="medium"
          >{{totalData.over}}</el-tag>
        </div>
        <div class="item">
          <label>{{$t('maintenance.calendar.label4')}}</label>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            size="medium"
            value-format="yyyy-MM-dd"
            @change="getTotal"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="calendar-box">
      <table>
        <thead>
          <tr>
            <td>{{$t('maintenance.calendar.col1')}}</td>
            <td>{{$t('maintenance.calendar.col2')}}</td>
            <td>{{$t('maintenance.calendar.col3')}}</td>
            <td>{{$t('maintenance.calendar.col4')}}</td>
            <td>{{$t('maintenance.calendar.col5')}}</td>
            <td>{{$t('maintenance.calendar.col6')}}</td>
            <td>{{$t('maintenance.calendar.col7')}}</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tr,trIndex) in calendarData"
            :key="trIndex"
          >
            <td
              v-for="(td,tdIndex) in tr"
              :key="trIndex + '-' + tdIndex"
            >
              <div class="info-box">
                <label :class="{'disabled': td.disabled}">{{td.day}}</label>
                <div
                  v-if="!td.hide"
                  class="alert-box"
                >
                  <div class="alert-wrap">
                    <div
                      v-if="td.completed != 0"
                      class="alert success"
                      @click="showRecord(td)"
                    >{{td.completed}}</div>
                  </div>
                  <div class="alert-wrap">
                    <div
                      v-if="td.planned != 0"
                      class="alert info"
                      @click="showRecord(2)"
                    >{{td.planned}}</div>
                  </div>

                  <!-- <div
                  class="alert warning"
                  @click="showRecord(3)"
                >50</div> -->
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <maintenance-record
      :visible.sync="MaintenanceRecordVisible"
      :date="recordDay"
      :branch="branch"
    ></maintenance-record>
  </div>
</template>

<script>
// 全局
import { formatDate } from '@/utils/common'
// 组件
import MaintenanceRecord from './MaintenanceRecord'
// 接口
import {
  GetCalendar,
  GetCalendar2,
} from '@/api/warehouse/maintenance'
export default {

  components: {
    MaintenanceRecord
  },
  props: ['active', 'branch'],
  data () {
    return {
      MaintenanceRecordVisible: false,
      commonYear: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      leapYear: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      dateRange: null,
      calendarData: [[]],
      currentMonth: '',
      recordDay: '',
      totalData: {
        completed: 0,
        over: 0,
        planned: 0,
      },
    }
  },
  watch: {
    'branch': function () {
      if (this.active === 'Calendar') {
        this.getTotal();
        this.getCalendarData();
      }
    },
    'active': function () {
      if (this.active === 'Calendar') {
        this.getTotal();
        this.getCalendarData();
      }
    }
  },
  created () {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    this.dateRange = [formatDate(date, 'yyyy-MM-1'), formatDate(date, `yyyy-MM-${lastDay}`)];
    this.currentMonth = formatDate(date, 'yyyy-MM');

    this.getTotal();
    this.getCalendarData();
  },
  methods: {
    getCalendarData () {
      this.calendarData = [[]];

      let date = new Date(this.currentMonth);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      let dateRange = [formatDate(date, 'yyyy-MM-1'), formatDate(date, `yyyy-MM-${lastDay}`)];
      let param = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        branchIds: this.branch,
      }
      GetCalendar2(param).then(res => {
        this.getCalendar(res);
      })
    },
    getTotal () {
      let param = {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        branchIds: this.branch,
      }
      GetCalendar(param).then(res => {
        this.totalData = res;
      })
    },
    getCalendar (data) {
      let date = new Date(this.currentMonth);

      let day = this.getMonthDay(date);
      let beforeMonth = this.getMonthDay(date);
      let trIndex = 0;
      let startWeek = date.getDay();

      for (let i = 0; i < startWeek; i++) {
        this.calendarData[trIndex].unshift({
          disabled: true,
          day: beforeMonth--,
          hide: true,
        })
      }

      for (var i = 1; i <= day; i++) {
        this.calendarData[trIndex].push({
          disabled: false,
          day: i,
          completed: data[i - 1].completed,
          planned: data[i - 1].planned,
        })

        let date = new Date(`${this.currentMonth}-${i}`)
        if (date.getDay() === 6) {
          trIndex++;
          this.calendarData[trIndex] = [];
        }
      }

      let endWeek = new Date(`${this.currentMonth}-${day}`).getDay()
      let endIndex = 1;
      for (let i = endWeek; i < 6; i++) {
        this.calendarData[trIndex].push({
          disabled: true,
          day: endIndex++,
          hide: true,
        })
      }
    },
    // 获取月份天数
    getMonthDay (date) {
      let year = date.getFullYear();
      let month = date.getMonth();
      if ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {
        return this.leapYear[month]
      } else {
        return this.commonYear[month]
      }
    },
    showRecord (e) {
      this.recordDay = `${this.currentMonth}-${e.day}`;
      this.MaintenanceRecordVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.date-box {
  height: 65px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-box {
    display: flex;
    align-items: center;
    h1 {
      font-size: 32px;
      margin: 0;
    }
    .left-date-box {
      height: 24px;
      width: 24px;
      margin-left: 10px;
      position: relative;
      i {
        font-size: 24px;
        color: #409eff;
        cursor: pointer;
      }
      .left-date {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        opacity: 0;
        cursor: pointer;
        ::v-deep .el-input__inner {
          width: 24px;
          height: 24px;
          padding: 0;
        }
      }
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    .item {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      label {
        margin-right: 5px;
      }
    }
  }
}
.calendar-box {
  height: calc(100% - 65px - 11px);
  .el-tag {
    cursor: pointer;
  }
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    td {
      width: calc(100% / 7);
      border: 1px solid #eee;
      text-align: center;
      .info-box {
        width: calc(100% - 10px);
        height: calc(24px + 22px + 22px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        label {
          font-size: 24px;
          line-height: 24px;
          font-weight: bold;
          &.disabled {
            color: #d7d7d7;
          }
        }
        .el-alert {
          height: 25px;
          margin-top: 3px;
          cursor: pointer;
        }
      }
    }
  }
}
.alert-box {
  width: 100%;
}
.alert-wrap {
  width: 100%;
  height: 20px;
}
.alert {
  width: 100%;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 3px;
  margin-top: 2px;
  text-align: left;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  &.success {
    background-color: #67c23a;
  }
  &.info {
    background-color: #909399;
  }
  &.warning {
    background-color: #e6a23c;
  }
}
</style>