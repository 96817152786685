<template>
  <div
    class="wrap"
    v-resize="resize"
  >
    <el-card>
      <div class="card-wrap">
        <div class="tree-wrap">
          <el-row>
            <el-button-group class="btn-box">
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showStore(1)"
              >{{$t('reportGroup.btn1')}}</el-button>
              <!-- <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showStore(2)"
              >Add Child</el-button> -->
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showStore(3)"
              >{{ $t("operation.edit") }}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delNode"
              >{{ $t("operation.del") }}</el-button>
              <!-- <el-button size="medium">Move</el-button>
              <el-button
                size="medium"
                @click="nodeCopy"
              >Copy</el-button> -->
            </el-button-group>
          </el-row>
          <el-row>
            <el-input
              :disabled="!permit.searchReport"
              type="text"
              placeholder="Search"
              size="medium"
              v-model="treeSerachVal"
            ></el-input>
          </el-row>
          <el-tree
            :data="treeData"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            v-loading="treeLoading"
            node-key="id"
            ref="tree"
            @node-click="nodeClick"
            highlight-current
          >
          </el-tree>
        </div>
        <div
          class="table-wrap"
          ref="tableWrap"
        >
          <el-row>
            <div class="info-box">
              <span class="label">{{$t('reportGroup.label1')}}：</span>
              <span
                class="name"
                :title="currentNode ? currentNode.label : ''"
              >{{currentNode ? currentNode.label : ''}}</span>
              <!-- <span class="label">Description：</span>
              <span
                class="description"
                :title="currentNode ? currentNode.description : ''"
              >{{currentNode ? currentNode.description : ''}}</span> -->
            </div>
          </el-row>
          <el-row>
            <div class="input-wrap">
              <el-button-group class="input-group">
                <el-input
                  :disabled="!permit.searchTemplate"
                  type="text"
                  placeholder="Search"
                  size="medium"
                  class="search-input"
                  v-model="tableSerachVal"
                  clearable
                ></el-input>
                <el-button
                  :disabled="!permit.searchTemplate"
                  size="medium"
                  @click="searchData"
                >{{$t('operation.search')}}</el-button>
                <el-button
                  :disabled="!permit.editTemplate"
                  size="medium"
                  @click="dblclickEdit"
                >{{$t('operation.edit')}}</el-button>
                <!-- <el-button size="medium">Move</el-button>
                <el-button size="medium">Copy</el-button> -->
              </el-button-group>
            </div>
          </el-row>
          <el-row
            class="table-row"
            ref="tableRow"
          >
            <el-table
              border
              ref="table"
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
              @selection-change="val => tableSelected = val"
              @row-dblclick="dblclickEdit"
              @row-click="rowClick"
            >
              <el-table-column type="selection"> </el-table-column>
              <!-- <el-table-column
                sortable
                label="ID"
                prop="storeId"
              ></el-table-column> -->
              <el-table-column
                min-width="160"
                sortable
                :label="$t('reportGroup.col1')"
                prop="name"
              ></el-table-column>
              <el-table-column
                min-width="130"
                sortable
                :label="$t('reportGroup.col2')"
                prop="description"
              ></el-table-column>
              <el-table-column
                min-width="170"
                sortable
                :label="$t('reportGroup.col3')"
                prop="accessGroupName"
              ></el-table-column>
            </el-table>
          </el-row>
          <!-- <el-row class="page-wrap">
            <my-page
              layout="prev, pager, next, jumper"
              :total="page.total"
              :page-size.sync="page.size"
              :current-page.sync="page.current"
              @current-change="getTableData"
              @size-change="getTableData"
            >
            </my-page>
          </el-row> -->
        </div>
      </div>
    </el-card>

    <!-- 添加商店弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="storeDialog.title"
      :visible.sync="storeDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="storeDialog"
        :rules="rules"
        ref="storeForm"
        size="small"
      >
        <el-form-item
          :label="storeDialog.label"
          prop="name"
        >
          <el-input v-model="storeDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportGroup.nodeDialog.label2')"
          prop="description"
        >
          <el-input
            type="textarea"
            :rows="3"
            v-model="storeDialog.description"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reportGroup.nodeDialog.label3')"
          prop="dataAccessGroup"
        >
          <el-select
            :disabled="!permit.dataAccessGroup"
            v-model="storeDialog.dataAccessGroup"
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="storeDialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitStore"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { dealTree, debounce } from '@/utils/common'
// 接口
import {
  GetReportGroupTree,
  AddReportGroupNode,
  AddReportGroupClidNode,
  EditReportGroupNode,
  DelReportGroupNode,
  GetReportGroupList,
} from '@/api/reportGroup'
import {
  GetDataAccessGroup,
  GetDataAccessGroupAll,
} from '@/api/organizations'
export default {
  name: 'ReportGroup',
  data () {
    return {
      permit: {
        add: true,
        addChild: true,
        edit: true,
        del: true,
        searchReport: true,
        dataAccessGroup: true,

        editTemplate: true,
        searchTemplate: true,
      },
      page: {
        total: 0,
        size: 9999,
        current: 1,
      },
      dataAccessGroups: [],
      storeDialog: {
        visible: false,
        loading: false,
        title: '',
        label: '',
        parentId: null,
        id: null,
        name: '',
        description: '',
        type: '1',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      treeLoading: false,
      currentNode: null,
      treeTime: null,
      treeSerachVal: '',
      treeData: [],
      tableLoading: false,
      tableSerachVal: '',
      tableSelected: [],
      tableHeight: null,
      tableData: [],
    }
  },
  watch: {
    treeSerachVal (val) {
      this.$refs.tree.filter(val);
    },
    'storeDialog.visible': function (newVal) {
      if (newVal) {
        if (this.storeDialog.parentId) {
        } else {
          // 获取数据访问组
          GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)
        }
      }
    },
    '$route': function (to) {
      if (to.name === 'ReportGroup' && !!this.currentNode) {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
    this.getTreeData();
    // this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
  },
  methods: {
    resize: debounce(function () {
      this.tableHeight = this.$refs.tableRow.$el.clientHeight
    }, 100),
    getTreeData () {
      this.treeLoading = true;
      GetReportGroupTree().then(res => {
        dealTree(res)
        this.treeData = res;
        this.treeLoading = false;
      }).catch(error => {
        this.treeLoading = false;
      })
    },
    getTableData (fun) {
      let param = {
        groupTreeId: this.currentNode.id,
        limit: this.page.size,
        page: this.page.current,
        search: this.tableSerachVal,
      }
      this.tableLoading = true;
      GetReportGroupList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;

        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },

    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示树节点弹框
    showStore (type) {
      if (this.currentNode === null && type !== 1) return this.$message.warning('Please select one item.');
      this.$refs.storeForm && this.$refs.storeForm.resetFields();
      this.dataAccessGroups = [];

      switch (type) {
        case 1:
          this.storeDialog.title = this.$i18n.t('reportGroup.nodeDialog.title1');
          this.storeDialog.label = this.$i18n.t('reportGroup.nodeDialog.label1');
          this.storeDialog.id = null;
          this.storeDialog.parentId = null;
          GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)
          break;
        case 2:
          this.storeDialog.title = 'Add Child Node';
          this.storeDialog.label = 'Child Node Name (Report Subcategory)';
          this.storeDialog.id = null;
          this.storeDialog.parentId = this.currentNode.id;
          GetDataAccessGroup({ accessGroupId: this.currentNode.accessGroupId }).then(res => this.dataAccessGroups = res)
          break;
        case 3:
          this.storeDialog.title = this.$i18n.t('reportGroup.nodeDialog.title2');
          this.storeDialog.label = this.$i18n.t('reportGroup.nodeDialog.label1');
          this.storeDialog.id = this.currentNode.id;
          this.storeDialog.parentId = this.currentNode.parentId || null;
          if (this.currentNode.parentSccessGroupId) {
            GetDataAccessGroup({ accessGroupId: this.currentNode.parentSccessGroupId }).then(res => this.dataAccessGroups = res)
          } else {
            GetDataAccessGroupAll().then(res => this.dataAccessGroups = res)
          }
          this.$nextTick(() => {
            this.storeDialog.name = this.currentNode.label;
            this.storeDialog.description = this.currentNode.description;
            this.storeDialog.type = this.currentNode.type;
            this.storeDialog.dataAccessGroup = this.currentNode.accessGroupId;
          })
          break;
      }
      this.storeDialog.visible = true;
    },
    // 添加、编辑节点
    submitStore () {
      this.$refs.storeForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.storeDialog.name,
          description: this.storeDialog.description,
          accessGroupId: this.storeDialog.dataAccessGroup,
        }
        let msg = this.$i18n.t('message.add');
        this.storeDialog.loading = true;

        if (this.storeDialog.id) {
          // 编辑
          param.id = this.storeDialog.id;
          msg = this.$i18n.t('message.edit');

          EditReportGroupNode(param).then(res => {
            if (res.success) {
              this.getTreeData();
              this.$message.success(msg);
              this.storeDialog.visible = false;
            } else {

            }
            this.storeDialog.loading = false;
          }).catch(error => {
            this.storeDialog.loading = false;
          })
        } else {
          // 添加
          if (this.storeDialog.parentId) {
            // 子节点
            param.parentId = this.storeDialog.parentId;
            AddReportGroupClidNode(param).then(res => {
              if (res.success) {
                this.getTreeData();
                this.$message.success(msg);
                this.storeDialog.visible = false;
              } else {

              }
              this.storeDialog.loading = false;
            }).catch(error => {
              this.storeDialog.loading = false;
            })
          } else {
            // 根节点
            AddReportGroupNode(param).then(res => {
              if (res.success) {
                this.getTreeData();
                this.$message.success(msg);
                this.storeDialog.visible = false;
              } else {

              }
              this.storeDialog.loading = false;
            }).catch(error => {
              this.storeDialog.loading = false;
            })
          }
        }
      })
    },
    // 删除节点
    delNode () {
      if (this.currentNode === null) return this.$message.warning('Please select one item.');

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.currentNode.id,
        }
        DelReportGroupNode(param).then(res => {
          if (res.success) {
            this.currentNode = {
              label: '',
              description: '',
            }
            this.getTreeData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 点击树节点
    nodeClick (node) {
      let oldid = this.currentNode === null ? null : this.currentNode.id;
      this.currentNode = node;
      this.getTableData();

      if (this.treeTime === null) {
        this.treeTime = new Date()
      } else {
        let interval = new Date() - this.treeTime;
        this.treeTime = null;
        if (interval < 1000 && oldid === this.currentNode.id) this.showStore(3)
      }
    },
    dblclickEdit () {
      if (!this.currentNode) return;

      this.$router.push({
        path: '/home/newReportGroup',
        query: {
          id: this.currentNode.id,
          group: this.currentNode.label,
          des: this.currentNode.description,
          data: this.currentNode.accessGroupId
        }
      })
    },
    // 复制
    nodeCopy () {

    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.add = permission.includes('d1a0f0ae-f1e1-4e9e-955b-ea99baf066f6');
        // this.permit.edit = permission.includes('56a59f32-0530-4138-b4cf-cd87da24dfcf');
        // this.permit.del = permission.includes('31e1c8ac-b8b6-4306-801e-fb6884879fb0');
        // this.permit.searchReport = permission.includes('92740dea-a449-44e1-b8a6-58d2ac7f5613');
        // this.permit.dataAccessGroup = permission.includes('GroupEditDataAccessGroups(DataPermissions)');

        // this.permit.editTemplate = permission.includes('8d51a0c5-4705-49f2-a336-eaa72705b1f8');
        // this.permit.searchTemplate = permission.includes('7d226f8e-8b61-45e7-9c24-772cd0f6852f');


      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.card-wrap {
  height: 100%;
  display: flex;
}
.tree-wrap {
  width: 400px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;

  .btn-box {
    width: 100%;
    display: flex;
    .el-button {
      flex: 1;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.table-wrap {
  width: calc(100% - 422px);
  .info-box {
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    .label {
      flex-shrink: 0;
    }
    .name {
      // width: 150px;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 0;
    }
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .input-group {
    display: flex;
    .search-input {
      width: 300px;
      ::v-deep .el-input__inner {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }
  }
}

.el-dialog {
  .el-select {
    width: 100%;
  }
}

.el-tree {
  height: calc(100% - 90px);
  border: 1px solid #e4e4e4;
  overflow: auto;
  padding: 10px;
  .icon-box {
    margin-left: 10px;
  }
  i {
    width: 15px;
    height: 15px;
    line-height: 15px;
    border: 1px solid #9d9d9d;
    text-align: center;
    font-size: 12px;

    &:hover {
      background: #9d9d9d;
      color: #fff;
    }
  }
}

.table-row {
  height: calc(100% - 92px);
}
</style>
