<template>
  <div>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="items.length !== 1"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            :disabled="items.length !== 1"
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>

        </el-button-group>
      </div>
    </el-row>
    <el-row>
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.receiptAddress.col1')"
          prop="name"
        ></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.receiptAddress.col2')"
          prop="phoneNumber"
        ></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.receiptAddress.col3')"
          prop="email"
        ></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.receiptAddress.col4')"
          prop="fax"
        ></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.receiptAddress.col5')"
          prop="postName"
        ></el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 联系人弹框 -->
    <add-contact
      :visible.sync="AddContactVisible"
      :data="contactData"
      :item="items[0]"
      type="ReceiptAddress"
      @update="getTableData"
    ></add-contact>
  </div>
</template>

<script>
// 组件
import AddContact from '../AddContact'
// 接口
import {
  GetReceiptContactList,
  DelReceiptContact,
} from '@/api/warehouse/dataMaintenance/receiptAddress'
export default {
  props: ['items', 'tableHeight'],
  components: {
    AddContact
  },
  data () {
    return {
      AddContactVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      contactData: null,
      searchVal: '',
      tableSelected: [],
      tableLoading: false,
      tableData: [],

    }
  },
  watch: {
    'tableHeight': function (newVal) {
      this.page.size = Math.ceil((newVal - 36) / 35);
    },
    'items': function () {
       if (this.items.length === 0) {
        this.tableData = [];
      } else {
        this.getTableData();
      }
    }
  },
  created () {
  },
  methods: {
    getTableData () {

      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        addressId: this.items.map(item => item.id).join(','),
      }
      this.tableLoading = true;
      GetReceiptContactList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.contactData = null;
      this.AddContactVisible = true;
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (e) {
      this.contactData = e;
      this.AddContactVisible = true;
    },
    // 删除设备
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelReceiptContact(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
  }
}
</script>

<style>
</style>