<template>
  <div class="wrap">
    <div class="style-title">Header</div>
    <el-row :gutter="10">
      <el-col :span="24">
        <div class="input-box">
          <label>height</label>
          <el-input-number
            :controls="false"
            size="small"
            v-model="elData.header.height"
            type="number"
          ></el-input-number>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>