<template>
  <div
    class="wrap"
    ref="wrap"
  >
    <el-row>
      <condition
        :order-state.sync="orderState"
        :bgcolor-state.sync="bgcolorState"
        :templates-id="reportTemplateId"
        :filter-param="filterParam"
        :is-data-null="isDataNull"
        :common-param="commonParam"
        :history-param="historyParam"
        :export-loading="exportLoading"
        :template-fields="templateFields"
        :sort-param.sync="sortParam"
        @paramBack="paramBack"
        @submit="submitParam"
        @resetParam="resetParam"
        @submitLayout="submitLayout"
        @saveLayout="saveLayout"
        @submitExport="submitExport"
      ></condition>
    </el-row>
    <div
      class="layout-box"
      ref="box"
      v-resize="resizeLayout"
    >
      <grid-layout
        :layout="layoutData"
        :col-num="24"
        :rowHeight="baseLen"
        :margin="[10, 10]"
        :verticalCompact="true"
        :useStyleCursor="false"
        :isResizable="false"
        :isDraggable="false"
      >
        <grid-item
          v-for="(item,index) in layoutData"
          :key="index"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :class="{'collapse':item.h === 1}"
        >
          <template v-if="!item.isTable">
            <layout-card
              ref="chartCard"
              :data="item"
              :common-param="commonParam"
              :cell-loading="item.loading"
              :index="index"
              @showDialog="showDialog"
              @cardClose="cardClose"
              :report="true"
            ></layout-card>
          </template>
          <template v-else>
            <el-card
              class="chart-card"
              v-resize="resizeTable"
            >
              <div class="title">
                <div
                  class="title-left"
                  v-if="item.isTable"
                >
                  <div
                    class="load-btn"
                    :class="{
                      'icon-start':!loadStatus,
                      'icon-suspend':loadStatus,
                      'blue':loadSign,
                    }"
                    @click="loadTableData"
                  ></div>
                  <span
                    v-show="isPage"
                    class="table-total"
                  >Total Entries {{loadPage}} / {{page.total}}</span>
                  <!-- <el-button
                    size="mini"
                    :type="loadStatus?'primary':'info'"
                    @click="loadTableData"
                  >load</el-button> -->
                </div>
                <div v-else></div>
                <div class="tooltip">
                  <!-- <i
                    class="btn el-icon-setting"
                    @click="showChartSet"
                  ></i> -->
                  <i
                    class="btn el-icon-arrow-down"
                    @click="collapseChange"
                  ></i>
                  <i
                    class="btn el-icon-close"
                    @click="cardClose(index)"
                  ></i>
                </div>
              </div>
              <div
                class="con"
                ref="tableCon"
              >
                <!-- row-id="tableId" 
                :tree-config="{
                  children: 'children',
                  }" -->
                <!-- :data="tableData" -->
                <u-table
                  empty-text="No Data"
                  @cell-click="cellClick"
                  :cell-style="cellStyle"
                  :row-class-name="tableRowClassName"
                  @row-contextmenu="rowContextmenu"
                  @cell-mouse-enter="tableEnter"
                  @cell-mouse-leave="tableLeave"
                  v-loading="tableLoading"
                  class="mode-table"
                  ref="plTable"
                  :height="tableHeight"
                  :row-height="50"
                  stripe
                  use-virtual
                  show-body-overflow
                  show-header-overflow
                  :data-changes-scroll-top="false"
                  @table-body-scroll="loadMore"
                >
                  <template v-if="mainLanguage === 0 && subLanguage === 0">
                    <u-table-column
                      v-for="(item,index) in tableHeader"
                      :key="item.tableId"
                      :prop="item.prop"
                      :min-width="item.width"
                      :label="item.label"
                      :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                      :formatter="amountFormat"
                    >
                      <template #header>
                        <div class="table-header">
                          <div class="sort-btn">
                            <!-- 'el-icon-sort': sortState[item.prop] === 0, -->
                            <i :class="{
                              'icon-sort-up':sortState[item.prop] === 1 || (tableSort.prop === item.prop && tableSort.order === 'ascending'),
                              'icon-sort-down':sortState[item.prop] === 2 || (tableSort.prop === item.prop && tableSort.order === 'descending'),
                            }"></i>
                          </div>
                          <div
                            class="label"
                            :title="item.label"
                          >{{item.label}}</div>
                          <i
                            v-if="item.children.length == 0"
                            class="table-icon"
                            :class="{'el-icon-caret-bottom':!tableStatus[item.prop], 'icon-filter':tableStatus[item.prop]}"
                            @click.stop="showFilterPopover(item.prop)"
                          ></i>
                        </div>
                      </template>
                      <u-table-column
                        v-for="(childItem) in item.children"
                        :key="childItem.tableId"
                        :prop="childItem.prop"
                        :min-width="childItem.width"
                        :label="childItem.label"
                        :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                        :formatter="amountFormat"
                      >
                        <template #header>
                          <div class="table-header">
                            <div class="sort-btn">
                              <!-- 'el-icon-sort': sortState[item.prop] === 0, -->
                              <i :class="{
                                'icon-sort-up':sortState[childItem.prop] === 1 || (tableSort.prop === childItem.prop && tableSort.order === 'ascending'),
                                'icon-sort-down':sortState[childItem.prop] === 2 || (tableSort.prop === childItem.prop && tableSort.order === 'descending'),
                              }"></i>
                            </div>
                            <div
                              class="label"
                              :title="childItem.label"
                            >{{childItem.label}}</div>
                            <i
                              class="table-icon"
                              :class="{'el-icon-caret-bottom':!tableStatus[childItem.prop], 'icon-filter':tableStatus[childItem.prop]}"
                              @click.stop="showFilterPopover(childItem.prop)"
                            ></i>
                          </div>
                        </template>
                      </u-table-column>
                    </u-table-column>
                  </template>
                  <template v-else-if="mainLanguage === 1 && subLanguage ===0">
                    <el-table-column
                      v-for="(item,index) in tableHeader"
                      :key="item.tableId"
                      :label="item.label"
                      :min-width="item.width"
                      :header-align="item.children!==null && item.children.length > 0 ? 'center' : ''"
                      :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                    >
                      <el-table-column
                        :prop="item.prop"
                        :min-width="item.width"
                        :header-align="item.children!==null && item.children.length > 0 ? 'center' : ''"
                        :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                        :formatter="amountFormat"
                      >
                        <template #header>
                          <div class="table-header">
                            <div
                              class="label"
                              :title="item.language"
                            >{{item.language}}</div>
                            <i
                              v-if="item.children.length == 0"
                              class="table-icon"
                              :class="{'el-icon-caret-bottom':!tableStatus[item.prop], 'icon-filter':tableStatus[item.prop]}"
                              @click.stop="showFilterPopover(item.prop)"
                            ></i>
                          </div>
                        </template>
                        <el-table-column
                          v-for="(childItem) in item.children"
                          :key=" childItem.tableId"
                          :prop="childItem.prop"
                          :min-width="childItem.width"
                          :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                          :formatter="amountFormat"
                        >
                          <template #header>
                            <div class="table-header">
                              <div
                                class="label"
                                :title="childItem.label"
                              >{{childItem.label}}</div>
                              <i
                                class="table-icon"
                                :class="{'el-icon-caret-bottom':!tableStatus[childItem.prop], 'icon-filter':tableStatus[childItem.prop]}"
                                @click.stop="showFilterPopover(childItem.prop)"
                              ></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </template>
                  <template v-else-if="mainLanguage === 0 && subLanguage ===1">
                    <el-table-column
                      v-for="(item,index) in tableHeader"
                      :key="item.tableId"
                      :min-width="item.width"
                      :prop="item.prop"
                      :header-align="item.children!==null && item.children.length > 0 ? 'center' : ''"
                      :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                      :formatter="amountFormat"
                    >
                      <template #header>
                        <div class="table-header">
                          <div
                            class="label"
                            :title="item.label"
                          >{{item.label}}</div>
                          <i
                            v-if="item.children.length == 0"
                            class="table-icon"
                            :class="{'el-icon-caret-bottom':!tableStatus[item.prop], 'icon-filter':tableStatus[item.prop]}"
                            @click.stop="showFilterPopover(item.prop)"
                          ></i>
                        </div>
                      </template>
                      <el-table-column
                        v-for="(childItem) in item.children"
                        :key=" childItem.tableId"
                        :min-width="childItem.width"
                        :label="childItem.label"
                        :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                      >
                        <el-table-column
                          :prop="childItem.prop"
                          :min-width="childItem.width"
                          :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                          :formatter="amountFormat"
                        >
                          <template #header>
                            <div class="table-header">
                              <div
                                class="label"
                                :title="childItem.language"
                              >{{childItem.language}}</div>
                              <i
                                class="table-icon"
                                :class="{'el-icon-caret-bottom':!tableStatus[childItem.prop], 'icon-filter':tableStatus[childItem.prop]}"
                                @click.stop="showFilterPopover(childItem.prop)"
                              ></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </template>

                  <template v-else-if="mainLanguage === 1 && subLanguage ===1">
                    <el-table-column
                      v-for="(item,index) in tableHeader"
                      :key="item.tableId"
                      :min-width="item.width"
                      :label="item.label"
                      :header-align="item.children!==null && item.children.length > 0 ? 'center' : ''"
                      :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                    >
                      <el-table-column
                        :prop="item.prop"
                        :min-width="item.width"
                        :header-align="item.children!==null && item.children.length > 0 ? 'center' : ''"
                        :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                        :formatter="amountFormat"
                      >
                        <template #header>
                          <div class="table-header">
                            <div
                              class="label"
                              :title="item.language"
                            >{{item.language}}</div>
                            <i
                              v-if="item.children.length == 0"
                              class="table-icon"
                              :class="{'el-icon-caret-bottom':!tableStatus[item.prop], 'icon-filter':tableStatus[item.prop]}"
                              @click.stop="showFilterPopover(item.prop)"
                            ></i>
                          </div>
                        </template>
                        <el-table-column
                          v-for="(childItem) in item.children"
                          :key="childItem.tableId"
                          :min-width="childItem.width"
                          :label="childItem.label"
                          :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                        >
                          <el-table-column
                            :prop="childItem.prop"
                            :min-width="childItem.width"
                            :class-name=" index % 2 === 0 ? 'striped1' : 'striped2'"
                            :formatter="amountFormat"
                          >
                            <template #header>
                              <div class="table-header">
                                <div
                                  class="label"
                                  :title="childItem.language"
                                >{{childItem.language}}</div>
                                <i
                                  class="table-icon"
                                  :class="{'el-icon-caret-bottom':!tableStatus[childItem.prop], 'icon-filter':tableStatus[childItem.prop]}"
                                  @click.stop="showFilterPopover(childItem.prop)"
                                ></i>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </template>
                </u-table>
              </div>
            </el-card>
          </template>
        </grid-item>
      </grid-layout>
    </div>

    <!-- 订单弹框 -->
    <order-card
      :visible.sync="orderData.show"
      :data.sync="orderData"
      :height="orderHeight"
      ref="orderCard"
      @mouseleave.native="orderLeave"
      @mouseenter.native="orderEnter"
      @showItem="showItem"
      class="order-card"
    ></order-card>
    <!-- 订单item表格 -->
    <item-dialog
      :visible.sync="ItemDialogVisible"
      :data="orderData.itemData"
    ></item-dialog>
    <!-- 风险等级颜色弹框 -->
    <bgcolor-dialog
      ref="bgcolorDialog"
      :visible.sync="BgcolorDialogVisible"
      :data="bgcolorData"
      @mouseleave.native="bgcolorLeave"
      @mouseenter.native="bgcolorEnter"
    ></bgcolor-dialog>
    <!-- 报表右键菜单 -->
    <table-contextmenu
      :visible.sync="TableContextmenuVisible"
      :type="contextmenuType"
      @submit="submitTableMenu"
      ref="contextmenu"
    ></table-contextmenu>
    <!-- 报表右键菜单 -->
    <new-query
      :visible.sync="NewQueryVisible"
      :data="newQueryData"
      :common-param="commonParam"
      @submit="submitParam"
    ></new-query>

    <!-- 总览设置弹框 -->
    <overview
      :visible.sync="OverviewVisible"
      :layout-index="layoutIndex"
      :chart-id="chartId"
      :templates-id="reportTemplateId"
      @submit="submitOverview"
    ></overview>
    <!-- 图表排序弹框 -->
    <sorting-dialog
      :visible.sync="SortingDialogVisible"
      :data="layoutData[layoutIndex]"
      @submit="submitSorting"
    ></sorting-dialog>

    <!-- 筛选弹框 -->
    <filter-popover
      ref="filterPopover"
      :visible.sync="FilterPopoverVisible"
      :sort.sync="tableSort"
      :prop="tableProp"
      :data="tableParam[tableProp]"
      @submit="filterData"
      @showFilter="showFilter"
      @showSort="showSort"
    ></filter-popover>
    <filter-dialog
      :visible.sync="FilterDialogVisible"
      :prop="tableProp"
      :data="tableParam[tableProp]"
      @submit="filterData"
    ></filter-dialog>

    <!-- 排序弹框 -->
    <sort-dialog
      ref="SortDialog"
      :visible.sync="SortDialogVisible"
      :template-fields="templateFields"
      @submit="submitSort"
    ></sort-dialog>
  </div>

</template>

<script>
// 全局方法
import { copyText, formatDate, debounce, dealLang } from '@/utils/common'
// 插件
import VueGridLayout from 'vue-grid-layout'
import axios from 'axios'
import domtoimage from 'dom-to-image';
// 组件
import Condition from './components/Condition'
import FilterPopover from '@/components/FilterPopover'
import FilterDialog from '@/components/DashBoardDialog/FilterDialog'
import OrderCard from './components/OrderCard'
import ItemDialog from './components/ItemDialog'
import BgcolorDialog from './components/BgcolorDialog'
import TableContextmenu from './components/TableContextmenu'
import NewQuery from './components/NewQuery'
import LayoutCard from '@/pages/dashBoard/components/LayoutCard'
import Overview from '@/components/DashBoardDialog/Overview'
import SortingDialog from '@/pages/dashBoard/components/SortingDialog'
import SortDialog from './components/SortDialog'

// 接口
import {
  GetReportData,
  GetTemplateParam,
  GetOrder,
  GetLayout,
  SaveLayout,
  ExportReport,
  ExportReportPDF,
} from '@/api/counterMode'

import {
  GetFieldTypeAll,
  GetReportDesignerList,
  GetChart,
} from '@/api/reportDesigner'

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Condition,
    FilterPopover,
    FilterDialog,
    OrderCard,
    ItemDialog,
    BgcolorDialog,
    TableContextmenu,
    NewQuery,
    LayoutCard,
    Overview,
    SortingDialog,
    SortDialog,
  },
  name: 'CounterMode',
  data () {
    return {
      loadSign: true,
      SortDialogVisible: false,
      FilterDialogVisible: false,
      ItemDialogVisible: false,
      BgcolorDialogVisible: false,
      TableContextmenuVisible: false,
      NewQueryVisible: false,
      OverviewVisible: false,
      SortingDialogVisible: false,
      FilterPopoverVisible: false,

      tableLoading: false,
      tableHeight: null,
      reportTemplateId: null,
      mainLanguage: 0,
      subLanguage: 0,
      // tableData: [],
      // tableLocalData: [],
      tableHeader: [],
      tableHeaderIndex: 0,
      serialNum: 1,
      filterParam: {},
      page: {
        total: 0,
        size: 100,
        current: 1,
      },

      bgColor: {},
      measures: [],
      orderData: {
        visible: true,
        show: false,
        headerColor: '#1ECCA9',
        title: 'Original Order',
        orderSummary: {},
        itemData: [],
        paymentData: [],
        lineVoidData: [],
        releaseData: [],
        CRMData: [],
        openKeys: [],
        tableData: [],
      },
      orderClock: null,
      orderHeight: {
        item: 60,
        payment: 60,
        lvs: 60,
      },
      bgcolorData: {
        num: '',
        bgcolor: {}
      },
      bgcolorClock: null,
      orderState: false,
      bgcolorState: false,
      contextmenuData: {
        row: null,
        property: null,
      },
      newQueryData: null,
      linkConfig: {},
      underlineFields: [],
      contextmenuType: false,
      paging: [
        "8054638a-1788-4c92-b87e-7575ff00c36f",
        "294aac2e-15b8-4ddc-ae4a-42a76d8293f3",
      ],
      notShowPage: [

      ],
      moneyProp: [],
      templateFields: [],
      isDataNull: true,
      tempParam: [],
      historyParam: [],
      commonParam: [{
        mark: 'Report',
        prop: 'templatesId',
        label: 'Report Template',
        con: '',
        val: {
          id: '',
          name: '',
          mainLanguage: 0,
          subLanguage: 0,
        },
      }, {
        mark: 'BU',
        prop: 'branchIds',
        label: 'Site Name',
        con: 'ALL',
        val: ''
      }],
      chartId: null,
      baseLen: 0,
      layoutIndex: 0,
      layoutData: [{
        x: 0,
        y: 0,
        w: 24,
        h: 24,
        i: 0,
        initH: 24,
        isTable: true,
      }],
      dataOrigin: null,

      tableStatus: {},
      tableParam: {},
      tableProp: null,
      tableSort: {
        prop: '',
        order: ''
      },

      source: null,
      exportLoading: false,
      prevId: null,
      loadStatus: false,

      sortParam: [],
      sortState: {},
      isPage: false,
    }
  },
  computed: {
    loadPage: function () {
      if (this.paging.includes(this.reportTemplateId)) {
        let loadpage = this.page.current * this.page.size;
        return loadpage > this.page.total ? this.page.total : loadpage;
      } else {
        return this.page.total;
      }
    }
  },
  watch: {
    'tableSort': function (newVal, oldVal) {
      if (newVal.order === '') {
        this.$refs.plTable[0].clearSort();
      } else {
        this.$refs.plTable[0].sort(newVal.prop, newVal.order)
        for (let i in this.sortState) {
          this.sortState[i] = 0
        }
      }
      // for (var i in this.tableParam) {
      //   this.tableStatus[i] = this.tableParam[i].status;
      //   if (i === newVal.prop && newVal.order !== '') {
      //     this.tableStatus[i] = true;
      //   }
      // }
    },
    'orderState': function (newVal) {
      if (!newVal) {
        this.orderData.show = false;
      }
    },
    'bgcolorState': function (newVal) {
      if (!newVal) {
        this.BgcolorDialogVisible = false;
      }
    },
    '$route': function (to) {
      if (to.name === 'CounterMode') {
        this.initFilterParam();
      }
      // 中断加载
      this.loadStatus = false;
    }
  },
  created () {
    // this.$route.query.type = 1;
    // sessionStorage.linkToData = JSON.stringify([{ "mark": "Report", "prop": "templatesId", "label": "Report Template", "con": "02 Guest check open and closed within HH:MM, allow to input HH:MM", "val": { "id": "928390db-6c69-483e-9649-83c51f2e9252", "name": "02 Guest check open and closed within HH:MM, allow to input HH:MM", "mainLanguage": 0, "subLanguage": 0 }, "treeId": "0007928390db-6c69-483e-9649-83c51f2e9252" }, { "mark": "BU", "prop": "branchIds", "label": "Site Name", "con": "ALL", "val": "" }, { "prop": "business_date", "label": "Date", "con": "All Time", "data": { "visible": true, "activeTab": "New Query", "relation": "and", "queryType": 0, "field": "Date", "prop": "business_date", "invert": false, "operator": 0, "condition": 11, "range": { "start": "", "end": "" }, "dateRange": ["2020-10-01 00:00:00", "2021-01-07 23:59:59"], "list": [], "description": "", "fieldType": "date" } }, { "prop": "order_no", "label": "Order No.", "con": "ALL", "data": { "visible": true, "activeTab": "New Query", "relation": "and", "queryType": 0, "field": "Order No.", "prop": "order_no", "invert": false, "operator": 0, "condition": "", "range": { "start": "", "end": "" }, "dateRange": [], "list": [], "description": "", "fieldType": "string" } }, { "prop": "HH:MM", "label": "HH:MM", "con": "ALL", "data": { "visible": true, "activeTab": "New Query", "relation": "and", "queryType": 1, "field": "HH:MM", "prop": "HH:MM", "invert": false, "operator": 0, "condition": "", "range": { "start": "", "end": "" }, "dateRange": [], "list": [], "description": "", "fieldType": "time" } }]);

    // 获取数据类型
    GetFieldTypeAll().then(res => {
      this.measures = res;
      this.initFilterParam();
    })

    document.body.addEventListener('click', this.clearDropdown)
  },
  mounted () {
    // dealLang();

  },
  destroyed () {
    document.body.removeEventListener('click', this.clearDropdown)
  },
  methods: {
    resizeLayout: debounce(function () {
      this.baseLen = (this.$refs.box.clientHeight - 10 * 25) / 24;
    }, 10),
    resizeTable: debounce(function () {
      let h = this.$refs.tableCon[0].offsetHeight;
      if (h <= 0) return;
      this.tableHeight = h;
    }, 10),
    // 条件初始化
    initFilterParam () {
      if (!sessionStorage.linkToData) return;
      let linkToData = JSON.parse(sessionStorage.linkToData);
      sessionStorage.removeItem('linkToData')
      if (linkToData.length > 0) {
        this.submitParam({
          isReport: true
        }, linkToData);
      }
    },
    // 加载下一页
    loadMore (data, event) {
      if (!data.judgeFlse) return;
      if (this.loadSign && !this.tableLoading) {
        this.loadSign = false;

        if (this.paging.includes(this.reportTemplateId)) {
          this.page.current++;
          this.getTableData({});
        } else {
          return this.$message.success('All data has been loaded')
        }
      }
    },
    // 筛选数据
    filterData (paramData) {
      this.tableLoading = true;

      if (!!paramData) this.tableParam[this.tableProp] = paramData;

      let data = JSON.parse(JSON.stringify(this.tableLocalData));
      this.tableStatus = {};

      for (var i in this.tableParam) {
        this.tableStatus[i] = this.tableParam[i].status;
      }

      // 表格筛选
      this.worker = this.$worker.run((data, tableParam) => {
        // 获取单元格的值
        function getCellVal (data, property) {
          let value;
          if (/^(.+)\.(.+)$/.test(property)) {
            let key = property.split('.');
            value = data[key[0]][key[1]];
          } else {
            value = data[property];
          }
          return value;
        }
        // 数值比较
        function numberCompare (a, data) {
          let b;
          let invert = data.invert;
          let result;
          if (data.operator == 7) {
            a = parseFloat(a);
            let start = isNaN(parseFloat(data.range.start));
            let end = isNaN(parseFloat(data.range.end));
            if (!start && end) {
              result = a >= parseFloat(data.range.start);
            } else if (start && !end) {
              result = a < parseFloat(data.range.end);
            } else if (!start && !end) {
              result = parseFloat(data.range.start) < a && a <= parseFloat(data.range.end);
            } else {
              result = true;
            }
          } else if (data.operator == 6) {
            a = a.toString();
            b = data.list;
            result = b.includes(a)
          } else {
            a = parseFloat(a);
            b = parseFloat(data.condition);
            switch (data.operator) {
              case 0:
                result = a == b;
                break;
              case 1:
                result = a < b;
                break;
              case 2:
                result = a > b
                break;
              case 3:
                result = a <= b
                break;
              case 4:
                result = a >= b
                break;
              case 5:
                result = a != b
                break;
            }
          }
          return invert ? !result : result;
        }
        // 字符比较
        function stringCompare (a, data) {
          let b = data.operator == 6 ? data.list : data.condition;
          let invert = data.invert;
          let result;
          let re;
          b = b.toString().toLowerCase();
          a = a.toString().toLowerCase();
          switch (data.operator) {
            case 0:
              result = a == b;
              break;
            case 1:
              result = a != b;
              break;
            case 2:
              re = new RegExp("^" + b);
              result = re.test(a);
              break;
            case 3:
              re = new RegExp(b + "$");
              result = re.test(a);
              break;
            case 4:
              result = a.indexOf(b) != -1
              break;
            case 5:
              result = a.indexOf(b) == -1
              break;
            case 6:
              result = b.includes(a)
              break;
          }
          return invert ? !result : result;
        }
        // 时间比较
        function dateCompare (a, data) {
          let invert = data.invert;
          let result;
          let start;
          let end;
          a = Date.parse(a);
          if (data.dateRange.length == 0) {
            result = true
          } else {
            start = Date.parse(data.dateRange[0])
            end = Date.parse(data.dateRange[1])
            result = start <= a && a <= end;
          }
          return invert ? !result : result;
        }
        // 网点比较
        function storesCompare (a, data) {
          let b = data.operator == 6 ? data.list : data.condition;
          let invert = data.invert;
          let result;
          let re;
          b = b.toString().toLowerCase();
          a = a.toString().toLowerCase();
          switch (data.operator) {
            case 0:
              result = a == b;
              break;
            case 1:
              result = a != b;
              break;
            case 2:
              result = b.includes(a)
              break;
          }
          return invert ? !result : result;
        }
        for (var i in tableParam) {

          // 全选不过滤
          if (tableParam[i].itemNumber !== 0) {
            data = data.filter(item => {
              let val = getCellVal(item, i)
              return tableParam[i].items.filter(item => item.checked).map(item => item.label).includes(val)
            })
          }

          // 
          if (!!tableParam[i].filter.data) {
            let filter = tableParam[i].filter;

            switch (filter.type) {
              case 'number':
                data = data.filter(item => {
                  let val = getCellVal(item, i)
                  return numberCompare(parseFloat(val), filter.data)
                });
                break;
              case 'string':
                data = data.filter(item => {
                  let val = getCellVal(item, i)
                  return stringCompare(val, filter.data)
                });
                break;
              case 'date':
                data = data.filter(item => {
                  let val = getCellVal(item, i)
                  return dateCompare(val, filter.data)
                });
                break;
              case 'stores':
                data = data.filter(item => {
                  let val = getCellVal(item, i)
                  return storesCompare(val, filter.data)
                });
                break;
            }
          }
        }

        return data;
      }, [data, this.tableParam]).then(data => {
        this.tableData = data;
        this.$refs.plTable[0].reloadData(data)

        this.setLayoutData(this.tableData)

        // setTimeout(() => {
        //   this.tableLoading = false;
        //   if (data.length === 0) {
        //     dealLang();
        //   }
        // }, 1)
      }).catch(error => { })
    },

    // 初始化筛选功能
    initFilter (header) {
      header.forEach(item => {
        if (item.prop !== null) {
          this.sortState[item.prop] = 0;
          this.tableParam[item.prop].itemNumber = 0;
          this.tableParam[item.prop].filter.data = null;
          this.tableParam[item.prop].status = false;
          this.tableStatus[item.prop] = false;
          let arr = new Set(this.tableLocalData.map(rowItem => {
            let val = this.getCellVal(rowItem, item.prop)
            return val;
          }));
          this.tableParam[item.prop].items = Array.from(arr).map(name => Object.assign({
            checked: true,
            label: name,
          }))
        } else {
          if (item.children.length !== 0)
            this.initFilter(item.children);
        }
      })
    },

    // 获取表格数据
    getTableData (argument) {
      // 兼容1w数据
      // let arr = [
      //   "58c9a3d5-22e5-4ab7-ae39-16bef91f1336",
      //   "2501e3c6-a2d7-4f5f-9965-28f860e2c24f",
      // ]
      // this.page.size = arr.includes(this.filterParam.templatesId.id) ? 2000 : 100;
      // this.page.size = 2000;
      let query = this.$route.query;
      let param = {
        limit: this.page.size,
        page: this.page.current,
        templatesId: this.filterParam.templatesId.id,
        sorts: this.sortParam,
      }
      this.mainLanguage = this.filterParam.templatesId.mainLanguage;
      this.subLanguage = this.filterParam.templatesId.subLanguage;

      if (query.min) {
        param.min = query.min;
        param.max = query.max;
      }

      for (let i in this.filterParam) {
        if (i !== 'templatesId')
          param[i] = this.filterParam[i];
      }
      // 中断请求
      if (!!this.source) {
        this.source.cancel('interrupt');
      }
      let CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      axios({
        url: process.env.VUE_APP_BASE_URL + '/sounterMode/queryOrders.do',
        method: 'post',
        data: JSON.stringify(param),
        headers: {
          token: this.$cookies.get('token') || '',
          'content-type': 'application/json;charset=UTF-8',
        },
        cancelToken: this.source.token
      }).then(response => {
        let res = response.data;

        if (argument.isReport)
          this.bgColor = res.data.param || {};
        else
          this.bgColor = Object.assign(this.bgColor, res.data.param || {})

        let data = this.dealData(res.data.data, res.header);
        this.tableHeader = res.header;
        this.page.total = parseInt(res.data.total);

        if (this.paging.includes(this.reportTemplateId)) {
          // 处理 后端分页  
          this.tableLocalData.push(...data);
          this.$refs.plTable[0].reloadData(this.tableLocalData)
          // this.filterData();
          if (data.length < this.page.size) {
            this.loadSign = false;
            this.$message.success('All data has been loaded')

            // 
            if (this.notShowPage.includes(this.reportTemplateId)) {
              this.page.total = this.page.size * (this.page.current - 1) + data.length;
              this.isPage = true;
            }
          } else {
            this.loadSign = true;
            if (this.loadStatus) {
              setTimeout(() => {
                this.page.current++;
                this.getTableData({});
              }, 100);
            }
          }
        } else {
          this.tableLocalData = JSON.parse(JSON.stringify(data));
          this.$refs.plTable[0].reloadData(this.tableLocalData)
          this.loadSign = true
        }
        this.initFilter(this.tableHeader);

        this.tableLoading = false;
        if (this.tableLocalData.length > 0) this.isDataNull = false;

        this.setLayoutData(this.tableLocalData)
        this.prevId = param.templatesId;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 处理数据
    dealData (data, header) {
      let jsonVar = {};
      let ratioVar = {};
      this.headerTraversal(header, jsonVar, ratioVar)
      data = data.map(item => {
        item.children = [];
        item.tableId = this.serialNum++;
        let count = 0;
        let itemColor = [];

        for (let key in jsonVar) {
          item[key] = !!item[key] ? JSON.parse(item[key]) : [];
          if (count < item[key].length) count = item[key].length;
        }
        for (let key in ratioVar) {
          item[key] = item[key] * 100 + '%';
        }


        for (let i in this.bgColor) {
          if (item.branch_code == i) {
            itemColor = this.bgColor[i];
            break;
          }
        }
        item.bgColor = itemColor;
        item.bgColorKey = itemColor.map(item => item.dbKey);

        if (count > 0) {
          for (let key in jsonVar) {
            for (let i = 0; i < count - 1; i++) {
              if (!item.children[i]) item.children[i] = { tableId: this.serialNum++ };
              item.children[i].order_no = null;
              item.children[i].bgColor = itemColor;
              item.children[i].bgColorKey = itemColor.map(item => item.dbKey);
              item.children[i][key] = item[key][i + 1] || {};
            }
            item[key] = item[key][0] || {};
          }
        }

        return item;
      })
      return data;
    },
    // 遍历出需要解析的字段
    headerTraversal (data, result, ratioVar) {
      // if (this.filterParam.templatesId.id == this.prevId) return;
      data.forEach(item => {
        if (this.filterParam.templatesId.id !== this.prevId) item.tableId = this.tableHeaderIndex++;
        if (/^(.+)\.(.+)$/.test(item.prop)) {
          var key = item.prop.split('.');
          result[key[0]] = 1;
        }
        if (item.dataType === 'ratio') {
          ratioVar[item.prop] = 1;
        }
        if (item.children.length > 0) {
          this.headerTraversal(item.children, result, ratioVar)
        }
      })
    },

    // 点击单元格
    cellClick (row, column, cell, event) {
      if (!!this.linkConfig[column.property] && !!this.linkConfig[column.property].linkToJson) {
        let branchCodeProps = ['void_branch_code', 'branch_code', 'storeId'];
        let linkToJson = JSON.parse(this.linkConfig[column.property].linkToJson)
        if (linkToJson.type === 0) {
          let param = {
            isReport: linkToJson.data[0].val.id !== this.commonParam[0].val.id,
            newQuery: true,
            history: true,
          }
          linkToJson.data = linkToJson.data.map((item, index) => {
            if (index === 0) return item;
            if (index === 1) {
              if (item.con === 'ALL' && !row.businessUnit) {
                item.all = this.commonParam[1].all;
              } else if (item.con === 'ALL' && !!row.businessUnit) {
                item.all = this.commonParam[1].all;
                item.con = row.businessUnitName;
                item.val = row.businessUnit;
              }
            } else if (item.data.fieldType === 'stores' && item.data.list.length === 0 && item.data.condition === '') {
              item.con = row.void_branch_code || row.branch_code || row.storeId;
              item.data.condition = row.void_branch_code || row.branch_code || row.storeId;
              item.data.operator = 0;
            } else {
              item.con = row[item.prop];
              item.data.condition = row[item.prop];
            }
            return item
          })
          // 带上当前报表时间条件
          let dateItem = this.commonParam.find(item => !!item.data && item.data.fieldType === 'date')
          let index = linkToJson.data.findIndex(item => item.prop === 'date');
          if (!!dateItem) {
            if (index == -1) {
              linkToJson.data.push(dateItem)
            } else {
              linkToJson.data[index] = dateItem;
            }
          }
          this.submitParam(param, linkToJson.data);
        } else {
          sessionStorage.paramData = JSON.stringify(this.commonParam)
          if (column.property === 'order_no') {
            sessionStorage.order_no = row.order_no;
            this.$router.push({ name: 'OrderDetails', query: { no: row.order_no, id: row.branch_code, time: row.trans_datetime, templatesId: this.reportTemplateId } })
          } else if (column.property === 'original_order_no') {
            sessionStorage.original_order_no = row.original_order_no;
            this.$router.push({ name: 'OrderDetails', query: { no: row.original_order_no, id: row.original_branch_code, time: row.original_trans_datetime, templatesId: this.reportTemplateId } })
          } else if (column.property === 'void_order_no') {
            sessionStorage.void_order_no = row.void_order_no;
            this.$router.push({ name: 'OrderDetails', query: { no: row.void_order_no, id: row.void_branch_code, time: row.void_trans_datetime, templatesId: this.reportTemplateId } })
          }
        }
      }

    },
    // 单元格样式
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      let result = {};
      // 金额靠右
      if (this.moneyProp.includes(column.property)) result['text-align'] = 'right !important';
      // 点击下划线
      if (this.underlineFields.includes(column.property)) {
        result['cursor'] = 'pointer';
        result['text-decoration'] = 'underline';
      }
      // 背景颜色
      if (!!row.bgColor) {
        let itemColor = row.bgColor.find(item => item.dbKey === column.property);
        if (!!itemColor) {
          let num = parseFloat(this.getCellVal(row, column.property));
          if (itemColor.minL1 < num && num <= itemColor.maxL1) {
            result['background'] = itemColor.colorL1 + '!important'
          } else if (itemColor.minL2 < num && num <= itemColor.maxL2) {
            result['background'] = itemColor.colorL2 + '!important'
          } else if (itemColor.minL3 < num && num <= itemColor.maxL3) {
            result['background'] = itemColor.colorL3 + '!important'
          }
        }
      }
      return result;
    },
    // 缩小卡片
    collapseChange (e) {
      let el = e.path[4];
      if (el.classList.contains('collapse'))
        el.classList.remove('collapse')
      else
        el.classList.add('collapse')
    },
    // 
    tableRowClassName ({ row, rowIndex }) {
      if (sessionStorage.order_no !== undefined && sessionStorage.order_no === row.order_no) return 'checked-row';
      if (sessionStorage.original_order_no !== undefined && sessionStorage.original_order_no === row.original_order_no) return 'checked-row';
      if (sessionStorage.void_order_no !== undefined && sessionStorage.void_order_no === row.void_order_no) return 'checked-row';
    },
    // 显示表盘编辑弹框
    showPanelDialog () {
      this.OverviewVisible = true;
    },
    // 显示筛选框
    showFilter () {
      this.FilterDialogVisible = true;
    },
    // 提交条件
    submitParam (param, data) {
      if (param.history) {
        this.historyParam.push(this.commonParam)
      }
      this.reportTemplateId = data[0].val.id;
      this.page.current = 1;
      this.tableLocalData = [];
      this.tableData = [];
      this.tableLoading = true;

      // 初始化筛选条件
      if (param.isReport) {
        this.tableSort = {
          prop: '',
          order: ''
        };
        this.isPage = false;
        // 不显示总数的报表自动加载
        if (this.notShowPage.includes(this.reportTemplateId)) {
          this.loadStatus = true;
        } else {
          this.loadStatus = false;
          this.isPage = true;
        }
        GetLayout({ templatesId: this.reportTemplateId }).then(res => {
          if (res === '') {
            this.commonParam = data;
            this.currentLayout = null;
            this.layoutData = [{
              x: 0,
              y: 0,
              h: 24,
              w: 24,
              i: 0,
              initH: 24,
              data: {
                templateId: this.reportTemplateId,
              },
              isTable: true,
              chartId: null,
            }]
            this.getDefaultParam(param);

          } else {
            this.commonParam = JSON.parse(res.conditionJson);
            this.currentLayout = res;
            this.layoutData = JSON.parse(res.layoutJson);

            let arr = this.layoutData
              .filter(item => !!item.chartId)
              .map((item, index) => this.getlayoutChart({
                i: item.i,
                chartId: item.chartId,
              }))
            Promise.all(arr).then(res => {
              this.getDefaultParam(param);
            })
          }
        })
      } else {
        this.commonParam = data;
        this.dealParam(param);
      }
    },
    // 处理条件
    dealParam (param) {
      let paramCom = {
        parameters: []
      };
      this.commonParam.forEach((item, index) => {
        if (item.mark === 'Report') {
          paramCom[item.prop] = item.val;
        } else if (item.mark === 'BU') {
          if (item.con !== 'ALL') {
            paramCom[item.prop] = item.val;
          } else {
            paramCom[item.prop] = '';
            // paramCom[item.prop] = '0f458006-7267-4643-944e-d2f5cb427564,1d9a50ec-bd16-4f20-bea7-d271a5ea12a0,1f5c3afe-41a0-448c-893a-8f33a9609170,28dab1c9-7f58-481a-a4f3-d2f9ac61bc54,2eda1607-9ec4-49e9-8c7d-b0de602b8735,4abd3e12-e1b8-4779-9b35-fc54da65493a,4ac4f9c1-2354-4e07-9246-2586e008f20a,52fe49a6-005f-45f8-9f39-52fae9fa22d1,5c555ef5-ebbe-4f47-9883-10dbc85a6067,5e1d3352-82a3-4bc7-ad41-e8a60c3ac203,5eee1d90-a0da-4eb7-96c1-41221b81e6cc,65f4b5c1-8f3b-41f0-9e42-7cbd9771cbb4,662006ef-9caf-469b-b00e-b2b073961f24,689165ae-a4c6-4f12-befc-7723b65bb870,6eba7d01-03f9-49e7-aaae-b68c8fd21fc4,70c24bad-ec94-4eb8-9a77-16136d364308,714b2241-2036-42be-898b-883360fba8fc,807ece25-8677-4b02-a1ea-207eb2e17d12,85056551-a53b-487e-9f75-20a9481c949a,8aca4e25-46cb-4f64-90dd-b66cbe62118a,8b3d876d-a5d7-4ca6-8b28-2c37fec9e7ae,a3af44b1-bb24-41db-a26c-0aa404617377,a980c247-d133-4b20-a0db-3536885d84f0,af6a5e52-056a-464a-9778-60ecf493f552,bea30e50-36a3-4aa5-9260-335b291aa812,c645d79a-fc8c-403b-8340-cc8fcf17626f,c711f5b8-ce4f-436f-bc9d-0d608a7051f3,cdfe9b66-a332-4566-ad61-69065bf058d6,ce21ee88-6de1-4e53-8586-1aea57e5bdbf,d2d89266-9e40-4b20-9677-6c261bb027fd,d9a3c566-1287-4312-bf82-cbf3955f99ba,dd411008-bb02-4b5d-9eda-71705932a18e,e947853a-eee5-4194-a498-7fb144697ddd,f1479c52-8d79-408c-afd2-1e8c59175d40';
          }
        } else {
          if (item.data.condition === '' && item.data.list.length === 0 && item.data.dateRange.length === 0 && item.data.range.start === '') return;
          // all time 不用传
          if (item.data.fieldType === 'date' && item.data.condition === 11) return;

          this.dealParamType(paramCom, item.data);
        }
      })
      this.filterParam = paramCom;

      this.getTableData(param);
    },
    // 处理条件属性
    dealParamType (paramCom, item) {
      let data = {
        key: item.prop,
        operator: this.$global.typeOperators[item.fieldType][item.operator],
        relation: item.relation,
        invert: Number(!item.invert),
      }
      switch (item.fieldType) {
        case 'number':
        case 'string':
          switch (item.operator) {
            case 6:
              data.value = item.list.join(',');
              break;
            case 7:
              data.value = `${item.range.start}~${item.range.end}`;
              break;
            default:
              if (item.prop === "branch_code_5" && this.filterParam.templatesId.id === "6a5730d1-79e0-409b-8152-095d9be1b732") {
                let branch = this.$global.BranchData.find(branch => branch.storeId5 === item.condition);
                data.value = !!branch ? branch.id : '';
              } else {
                data.value = item.condition;
              }
          }
          break;
        case 'stores':
          data.value = this.getStoreId(item);
          break;
        case 'date':
          // data.value = item.condition !== 11 ? item.dateRange[0] + '~' + item.dateRange[1] : '';
          data.value = item.dateRange[0] + '~' + item.dateRange[1];
          break;
        case 'time':
          data.value = `${item.dateRange[0]}~${item.dateRange[1]}`;
          break;
        case 'mins':
          data.value = item.operator !== 2 ? `${item.condition}~0` : `${item.range.start}~${item.range.end}`;
          break;
        default:
          data.value = item.condition;
      }
      data.type = item.fieldType;
      data.value = !!data.value ? data.value.toString().trim() : '';
      paramCom.parameters.push(data)
    },
    // 获取网点id
    getStoreId (data) {
      let result;
      let temp;
      let columnProp = data.field === 'Branch Code' ? 'storeId5' : 'storeId5';
      switch (data.operator) {
        case 1:
          temp = data.condition.split(',');
          result = this.$global.BranchData.filter(item => !temp.includes(item[columnProp]))
          break;
        case 2:
          result = this.$global.BranchData.filter(item => data.list.includes(item[columnProp]))
          break;
        default:
          temp = data.condition.split(',');
          result = this.$global.BranchData.filter(item => temp.includes(item[columnProp]))
      }
      return result.map(item => item.id).join(',');
    },
    // 表格单元格进入
    tableEnter (row, column, cell, event) {
      if (column.property === 'order_no' && this.orderState) {
        clearTimeout(this.orderClock);

        if (!this.$refs.orderCard.isNail) {
          // 计算位置
          let el = this.$refs.orderCard.$el;
          let screenData = cell.getBoundingClientRect();
          if (360 < window.innerWidth - screenData.right) {
            el.style.left = screenData.right + 'px';
          } else {
            el.style.left = screenData.left - 360 + 'px';
          }

          if (window.innerHeight * 0.6 < window.innerHeight - screenData.top) {
            el.style.top = screenData.top + 'px';
          } else {
            el.style.bottom = '0px';
          }
          el.style.height = window.innerHeight * 0.6 + 'px';
        }

        // 获取参数
        let query;
        let param;
        if (column.property === 'order_no') {
          query = { no: row.order_no, id: row.branch_code, time: row.trans_datetime, templatesId: this.reportTemplateId };
        } else if (column.property === 'original_order_no') {
          query = { no: row.original_order_no, id: row.original_branch_code, time: row.original_trans_datetime, templatesId: this.reportTemplateId };
        } else if (column.property === 'void_order_no') {
          query = { no: row.void_order_no, id: row.void_branch_code, time: row.void_trans_datetime, templatesId: this.reportTemplateId };
        }

        // 请求订单
        param = {
          orderNo: query.no,
          storeId: query.id,
          // transDatetime: formatDate(new Date(query.time), 'yyyy-MM-dd 00:00:00'),
          transDatetime: query.time,
        }
        GetOrder(param).then(res => {
          res.items = !!res.items ? JSON.parse(res.items) : [];
          res.payments = !!res.payments ? JSON.parse(res.payments) : [];
          res.lvs = !!res.lvs ? JSON.parse(res.lvs) : [];

          this.dealOrder(res, 0);

          this.orderData.show = true;
        })
      } else if (row.bgColorKey.includes(column.property) && this.bgcolorState) {
        clearTimeout(this.bgcolorClock);

        let el = this.$refs.bgcolorDialog.$el;
        let screenData = cell.getBoundingClientRect();
        if (220 < window.innerWidth - screenData.right) {
          el.style.left = screenData.right + 'px';
        } else {
          el.style.left = screenData.left - 220 + 'px';
        }

        if (200 < window.innerHeight - screenData.top) {
          el.style.top = screenData.top + 'px';
        } else {
          el.style.bottom = '0px';
        }

        let num = parseFloat(this.getCellVal(row, column.property));

        this.bgcolorData = {
          num: num,
          bgcolor: row.bgColor.find(item => item.dbKey === column.property)
        }

        this.BgcolorDialogVisible = true;
      }
    },
    // 表格单元格离开
    tableLeave (row, column, cell, event) {
      if (column.property === 'order_no') {
        if (!this.$refs.orderCard.isNail) {
          this.orderClock = setTimeout(() => {
            this.orderData.show = false;
          }, 300);
        }
      } else if (row.bgColorKey.includes(column.property)) {
        this.bgcolorClock = setTimeout(() => {
          this.BgcolorDialogVisible = false;
        }, 300);
      }
    },
    orderEnter () {
      clearTimeout(this.orderClock);
    },
    orderLeave () {
      if (!this.$refs.orderCard.isNail) {
        this.orderClock = setTimeout(() => {
          this.orderData.show = false;
        }, 300);
      }
    },
    bgcolorEnter () {
      clearTimeout(this.bgcolorClock);
    },
    bgcolorLeave () {
      this.bgcolorClock = setTimeout(() => {
        this.BgcolorDialogVisible = false;
      }, 300);
    },
    showItem () {
      this.ItemDialogVisible = true
    },
    // 处理订单详情
    dealOrder (data, index) {
      for (let i = data.items.length - 1; i >= 0; i--) {
        data.items[i].amount = data.items[i].unit_price * data.items[i].item_qty;
        // 优惠券折扣信息
        if (data.items[i].deduct_amt !== 0) {
          let item = JSON.parse(JSON.stringify(data.items[i]));
          data.items[i].pmt_no = '';
          item.pmt = 'PMT';
          item.item_qty = 1;
          item.unit_price = 0 - item.deduct_amt;
          item.amount = 0 - item.deduct_amt;
          data.items.push(item)
        }
      }
      // 员工折扣信息
      if (data.deduct_amt !== 0) {
        data.items.push({
          item_edesc: 'Discount',
          item_qty: 1,
          unit_price: 0 - data.deduct_amt,
          amount: 0 - data.deduct_amt,
        })
      }

      this.orderData.orderSummary = {
        orderNo: data.order_no,
        orderTime: data.trans_datetime,
        closeTime: data.close_datetime,
        storeID: data.branch_code,
        cashierID: data.uid,
        transactionHandler: '',
        totalAmount: data.grand_total,
        close_uid: data.close_uid,
        serv_chg_amt: data.serv_chg_amt,
        tips: data.payments.reduce((total, item) => total + item.tips, 0).toFixed(2),
        change: data.payments.reduce((total, item) => total + item.change, 0).toFixed(2),
        tender: data.payments.reduce((total, item) => total + item.tender, 0).toFixed(2),
      }

      this.orderData.itemData = data.items;
      this.orderData.paymentData = data.payments;
      this.orderData.lineVoidData = data.lvs;

      this.orderData.tableData = [{
        table_id: data.table_id,
        nop: data.nop,
        serv_chg_amt: data.serv_chg_amt,
        close_uid: data.close_uid,
      }]
      this.orderHeight.item = this.orderHeight.item < (data.items.length + 1) * 35 + 8 ? (data.items.length + 1) * 35 + 8 : this.orderHeight.item;
      this.orderHeight.payment = this.orderHeight.payment < (data.payments.length + 1) * 35 + 8 ? (data.payments.length + 1) * 35 + 8 : this.orderHeight.payment;
      this.orderHeight.lvs = this.orderHeight.lvs < (data.lvs.length + 1) * 35 + 8 ? (data.lvs.length + 1) * 35 + 8 : this.orderHeight.lvs;
    },
    // 表格右键菜单
    rowContextmenu (row, column, event) {
      event.preventDefault();

      if (this.reportTemplateId === '4cc26fab-6d91-4bd1-a962-dcbab3b27c52' && column.property !== 'branch_code') return;
      if (this.reportTemplateId === '1c80f635-515d-4417-aaea-e5c8b6be233e' && column.property !== 'refund_trans_datetime' && column.property !== 'refund_branch_code' && column.property !== 'refund_business_date') return;
      if (this.reportTemplateId === 'b84486e0-3adc-4557-98c6-58bb826ace5a' && column.property !== 'void_trans_datetime' && column.property !== 'void_branch_code' && column.property !== 'void_business_date') return;

      let el = this.$refs.contextmenu.$el;
      if (130 < window.innerWidth - event.pageX) {
        el.style.left = event.pageX + 'px';
      } else {
        el.style.left = event.pageX - 130 + 'px';
      }

      if (130 < window.innerHeight - event.pageY) {
        el.style.top = event.pageY + 'px';
      } else {
        el.style.top = event.pageY - 148 + 'px';
      }
      this.contextmenuData = {
        row: row,
        property: column.property,
      }
      this.contextmenuType = /.+\..+/.test(column.property);
      this.TableContextmenuVisible = true;
    },
    clearDropdown () {
      this.TableContextmenuVisible = false;
      this.FilterPopoverVisible = false;

    },
    submitTableMenu (type) {
      switch (type) {
        case 1:
        case 2:
          if (this.contextmenuData.property !== 'businessUnitName' && this.contextmenuData.property !== 'sub_businessUnitName') {
            var result = this.queryTableHeader(this.contextmenuData.property);
            var val = this.getCellVal(this.contextmenuData.row, this.contextmenuData.property);
            var operator = 0;
            var date;
            var data;
            var dateRange = [];
            if (result.dataType === 'date') {
              date = new Date(val)
              operator = 12;
              dateRange[0] = formatDate(date, 'yyyy-MM-dd 00:00:00');
              dateRange[1] = formatDate(date, 'yyyy-MM-dd 23:59:59');
              val = `${dateRange[0]}~${dateRange[1]}`;
            }
            data = {
              prop: this.contextmenuData.property,
              label: result.label,
              con: val,
              data: {
                visible: true,
                activeTab: "New Query",
                relation: "and",
                queryType: 0,
                field: result.label,
                prop: this.contextmenuData.property,
                invert: false,
                operator: operator,
                condition: val,
                range: {
                  start: "",
                  end: ""
                },
                dateRange: dateRange,
                list: [],
                description: "",
                fieldType: result.dataType,
              }
            };
          } else {
            data = {
              con: this.contextmenuData.row.businessUnitName,
              label: "Site Name",
              mark: "BU",
              prop: "branchIds",
              val: this.contextmenuData.row.businessUnit,
            }
          }
          if (type === 1) {
            var index = this.commonParam.findIndex(item => item.prop === data.prop);
            if (index == -1) {
              this.commonParam.push(data)
            } else {
              this.$set(this.commonParam, index, data)
            }
            this.submitParam({}, this.commonParam);
          } else {
            this.newQueryData = data;
            this.NewQueryVisible = true;
          }
          break;
        case 3:
          var val = this.getCellVal(this.contextmenuData.row, this.contextmenuData.property);
          copyText(val)
          this.$message.success(this.$i18n.t('message.copy'))
          break;
      }
    },
    queryTableHeader (prop) {
      let result = {
        dataType: '',
        label: '',
      }
      this.tableHeader.map(item => {
        let name = item.label;
        if (item.prop === prop) {
          result = {
            dataType: item.dataType,
            label: item.label,
          }
        }
        if (item.children.length > 0) {
          item.children.map(item => {
            if (item.prop === prop) {
              result = {
                dataType: item.dataType,
                label: name + ' - ' + item.label,
              }
            }
          })
        }
      })
      return result;
    },
    // 获取单元格的值
    getCellVal (data, property) {
      let value;
      if (/^(.+)\.(.+)$/.test(property)) {
        let key = property.split('.');
        value = data[key[0]][key[1]];
      } else {
        value = data[property];
      }
      return value;
    },

    // 获取默认参数
    getDefaultParam (params) {
      let param = {
        limit: 9999,
        page: 1,
        search: '',
        templatesId: this.commonParam[0].val.id,
      }
      let result = [];
      GetReportDesignerList(param).then(res => {
        this.templateFields = [];
        this.linkConfig = {};
        this.underlineFields = [];
        this.moneyProp = [];
        this.tableParam = {};
        res.list.forEach(item => {
          if (item.fieIdType === 0) {
            if (!!item.linkToJson) this.underlineFields.push(item.dbKey);
            if (item.dataTypeId == 2) this.moneyProp.push(item.dbKey)

            this.linkConfig[item.dbKey] = item;

            let measure = this.measures.find(measure => measure.id === item.dataTypeId);

            this.tableParam[item.dbKey] = {
              status: false,
              itemNumber: 0,
              filter: {
                label: item.mainFieText,
                type: measure.type,
                measure: measure.name,
                data: null,
              },
              items: [],
            };
          }
          this.templateFields.push(item);
        })
        this.commonParam.forEach(item => {
          if (!!item.data && (item.data.fieldType === 'date' || item.data.fieldType === 'stores')) {
            let index = this.templateFields.findIndex(field => {
              let measure = this.measures.find(measure => measure.id === field.dataTypeId);
              return measure.type === item.data.fieldType;
            })

            if (index !== -1) {
              let prop = this.templateFields[index].fieIdType === 0 ? this.templateFields[index].dbKey : this.templateFields[index].mainFieText;

              item.prop = prop;
              item.label = this.templateFields[index].mainFieText;
              item.data.field = this.templateFields[index].mainFieText;
              item.data.prop = prop;
              item.data.queryType = this.templateFields[index].fieIdType;
            }
          }
        })

        this.templateFields
          .filter(item => item.defaultQueryFields === 1 || item.fieIdType === 1)
          .forEach(item => {
            let prop = item.fieIdType === 0 ? item.dbKey : item.mainFieText;
            let index = this.commonParam.findIndex(param => param.prop === prop)
            let measure = this.measures.find(measure => measure.id === item.dataTypeId);

            if (index === -1) {

              let data = {
                prop: prop,
                label: item.mainFieText,
                con: measure.type === 'date' ? 'Last 30 minutes' : 'ALL',
                data: {
                  visible: true,
                  activeTab: "New Query",
                  relation: "and",
                  queryType: item.fieIdType,
                  field: item.mainFieText,
                  prop: prop,
                  invert: false,
                  operator: 0,
                  condition: measure.type === 'date' ? 0 : '',
                  range: {
                    start: "",
                    end: ""
                  },
                  dateRange: measure.type === 'date' ? this.$global.TheLastThirtyMinutes.split('~') : [],
                  list: [],
                  description: "",
                  fieldType: measure.type,
                }
              };

              result.push(data)
            }
          })
        this.commonParam = this.commonParam.concat(result);
        this.tempParam = JSON.parse(JSON.stringify(this.commonParam))
        this.dealParam(params);
      })
    },
    // 报表后退
    paramBack () {
      if (this.historyParam.length == 0) return;
      let result = this.historyParam.pop();
      let param = {
        isReport: true,
      }
      this.submitParam(param, result)
    },
    // 重置筛选条件
    resetParam () {
      let result = JSON.parse(JSON.stringify(this.tempParam));
      this.loadStatus = false;
      this.submitParam({}, result)
      // setTimeout(() => {
      //   this.loadStatus = true;
      // }, 300);
    },
    // 显示图表设置
    showDialog (type, param) {
      this.layoutIndex = param.layoutIndex;
      switch (type) {
        case 1:
          this.chartId = this.layoutData[this.layoutIndex].chartId;
          this.OverviewVisible = true;
          break;
        case 3:
          this.SortingDialogVisible = true;
          break;
      }
    },
    // 关闭卡片
    cardClose (index) {
      this.layoutData.splice(index, 1);
    },
    submitLayout (index) {
      let result = [];
      switch (index) {
        case 0:
          result = [{
            x: 0,
            y: 0,
            w: 24,
            h: 24,
            i: 0,
            initH: 24,
            isTable: true,
            data: {
              templateId: this.reportTemplateId,
            },
          }]
          break;
        case 1:
          result = [{
            x: 0,
            y: 0,
            w: 24,
            h: 12,
            i: 0,
            initH: 12,
          }, {
            x: 0,
            y: 12,
            w: 24,
            h: 12,
            i: 1,
            initH: 12,
            isTable: true,
            data: {
              templateId: this.reportTemplateId,
            },
          }]
          break;
        case 2:
          result = [{
            x: 0,
            y: 0,
            w: 12,
            h: 12,
            i: 0,
            initH: 12,
          }, {
            x: 12,
            y: 0,
            w: 12,
            h: 12,
            i: 1,
            initH: 12,
          }, {
            x: 0,
            y: 12,
            w: 24,
            h: 12,
            i: 2,
            initH: 12,
            isTable: true,
            data: {
              templateId: this.reportTemplateId,
            },
          }]
          break;
      }
      this.layoutData = result;
    },
    // 保存布局
    saveLayout () {
      let param = {
        layoutJson: JSON.stringify(this.layoutData.map(item => {
          return {
            x: item.x,
            y: item.y,
            h: item.h,
            w: item.w,
            i: item.i,
            initH: item.initH,
            data: {
              templateId: !!item.data ? item.data.templateId : '',
            },
            chartId: item.chartId,
            isTable: item.isTable,
          }
        })),
        conditionJson: JSON.stringify(this.commonParam),
        templatesId: this.reportTemplateId,
      }

      if (this.currentLayout) {
        param.id = this.currentLayout.id;
        param.panelLayoutId = this.currentLayout.panelLayoutId;
      }

      this.saveLoading = true;
      SaveLayout(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.save'))
          this.saveLoading = false;
        } else {
          this.$message.warning(res.data);
        }
      }).catch(error => {
        this.saveLoading = false;
      })
    },
    // 提交报表配置
    submitOverview (type, data, index) {
      let item = Object.assign(this.layoutData[index], {
        data: data,
        chartId: data.id,
        cardType: type,
        dataOrigin: {
          data: {
            data: this.tableData
          },
          header: this.tableHeight
        },
      })
      this.$set(this.layoutData, index, item)

      this.$nextTick(() => {
        this.$refs.chartCard[index].initData();
      })
    },
    getlayoutChart (param) {
      return new Promise((resolve, reject) => {
        GetChart(param.chartId).then(res => {
          if (!!res) {
            let index = this.layoutData.findIndex(item => item.i === param.i);
            this.layoutData[index].data = res;
            resolve()
          } else {
            resolve()
          }
        })
      })
    },
    // 提交排序
    submitSorting (data) {
      this.layoutData[this.layoutIndex].data.sortingJson = data;
      this.$refs.chartCard[this.layoutIndex].initData();
    },
    // 显示筛选弹框
    showFilterPopover (prop) {
      event.preventDefault();

      // 设置弹框显示位置
      let el = this.$refs.filterPopover.$el;
      let screenData = event.currentTarget.getBoundingClientRect()
      this.FilterPopoverVisible = false;

      // 高度
      if (window.innerHeight - screenData.bottom > window.innerHeight * 0.6) {
        el.style.height = window.innerHeight * 0.6 + 'px';
      } else {
        el.style.height = window.innerHeight - screenData.bottom - 5 + 'px';
      }

      // left
      if (screenData.left < 150) {
        el.style.left = 10 + 'px';
      } else if (window.innerWidth - screenData.right > 150) {
        el.style.left = screenData.left + screenData.width / 2 - 150 + 'px';
      } else {
        el.style.left = window.innerWidth - 310 + 'px';
      }

      el.style.top = screenData.bottom + 5 + 'px';
      this.tableProp = prop;

      this.$nextTick(() => {
        this.FilterPopoverVisible = true;
      })
    },
    // 导出 
    submitExport (data) {
      let param;
      let formats = {
        PDF: 'pdf',
        EXCEL: 'xlsx',
        JPG: 'jpg',
        PNG: 'png',
      };
      this.exportLoading = true;
      if (data.format === 'EXCEL') {
        if (!this.tableLocalData || this.tableLocalData.length === 0) {
          this.exportLoading = false;
          return this.$message.warning('Report data is empty and cannot be exported.')
        }
        let query = this.$route.query;
        param = {
          templatesId: this.filterParam.templatesId.id,
        }
        this.mainLanguage = this.filterParam.templatesId.mainLanguage;
        this.subLanguage = this.filterParam.templatesId.subLanguage;

        if (query.min) {
          param.min = query.min;
          param.max = query.max;
        }

        for (let i in this.filterParam) {
          if (i !== 'templatesId')
            param[i] = this.filterParam[i];
        }
        ExportReport(param).then(res => {
          let blob = new Blob([res]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          let myEvent = new MouseEvent('click')
          a.download = `${data.name}.xlsx`;
          a.href = url
          a.dispatchEvent(myEvent)

          this.$message.success(this.$i18n.t('message.export'))
          this.exportLoading = false;
        }).catch(error => {
          this.exportLoading = false;
        })
      } else if (data.format === 'PDF') {
        // window.print()
        let logo = this.$cookies.get('logo');
        let baseUrl = process.env.VUE_APP_BASE_URL;

        param = {
          limit: this.page.size,
          page: this.page.current,
          templatesId: this.filterParam.templatesId.id,
          sorts: this.sortParam,

          startDate: '',
          endDate: '',
          orientation: data.orientation,
          showChart: data.showChart,
          logoUrl: !!logo ? `${baseUrl}/reportTemplatesChartType/getImage.do?imageId=${logo}` : '',
        }

        for (let i in this.filterParam) {
          if (i !== 'templatesId')
            param[i] = this.filterParam[i];
        }

        let date = this.filterParam.parameters.find(item => item.type === 'date');
        if (!!date) {
          let dateValue = date.value.split('~');
          param.startDate = dateValue[0];
          param.endDate = dateValue[1];
        }
        ExportReportPDF(param).then(res => {
          let blob = new Blob([res]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          let myEvent = new MouseEvent('click')
          a.download = `${data.name}.pdf`;
          a.href = url
          a.dispatchEvent(myEvent)
          this.exportLoading = false;
        })
      } else {
        domtoimage.toPng(this.$refs.wrap).then((dataUrl) => {
          let a = document.createElement('a');
          let myEvent = new MouseEvent('click')
          a.download = `${data.name}.${formats[data.format]}`;
          a.href = dataUrl;
          a.dispatchEvent(myEvent)

          this.$message.success(this.$i18n.t('message.export'))
          this.exportLoading = false;
        })
      }
    },
    // 加载表格数据
    loadTableData () {
      if (!this.reportTemplateId) return;
      if (!this.loadStatus) {
        this.page.current++;
        this.getTableData({});
      }
      this.loadStatus = !this.loadStatus;
    },
    // 线下单列排序 
    sortColumn (prop) {
      let temp = JSON.parse(JSON.stringify(this.sortState));
      for (var i in temp) temp[i] = 0;
      temp[prop] = (this.sortState[prop] + 1) % 3
      this.sortState = temp;

      switch (temp[prop]) {
        case 0:
          this.$refs.plTable[0].clearSort();
          break;
        case 1:
          this.$refs.plTable[0].sort(prop, 'ascending');
          break;
        case 2:
          this.$refs.plTable[0].sort(prop, 'descending');
          break;
      }
    },
    // 显示多列排序弹框
    showSort () {
      this.SortDialogVisible = true;
    },
    // 线下多列排序
    submitSort (sortData) {
      this.$refs.plTable[0].clearSort();
      this.tableSort = {
        prop: '',
        order: ''
      };

      if (sortData.length === 0) {
        this.$refs.plTable[0].reloadData(this.tableLocalData)
      } else {
        let data = JSON.parse(JSON.stringify(this.tableLocalData));
        let result = this.sortTableData(data, sortData, 0);
        let res = [];
        result.forEach(item => {
          res.push(...item.data)
        })
        this.$refs.plTable[0].reloadData(res)

        // 修改排序状态
        let temp = JSON.parse(JSON.stringify(this.sortState));
        for (var i in temp) temp[i] = 0;

        sortData.forEach(item => {
          temp[item.key] = item.sort === 'DESC' ? 2 : 1;
        })
        this.sortState = temp;
      }
    },
    sortTableData (data, sortRuleArr, index) {
      let sortRule = sortRuleArr[index++]
      let obj = [];
      data.forEach(item => {
        if (!obj[item[sortRule.key]]) {
          obj[item[sortRule.key]] = [item];
        } else {
          obj[item[sortRule.key]].push(item);
        }
      })
      let temp = [];
      for (let i in obj) {
        temp.push({
          val: i,
          data: obj[i]
        })
      }

      if (sortRule.sort == 'DESC') {
        temp.sort((a, b) => {
          return b.val - a.val
        })
      } else {
        temp.sort((a, b) => {
          return a.val - b.val
        })
      }
      let result = [];
      if (sortRuleArr.length > index) {
        temp.forEach(item => {
          result.push(...this.sortTableData(item.data, sortRuleArr, index))
        })
      } else {
        result = temp
      }
      return result;
    },
    // 格式化金额
    amountFormat (row, column) {
      if (this.moneyProp.includes(column.property)) {
        return Number(row[column.property]).toFixed(2);
      } else {
        return row[column.property]
      }
    },
    // 图表设置数据
    setLayoutData (data) {
      this.layoutData = this.layoutData.map(item => {
        if (!item.isTable) {
          item.dataOrigin = {
            data: {
              data: data
            },
            header: this.tableHeight
          };
        }
        return item;
      })
      this.$refs.chartCard && this.$refs.chartCard.forEach(item => {
        let index = this.layoutData.findIndex(layout => layout.i === item.data.i);
        let layout = this.layoutData[index];
        if (!!layout.data.templateId) {
          item.initData()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  background: #f2f7fb;
}
.title {
  height: 40px;
  padding: 5px 10px;
  // border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tooltip {
    color: #535353;
    right: 10px;
    top: 5px;
    cursor: pointer;
    user-select: none;
    .btn {
      border-radius: 5px;
      &:hover {
        background: #b4bccc;
        color: #fff;
      }
    }
  }
  .title-left {
    height: 30px;
    display: flex;
    align-items: center;
  }
  .load-btn {
    font-size: 24px;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    &.blue {
      color: #409eff;
    }
  }
  .table-total {
    font-size: 13px;
  }
}

.table-wrap {
  height: calc(100% - 24px);
  ::v-deep .el-table__expand-icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  .label {
    width: calc(100% - 20px);
    line-height: 25px;
    padding-left: 0;
  }
}

.collapse {
  height: 35px !important;
}

// 布局
.layout-box {
  height: calc(100% - 60px);
}
.chart-card {
  height: 100%;

  ::v-deep .el-card__body {
    height: 100%;
    padding: 0;
    .chart {
      height: calc(100% - 25px);
    }
  }
}
.layout-type0 {
  .layout-item {
    height: 100%;
  }
}
.layout-type1 {
  .layout-item:nth-of-type(1),
  .layout-item:nth-of-type(2) {
    width: 100%;
    height: calc((100% - 10px) / 2);
    margin-bottom: 10px;
  }
}
.layout-type2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .layout-item:nth-of-type(1),
  .layout-item:nth-of-type(2) {
    width: calc(50% - 5px);
    height: calc((100% - 10px) / 2);
  }
  .layout-item:nth-of-type(3) {
    width: 100%;
    height: calc((100% - 10px) / 2);
  }
}
::v-deep .is-center ::v-deep .cell {
  text-align: center !important;
}

.order-card {
  width: 360px;
  position: fixed;
  z-index: 999;
}

.layout-box {
  height: calc(100% - 50px);
  margin-left: -10px;
  margin-right: -10px;
}
.con {
  height: calc(100% - 40px);
  overflow: hidden;
}
.table-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0;
  cursor: pointer;
}
// .con {
//   ::v-deep th .cell {
//     padding: 0;
//     display: flex;
//     flex-direction: row-reverse;
//     justify-content: flex-end;
//     .caret-wrapper {
//       margin-right: 10px;
//     }
//   }
// }

.sort-btn {
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  margin-right: 10px;
  text-align: center;
  // cursor: pointer;
  font-size: 15px;
  .icon-sort-down,
  .icon-sort-up {
    color: #409eff;
    font-size: 20px;
    line-height: 25px;
  }
}
</style>