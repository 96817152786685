<template>
  <div>
    <el-dialog
      :modal-append-to-body="false"
      title="Custom Sort"
      :visible.sync="dialog.visible"
      width="700px"
      center
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-button
              size="medium"
              @click="showAdd"
            >{{$t('operation.add')}}</el-button>
            <el-button
              size="medium"
              @click="delItem"
            >{{$t('operation.del')}}</el-button>
            <el-button
              size="medium"
              type="drang"
              @click="reset"
            >{{$t('operation.reset')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-table
        border
        :data="tableData"
        :row-class-name="tableRowClassName"
        @selection-change="val => tableSelected = val"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          label="Field"
          prop="key"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.key"
              size="medium"
            >
              <el-option
                v-for="item in fields"
                :key="item.id"
                :label="item.mainFieText"
                :value="item.dbKey"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="Order"
          prop="sort"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.sort"
              size="medium"
            >
              <el-option
                label="A to Z"
                value="ASC"
              ></el-option>
              <el-option
                label="Z to A"
                value="DESC"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['visible', 'templateFields'],
  data () {
    return {
      dialog: {
        visible: false,
      },
      tableSelected: [],
      tableData: [],
      fields: [],
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;

        this.fields = this.templateFields.filter(item => item.fieIdType === 0)
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  methods: {
    showAdd () {
      this.tableData.push({
        key: '',
        sort: 'ASC'
      })
    },
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      let checkIndex = this.tableSelected.map(item => item.rowIndex);
      this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
    },
    reset () {
      this.tableData = [];
      this.submit();
    },
    submit () {
      this.$emit('submit', this.tableData)
      this.dialog.visible = false;
    },
    resetData () {
      this.tableData = [];
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
  },
}
</script>

<style>
</style>