<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            :disabled="!organic"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @row-click="rowClick"
        @row-dblclick="dblclickEdit"
        @selection-change="val => tableSelected = val"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="申购人"
          prop="purchaseUser"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="出库人"
          prop="outUser"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="入库日期"
          prop="inDate"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="出库日期"
          prop="outDate"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="生产日期"
          prop="productionDate"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="厂家质保期"
          prop="warrantyPeriod"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <purchase-records-dialog
      :visible.sync="PurchaseRecordsDialogVisible"
      :data="purchaseRecordsData"
      :device-id="deviceId"
      @update="getTableData"
    ></purchase-records-dialog>
  </div>
</template>

<script>
// 组件
import PurchaseRecordsDialog from './PurchaseRecordsDialog'
// 接口
import {
  GetPurchaseRecordList,
  DelPurchaseRecord,
} from '@/api/warehouse/deviceManagement'
export default {
  components: {
    PurchaseRecordsDialog,
  },
  props: ['data', 'organic', 'active', 'deviceId'],
  data () {
    return {
      PurchaseRecordsDialogVisible: false,
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      purchaseRecordsData: null,
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'PurchaseRecords') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
      }
    }
  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData () {
      if (!this.deviceId) return;

      let param = {
        limit: 9999,
        page: 1,
        search: '',
        deviceId: this.deviceId,
      }
      this.tableLoading = true;
      GetPurchaseRecordList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;

      }).catch(error => {
        this.tableLoading = false;
      })
    },
    showAdd () {
      this.purchaseRecordsData = null;
      this.PurchaseRecordsDialogVisible = true;
    },
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        // let checkIndex = this.tableSelected.map(item => item.rowIndex);
        // this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelPurchaseRecord(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.purchaseRecordsData = e;
      this.PurchaseRecordsDialogVisible = true;
    },

    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: calc(100% - 46px);
}
</style>