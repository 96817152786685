<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="user-form"
      >
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col1')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.deviceType')"
          prop="type"
        >
          <el-select
            v-model="dialog.type"
            filterable
          >
            <el-option
              v-for="(item,index) in types"
              :key="index"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col2')"
          prop="brandId"
        >
          <my-select
            :data="brands"
            :value.sync="dialog.brandId"
            size="small"
            :prop="{
              label:'name',
              value:'id'
            }"
          ></my-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col3')"
          prop="model"
        >
          <el-input v-model="dialog.model"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col4')"
          prop="supplierId"
        >
          <el-select
            v-model="dialog.supplierId"
            multiple
            filterable
            collapse-tags
            @visible-change="visibleChange"
          >
            <el-option
              v-for="(item,index) in suppliers"
              :key="index"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col7')"
          prop="unit"
        >
          <my-select
            :data="units"
            :value.sync="dialog.unit"
            size="small"
            :prop="{
              label:'name',
              value:'id'
            }"
          ></my-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col8')"
          prop="status"
        >
          <el-select v-model="dialog.status">
            <el-option
              :value="1"
              label="Valid"
            ></el-option>
            <el-option
              :value="0"
              label="Invalid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.deviceType.col9')"
          prop="sync"
        >
          <el-select v-model="dialog.sync">
            <el-option
              :label="$t('dataMaintenance.deviceType.option1')"
              :value="1"
            ></el-option>
            <el-option
              :label="$t('dataMaintenance.deviceType.option2')"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'

// 接口
import { AddDevice } from '@/api/warehouse/dataMaintenance/deviceType'
import {
  GetDataFieldAll,
} from '@/api/warehouse/dataMaintenance'
export default {
  components: {
    MySelect
  },
  props: ['visible', 'data'],
  data () {
    return {
      types: [],
      brands: [],
      suppliers: [],
      units: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
        type: '',
        brandName: '',
        brandId: '',
        model: '',
        supplierName: '',
        supplierId: [],
        unit: '',
        sync: 1,
        status: 1,
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.deviceType.col1'), trigger: 'blur' }],
        type: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.deviceType.deviceType'), trigger: 'blur' }],
        brandName: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.deviceType.col2'), trigger: 'blur' }],
        model: [
          { required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.deviceType.col3'), trigger: 'blur' },
          { max: 64, message: 'A maximum of 64 characters', trigger: 'blur' }
        ],
        // supplierName: [{ required: true, message: this.$i18n.t('message.please') + this.$i18n.t('dataMaintenance.col4'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('dataMaintenance.deviceType.title2'),
              name: this.data.name,
              type: this.data.deviceTypeId,
              brandName: this.data.brandName,
              brandId: this.data.brandId,
              model: this.data.model,
              supplierName: this.data.supplierName,
              supplierId: !!this.data.supplierId ? this.data.supplierId.split(',') : [],
              unit: this.data.unit,
              sync: this.data.isOutSync,
              status: this.data.status,
            })
            // this.brands = [{ name: this.data.brandName, id: this.data.brandId }];
            // this.suppliers = [{ name: this.data.supplierName, id: this.data.supplierId }];

            this.sortSupplier();

          })
        } else {
          this.dialog.title = this.$i18n.t('dataMaintenance.deviceType.title1')
        }
        // 获取 Item Types
        // if (this.types.length === 0)
        GetDataFieldAll({ parent: '100000000000000000000000000000000001' }).then(res => this.types = res)
        // 获取品牌
        // if (this.brands.length === 0)
        GetDataFieldAll({ parent: '100000000000000000000000000000000002' }).then(res => this.brands = res)
        // 获取供应商
        // if (this.suppliers.length === 0)
        GetDataFieldAll({ parent: '100000000000000000000000000000000003' }).then(res => {
          this.suppliers = res
          this.sortSupplier();
        })
        // 获取单位
        // if (this.units.length === 0)
        GetDataFieldAll({ parent: '100000000000000000000000000000000011' }).then(res => this.units = res)

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    // 供应商提交
    supplierSubmit (data) {
      this.dialog.supplierName = data.name;
      this.dialog.supplierId = data.id
    },
    // 添加或修改
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.dialog.name,
          deviceTypeId: this.dialog.type,
          model: this.dialog.model,
          supplierId: this.dialog.supplierId.join(','),
          isOutSync: this.dialog.sync,
          status: this.dialog.status,
        }
        // 验证是否是新品牌
        if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.brandId) || this.dialog.brandId.length === 36) {
          param.brandId = this.dialog.brandId;
        } else {
          param.brandName = this.dialog.brandId;
          param.brandId = '';
        }
        // 验证是否是新供应商
        // if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.supplierId) || this.dialog.supplierId.length === 36) {
        //   param.supplierId = this.dialog.supplierId;
        // } else {
        //   param.supplierName = this.dialog.supplierId;
        //   param.supplierId = '';
        // }
        // 验证是否是新单位
        if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.unit)) {
          param.unitId = this.dialog.unit;
        } else {
          param.unit = this.dialog.unit;
          param.unitId = '';
        }
        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }
        this.dialog.loading = true;
        AddDevice(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update', true)
          } else {
            if (res.status === 100) this.$message.warning('User account is occupied.')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      })
    },
    visibleChange (e) {
      if (e) return;

      this.sortSupplier();

    },
    sortSupplier () {
      this.suppliers = this.suppliers.sort((a, b) => {
        if (this.dialog.supplierId.includes(a.id)) {
          return -1
        } else {
          return 0
        }
      })
      
      // let before = [];
      // this.suppliers = this.suppliers.filter(item => {
      //   if (this.dialog.supplierId.includes(item.id)) {
      //     before.push(item);
      //     return false;
      //   } else {
      //     return true;
      //   }
      // })
      // this.suppliers = before.concat(this.suppliers);
    }

  },

}
</script>

<style lang="scss" scoped>
.user-form {
  .el-switch,
  .el-autocomplete,
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
}
</style>