<template>
  <div class="wrap">
    <el-card>
      <page-header title="New Risk of Loss Level Templates">
        <el-button
          size="medium"
          @click="back"
        >Cancle</el-button>
        <el-button size="medium">Reset</el-button>
        <el-button
          :disabled="!permit.new"
          size="medium"
          @click="showSave"
        >Save</el-button>
        <el-button
          :disabled="!permit.submit"
          size="medium"
          type="primary"
          @click="submit"
        >Submit</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item
              label="Templeates Name"
              prop="name"
            >
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Brand">
              <el-select v-model="groupForm.brand">
                <el-option
                  v-for="item in brands"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row class="search-box">
          <el-input
            size="medium"
            class="search-input"
            placeholder="Search"
          ></el-input>
        </el-row> -->
        <el-row>
          <el-table border            :data="tableData"
            class="table-wrap mode-table"
          >
            <el-table-column
              label="Report Templates"
              prop="templatesName"
            ></el-table-column>
            <el-table-column
              label="Main Fields"
              prop="mainFieText"
            ></el-table-column>
            <el-table-column
              label="Sub Fields"
              prop="subFieldText"
            ></el-table-column>
            <el-table-column
              label="Measure"
              prop="measure"
            ></el-table-column>
            <el-table-column>
              <template
                slot="header"
                slot-scope="scope"
              >
                <div class="color-item">
                  <span>Level1</span>
                  <el-color-picker
                    :disabled="!permit.color"
                    v-model="levelColor1"
                    size="medium"
                  ></el-color-picker>
                </div>
              </template>
              <el-table-column
                label="Min"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.minL1"
                    readonly
                    @dblclick.native="dblclickInput"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="Max"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.maxL1"
                    readonly
                    @dblclick.native="dblclickInput"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column width="36">
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <el-checkbox
                    @change="allChecked(1)"
                    v-model="isCheckL1"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isCheckL1"
                    @change="checkboxChange(1,scope.row.isCheckL1)"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column>
              <template
                slot="header"
                slot-scope="scope"
              >
                <div class="color-item">
                  <span>Level2</span>
                  <el-color-picker
                    :disabled="!permit.color"
                    v-model="levelColor2"
                    size="medium"
                  ></el-color-picker>
                </div>
              </template>
              <el-table-column
                label="Min"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.minL2"
                    readonly
                    @dblclick.native="dblclickInput"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="Max"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.maxL2"
                    readonly
                    @dblclick.native="dblclickInput"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column width="36">
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <el-checkbox
                    @change="allChecked(2)"
                    v-model="isCheckL2"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isCheckL2"
                    @change="checkboxChange(2,scope.row.isCheckL2)"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column>
              <template
                slot="header"
                slot-scope="scope"
              >
                <div class="color-item">
                  <span>Level3</span>
                  <el-color-picker
                    :disabled="!permit.color"
                    v-model="levelColor3"
                    size="medium"
                  ></el-color-picker>
                </div>
              </template>
              <el-table-column
                label="Min"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.minL3"
                    readonly
                    @dblclick.native="dblclickInput"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="Max"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.maxL3"
                    readonly
                    @dblclick.native="dblclickInput"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column width="36">
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <el-checkbox
                    @change="allChecked(3)"
                    v-model="isCheckL3"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isCheckL3"
                    @change="checkboxChange(3,scope.row.isCheckL3)"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Description">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.descirption"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
    </el-card>

    <!-- 保存弹框 -->
    <save-templates :visible.sync="SaveTemplatesVisible"></save-templates>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import SaveTemplates from './components/SaveTemplates'

// 接口
import {
  GetBrandAll,
} from '@/api/organizations'
import {
  AddLossLeve,
  GetLeveListItem,
  GetAddHeader,
} from '@/api/riskOfLossLevel'
export default {
  name: 'NewRiskOfLossLevel',
  components: {
    PageHeader,
    SaveTemplates,
  },
  data () {
    return {
      viewType: null,
      permit: {
        new: true,
        submit: true,
        color: false,
        number: true,
      },
      SaveTemplatesVisible: false,
      dataAccessGroups: [],
      brands: [],
      groupForm: {
        id: null,
        name: '',
        dataAccessGroup: localStorage.accessGroupId,
        brand: '',
        descirption: '',
        isDafault: 1,
        isDefaultLock: false,
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Report Templeates Name.', trigger: 'blur' }]
      },
      levelColor1: '#FF0000',
      levelColor2: '#FF6600',
      levelColor3: '#0099FF',
      isCheckL1: 0,
      isCheckL2: 0,
      isCheckL3: 0,
      tableData: [],
      saveDialog: {
        visible: false,
        name: '',
        description: '',
        dataAccessGroup: '',
        isDefault: true,
      }
    }
  },
  created () {
    this.viewType = this.$route.query.type;
    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.getItem();
    } else {
      this.isCheckL1 = 1;
      this.isCheckL2 = 1;
      this.isCheckL3 = 1;
      GetAddHeader().then(res => this.tableData = res.levelValueList.map(item => {
        item.isCheckL1 = 1;
        item.isCheckL2 = 1;
        item.isCheckL3 = 1;
        return item;
      }))
    }
    // 获取品牌集合
    GetBrandAll().then(res => this.brands = res)
    this.dealPermission();

  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },

    // 通过id获取级别模板
    getItem () {
      let param = {
        id: this.groupForm.id
      }
      GetLeveListItem(param).then(res => {
        Object.assign(this.groupForm, {
          id: res.id,
          name: res.name,
          dataAccessGroup: res.accessGroupId,
          descirption: res.descirption,
          isDafault: res.setAsDefault,
          brand: res.storesId,
        })
        this.tableData = res.levelValueList;
        this.isCheckL1 = Number(this.tableData.every(item => item.isCheckL1));
        this.isCheckL2 = Number(this.tableData.every(item => item.isCheckL2));
        this.isCheckL3 = Number(this.tableData.every(item => item.isCheckL3));
        if (this.tableData.length > 0) {
          this.levelColor1 = this.tableData[0].colorL1;
          this.levelColor2 = this.tableData[0].colorL2;
          this.levelColor3 = this.tableData[0].colorL3;
        }
      })
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.groupForm.name,
          accessGroupId: this.groupForm.dataAccessGroup,
          storesId: this.groupForm.brand,
          descirption: this.groupForm.descirption,
          setAsDefault: this.groupForm.isDafault,
          levelValueList: [],
        }
        this.tableData.forEach(item => {
          param.levelValueList.push({
            reportDesignerId: item.id,
            descirption: item.descirption,
            isCheckL1: item.isCheckL1,
            isCheckL2: item.isCheckL2,
            isCheckL3: item.isCheckL3,
            colorL1: this.levelColor1,
            colorL2: this.levelColor2,
            colorL3: this.levelColor3,
            minL1: item.minL1,
            minL2: item.minL2,
            minL3: item.minL3,
            maxL1: item.maxL1,
            maxL2: item.maxL2,
            maxL3: item.maxL3,
          })
        })
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddLossLeve(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          } else {
            if (res.status === 100) this.$message.warning('Templeates Name is occupied.')
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 复选框处理
    allChecked (level) {
      var key = 'isCheckL' + level;
      this[key] ? this.tableData.forEach(item => item[key] = 1) : this.tableData.forEach(item => item[key] = 0);
    },
    checkboxChange (level, state) {
      var key = 'isCheckL' + level;
      if (state) {
        this.tableData.some(item => item[key] !== 1) ? '' : (this[key] = 1);
      } else {
        this.tableData.some(item => item[key] === 0) ? (this[key] = 0) : '';
      }
    },
    // 双击编辑
    dblclickInput (e) {
      if(!this.permit.number) return;
      let el = e.target;
      if (el.readOnly) {
        el.readOnly = false;
        el.blur();
        el.focus();
      }
    },
    showSave () {
      this.SaveTemplatesVisible = true;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        switch (this.viewType) {
          case '1': //dashBoard
            // this.permit.new = localStorage.permission.includes('5881445c-a7d5-4e0a-90ad-90912d74ed0b')
            // this.permit.submit = localStorage.permission.includes('62220afa-cc53-4490-b9b1-1da30093d291')
            // this.permit.color = localStorage.permission.includes('77e161a8-1e45-4af3-9cf1-341e3af85c1b')
            // this.permit.number = localStorage.permission.includes('76796eec-6070-4fcd-affa-319bcdfdb7e7')
            break;
          case '2': //counterMode
            break;
          case '3': //diningMode
            break;
        }
        // this.permit.paw = localStorage.permission.includes('39948e81-a9a5-4e39-b703-c4b709b00c41')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .el-select {
    width: 100%;
  }
  .describe {
    ::v-deep .el-textarea__inner {
      height: 132px;
    }
  }
  .switch-box {
    margin-top: 58px;
    .el-switch {
      margin-right: 30px;
    }
  }
  .search-box {
    display: flex;
    .search-input {
      width: 400px;
      margin-right: 50px;
    }
    .color-item {
      margin-right: 50px;
      height: 36px;
      display: flex;
      align-items: center;
      span {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  .table-wrap {
    ::v-deep td {
      border-bottom: 1px solid #cfe4f8 !important;
    }
    .color-item {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      ::v-deep .el-color-picker__mask{
        margin-left: 10px;
      }
      ::v-deep .el-color-picker__trigger {
        border: none;
        padding: 4px;
      }
    }
    .cell {
      padding: 0;
    }
    .el-input {
      width: 100%;
      ::v-deep .el-input__inner {
        text-align: center;
        border: none;
        background: none;
      }
    }
    // .is-disabled ::v-deep .el-input__inner {
    //   color: #606266;
    //   background-color: #fff;
    //   cursor: default;
    // }
  }
}
</style>