<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog3.label1')"
          prop="reasonId"
        >
          <el-select v-model="dialog.reasonId">
            <el-option
              v-for="item in reasonReplaces"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('newServiceConfirmationForm.dialog3.label2')">
          <div class="label-wrap">
            <div class="add-box">
              <el-button
                icon="el-icon-plus"
                @click="IssueKeywordVisible = true"
              ></el-button>
            </div>
            <div class="tag-box">
              <el-tag
                v-for="(item,index) in tags"
                :key="index"
                closable
                @close="tagClose(item)"
              >
                {{item.description}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog3.label3')"
          prop="faultDescription"
        >
          <el-input
            type="textarea"
            v-model="dialog.faultDescription"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('newServiceConfirmationForm.dialog3.label4')"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dialog.remark"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label5')"
              prop="oldDeviceName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.oldDeviceName"
                  class="search-input"
                  @change="oldDeviceChange"
                ></el-input>
                <el-button @click="searchData('old')">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label6')"
              prop="oldInstallationSite"
            >
              <!-- <el-input
                v-model="dialog.oldInstallationSite"
                @change="oldDeviceChange"
              ></el-input> -->

              <my-select
                :data="locations"
                :value.sync="dialog.oldInstallationSite"
                size="small"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="oldDeviceChange"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label7')"
              prop="oldDeviceInfoModel"
            >
              <el-input
                v-model="dialog.oldDeviceInfoModel"
                @change="oldDeviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label8')"
              prop="oldSerialNumber"
            >
              <el-input
                v-model="dialog.oldSerialNumber"
                @change="oldDeviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label9')"
              prop="oldQrcode"
            >
              <el-input
                v-model="dialog.oldQrcode"
                @change="oldQrcodeChange"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label10')"
              prop="oldLabel"
            >
              <el-input
                v-model="dialog.oldLabel"
                @change="oldDeviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label11')"
              prop="deviceTo"
            >
              <el-input v-model="dialog.deviceTo"></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label12')"
              prop="attributable"
            >
              <el-select v-model="dialog.attributable">
                <el-option
                  :value="0"
                  :label="$t('newServiceConfirmationForm.dialog3.option1')"
                ></el-option>
                <el-option
                  :value="1"
                  :label="$t('newServiceConfirmationForm.dialog3.option2')"
                ></el-option>
                <el-option
                  :value="2"
                  :label="$t('newServiceConfirmationForm.dialog3.option3')"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label13')"
              prop="deviceInfoName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.deviceInfoName"
                  class="search-input"
                  @change="newDeviceChange"
                ></el-input>
                <el-button @click="searchData('new')">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label14')"
              prop="installationSite"
            >
              <!-- <el-input
                v-model="dialog.installationSite"
                @change="newDeviceChange"
              ></el-input> -->
              <my-select
                :data="locations"
                :value.sync="dialog.installationSite"
                size="small"
                :prop="{
                  label:'name',
                  value:'name'
                }"
                @change="newDeviceChange"
              ></my-select>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label15')"
              prop="deviceInfoModel"
            >
              <el-input
                v-model="dialog.deviceInfoModel"
                @change="newDeviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label16')"
              prop="newSerialNumber"
            >
              <el-input
                v-model="dialog.newSerialNumber"
                @change="newDeviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label17')"
              prop="newQrcode"
            >
              <el-input
                v-model="dialog.newQrcode"
                @change="newQrcodeChange"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label18')"
              prop="newLabel"
            >
              <el-input
                v-model="dialog.newLabel"
                @change="newDeviceChange"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newServiceConfirmationForm.dialog3.label19')"
              prop="deviceSupplier"
            >
              <el-input v-model="dialog.deviceSupplier"></el-input>
            </el-form-item>
            <el-form-item :label="$t('newServiceConfirmationForm.dialog3.label20')">
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.referenceNo"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showNumber(1)">{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item :label="$t('newServiceConfirmationForm.dialog3.label21')">
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.quotationNo"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showNumber(2)">{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item :label="$t('newServiceConfirmationForm.dialog3.label22')">
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.purchaseNo"
                  class="search-input"
                  readonly
                ></el-input>
                <el-button @click="showNumber(3)">{{$t('operation.edit')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          @click="copySubmit"
        >{{$t('newServiceConfirmationForm.dialog3.btn1')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :branch-id="branchId"
      @submit="equipmentSubmit"
    ></equipment-dialog>
    <!-- 编号弹框 -->
    <number-dialog
      :visible.sync="NumberDialogVisble"
      :data="numberData"
      :type="numberType"
      :branch-id="branchId"
      @submit="numberSubmit"
    ></number-dialog>
    <!--  -->
    <issue-keyword
      :visible.sync="IssueKeywordVisible"
      :tags-data="tags"
      :describe="describe"
      :dispose="dispose"
      @submit="issueSubmit"
    ></issue-keyword>
  </div>
</template>

<script>
// 组件
import EquipmentDialog from './EquipmentDialog'
import NumberDialog from '@/pages/warehouse/submitRequest/components/NumberDialog'
import IssueKeyword from './IssueKeyword'
import MySelect from '@/components/MySelect'

// 接口
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import { GetLocationAll } from '@/api/warehouse/deviceManagement'

export default {
  components: {
    EquipmentDialog,
    NumberDialog,
    IssueKeyword,
    MySelect
  },
  props: ['visible', 'data', 'describe', 'dispose', 'isNext', 'branchId', 'tagsData'],
  data () {
    return {
      EquipmentDialogVisible: false,
      NumberDialogVisble: false,
      IssueKeywordVisible: false,
      tags: [],
      reasonReplaces: [],
      locations: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        oldDeviceId: '',
        oldDeviceName: '',
        oldQrcode: '',
        oldSerialNumber: '',
        newQrcode: '',
        newSerialNumber: '',
        deviceInfoName: '',
        installationSite: '',
        deviceSupplier: '',
        deviceInfoId: '',
        faultDescription: '',
        remark: '',
        attributable: 0,
        whereabouts: '',
        reasonId: '',
        deviceTo: '',
        deviceTypeName: '',
        deviceInfoModel: '',
        oldDeviceTypeName: '',
        oldDeviceInfoModel: '',
        oldInstallationSite: '',
        oldLabel: '',
        newLabel: '',
        referenceId: '',
        quotationId: '',
        purchaseId: '',
        referenceNo: '',
        quotationNo: '',
        purchaseNo: '',
      },
      rules: {
        reasonId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        oldDeviceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceInfoName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        installationSite: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        oldInstallationSite: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      faultTypeName: '',
      submitType: null,
      numberType: null,
      numberData: null,
      reference: [],
      quotation: [],
      purchase: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog3.title1');
        this.tags = [];
        this.reference = [];
        this.quotation = [];
        this.purchase = [];
        if (this.data) {
          this.$nextTick(() => {
            if (!this.isNext) {
              Object.assign(this.dialog, this.data)
              this.dialog.title = this.$i18n.t('newServiceConfirmationForm.dialog3.title1') + ` - ${this.data.rowIndex + 1}`;
              this.tags = !!this.data.faultTypeItemList ? JSON.parse(JSON.stringify(this.data.faultTypeItemList)) : [];
              let referenceId = !!this.dialog.referenceId ? this.dialog.referenceId.split(',') : [];
              let quotationId = !!this.dialog.quotationId ? this.dialog.quotationId.split(',') : [];
              let purchaseId = !!this.dialog.purchaseId ? this.dialog.purchaseId.split(',') : [];
              let referenceNo = !!this.dialog.referenceNo ? this.dialog.referenceNo.split(',') : [];
              let quotationNo = !!this.dialog.quotationNo ? this.dialog.quotationNo.split(',') : [];
              let purchaseNo = !!this.dialog.purchaseNo ? this.dialog.purchaseNo.split(',') : [];
              referenceId.forEach((item, index) => {
                this.reference.push({
                  name: referenceNo[index],
                  id: item,
                })
              })
              quotationId.forEach((item, index) => {
                this.quotation.push({
                  name: quotationNo[index],
                  id: item,
                })
              })
              purchaseId.forEach((item, index) => {
                this.purchase.push({
                  name: purchaseNo[index],
                  id: item,
                })
              })
            } else {
              this.tags = this.tagsData;
              Object.assign(this.dialog, {
                oldDeviceId: this.data.deviceTypeId,
                oldQrcode: this.data.qrCode,
                oldSerialNumber: this.data.serialNumber,
                oldDeviceName: this.data.deviceInfoName,
                oldDeviceTypeName: this.data.deviceTypeName,
                oldDeviceInfoModel: this.data.deviceInfoModel,
                oldLabel: this.data.installationNumber,
                oldInstallationSite: this.data.installationSite,
                faultDescription: this.data.faultDescription,
                remark: this.data.handleMethod,
              })
            }
          })
        } else {
          this.dialog.faultDescription = sessionStorage.faultDescription;
          this.dialog.remark = sessionStorage.handleMethod;
        }

        // 获取替换原因
        if (this.reasonReplaces) {
          GetDataFieldAll({ parent: '100000000000000000000000000000000022' }).then(res => this.reasonReplaces = res)
        }
        // 获取设备位置
        GetLocationAll({ branchId: this.branchId }).then(res => this.locations = res.map(item => Object.assign({ name: item })));

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },

    'reference': {
      handler: function () {
        this.dialog.referenceId = this.reference.map(item => item.id).join(',');
        this.dialog.referenceNo = this.reference.map(item => item.name || item.id).join(',');
      },
      deep: true
    },
    'quotation': {
      handler: function () {
        this.dialog.quotationId = this.quotation.map(item => item.id).join(',');
        this.dialog.quotationNo = this.quotation.map(item => item.name || item.id).join(',');
      },
      deep: true
    },
    'purchase': {
      handler: function () {
        this.dialog.purchaseId = this.purchase.map(item => item.id).join(',');
        this.dialog.purchaseNo = this.purchase.map(item => item.name || item.id).join(',');
      },
      deep: true
    },
  },
  created () {

  },
  methods: {
    searchData (type) {
      this.submitType = type;
      this.EquipmentDialogVisible = true;
    },
    // 设备提交
    equipmentSubmit (data) {
      if (this.submitType === 'old') {
        Object.assign(this.dialog, {
          oldDeviceId: data.id,
          oldDeviceName: data.deviceInfoName,
          oldQrcode: data.qrCode,
          oldSerialNumber: data.serialNumber,
          oldDeviceTypeName: data.deviceTypeName,
          oldDeviceInfoModel: data.deviceInfoModel,
          oldInstallationSite: data.location,
          oldLabel: data.label,
        })
        this.$refs.dialogForm.validateField('oldDeviceName');
      } else {
        Object.assign(this.dialog, {
          deviceInfoId: data.id,
          deviceInfoName: data.deviceInfoName,
          newQrcode: data.qrCode,
          newSerialNumber: data.serialNumber,
          deviceTypeName: data.deviceTypeName,
          deviceInfoModel: data.deviceInfoModel,
          installationSite: data.location,
          newLabel: data.label,
          deviceSupplier: data.supplierName,
        })
        this.$refs.dialogForm.validateField('deviceInfoName');
      }

    },
    // 提交
    submit (param) {
      if (this.tags.length === 0 && (this.dialog.reasonId === '220000000000000000000000000000000002' || this.dialog.reasonId === '220000000000000000000000000000000001'))
        return this.$message.warning('Issue Keyword not null');

      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        this.dialog.faultTypeItemList = this.tags;
        this.dialog.faultTypeId = this.tags.map(item => item.id ? item.id : item.description).join(',');
        this.dialog.faultTypeName = this.tags.map(item => item.description).join('、');
        this.$emit('submit', {
          data: JSON.parse(JSON.stringify(this.dialog)),
          type: this.data ? 'edit' : 'add',
          copy: param.copy
        });
        sessionStorage.faultDescription = this.dialog.faultDescription;
        sessionStorage.handleMethod = this.dialog.remark;
        this.dialog.visible = false;
      });
    },
    // 复制
    copySubmit () {
      this.submit({ copy: true })
    },
    // 关闭故障标签
    tagClose (tag) {
      this.tags.splice(this.tags.findIndex(item => item.id === tag.id), 1);
    },
    // 修改设备信息，清空设备id 
    oldDeviceChange () {
      this.dialog.oldDeviceId = '';
      this.dialog.oldQrcode = '';
    },
    newDeviceChange () {
      this.dialog.deviceInfoId = '';
      this.dialog.newQrcode = '';
    },
    oldQrcodeChange () {
      this.dialog.oldDeviceId = '';
    },
    newQrcodeChange () {
      this.dialog.deviceInfoId = '';
    },
    // 显示编号弹框
    showNumber (type) {
      switch (type) {
        case 1:
          this.numberData = this.reference;
          break;
        case 2:
          this.numberData = this.quotation;
          break;
        case 3:
          this.numberData = this.purchase;
          break;
      }
      this.numberType = type;
      this.NumberDialogVisble = true;
    },
    // 提交编号
    numberSubmit (type, data) {
      switch (type) {
        case 1:
          this.reference = data;
          break;
        case 2:
          this.quotation = data;
          break;
        case 3:
          this.purchase = data;
          break;
      }
    },
    // 提交故障类型
    issueSubmit (data) {
      this.tags = data;
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .add-box {
    width: 100px;
  }
  .label-wrap {
    display: flex;
  }
}
.el-tag {
  margin: 0 5px 5px;
  cursor: pointer;
}

.search-box {
  .tag-box {
    min-height: calc(256px - 42px - 36px);
  }
  .label-item {
    height: 32px;
    line-height: 32px;
  }
  .el-input {
    margin-bottom: 10px;
  }
  .tag {
    background-color: #ffffff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #606266;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
    &.active {
      color: #409eff;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
    }
  }
}
.add-box {
  .label-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      width: 150px;
      padding-right: 12px;
      text-align: right;
      display: inline-block;
    }
  }
}
.align-right {
  text-align: right;
}

.switch-box {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-right: 10px;
  }
}
.label-box {
  width: 100%;
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: space-between;
}
</style>