<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            label="Issue MainType"
            prop="name"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialog.loading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetFaultTypeByItemType,
  BatchDelFaultTypeItem,
} from '@/api/warehouse/dataMaintenance/faultType'
export default {
  props: ['visible', 'deviceTypeId', 'ids'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Select Issue MainType',
      },
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: 386,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.searchData();

      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    getTableData () {
      let param = {
        deviceTypeId: this.deviceTypeId
      }
      this.tableLoading = true;
      GetFaultTypeByItemType(param).then(res => {
        this.tableData = res;
        this.$nextTick(() => {
          this.tableData.forEach(item => {
            if (item.isSelect || this.ids.includes(item.id)) this.$refs.table.toggleRowSelection(item, true)
          })
          this.tableLoading = false;
        })
      })
    },

    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$emit('submit', this.tableSelected);
      this.dialog.loading = false;
      this.dialog.visible = false;

    },
    selectionChange (selection, row) {
      if (!this.tableLoading) {
        let ids = this.tableSelected.map(item => item.id);
        let selectionIds = selection.map(item => item.id);
        ids = ids.filter(item => !selectionIds.includes(item));
        if (ids.length !== 0) {
          let param = {
            faultTypeIds: ids,
            deviceTypeIds: [this.deviceTypeId],
          }
          BatchDelFaultTypeItem(param)
        }
      }
      this.tableSelected = selection;
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}
</style>