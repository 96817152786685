<template>
  <div>
    <el-table
      border
      :cell-style="cellStyle"
      :data="tableData"
      :height="tableHeight"
      ref="table"
      v-loading="tableLoading"
      @selection-change="selectionChange"
      @row-dblclick="rowDblclick"
      @row-click="rowClick"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        min-width="150"
        prop="itemName"
        :label="$t('quotation.col1')"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="model"
        :label="$t('quotation.col2')"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="brand"
        :label="$t('quotation.col3')"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="type"
        :label="$t('quotation.col4')"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="lastValidPrice"
        :label="$t('quotation.col5')"
        sortable
      >
        <template slot-scope="scope">
          {{scope.row.lastValidPrice.toFixed(decimals)}}
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="inquiryDate"
        :label="$t('quotation.col10')"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="fluctuation"
        :label="$t('quotation.col6')"
        sortable
        :formatter="amountFormat"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="averagePrice"
        :label="$t('quotation.col7')"
        sortable
      >
        <template slot-scope="scope">
          {{scope.row.averagePrice.toFixed(decimals)}}
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="highPrice"
        :label="$t('quotation.col8')"
        sortable
      >
        <template slot-scope="scope">
          {{scope.row.highPrice.toFixed(decimals)}}
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="lowPrice"
        :label="$t('quotation.col9')"
        sortable
      >
        <template slot-scope="scope">
          {{scope.row.lowPrice.toFixed(decimals)}}
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="xxx"
        :label="$t('quotation.col11')"
        sortable
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.discontinue === 0"
            size="small"
            type="success"
          >{{$t('quotation.tag1')}}</el-tag>
          <el-tag
            v-else
            size="small"
            type="danger"
          >{{$t('quotation.tag2')}}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
    
<script>
export default {
  props: ['tableLoading', 'tableSelected', 'tableData', 'tableHeight', 'decimals'],
  data () {
    return {
      moneyProp: ['lastValidPrice', 'fluctuation', 'averagePrice', 'highPrice', 'lowPrice'],
    }
  },
  methods: {
    selectionChange (val) {
      this.$emit('update:tableSelected', val)
    },
    // 格式化金额
    amountFormat (row, column) {
      let fluctuation = row.fluctuation;
      if (fluctuation > 0) {
        return '+' + Number(fluctuation).toFixed(this.decimals);
      } else {
        return Number(fluctuation).toFixed(this.decimals);
      }
    },
    // 单元格样式
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      let result = {};
      if (this.moneyProp.includes(column.property)) {
        result['text-align'] = 'right !important';
        result['padding-right'] = `${column.realWidth / 2 - 28}px !important`;
      }
      return result;
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    rowDblclick (e) {
      this.$emit('row-dblclick', e);
    },
  }
}
</script>
    
<style>
</style>