<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
            <el-button
              size="medium"
              @click="showEdit"
            >{{$t('operation.edit')}}</el-button>
            <el-button
              :disabled="!permit.copy"
              size="medium"
              @click="copyTo"
            >{{$t('operationalTime.btn1')}}</el-button>
          </el-button-group>
          <col-set
            @update="getTableData"
            url="/"
          ></col-set>
        </div>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="tableHeight"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclickEdit"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            prop="name"
            :label="$t('operationalTime.col1')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="dag"
            :label="$t('operationalTime.col2')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="180"
            prop="timeZone"
            :label="$t('operationalTime.col3')"
            sortable
          >
            <template slot-scope="scope">
              {{!!scope.row.timeZone ? $global.timeZones[scope.row.timeZone].name:''}}
            </template>
          </el-table-column>
          <el-table-column
            min-width="180"
            prop="country"
            :label="$t('operationalTime.col4')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 复制弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="350px"
    >
      <el-select
        size="small"
        v-model="dialog.organization"
        multiple
      >
        <el-option
          v-for="item in organizations"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        ></el-option>
      </el-select>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submitCopy"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import ColSet from '@/components/ColSet'
// 接口
import {
  GetTimeZoneList,
  CopyTimeZone,
} from '@/api/warehouse/operationalTime'
import {
  GetOrganizationAll
} from '@/api/organizations'

export default {
  name: 'OperationalTime',
  components: {
    ColSet,
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        copy: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      organizations: [],
      dialog: {
        visible: false,
        loading: false,
        title: this.$i18n.t('operationalTime.title1'),
        organization: [],
      }
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'OperationalTime') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
  },
  created () {
    this.dealPermission();
    // 获取组织
    GetOrganizationAll().then(res => this.organizations = res)
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetTimeZoneList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);

    },
    dblclickEdit (item) {
      this.$router.push({ path: '/home/editOperationalTime', query: { orgId: item.id } })
    },
    // 复制
    copyTo () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dialog.organization = [];
      this.dialog.visible = true;
    },
    // 提交复制
    submitCopy () {
      let param = {
        source: this.tableSelected[0].id,
        target: this.dialog.organization.join(','),
      }
      this.dialog.loading = true;
      CopyTimeZone(param).then(res => {
        if (res.success) {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.copy'))
          this.dialog.visible = false;
        } else {
          this.$message.warning(res.data)
        }
        this.dialog.loading = false;

      })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('OperationalTimeSearch');
        this.permit.add = permission.includes('OperationalTimeAdd');
        this.permit.copy = permission.includes('OperationalTimeCopyto');

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.el-select {
  width: 100%;
}
</style>
