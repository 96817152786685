<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
      </page-header>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('allocateGoods.tab1')"
          name="tab1"
        >
          <apply-from :active="activeName"></apply-from>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('allocateGoods.tab2')"
          name="tab2"
        >
          <approval :active="activeName"></approval>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!$route.query.id"
          :label="$t('allocateGoods.tab3')"
          name="tab3"
        >
          <change-record :active="activeName"></change-record>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!$route.query.id"
          :label="$t('allocateGoods.tab4')"
          name="tab4"
        >
          <logistics-record :active="activeName"></logistics-record>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!$route.query.id"
          :label="$t('allocateGoods.tab5')"
          name="tab5"
        >
          <sign-in-record :active="activeName"></sign-in-record>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>

</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import ApplyFrom from './components/ApplyFrom'
import Approval from './components/Approval'
import ChangeRecord from './components/ChangeRecord'
import SignInRecord from './components/SignInRecord'
import LogisticsRecord from './components/LogisticsRecord'

export default {
  name: 'AllocateGoods',
  components: {
    PageHeader,
    ApplyFrom,
    Approval,
    ChangeRecord,
    SignInRecord,
    LogisticsRecord,
  },
  data () {
    return {
      permit: {
        editAll: true,
        editSelf: true,
      },
      viewType: null,
      activeName: 'tab1',
      title: '',
    }
  },
  created () {
    this.dealPermission();
    this.initView();
  },
  methods: {
    initView () {
      this.viewType = Number(this.$route.query.type);

      switch (this.viewType) {
        case 1:
          this.title = this.$i18n.t('allocateGoods.title3');
          break;
        case 2:
          if (this.$route.query.id) {
            this.title = this.permit.editAll || this.permit.editSelf ? this.$i18n.t('allocateGoods.title2') : this.$i18n.t('allocateGoods.title4');

          } else {
            this.title = this.$i18n.t('allocateGoods.title1');
          }
          break;
      }
    },
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.editAll = permission.includes('PurchaseRequisitionEdit-Allitems');
        this.permit.editSelf = permission.includes('PurchaseRequisitionEdit-Toonlyallowausertoedithisownitems')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-tabs {
  height: calc(100% - 56px);
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>