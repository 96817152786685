import request from '@/utils/request'

// 查询组织架构
export const GetOrganizationList = (data) => request({
  method: 'post',
  url: '/sysOrgZarions/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加和修改组织架构
export const AddOrganization = (data) => request({
  method: 'post',
  url: '/sysOrgZarions/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除组织架构
export const DelOrganization = (data) => request({
  method: 'post',
  url: '/sysOrgZarions/deleteById.do',
  data: JSON.stringify(data)
})

// 上传公司图标
export const UploadLogo = (data) => request({
  method: 'post',
  url: '',
  data: JSON.stringify(data)
})

// 获取部门树
export const GetDepartmentTree = (data) => request({
  method: 'post',
  url: '/sysDep/queryAll.do',
  data: JSON.stringify(data)
})

// 添加公司部门tree根节点
export const AddDepartment = (data) => request({
  method: 'post',
  url: '/sysDep/addNode.do',
  data: JSON.stringify(data)
})

// 添加公司部门tree子节点
export const AddChildDepartment = (data) => request({
  method: 'post',
  url: '/sysDep/addChild.do',
  data: JSON.stringify(data)
})

// 删除部门节点
export const DelDepartment = (data) => request({
  method: 'post',
  url: '/sysDep/deleteById.do',
  data: JSON.stringify(data)
})

// 查询访问数据组
export const GetDataAccessGroupList = (data) => request({
  method: 'post',
  url: '/sysDatsAccessGroup/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加和修改访问数据组
export const AddDataAccessGroup = (data) => request({
  method: 'post',
  url: '/sysDatsAccessGroup/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除访问数据组
export const DelDataAccessGroup = (data) => request({
  method: 'post',
  url: '/sysDatsAccessGroup/deleteById.do',
  data: JSON.stringify(data)
})

// 查询单个数据访问组信息
export const GetDataAccessGroupItem = (data) => request({
  method: 'post',
  url: '/sysDatsAccessGroup/queryById.do',
  data: JSON.stringify(data)
})

// 获取组织集合
export const GetOrganization = (data) => request({
  method: 'post',
  url: '/sysOrgZarions/queryOrganization.do',
  data: JSON.stringify(data)
})
// 获取数据访问集合
export const GetDataAccessGroup = (data) => request({
  method: 'post',
  url: '/sysDatsAccessGroup/querySelect.do',
  data: JSON.stringify(data)
})

// 获取所有数据访问组
export const GetDataAccessGroupAll = (data) => request({
  method: 'post',
  url: '/sysDatsAccessGroup/queryDataAccessGroup.do',
  data: JSON.stringify(data)
})

// 获取所有组织
export const GetOrganizationAll = (data) => request({
  method: 'post',
  url: '/sysOrgZarions/queryAccessGroupAll.do',
  data: JSON.stringify(data)
})

// 保存Layout
export const SaveOrganizationLayout = (data) => request({
  method: 'post',
  url: '/sysOrgZarions/orgUpdate.do',
  data: JSON.stringify(data)
})

// 获取品牌集合
export const GetBrandAll = (data) => request({
  method: 'post',
  url: '/sysStores/queryStoresBrand.do',
  data: JSON.stringify(data)
})


// 获取部门集合
export const GetDepartmentAll = (data) => request({
  method: 'post',
  url: '/sysDep/queryByList.do',
  data: JSON.stringify(data)
})

// 获取部门下属职位
export const GetPositionByDepartment = (data) => request({
  method: 'post',
  url: '/sysDep/queryDepId.do',
  data: JSON.stringify(data)
})
