<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-date-picker
          size="medium"
          v-model="dateRange"
          type="daterange"
          :picker-options="pickerOptions"
          unlink-panels
          value-format="yyyy-MM-dd"
          @change="searchData"
        >
        </el-date-picker>
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="printOrders(1)"
          >{{$t('purchaseOrders.btn1')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="printOrders(2)"
          >{{$t('purchaseOrders.btn2')}}</el-button>
          <el-button
            size="medium"
            type="danger"
            @click="cancel"
          >撤销</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <order-list-table
        :table-data="tableData"
        :table-loading="tableLoading"
        :table-selected.sync="tableSelected"
        :permit="permit"
        :table-height="tableHeight"
        @row-dblclick="dblclickEdit"
      ></order-list-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
    <!-- 撤销弹框 -->
    <cancel-dialog
      :visible.sync="CancelDialogVisible"
      :data="orderData"
      @update="getTableData"
    ></cancel-dialog>
  </div>
</template>

<script>
// 组件
import CancelDialog from './CancelDialog'
import OrderListTable from './OrderListTable'
// 接口
import {
  GetPurchaseOrdersList,
  DelPurchaseOrders,

} from '@/api/warehouse/purchaseOrders'
export default {
  components: {
    CancelDialog,
    OrderListTable
  },
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      CancelDialogVisible: false,
      permit: {
        search: true,
        add: true,
        del: true,
        showPOAmount: true,
      },

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      dateRange: null,
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      orderData: null,
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'PurchaseOrders') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
    'active': function (newVal) {
      if (newVal === 'tab2') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
  },
  created () {
    this.dealPermission();

  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        startDate: !!this.dateRange ? this.dateRange[0] + ' 00:00:00' : null,
        endDate: !!this.dateRange ? this.dateRange[1] + ' 23:59:59' : null,
      }
      this.tableLoading = true;
      GetPurchaseOrdersList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (!!item.projectName) item.projectName = Array.from(new Set(item.projectName.split(',')));
          if (!!item.quotationNumber) item.quotationNumber = Array.from(new Set(item.quotationNumber.split(',')));
          return item;
        });;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增
    showAdd () {
      this.$router.push({ path: '/home/newOrder', query: { type: 1 } })
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.$router.push({ path: '/home/newOrder', query: { id: e.id, type: 1 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelPurchaseOrders(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          } else {
            this.$message.warning('订单有出入库操作，不能删除.')
          }
        })
      }).catch(() => { });
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('PurchaseOrdersSearch');
        this.permit.add = permission.includes('PurchaseOrdersAdd');
        // this.permit.edit = permission.includes('PurchaseOrdersEdit');
        this.permit.del = permission.includes('PurchaseOrdersDelete');
        this.permit.showPOAmount = permission.includes('PurchaseOrdersBeallowedtoseetheprice');
      }
    },

    // 打印
    printOrders (type) {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      switch (type) {
        case 1:
          let templateId = this.tableSelected[0].contractId || 'default';
          window.open(`${this.baseUrl}/Export/exportPurchase1.do?id=${this.tableSelected[0].id}&templateId=${templateId}`);
          break;
        case 2:
          window.open(`${this.baseUrl}/Export/exportPurchase2.do?id=${this.tableSelected[0].id}`);
          break;
      }
    },
    // 撤销
    cancel () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.orderData = this.tableSelected[0];
      this.CancelDialogVisible = true;
    },
  }
}
</script>
<style lang="scss" scoped>
.input-wrap {
  justify-content: start;
  .el-date-editor {
    margin-right: 10px;
  }
}

.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
.cell-style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>