import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import router from '@/router'
import {
  Message
} from 'element-ui'
import {
  logout
} from '@/utils/common'

const CancelToken = axios.CancelToken;
Vue.$httpRequestList = [];

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 5000,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
  },
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (VueCookies.get('token')) {
      config.headers['token'] = VueCookies.get('token') || '';
    }
    config['cancelToken'] = new CancelToken(c => { //强行中断请求要用到的
      Vue.$httpRequestList.push(c);
    })
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status == 403 && !/\#\/login/.test(window.location.href)) {
      logout();

      router.push({
        path: '/login'
      })

      if (document.getElementsByClassName('el-message--warning').length === 0) {
        Message.error({
          message: 'Login invalid.',
          duration: 5 * 1000
        })
      }
    } else if (res.status == 401) {
      Message.error({
        message: "Sorry, you don't have access, please contact the administrator.",
        duration: 5 * 1000
      })
    } else if (res.status == 500) {
      // debugger
      if (document.getElementsByClassName('el-message--warning').length === 0)
        Message.warning('Please try again later.')
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    if (error.message !== 'interrupt' && document.getElementsByClassName('el-message--warning').length === 0)
      Message.warning('Please try again later.')

    return Promise.reject(error)
  }
)

export default service
