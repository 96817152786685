<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <div class="view-box">
        <div class="head-item">{{this.dialog.name}}</div>
        <div
          class="item-box"
          v-for="(item,index) in this.dialog.data"
          :key="index"
        >
          <i class="el-icon-bottom"></i>
          <div class="item">{{item}}</div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="dialog.visible = false"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { GetApprovalWorkflowItem } from '@/api/warehouse/approvalWorkflow'
export default {
  props: ['visible', 'id'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Flow diagram',
        data: [],
        name: '',
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.getData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },

  },
  created () {

  },
  methods: {
    getData () {
      let param = { id: this.id }
      GetApprovalWorkflowItem(param).then(res => {
        this.dialog.name = res.name;
        res.workflowApprovalSymbolList.forEach(item => {
          this.dialog.data[item.step - 1] = item.approvalSymbol
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-box {
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.head-item {
  height: 36px;
  line-height: 36px;
  border-radius: 36px;
  border: 1px solid #797979;
  padding: 0 20px;
}
.item-box {
  width: 150px;
}
.item {
  padding: 15px 5px;
  border: 1px solid #797979;
}
</style>