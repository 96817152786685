<template>
  <div>
    <el-row>
      <el-button-group>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="delItem"
        >{{$t('operation.del')}}</el-button>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="addItem"
        >{{$t('operation.add')}}</el-button>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="showEdit"
        >{{$t('operation.edit')}}</el-button>
        <el-button
          :disabled="!permit.editInfo"
          size="medium"
          @click="copyItem"
        >{{$t('operation.copy')}}</el-button>
      </el-button-group>
    </el-row>
    <el-table
      border
      ref="table"
      :data="tableData"
      :row-class-name="tableRowClassName"
      @selection-change="val => tableSelected = val"
      @row-dblclick="dblclickEdit"
      @row-click="rowClick"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        min-width="130"
        prop="deviceInfoName"
        :label="$t('newServiceConfirmationForm.tab4.col1')"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        prop="installationSite"
        :label="$t('newServiceConfirmationForm.tab4.col2')"
      >
      </el-table-column>
      <el-table-column
        min-width="170"
        prop="faultDescription"
        :label="$t('newServiceConfirmationForm.tab4.col3')"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        prop="remark"
        :label="$t('newServiceConfirmationForm.tab4.col4')"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        prop="serialNumber"
        :label="$t('newServiceConfirmationForm.tab4.col5')"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        prop="customerConfirmUserName"
        :label="$t('newServiceConfirmationForm.tab4.col6')"
      >
      </el-table-column>
      <el-table-column
        min-width="150"
        prop="sceneConfirmUserName"
        :label="$t('newServiceConfirmationForm.tab4.col7')"
      >
      </el-table-column>
      <el-table-column
        min-width="130"
        prop="deviceTo"
        :label="$t('newServiceConfirmationForm.tab4.col8')"
      >
      </el-table-column>
    </el-table>

    <!-- 拆除/搬离设备 -->
    <demolition-equipment-dialog
      :visible.sync="DemolitionEquipmentVisble"
      :data="demolitionData"
      :describe="describe"
      :dispose="dispose"
      :branch-id="branchId"
      @submit="demolitionSubmit"
    ></demolition-equipment-dialog>
  </div>
</template>

<script>
// 组件
import DemolitionEquipmentDialog from './DemolitionEquipmentDialog'

export default {
  components: {
    DemolitionEquipmentDialog
  },
  props: ['data', 'permit', 'describe', 'dispose', 'branchId'],
  data () {
    return {
      DemolitionEquipmentVisble: false,
      tableSelected: [],
      tableData: [],
      demolitionData: null,
    }
  },
  watch: {
    'data': function (newVal) {
      this.tableData = newVal;
    }
  },
  methods: {
    // 新增
    addItem () {
      this.demolitionData = null;
      this.DemolitionEquipmentVisble = true;
    },
    // 修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      if (!this.permit.editInfo) return;
      this.demolitionData = e;
      this.DemolitionEquipmentVisble = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkIndex = this.tableSelected.map(item => item.rowIndex);
        this.tableData = this.tableData.filter(item => !checkIndex.includes(item.rowIndex));
      }).catch(() => { });
    },
    // 复制
    copyItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.copyData(this.tableSelected[0])
    },
    copyData (data) {
      let item = JSON.parse(JSON.stringify(data));
      item.id = '';
      item.installationSite = '';
      item.serialNumber = '';
      item.qrCode = '';
      item.installationNumber = '';
      this.tableData.push(item)
      this.$nextTick(() => {
        // 重新打开弹框
        this.demolitionData = item;
        this.DemolitionEquipmentVisble = true;
      })
    },
    // 新增拆除/搬离设备
    demolitionSubmit ({ data, type, copy }) {
      switch (type) {
        case 'add':
          this.tableData.push(data);
          break
        case 'edit':
          this.$set(this.tableData, data.rowIndex, data);
          break
      }
      if (copy) this.copyData(data);
    },
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },

  },
}
</script>

<style>
</style>