<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="userForm"
        size="small"
        class="user-form"
      >
        <el-form-item
          :label="$t('projectDocuments.label1')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { AddDocument } from '@/api/warehouse/projectDocuments'
export default {
  props: ['visible', 'data', 'type'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.type == 2) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('projectDocuments.title2'),
              name: this.data.name,
            })
          })
        } else {
          this.dialog.title = this.$i18n.t('projectDocuments.title1')
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 添加或修改用户
    submit () {
      this.$refs.userForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          type: 0,
        }
        if (!this.data) {
          param.taskId = 0;
          param.parentId = 0
        } else if (this.type == 1) {
          param.parentId = this.data.id;
          param.taskId = this.data.taskId;
        } else {
          param.parentId = this.data.parentId;
          param.taskId = this.data.taskId;
        }

        let msg = this.$i18n.t('message.add');
        if (this.type == 2) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddDocument(param).then(res => {
          if (res) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        }).catch(err => {
          this.dialog.loading = false;
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>