<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
      </page-header>
      <div class="label-item">
        <label>{{$t('documentsDetail.label1')}}：</label>
        <span>{{taskName}}</span>
      </div>
      <div class="document-wrap">
        <div
          v-for="(item,index) in fileData"
          :key="index"
          class="document-box"
        >
          <div class="file-item">
            <div class="block">
              <img :src="'./static/image/fileType/' + item.formats + '.png'">
            </div>
            <label>{{item.name + '.' + item.formats}}</label>
          </div>
          <div class="submit-box">
            <div
              v-for="(submitItem,submitIndex) in fileSubmitData[index]"
              :key="submitIndex"
              class="file-item"
            >
              <div class="block">
                <img :src="'./static/image/fileType/' + submitItem.formats + '.png'">
                <i
                  class="el-icon-delete"
                  @click="delFile(submitItem.id)"
                ></i>
              </div>
              <label>{{submitItem.name + '.' + submitItem.paths.match(/^(.*)\.([^.]+)$/)[2]}}</label>
            </div>
            <div
              class="file-item add-item"
              @click="showDocument(item.id)"
            >
              <div class="block">
                <i class="el-icon-plus"></i>
              </div>
              <label></label>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 上传文件 -->
    <input
      type="file"
      hidden
      ref="fileInput"
      @change="uploadFile"
    >
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  GetTaskDocumentList,
  GetSubmitDocument,
  AddSubmitDocument,
  DelSubmitDocument,
} from '@/api/warehouse/projects'
import { UploadImage } from '@/api'

export default {
  name: 'DocumentsDetail',
  components: {
    PageHeader,
  },
  data () {
    return {
      title: this.$i18n.t('documentsDetail.title1'),
      taskId: null,
      taskName: null,
      fileData: [],
      fileSubmitData: [],
      rowId: null,
    }
  },
  created () {
    this.taskId = this.$route.query.id;
    this.taskName = this.$route.query.name;
    this.getDocumentData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    getDocumentData () {
      GetTaskDocumentList(this.taskId).then(res => {
        this.fileData = res;
        this.getData();
      })
    },
    getData () {
      let arr = this.fileData.map(item => this.getPromise(item.id));

      Promise.all(arr).then((res) => {
        this.fileSubmitData = res;
        this.fileSubmitData.forEach(fileArr => {
          fileArr.forEach(item => {
            let fileProperty = file.name.match(/^(.*)\.([^.]+)$/);
            item.formats = fileProperty[2];
          })
        })
      })

    },
    getPromise (id) {
      return new Promise((resolve, reject) => {
        GetSubmitDocument(id).then(res => {
          if (res) resolve(res);
        })
      })
    },
    delFile (id, index) {
      DelSubmitDocument(id).then(res => {
        if (res) {
          this.getData();
          this.$message.success(this.$i18n.t('message.del'))
        }
      })
    },
    showDocument (id) {
      this.rowId = id;
      this.$refs.fileInput.click();
    },
    // 上传文件
    uploadFile () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let fileProperty = file.name.match(/^(.*)\.([^.]+)$/);
          let param = {
            referId: this.rowId,
            name: fileProperty[1],
            paths: res.message,
          }
          AddSubmitDocument(param).then(res => {
            if (res) {
              this.getData()
              this.$message.success(this.$i18n.t('message.add'))
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.label-item {
  height: 36px;
  line-height: 36px;
  label {
    margin-right: 10px;
  }
}
.document-box {
  display: flex;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 120px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 120px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: static;
    }
  }
}
.submit-box {
  display: flex;
  flex-wrap: wrap;
}
</style>