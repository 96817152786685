<template>
  <div class="filter-wrap">
    <div
      class="filter-item"
      ref="filterItem"
      @click="showFilter"
    >
      <div
        class="con"
        :class="{
          'and':!data.data || ( !!data.data && data.data.relation == 'and' ) ,
          'or':!!data.data && data.data.relation == 'or' ,
          'param':!!data.data && data.data.queryType == 1,
        }"
      >
        <div class="label-box">
          <label :title="data.label">{{data.label}}</label>
          <div class="btn-box">
            <i
              v-if="data.mark != 'Report' && data.mark != 'BU'"
              class="el-icon-close"
              @click.stop="closeCondition"
            ></i>
          </div>
        </div>
        <div
          class="val-text"
          :title="data.con"
        >{{data.con}}</div>
      </div>
    </div>
    <component
      ref="filterDialog"
      :is="dialogName"
      :visible.sync="FilterDialogVisible"
      :zindex="zindex"
      :data="data"
      :reportId="reportId"
      @submit="submit"
      @showIndex="showIndex"
      @showQuery="showQuery"
      @wheel.native.stop
    ></component>
  </div>
</template>

<script>
import FilterDialog1 from '@/components/FilterItem/FilterDialog1'
import FilterDialog2 from '@/components/FilterItem/FilterDialog2'
import FilterDialog3 from '@/components/FilterItem/FilterDialog3'
import FilterDialog4 from '@/components/FilterItem/FilterDialog4'
import FilterDialog5 from '@/components/FilterItem/FilterDialog5'
import FilterDialog6 from '@/components/FilterItem/FilterDialog6'
import FilterDialog7 from '@/components/FilterItem/FilterDialog7'
import FilterDialog8 from '@/components/FilterItem/FilterDialog8'
import FilterDialog9 from '@/components/FilterItem/FilterDialog9'
import FilterDialog10 from '@/components/FilterItem/FilterDialog10'
import FilterDialog11 from '@/components/FilterItem/FilterDialog11'
import FilterDialog12 from '@/components/FilterItem/FilterDialog12'

export default {
  components: {
    FilterDialog1,
    FilterDialog2,
    FilterDialog3,
    FilterDialog4,
    FilterDialog5,
    FilterDialog6,
    FilterDialog7,
    FilterDialog8,
    FilterDialog9,
    FilterDialog10,
    FilterDialog11,
    FilterDialog12,
  },
  props: ['mode', 'zindex', 'data', 'reportId'],
  data () {
    return {
      dialogName: '',
      FilterDialogVisible: false,
    }
  },
  watch: {
    'FilterDialogVisible': function (newVal) {
      if (this.FilterDialogVisible) {

      }
    },
    'data': {
      handler: function (newVal, oldVal) {

      },
      deep: true
    },
  },
  created () {
    if (this.data.mark === 'BU') {
      this.dialogName = 'FilterDialog2';
    }
  },
  methods: {
    submit (data, type) {
      this.$emit('submit', data, type);
    },
    showFilter (e, fun) {
      let dialogType;
      if (!!this.data.mark) {
        dialogType = this.data.mark == 'Report' ? 1 : 2;
      } else {
        switch (this.data.data.fieldType) {

          case 'date':
            dialogType = 3;
            break;
          case 'option':
            dialogType = 6;
            break;
          case 'stores':
            dialogType = 4;
            break;
          case 'time':
            dialogType = 7;
            break;
          case 'mins':
            dialogType = 8;
            break;
          case 'sla':
            dialogType = 9;
            break;
          case 'device':
            dialogType = 10;
            break;
          case 'system':
            dialogType = 11;
            break;
          case 'engineer':
            dialogType = 12;
            break;
          default:
            // string number days LowValues
            dialogType = 5;
        }
      }

      this.dialogName = 'FilterDialog' + dialogType;
      this.$nextTick(() => {

        let el = this.$refs.filterDialog.$el;
        let screenData = e.currentTarget.getBoundingClientRect()

        el.style.height = window.innerHeight * 0.6 + 'px';
        el.style.left = screenData.left + 'px';
        el.style.top = screenData.bottom + 5 + 'px';
        el.style.zIndex = this.zindex;
        this.FilterDialogVisible = true;
        this.$emit('update:zindex', this.zindex + 1)

        if (!!fun) fun();
      })
    },
    showIndex () {
      this.$emit('update:zindex', this.zindex + 1)
    },
    showQuery () {
      this.$emit('showQuery', this.data)
    },
    closeCondition () {
      this.$emit('closeCondition', this.data)
    },
    closeDialog () {
      this.FilterDialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
  width: 260px;
  height: 100%;
  margin-left: 5px;
  flex-shrink: 0;
}

.filter-item {
  width: 100%;
  height: 100%;
  border: 1px solid #e4e4e4;
  background: #fff;
  cursor: pointer;
  float: left;
  display: flex;
  // box-shadow: 0 2px 4px 0 #0002;

  &:first-child {
    margin-left: 0;
  }

  .con {
    // width: calc(100% - 15px);
    width: 100%;
    padding: 5px 5px 5px 10px;
    border-bottom: 2px solid #fff;
    &.and {
      border-bottom: 2px solid #00c875;
    }
    &.or {
      border-bottom: 2px solid #fdab3d;
    }
    &.param {
      border-bottom: 2px solid #ff0000;
    }
    .label-box {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      display: flex;
      label {
        width: calc(100% - 18px);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn-box {
        width: 18px;
        height: 20px;
        display: none;
        i {
          width: 18px;
          height: 18px;
          text-align: center;
          line-height: 18px;
          cursor: pointer;
          &:hover {
            background: #f0f2f5;
          }
        }
      }
    }
    .val-text {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    .btn-box {
      display: block !important;
    }
  }
}
</style>