<template>
  <div
    class="wrap"
    v-resize="resize"
  >
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-image
            lazy
            class="picture"
            ref="picture"
            :src="photo"
            fit="cover"
            @click.native="$refs.fileImage.click()"
          ></el-image>
          <input
            type="file"
            ref="fileImage"
            @change="uploadImage"
            hidden
          >
        </el-col>
        <el-col :span="18">
          <el-form
            :model="groupForm"
            :rules="rules"
            ref="groupForm"
            class="group-form"
            size="medium"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label1')"
                  prop="name"
                >
                  <el-input v-model="groupForm.name"></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('newEngineer.label2')"
                  prop="employeeNumber"
                >
                  <el-input v-model="groupForm.employeeNumber"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label3')"
                  prop="userid"
                >
                  <el-input v-model="groupForm.userid"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label4')"
                  prop="dataAccessGroup"
                >
                  <el-select
                    v-model="groupForm.dataAccessGroup"
                    @change="dataAccessGroupChange"
                    default-first-option
                    filterable
                  >
                    <el-option
                      v-for="item in dataAccessGroups"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label11')"
                  prop="organic"
                >
                  <el-select
                    v-model="groupForm.organic"
                    @change="organizationChange"
                    default-first-option
                    filterable
                  >
                    <el-option
                      v-for="item in organizations"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label12')"
                  prop="department"
                >
                  <el-select
                    v-model="groupForm.department"
                    @change="departmentChange"
                    default-first-option
                    filterable
                  >
                    <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :value="item.id"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                  <!-- <el-cascader
                    v-model="groupForm.department"
                    :options="departments"
                    :props="{ checkStrictly: true , value:'id' , label:'label' }"
                    clearable
                    :show-all-levels="false"
                    @change="departmentChange"
                  ></el-cascader> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label5')"
                  prop="personalID"
                >
                  <el-select
                    v-model="groupForm.personalID"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="item in positions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label6')"
                  prop="workPhone"
                >
                  <el-input v-model="groupForm.workPhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label13')"
                  prop="siteGroup"
                >
                  <el-select
                    v-model="groupForm.siteGroup"
                    filterable
                  >
                    <el-option
                      v-for="item in sites"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label7')"
                  prop="homePhone"
                >
                  <el-input v-model="groupForm.homePhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label14')"
                  prop="reportGroup"
                >
                  <el-select
                    v-model="groupForm.reportGroup"
                    filterable
                  >
                    <el-option
                      v-for="item in reports"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label8')"
                  prop="email"
                >
                  <el-input v-model="groupForm.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label9')"
                  prop="employeeStatus"
                >
                  <el-radio-group v-model="groupForm.employeeStatus">
                    <el-radio :label="0">Active</el-radio>
                    <el-radio :label="1">Terminated</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label18')"
                  prop="employeeStatus"
                >
                  <el-radio-group v-model="groupForm.outwork">
                    <el-radio :label="1">Yes</el-radio>
                    <el-radio :label="0">No</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label10')"
                  prop="attendance"
                >
                  <el-select
                    v-model="groupForm.attendance"
                    filterable
                  >
                    <el-option
                      :value="0"
                      :label="$t('newEngineer.option1')"
                    ></el-option>
                    <el-option
                      :value="1"
                      :label="$t('newEngineer.option2')"
                    ></el-option>
                    <el-option
                      :value="2"
                      :label="$t('newEngineer.option3')"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  v-if="!groupForm.id"
                  :label="$t('newEngineer.label16')"
                  prop="createAccount"
                >
                  <el-checkbox
                    v-model="groupForm.createAccount"
                    @change="createAccountChange"
                  ></el-checkbox>
                  <el-select
                    :disabled="!!groupForm.createAccount"
                    v-model="groupForm.sysUserId"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in requisitioners"
                      :key="item.id"
                      :value="item.id"
                      :label="item.userAccount_fullname"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-else
                  :label="$t('newEngineer.label19')"
                  prop="systemUser"
                >
                  <el-radio-group v-model="groupForm.systemUser">
                    <el-radio
                      disabled
                      :label="1"
                    >Yes</el-radio>
                    <el-radio
                      disabled
                      :label="0"
                    >No</el-radio>
                  </el-radio-group>
                  <el-select
                    v-model="groupForm.sysUserId"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in requisitioners"
                      :key="item.id"
                      :value="item.id"
                      :label="item.userAccount_fullname"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label15')"
                  prop="leaveType"
                >
                  <el-select
                    v-model="groupForm.leaveType"
                    filterable
                  >
                    <el-option
                      :value="0"
                      label="Sick Leave"
                    ></el-option>
                    <el-option
                      :value="1"
                      label="Annual vacation"
                    ></el-option>
                    <el-option
                      :value="2"
                      label="Special Leave"
                    ></el-option>
                    <el-option
                      :value="3"
                      label="Unpaid Leave"
                    ></el-option>
                    <el-option
                      :value="4"
                      label="Other"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newEngineer.label17')"
                  prop="dateRange"
                >

                  <el-date-picker
                    v-model="groupForm.dateRange"
                    type="datetimerange"
                    start-placeholder="Start Date"
                    end-placeholder="End Date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    unlink-panels
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 全局方法
import {
  debounce,
  formatUTCDate,
  toLocaleDate,
} from '@/utils/common'
// 组件
import PageHeader from '@/components/PageHeader'

// 接口
import {
  GetOrganization,
  GetDataAccessGroupAll,
} from '@/api/organizations'
import { UploadImage } from '@/api'
import {
  AddEngineer,
  GetEngineerStateItem
} from '@/api/warehouse/engineerManagement'
import {
  GetDepartmentAll,
  GetPositionByDepartment,
} from '@/api/organizations'
import { GetSysReportGroupAll } from '@/api/reportGroup'
import { GetSiteGroupAll } from '@/api/stores'
import { GetUserAll } from "@/api/userManagement";

export default {
  name: 'NewEngineer',
  components: {
    PageHeader,
  },
  data () {
    return {
      permit: {
        edit: true,
      },
      title: '',
      baseUrl: process.env.VUE_APP_BASE_URL,
      organizations: [],
      dataAccessGroups: [],
      positions: [],
      departments: [],
      sites: [],
      reports: [],
      requisitioners: [],
      groupForm: {
        loading: false,
        id: null,
        name: '',
        employeeNumber: '',
        personalID: '',
        userid: '',
        organic: '',
        position: '',
        workPhone: '',
        homePhone: '',
        email: '',
        employeeStatus: 0,
        attendance: 0,
        department: '',
        dataAccessGroup: '',
        siteGroup: '',
        reportGroup: '',
        leaveType: 0,
        dateRange: null,
        photo: '',
        createAccount: true,
        outwork: 0,
        systemUser: 0,
        sysUserId: '',
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Name.', trigger: 'blur' }],
      },
    }
  },
  computed: {
    photo () {
      if (!!this.groupForm.photo) {
        return this.baseUrl + '/Engineer/getImage.do?imageId=' + this.groupForm.photo;
      } else {
        return '';
      }
    }
  },
  watch: {
    'groupForm.sysUserId': function (newVal) {
      this.groupForm.systemUser = !!newVal ? 1 : 0;
    }
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newEngineer.title2') : this.$i18n.t('newEngineer.title3');
      // 获取人员信息
      this.getItem()

    } else {
      this.title = this.$i18n.t('newEngineer.title1')
    }

    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
    // 获取申购人
    GetUserAll({}).then(res => {
      res = res.map(item => {
        item.userAccount_fullname = `${item.userAccount} - ${item.fullname}`.trim();
        return item
      });
      // res.sort((a, b) => a.userAccount_fullname > b.userAccount_fullname);

      this.requisitioners = res;
    });
  },
  mounted () {
    this.initPictureheight();
  },
  methods: {
    resize: debounce(function () {
      this.initPictureheight();
    }, 10),
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取单项信息
    getItem () {
      let param = {
        id: this.groupForm.id
      }
      GetEngineerStateItem(param).then(res => {
        Object.assign(this.groupForm, {
          name: res.name,
          employeeNumber: res.number,
          userid: res.userid,
          organic: res.orgId,
          position: res.position,
          personalID: res.personalID,
          workPhone: res.workPhone,
          homePhone: res.homePhone,
          email: res.email,
          employeeStatus: res.status,
          attendance: Number(res.attendance),
          department: res.depId,
          dataAccessGroup: res.dagId,
          siteGroup: res.sgId,
          reportGroup: res.rgId,
          leaveType: Number(res.leaveType),
          dateRange: res.startDate === null ? null : [toLocaleDate(res.startDate), toLocaleDate(res.endDate)],
          photo: res.photo,
          outwork: res.external,
          systemUser: !!res.sysUserId ? 1 : 0,
          sysUserId: res.sysUserId,
        })
        if (!!this.groupForm.dataAccessGroup) this.dataAccessGroupChange();
        if (!!this.groupForm.organic) this.organizationChange();
        if (!!this.groupForm.department) this.departmentChange();
      })
    },
    // 设置图片高度
    initPictureheight () {
      let el = this.$refs.picture.$el;
      let w = el.clientWidth;

      el.style.height = w * 7 / 5 + 'px';
    },
    // 添加或修改用户
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.groupForm.name,
          number: this.groupForm.employeeNumber,
          userid: this.groupForm.userid,
          personalID: this.groupForm.personalID,
          position: this.groupForm.position,
          workPhone: this.groupForm.workPhone,
          homePhone: this.groupForm.homePhone,
          email: this.groupForm.email,
          status: this.groupForm.employeeStatus,
          attendance: this.groupForm.attendance,
          leaveType: this.groupForm.leaveType,
          startDate: this.groupForm.dateRange === null ? null : formatUTCDate(this.groupForm.dateRange[0]),
          endDate: this.groupForm.dateRange === null ? null : formatUTCDate(this.groupForm.dateRange[1]),
          photo: this.groupForm.photo,
          orgId: this.groupForm.organic,
          depId: this.groupForm.department,
          dagId: this.groupForm.dataAccessGroup,
          sgId: this.groupForm.siteGroup,
          rgId: this.groupForm.reportGroup,
          userAccout: Number(this.groupForm.createAccount),
          external: this.groupForm.outwork,
          sysUserId: this.groupForm.sysUserId,
        }
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddEngineer(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.back();
          } else {
            if (res.status === 100) this.$message.warning('The name is already occupied.')
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.fileImage.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.groupForm.photo = res.message;
        }
      })
    },
    // 数据访问组变更
    dataAccessGroupChange (e) {
      let param = { accessGroupId: this.groupForm.dataAccessGroup }
      if (e !== undefined) {
        this.groupForm.organic = '';
        this.groupForm.siteGroup = '';
        this.groupForm.reportGroup = '';
      };
      // 获取组织
      GetOrganization(param).then(res => this.organizations = res)
      // 获取店铺组
      GetSiteGroupAll(param).then(res => this.sites = res)
      // 获取系统报表组
      GetSysReportGroupAll(param).then(res => this.reports = res);
    },
    // 组织变更
    organizationChange (e) {
      let param = { orgZarionsId: this.groupForm.organic }
      if (e !== undefined) {
        this.groupForm.department = '';
      };
      GetDepartmentAll(param).then(res => {
        this.departments = res
      })
    },
    // 部门变更 
    departmentChange () {
      let param = { id: this.groupForm.department }
      GetPositionByDepartment(param).then(res => this.positions = res)
    },
    // 
    createAccountChange () {
      this.groupForm.sysUserId = '';
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.edit = permission.includes('PersonnelManagementEdit');
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.group-form {
  .el-select {
    width: 100%;
  }
  .select-table {
    margin-top: 20px;
    ::v-deep td,
    ::v-deep th {
      height: 35px;
      line-height: 35px;
      padding: 0;
    }
  }

  .el-date-editor {
    width: 100%;
  }

  .select-box {
    display: flex;
  }
}

.picture {
  width: 80%;
  margin: 0 auto;
  display: block;
}
</style>