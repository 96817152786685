import request from '@/utils/request'

// 查询订货单列表
export const GetPurchaseOrdersList = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加或修改订货单
export const AddPurchaseOrders = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除订货单
export const DelPurchaseOrders = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/del.do',
  data: JSON.stringify(data)
})

// 查询订货单详情
export const GetPurchaseOrdersItem = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/queryById.do',
  data: JSON.stringify(data)
})

// 自动生成订单号
export const AutoPurchaseOrdersNumber = (data) => request({
  method: 'get',
  url: '/WarehouseOrderForm/getNum.do',
})

// 获取申请设备列表
export const GetOrdersDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormDeviceInfo/queryByPage.do',
  data: JSON.stringify(data)
})

// 删除申请设备
export const DelOrdersDevice = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormDeviceInfo/del.do',
  data: JSON.stringify(data)
})

// 获取订货状态列表
export const GetOrdersStatusList = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/queryOrderStatus.do',
  data: JSON.stringify(data)
})

// 添加订货状态
export const AddOrdersStatus = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOrderStatus/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 添加付款状态
export const AddPaymentStatus = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormPaymentStatus/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 添加到货状态
export const AddArrivalStatus = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormArrivalStatus/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取收货人
export const GetReceiver = () => request({
  method: 'get',
  url: '/WarehouseOrderForm/queryReceiver.do',
})
// 获取收货地址
export const GetAddress = () => request({
  method: 'get',
  url: '/WarehouseOrderForm/queryAddress.do',
})
// 获取联系方式
export const GetPhoneNumber = () => request({
  method: 'get',
  url: '/WarehouseOrderForm/queryPhoneNumber.do',
})

// 根据购物申请单查询订货单列表
export const GetPurchaseOrdersByAppList = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/queryByApp.do',
  data: JSON.stringify(data)
})

// 预分配
export const PreAllocated = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/preAllocated.do',
  data: JSON.stringify(data)
})

// 获取指定设备预分配数量
export const GetAllPreCount = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/queryCount.do',
  data: JSON.stringify(data)
})

// 获取 其他项 列表
export const GetOtherList = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增 其他项
export const AddOther = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 其他项
export const DelOther = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/del.do',
  data: JSON.stringify(data)
})

// 获取 到货确认 列表
export const GetArrivalRecordList = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormArrivalRecord/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增、修改 到货确认
export const AddArrivalRecord = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormArrivalRecord/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 到货确认
export const DelArrivalRecord = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormArrivalRecord/del.do',
  data: JSON.stringify(data)
})

// 获取 到货确认 详情
export const GetArrivalRecordItem = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormArrivalRecord/queryById.do',
  data: JSON.stringify(data)
})

// 获取 物流信息 列表
export const GetLogisticsInfoList = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsInformation/queryByPage.do',
  data: JSON.stringify(data)
})

// 获取 物流记录 列表
export const GetLogisticsRecordList = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsInformation/queryByApp.do',
  data: JSON.stringify(data)
})

// 添加、修改 物流信息
export const AddLogisticsInfo = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsInformation/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 物流信息
export const DelLogisticsInfo = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsInformation/del.do',
  data: JSON.stringify(data)
})

// 获取 合同模板
export const GetContractTemplateAll = (data) => request({
  method: 'post',
  url: '/WarehouseOrderTemplate/queryByCondition.do',
  data: JSON.stringify(data)
})

// 添加、修改 合同模板
export const AddContractTemplate = (data) => request({
  method: 'post',
  url: '/WarehouseOrderTemplate/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelContractTemplate = (data) => request({
  method: 'post',
  url: '/WarehouseOrderTemplate/delete.do',
  data: JSON.stringify(data)
})

// 获取 合同模板 内容
export const GetContractTemplate = (data) => request({
  method: 'post',
  url: '/WarehouseOrderTemplate/queryContent.do',
  data: JSON.stringify(data)
})

// 获取订单合同
export const GetOrderContract = (data) => request({
  method: 'post',
  url: '/WarehouseOrderTemplate/queryByOrderFormId.do',
  data: JSON.stringify(data)
})



// 新增 其他项 名称
export const AddOtherName = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/addItemName.do',
  data: JSON.stringify(data)
})

// 新增 其他项 型号
export const AddOtherModel = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/addItemModel.do',
  data: JSON.stringify(data)
})

// 删除 其他项 
export const DelOtherItem = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/deleteItem.do',
  data: JSON.stringify(data)
})

// 获取 其他项 名称
export const GetOtherName = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/queryItemName.do',
  data: JSON.stringify(data)
})

// 获取 其他项 型号
export const GetOtherModel = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormOtherItem/queryItemModel.do',
  data: JSON.stringify(data)
})

// 替换订单设备
export const ReplacOrderDevice = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/replaceData.do',
  data: JSON.stringify(data)
})

// 撤销订单
export const RevokeOrder = (data) => request({
  method: 'post',
  url: '/WarehouseOrderForm/revoke.do',
  data: JSON.stringify(data)
})

// 撤销记录
export const RevokeOrderRecord = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormRevokeRecord/queryByCondition.do',
  data: JSON.stringify(data)
})

// 编辑 撤销记录
export const EditRevokeOrderRecord = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormRevokeRecord/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 撤销记录
export const DelRevokeOrderRecord = (data) => request({
  method: 'post',
  url: '/WarehouseOrderFormRevokeRecord/delete.do',
  data: JSON.stringify(data)
})

// 获取 发货信息 列表
export const GetShipmentRecordList = (data) => request({
  method: 'post',
  url: '/WarehouseAppFormShipmentRecord/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增、修改 发货信息
export const AddShipmentRecord = (data) => request({
  method: 'post',
  url: '/WarehouseAppFormShipmentRecord/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 发货信息
export const DelShipmentRecord = (data) => request({
  method: 'post',
  url: '/WarehouseAppFormShipmentRecord/del.do',
  data: JSON.stringify(data)
})

// 获取 发货信息 详情
export const GetShipmentRecordItem = (data) => request({
  method: 'post',
  url: '/WarehouseAppFormShipmentRecord/queryById.do',
  data: JSON.stringify(data)
})

// 查询 发货数量
export const GetShipmentCount = (data) => request({
  method: 'post',
  url: '/WarehouseAppFormShipmentRecord/queryAllCount.do',
  data: JSON.stringify(data)
})

// 获取供应商信息和收货人信息
export const GetSendAndRecipient = (data) => request({
  method: 'post',
  url: '/WarehouseAppFormShipmentRecord/querySendAndRecipient.do',
  data: JSON.stringify(data)
})

// 删除订单设备
export const DelOrderDevice = (data) => request({
  method: 'post',
  url: '/WarehousePurchaseApplicationFormDeviceInfo/delete.do',
  data: JSON.stringify(data)
})