<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>Report Template</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label"></div>
      <el-input
        class="search-input"
        size="small"
        v-model="searchVal"
        placeholder="Search"
        clearable
      ></el-input>
      <div class="option-box">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          node-key="id"
          ref="tree"
          @check="check"
          show-checkbox
          default-expand-all
          check-on-click-node
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
          >
            <i :class="{'el-icon-folder-opened' : !data.leaf, 'el-icon-document' : data.leaf}"></i>
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <!-- <el-col
          :offset="16"
          :span="8"
        >
          <el-button
            size="small"
            @click="dialogClose"
          >OK</el-button>
        </el-col> -->
      </el-row>
    </div>
    <div
      class="side"
      @mousedown.stop="sideMove"
      ref="side"
    ></div>
  </div>
</template>

<script>
// 接口
import { GetReportTree } from '@/api/reportGroup'
export default {
  props: ['visible', 'data', 'zindex'],
  data () {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeIndex: 0,
      queryNode: null,
      checkedId: [],

      searchVal: '',
      mode: 1,
    }
  },
  watch: {
    visible: function (newVal) {
      if (newVal) {
        if (!!this.data.treeId) {
          this.$refs.tree.setCheckedKeys([this.data.treeId]);
        } else {
          this.$refs.tree.setCheckedKeys([]);
        }
      }
    },
    '$route': function (to, from) {
      if (to.name === 'CounterMode' && this.mode == 1 || to.name === 'DiningMode' && this.mode == 2 || to.name === 'UserDefined' && this.mode == 3) {
        this.getTreeData();
      }
    },
    searchVal (val) {
      this.$refs.tree.filter(val);
    },
    treeData: function () {
      if (!!this.data.treeId) {
        this.$refs.tree.setCheckedKeys([this.data.treeId]);
      }
    }
  },
  created () {
    let viewName = this.$route.name;
    switch (viewName) {
      case 'CounterMode':
        this.mode = 1;
        break;
      case 'DiningMode':
        this.mode = 2;
        break;
      case 'UserDefined':
        this.mode = 3;
        break;
    }
    this.getTreeData();
  },
  methods: {
    dialogClose () {
      this.$emit('update:visible', false);
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    getTreeData () {
      let param = {
        mode: this.mode,
      }
      this.treeIndex = 0;
      GetReportTree(param).then(res => {
        let id = this.$route.query.templatesId;
        this.treeData = res
        this.setDataId(this.treeData)

        if (!!id) {
          this.getTemplate(this.treeData, id);
          if (this.queryNode !== null) {

            this.$refs.tree.setCheckedKeys([]);
            this.$refs.tree.setCheckedKeys([this.queryNode.id]);

            this.$emit('submit', Object.assign(this.data, {
              con: this.queryNode.label,
              treeId: this.queryNode.id,
              val: {
                id: this.queryNode.id.substr(4),
                name: this.queryNode.label,
                mainLanguage: this.queryNode.mainLanguage,
                subLanguage: this.queryNode.subLanguage,
              }
            }));
          }
        } else {
          this.$refs.tree.setCheckedKeys(this.checkedId);
        }
      })
    },
    check (e) {
      this.$refs.tree.setCheckedKeys([]);
      if (e.leaf) {

        this.$refs.tree.setCheckedKeys([e.id]);
        this.checkedId = [e.id];
        this.$emit('submit', Object.assign(this.data, {
          con: e.label,
          treeId: e.id,
          val: {
            id: e.id.substr(4),
            name: e.label,
            mainLanguage: e.mainLanguage,
            subLanguage: e.subLanguage,
          }
        }));
        this.dialogClose();
      } else {
        this.$refs.tree.setCheckedKeys(this.checkedId);
      }
    },
    // 给数据设置id
    setDataId (data) {
      data.forEach(item => {
        item.id = (++this.treeIndex).toString().padStart(4, '0') + item.id;
        if (item.children !== undefined && item.children.length > 0)
          this.setDataId(item.children);
      })
    },
    getTemplate (data, _id) {
      data.some(item => {
        if (item.id.substr(4) === _id) {
          this.queryNode = item;
          return true;
        }
        if (item.children !== undefined && item.children.length > 0)
          this.getTemplate(item.children, _id);
      })
    },

    sideMove (e) {
      let el = this.$refs.side;
      let disx = e.pageX - el.offsetLeft;
      document.onmousemove = function (e) {
        if (e.pageX - disx < 200) return;
        el.parentElement.style.width = e.pageX - disx + 'px';
      }
      document.onmouseup = function () {
        document.onmousemove = document.onmouseup = null;
      }
    },
    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
</style>