<template>
  <div
    class="model-wrap"
    ref="modelWrap"
  >
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
      </div>
    </el-row>
    <el-tabs
      v-model="activeName"
      type="card"
    >
      <el-tab-pane
        :label="$t('dataMaintenance.systemType.tab1')"
        name="tab1"
      >
        <device-type
          :items="items"
          :table-height="tableHeight"
        ></device-type>
      </el-tab-pane>
      <!-- <el-tab-pane
        :label="$t('dataMaintenance.systemType.tab2')"
        name="tab2"
      >
        <config-type
          :item="item"
          :table-height="tableHeight"
        ></config-type>
      </el-tab-pane> -->
      <el-tab-pane
        :label="$t('dataMaintenance.systemType.tab3')"
        name="tab3"
      >
        <fault-phenomenon
          :items="items"
          :table-height="tableHeight"
        ></fault-phenomenon>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
// 组件
import DeviceType from './DeviceType'
// import ConfigType from './ConfigType'
import FaultPhenomenon from './FaultPhenomenon'
export default {
  props: ['items', 'countProp'],
  components: {
    DeviceType,
    // ConfigType,
    FaultPhenomenon,
  },
  data () {
    return {
      activeName: 'tab1',
      name: '',
      tableHeight: null,
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.name = '';
      } else {
        this.name = this.items.length === 1 ? this.items[0].name : `${this.items[0].name}…`
      }
    },
    'activeName': function () {
      if (this.activeName === 'tab1') {
        this.$emit('update:countProp', 'count2');
      } else {
        this.$emit('update:countProp', 'count');
      }
    },
  },
  mounted () {
    this.tableHeight = this.$refs.modelWrap.clientHeight - 46 - 56 - 46 - 42
  },
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";
.model-wrap {
  height: calc(100% - 55px);
}
</style>