<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('addOrder.title2')"
      :visible.sync="dialog.visible"
      center
      width="1000px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
        label-position="left"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="可用库存/预分配/已入库">
              <div class="display-flex">
                <el-input
                  disabled
                  v-model="dialog.inventoryCount"
                ></el-input>
                <el-input
                  disabled
                  v-model="dialog.allPreCount"
                ></el-input>
                <el-input
                  disabled
                  v-model="data.inCount"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="出库1/2/3">
              <div class="display-flex">
                <el-input
                  disabled
                  v-model="data.outCount"
                ></el-input>
                <el-input
                  disabled
                  v-model="data.outCount2"
                ></el-input>
                <el-input
                  disabled
                  v-model="data.outCount3"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="借调补仓">
              <el-radio-group v-model="dialog.isSecondment">
                <el-radio :label="1">Yes</el-radio>
                <el-radio :label="0">No</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('addOrder.label1')">
              <el-input
                disabled
                v-model="data.appCount"
              ></el-input>
            </el-form-item>
            <el-form-item label="已预分配库存数量">
              <div class="display-flex">
                <el-input
                  disabled
                  v-model="dialog.preCount"
                ></el-input>
                <el-input-number
                  :controls="false"
                  v-model="dialog.number"
                  :min="0"
                  :max="dialog.maxPreCount"
                ></el-input-number>
                <el-button @click="okClick">OK</el-button>
              </div>
            </el-form-item>
            <el-form-item :label="$t('addOrder.label5')">
              <el-input
                v-model="dialog.orderCount"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="待订货数量">
              <el-input
                v-model="dialog.unorderCount"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('addOrder.label6')">
              <el-input-number
                size="medium"
                :controls="false"
                :max="dialog.numberMax"
                :min="0"
                v-model="dialog.count"
              ></el-input-number>
            </el-form-item>
            <el-form-item :label="$t('addOrder.label7')">
              <div class="display-flex">
                <el-input-number
                  v-model="dialog.singlePrice"
                  :controls="false"
                  :min="0"
                ></el-input-number>
                <el-select
                  size="small"
                  v-model="dialog.singlePriceUnit"
                  filterable
                >
                  <el-option
                    v-for="item in $global.currencysData"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="单位">
              <el-select
                v-model="dialog.unit"
                filterable
              >
                <el-option
                  v-for="item in units"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="设备名称">
              <el-input
                v-model="data.deviceName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="设备品牌">
              <el-input
                v-model="data.brandName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="规格型号">
              <el-input
                v-model="data.deviceModel"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="详细规格"
              prop="detailed"
            >
              <el-input
                type="textarea"
                v-model="dialog.detailed"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remark"
            >
              <el-input
                type="textarea"
                v-model="dialog.remark"
              ></el-input>
            </el-form-item>
            <div class="recipient-box">
              <div class="label-box">
                <span>
                  收货信息
                </span>
                <div class="btn-box">
                  <el-button
                    size="small"
                    @click="addItem"
                  >增加</el-button>
                  <el-button
                    size="small"
                    @click="delItem"
                  >删除</el-button>
                </div>
              </div>
              <table class="recipient-table">
                <tr>
                  <th></th>
                  <th>数量</th>
                  <th>收货地址</th>
                  <th>联系人</th>
                  <th>电话</th>
                </tr>
                <tr
                  v-for="(item,index) in dialog.deliveryInfos"
                  :key="index"
                >
                  <td>
                    <el-checkbox v-model="item.checked"></el-checkbox>
                  </td>
                  <td>
                    <el-input-number
                      size="small"
                      v-model="item.count"
                      :controls="false"
                      :min="0"
                    ></el-input-number>
                  </td>
                  <td>
                    <my-select
                      size="small"
                      :data="addresss"
                      :value.sync="item.addressId"
                      :prop="{
                        label:'name',
                        value:'id'
                      }"
                      @change="addressChange(item,true)"
                    ></my-select>
                  </td>
                  <td>
                    <my-select
                      size="small"
                      :disabled="!item.addressId"
                      :data="item.recipients"
                      :value.sync="item.consigneeId"
                      :prop="{
                        label:'name',
                        value:'id'
                      }"
                      @change="consigneeChange(item)"
                    ></my-select>
                  </td>
                  <td>
                    <el-input
                      size="small"
                      v-model="item.phone"
                    ></el-input>
                  </td>
                </tr>
              </table>
            </div>
          </el-col>
        </el-row>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import { GetReceiptContactList } from '@/api/warehouse/dataMaintenance/receiptAddress'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import {
  DelReceipt,
  GetReceiptList,
  AddReceipt,
} from '@/api/warehouse/purchaseRequisition'

export default {
  components: {
    MySelect
  },
  props: ['visible', 'data', 'total'],
  data () {
    return {
      addresss: [],
      recipients: [],
      units: [],
      dialog: {
        visible: false,
        loading: false,
        title: '订购数量和价格编辑',
        count: 0,
        singlePrice: 0,
        singlePriceUnit: 1,
        stockout: 0,
        orderCount: 0,
        numberMax: 0,
        id: null,

        isSecondment: 0,
        inventoryCount: 0,
        allPreCount: 0,
        preCount: 0,
        unorderCount: 0,
        number: 0,
        maxPreCount: 0,

        addressId: '',
        consigneeId: '',
        detailed: '',
        remark: '',

        unit: '',
        deliveryInfos: [],
      },
      rules: {
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.orderCount = this.data.orderCount + this.total - this.data.count;
        Object.assign(this.dialog, {
          id: this.data.applicationFormInfoId,
          preCount: this.data.preCount,
          allPreCount: this.data.allPreCount,
          inventoryCount: this.data.inventoryCount,
          number: 0,
          count: this.data.count,
          singlePrice: this.data.singlePrice,
          singlePriceUnit: this.data.singlePriceUnit,

          numberMax: this.data.appCount - this.dialog.orderCount - this.data.preCount,

          isSecondment: this.data.isSecondment || 0,
          unorderCount: this.data.appCount - this.dialog.orderCount - this.data.preCount,
          maxPreCount: this.data.inventoryCount + this.data.preCount,
          addressId: this.data.addressId,
          consigneeId: this.data.consigneeId,
          detailed: this.data.detailed,
          remark: this.data.remark,
          unit: this.data.unit,
          deliveryInfos: this.data.deliveryInfos,
        })
        this.getReceiptList();

        // 获取收货地址
        if (this.addresss.length === 0) GetDataFieldAll({ parent: '100000000000000000000000000000000014' }).then(res => this.addresss = res);
        // 获取单位
        if (this.units.length === 0) GetDataFieldAll({ parent: '100000000000000000000000000000000011' }).then(res => this.units = res)

        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    okClick () {
      this.dialog.unorderCount = this.dialog.unorderCount + this.dialog.preCount - this.dialog.number;
      this.dialog.unorderCount = this.dialog.unorderCount < 0 ? 0 : this.dialog.unorderCount;
      this.dialog.numberMax = this.dialog.numberMax + this.dialog.preCount - this.dialog.number;
      this.dialog.count = this.dialog.count > this.dialog.numberMax ? this.dialog.numberMax : this.dialog.count;

      this.dialog.inventoryCount = this.dialog.inventoryCount + this.dialog.preCount - this.dialog.number;
      this.dialog.allPreCount = this.dialog.allPreCount - this.dialog.preCount + this.dialog.number;
      this.dialog.preCount = this.dialog.number;
      this.dialog.number = 0;
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        if (!!this.data && !!this.data.id) {
          this.saveReceipt(this.submitData);
        } else {
          this.submitData();
        }
      });
    },
    submitData () {
      let data = JSON.parse(JSON.stringify(this.dialog));
      this.$emit('submit', data)
      this.dialog.visible = false;
    },
    addressChange (data, reset) {
      if (!/.{8}(-.{4}){3}-.{12}/.test(data.addressId)) {
        data.consigneeId = '';
        data.recipients = [];
        return
      }
      if (reset) {
        data.consigneeId = '';
      }
      let param = {
        limit: 9999,
        page: 1,
        search: '',
        addressId: data.addressId,
      }
      GetReceiptContactList(param).then(res => data.recipients = res.list)
    },
    consigneeChange (data) {
      if (!/.{8}(-.{4}){3}-.{12}/.test(data.addressId)) return;
      let item = data.recipients.find(item => item.id === data.consigneeId);
      data.phone = !!item ? item.phoneNumber : '';
    },
    addItem () {
      this.dialog.deliveryInfos.push({
        checked: false,
        count: 0,
        addressId: '',
        consigneeId: '',
        phone: '',
        recipients: [],
      })
    },
    delItem () {
      let param = {
        id: this.dialog.deliveryInfos.filter(item => item.checked).map(item => item.id).join(',')
      }
      DelReceipt(param).then(res => {
        if (res.success) {
          this.dialog.deliveryInfos = this.dialog.deliveryInfos.filter(item => !item.checked)
        }
      })
    },

    // 获取收货信息
    getReceiptList () {
      if (!this.data.id) return;

      GetReceiptList({ appFormDeviceId: this.data.id }).then(res => {
        this.dialog.deliveryInfos = res.map(item => {
          item.checked = false;
          item.recipients = [];
          return item;
        });
        this.dialog.deliveryInfos.forEach(item => {
          this.addressChange(item, false)
        })
      })
    },
    // 保存收货信息
    saveReceipt (fun) {
      let param = this.dialog.deliveryInfos.map(item => {
        item.appFormDeviceId = this.data.id;
        // 验证是否是新收货地址
        if (!/.{8}(-.{4}){3}-.{12}/.test(item.addressId)) {
          item.address = item.addressId;
          item.addressId = '';
        }
        // 验证是否是新收件人
        if (!/.{8}(-.{4}){3}-.{12}/.test(item.consigneeId)) {
          item.consignee = item.consigneeId;
          item.consigneeId = '';
        }
        return item
      })

      AddReceipt(param).then(res => {
        if (res.success) {
          this.dialog.deliveryInfos = JSON.parse(res.message).map(item => {
            item.checked = false;
            item.recipients = [];
            return item;
          });
          fun();
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
  .el-select:first-child,
  .el-button:first-child,
  .el-input:first-child,
  .el-input-number:first-child {
    margin-left: 0px;
  }
  .el-select,
  .el-button,
  .el-input,
  .el-input-number {
    flex: 1;
    margin-left: 10px;
  }
  .el-button {
    flex: 1;
    margin-left: 10px;
    padding: 0;
  }
}
.el-input-number,
.el-input {
  width: 100%;
  ::v-deep .el-input__inner {
    padding: 0 5px;
    text-align: center;
  }
}

.el-textarea {
  ::v-deep .el-textarea__inner {
    height: 84px;
  }
}
.el-form-item {
  ::v-deep .el-form-item__label {
    float: inherit;
  }
}
.recipient-box {
  .label-box {
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
  }
  .recipient-table {
    width: 100%;
    margin-top: 10px;
  }
}
.recipient-table {
  .el-input-number,
  .el-input {
    width: 100%;
    ::v-deep .el-input__inner {
      padding: 0 5px;
      text-align: left;
    }
  }
}
</style>