<template>
  <div class="wrap">
    <el-card>
      <el-container>
        <el-aside width="350px">
          <el-row>
            <el-button-group class="input-group">
              <el-input
                v-model="treeSerachVal"
                class="search-input"
                size="medium"
                placeholder="Search"
                clearable
              ></el-input>
              <el-button
                size="medium"
                @click="checkAll"
              >{{ $t("stores.btn3") }}</el-button>
            </el-button-group>
          </el-row>
          <el-tree
            :class="{'all': allState}"
            :data="treeData"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            v-loading="treeLoading"
            node-key="id"
            ref="tree"
            highlight-current
            @node-click="nodeClick"
          ></el-tree>
        </el-aside>
        <el-main>
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="$t('maintenance.tab1')"
              name="Calendar"
            >
              <calendar
                ref="calendar"
                :active="activeName"
                :branch="currentNode.id"
              ></calendar>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('maintenance.tab2')"
              name="Maintenance"
            >
              <Maintenance-table
                ref="maintenance"
                :active="activeName"
                :branch="currentNode"
              ></Maintenance-table>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('maintenance.tab3')"
              name="Record"
            >
              <record
                ref="record"
                :active="activeName"
                :branch="currentNode.id"
              ></record>
            </el-tab-pane>

          </el-tabs>
        </el-main>
      </el-container>
    </el-card>
  </div>
</template>

<script>
// 组件
import Calendar from './components/Calendar'
import MaintenanceTable from './components/Maintenance'
import Record from './components/Record'
// 接口
import {
  GetStoreTree,
} from "@/api/stores";
export default {
  name: 'Maintenance',
  components: {
    Calendar,
    MaintenanceTable,
    Record,
  },
  data () {
    return {
      permit: {
      },
      treeSerachVal: '',
      treeLoading: false,
      treeData: [],
      currentNode: {},
      allState: true,

      activeName: 'Calendar',
    }
  },
  watch: {
    treeSerachVal: function (val) {
      this.$refs.tree.filter(val);
    },
  },
  created () {
    this.getTreeData();
    this.dealPermission();
  },
  methods: {
    getTreeData () {
      let param = {
        type: '2'
      };
      this.treeLoading = true;
      GetStoreTree(param).then(res => {
        this.treeData = res;
        this.treeLoading = false;
      }).catch(error => {
        this.treeLoading = false;
      });
    },

    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 全选
    checkAll () {
      this.allState = true;
      this.currentNode = {};
    },
    // 点击树节点
    nodeClick (node) {
      this.allState = false;
      this.currentNode = node;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.update = permission.includes('ProjectContractUpdate');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-container {
  height: 100%;
}
.el-aside {
  margin-right: 20px;
}
.el-main {
  padding: 0;
}

.el-tree {
  height: calc(100% - 46px);
  border: 1px solid #e4e4e4;
  overflow: auto;
  &.all {
    background-color: #f0f7ff;
  }
}

.el-tabs {
  height: 100%;
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
</style>