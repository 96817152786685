<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="1000px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialog"
        class="group-form"
        size="medium"
        label-width="120px"
      >
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label1')"
              prop="name"
            >
              <el-input
                disabled
                v-model="groupForm.itemName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label2')"
              prop="model"
            >
              <el-input
                disabled
                v-model="groupForm.model"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label3')"
              prop="brand"
            >
              <el-input
                disabled
                v-model="groupForm.brand"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newQuotation.label4')"
              prop="type"
            >
              <el-input
                disabled
                v-model="groupForm.type"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('newQuotation.tab1')"
          name="tab1"
        >
          <quotation-list
            ref="quotationList"
            :data="quotationData"
            :total="total"
            :permit="permit"
            @tableChange="initData"
          ></quotation-list>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('newQuotation.tab2')"
          name="tab2"
        >
          <eol-alternative
            ref="eolAlternative"
            :data="groupForm"
            :permit="permit"
          ></eol-alternative>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('newQuotation.tab3')"
          name="tab3"
        >
          <specifications
            ref="specifications"
            :data="groupForm.specifications"
          ></specifications>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import QuotationList from './QuotationList'
import EolAlternative from './EolAlternative'
import Specifications from './Specifications'
// 接口
import {
  GetDeviceNameAll,
  GetDeviceModelAll,
} from '@/api/warehouse/dataMaintenance/deviceType'
import {
  EditQuotationDevice,
  GetQuotationDetailsList,
} from '@/api/warehouse/quotation'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    QuotationList,
    EolAlternative,
    Specifications,
  },
  props: ['visible', 'data'],
  data () {
    return {
      permit: {
        add: true,
        edit: true,
        del: true,
      },
      dialog: {
        visible: false,
        title: '查看商品信息',
      },
      rules: {},
      groupForm: {
        id: null,
        itemName: '',
        model: '',
        deviceTypeId: '',
        brand: '',
        brandId: '',
        type: '',
        typeInfoId: '',
        discontinue: '',
        discontinueDate: null,
        specifications: '',
      },
      activeName: 'tab1',
      total: 0,
      quotationData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;

        this.groupForm.id = this.data.infoId;
        this.groupForm.specifications = ''
        this.initData();

        this.$nextTick(() => {
          this.$refs.eolAlternative.getTableData();
        })
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.dealPermission();
  },
  methods: {
    initData (size, current) {
      let param = {
        limit: size || 10,
        page: current || 1,
        infoId: this.groupForm.id,
      }
      GetQuotationDetailsList(param).then(res => {
        this.quotationData = res.body.list.map(item => {
          item.inquiryDate = formatDate(item.inquiryDate, 'yyyy-MM-dd')
          return item;
        });
        this.total = res.total;
        Object.assign(this.groupForm, res.info);
        localStorage.quotationData = JSON.stringify(this.groupForm);
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('QuotationSearch');
        this.permit.add = permission.includes('QuotationAdd');
        this.permit.edit = permission.includes('QuotationEdit');
        this.permit.del = permission.includes('QuotationDelete');

      }
    }
  }
}
</script>

<style>
</style>