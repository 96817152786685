import request from '@/utils/request'

// 获取列表
export const GetMaintenanceFileList = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改
export const AddMaintenanceFile = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/addOrUpdate.do',
  data: JSON.stringify(data)
})
// 删除
export const DelMaintenanceFile = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/del.do',
  data: JSON.stringify(data)
})
// 创建副本
export const CopyMaintenanceFile = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/createACopy.do',
  data: JSON.stringify(data)
})

// 复制
export const CopyToMaintenanceFile = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/copyTo.do',
  data: JSON.stringify(data)
})

// 获取表格元素列表
export const GetTableElementList = (data) => request({
  method: 'post',
  url: '/TableElements/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加表格元素列表
export const AddTableElement = (data) => request({
  method: 'post',
  url: '/TableElements/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 批量添加
export const BatchAddTableElement = (data) => request({
  method: 'post',
  url: '/TableElements/addOrUpdateList.do',
  data: JSON.stringify(data)
})

// 获取单项表格元素详情
export const GetTableElementItem = (data) => request({
  method: 'post',
  url: '/TableElements/queryById.do',
  data: JSON.stringify(data)
})

// 删除表格元素
export const DelTableElement = (data) => request({
  method: 'post',
  url: '/TableElements/del.do',
  data: JSON.stringify(data)
})

// 查询checkItem
export const GetCheckItem = () => request({
  method: 'post',
  url: '/TableContent/queryCheckItem.do',
})

// 查询MainType
export const GetMainType = () => request({
  method: 'post',
  url: '/TableContent/queryMainType.do',
})

// 删除
export const DelCheckListItem = (data) => request({
  method: 'post',
  url: '/CheckListItem/del.do',
  data: JSON.stringify(data)
})

// 删除
export const DelTableContent = (data) => request({
  method: 'post',
  url: '/TableContent/del.do',
  data: JSON.stringify(data)
})

// 日历表
export const GetCalendar = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/queryCalendar.do',
  data: JSON.stringify(data)
})

// 日历表2
export const GetCalendar2 = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/queryCalendar2.do',
  data: JSON.stringify(data)
})

// 日历记录
export const GetCalendarRecord = (data) => request({
  method: 'post',
  url: '/ConfirmOrderMaintenance/queryByList.do',
  data: JSON.stringify(data)
})

// 查询记录
export const GetRecord = (data) => request({
  method: 'post',
  url: '/MaintenanceFile/queryByRecord.do',
  data: JSON.stringify(data)
})


// 获取表格元素所有列表
export const GetTableElementAll = (data) => request({
  method: 'post',
  url: '/TableElements/queryByCondition.do',
  data: JSON.stringify(data)
})


// 添加、修改
export const AddCheckListItem = (data) => request({
  method: 'post',
  url: '/CheckListItem/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 添加、修改
export const AddTableContent = (data) => request({
  method: 'post',
  url: '/TableContent/addOrUpdateList.do',
  data: JSON.stringify(data)
})

// 
export const GetCheckList = (data) => request({
  method: 'post',
  url: '/TableData/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加、修改一行数据
export const AddRowData = (data) => request({
  method: 'post',
  url: '/TableData/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除一行数据
export const DelRowData = (data) => request({
  method: 'post',
  url: '/TableData/del.do',
  data: JSON.stringify(data)
})

// 添加、修改多行数据
export const AddRowDataList = (data) => request({
  method: 'post',
  url: '/TableData/addOrUpdateList.do',
  data: JSON.stringify(data)
})
