<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <div class="left-box">
          <el-date-picker
            size="medium"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            unlink-panels
            value-format="yyyy-MM-dd"
            @change="searchData"
          >
          </el-date-picker>
          <el-button-group class="input-group">
            <el-input
              :disabled="!permit.search"
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              :disabled="!permit.search"
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
        <div class="right-box">
          <span>审批</span>
          <el-select
            size="medium"
            v-model="approvalSymbol"
            @change="searchData"
            collapse-tags
            multiple
            clearable
            placeholder="所有"
          >
            <el-option
              v-for="(item,index) in approvalSymbols"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <span>订货</span>
          <el-select
            size="medium"
            v-model="orderStatus"
            @change="searchData"
            collapse-tags
            multiple
            clearable
            placeholder="所有"
          >
            <el-option
              value="待处理"
              label="待处理"
            ></el-option>
            <el-option
              value="已订货-部分"
              label="已订货-部分"
            ></el-option>
          </el-select>
        </div>
      </div>
    </el-row>
    <el-row>
      <el-button-group class="input-group">
        <el-button
          :disabled="!permit.add"
          size="medium"
          @click="showAdd"
        >{{$t('operation.add')}}</el-button>
        <el-button
          :disabled="!permit.delAll && !permit.delSelf"
          size="medium"
          @click="delItem"
        >{{$t('operation.del')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="printApply"
        >{{$t('purchaseRequisition.btn1')}}</el-button>
        <el-button
          :disabled="!permit.resubmit"
          size="medium"
          @click="resubmitApply"
        >{{$t('purchaseRequisition.btn2')}}</el-button>
        <el-button
          :disabled="!permit.request || !$global.ExamineData.success || tableSelected.length > 1 || tableSelected.length === 0 ||(tableSelected.length === 1 && tableSelected[0].approvalStatus === 2)"
          size="medium"
          @click="showExamine"
        >审核</el-button>
      </el-button-group>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="createTime"
          :label="$t('purchaseRequisition.col1')"
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          :label="$t('purchaseRequisition.col13')"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.approvalStatus === null || scope.row.approvalStatus === 0">待审批</div>
            <div v-else>
              <div>{{scope.row.approvalSymbol}}</div>
              <div>{{scope.row.opName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="230"
          sortable
          prop="applicationNumber"
          :label="$t('purchaseRequisition.col2')"
        >
        </el-table-column>
        <el-table-column
          min-width="300"
          sortable
          prop="projectName"
          :label="$t('purchaseRequisition.col3')"
        >
          <template slot-scope="scope">
            <div
              class="cell-style"
              v-for="(item,index) in scope.row.projectName"
              :key="index"
            >{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="230"
          sortable
          prop="quotationNumber"
          :label="$t('purchaseRequisition.col4')"
        >
          <template slot-scope="scope">
            <div
              class="cell-style"
              v-for="(item,index) in scope.row.quotationNumber"
              :key="index"
            >{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          prop="quotationType"
          :label="$t('purchaseRequisition.col5')"
        >
          <template slot-scope="scope">
            <div
              class="cell-style"
              v-for="(item,index) in scope.row.quotationType"
              :key="index"
            >{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="customerApproval"
          :label="$t('purchaseRequisition.col6')"
        >
          <template slot-scope="scope">
            <div
              class="cell-style"
              v-for="(item,index) in scope.row.customerApproval"
              :key="index"
            >{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="purchaseUser"
          :label="$t('purchaseRequisition.col7')"
        >
        </el-table-column>

      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 审核弹框 -->
    <examine-dialog
      :visible.sync="ExamineDialogVisible"
      :id="applyId"
      @update="getTableData"
    ></examine-dialog>
  </div>
</template>

<script>
// 组件
import ExamineDialog from './ExamineDialog'
// 接口
import {
  GetPurchaseRequisitionList,
  DelPurchaseRequisition,
  ResubmitApply,
} from '@/api/warehouse/purchaseRequisition'
import { GetApprovalWorkflowItem } from '@/api/warehouse/approvalWorkflow'
export default {
  components: {
    ExamineDialog
  },
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      permit: {
        search: true,
        add: true,

        delAll: true,
        delSelf: true,
        resubmit: true,
        request: true,
      },
      ExamineDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      dateRange: null,
      searchVal: '',

      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      applyId: null,
      orderStatus: [],
      approvalSymbols: [],
      approvalSymbol: [],
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'PurchaseRequisition' && this.active === 'tab1') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    }
  },
  created () {
    this.dealPermission();
    this.getApproval();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        startDate: this.dateRange !== null ? this.dateRange[0] + ' 00:00:00' : '',
        endDate: this.dateRange !== null ? this.dateRange[1] + ' 23:59:59' : '',
        orderStatus: this.orderStatus.join(','),
        approvalSymbols: [],
        opNames: [],
      }

      if (this.approvalSymbol.length > 0) {
        this.approvalSymbol.forEach(item => {
          let temp = item.split('-');
          param.approvalSymbols.push(temp[0]);
          param.opNames.push(temp[1]);
        })
      }
      param.approvalSymbols = param.approvalSymbols.join(',')
      param.opNames = param.opNames.join(',')

      this.tableLoading = true;
      GetPurchaseRequisitionList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (!!item.projectName) item.projectName = Array.from(new Set(item.projectName.split(',')));
          if (!!item.quotationNumber) item.quotationNumber = Array.from(new Set(item.quotationNumber.split(',')));
          if (!!item.quotationType) item.quotationType = item.quotationType.split(',');
          if (!!item.customerApproval) item.customerApproval = item.customerApproval.split(',');
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 获取审批条件
    getApproval () {
      let param = { id: "27335f41-cc9f-4e4e-affe-e8e0a84539b2" }
      GetApprovalWorkflowItem(param).then(res => {
        let approvalSymbol = [];
        this.approvalSymbols = [{
          label: '待审批',
          value: '待审批-待审批',
        }]
        res.workflowApprovalSymbolList.forEach(item => {
          let step = item.step - 1;
          if (!approvalSymbol[step]) {
            approvalSymbol[step] = item.approvalSymbol;
            item.optionList.forEach(opName => {
              this.approvalSymbols.push({
                label: `${item.approvalSymbol}-${opName.name}`,
                value: `${item.approvalSymbol}-${opName.name}`,
              })
            })

          }
        })
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.$router.push({ path: '/home/allocateGoods', query: { type: 2 } })
    },
    // 显示修改
    dblclickEdit (e) {
      this.$router.push({ path: '/home/allocateGoods', query: { id: e.id, type: 2 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.some(item => !!item.hasOrder || !!item.hasPre)) return this.$message.warning('已订货或已预分配库存不可删除！');

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelPurchaseRequisition(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },
    // 打印
    printApply () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      window.open(`${this.baseUrl}/Export/exportPurchase3.do?id=${this.tableSelected[0].id}&timeZone=${timeZone}`);

    },
    //重新提交
    resubmitApply () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let param = {
        id: this.tableSelected[0].id
      }
      ResubmitApply(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.edit'));
          this.getTableData();
        }
      })
    },
    // 显示审核
    showExamine () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.applyId = this.tableSelected[0].id;
      this.ExamineDialogVisible = true;
    },

    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('PurchaseRequisitionvSearch');
        this.permit.add = permission.includes('PurchaseRequisitionvAdd');
        // this.permit.editAll = permission.includes('PurchaseRequisitionEdit-Allitems');
        // this.permit.editSelf = permission.includes('PurchaseRequisitionEdit-Toonlyallowausertoedithisownitems')
        this.permit.delAll = permission.includes('PurchaseRequisitionDelete-Allitems');
        this.permit.delSelf = permission.includes('PurchaseRequisitionDelete-Toonlyallowausertodeletehisownitems');
        this.permit.resubmit = permission.includes('PurchaseRequisitionResubmit');
        this.permit.request = permission.includes('PurchaseRequisitionRequestApproval');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-wrap {
  justify-content: space-between;
  .el-date-editor {
    margin-right: 10px;
  }
}

.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px - 46px);
}
.cell-style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left-box {
  display: flex;
}
</style>