import Vue from 'vue'
import App from './App'
import store from './vuex/store'
// 路由
import router from './router'
import './router/routerGuards'
import {
  loadRouter
} from './router/loadRouter'

// UI
import ElementUI from 'element-ui'
// 大数据表格
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/index.css'
// 图表
import echarts from '@/utils/initEcharts'
// cookies
import VueCookies from 'vue-cookies'
// 国际化
import i18n from './i18n'
// 子线程
import VueWorker from 'vue-worker'

// 兼容
// import 'babel-polyfill'
// 全局指令
import '@/utils/directives'
// 公共css
import "@/assets/css/common.scss"
// 主题css
import "@/assets/css/themeColor.scss"
// 字体图标
// import "/static/icomoon/style.css"
import "../public/static/icomoon/style.css"
// 公共组件
import '@/utils/components'
// 全局变量
import globalVariable from '@/utils/globalVariable.js'
import {
  getGlobalData
} from '@/utils/getGlobalData.js'
// 全局方法
import {
  sortChange,
  formatTableData
} from '@/utils/common'

Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(UmyUi)
Vue.use(VueWorker)

Vue.prototype.$echarts = echarts
Vue.prototype.$global = globalVariable
Vue.prototype.$formatTableData = formatTableData
Vue.prototype.$sortChange = sortChange

Vue.config.productionTip = false

// 页面刷新，重新设置路由
let token = VueCookies.get('token');
if (!!token && (!!localStorage.permission || !!localStorage.isSuper)) {
  loadRouter();
  getGlobalData();
}

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
