<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            :disabled="!permit.edit"
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
        <col-set @update="getTableData"></col-set>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="dblclickEdit"
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="name"
          :label="$t('organizations.col1')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="description"
          :label="$t('organizations.col2')"
        >
        </el-table-column>
        <el-table-column
          min-width="170"
          sortable
          prop="accessGroupName"
          :label="$t('organizations.col3')"
        >
        </el-table-column>
        <el-table-column
          min-width="120"
          sortable
          prop="createByName"
          :label="$t('organizations.col4')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="createTime"
          :label="$t('organizations.col5')"
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="updateTime"
          :label="$t('organizations.col6')"
          :formatter="$formatTableData"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 添加组织弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="orgnicDialog.title"
      :visible.sync="orgnicDialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="orgnicDialog"
        :rules="rules"
        ref="orgnicForm"
        size="small"
        v-loading="formLoading"
      >
        <el-form-item
          :label="$t('organizations.dialog.label1')"
          prop="name"
        >
          <el-input v-model="orgnicDialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('organizations.dialog.label2')"
          prop="descirption"
        >
          <el-input
            type="textarea"
            :rows="4"
            v-model="orgnicDialog.descirption"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('organizations.dialog.label3')"
          prop="dataAccessGroup"
        >
          <el-select
            :disabled="!permit.editDataAccessGroup"
            v-model="orgnicDialog.dataAccessGroup"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="orgnicDialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submitOrganic"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ColSet from '@/components/ColSet'

import {
  GetOrganizationList,
  AddOrganization,
  DelOrganization,
  GetDataAccessGroupAll,
} from '@/api/organizations'
export default {
  components: {
    ColSet,
  },
  props: ['organizationId', 'data', 'active'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
        editDataAccessGroup: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: true,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      formLoading: false,
      dataAccessGroups: [],
      orgnicDialog: {
        visible: false,
        id: null,
        title: '',
        name: '',
        descirption: '',
        dataAccessGroup: localStorage.accessGroupId,
      },
      rules: {
        name: [{ required: true, message: 'Please enter an Organization Name.', trigger: 'blur' }]
      }
    }
  },
  watch: {
    'orgnicDialog.visible': function (newVal) {
      if (newVal) {
        this.getOption();
      }
    },
    'active': function (newVal) {
      if (newVal === 'Organizations') {
        this.getTableData();
        this.$refs.table.doLayout();
      }
    }

  },
  created () {
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      };
      this.tableLoading = true;
      GetOrganizationList(param).then((res) => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (this.tableData.length !== 0) {
          this.rowClick(this.tableData[0])
        }
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 获取下拉框选项
    getOption () {
      this.formLoading = true;
      Promise.all([
        // 获取数据访问组
        GetDataAccessGroupAll().then(res => this.dataAccessGroups = res),
      ]).then(res => {
        this.formLoading = false;
      })
    },
    showAdd () {
      this.$refs.orgnicForm && this.$refs.orgnicForm.resetFields();
      this.orgnicDialog.id = null;
      this.orgnicDialog.title = this.$i18n.t('organizations.dialog.title1');
      this.orgnicDialog.visible = true;
    },
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item);
    },
    dblclickEdit (item) {
      if (!this.permit.edit) return;
      this.$refs.orgnicForm && this.$refs.orgnicForm.resetFields();
      this.orgnicDialog.title = this.$i18n.t('organizations.dialog.title2');
      this.orgnicDialog.id = item.id;
      this.$nextTick(() => {
        this.orgnicDialog.name = item.name;
        this.orgnicDialog.descirption = item.description;
        this.orgnicDialog.dataAccessGroup = item.accessGroupId;
      })
      this.orgnicDialog.visible = true;
    },

    // 添加或修改
    submitOrganic () {
      this.$refs.orgnicForm.validate(valid => {
        if (!valid) return;

        let param = {
          name: this.orgnicDialog.name,
          description: this.orgnicDialog.descirption,
          accessGroupId: this.orgnicDialog.dataAccessGroup,
        }
        let msg = this.$i18n.t('message.add');
        if (this.orgnicDialog.id) {
          param.id = this.orgnicDialog.id;
          msg = this.$i18n.t('message.edit');
        }

        this.orgnicDialog.loading = true;
        AddOrganization(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.orgnicDialog.visible = false;
            this.getTableData();
          } else {
            if (res.status === 100) this.$message.warning('Organization Name is occupied.')
          }
          this.orgnicDialog.loading = false;
        }).catch(error => {
          this.orgnicDialog.loading = false;

        })
      })
    },
    // 删除 
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(','),
        }
        DelOrganization(param).then(res => {
          if (res.success) {
            if (this.tableSelected.some(item => item.id === this.organizationId))
              this.$emit('update:organizationId', null)

            this.getTableData()
            this.$message.success('deleted successfully.')
          }
        }).catch(error => {

        })
      }).catch(() => { });
    },
    // 点击表格
    rowClick (row, column, event) {
      this.$emit('update:organizationId', row.id)
      this.$emit('update:data', row)
    },
    tableRowClassName ({ row, rowIndex }) {

      if (row.id === this.organizationId) return 'checked-row';
      else return '';
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('OrganizationsSearchOrganizations');
        this.permit.add = permission.includes('OrganizationsAddOrganizations');
        this.permit.edit = permission.includes('OrganizationsEditOrganizations');
        this.permit.del = permission.includes('OrganizationsDeleteOrganizations');
        this.permit.editDataAccessGroup = permission.includes('OrganizationsEditDataAccessGroups(DataPermissions)');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog {
  .el-select {
    width: 100%;
  }
}

.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 42px);
}
</style>