<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showAdd"
            :loading="addLoading"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="importItem"
          >{{$t('newMaintenanceCheckList.checkList.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="moveItem(1)"
          >{{$t('newMaintenanceCheckList.checkList.btn2')}}</el-button>
          <el-button
            size="medium"
            @click="moveItem(2)"
          >{{$t('newMaintenanceCheckList.checkList.btn3')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <!-- stripe
      use-virtual
      show-body-overflow
      show-header-overflow
      :row-height="35" -->
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        :row-class-name="tableRowClassName"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          v-for="(mainItem,mainIndex) in tableHeader"
          :key="mainIndex"
          :label="mainItem.mainType"
        >
          <el-table-column
            v-for="(item,index) in mainItem.children"
            :key="index"
            min-width="130"
            :label="item.checkItem"
          >
            <template slot-scope="scope">
              <div class="input-box">
                <div
                  v-if="![4,7,11,12].includes(item.checkTypeItem.type) && !scope.row[`visible${item.index}`]"
                  class="cell-full"
                  @click="cellClick(scope.row,item.index)"
                >
                  {{scope.row[`text${item.index}`]}}
                </div>
                <div v-else>
                  <template v-if="item.checkTypeItem.type === 1">
                    <el-input
                      size="mini"
                      placeholder="Type here"
                      v-model="scope.row[`content${item.index}`]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    ></el-input>
                  </template>
                  <template v-if="item.checkTypeItem.type === 2">
                    <el-select
                      size="mini"
                      v-model="scope.row[`content${item.index}`]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                      <el-option
                        v-for="option in  item.checkTypeItem.options"
                        :key="option.id"
                        :value="option.id"
                        :label="option.name"
                      ></el-option>
                    </el-select>
                  </template>
                  <template v-if="item.checkTypeItem.type === 3">
                    <el-checkbox-group
                      v-model="scope.row[`content${item.index}`]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                      <el-checkbox
                        v-for="checkbox in item.checkTypeItem.options"
                        :key="checkbox.id"
                        :label="checkbox.id"
                      >{{checkbox.name}}</el-checkbox>
                    </el-checkbox-group>
                  </template>
                  <template v-if="item.checkTypeItem.type === 4">
                    <el-radio-group
                      v-model="scope.row[`content${item.index}`]"
                      @change="saveCell(scope.row,item.index)"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                      <el-radio
                        v-for="radio in item.checkTypeItem.options"
                        :key="radio.id"
                        :label="radio.id"
                      >{{radio.name}}</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-if="item.checkTypeItem.type === 5">
                    <el-date-picker
                      v-if="item.checkTypeItem.options[0].name == 0||item.checkTypeItem.options[0].name == 1|| item.checkTypeItem.options[0].name == 2 || item.checkTypeItem.options[0].name == 3"
                      size="mini"
                      type="date"
                      v-model="scope.row[`content${item.index}`]"
                      :format="dateFormats[item.checkTypeItem.options[0].name]"
                      :value-format="dateFormats[item.checkTypeItem.options[0].name]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                    </el-date-picker>
                    <el-date-picker
                      v-else
                      size="mini"
                      type="month"
                      v-model="scope.row[`content${item.index}`]"
                      :format="dateFormats[item.checkTypeItem.options[0].name]"
                      :value-format="dateFormats[item.checkTypeItem.options[0].name]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                    </el-date-picker>
                  </template>
                  <template v-if="item.checkTypeItem.type === 6">
                    <el-time-picker
                      size="mini"
                      v-model="scope.row[`content${item.index}`]"
                      :format="timeFormats[item.checkTypeItem.options[0].name]"
                      :value-format="timeFormats[item.checkTypeItem.options[0].name]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    ></el-time-picker>
                  </template>
                  <template v-if="item.checkTypeItem.type === 7">
                    {{scope.row.serialNumber}}
                  </template>
                  <template v-if="item.checkTypeItem.type === 8 || item.checkTypeItem.type === 9 || item.checkTypeItem.type === 10">
                    <el-input
                      size="mini"
                      v-model="scope.row[`content${item.index}`]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                      <template slot="append">{{item.checkTypeItem.options[0].name}}</template>
                    </el-input>
                  </template>
                  <template v-if="item.checkTypeItem.type === 11">
                    {{item.checkTypeItem.options[0].name}}
                  </template>
                  <template v-if="item.checkTypeItem.type === 12">
                    <img
                      class="signature"
                      src="/static/image/signature.png"
                    >
                  </template>
                  <template v-if="item.checkTypeItem.type === 13">
                    <el-select
                      size="mini"
                      v-model="scope.row[`content${item.index}`]"
                      @click.native.stop
                      :ref="scope.row[`ref${item.index}`]"
                    >
                      <el-option
                        v-for="option in  item.checkTypeItem.options.filter(option=> 
                          item.filterIndex === null || 
                          !scope.row[`content${item.filterIndex}`] ||
                          (!!originData[scope.row.rowIndex].deviceId && scope.row[`content${item.filterIndex}`] === option.filterName)
                        )"
                        :key="option.id"
                        :value="option.id"
                        :label="option.name"
                      ></el-option>
                    </el-select>
                  </template>
                </div>
                <i
                  v-if="item.relatedDevice"
                  class="el-icon-share"
                  :class="{'checked':!!originData[scope.row.rowIndex].deviceId}"
                  @click="showRelated(scope.row.rowIndex)"
                ></i>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 关联弹框 -->
    <related-device
      :visible.sync="RelatedDeviceVisible"
      :device-id="deviceId"
      :origin-data="originData"
      @submit="submitRelated"
    ></related-device>

    <!-- 导入弹框 -->
    <import-dialog
      :visible.sync="ImportDialogVisible"
      @submit="submitImport"
    ></import-dialog>
  </div>
</template>

<script>
// 组件
import RelatedDevice from './RelatedDevice'
import ImportDialog from './ImportDialog'

// 接口
import {
  AddCheckListItem,
  GetCheckList,
  AddRowData,
  DelRowData,
  AddRowDataList,
} from '@/api/warehouse/maintenance'

export default {
  components: {
    RelatedDevice,
    ImportDialog,
  },
  props: ['active', 'type', 'elementId', 'maintenanceId'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
      },
      RelatedDeviceVisible: false,
      ImportDialogVisible: false,

      tableLoading: false,
      tableSelected: [],
      tableData: [],
      originData: [],
      tableHeader: [],
      checkListData: [],
      tableHeight: null,
      deviceId: null,
      rowIndex: null,
      numberStart: 0,
      numberEnd: 999,
      dateFormats: [
        'yyyy-MM-dd',
        'yyyy/MM/dd',
        'MM/dd/yyyy',
        'yyyy年M月dd日',
        'yyyy-MM',
        'MM-yyyy',
      ],
      timeFormats: [
        'HH:mm:ss',
        'hh:mm:ssA',
      ],

      addLoading: false,

      relatedField: [
        'deviceInfoName',
        'brandName',
        'deviceTypeName',
        'deviceInfoModel',
        'location',
        'serialNumber',
        'label',
        'deviceId',
        'floor',
        'qrCode',
        'ip',
        'remark',
      ],
      currentRow: null,
      currentColIndex: null
    }
  },
  watch: {
    'active': function (newVal, oldVal) {
      if (newVal === 'CheckList') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight;

        this.$nextTick(() => {
          if (this.elementId) {
            this.getTableData();
          }
        })
      }
      if (oldVal === 'CheckList') {
        this.tableData = [];
      }
    }
  },
  created () {
    this.dealPermission();
  },
  mounted () {

  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: 9999,
        page: 1,
        elementId: this.elementId,
        isRecord: this.type,
        maintenanceId: this.type === 1 ? this.maintenanceId : undefined
      }
      this.tableLoading = true;
      GetCheckList(param).then(res => {
        let tempData = [];
        this.originData = res.list;
        this.tableData = [];
        res.list.forEach((row, rowIndex) => {
          tempData[rowIndex] = {};
          row.itemList.forEach((item, colIndex) => {
            if (this.checkListData[colIndex].checkTypeItem.type === 3) {
              item.content = !!item.content ? item.content.split(',') : [];
            }
            if (this.checkListData[colIndex].checkTypeItem.type === 13) {
              tempData[rowIndex][`filter${colIndex}`] = this.checkListData[colIndex].filterIndex;
            }
            tempData[rowIndex][`id${colIndex}`] = item.id;
            tempData[rowIndex][`contentId${colIndex}`] = item.contentId;
            tempData[rowIndex][`content${colIndex}`] = item.content;
            tempData[rowIndex][`visible${colIndex}`] = false;
            tempData[rowIndex][`ref${colIndex}`] = `ref${rowIndex}-${colIndex}`;
            tempData[rowIndex][`text${colIndex}`] = this.dealText(item.content, colIndex);
          })
        });
        this.tableData = tempData;
        this.tableLoading = false;

        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 处理显示文本
    dealText (content, index) {
      let result;
      let item;
      let type = this.checkListData[index].checkTypeItem.type;
      switch (type) {
        case 1:
          result = content;
          break;
        case 2:
          item = this.checkListData[index].checkTypeItem.options.find(item => item.id === content);
          result = !!item ? item.name : '';
          break;
        case 3:
          item = this.checkListData[index].checkTypeItem.options.filter(item => content.includes(item.id));
          result = item.length > 0 ? item.map(checkbox => checkbox.name).join('、') : '';
          break;
        case 4:
          result = '';
          this.checkListData[index].checkTypeItem.options.forEach(item => {
            if (item.id === content) {
              result += `√${item.name} `
            } else {
              result += ` ${item.name} `
            }
          })
          break;
        case 5:
        case 6:
          result = content;
          break;
        case 7:
          break;
        case 8:
        case 9:
        case 10:
          result = content;
          break;
        case 11:
          break;
        case 12:
          break;
        case 13:
          item = this.checkListData[index].checkTypeItem.options.find(item => item.id === content);
          result = !!item ? item.name : '';
          break;
      }
      return result;
    },
    syncData () {
      this.checkListData = this.$store.state.checkListData;
      this.tableHeader = [];
      this.checkListData.forEach((item, i) => {

        if (item.checkTypeItem.type === 7) {
          this.numberStart = Number(item.checkTypeItem.options[1].name);
          this.numberEnd = Number(item.checkTypeItem.options[2].name);
        }

        if (item.checkTypeItem.type === 13) {
          item.filterIndex = null;
          if (item.checkTypeItem.filterData !== null) {
            let filterData = item.checkTypeItem.filterData;
            let checkData = this.checkListData.find(item => !!item.relatedDevice && item.relatedField === filterData);
            item.filterIndex = !!checkData ? checkData.sn : null;
          }
        }

        // 表头
        item.index = i;
        if (this.tableHeader.length > 0 && this.tableHeader[this.tableHeader.length - 1].mainType === item.mainType) {
          this.tableHeader[this.tableHeader.length - 1].children.push(item);
        } else {
          this.tableHeader.push({
            mainType: item.mainType,
            children: [item],
          })
        }
      })
    },
    // 显示新增弹框
    showAdd () {
      if (this.tableData.length >= (this.numberEnd - this.numberStart + 1)) return this.$message.warning(`A maximum of ${this.numberEnd - this.numberStart + 1} can be added`)

      let param = {
        elementId: this.elementId,
        deviceId: '',
        sn: this.tableData.length,
        itemList: this.checkListData.map((item, i) => {
          return {
            contentId: item.id,
            content: '',
            sn: i,
            isRecord: this.type,
          }
        }),
      }
      this.addLoading = true;
      AddRowData(param).then(res => {
        if (res.success) {
          this.getTableData(() => {
            this.addLoading = false;
          });
        }
      }).catch(error => {
        this.addLoading = false;
      })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let indexArr = this.tableSelected.map(item => item.rowIndex);
        let param = {
          id: indexArr.map(item => this.originData[item].id).join(',')
        }
        DelRowData(param).then(res => {
          if (res.success) {
            this.getTableData();
          }
        })
      }).catch(() => { });
    },
    // 导入
    importItem () {
      this.ImportDialogVisible = true;
    },
    // 移动
    moveItem (type) {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let index = this.tableSelected[0].rowIndex;
      let item;
      let param = [];
      if (type === 1 && index !== 0) {
        param = [{
          elementId: this.elementId,
          sn: index - 1,
          id: this.originData[index].id,
          deviceId: this.originData[index].deviceId,
        }, {
          elementId: this.elementId,
          sn: index,
          id: this.originData[index - 1].id,
          deviceId: this.originData[index - 1].deviceId,
        }]
        AddRowDataList(param)

        item = this.tableData.splice(index, 1);
        this.tableData.splice(index - 1, 0, ...item)
        item = this.originData.splice(index, 1);
        this.originData.splice(index - 1, 0, ...item)
      } else if (type === 2 && index !== this.tableData.length - 1) {
        param = [{
          elementId: this.elementId,
          sn: index + 1,
          id: this.originData[index].id,
          deviceId: this.originData[index].deviceId,
        }, {
          elementId: this.elementId,
          sn: index,
          id: this.originData[index + 1].id,
          deviceId: this.originData[index + 1].deviceId,
        }]
        AddRowDataList(param)

        item = this.tableData.splice(index, 1);
        this.tableData.splice(index + 1, 0, ...item)
        item = this.originData.splice(index, 1);
        this.originData.splice(index + 1, 0, ...item)
      }
    },
    // 显示关联弹框
    showRelated (rowIndex) {
      this.deviceId = this.originData[rowIndex].deviceId;
      this.rowIndex = rowIndex;
      this.RelatedDeviceVisible = true;
    },
    // 关联提交
    submitRelated (data) {
      let relatedDeviceArr = [];
      this.originData[this.rowIndex].deviceId = data.id;
      this.checkListData.forEach((item, index) => {
        if (item.relatedDevice) {
          relatedDeviceArr.push(index);
        }
      })
      relatedDeviceArr.forEach(colIndex => {
        if (this.checkListData[colIndex].relatedField !== null) {
          let prop = this.relatedField[this.checkListData[colIndex].relatedField];
          this.tableData[this.rowIndex][`content${colIndex}`] = data[prop]
        }
      })

      let param = {
        elementId: this.elementId,
        deviceId: data.id,
        sn: this.originData[this.rowIndex].sn,
        id: this.originData[this.rowIndex].id,
        itemList: this.checkListData.map((item, i) => {
          let colData = {
            contentId: this.tableData[this.rowIndex][`contentId${i}`],
            sn: i,
            isRecord: this.type,
            id: this.tableData[this.rowIndex][`id${i}`],
          }

          if (this.checkListData[i].checkTypeItem.type === 3) {
            colData.content = this.tableData[this.rowIndex][`content${i}`].join(',');
          } else {
            colData.content = this.tableData[this.rowIndex][`content${i}`];
          }
          return colData;
        })
      }
      AddRowData(param).then(res => {
        if (res.success) {
          this.getTableData();
          this.$message.success(this.$i18n.t('message.import'));
        }
      })
    },
    // 点击单元格
    cellClick (row, index) {
      if (!!this.checkListData[index].relatedDevice) return;
      if (!!this.currentRow && this.currentRow[`visible${this.currentColIndex}`]) this.elHide();

      row[`visible${index}`] = true;
      this.currentRow = row;
      this.currentColIndex = index;

      setTimeout(() => {
        if (!!this.$refs[row[`ref${index}`]][0].focus) this.$refs[row[`ref${index}`]][0].focus();
        document.body.addEventListener('click', this.elHide)
      }, 100);
    },
    // 隐藏元素
    elHide () {
      this.currentRow[`text${this.currentColIndex}`] = this.dealText(this.currentRow[`content${this.currentColIndex}`], this.currentColIndex);
      this.currentRow[`visible${this.currentColIndex}`] = false;
      this.saveCell(this.currentRow, this.currentColIndex)

      document.body.removeEventListener('click', this.elHide)
    },
    // 单元格保存
    saveCell (row, index) {
      let param = [{
        id: row[`id${index}`],
        contentId: row[`contentId${index}`],
        content: row[`content${index}`],
        sn: index,
        isRecord: this.type,
        maintenanceId: this.maintenanceId,
        tableDataId: this.originData[row.rowIndex].id,
      }]

      if (this.checkListData[index].checkTypeItem.type === 3) {
        param[0].content = row[`content${index}`].join(',');
      }

      AddCheckListItem(param)
    },
    // 导入提交
    submitImport (data) {
      let relatedDeviceArr = [];
      this.checkListData.forEach((item, index) => {
        if (item.relatedDevice) {
          relatedDeviceArr.push(index);
        }
      })
      let param = data.map((device, index) => {
        let rowData = {
          elementId: this.elementId,
          deviceId: device.id,
          sn: index,
        }
        if (!!this.tableData[index]) {
          rowData.id = this.originData[index].id;
          rowData.itemList = this.checkListData.map((item, i) => {
            let colData = {
              contentId: this.tableData[index][`contentId${i}`],
              content: this.tableData[index][`content${i}`],
              sn: i,
              isRecord: this.type,
              id: this.tableData[index][`id${i}`],
            }
            if (item.checkTypeItem.type === 3) {
              colData.content = this.tableData[index][`content${i}`].join(',')
            }

            if (relatedDeviceArr.includes(i)) {
              if (item.relatedField !== null) {
                let prop = this.relatedField[item.relatedField];
                colData.content = device[prop];
              } else {
                colData.content = '';
              }
            } else {
              // 导入设备,数量强制修改1
              if (item.checkItem === '数量') {
                colData.content = '1';
              }
            }
            return colData;
          });
        } else {
          rowData.id = undefined;
          rowData.itemList = this.checkListData.map((item, i) => {
            let colData = {
              contentId: item.id,
              content: '',
              sn: i,
              isRecord: this.type,
            }
            if (relatedDeviceArr.includes(i)) {
              if (this.checkListData[i].relatedField !== null) {
                let prop = this.relatedField[this.checkListData[i].relatedField];
                colData.content = device[prop];
              }
            }
            return colData;
          })
        }
        return rowData;
      })

      AddRowDataList(param).then(res => {
        if (res.success) {
          this.$message.success(this.$i18n.t('message.import'))
          this.getTableData();
        }
      })
    },
    // 设置行号
    tableRowClassName ({ row, rowIndex }) {
      row.serialNumber = this.numberStart + rowIndex;
      row.rowIndex = rowIndex;
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        // this.permit.search = permission.includes('UserSearchUser');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 0;
  height: 100%;
}

.table-row {
  height: calc(100% - 46px);
}
.input-box {
  display: flex;
  align-items: center;

  .el-icon-share {
    font-size: 18px;
    cursor: pointer;
    margin-left: 5px;
  }
}
.signature {
  width: 40px;
  margin-top: 5px;
}

.checked {
  color: #67c23a;
}
.cell-full {
  width: 100%;
  height: 100%;
  min-height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
// .table-row {
//   ::v-deep th,
//   ::v-deep td {
//     height: 35px;
//     padding: 0;
//   }
// }
</style>
