import request from '@/utils/request'

// 查询列表
export const GetSLAPoliciesList = (data) => request({
  method: 'post',
  url: '/SLAPolicies/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改
export const AddSLAPolicies = (data) => request({
  method: 'post',
  url: '/SLAPolicies/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelSLAPolicies = (data) => request({
  method: 'post',
  url: '/SLAPolicies/del.do',
  data: JSON.stringify(data)
})

export const GetSLATriggerList = (data) => request({
  method: 'post',
  url: '/SLAPriority/queryByPage.do',
  data: JSON.stringify(data)
})

export const DelSLAPriority = (data) => request({
  method: 'post',
  url: '/SLAPriority/del.do',
  data: JSON.stringify(data)
})

export const DelSLATrigger = (data) => request({
  method: 'post',
  url: '/SLATrigger/del.do',
  data: JSON.stringify(data)
})

// 添加、修改 SLA Priority
export const AddSLAPriority = (data) => request({
  method: 'post',
  url: '/SLAPriority/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取 SLA Priority 信息
export const GetSLAPriorityItem = (data) => request({
  method: 'post',
  url: '/SLAPriority/queryById.do',
  data: JSON.stringify(data)
})

// 获取SLATrigger ID
export const GetSLATriggerID = (data) => request({
  method: 'post',
  url: '/SLATrigger/queryTid.do',
  data: JSON.stringify(data)
})

export const GetSLALevelList = (data) => request({
  method: 'post',
  url: '/SysContactlist/queryByPolicy.do',
  data: JSON.stringify(data)
})

// 添加、修改 SLA Level
export const AddSLALevel = (data) => request({
  method: 'post',
  url: '/SLALevel/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除 SLA Level
export const DelSLALevel = (data) => request({
  method: 'post',
  url: '/SLALevel/del.do',
  data: JSON.stringify(data)
})

// 获取级别
export const GetSLALevelAll = (data) => request({
  method: 'post',
  url: '/SLALevel/queryByCondition.do',
  data: JSON.stringify(data)
})

// 根据服务类型查询priority
export const GetSLAPriorityByserverType = (data) => request({
  method: 'post',
  url: '/SLAPriority/queryByService.do',
  data: JSON.stringify(data)
})

// 根据policyId 查询 Priority
export const GetPriorityByPolicyId = (data) => request({
  method: 'post',
  url: '/SLAPriority/queryByCondition.do',
  data: JSON.stringify(data)
})

// 获取 SLATracking
export const GetSLATracking = (data) => request({
  method: 'post',
  url: '/SLATracking/queryByCondition.do',
  data: JSON.stringify(data)
})

// 删除联系人
export const DelContract = (data) => request({
  method: 'post',
  url: '/SLALevel/deleteContract.do',
  data: JSON.stringify(data)
})

// 复制sla
export const CopySLA = (data) => request({
  method: 'post',
  url: '/SLAPolicies/copyTo.do',
  data: JSON.stringify(data)
})

