<template>
  <div
    class="wrap"
    v-show="visible"
    v-drag
    ref="filterDialogWrap"
  >
    <div
      class="header"
      @click="showIndex"
    >
      <label>{{title}}</label>
      <div
        class="btn-box"
        @mousedown.stop
        @click.stop
      >
        <i
          class="el-icon-close"
          @click="dialogClose"
        ></i>
      </div>
    </div>
    <div
      @mousedown.stop
      class="content"
    >
      <div class="operator-label">Equals</div>
      <el-input
        class="search-input"
        size="small"
        v-model="condition"
        placeholder="Value"
        readonly
      ></el-input>
      <div class="option-box">
        <!-- <el-tree
          :data="treeData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          node-key="id"
          ref="tree"
          show-checkbox
        >
        </el-tree> -->
        <div
          v-for="(item,index) in listData"
          :key="index"
          class="date-item"
          :class="{'active':condition == item.value}"
          @click="selectType(item.value)"
        >{{item.label}}</div>
      </div>
    </div>
    <div
      @mousedown.stop
      class="footer"
    >
      <el-row :gutter="10">
        <!-- <el-col :span="8">
          <el-button
            size="small"
            @click="selectAll"
          >Select All</el-button>
        </el-col> -->
        <el-col
          :offset="8"
          :span="8"
        >
          <el-button
            size="small"
            @click="showQuery"
          >Detail</el-button>
        </el-col>
        <el-col :span="8">
          <el-button
            size="small"
            @click="submit"
          >OK</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

// 接口
import {
  GetTemplateParam,
} from '@/api/counterMode'
export default {
  props: ['visible', 'zindex', 'data', 'reportId'],
  data () {
    return {
      condition: '',
      title: '',
      listData: [],
    }
  },

  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.initData();
      },
      deep: true
    },
    'reportId': {
      handler: function (newVal) {
        this.getTemplateParam();
      },
      deep: true
    },
  },
  created () {
    this.getTemplateParam();
    this.initData();
  },
  methods: {
    initData () {
      this.title = this.data.data.field;
      this.condition = this.data.data.condition;
    },
    dialogClose () {
      this.$emit('update:visible', false);
    },
    selectType (item) {
      this.condition = item;
    },
    submit () {
      this.$emit('submit', Object.assign(this.data, {
        con: this.condition,
        data: Object.assign(this.data.data, {
          condition: this.condition,
        })
      }));
      this.dialogClose();
    },

    showIndex () {
      this.$refs.filterDialogWrap.style.zIndex = this.zindex;
      this.$emit('showIndex');
    },
    showQuery () {
      this.$emit('showQuery');
    },
    // 获取模板参数
    getTemplateParam () {
      let param = {
        templatesId: this.reportId,
        fieIdType: this.data.data.queryType,
      }
      GetTemplateParam(param).then(res => {
        let item = res.find(item => item.mainFieText === this.data.data.prop)
        if (item) {
          this.listData = !!item ? JSON.parse(item.optionJson) : [];
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./dialog.scss";
</style>