<template>
  <div class="wrap">
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :data="tableData"
        :height="tableHeight"
      >

        <el-table-column
          prop="name"
          :label="$t('chartType.chartElements.col1')"
        >
        </el-table-column>
        <el-table-column :label="$t('chartType.chartElements.col2')">
          <template slot-scope="scope">
            <template v-if="!scope.row.options">
              <el-input
                size="small"
                v-model="scope.row.value"
                @change="submit"
              ></el-input>
            </template>
            <template v-else-if="scope.row.options.length > 0">
              <el-select
                size="small"
                v-model="scope.row.value"
                @change="submit"
              >
                <el-option
                  v-for="(item,index) in scope.row.options"
                  :key="index"
                  :value="index"
                  :label="item"
                ></el-option>
              </el-select>
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('chartType.chartElements.col3')">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :title="scope.row.status ? 'Enable':'Disable'"
              @change="submit"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
      tableData: [],
      tableHeight: null,
    }
  },
  watch: {
    'data': {
      handler: function (newVal, oldVal) {
        this.tableData = this.data;
      },
      deep: true,
    }
  },
  created () {
    this.tableData = this.data;
  },
  methods: {
    showTab () {
      this.tableHeight = this.$refs.tableRow.$el.clientHeight;
    },
    submit () {
      this.$emit('submit', this.tableData);
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: 100%;

  .el-select,
  .el-input {
    width: 215px;
  }
}
</style>