<template>
  <textarea
    v-model="elData.content"
    :readonly="elData.readonly"
    :class="{
      'active-input':!elData.readonly
    }"
    :style="{
        'font-size':elData.style.fontSize + 'px',
        'line-height':elData.style.lineHeight + 'px',
        color :elData.style.color ,
        'font-weight': elData.style.fontWeight,
        'font-style': elData.style.fontStyle,
        'text-decoration': elData.style.textDecoration,
        'text-align': elData.style.textAlign,
        'font-family':elData.style.fontFamily,
      }"
  ></textarea>
</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {
    }
  },
  watch: {

  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
// .box {
//   position: absolute;
//   border: 1px solid transparent;
// }
textarea {
  width: 100%;
  height: 100%;
  outline: none;
  cursor: default;
  padding: 0px;
  border-width: 0px;
  resize: none;
  overflow: hidden;
  display: block;
  cursor: inherit;
  background: none;
}
.active-input {
  border: 1px solid #000;
}
</style>