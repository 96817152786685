<template>
  <div>
    <el-row>
      <el-table
        border
        :data="tableData"
        v-loading="tableLoading"
        ref="table"
        @selection-change="val => (tableSelected = val)"
        @row-click="rowClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('newQuotation.col14')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          prop="discontinue"
          :label="$t('newQuotation.col15')"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.discontinue === 0"
              size="small"
              type="success"
            >{{$t('newQuotation.tag1')}}</el-tag>
            <el-tag
              v-else
              size="small"
              type="danger"
            >{{$t('newQuotation.tag2')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="discontinueDate"
          :label="$t('newQuotation.col16')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="itemName"
          :label="$t('newQuotation.col17')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="model"
          :label="$t('newQuotation.col18')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="brand"
          :label="$t('newQuotation.col19')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="type"
          :label="$t('newQuotation.col20')"
          sortable
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 设备弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :data="data"
      @submit="equipmentSubmit"
    ></equipment-dialog>
  </div>
</template>

<script>
// 全局方法
import { formatDate } from "@/utils/common";
// 组件
import EquipmentDialog from "@/components/EquipmentDialog/EquipmentDialog9";
// 接口
import {
  GetEolList,
  DelRelevanceDevive,
  RelevanceDevive
} from "@/api/warehouse/quotation";
export default {
  components: {
    EquipmentDialog
  },
  props: ["data", "permit"],
  data () {
    return {
      EquipmentDialogVisible: false,
      eol: false,
      date: null,
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      tableLoading: false,
      tableSelected: [],
      tableData: []
    };
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.eol = Boolean(newVal.discontinue);
        this.date = newVal.discontinueDate;
      },
      deep: true
    }
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        id: this.data.id
      };
      this.tableLoading = true;
      GetEolList(param)
        .then(res => {
          this.tableData = res.list.map(item => {
            item.createTime = formatDate(new Date(item.createTime));
            return item;
          });
          this.page.total = res.total;
          this.tableLoading = false;
        })
        .catch(error => {
          this.tableLoading = false;
        });
    },

    // 显示新增弹框
    showAdd () {
      this.$router.push({ path: "/home/newQuote", query: { type: 3 } });
    },
    // 显示设备
    showRelevance () {
      this.EquipmentDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0)
        return this.$message.warning("Please select at least one item.");
      this.$confirm("Delete operation, please confirm!", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          let arr = this.tableSelected.map(item => this.delPromise(item.id));
          Promise.all(arr).then(res => {
            this.getTableData();
            this.$message.success(this.$i18n.t("message.del"));
          });
        })
        .catch(() => { });
    },
    delPromise (id) {
      return new Promise((resolve, reject) => {
        DelRelevanceDevive(id).then(res => {
          if (res) resolve();
        });
      });
    },
    // 设备提交
    equipmentSubmit (data) {
      let param = {
        id: this.data.id,
        ids: data.map(item => item.id).join(",")
      };
      RelevanceDevive(param).then(res => {
        this.getTableData();
      });
    },
    // 行点击
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row);
    }
  }
};
</script>
<style lang="scss" scoped>
.label-item {
  height: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  label {
    width: 110px;
    padding-right: 10px;
    font-size: 14px;
    color: #606266;
    text-align: right;
    flex-shrink: 0;
  }
  .el-date-editor {
    width: 100%;
  }
}
</style>
