import request from '@/utils/request'

// 查询投诉和建议列表
export const GetComplaintsEvaluationList = (data) => request({
  method: 'post',
  url: '/MaintenanceComplaintsAndSuggestions/queryByPage.do',
  data: JSON.stringify(data)
})

// 删除
export const AddComplaintsEvaluation = (data) => request({
  method: 'post',
  url: '/MaintenanceComplaintsAndSuggestions/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除
export const DelComplaintsEvaluation = (data) => request({
  method: 'post',
  url: '/MaintenanceComplaintsAndSuggestions/delete.do',
  data: JSON.stringify(data)
})

