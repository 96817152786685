<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="userForm"
        size="small"
        class="user-form"
      >

        <el-form-item
          :label="$t('standardTask.label2')"
          prop="type"
        >
          <my-select
            :data="taskType"
            :value.sync="dialog.type"
            size="small"
            :prop="{
              label:'name',
              value:'id'
            }"
          ></my-select>
          <!-- <el-select
            v-model="dialog.type"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in taskType"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item
          :label="$t('standardTask.label1')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('standardTask.label6')"
          prop="sequence"
        >
          <el-input v-model="dialog.sequence"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('standardTask.label7')"
          prop="stage"
        >
          <el-input v-model="dialog.stage"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('standardTask.label3')"
          prop="projectType"
        >
          <my-select
            :data="projectTypes"
            :value.sync="dialog.projectType"
            size="small"
            :prop="{
                label:'name',
                value:'id'
              }"
          ></my-select>

          <!-- <el-select
            v-model="dialog.projectType"
            filterable
            remote
            allow-create
            default-first-option
            :remote-method="queryProjectType"
          >
            <el-option
              v-for="item in projectTypes"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item
          :label="$t('standardTask.label4')"
          prop="remark"
        >
          <el-input
            v-model="dialog.remark"
            type="textarea"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="!!data"
          :label="$t('standardTask.label5')"
        >
          <div class="document-box">
            <span
              v-for="(item,index) in data.pdNames.split(',')"
              :key="index"
            >{{item}}</span>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'
// 接口
import { AddStandardTask } from '@/api/warehouse/standardTask'
import { GetTaskTypeAll } from '@/api/warehouse/dataMaintenance/projectTaskType'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    MySelect,
  },
  props: ['visible', 'data',],
  data () {
    return {
      projectTypes: [],
      taskType: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
        type: '',
        projectType: '',
        remark: '',
        sequence: '',
        stage: '',
      },
      rules: {
        // user: [{ required: true, message: 'Please enter an User Account.', trigger: 'blur' }],
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.userForm && this.$refs.userForm.resetFields();
        this.dialog.visible = this.visible;

        if (this.data) {
          this.$nextTick(() => {
            Object.assign(this.dialog, {
              title: this.$i18n.t('standardTask.title2'),
              name: this.data.name,
              type: this.data.typeId,
              projectType: this.data.projectTypeId,
              remark: this.data.notes,
              sequence: this.data.sort,
              stage: this.data.stage,
            })
          })

        } else {
          this.dialog.title = this.$i18n.t('standardTask.title1')
        }

        GetTaskTypeAll().then(res => this.taskType = res);
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    // 获取合同类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000008' }).then(res => this.projectTypes = res)

  },
  methods: {
    // 添加或修改用户
    submit () {
      this.$refs.userForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.name,
          projectTypeId: this.dialog.projectType,
          notes: this.dialog.remark,
          stage: this.dialog.stage,
          sort: this.dialog.sequence,
        }
        // 验证是否是新类型
        if (/.{8}(-.{4}){3}-.{12}/.test(this.dialog.type)) {
          param.typeId = this.dialog.type;
        } else {
          param.typeName = this.dialog.type;
          param.typeId = '';
        }

        let msg = this.$i18n.t('message.add');
        if (this.data) {
          param.id = this.data.id;
          msg = this.$i18n.t('message.edit');
        }

        this.dialog.loading = true;
        AddStandardTask(param).then(res => {
          if (res) {
            this.$message.success(msg)
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        }).catch(err => {
          this.dialog.loading = false;

        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  .el-switch {
    width: 100px;
  }
}
.document-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>