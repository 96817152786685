<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
      :before-close="closeDialog"
    >
      <el-row>
        <el-radio-group v-model="linkToType">
          <el-radio :label="0">Report Template</el-radio>
          <el-radio :label="1">Receipt Template</el-radio>
        </el-radio-group>
      </el-row>
      <div
        class="filter-box"
        v-show="linkToType === 0"
      >
        <filter-item
          class="filter-wrap"
          v-for="(item,index) in filterData"
          :key="index"
          :data="item"
          :mode="mode"
          :reportId="reportId"
          :zindex.sync="zIndex"
          @submit="submitParam"
          @showQuery="showQueryData"
          @closeCondition="closeCondition"
          ref="filterItem"
        ></filter-item>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="closeDialog"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="danger"
          @click="resetQuery"
          :loading="dialog.resetLoading"
        >Reset</el-button>
        <el-button
          v-show="!!reportId && linkToType === 0"
          type="primary"
          @click="showQuery(0)"
        >Add Field</el-button>
        <el-button
          v-show="!!reportId && linkToType === 0"
          type="primary"
          @click="showQuery(1)"
        >Add Parameters</el-button>
        <el-button
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >Save</el-button>
      </div>
    </el-dialog>

    <!-- 查询弹窗 -->
    <query
      :visible.sync="QueryVisible"
      :data="queryData"
      :report-template-id="reportId"
      @submit="submitQuery"
    ></query>
  </div>
</template>

<script>
// 组件
import FilterItem from '@/components/FilterItem'
import Query from '@/components/DashBoardDialog/Query'

export default {
  components: {
    FilterItem,
    Query,
  },
  props: ['visible', 'reportData'],
  data () {
    return {
      dialog: {
        visible: false,
        title: 'Link to',
        loading: false,
        resetLoading: false,
      },
      linkToType: 0,
      condition: '',
      reportId: '',
      filterData: [],
      mode: '1',
      zIndex: 1,
      QueryVisible: false,
      queryData: null,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        if (!!this.reportData.linkToJson) {
          let linkToJson = JSON.parse(this.reportData.linkToJson)
          this.filterData = linkToJson.data;
          this.linkToType = linkToJson.type;
        } else {
          this.resetQuery();
        }
      }
      this.dialog.visible = this.visible;
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'filterData': {
      handler: function (newVal) {
        if (newVal.length > 0)
          this.reportId = this.filterData[0].val.id;
      },
      deep: true,
    },
  },
  created () {
  },
  methods: {
    // 提交参数
    submitParam (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);
      this.$set(this.filterData, index, data)
    },

    // 显示参数弹框
    showQuery (type) {
      this.queryData = {
        data: {
          queryType: type
        }
      }
      this.QueryVisible = true;
    },

    showQueryData (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);

      this.queryData = this.filterData[index];
      this.QueryVisible = true;
    },
    closeCondition (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);
      let dialogData = this.$refs.filterItem.map((item, index) => {
        let result = { visible: item.FilterDialogVisible };
        if (item.FilterDialogVisible) {
          let dialog = item.$refs.filterDialog.$el;
          result.left = dialog.style.left;
          result.top = dialog.style.top;
          result.prop = this.filterData[index].prop
        }
        return result;
      });

      this.filterData.splice(index, 1);

      this.$nextTick(() => {
        dialogData.splice(index, 1);
        dialogData.forEach((item, index) => {
          this.$refs.filterItem[index].FilterDialogVisible = item.visible;
          if (item.visible) this.$refs.filterItem[index].setPosition(item);
        })
      })
    },
    submitQuery (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);
      if (index == -1) {
        this.filterData.push(data)
      } else {
        this.filterData[index] = Object.assign(this.filterData[index], data);
      }
    },
    // 重置参数
    resetQuery () {
      this.linkToType = 0;
      this.filterData = [{
        mark: 'Report',
        prop: 'templatesId',
        label: 'Report Template',
        con: '',
        val: {
          id: '',
          name: '',
          mainLanguage: 0,
          subLanguage: 0,
        },
      }, {
        mark: 'BU',
        prop: 'branchIds',
        label: 'Site Name',
        con: 'ALL',
        val: ''
      }];
    },
    // 提交
    submit () {
      let data;
      if (this.linkToType === 0) {
        if (!!this.reportId) {
          data = {
            linkToJson: JSON.stringify({
              type: this.linkToType,
              data: this.filterData
            })
          }
        } else {
          data = {
            linkToJson: ''
          }
        }
      } else {
        data = {
          linkToJson: JSON.stringify({
            type: this.linkToType,
            data: ''
          })
        }
      }
      this.dialog.loading = true;
      this.$emit('submit', data)
    },
    closeDialog (done) {
      this.$refs.filterItem && this.$refs.filterItem.forEach(item => {
        item.closeDialog();
      })
      this.dialog.visible = false;
      if (typeof done === 'function') done()
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-box {
  display: flex;
  flex-direction: column;

  .filter-wrap {
    height: 52px;
    margin-left: 0;
    margin-bottom: 10px;
  }
}
</style>