<template>
  <div class="wrap">
    <el-card class="card-wrap">
      <page-header :title="$t('chartType.title1')">
        <el-button
          v-if="!!chartDataId"
          size="medium"
          type="danger"
          @click="delItem"
        >{{$t('operation.del')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </page-header>

      <el-container>
        <el-aside>
          <div class="header">{{$t('chartType.label1')}}</div>
          <div class="chart-menu">
            <template v-if="viewType == 1">
              <div
                v-for="(item,index) in charts"
                :key="index"
                class="chart-item"
                :class="{
                'active':seriesType === item.value
              }"
                @click="selectType(item.value)"
              >
                <div
                  class="icon"
                  :class="item.icon"
                ></div>
                <span class="label">{{item.name}}</span>
              </div>
            </template>

            <div
              v-else
              class="chart-item active"
            >
              <div class="icon icon-statistics"></div>
              <span class="label">{{$t('chartType.type1')}}</span>
            </div>
          </div>
        </el-aside>
        <el-main>
          <div class="title-box">
            <div class="input-item">
              <label>{{$t('chartType.title1')}}</label>
              <el-input
                size="medium"
                v-model="chartTitle"
              ></el-input>
            </div>
            <el-switch
              v-model="enables"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="enables ? 'Enable' : 'Disable'"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </div>
          <div class="chart-wrap">
            <div
              v-show="viewType == 1"
              class="chart-box"
              ref="myChart"
            >
            </div>
            <div
              v-show="viewType == 2"
              class="icon-box"
            >
              <div
                class="left-box"
                ref="iconBox"
              >
                <div
                  class="panel-item"
                  ref="panelItem"
                  @click="iconLinkto"
                >
                  <img
                    v-show="!!iconImg"
                    class="icon"
                    :src="`${baseUrl}/reportTemplatesChartType/getImage.do?imageId=${iconImg}`"
                  >
                  <div class="value">
                    <span
                      ref="amountIcon"
                      v-if="isAmount"
                    >HK$</span>
                    <span>{{iconCount}}</span>
                  </div>
                  <div class="name">{{chartTitle}}</div>
                </div>
              </div>
              <div class="right-box">
                <el-button-group class="input-group">
                  <el-button
                    class="icon-btn"
                    @click="$refs.inputFile.click()"
                    size="medium"
                  >{{$t('chartType.btn1')}}</el-button>
                  <el-button
                    size="medium"
                    @click="imageState = !imageState"
                    :type="imageState ? 'primary' : 'danger'"
                  >{{$t('operation.del')}}</el-button>
                </el-button-group>
                <div class="img-box">
                  <el-image
                    class="img"
                    :class="{ 'danger': !imageState }"
                    v-for="(item,index) in images"
                    :key="index"
                    :src="`${baseUrl}/reportTemplatesChartType/getImage.do?imageId=${item.mapAddress}`"
                    @click="imageClick(item,index)"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane :label="$t('chartType.tab1')">
              <data-source
                ref="dataSource"
                :data="axisData"
                @submit="submitAxisData"
              ></data-source>
            </el-tab-pane>
            <el-tab-pane :label="$t('chartType.tab2')">
              <query-conditions
                ref="queryConditions"
                :data="queryConditions"
                :template-id="templateId"
                @submitData="submitData"
                @submitParam="submitParam"
              ></query-conditions>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('chartType.tab3')"
              :disabled="viewType == 2"
            >
              <sorting-table
                ref="sortingTable"
                :data="sortingData"
                :template-id="templateId"
                @submit="submitSortingData"
              ></sorting-table>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('chartType.tab4')"
              :disabled="viewType == 2"
            >
              <chart-elements
                ref="chartElements"
                :data="optionData"
                @submit="submitOptionData"
              ></chart-elements>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-card>
    <!-- 上传 -->
    <input
      type="file"
      ref="inputFile"
      @change="uploadImage"
      hidden
    >
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import DataSource from './components/DataSource'
import QueryConditions from './components/QueryConditions'
import SortingTable from './components/SortingTable'
import ChartElements from './components/ChartElements'

// 接口
import {
  AddReportChart,
  DelReportChart,
  UploadImage,
  GetImageAll,
  DelImage,
} from '@/api/reportDesigner'

export default {
  name: 'ChartType',
  components: {
    PageHeader,
    DataSource,
    QueryConditions,
    SortingTable,
    ChartElements
  },
  data () {
    return {
      viewType: 1,
      baseUrl: process.env.VUE_APP_BASE_URL,
      activeName: '',
      charts: [
        // { name: 'Area', icon: 'fa-area-chart', value: 'area', },
        { name: this.$i18n.t('chartType.type2'), icon: 'icon-bar', value: 0, },
        { name: this.$i18n.t('chartType.type3'), icon: 'icon-pie', value: 1, },
        { name: this.$i18n.t('chartType.type4'), icon: 'icon-line', value: 2, },
      ],
      colors: [
        [],
        ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
        ['#dd6b66', '#759aa0', '#e69d87', '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab', '#91ca8c', '#f49f42'],
        ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa', '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050', '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'],
        ['#C1232B', '#27727B', '#FCCE10', '#E87C25', '#B5C334', '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD', '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0'],
        ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8', '#cda819', '#32a487'],
        ['#E01F54', '#001852', '#f5e8c8', '#b8d2c7', '#c6b38e', '#a4d8c2', '#f3d999', '#d3758f', '#dcc392', '#2e4783', '#82b6e9', '#ff6347', '#a092f1', '#0a915d', '#eaf889', '#6699FF', '#ff6666', '#3cb371', '#d5b158', '#38b6b6'],
      ],
      seriesTypes: ['bar', 'pie', 'line'],
      enables: 1,
      chartTitle: '',
      seriesType: 0,
      dataOrigin: null,
      axisData: [],
      queryConditions: [],
      sortingData: [],
      chartConfig: {},
      optionData: [{
        name: 'Axes',
        key: 'axes',
        options: ['Primary Vertical', 'Primary Horizontal'],
        value: 0,
        status: true,
      }, {
        name: 'Gridlines',
        key: 'gridlines',
        options: ['Primary Major Vertical', 'Primary Major Horizontal'],
        value: 0,
        status: true,
      }, {
        name: 'Legend',
        key: 'legend',
        options: ['Top', 'Right', 'Bottom', 'Left'],
        value: 0,
        status: true,
      }, {
        name: 'Data Zoom',
        key: 'dataZoom',
        options: ['Top', 'Right', 'Bottom', 'Left'],
        value: 2,
        status: true,
      }, {
        name: 'Tooltip',
        key: 'tooltip',
        options: [],
        value: 0,
        status: true,
      }, {
        name: 'Theme',
        key: 'theme',
        options: ['normal', 'vintage', 'dark', 'macarons', 'infographic', 'shine', 'roma'],
        value: 0,
        status: true,
      }, {
        name: 'Pie Size',
        key: 'pieSize',
        value: 50,
        status: true,
      }, {
        name: 'Pie Label',
        key: 'pieLabel',
        options: [],
        value: 0,
        status: false,
      },

        //  {
        //   name: 'Chart Title',
        //   key: 'chartTitle',
        //   options: ['Above Chart', 'Centered Overlay'],
        //   value: 0,
        //   status: true,
        // }, {
        //   name: 'Data Table',
        //   key: 'dataTable',
        //   options: ['With Legend Keys', 'No Legend Keys'],
        //   value: 0,
        //   status: true,
        // }, 
        // {
        //   name: 'Data Labels',
        //   key: 'dataLabels',
        //   value: 0,
        //   status: true,
        // },
      ],
      myChart: null,
      chartDataId: null,
      isAmount: false,
      iconImg: '',
      iconCount: 0,

      images: [],
      imageState: true,
      templateId: null,

      serialNum: 1,
    }
  },
  watch: {
    activeName: function (newVal) {
      this.$nextTick(() => {
        switch (newVal) {
          case '0':
            this.$refs.dataSource.showTab();
            break;
          case '1':
            this.$refs.queryConditions.showTab();
            break;
          case '2':
            this.$refs.sortingTable.showTab();
            break;
          case '3':
            this.$refs.chartElements.showTab();
            break;
        }
      })
    },
  },

  mounted () {
    this.templateId = this.$route.query.id;
    this.viewType = this.$route.query.type;
    if (this.viewType == 2) {
      this.seriesType = 999;

      GetImageAll().then(res => this.images = res)
    }

    this.initData();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    selectType (type) {
      this.seriesType = type;
      if (this.seriesType !== 999) {
        this.initChart();
      } else {
        this.initIcon();
      }
    },

    initData () {
      let chartData = sessionStorage.chartData
      if (!!chartData) {
        chartData = JSON.parse(chartData);
        this.chartDataId = chartData.id;
        this.enables = chartData.enables;
        this.chartTitle = chartData.chartTitle;
        this.seriesType = chartData.chartType;
        this.iconImg = chartData.mapAddress;

        this.axisData = JSON.parse(chartData.dataSource);
        this.queryConditions = JSON.parse(chartData.queryConditions);
        this.sortingData = JSON.parse(chartData.sortingJson);

        this.chartConfig = JSON.parse(chartData.chartElements);
        this.optionData.forEach(item => {
          if (!!this.chartConfig[item.key]) {
            Object.assign(item, this.chartConfig[item.key])
          }
        })
      }
    },
    // 提交数据
    submitData (data) {
      this.dataOrigin = data;
      this.dataOrigin.data.data = this.dealData(this.dataOrigin.data.data, this.dataOrigin.header);
      if (this.seriesType !== 999) {
        this.initChart();
      } else {
        this.initIcon();
      }
    },
    submitParam (data) {
      this.queryConditions = data;
    },
    // 提交数据
    submitAxisData (data) {
      this.axisData = data;
      if (this.seriesType !== 999) {
        this.initChart();
      } else {
        this.initIcon();
      }
    },
    // 排序字段提交
    submitSortingData (data) {
      this.sortingData = data;
      this.initChart();
    },
    // 图标参数提交
    submitOptionData (data) {
      this.optionData = data;
      this.initChart();
    },
    initIcon () {
      if (!this.dataOrigin) return;
      if (this.axisData.length === 0) return;

      let iconBox = this.$refs.iconBox;
      let panelItem = this.$refs.panelItem;
      let h = iconBox.clientHeight;
      let w = iconBox.clientWidth;
      let bassLen = h > w ? w : h;

      panelItem.style.width = bassLen + 'px';
      panelItem.style.height = bassLen + 'px';
      panelItem.style.fontSize = bassLen / 115 * 12 + 'px';

      this.iconCount = 0;
      this.dataOrigin.data.data.forEach(item => {
        let val = this.getCellVal(item, this.axisData[0].key);

        if (/,/.test(val)) val = val.replace(/,/g, '');
        if (isNaN(val)) {
          this.iconCount += 1;
        } else {
          this.iconCount += parseFloat(val) || 0;
        }
      });

      if (this.axisData[0].measure == 2) {
        this.iconCount = this.iconCount.toFixed(2).replace(/(?=(?!\b)(?:\d{3})+(?!\d))/g, ',')
        this.isAmount = true;
      } else {
        this.iconCount = this.iconCount.toFixed(0).replace(/(?=(?!\b)(?:\d{3})+(?!\d))/g, ',')
        this.isAmount = false;
      }

      this.$nextTick(() => {
        if (this.isAmount)
          this.$refs.amountIcon.style.fontSize = bassLen / 200 * 12 + 'px';
      })
    },
    initChart () {
      if (!this.dataOrigin) return;
      if (this.axisData.length === 0) return;
      let isColumn = false;
      let isRow = false;
      let rowData;
      let columnData = [];

      this.axisData.forEach(item => {
        if (item.type === 0) {
          columnData.push(item);
          return isColumn = true;
        }
        if (item.type === 1) {
          rowData = item;
          return isRow = true;
        }
      })

      if (!isColumn || !isRow) return;

      let xAxisData = [];
      let tempData = {};
      let sortingItem = this.sortingData.find(item => item.status);

      this.chartConfig = {};
      this.optionData.forEach(item => {
        this.chartConfig[item.key] = item;
      })

      this.dataOrigin.data.data.forEach(item => {
        if (!tempData[this.getCellVal(item, rowData.key)]) {
          tempData[this.getCellVal(item, rowData.key)] = {};
          for (let i in columnData) {
            tempData[this.getCellVal(item, rowData.key)][columnData[i].key] = parseFloat(this.getCellVal(item, columnData[i].key))
          }
          if (!!sortingItem && !columnData.some(item => sortingItem.key === item.key))
            tempData[this.getCellVal(item, rowData.key)][sortingItem.key] = this.getCellVal(item, sortingItem.key)
        } else {
          for (let i in columnData) {
            tempData[this.getCellVal(item, rowData.key)][columnData[i].key] += parseFloat(this.getCellVal(item, columnData[i].key))
          }
        }
      })
      let tempData2 = [];
      for (let i in tempData) {
        tempData2.push(Object.assign({ key: i }, tempData[i]))
      }
      tempData = JSON.parse(JSON.stringify(tempData2))
      if (!!sortingItem) {
        switch (sortingItem.type) {
          case 'date':
            if (sortingItem.order === 0) {
              tempData.sort((a, b) => new Date(a[sortingItem.key]).getTime() - new Date(b[sortingItem.key]).getTime())
            } else {
              tempData.sort((a, b) => new Date(b[sortingItem.key]).getTime() - new Date(a[sortingItem.key]).getTime())
            }
            break;
          default:
            if (sortingItem.order === 0) {
              tempData.sort((a, b) => a[sortingItem.key] - b[sortingItem.key])
            } else {
              tempData.sort((a, b) => b[sortingItem.key] - a[sortingItem.key])
            }
        }
      }

      let option = {
        tooltip: {
          show: this.chartConfig.tooltip.status,
          confine: true,
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          show: this.chartConfig.legend.status,
          type: 'scroll',
          data: [],
        },
        xAxis: {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            interval: 0,
          },
        },
        dataZoom: [{
          type: 'slider',
          show: false,
          start: 0,
        }],
        series: []
      };
      // 类型配置
      if (this.seriesType === 0 || this.seriesType === 2) {
        for (let i in columnData) {
          option.legend.data.push(columnData[i].fieldName);
          option.series[i] = {
            name: columnData[i].fieldName,
            data: [],
            type: this.seriesTypes[this.seriesType]
          };
          option.series[i].data = tempData.map(item => item[columnData[i].key])
        }
        xAxisData = tempData.map(item => item.key)

        option.dataZoom[0].show = this.chartConfig.dataZoom.status;

        if (this.chartConfig.gridlines.status) {
          switch (this.chartConfig.gridlines.value) {
            case 0:
              option.xAxis.splitLine.show = true;
              break;
            case 1:
              option.yAxis.splitLine.show = true;
              break;
          }
        }
        if (this.chartConfig.axes.status) {
          switch (this.chartConfig.axes.value) {
            case 0:
              option.xAxis.type = 'category';
              option.yAxis.type = 'value';
              option.xAxis.data = xAxisData;
              break;
            case 1:
              option.yAxis.type = 'category';
              option.xAxis.type = 'value';
              option.yAxis.data = xAxisData;
              break;
          }
        }
        if (this.chartConfig.dataZoom.status) {
          option.dataZoom[0].end = 10 / xAxisData.length * 100;
          switch (this.chartConfig.dataZoom.value) {
            case 0:
              option.dataZoom[0].height = 20;
              option.dataZoom[0].top = 0;
              break;
            case 1:
              option.dataZoom[0].orient = 'vertical';
              option.dataZoom[0].width = 20;
              option.dataZoom[0].right = 0;
              break;
            case 2:
              option.dataZoom[0].height = 20;
              option.dataZoom[0].bottom = 0;
              break;
            case 3:
              option.dataZoom[0].orient = 'vertical';
              option.dataZoom[0].width = 20;
              option.dataZoom[0].left = 0;
              break;
          }
        }
        if (this.chartConfig.tooltip.status) {
          option.tooltip.trigger = 'axis';
        }
      } else if (this.seriesType === 1) {

        option.series = [{
          data: [],
          radius: '50%',
          type: this.seriesTypes[this.seriesType],
          label: {
            show: this.chartConfig.pieLabel.status,
          },
        }]
        tempData.forEach(item => {
          option.legend.data.push(item.key);
          option.series[0].data.push({
            value: item[columnData[0].key],
            name: item.key,
          })
        })

        option.xAxis.show = false;
        option.yAxis.show = false;

        if (this.chartConfig.tooltip.status) {
          option.tooltip.trigger = 'item';
        }

        if (this.chartConfig.pieSize.status && !isNaN(this.chartConfig.pieSize.value)) {
          option.series[0].radius = this.chartConfig.pieSize.value + '%';
        }
      }
      // 公共配置

      if (this.chartConfig.legend.status) {
        switch (this.chartConfig.legend.value) {
          case 0:
            break;
          case 1:
            option.legend.orient = 'vertical';
            option.legend.y = 'center';
            option.legend.x = 'right';
            break;
          case 2:
            option.legend.y = 'bottom';
            option.legend.x = 'center';
            break;
          case 3:
            option.legend.orient = 'vertical';
            option.legend.y = 'center';
            option.legend.x = 'left';
            break;
        }
      }
      if (this.chartConfig.theme.status && this.chartConfig.theme.value !== 0) {
        option.color = this.colors[this.chartConfig.theme.value];
      }
      this.myChart = this.$echarts.init(this.$refs.myChart);
      this.myChart.setOption(option, true);

      if (!rowData.linkToJson) return;
      this.myChart.off('click')
      this.myChart.on('click', e => {
        let branchCodeProps = ['void_branch_code', 'branch_code', 'storeId'];
        let val = e.name;
        let linkToJson = JSON.parse(rowData.linkToJson);
        let index = linkToJson.data.findIndex(item => item.prop === rowData.key)
        if (index !== -1) {
          linkToJson.data[index].con = val;
          linkToJson.data[index].data.condition = val;
        } else if (branchCodeProps.includes(rowData.key)) {
          index = linkToJson.data.findIndex(item => branchCodeProps.includes(item.prop))
          if (index !== -1) {
            linkToJson.data[index].con = val;
            linkToJson.data[index].data.condition = val;
          }
        }

        // 带上时间
        let dateIndex = linkToJson.data.findIndex(item => !!item.data && item.data.fieldType === 'date')
        let queryIndex = this.queryConditions.findIndex(item => !!item.data && item.data.fieldType === 'date')
        if (dateIndex !== -1 && queryIndex !== -1) {
          linkToJson.data[dateIndex].con = this.queryConditions[queryIndex].con;
          linkToJson.data[dateIndex].data.condition = this.queryConditions[queryIndex].data.condition;
          linkToJson.data[dateIndex].data.dateRange = this.queryConditions[queryIndex].data.dateRange;
        }

        // 带上BU
        let buProps = ['bu_name'];
        if (buProps.includes(rowData.key)) {
          linkToJson.data[1].con = val;
          let item = this.dataOrigin.data.data.find(item => item.bu_name === val);
          linkToJson.data[1].val = this.getBuId(item.bu_name);
        }

        sessionStorage.linkToData = JSON.stringify(linkToJson.data);
        this.$router.push({ path: '/home/counterMode' })
      })
    },
    // 处理数据
    dealData (data, header) {
      let jsonVar = {};
      this.headerTraversal(header, jsonVar)
      data = data.map(item => {
        item.children = [];
        item.tableId = this.serialNum++;
        let count = 0;
        for (let key in jsonVar) {
          item[key] = !!item[key] ? JSON.parse(item[key]) : [];
          if (count < item[key].length) count = item[key].length;
        }

        if (count > 0) {
          for (let key in jsonVar) {
            for (let i = 0; i < count - 1; i++) {
              if (!item.children[i]) item.children[i] = { tableId: this.serialNum++ };
              item.children[i].order_no = null;
              item.children[i][key] = item[key][i + 1] || {};
              // if (!!item.children[i][key].card_no) item.children[i][key].card_no = item.children[i][key].card_no.replace(/.{4}$/, '****');
            }
            item[key] = item[key][0] || {};
            // if (!!item[key].card_no) item[key].card_no = item[key].card_no.replace(/.{4}$/, '****');
          }
        }

        // if (!!item.card_no) item.card_no = item.card_no.replace(/.{4}$/, '****');
        return item;
      })
      return data;
    },
    // 遍历出需要解析的字段
    headerTraversal (data, result) {
      data.forEach(item => {
        if (/^(.+)\.(.+)$/.test(item.prop)) {
          var key = item.prop.split('.');
          result[key[0]] = 1;
        }
        if (item.children.length > 0) {
          this.headerTraversal(item.children, result)
        }
      })
    },
    // 获取单元格的值
    getCellVal (data, property) {
      let value = 0;
      if (/^(.+)\.(.+)$/.test(property)) {
        let key = property.split('.');
        value += parseFloat(data[key[0]][key[1]]);
        data.children.forEach(item => {
          value += parseFloat(item[key[0]][key[1]]);
        })
      } else {
        value = data[property];
      }
      return value;
    },
    // 提交
    submit () {
      let param = {
        templateId: this.templateId,
        enables: this.enables,
        chartTitle: this.chartTitle,
        chartType: this.seriesType,
        dataSource: JSON.stringify(this.axisData),
        queryConditions: JSON.stringify(this.queryConditions),
        sortingJson: JSON.stringify(this.sortingData),
      }
      if (this.seriesType === 999) {
        param.chartElements = '{}';
        param.mapAddress = this.iconImg;
      } else {
        param.chartElements = JSON.stringify(this.chartConfig);
      }
      if (!!this.chartDataId) {
        param.id = this.chartDataId;
      }
      AddReportChart(param).then(res => {
        if (res) {
          this.$message.success(this.$i18n.t('message.save'))
          this.back();
        }
      })
    },
    // 删除
    delItem () {
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        DelReportChart(this.chartDataId).then(res => {
          if (res) {
            this.$message.success(this.$i18n.t('message.del'))
            this.back();
          }
        })
      }).catch(() => { })
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.inputFile.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.iconImg = res.message;
          GetImageAll().then(res => this.images = res)

        }
      })
    },
    // 选择图片
    imageClick (e, index) {
      if (this.imageState) {
        this.iconImg = e.mapAddress;
      } else {
        DelImage(e.id).then(res => {
          if (res) this.images.splice(index, 1)
        });
      }
    },
    // 图标跳转
    iconLinkto () {
      if (!this.axisData[0].linkToJson) return;

      let linkToJson = JSON.parse(this.axisData[0].linkToJson);
      sessionStorage.linkToData = JSON.stringify(linkToJson.data);
      this.$router.push({ path: '/home/counterMode' })
    },
    // 获取bu id
    getBuId (data) {
      let item = this.$global.BusinessData.find(item => item.name === data);
      return !!item ? item.id : null;
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.card-wrap {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
  .el-container {
    height: calc(100% - 56px);
    .el-aside {
      width: 200px !important;
      .header {
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
      .chart-menu {
        height: calc(100% - 30px);
        border: 1px solid #dcdfe6;
        padding: 10px;
        overflow: auto;
        .chart-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
          &.active {
            color: #409eff;
          }
          &:hover {
            background: #dcdfe6;
          }
          .icon {
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            // background: #f2f2f2;
            margin-right: 10px;
            font-size: 24px;
          }
        }
      }
    }
    .el-main {
      padding-bottom: 0;
      .title-box {
        padding-bottom: 10px;
        border-bottom: 1px solid #9b9b9b;
        position: relative;
      }
      .el-switch {
        position: absolute;
        top: 10px;
        left: 0;
      }
      .input-item {
        width: 500px;
        margin: 0 auto;
        font-size: 0;
        label {
          width: 100px;
          font-size: 16px;
          display: inline-block;
        }
        .el-input {
          width: calc(100% - 100px);
          font-size: 16px;
          display: inline-block;
        }
      }
    }
  }
}

.chart-wrap {
  height: 40%;
}
.el-tabs {
  height: calc(60% - 47px);
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
    .el-tab-pane {
      height: 100%;
    }
  }
}

.chart-box {
  height: 100%;
}
.icon-box {
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  .left-box,
  .right-box {
    width: 50%;
    height: 100%;
  }
  .panel-item {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
      height: 30%;
      width: 30%;
    }
    .value {
      margin: 5px 0;
      font-weight: bold;
      display: flex;
      align-items: baseline;
      span {
        margin: 0 2px;
      }
    }
    .name {
      width: 100%;
      word-wrap: break-word;
      text-align: center;
    }
  }

  .input-group {
    text-align: right;
  }
  .img-box {
    height: calc(100% - 36px);
    margin-top: 10px;
    .el-image {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border: 2px solid #fff;
      &:hover {
        border: 2px solid #409eff;
      }
      &.danger:hover {
        border: 2px solid #f56c6c;
      }
    }
  }
}
</style>