import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import en from './en';
import cn from './cn';
import tw from './tw';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

//从localStorage获取语言选择。
const i18n = new VueI18n({
  locale: localStorage.lang || 'en',
  messages: {
    en: en,
    cn: cn,
    tw: tw,
  },
})
locale.i18n((key, value) => i18n.t(key, value)) //兼容element

export default i18n
