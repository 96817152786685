<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newProjects.ganttChart.task.title3')"
      :visible.sync="dialogVisible"
      center
      width="750px"
    >
      <!-- <el-divider content-position="left">{{$t('newProjects.ganttChart.task.subtitle1')}}</el-divider> -->
      <div class="select-box">
        <el-select
          v-model="type"
          size="medium"
          @change="searchData"
        >
          <el-option
            value=""
            label="All"
          >All</el-option>
          <el-option
            v-for="item in taskTypes"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <el-row>
        <el-table border          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
          @row-dblclick="dblclick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            :label="$t('newProjects.ganttChart.task.col6')"
            prop="sort"
          ></el-table-column>
          <el-table-column
            :label="$t('newProjects.ganttChart.task.col4')"
            prop="name"
          ></el-table-column>
          <el-table-column
            :label="$t('newProjects.ganttChart.task.col5')"
            prop="projectTypeName"
          ></el-table-column>
          <el-table-column
            :label="$t('newProjects.ganttChart.task.col7')"
            prop="stage"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialogLoading"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import { ImportStandardTask } from '@/api/warehouse/projects'
import { GetStandardTaskList } from '@/api/warehouse/standardTask'
import { GetTaskTypeAll } from '@/api/warehouse/dataMaintenance/projectTaskType'

export default {
  props: ['visible', 'id'],
  data () {
    return {
      dialogVisible: false,
      dialogLoading: false,
      taskTypes: [],
      type: '',
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        if (this.tableData.length === 0) this.getTableData();
        if (this.taskTypes.length === 0) GetTaskTypeAll().then(res => this.taskTypes = res);

      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
    this.getTableData();
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        taskTypeId: this.type,
        projectTypeId: '',
      }
      this.tableLoading = true;
      GetStandardTaskList(param).then(res => {

        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.tableSelected = [e];
      this.importTask();
    },
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.importTask()
    },
    importTask () {
      let param = {
        projectId: this.id,
        taskIds: this.tableSelected.map(item => item.id).join(','),
      }
      this.dialogLoading = true;
      ImportStandardTask(param).then(res => {
        if (res) {
          this.$emit('update')
          this.$message.success(this.$i18n.t('message.import'))
          this.dialogVisible = false;
        }
        this.dialogLoading = false;
      })
    },
    indexMethod (index) {
      return (this.page.current - 1) * this.page.size + index + 1;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
.select-box {
  text-align: right;
  margin-bottom: 10px;
}
</style>