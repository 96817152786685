<template>
  <div
    class="model-wrap"
    ref="modelWrap"
  >
    <el-row>

      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
      </div>
    </el-row>
    <el-tabs
      v-model="activeName"
      type="card"
    >
      <el-tab-pane
        :label="$t('dataMaintenance.receiptAddress.tab')"
        name="tab1"
      >
        <supply-contact
          :items="items"
          :table-height="tableHeight"
        ></supply-contact>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SupplyContact from './SupplyContact'
export default {
  props: ['items'],
  components: {
    SupplyContact,
  },
  data () {
    return {
      activeName: 'tab1',
      name: '',
      abbreviation: '',
      tableHeight: null,
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.name = '';
        this.abbreviation = '';
      } else {
        this.name = this.items.length === 1 ? this.items[0].name : `${this.items[0].name}…`
        this.abbreviation = this.items.length === 1 ? this.items[0].abbreviation : `${this.items[0].abbreviation}…`
      }
    }
  },
  created () {
  },
  mounted () {
    this.tableHeight = this.$refs.modelWrap.clientHeight - 46 - 56 - 46 - 42
  },
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";
.model-wrap {
  height: calc(100% - 55px);
}
</style>