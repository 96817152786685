<template>
  <div :style="{
        'font-size':elData.style.fontSize + 'px',
        'line-height':elData.style.lineHeight + 'px',
        color :elData.style.color ,
        'font-weight': elData.style.fontWeight,
        'font-style': elData.style.fontStyle,
        'text-decoration': elData.style.textDecoration,
        'text-align': elData.style.textAlign,
      }">
    {{elData.contentStart}}{{index + 1}}{{elData.contentEnd}}
  </div>

</template>

<script>
export default {
  props: ['elData', 'index'],
  data () {
    return {
    }
  },
  watch: {

  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>