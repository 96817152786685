<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
      >
        <div
          slot="dot"
          class="dot"
          :class="{
            'primary': activity.status === 0,
            'success': activity.status === 1,
            'warning': activity.status === 2,
          }"
        >{{activities.length - index}}</div>
        <div class="itme-box">
          <div class="top-box">
            <span>{{activity.createTime}}</span>
            <span>{{activity.approvalStatus || '待审批'}}</span>
            <span>{{activity.approvedBy}}</span>
          </div>
          <div class="bottom-box">
            {{activity.reason}}
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
// 全局方法
import {
  toLocaleDate,
} from '@/utils/common'
// 接口
import {
  ExamineApplyLog
} from '@/api/warehouse/purchaseRequisition'

export default {
  props: ['active'],
  data () {
    return {
      activities: []
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab2') {
        this.getLog();
      }
    }
  },
  created () {
  },
  methods: {
    // 初始化数据
    getLog () {
      let param = {
        id: this.$route.query.id
      }
      ExamineApplyLog(param).then(res => {
        this.activities = res.map(item => {
          item.createTime = toLocaleDate(item.createTime);
          return item;
        });
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dot {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  position: relative;
  left: -5px;
  &.primary {
    background: #409eff;
  }
  &.success {
    background: #00cc66;
  }
  &.warning {
    background: #ffcc66;
  }
}
.itme-box {
  width: 620px;
  .top-box {
    span {
      width: 200px;
      display: inline-block;
    }
  }
}
h3 {
  margin-bottom: 10px;
}
</style>