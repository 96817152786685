<template>
  <div>
    <el-form
      :model="groupForm"
      :rules="rules"
      ref="groupForm"
      class="group-form"
      size="medium"
      label-position="top"
    >
      <el-row :gutter="160">
        <el-col :span="12">
          <el-form-item
            label="Title"
            prop="title"
          >
            <el-input v-model="groupForm.title"></el-input>
          </el-form-item>
          <el-form-item
            label="Sub Title 1"
            prop="subTitle1"
          >
            <el-input v-model="groupForm.subTitle1"></el-input>
          </el-form-item>
          <el-form-item
            label="Sub Title 2"
            prop="subTitle2"
          >
            <el-input v-model="groupForm.subTitle2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Icon"
            prop="icon"
          >
            <div class="img-box">
              <el-image
                lazy
                :src="photo"
                fit="cover"
              >
              </el-image>
              <el-button @click="$refs.fileImage.click()">...</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 上传 -->
    <input
      type="file"
      ref="fileImage"
      @change="uploadImage"
      hidden
    >
  </div>
</template>

<script>
// 接口
import { UploadImage } from '@/api'

export default {
  props: ['data'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      imageState: true,
      images: [],
      groupForm: {
        title: '',
        subTitle1: '',
        subTitle2: '',
        icon: '',
      },
      rules: {
        title: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

    }
  },
  computed: {
    photo () {
      if (!!this.groupForm.icon) {
        return this.baseUrl + '/Engineer/getImage.do?imageId=' + this.groupForm.icon;
      } else {
        return '';
      }
    }
  },
  methods: {
    initData (data) {
      this.groupForm = data;
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.fileImage.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.groupForm.icon = res.message;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.img-box {
  display: flex;
  align-items: flex-start;
  .el-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
</style>