<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="500px"
      class="save-dashboard-dialog"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
        label-position="left"
      >
        <el-form-item
          :label="$t('dashBoard.addDashboard.label1')"
          prop="name"
        >
          <el-input v-model="dialog.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('dashBoard.addDashboard.label2')"
          prop="dataAccessGroup"
        >
          <el-select
            :disabled="!permit.dataAccessGroup"
            v-model="dialog.dataAccessGroup"
            @change="dataAccessGroupChange"
            filterable
          >
            <el-option
              v-for="item in dataAccessGroups"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dashBoard.addDashboard.label3')"
          prop="organization"
        >
          <el-select
            v-model="dialog.organization"
            filterable
          >
            <el-option
              v-for="item in organizations"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dashBoard.addDashboard.label4')"
          prop="isDefault"
        >
          <div class="switch-box">
            <el-switch
              :disabled="!permit.default"
              v-model="dialog.isDefault"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="dialog.isDefault ? 'Enable':'Disable'"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <lock-module :state="!permit.default"></lock-module>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          :loading="dialog.loading"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import LockModule from '@/components/LockModule'
// 接口
import {
  GetOrganization,
  GetDataAccessGroupAll,
} from '@/api/organizations'
import { AddLayout } from '@/api/dashboard'

export default {
  props: ['visible', 'layoutData', 'layoutParam', 'type'],
  components: {
    LockModule,
  },
  data () {
    return {
      permit: {
        dataAccessGroup: true,
        default: true,
      },
      isLock: false,
      dataAccessGroups: [],
      organizations: [],
      dialog: {
        visible: false,
        loading: false,
        title: '',
        name: '',
        dataAccessGroup: localStorage.accessGroupId,
        organization: localStorage.organizationId,
        isDefault: 0,
      },
      rules: {
        name: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        dataAccessGroup: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        organization: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        if (this.type === 1) {
          this.dialog.title = this.$i18n.t('dashBoard.addDashboard.title1')
        } else {
          this.dialog = Object.assign(this.dialog, {
            title: this.$i18n.t('dashBoard.addDashboard.title2'),
            name: this.layoutParam.name,
            organization: this.layoutParam.organizationId,
            dataAccessGroup: this.layoutParam.accessGroupId,
            isDefault: this.layoutParam.isDefaultEnable,
          })
        }
        this.dataAccessGroupChange();
        // 获取数据访问组
        GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
      }
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    }
  },
  created () {

    this.dealPermission();
  },
  methods: {
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          layoutJson: JSON.stringify(this.layoutData.map(item => {
            return {
              chartId: item.chartId,
              cardType: item.cardType || 0,
              i: item.i,
              w: item.w,
              h: item.h,
              initH: item.initH,
              x: item.x,
              y: item.y,
            };
          })),
          name: this.dialog.name,
          accessGroupId: this.dialog.dataAccessGroup,
          organizationId: this.dialog.organization,
          isDefaultEnable: this.dialog.isDefault,
        }

        if (this.type === 2) param.id = this.layoutParam.id;

        this.dialog.loading = true;
        AddLayout(param).then(res => {
          if (res === 1) {
            this.$message.success(this.$i18n.t('message.save'));
            this.dialog.visible = false;
            this.$emit('update');
          } else if (res === -1) {
            this.$message.warning('Name is occupied.')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      });
    },
    // 数据访问组变更
    dataAccessGroupChange (e) {
      let param = { accessGroupId: this.dialog.dataAccessGroup }
      if (e !== undefined) {
        this.dialog.organization = '';
      }
      // 获取组织
      GetOrganization(param).then(res => this.organizations = res)

    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        // this.permit.dataAccessGroup = permission.includes('89286471-bec1-49a6-8a85-e1c64308bd2a');
        // this.permit.default = permission.includes('49b06997-4717-4d27-8041-9e1179ae7a4a');
        this.permit.dataAccessGroup = permission.includes('DashboardTemplatesEditDataAccessGroups');
        this.permit.default = permission.includes('DashboardTemplatesSetasDefaultOverviewDashboardTemplates');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  ::v-deep .el-form-item__label {
    width: 100%;
  }
}
.switch-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>