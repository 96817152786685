<template>
  <div>
    <el-row v-if="type == 2">
      <el-button-group>
        <el-button
          size="medium"
          @click="delItem"
        >{{$t('operation.del')}}</el-button>
        <el-button
          size="medium"
          @click="showAdd"
        >{{$t('operation.add')}}</el-button>
        <el-button
          size="medium"
          @click="showEidt"
        >{{$t('operation.reassign')}}</el-button>
      </el-button-group>
    </el-row>
    <el-row>
      <el-table border        :data="tableData"
        @selection-change="val => tableSelected = val"
        @row-click="editClick"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          prop="createTime"
          :label="$t('newSubmitRequest.col3')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          prop="serviceUserName"
          :label="$t('newSubmitRequest.col4')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('newSubmitRequest.col5')"
          sortable
        >
          <template>Unknown</template>
        </el-table-column>
        <el-table-column
          min-width="100"
          v-if="type == 2"
          prop="status"
          :label="$t('newSubmitRequest.col6')"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.status == 0 ? $t('newSubmitRequest.tag3') : $t('newSubmitRequest.tag4')}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          v-if="type == 2"
          prop="dispatchToday"
          :label="$t('newSubmitRequest.col7')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="160"
          prop="workStatusId"
          :label="$t('newSubmitRequest.col9')"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.workStatusId === '00000000000000000000000000000000006'"
              type="warning"
              size="small"
            >{{$t('newSubmitRequest.tag1')}}</el-tag>
            <el-tag
              v-else-if="scope.row.workStatusId === '00000000000000000000000000000000002'"
              type="success"
              size="small"
            >{{$t('newSubmitRequest.tag2')}}</el-tag>
            <!-- <el-tag
              v-else
              type="info"
              size="small"
            >Null</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column
          min-width="240"
          prop="number"
          :label="$t('newSubmitRequest.col10')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="210"
          prop="planArrivalTime"
          :label="$t('newSubmitRequest.col8')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 人员列表弹框 -->
    <staff-dialog
      :visible.sync="staff.visible"
      :type="staff.type"
      :data="tableData"
      @submit="staffSubmit"
    ></staff-dialog>

    <!-- 编辑预计抵达时间 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.type === 1? $t('sendOrder.dialog.title2') : $t('sendOrder.dialog.title1')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item :label="dialog.type === 1? $t('sendOrder.dialog.name2') : $t('sendOrder.dialog.name1') ">
          <span>{{dialog.name}}</span>
        </el-form-item>
        <el-form-item
          v-if="dialog.type === 1"
          :label="$t('sendOrder.dialog.reason')"
          prop="cause"
        >
          <el-input v-model="dialog.cause"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('sendOrder.dialog.date')"
          prop="date"
        >
          <el-date-picker
            v-model="dialog.date"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="editTime"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import {  formatUTCDate } from '@/utils/common'
// 组件
import StaffDialog from '../../workOrderScheduling/components/StaffDialog'
// 接口
import {
  DistributeSubmitRequest,
} from '@/api/warehouse/submitRequest'
export default {
  props: ['info'],
  components: {
    StaffDialog,
  },
  data () {
    return {
      type: null,
      staff: {
        visible: false,
        type: 'add'
      },
      dialog: {
        visible: false,
        loading: false,
        type: 0,
        cause: '',
        name: '',
        date: '',
      },
      rules: {
        // date: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // cause: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
      tableSelected: [],
      tableData: [],
      checkedTabel: [],
    }
  },
  created () {
    this.type = this.$route.query.type;
  },
  methods: {
    // 初始化数据
    initData () {
      this.tableData = this.info;
    },
    //新增
    showAdd () {
      this.staff.type = 'add';
      this.staff.visible = true;
    },
    // 显示改派
    showEidt () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (!this.tableSelected.every(item => item.status == 0)) return this.$message.warning(this.$i18n.t('newSubmitRequest.message1'))
      this.staff.type = 'edit';
      this.staff.visible = true;
    },
    // 编辑预计抵达时间
    editClick (e) {
      if (this.type == 1) return;
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.dialog.name = e.serviceUserName;
      this.dialog.type = 0;
      this.dialog.visible = true;
    },

    // 编辑抵达时间
    editTime () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        if (this.dialog.type === 0) {
          this.tableData = this.tableData.map(item => {
            item.planArrivalTime = formatUTCDate(this.dialog.date);
            return item;
          })
        } else {
          this.tableSelected.forEach(item => {
            item.status = 1;
            item.reason = this.dialog.cause;
          })
          this.tableData = this.tableData.concat(this.checkedTabel.filter(item1 => {
            if (this.tableData.find(item2 => (item2.serviceUserName === item1.name) && (item2.workStatusId === '00000000000000000000000000000000002' || item2.workStatusId == null)) === undefined) {
              return true;
            } else {
              this.$message.warning(`${item1.name}已派单`);
              return false;
            }
          }).map(item => {
            item.serviceUserName = item.name;
            item.dispatchToday = item.currentSum;
            item.planArrivalTime = formatUTCDate(this.dialog.date);
            item.serviceUserId = item.id;
            item.createTime = formatUTCDate(new Date());
            item.status = 0;
            return item;
          }))
        }
        this.dialog.visible = false;
      })
    },
    // 人员提交
    staffSubmit (data) {
      if (this.staff.type === 'add') {
        this.tableData = this.tableData.concat(data.filter(item1 => {
          if (this.tableData.find(item2 => (item2.serviceUserName === item1.name) && (item2.workStatusId === '00000000000000000000000000000000002' || item2.workStatusId == null)) === undefined) {
            return true;
          } else {
            this.$message.warning(`${item1.name}已派单`);
            return false;
          }
        }).map(item => {
          item.serviceUserName = item.name;
          item.dispatchToday = item.currentSum;
          item.serviceUserId = item.id;
          item.status = 0;
          item.createTime = formatUTCDate(new Date());
          item.planArrivalTime = formatUTCDate(new Date(Date.parse(new Date) + 3600 * 1000));
          return item;
        }));
      } else {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.type = 1;
        this.dialog.name = data.map(item => item.name).join(',')
        this.checkedTabel = data;
        this.dialog.visible = true;
      }
    },
    // 删除
    delItem () {
      let checkids = this.tableSelected.map(item => item.id);
      this.tableData = this.tableData.filter(item => {
        if (checkids.includes(item.id)) return false;
        else return true
      })
    },
    // 提交
    submit () {
      this.$emit('update:info', this.tableData)
      // let workOrderId = this.$route.query.id;
      // let parma = this.tableData.map(item => {
      //   return {
      //     workOrderId: workOrderId,
      //     serviceUserId: item.serviceUserId,
      //     status: item.status,
      //     reason: item.reason || '',
      //     planArrivalTime: item.planArrivalTime,
      //     location: item.location,
      //   }
      // })
      // DistributeSubmitRequest(parma).then()
    },
  }
}
</script>

<style>
</style>