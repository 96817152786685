<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.priorityEdit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-position="top"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item
              :label="$t('newSLAPriority.label1')"
              prop="priorityName"
            >
              <!-- <my-select
                :data="prioritys"
                :value.sync="groupForm.priorityName"
                :prop="{
                    label:'name',
                    value:'name'
                  }"
              ></my-select> -->
              <el-input v-model="groupForm.priorityName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :label="$t('newSLAPriority.label2')"
              prop="priorityId"
            >
              <div class="color-box">
                <el-select v-model="groupForm.priorityId">
                  <el-option
                    v-for="item in $global.priorityIds"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-color-picker v-model="groupForm.color"></el-color-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Related To">
              <el-button @click="showServiceCategory">Service Category</el-button>
              <div class="service-name">{{groupForm.serviceName}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs v-model="tabActive">
          <el-tab-pane
            :label="stageTypes[1].name"
            :name="stageTypes[1].name"
          >
            <sla-stage-type
              :tab-active="tabActive"
              :data="stageList[1]"
              :trigger-id.sync="triggerId"
              :warnings="warnings"
              :escalations="escalations"
              :policy-id="policyId"
              :stage-type-name="stageTypes[1].name"
            ></sla-stage-type>
          </el-tab-pane>
          <el-tab-pane
            :label="stageTypes[0].name"
            :name="stageTypes[0].name"
          >
            <sla-stage-type
              :tab-active="tabActive"
              :data="stageList[0]"
              :trigger-id.sync="triggerId"
              :warnings="warnings"
              :escalations="escalations"
              :policy-id="policyId"
              :stage-type-name="stageTypes[0].name"
            ></sla-stage-type>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>

    <!-- 服务类型弹框 -->
    <service-category
      :visible.sync="ServiceCategoryVisible"
      :ids="groupForm.serviceId"
      @submit="submitServiceCategory"
    ></service-category>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import MySelect from '@/components/MySelect'
import ServiceCategory from './components/ServiceCategory'
import SlaStageType from './components/SLAStageType'
// 接口
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'
import {
  AddSLAPriority,
  DelSLATrigger,
  GetSLAPriorityItem,
  GetSLATriggerID,
  GetSLALevelAll,
} from '@/api/warehouse/slaPolicies'

export default {
  name: 'NewSLAPriority',
  components: {
    PageHeader,
    MySelect,
    ServiceCategory,
    SlaStageType,
  },
  data () {
    return {
      permit: {
        priorityEdit: true,
      },
      ServiceCategoryVisible: false,
      title: '',
      tabActive: 'Response',
      // prioritys: [],
      warnings: [],
      escalations: [],
      stageIds: [
        { id: 1, name: 'S01' },
        { id: 2, name: 'S02' },
        { id: 3, name: 'S03' },
        { id: 4, name: 'S04' },
        { id: 5, name: 'S05' },
      ],
      stageTypes: [{}, {}],
      serviceContent: [],
      opHours: [
        '7x24 Hours',
        'Holiday Hours',
        'Holiday Working Hours',
        'Holiday Non-Working Hours',
        'Working Hours',
        'Non-Working Hours',
      ],
      dateTypes: ['Days', 'Hours', 'Mins'],
      groupForm: {
        loading: false,
        id: null,
        priorityName: '',
        priorityId: 1,
        color: '',
        serviceId: '',
        serviceName: '',
      },
      rules: {
        priorityName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // stageType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // triggerName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      policyId: null,
      triggerId: null,
      stageType: '',
      stageList: [{}, {}],
      stageListIndex: 0,
    }
  },
  created () {
    this.policyId = this.$route.query.policyId;
    this.groupForm.id = this.$route.query.id;
    this.dealPermission();
    Promise.all([
      // 获取 SLA Trigger ID
      GetSLATriggerID().then(res => this.triggerId = res.data),
      // 获取SLA Stage Type
      GetDataFieldAll({ parent: '100000000000000000000000000000000021' }).then(res => this.stageTypes = res),

      GetSLALevelAll({ type: 0, policyId: this.policyId }).then(res => this.warnings = res.map(item => {
        item.name = `L${item.level}-${item.description}`;
        return item;
      })),
      GetSLALevelAll({ type: 1, policyId: this.policyId }).then(res => this.escalations = res.map(item => {
        item.name = `Level${item.level}-${item.description}`
        return item;
      })),
    ]).then(res => {
      this.init()
    })

  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    init () {
      if (this.groupForm.id) {
        this.title = this.permit.priorityEdit ? this.$i18n.t('newSLAPriority.title2') : this.$i18n.t('newSLAPriority.title3');
        this.getItem();
      } else {
        this.title = this.$i18n.t('newSLAPriority.title1');
        this.stageType = this.stageTypes[0].id;
        this.stageList = this.stageTypes.map(item => {
          let data = {
            priorityId: this.groupForm.id,
            id: null,
            stageType: item.id,
            stageId: 1,
            triggerName: '',
            description: '',
            startTimer: 0,
            stopConfirmed: false,
            stopScheduled: false,
            stopCompleted: false,
            stopOnHold: false,
            triggers: [],
          }
          data.triggers.push(this.addTriggers())
          return data;
        })
      }
    },
    // 获取单项信息
    getItem () {
      let param = {
        id: this.groupForm.id
      }
      GetSLAPriorityItem(param).then(res => {
        Object.assign(this.groupForm, {
          id: res.id,
          priorityName: res.name,
          priorityId: Number(res.priorityId),
          color: res.color,
          serviceId: res.serviceId || '',
          serviceName: res.serviceName || '',
        })
        this.stageList = res.stageList.map(item => {
          item.stopConfirmed = Boolean(item.stopConfirmed);
          item.stopScheduled = Boolean(item.stopScheduled);
          item.stopCompleted = Boolean(item.stopCompleted);
          item.stopOnHold = Boolean(item.stopOnHold);
          item.triggers = item.triggers.map(item => {
            item.levelId = item.levels.map(item => item.id);
            item.slaLevelId = item.slaLevels.map(item => item.id);
            return item;
          })
          return item;
        });
        this.stageType = this.stageList[0].stageType;
      })
    },
    addTriggers (stageId) {
      let item = {
        stageId: stageId,
        priorityId: '',
        opHour: 0,
        slaTimer: '',
        isWarning: 0,
        timer: '',
        slaTimeType: 0,
        timeType: 0,
        levelId: [],
        slaLevelId: [],
        triggerId: this.triggerId,
      }
      this.triggerId = 'T' + (parseInt(this.triggerId.substr(1)) + 1).toString().padStart(4, '0');

      return item;
    },

    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;

        // 验证Operational hours是否重复
        let opHourArr = new Array(this.opHours.length * 2).fill(0);
        this.stageList.forEach((item, index) => {
          item.triggers.forEach(item => {
            opHourArr[item.opHour + index * this.opHours.length]++
          })
        })
        if (opHourArr.some(item => item > 1)) {
          return this.$message.warning('Operational hours cannot be repeated');
        }

        let param = {
          policyId: this.policyId,
          name: this.groupForm.priorityName,
          priorityId: this.groupForm.priorityId,
          color: this.groupForm.color,
          serviceId: this.groupForm.serviceId,
          serviceName: this.groupForm.serviceName,
          stageList: [],
        }

        param.stageList = this.stageList.map(item => {
          item.stopConfirmed = Number(item.stopConfirmed);
          item.stopScheduled = Number(item.stopScheduled);
          item.stopCompleted = Number(item.stopCompleted);
          item.stopOnHold = Number(item.stopOnHold);
          item.triggers = item.triggers.map(item => {
            item.levelId = !!item.levelId ? item.levelId.join(',') : '';
            item.slaLevelId = !!item.slaLevelId ? item.slaLevelId.join(',') : '';
            return item;
          })
          return item;
        })
        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddSLAPriority(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          } else {
            this.$message.warning(res.data);
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },

    // 显示服务类型弹框
    showServiceCategory () {
      this.ServiceCategoryVisible = true;
    },
    // 提交服务类型
    submitServiceCategory (data) {
      this.groupForm.serviceId = data.map(item => item.id).join(',');
      this.groupForm.serviceName = data.map(item => item.name).join('、');
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');

        this.permit.priorityEdit = permission.includes('SLAPolicies-SLAPriorityEdit')
      }
    }
  },

}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-form {
  height: calc(100% - 56px);
}
.el-tabs {
  height: calc(100% - 115px);
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
    .el-tab-pane {
      height: 100%;
    }
  }
}

.color-box {
  display: flex;
  .el-color-picker {
    margin-left: 5px;
  }
}
.service-name {
  width: calc(100% - 163px);
  margin-left: 10px;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>