<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        class="dialog-form"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          label="数量"
          prop="count"
        >
          <el-input-number
            v-model="dialog.count"
            :max="dialog.maxCount"
            :min="0"
            :controls="false"
            @change="countChange"
          ></el-input-number>
        </el-form-item>
        <!-- <el-form-item
          label="有序列号"
          prop="xxx"
        >
          <el-radio-group>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <el-row>
        <el-table
          border
          ref="table"
          :data="tableData"
          :height="385"
          @selection-change="val => this.tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            label="序列号"
            prop="serialNumber"
          >
          </el-table-column>
        </el-table>

      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
export default {
  components: {
  },
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '本次出库数量编辑',
        count: 0,
        maxCount: 0,
      },
      rules: {},
      tableSelected: [],
      tableData: [],
      toggleEnd: false,
      oldCount: 0,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.toggleEnd = false;
        this.dialog.visible = this.visible;

        this.dialog.count = this.data.deviceCount2;
        this.oldCount = this.dialog.count;
        this.dialog.maxCount = this.data.maxCount;
        this.dialog.count1 = this.data.maxCount - this.data.inventoryDevices.length < 0 ? 0 : this.data.maxCount - this.data.inventoryDevices.length;
        this.dialog.count2 = this.data.maxCount - this.dialog.count1;
        this.tableData = this.data.inventoryDevices;

        this.$nextTick(() => {
          this.tableData.forEach(item => {
            let re = new RegExp(`(?<=^|;)${item.serialNumber}(?=;|$)`);
            if (re.test(this.data.serialNumbers)) {
              this.$refs.table.toggleRowSelection(item, true)
            }
          })
          this.$nextTick(() => {
            this.toggleEnd = true;
          });
        })
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'tableSelected': function (newVal, oldVal) {
      if (!this.toggleEnd) return;

      this.dialog.count += (newVal.length - oldVal.length);
    }
  },
  created () {

  },
  methods: {
    countChange () {
      this.toggleEnd = false;
      if (this.dialog.count > this.oldCount) {
        if (this.dialog.count > this.dialog.count1) {
          let ids = this.tableSelected.map(item => item.id);
          let data = this.tableData.filter(item => !ids.includes(item.id)).slice(0, this.dialog.count - this.dialog.count1 - this.tableSelected.length);
          data.forEach(item => {
            this.$refs.table.toggleRowSelection(item, true);
          })
        }
      } else {
        if (this.dialog.count < this.tableSelected.length) {
          let data = this.tableSelected.slice(0, this.tableSelected.length - this.dialog.count);
          data.forEach(item => {
            this.$refs.table.toggleRowSelection(item, false);
          })
        }
      }

      this.oldCount = this.dialog.count;
      this.$nextTick(() => {
        this.toggleEnd = true;
      })
    },
    // 提交序列号
    submit () {
      this.$emit('submit', {
        index: this.data.rowIndex,
        count: this.dialog.count,
        tableSelected: this.tableSelected,
      });
      this.dialog.visible = false;
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e);
    },
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  line-height: 36px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.input-box {
  display: flex;
  .el-input {
    margin-right: 10px;
  }
}

.dialog-form {
  .el-input-number {
    width: 100%;
  }
}
</style>