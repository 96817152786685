<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="600px"
      :before-close="closeDialog"
    >
      <div class="filter-box">
        <filter-item
          ref="filterItem"
          v-for="(item,index) in filterData"
          :key="index"
          :data="item"
          :mode="mode"
          :reportId="reportId"
          :zindex.sync="zIndex"
          @submit="submitParam"
          @showQuery="showQueryData"
          @closeCondition="closeCondition"
        ></filter-item>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="closeDialog"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >OK</el-button>
      </div>
    </el-dialog>
    <!-- 查询弹窗 -->
    <query
      :visible.sync="QueryVisible"
      :data="queryData"
      :report-template-id="filterData[0].val.id"
      @submit="submitQuery"
    ></query>
  </div>
</template>

<script>
// 组件
import FilterItem from '@/components/FilterItem'
import Query from '@/components/DashBoardDialog/Query'

export default {
  components: {
    FilterItem,
    Query,
  },
  props: ['visible', 'mode', 'data', 'commonParam'],
  data () {
    return {
      dialog: {
        visible: false,
        title: 'New Query',
      },
      reportId: '',
      filterData: [{ val: '' }],
      zIndex: 1,
      QueryVisible: false,
      queryData: null,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.filterData = [
          JSON.parse(JSON.stringify(this.commonParam[0])),
          this.data
        ]
        this.reportId = this.filterData[0].val.id;
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交参数
    submitParam (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);
      this.$set(this.filterData, index, data)
    },

    showQueryData (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);

      this.queryData = this.filterData[index];
      this.QueryVisible = true;
    },
    closeCondition () {

    },
    submitQuery (data) {
      let index = this.filterData.findIndex(item => item.prop === data.prop);
      if (index == -1) {
        this.filterData.push(data)
      } else {
        this.filterData[index] = Object.assign(this.filterData[index], data);
      }
    },
    submit () {
      let param = {
        isReport: this.filterData[0].val.id !== this.reportId,
        newQuery: true,
        history: true,
      };
      let result = JSON.parse(JSON.stringify(this.filterData));
      if (this.filterData[1].prop !== 'branchIds') {
        result.splice(1, 0, Object.assign(this.commonParam[1], {
          mark: "BU",
          prop: "branchIds",
          label: "Site Name",
          con: "ALL",
          val: '',
        }))
      }

      this.$emit('submit', param, result)
      this.dialog.visible = false;
    },
    closeDialog (done) {
      this.$refs.filterItem && this.$refs.filterItem.forEach(item => {
        item.closeDialog();
      })
      this.dialog.visible = false;
      if (typeof done === 'function') done()
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-box {
  display: flex;
  height: 52px;
}
</style>