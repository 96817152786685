<template>
  <div class="lock-wrap">
    <i
      class="default-lock"
      :class="{'el-icon-lock':state,'el-icon-unlock':!state}"
      @click="lockChange"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'LockModule',
  props: ['state'],
  data () {
    return {

    }
  },
  methods: {
    lockChange () {
      if (this.state) {
        this.$confirm('If you  need to change the settings,Please contact your System administrator to approve.', 'Message', {
          confirmButtonText: 'Submit a Request',
          cancelButtonText: 'Cancel',
          center: true,
        }).then(() => {
          this.$message({
            type: 'success',
            message: 'The application has been sent.'
          });
        }).catch(() => { });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lock-wrap {
  display: inline;
}
.default-lock {
  font-size: 26px;
  cursor: pointer;
  position: relative;
  top: 5px;
}

.el-icon-lock {
  color: #67c23a;
}
</style>