<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        class="dialog-form"
        size="small"
      >
        <el-form-item
          label="SLA协议名称"
          prop="slaId"
        >
          <el-select v-model="dialog.slaId">
            <el-option
              v-for="item in slaList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialog.loading"
        >{{$t('operation.submit')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import {
  GetSLAPoliciesList,
  CopySLA,
} from '@/api/warehouse/slaPolicies'
export default {

  props: ['visible', 'copyId'],
  data () {
    return {
      slaList: [],
      dialog: {
        visible: false,
        title: '复制到SLA',
        slaId: null,
      },
      rules: {
        slaId: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },
    }
  },

  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.getTableData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: 9999,
        page: 1,
        search: '',
      };
      GetSLAPoliciesList(param).then((res) => this.slaList = res.list)
    },
    submit () {

      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          src: this.copyId,
          target: this.dialog.slaId,
        }
        CopySLA(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.copy'))
            this.dialog.visible = false;
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>