import request from '@/utils/request'

// 查询联系人列表
export const GetContactList = (data) => request({
  method: 'post',
  url: '/SysContactlist/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改联系人
export const AddContact = (data) => request({
  method: 'post',
  url: '/SysContactlist/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除联系人
export const DelContact = (data) => request({
  method: 'post',
  url: '/SysContactlist/delete.do',
  data: JSON.stringify(data)
})

// 获取联系人集合
export const GetContactAll = (data) => request({
  method: 'post',
  url: '/SysContactlist/queryByCondition.do',
  data: JSON.stringify(data)
})

// 获取联系人集合
export const GetContactAll2 = (data) => request({
  method: 'post',
  url: '/SysContactlist/querySelect.do',
  data: JSON.stringify(data)
})


// 查询联系人绑定的分支机构
export const GetContactByBranch = (data) => request({
  method: 'post',
  url: '/SysContactlist/queryBranch.do',
  data: JSON.stringify(data)
})

// 添加联系人
export const AddContact2 = (data) => request({
  method: 'post',
  url: '/SysContactlist/addContact.do',
  data: JSON.stringify(data)
})
