<template>
  <div class="wrap">
    <el-card>
      <div class="card-wrap">
        <div class="tree-wrap">
          <el-row>
            <el-button-group class="input-group">
              <el-input
                v-model="treeSerachVal"
                class="search-input"
                size="medium"
                placeholder="Search"
              ></el-input>
              <el-button
                :disabled="!permit.add"
                size="medium"
                @click="showAdd"
              >{{$t('operation.add')}}</el-button>
              <el-button
                :disabled="!permit.edit"
                size="medium"
                @click="showEdit"
              >{{$t('operation.edit')}}</el-button>
              <el-button
                :disabled="!permit.del"
                size="medium"
                @click="delNode"
              >{{$t('operation.del')}}</el-button>
            </el-button-group>
          </el-row>
          <el-tree
            :data="treeData"
            :props="defaultProps"
            :default-expanded-keys="expandedList"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            v-loading="treeLoading"
            node-key="id"
            ref="tree"
            highlight-current
            @node-click="nodeClick"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
          >
          </el-tree>
        </div>
        <div class="table-wrap">
          <el-row>
            <div class="input-wrap">
              <el-button-group class="input-group">
                <el-input
                  placeholder="Search"
                  size="medium"
                  class="search-input"
                  v-model="tableSerachVal"
                  clearable
                ></el-input>
                <!-- <el-button
                  size="medium"
                  @click="searchData"
                >{{$t('operation.search')}}</el-button> -->
                <el-button
                  :disabled="!permit.addFile"
                  size="medium"
                  @click="showAddFile"
                >{{$t('operation.add')}}</el-button>
                <el-button
                  :disabled="!permit.editFile"
                  size="medium"
                  @click="showEditFile"
                >{{$t('operation.edit')}}</el-button>
                <el-button
                  :disabled="!permit.delFile"
                  size="medium"
                  @click="delFile"
                >{{$t('operation.del')}}</el-button>
              </el-button-group>
            </div>
          </el-row>
          <el-row
            class="table-row"
            ref="tableRow"
          >
            <el-table
              border
              ref="table"
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
              @selection-change="val => tableSelected = val"
              @row-dblclick="dblclickEdit"
              @row-click="rowClick"
            >
              <el-table-column type="selection"> </el-table-column>
              <el-table-column
                sortable
                :label="$t('projectDocuments.col1')"
                prop="name"
              ></el-table-column>
              <el-table-column
                sortable
                :label="$t('projectDocuments.col2')"
                prop="formats"
              >
              </el-table-column>
              <el-table-column :label="$t('projectDocuments.col3')">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="upload(scope.row)"
                  >{{$t('projectDocuments.btn1')}}</el-button>
                  <el-button
                    size="mini"
                    @click="download(scope.row)"
                  >{{$t('projectDocuments.btn2')}}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
      </div>
    </el-card>
    <!-- 上传文件 -->
    <input
      type="file"
      hidden
      ref="fileInput"
      @change="uploadFile"
    >
    <!-- 文件夹 -->
    <folder-dialog
      :visible.sync="FolderDialogVisible"
      :data="currentNode"
      :type="folderType"
      @update="getTreeData"
    ></folder-dialog>

    <!-- 文件 -->
    <file-dialog
      :visible.sync="FileDialogVisible"
      :parent-data="currentNode"
      :data="fileData"
      @update="getTableData"
    ></file-dialog>
  </div>
</template>

<script>
// 全局方法
import { downFile } from "@/utils/common";
// 组件
import FolderDialog from "./components/FolderDialog";
import FileDialog from "./components/FileDialog";

// 接口
import {
  GetFolderTree,
  GetFileList,
  DelDocument,
  AddDocument,
} from "@/api/warehouse/projectDocuments";
import { UploadImage, DownloadFile } from "@/api";
export default {
  name: "ProjectDocuments",
  components: {
    FolderDialog,
    FileDialog,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      FolderDialogVisible: false,
      FileDialogVisible: false,
      permit: {
        add: true,
        edit: true,
        del: true,
        addFile: true,
        editFile: true,
        delFile: true,
      },

      treeTime: null,
      currentNode: null,
      treeLoading: false,
      treeSerachVal: "",
      expandedList: [],
      treeData: [],
      defaultProps: {
        children: "childrens",
        label: "name",
      },

      tableLoading: false,
      tableSerachVal: "",
      tableSelected: [],
      tableHeight: null,
      tableDataLocal: [],

      folderType: null,
      folderData: null,
      fileData: null,
    };
  },
  computed: {
    tableData: function () {
      return this.tableSerachVal === ""
        ? this.tableDataLocal
        : this.tableDataLocal.filter(
          (item) => item.name.indexOf(this.tableSerachVal) > -1
        );
    },
  },
  watch: {
    treeSerachVal: function (val) {
      this.$refs.tree.filter(val);
    },
    "storeDialog.visible": function (newVal) {
      if (newVal) {
        if (this.storeDialog.parentId) {
        } else {
          // 获取数据访问组
          GetDataAccessGroupAll().then((res) => (this.dataAccessGroups = res));
        }
      }
    },
  },
  created () {
    this.getTreeData();
    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight;
    // this.getTableData();
  },
  methods: {
    getTreeData () {
      this.treeLoading = true;
      GetFolderTree().then((res) => {
        this.treeData = res;
        this.treeLoading = false;
        this.currentNode = null;
      });
    },
    // 节点筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getTableData () {
      this.tableLoading = true;
      GetFileList(this.currentNode.id).then((res) => {
        this.tableDataLocal = res;
        this.tableLoading = false;
      });
    },

    // 显示新增
    showAdd () {
      if (!!this.currentNode && this.currentNode.parentId == -1)
        return this.$message.warning("Task type cannot be added.");
      this.folderType = 1;
      this.FolderDialogVisible = true;
    },
    // 显示编辑
    showEdit () {
      if (!this.currentNode)
        return this.$message.warning("Please select one item.");
      if (this.currentNode.parentId == -1)
        return this.$message.warning("Task type cannot be edited.");
      this.folderType = 2;
      this.FolderDialogVisible = true;
    },
    // 点击树节点
    nodeClick (node) {
      // let oldid = this.currentNode === null ? null : this.currentNode.id;
      if (!!this.currentNode && this.currentNode.id === node.id) {
        this.$refs.tree.setCurrentKey(null);
        this.currentNode = null;
        return;
      }
      this.currentNode = node;
      if (this.currentNode.parentId == -1) return;

      this.getTableData();
      // if (this.treeTime === null) {
      //   this.treeTime = new Date();
      // } else {
      //   let interval = new Date() - this.treeTime;
      //   this.treeTime = null;
      //   if (interval < 1000 && oldid === this.currentNode.id) this.showEdit();
      // }
    },
    // 删除节点
    delNode () {
      if (!this.currentNode)
        return this.$message.warning("Please select one item.");

      this.$confirm("Delete operation, please confirm!", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          DelDocument(this.currentNode.id).then((res) => {
            if (res) {
              this.currentNode = null;
              this.getTreeData();
              this.$message.success(this.$i18n.t("message.del"));
            }
          });
        })
        .catch(() => { });
    },

    // 显示添加
    showAddFile () {
      if (!this.currentNode)
        return this.$message.warning("Please select one item.");
      this.fileData = null;
      this.FileDialogVisible = true;
    },
    // 显示编辑
    showEditFile () {
      if (this.tableSelected.length === 0)
        return this.$message.warning("Please select one item.");
      if (this.tableSelected.length > 1)
        return this.$message.warning("You can only choose one.");
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (item) {
      this.fileData = item;
      this.FileDialogVisible = true;
    },
    // 删除
    delFile () {
      if (this.tableSelected.length === 0)
        return this.$message.warning("Please select at least one item.");

      this.$confirm("Delete operation, please confirm!", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          let arr = this.tableSelected.map((item) => this.delPromise(item.id));
          Promise.all(arr).then((res) => {
            this.getTableData();
            this.$message.success(this.$i18n.t("message.del"));
          });
        })
        .catch(() => { });
    },
    delPromise (id) {
      return new Promise((resolve, reject) => {
        DelDocument(id).then((res) => {
          if (res) resolve();
        });
      });
    },
    // 上传
    upload (item) {
      this.fileData = item;
      this.$refs.fileInput.click();
    },
    // 下载
    download (item) {
      if (!item.paths) return;
      let param = { imageId: item.paths };
      DownloadFile(param).then((res) => {
        downFile(res, `${item.name}.${item.paths.split(".").pop()}`);
      });
    },
    // 上传文件
    uploadFile () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;

      formData.append("file", file);
      UploadImage(formData).then((res) => {
        if (res.success) {
          this.editDocument(res.message);
        }
      });
    },
    editDocument (paths) {
      let param = {
        id: this.fileData.id,
        taskId: this.fileData.taskId,
        parentId: this.fileData.parentId,
        name: this.fileData.name,
        formats: this.fileData.formats,
        type: 1,
        paths: paths,
      };
      AddDocument(param).then((res) => {
        if (res) {
          this.getTableData();
          this.$message.success(this.$i18n.t("message.upload"));
        }
      });
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(",");

        this.permit.edit = permission.includes(
          "ProjectDocumentsEditFoldername"
        );
        this.permit.del = permission.includes("ProjectDocumentsDeleteFolder");
        this.permit.addFile = permission.includes("ProjectDocumentsAddNewfile");
        this.permit.editFile = permission.includes(
          "ProjectDocumentsEditfilenameandformat"
        );
        this.permit.delFile = permission.includes("ProjectDocumentsDeleteFile");
      }
    },

    nodeExpand (data) {
      this.expandedList.push(data.id); // 在节点展开是添加到默认展开数组
    },
    nodeCollapse (data) {
      this.expandedList.splice(this.expandedList.indexOf(data.id), 1); // 收起时删除数组里对应选项
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.card-wrap {
  height: 100%;
  display: flex;
}
.tree-wrap {
  width: 500px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;
}
.table-wrap {
  width: calc(100% - 520px);
}

.el-dialog {
  .el-select {
    width: 100%;
  }
}

.el-tree {
  height: calc(100% - 46px);
  border: 1px solid #e4e4e4;
  overflow: auto;
  padding: 10px;
  .icon-box {
    margin-left: 10px;
  }
  i {
    width: 15px;
    height: 15px;
    line-height: 15px;
    border: 1px solid #9d9d9d;
    text-align: center;
    font-size: 12px;

    &:hover {
      background: #9d9d9d;
      color: #fff;
    }
  }
  // 取消focus的背景颜色
  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background-color: #fff;
  }
  ::v-deep .is-current > .el-tree-node__content {
    background-color: #f0f7ff !important;
  }
}

.table-row {
  height: calc(100% - 46px);
}
</style>
