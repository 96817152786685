<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="900px"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="签收"
          name="tab1"
        >
          <el-form
            :model="dialog"
            :rules="rules"
            ref="dialogForm"
            size="small"
            class="dialog-form"
            label-width="120px"
          >
            <el-form-item
              label="设备名称"
              prop="deviceName"
            >
              <el-input
                v-model="dialog.deviceName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="品牌"
              prop="brandName"
            >
              <el-input
                v-model="dialog.brandName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="规格型号"
              prop="deviceModel"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="dialog.deviceModel"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button
                  v-if="type === 1"
                  @click="searchData"
                >{{$t("operation.search")}}</el-button>
              </el-button-group>
            </el-form-item>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item
                  label="申请数量"
                  prop="appCount"
                >
                  <el-input
                    v-model="dialog.appCount"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="已签收"
                  prop="receiptCount"
                >
                  <el-input
                    v-model="dialog.receiptCount"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              label="本次签收"
              prop="count"
            >
              <el-input-number
                v-model="dialog.count"
                :controls="false"
                :min="countMin"
                :max="countMax"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remark"
            >
              <el-input
                type="textarea"
                v-model="dialog.remark"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="attachment"
              label="上传文件"
            >
              <div class="file-box">
                <div
                  v-for="(item,index) in dialog.attachment"
                  :key="index"
                  class="file-item"
                >
                  <div
                    class="block"
                    @click="previewFile(item)"
                  >
                    <img :src="'./static/image/fileType/' + item.formats + '.png'">
                    <i
                      class="el-icon-delete"
                      @click.stop="delFile(index)"
                    ></i>
                  </div>
                </div>
                <div
                  class="file-item add-item"
                  @click="showUpload"
                >
                  <div class="block">
                    <i class="el-icon-plus"></i>
                  </div>
                  <label></label>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          label="签收历史"
          name="tab2"
        >
          <el-row>
            <el-table
              border
              :data="tableData"
              :height="tableHeight"
              v-loading="tableLoading"
            >
              <el-table-column
                min-width="150"
                prop="receiptDate"
                label="日期"
                sortable
                :formatter="$formatTableData"
              >
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="count"
                label="签收数量"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="180"
                prop="receiptName"
                label="签收人"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="180"
                prop="remark"
                label="备注"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="200"
                label="附件"
                sortable
              >
                <div
                  class="file-box"
                  slot-scope="scope"
                >
                  <div
                    v-for="(item,index) in scope.row.attachment"
                    :key="index"
                    class="file-item"
                  >
                    <div
                      class="block"
                      @click="previewFile(item)"
                    >
                      <img :src="'./static/image/fileType/' + item.formats + '.png'">
                    </div>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="accessGroupName"
                label="操作"
                sortable
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="showEdit(scope.row)"
                  >{{$t('operation.edit')}}</el-button>
                  <el-button
                    size="mini"
                    @click="delItem(scope.row)"
                  >{{$t('operation.del')}}</el-button>
                  <!-- <el-button size="mini">附件</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </el-row>

          <el-row class="page-wrap">
            <my-page
              layout="prev, pager, next, jumper"
              :total="page.total"
              :page-size.sync="page.size"
              :current-page.sync="page.current"
              @current-change="getTableData"
              @size-change="getTableData"
            >
            </my-page>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t("operation.cancel")}}</el-button>
        <el-button
          v-show="activeName === 'tab1'"
          :loading="dialog.loading"
          size="medium"
          type="primary"
          @click="submit"
        >{{$t("operation.confirm")}}</el-button>
      </div>
    </el-dialog>
    <!-- 图片上传 -->
    <input
      type="file"
      ref="fileInput"
      hidden
      @change="fileInputChange"
    >
  </div>
</template>

<script>
// 接口
import { UploadImage } from '@/api'
import {
  AddReceiptRecord,
  GetReceiptRecordList,
  DelReceiptRecord,
} from "@/api/warehouse/purchaseRequisition";
export default {
  props: ['visible', 'data', 'type'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      activeName: 'tab1',
      dialog: {
        visible: false,
        loading: false,
        title: '',
        id: null,
        infoId: '',
        deviceName: '',
        brandName: '',
        deviceModel: '',
        appCount: '',
        receiptCount: '',
        count: 1,
        remark: '',
        attachment: [],
        receiptDate: '',
        receiptBy: '',
      },
      rules: {
        count: [
          { required: true, message: this.$i18n.t("message.notNull"), trigger: "blur" },
          { type: 'number', min: 1, message: '签收数至少为1', trigger: 'blur' },
        ],
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
      tableHeight: 466,
      countMax: 0,
      countMin: 1,
      deviceInfoId: null,
    };
  },

  watch: {
    visible: function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.dialog.loading = false;

        this.init(this.type, this.data);
      }
    },
    "dialog.visible": function (newVal) {
      if (!newVal) {
        this.$emit("update:visible", false);
      }
    },
    'activeName': function (newVal) {
      if (newVal === 'tab2') {
        this.getTableData();
      }
    }
  },
  created () {
  },
  methods: {
    init (type, data) {
      this.activeName = 'tab1';
      switch (type) {
        case 1:
          Object.assign(this.dialog, {
            title: '编辑签收记录',
            appCount: data.appCount,
            count: data.count,
            receiptCount: data.receiptCount - data.count,
            infoId: data.infoId,
            receiptDate: data.receiptDate,
            receiptBy: data.receiptBy,
            attachment: data.attachment,
            remark: data.remark,
          });
          break;
        case 2:
          Object.assign(this.dialog, {
            title: '签收',
            appCount: data.count,
            receiptCount: data.receiptCount,
            infoId: data.id,
          });
      }
      Object.assign(this.dialog, {
        id: data.id,
        deviceName: data.deviceName,
        brandName: data.brandName,
        deviceModel: data.deviceModel,
      })

      this.countMax = this.dialog.appCount - this.dialog.receiptCount;
      this.countMin = this.countMax <= 0 ? 0 : 1;
      this.dialog.count = this.countMin;
    },
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: '',
        deviceInfoId: this.dialog.infoId,
      }
      this.tableLoading = true;
      GetReceiptRecordList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (!item.attachment) {
            item.attachment = [];
          } else {
            item.attachment = item.attachment.split(',').map(item => {
              let formats = item.match(/[^.]*$/)[0];
              return { name: item, formats: formats }
            })
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      // this.page.current = 1;
      // this.getTableData();
    },
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          infoId: this.dialog.infoId,
          count: this.dialog.count,
          attachment: this.dialog.attachment.map(item => item.name).join(','),
          remark: this.dialog.remark,
          receiptCount: this.dialog.receiptCount,
        }
        if (this.type === 1) {
          param.id = this.dialog.id;
          param.receiptDate = this.dialog.receiptDate;
          param.receiptBy = this.dialog.receiptBy;
        }
        this.dialog.loading = true;
        AddReceiptRecord(param).then(res => {
          if (res.success) {
            this.$emit('update');
            this.$message.success(this.$i18n.t("message.receipt"));
            this.dialog.visible = false;
          }

        })
      });
    },
    // 显示修改
    showEdit (row) {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();

      this.init(1, row);
      this.activeName = 'tab1';
    },
    // 删除记录
    delItem (row) {
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: row.id
        }
        DelReceiptRecord(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 删除文件
    delFile (index) {
      this.dialog.attachment.splice(index, 1)
    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
    // 显示上传
    showUpload () {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    fileInputChange () {
      let formData = new FormData();
      let file = this.$refs.fileInput.files[0];
      if (file === undefined) return;
      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          let formats = res.message.match(/[^.]*$/)[0];
          this.dialog.attachment.push({
            name: res.message,
            formats: formats,
          });
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }

  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-input-number {
    width: 100%;
    ::v-deep .el-input__inner {
      text-align: left;
    }
  }
}

.file-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  .block {
    width: 120px;
    height: 120px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
.add-item {
  cursor: pointer;
  .block {
    i {
      position: static;
    }
  }
}

.el-table {
  .file-item {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .block {
    width: 50px;
    height: 50px;
    img {
      width: 40px;
    }
  }
}
</style>
