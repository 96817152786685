import request from '@/utils/request'

// 获取评分选项列表
export const GetEvaluationList = (data) => request({
  method: 'post',
  url: '/EvaluationItem/queryAll.do',
  data: JSON.stringify(data)
})

// 评分
export const Evaluation = (data) => request({
  method: 'post',
  url: '/Evaluation/addEvaluationWeb.do',
  data: JSON.stringify(data)
})