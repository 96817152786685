<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            :disabled="!permit.search"
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            :disabled="!permit.search"
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            size="medium"
            @click="deviceCode"
          >{{$t('inventoryManagement.inWarehouse.btn1')}}</el-button>
          <el-button
            size="medium"
            @click="exportExcel"
          >{{$t('inventoryManagement.inWarehouse.btn2')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="showInWarehouse"
          >批量入库</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="inWarehouse"
          >{{$t('inventoryManagement.inWarehouse.btn3')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="cancelInWarehouse"
          >{{$t('inventoryManagement.inWarehouse.btn4')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="outWarehouse"
          >{{$t('inventoryManagement.inWarehouse.btn5')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="projectOut"
          >{{$t('inventoryManagement.inWarehouse.btn6')}}</el-button>
          <el-button
            size="medium"
            type="primary"
            @click="showCheck"
          >{{$t('inventoryManagement.inWarehouse.btn7')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="showDetails"
        :default-sort="{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="createTime"
          :label="$t('inventoryManagement.inWarehouse.col1')"
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="170"
          sortable
          prop="inType"
          :label="$t('inventoryManagement.inWarehouse.col2')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          label="出库状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.outCount === 0">未出库</span>
            <span v-if="scope.row.outCount > 0 && scope.row.deviceCount > 0">已出库 - 部分</span>
            <span v-if="scope.row.deviceCount === 0">已出库 - 全部</span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="supplierName"
          :label="$t('inventoryManagement.inWarehouse.col3')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="deviceName"
          :label="$t('inventoryManagement.inWarehouse.col4')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="deviceBrand"
          :label="$t('inventoryManagement.inWarehouse.col5')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="deviceModel"
          :label="$t('inventoryManagement.inWarehouse.col6')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="deviceType"
          :label="$t('inventoryManagement.inWarehouse.col7')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="serialNumbers"
          :label="$t('inventoryManagement.inWarehouse.col8')"
        >
          <template slot-scope="scope">
            {{ [scope.row.serialNumbers,scope.row.serialNumbers3].join(';').replace(/^;|;$/g,'') }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          sortable
          prop="deviceUnit"
          :label="$t('inventoryManagement.inWarehouse.col9')"
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          sortable
          prop="deviceCount"
          :label="$t('inventoryManagement.inWarehouse.col10')"
        >
          <template slot-scope="scope">
            {{scope.row.deviceCount + scope.row.outCount }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          sortable
          prop="devicePrice"
          :label="$t('inventoryManagement.inWarehouse.col11')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="applicationFormNumber"
          :label="$t('inventoryManagement.inWarehouse.col12')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="orderNumber"
          :label="$t('inventoryManagement.inWarehouse.col13')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="orderTotalPrice"
          :label="$t('inventoryManagement.inWarehouse.col14')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="purchaseUser"
          :label="$t('inventoryManagement.inWarehouse.col15')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="orderUser"
          :label="$t('inventoryManagement.inWarehouse.col16')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="projectName"
          :label="$t('inventoryManagement.inWarehouse.col17')"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="210"
          sortable
          prop="quotationNumber"
          :label="$t('inventoryManagement.inWarehouse.col18')"
        >
        </el-table-column>
        <el-table-column
          min-width="120"
          sortable
          prop="isNewDevice"
          :label="$t('inventoryManagement.inWarehouse.col19')"
        >
          <template slot-scope="scope">
            {{scope.row.isNewDevice === 1 ? $t('inventoryManagement.inWarehouse.new'): $t('inventoryManagement.inWarehouse.old')}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="isBorrowFactoryDevice"
          :label="$t('inventoryManagement.inWarehouse.col20')"
        >
          <template slot-scope="scope">
            {{scope.row.isBorrowFactoryDevice === 1 ? $t('inventoryManagement.inWarehouse.yes'):$t('inventoryManagement.inWarehouse.no')}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="170"
          sortable
          prop="inRemark"
          :label="$t('inventoryManagement.inWarehouse.col21')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="isConfirm"
          :label="$t('inventoryManagement.inWarehouse.col22')"
        >
          <template slot-scope="scope">
            {{scope.row.isConfirm ? $t('inventoryManagement.inventory.option3'):$t('inventoryManagement.inventory.option2')}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="checkName"
          :label="$t('inventoryManagement.inWarehouse.col23')"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="checkTime"
          :label="$t('inventoryManagement.inWarehouse.col24')"
          :formatter="$formatTableData"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 核对弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-select
        class="full-width"
        size="medium"
        v-model="dialog.checkState"
      >
        <el-option
          :value="0"
          label="待核对"
        ></el-option>
        <el-option
          :value="1"
          label="已核对"
        ></el-option>
      </el-select>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="checkSumit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { downFile } from '@/utils/common'
// 接口
import {
  GetInWarehouseList,
  DelInWarehouse,
  ExportInWarehouse,
  CheckInWarehouse,
} from '@/api/warehouse/inventoryManagement'
export default {
  props: ['active'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        edit: true,
        del: true,
      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '核对',
        checkState: 0,
      },
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === "InventoryManagement" && this.active === 'inWarehouse') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }

    },
    'active': function (newVal) {
      if (newVal === 'inWarehouse') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
  },
  created () {
    this.dealPermission();
  },

  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetInWarehouseList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 设备二维码
    deviceCode () {
      this.$router.push({
        path: '/home/inventoryDeviceCode',
        query: {
          type: 2,
          id: this.tableSelected.length === 0 ? undefined : this.tableSelected.map(item => item.id).join(',')
        }
      })
    },
    // 导出Excel
    exportExcel () {
      let param = {
        search: this.searchVal,
        fieldMap: {
          inTime: this.$i18n.t('inventoryManagement.inWarehouse.col1'),
          inType: this.$i18n.t('inventoryManagement.inWarehouse.col2'),
          supplierName: this.$i18n.t('inventoryManagement.inWarehouse.col3'),
          deviceName: this.$i18n.t('inventoryManagement.inWarehouse.col4'),
          deviceBrand: this.$i18n.t('inventoryManagement.inWarehouse.col5'),
          deviceModel: this.$i18n.t('inventoryManagement.inWarehouse.col6'),
          deviceType: this.$i18n.t('inventoryManagement.inWarehouse.col7'),
          serialNumbers: this.$i18n.t('inventoryManagement.inWarehouse.col8'),
          deviceUnit: this.$i18n.t('inventoryManagement.inWarehouse.col9'),
          deviceCount: this.$i18n.t('inventoryManagement.inWarehouse.col10'),
          devicePrice: this.$i18n.t('inventoryManagement.inWarehouse.col11'),
          applicationFormNumber: this.$i18n.t('inventoryManagement.inWarehouse.col12'),
          orderNumber: this.$i18n.t('inventoryManagement.inWarehouse.col13'),
          orderTotalPrice: this.$i18n.t('inventoryManagement.inWarehouse.col14'),
          purchaseUser: this.$i18n.t('inventoryManagement.inWarehouse.col15'),
          orderUser: this.$i18n.t('inventoryManagement.inWarehouse.col16'),
          projectName: this.$i18n.t('inventoryManagement.inWarehouse.col17'),
          quotationNumber: this.$i18n.t('inventoryManagement.inWarehouse.col18'),
          isNewDevice1: this.$i18n.t('inventoryManagement.inWarehouse.col19'),
          isBorrowFactoryDevice1: this.$i18n.t('inventoryManagement.inWarehouse.col20'),
          inRemark: this.$i18n.t('inventoryManagement.inWarehouse.col21'),
        }
      }
      ExportInWarehouse(param).then(res => {

      })
    },
    // 详情
    showDetails (e) {
      this.$router.push({ path: '/home/inWarehouseDetails', query: { id: e.id, type: this.permit.edit ? 3 : 2 } })
    },
    // 入库
    inWarehouse () {
      this.$router.push({ path: '/home/inWarehouseDetails', query: { type: 1 } })
    },
    // 撤销入库
    cancelInWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('确认要撤销选择的设备吗？', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: this.tableSelected.map(item => item.id).join(',') }
        DelInWarehouse(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success('撤销成功')
          } else {
            this.$message.warning('设备已出库，不能撤销')
          }
        })
      }).catch();
    },
    // 出库
    outWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (this.tableSelected[0].deviceCount === 0) return this.$message.warning('The equipment is out of stock.')
      this.$router.push({
        path: '/home/outWarehouseDetails',
        query: {
          id: this.tableSelected[0].id,
          deviceInfoId: this.tableSelected[0].deviceInfoId,
          type: 5
        }
      })
    },
    // 按项目出库
    projectOut () {
      this.$router.push({ path: '/home/outWarehouseDetails', query: { type: 2 } })
    },
    showCheck () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dialog.checkState = this.tableSelected[0].isConfirm;
      this.dialog.id = this.tableSelected[0].id;
      this.dialog.visible = true;
    },
    // 提交核对状态
    checkSumit () {
      let param = {
        id: this.dialog.id,
        isConfirm: this.dialog.checkState,
      }
      CheckInWarehouse(param).then(res => {
        if (res.success) {
          this.getTableData();
          this.$message.success('核对成功');
          this.dialog.visible = false;
        }
      })
    },
    // 批量入库
    showInWarehouse () {
      this.$router.push({ path: '/home/batchInWarehouse', query: {} })
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('InventoryManagementSearch');
        this.permit.add = permission.includes('InventoryManagementAdd');
        this.permit.edit = permission.includes('InventoryManagementEdit');
        this.permit.del = permission.includes('InventoryManagementDelete');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  padding: 0;
}
.table-row {
  height: calc(100% - 36px - 10px - 32px - 10px);
}
.full-width {
  width: 100%;
}
</style>