<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <div class="left-box">
          <el-date-picker
            size="medium"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            unlink-panels
            value-format="yyyy-MM-dd"
            @change="searchData"
          >
          </el-date-picker>
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
          <div class="checkbox">
            <el-checkbox
              v-if="type === 'order'"
              v-model="isAll"
              @change="searchData"
              :true-label="1"
              :false-label="undefined"
            >待订货</el-checkbox>
            <el-checkbox
              v-if="type === 'inventory'"
              v-model="isInventory"
              @change="searchData"
              :true-label="1"
              :false-label="undefined"
            >待入库</el-checkbox>
            <el-checkbox
              v-if="type === 'inventory'"
              v-model="isOut"
              @change="searchData"
              :true-label="1"
              :false-label="undefined"
            >待出库</el-checkbox>
          </div>
        </div>
        <div class="right-box">
          <span>状态</span>
          <el-select
            size="medium"
            v-model="status"
            @change="searchData"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in $global.deviceStates"
              :key="index"
              :value="index"
              :label="item"
            ></el-option>
          </el-select>
        </div>
      </div>
    </el-row>
    <el-row>
      <el-button-group class="input-group">
        <el-button
          v-if="type === 'order'"
          :disabled="isReplace"
          size="medium"
          @click="showReplace"
        >替换</el-button>
        <el-button
          v-if="type === 'order'"
          :disabled="isDel"
          size="medium"
          @click="delItem"
        >删除</el-button>
        <el-button
          v-if="type === 'order'"
          size="medium"
          @click="showAdd"
        >新建订单</el-button>
        <!-- :disabled="abnormal" -->
        <el-button
          size="medium"
          @click="showDistribute"
        >预分配库存</el-button>
        <el-button
          size="medium"
          @click="showLogistics"
        >发货信息</el-button>
        <el-button
          size="medium"
          @click="showArrival"
        >到货确认</el-button>
        <el-button
          v-if="type === 'inventory'"
          :disabled="signState"
          size="medium"
          @click="showSignIn"
        >签收</el-button>
        <el-button
          v-if="type === 'inventory'"
          size="medium"
          @click="cancelInWarehouse"
        >撤销出入库</el-button>
        <el-button
          size="medium"
          @click="showStatus"
        >状态详情</el-button>
        <el-button
          v-if="type === 'inventory'"
          size="medium"
          type="primary"
          @click="showInWarehouse"
        >批量入库</el-button>
        <el-button
          v-if="type === 'inventory'"
          size="medium"
          type="primary"
          @click="inWarehouse"
        >入库</el-button>
        <el-button
          v-if="type === 'inventory'"
          :disabled="abnormal"
          size="medium"
          type="primary"
          @click="outWarehouse"
        >出库</el-button>
        <el-button
          v-if="type === 'inventory'"
          size="medium"
          type="primary"
          @click="projectOut"
        >按项目出库</el-button>
      </el-button-group>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        ref="table"
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-click="rowClick"
        @row-dblclick="rowContextmenu"
        :default-sort="{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            {{$global.deviceStates[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          label="审批状态"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.approvalStatus === null || scope.row.approvalStatus === 0">待审批</div>
            <div v-else>
              <div>{{scope.row.approvalSymbol}}</div>
              <div>{{scope.row.opName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="typeName"
          label="设备类型"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="deviceName"
          label="设备名称"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          sortable
          prop="brandName"
          label="品牌"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="deviceModel"
          label="规格型号"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="count"
          label="申请数量"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="orderCount"
          label="已订货数"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          label="待订货数"
        >
          <template slot-scope="scope">
            <span v-text="scope.row.count - scope.row.orderCount < 0 ? 0:scope.row.count - scope.row.orderCount"></span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="arrivalCount"
          label="到货数"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="inventoryCount"
          label="可用库存数量"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="preCount"
          label="预分配库存数"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="inCount"
          label="已入库总数"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="outCount"
          label="已出库数1"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="outCount2"
          label="已出库数2"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="outCount3"
          label="已出库数3"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="receiptCount"
          label="已签收"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="lockCount"
          label="锁定"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          prop="purchaseUser"
          label="申请人"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="projectName"
          label="项目名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="210"
          sortable
          prop="quotationNumber"
          label="合同编号"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="applicationNumber"
          label="购物申请单号"
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          prop="orderNumber"
          label="订单编号"
        >
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.orderNumber"
              :key="index"
            >{{ item}}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>
    <!-- 替换弹框 -->
    <replace-dialog
      :visible.sync="ReplaceDialogVisible"
      :application-form-id="equipmentData.applicationFormId"
      :data="equipmentData"
      @update="getTableData"
    ></replace-dialog>

    <!-- 分配弹框 -->
    <distribute-dialog
      :visible.sync="DistributeDialogVisible"
      :data="equipmentData"
      @update="getTableData"
    ></distribute-dialog>
    <!-- 到货弹框 -->
    <arrival-dialog
      :visible.sync="ArrivalDialogVisible"
      :data="equipmentData"
      @update="getTableData"
    ></arrival-dialog>
    <!-- 物流弹框 -->
    <logistics-dialog
      :visible.sync="LogisticsDialogVisible"
      :data="equipmentData"
    ></logistics-dialog>
    <!-- 状态详情 -->
    <state-details
      :visible.sync="StateDetailsVisible"
      :id="deviceInfoId"
    ></state-details>
    <!-- 跳转弹框 -->
    <transfer-dialog
      :visible.sync="TransferDialogVisible"
      :data="equipmentData"
      ref="contextmenu"
    ></transfer-dialog>
    <!-- 签收弹框 -->
    <sign-in-dialog
      :visible.sync="SignInDialogVisible"
      :data="signInData"
      :type="signInType"
      @update="getTableData"
    ></sign-in-dialog>
    <!-- 批量签收弹框 -->
    <batch-sign-in-dialog
      :visible.sync="BatchSignInDialogVisible"
      :data="signInData"
      @update="getTableData"
    ></batch-sign-in-dialog>
  </div>
</template>

<script>
// 插件
import axios from 'axios'
// 组件
import ReplaceDialog from "@/pages/warehouse/inventoryManagement/components/ReplaceDialog";
import DistributeDialog from "./DistributeDialog";
import ArrivalDialog from "./ArrivalDialog"
import LogisticsDialog from './LogisticsDialog'
import StateDetails from "@/pages/warehouse/purchaseRequisition/components/StateDetails";
import TransferDialog from './TransferDialog'
import SignInDialog from '@/pages/warehouse/inventoryManagement/components/SignInDialog'
import BatchSignInDialog from '@/pages/warehouse/inventoryManagement/components/BatchSignInDialog'

// 接口
import {
  DelOutInWarehouse
} from '@/api/warehouse/inventoryManagement'
import {
  DelOrderDevice
} from '@/api/warehouse/purchaseOrders'
export default {
  components: {
    ReplaceDialog,
    DistributeDialog,
    ArrivalDialog,
    LogisticsDialog,
    StateDetails,
    TransferDialog,
    SignInDialog,
    BatchSignInDialog,
  },
  props: ['active', 'type'],
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
        showPOAmount: true,
      },
      ReplaceDialogVisible: false,
      DistributeDialogVisible: false,
      ArrivalDialogVisible: false,
      LogisticsDialogVisible: false,
      StateDetailsVisible: false,
      TransferDialogVisible: false,
      SignInDialogVisible: false,
      BatchSignInDialogVisible: false,
      statusArr: [],
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      dateRange: null,
      status: '',
      isInventory: undefined,
      isOut: undefined,
      isAll: undefined,
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      equipmentData: {},
      deviceInfoId: null,
      source: null,

      signInData: null,
      signInType: null,
    }
  },
  computed: {
    abnormal () {
      return this.tableSelected.length > 1 ||
        this.tableSelected.length === 0 ||
        (
          this.tableSelected.length === 1 &&
          (
            this.tableSelected[0].approvalStatus === 2 ||
            this.tableSelected[0].inventoryCount === 0
          )
        )
    },
    isReplace () {
      return this.tableSelected.length > 1 ||
        this.tableSelected.length === 0 ||
        (
          this.tableSelected.length === 1 &&
          (
            this.tableSelected[0].approvalStatus === 2 ||
            this.tableSelected[0].orderCount > 0 ||
            this.tableSelected[0].preCount > 0
          )
        )
    },
    signState () {
      if (this.tableSelected.length === 0) return true;

      return this.tableSelected.some(item => item.status === 13)
    },
    isDel () {
      // 没有出库， 没有订货，没有预分配库存，没有签收
      return this.tableSelected.length > 1 ||
        this.tableSelected.length === 0 ||
        (
          this.tableSelected.length === 1 &&
          (
            this.tableSelected[0].outCount > 0 ||
            this.tableSelected[0].outCount2 > 0 ||
            this.tableSelected[0].receiptCount > 0 ||
            this.tableSelected[0].orderCount > 0 ||
            this.tableSelected[0].preCount > 0
          )
        )
    },
  },
  watch: {
    '$route': function (to) {
      if ((to.name === 'PurchaseOrders' || to.name === 'InventoryManagement') && this.active === 'orderStatus') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }
    },
    'active': function (newVal) {
      if (newVal === 'orderStatus') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
      }
    },
  },
  created () {
    this.dealPermission();

    document.body.addEventListener('click', this.clearDropdown)

    if (this.type === 'order') this.isAll = 1;
    if (this.type === 'inventory') this.isInventory = 1;
  },
  destroyed () {
    document.body.removeEventListener('click', this.clearDropdown)
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        startDate: !!this.dateRange ? this.dateRange[0] + ' 00:00:00' : null,
        endDate: !!this.dateRange ? this.dateRange[1] + ' 23:59:59' : null,
        statusList: this.status,
        isInventory: this.isInventory,
        isOut: this.isOut,
        all: this.isAll,
      }
      this.tableLoading = true;

      // GetApplyDeviceList(param).then(res => {
      //   this.tableData = res.list
      //   this.page.total = res.total;
      //   this.tableLoading = false;
      //   if (typeof fun === 'function') fun();
      // }).catch(error => {
      //   this.tableLoading = false;
      // })

      // 中断请求
      if (!!this.source) {
        this.source.cancel('interrupt');
      }
      let CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      axios({
        url: process.env.VUE_APP_BASE_URL + '/WarehousePurchaseApplicationFormDeviceInfo/queryByPage.do',
        method: 'post',
        data: JSON.stringify(param),
        headers: {
          token: this.$cookies.get('token') || '',
          'content-type': 'application/json;charset=UTF-8',
        },
        cancelToken: this.source.token
      }).then(response => {
        let res = response.data;
        this.tableData = res.list.map(item => {
          item.orderFormId = !!item.orderFormId ? item.orderFormId.split(',') : [];
          item.orderNumber = !!item.orderNumber ? item.orderNumber.split(',') : [];
          return item;
        })
        this.page.total = res.total;
        this.tableLoading = false;
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    showReplace () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.equipmentData = this.tableSelected[0];
      this.ReplaceDialogVisible = true;
    },
    // 显示新增
    showAdd () {
      this.$router.push({ path: '/home/newOrder', query: { type: 1 } })
    },

    showDistribute () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (this.tableSelected[0].count <= this.tableSelected[0].orderCount) return this.$message.warning('设备已订货,无须预分配.')

      this.equipmentData = this.tableSelected[0];
      this.DistributeDialogVisible = true;
    },
    // 物流信息
    showLogistics () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.equipmentData = this.tableSelected[0];
      this.LogisticsDialogVisible = true
    },
    // 到货确认
    showArrival () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.equipmentData = this.tableSelected[0];
      this.ArrivalDialogVisible = true
    },
    // 批量入库
    showInWarehouse () {
      this.$router.push({ path: '/home/batchInWarehouse', query: {} })
    },
    // 入库
    inWarehouse () {
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      if (this.tableSelected.length === 1) sessionStorage.deviceData = JSON.stringify(this.tableSelected[0])

      this.$router.push({ path: '/home/inWarehouseDetails', query: { type: 1 } })
    },
    // 出库
    outWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (this.tableSelected[0].inventoryCount === 0) return this.$message.warning('The equipment is out of stock.')
      this.$router.push({ path: '/home/outWarehouseDetails', query: { id: this.tableSelected[0].deviceInfoId, type: 1 } })
    },
    // 按项目出库
    projectOut () {
      this.$router.push({ path: '/home/outWarehouseDetails', query: { type: 2 } })
    },
    // 显示状态弹框
    showStatus () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.deviceInfoId = this.tableSelected[0].id;
      this.StateDetailsVisible = true;
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('PurchaseOrdersSearch');
        this.permit.add = permission.includes('PurchaseOrdersAdd');
        // this.permit.edit = permission.includes('PurchaseOrdersEdit');
        this.permit.del = permission.includes('PurchaseOrdersDelete');
        this.permit.showPOAmount = permission.includes('PurchaseOrdersBeallowedtoseetheprice');
      }
    },
    // 行点击
    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    rowContextmenu (row, column, event) {
      event.preventDefault();

      let el = this.$refs.contextmenu.$el;
      if (130 < window.innerWidth - event.pageX) {
        el.style.left = event.pageX + 'px';
      } else {
        el.style.left = event.pageX - 130 + 'px';
      }
      if (180 < window.innerHeight - event.pageY) {
        el.style.top = event.pageY + 'px';
      } else {
        el.style.top = event.pageY - 180 + 'px';
      }
      this.equipmentData = row;
      this.TransferDialogVisible = true;
    },
    clearDropdown () {
      this.TransferDialogVisible = false;
    },
    // 撤销入库
    cancelInWarehouse () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('确认是否撤销！', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = { id: this.tableSelected.map(item => item.id).join(',') }
        DelOutInWarehouse(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success('撤销成功')
          }
        })
      }).catch();
    },
    // 显示签收弹框
    showSignIn () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')

      if (this.tableSelected.length > 1) {
        this.signInData = this.tableSelected;
        this.BatchSignInDialogVisible = true;
      } else {
        this.signInType = 2;
        this.signInData = this.tableSelected[0];
        this.SignInDialogVisible = true;
      }
    },
    // 删除
    delItem () {
      this.$prompt('删除原因', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /.+/,
        inputErrorMessage: '原因不能为空'
      }).then(({ value }) => {
        let param = {
          id: this.tableSelected[0].id,
          deleteReason: value
        }
        DelOrderDevice(param).then(res=>{
          if(res.success){
            this.getTableData()
          }
        })
      }).catch(() => { });
    },
  }
}
</script>
<style lang="scss" scoped>
.input-wrap {
  justify-content: space-between;
  .el-date-editor {
    margin-right: 10px;
  }
}

.wrap {
  height: 100%;
  padding: 0;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 46px - 42px);
}
.cell-style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left-box {
  display: flex;
  align-items: center;
  .checkbox {
    margin-left: 30px;
  }
}
</style>