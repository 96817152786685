<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-row>
        <el-input
          v-model="searchVal"
          placeholder="Search"
          class="search-input"
          size="medium"
          clearable
        ></el-input>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          reserve-selection
          ref="table"
          :data="tableData"
          :height="350"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            prop="name"
            label="All"
          >
          </el-table-column>
        </el-table>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 全局方法
import { debounce } from '@/utils/common'
// 接口
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  props: ['visible'],
  data () {
    return {
      dialog: {
        visible: false,
        title: 'Filter',
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      isNew: true,
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        if (this.isNew) {
          this.getTableData();
        }
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'searchVal': function (newVal) {
      this.getTableData();
    },

  },
  methods: {
    getTableData: debounce(function () {
      let param = {
        parent: '100000000000000000000000000000000006',
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetDataFieldAll(param).then(res => {
        this.tableData = res
        this.tableLoading = false;

        if (this.isNew) {
          this.$nextTick(() => {
            this.$refs.table.toggleAllSelection();
            this.isNew = false;
          })
        }
      })
    }, 300),

    rowClick (e) {
      this.$refs.table.toggleRowSelection(e)
    },
    submit () {
      this.$emit('submit', this.tableSelected);
      this.dialog.visible = false;
    },

  }
}
</script>

<style>
</style>