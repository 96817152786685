<template>
  <div
    class="wrap orderDialogWrap"
    v-show="data.show"
    v-drag="drag"
    ref="orderWrap"
    :class="{
      'collapse-order': !data.visible,
    }"
  >
    <el-card class="card-wrap">
      <div
        class="card-header"
        :style="{background:data.headerColor}"
      >
        <span class="card-title">{{data.title}}</span>
        <div class="header-btn-box">
          <div
            v-if="drag !== false"
            v-show="data.visible"
            class="header-btn"
            @click="nailCard"
          >
            <i
              class="icon-nail"
              :class="{
              'not-nail': isNail
            }"
            ></i>
          </div>
          <div
            v-if="drag === false"
            class="header-btn"
            @click="data.visible = !data.visible"
          >
            <i :class="{'el-icon-arrow-left': data.visible,'el-icon-arrow-right': !data.visible}"></i>
          </div>
          <div
            v-if="drag !== false"
            v-show="data.visible"
            class="header-btn"
            @click="closeCard"
          >
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item
          title="Order Summary"
          name="1"
        >
          <table class="summary-table">
            <tr>
              <td>Order Type</td>
              <td>{{data.title}}</td>
            </tr>
            <tr>
              <td>Order No</td>
              <td>{{data.orderSummary.orderNo}}</td>
            </tr>
            <tr>
              <td>Order Time</td>
              <td>{{data.orderSummary.orderTime}}</td>
            </tr>
            <tr>
              <td>Close Time</td>
              <td>{{data.orderSummary.closeTime}}</td>
            </tr>
            <tr>
              <td>Store ID</td>
              <td>{{data.orderSummary.storeID}}</td>
            </tr>
            <tr>
              <td>Staff Close ID</td>
              <td>{{data.orderSummary.close_uid}}</td>
            </tr>
            <tr>
              <td>Cashier ID</td>
              <td>{{data.orderSummary.cashierID}}</td>
            </tr>
            <!-- <tr>
                        <td>Transaction Handler</td>
                        <td>{{data.orderSummary.transactionHandler}}</td>
                      </tr> -->
            <tr>
              <td>Tips</td>
              <td>{{data.orderSummary.tips}}</td>
            </tr>
            <tr>
              <td>Total Service Charge</td>
              <td>{{data.orderSummary.serv_chg_amt}}</td>
            </tr>
            <tr>
              <td class="total-amount">Total Amount</td>
              <td>{{data.orderSummary.totalAmount}}</td>
            </tr>
            <tr>
              <td>Total Payment</td>
              <td>{{data.orderSummary.tender}}</td>
            </tr>
            <tr>
              <td>Change</td>
              <td>{{data.orderSummary.change}}</td>
            </tr>
          </table>
        </el-collapse-item>
        <el-collapse-item name="2">
          <div
            slot="title"
            class="title"
          >
            <span>Item</span>
            <i
              class="el-icon-full-screen"
              @click.stop="showItemData"
            ></i>
          </div>
          <el-table
            border
            :data="data.itemData"
            :height="height.item"
          >
            <el-table-column
              fixed
              width="130"
              label="Item Name"
            >
              <template slot-scope="scope">{{scope.row.subitem_seq > 0 ? '&nbsp;&nbsp;&nbsp;' + scope.row.item_edesc : scope.row.item_edesc}}</template>
            </el-table-column>
            <el-table-column
              width="80"
              label="Amount"
              prop="amount"
            >
            </el-table-column>
            <el-table-column
              width="110"
              label="Unit Price"
              prop="unit_price"
            ></el-table-column>
            <el-table-column
              width="70"
              label="Quantity"
              prop="item_qty"
            ></el-table-column>
            <el-table-column
              label="PMT"
              prop="pmt"
            >
            </el-table-column>
            <el-table-column
              width="120"
              label="Services Chg(%)"
              prop="serv_chg_amt"
            >
            </el-table-column>
            <el-table-column
              label="Description"
              width="100"
            ></el-table-column>

            <el-table-column
              label="Item ID"
              width="130"
              prop="item_code"
            ></el-table-column>

            <el-table-column
              width="100"
              label="PMT ID"
              prop="pmt_no"
            >
            </el-table-column>
            <el-table-column
              label="Discount Details"
              width="115"
            ></el-table-column>
            <el-table-column
              label="Discount Handler"
              width="115"
            ></el-table-column>
            <el-table-column
              label="Discount Approval code"
              width="160"
            ></el-table-column>
            <el-table-column
              label="Discount Type"
              width="105"
            ></el-table-column>
            <el-table-column
              label="Key in/card"
              width="100"
            ></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          title="Payment"
          name="3"
        >
          <el-table
            border
            :data="data.paymentData"
            :height="height.payment"
          >
            <el-table-column
              width="130"
              label="Type"
              prop="pay_edesc"
            ></el-table-column>
            <el-table-column
              width="80"
              label="Amount"
              prop="pay_amt"
            ></el-table-column>
            <el-table-column
              width="110"
              label="Card ID"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.card_no.replace(/.{4}$/, '****')}}
              </template>
            </el-table-column>
            <el-table-column
              width="70"
              label="Remain"
            ></el-table-column>

            <el-table-column
              label="PayNO."
              width="130"
            ></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          title="Line Void"
          name="4"
        >
          <el-table
            border
            :data="data.lineVoidData"
            :height="height.lvs"
          >
            <el-table-column
              label="Line void time"
              width="130"
              prop="trans_datetime"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="Line void item Code"
              width="130"
              prop="item_code"
            ></el-table-column>
            <el-table-column
              label="Item Qty"
              width="130"
              prop="item_qty"
            ></el-table-column>
            <el-table-column
              label="Unit Price"
              width="130"
              prop="unit_price"
            ></el-table-column>
            <el-table-column
              label="Prn cdesc"
              width="130"
              prop="prn_cdesc"
            ></el-table-column>
            <el-table-column
              label="Prn edesc"
              width="130"
              prop="prn_edesc"
            ></el-table-column>
            <el-table-column
              label="Field void"
              width="130"
              prop="field_void"
            ></el-table-column>
            <el-table-column
              label="Trans type"
              width="130"
              prop="trans_type"
            ></el-table-column>
            <el-table-column
              label="Field Recall"
              width="130"
              prop="field_recall"
            ></el-table-column>
            <el-table-column
              label="Field Refund"
              width="130"
              prop="field_refund"
            ></el-table-column>
            <el-table-column
              label="UID"
              width="130"
              prop="uid"
            ></el-table-column>
            <el-table-column
              label="Item details"
              width="100"
              prop="xxx"
            ></el-table-column>
            <el-table-column
              label="Handler and Approver"
              width="140"
              prop="xxx"
            ></el-table-column>
            <el-table-column
              label="reason"
              width="140"
              prop="xxx"
            ></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          title="Release"
          name="5"
        >
          <el-table
            border
            :data="data.releaseData"
          >
            <el-table-column
              label="Releas time"
              width="130"
            ></el-table-column>
            <el-table-column
              label="Release item ID"
              width="130"
            ></el-table-column>
            <el-table-column
              label="Release Item details"
              width="130"
            ></el-table-column>
            <el-table-column
              label="Handler and Approver"
              width="140"
            ></el-table-column>
            <el-table-column label="Reason"></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          title="CRM"
          name="6"
        >
          <el-table
            border
            :data="data.CRMData"
          >
            <el-table-column
              label="CRM ID"
              width="130"
            ></el-table-column>
            <el-table-column
              label="Action details"
              width="130"
            ></el-table-column>
            <el-table-column label=""></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          title="Open Key"
          name="7"
        >
          <el-table
            border
            :data="data.openKeys"
          >
            <el-table-column
              label="Open key type"
              width="130"
            ></el-table-column>
            <el-table-column
              label="Key descriptions"
              width="130"
            ></el-table-column>
            <el-table-column label=""></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          title="Table"
          name="8"
        >
          <el-table
            border
            :data="data.tableData"
          >
            <el-table-column
              label="Table No."
              width="130"
              prop="table_id"
            ></el-table-column>
            <el-table-column
              label="Number of people"
              width="130"
              prop="nop"
            ></el-table-column>
            <el-table-column
              label="Details"
              width="130"
              prop="xxx"
            ></el-table-column>

            <!-- <el-table-column
                        label="service charge"
                        width="130"
                        prop="serv_chg_amt"
                      ></el-table-column>
                      <el-table-column
                        label="Checkout clerk"
                        width="130"
                        prop="close_uid"
                      ></el-table-column> -->
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ['data', 'height', 'drag'],
  data () {
    return {
      activeNames: ['1', '2', '3'],

      isNail: false,
    }
  },
  created () {

  },
  methods: {
    nailCard () {
      this.isNail = !this.isNail;
    },
    closeCard () {
      let item = Object.assign(this.data, {
        show: false,
      })
      this.$emit('update:data', item)
      this.isNail = false;
    },
    showItemData () {
      this.isNail = true;
      this.$emit('showItem')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
  background: #fff;
}

.title {
  padding-bottom: 5px;
  // border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  .tooltip {
    color: #535353;
    right: 10px;
    top: 5px;
    cursor: pointer;
    display: flex;
    user-select: none;
    .btn {
      margin: 0 3px;
      border-radius: 5px;
      &:hover {
        background: #b4bccc;
        color: #fff;
      }
    }
    .el-color-picker {
      width: 16px;
      height: 16px;
      margin: 0 3px;
      ::v-deep .el-color-picker__trigger {
        width: 16px;
        height: 16px;
        padding: 0;
      }
    }
  }
}

.card-wrap {
  height: 100%;
  // width: 420px;
  // margin-right: 15px;
  background: #f2f7fb;
  flex-wrap: nowrap;
  flex-shrink: 0;
  .el-table {
    ::v-deep .cell {
      font-size: 12px;
    }
  }
  ::v-deep .el-card__body {
    height: 100%;
    padding: 0;
  }
  &.collapse-order {
    width: 40px !important;
    ::v-deep .el-card__body {
      height: 100%;
      overflow: hidden;
    }
    .card-header {
      height: 100%;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
      i {
        margin: 0;
      }
      .card-title {
        transform: rotate(90deg);
        width: 200px;
        position: relative;
        top: 115px;
        height: 0;
        line-height: 0;
      }
    }
    .el-row {
      // display: none;
      opacity: 0;
    }
  }

  .card-header {
    width: 100%;
    padding: 15px;
    color: #fff;
    display: flex;
    justify-content: space-between;

    .card-title {
      font-size: 16px;
    }
    .header-btn-box {
      display: flex;
    }
    .header-btn {
      width: 20px;
      height: 20px;
      text-align: center;
      margin-left: 5px;
      font-size: 14px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #b4bccc;
      }
      i {
        display: block;
        line-height: 20px;
      }
    }
  }
  .list-header {
    color: #000;
  }
}
.summary-table {
  width: 100%;
  border-collapse: collapse;
  tr:nth-of-type(2n - 1) {
    background-color: #fff !important;
  }
  tr:nth-of-type(2n) {
    background-color: #e0ecf5 !important;
  }
  td:first-child {
    width: 110px;
  }
  td {
    font-size: 12px;
    // border: 1px solid #b9bcc1;
    height: 40px;
    color: #000;
    text-align: left;
    padding: 0 10px;
  }
  .total-amount {
    background-color: #aaffaa;
  }
}

.collapse {
  height: 35px !important;
}
.el-collapse {
  height: calc(100% - 50px);
  overflow: auto;

  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.el-collapse-item {
  .title {
    width: 100%;
    padding: 0;
    padding-right: 5px;
    display: flex;
    align-items: center;
  }
}
::v-deep .el-collapse-item__header {
  padding: 0 15px;
  font-size: 14px;
}
::v-deep .el-collapse-item__content {
  padding: 0;
}
.el-table {
  ::v-deep tr {
    background-color: #fff !important;
  }
  ::v-deep td {
    border: none;
    text-align: left;
    color: #555;
  }
  ::v-deep th {
    color: #555 !important;
    text-align: left;
    background-color: #e0ecf5 !important;
  }

  ::v-deep .el-table__empty-block {
    .el-table__empty-text {
      color: #000;
    }
  }
  ::v-deep .original-order {
    background-color: #1ecca9 !important;
    td {
      background-color: #1ecca9 !important;
      color: #fff;
    }
  }
  ::v-deep .pre-order {
    background-color: #39f !important;
    td {
      background-color: #39f !important;
      color: #fff;
    }
  }
  ::v-deep .post-order {
    background-color: #006cd9 !important;
    td {
      background-color: #006cd9 !important;
      color: #fff;
    }
  }
}

::v-deep .el-collapse-item__wrap,
::v-deep .el-collapse-item__header {
  background: none;
  border-bottom: none;
}

.el-collapse-item:nth-of-type(1) {
  background-color: #e0ecf5;
}

.el-collapse-item:nth-of-type(2) {
  background-color: #bcd7ed;
}

.el-collapse-item:nth-of-type(3) {
  background-color: #abcde9;
}

.el-collapse-item:nth-of-type(4) {
  background-color: #8fbce0;
}

.el-collapse-item:nth-of-type(5) {
  background-color: #6fa8d7;
}
.el-collapse-item:nth-of-type(6) {
  background-color: #5f9fd3;
}
.el-collapse-item:nth-of-type(7) {
  background-color: #4992cd;
}
.el-collapse-item:nth-of-type(8) {
  background-color: #3584c4;
}

.el-collapse-item:nth-of-type(4),
.el-collapse-item:nth-of-type(5),
.el-collapse-item:nth-of-type(6),
.el-collapse-item:nth-of-type(7),
.el-collapse-item:nth-of-type(8) {
  ::v-deep .el-collapse-item__header {
    color: #fff;
  }
}

.icon-nail.not-nail {
  transform: rotate(30deg);
}
</style>