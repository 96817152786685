<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="500px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialog"
        class="dialog-form"
        size="small"
        label-width="270px"
      >
        <el-form-item label="申请数量">
          {{data.appCount}}
        </el-form-item>
        <el-form-item label="已订货数量">
          {{data.orderCount}}
        </el-form-item>
        <el-form-item label="已入库总数">
          {{data.inCount}}
        </el-form-item>
        <el-form-item label="当次入库数量">
          {{data.curInCount}}
        </el-form-item>
        <el-form-item label="已预分配">
          {{data.allPreCount}}
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <div>出库1</div>
            <div>( 出库到本项目 )</div>
          </template>
          {{data.outCount}}
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <div>出库2</div>
            <div>( 出库到其他项目 )</div>
          </template>
          {{data.outCount2}}
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <div>出库3</div>
            <div>( 其他项目出到本项目 )</div>
          </template>
          {{data.outCount3}}
        </el-form-item>
        <el-form-item label="锁定">
          {{data.lockCount}}
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <div>剩余未出库</div>
            <div>( 本项目入库后再出库后，剩余未出库 )</div>
          </template>
          <span v-if="data.outCount < data.lockCount">
            {{data.inCount - data.lockCount - data.outCount2}}
          </span>
          <span v-else>
            {{data.inCount - data.outCount - data.outCount2}}
          </span>
        </el-form-item>
        <el-form-item label="可用库存总数">
          {{data.inventoryCount}}
        </el-form-item>
        <el-form-item label="总预分配数量">
          {{data.allPreCount}}
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口

export default {
  props: ['visible', 'data'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '库存详情',
      },
      rules: {},
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  ::v-deep .el-form-item__label {
    height: 32px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}
</style>