import request from '@/utils/request'

// 查询库存列表
export const GetInventoryList = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/queryAllGroup.do',
  data: JSON.stringify(data)
})

// 查询入库列表
export const GetInWarehouseList = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/queryByPage.do',
  data: JSON.stringify(data)
})

// 查询出库列表
export const GetOutWarehouseList = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/queryByPage.do',
  data: JSON.stringify(data)
})

// 添加或修改入库
export const AddInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 添加或修改出库
export const AddOutWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 获取入库详情
export const GetInWarehouseItem = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/queryById.do',
  data: JSON.stringify(data)
})

// 获取出库详情
export const GetOutWarehouseItem = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/queryById.do',
  data: JSON.stringify(data)
})

// 撤销入库
export const DelInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/cancel.do',
  data: JSON.stringify(data)
})

// 撤销出库
export const DelOutWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/del.do',
  data: JSON.stringify(data)
})

// 撤销出入库
export const DelOutInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/revokeInAndOut.do',
  data: JSON.stringify(data)
})

// 解除归还
export const ReturnOutWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/retrocede.do',
  data: JSON.stringify(data)
})

// 获取出库设备
export const GetOutWarehouseDevice = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/queryByOut.do',
  data: JSON.stringify(data)
})

// 获取库存设备详情分页
export const GetInventoryDeviceList = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/queryAllDetails.do',
  data: JSON.stringify(data)
})

// 库存导出
export const ExportInventory = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/exportAllGroup.do',
  data: JSON.stringify(data),
  responseType: 'arraybuffer'
})
// 入库导出
export const ExportInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/exportByPage.do',
  data: JSON.stringify(data),
  responseType: 'arraybuffer'
})
// 出库导出
export const ExportOutWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/exportByPage.do',
  data: JSON.stringify(data),
  responseType: 'arraybuffer'
})

// 出库设备分页查询
export const GetOutWarehouseDeviceByDeviceId = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/queryGroupByAppNum.do',
  data: JSON.stringify(data)
})

// 核对
export const CheckInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/check.do',
  data: JSON.stringify(data)
})

// 获取出库其它项目列表
export const GetOutWarehouseElseList = (data) => request({
  method: 'post',
  url: '/WarehouseInventoryOut/queryOutByApp.do',
  data: JSON.stringify(data)
})

// 批量入库
export const AddBatchInWarehouse = (data) => request({
  method: 'post',
  url: '/WarehouseInventory/addList.do',
  data: JSON.stringify(data)
})