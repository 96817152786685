<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="800px"
    >
      <div class="box-wrap">
        <grid-layout
          ref="shadow"
          :layout="layout"
          :col-num="24"
          :rowHeight="baseLen"
          :is-draggable="true"
          :margin="[6, 6]"
          :verticalCompact="true"
          :isResizable="true"
        >
          <div class="shadow-box">

            <grid-item
              v-for="(item,index) in layout"
              :key="item.i"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
            >
              <div class="con">
                <img
                  v-show="!!item.img"
                  :src="item.img"
                  class="card-img"
                >
              </div>
            </grid-item>
          </div>
          <div
            class="box"
            ref="box"
            @contextmenu.prevent="saveCard"
          >
            <div
              v-for="item in blockNum"
              :key="item"
              class="item"
              :data-index="item"
              :class="{
              'start':coord.end.length === 0 && Math.ceil(item / 24) - 1 === coord.start[1] && (item-1) % 24 === coord.start[0],
              'active': Math.ceil(item / 24) - 1 >= coord.start[1] && Math.ceil(item / 24) - 1 <= coord.end[1] && (item-1) % 24 >= coord.start[0] &&(item-1) % 24 <= coord.end[0],
               }"
              :style="{height: blockLen +'px', width: blockLen +'px'}"
            >
              <div
                class="con"
                @click="clickItem(item)"
              >
              </div>
            </div>
          </div>
        </grid-layout>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import VueGridLayout from 'vue-grid-layout'
export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: ['visible', 'layoutData'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: 'Add New Widget',
      },
      layout: [],
      baseLen: 0,
      blockLen: 0,
      blockNum: 0,
      coord: {
        start: [],
        end: [],
      },
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.layout = JSON.parse(JSON.stringify(this.layoutData));
        this.coord = {
          start: [],
          end: [],
        }
        let clock = null;
        this.dialog.visible = this.visible;

        this.$nextTick(() => {
          var myEvent = new Event('resize');
          window.dispatchEvent(myEvent);
          
          this.baseLen = Math.floor((this.$refs.box.clientWidth - 6 * 25) / 24);
          this.blockLen = Math.floor(this.$refs.box.clientWidth / 24);

          clock = setInterval(() => {
            if (this.$refs.shadow.$el.clientHeight !== 0) {
              this.blockNum = ((this.$refs.shadow.$el.clientHeight - 6) / 31 + 12) * 24;
              clearInterval(clock)
            }
          }, 300)
        })
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 选择大小
    clickItem (index) {
      let x = (index - 1) % 24;
      let y = Math.ceil(index / 24) - 1;
      if (this.coord.start.length === 0) {
        this.coord.start = [x, y]
      } else if (this.coord.end.length === 0) {
        if (this.coord.start[0] <= x && this.coord.start[1] <= y) {
          this.coord.end = [x, y]
        } else if (this.coord.start[0] < x && this.coord.start[1] > y) {
          this.coord.end = [x, this.coord.start[1]]
          this.coord.start[1] = y;
        } else if (this.coord.start[0] > x && this.coord.start[1] < y) {
          this.coord.end = [this.coord.start[0], y]
          this.coord.start[0] = x;
        } else {
          this.coord.end = JSON.parse(JSON.stringify(this.coord.start));
          this.coord.start = [x, y]
        }
      } else {
        this.coord.start = [x, y]
        this.coord.end = []
      }
    },
    saveCard () {
      if (this.coord.start.length === 0 || this.coord.end.length === 0) return this.$message.warning('Please select size.')
      let i = this.layout.length > 0 ? this.layout.slice(-1)[0].i + 1 : 1;
      this.layout.push({
        i: i,
        x: this.coord.start[0],
        y: this.coord.start[1],
        w: this.coord.end[0] - this.coord.start[0] + 1,
        h: this.coord.end[1] - this.coord.start[1] + 1,
        initH: this.coord.end[1] - this.coord.start[1] + 1,
      });
      this.coord = {
        start: [],
        end: [],
      }
      let clock = null;
    },
    // 提交
    submit () {
      this.$emit('addLayout', this.layout)
      this.dialog.visible = false;
    },

  }
}
</script>

<style lang="scss" scoped>
.box-wrap {
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
  }
  ::v-deep .vue-grid-item.vue-grid-placeholder {
    display: none;
  }
}
.box {
  width: 100%;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .item {
    float: left;
    position: relative;
    &.start {
      .con {
        background: #29e0bb;
      }
    }
    &.active {
      .con {
        background: #99ccff;
      }
    }
    .con {
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      background: #eee;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 3px;
      left: 3px;
    }
  }
}
.shadow-box {
  width: 100%;
  margin: -3px;
  position: absolute;
  top: 0;
  z-index: 2;
  .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #eee;
    .card-img {
      width: 100%;
      height: 100%;
    }
  }
}
.vue-grid-item {
  z-index: 2;
}
</style>