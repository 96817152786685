<template>
  <div
    class="wrap"
    v-resize="resize"
  >
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
        label-width="170px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('newDevice.label1')"
              prop="deviceName"
            >
              <el-button-group class="input-group">
                <el-input
                  v-model="groupForm.deviceName"
                  class="search-input"
                  disabled
                ></el-input>
                <el-button @click="showEquipment">{{$t('operation.search')}}</el-button>
              </el-button-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newDevice.label2')"
              prop="operatingStatus"
            >
              <el-select v-model="groupForm.operatingStatus">
                <el-option
                  v-for="item in operatingStatus"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newDevice.label3')"
              prop="warrantyExpiryDate"
            >
              <el-date-picker
                v-model="groupForm.warrantyExpiryDate"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('newDevice.label4')"
              prop="company"
            >
              <el-select
                v-model="groupForm.company"
                @change="companyChange"
                filterable
              >
                <el-option
                  v-for="item in companys"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('newDevice.label5')"
              prop="organic"
            >
              <el-select
                v-model="groupForm.organic"
                filterable
                @change="changeOrganic"
              >
                <el-option
                  v-for="item in organics"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="$t('newDevice.tab1')"
            name="BaseInfomation"
          >
            <el-row>
              <el-col :span="6">
                <el-image
                  lazy
                  class="picture"
                  ref="picture"
                  :src="photo"
                  fit="cover"
                  @click.native="$refs.fileImage.click()"
                ></el-image>
                <input
                  type="file"
                  ref="fileImage"
                  @change="uploadImage"
                  hidden
                >
              </el-col>
              <el-col :span="18">
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label6')"
                      prop="deviceBrand"
                    >
                      <el-input
                        v-model="groupForm.deviceBrand"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label7')"
                      prop="deviceType"
                    >
                      <el-input
                        v-model="groupForm.deviceType"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label8')"
                      prop="serialNumber"
                    >
                      <el-input v-model="groupForm.serialNumber"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label9')"
                      prop="deviceModel"
                    >
                      <el-input
                        v-model="groupForm.deviceModel"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label10')"
                      prop="installPosition"
                    >
                      <el-input v-model="groupForm.installPosition"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label11')"
                      prop="deviceLabel"
                    >
                      <el-input v-model="groupForm.deviceLabel"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label12')"
                      prop="floor"
                    >
                      <el-input v-model="groupForm.floor"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label13')"
                      prop="installNumber"
                    >
                      <el-input v-model="groupForm.installNumber"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('newDevice.label30')"
                      prop="qrCode"
                    >
                      <div class="qrcode-box">
                        <el-input v-model="groupForm.qrCode"></el-input>
                        <el-popover
                          placement="right"
                          trigger="hover"
                          class="icon-qrcode-wrap"
                        >
                          <i
                            class="icon-qrcode"
                            slot="reference"
                          ></i>
                          <qrcode :value="groupForm.qrCode"></qrcode>
                        </el-popover>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="Import ID"
                      prop="sn"
                    >
                      <el-input-number
                        disabled
                        :controls="false"
                        v-model="groupForm.sn"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item
                        label="Remarks"
                        prop="remark"
                      >
                        <el-input v-model="groupForm.remark"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Connected to"
                        prop="connectedId"
                      >
                        <el-select
                          v-model="groupForm.connectedId"
                          filterable
                        >
                          <el-option
                            v-for="item in deviceData"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-row>
              </el-col>
            </el-row>

          </el-tab-pane>
          <el-tab-pane
            :label="$t('newDevice.tab2')"
            name="Parameters"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('newDevice.label14')"
                  prop="deviceIP"
                >
                  <el-input v-model="groupForm.deviceIP"></el-input>
                  <!-- :placeholder="$t('newDevice.prompt')" -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('newDevice.tab3')"
            name="ProjectRecords"
          >
            <project-records
              ref="projectRecords"
              :organic="groupForm.organic"
              :device-id="groupForm.id"
              :data="groupForm.contractList"
              :active="activeName"
            ></project-records>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('newDevice.tab4')"
            name="PurchaseRecords"
          >
            <purchase-records
              ref="purchaseRecords"
              :organic="groupForm.organic"
              :device-id="groupForm.id"
              :data="groupForm.contractList"
              :active="activeName"
            ></purchase-records>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('newDevice.tab5')"
            name="ContractWarranty"
          >
            <contract-warranty
              ref="contractWarranty"
              :organic="groupForm.organic"
              :data="groupForm.contractList"
            ></contract-warranty>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('newDevice.tab6')"
            name="RecordsMaintenance"
          >
            <div class="label-box">
              <div
                class="item"
                :class="{'active': tableName === 'RepairTable'}"
                @click="switchRecord('RepairTable')"
              >
                <label>{{$t('newDevice.repair.title1')}}</label>
                <span>{{groupForm.repairDeviceList.length}}</span>
              </div>
              <div
                class="item"
                :class="{'active': tableName === 'ReplaceTable'}"
                @click="switchRecord('ReplaceTable')"
              >
                <label>{{$t('newDevice.replace.title1')}}</label>
                <span>{{groupForm.replaceDeviceList.length}}</span>
              </div>
              <div
                class="item"
                :class="{'active': tableName === 'RemoveTable'}"
                @click="switchRecord('RemoveTable')"
              >
                <label>{{$t('newDevice.remove.title1')}}</label>
                <span>{{groupForm.removeDeviceList.length}}</span>
              </div>
              <div
                class="item"
                :class="{'active': tableName === 'InstallationTable'}"
                @click="switchRecord('InstallationTable')"
              >
                <label>{{$t('newDevice.installation.title1')}}</label>
                <span>{{groupForm.installationDeviceList.length}}</span>
              </div>
              <div
                class="item"
                :class="{'active': tableName === 'MaintainTable'}"
                @click="switchRecord('MaintainTable')"
              >
                <label>{{$t('newDevice.maintain.title1')}}</label>
                <span>{{groupForm.itemList.length}}</span>
              </div>
              <div
                class="item"
                :class="{'active': tableName === 'OtherTable'}"
                @click="switchRecord('OtherTable')"
              >
                <label>{{$t('newDevice.otherServices.title1')}}</label>
                <span>{{groupForm.classifiedList.length}}</span>
              </div>
            </div>
            <component
              :is="tableName"
              :data="tableData"
            ></component>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>

    <!-- 设备弹框 -->
    <equipment-dialog
      :visible.sync="EquipmentDialogVisible"
      :is-create="true"
      :organic="groupForm.organic"
      @submit="equipmentSubmit"
    ></equipment-dialog>

  </div>
</template>

<script>
// 全局方法
import { formatUTCDate, toLocaleDate, debounce } from '@/utils/common'

// 组件
import MySelect from '@/components/MySelect'
import PageHeader from '@/components/PageHeader'
import EquipmentDialog from './components/EquipmentDialog'
import RepairTable from './components/RepairTable'
import ReplaceTable from './components/ReplaceTable'
import RemoveTable from './components/RemoveTable'
import InstallationTable from './components/InstallationTable'
import MaintainTable from './components/MaintainTable'
import OtherTable from './components/OtherTable'
import qrcode from '@/components/qrcode'
import ContractWarranty from './components/ContractWarranty'
import PurchaseRecords from './components/PurchaseRecords'
import ProjectRecords from './components/ProjectRecords'
// 接口
import {
  AddDevice,
  GetDeviceItem,
  GetDeviceList,
} from '@/api/warehouse/deviceManagement'
import { GetCompanyBranch } from '@/api/warehouse/projectContract'
import { ConfirmationTemplates } from '@/api/warehouse/dataMaintenance/serviceConfirmationTemplate'
import { UploadImage } from '@/api'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  components: {
    MySelect,
    PageHeader,
    EquipmentDialog,
    RepairTable,
    ReplaceTable,
    RemoveTable,
    InstallationTable,
    MaintainTable,
    OtherTable,
    qrcode,
    ContractWarranty,
    PurchaseRecords,
    ProjectRecords,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,

      permit: {
        edit: true,
        project: true,
        inventory: true,
        maintenace: true,
        repair: true,
      },
      EquipmentDialogVisible: false,
      activeName: 'BaseInfomation',
      title: '',
      companys: [],
      organics: [],
      projectTypes: [],
      contractStates: [],
      maintenanceTypes: [],
      operatingStatus: [],
      devices: [],
      deviceData: [],
      groupForm: {
        loading: false,
        id: null,
        company: '',
        organic: '',
        deviceId: '',
        deviceName: '',
        deviceType: '',
        deviceBrand: '',
        deviceModel: '',
        serialNumber: '',
        deviceIP: '',
        deviceLabel: '',
        installPosition: '',
        installNumber: '',
        installDate: null,
        receptionDate: null,
        requisitioner: '',
        inWarehouseDate: null,
        applyStaff: '',
        outWarehouseDate: null,
        shelfLife: '',
        projectId: '',
        projectName: '',
        contractNo: '',
        projectType: '',
        maintenanceDaterange: null,
        contractState: '',
        maintenanceType: '',
        maintainDate: null,
        changeDate: null,
        maintainStaff: '',
        operatingStatus: '',
        warrantyExpiryDate: null,
        productionDate: null,
        installedNote: '',
        floor: '',
        img: '',
        qrCode: '',
        repairDeviceList: [],
        replaceDeviceList: [],
        removeDeviceList: [],
        installationDeviceList: [],
        classifiedList: [],
        itemList: [],
        contractList: [],
        sn: 0,
        remark: '',
        connectedId: '',
      },
      rules: {
        company: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        organic: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // projectName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        operatingStatus: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        // productionDate: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

      tableName: 'RepairTable',
      tableData: [],
    }
  },
  computed: {
    photo () {
      if (!!this.groupForm.img) {
        return this.baseUrl + '/Engineer/getImage.do?imageId=' + this.groupForm.img;
      } else {
        return '';
      }
    }
  },
  watch: {

  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;

    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newDevice.title2') : this.$i18n.t('newDevice.title3');
      this.getItem();
    } else {
      this.title = this.$i18n.t('newDevice.title1')

      if (!!this.$route.query.company) {
        this.groupForm.company = this.$route.query.company;
        // 获取网点
        this.companyChange(false);
      }
      if (this.$route.query.type === 'brand') {
        this.groupForm.organic = this.$route.query.organic;
      }
    }

    // 获取客户
    ConfirmationTemplates().then(res => this.companys = res);

    // 获取状态
    GetDataFieldAll({ parent: '100000000000000000000000000000000025' }).then(res => {
      this.operatingStatus = res;
      if (this.operatingStatus.length !== 0 && !this.groupForm.id) this.groupForm.operatingStatus = this.operatingStatus[0].id;
    })
    // 获取设备
    this.getDeviceData()
  },
  mounted () {
    this.initPictureheight();
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    resize: debounce(function () {
      this.initPictureheight();
    }, 10),
    // 获取设备详情
    getItem () {
      let param = { id: this.groupForm.id };
      GetDeviceItem(param).then(res => {

        Object.assign(this.groupForm, {
          deviceId: res.infoId,
          operatingStatus: res.opStatusId,
          warrantyExpiryDate: res.warrantyExpiryDate,
          organic: res.branchId,
          deviceIP: res.ip,
          serialNumber: res.serialNumber,
          installPosition: res.location,
          deviceLabel: res.label,
          floor: res.floor,
          installNumber: res.deviceId,
          productionDate: toLocaleDate(res.productionDate, 'yyyy-MM-dd'),
          shelfLife: toLocaleDate(res.warrantyPeriod, 'yyyy-MM-dd'),
          installDate: toLocaleDate(res.installedDate, 'yyyy-MM-dd'),
          receptionDate: toLocaleDate(res.acceptanceDate, 'yyyy-MM-dd'),
          installedNote: res.installedNote,
          img: res.img,
          qrCode: res.qrCode,
          company: res.branchDomId,
          deviceName: res.deviceInfoName,
          deviceType: res.deviceTypeName,
          deviceBrand: res.brandName,
          deviceModel: res.deviceInfoModel,
          repairDeviceList: res.repairDeviceList,
          replaceDeviceList: res.replaceDeviceList,
          removeDeviceList: res.removeDeviceList,
          installationDeviceList: res.installationDeviceList,
          classifiedList: res.classifiedList,
          itemList: res.itemList,
          contractList: res.contractList,
          sn: res.sn,
          remark: res.remark,
          connectedId: res.connectedId,
        })
        this.tableData = this.groupForm.repairDeviceList;
        // 获取网点
        this.companyChange(false);
      })
    },
    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          infoId: this.groupForm.deviceId,
          opStatusId: this.groupForm.operatingStatus,
          warrantyExpiryDate: this.groupForm.warrantyExpiryDate,
          branchId: this.groupForm.organic,
          ip: this.groupForm.deviceIP,
          serialNumber: this.groupForm.serialNumber,
          location: this.groupForm.installPosition,
          label: this.groupForm.deviceLabel,
          floor: this.groupForm.floor,
          deviceId: this.groupForm.installNumber,
          productionDate: formatUTCDate(this.groupForm.productionDate, 'yyyy-MM-dd') || null,
          warrantyPeriod: formatUTCDate(this.groupForm.shelfLife, 'yyyy-MM-dd') || null,
          installedDate: formatUTCDate(this.groupForm.installDate, 'yyyy-MM-dd') || null,
          acceptanceDate: formatUTCDate(this.groupForm.receptionDate, 'yyyy-MM-dd') || null,
          installedNote: this.groupForm.installedNote,
          src: 'Manually',
          img: this.groupForm.img,
          qrCode: this.groupForm.qrCode,
          contractList: this.$refs.contractWarranty.tableData,
          sn: this.groupForm.sn,
          remark: this.groupForm.remark,
          connectedId: this.groupForm.connectedId,
        }
        // if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.requisitioner) || "00000000000000000000000000000" === this.groupForm.requisitioner) {
        //   param.purchaseApplicationUserId = this.groupForm.requisitioner;
        // } else {
        //   param.purchaseApplicationUserName = this.groupForm.requisitioner;
        // }
        // if (/.{8}(-.{4}){3}-.{12}/.test(this.groupForm.applyStaff) || "00000000000000000000000000000" === this.groupForm.applyStaff) {
        //   param.outWareHouseUserId = this.groupForm.applyStaff;
        // } else {
        //   param.outWareHouseUserName = this.groupForm.applyStaff;
        // }

        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }

        this.groupForm.loading = true;
        AddDevice(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 获取客户对应网点
    companyChange (isClear = true) {
      if (isClear) this.groupForm.organic = '';
      GetCompanyBranch({ groupId: this.groupForm.company }).then(res => this.organics = res);
    },
    // 设备提交
    equipmentSubmit (data) {
      Object.assign(this.groupForm, {
        deviceId: data.id,
        deviceName: data.name,
        deviceType: data.typeName,
        deviceBrand: data.brandName,
        deviceModel: data.model,
      })
      this.$refs.groupForm.validateField('deviceName');
    },
    // 设置图片高度
    initPictureheight () {
      let el = this.$refs.picture.$el;
      let w = el.clientWidth;

      el.style.height = w * 7 / 5 + 'px';
    },
    // 上传图片 
    uploadImage () {
      let formData = new FormData();
      let file = this.$refs.fileImage.files[0];
      if (file === undefined) return;

      formData.append('file', file);
      UploadImage(formData).then(res => {
        if (res.success) {
          this.groupForm.img = res.message;
        }
      })
    },
    // 切换记录表格
    switchRecord (name) {
      this.tableName = name;

      switch (this.tableName) {
        case 'RepairTable':
          this.tableData = this.groupForm.repairDeviceList;
          break
        case 'ReplaceTable':
          this.tableData = this.groupForm.replaceDeviceList;
          break
        case 'RemoveTable':
          this.tableData = this.groupForm.removeDeviceList;
          break
        case 'InstallationTable':
          this.tableData = this.groupForm.installationDeviceList;
          break
        case 'MaintainTable':
          this.tableData = [];
          break
        case 'OtherTable':
          this.tableData = this.groupForm.classifiedList;
          break
      }
    },
    // 显示设备弹框
    showEquipment () {
      if (!!this.groupForm.organic) {
        this.EquipmentDialogVisible = true;
      } else {
        this.$message.warning('Please select Site');
      }
    },
    changeOrganic () {
      Object.assign(this.groupForm, {
        deviceId: '',
        deviceName: '',
        deviceType: '',
        deviceBrand: '',
        deviceModel: '',
      })
    },
    // 获取设备
    getDeviceData () {
      let param = {
        limit: 9999,
        page: 1,
        search: '',
        branchId: '',
        all: 1,
      }
      GetDeviceList(param).then(res => {
        this.deviceData = res.list.filter(item => !!item.label);
      })
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.edit = permission.includes('DeviceManagementEdit');
        this.permit.project = permission.includes('DeviceManagementViewProjectrecords');
        this.permit.inventory = permission.includes('DeviceManagementViewInventoryrecords');
        this.permit.maintenace = permission.includes('DeviceManagementViewMaintenacereords');
        this.permit.repair = permission.includes('DeviceManagementViewRepairrecords');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.group-form {
  .el-date-editor,
  .el-cascader,
  .el-select {
    width: 100%;
  }
}
.upload-box {
  width: 100%;
  height: auto;
  &:before {
    content: "";
    width: 0;
    height: 0;
    clear: both;
    display: block;
  }
}
.input-group {
  height: 36px;
}
.el-message-box__message {
  img {
    width: 100%;
  }
}

.group-form .el-select.unit-select {
  width: 200px;
  ::v-deep .el-input__inner {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.picture {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.label-box {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  .item {
    height: 80px;
    background: #f2f2f2;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
    flex-grow: 1;
    :first-child {
      margin-left: 0;
    }
    &.active {
      background: #02a7f0;
    }

    label {
      font-size: 18px;
      font-weight: bold;
    }
    span {
      font-size: 32px;
      font-weight: bold;
    }
  }
}
.qrcode-box {
  display: flex;
  .icon-qrcode {
    margin-left: 10px;
    height: 36px;
    line-height: 36px;
    font-size: 36px;
  }
}
.group-form {
  height: calc(100% - 56px);
}
.el-tabs {
  height: calc(100% - 137px);
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
  }
}
</style>