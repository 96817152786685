<template>
  <div class="wrap">
    <el-card>
      <page-header :title="$t('newReportGroup.title1')">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </page-header>
      <el-form
        :model="groupForm"
        :rules="rules"
        ref="groupForm"
        class="group-form"
        size="medium"
      >
        <el-row :gutter="150">
          <el-col :span="12">
            <el-form-item
              :label="$t('newReportGroup.label1')"
              prop="name"
            >
              <el-input
                type="text"
                v-model="groupForm.name"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('newReportGroup.label2')"
              prop="dataAccessGroup"
            >
              <el-select
                :disabled="true"
                v-model="groupForm.dataAccessGroup"
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('newReportGroup.label3')"
              prop="descirption"
            >
              <el-input
                type="textarea"
                v-model="groupForm.descirption"
                class="describe"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row
          :gutter="150"
          class="table-row"
          ref="tableRow"
        >
          <el-col :span="12">
            <el-form-item :label="$t('newReportGroup.label4')">
              <el-input
                placeholder="Search"
                v-model="groupForm.organicSearch"
              ></el-input>
              <el-table border                ref="organicTable"
                :data="organicData"
                :height="tableHeight"
                @selection-change="val => organicSelect = val"
                @row-click="rowClick1"
                class="select-table"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column
                  width="80"
                  type="index"
                  :label="$t('newReportGroup.col1')"
                ></el-table-column>
                <el-table-column
                  :label="$t('newReportGroup.col2')"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  :label="$t('newReportGroup.col3')"
                  prop="description"
                ></el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('newReportGroup.label4')">
              <el-input
                placeholder="Search"
                v-model="groupForm.selectedSearch"
              ></el-input>
              <el-table border                ref="selectedTable"
                :data="selectedData"
                :height="tableHeight"
                @selection-change="val => selected = val"
                class="select-table"
                @row-click="rowClick2"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column
                  width="80"
                  type="index"
                  :label="$t('newReportGroup.col1')"
                ></el-table-column>
                <el-table-column
                  :label="$t('newReportGroup.col2')"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  :label="$t('newReportGroup.col3')"
                  prop="description"
                ></el-table-column>
              </el-table>
            </el-form-item>
            <div class="select-box">
              <el-button
                size="medium"
                class="select-btn"
                @click="addItem"
              >{{$t('operation.add')}}<i class="el-icon-arrow-right"></i></el-button>
              <el-button
                size="medium"
                class="select-btn"
                @click="removeItem"
              ><i class="el-icon-arrow-left"></i>{{$t('operation.del')}}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
// 接口
import {
  GetDataAccessGroupAll,
} from '@/api/organizations'
import {
  AddReportGroup,
  GetReportGroupItem,
} from '@/api/reportGroup'
export default {
  name: 'NewReportGroup',
  components: {
    PageHeader,
  },
  data () {
    return {
      dataAccessGroups: [],
      organicData: [],
      selectedData: [],
      organicSelect: [],
      selected: [],

      groupForm: {
        loading: false,
        id: null,
        groupTreeId: null,
        name: '',
        dataAccessGroup: '',
        descirption: '',
        organicSearch: '',
        selectedSearch: '',
        organicData: [],
        selectedData: [],
      },
      rules: {
        // name: [{ required: true, message: 'Please enter an Report Group Name.', trigger: 'blur' }]
      },
      tableHeight: null,

    }
  },
  // computed: {
  //   filterOrganicData: function () {
  //     return this.organicData.filter(item => !this.organicSearch || item.name.indexOf(this.organicSearch) !== -1);
  //   },
  //   filterSelectedData: function () {
  //     return this.selectedData.filter(item => !this.selectedSearch || item.name.indexOf(this.selectedSearch) !== -1);
  //   },
  // },
  watch: {
    'groupForm.organicSearch': function (newVal) {
      // 组织表格筛选
      this.organicData = this.groupForm.organicData.filter(item => item.name.toLowerCase().indexOf(newVal.toLowerCase()) !== -1)
    },
    'groupForm.selectedSearch': function (newVal) {
      // 选中表格筛选
      this.selectedData = this.groupForm.selectedData.filter(item => item.name.toLowerCase().indexOf(newVal.toLowerCase()) !== -1)
    },
  },
  created () {
    let query = this.$route.query;
    this.groupForm.id = query.id;
    this.groupForm.name = query.group;
    this.groupForm.descirption = query.des;
    this.groupForm.dataAccessGroup = query.data;

    this.getItem();
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight;
  },
  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 通过id查询报表组
    getItem () {
      let param = {
        groupTreeId: this.groupForm.id,
      }
      GetReportGroupItem(param).then(res => {
        this.groupForm.organicData = res.notExistenceList;
        this.groupForm.selectedData = res.existenceList;
        this.organicData = JSON.parse(JSON.stringify(this.groupForm.organicData));
        this.selectedData = JSON.parse(JSON.stringify(this.groupForm.selectedData));
      })
    },
    // 添加选中组织
    addItem () {
      this.groupForm.selectedData = this.groupForm.selectedData.concat(this.organicSelect);
      this.groupForm.organicData = this.groupForm.organicData.filter(item1 => {
        return !this.organicSelect.some(item2 => item2.id === item1.id);
      })
      this.syncItem();
    },
    // 移除选中组织
    removeItem () {
      this.groupForm.organicData = this.groupForm.organicData.concat(this.selected);
      this.groupForm.selectedData = this.groupForm.selectedData.filter(item1 => {
        return !this.selected.some(item2 => item2.id === item1.id)
      })
      this.syncItem();
    },
    // 同步
    syncItem () {
      this.organicData = JSON.parse(JSON.stringify(this.groupForm.organicData));
      this.selectedData = JSON.parse(JSON.stringify(this.groupForm.selectedData));
      this.organicData = this.groupForm.organicData.filter(item => item.name.indexOf(this.groupForm.organicSearch) !== -1)
      this.selectedData = this.groupForm.selectedData.filter(item => item.name.indexOf(this.groupForm.selectedSearch) !== -1)
    },

    // 提交
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          listTemplatesId: this.groupForm.selectedData.map(item => item.id).join(','),
          groupTreeId: this.groupForm.id,
        }
        let msg = this.$i18n.t('message.edit');
        this.groupForm.loading = true;
        AddReportGroup(param).then(res => {
          if (res.success) {
            this.$message.success(msg);
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 行点击
    rowClick1 (row) {
      this.$refs.organicTable.toggleRowSelection(row)
    },
    rowClick2 (row) {
      this.$refs.selectedTable.toggleRowSelection(row)
    },
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 332px);
}
.group-form {
  height: calc(100% - 56px);
  .el-select {
    width: 100%;
  }
  .describe {
    ::v-deep .el-textarea__inner {
      height: 132px;
    }
  }
  .select-table {
    margin-top: 20px;
    ::v-deep td,
    ::v-deep th {
      height: 35px;
      line-height: 35px;
      padding: 0;
    }
  }
  .select-box {
    width: 150px;
    position: absolute;
    top: 100px;
    left: calc(50% - 75px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .select-btn {
      width: 100px;
      margin: 10px 0;
    }
  }
}
</style>