<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Box Position X</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.left"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Box Position Y</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.top"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Box Width</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.width"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Box Height</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.height"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Text Font Size</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.fontSize"
          type="number"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <label class="input-label">Text Row Height</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.lineHeight"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <label class="input-label">Table Row Height</label>
        <el-input-number
          :controls="false"
          size="small"
          v-model="elData.style.trHeight"
          type="number"
        ></el-input-number>
      </el-col>
    </el-row>
    <hr>
    <el-button-group class="btn-box">
      <el-button
        size="small"
        @click="changeFont('b')"
        :type="elData.style.fontWeight === 400 ? '':'primary'"
      >B</el-button>
      <el-button
        size="small"
        @click="changeFont('i')"
        :type="elData.style.fontStyle === 'normal' ? '':'primary'"
        class="text-italic"
      >I</el-button>
      <el-button
        size="small"
        @click="changeFont('u')"
        :type="elData.style.textDecoration === 'underline' ? 'primary':''"
        class="text-underline"
      >U</el-button>
      <el-button
        size="small"
        @click="changeFont('abc')"
        :type="elData.style.textDecoration === 'line-through' ? 'primary':''"
        class="text-line-through"
      >abc</el-button>
      <el-color-picker
        size="small"
        v-model="elData.style.color"
      ></el-color-picker>
      <el-button
        size="small"
        @click="changeFont('left')"
        icon="icon-text-left"
        class="align-btn"
      ></el-button>
      <el-button
        size="small"
        @click="changeFont('center')"
        icon="icon-text-center"
        class="align-btn"
      ></el-button>
      <el-button
        size="small"
        @click="changeFont('right')"
        icon="icon-text-right"
        class="align-btn"
      ></el-button>
    </el-button-group>
  </div>

</template>

<script>
export default {
  props: ['elData'],
  data () {
    return {

    }
  },
  watch: {
    'elData.style.trHeight': function (newVal) {
      this.$emit('trHeightChange', this.elData);
    }
  },
  created () {
  },
  methods: {
    changeFont (type) {
      let item = this.elData;
      switch (type) {
        case 'b':
          item.style.fontWeight = item.style.fontWeight === 400 ? 700 : 400;
          break;
        case 'i':
          item.style.fontStyle = item.style.fontStyle === 'normal' ? 'italic' : 'normal';
          break;
        case 'u':
          item.style.textDecoration = item.style.textDecoration === 'underline' ? 'none' : 'underline';
          break;
        case 'abc':
          item.style.textDecoration = item.style.textDecoration === 'line-through' ? 'none' : 'line-through';
          break;
        case 'left':
          item.style.textAlign = 'left';
          break;
        case 'center':
          item.style.textAlign = 'center';
          break;
        case 'right':
          item.style.textAlign = 'right';
          break;
      }
      // this.$emit('update:elData', item)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>