<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newSubmitRequest.faultItem.title1')"
      :visible.sync="dialog.visible"
      center
      width="500px"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <i
            v-show="activeName === 'tab2'"
            class="icon-filter"
            @click="SystemCategoryVisible = true"
          ></i>
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="`${$t('newSubmitRequest.faultItem.tab1')}(${tags.length})`"
              name="tab1"
            >
              <el-table
                border
                :data="tags"
                :height="350"
                :show-header="false"
                @row-click="deselect"
              >
                <el-table-column width="50">
                  <i
                    slot-scope="scope"
                    class="el-icon-check"
                  ></i>
                </el-table-column>
                <el-table-column prop="description">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('newSubmitRequest.faultItem.tab2')"
              name="tab2"
            >
              <el-table
                border
                :data="faultTypeLabels"
                :height="350"
                :show-header="false"
                v-loading="tableLoading"
                @row-click="rowClick"
              >
                <el-table-column width="50">
                  <i
                    slot-scope="scope"
                    class="el-icon-check"
                    v-if="scope.row.checked"
                  ></i>
                </el-table-column>
                <el-table-column prop="description">
                </el-table-column>
              </el-table>
            </el-tab-pane>

          </el-tabs>
        </el-col>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="showCreate"
        >{{$t('newSubmitRequest.faultItem.btn1')}}</el-button>
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
    <!-- 服务类型 -->
    <system-category
      :visible.sync="SystemCategoryVisible"
      @submit="systemCategorySubmit"
    ></system-category>
  </div>
</template>

<script>
// 组件
import SystemCategory from './SystemCategory'
// 接口
import { GetFaultPhenomenonList } from '@/api/warehouse/dataMaintenance/systemType'

export default {
  components: {
    SystemCategory,
  },
  props: ['visible', 'tagsData'],
  data () {
    return {
      SystemCategoryVisible: false,
      dialog: {
        visible: false,
        title: '',
      },
      activeName: 'tab2',
      searchVal: '',
      tableLoading: false,
      tags: [],
      faultTypes: [],
      faultTypeLabels: [],
      systemCategory: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        this.tags = !!JSON.parse(JSON.stringify(this.tagsData)) ? JSON.parse(JSON.stringify(this.tagsData)) : [];
        this.searchVal = '';
        this.systemCategory = [];
        this.initData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    'searchVal': function (newVal) {
      this.searchData();
    },
    'activeName': function () {
      if (this.activeName === 'tab1') return;
      this.searchData();
    },
  },
  created () {

  },
  methods: {
    initData () {
      GetFaultPhenomenonList({
        limit: 9999,
        page: 1,
        search: ''
      }).then(res => {
        let ids = this.tags.map(item => item.id);
        this.faultTypes = res.list.map(item => {
          item.checked = ids.includes(item.id);
          return item;
        });
        this.faultTypeLabels = this.faultTypes.slice(0, 100);
      })
    },
    searchData () {
      let systemCategoryId = this.systemCategory.map(item => item.id)
      let tempData = this.faultTypes.filter(item => systemCategoryId.length === 0 || systemCategoryId.includes(item.systemTypeId));
      this.faultTypeLabels = !!this.searchVal ? tempData.filter(item => item.description.indexOf(this.searchVal) !== -1) : tempData.slice(0, 100);
    },
    rowClick (row) {
      row.checked = !row.checked;
      if (row.checked) {
        this.tags.push(row);
      } else {
        let index = this.tags.findIndex(item => item.id === row.id);
        this.tags.splice(index, 1);
      }
    },
    submit () {
      this.$emit('submit', this.tags);
      this.dialog.visible = false;
    },
    // 服务类型提交
    systemCategorySubmit (data) {
      this.systemCategory = data;
      this.searchData();
    },
    // 
    showCreate () {
      this.$prompt(this.$i18n.t('newSubmitRequest.faultItem.label1'), {
        confirmButtonText: this.$i18n.t('operation.confirm'),
        cancelButtonText: this.$i18n.t('operation.cancel'),
        inputPattern: /^.+$/,
        inputErrorMessage: this.$i18n.t('message.notNull')
      }).then(({ value }) => {
        this.faultTypes.push({ description: value, id: '', checked: true })
        this.tags.push({ description: value, id: '' });
        this.searchData();
      }).catch(() => { });
    },
    deselect (row) {
      let index = this.tags.findIndex(item => item.id === row.id);
      this.tags.splice(index, 1);

      let fault = this.faultTypeLabels.find(item => item.id === row.id)
      if (!!fault) {
        fault.checked = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-box {
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-filter {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 0px;
  z-index: 1;
}
.title {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}
.label-item {
  height: 40px;
  line-height: 40px;
}
.textarea {
  ::v-deep .el-textarea__inner {
    height: 162px;
  }
}
</style>