<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('projectDialog.title')"
      :visible.sync="dialogVisible"
      center
      width="700px"
    >
      <el-row>
        <div class="input-wrap">
          <el-button-group class="input-group">
            <el-input
              v-model="searchVal"
              placeholder="Search"
              class="search-input"
              size="medium"
              clearable
            ></el-input>
            <el-button
              size="medium"
              @click="searchData"
            >{{$t('operation.search')}}</el-button>
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          @row-dblclick="dblclick"
        >
          <el-table-column
            :label="$t('projectDialog.col1')"
            prop="quotationNumber"
          ></el-table-column>
          <el-table-column
            :label="$t('projectDialog.col2')"
            prop="name"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetProjectContractList,
  GetProjectContractItem,
} from '@/api/warehouse/projectContract'
export default {
  props: ['visible', 'projectId'],
  data () {
    return {
      dialogVisible: false,
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.getTableData();
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
      }
      this.tableLoading = true;
      GetProjectContractList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 双击选择
    dblclick (e) {
      this.$emit('submit', e)
      this.dialogVisible = false;
    },
    // 自动选择
    autoSelect () {
      let param = { id: this.projectId }
      GetProjectContractItem(param).then(res => {
        this.$emit('submit', res);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    text-align: right;
  }
  .el-switch {
    width: 100px;
  }
}
</style>