<template>
  <div class="model-wrap">
    <el-row>
      <div class="label-item">
        <label>{{$t('dataMaintenance.name')}}：{{name}}</label>
        <!-- <label>Category name</label> -->
      </div>
    </el-row>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            :disabled="items.length !== 1"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="130"
          sortable
          :label="$t('dataMaintenance.checkType.col1')"
          prop="itemId"
        >
        </el-table-column>
        <el-table-column
          min-width="130"
          sortable
          :label="$t('dataMaintenance.checkType.col2')"
          prop="item"
        ></el-table-column>
        <el-table-column
          min-width="200"
          sortable
          :label="$t('dataMaintenance.checkType.col3')"
          prop="checkType"
        ></el-table-column>
        <el-table-column
          min-width="130"
          :label="$t('dataMaintenance.checkType.col4')"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.type === 1">
              <el-input
                size="mini"
                disabled
                placeholder="Type here"
              ></el-input>
            </div>
            <div v-if="scope.row.type === 2">
              <el-tag
                size="small"
                v-for="(item,index) in scope.row.options"
                :key="index"
              >{{item.name}}</el-tag>
            </div>
            <div v-if="scope.row.type === 3">
              <el-checkbox
                v-for="(item,index) in scope.row.options"
                :key="index"
                :label="item.name"
              ></el-checkbox>
            </div>
            <div v-if="scope.row.type === 4">
              <el-radio
                v-for="(item,index) in scope.row.options"
                :key="index"
                :label="item.name"
              ></el-radio>
            </div>
            <div v-if="scope.row.type === 5">
              {{dateFormats[scope.row.options[0].name]}}
            </div>
            <div v-if="scope.row.type === 6">
              {{timeFormats[scope.row.options[0].name]}}
            </div>
            <div v-if="scope.row.type === 7">
              {{scope.row.options[1].name}} to {{scope.row.options[2].name}}
            </div>
            <div v-if="scope.row.type === 8 || scope.row.type === 9 || scope.row.type === 10">
              <el-input
                size="mini"
                disabled
              >
                <template slot="append">{{scope.row.options[0].name}}</template>
              </el-input>
            </div>
            <div v-if="scope.row.type === 11">
              {{scope.row.options[0].name}}
            </div>
            <div v-if="scope.row.type === 12">
              <img
                class="signature"
                src="/static/image/signature.png"
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!--  -->
    <check-dialog
      :visible.sync="CheckDialogVisible"
      :data="checkData"
      :check-item="items[0]"
      @update="getTableData"
    ></check-dialog>

  </div>
</template>

<script>
// 全局方法
import { formatDate } from '@/utils/common'
// 组件
import CheckDialog from './CheckDialog'
// 接口
import {
  GetCheckTypeItemList,
  DelCheckTypeItem,
} from '@/api/warehouse/dataMaintenance/checkType'
export default {
  props: ['items'],
  components: {
    CheckDialog,
  },
  data () {
    return {
      CheckDialogVisible: false,

      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      checkData: null,
      name: '',
      searchVal: '',
      tableHeight: null,
      tableSelected: [],
      tableLoading: false,
      tableData: [],
      dateFormats: [
        '2021-08-18',
        '2021/08/18',
        '08/18/2021',
        '2021年8月18日',
        '2021-08',
        '08-2021',
      ],
      timeFormats: [
        '10:28:28',
        '10:28:28AM',
      ],
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.name = '';
        this.tableData = [];
      } else {
        this.name = this.items.length === 1 ? this.items[0].name : `${this.items[0].name}…`
        this.getTableData();
      }
    }
  },
  created () {
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.ceil((this.tableHeight - 36) / 35);
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        parent: this.items.map(item => item.id).join(','),
      }
      this.tableLoading = true;
      GetCheckTypeItemList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.checkData = null;
      this.CheckDialogVisible = true;
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (e) {
      this.checkData = e;
      this.CheckDialogVisible = true;
    },
    // 删除设备
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelCheckTypeItem(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/index.scss";
.model-wrap {
  height: calc(100% - 55px);
}
.table-row {
  height: calc(100% - 46px - 46px - 42px);
}

.el-button--medium {
  padding: 10px 10px;
}
.el-dropdown {
  border-left: 1px solid #dcdfe6;
}

.el-tag {
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}
.signature {
  width: 40px;
  margin-top: 5px;
}
</style>