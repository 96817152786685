<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dataMaintenance.deviceType.title4')"
      :visible.sync="dialog.visible"
      center
      width="750px"
    >
      <el-row>
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          @selection-change="val => tableSelected = val"
          @row-click="rowClick"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            :label="$t('dataMaintenance.deviceType.col6')"
            prop="typeName"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            :label="$t('dataMaintenance.deviceType.col1')"
            prop="name"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            :label="$t('dataMaintenance.deviceType.col2')"
            prop="brandName"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            :label="$t('dataMaintenance.deviceType.col3')"
            prop="model"
          ></el-table-column>
          <el-table-column
            min-width="130"
            sortable
            :label="$t('dataMaintenance.deviceType.col4')"
            prop="supplierName"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :page-size="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
        >
        </el-pagination>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>

        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submit"
        >{{$t('operation.submit')}}</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetDeviceList,
  ItemTypeReplace,
} from '@/api/warehouse/dataMaintenance/deviceType'

export default {
  props: ['visible', 'ids', 'items'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '',

      },
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.$refs.table && this.$refs.table.clearSelection();
        this.tableSelected = [];
        this.searchData();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        deviceTypeId: this.items.map(item => item.id).join(','),
        all: 1,
      }
      this.tableLoading = true;
      GetDeviceList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    rowClick (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 提交
    submit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (this.ids.includes(this.tableSelected[0].id)) return this.$message.warning('不能替换自己.')

      this.$confirm('The replaced device will be deleted, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          src: this.ids,
          target: this.tableSelected[0].id,
        }
        this.dialog.loading = true;
        ItemTypeReplace(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.edit'))
            this.dialog.visible = false;
            this.$emit('update')
          }
          this.dialog.loading = false;
        }).catch(error => {
          this.dialog.loading = false;
        })
      }).catch(() => { });
    },
  }
}
</script>

<style lang="scss" scoped>
.el-table {
  outline: none;
  ::v-deep .current-row td {
    background-color: #91e8d9 !important;
  }
}
</style>