<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button-group>
          <el-button
            size="medium"
            @click="back"
          >{{$t('operation.cancel')}}</el-button>
          <template v-if="!isCancel">
            <el-button
              v-if="!orderId || permit.edit"
              size="medium"
              type="primary"
              @click="submit"
            >{{$t('newOrder.orderForm.btn1')}}</el-button>
            <el-button
              :disabled="!orderId"
              size="medium"
              type="primary"
              @click="printOrders"
            >{{$t('newOrder.orderForm.btn2')}}</el-button>
          </template>
          <template v-else>
            <el-button
              :disabled="!orderId"
              size="medium"
              type="primary"
              @click="printOrders"
            >{{$t('newOrder.orderForm.btn2')}}</el-button>
          </template>
        </el-button-group>
      </page-header>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('newOrder.tab1')"
          name="tab1"
        >
          <order-form
            ref="orderForm"
            :order-id="orderId"
            :type="type"
            :application-form-id="applicationFormId"
            :is-cancel="isCancel"
          ></order-form>
        </el-tab-pane>
        <el-tab-pane
          :disabled="!orderId"
          label="合同"
          name="tab2"
        >
          <contract-template
            :active="activeName"
            :order-id="orderId"
            :is-cancel="isCancel"
          ></contract-template>
        </el-tab-pane>
        <!-- <el-tab-pane
          :label="$t('newOrder.tab2')"
          name="tab3"
        >
          <order-tracking
            :active="activeName"
            :order-id="orderId"
          ></order-tracking>
        </el-tab-pane> -->
        <el-tab-pane
          label="撤销记录"
          name="tab4"
        >
          <cancel-record
            :active="activeName"
            :order-id="orderId"
          ></cancel-record>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import OrderForm from './components/OrderForm'
import OrderTracking from './components/OrderTracking'
import ContractTemplate from './components/ContractTemplate'
import CancelRecord from './components/CancelRecord'

export default {
  name: 'NewOrder',
  components: {
    PageHeader,
    OrderForm,
    OrderTracking,
    ContractTemplate,
    CancelRecord,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      permit: {
        edit: true,
      },
      title: '',
      activeName: 'tab1',
      orderId: null,
      type: null,
      applicationFormId: null,
      isCancel: null,
    }
  },
  created () {
    this.dealPermission();
    this.orderId = this.$route.query.id;
    this.type = this.$route.query.type;
    this.applicationFormId = this.$route.query.applicationFormId;
    this.isCancel = this.$route.query.cancel == 1;

    if (this.orderId) {
      this.title = this.permit.edit ? this.$i18n.t('newOrder.title2') : this.$i18n.t('newOrder.title3');
    } else {
      this.title = this.$i18n.t('newOrder.title1');
    }

    if (this.isCancel) {
      this.title = this.$i18n.t('newOrder.title3');
    }
  },
  methods: {
    back () {
      // this.$router.push({ path: '/home/purchaseOrders' })
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    submit () {
      this.$refs.orderForm.submit()
    },
    // 打印
    printOrders () {
      window.open(`${this.baseUrl}/Export/exportPurchase1.do?id=${this.orderId}&templateId=${this.$store.state.orderContract.templateId}`);
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.edit = permission.includes('PurchaseOrdersEdit');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.el-tabs {
  height: calc(100% - 56px);
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }
  .el-tab-pane {
    height: 100%;
    // height: calc(100% - 20px);
  }
}
</style>