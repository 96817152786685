<template>
  <div class="wrap">
    <el-card>
      <page-header :title="title">
        <el-button
          size="medium"
          @click="back"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          v-if="!groupForm.id || permit.edit"
          size="medium"
          type="primary"
          @click="submit"
          :loading="groupForm.loading"
        >{{$t('operation.save')}}</el-button>
      </page-header>

      <el-form
        ref="groupForm"
        :model="groupForm"
        class="group-form"
        size="medium"
        label-width="220px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('newSchedule.label1')">
              <el-input v-model="groupForm.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('newSchedule.label2')">
              <el-input
                type="textarea"
                v-model="groupForm.describe"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('newSchedule.label10')">
              <el-switch
                :disabled="!permit.scheduler"
                v-model="groupForm.isStatus"
                :active-text="groupForm.isStatus?'Enable':'Disable'"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item>
              <div slot="label">
                {{$t('newSchedule.label3')}}<br>
                {{$t('newSchedule.label4')}}
              </div>
              <el-select
                :disabled="!permit.editDataAccessGroup"
                v-model="groupForm.dataAccessGroup"
                filterable
              >
                <el-option
                  v-for="item in dataAccessGroups"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="$t('newSchedule.tab1')"
            name="tab1"
          >
            <el-form-item>
              <div
                slot="label"
                class="align-left"
              >
                <el-switch
                  v-model="groupForm.isAppoint"
                  :active-text="$t('newSchedule.label5')"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </div>
              <el-date-picker
                :disabled="!groupForm.isAppoint"
                v-model="groupForm.appointmentTime"
                type="datetime"
                placeholder="Select date and time"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <div
                slot="label"
                class="align-left"
              >
                <el-switch
                  v-model="groupForm.isRecur"
                  :active-text="$t('newSchedule.label6')"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </div>
              <div class="date-box">
                <el-select
                  :disabled="!groupForm.isRecur"
                  v-model="groupForm.recurType"
                  @change="recurTypeChange"
                  filterable
                >
                  <el-option
                    v-for="(item,index) of recurTypes"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  :disabled="!groupForm.isRecur"
                  v-model="groupForm.recurWeek"
                  v-show="groupForm.recurType === 'Weekly'"
                  filterable
                >
                  <el-option
                    v-for="(item,index) of recurWeeks"
                    :key="index"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-select
                  :disabled="!groupForm.isRecur"
                  v-model="groupForm.recurMonth"
                  v-show="groupForm.recurType === 'Yearly'"
                  @change="recurMonthsChange"
                  filterable
                >
                  <el-option
                    v-for="(item,index) of recurMonths"
                    :key="index"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-select
                  :disabled="!groupForm.isRecur"
                  v-model="groupForm.recurDay"
                  v-show="groupForm.recurType === 'Monthly'|| groupForm.recurType === 'Yearly'"
                  filterable
                >
                  <el-option
                    v-for="(item,index) of recurDays"
                    :key="index"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-time-picker
                  :disabled="!groupForm.isRecur"
                  v-model="groupForm.recurTime"
                  placeholder="time"
                  value-format="HH:mm:ss"
                > </el-time-picker>
              </div>

            </el-form-item>
            <el-form-item>
              <div
                slot="label"
                class="align-left"
              >
                <el-switch
                  v-model="groupForm.isSpecificEvent"
                  :active-text="$t('newSchedule.label7')"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </div>
              <template v-if="isNaN(groupForm.specificEvent)">
                <el-input
                  disabled
                  v-model="groupForm.specificEvent"
                ></el-input>
              </template>
              <template v-else>
                <el-select
                  :disabled="!groupForm.isSpecificEvent"
                  v-model="groupForm.specificEvent"
                >
                  <el-option
                    :value="0"
                    label="APP Service Confirmed"
                  >
                  </el-option>
                  <el-option
                    :value="1"
                    label="APP Complaint Submitted"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('newSchedule.tab2')"
            name="tab2"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('newSchedule.label8')">
                  <el-select
                    v-model="groupForm.action"
                    filterable
                  >
                    <el-option
                      v-for="(item,index) in actions"
                      :key="index"
                      :value="index"
                      :label="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('newSchedule.label9')">
                  <el-select
                    v-model="groupForm.reportTemplate"
                    filterable
                  >
                    <el-option
                      v-for="item in reportTemplates"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <div class="input-wrap">
                <el-button-group class="input-group">
                  <el-input
                    v-model="searchVal"
                    placeholder="Search"
                    class="search-input"
                    size="medium"
                    clearable
                  ></el-input>
                  <el-button
                    size="medium"
                    @click="searchData"
                  >{{$t('operation.search')}}</el-button>
                  <el-button
                    size="medium"
                    @click="showAdd"
                  >{{$t('operation.add')}}</el-button>
                  <el-button
                    size="medium"
                    @click="delItem"
                  >{{$t('operation.del')}}</el-button>
                </el-button-group>
              </div>
            </el-row>
            <el-row>
              <el-table border                :data="tables.slice((page.current - 1) * page.size, page.current * page.size)"
                @selection-change="val=> tableSelected = val"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column
                  :label="$t('newSchedule.col1')"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  :label="$t('newSchedule.col2')"
                  prop="sgName"
                ></el-table-column>
                <el-table-column
                  :label="$t('newSchedule.col3')"
                  prop="email1"
                ></el-table-column>
                <el-table-column
                  :label="$t('newSchedule.col4')"
                  prop="phone"
                ></el-table-column>
                <el-table-column
                  :label="$t('newSchedule.col5')"
                  prop="email2"
                ></el-table-column>
                <el-table-column
                  :label="$t('newSchedule.col6')"
                  prop="secondPhone"
                ></el-table-column>
              </el-table>
            </el-row>
            <el-row class="page-wrap">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="page.total"
                :current-page.sync="page.current"
                @current-change="getTableData"
              >
              </el-pagination>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>

    </el-card>

    <!-- 联系人弹框 -->
    <contact-dialog
      :visible.sync="ContactDialogVisible"
      @submit="contactSubmit"
    ></contact-dialog>
  </div>
</template>

<script>
// 组件
import PageHeader from '@/components/PageHeader'
import ContactDialog from '@/components/ContactDialog'
// 接口
import { GetDataAccessGroupAll } from '@/api/organizations'
import {
  AddSchedule,
  GetScheduleItem,
} from '@/api/schedule'
export default {
  components: {
    PageHeader,
    ContactDialog,
  },
  data () {
    return {
      title: '',
      ContactDialogVisible: false,
      permit: {
        editDataAccessGroup: true,
        scheduler: true,
        edit: true,
      },
      recurTypes: [
        { label: 'No recurrences', value: 'Norecurrences' },
        { label: 'Daily', value: 'Daily' },
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Yearly', value: 'Yearly' }
      ],
      recurWeeks: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      recurMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      recurDaysArr: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      recurDays: 0,
      dataAccessGroups: [],
      actions: ['Send Report', 'Send Notification', 'Send SMS Message'],
      reportTemplates: [],
      groupForm: {
        loading: false,
        id: null,
        name: '',
        recurType: 'No recurrences',
        recurTime: '',
        recurWeek: '',
        recurDay: '',
        recurMonth: '',
        describe: '',
        specificEvent: 0,
        isStatus: true,
        isAppoint: false,
        isRecur: true,
        isSpecificEvent: false,
        dataAccessGroup: localStorage.accessGroupId,
        action: 0,
        reportTemplate: '',
        appointmentTime: '',
      },
      activeName: 'tab1',
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableSelected: [],
      tableData: [],
    }
  },
  watch: {
    'groupForm.isAppoint': function (newVal) {
      if (newVal) {
        this.groupForm.isRecur = false;
        this.groupForm.isSpecificEvent = false;
      }
    },
    'groupForm.isRecur': function (newVal) {
      if (newVal) {
        this.groupForm.isAppoint = false;
        this.groupForm.isSpecificEvent = false;
      }
    },
    'groupForm.isSpecificEvent': function (newVal) {
      if (newVal) {
        this.groupForm.isAppoint = false;
        this.groupForm.isRecur = false;
      }
    },
    'tables': function (newVal) {
      this.page.total = newVal.length;
    },
  },
  computed: {
    // 前端过滤
    tables () {
      if (this.searchVal) {
        return this.tableData.filter(item => item.deviceName.indexOf(this.searchVal) !== -1 || item.serialNumber.indexOf(this.searchVal) !== -1 || item.qrCode.indexOf(this.searchVal) !== -1)
      }
      return this.tableData
    },
  },
  created () {
    this.dealPermission();

    this.groupForm.id = this.$route.query.id;
    if (this.groupForm.id) {
      this.title = this.permit.edit ? this.$i18n.t('newSchedule.title2') : this.$i18n.t('newSchedule.title3');
      this.getItem();
    } else {
      this.title = this.$i18n.t('newSchedule.title1');
    }
    // 获取数据访问组
    GetDataAccessGroupAll().then(res => this.dataAccessGroups = res);

  },

  methods: {
    back () {
      window.history.length <= 1
        ? this.$router.push({ path: '/' })
        : this.$router.go(-1)
    },
    // 获取详情
    getItem () {
      let param = { id: this.groupForm.id }
      GetScheduleItem(param).then(res => {
        Object.assign(this.groupForm, {
          name: res.name,
          recurType: res.type,
          recurTime: res.time,
          recurWeek: res.weekly,
          recurDay: res.daily,
          recurMonth: res.monthly,
          describe: res.descirption,
          specificEvent: isNaN(res.specificEvent) ? res.specificEvent : Number(res.specificEvent),
          isStatus: Boolean(res.status),
          dataAccessGroup: res.accessGroupId,
          action: Number(res.action),
          reportTemplate: res.template,
          appointmentTime: res.appointmentTime,
        })
        this.tableData = res.list || [];
        if (res.recurrences == 1) this.groupForm.isAppoint = true;
        else if (res.recurrences == 2) this.groupForm.isRecur = true;
        else if (res.recurrences == 3) this.groupForm.isSpecificEvent = true;
      })
    },
    getTableData () {

    },
    searchData () {

    },
    // 改变复发类型
    recurTypeChange () {
      switch (this.groupForm.recurType) {
        case 'Monthly':
          this.groupForm.recurDay = '1';
          this.recurDays = this.recurDaysArr[new Date().getMonth()];
          break;
        case 'Yearly':
          this.groupForm.recurMonth = 'Jan';
          this.groupForm.recurDay = '1';
          this.recurDays = this.recurDaysArr[0];
          break;
      }
    },
    // 改变复发月份
    recurMonthsChange () {
      let index = this.recurMonths.indexOf(this.groupForm.recurMonth);
      let year = new Date().getFullYear();
      if ((year % 4 === 0 && year & 100 !== 0 || year % 400 == 0) && index === 1) {
        this.recurDays = 29;
      } else {
        this.recurDays = this.recurDaysArr[index];
      }

    },
    // 显示新增
    showAdd () {
      this.ContactDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let checkids = this.tableSelected.map(item => item.id);
        this.tableData = this.tableData.filter(item => !checkids.includes(item.id))
      }).catch(() => { });
    },
    submit () {
      this.$refs.groupForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.groupForm.name,
          status: Number(this.groupForm.isStatus),
          descirption: this.groupForm.describe,
          accessGroupId: this.groupForm.dataAccessGroup,
          appointmentTime: this.groupForm.appointmentTime,
          recurrences: '',
          type: this.groupForm.recurType,
          time: this.groupForm.recurTime,
          weekly: this.groupForm.recurWeek,
          monthly: this.groupForm.recurMonth,
          daily: this.groupForm.recurDay,
          specificEvent: this.groupForm.specificEvent,
          action: this.groupForm.action,
          template: this.groupForm.reportTemplate,
          contactIds: this.tableData.map(item => item.id).join(','),
        }
        if (this.groupForm.isAppoint) param.recurrences = 1;
        else if (this.groupForm.isRecur) param.recurrences = 2;
        else if (this.groupForm.isSpecificEvent) param.recurrences = 3;

        let msg = this.$i18n.t('message.add');
        if (this.groupForm.id) {
          param.id = this.groupForm.id;
          msg = this.$i18n.t('message.edit');
        }
        this.groupForm.loading = true;
        AddSchedule(param).then(res => {
          if (res.success) {
            this.$message.success(msg)
            this.back();
          }
          this.groupForm.loading = false;
        }).catch(error => {
          this.groupForm.loading = false;
        })
      })
    },
    // 联系人提交
    contactSubmit (data) {
      let ids = this.tableData.map(item => item.id).join(',');
      data = data.filter(item => {
        if (ids.includes(item.id)) {
          this.$message.warning(`${item.name}已添加`)
          return false;
        } else {
          return true;
        }
      })
      this.tableData = this.tableData.concat(data);
    },
    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.editDataAccessGroup = permission.includes('ScheduleEditDataAccessGroups(DataPermissions)');
        this.permit.scheduler = permission.includes('ScheduleEnableorDisableScheduler');
        this.permit.edit = permission.includes('ScheduleEditSchedule');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.group-form {
  .input-group {
    width: auto;
  }
}

.date-box {
  width: 100%;
  display: flex;
  > div {
    flex: 1;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.input-group {
  display: flex;
  .search-input {
    width: 200px;
    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
}

.select-full {
  width: 100%;
}

.align-left {
  text-align: left;
}
</style>