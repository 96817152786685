<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      center
      width="500px"
    >
      <el-row>
        <el-input
          v-model="searchVal"
          placeholder="Search"
          size="small"
        ></el-input>
      </el-row>
      <el-tree
        ref="tree"
        :data="treeData"
        show-checkbox
        node-key="id"
        :props="defaultProps"
        :filter-node-method="filterNode"
      >
      </el-tree>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialogVisible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import {
  GetDepartmentTree
} from '@/api/warehouse/approvalWorkflow'
export default {
  props: ['visible', 'isCreate', 'checkedKeys'],
  data () {
    return {
      dialogVisible: false,
      title: this.$i18n.t('approvalWorkflow.person.title1'),
      searchVal: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialogVisible = this.visible;
        this.$nextTick(() => {
          // this.$refs.tree.setCheckedKeys(this.checkedKeys);
          this.$refs.tree.setCheckedKeys(this.checkedKeys);
        })
      }
    },
    'dialogVisible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
    searchVal (val) {
      this.$refs.tree.filter(val);
    }

  },
  created () {
    this.getTreeData();

  },
  methods: {
    getTreeData () {
      this.tableLoading = true;
      GetDepartmentTree().then(res => {
        this.treeData = res.map(item => {
          item.isDepartment = true;
          item.children.forEach(staff => {
            staff.department = item.label
          });
          return item;
        });
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    // 提交
    submit () {
      let data = this.$refs.tree.getCheckedNodes().filter(item => !item.isDepartment)
      this.$emit('submit', data);
      this.dialogVisible = false;
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    }

  }
}
</script>

<style lang="scss" scoped>
</style>