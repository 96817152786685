<template>
  <counter-mode :report-mode="3"></counter-mode>
</template>

<script>
import counterMode from '@/pages/counterMode/counterMode'
export default {
  name: 'UserDefined',
  components: {
    counterMode
  },
  data () {
    return {

    }
  }
}
</script>

<style>
</style>