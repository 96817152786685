<template>
  <div class="wrap">
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row
      class="table-row"
      ref="tableRow"
    >
      <el-table
        ref="table"
        border
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          min-width="150"
          prop="receiptDate"
          :label="$t('allocateGoods.signInRecord.col1')"
          sortable
          :formatter="$formatTableData"
        >
        </el-table-column>
        <el-table-column
          min-width="150"
          prop="receiptName"
          :label="$t('allocateGoods.signInRecord.col2')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="projectName"
          :label="$t('allocateGoods.signInRecord.col3')"
          sortable
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="deviceName"
          :label="$t('allocateGoods.signInRecord.col4')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="brandName"
          :label="$t('allocateGoods.signInRecord.col5')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="deviceModel"
          :label="$t('allocateGoods.signInRecord.col6')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          prop="appCount" 
          :label="$t('allocateGoods.signInRecord.col7')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="180"
          prop="count"
          :label="$t('allocateGoods.signInRecord.col8')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="200"
          :label="$t('allocateGoods.signInRecord.col9')"
          sortable
        >
          <div
            class="file-box"
            slot-scope="scope"
          >
            <div
              v-for="(item,index) in scope.row.attachment"
              :key="index"
              class="file-item"
            >
              <div
                class="block"
                @click="previewFile(item)"
              >
                <img :src="'./static/image/fileType/' + item.formats + '.png'">
              </div>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="page-wrap">
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row>

    <!-- 编辑弹框 -->
    <sign-in-dialog
      :visible.sync="SignInDialogVisible"
      :data="signInData"
      :type="1"
      @update="getTableData"
    ></sign-in-dialog>
  </div>
</template>

<script>
// 组件
import SignInDialog from './SignInDialog'
// 接口
import {
  GetReceiptRecordList,
  DelReceiptRecord,
} from '@/api/warehouse/purchaseRequisition'
export default {
  components: {
    SignInDialog
  },
  props: ['active'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      SignInDialogVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      searchVal: '',
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      signInData: null,
    }
  },
  watch: {
    'active': function (newVal) {
      if (newVal === 'tab5') {
        this.tableHeight = this.$refs.tableRow.$el.clientHeight
        this.page.size = Math.floor(this.tableHeight / 35);
        this.getTableData();
        // this.$refs.table.doLayout();
      }
    }
  },
  created () {
  },
  methods: {
    getTableData () {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        applicationFormId: this.$route.query.id
      }
      this.tableLoading = true;
      GetReceiptRecordList(param).then(res => {
        this.tableData = res.list.map(item => {
          if (!item.attachment) {
            item.attachment = [];
          } else {
            item.attachment = item.attachment.split(',').map(item => {
              let formats = item.match(/[^.]*$/)[0];
              return { name: item, formats: formats }
            })
          }
          return item;
        });
        this.page.total = res.total;
        this.tableLoading = false;
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },

    // 显示修改弹框
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      this.dblclickEdit(this.tableSelected[0]);
    },
    dblclickEdit (e) {
      this.signInData = e;
      this.SignInDialogVisible = true;
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelReceiptRecord(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 预览文件
    previewFile (file) {
      window.open(`${this.baseUrl}/Export/download.do?fileName=${file.name}`)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0;
}
.table-row {
  height: calc(100% - 46px - 42px);
}

.file-box {
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  font-size: 16px;
  margin-right: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  .block {
    width: 50px;
    height: 50px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  label {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
</style>