<template>
  <div>
    <el-row>
      <div class="input-wrap">
        <el-button-group class="input-group">
          <el-input
            v-model="searchVal"
            placeholder="Search"
            class="search-input"
            size="medium"
            clearable
          ></el-input>
          <el-button
            size="medium"
            @click="searchData"
          >{{$t('operation.search')}}</el-button>
          <el-button
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            size="medium"
            @click="showEdit"
          >{{$t('operation.edit')}}</el-button>
          <el-button
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>

        </el-button-group>
      </div>
    </el-row>
    <el-row>
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        v-loading="tableLoading"
        @selection-change="val => tableSelected = val"
        @row-dblclick="dblclickEdit"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.systemType.col5')"
          prop="description"
        ></el-table-column>
        <el-table-column
          sortable
          :label="$t('dataMaintenance.systemType.col6')"
          prop="heat"
        ></el-table-column>
      </el-table>
    </el-row>
    <!-- <el-row
      class="page-wrap"
      v-if="item.id !== undefined"
    >
      <my-page
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        @current-change="getTableData"
        @size-change="getTableData"
      >
      </my-page>
    </el-row> -->
    <!-- 添加弹框 -->
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('dataMaintenance.systemType.systemType')"
          prop="systemTypeId"
        >
          <el-select
            v-model="dialog.systemTypeId"
            filterable
          >
            <el-option
              v-for="item in systemTypes"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataMaintenance.systemType.col5')"
          prop="description"
        >
          <el-input v-model="dialog.description"></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('dataMaintenance.systemType.col6')"
          prop="heat"
        >
          <el-input
            type="number"
            v-model="dialog.heat"
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="dialog.loading"
        >{{$t('operation.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

// 接口
import {
  GetFaultPhenomenonList,
  AddFaultPhenomenon,
  DelFaultPhenomenon,
} from '@/api/warehouse/dataMaintenance/systemType'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  props: ['items', 'tableHeight'],
  data () {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      contactData: null,
      searchVal: '',
      tableSelected: [],
      tableLoading: false,
      tableData: [],
      serviceTypes: [],
      systemTypes: [],
      dialog: {
        visible: false,
        loading: false,
        id: null,
        title: '',
        description: '',
        heat: 0,
        systemTypeId: '',
      },
      rules: {
        serviceTypeId: [{ required: true, message: 'Please enter Service Type.', trigger: 'blur' }]
      },
    }
  },
  watch: {
    'items': function () {
      if (this.items.length === 0) {
        this.tableData = [];
      } else {
        this.getTableData();
      }
    }
  },
  created () {
    // 获取服务类型 
    GetDataFieldAll({ parent: '100000000000000000000000000000000006' }).then(res => this.serviceTypes = res)

    // 获取系统类型
    GetDataFieldAll({ parent: '100000000000000000000000000000000004' }).then(res => this.systemTypes = res)
  },
  methods: {
    getTableData () {
      let param = {
        limit: 9999,
        page: this.page.current,
        search: this.searchVal,
        systemTypeId: this.items.map(item => item.id).join(','),
      }
      this.tableLoading = true;
      GetFaultPhenomenonList(param).then(res => {
        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示添加
    showAdd () {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      Object.assign(this.dialog, {
        id: null,
        title: this.$i18n.t('dataMaintenance.systemType.title3'),
        visible: true,
      })
    },
    // 显示修改
    showEdit () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      let item = this.tableSelected[0];
      this.dblclickEdit(item)
    },
    dblclickEdit (e) {
      this.dialog.visible = true;
      this.$nextTick(() => {
        Object.assign(this.dialog, {
          title: this.$i18n.t('dataMaintenance.systemType.title4'),
          id: e.id,
          description: e.description,
          heat: e.heat,
          systemTypeId: e.systemTypeId,
        })
      })

    },
    // 删除设备
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')

      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelFaultPhenomenon(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 提交
    submit () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          systemTypeId: this.dialog.systemTypeId,
          description: this.dialog.description,
          heat: this.dialog.heat,
        }
        this.msg = this.$i18n.t('message.add');
        if (this.dialog.id) {
          param.id = this.dialog.id;
          this.msg = this.$i18n.t('message.edit');
        }
        this.dialog.loading = true;
        AddFaultPhenomenon(param).then(res => {
          this.$message.success(this.msg)
          this.getTableData();
          this.dialog.visible = false;
          this.dialog.loading = false;
        })
      });
    }
  }
}
</script>

<style>
</style>