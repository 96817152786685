<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="Add Key Performance Indicator"
      :visible.sync="dialog.visible"
      center
      width="450px"
    >
      <el-form
        :model="dialog"
        size="small"
      >
        <el-form-item label="KPI Report Templates">
          <el-select
            v-model="dialog.template"
            filterable
          >
            <el-option
              v-for="item in templates"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Main Fields">
          <el-select
            v-model="dialog.mainField"
            filterable
          >
            <el-option
              v-for="item in mainFields"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Sub Fields">
          <el-select
            v-model="dialog.subField"
            filterable
          >
            <el-option
              v-for="item in subFields"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="level-item">
              <span class="label">KPI Level</span>
              <el-select
                v-model="dialog.level"
                class="level-select"
                size="small"
              >
                <el-option
                  v-for="item in 5"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div
              class="level-item"
              v-for="item in dialog.level"
              :key="item"
            >
              <span class="label">KPI Level</span>
              <span class="level-label">{{item}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="level-item">
              Score Range(Total 10)
            </div>
            <div
              class="level-item range-wrap"
              v-for="item in dialog.level"
              :key="item"
            >
              <el-select
                v-model="dialog.levelVal[(item - 1) * 2]"
                size="small"
              >
                <el-option
                  v-for="item in 10"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
              <span class="link"></span>
              <el-select
                v-model="dialog.levelVal[(item - 1) * 2 + 1]"
                size="small"
              >
                <el-option
                  v-for="item in 10"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >Cancel</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="dialog.visible = false"
        >OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddKpi',
  props: ['visible'],
  data () {
    return {
      templates: [],
      mainFields: [],
      subFields: [],
      levelVals: [
        [1, 10],
        [1, 5, 6, 10],
        [1, 3, 4, 7, 8, 10],
        [1, 2, 3, 4, 5, 7, 8, 10],
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      ],
      dialog: {
        visible: false,
        template: '',
        mainField: '',
        subField: '',
        level: 1,
        levelVal: [1, 10],
      },
    }
  },
  watch: {
    'visible': function () {
      this.dialog.visible = this.visible
    },
    'dialog.visible': function () {
      if (!this.dialog.visible) {
        this.$emit('update:visible', false)
      }
    },
    'dialog.level': function (newVal) {
      this.dialog.levelVal = this.levelVals[newVal - 1]
    }
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.level-item {
  height: 32px;
  line-height: 32px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  .label {
    margin-right: 10px;
  }
  .level-select {
    width: 100px;
  }
  .level-label {
    width: 85px;
    padding-left: 15px;
  }
}
.range-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-select {
    width: 100px;
  }
  .link {
    width: 5px;
    height: 1px;
    background: #dcdfe6;
    margin: 0 5px;
  }
}
</style>