<template>
  <div class="wrap">
    <el-card class="table-card">
      <el-row>
        <div class="input-wrap">
          <div class="left-box">
            <el-date-picker
              size="medium"
              v-model="dateRange"
              type="daterange"
              :picker-options="pickerOptions"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="searchData"
            >
            </el-date-picker>
            <el-button-group class="input-group">
              <el-input
                :disabled="!permit.search"
                v-model="searchVal"
                placeholder="Search"
                class="search-input"
                size="medium"
                clearable
              ></el-input>
              <el-button
                :disabled="!permit.search"
                size="medium"
                @click="searchData"
              >{{$t('operation.search')}}</el-button>
            </el-button-group>
          </div>
          <div>
            <span>{{$t('servicesScheduling.status')}}：</span>
            <el-select
              class="state-select"
              v-model="state"
              size="medium"
              @change="searchData"
              multiple
            >
              <el-option
                value="all"
                label="All"
              ></el-option>
              <el-option
                v-for="item in states"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>

          </div>
        </div>
      </el-row>
      <el-row>
        <el-button-group class="input-group">
          <el-button
            :disabled="!permit.add"
            size="medium"
            @click="showAdd"
          >{{$t('operation.add')}}</el-button>
          <el-button
            :disabled="!permit.del"
            size="medium"
            @click="delItem"
          >{{$t('operation.del')}}</el-button>
          <el-button
            size="medium"
            @click="sendOrders"
          >{{$t('operation.send')}}</el-button>
          <el-button
            size="medium"
            @click="stateUpdate"
          >{{$t('operation.updateState')}}</el-button>
          <el-button
            size="medium"
            @click="showCancel"
          >{{$t('operation.repeal')}}</el-button>
          <el-button
            size="medium"
            @click="showStaff"
          >{{$t('operation.staffStatus')}}</el-button>
          <el-button
            size="medium"
            @click="closeForm"
          >{{$t('operation.close')}}</el-button>
        </el-button-group>
      </el-row>
      <el-row
        class="table-row"
        ref="tableRow"
      >
        <el-table
          border
          :data="tableData"
          :height="tableHeight"
          ref="table"
          v-loading="tableLoading"
          @selection-change="val => tableSelected = val"
          @row-dblclick="showEdit"
          @row-click="e => $refs.table.toggleRowSelection(e)"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            min-width="150"
            prop="createTime"
            :label="$t('servicesScheduling.col1')"
            sortable
            :formatter="$formatTableData"
          >
          </el-table-column>
          <el-table-column
            min-width="170"
            prop="computeTime"
            :label="$t('servicesScheduling.col2')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="cityName"
            :label="$t('servicesScheduling.col3')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="customerName"
            :label="$t('servicesScheduling.col9')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="branchName"
            :label="$t('servicesScheduling.col4')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="sourceName"
            :label="$t('servicesScheduling.col5')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="serviceTypeName"
            :label="$t('servicesScheduling.col7')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="120"
            prop="priorityName"
            :label="$t('servicesScheduling.col10')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="120"
            prop="statusName"
            :label="$t('servicesScheduling.col8')"
            sortable
          >
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="page-wrap">
        <my-page
          layout="prev, pager, next, jumper"
          :total="page.total"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          @current-change="getTableData"
          @size-change="getTableData"
        >
        </my-page>
      </el-row>
    </el-card>

    <!-- 投诉 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('servicesScheduling.cancel.title')"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('servicesScheduling.cancel.cause')"
          prop="cause"
        >
          <el-input
            type="textarea"
            v-model="dialog.cause"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submitCancel"
        >{{$t('operation.save')}}</el-button>
      </div>
    </el-dialog>
    <!-- 人员状态弹框 -->
    <staff-dialog :visible.sync="StaffVisible"></staff-dialog>
  </div>
</template>

<script>
// 全局方法
import { computeTime, formatUTCDate } from '@/utils/common'
// 组件
import StaffDialog from './components/StaffDialog'
// 接口
import {
  GetSubmitRequestList,
  RevokeSubmitRequest,
  DelSubmitRequest,
  CloseSubmitRequest,
} from '@/api/warehouse/submitRequest'
import { GetDataFieldAll } from '@/api/warehouse/dataMaintenance'

export default {
  name: 'WorkOrderScheduling',
  components: {
    StaffDialog
  },
  data () {
    return {
      permit: {
        search: true,
        add: true,
        del: true,
      },

      StaffVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pickerOptions: this.getPickerOptions(),
      states: [],
      dateRange: null,
      searchVal: '',
      state: [],
      tableLoading: false,
      tableSelected: [],
      tableData: [],
      tableHeight: null,

      dialog: {
        visible: false,
        cause: '',
      },
      rules: {
        cause: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    '$route': function (to) {
      if (to.name === 'WorkOrderScheduling') {
        let ids = this.tableSelected.map(item => item.id)
        this.getTableData(() => {
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (ids.includes(item.id))
                this.$refs.table.toggleRowSelection(item, true)
            })
          })
        });
      }

    },
  },
  created () {
    // 获取完成状态
    GetDataFieldAll({ parent: '100000000000000000000000000000000007' }).then(res => this.states = res)

    this.dealPermission();
  },
  mounted () {
    this.tableHeight = this.$refs.tableRow.$el.clientHeight
    this.page.size = Math.floor(this.tableHeight / 35);
    this.getTableData();
  },
  methods: {
    getTableData (fun) {
      let param = {
        limit: this.page.size,
        page: this.page.current,
        search: this.searchVal,
        statusId: this.state.find(item => item === 'all') ? this.states.map(item => item.id).join(',') : this.state.join(','),
        date: this.dateRange !== null ? `${formatUTCDate(this.dateRange[0])}~${formatUTCDate(this.dateRange[1] + ' 23:59:59')}` : '',
      }
      this.tableLoading = true;
      GetSubmitRequestList(param).then(res => {
        let noStatus = ['00000000000000000000000000000000002', '00000000000000000000000000000000004', '00000000000000000000000000000000007'];

        this.tableData = res.list;
        this.page.total = res.total;
        this.tableLoading = false;
        this.tableData.forEach((item, index) => {
          if (noStatus.includes(item.statusId))
            this.$set(this.tableData[index], 'computeTime', computeTime(item.createTime, item.updateTime));
          else
            this.$set(this.tableData[index], 'computeTime', computeTime(item.createTime));
        });
        if (typeof fun === 'function') fun();
      }).catch(error => {
        this.tableLoading = false;
      })
    },
    searchData () {
      this.page.current = 1;
      this.getTableData();
    },
    // 显示新增
    showAdd () {
      this.$router.push({ path: '/home/newSubmitRequest', query: { type: 2 } })
    },
    // 显示修改
    showEdit (item) {
      this.$router.push({ path: '/home/newSubmitRequest', query: { id: item.id, type: 2 } })
    },
    // 删除
    delItem () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.$confirm('Delete operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.tableSelected.map(item => item.id).join(',')
        }
        DelSubmitRequest(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success(this.$i18n.t('message.del'))
          }
        })
      }).catch(() => { });
    },
    // 派单
    sendOrders () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      let status = this.tableSelected[0].statusId;
      let noStatus = ['00000000000000000000000000000000002', '00000000000000000000000000000000004', '00000000000000000000000000000000007'];
      if (noStatus.includes(status)) return this.$message.warning('已撤销，已关闭，已完成不能派单')

      this.$router.push({ path: '/home/sendOrder', query: { id: this.tableSelected[0].id } })
    },
    // 状态更新
    stateUpdate () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')

      this.$router.push({ path: '/home/orderStateUpdate', query: { id: this.tableSelected[0].id } })
    },
    // 撤销
    showCancel () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      this.dialog.visible = true;
    },
    // 显示人员列表弹框
    showStaff () {
      this.StaffVisible = true;
    },
    // 提交投诉
    submitCancel () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          workOrderId: this.tableSelected.map(item => item.id).join(','),
          revokeReason: this.dialog.cause,
        }
        RevokeSubmitRequest(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.dialog.visible = false;
            this.$message.success(this.$i18n.t('message.cancel'))
          }
        })
      })
    },
    // 关闭工单
    closeForm () {
      if (this.tableSelected.length === 0) return this.$message.warning('Please select at least one item.')
      if (this.tableSelected.length > 1) return this.$message.warning('You can only choose one.')
      if (this.tableSelected[0].statusId !== '00000000000000000000000000000000002') return this.$message.warning(`只能关闭已完成的工单`);

      this.$confirm('Close operation, please confirm!', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {

        let param = { workOrderId: this.tableSelected[0].id }
        CloseSubmitRequest(param).then(res => {
          if (res.success) {
            this.getTableData();
            this.$message.success('关闭成功')
          }
        })
      }).catch(() => { });
    },
    getPickerOptions () {
      return {
        shortcuts: [{
          text: this.$i18n.t('operation.date.text1'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text2'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('operation.date.text3'),
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },

    // 权限限制
    dealPermission () {
      if (!localStorage.isSuper) {
        let permission = localStorage.permission.split(',');
        this.permit.search = permission.includes('WorkOrderSchedulingSearch');
        this.permit.add = permission.includes('WorkOrderSchedulingAdd');
        // this.permit.edit = permission.includes('WorkOrderSchedulingEdit');
        this.permit.del = permission.includes('WorkOrderSchedulingDelete');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.left-box {
  display: flex;
  .el-date-editor {
    margin-right: 10px;
  }
}
.state-select {
  width: 150px;
}
.wrap {
  height: 100%;
}
.el-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
  }
}
.table-row {
  height: calc(100% - 46px - 46px - 42px);
}
</style>
