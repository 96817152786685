import request from '@/utils/request'

// 查询物流公司列表
export const GetLogisticsCompanyList = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompany/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改物流公司
export const AddLogisticsCompany = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompany/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除物流公司
export const DelLogisticsCompany = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompany/del.do',
  data: JSON.stringify(data)
})

// 查询物流公司联系人列表
export const GetLogisticsContactList = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompanyContact/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改物流公司联系人
export const AddLogisticsContact = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompanyContact/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除物流公司联系人
export const DelLogisticsContact = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompanyContact/del.do',
  data: JSON.stringify(data)
})

// 获取物流公司集合
export const GetLogisticsCompanyAll = (data) => request({
  method: 'post',
  url: '/WarehouseLogisticsCompany/queryByCondition.do',
  data: JSON.stringify(data)
})