<template>
  <div>
    <!-- 更换设备 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      center
      width="650px"
    >
      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
      >
        <el-form-item
          label="备注"
          prop="reason"
        >
          <el-input
            type="textarea"
            v-model="dialog.reason"
            :rows="10"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="danger"
          @click="submit(2)"
        >{{dialog.btn1.button}}</el-button>
        <el-button
          size="medium"
          type="success"
          @click="submit(1)"
        >{{dialog.btn2.button}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 接口
import { ExamineApply } from '@/api/warehouse/purchaseRequisition'
import { GetApprovalBtn } from '@/api/warehouse/approvalWorkflow'

export default {
  props: ['visible', 'id'],
  data () {
    return {
      dialog: {
        visible: false,
        loading: false,
        title: '审核',
        btn1: {},
        btn2: {},
        reason: '',
      },
      rules: {
        // reason: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
      },

    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
        this.dialog.visible = this.visible;
        this.$global.ExamineData.data.forEach(item => {
          if (item.status === 0) this.dialog.btn2 = item;
          if (item.status === 1) this.dialog.btn1 = item;
        })
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {
  },
  methods: {
    // 提交
    submit (type) {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;

        let param = {
          applicationFormId: this.id,
          status: type,
          reason: this.dialog.reason,
          optionId: type === 1 ? this.dialog.btn2.id : this.dialog.btn1.id,
        }
        ExamineApply(param).then(res => {
          if (res.success) {
            this.$message.success(this.$i18n.t('message.edit'));
            this.dialog.visible = false;
            this.$emit('update')
          } else {
            this.$message.warning(res.data);
          }
        })
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-select {
    width: 100%;
  }
  .el-button {
    width: 100%;
  }
}
</style>