import request from '@/utils/request'

// 查询列表
export const GetTimeZoneList = (data) => request({
  method: 'post',
  url: '/SysTimeZone/queryByPage.do',
  data: JSON.stringify(data)
})

// 新增和修改
export const AddTimeZone = (data) => request({
  method: 'post',
  url: '/SysTimeZone/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 复制
export const CopyTimeZone = (data) => request({
  method: 'post',
  url: '/SysTimeZone/copyTo.do',
  data: JSON.stringify(data)
})

// 查询假日、工作日
export const GetHolidayList = (data) => request({
  method: 'post',
  url: '/HolidaySet/queryByCondition.do',
  data: JSON.stringify(data)
})


// 新增和修改假日、工作日
export const AddHoliday = (data) => request({
  method: 'post',
  url: '/HolidaySet/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除假日
export const DelHoliday = (data) => request({
  method: 'post',
  url: '/HolidaySet/del.do',
  data: JSON.stringify(data)
})

// 查询假日日期
export const GetHolidayDateList = (data) => request({
  method: 'post',
  url: '/Holiday/queryByCondition.do',
  data: JSON.stringify(data)
})

// 删除假日日期
export const DelHolidayDate = (data) => request({
  method: 'post',
  url: '/Holiday/del.do',
  data: JSON.stringify(data)
})

// 根据组织机构查询时区
export const GetTimeZoneByOrg = (data) => request({
  method: 'post',
  url: '/SysTimeZone/queryByZationId.do',
  data: JSON.stringify(data)
})
