<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialog.visible"
      center
      width="400px"
    >

      <el-form
        :model="dialog"
        :rules="rules"
        ref="dialogForm"
        size="small"
        class="dialog-form"
      >
        <el-form-item
          :label="$t('equipmentDialog3.label1')"
          prop="deviceName"
        >
          <my-select
            :data="deviceNames"
            :value.sync="dialog.deviceName"
            size="small"
            :prop="{
                label:'name',
                value:'name'
              }"
            @change="deviceNameChange"
          ></my-select>
        </el-form-item>
        <el-form-item
          :label="$t('equipmentDialog3.label2')"
          prop="deviceType"
        >
          <my-select
            :disabled="!dialog.deviceName"
            :data="deviceTypes"
            :value.sync="dialog.deviceType"
            size="small"
            :prop="{
                label:'typeName',
                value:'typeName'
              }"
            bold-prop="isLink"
          ></my-select>
        </el-form-item>
        <el-form-item
          :label="$t('equipmentDialog3.label3')"
          prop="deviceBrand"
        >
          <my-select
            :disabled="!dialog.deviceType"
            :data="deviceBrands"
            :value.sync="dialog.deviceBrand"
            size="small"
            :prop="{
                label:'brandName',
                value:'brandName'
              }"
            bold-prop="isLink"
          ></my-select>
        </el-form-item>
        <el-form-item
          :label="$t('equipmentDialog3.label4')"
          prop="deviceModel"
        >
          <my-select
            :disabled="!dialog.deviceBrand"
            :data="deviceModels"
            :value.sync="dialog.deviceModel"
            size="small"
            :prop="{
                label:'model',
                value:'model'
              }"
            bold-prop="isLink"
          ></my-select>
        </el-form-item>
      </el-form>
      <div class="footer-box">
        <el-button
          size="medium"
          @click="dialog.visible = false"
        >{{$t('operation.cancel')}}</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="dialog.loading"
          @click="submitCreateDevice"
        >{{$t('operation.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import MySelect from '@/components/MySelect'

// 接口
import {
  GetDeviceNameAll,
  GetDeviceModelAll,
  GetDeviceBrandAll,
  GetDeviceTypeAll,
  AddDevice,
} from '@/api/warehouse/dataMaintenance/deviceType'
export default {
  components: {
    MySelect
  },
  props: ['visible',],
  data () {
    return {
      title: '选择设备',
      searchVal: '',
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      tableLoading: false,
      tableData: [],

      deviceNames: [],
      deviceTypes: [],
      deviceBrands: [],
      deviceModels: [],
      dialog: {
        visible: false,
        loading: false,
        deviceName: '',
        deviceType: '',
        deviceBrand: '',
        deviceModel: '',
      },
      rules: {
        deviceName: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceType: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceBrand: [{ required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }],
        deviceModel: [
          { max: 64, message: 'A maximum of 64 characters', trigger: 'blur' },
          { required: true, message: this.$i18n.t('message.notNull'), trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'visible': function (newVal) {
      if (newVal) {
        this.dialog.visible = this.visible;
        Object.assign(this.dialog, {
          deviceName: '',
          deviceType: '',
          deviceBrand: '',
          deviceModel: '',
        })

        if (this.deviceNames.length === 0) this.initSelect();
      }
    },
    'dialog.visible': function (newVal) {
      if (!newVal) {
        this.$emit('update:visible', false)
      }
    },
  },
  created () {

  },
  methods: {
    submitCreateDevice () {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return;
        let param = {
          name: this.dialog.deviceName,
          typeName: this.dialog.deviceType,
          brandName: this.dialog.deviceBrand,
          model: this.dialog.deviceModel,
        }
        this.dialog.loading = true;
        AddDevice(param).then(res => {
          if (res.success) {
            let data = JSON.parse(res.data)
            this.$emit('submit', data);
            this.dialog.visible = false;
          }
          this.dialog.loading = false;
        })
      });
    },
    // 初始化下拉框 
    initSelect () {
      this.queryDeviceName('');
      this.queryModel('');
      this.queryBrand('');
      this.queryType('');
    },
    // 查询设备名称
    queryDeviceName (query) {
      let param = { name: query }
      GetDeviceNameAll(param).then(res => this.deviceNames = res);
    },
    // 查询设备型号
    queryModel (query) {
      let param = {
        name: this.dialog.deviceName || undefined,
        // model: query
      }
      GetDeviceModelAll(param).then(res => this.deviceModels = res);
    },
    // 查询品牌
    queryBrand (query) {
      let param = {
        name: this.dialog.deviceName || undefined,
        // brand: query
      }
      GetDeviceBrandAll(param).then(res => this.deviceBrands = res);
    },
    // 查询设备类型
    queryType (query) {
      let param = {
        name: this.dialog.deviceName || undefined,
        // type: query
      }
      GetDeviceTypeAll(param).then(res => this.deviceTypes = res);
    },
    deviceNameChange () {
      let param = {
        name: this.dialog.deviceName,
      }
      GetDeviceModelAll(param).then(res => {
        this.deviceModels = res
        this.dialog.deviceModel = res.length > 0 && res[0].isLink === 1 ? res[0].model : '';
      });
      GetDeviceTypeAll(param).then(res => {
        this.deviceTypes = res
        this.dialog.deviceType = res.length > 0 && res[0].isLink === 1 ? res[0].typeName : '';
      });
      GetDeviceBrandAll(param).then(res => {
        this.deviceBrands = res
        this.dialog.deviceBrand = res.length > 0 && res[0].isLink === 1 ? res[0].brandName : '';
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.footer-box {
  text-align: center;
}
</style>