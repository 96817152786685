import request from '@/utils/request'

// 查询计划列表
export const GetScheduleList = (data) => request({
  method: 'post',
  url: '/SysPlanTask/queryByPage.do',
  data: JSON.stringify(data),
})

// 计划新增和修改
export const AddSchedule = (data) => request({
  method: 'post',
  url: '/SysPlanTask/addOrUpdate.do',
  data: JSON.stringify(data)
})

// 删除计划
export const DelSchedule = (data) => request({
  method: 'post',
  url: '/SysPlanTask/delete.do',
  data: JSON.stringify(data)
})

// 获取计划详情
export const GetScheduleItem = (data) => request({
  method: 'post',
  url: '/SysPlanTask/queryById.do',
  data: JSON.stringify(data)
})
