<template>
  <div
    v-show="visible"
    class="bgcolor-wrap"
  >
    <el-row :gutter="20">
      <el-col :span="8">{{data.bgcolor.subFieldText}}</el-col>
      <el-col :span="16">{{data.bgcolor.name}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">Level</el-col>
      <el-col :span="16">Threshold Range</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div
          class="level"
          :style="{
            'background':data.bgcolor.colorL1
          }"
        >L1</div>
      </el-col>
      <el-col :span="16">
        <div
          class="level-con"
          :style="{
            'border-color':data.bgcolor.colorL1
          }"
        >
          <div class="level-num">
            <div class="triangle"></div>
            <div class="num">{{data.bgcolor.minL1}}</div>
          </div>
          <span
            class="con"
            :style="{
              'background':bgcolor1
            }"
          >{{num1}}</span>
          <div class="level-num">
            <div class="triangle next"></div>
            <div class="num">{{data.bgcolor.maxL1}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div
          class="level"
          :style="{
          'background':data.bgcolor.colorL2
        }"
        >L2</div>
      </el-col>
      <el-col :span="16">
        <div
          class="level-con"
          :style="{
            'border-color':data.bgcolor.colorL2
          }"
        >
          <div class="level-num">
            <div class="triangle"></div>
            <div class="num">{{data.bgcolor.minL2}}</div>
          </div>
          <span
            class="con"
            :style="{
              'background':bgcolor2
            }"
          >{{num2}}</span>
          <div class="level-num">
            <div class="triangle next"></div>
            <div class="num">{{data.bgcolor.maxL2}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div
          class="level"
          :style="{
          'background':data.bgcolor.colorL3
        }"
        >L3</div>
      </el-col>
      <el-col :span="16">
        <div
          class="level-con"
          :style="{
            'border-color':data.bgcolor.colorL3
          }"
        >
          <div class="level-num">
            <div class="triangle"></div>
            <div class="num">{{data.bgcolor.minL3}}</div>
          </div>
          <span
            class="con"
            :style="{
              'background':bgcolor3
            }"
          >{{num3}}</span>
          <div class="level-num">
            <div class="triangle next"></div>
            <div class="num">{{data.bgcolor.maxL3}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['visible', 'data'],
  data () {
    return {
      num1: '',
      num2: '',
      num3: '',
      bgcolor1: '',
      bgcolor2: '',
      bgcolor3: '',
    }
  },
  watch: {
    data: function () {
      let data = this.data.bgcolor;
      this.num1 = '';
      this.num2 = '';
      this.num3 = '';
      this.bgcolor1 = '';
      this.bgcolor2 = '';
      this.bgcolor3 = '';
      if (data.minL1 < this.data.num && this.data.num <= data.maxL1) {
        this.num1 = this.data.num;
        this.bgcolor1 = data.colorL1;
      } else if (data.minL2 < this.data.num && this.data.num <= data.maxL2) {
        this.num2 = this.data.num;
        this.bgcolor2 = data.colorL2;
      } else if (data.minL3 < this.data.num && this.data.num <= data.maxL3) {
        this.num3 = this.data.num;
        this.bgcolor3 = data.colorL3;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bgcolor-wrap {
  width: 220px;
  height: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  position: fixed;
  z-index: 999;
}

.level {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}
.level-con {
  height: 35px;
  padding-top: 2px;

  border-top: #f2f2f2 solid 7px;
  display: flex;
  .level-num {
    width: 26px;
    text-align: center;
    .num {
      height: calc(100% - 10px);
      background: #f2f2f2;
    }
    .triangle {
      box-sizing: content-box;
      width: 0px;
      height: 0px;
      border: #ccc solid 10px;

      border-width: 0px 13px 10px 13px;
      border-left-color: transparent;
      border-right-color: transparent;
      &.next {
        border-bottom-color: #666;
      }
    }
  }
  .con {
    line-height: 28px;
    text-align: center;
    margin: 0 5px;
    flex-grow: 1;
  }
}
</style>